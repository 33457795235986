import React, {useState, useEffect} from "react";
import {WrapField} from "../../reuse/dashboardUI/modalStyles";
import {MultipleSelect} from "../../../../oat-ui";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {selectActiveWorkSpaceInfo} from "../../../store/selectors/userDashboard.selector";
import {connect} from "react-redux";

const PrivateFolderUI = ({
  privateFolder,
  accessibleUsers,
  onChangeVisibility,
  handleAccessibleUsers,
  workspaceMembers,
  username,
}) => {
  const [wsMembers, setWsMembers] = useState([]);

  useEffect(() => {
    let restructureMembers = [];
    workspaceMembers &&
      Object.entries(workspaceMembers).map(([id, member]) => {
        if (id !== username) {
          restructureMembers.push({
            display: member.name,
            value: id,
          });
        }
      });
    setWsMembers(restructureMembers);
  }, [workspaceMembers]);

  return (
    <div>
      <WrapField style={{margin: "6px 0 16px", padding: 0}}>
        <div style={{flex: 1}}>Private</div>
        <div style={{flex: 1}}>
          <label className="oatall-input-switch">
            <input
              type="checkbox"
              value={privateFolder}
              onChange={onChangeVisibility}
              checked={privateFolder}
            />
            <span className="slider round" />
          </label>
        </div>
      </WrapField>

      {privateFolder ? (
        <WrapField
          style={{
            alignItems: "flex-start",
            margin: "16px 0 0",
            padding: 0,
          }}
        >
          <div style={{flex: 1}}>Select Users</div>
          <div style={{flex: 1}}>
            <MultipleSelect
              items={wsMembers}
              selected={accessibleUsers}
              onSelect={handleAccessibleUsers}
            />
          </div>
        </WrapField>
      ) : undefined}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
    workspaceMembers: selectActiveWorkSpaceInfo(state.userDashboard).members,
  };
};
export default connect(mapStateToProps, null)(PrivateFolderUI);
