import {useEffect} from "react";

function DetectClickOutside(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      // do nothing
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

export {DetectClickOutside as detectClickOutside};
