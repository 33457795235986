const LineChartIcon = (
  <svg width="100%" height="100%" viewBox="0 0 857.5 514.5">
    <g>
      <g>
        <path
          fill="transparent"
          stroke="#ff4747"
          strokeWidth="3"
          d="M51.45,171.5,302.98333333333335,0,554.5166666666667,171.5,806.0500000000001,257.25"
        ></path>
      </g>
      <g>
        <g>
          <circle cx="51.45" cy="171.5" r="6" fill="#ff4747"></circle>
        </g>
        <g>
          <circle cx="302.9833" cy="0" r="6" fill="#ff4747"></circle>
        </g>
        <g>
          <circle cx="554.5166" cy="171.5" r="6" fill="#ff4747"></circle>
        </g>
        <g>
          <circle cx="806.0501" cy="257.25" r="6" fill="#ff4747"></circle>
        </g>
      </g>
    </g>
    <g>
      <g>
        <path
          fill="transparent"
          stroke="#f4dd12ff"
          strokeWidth="3"
          d="M51.45,428.75,302.98333333333335,171.5,554.5166666666667,257.25,806.0500000000001,85.75"
        ></path>
      </g>
      <g>
        <g>
          <circle cx="51.45" cy="428.75" r="6" fill="#f4dd12ff"></circle>
        </g>
        <g>
          <circle
            cx="302.98333333333335"
            cy="171.5"
            r="6"
            fill="#f4dd12ff"
          ></circle>
        </g>
        <g>
          <circle
            cx="554.5166666666667"
            cy="257.25"
            r="6"
            fill="#f4dd12ff"
          ></circle>
        </g>
        <g>
          <circle
            cx="806.0500000000001"
            cy="85.75"
            r="6"
            fill="#f4dd12ff"
          ></circle>
        </g>
      </g>
    </g>

    <g>
      <g>
        <line
          x1="0"
          x2="857.5"
          y1="514.5"
          y2="514.5"
          stroke="#232323"
          strokeWidth="1"
        ></line>
      </g>
    </g>
  </svg>
);

export default LineChartIcon;
