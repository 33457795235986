import React from "react";
import {connect} from "react-redux";
import {
  selectedFieldType,
  selectActiveField,
} from "../../../store/selectors/fields.selector";
import {selectMobEditingPanel} from "../../../store/selectors/layout/layout.selector";
// import TextEditPanel from "./panelTypes/mobile/editingPanel/TextEditPanel";
// import ShapeEditPanel from "./panelTypes/mobile/editingPanel/ShapeEditPanel";
// import ImageEditPanel from "./panelTypes/mobile/editingPanel/ImageEditPanel";
// import MobBgColor from "./panelTypes/mobile/MobBgColor";
import SelectedFieldPanel from "./panelTypes/mobile/SelectedFieldPanel";

const EditingPanel = ({fieldType, mobEditingPanel, activeCreatePanel}) => {
  let displayOptions;

  return (
    // <LazyLoad height="100%" once>
    //   <Wrapper style={{height: mobEditingPanel.addedHeight + 1}}>
    //     {displayOptions}
    //   </Wrapper>

    <div
      style={{
        background: "#171723",
        width: "100%",
        height: 50,
        // overflow: "hidden",
        // display:
        //   fieldType === "" || fieldType === null || fieldType === undefined
        //     ? "none"
        //     : "block",
        //   display: "flex",
        //   alignItems: "center",
      }}
    >
      <SelectedFieldPanel type={fieldType} />
    </div>
    // </LazyLoad>
  );
};

function mapStateToProps(state) {
  const {designTemplate} = state;
  return {
    fieldType: selectedFieldType(designTemplate),
    activeField: selectActiveField(designTemplate),
    mobEditingPanel: selectMobEditingPanel(designTemplate),
    // activeCreatePanel: selectCreatePanel(template),
  };
}

export default connect(mapStateToProps, null)(EditingPanel);
