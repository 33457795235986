import React from "react";
import PopoverColorBox from "./PopoverColorBox";
import styled from "styled-components";
import RangeSlider from "../inputs/RangeSlider";
import {WrapColorBox} from "../../desktop/common/WrapColorStyles";
import {Title} from "../reuseStyles/Title";

const DeskGradientColors = ({
  angle,
  colorOne,
  colorTwo,
  updateColorOne,
  updateColorTwo,
  updateFieldStyles,
  gradientType,
  onDebounceColorOne,
  onDebounceColorTwo,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ColorsSection
        style={{
          marginBottom: gradientType === "linear" ? "2em" : "0",
        }}
      >
        <Title>Colors</Title>
        <WrapColorBox>
          <div style={{marginRight: 25}}>
            <PopoverColorBox
              title="Color 1"
              value={colorOne}
              updateFieldStyles={updateColorOne}
              onDebounce={onDebounceColorOne}
            />
          </div>

          <div>
            <PopoverColorBox
              title="Color 2"
              value={colorTwo}
              updateFieldStyles={updateColorTwo}
              onDebounce={onDebounceColorTwo}
            />
          </div>
        </WrapColorBox>
      </ColorsSection>

      {gradientType === "linear" ? (
        <WrapField>
          <RangeSlider
            title="Angle"
            value={angle}
            max={360}
            min={0}
            step={1}
            onItemChange={updateFieldStyles}
            fieldType="background-angle"
          />
        </WrapField>
      ) : undefined}
    </div>
  );
};

const WrapField = styled.div``;

const ColorsSection = styled.div`
  display: flex;
  align-items: center;
`;

export default DeskGradientColors;
