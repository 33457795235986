import React, {useEffect, useRef, useState, useCallback, useLayoutEffect} from "react";
import {
  Modal,
  Spinner,
  BottomUpDrawer
} from "../../oat-ui";
import {
  Image,
  Layout,
  Items,
  PotraitItem,
  LandscapeItem,
  ErrorMsg,
  DesignType,
  listSubtractWidth,
  listViewWidth,
  RequiresUpgradeToPro,
  Owner
} from "./styles/templateListStyles";
import TemplateTags from "./TemplateTags";
import TemplateInfo from "./TemplateInfo";
import {getWindowSize} from "../../oat-window-size/getWindowSize";
import {useParams, useNavigate, useLocation} from "react-router";
import TemplateHeader from "./TemplateHeader";
import RequestTempLocation from "./RequestTempLocation";
import {WrapSection, NothingHere, PricingTag, WrapSpinner } from "./styles/itemListStyles";
import AnimateIn from "../../oat-ui/animation/Animate";
import { PricingType } from "./styles/itemDetailsStyles";
import { connect } from "react-redux";

const DisplayItem = ({item, onOpenModal, thumbnailType, category, ratio}) => {
  const itemRef = useRef();
  const [itemSize, setItemSize] = useState({w: 0, h: 0});
  const [loading, setLoading] = useState(true);
  const windowSize = getWindowSize();
  
  const handleClick = (e) => {
    e.preventDefault();
    // if it's a right-click 
    if (e.type === 'contextmenu') {
      // Right-click, redirect to the link
      window.location.href = e.currentTarget.getAttribute('href');
    } else {
      // Regular click, show the modal
      onOpenModal({
        tempID: item.tempID,
        theme: item.theme,
      });
    }
  };

  useLayoutEffect(() => {
    const paddingValue = `${Math.floor(windowSize.width * (listViewWidth / 100)) - listSubtractWidth}`;
    let gapValue = 16; // gap
    if (windowSize.width >= 1500) {
      gapValue = 24
    } else if (windowSize.width >= 900 && windowSize.width < 1500) {
      gapValue = 28
    }

    const remainingSpace =  Number(`${windowSize.width - 2 * parseInt(paddingValue, 10) - gapValue}`);

    if (item.size?.w && itemRef?.current) {
      const width = remainingSpace / (item.dms === "w<h" || item.dms === "w==h" ? 3 : 2); // itemRef?.current?.offsetWidth; // not working when resize on safari and moz
      const height = (item.size?.h * width) / item.size?.w;

      if (width * 3 > 1500) {
        setItemSize({w: (1500 / 3) - gapValue * 2, h: height});
      } else {
        setItemSize({w: width, h: height});
      } 
    };
  }, [item.size?.w, windowSize.width]);
  
  const onFinishedLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 200); 
  }
  
  return item.dms === "w<h" || item.dms === "w==h" ? (
    <AnimateIn 
      style={{ 
        minWidth: loading ? itemSize.w : undefined, 
        minHeight: loading ? itemSize.h : undefined, 
      }} 
      speed={0.33}
    >
      <PotraitItem 
        ref={itemRef} 
        to={`/templates/${category}/${ratio}?id=${item.tempID}`}
        style={{ 
          minWidth: loading ? itemSize.w : undefined, 
          minHeight: loading ? itemSize.h : undefined, 
        }}
        onClick={handleClick}
      >
        <Image
          src={`https://d1nryft0rd5acr.cloudfront.net/${item.thumbs[thumbnailType]}`}
          loading="lazy"
          style={{ 
            minWidth: loading ? itemSize.w : undefined, 
            minHeight: loading ? itemSize.h : undefined, 
            boxShadow: !loading ? `0px 0px 1px 1px #eeeeee` : undefined
          }}
          onLoad={onFinishedLoading}
        />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Owner src="https://oat-default-init.s3.amazonaws.com/logos/oat-all-logo.jpg" alt="template-owner" />
          
          <DesignType>
            {item?.designType}
          </DesignType>
          
          <PricingTag>
            {item?.requiresUpgrade ?
              <RequiresUpgradeToPro>
                Upgrade
              </RequiresUpgradeToPro> : 
              <PricingType>
                Free
              </PricingType>
            }
          </PricingTag>
        </div>
      </PotraitItem>
    </AnimateIn>
  ) : (
    <AnimateIn 
      style={{
        minWidth: loading ? itemSize.w : undefined, 
        minHeight: loading ? itemSize.h : undefined, 
      }} 
      speed={0.33}
    >
      <LandscapeItem 
        ref={itemRef} 
        to={`/templates/${category}/${ratio}?id=${item.tempID}`}
        style={{ 
          minWidth: loading ? itemSize.w : undefined,
          minHeight: loading ? itemSize.h : undefined, 
        }}
        onClick={handleClick}
      >
        <Image
          src={`https://d1nryft0rd5acr.cloudfront.net/${item.thumbs[thumbnailType]}`}
          loading="lazy"
          style={{ 
            minWidth: loading ? itemSize.w : undefined, 
            minHeight: loading ? itemSize.h : undefined,
            boxShadow: !loading ? `0px 0px 1px 1px #eeeeee` : undefined
          }}
          onLoad={onFinishedLoading}
        />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Owner src="https://dnaeh50kru9rd.cloudfront.net/logos/oat-all-logo.jpg" alt="template-owner" />
          
          <DesignType>
            {item?.designType}
          </DesignType>
          
          <PricingTag>
            {item?.requiresUpgrade ?
              <RequiresUpgradeToPro>
                Upgrade
              </RequiresUpgradeToPro> : 
              <PricingType>
                Free
              </PricingType>
            }
          </PricingTag>
        </div>
        {/* <WrapTempInfo>
          <ItemName style={{flex: 4}} onClick={linkToTemplate}>
            {item.title}
          </ItemName>
        </WrapTempInfo> */}
      </LandscapeItem>
    </AnimateIn>
  );
};

const Templates = () => {
  const [openModal, setOpenModal] = useState(false);
  const windowSize = getWindowSize();
  let params = useParams();
  const {category, ratio} = params;
  let navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const tagParam = query.get("tag");
  const tempIDParam = query.get("id");
  const workspaceID = query.get("workspace");
  const folderID = query.get("folder");
  const [thumbnailSizeType, setThumbnailSizeType] = useState("small"); // small medium
  // request workspace and folder to store template if not provided
  const [requestTempLocation, setRequestTempLocation] = useState({
    status: false,
    workspaceID: null,
    folderID: null,
    tempInfo: {},
  });

  const [templates, setTemplates] = useState({
    items: [],
    selectedTemplate: null,
    error: null,
    lastEvaluatedKey: null,
    initialLoad: true
  });
  const [tag, setTag] = useState({
    items: [],
    selected: "",
  });
  const [loading, setLoading] = useState({
    buttonSpinner: false,
    spinner: false,
  });
  const [page, setPage] = useState(1);
  const [tempCategoryRatio, setTempCategoryRatio] = useState({
    ratio: "",
    category: ""
  })

  useEffect(() => {
    // if there is query ID -> ID from opening modal -> redirect to details page 
    if (tempIDParam && !workspaceID && !folderID) {
      return navigate(`/templates/${category}/${ratio}/${tempIDParam}`)
    }
  }, [])

  useEffect(() => {
    setTempCategoryRatio({
      category, ratio
    })
  }, [category, ratio])

  const fetchTemplates = (url, lastEvaluatedKey) => {
    if (lastEvaluatedKey === null || tempCategoryRatio?.category !== category) {
      setLoading({
        ...loading,
        spinner: true,
      });
      setTemplates({
        ...templates,
        items: [],
        lastEvaluatedKey: null,
        initialLoad: true
      });
    } else {
      setLoading({
        ...loading,
        buttonSpinner: true,
      });
    }
    const requestOptions = {
      method: "GET",
      cache: "no-store",
      headers: {"Content-Type": "application/json"},
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (tempCategoryRatio.category !== category) {
          setTemplates({
            ...templates,
            items: data.items,
            lastEvaluatedKey: data.paginationToken,
            initialLoad: false
          });
        }
        else if (data.items.length > 0) {
          setTemplates({
            ...templates,
            items: [...templates.items, ...data.items],
            lastEvaluatedKey: data.paginationToken,
            initialLoad: false
          });
        } else if (data.items.length === 0) {
          setTemplates({
            items: [],
            selectedTemplate: null,
            error: null,
            lastEvaluatedKey: null,
            initialLoad: false
          });
        }
        setLoading({
          ...loading,
          spinner: false,
          buttonSpinner: false,
        });
      })
      .catch((err) => {
        setLoading({
          ...loading,
          spinner: false,
          buttonSpinner: false,
        });
      });
  };
  
  useEffect(() => {
    if(page === 1) {
      fetchTemplates(
        tagParam
          ? `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category}/${ratio}/en?tag=${tagParam}`
          : `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category}/${ratio}/en?tag=`,
        // ? `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${params.category}/16x9/en?tag=${tagParam}`
        // : `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${params.category}/16x9/en?tag=`,
        null
      );
      if (tagParam) {
        setTag({
          ...tag,
          selected: tagParam,
        });
      }
    }
  }, [tagParam, page, category, ratio]);

  const retrieveTemplatesByTags = (tag) => {
    setTag({
      ...tag,
      selected: tag,
    });
    navigate(`/templates/${category}/${ratio}?tag=${tag}`);
    fetchTemplates(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${params.category}/${params.ratio}/en?tag=${tag}`,
      null
    );
  };

  const handleModalOpen = (item) => {
    setOpenModal(!openModal);
    setTemplates({
      ...templates,
      selectedTemplate: item,
    });
    if (workspaceID && folderID) {
      navigate(`/templates/${category}/${ratio}?workspace=${workspaceID}&folder=${folderID}&id=${item.tempID}`);
    } else {
      navigate(`/templates/${category}/${ratio}?id=${item.tempID}`);
    }
    const bodyElt = document.querySelector("body");
    bodyElt.style.overflow = "hidden";    
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setRequestTempLocation({...requestTempLocation, status: false});
    if (workspaceID && folderID) {
      navigate(`/templates/${category}/${ratio}?workspace=${workspaceID}&folder=${folderID}`);
    } else {
      navigate(`/templates/${category}/${ratio}`);
    }
    const bodyElt = document.querySelector("body");
    bodyElt.style.overflow = "auto";
  };

  useEffect(() => {
    if (page !== 1) {
      !tag.selected
        ? fetchTemplates(
          `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category}/${ratio}/en?tag=&paginationToken=${templates.lastEvaluatedKey}`,
           templates.lastEvaluatedKey
          )
        : fetchTemplates(
            `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category}/${ratio}/en?tag=${tag}&paginationToken=${templates.lastEvaluatedKey}`,
            templates.lastEvaluatedKey
          );
    }
  }, [page, category, ratio]);

  useEffect(() => {
    if (windowSize.width > 600) {
      setThumbnailSizeType("medium");
    } else {
      setThumbnailSizeType("small");
    }
  }, [windowSize.width]);

  const onRequestTempLocation = (tempInfo) => {
    setRequestTempLocation({
      ...requestTempLocation,
      status: true,
      tempInfo,
    });
  };

  const onSelectWorkspace = (item) => {
    setRequestTempLocation({
      ...requestTempLocation,
      workspaceID: item.value,
      workspaceName: item.display,
    });
  };

  const onSelectFolder = (item) => {
    setRequestTempLocation({
      ...requestTempLocation,
      folderID: item.value,
      folderName: item.display,
    });
  };

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (templates.initialLoad) return; 
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async(entries) => {
        if (entries[0].isIntersecting && templates.lastEvaluatedKey !== null) {
          setPage((prev) => prev + 1);
        }
      }, { threshold: 0.5 });
      if (node) observer.current.observe(node);
    },
    [templates.initialLoad, templates.lastEvaluatedKey]
  );

  return (
    <Layout>
      {/* <div style={{position: "sticky", top: -1}}>
        <TemplateTags
          selectedTag={tag.selected}
          onSelectTag={retrieveTemplatesByTags}
        />
      </div> */}

      <WrapSection>
        {/* <TemplateHeader /> */}

        <div style={{ height: 20}} />

        {loading.spinner && templates.initialLoad ? (
          <WrapSpinner>
            <Spinner />
          </WrapSpinner>
        ) : undefined}

        <div style={{minHeight: window.innerHeight - 200}}>
          {!loading.spinner && templates.items?.length > 0 ? (
            <>
              <Items
                $landscape={templates.items[0].dms === "w>h" ? true : false}
                 // important for setting infinite scroll to load properly
              >
                {templates.items &&
                  templates.items.map((item, i) => (
                    <DisplayItem
                      key={i}
                      item={item}
                      onOpenModal={handleModalOpen}
                      thumbnailType={thumbnailSizeType}
                      category={category}
                      ratio={ratio}
                    />
                  ))}   
              </Items>

              {templates.items && templates.items?.length > 0 ?
                <>
                  <div ref={lastItemRef} />
                  <div style={{height: 300}} />
                </>
              : undefined}
            </>
          ) : undefined}

          {!loading.spinner && templates.error !== null ? (
            <ErrorMsg>{templates.error}</ErrorMsg>
          ) : undefined}

          {!loading.spinner && !templates.initialLoad && templates?.items?.length === 0 ? (
            <NothingHere>Nothing here...!</NothingHere>
          ) : undefined}
        </div>
      </WrapSection>

      {openModal && !requestTempLocation.status && windowSize.width > 600 ? (
        <Modal
          top={windowSize.width > 1400 ? (10 * windowSize.height) / 100 : 85}
          width={1150}
          height={windowSize.height > 450 ? 548 : windowSize.height - 10}
          close={handleModalClose}
        >
          <TemplateInfo
            modalOpen={openModal}
            height={windowSize.height > 450 ? 548 : windowSize.height - 10}
            selectedItem={templates.selectedTemplate}
            onRequestTempLocation={onRequestTempLocation}
          />
        </Modal>
      ) : undefined}

      {requestTempLocation.status ? (
          <RequestTempLocation
            selectedLocation={requestTempLocation}
            tempInfo={requestTempLocation.tempInfo}
            onSelectWorkspace={onSelectWorkspace}
            onSelectFolder={onSelectFolder}
            onClose={handleModalClose}
          />
      ) : undefined}

      {windowSize.width <= 600 && !requestTempLocation.status ? (
        <BottomUpDrawer
          open={openModal}
          height={window.innerHeight - 60}
          close={handleModalClose}
        >
          {templates.selectedTemplate ? (
            <TemplateInfo
              selectedItem={templates.selectedTemplate}
              modalOpen={openModal}
              onRequestTempLocation={onRequestTempLocation}
            />
          ) : undefined}
        </BottomUpDrawer>
      ) : undefined}
    </Layout>
  );
};

export default Templates;
