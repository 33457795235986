import React from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {selectCanvasPanelWidth} from "../../../../store/selectors/layout/layout.selector";
import {selectSmartAlignment} from "../../../../store/selectors/smartAlign.selector";
import {selectTemplateSize} from "../../../../store/selectors/template/template.selector";

const FieldAlign = ({alignment, canvasWidth, tempSize, tempScale}) => {
  const adjustedXOffset = window.innerWidth >= 850 ? Math.round(canvasWidth - tempSize.w * tempScale) / 2 : 0;
  return (
    <>
      {alignment?.x?.display ? (
        <VertAlign
          style={{
            left: Math.ceil(adjustedXOffset + alignment?.x?.value * tempScale),
            top: 0,
            height: "100vh",
            borderLeft: `1px #f92ff5 solid`,
          }}
        />
      ) : undefined}

      {alignment?.y?.display ? (
        <HorzAlign
          style={{
            left: 0,
            top: Math.ceil(alignment?.y?.value * tempScale),
            width: "100vw",
            borderTop: `1px #f92ff5 solid`,
          }}
        />
      ) : undefined}
    </>
  );
};

const styles = css`
  z-index: 10000;
  position: absolute;
  pointer-events: none;
  display: block;
`;

const VertAlign = styled.div`
  ${styles};
`;

const HorzAlign = styled.div`
  ${styles};
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    alignment: selectSmartAlignment(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    canvasWidth: selectCanvasPanelWidth(designTemplate),
  };
};

export default connect(mapStateToProps)(FieldAlign);
