import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import {Close} from "../../oat-svg-icons";
import {createPortal} from "react-dom";
import {detectClickOutside} from "../utils/detectClickOutside";

// Drawer with rounded corners, not a fully height one
const BottomUpDrawer = ({
  open,
  children,
  height,
  background,
  styles,
  close,
  hideClosePanel,
}) => {
  const layoutRef = useRef();
  const [activateDimBg, setActivateDimBg] = useState(false);

  detectClickOutside(layoutRef, () => {
    close();
    // setActivateDimBg(false);
  });

  // useEffect(() => {
  //   if (open) setActivateDimBg(true);
  // }, [open]);

  return createPortal(
    // <div
    //   style={{
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //     // background: "rgb(36 35 41 / 90%)",
    //     position: "fixed",
    //     // bottom: open ? 0 : 0,
    //     zIndex: "99999",
    //     transition: "all 0.5s ease-in-out",
    //   }}
    // >
    <>
      <BackLayer
        style={{
          width: "100vw",
          height: open ? "100vh" : 0,
          opacity: open ? 0.5 : 1,
        }}
      ></BackLayer>
      <Wrapper
        ref={layoutRef}
        style={{
          overflowY: "hidden",
          ...styles,
          background,
          // boxShadow: "0px 1px 2px 0px #232323",
          // bottom: open ? 0 : -800,
          // // overflow: "hidden",
          zIndex: 9999,
          height: open ? height : 0,
        }}
      >
        {!hideClosePanel ? (
          <TopCloseBar>
            <CloseBtn onClick={close}>
              <WrapSvg>{Close}</WrapSvg>
            </CloseBtn>
          </TopCloseBar>
        ) : undefined}
        <div style={{overflowY: "scroll"}}>{children}</div>
      </Wrapper>
    </>,
    document.getElementById("bottomUp-drawer")
  );
};

const BackLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #1c1c1ce6;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  color: ${({theme}) => theme.deskPrimaryTxt};
  box-sizing: border-box;
  font-size: 1.1rem;
  transition: all 0.4s ease-in-out;
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .drawer-container {
    height: 100%;
    margin-bottom: 32px;
  }
`;

const TopCloseBar = styled.div`
  height: 30px;
  position: fixed;
  top: 5px;
  right: 22px;
  fill: #fff;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 850px) {
    position: relative;
    height: 40px;
    fill: #232323;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 8px;
  }
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  fill: #232323;
  margin-right: 6px;
`;

const CloseBtn = styled.div`
  width: 42px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

BottomUpDrawer.defaultProps = {
  height: "100vh",
  background: "#fff",
  hideClosePanel: false,
};

export default BottomUpDrawer;
