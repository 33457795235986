export function drawLine(x1, y1, x2, y2) {
  let a = x1 - x2,
    b = y1 - y2,
    distance = Math.sqrt(a * a + b * b);

  let sx = (x1 + x2) / 2,
    sy = (y1 + y2) / 2;

  let x = sx - distance / 2,
    y = sy;

  //   const alpha = Math.PI - Math.atan2(-b, a); as rad
  const angleDeg = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
  return {x, y, distance, angle: angleDeg};
}
