import React from "react";
import {WrapResizer} from "./../resize/Resizer";
import styled from "styled-components";
import "./../resize/resizer.css";
import {NameTab} from "../SelectedOnlineUsersItems";
import {resizerColor} from "../../../../../themes";

const OnlineUsersGroupedItems = ({tempScale, onlineUser, collaboration}) => {
  const groupSelect = onlineUser.groupSelection;
  const {size, offset} = groupSelect.selection || {};
  return (
    <WrapResizer
      style={{
        position: "absolute",
        width: size?.w * tempScale + 1, // remove gap white space
        height: size?.h * tempScale + 1,
        border: `1px solid ${!collaboration ? resizerColor : onlineUser.color}`,
        transform: `translate(${offset?.x * tempScale - 1}px, ${
          offset?.y * tempScale - 1
        }px)`,
        outline: `rgba(234, 243, 247, 0.5) solid ${1 / tempScale}px`,
        zIndex: 9999,
        fontSize: 12,
      }}
    >
      {collaboration ? (
        <NameTab style={{ left: 14, top: -24}} tempScale={1} color={onlineUser?.color}>
          {onlineUser?.name}
        </NameTab>
      ) : undefined}
    </WrapResizer>
  );
};

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  z-index: 19999;
  transform-origin: 50% 50%;
  &:after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
`;

export default OnlineUsersGroupedItems;
