import React from "react";
import styled from "styled-components";
import {Modal, Spinner} from "../../../../../oat-ui";

const ModalProcessing = ({close}) => {
  return (
    <Modal width={210} height={82} close={close}>
      <Wrapper>
        <Spinner />
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export default ModalProcessing;
