import React from "react";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {Layout, WrapHeader, maxWidthPanel} from "../FeatureList";
import AnimateIn from "../../../../oat-ui/animation/Animate";
import {HomeHeader} from "../FeaturesShowcase2";
import styled from "styled-components";
import {homePageBackground2} from "../styles/layoutStyles";
import Check from "../../../../oat-svg-icons/icons/Check";
import { Section, WrapImage, ContentList, CheckSvg, wrapImgStyles } from "./homeCoreStyles";

const SharingAndExport = () => {
  const windowSize = getWindowSize();
  return (
    <Layout style={{background: homePageBackground2, paddingBottom: 24}}>
      <Section>
        {/* <WrapHeader>
          <AnimateIn>
            <HomeHeader><span>3 -</span>Sharing & Export</HomeHeader>
          </AnimateIn>
        </WrapHeader> */}

        <Wrapper>
          <AnimateIn>
            <ImageItem
              loading="lazy"
              style={{
                background: `linear-gradient(rgb(215 215 248), rgb(220 225 255))`,
                backgroundImage: `url(https://dnaeh50kru9rd.cloudfront.net/backgrounds/pale-pink-bg-1.jpg)`,
                width: `100%`,
                height: `100%`,
                backgroundSize: `cover`
              }}
            >
              <WrapImage>
                <Image
                  loading="lazy"
                  src={
                    windowSize.width > 600
                      ? `https://dnaeh50kru9rd.cloudfront.net/home-page/sharing-and-export-800.png`
                      : `https://dnaeh50kru9rd.cloudfront.net/home-page/sharing-and-export-500.png`
                  }
                  alt="template-sharing"
                />
              </WrapImage>
            </ImageItem>
          </AnimateIn>

          <AnimateIn>
            <Item>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {/* <ShortTitle>
                  Easily share as link or export to desire format
                </ShortTitle> */}
                <ContentList>
                  <HomeHeader><span>3 -</span>Sharing & Export</HomeHeader>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <div>
                        <p className="feature">Passcode Protected</p>
                        <p className="feature-description">
                          Only allows authorized users to gain access
                        </p>
                      </div>
                    </div>
                  </>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <div>
                        <p className="feature">Friendly URL Builder</p>
                        <p className="feature-description">
                          Create a human understandable links for a recipient
                        </p>
                      </div>
                    </div>
                  </>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <div>
                        <p className="feature">Easily export your designs</p>
                        <p className="feature-description">JPG, PNG, PDF</p>
                      </div>
                    </div>
                  </>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <div>
                        <p className="feature">Analytics (Coming Soon)</p>
                        <p className="feature-description">
                          Understand your user better
                        </p>
                      </div>
                    </div>
                  </>
                </ContentList>
              </div>
            </Item>
          </AnimateIn>
        </Wrapper>
      </Section>
    </Layout>
  );
};

const Item = styled.div`
  ${wrapImgStyles};
  @media only screen and (min-width: 901px) {
    margin-left: 24px;
  }
`;

const ImageItem = styled.div`
  ${wrapImgStyles};
  padding: 94px 32px;
  @media only screen and (max-width: 900px){
    padding: 42px 28px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  ${maxWidthPanel};
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  animation: sharing-export-animate 5s linear 0.1s infinite alternate;
  -webkit-animation: sharing-export-animate 5s 0.1s linear infinite alternate;
  @-webkit-keyframes sharing-export-animate {
    0% {
      -webkit-transform: rotate(3deg) translateY(-13%);
    }
  }
  @keyframes sharing-export-animate {
    0% {
      transform: rotate(3deg) translateY(-13%);
    }
  }
`

export default SharingAndExport;
