export const selectAnimationType = () => {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const {groupedKeys} = action;
    const newState = [...state.template];
    const slideIndex = newState.findIndex((slide) => slide.id === slideID);
    const field = newState[slideIndex].fields[fieldID];
    if (
      groupedKeys.length === 0 &&
      action.active &&
      action.active.fieldID !== -1
    ) {
      let currentSlideOnClickAnimationOrder =
        field.styles?.animationOrder > 0 ? field.styles.animationOrder : 1;
      if (action.payload === "none") {
        newState[slideIndex].fields[fieldID] = {
          ...field,
          ["styles"]: {
            ...field.styles,
            animationType: action.payload,
            animationOrder: 0,
            enableExitAnimation: false
          },
        };
        currentSlideOnClickAnimationOrder = 0;
      } else {
        newState[slideIndex].fields[fieldID] = {
          ...field,
          ["styles"]: {
            ...field.styles,
            animationType: action.payload,
            animationDelay: field?.styles?.animationDelay ? field?.styles?.animationDelay : 0.5,
            animateOnSlideDisplay: field?.styles?.animateOnSlideDisplay === null 
              ? true : field?.styles?.animateOnSlideDisplay 
          },
        };
      }
    } else if (groupedKeys.length > 0) {
      let updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (groupedKeys.includes(id)) {
            if (action.payload === "none") {
              return {
                ...field,
                ["styles"]: {
                  ...field.styles,
                  animationType: action.payload,
                  animationOrder: 0,
                  enableExitAnimation: false,
                },
              };
            } else {
              return {
                ...field,
                ["styles"]: {
                  ...field.styles,
                  animationType: action.payload,
                  animationDelay: field?.styles?.animationDelay ? field?.styles?.animationDelay : 0.5,
                },
              };
            }
          } else {
            return field;
          }
        }
      );

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
};

export function applyAnimationOrder() {
  return function (state, action) {
    const newState = [...state.template];
    const {slideID, fieldID} = action.active;
    const {groupedKeys} = action;
    const slideIndex = newState.findIndex((slide) => slide.id === slideID);
    if (groupedKeys.length === 0 && action.active?.fieldID !== -1) {
      const newState = [...state.template];
      let reindexAnimationOrder = [];
      const updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (field.styles?.animateOnSlideDisplay === false) {
            if (fieldID === field.key) {
              if (!reindexAnimationOrder.includes(action.value)) {
                reindexAnimationOrder.push(action.value);
              }
              return {
                ...field,
                ["styles"]: {
                  ...field.styles,
                  animationOrder: action.value,
                  // animationDelay: 0,
                },
              };
            } else {
              if (
                field.styles?.animationOrder &&
                !reindexAnimationOrder.includes(field.styles?.animationOrder)
              ) {
                reindexAnimationOrder.push(field.styles?.animationOrder);
              }
              return field;
            }
          }
          else {
            return field
          }
        }
      );

      let indexMapping = {};
      const sortedArray = [...reindexAnimationOrder];
      sortedArray.sort((a, b) => a - b);
      sortedArray.map((order, i) =>
        order === 0 ? 0 : (indexMapping[order] = action.value !== 0 ? i + 1 : i)
      );

      const updatedAnimationOrder = updatedFields.map((item) => {
        if (indexMapping.hasOwnProperty(item.styles?.animationOrder)) {
          return {
            ...item,
            ["styles"]: {
              ...item.styles,
              animationOrder: indexMapping[item.styles?.animationOrder],
              // animationDelay: 0,
            },
          };
        } else {
          return item;
        }
      });

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedAnimationOrder.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };

      return {
        ...state,
        template: newState,
      };
    } else if (groupedKeys.length > 0) {
      let reindexAnimationOrder = [];
      let updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (field.styles?.animateOnSlideDisplay === false) {
            if (groupedKeys.includes(id)) {
              if (!reindexAnimationOrder.includes(action.value)) {
                reindexAnimationOrder.push(action.value);
              }
              // code necessary
              return {
                ...field,
                ["styles"]: {
                  ...field.styles,
                  animationOrder: action.value,
                  // animationDelay: 0,
                },
              };
            } else if (
              field.styles?.animationType === "none" ||
              field.styles?.animationOrder == 0
            ) {
              return {
                ...field,
                ["styles"]: {
                  ...field.styles,
                  animationType: "none",
                  animationOrder: action.value,
                  // animationDelay: 0,
                },
              };
            } else {
              if (!reindexAnimationOrder.includes(field.styles?.animationOrder)) {
                reindexAnimationOrder.push(field.styles?.animationOrder);
              }
              return field;
            }
          } else {
            return field;
          }
        }
      );

      let indexMapping = {};
      const sortedArray = [...reindexAnimationOrder];
      sortedArray.sort((a, b) => a - b);
      sortedArray.map((order, i) => (indexMapping[order] = i + 1));

      const updatedAnimationOrder = updatedFields.map((item) => {
        if (indexMapping.hasOwnProperty(item.styles?.animationOrder)) {
          return {
            ...item,
            ["styles"]: {
              ...item.styles,
              animationOrder: indexMapping[item.styles?.animationOrder],
              // animationDelay: 0,
            },
          };
        } else {
          return {
            ...item,
            ["styles"]: {
              ...item.styles,
              animationOrder: 0,
              // animationDelay: 0,
            },
          };
        }
      });

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedAnimationOrder.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };

      return {
        ...state,
        template: newState,
      };
    }
  };
};

export function applyAnimationDelay() {
  return function (state, action) {
    const newState = [...state.template];
    const {slideID, fieldID} = action.active;
    const {groupedKeys} = action;
    const slideIndex = newState.findIndex((slide) => slide.id === slideID);
    const field = newState[slideIndex].fields[fieldID];
    if (
      groupedKeys.length === 0 &&
      action.active &&
      action.active.fieldID !== -1 &&
      field.styles?.animateOnSlideDisplay
    ) {
      newState[slideIndex].fields[fieldID] = {
        ...field,
        ["styles"]: {
          ...field.styles,
          animationDelay: action.value,
          animationOrder: 0
        },
      };
    } else if (groupedKeys.length > 0) {
      let updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (groupedKeys.includes(id)) {
            return {
              ...field,
              ["styles"]: {
                ...field.styles,
                animationDelay: action.value,
                animationOrder: 0
              },
          };
          } else {
            return field;
          }
        }
      );

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    };

    return {
      ...state,
      template: newState,
    };
  };
}

export const previewAnimation = () => {
  return function (state, action) {
    return {
      ...state,
      previewAnimation: action.status,
    };
  };
};

export const applyAnimationOnSlideLoad = () => {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const {groupedKeys} = action;
    let newState = [...state.template];
    const slideIndex = newState.findIndex((slide) => slide.id === slideID);
    const field = newState[slideIndex].fields[fieldID];
    const animateOnSlideLoad = action.value;
    if (
      groupedKeys.length === 0 &&
      action.active &&
      action.active.fieldID !== -1
    ) {
      newState[slideIndex].fields[fieldID] = {
        ...field,
        ["styles"]: {
          ...field.styles,
          animateOnSlideDisplay: action.value,
          animationOrder: !animateOnSlideLoad ? 1 : 0,
          animationDelay: animateOnSlideLoad ? 0.5 : 0,
          animationType: field.styles?.animationType === 'none' ?
            "bottom-to-top" : field.styles?.animationType
        },
      };
    } else if (groupedKeys.length > 0) {
      let updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (groupedKeys.includes(id)) {
            return {
              ...field,
              ["styles"]: {
                ...field.styles,
                animateOnSlideDisplay: action.value,
                animationOrder: !animateOnSlideLoad ? 1 : 0,
                animationDelay: animateOnSlideLoad ? 0.5 : 0,
                animationType: field.styles?.animationType === 'none' ? 
                  "bottom-to-top" : field.styles?.animationType
              },
          };
          } else {
            return field;
          }
        }
      );

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
};

export const setCurrentAnimationOrder = () => {
  return function (state, action) {
    if (action.animateOnSlideDisplay) {
      return {
        ...state,
        currentSlideOnDisplayAnimationOrder: action.order,
      };
    } else if (action.animateOnSlideDisplay === false) {
      return {
        ...state,
        currentSlideOnClickAnimationOrder: action.order,
      };
    } else {
      return state
    }
  };
};

export function setAnimationPreviewOption() {
  return function (state, action) {
    return {
      ...state,
      previewAnimationOption: action.value,
    }
  };
};


export const applyAnimationOnExit = () => {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const {groupedKeys} = action;
    const newState = [...state.template];
    const slideIndex = newState.findIndex((slide) => slide.id === slideID);
    const field = newState[slideIndex].fields[fieldID];
    if (
      groupedKeys.length === 0 &&
      action.active &&
      action.active.fieldID !== -1
    ) {
      let currentSlideOnClickAnimationOrder =
        field.styles?.animationOrder > 0 ? field.styles.animationOrder : 1;
      if (field.animationType !== "none") {
        newState[slideIndex].fields[fieldID] = {
          ...field,
          ["styles"]: {
            ...field.styles,
            enableExitAnimation: action.value
          },
        };
        currentSlideOnClickAnimationOrder = 0;
      } 
        return field
    } else if (groupedKeys.length > 0) {
      let updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (groupedKeys.includes(id)) {
            if (field.animationType !== "none") {
              return {
                ...field,
                ["styles"]: {
                  ...field.styles,
                  enableExitAnimation: action.value
                },
              };
            } 
              return field
          } else {
            return field
          }
        }
      );

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    }
    
    return {
      ...state,
      template: newState,
    };
  };
};