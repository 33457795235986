export const SvgStar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M14.4,3.8A8.8,8.8,0,0,1,15.5,8,8.5,8.5,0,0,1,7,16.5a9.3,9.3,0,0,1-2.4-.3A8.3,8.3,0,0,0,12,20.5,8.5,8.5,0,0,0,14.4,3.8Z" />
  </svg>
);

const sunColor = "#fff307";
const sunStyles = {
  fill: "none",
  stroke: sunColor,
};
export const SvgSun = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="5.5" fill={sunColor}></circle>
    <line
      x1="12"
      y1="24"
      x2="12"
      y2="20"
      style={sunStyles}
      strokeWidth={1}
    ></line>
    <line x1="12" y1="4" x2="12" style={sunStyles} strokeWidth={1}></line>
    <line
      x1="0"
      y1="12"
      y2="12"
      x2="3"
      style={sunStyles}
      strokeWidth={1}
    ></line>
    <line
      x1="24"
      y1="12"
      x2="21"
      y2="12"
      style={sunStyles}
      strokeWidth={1}
    ></line>
    <line
      x1="3.8"
      y1="4.1"
      x2="2.3"
      y2="2.8"
      strokeWidth={0.75}
      style={sunStyles}
    ></line>
    <line
      x1="22.8"
      y1="21.1"
      x2="21.3"
      y2="19.8"
      strokeWidth={0.75}
      style={sunStyles}
    ></line>
    <line
      x1="4.3"
      y1="20.3"
      x2="2.9"
      y2="21.7"
      strokeWidth={0.75}
      style={sunStyles}
    ></line>
    <line
      x1="22"
      y1="2.2"
      x2="20.6"
      y2="3.8"
      strokeWidth={0.75}
      style={sunStyles}
    ></line>
  </svg>
);

export const SvgText = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M2,2H22a1.1,1.1,0,0,1,1,1.2h0a1.1,1.1,0,0,1-1,1.2H2A1.1,1.1,0,0,1,1,3.2H1A1.1,1.1,0,0,1,2,2Z" />
    <path d="M13.3,21.8V4.1c0-.5-.6-.9-1.3-.9h0c-.7,0-1.3.4-1.3.9V21.8c0,.5.6.9,1.3.9h0C12.7,22.7,13.3,22.3,13.3,21.8Z" />
  </svg>
);

export const SvgImage = (
  // <svg viewBox="0 0 24 24">
  //   <g>
  //     <path
  //       d="M23.4,15,15.3,7.1a1.5,1.5,0,0,0-2,0L9.1,11.5l6,5.9h7.4A1.3,1.3,0,0,0,23.4,15Z"
  //       stroke="#070707"
  //       strokeMiterlimit={10}
  //       strokeWidth={0.25}
  //     />
  //     <path
  //       d="M13.1,17.4h0l-5-4.9-.7-.6-.8-.8a1.3,1.3,0,0,0-1.9,0L.6,15.3a1.3,1.3,0,0,0,1,2.3H3.2l9.9-.2Z"
  //       stroke="#070707"
  //       strokeMiterlimit={10}
  //       strokeWidth={0.25}
  //     />
  //   </g>
  //   <g>
  //     <path d="M23.4,15,15.3,7.1a1.5,1.5,0,0,0-2,0L9.1,11.5l6,5.9h7.4A1.3,1.3,0,0,0,23.4,15Z" />
  //     <path d="M13.1,17.4h0l-5-4.9-.7-.6-.8-.8a1.3,1.3,0,0,0-1.9,0L.6,15.3a1.3,1.3,0,0,0,1,2.3H3.2l9.9-.2Z" />
  //   </g>
  // </svg>
  <svg viewBox="0 0 24 24">
    <path
      d="M23.4,15,15.3,7.1a1.5,1.5,0,0,0-2,0L9.1,11.5l6,5.9h7.4A1.3,1.3,0,0,0,23.4,15Z"
      // stroke="#070707"
      fill="none"
      strokeMiterlimit={10}
      strokeWidth={0.9}
    />
    <path
      d="M13.1,17.4h0l-5-4.9-.7-.6-.8-.8a1.3,1.3,0,0,0-1.9,0L.6,15.3a1.3,1.3,0,0,0,1,2.3H3.2l9.9-.2Z"
      // stroke="#070707"
      fill="none"
      strokeMiterlimit={10}
      strokeWidth={0.9}
    />
  </svg>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 50 30"
  //   style={{transform: "scale(1.6)"}}
  // >
  //   <path
  //     stroke="#050505"
  //     strokeMiterLimit={10}
  //     strokeWidth={0.75}
  //     d="M47.2,20.8,31.4,5.4a2.8,2.8,0,0,0-3.8.1l-8.3,8.6L31,25.6l14.4-.2A2.7,2.7,0,0,0,47.2,20.8Z"
  //   />
  //   <path
  //     stroke="#050505"
  //     strokeMiterLimit={10}
  //     strokeWidth={0.75}
  //     d="M27.2,25.6h0L17.4,16l-1.3-1.2-1.7-1.6a2.7,2.7,0,0,0-3.7,0L2.8,21.4a2.6,2.6,0,0,0,1.9,4.5H7.8l19.4-.3Z"
  //   />
  // </svg>
  // <svg viewBox="0 0 24 24" style={{transform: "scale(1.4)"}}>
  //   <path d="M14 6l-3.75 5 2.85 3.8-1.6 1.2C9.81 13.75 7 10 7 10l-6 8h22L14 6z"></path>
  // </svg>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 24 24"
  //   style={{transform: "scale(1.2)"}}
  // >
  //   <path d="M1.8,18.7h8.6a1.2,1.2,0,0,0,.9-1.9l-4.6-5a1.1,1.1,0,0,0-1.7.1L1,16.8A1.2,1.2,0,0,0,1.8,18.7Z" />
  //   <path d="M16.2,18.7h6a1.2,1.2,0,0,0,.8-1.9L12.9,5.7a1.1,1.1,0,0,0-1.7,0l-3,3.5a1.2,1.2,0,0,0,.1,1.5l7.1,7.6A1.2,1.2,0,0,0,16.2,18.7Z" />
  // </svg>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 24 24"
  //   style={{transform: "scale(1.2)"}}
  // >
  //   <path d="M1.4,19h21c.5,0,.8-.8.5-1.3L15.3,5.2c-.5-.7-1.2-.8-1.7-.2L11,7.8a1.7,1.7,0,0,0-.1,1.7l3.6,6H13.2a.6.6,0,0,1-.6-.4L9.7,10.2A1.3,1.3,0,0,0,7.5,10L1.1,18C.9,18.3,1,19,1.4,19Z" />
  // </svg>
);

export const SvgChart = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect x="2.4" y="7" width="4" height="16" />
    <rect x="9.4" y="1" width="4" height="22" />
    <rect x="16.4" y="12.6" width="4" height="10.35" />
  </svg>
);

export const SvgShape = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      style={{
        fill: "none",
        strokeWidth: 1.2,
      }}
      d="M16.6,2.6H7.4a1.4,1.4,0,0,0-1.2.7l-4.7,8a1.7,1.7,0,0,0,0,1.4l4.7,8a1.4,1.4,0,0,0,1.2.7h9.2a1.4,1.4,0,0,0,1.2-.7l4.7-8a1.7,1.7,0,0,0,0-1.4l-4.7-8A1.4,1.4,0,0,0,16.6,2.6Z"
    />
  </svg>
);

export const SvgIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle
      style={{
        fill: "none",
        strokeMiterlimit: 10,
        strokeWidth: 1.2,
      }}
      cx="12"
      cy="12"
      r="11"
    />
    <circle cx="8.5" cy="9.2" r="1.6" />
    <circle cx="15.5" cy="9.2" r="1.6" />
    <path d="M15.2,15c1.3,0,1.7.8,1.3,1.3A5.9,5.9,0,0,1,12,18a5.9,5.9,0,0,1-4.5-1.7A.8.8,0,0,1,8.2,15Z" />
  </svg>
);

export const SvgTable = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect
      style={{
        fill: "none",
        strokeWidth: 1.2,
      }}
      x="1.5"
      y="1.5"
      width="21"
      height="21"
    />
    <line
      style={{
        fill: "none",
        strokeWidth: 1.2,
      }}
      x1="1.5"
      y1="12"
      x2="22.5"
      y2="12"
    />
    <line
      style={{
        fill: "none",
        strokeWidth: 1.2,
      }}
      x1="12"
      y1="1.5"
      x2="12"
      y2="22.5"
    />
  </svg>
);

export const SvgCustomise = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M11.6,2.8a13.5,13.5,0,0,1-7.2,8.8c-.3.1-.3.6,0,.8,4.6,2,6.5,6.3,7.2,8.8a.4.4,0,0,0,.8,0c.7-2.5,2.6-6.8,7.2-8.8.3-.2.3-.7,0-.8a13.5,13.5,0,0,1-7.2-8.8A.4.4,0,0,0,11.6,2.8Z" />
    <path d="M17.7,15.9a4.7,4.7,0,0,1-2.4,3c-.1,0-.1.2,0,.2a4.7,4.7,0,0,1,2.4,3c.1.2.3.2.3,0a4.7,4.7,0,0,1,2.4-3c.2,0,.2-.2,0-.2a4.7,4.7,0,0,1-2.4-3C18,15.7,17.8,15.7,17.7,15.9Z" />
  </svg>
);

export const SvgShare = (
  // <svg viewBox="0 0 24 24">
  //   <path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"></path>
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.9,10.8,14.7,4.6a.5.5,0,0,0-.8.4V7.5C4.4,7.5,2,19.5,2,19.5c6.1-7.1,10.5-6.5,11.6-6.1s.3.2.3.4v2.4a.5.5,0,0,0,.8.4l7.1-5.1C22.1,11.3,22.1,10.9,21.9,10.8Z" />
  </svg>
);

export const SvgVideo = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect
      width="16"
      height="14"
      x="1.5"
      y="6"
      r="12"
      rx="2"
      ry="2"
      fill="none"
      strokeWidth="1.2"
    />
    <path d="M16.8,13.8l4-3.4a1.2,1.2,0,0,1,1.8.9v5.4a1.1,1.1,0,0,1-1.8.8l-4-3.3A.3.3,0,0,1,16.8,13.8Z" />
  </svg>
);
