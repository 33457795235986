export const ADMIN_SWITCH_WORKSPACE = "ADMIN_SWITCH_WORKSPACE";

// export const ADMIN_CHANGE_FOLDER_TYPE = "ADMIN_CHANGE_FOLDER_TYPE";

export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATES_ERROR = "FETCH_TEMPLATES_ERROR";
export const FETCH_TEMPLATES_INIT = "FETCH_TEMPLATES_INIT";
export const SWITCH_TEMPLATE_CATEGORY = "SWITCH_TEMPLATE_CATEGORY";
export const FETCH_TEMP_CATEGORIES_INIT = "FETCH_TEMP_CATEGORIES_INIT";
export const FETCH_TEMP_CATEGORIES_SUCCESS = "FETCH_TEMP_CATEGORIES_SUCCESS";
export const FETCH_TEMP_CATEGORIES_ERROR = "FETCH_TEMP_CATEGORIES_ERROR";

export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";
export const REMOVE_IMAGE_ITEM = "REMOVE_IMAGE_ITEM";


// Media
export const FETCH_MEDIA_INIT = "FETCH_MEDIA_INIT";
export const FETCH_MEDIA_SUCCESS = "FETCH_MEDIA_SUCCESS";
export const FETCH_MEDIA_ERROR = "FETCH_MEDIA_ERROR";

export const UPLOAD_MEDIA_INIT = "UPLOAD_MEDIA_INIT";
export const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const UPLOAD_MEDIA_ERROR = "UPLOAD_MEDIA_ERROR";

export const TOGGLE_MEDIA_MODAL = "TOGGLE_MEDIA_MODAL";
