import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {black1, darkGrey1, grey5} from "../../../../../oat-color-variables";
import {Button, ButtonSpinner} from "../../../../../oat-ui";
import Select from "../../../../../oat-ui/core/Select";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import {
  CollaboratorAccessLevel,
  Error,
  Header,
  Section,
} from "../workspaceStyles";

const rolesForOwner = [
  {display: "Owner", value: "owner"},
  {display: "Admin", value: "admin"},
  {display: "Collaborator", value: "collaborator"},
];

const rolesForAdmin = [
  {display: "Admin", value: "admin"},
  {display: "Collaborator", value: "collaborator"},
];

const AddWorkspaceMember = ({user, workspace, close}) => {
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [memberType, setMemberType] = useState("collaborator");
  const [loading, setLoading] = useState(false);
  const [showInviteSuccessMsg, setShowInviteSuccessMsg] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  const handleUsername = (e) => {
    const {value} = e.target;
    setInviteeEmail(value);
    if (showInviteSuccessMsg) setShowInviteSuccessMsg(false);
  };

  const onSelectRole = (item) => {
    setMemberType(item.value);
  };

  const onSubmit = async () => {
    if (!inviteeEmail.includes("@") || inviteeEmail.split("@")[1] === "") {
      setError({
        status: true,
        message: "Please enter a valid email address.",
      });
    } else {
      if (error.status)
        setError({
          status: false,
          message: null,
        });
      if (inviteeEmail === "") {
        setLoading(false);
        setError({
          status: true,
          message: "Invited username cannot be blank.",
        });
      } else if (workspace.role === "collaborator") {
        setLoading(false);
        setError({
          status: true,
          message: "Collaborator cannot invite members.",
        });
      } else if (
        !loading &&
        (workspace.role === "admin" || workspace.role === "owner")
      ) {
        setLoading(true);
        setShowInviteSuccessMsg(false);
        await fetch(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspace.ID}/invites`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              workspaceName: workspace.name,
              inviteeEmail: inviteeEmail.trim(),
              memberType,
              invitedBy: {
                username: user.username,
                name: user.name,
              },
              accessLevel: "", // for later
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              setLoading(false);
              setShowInviteSuccessMsg(true);
              setInviteeEmail("");
              setMemberType("collaborator");
            } else {
              setLoading(false);
              setError({
                status: true,
                message: data.response.message,
              });
            }
          })
          .catch((e) => {
            setLoading(false);
            setError({
              status: true,
              message: "Something went wrong.",
            });
          });
      } else {
        setError({
          status: true,
          message: "You do not have enough permission.",
        });
      }
    }
  };

  return (
    <Section>
      <Header>Add Member</Header>

      <WrapField
        style={{
          pointerEvents: workspace.role === "collaborator" ? "none" : undefined,
          opacity:
            workspace.role === "owner" || workspace.role === "admin" ? 1 : 0.5,
        }}
      >
        <WrapInput>
          <TextField
            value={inviteeEmail}
            placeholder="Email"
            onChange={handleUsername}
            autoFocus={window.innerWidth >= 850 ? true : false}
          />
        </WrapInput>

        <WrapInput
          style={{marginLeft: window.innerWidth > 600 ? 8 : undefined}}
        >
          <Select
            onSelect={onSelectRole}
            selected={memberType}
            options={workspace.role === "owner" ? rolesForOwner : rolesForAdmin}
            height={110}
            overflowY={true}
          />
        </WrapInput>

        <WrapInviteButton>
          <Button onClick={onSubmit} disabled={loading}>
            {!loading ? "Invite" : <ButtonSpinner />}
          </Button>
        </WrapInviteButton>
      </WrapField>

      {showInviteSuccessMsg ? (
        <Message
          style={{
            width: "50%",
            color: "#1b5a2a",
            background: "rgb(226 255 227)",
            padding: 6,
            boxSizing: "border-box",
            marginTop: 12,
            borderRadius: 5,
            boxShadow: '0px 0px 1px 1px #f5f5f5'
          }}
        >
          User has been invited.
        </Message>
      ) : undefined}

      {workspace.role === "collaborator" ? (
        <CollaboratorAccessLevel
          style={{
            textAlign: "left",
            marginTop: 12,
            fontSize: "0.84rem",
            color: darkGrey1,
          }}
        >
          * Collaborator cannot invite members.
        </CollaboratorAccessLevel>
      ) : undefined}

      {/* {workspace.type === "personal" ? (
        <WrapField style={{padding: 0, margin: 0}}>
          <Message style={{fontStyle: "italic", color: red4}}>
            <span>This is a Personal workspace. </span>
            <span>
              Adding a member will automatically switch to a Team workspace.
            </span>
          </Message>
        </WrapField>
      ) : undefined} */}

      {error.status ? <Error>{error.message}</Error> : undefined}
    </Section>
  );
};

const WrapInput = styled.div`
  flex: 1.2;
  label {
    padding-left: 8px;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 16px;
  }
`;

const WrapField = styled.div`
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const TextField = styled.input`
  width: 100%;
  height: 100%;
  color: #232323;
  border-radius: 8px;
  background: #fff;
  border: 1px solid ${grey5};
  padding: 6px 12px;
  box-sizing: border-box;
`;

const Message = styled.div`
  font-size: 0.92rem;
  color: ${black1};
  padding-top: 8px;
  box-sizing: border-box;
  span {
    display: flex;
    margin-bottom: 8px;
  }
`;

const WrapInviteButton = styled.div`
  margin: 0 12px 0;
  width: 94px;
  height: 32px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: 0 0 22px 0;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(AddWorkspaceMember);
