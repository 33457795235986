import React, {useState, useEffect, useCallback} from "react";
import {connect} from "react-redux";
import {getWindowSize} from "../../../oat-window-size";
import {
  getTemplateLayout,
  selectTemplate,
  selectTemplateSize,
} from "../../../oat-design-template/store/selectors/template/template.selector";
import {toggleTemporarilyDisableSlideTransition} from "../../../oat-design-template/store/actions/template/slide.action";
import {
  selectActiveSlide,
  selectTemporarilyDisableTransition,
} from "../../../oat-design-template/store/selectors/template/slide.selector";
import ShowCanvas from "../../../oat-design-template/components/viewTemplate/ShowCanvas";
import {
  Layout,
  WrapCanvas,
  PanelArea,
  CarouselFrame,
  CanvasSection,
  WrapCloseSvg,
  MobileClosBtn,
} from "../../../oat-design-template/components/editDesign/canvasPanel/CanvasPanelStyles";
import {changeSlide} from "../../../oat-design-template/store/actions/template/slide.action";
import {Carousel, Spinner} from "../../../oat-ui";
// import PresentViewSlideArrows from "../../../oat-design-template/components/viewTemplate/presentViewSlideBar/PresentViewSlideArrows";
import styled from "styled-components";
import {calcCanvasWidth} from "../../utils/calcCanvasWidth";
import {paleBlue2} from "../../../oat-color-variables";
import ToggleFullScreen from "./ToggleFullScreen";
import {Close} from "../../../oat-svg-icons";
import {useFullscreen} from "../../../oat-hooks";
import {
  DesktopToggleSlides,
  MobileToggleSlides,
} from "../../../oat-design-template";
import {isMobile, isTablet, isDesktop} from "react-device-detect";

const transition = "no-transition";
// classic-transition /overlay-transition / no-transition
const WrapProfileTemp = ({
  activeSlide,
  slides,
  canvasSize,
  // transition,
  changeSlide,
  temporaryDisableTransition,
  toggleTemporarilyDisableSlideTransition,
}) => {
  const windowSize = getWindowSize();
  const [scale, setScale] = useState(0);
  const [displayPanel, setDisplayPanel] = useState({
    width: 0,
    height: 0,
  });
  const fullScreenRef = React.useRef(null);
  let isFullscreen, setIsFullscreen;

  // checking for landscape
  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? "landscape" : "portrait"
    ),
    updateOrientation = () => {
      clearTimeout(window.resizeLag);
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 200);
    };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  const [panelHeight, setPanelHeight] = useState(0);
  useEffect(() => {
    if (canvasSize.w) {
      let calcScale = null;
      const canvasWidth = calcCanvasWidth(windowSize.width);

      if (!document.fullscreenElement) {
        const canvasHeight = (canvasSize.h * canvasWidth) / canvasSize.w;
        setPanelHeight(canvasHeight);
        calcScale = Math.min(
          canvasHeight / canvasSize.h,
          canvasWidth / canvasSize.w
        );
      } else {
        // change width and height on fullscreen
        const canvasHeight = (canvasSize.h * windowSize.width) / canvasSize.w;
        setPanelHeight(canvasHeight);
        calcScale = Math.min(
          canvasHeight / canvasSize.h,
          windowSize.width / canvasSize.w
        );
      }
      if (calcScale !== Infinity) setScale(calcScale);
    }
  }, [windowSize, canvasSize, transition, document.fullscreenElement]);

  useEffect(() => {
    if (transition === "no-transition" || transition === "overlay-transition") {
      setDisplayPanel({
        width: canvasSize.w * scale,
        height: canvasSize.h * scale,
      });
    }
  }, [scale]);

  const detectShortcutKeys = useCallback(
    (event) => {
      if (event.keyCode === 37 && activeSlide !== 0) {
        changeSlide(activeSlide - 1);
      } else if (event.keyCode === 39 && activeSlide !== slides.length - 1) {
        changeSlide(activeSlide + 1);
      }
    },
    [activeSlide]
  );
  useEffect(() => {
    document.addEventListener("keydown", detectShortcutKeys, false);
    return () => {
      document.removeEventListener("keydown", detectShortcutKeys, false);
    };
  });

  useEffect(() => {
    if (temporaryDisableTransition) {
      setTimeout(() => {
        toggleTemporarilyDisableSlideTransition();
      }, 100);
    }
  }, [temporaryDisableTransition]);

  const handleExitFullscreen = () => {
    document.exitFullscreen();
  };

  try {
    [isFullscreen, setIsFullscreen] = useFullscreen(fullScreenRef);
  } catch (e) {
    // alert("Fullscreen is not supported on your device");
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  return slides && slides.length > 0 ? (
    <Layout
      ref={fullScreenRef}
      style={{
        width: windowSize.width < 850 ? "100%" : windowSize.width, // 100% without side contents,
        // justifyContent: "flex-start",
        // alignItems: "center", // flex-start
        paddingTop: 0,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // smoother in overlay transition
        // transition === "overlay-transition" ? windowSize.height : undefined,
      }}
    >
      <PanelArea
        cardType="card"
        style={{height: panelHeight, postion: "relative"}}
      >
        <div style={{width: canvasSize.w * scale}}>
          {transition === "classic-transition" ? (
            <Carousel
              activeIndex={activeSlide}
              duration={!temporaryDisableTransition ? 0.4 : 0}
              timing="ease-in-out"
              $carouselType={transition}
              styles={{height: "100%"}} // windowSize.height
            >
              {slides &&
                slides.map((slide, index) => {
                  if (transition === "classic-transition") {
                    return (
                      <CarouselFrame
                        aria-hidden={index === activeSlide ? false : true}
                        key={index}
                      >
                        <WrapCanvas>
                          <CanvasSection>
                            <ShowCanvas
                              slide={slide}
                              canvasIndex={index}
                              canvasSize={canvasSize}
                              scale={scale}
                            />
                          </CanvasSection>
                        </WrapCanvas>
                      </CarouselFrame>
                    );
                  }
                })}
            </Carousel>
          ) : (
            <Carousel
              activeIndex={activeSlide}
              duration={0}
              timing="ease-in-out"
              $carouselType={transition}
              styles={{
                width: displayPanel.width,
                height: displayPanel.height,
                overflow: "hidden",
                borderRadius: 0,
                // hide this block for classic transition
              }}
            >
              {slides &&
                slides.map((slide, index) => {
                  if (transition === "no-transition") {
                    return (
                      <CarouselFrame
                        aria-hidden={index === activeSlide ? false : true}
                        key={index}
                        style={{
                          // WebkitTransition: `all ${
                          //   clickedSideContent ? 0 : 0.2 // adjust transtion here
                          // }s ease-out`,
                          // transition: `all ${
                          //   clickedSideContent ? 0 : 0.2 // adjust transtion here
                          // }s ease-out`,
                          opacity: index === activeSlide ? 1 : 0,
                          position: "absolute",
                          top: 0,
                          zIndex: index === activeSlide ? 100 : 1,
                        }}
                      >
                        <WrapCanvas>
                          <CanvasSection>
                            <ShowCanvas
                              slide={slide}
                              canvasIndex={index}
                              canvasSize={canvasSize}
                              scale={scale}
                            />
                          </CanvasSection>
                        </WrapCanvas>
                      </CarouselFrame>
                    );
                  } else if (transition === "overlay-transition") {
                    return (
                      <CarouselFrame
                        aria-hidden={index === activeSlide ? false : true}
                        key={index}
                        style={{
                          transition: `all ${
                            !temporaryDisableTransition ? 0.5 : 0
                          }s ease-out`,
                          transform: `translate(${
                            index === activeSlide
                              ? activeSlide * -100
                              : index < activeSlide
                              ? index * -100
                              : (index - 1) * -99
                          }%, 0px)`,
                        }}
                      >
                        <WrapCanvas style={{justifyContent: "flex-start"}}>
                          <CanvasSection>
                            <ShowCanvas
                              slide={slide}
                              canvasIndex={index}
                              canvasSize={canvasSize}
                              scale={scale}
                            />
                          </CanvasSection>
                        </WrapCanvas>
                      </CarouselFrame>
                    );
                  }
                })}
            </Carousel>
          )}
        </div>
        {windowSize.width >= 850 ? <DesktopToggleSlides /> : undefined}

        {/* for orientation mode on mobile devices */}
        {orientation === "landscape" && (isMobile || isTablet) ? (
          <DesktopToggleSlides />
        ) : undefined}

        {windowSize.width >= 850 ? (
          <ToggleFullScreen
            rightOffset={(windowSize.width - canvasSize.w * scale) / 2 + 20}
            isFullscreen={isFullscreen}
            setIsFullscreen={setIsFullscreen}
            handleExitFullscreen={handleExitFullscreen}
          />
        ) : undefined}
      </PanelArea>

      {orientation === "portrait" && (windowSize.width < 850 || isMobile) ? (
        <MobileToggleSlides setIsFullscreen={setIsFullscreen} />
      ) : undefined}

      {document.fullscreenElement ? (
        <MobileClosBtn>
          <WrapCloseSvg onClick={handleExitFullscreen}>{Close}</WrapCloseSvg>
        </MobileClosBtn>
      ) : undefined}
    </Layout>
  ) : (
    <WrapSpinner>
      <Spinner />
    </WrapSpinner>
  );
};

const WrapSpinner = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 850px) {
    height: ${window.innerHeight - 350}px;
  }
  @media only screen and (max-width: 849px) {
    background: ${paleBlue2};
    height: ${(window.innerWidth * 1080) / 1920}px;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeSlide: selectActiveSlide(designTemplate),
    slides: selectTemplate(designTemplate),
    canvasSize: selectTemplateSize(designTemplate),
    layout: getTemplateLayout(designTemplate),
    temporaryDisableTransition:
      selectTemporarilyDisableTransition(designTemplate),
  };
};

export default connect(mapStateToProps, {
  changeSlide,
  toggleTemporarilyDisableSlideTransition,
})(WrapProfileTemp);
