import React from "react";
import {AdminAuth} from "../../../packages/oat-auth";

const Auth = () => {
  return (
    <AdminAuth onSubmit={(email, password) => console.log()} />
  );
};

export default Auth;
