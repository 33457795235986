import React, {useState, useMemo, useEffect, useCallback} from "react";
import SelectedShape from "../fields/shapes/SelectedShape";
import {Field, WrapTextField, imgFieldFontSize} from "../fields/DisplayFields";
import DisplayChart from "../fields/charts/DisplayChart";
import DisplayImage from "./ViewOnlyImage";
import DisplayIcon from "../fields/icons/DisplayIcon";
import ViewOnlyTable from "./ViewOnlyTable";
import {connect} from "react-redux";
import EmbedVideo from "../fields/video/EmbedVideo";
import {selectIsViewOnDesignPage, selectTempScale} from "../../../../store/selectors/template/template.selector";
import {selectCurrentSlideOnClickAnimationOrder, selectSlideAnimationOrder} from "../../../../store/selectors/template/slide.selector";
import {selectTemplateSize} from "../../../../store/selectors/template/template.selector";
import TextField from "../fields/text/TextField";
import {useNavigate, useLocation} from 'react-router-dom';
import { changeSlideByID } from "../../../../store/actions/template/slide.action";
import { motion } from "framer-motion";

const transitionTiming = 'cubic-bezier(0.445, 0.05, 0.55, 0.95)'
//'cubic-bezier(.29,.07,.64,.9)' // 'cubic-bezier(0,.49,1,.97)';
const ViewOnlyFields = ({
  field,
  editingDesign,
  tempScale,
  activeSlide,
  enableAnimation,
  animateByClickOrder,
  canvasSize,
  activeSlideIndex,
  prevSlideIndex,
  animationOrder,
  viewOnDesignPage,
  tempSize,
  changeSlideByID
}) => {
  const {size, pos, deleted, styles} = field || {}; 
  const {
    highestOrder: animateByClickHighestOrder, 
    highestOrderIsNotTypeAnimateOnClick,
    lastOnClickAnimationOrderHasEnabledExit: hasExitAnimation,
    prevSlideHasExitAnimation,
    currentSlideHasExitAnimation
  } = animationOrder || {};
  const navigate = useNavigate();

  const onClickFieldWidthLink = useCallback(() => {
    if (field?.link?.linkType === 'slide-number') {
      changeSlideByID(field?.link?.value);
      navigate(`?slide=${field?.link?.value}`);
    } else if (field?.link?.linkType === 'url') {
      const newTab = window.open(
        `${field?.link?.value.includes('http') ? 
          field?.link?.value : `https://${field?.link?.value}`}`,
          '_blank');
      if (newTab) {
        newTab.focus();
      }
    }
  }, [field?.link?.value, field?.link?.linkType]);

  const displayFields = (field) => {
    const {type, size} = field || {};
    const hiddenMask = 
    // `linear-gradient(to bottom, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 0%)`;
    `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
    const visibleMask = 
    // `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0%)`;
    `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;
    
    switch (type) {
      case "text":
        return (
          <WrapTextField
            onClick={onClickFieldWidthLink}
            style={{
              // width, height here important (overflow click)
              ...fieldSize,
              borderRadius: field?.styles?.borderRadius,
              background: field?.styles?.background,
              boxShadow:
                styles?.bgType === "button"
                  ? `${3 / tempScale}px ${3 / tempScale}px 0 0 ${
                      field?.styles?.shadowColor
                    }`
                  : undefined,
            }}
          >
            {/* <motion.div
              initial={false}
              animate={
                activeSlide
                  ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                  : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
              }
              transition={{ duration: 0, delay: 0 }}
              viewport={{ once: false }}
              exit={{ opacity: 0 }}
              style={{ 
                height: '99.8%',
                width: '100%',
                WebkitMaskImage: activeSlide ? visibleMask : hiddenMask,
                maskImage: activeSlide ? visibleMask : hiddenMask,
              }}
            > */}
            <TextField
              onSelectItem={null}
              field={field}
              ref={null}
              handleDragStart={null}
              handleTouchStart={null}
              selected={null}
              onEditText={null}
              disableLinkStyles={false}
            />
            {/* </motion.div> */}
          </WrapTextField>
        );
      case "image":     
        return (
          <Field onClick={onClickFieldWidthLink} style={{ 
            fontSize: Math.max(1, Math.min(size.w, size.h) / imgFieldFontSize)
            // overflow: field.fileType === 'png' ? 'hidden' : undefined 
          }}>
            {/* <motion.div
              initial={false}
              animate={
                activeSlide
                  ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                  : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
              }
              transition={{ duration: 1, delay: 0 }}
              viewport={{ once: false }}
              exit={{ opacity: 0 }}
              style={{ 
                height: '99.8%',
                width: '100%',
                fontSize: imgFieldFontSize, 
                overflow: field.fileType === 'png' ? 'hidden' : undefined,
                WebkitMaskImage: activeSlide ? visibleMask : hiddenMask,
                maskImage: activeSlide ? visibleMask : hiddenMask,
              }}
            > */}
              <DisplayImage field={field} />
            {/* </motion.div> */}
          </Field>
        );
      case "shape":
        return (
          <Field>
            <SelectedShape
              shape={field?.name}
              styles={{...styles}}
              size={size}
              tempScale={tempScale}
              index={field?.key + "shape"}
              isLandScape={tempSize?.w > tempSize?.h}
            />
          </Field>
        );
      case "icon":
        return (
          <Field onClick={onClickFieldWidthLink}>
            <DisplayIcon field={field} />
          </Field>
        );
      case "chart":
        return (
          <Field>
            <DisplayChart field={field} />
          </Field>
        );
      case "table":
        return (
          <Field>
            <ViewOnlyTable field={field} />
          </Field>
        );
      case "video":
        return (
          <Field>
            <EmbedVideo
              field={field}
              activeSlide={activeSlide}
              pointerEvents={editingDesign ? false : true}
            />
          </Field>
        );
      default:
        return "";
    }
  };

  const [animateOnSlideLoadOrder, setAnimateOnSlideLoadOrder] = useState(0);
  useEffect(() => {
    if (
      activeSlide && styles?.animateOnSlideDisplay && 
      styles?.animationType !== "none" &&
      animateOnSlideLoadOrder === 0
    ){
      // if (activeSlideIndex > prevSlideIndex) {
      //   setAnimateOnSlideLoadOrder(animateOnSlideLoadOrder + 0.5);
      // } else {
      setTimeout(() => {
        setAnimateOnSlideLoadOrder(animateOnSlideLoadOrder + 0.5);
      }, Number(styles?.animationDelay * 500)) // ; // delay duration between each order
    // }
    } 
  }, [
    activeSlide, 
    styles?.animateOnSlideDisplay,
    styles?.animationDelay,
    styles?.animationType,
    animateOnSlideLoadOrder,
    // activeSlideIndex, 
    // prevSlideIndex
  ]);

  // useEffect(() => {
  //   if (activeSlideIndex < prevSlideIndex) {
  //     setAnimateOnSlideLoadOrder(0);
  //   }
  // },[activeSlideIndex, prevSlideIndex])

  const animatedValue = useMemo(() => {
    let animationEffects;
    const horizontalDistance = (canvasSize.w * 12) / 100;
    const verticalDistance = (canvasSize.h * 12) / 100;
    if (activeSlide && enableAnimation && !styles?.animateOnSlideDisplay) {
      if (styles?.animationType === "bottom-to-top") {
        animationEffects = {
          transform: `translate3d(0px, ${
            styles.animationOrder > animateByClickOrder
              ? `${verticalDistance}`
              : 0
          }px, 0)`,
        };
      } else if (styles?.animationType === "top-to-bottom") {
        animationEffects = {
          transform: `translate3d(0px, ${
            styles.animationOrder > animateByClickOrder
              ? `-${verticalDistance}`
              : 0
          }px, 0)`,
        };
      } else if (styles?.animationType === "left-to-right") {
        animationEffects = {
          transform: `translate3d(${
            styles?.animationOrder > animateByClickOrder
              ? -horizontalDistance
              : 0
          }px, 0px, 0)`,
        };
      } else if (styles?.animationType === "right-to-left") {
        animationEffects = {
          transform: `translate3d(${
            styles?.animationOrder > animateByClickOrder
              ? horizontalDistance
              : 0
          }px, 0px, 0)`,
        };
      } else if (styles?.animationType === "zoom-in") {
        animationEffects = {
          transform: `translate(0px, 0px) scale(${
            styles?.animationOrder > animateByClickOrder ? 0.7 : 1
          })`,
        };
      } else if (styles?.animationType === "zoom-out") {
        animationEffects = {
          transform: `translate(0px, 0px) scale(${
            styles?.animationOrder > animateByClickOrder ? 1.3 : 1
          })`,
        };
      } else if (styles?.animationType === "no-animate") {
        animationEffects = {
          transform: `translate(0px, 0px) scale(1)`,
        };
      }
    }
    // activeSlide
    if (enableAnimation && styles?.animateOnSlideDisplay) {
      if (styles?.animationType === "bottom-to-top") {
        animationEffects = {
          transform: `translate3d(0px, ${
            0.5 > animateOnSlideLoadOrder // 0.5 necessary -> animateOnSlideLoadOrder
              ? `${verticalDistance}`
              : 0
          }px, 0)`,   
        };
      } else if (styles?.animationType === "top-to-bottom") {
        animationEffects = {
          transform: `translate3d(0px, ${
            0.5 > animateOnSlideLoadOrder
              ? `-${verticalDistance}`
              : 0
          }px, 0)`,
        };
      } else if (styles?.animationType === "left-to-right") {
        animationEffects = {
          transform: `translate3d(${
            0.5 > animateOnSlideLoadOrder
              ? -horizontalDistance
              : 0
          }px, 0px, 0)`,
        };
      } else if (styles?.animationType === "right-to-left") {
        animationEffects = {
          transform: `translate3d(${
            0.5 > animateOnSlideLoadOrder
              ? horizontalDistance
              : 0
          }px, 0px, 0)`,
        };
      } else if (styles?.animationType === "zoom-in") {
        animationEffects = {
          transform: `translate(0px, 0px) scale(${
            0.5 > animateOnSlideLoadOrder ? 0.7 : 1
          })`,
        };
      } else if (styles?.animationType === "zoom-out") {
        animationEffects = {
          transform: `translate(0px, 0px) scale(${
            styles?.animationDelay > animateOnSlideLoadOrder ? 1.3 : 1
          })`,
        };
      } else if (styles?.animationType === "no-animate") {
        animationEffects = {
          transform: `translate(0px, 0px) scale(1)`,
        };
      }
    }
    return animationEffects;
  }, [
    canvasSize,
    activeSlide,
    tempScale,
    styles?.animateOnSlideDisplay,
    styles?.animationDuration,
    styles?.animationType,
    styles?.animationOrder,
    styles?.animationDelay,
    styles?.animationSpeed,
    animateByClickOrder,
    enableAnimation,
    animateOnSlideLoadOrder, 
  ]);

  const animateFields = useMemo(() => {
    let animationEffects = undefined;
    if (styles?.animationType !== "none" && (styles?.animationDelay !== 0 || styles?.animationOrder !== 0)) {
      if (enableAnimation && 
        styles?.animationOrder < animateByClickOrder &&
        styles?.animationOrder >= 0 &&
        styles?.enableExitAnimation &&
        styles?.animationType !== "no-animate" 
      ) {
        const horizontalDistance = (canvasSize.w * 12) / 100;
        const verticalDistance = (canvasSize.h * 12) / 100;
        if (styles?.animationType === "bottom-to-top") {
          animationEffects = {
            transform: `translate3d(0px, ${`${verticalDistance}`}px, 0)`,
          };
        } else if (styles?.animationType === "top-to-bottom") {
          animationEffects = {
            transform: `translate3d(0px, ${`-${verticalDistance}`}px, 0)`,
          };
        } else if (styles?.animationType === "left-to-right") {
          animationEffects = {
            transform: `translate3d(${-horizontalDistance}px, 0px, 0)`,
          };
        } else if (styles?.animationType === "right-to-left") {
          animationEffects = {
            transform: `translate3d(${horizontalDistance}px, 0px, 0)`,
          };
        } else if (styles?.animationType === "zoom-in") {
          animationEffects = {
            transform: `translate(0px, 0px) scale(${0.7})`,
          };
        } else if (styles?.animationType === "zoom-out") {
          animationEffects = {
            transform: `translate(0px, 0px) scale(${1.3})`,
          };
        } else if (styles?.animationType === "no-animate") {
          animationEffects = {
            transform: `translate(0px, 0px) scale(1)`,
          };
        }
        animationEffects = {
          ...animationEffects,
          WebkitTransition: styles?.animationType !== "no-animate" ? `${styles?.animationSpeed * 1.5}s all ${transitionTiming}` : undefined,
          transition: styles?.animationType !== "no-animate" ? `${styles?.animationSpeed * 1.5}s all ${transitionTiming}` : undefined,
          opacity: 0,
          visibility: "hidden",
        };
      } else if (enableAnimation && !styles?.animateOnSlideDisplay && styles?.animationOrder >= 0) {
        animationEffects = {
          WebkitTransition: styles?.animationOrder >= animateByClickOrder && styles?.animationType !== "none"
          ? `${styles?.animationSpeed}s all ${transitionTiming}`
          : undefined,
          transition:
          //animateByClickOrder >= 0
          // `${styles?.animationSpeed}s all ${transitionTiming}`,
          styles?.animationOrder >= animateByClickOrder && styles?.animationType !== "none"
            ? `${styles?.animationSpeed}s all ${transitionTiming}`
            : undefined,
          opacity:
            styles?.animationOrder > animateByClickOrder &&
            styles?.animationType !== "none"
              ? 0
              : styles.opacity,
          visibility:
            styles?.animationOrder > animateByClickOrder &&
            styles?.animationType !== "none"
              ? "hidden"
              : "visible",
        };
      } else if (enableAnimation && styles?.animateOnSlideDisplay && currentSlideHasExitAnimation) {// && animateByClickOrder === 0             
        animationEffects = {
          WebkitTransition: styles?.animationType !== "no-animate" ? `all ${styles?.animationSpeed * 1.5}s ${transitionTiming}` : undefined,
          transition: styles?.animationType !== "no-animate" ? `all ${styles?.animationSpeed * 1.5}s ${transitionTiming}` : undefined,
          opacity:
            0.5 > animateOnSlideLoadOrder &&
            styles?.animationType !== "none"
              ? 0
              : styles.opacity, // problem here
          visibility: 
            0.5 > animateOnSlideLoadOrder &&
            styles?.animationType !== "none"
              ? "hidden"
              : "visible"
        };
      } 
      else if (enableAnimation && styles?.animateOnSlideDisplay && animateByClickOrder === 0) {      
        animationEffects = {
          WebkitTransition: styles?.animationType !== "no-animate" ? `all ${styles?.animationSpeed * 1.5}s ${transitionTiming}` : undefined,
          transition: styles?.animationType !== "no-animate" ? `all ${styles?.animationSpeed * 1.5}s ${transitionTiming}` : undefined,
          opacity:
            0.5 > animateOnSlideLoadOrder &&
            styles?.animationType !== "none"
              ? 0
              : styles.opacity, // problem here
          visibility: 
            0.5 > animateOnSlideLoadOrder &&
            styles?.animationType !== "none"
              ? "hidden"
              : "visible"
        };
      } 
      
      else  if (enableAnimation && styles?.animateOnSlideDisplay && styles?.enableExitAnimation && animateByClickOrder > 0 && activeSlideIndex >= prevSlideIndex) { 
        // animationEffects = {
        //   transition: activeSlideIndex >= prevSlideIndex ? `all ${styles?.animationSpeed}s ${transitionTiming}` : undefined,
        //   opacity: 0,
        //   visibility: "hidden",
        //   // transform: `translate3d(${-200}px, 0px, 0)`
        // };
        animationEffects = {};
        const horizontalDistance = (canvasSize.w * 12) / 100;
        const verticalDistance = (canvasSize.h * 12) / 100;
        if (styles?.animationType === "bottom-to-top") {
          animationEffects = {
            transform: `translate3d(0px, ${`${verticalDistance}`}px, 0)`,
          };
        } else if (styles?.animationType === "top-to-bottom") {
          animationEffects = {
            transform: `translate3d(0px, ${`-${verticalDistance}`}px, 0)`,
          };
        } else if (styles?.animationType === "left-to-right") {
          animationEffects = {
            transform: `translate3d(${-horizontalDistance}px, 0px, 0)`,
          };
        } else if (styles?.animationType === "right-to-left") {
          animationEffects = {
            transform: `translate3d(${horizontalDistance}px, 0px, 0)`,
          };
        } else if (styles?.animationType === "zoom-in") {
          animationEffects = {
            transform: `translate(0px, 0px) scale(${0.7})`,
          };
        } else if (styles?.animationType === "zoom-out") {
          animationEffects = {
            transform: `translate(0px, 0px) scale(${1.3})`,
          };
        } else if (styles?.animationType === "no-animate") {
          animationEffects = {
            transform: `translate(0px, 0px) scale(1)`,
          };
        }
        animationEffects = {
          ...animationEffects,
          WebkitTransition: undefined,
          transition: undefined, //`${styles?.animationSpeed * 1.5}s all ${transitionTiming}`,
          opacity: 0,
          visibility: "hidden"
        };
      } 
    } 
    return animationEffects;
  }, [
    activeSlide,
    styles?.animationDuration,
    styles?.animationType,
    styles?.animationOrder,
    styles?.animationSpeed,
    animateByClickOrder,
    enableAnimation,
    animateOnSlideLoadOrder, //
    styles?.animateOnSlideDisplay, //
    styles?.animationDelay,
    styles?.enableExitAnimation,
    activeSlideIndex,
    prevSlideIndex,
    currentSlideHasExitAnimation
  ]);

  const [fadeIn, setFadeIn] = useState({ opacity: 0 });
  useEffect(() => {
    // Trigger the fade-in effect after a delay (adjust the delay as needed)
    if (prevSlideHasExitAnimation && !viewOnDesignPage && activeSlide && styles?.animationType === 'none' && activeSlideIndex >= prevSlideIndex){
      // animateOnSlideLoadOrder && prevSlideHasExitAnimation && !viewOnDesignPage && activeSlide && styles?.animationType === 'none' && activeSlideIndex >= prevSlideIndex
      const timeoutId = setTimeout(() => {
        setFadeIn({ 
          opacity: styles?.opacity, 
          transition: activeSlideIndex >= prevSlideIndex ? `all 0.5s ${transitionTiming}` : undefined,
          WebkitTransition: activeSlideIndex >= prevSlideIndex ? `all 0.5s ${transitionTiming}` : undefined,
        });
      }, 300);
      return () => clearTimeout(timeoutId); // Cleanup on unmount
    } 
    else if (!viewOnDesignPage && activeSlideIndex < prevSlideIndex) {
      setFadeIn({ 
        opacity: 0, 
        WebkitTransition: undefined,
        transition: undefined
      });
    } 
  }, [
    activeSlide, 
    styles?.animationType,
    styles?.opacity,
    activeSlideIndex,
    prevSlideIndex,
    viewOnDesignPage,
    prevSlideHasExitAnimation,
  ]);

  const fieldOffset = useMemo(() => {
    return {
      display: deleted === true ? "none" : "block",
      transform: `translate(${pos?.x}px, ${pos?.y}px) rotate(${styles?.rotate}deg)`,
    };
  }, [deleted, pos?.x, pos?.y, styles?.rotate]);

  const fieldSize = useMemo(() => {
    if (size?.w)
      return {
        width: Math.ceil(size?.w),
        height: size?.h,
      };
  }, [size?.w, size?.h]);

  useEffect(() => {
    if (!activeSlide && animateOnSlideLoadOrder !== 0 && activeSlideIndex < prevSlideIndex){
      setAnimateOnSlideLoadOrder(0);
    } 
  },[activeSlide, activeSlideIndex, prevSlideIndex, animateOnSlideLoadOrder])

  return (
    <div
      style={{
        position: "absolute",
        ...fieldOffset,
        ...fieldSize,
        zIndex: styles?.zIndex,
      }}
    >
      <div
        style={{
          ...fieldSize,
          transformOrigin: "50% 50%",
          opacity: !enableAnimation || activeSlide ? styles?.opacity : 0, // activeSlide ?  : 0, // 
          ...animatedValue,
          ...(enableAnimation ? {...animateFields} : {}),
          ...(prevSlideHasExitAnimation && 
            styles?.animationType === 'none' && 
            activeSlideIndex > prevSlideIndex && 
            !viewOnDesignPage 
            ? {...fadeIn} : undefined
          ),
        }}
      >
        {displayFields(field)}
      </div>
    </div>
  );
};

ViewOnlyFields.defaultProps = {
  enableAnimation: false,
  editingDesign: false, // if user is on presenting page or editing template page
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempScale: selectTempScale(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    canvasSize: selectTemplateSize(designTemplate),
    animateByClickOrder: selectCurrentSlideOnClickAnimationOrder(designTemplate),
    animationOrder: selectSlideAnimationOrder(designTemplate),
    prevSlideIndex: state.designTemplate.prevSlideIndex,
    viewOnDesignPage: selectIsViewOnDesignPage(designTemplate)
  };
};

export default connect(mapStateToProps, {changeSlideByID})(ViewOnlyFields);