import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {Items, Item, Title} from "../../MobilePanelStyles";
import {
  cloneTableRowColumn,
  createTableColumn,
  createTableRow,
  removeTableColumn,
  removeTableRow,
  setActiveTableCell,
} from "../../../../../../../../store/actions/fields/table.action";
import {selectActiveSlide} from "../../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../../store/selectors/fields.selector";
import {createShortKey} from "../../../../../../../../store/reducers/functions/fields/utils";
import {red4} from "../../../../../../../../../oat-color-variables";

const EditTableRowsColumns = ({
  slideID,
  fieldID,
  removeTableRow,
  removeTableColumn,
  actionDrawerStatus,
  createTableRow,
  createTableColumn,
  setActiveTableCell,
  cloneTableRowColumn,
  onClose,
}) => {
  const [selectInsertOption, setSelectedInsertOption] = useState(false);
  const active = {slideID, fieldID};

  const onSelectInsertOption = () => {
    setSelectedInsertOption(true);
  };

  const handleClone = () => {
    cloneTableRowColumn({
      active,
      cloneType: actionDrawerStatus.type,
      index: actionDrawerStatus.index,
    });
    onClose();
  };

  const handleDelete = () => {
    if (actionDrawerStatus?.type === "row") {
      removeTableRow(active, actionDrawerStatus?.id);
    } else if (actionDrawerStatus?.type === "column") {
      removeTableColumn(active, actionDrawerStatus.index);
    }
    setActiveTableCell({row: null, column: null});
    onClose();
  };

  const onInsertAfter = () => {
    if (actionDrawerStatus?.type === "row") {
      const rowID = createShortKey();
      createTableRow(active, actionDrawerStatus.index, rowID);
    } else if (actionDrawerStatus?.type === "column") {
      const columnID = createShortKey();
      createTableColumn(active, actionDrawerStatus.index, columnID);
    }
    setActiveTableCell({row: null, column: null});
    onClose();
  };

  const onInsertBefore = () => {
    if (actionDrawerStatus?.type === "row") {
      const rowID = createShortKey();
      createTableRow(active, actionDrawerStatus.index - 1, rowID);
    } else if (actionDrawerStatus?.type === "column") {
      const columnID = createShortKey();
      createTableColumn(active, actionDrawerStatus.index - 1, columnID);
    }
    setActiveTableCell({row: null, column: null});
    onClose();
  };

  return !selectInsertOption ? (
    <Items>
      <Item onClick={onSelectInsertOption}>
        <Title style={{fontSize: "0.82rem"}}>
          Insert {actionDrawerStatus?.type === "row" ? `Row` : "Column"}
        </Title>
      </Item>

      <Item onClick={handleClone}>
        <Title style={{fontSize: "0.82rem"}}>
          Clone Selected {actionDrawerStatus?.type === "row" ? `Row` : "Column"}
        </Title>
      </Item>

      <Item onClick={handleDelete}>
        <Title style={{color: red4, fontSize: "0.82rem"}}>
          Delete Selected {actionDrawerStatus?.type === "row" ? `Row` : "Column"}
        </Title>
      </Item>
    </Items>
  ) : (
    <Actions>
      <div onClick={onInsertBefore}>Insert Before</div>
      <div onClick={onInsertAfter}>Insert After</div>
    </Actions>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 32px;
    border-radius: 4px;
    background: ${({theme}) => theme.deskSecondaryBg};
    margin-right: 12px;
    font-size: 0.9rem;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    actionDrawerStatus: state.designTemplate.toggleTableRowsColumnsDrawer,
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
  };
};

export default connect(mapStateToProps, {
  createTableColumn,
  createTableRow,
  removeTableRow,
  removeTableColumn,
  setActiveTableCell,
  cloneTableRowColumn,
})(EditTableRowsColumns);
