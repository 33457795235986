import React from "react";
import {Wrapper, EditPanel} from "../../EditPanelStyles";
import ShapePanel from "./fieldType/ShapePanel";
import TextPanel from "./fieldType/TextPanel";
import ImagePanel from "./fieldType/ImagePanel";
import TablePanel from "./fieldType/TablePanel";
import ChartPanel from "./fieldType/ChartPanel";
import IconPanel from "./fieldType/IconPanel";
import VideoPanel from "./fieldType/VideoPanel";

const SelectedFieldPanel = ({type}) => {
  let selectedPanel = null;
  switch (type) {
    case "text":
      selectedPanel = <TextPanel />;
      break;
    case "image":
      selectedPanel = <ImagePanel />;
      break;
    case "icon":
      selectedPanel = <IconPanel />;
      break;
    case "shape":
      selectedPanel = <ShapePanel />;
      break;
    case "chart":
      selectedPanel = <ChartPanel />;
      break;
    case "table":
      selectedPanel = <TablePanel />;
      break;
    case "video":
      selectedPanel = <VideoPanel />;
      break;
    default:
      selectedPanel = null;
  }

  return (
    <EditPanel>
      <Wrapper>{selectedPanel}</Wrapper>
    </EditPanel>
  );
};

export default SelectedFieldPanel;
