export function getSelectedShadow({
    shadowType, 
    shadowTypeOptions, 
    shadowColor, 
    shadowSize,
    shadowBlur, 
    tempScale
}) 
{
    let calcShadowSize = shadowSize > 16 ? 0.5 / tempScale : shadowSize // tempScale;
    let blurSize = shadowBlur > 10 ? 10 : shadowBlur;
    if (shadowType === shadowTypeOptions[1].shadowType) {
        return `${shadowColor} ${calcShadowSize}em ${calcShadowSize}em ${
            blurSize
        }em`;
    } else if (shadowType === shadowTypeOptions[2].shadowType) {
        return `${shadowColor} -${calcShadowSize}em -${calcShadowSize}em ${
            blurSize
        }em, ${shadowColor} ${calcShadowSize}em ${calcShadowSize}em ${
            blurSize
        }em`;
        // `0 ${1 / tempScale}px ${1 / tempScale}px ${shadowColor},
        // 0 ${2 / tempScale}px ${2 / tempScale}px ${shadowColor}, 
        // 0 ${4 / tempScale}px ${4 / tempScale}px ${shadowColor}, 
        // 0 ${8 / tempScale}px ${8 / tempScale}px ${shadowColor}, 
        // 0 ${16 / tempScale}px ${16 / tempScale}px ${shadowColor}`;
    } else if (shadowType === shadowTypeOptions[3].shadowType) {
        return  `${shadowColor} ${calcShadowSize}em ${calcShadowSize}em ${
            blurSize
            }em`;
    } else if (shadowType === shadowTypeOptions[4].shadowType) {
        return `${shadowColor} 0px 0px ${
            blurSize
        }em ${calcShadowSize}em`;
    }
}