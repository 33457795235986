import styled, {css} from "styled-components";
import {
  defaultMaxWidthLaptop,
  defaultMaxWidthDesktop,
  defaultMaxWidthLaptop2,
} from "../../layoutVariables";
import {greyText1, secondaryBlack} from "../../../../oat-color-variables";

export const homePageBackground1 = "#fbf7fd";
export const homePageBackground2 = "#f2f1ff";

export const Container = styled.div`
  max-width: ${defaultMaxWidthLaptop}px;
  @media only screen and (min-width: 1000px) and (max-width: 1179px) {
    max-width: 94%;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1299px) {
    max-width: ${defaultMaxWidthLaptop2}px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1500px) {
    max-width: ${defaultMaxWidthLaptop}px;
  }
  @media only screen and (min-width: 1501px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
`;

export const contentListStyles = css`
  padding-top: 15px;
  .content-list-item {
    display: flex;
    text-align: left;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    padding: 12px 0px 24px 6px;
    .feature {
      font-size: 1.1rem;
      color: #47464d;
      font-weight: 700;
      padding-bottom: 10px;
    }
  }
  .feature-description {
    font-size: 1.12rem;
    color: ${greyText1};
  }
  @media only screen and (max-width: 600px) {
    .content-list-item {
      padding: 12px 0px 15px 4px;
      .feature {
        font-size: 1.04rem;
        padding-bottom: 8px;
      }
      .feature-description {
        font-size: 1rem;
      }
    }
  }
`;
