import React, {useState} from "react";
import styled from "styled-components";

const TemplateHeader = () => {
  const [displayCover, setDisplayCover] = useState(false);

  const finishedOnLoad = () => {
    setDisplayCover(true);
  };

  return (
    <Layout>
      <Wrapper>
        <WrapImage>
          <Image
            src={
              "https://oat-all-media-storage.s3.amazonaws.com/for-home-page/template-here-bg-charcters-1200x.png"
              // "https://oat-default-init.s3.amazonaws.com/templates-page/choose-your-templates-500x.png"
            }
            alt=""
            loading="lazy"
            style={{
              background: displayCover ? 'linear-gradient(120deg, rgb(233 238 255), rgb(243 255 247))' : undefined,
              boxShadow: displayCover ? '0px 0px 0px 1px #f2efef' : undefined
            }}
            onLoad={finishedOnLoad}
          />
        </WrapImage>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2% 55px;
  @media only screen and (max-width: 600px) {
    padding: 0 3.5%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 1200px;
  margin: 24px 0px 10px;
  @media only screen and (max-width: 950px) {
    width: 100%;
    margin: 20px 0px 0px;
  }
  @media only screen and (max-width: 600px) {
    position: relative;
    width: 100%;
    margin: 20px 0px 0px;
    overflow: hidden;
  }
`;

const WrapImage = styled.div`
  flex: 1;
  box-sizing: border-box;
`;

const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 35px 0px 0px;
  @media only screen and (min-width: 900px) {
    padding: 45px 132px 0;
    box-sizing: border-box;
  }
`;

export default TemplateHeader;
