const getItemTopMiddle = (points, cos, sin) => {
  const {size, offset} = points;
  return {
    x: offset.x + (sin * size.height) / 2,
    y: offset.y - (cos * size.height) / 2,
  };
};

/** Middle point on item's bottom side. */
const getItemBtmMiddle = (points, cos, sin) => {
  const {size, offset} = points;
  return {
    x: offset.x - (sin * size.height) / 2,
    y: offset.y + (cos * size.height) / 2,
  };
};

export const getItemCorners = (points) => {
  const {size, angle} = points;
  const sin = Math.sin(angle);
  const cos = Math.cos(angle);
  const topMiddle = getItemTopMiddle(points, cos, sin);
  const btmMiddle = getItemBtmMiddle(points, cos, sin);
  //   const nw = {
  //     x: topMiddle.x - (cos * size.width) / 2,
  //     y: topMiddle.y - (sin * size.width) / 2,
  //   };
  //   const ne = {
  //     x: topMiddle.x + (cos * size.width) / 2,
  //     y: topMiddle.y + (sin * size.width) / 2,
  //   };
  //   const sw = {
  //     x: btmMiddle.x - (cos * size.width) / 2,
  //     y: btmMiddle.y - (sin * size.width) / 2,
  //   };
  //   const se = {
  //     x: btmMiddle.x + (cos * size.width) / 2,
  //     y: btmMiddle.y + (sin * size.width) / 2,
  //   };
  const yOffsets = [
    topMiddle.y - (sin * size.width) / 2,
    topMiddle.y + (sin * size.width) / 2,
    btmMiddle.y - (sin * size.width) / 2,
    btmMiddle.y + (sin * size.width) / 2,
  ];
  const xOffsets = [
    topMiddle.x - (cos * size.width) / 2,
    topMiddle.x + (cos * size.width) / 2,
    btmMiddle.x - (cos * size.width) / 2,
    btmMiddle.x + (cos * size.width) / 2,
  ];
  const minX = Math.min.apply(Math, xOffsets);
  const maxX = Math.max.apply(Math, xOffsets);
  const minY = Math.min.apply(Math, yOffsets);
  const maxY = Math.max.apply(Math, yOffsets);

  return {minX, maxX, minY, maxY};
};
