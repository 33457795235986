import React from 'react';
import { Field } from './TextField';
import { createPortal } from 'react-dom';
import { selectTempScale, selectTemplateSize } from '../../../../../store/selectors/template/template.selector';
import { connect } from 'react-redux';
import { getWindowSize } from '../../../../../../oat-window-size/getWindowSize';
import { selectCanvasPanelWidth } from '../../../../../store/selectors/layout/layout.selector';

const TypingTextField = React.forwardRef(({
    styles, 
    textStyles,
    onInputChange, 
    textInput,
    subtype,
    cancelEvent,
    disableLinkStyles,
    onPaste,
    applyWillTransform,
    tempScale,
    offset,
    canvasWidth,
    tempSize
}, fieldRef) => {
    const windowSize = getWindowSize();
    const adjustedXOffset =
        windowSize.width >= 850
        ? Math.round(canvasWidth - tempSize.w * tempScale) / 2
        : 0;

    return createPortal(
        <Field
            id={`text-field-editing-active`}
            ref={fieldRef}
            spellCheck="true"
            fontSize={styles?.fontSize}
            lineHeight={styles?.lineHeight}
            style={{
                ...textStyles, 
                ...applyWillTransform, 
                // width: textStyles.width * tempScale, 
                // height: textStyles.height * tempScale,
                transform: `translate(${(offset?.x * tempScale) + Math.ceil(adjustedXOffset)}px, ${offset.y * tempScale}px) scale(${styles?.scale * tempScale})`,
                // transformOrigin: `10px -50%`
            }}
            onMouseDown={cancelEvent}
            onPaste={onPaste}
            fontSource={styles?.fontStyle}
            contentEditable={true}
            dangerouslySetInnerHTML={{
              __html: textInput.current,
            }}
            suppressContentEditableWarning={true}
            onInput={onInputChange}
            subtype={subtype}
            background={styles?.bg1}
            $unorderedBullet={{
              bulletType: styles?.bulletType,
              bulletColor: styles?.bulletColor
            }}
            disableLinkStyles={disableLinkStyles}
        />,
        document.getElementById("text-field-on-edit")
    )
})

const mapStateToProps = ({designTemplate}) => {
    return {
        tempScale: selectTempScale(designTemplate),
        canvasWidth: selectCanvasPanelWidth(designTemplate),
        tempSize: selectTemplateSize(designTemplate),
    }
}

export default connect(mapStateToProps, null, null, {
    forwardRef: true,
  })(TypingTextField)