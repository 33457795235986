export const ToFront = (
  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227.5 228.4">
    <title>To Front</title>
      <g>
        <rect x="5" y="5" width="143.3" height="143.33" rx="13.8" ry="13.8" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10"/>
        <line x1="143.1" y1="80.1" x2="153.1" y2="80.1" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10"/>
        <path d="M170.9,80.1h37.8a13.8,13.8,0,0,1,13.8,13.8v2.7" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10" strokeDasharray="17.76 17.76 17.76 17.76"/>
        <path d="M222.5,105.5V209.6a13.8,13.8,0,0,1-13.8,13.8H93a13.8,13.8,0,0,1-13.8-13.8V161.9" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10" strokeDasharray="17.76 17.76 17.76 17.76 17.76 17.76"/>
        <line x1="79.2" y1="153" x2="79.2" y2="143" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10"/>
        <line x1="79.2" y1="148.3" x2="79.2" y2="145" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10" strokeDasharray="3.32"/>
      </g>
  </svg>
);
