import {css} from "styled-components";

export const proButtonStyles = css`
  background: linear-gradient(345deg, #6681ff, #dd94d4);
  padding: 1px 12px 1px;
  border-radius: 14px;
  color: #fff;
  font-size: 0.85rem;
  user-select: none;
`;
