import React, {useState, useEffect} from "react";
import styled from "styled-components";
import ValueLabel from "../../../editDesign/canvasPanel/fields/charts/barCharts/common/ValueLabel";
import ThemeGrid from "./ThemeGrid";

const Bar = ({
  fill = "#ffd13d",
  x,
  y,
  heightInPercent,
  heightInPx,
  width,
  value,
  fontSize,
  valueColor,
}) => {
  return (
    <g>
      <rect
        fill={fill}
        x={x}
        y={y}
        height={heightInPercent + "%"}
        width={width}
      />
      <g
        style={{
          width,
          transform: `translate(${x + width / 2}px, ${heightInPx / 2 + y}px)`,
        }}
      >
        <text
          style={{
            textAnchor: "middle",
            alignmentBaseline: "central",
            fontSize: fontSize,
            fill: valueColor,
          }}
        >
          {value}
        </text>
      </g>
    </g>
  );
};

const StackedBar = ({size, styles, content}) => {
  let labels = [];
  let longestLabelSize = 0;
  const {data} = content;
  const ratioDiff = 1;

  const width = size.w; //data.length * (barWidth + 16.5) + 16.5;
  // const width = data.length * (barWidth + barMargin) + 16.5;
  const edgeSpace = 16.5 * ratioDiff;
  const {
    barSize,
    showBackground,
    background,
    gridColor,
    labelColor,
    valueColor,
    fontSize,
    fontFamily,
    axisColor,
  } = styles;

  const [initChartWidth, setInitChartWidth] = useState(size.w);
  const [initBarWidth, setInitBarWidth] = useState(barSize); // * 3.14 ratio
  const [barWidth, setBarWidth] = useState(
    (size.w * initBarWidth) / initChartWidth
  );
  const barMargin =
    (size.w - (barWidth * data.length + 2 * edgeSpace)) / (data.length - 1);
  const height = size.h;
  const totalYears = data.map((datum) =>
    datum.items.reduce(function (acc, item) {
      return acc + item.value;
    }, 0)
  );
  const highest = Math.max(...totalYears); // biggest value

  let accumulator = null;
  const chartInfo = content.data.map((datum) => {
    accumulator = 0;
    return {
      ...datum,
      items: datum.items.map(function (item, i) {
        if (i === 0) {
          accumulator = (item.value * height) / highest; // base height
          return {
            ...item,
            yOffset: (height * (highest - item.value)) / highest,
          };
        } else {
          const getYOffsets =
            (height * (highest - item.value)) / highest - accumulator;
          accumulator = accumulator + (item.value * height) / highest;
          return {
            ...item,
            yOffset: getYOffsets,
          };
        }
      }),
    };
  });

  content.data.map((datum, index) => {
    labels.push({
      label: datum.category.label,
      labelSize: datum.category.labelSize,
    });
    if (datum.category.labelSize > longestLabelSize) {
      longestLabelSize = datum.category.labelSize;
    }
  });

  const lineOffsets = [
    {
      x1: 0,
      y1: height / 5,
      x2: width,
      y2: height / 5,
    },
    {
      x1: 0,
      y1: (height / 5) * 2,
      x2: width,
      y2: (height / 5) * 2,
    },
    {
      x1: 0,
      y1: (height / 5) * 3,
      x2: width,
      y2: (height / 5) * 3,
    },
    {
      x1: 0,
      y1: (height / 5) * 4,
      x2: width,
      y2: (height / 5) * 4,
    },
  ];

  const [lines, setLines] = useState(lineOffsets);
  const [slope, setSlope] = useState(false);
  useEffect(() => {
    setLines(lineOffsets);
    // think here (slope point!)
    setSlope(longestLabelSize < barWidth + 15 ? false : true);
  }, [size]);

  useEffect(() => {
    setInitBarWidth((size.w * barSize) / initChartWidth);
    setBarWidth((size.w * barSize) / initChartWidth);
  }, [barSize]);

  return (
    <Wrapper>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{background: showBackground ? background : undefined}}
      >
        <g>
          {chartInfo &&
            chartInfo.map((datum, index) =>
              datum.items.map((item, i) => {
                const heightInPercent = (item.value * 100) / highest;
                const barHeightInPx = (heightInPercent * height) / 100;
                return (
                  <Bar
                    key={i}
                    fill={item.color}
                    x={index * (barWidth + barMargin) + edgeSpace}
                    y={item.yOffset}
                    width={barWidth}
                    heightInPercent={heightInPercent}
                    heightInPx={barHeightInPx}
                    value={item.value}
                    fontSize={14}
                    valueColor={valueColor}
                  />
                );
              })
            )}
        </g>

        <g>
          {lines.map((line, index) => (
            <ThemeGrid
              stroke={gridColor}
              x1={line.x1}
              x2={line.x2}
              y1={line.y1}
              y2={line.y2}
              key={index}
            />
          ))}
        </g>

        <g>
          {data.map((item, index) => (
            //  For straight
            // <ValueLabel
            //   key={index}
            //   x={edgeSpace + (barWidth + barMargin) * index + barWidth / 2}
            //   y={height + 5}
            //   color={valueColor}
            //   width={barWidth}
            //   height={20}
            //   value={item.category.label}
            //   fontSize={fontSize}
            //   textAnchor="middle"
            //   alignmentBaseline="hanging"
            // />

            // For curve
            <ValueLabel
              key={index}
              x={edgeSpace + (barWidth + barMargin) * index + barWidth / 2}
              y={height + 5}
              color={labelColor}
              width={barWidth}
              height={20}
              value={item.category.label}
              fontSize={14}
              fontFamily={fontFamily}
              textAnchor="end"
              alignmentBaseline="before-edge"
              rotate={-45}
            />
          ))}
        </g>

        <g>
          <line
            x1={0}
            x2={width}
            y1={height}
            y2={height}
            stroke={axisColor} // axisColor
            strokeWidth={1}
          />
        </g>

        <g>
          <line
            x1={0}
            x2={0}
            y1={0}
            y2={height}
            stroke={axisColor}
            strokeWidth={1}
          ></line>
        </g>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  position: relative;
  svg {
    pointer-events: none;
    overflow: visible;
    rect {
      pointer-events: none;
    }
    transform-box: fill-box;
  }
`;

export default StackedBar;
