import React, {useState, useRef} from "react";
import styled from "styled-components";
import {Plus} from "../../../../../oat-svg-icons";
import {ButtonSpinner, detectClickOutside} from "../../../../../oat-ui";
import {Popper} from "./ProfileCollections";
import {
  TextField,
  Wrapper,
  WrapButton,
} from "./../../../sidebar/folderTypes/folderStyles";
import Button from "../../../../../oat-ui/core/Button";
import {addProfileTag} from "../../../../store/actions/userProfile.action";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";

const CreateProfileTag = ({addProfileTag, user}) => {
  const [openPopper, setOpenPopper] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [tag, setTag] = useState("");
  const popperRef = useRef();

  const togglePopper = () => {
    setOpenPopper(!openPopper);
    setTag("");
  };

  const onIgnoreEvent = (e) => {
    e.stopPropagation();
  };

  const onChangeInput = (e) => {
    const {value} = e.target;
    setTag(value);
  };

  const handleCreate = () => {
    setProcessing(true);
    const genID = Math.random().toString(36).substring(2, 9);
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        profileSlug: user.account.business.slug,
        payload: {id: genID, display: tag},
      }),
    };
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/userInfo/profile/tags`,
      requestOptions
    )
      .then((response) => response.json())
      .then(() => {
        addProfileTag({id: genID, display: tag});
        setProcessing(false);
        setOpenPopper(false);
      })
      .catch((e) => {});
  };

  detectClickOutside(popperRef, () => {
    togglePopper();
  });

  return (
    <WrapSvg onClick={togglePopper}>
      {Plus}
      {openPopper ? (
        <Popper style={{height: 100}} onClick={onIgnoreEvent} ref={popperRef}>
          <TextField
            value={tag}
            placeholder="Enter Tag"
            onChange={onChangeInput}
          />
          <Wrapper style={{justifyContent: "flex-end"}}>
            <WrapButton>
              <Button onClick={handleCreate} disabled={processing}>
                {!processing ? "Create" : <ButtonSpinner />}
              </Button>
            </WrapButton>
          </Wrapper>
        </Popper>
      ) : undefined}
    </WrapSvg>
  );
};

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #858383;
  position: relative;
  z-index: 99999;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {addProfileTag})(CreateProfileTag);
