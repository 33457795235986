import {css} from "styled-components";

export const Bouncy = css`
  animation: bouncy 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  @-webkit-keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

// export default Bouncy;
