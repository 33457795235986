import React from "react";
import {useParams} from "react-router-dom";
import DashboardPanel from "../../components/dashboardArea/DashboardPanel";
import TemplateDetails from "../../components/dashboardArea/templateDetails/TemplateDetails";
import AdminSidebar from "../../components/layout/AdminSidebar";
import Categories from "../../components/layout/folders/Categories";
import LayoutsRatio from "../../components/layout/folders/LayoutsRatio";
import Hoc from "../../hoc/Hoc";

const TemplateInfo = () => {
  let params = useParams();
  const {category} = params;
  return (
    <Hoc>
      <AdminSidebar>
        {category === "layouts" ? <LayoutsRatio /> : <Categories />}
      </AdminSidebar>

      <DashboardPanel>
        <TemplateDetails />
      </DashboardPanel>
    </Hoc>
  );
};

export default TemplateInfo;
