export function sendChatTemplate() {
  return function (state, action) {
    return {
      ...state,
      chats: [
        {
          user: action.user,
          message: action.message,
          timestamp: new Date().getTime(),
        },
        ...state.chats,
      ],
    };
  };
}
