import React, {useState, useEffect, useRef, useCallback} from "react";
import {connect, batch} from "react-redux";
import styled, {css} from "styled-components";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../store/selectors/template/template.selector";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../../store/selectors/fields.selector";
import {
  updateFieldSize,
  updateFieldPosition,
  resizingField,
} from "../../../../../store/actions/fields/common.action";
import {transformImgOffset} from "../../../../../store/actions/fields/image.action";
import {centerToTL} from "../../dragResize/utils";
import {
  getItemXOffset,
  getItemYOffset,
} from "../../../../../utils/getItemOffset";
import {cropImageResize} from "./cropImageResize";
import {storeFieldUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {resizerColor} from "../../../../../themes";
import {selectCanvasPanelWidth} from "../../../../../store/selectors/layout/layout.selector";
import {selectResizing} from "../../../../../store/selectors/common.selector";
import {updateFieldCollaboration} from "../../../../../store/actions/collaboration/collaboration.action";

let delayDebounce;
const ResizeImage = ({
  field,
  selectedProps,
  updateFieldSize,
  updateFieldPosition,
  transformImgOffset,
  tempScale,
  storeFieldUndoRedo,
  slideID,
  fieldID,
  tempSize,
  canvasWidth,
  resizing,
  resizingField,
  updateFieldCollaboration,
}) => {
  const [dragResize, setDragResize] = useState({
    dragging: false,
    orig: {},
    direction: "",
    side: false,
  });
  const [resizeLimit, setResizeLimit] = useState({
    min: {x: 0, y: 0},
    max: {x: 0, y: 0},
  });
  const resizerRef = useRef();
  const active = {slideID, fieldID};

  // 1321, 576
  const onResizeStart = (e, dir) => {
    const {size, pos, imgPos} = field || {};
    const xOffset = pos?.x,
      yOffset = pos?.y,
      outerWidth = size?.w,
      outerHeight = size?.h;
    const clientX = getItemXOffset(e, tempScale);
    const clientY = getItemYOffset(e, tempScale);
    setDragResize({
      ...dragResize,
      orig: {
        mouseX: clientX,
        mouseY: clientY,
        outerWidth,
        outerHeight,
        initialX: xOffset, // for resize offset
        initialY: yOffset,
        innerWidth: Math.ceil(size.w2),
        innerHeight: Math.ceil(size.h2),
        imgPosX: imgPos?.x,
        imgPosY: imgPos?.y,
        remainingWidth: size?.w2 - (outerWidth - imgPos?.x),
        remainingHeight: size?.h2 - (outerHeight - imgPos?.y),
        fullImgXOffset: xOffset + (imgPos?.x > 0 ? 0 : imgPos?.x),
        fullImgYOffset: yOffset + (imgPos?.y > 0 ? 0 : imgPos?.y),
      },
      direction: dir,
    });

    // store image's resized width
    // for eg - for top left resizer
    // store how much width and height could it further extend from its current offset
    // calculate this for four corners

    setResizeLimit({
      min: {x: Math.ceil(pos?.x), y: Math.ceil(pos?.y)},
      max: {
        x: Math.ceil(pos?.x + imgPos?.x + size?.w2),
        y: Math.ceil(pos?.y + imgPos?.y + size?.h2),
      },
    });

    // to hide tooltip when the cropping (resizing) is performed.
    resizingField({resizing: true});

    storeFieldUndoRedo();
    e.stopPropagation();
  };

  const onResize = (e) => {
    const {styles, pos, size} = selectedProps || {};
    const {orig, direction} = dragResize || {};
    const imgPos = {x: orig?.imgPosX, y: orig?.imgPosY};
    // const {fullImgXOffset, fullImgYOffset} = orig;
    const {centerX, centerY, width, height, rotate} = cropImageResize({
      e,
      size,
      pos,
      initialProps: dragResize,
      tempScale: tempScale,
      rotate: styles?.rotate,
      direction: direction,
    });
    const resize = centerToTL({centerX, centerY, width, height, rotate});

    const rotat = cropImageResize({
      e: {
        clientX: 0,
        clientY: 0,
      },
      size: {
        w: size?.w2,
        h: size?.h2,
      },
      pos: {
        x: orig.fullImgXOffset,
        y: orig.fullImgYOffset,
      },
      initialProps: {
        orig: {
          mouseX: 0,
          mouseY: 0,
        },
      },
      tempScale: tempScale,
      rotate: field?.styles?.rotate,
      direction: "bottom-right",
    });
    const rotatRsize = centerToTL({
      centerX: rotat.centerX,
      centerY: rotat.centerY,
      width: rotat.width,
      height: rotat.height,
      rotate: rotat.rotate,
    });

    // fixed image starting offset
    let fullImgXOffset =
      styles?.rotate !== 0 || styles?.rotate !== 360
        ? rotatRsize?.left // orig.fullImgXOffset
        : orig?.fullImgXOffset; // resize.left;
    let fullImgYOffset =
      styles?.rotate !== 0 || styles?.rotate !== 360
        ? rotatRsize?.top // orig.fullImgYOffset
        : orig?.fullImgYOffset; //resize.top;

    let maxWidthLimit = Math.ceil(resize?.left);
    // resize.left + resize.width > resizeLimit.max.x + imgPos.x
    //   ? resizeLimit.min.x // resize.left //
    //   : resize.left;
    let maxHeightLimit = Math.ceil(resize?.top);
    // resize.top + resize.height > resizeLimit.max.y + imgPos.y
    //   ? resizeLimit.min.y // resize.top
    //   : resize.top;

    const newX =
      fullImgXOffset < Math.floor(resize?.left) ? maxWidthLimit : fullImgXOffset;
    const newY =
      fullImgYOffset < Math.floor(resize?.top) ? maxHeightLimit : fullImgYOffset;
    updateFieldPosition({
      active: {
        slideID,
        fieldID,
      },
      payload: {
        // x: maxWidthLimit,
        // y: maxHeightLimit,
        // need to check each corner for range exceeding like this
        // fullImgXOffset + size.w2 < Math.round(resize.left)
        // fullImgYOffset + size.h2 < Math.round(resize.top)
        x: newX,
        y: newY,
      },
    });

    // let resizedWidth = 0,
    //   resizedHeight = 0;
    // if (styles.rotate === 0 || styles.rotate === 360) {
    //   if (resize.left + resize.width > resizeLimit.max.x) {
    //     resizedWidth = Math.round(size.w2) + imgPos.x;
    //   } else if (resize.top + resize.height > resizeLimit.max.y) {
    //     resizedHeight = Math.round(size.h2) + imgPos.y;
    //   }
    //   // full img
    //   else if (fullImgXOffset > Math.round(resize.left)) {
    //     resizedWidth = size.w2 - orig.remainingWidth;
    //   } else if (fullImgYOffset > Math.round(resize.top)) {
    //     resizedHeight = size.h2 - orig.remainingHeight;
    //   }
    //   //
    //   else {
    //     resizedWidth = Math.round(resize.width);
    //     resizedHeight = Math.round(resize.height);
    //   }
    // } else {
    //   if (fullImgXOffset > Math.round(resize.left)) {
    //     // console.log(orig.remainingWidth);
    //     resizedWidth = size.w2 - orig.remainingWidth;
    //     resizedHeight = size.h2 - orig.remainingHeight;
    //   } else if (
    //     fullImgXOffset + size.w2 < Math.round(resize.left) ||
    //     fullImgYOffset + size.h2 < Math.round(resize.top)
    //   ) {
    //     resizedWidth = size.w2 - orig.remainingWidth;
    //     resizedHeight = size.h2 - orig.remainingHeight;
    //   } else {
    //     resizedWidth = Math.round(resize.width);
    //     resizedHeight = Math.round(resize.height);
    //   }
    // }

    // console.log(resize.left + resize.width > resizeLimit.max.x, 1);
    // console.log(orig.remainingWidth, "remaining");
    // console.log(fullImgXOffset > Math.round(resize.left), fullImgXOffset, 2);

    // 1) size.w2 - orig.remaininggWidth of outerWidth's updateFieldSize doesn't work
    // for top right
    // as orig.remainingWidth needs to be changed when resizing to right
    // as image only calculate imgPos from the left part of the side
    // same goes for bottom right ?

    const newSize = {
      outerWidth:
        styles?.rotate == 0 && resize?.left + resize?.width > resizeLimit.max.x //+ imgPos.x
          ? Math.ceil(size?.w2) + imgPos?.x
          : fullImgXOffset > Math.ceil(resize?.left)
          ? size?.w2 - orig.remainingWidth
          : Math.ceil(resize.width),
      outerHeight:
        styles?.rotate == 0 && resize?.top + resize?.height > resizeLimit.max.y //+ imgPos.y
          ? Math.ceil(size?.h2) + imgPos?.y
          : fullImgYOffset > Math.ceil(resize?.top)
          ? size?.h2 - orig.remainingHeight
          : Math.ceil(resize?.height),
      innerWidth: Math.ceil(size?.w2),
      innerHeight: Math.ceil(size?.h2),
      scale: styles?.scale,
    };

    updateFieldSize({
      active: {
        slideID,
        fieldID,
      },
      payload: {
        outerWidth: newSize.outerWidth,
        // resizedWidth,
        // resize.left + resize.width > resizeLimit.max.x //+ imgPos.x
        //   ? size.w2 + imgPos.x
        //   : resize.width,
        outerHeight: newSize.outerHeight,
        // resizedHeight,

        // resize.top + resize.height > resizeLimit.max.y //+ imgPos.y
        //   ? size.h2 + imgPos.y
        //   : resize.height,
        innerWidth: newSize.innerWidth,
        innerHeight: newSize.innerHeight,
        scale: styles?.scale,
      },
    });

    const newOffset = {x: newX, y: newY};

    if (direction === "bottom-right") {
      // transformImgOffset(pos.x - pos.x + imgPos.x, pos.y - pos.y + imgPos.y);
      const newImgPos = {x: imgPos?.x, y: imgPos?.y};
      transformImgOffset(active, newImgPos);
      handleSocketEmit({pos: newOffset, size: newSize, imgPos: newImgPos});
    } else if (direction === "bottom-left") {
      //&& selectedProps.pos.x + imgPos.x
      // transformImgOffset(orig.initialX - pos.x, imgPos.y);
      const newImgPos = {
        x:
          fullImgXOffset > resize?.left
            ? 0
            : imgPos?.x - (orig.outerWidth - resize?.width),
        y: fullImgYOffset > resize?.top ? 0 : imgPos?.y,
      };
      transformImgOffset(
        active,
        newImgPos
        // imgPos.x - (orig.outerWidth - resize.width), imgPos.y
      );
      handleSocketEmit({pos: newOffset, size: newSize, imgPos: newImgPos});
    } else if (direction === "top-left") {
      // && resize.left > resizeLimit.min.x
      // transformImgOffset(pos.x + imgPos.x - pos.x, pos.y + imgPos.y - pos.y);
      const newImgPos = {
        x:
          fullImgXOffset > resize?.left
            ? 0
            : imgPos?.x - (orig?.outerWidth - resize?.width),
        y:
          fullImgYOffset > resize?.top
            ? 0
            : imgPos?.y - (orig?.outerHeight - resize?.height),
      };
      transformImgOffset(
        active,
        // imgPos.x - (orig.outerWidth - resize.width),
        // imgPos.y - (orig.outerHeight - resize.height)
        newImgPos
      );
      handleSocketEmit({pos: newOffset, size: newSize, imgPos: newImgPos});
    } else {
      //  imgPos.x, for x
      // transformImgOffset(pos.x - pos.x + imgPos.x, pos.y - resize.top);
      // console.log("4", orig.outerHeight, imgPos.y);
      const newImgPos = {
        x: fullImgXOffset > resize?.left ? 0 : imgPos?.x,
        y:
          fullImgYOffset > resize?.top
            ? 0
            : imgPos?.y - (orig?.outerHeight - resize?.height),
      };
      transformImgOffset(active, newImgPos);
      handleSocketEmit({pos: newOffset, size: newSize, imgPos: newImgPos});
    }
  };

  const onResizeEnd = (e) => {
    // to hide tooltip when the cropping (resizing) is performed.
    resizingField({resizing: false});
    storeFieldUndoRedo();
    e.stopPropagation();
  };

  const handleSocketEmit = ({pos, size, imgPos}) => {
    clearTimeout(delayDebounce);
    delayDebounce = setTimeout(() => {
      updateFieldCollaboration();
    }, 800);
    // set 800 here, as it requires to be quite an instant update
    // as user would re-adjust image by panning
    // if timeout is long, this update would fire behind the last pan's action
    // hence, last imgPos value will not be updated.
    return () => clearTimeout(delayDebounce);
  };

  useEffect(() => {
    if (resizing.status === true && window) {
      window.addEventListener("touchmove", onResize);
      window.addEventListener("touchend", onResizeEnd);
      window.addEventListener("mouseup", onResizeEnd);
      window.addEventListener("mousemove", onResize);
      window.addEventListener("mouseleave", onResizeEnd);
    } else {
      window.removeEventListener("touchmove", onResize);
      window.removeEventListener("touchend", onResizeEnd);
      window.removeEventListener("mouseup", onResizeEnd);
      window.removeEventListener("mousemove", onResize);
      window.removeEventListener("mouseleave", onResizeEnd);
    }
    return () => {
      window.removeEventListener("touchmove", onResize);
      window.removeEventListener("touchend", onResizeEnd);
      window.removeEventListener("mouseup", onResizeEnd);
      window.removeEventListener("mousemove", onResize);
      window.removeEventListener("mouseleave", onResizeEnd);
    };
  }, [resizing.status]);

  const onResizeStartTopLeft = (e) => {
    onResizeStart(e, "top-left");
  };

  const onResizeStartTopRight = (e) => {
    onResizeStart(e, "top-right");
  };

  const onResizeStartBottomLeft = (e) => {
    onResizeStart(e, "bottom-left");
  };

  const onResizeStartBottomRight = (e) => {
    onResizeStart(e, "bottom-right");
  };

  const adjustedXOffset = Math.ceil(canvasWidth - tempSize.w * tempScale) / 2;

  let accomodateSpace = 0;
  if (window.innerWidth < 850 && canvasWidth > tempSize.w * tempScale) {
    accomodateSpace = (canvasWidth - (tempSize.w * tempScale)) / 2;
  }
  // pos.x * tempScale + adjustedXOffset - 1 * tempScale;
  // field.pos.x * tempScale + 15;
  return (
    <Wrapper
      ref={resizerRef}
      style={{
        width: field?.size?.w * tempScale,
        height: field?.size?.h * tempScale,
        transform: `translate(${field?.pos?.x * tempScale + adjustedXOffset - accomodateSpace}px, ${
          field?.pos?.y * tempScale
        }px) rotate(${field?.styles?.rotate}deg)`,
        border: `1px solid ${resizerColor}`,
        outline: `rgba(234, 243, 247, 0.5) solid 1px`,
        zIndex: 9999,
        transformOrigin: "50% 50%",
      }}
    >
      <Resizer
        style={{
          cursor: "nwse-resize",
          position: "absolute",
          top: -2,
          left: -2,
        }}
        onMouseDown={onResizeStartTopLeft}
        onTouchStart={onResizeStartTopLeft}
      >
        <TL1 />
        <TL2 />
      </Resizer>
      <Resizer
        style={{
          cursor: "nesw-resize",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          top: -2,
          right: -2,
        }}
        onMouseDown={onResizeStartTopRight}
        onTouchStart={onResizeStartTopRight}
      >
        <TR1 />
        <TR2 />
      </Resizer>
      <Resizer
        style={{
          cursor: "nesw-resize",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          bottom: -2,
          left: -2,
        }}
        onMouseDown={onResizeStartBottomLeft}
        onTouchStart={onResizeStartBottomLeft}
      >
        <BL1 />
        <BL2 />
      </Resizer>
      <Resizer
        style={{
          cursor: "nwse-resize",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          bottom: -2,
          right: -2,
        }}
        onMouseDown={onResizeStartBottomRight}
        onTouchStart={onResizeStartBottomRight}
      >
        <BR1 />
        <BR2 />
      </Resizer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  &:after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
`;

const Resizer = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  pointer-events: auto;
  display: flex;
`;

const flexStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  position: absolute;
`;

const styles = css`
  content: "";
  background: #fff;
  box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
`;

const BR1 = styled.div`
  ${flexStyles};
  &:after {
    ${styles};
    width: 3px;
    height: 12px;
  }
`;

const BR2 = styled.div`
  ${flexStyles};
  &:after {
    ${styles};
    width: 12px;
    height: 3px;
  }
`;

const BL1 = styled.div`
  ${flexStyles};
  bottom: 0;
  &:after {
    ${styles};
    width: 3px;
    height: 12px;
  }
`;

const BL2 = styled.div`
  ${flexStyles};
  bottom: 0;
  &:after {
    ${styles};
    width: 12px;
    height: 3px;
  }
`;

const TL1 = styled.div`
  ${flexStyles};
  top: 0;
  &:after {
    ${styles};
    width: 3px;
    height: 12px;
  }
`;

const TL2 = styled.div`
  ${flexStyles};
  top: 0;
  &:after {
    ${styles};
    width: 12px;
    height: 3px;
  }
`;

const TR1 = styled.div`
  ${flexStyles};
  top: 0;
  &:after {
    ${styles};
    width: 3px;
    height: 12px;
  }
`;

const TR2 = styled.div`
  ${flexStyles};
  top: 0;
  &:after {
    ${styles};
    width: 12px;
    height: 3px;
  }
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    selectedProps: selectActiveFieldProps(designTemplate),
    tempScale: selectTempScale(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    canvasWidth: selectCanvasPanelWidth(designTemplate),
    resizing: selectResizing(designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    transformImgOffset,
    updateFieldSize,
    updateFieldPosition,
    storeFieldUndoRedo,
    resizingField,
    updateFieldCollaboration,
  }))
)(ResizeImage);
