import React from "react";
import styled, {css} from "styled-components";
import {TooltipStyles} from "../../../ui/styles/TooltipStyles";
import {QuestionMark} from "../../../../oat-svg-icons";
import SlideActionItems from "./SlideActionItems";

const SlideActionsPanel = ({theme, panelOpened}) => {
  return (
    <Wrapper>
      <SlideActionItems theme={theme} panelOpened={panelOpened} />
      <div>
        <WrapActionItem style={{marginBottom: 12}}>
          <WrapSvg>{QuestionMark}</WrapSvg>
          <Tooltip style={{top: -32, right: -10}}>Help</Tooltip>
        </WrapActionItem>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 95px;
  padding-top: 22px;
  z-index: 9;
  background: ${({theme}) => theme.deskPrimaryBg};
  box-shadow: ${({theme}) => theme.rightPanelBoxShadow};
`;

const Tooltip = styled.div`
  width: auto;
  top: 10px;
  right: 56px;
  ${TooltipStyles};
`;

export const actionItemStyles = css`
  position: relative;
  width: 45px;
  height: 45px;
  fill: ${({theme}) => theme.rightPanelSvgFill};
  // background: ${({theme}) => theme.rightPanelSvgBg};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // box-shadow: ${({theme}) => theme.rightPanelSvgBoxShadow};
  &:hover ${Tooltip} {
    display: flex;
  }
`;

const WrapActionItem = styled.div`
  ${actionItemStyles}
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
`;

export default SlideActionsPanel;
