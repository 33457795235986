import React from "react";
import Switch from "../../shared/inputs/Switch";
import RangeSlider from "../../shared/inputs/RangeSlider";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import {WrapColorBox} from "../common/WrapColorStyles";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {textEditProps} from "./EditText";
import {connect} from "react-redux";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const TextBackground = ({
  activeField,
  slideID,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const {styles, key} = activeField || {};
  const active = {slideID, fieldID: key};

  const handleUpdates = (type, value) => {
    updateFieldStyles({
      active,
      type,
      value,
    });
  };

  const changeBgStatus = async (value) => {
    handleUpdates("bgType", styles.bgType === "" ? "button" : "");
    await updateFieldCollaboration();
  };

  const changeBgColor = (value) => {
    handleUpdates("background", value);
  };

  const changeShadowColor = (value) => {
    handleUpdates("shadowColor", value);
  };

  const changeBorderRadius = (value) => {
    handleUpdates("borderRadius", Number(value));
  };

  const onBgDebounce = (value) => {
    const selection = window.getSelection();
    if (selection.isCollapsed) {
      storeFieldUndoRedo();
      updateFieldCollaboration();
    }
  };

  const onShadowColorDebounce = (value) => {
    const selection = window.getSelection();
    if (selection.isCollapsed) {
      storeFieldUndoRedo();
      updateFieldCollaboration();
    }
  };

  return (
    <>
      <div
        style={{
          padding:
            styles?.enableBg || window.innerWidth < 850 ? "0.5em 0 2em 0" : "0",
        }}
      >
        <Switch
          title="Enable Background"
          value={styles?.enableBg}
          onItemChange={changeBgStatus}
          checked={styles?.enableBg}
        />
      </div>

      {styles?.enableBg ? (
        <div
          style={{
            marginBottom: window.innerWidth < 850 ? 58 : 0,
          }}
        >
          <WrapColorBox style={{paddingBottom: "2em"}}>
            <Title>Color</Title>
            <PopoverColorBox
              value={styles?.background}
              updateFieldStyles={changeBgColor}
              onDebounce={onBgDebounce}
            />
          </WrapColorBox>

          <WrapColorBox style={{paddingBottom: "2em"}}>
            <Title>Shadow</Title>
            <PopoverColorBox
              value={styles?.shadowColor}
              updateFieldStyles={changeShadowColor}
              onDebounce={onShadowColorDebounce}
            />
          </WrapColorBox>

          <div style={{paddingBottom: "0.5em"}}>
            <RangeSlider
              title="Radius"
              fieldType="borderRadius"
              value={styles?.borderRadius ? styles?.borderRadius : 0}
              max={textEditProps.borderRadius.max}
              min={textEditProps.borderRadius.min}
              step={textEditProps.borderRadius.step}
              onItemChange={changeBorderRadius}
            />
          </div>
        </div>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveFieldProps(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(TextBackground);
