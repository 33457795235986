import React from "react";
import styled from "styled-components";
import EditText from "./EditText";

const EditTextPanel = () => {
  return (
    <Wrapper>
      <EditText />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-direction: column;
  z-index: 10;
  padding-bottom: 120px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export default EditTextPanel;
