import React, {useState, useRef} from "react";
import {changeSlide} from "../../../store/actions/template/slide.action";
import {connect} from "react-redux";
import styled from "styled-components";
import {switchSlideIndex} from "../../../store/actions/template/slide.action";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  selectTemplate,
  selectTemplateSize,
} from "../../../store/selectors/template/template.selector";
import {selectActiveSlide, selectActiveSlideID} from "../../../store/selectors/template/slide.selector";
import {detectClickOutside, Popper} from "../../../../oat-ui";
import SortableItem from "./SortableItem";
import SlideListPopper from "./SlideListPopper";
import {useSocket} from "../../../webSocket/useSocket";
import {useParams} from "react-router-dom";

const popUpHeight = 102;
const SlideList = ({
  slides,
  changeSlide,
  canvasSize,
  switchSlideIndex,
  activeSlide,
  activeSlideID,
  panelWidth,
}) => {
  const [dragSlideID, setDragSlideID] = useState();
  const ref = useRef();
  const {emitSocketEvents} = useSocket() || {};
  let params = useParams();
  const {category} = params;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const findTemplateIndex = (value) => {
    return slides.findIndex((x) => x.id === value);
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (over) {
      const oldSlideID = active?.id;
      const newSlideID = over?.id;
      const currentSlide = slides.find((item, index) => index === activeSlide);

      if (event.over !== null && active.id !== over?.id) {
        const fromIndex = findTemplateIndex(oldSlideID);
        const toIndex = findTemplateIndex(newSlideID);
        const placeUnderSlideID = slides.find(
          (item, index) => index === toIndex - 1
        );
        switchSlideIndex({
          fromIndex,
          toIndex,
          activeSlideID: currentSlide.id,
          placeUnderSlideID: placeUnderSlideID?.id,
          emitForCollaboration: false,
        });

        const item = {
          type: "",
          value: {
            fromIndex,
            toIndex,
            activeSlideID: currentSlide.id,
            placeUnderSlideID: placeUnderSlideID?.id,
            emitForCollaboration: true,
          },
        };
        if (emitSocketEvents) {
          emitSocketEvents({actionType: "swapped-slide", item});
        }
      } 
  
      if (active.id === newSlideID && active.id !== currentSlide.id) {
        const newSlideIndex = findTemplateIndex(newSlideID);
        changeSlide(newSlideIndex);
      }
    } else {
      const newSlideIndex = findTemplateIndex(active?.id);
      changeSlide(newSlideIndex);
    }
  };

  const handleDragStart = (e) => {
    const {active} = e;
    setDragSlideID(active.id);
  };

  const [popUpBox, setPopUpBox] = useState({
    status: false,
    offset: {x: null, y: null},
  });

  const handleRightClick = (e, slideID) => {
    if (e.pageY < 100) {
      // first top
      setPopUpBox({
        status: true,
        selectedSlide: slideID,
        offset: {x: e.pageX, y: e.pageY - popUpHeight},
      });
    } else if (e.pageY + popUpHeight < window.innerHeight) {
      // middle
      setPopUpBox({
        status: true,
        selectedSlide: slideID,
        offset: {x: e.pageX, y: e.pageY - 100},
      });
    } else {
      // low bottom
      setPopUpBox({
        status: true,
        selectedSlide: slideID,
        offset: {x: e.pageX, y: e.pageY - popUpHeight},
      });
    }
  };

  const closeOptionBox = () => {
    setPopUpBox({
      status: false,
      selectedSlide: null,
      offset: {x: null, y: null},
    });
  };

  detectClickOutside(ref, () => {
    closeOptionBox();
  });

  return (
    <Wrapper
      style={{
        // overflow: "hidden",
        boxSizing: "content-box",
        height: window.innerHeight + 70,
        paddingBottom: "5.2rem",
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={slides} strategy={verticalListSortingStrategy}>
          {/* <LazyLoad height="100%" once> */}
          {slides &&
            slides.map((slide, index) => (
              <SortableItem
                key={slide.id}
                id={slide.id}
                slide={slide}
                canvasSize={canvasSize}
                currentDragSlide={dragSlideID === slide.id}
                index={index}
                $activeSlide={slide.id === activeSlideID}
                panelWidth={panelWidth}
                onRightClick={handleRightClick}
              />
            ))}
          {/* </LazyLoad> */}
        </SortableContext>
      </DndContext>

      {popUpBox.status ? (
        <Popper
          offset={{x: popUpBox.offset.x, y: popUpBox.offset.y + popUpHeight}}
          width={200}
          height={92}
          onClose={closeOptionBox}
          styles={{
            zIndex: 99,
            boxShadow: "rgb(138 116 179 / 52%) 0px 0px 2px 0px",
          }}
        >
          <div
            ref={ref}
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <SlideListPopper
              selectedSlide={popUpBox.selectedSlide}
              onClose={closeOptionBox}
              slidesLength={slides.length}
            />
          </div>
          
        </Popper>
      ) : undefined}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    canvasSize: selectTemplateSize(designTemplate),
    activeSlide: selectActiveSlide(designTemplate),
    activeSlideID: selectActiveSlideID(designTemplate)
  };
};

export default connect(mapStateToProps, {changeSlide, switchSlideIndex})(
  SlideList
);
