import React from "react";
import styled from "styled-components";
import IncrementDropDown from "../../../../../../ui/inputs/IncrementDropDown";
import {Title} from "../../shared/reuseStyles/Title";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";
import RangeSlider from "../../shared/inputs/RangeSlider";

export const fontSizes = [
  // {value: 6, display: "6"},
  // {value: 8, display: "8"},
  // {value: 10, display: "10"},
  // {value: 12, display: "12"},
  {value: 16, display: "16"},
  {value: 20, display: "20"},
  {value: 24, display: "24"},
  {value: 28, display: "28"},
  {value: 32, display: "32"},
  {value: 38, display: "38"},
  {value: 46, display: "46"},
  {value: 60, display: "60"},
  {value: 75, display: "75"},
  {value: 90, display: "90"},
  {value: 120, display: "120"},
  {value: 150, display: "150"},
  {value: 180, display: "180"},
];

const SelectFontSize = ({value, onSelect, onClose, scale}) => {
  // 21.2 -> [21, 2]
  // const [valueSet, setValueSet] = useState([]);

  const windowSize = getWindowSize();
  const changeFontSize = (item) => {
    const updateValue = Number(item / scale);
    onSelect(updateValue);
  };

  // useEffect(() => {
  //   const splitValue = ((value * scale).toFixed(1) + "").split(".");
  //   setValueSet(splitValue);
  // }, [value, scale]);

  return windowSize.width >= 850 ? (
    <Wrapper>
      <Title>Font Size</Title>

      <WrapDropDown>
        <IncrementDropDown
          onSelect={changeFontSize}
          selected={
            Number((value * scale).toFixed(1))
            // valueSet[1] == 0
            //   ? Number(valueSet[0])
            //   : Number((value * scale).toFixed(1))
          }
          options={fontSizes}
          minWidth={70}
          overflowY="scroll"
          height={160}
          onClose={onClose}
        />
      </WrapDropDown>
    </Wrapper>
  ) : (
    <RangeSlider
      title="Font Size"
      fieldType="fontSize"
      value={value}
      max={fontSizes[fontSizes.length - 1].value}
      min={fontSizes[0].value}
      step={1}
      onItemChange={changeFontSize}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  overflow: visible;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

SelectFontSize.defaultProps = {
  scale: 1,
};

export default SelectFontSize;
