import {connect} from "react-redux";
import React, {useState, useRef} from "react";
import {CommentActionsLayout, MoreOptions} from "./commentStyles";
import {selectActiveSlideInfo} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectTemplateID,
  selectTempSourceID,
} from "../../../../../../store/selectors/template/template.selector";
import {selectUserInfo} from "../../../../../../../../redux/user/authUser";
import {detectClickOutside, Spinner} from "../../../../../../../oat-ui";
import {redMessage} from "../../../../../../themes";
import {onResolvedIssue} from "../../../../../../store/actions/template/comment.action";
import styled from "styled-components";
import {useSocket} from "../../../../../../webSocket/useSocket";

const CommentActions = ({
  item,
  onEdit,
  subcomment,
  onDelete,
  username,
  deleting,
  tempID,
  tempSourceID,
  slideID,
  onResolvedIssue,
  isMentionedUser
}) => {
  const [openActions, setOpenActions] = useState(false);
  const [processing, setProcessing] = useState(false);
  const actionLayoutRef = useRef();
  const {emitSocketEvents} = useSocket() || {};

  const onOpenActions = () => {
    setOpenActions(!openActions);
  };

  detectClickOutside(actionLayoutRef, () => {
    setOpenActions(false);
    setProcessing(false);
  });

  const handleEdit = () => {
    onEdit(item.ID);
  };

  const handleDelete = () => {
    onDelete(item);
  };

  const handleResolvedIssue = () => {
    if (!processing) {
      setProcessing(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/comments/${item.ID}/resolved-issue`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempSourceID,
            slideID,
          }),
        }
      )
        .then((response) => response.json())
        .then((e) => {
          onResolvedIssue({slideID, commentID: item.ID});
          if (emitSocketEvents) {
            emitSocketEvents({
              actionType: "resolved-comment",
              item: {
                type: null,
                value: {slideID, commentID: item.ID},
              },
            });
          }
          setProcessing(false);
          setOpenActions(false);
        })
        .catch((e) => {
          // show error message later
          setProcessing(false);
        });
    }
  };

  return (
    <div ref={actionLayoutRef} style={{position: "relative"}}>
      <MoreOptions onClick={onOpenActions}>
        <div />
        <div />
        <div />
      </MoreOptions>
      {openActions ? (
        <CommentActionsLayout processing={deleting}>
          {!isMentionedUser ? (
            <div className="comment-popper-option" onClick={handleEdit}>
              Edit
            </div>
          ) : undefined}
          
          {!subcomment &&
          item.actionRequire.status &&
          !item.actionRequire.resolved ? (
            <div
              className="comment-popper-option"
              style={{display: "flex"}}
              onClick={handleResolvedIssue}
            >
              Resolved
              {processing ? (
                <WrapSpinner>
                  <Spinner />
                </WrapSpinner>
              ) : undefined}
            </div>
          ) : undefined}
          
          {!isMentionedUser ? 
            (<div
              className="comment-popper-option"
              onClick={handleDelete}
              style={{color: redMessage}}
            >
              Delete
            </div>) 
          : undefined}
        </CommentActionsLayout>
      ) : undefined}
    </div>
  );
};

const WrapSpinner = styled.div`
  width: 18px;
  height: 18px;
  transform: scale(0.5);
  margin-left: 8px;
`;

const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
    slideID: selectActiveSlideInfo(state.designTemplate).id,
    tempSourceID: selectTempSourceID(state.designTemplate),
    tempID: selectTemplateID(state.designTemplate),
  };
};

export default connect(mapStateToProps, {onResolvedIssue})(CommentActions);
