import React, {useState, useRef} from "react";
import {paleBlue, userPrimaryGrey} from "../../../../userDashboardStyles";
import styled from "styled-components";
import {Popper} from "./ProfileCollections";
import {detectClickOutside} from "../../../../../oat-ui";
import EditProfileCollection from "./EditProfileCollection";
import DeleteProfileCollection from "./DeleteProfileCollection";
import {useNavigate} from "react-router-dom";

const Collection = ({collection}) => {
  let navigate = useNavigate();
  const popperRef = useRef();
  const [actionsPopper, setActionsPopper] = useState({
    type: null,
    status: false,
  });

  const onSelectCollection = () => {
    return navigate(`/dashboard/profile?collection=${collection.id}`);
  };

  const openActionsPopper = () => {
    setActionsPopper({
      type: "actions",
      status: true,
    });
  };

  const closePopper = () => {
    setActionsPopper({
      type: null,
      status: false,
    });
  };

  detectClickOutside(popperRef, () => {
    closePopper();
  });

  const onIgnoreEvent = (e) => {
    e.stopPropagation();
  };

  const openEditPopper = () => {
    setActionsPopper({
      type: "edit-collection",
      status: true,
    });
  };

  const openDeletePopper = () => {
    setActionsPopper({
      type: "delete-collection",
      status: true,
    });
  };

  return (
    <Item>
      <CollectionName onClick={onSelectCollection}>
        {collection.display}
      </CollectionName>

      <Options onClick={openActionsPopper}>
        <div />
        <div />
        <div />
      </Options>

      {actionsPopper.status && actionsPopper.type === "actions" ? (
        <Popper
          style={{height: 86, left: 80, zIndex: 99999}}
          onClick={onIgnoreEvent}
          ref={popperRef}
        >
          <Option onClick={openEditPopper}>
            <div>Edit</div>
          </Option>
          <Option onClick={openDeletePopper}>
            <div>Delete</div>
          </Option>
        </Popper>
      ) : undefined}

      {actionsPopper.status && actionsPopper.type === "edit-collection" ? (
        <EditProfileCollection collection={collection} close={closePopper} />
      ) : undefined}

      {actionsPopper.status && actionsPopper.type === "delete-collection" ? (
        <DeleteProfileCollection collection={collection} close={closePopper} />
      ) : undefined}
    </Item>
  );
};

const Options = styled.div`
  display: none;
  align-items: center;
  margin-right: 5px;
  padding: 4px 0;
  div {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-left: 3px;
    background: #858383;
  }
`;

const CollectionName = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: 600;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  justify-content: space-between;
  padding: 0 4px 0 18px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  position: relative;
  div {
    color: ${userPrimaryGrey};
    align-items: center;
  }
  &:hover {
    background: ${paleBlue};
  }
  &:hover ${Options} {
    display: flex;
  }
`;

const Option = styled.div`
  color: ${userPrimaryGrey};
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 6px;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: 600;
  &:hover {
    background: ${paleBlue};
  }
`;

export default Collection;
