export function getDashboardInit() {
  return function (state, action) {
    return {
      ...state,
      fetchData: {
        ...state.fetchData,
        loading: action.paginate ? false : true,
        error: false,
      },
    };
  };
}

export function getDashboardSuccess() {
  return function (state, action) {
    const {templates, workspace, workspaces, folders, paginationToken} = action.payload;
    const templateList = action.paginate ? [...state.templates, ...templates] : templates;
    return {
      ...state,
      workspace: {
        ...state.workspace,
        active: workspace,
        items: workspaces,
        members: workspace.members,
        profileSlug: workspace.profileSlug,
      },
      activeFolder: {
        ID: folders.activeFolderID,
        folderType: folders.folderType,
        folderName: folders.folderName,
      },
      folders: {
        designs: folders.designFolders,
        ['library-images']: folders.libraryImagesFolders
        // notes: folders.notesFolders,
        // forms: folders.formsFolders,
      },
      templates: templateList,
      paginationToken,
      fetchData: {
        ...state.fetchData,
        loading: false,
        error: false,
      },
    };
  };
}

export function getDashboardError() {
  return function (state, action) {
    const {error} = action;
    return {
      ...state,
      fetchData: {
        loading: false,
        error: true,
      },
    };
  };
}

export function openDashboardModal() {
  return function (state, action) {
    const {type, modalName} = action.payload;
    return {
      ...state,
      modal: {
        name: modalName,
        type,
        status: true,
        error: null,
      },
    };
  };
}

export function closeDashboardModal() {
  return function (state) {
    return {
      ...state,
      modal: {
        name: "",
        type: "",
        status: false,
        error: null,
      },
    };
  };
}

// use when loading library for the very first time
export function setFoldersWorkspaceInfo() {
  return function (state, action) {
    const { folders } = action.payload;
    return {
      ...state,
      folders: {
        designs: folders.designFolders,
        ['library-images']: folders.libraryImagesFolders
        // notes: action.payload.folders.notesFolders,
        // forms: action.payload.folders.formsFolders,
      },
      workspace: {
        ...state.workspace,
        active: {
          ...state.workspace.active,
          ...action.payload.workspace,
        },
      },
    };
  };
}
