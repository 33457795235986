import React from "react";
import {selectActiveSlide} from "../../../../../../../../store/selectors/template/slide.selector";
import {connect} from "react-redux";
import {
  selectActiveFieldProps,
  selectCachedFieldStyles,
} from "../../../../../../../../store/selectors/fields.selector";
import {updateFieldStyles} from "../../../../../../../../store/actions/fields/common.action";
import {DrawerLayout} from "../layoutStyles";
import EditOpacity from "../../../../desktop/common/EditOpacity";
import EditChartGrid from "../../../../desktop/charts/reuse/EditChartGrid";
import EditChartValue from "../../../../desktop/charts/EditChartValue";
import EditChartMeasurement from "../../../../desktop/charts/EditChartMeasurement";
import ChartBackground from "../../../../desktop/charts/ChartBackground";
import EditChartLegend from "../../../../desktop/charts/EditChartLegend";
import EditChartCategories from "../../../../desktop/charts/EditChartCategories";
import styled from "styled-components";
import SelectAxisColor from "../../../../desktop/charts/SelectAxisColor";
import RangeSlider from "../../../../shared/inputs/RangeSlider";
import {barSizes} from "../../../../desktop/charts/ChartBarSize";
import EditChartTable from "../../../../desktop/charts/editChartData/EditChartTable";
import EditRotate from "../common/EditRotate";
import ZIndex from "../../../../shared/ZIndex";
import ApplyAnimations from "../../../../desktop/animations/ApplyAnimations";
import ShowAxis from "../../../../desktop/charts/ShowAxis";

const ChartDrawers = ({
  drawerType,
  slideID,
  activeField,
  updateFieldStyles,
}) => {
  let displayView = null;
  const {styles} = activeField || {};

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const onUpdateBarWidth = (value) => {
    handleStyleUpdate("barSize", Number(value));
  };

  switch (drawerType) {
    case "edit-chart-info":
      displayView = <EditChartTable />;
      break;
    case "bar-width":
      displayView = (
        <RangeSlider
          title="Bar Width"
          fieldType="barWidth"
          value={styles?.barSize}
          max={barSizes[barSizes.length - 1]?.value}
          min={barSizes[0]?.value}
          step={1}
          onItemChange={onUpdateBarWidth}
        />
      );
      break;
    case "axis":
      displayView = 
        <>
          <ShowAxis visible={styles?.showXAxis} label="X-Axis" fieldValue="showXAxis" />
          <div style={{ marginBottom: 20 }} />
          
          <ShowAxis visible={styles?.showYAxis} label="Y-Axis" fieldValue="showYAxis" />
          <div style={{ marginBottom: 20 }} />

          <SelectAxisColor value={styles?.axisColor} />
        </>
      break;
    case "opacity":
      displayView = <EditOpacity value={styles?.opacity} />;
      break;
    case "grid":
      displayView = (
        <Wrapper>
          <EditChartGrid
            showGrid={styles?.showGrid}
            gridColor={styles?.gridColor}
            gridStyle={styles?.gridStyle}
          />
        </Wrapper>
      );
      break;
    case "values":
      displayView = (
        <Wrapper>
          <EditChartValue
            fontSize={styles?.fontSize}
            valueColor={styles?.valueColor}
            showValue={styles?.showValue}
            scale={styles?.scale}
          />
        </Wrapper>
      );
      break;
    case "categories":
      displayView = (
        <Wrapper>
          <EditChartCategories
            showCategory={styles?.showCategory}
            categoryColor={styles?.categoryColor}
            categoryFontSize={styles?.categoryFontSize}
            scale={styles?.scale}
          />
        </Wrapper>
      );
      break;
    case "measurements":
      displayView = (
        <Wrapper>
          <EditChartMeasurement
            showMeasurement={styles?.showMeasurement}
            measurementColor={styles?.measurementColor}
            measurementFontSize={styles?.measurementFontSize}
            scale={styles?.scale}
          />
        </Wrapper>
      );
      break;
    case "background":
      displayView = (
        <Wrapper>
          <ChartBackground
            background={styles?.background}
            showBackground={styles?.showBackground}
          />
        </Wrapper>
      );
      break;
    case "legend":
      displayView = (
        <Wrapper>
          <EditChartLegend
            legendColor={styles?.legendColor}
            legendFontSize={styles?.legendFontSize}
            legendPosition={styles?.legendPosition}
            showLegend={styles?.showLegend}
            scale={styles?.scale}
          />
        </Wrapper>
      );
      break;
    case "rotate":
      displayView = <EditRotate value={styles?.rotate} />;
      break;
    case "layer":
      displayView = <ZIndex />;
      break;
    case "animation":
      displayView = <ApplyAnimations />;
      break;
    default:
      displayView = "";
  }
  return <DrawerLayout>{displayView}</DrawerLayout>;
};

const Wrapper = styled.div`
  padding-bottom: 2rem;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    stableStyles: selectCachedFieldStyles(designTemplate),
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(ChartDrawers);
