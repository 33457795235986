import React, {useState} from "react";
import styled from "styled-components";
import OatAllFontList from "../../../../desktop/common/fontFamily/OatAllFontList";
import {selectBuildingTheme} from "../../../../../../../../store/selectors/template/theme.selector";
import {connect} from "react-redux";
import {storeFieldAsActiveUndoRedo} from "../../../../../../../../store/actions/fields/undoRedo.action";
import MyFontList from "../../../../desktop/common/fontFamily/MyFontList";

const FontFamilyList = ({
  field,
  buildingTheme,
  onSelect,
  storeFieldAsActiveUndoRedo,
}) => {
  const [tab, setTab] = useState("googleFonts"); // or workspaceFonts
  const {styles} = field || {};

  const onSelectAllFonts = () => {
    setTab("googleFonts");
  };

  const onSelectWsFonts = () => {
    setTab("workspaceFonts");
  };

  const onSelectFont = (value) => {
    onSelect(value);
    if (!buildingTheme) {
      storeFieldAsActiveUndoRedo();
    }
  };

  return (
    <>
      <Tabs>
        <Tab active={tab === "googleFonts"} onClick={onSelectAllFonts}>
          Google Fonts
        </Tab>
        <Tab active={tab === "workspaceFonts"} onClick={onSelectWsFonts}>
          Workspace Fonts
        </Tab>
      </Tabs>

      {tab === "googleFonts" ? (
        <OatAllFontList
          selected={styles?.fontFamily}
          onSelectFont={onSelectFont}
          fontWeight={styles?.fontWeight}
          fontStyle={styles?.fontStyle}
        />
      ) : (
        <MyFontList onSelectFont={onSelectFont} selected={styles?.fontFamily} />
      )}
    </>
  );
};

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  flex: 1;
  padding: 7px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background: ${({theme, active}) =>
    active ? theme.deskSecondaryBg : undefined};
`;

const mapStateToProps = (state) => {
  return {
    buildingTheme: selectBuildingTheme(state.designTemplate),
  };
};

export default connect(mapStateToProps, {storeFieldAsActiveUndoRedo})(
  FontFamilyList
);
