import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import ViewOnlyFields from "../../components/editDesign/canvasPanel/viewOnlyFields/ViewOnlyFields";
import {changeSlide} from "../../store/actions/template/slide.action";
import {connect} from "react-redux";
import {getItemXOffset} from "../../utils/getItemOffset";

export const tempPaddingTop = 0, //14, //0 for screenshot pdf ..window.innerWidth < 850 ? 20 : 20,
  tempPaddingBtm = 0,
  mobTopPanel = 55,
  mobBtmPanel = 50;
const ShowCanvas = ({
  canvasSize,
  slide,
  scale,
  changeSlide,
  activeSlideIndex,
  canvasIndex,
  lastSlide,
  enableAnimation,
  activeSlide
}) => {
  const width = canvasSize.w;
  const height = canvasSize.h;
  let params = useParams();
  const screenshot = params.screenshot == "true";
  const {bg} = slide;
  const [touchXOffset, setTouchXOffset] = useState({
    start: 0,
    end: 0,
    swiping: false,
  });

  const activateOnTouchSlideBg = (e) => {
    const clientX = getItemXOffset(e, 1);
    setTouchXOffset({
      ...touchXOffset,
      start: clientX,
      swiping: true,
    });
  };

  const handleTouchEnd = (e) => {
    const offset = e.changedTouches[e.changedTouches.length - 1].clientX;
    setTouchXOffset({
      ...touchXOffset,
      end: offset,
      swiping: false,
    });
    const swipedRange = touchXOffset.start - offset;
    if (swipedRange > 50 && !lastSlide) {
      changeSlide(activeSlideIndex + 1);
    } else if (swipedRange < -50 && activeSlideIndex !== 0) {
      changeSlide(activeSlideIndex - 1);
    }
  };

  return (
    <Template
      style={{
        width: width * scale,
        height: height * scale + tempPaddingTop,
      }}
      onTouchStart={activateOnTouchSlideBg}
      onTouchEnd={handleTouchEnd}
    >
      <Layer
        style={{
          width,
          height,
          transform: `scale(${scale})`,
          overflow: "hidden",
        }}
      >
        <ZoomTemplate
          width={width}
          height={height}
          style={{
            boxShadow: !screenshot
              ? "#8688a926 0px 0px 0px 1px inset"
              : undefined,
            background: !bg.gradient
              ? bg.bg1
              : bg.type !== "center"
              ? `linear-gradient(${bg.bgAng}deg, ${bg.bg1}, ${bg.bg2})`
              : `radial-gradient(${bg.bg1}, ${bg.bg2})`,
          }}
          id={screenshot ? `snapshot-oat-design-${canvasIndex}` : undefined}
        >
          <WrapCard>
            {slide && slide.fields
              ? Object.entries(slide.fields).map(([id, field]) => (
                  <ViewOnlyFields
                    key={id}
                    field={field}
                    slideID={slide.id}
                    enableUserSelect={true}
                    enableAnimation={enableAnimation}
                    activeSlide={activeSlide}
                    activeSlideIndex={activeSlideIndex}
                  />
                ))
              : undefined}
          </WrapCard>
        </ZoomTemplate>
      </Layer>
    </Template>
  );
};

export const ZoomTemplate = styled.div`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  outline: none;
  text-align: center;
  position: relative; //
  touch-action: none;
  overflow: hidden; //
  display: flex;
  box-sizing: border-box;
  transform-origin: top left;
  border-radius: ${({templateShape}) =>
    templateShape === "circle" ? "50%" : "0%"};
`;

export const Template = styled.div`
  position: relative;
  margin: ${({templateShape}) =>
    templateShape === "circle" ? "10px 0" : "0px"};
  box-sizing: border-box;
  // transition: all 0.2s ease;
  @media only screen and (min-width: 850px) {
    // overflow: scroll; // maybe only for scrollable contents in the future?
    overflow: hidden;
  }
`;

export const WrapCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const DragOverSelect = styled.div`
  border: 1px solid #dedede;
  position: absolute;
  background: rgba(157, 152, 237, 0.5);
  z-index: 5000;
`;

const Layer = styled.div`
  background: #fff;
  transform-origin: top left;
  // margin-top: ${tempPaddingTop}px;
`;

export const DragLayer = styled.div``;
export const ResizeLayer = styled.div``;

ShowCanvas.defaultProps = {
  panelWidth: 60,
};

export default connect(null, {changeSlide})(ShowCanvas);
