import React, {useState} from "react";
import {SplitLine} from "../common/SplitLine";
import ChartBackground from "./ChartBackground";
import EditChartLegend from "./EditChartLegend";
import EditChartGrid from "./reuse/EditChartGrid";
import EditChartValue from "./EditChartValue";
import DonutChartGap from "./DonutChartGap";
import EditChartCategories from "./EditChartCategories";
import EditAllChartLabels from "./EditAllChartLabels";
import EditChartMeasurement from "./EditChartMeasurement";
import EditOpacity from "../common/EditOpacity";
import ChartBarSize from "./ChartBarSize";
import ShowAxis from "./ShowAxis";
import SelectAxisColor from "./SelectAxisColor";
import EditChartLine from "./lineChart/EditChartLine";
import EditChartDot from "./lineChart/EditChartDot";
import styled from "styled-components";
import ChangeChartType from "./reuse/ChangeChartType";
import {connect} from "react-redux";
import {onViewChartInfo} from "../../../../../../store/actions/fields/chart.action";
import {selectEditChartInfoStatus} from "../../../../../../store/selectors/fields.selector";

const SelectedChartPanel = ({
  openEditChartInfo,
  styles,
  chartType,
  onViewChartInfo,
}) => {
  let displayPanel = null;
  // const [openEditChartInfo, setOpenEditChartInfo] = useState();

  const {
    fontSize,
    fontFamily,
    fontStyle,
    fontWeight,
    valueColor,
    showValue,
    showLegend,
    legendColor,
    legendFontSize,
    legendPosition,
    showCategory,
    categoryColor,
    categoryFontSize,
    showGrid,
    background,
    gridColor,
    showBackground,
    showMeasurement,
    measurementColor,
    measurementFontSize,
    barSize,
    showXAxis,
    showYAxis,
    axisColor,
    lineSize,
    showDots,
    dotSize,
    gridStyle,
    scale,
  } = styles || {};

  const onToggleChartInfo = (e) => {
    onViewChartInfo();
  };

  if (
    chartType === "column-chart" ||
    chartType === "bar-chart" ||
    chartType === "stacked-column-chart"
  ) {
    displayPanel = (
      <>
        <ChangeChartType chartType={chartType} />
        <SplitLine />

        <EditChartInfo onClick={onToggleChartInfo}>
          Edit Chart Info
        </EditChartInfo>
        {/* <WrapScroll>
          Scrollable
          <WrapSvg>
            <svg viewBox="0 0 24 24">
              <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
            </svg>
          </WrapSvg>
        </WrapScroll> */}
        <SplitLine />

        <ChartBarSize barSize={barSize} />
        <SplitLine />

        <Wrapper>
          <ShowAxis visible={showXAxis} label="X-Axis" fieldValue="showXAxis" />
        </Wrapper>
        <div>
          <ShowAxis visible={showYAxis} label="Y-Axis" fieldValue="showYAxis" />
        </div>
        {showXAxis || showYAxis ? (
          <div style={{paddingTop: "2em"}}>
            <SelectAxisColor value={axisColor} />
          </div>
        ) : undefined}
        <SplitLine />

        <EditAllChartLabels
          fontSize={fontSize}
          fontFamily={fontFamily}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
          scale={scale}
        />
        <SplitLine />

        <EditChartValue
          fontSize={fontSize}
          valueColor={valueColor}
          showValue={showValue}
          scale={scale}
        />
        <SplitLine />

        <EditChartCategories
          showCategory={showCategory}
          categoryColor={categoryColor}
          categoryFontSize={categoryFontSize}
          scale={scale}
        />
        <SplitLine />

        <EditChartMeasurement
          showMeasurement={showMeasurement}
          measurementColor={measurementColor}
          measurementFontSize={measurementFontSize}
          scale={scale}
        />
        <SplitLine />

        <ChartBackground
          background={background}
          showBackground={showBackground}
        />
        <SplitLine />

        <EditChartLegend
          legendColor={legendColor}
          legendFontSize={legendFontSize}
          legendPosition={legendPosition}
          showLegend={showLegend}
          scale={scale}
        />
        <SplitLine />

        <EditChartGrid
          showGrid={showGrid}
          gridColor={gridColor}
          gridStyle={gridStyle}
        />
        <SplitLine />

        <EditOpacity value={styles?.opacity} />
      </>
    );
  } else if (
    chartType === "line-chart" ||
    chartType === "area-chart" ||
    chartType === "stacked-area-chart"
  ) {
    displayPanel = (
      <>
        <ChangeChartType chartType={chartType} />
        <SplitLine />

        <EditChartInfo onClick={onToggleChartInfo}>
          Edit Chart Info
        </EditChartInfo>
        <SplitLine />

        <Wrapper>
          <EditChartLine value={lineSize} />
        </Wrapper>
        <EditChartDot showDots={showDots} dotSize={dotSize} />
        <SplitLine />

        <Wrapper>
          <ShowAxis visible={showXAxis} label="X-Axis" fieldValue="showXAxis" />
        </Wrapper>
        <div>
          <ShowAxis visible={showYAxis} label="Y-Axis" fieldValue="showYAxis" />
        </div>
        {showXAxis || showYAxis ? (
          <div style={{paddingTop: "2em"}}>
            <SelectAxisColor value={axisColor} />
          </div>
        ) : undefined}
        <SplitLine />

        <EditAllChartLabels
          fontSize={fontSize}
          fontFamily={fontFamily}
          scale={scale}
        />
        <SplitLine />

        <EditChartValue
          fontSize={fontSize}
          valueColor={valueColor}
          showValue={showValue}
          scale={scale}
        />
        <SplitLine />

        <EditChartCategories
          showCategory={showCategory}
          categoryColor={categoryColor}
          categoryFontSize={categoryFontSize}
          scale={scale}
        />
        <SplitLine />

        <EditChartMeasurement
          showMeasurement={showMeasurement}
          measurementColor={measurementColor}
          measurementFontSize={measurementFontSize}
          scale={scale}
        />
        <SplitLine />

        <ChartBackground
          background={background}
          showBackground={showBackground}
        />
        <SplitLine />

        <EditChartLegend
          legendColor={legendColor}
          legendFontSize={legendFontSize}
          legendPosition={legendPosition}
          showLegend={showLegend}
          scale={scale}
        />
        <SplitLine />

        <EditChartGrid
          showGrid={showGrid}
          gridColor={gridColor}
          gridStyle={gridStyle}
        />
        <SplitLine />

        <EditOpacity value={styles?.opacity} />
      </>
    );
  } else if (
    chartType === "pie" ||
    chartType === "donut" ||
    chartType === "gauge"
  ) {
    displayPanel = (
      <>
        <ChangeChartType chartType={chartType} />
        <SplitLine />

        <EditChartInfo onClick={onToggleChartInfo}>
          Edit Chart Info
        </EditChartInfo>
        <SplitLine />

        <EditAllChartLabels fontSize={fontSize} fontFamily={fontFamily} />
        <SplitLine />

        <EditChartValue
          fontSize={fontSize}
          valueColor={valueColor}
          showValue={showValue}
        />
        <SplitLine />

        {chartType === "donut" ? (
          <DonutChartGap gapSize={styles?.donutGap} />
        ) : undefined}
        {chartType === "donut" ? <SplitLine /> : undefined}

        <EditChartLegend
          legendColor={legendColor}
          legendFontSize={legendFontSize}
          legendPosition={legendPosition}
          showLegend={showLegend}
        />
        <SplitLine />

        <EditOpacity value={styles?.opacity} />
      </>
    );
  }

  return displayPanel;
};

const EditChartInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 6px;
  color: #fff;
  background: ${({theme}) => theme.deskButton};
  margin: 0 1rem;
  cursor: pointer;
  box-shadow: ${({theme}) => theme.boxShadow1};
`;

const Wrapper = styled.div`
  padding-bottom: 2em;
`;

const mapStateToProps = (state) => {
  return {
    openEditChartInfo: selectEditChartInfoStatus(state.designTemplate),
  };
};

export default connect(mapStateToProps, {onViewChartInfo})(SelectedChartPanel);
