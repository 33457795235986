import React, {useState, useEffect} from "react";
import {Refresh} from "../../../../../../oat-svg-icons";
import {Spinner} from "../../../../../../oat-ui";
import {Item, WrapSpinner, WrapSvg} from "./reuseStyles";

const DisplayItem = ({item, onSelect}) => {
  const handleClick = () => {
    onSelect(item);
  };
  return <Item onClick={handleClick}>{item.name}</Item>;
};

const WorkspaceList = ({username, onSelect}) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [fetching, setFetching] = useState({
    status: false,
    error: false,
  });

  const loadFolders = async () => {
    setFetching({
      status: true,
      error: false,
    });
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces`
    )
      .then((res) => res.json())
      .then((data) => {
        setWorkspaces(data);
        setFetching({
          status: false,
          error: false,
        });
      })
      .catch((e) =>
        setFetching({
          status: false,
          error: true,
        })
      );
  };

  useEffect(() => {
    loadFolders();
  }, []);

  const handleSelection = (workspace) => {
    onSelect(workspace);
  };

  const reloadFolders = () => {
    loadFolders();
  };

  useEffect(() => {
    loadFolders();
  }, []);

  return (
    <>
      {workspaces &&
        workspaces.map((item, i) => (
          <DisplayItem key={item.ID} item={item} onSelect={handleSelection} />
        ))}

      {fetching.status ? (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      ) : undefined}

      {fetching.error ? (
        <WrapSvg onClick={reloadFolders}>{Refresh}</WrapSvg>
      ) : undefined}
    </>
  );
};

export default WorkspaceList;
