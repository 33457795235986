import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {
  selectTemplateID,
  selectTemplateSize,
  selectTempScale,
} from "../../../../store/selectors/template/template.selector";
import {
  selectActiveField,
  selectActiveFieldProps,
  selectEnableTextEdit,
} from "../../../../store/selectors/fields.selector";
import {deselectActiveField} from "../../../../store/actions/fields/common.action";
import {
  getOriginalOffset,
  onInterceptFields,
} from "../../../../store/actions/fields/groupSelection.action";
import {calcGroupSelection} from "../dragResize/groupSelection/calcGroupSelection";
import {selectBackground} from "../../../../store/actions/fields/background.action";
import {WrapFrame, Card} from "./CanvasStyles";
import DragResize from "../dragResize/DragResize";
import {updateEditingPanel} from "../../../../store/actions/layout.action";
import ShowGroupSelection from "./ShowGroupSelection";
import {changeSlide} from "../../../../store/actions/template/slide.action";
import {getItemXOffset} from "../../../../utils/getItemOffset";
import {selectInterceptedFields, selectUsersOnLine} from "../../../../store/selectors/common.selector";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {useSocket} from "../../../../webSocket/useSocket";
const SAT = require('sat');

let fieldOffsets = []; // For multi select
let interceptedKeys = []; // For multi select

export const tempPaddingTop = 14, //14 //0 for screenshot pdf ..window.innerWidth < 850 ? 20 : 20,
  tempPaddingBtm = 0,
  mobTopPanel = 55,
  mobBtmPanel = 50;
const Canvas = ({
  canvasSize,
  slide,
  activeSlide,
  canvasIndex,
  updateEditingPanel,
  selectBackground,
  getOriginalOffset,
  onInterceptFields,
  tempScale,
  textEditing,
  changeSlide,
  lastSlide,
  usersOnline,
  username,
  tempID,
  isGroupSelectActive
}) => {
  const width = canvasSize?.w;
  const height = canvasSize?.h;
  const {bg} = slide || {};
  const [groupSelect, setGroupSelect] = useState({
    draggingOver: false,
    rectOffset: {left: 0, top: 0},
    positions: {orgX: 0, orgY: 0, xOffset: 0, yOffset: 0},
    overlaySize: {w: 0, h: 0},
  });
  // change slide on swipe
  const [touchXOffset, setTouchXOffset] = useState({
    start: 0,
    end: 0,
  });
  const {socket} = useSocket() || {};
  const {draggingOver, rectOffset, positions, overlaySize} = groupSelect;

  const onDragStart = (e) => {
    if (activeSlide) {
      const rect = e.currentTarget.getBoundingClientRect();
      const {left, top} = rect;
      const orgX = (e.clientX - left) / tempScale;
      const orgY = (e.clientY - top) / tempScale;
      setGroupSelect({
        draggingOver: true,
        rectOffset: {top, left},
        positions: {...positions, orgX: orgX, orgY: orgY},
        overlaySize: {w: 0, h: 0},
      });
      onInterceptFields([]);
      interceptedKeys = [];
      fieldOffsets = [];
      let fieldAlreadySelected = false;

      slide &&
        Object.entries(slide?.fields).map(([id, field]) => {
          if (!field?.deleted && !field?.styles?.lock) {
            fieldAlreadySelected = false;
            if (Object.keys(usersOnline?.users).length > 0) {
              Object.entries(usersOnline?.users).map(([username, item]) => {
                if (usersOnline?.users[username]?.groupSelection?.keys.includes(id) || usersOnline?.users[username]?.activeField === id) {
                  fieldAlreadySelected = true;
                } 
              })
            }

            if (!fieldAlreadySelected) {
              fieldOffsets.push({
                topLeftX: field?.rotatedOffsets?.topLeft?.x 
                  ? field?.rotatedOffsets?.topLeft?.x
                  : field?.pos?.x,
                topLeftY: field?.rotatedOffsets?.topLeft?.y 
                  ? field?.rotatedOffsets?.topLeft?.y
                  : field?.pos?.y,
                topRightX: field?.rotatedOffsets?.topRight?.x 
                  ? field?.rotatedOffsets?.topRight?.x 
                  : field?.pos?.x + field?.size?.w,
                topRightY: field?.rotatedOffsets?.topRight?.y 
                  ? field?.rotatedOffsets?.topRight?.y 
                  : field?.pos?.y,
                btmLeftX: field?.rotatedOffsets?.btmLeft?.x 
                  ? field?.rotatedOffsets?.btmLeft?.x
                  : field?.pos?.x,
                btmLeftY: field?.rotatedOffsets?.btmLeft?.y 
                  ? field?.rotatedOffsets?.btmLeft?.y
                  : field?.pos?.y + field.size?.h,
                btmRightX: field?.rotatedOffsets?.btmRight?.x 
                  ? field?.rotatedOffsets?.btmRight?.x 
                  : field?.pos?.x + field?.size?.w,
                btmRightY: field?.rotatedOffsets?.btmRight?.y 
                  ? field?.rotatedOffsets?.btmRight?.y 
                  : field?.pos?.y + field?.size?.h,
                key: field?.key,
                groupKeys: field?.groupAsOne?.keys ? field?.groupAsOne?.keys : [],
                type: field?.type,
                subtype: field?.subtype,
                rotate: field?.styles?.rotate,
                width: field?.size?.w,
                height: field?.size?.h
              });
            }
          }
        });
    }
  };

  const onDragOver = useCallback((e) => {
    if (groupSelect.draggingOver) {
      const {orgX, orgY} = positions || {};
      const xPos = (e.clientX - rectOffset?.left) / tempScale;
      const yPos = (e.clientY - rectOffset?.top) / tempScale;
      const dragOverStartX = Math.min(orgX, xPos);
      const x4 = Math.max(orgX, xPos);
      const dragOverStartY = Math.min(orgY, yPos);
      const y4 = Math.max(orgY, yPos);
      const getOrginalOffsets = {
        ...positions,
        xOffset: dragOverStartX,
        yOffset: dragOverStartY,
      };
      const calcOverlaySize = {
        w: x4 - dragOverStartX,
        h: y4 - dragOverStartY,
      };
      setGroupSelect({
        ...groupSelect,
        positions: getOrginalOffsets,
        overlaySize: calcOverlaySize,
      });
      const dragOverEndX = dragOverStartX + x4 - dragOverStartX;
      const dragOverEndY = dragOverStartY + y4 - dragOverStartY;

      fieldOffsets && fieldOffsets.map((offset) => {
        const fieldCorners = {
          topLeft: { x: offset.topLeftX, y: offset.topLeftY },
          topRight: { x: offset.topRightX, y: offset.topRightY },
          bottomLeft: { x: offset.btmLeftX, y: offset.btmLeftY },
          bottomRight: { x: offset.btmRightX, y: offset.btmRightY },
        }

        const rotatedFieldPolygon = new SAT.Polygon(new SAT.Vector(), [
          new SAT.Vector(fieldCorners.topLeft.x, fieldCorners.topLeft.y),
          new SAT.Vector(fieldCorners.topRight.x, fieldCorners.topRight.y),
          new SAT.Vector(fieldCorners.bottomRight.x, fieldCorners.bottomRight.y),
          new SAT.Vector(fieldCorners.bottomLeft.x, fieldCorners.bottomLeft.y),
        ]);
        
        const groupDragPolygon = new SAT.Box(
          new SAT.Vector(Math.min(dragOverStartX, dragOverEndX), Math.min(dragOverStartY, dragOverEndY)),
          Math.abs(dragOverEndX - dragOverStartX),
          Math.abs(dragOverEndY - dragOverStartY)
        ).toPolygon();
        
        // Check for intersection
        const response = new SAT.Response();
        const intersection = SAT.testPolygonPolygon(rotatedFieldPolygon, groupDragPolygon, response);
      
        if (intersection) {
          if (
            !interceptedKeys.includes(offset?.key) &&
            !usersOnline.selectedIDs.includes(offset?.key)
          ) {
            interceptedKeys.push(offset?.key);
            interceptedKeys.push(...offset?.groupKeys);
            onInterceptFields(interceptedKeys);
          }
        } else if (interceptedKeys.includes(offset.key)) {
          interceptedKeys = interceptedKeys.filter(function (key) {
            return key !== offset?.key;
          });
          interceptedKeys = Array.from(new Set([...interceptedKeys, ...offset.groupKeys]));
          onInterceptFields(interceptedKeys);
        }
      });
      e.preventDefault();
      e.stopPropagation();
    }
  }, [groupSelect?.positions, tempScale, groupSelect.draggingOver]);

  const onDragStop = (e) => {
    setGroupSelect({
      ...groupSelect,
      draggingOver: false,
    });
    const {size, offset, initValues} = calcGroupSelection(
      slide,
      interceptedKeys
    );
    if (interceptedKeys.length > 0) {
      getOriginalOffset(size, offset, initValues);
    }
    if (
      Object.keys(usersOnline.users).length > 0 &&
      interceptedKeys.length > 0
    ) {
      socket?.current?.send(
        JSON.stringify({
          action: "selectedGroupedItems",
          tempID,
          payload: {
            username,
            slideID: slide.id,
            keys: interceptedKeys,
            size: {
              w: size?.width,
              h: size?.height,
            },
            offset,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (draggingOver === true && activeSlide) {
      document.addEventListener("mousemove", onDragOver);
      document.addEventListener("mouseup", onDragStop);
    } else {
      document.removeEventListener("mousemove", onDragOver);
      document.removeEventListener("mouseup", onDragStop);
    }
    return () => {
      document.removeEventListener("mousemove", onDragOver);
      document.removeEventListener("mouseup", onDragStop);
    };
  }, [draggingOver, activeSlide, slide.fields]);

  const handleMouseDown = useCallback((e) => {
    if (!textEditing && !isGroupSelectActive && activeSlide) {
      onDragStart(e);
      // e.stopPropagation();
      // if (activeField !== -1 || groupedItems.status) {
      //   deselectActiveField();
      // }
    } else {
      // enableTextEdit();
      // deselectActiveField();
      // if (activeField !== -1) {
      //   deselectActiveField();
      // }
      // e.stopPropagation();
    }
  },[textEditing, isGroupSelectActive, activeSlide, tempScale, slide.fields]);

  // need to reflect on ShowCanvas.js
  const activateOnTouchSlideBg = (e) => {
    const clientX = getItemXOffset(e, 1);
    setTouchXOffset({
      ...touchXOffset,
      start: clientX,
      swiping: true,
    });

    if (e.clientX) {
      selectBackground();
      const timeout = setTimeout(() => {
        updateEditingPanel(50, "background");
      }, 50);
      return () => clearTimeout(timeout);
    }
  };

  const handleTouchEnd = (e) => {
    const offset = e.changedTouches[e.changedTouches.length - 1].clientX;
    setTouchXOffset({
      ...touchXOffset,
      end: offset,
      swiping: false,
    });
    const swipedRange = touchXOffset?.start - offset;
    // if (swipedRange > 50 && !lastSlide) {
    //   changeSlide(canvasIndex + 1);
    // } else if (swipedRange < -50 && canvasIndex !== 0) {
    //   changeSlide(activeSlide - 1);
    // } else {
    // selectBackground();
    // setTimeout(() => {
    //   updateEditingPanel(50, "background");
    // }, 50);
    // }
  };

  return (
    <Template
      style={{
        width: Math.floor(width * tempScale),
        height: Math.floor(height * tempScale),
      }}
    >
      {/* // pdf testing purpose */}
      {/* <div
        style={{
          background: bg.bg1,
          width: 980,
          height: 730,
        }}
      ></div> */}

      {activeSlide ? (
        <OverlayLayer>
          <ShowGroupSelection scale={tempScale} slide={slide} />
          <DragOverSelect
            style={{
              display: draggingOver ? "block" : "none",
              left: positions?.xOffset * tempScale,
              top: positions?.yOffset * tempScale,
              width: overlaySize?.w * tempScale,
              height: overlaySize?.h * tempScale,
              pointerEvents: 'none'
            }}
          />
        </OverlayLayer>
      ) : undefined}

      {/* for transparent colors */}
      <BackgroundLayer
        style={{
          width,
          height: height - 1, // + 1
          transform: `scale(${tempScale})`,
          boxSizing: "border-box",
          boxShadow: "#8688a926 0px 0px 0px 1px inset",
        }}
      />

      <Layer
        style={{
          width,
          height: height, // + 1
          transform: `scale(${tempScale})`,
          background: !bg.gradient
            ? bg.bg1
            : bg.type !== "center"
            ? `linear-gradient(${bg?.bgAng}deg, ${bg?.bg1}, ${bg?.bg2})`
            : `radial-gradient(${bg?.bg1}, ${bg?.bg2})`,
          zIndex: 0,
          position: "absolute",
        }}
      >
        <WrapFrame
          width={width}
          height={height} // -1 height
          // style={{
          //   boxSizing: "border-box",
          //   boxShadow: "#8688a926 0px 0px 0px 1px inset"
          // }}
          onMouseDown={handleMouseDown}
        >
          <Card
            style={{
              WebkitUserSelect: textEditing ? "auto" : "none",
              userSelect: textEditing ? "auto" : "none",
              fontSize: 16, //24
            }}
            // onTouchStart={activateOnTouchSlideBg}
            // onTouchEnd={handleTouchEnd}
          >
            <DragResize
              slide={slide}
              activeSlide={activeSlide}
              tempScale={tempScale}
              groupSelectActive={activeSlide && groupSelect.draggingOver}
              interceptedKeys={activeSlide && interceptedKeys}
            />
          </Card>
        </WrapFrame>
      </Layer>
    </Template>
  );
};

export const Template = styled.div`
  position: relative;
  margin: ${({templateShape}) =>
    templateShape === "circle" ? "10px 0" : "0px"};
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  @media only screen and (min-width: 850px) {
    // overflow: scroll; // maybe only for scrollable contents in the future?
    // overflow: hidden;
  }
`;

const OverlayLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const WrapCard = styled.div`
  position: relative;
  // user-select: none;
  pointer-events: auto;
  width: 100%;
  height: 100%;
`;

export const DragOverSelect = styled.div`
  border: 1px solid #dedede;
  position: absolute;
  background: rgba(157, 152, 237, 0.5);
  z-index: 5000;
`;

const Layer = styled.div`
  background: #fff;
  transform-origin: top left;
`;

const BackgroundLayer = styled.div`
  background: rgb(255, 255, 255);
  position: absolute;
  transform-origin: 0 0;
`;

export const DragLayer = styled.div``;
export const ResizeLayer = styled.div`
  position: relative;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    canvasSize: selectTemplateSize(designTemplate),
    tempScale: selectTempScale(designTemplate),
    textEditing: selectEnableTextEdit(designTemplate),
    activeField: selectActiveField(designTemplate),
    activeFieldProps: selectActiveFieldProps(designTemplate),
    usersOnline: selectUsersOnLine(designTemplate),
    username: selectUserInfo(state.authUser).username,
    tempID: selectTemplateID(designTemplate),
    isGroupSelectActive: selectInterceptedFields(designTemplate).selection?.show,
  };
};

export default connect(mapStateToProps, {
  deselectActiveField,
  updateEditingPanel,
  selectBackground,
  getOriginalOffset,
  onInterceptFields,
  changeSlide,
})(Canvas);
