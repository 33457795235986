import React from "react";
import styled from "styled-components";
import {WrapColorBox} from "../common/WrapColorStyles";
import {
  updateTextEffects,
  applyTextEffects,
} from "../../../../../../store/actions/fields/text.action";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const textEeffectProps = {
  blur: {
    min: 0,
    max: 1,
    step: 0.001,
  },
};

// loop
const DisplayColorBox = ({effect, updateEffects, index, onColorDebounce}) => {
  const handleClick = (value) => {
    updateEffects(value, index);
  };

  const handleDebounce = (value) => {
    onColorDebounce(value, index);
  };

  return (
    <div style={{marginRight: 25}}>
      <PopoverColorBox
        value={effect.c}
        updateFieldStyles={handleClick}
        onDebounce={handleDebounce}
      />
    </div>
  );
};

const SelectedTxtEffects = ({
  slideID,
  item,
  type,
  storeFieldUndoRedo,
  updateTextEffects,
  updateFieldCollaboration,
}) => {
  let selectedType = null;
  const {effects} = item || {};
  const active = {slideID, fieldID: item?.key};

  const handleBlurUpdate = (value) => {
    const payload = {
      active,
      key: "b",
      value,
      arrKey: 0,
      arrType: false,
    };
    updateTextEffects({...payload});
  };

  // single type
  const handleSingleShadowColor = (value) => {
    const payload = {
      active,
      key: "c",
      value,
      arrKey: 0,
      doubleShadow: false,
    };
    updateTextEffects({...payload});
  };

  const onSingleColorDebounce = (value, index) => {
    const payload = {
      active,
      key: "c",
      value,
      arrKey: 0,
      doubleShadow: false,
    };
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  // array type
  const handleDoubleShadowColor = (value, index) => {
    const payload = {
      active,
      key: "c",
      value,
      arrKey: index,
      doubleShadow: true,
    };
    updateTextEffects({...payload});
  };

  const onDoubleColorDebounce = (value, index) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  switch (type) {
    case "single-shadow":
      selectedType = (
        <>
          {/* <div style={{padding: "1rem 0 2rem"}}>
            <RangeSlider
              title="Direction"
              value={effects.shadow[0].h}
              max={10}
              min={-10}
              step={1}
              stableValue={effects.shadow[0].h}
              onItemChange={(value) => updateTextEffects("h", value, 0)}
            />
          </div>
          <div style={{paddingBottom: "2rem"}}>
            <RangeSlider
              title="Angle"
              value={effects.shadow[0].v}
              max={10}
              min={-10}
              step={1}
              stableValue={effects.shadow[0].v}
              onItemChange={(value) => updateTextEffects("v", value, 0)}
            />
          </div> 
          <div style={{paddingBottom: "2rem"}}>
            <RangeSlider
              title="Blur"
              value={effects.shadow[0].b}
              max={textEeffectProps.blur.max}
              min={textEeffectProps.blur.min}
              step={textEeffectProps.blur.step}
              stableValue={effects.shadow[0].b}
              onItemChange={handleBlurUpdate}
            />
          </div>*/}
          <ColorsSection style={{paddingBottom: "2rem"}}>
            <Title>Colors</Title>
            <WrapColorBox>
              {effects.shadow.map((effect, i) => (
                <DisplayColorBox
                  key={i}
                  index={i}
                  effect={effect}
                  updateEffects={handleSingleShadowColor}
                  onColorDebounce={onDoubleColorDebounce}
                />
              ))}
            </WrapColorBox>
          </ColorsSection>
        </>
      );
      break;
    case "double-shadow":
      selectedType = (
        <ColorsSection style={{padding: "0 0 2rem"}}>
          <Title>Colors</Title>
          <WrapColorBox>
            {effects.shadow.map((effect, i) => (
              <DisplayColorBox
                key={i}
                index={i}
                effect={effect}
                updateEffects={handleDoubleShadowColor}
                onColorDebounce={onDoubleColorDebounce}
              />
            ))}
          </WrapColorBox>
        </ColorsSection>
      );
      break;
    case "stroke":
      selectedType = "";
      break;
    case "shadow-stroke":
      selectedType = (
        <>
          <div style={{padding: "0 0 2rem"}}>
            <RangeSlider
              title="Blur"
              value={effects.shadow[0]?.b}
              max={textEeffectProps.blur.max}
              min={textEeffectProps.blur.min}
              step={textEeffectProps.blur.step}
              stableValue={effects.shadow[0]?.b}
              onItemChange={handleBlurUpdate}
            />
          </div>
          <ColorsSection style={{paddingBottom: "2rem"}}>
            <Title>Colors</Title>
            <WrapColorBox>
              {effects.shadow.map((effect, i) => (
                <DisplayColorBox
                  key={i}
                  index={i}
                  effect={effect}
                  updateEffects={handleDoubleShadowColor}
                  onColorDebounce={onDoubleColorDebounce}
                />
              ))}
            </WrapColorBox>
          </ColorsSection>
        </>
      );
      break;
    case "glow":
      selectedType = (
        <>
          <div style={{padding: "0 0 2rem"}}>
            <RangeSlider
              title="Blur"
              value={effects.shadow[0]?.b}
              max={textEeffectProps.blur.max}
              min={textEeffectProps.blur.min}
              step={textEeffectProps.blur.step}
              stableValue={effects.shadow[0]?.b}
              onItemChange={handleBlurUpdate}
            />
          </div>
          <ColorsSection style={{paddingBottom: "2rem"}}>
            <Title>Colors</Title>
            <WrapColorBox>
              {effects.shadow.map((effect, i) => (
                <DisplayColorBox
                  key={i}
                  index={i}
                  effect={effect}
                  updateEffects={handleDoubleShadowColor}
                  onColorDebounce={onDoubleColorDebounce}
                />
              ))}
            </WrapColorBox>
          </ColorsSection>
        </>
      );
      break;
    case "txt-bg":
      selectedType = (
        <div>
          {/* <div style={{padding: "1rem 0 2rem"}}>
            <RangeSlider
              title="Stroke Width"
              value={effects.shadow[0].h}
              max={1.5}
              min={0.1}
              step={0.1}
              stableValue={effects.shadow[0].h}
              onItemChange={(value) => {
                updateTextEffects("h", value);
                updateTextEffects("v", value);
              }}
            />
          </div> */}
          <ColorsSection style={{paddingBottom: "2rem"}}>
            <Title>Colors</Title>
            <WrapColorBox>
              <div style={{marginRight: 25}}>
                <PopoverColorBox
                  value={effects.shadow[0]?.c}
                  updateFieldStyles={handleSingleShadowColor}
                  onDebounce={onSingleColorDebounce}
                />
              </div>
            </WrapColorBox>
          </ColorsSection>
        </div>
      );
      break;
    case "thick-bg":
      selectedType = (
        <div>
          <ColorsSection style={{paddingBottom: "2rem"}}>
            <Title>Colors</Title>
            <WrapColorBox>
              <div style={{marginRight: 25}}>
                <PopoverColorBox
                  value={effects.shadow[0]?.c}
                  updateFieldStyles={handleSingleShadowColor}
                  onDebounce={onSingleColorDebounce}
                />
              </div>
            </WrapColorBox>
          </ColorsSection>
        </div>
      );
      break;
    default:
      selectedType = "";
  }
  return <div style={{margin: "1rem 0 2rem"}}>{selectedType}</div>;
};

const ColorsSection = styled.div`
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state) => {
  return {
    item: selectActiveFieldProps(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateTextEffects,
  updateFieldStyles,
  applyTextEffects,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(SelectedTxtEffects);
