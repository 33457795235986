import React from "react";
import styled from "styled-components";

const ProfileCover = ({cover}) => {
  return (
    <Wrapper>
      <Image loading="lazy" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background: #cedede;
  border-radius: 6px;
  overflow: hidden;
  @media only screen and (min-width: 1600px) {
    height: 300px;
  }
  @media only screen and (max-width: 1599px) {
    height: 300px;
  }
  @media only screen and (max-width: 768px) {
    height: 240px;
  }
  @media only screen and (max-width: 600px) {
    height: 194px;
  }
`;

const Image = styled.div`
  max-width: 100%;
  height: 100%;
  background-image: url("https://images.unsplash.com/photo-1618855576332-06f46cc329a2?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
`;

export default ProfileCover;
