import styled from "styled-components";

export const ChartActions = styled.div`
  font-size: 0.85rem;
  font-weight: 600;
  background: ${({theme, removeStatus}) =>
    removeStatus ? theme.deskButton : undefined};
  border-radius: 6px;
  padding: 8px 12px;
  @media only screen and (min-width: 850px) {
    margin-right: 1.4rem;
  }
`;

export const RemoveRow = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ee7878;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({disabled}) => (!disabled ? 1 : 0.3)};
  cursor: ${({disabled}) => (!disabled ? "pointer" : "not-allowed")};
`;

export const Minus = styled.div`
  width: 40%;
  height: 3px;
  background: #fff;
`;
