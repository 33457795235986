import React, {useRef, useState, useCallback, useLayoutEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {Spinner} from "../../../../../oat-ui";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import {fetchDesignList} from "../../../../store/actions/template/create/designList.action";
import {fetchOatTemplateList, fetchUserTempForSelection} from "../../../../store/actions/template/create/templateList.action";
import {
  selectTemplateCategory,
  selectTemplateRatio,
  selectTemplateSelection,
  selectWorkspaceID,
  selectWorkspacePlan,
} from "../../../../store/selectors/template/template.selector";
import { panelHeight } from "../SlideListPanel";
import { selectUserInfo } from "../../../../../../redux/user/authUser";
import { requiresUpgradeToProStyles } from "../../parts/upgradeToPro";

export const slideListPanelHeight = 530;
const DisplayItem = ({item, size, userMadeTemplates, onSelect, workspacePlan}) => {
  const handleClick = () => {
    if (item?.requiresUpgrade != true || workspacePlan === 'pro') {
      onSelect(userMadeTemplates ? "temp-" + item.tempSourceID : item.tempID);
    } else {
      onSelect(null);
    }
  };

  return (
    <WrapItem
      style={{
        width: size.width,
        height: size.height,
        position: 'relative'
      }}
    >
      <Image
        userMadeTemplates={userMadeTemplates}
        src={
          userMadeTemplates
            ? `https://oat-users.s3.amazonaws.com/${item.image}`
            : `https://d1nryft0rd5acr.cloudfront.net/${item.thumbs["small"]}`
        }
        onClick={handleClick}
        loading="lazy"
      />
      {item?.requiresUpgrade ?
        <RequiresUpgradeToPro>
          Upgrade
        </RequiresUpgradeToPro> :
      undefined}
    </WrapItem>
  );
};

const ChooseTemplateCollection = ({
  templates,
  userMadeTemplates,
  loading,
  fetchDesignList,
  tempSize,
  paginationToken,
  fetchOatTemplateList,
  fetchUserTempForSelection,
  ratio,
  category,
  noOfColumns,
  user, 
  workspaceID,
  selection,
  selectedFolder,
  workspacePlan
}) => {
  const layoutRef = useRef();
  const [itemSize, setItemSize] = useState({
    width: 0,
    height: 0,
  });
  const [error, setError] = useState(false)
  const windowSize = getWindowSize();
  
  const chooseTemplate = (tempSourceID) => {
    if (tempSourceID) {
      setError(false)
      fetchDesignList({
        url: userMadeTemplates
          ? `https://oat-users.s3.amazonaws.com/${tempSourceID}.json`
          : `https://oat-admin-templates.s3.amazonaws.com/${tempSourceID}-main.json`,
        selectedTempID: tempSourceID,
      });
    } else {
      setError(true)
    }
  };

  useLayoutEffect(() => {
    if (layoutRef.current && templates && templates.length > 0) {
      // 24 - 12 gap (12 * 2)
      const getWidth = (layoutRef.current.offsetWidth - 24) / noOfColumns;
      setItemSize({
        width: getWidth,
        height: (tempSize.h * getWidth) / tempSize.w,
        //(templates[0].size.h * getWidth) / templates[0].size.w,
      });
    }
  }, [templates, windowSize.width, tempSize]);

  const observer = useRef();
  const lastItemRef = useCallback((node) => {
    if (!paginationToken || templates.length === 0) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && paginationToken) {
        if (selection?.userMadeTemplates) {
          const paginationParts = paginationToken.split("#");
          const timestamp = paginationParts[0]; // "1701019689486"
          const tempID = paginationParts[1]; 
          fetchUserTempForSelection({
            api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${workspaceID}&folderID=${selectedFolder}&paginationToken=${timestamp}&tempID=${tempID}`,
            paginate: true
          });
        } else {
          fetchOatTemplateList({
            api: `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category}/${ratio}/en?tag=&paginationToken=${paginationToken}`,
            paginate: true
          });
        }
      }
    }, { threshold: 0.5 });
    if (node) observer.current.observe(node);
  }, [templates, selection?.userMadeTemplates, selectedFolder]);

  useLayoutEffect(() => {
    if (layoutRef && layoutRef.current) {
      const overflowArea = layoutRef.current;
      overflowArea.scrollTop = 0;
    }
  }, [userMadeTemplates, selectedFolder]);

  return (
    <div>
      <Layout ref={layoutRef}>
        {loading && templates && templates.length === 0 ? (
          <WrapSpinner>
            <Spinner />
          </WrapSpinner>
        ) : !loading && templates && templates.length === 0 ? (
          <EmptyMsg>This section is empty</EmptyMsg>
        ) : undefined}

        {templates && templates.length > 0 ? (
          <Items noOfColumns={noOfColumns}>
            {templates &&
              templates.map((template, i) => (
                <DisplayItem
                  key={i}
                  item={template}
                  onSelect={chooseTemplate}
                  size={itemSize}
                  userMadeTemplates={userMadeTemplates}
                  workspacePlan={workspacePlan}
                />
            ))}

            <div ref={lastItemRef} />
            <div style={{height: 120}} />
          </Items>
        ) : undefined}

        
      </Layout>
      {error ? <Error>Requires Upgrade</Error> : undefined}
    </div>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  position: relative;
  // overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  height: ${panelHeight - 70}px;
  @media only screen and (max-width: 849px) {
    height: auto;
  }
`;

const Items = styled.div`
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(
    ${({noOfColumns}) => noOfColumns},
    minmax(0, 1fr)
  );
  margin-bottom: 100px;
  &:last-child {
    padding-bottom: 100px;
  }
`;

const WrapItem = styled.div`
  font-size: 0.92rem;
  margin-right: 1.5%;
  text-decoration: none;
  @media only screen and (min-width: 769px) {
    margin-bottom: 8px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  width: 100%;
  height: ${({userMadeTemplates}) => (userMadeTemplates ? "100%" : "auto")};
  object-fit: ${({userMadeTemplates}) =>
    userMadeTemplates ? "contain" : "cover"};
  user-select: none;
  cursor: pointer;
`;

const WrapSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 120px;
`;

const EmptyMsg = styled.div`
  margin-top: 125px;
  font-size: 1.15rem;
  text-align: center;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

const Error = styled.div`
  font-size: 16px;
  color: #e04848;
  position: absolute;
  left: 42%;
  top: 65px;
  z-index: 9999;
  border-radius: 18px;
  border: 1px solid #ffcdcd;
  background: #efcfcf;
  padding: 6px 22px;
  font-weight: 700;
`

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    ratio: selectTemplateRatio(designTemplate),
    category: selectTemplateCategory(designTemplate),
    user: selectUserInfo(state.authUser),
    workspaceID: selectWorkspaceID(state.designTemplate),
    selection: selectTemplateSelection(designTemplate),
    workspacePlan: selectWorkspacePlan(designTemplate)
  };
};

export const RequiresUpgradeToPro = styled.div`
  ${requiresUpgradeToProStyles};
  font-size: 0.8rem;
  position: absolute;
  bottom: 8px;
  right: 10px;
  padding: 2px 12px;
`

export default connect(mapStateToProps, {
  fetchDesignList,
  fetchOatTemplateList,
  fetchUserTempForSelection
})(ChooseTemplateCollection);
