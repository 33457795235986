import React from 'react';
import LayoutHoc from '../layout/LayoutHoc';
import { Layout, Panel, Header, Section } from './legalCSS';

const terms = [
    {
        title: "Definitions",
        contents: [
            "User” means an employee, contractor or other individual associated with Customer who has an access to the Services.",
            "Services” means Oat All Software as a Service product, web design software, along with its related applications.",
            "Content​” means documents, fonts, content, graphics, videos, materials created or imported using the Services by Customer."
        ]
    },
    { 
        title: "Acceptance of Terms",
        contents: [
            "By using Oat All, you agree to be bound by these Terms of Use.",
            "Oat All reserves the right to remove certain features from the application at its discretion without notice.",
        ] 
    },
    {
        title: "Use of Software",
        contents: [
            "Users may input text and upload pictures and related media content (including fonts, videos, and illustrations) into the application, provided that such actions do not infringe upon third-party rights and comply with applicable laws.",
            "Customer agrees that Oat All may store and process the content uploaded by the customer to facilitate retrieval.",
            "Customer agrees not to upload, support, promote any content that could be viewed as harmful, unlawful, and discriminatory (including race, gender, disability, religion).",
            "Customer agrees not to upload and transmit any content which contains software viruses, worms or any software agents, designed to interrupt, destroy any software or hardware devices."
        ]
    },
    {
        title: "Ownership Rights",
        contents: [
            "Oat All own all rights, including, but not limited to, all copyright rights in the Services. This includes any content, trademarks text, logos, graphics & illustrations, photographs, artwork, applications and its related computer code along with associated documentation that is owned by or licensed to Oat All.",
            "Customer own all rights of his/her own contents. Oat All disclaims any rights in Customer’s Content.",
            "Oat All posts a list of templates either created by Oat All or thrid parties. Templates are owned by the persons or entities who create them. Templates made by Oat All are supported as part of the Services. Oat All disclaims all warranties to templates created by third parties, and it is the responsibility of the Template creator to offer technical support. The creator assumes any responsibility and liability for the template's use."
        ]
    }

]
const TermsOfUse = () => {
    return (
        <LayoutHoc>
            <Layout>
                <Panel>
                    <>
                        <Header>Terms of Use</Header>

                        <>
                        {terms && terms.map((term, outerIndex) => (
                            <Section>
                                <h3 className="terms-of-use__section-title">
                                <span style={{ marginRight: 12 }}>{outerIndex + 1}.</span> {term.title}
                                </h3>

                                {term.contents && term.contents.map((content, innerIndex) => (
                                    <p className="terms-of-use__section-description">
                                        <span style={{ marginRight: 12 }}>{outerIndex + 1}.{innerIndex + 1}</span> {content}
                                    </p>
                                ))}  
                            </Section>
                        ))}                
                        </>
                    </>
                    
                </Panel>
            </Layout>
        </LayoutHoc>
    )
}


export default TermsOfUse;