import React, {useEffect} from "react";
import {CategorySection, WrapCategory} from "./folderStyles";
import {connect} from "react-redux";
import TemplateCategory from "./TemplateCategory";
import {selectActiveCategory} from "../../../store/selectors/adminPanel.selector";
import {
  fetchTempCategories,
  onCategoryChange,
} from "../../../store/actions/tempCategory.action";
import {useParams} from "react-router";

const categories = [
  {
    name: "Layouts (16x9)",
    ratio: "16x9",
    size: {w: 1920, h: 1080},
    value: "layouts",
  },
];

const LayoutsRatio = ({activeCategory}) => {
  return (
    <CategorySection>
      <WrapCategory>
        {categories &&
          categories.map((item, i) =>
            item.value === "layouts" ? (
              <TemplateCategory
                key={i}
                category={item}
                active={activeCategory}
              />
            ) : undefined
          )}
      </WrapCategory>
    </CategorySection>
  );
};

const mapStateToProps = (state) => {
  const {adminPanel} = state;
  return {
    activeCategory: selectActiveCategory(adminPanel),
  };
};

export default connect(mapStateToProps, null)(LayoutsRatio);
