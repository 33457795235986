import React, {useState, useEffect, useRef} from "react";
import {
  BottomSection,
  Cancel,
  Footer,
} from "../../../reuse/dashboardUI/modalStyles";
import { Image, ImageList, ImageSection, Item, WrapImages, WrapSpinner } from "./purchasedItemDetails.styles";
import { getWindowSize } from "../../../../../oat-window-size/getWindowSize";
import { becomesDrawerPoint } from "../PurchasedPanel";
import { Spinner } from "../../../../../oat-ui";

const numberOfItemsInRow = 4;
const DisplayItem = ({item, panelWidth, index}) => {
    const [itemSize, setItemSize] = useState({w: 0, h: 0});
  
    useEffect(() => {
      const width = (panelWidth - (10 * numberOfItemsInRow)) / numberOfItemsInRow;
      setItemSize({w: width, h: width});
    }, [panelWidth]);
  
    return (
        <Item style={{background: '#f2f7f2ed', overflow: 'hidden'}}>
            <Image 
                style={{
                    border: '1px solid #eadada5e',
                    boxSizing: 'border-box',
                    cursor: 'default',
                    minWidth: itemSize.w, 
                    minHeight: itemSize.h,
                    padding: 20
                }} 
                src={`https://oat-images-icons.s3.amazonaws.com/${item?.image}`} 
                loading="lazy"
                alt="" 
            />
        </Item>
    );
};

const PurchasedItemDetails = ({selectedItem, modalHeight, onClose}) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({status: false, message: null});
    const [panelWidth, setPanelWidth] = useState(0);
    const [panelHeight, setPanelHeight] = useState(null);
    const listPanelRef = useRef(null);
    const imageSectionRef = useRef(null);
    const windowSize = getWindowSize();
    
    useEffect(() => {
        if (selectedItem) {
          setLoading(true);
          fetch(
            `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/icons/${selectedItem?.category}/${selectedItem?.item?.productID}`
            // `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/icons/${selectedItem.item.productID}` 
          )
          .then((response) => response.json())
          .then((data) => {
            setItems(data);
            setLoading(false);
          })
          .catch((err) => {
            setError({
              status: true, message: 'Something went wrong.'
            })
          });
        }
    }, [selectedItem]);

    useEffect(() => {
        if (imageSectionRef.current && imageSectionRef.current.offsetHeight) {
            setPanelHeight(imageSectionRef.current.offsetHeight);
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (listPanelRef?.current) {
            const width = listPanelRef?.current.offsetWidth;
            setPanelWidth(width);
        }
    }, [loading, windowSize.width]);

    return loading ? (
        <WrapSpinner>
            <Spinner/>
        </WrapSpinner>
    ) :
        <>     
            <ImageSection style={{
                height: modalHeight ? modalHeight - 60 : windowSize.height - 130,
                padding: windowSize.width > 600 ? '12px 12px 0 12px' : undefined
            }}>        
                <WrapImages ref={listPanelRef} panelHeight={panelHeight}>
                    <ImageList style={{ padding : '0px 1px'}}>
                    {items && items.map((item, i) => (
                        <DisplayItem
                            key={i}
                            item={item}
                            index={i}
                            panelWidth={panelWidth}
                        />
                    ))}
                    </ImageList>
                </WrapImages>
            </ImageSection>

            <Footer 
                style={{ 
                    position: windowSize.width <= becomesDrawerPoint ? 'absolute' : undefined, 
                    bottom: windowSize.width <= becomesDrawerPoint ? 0 : undefined,
                }}
            >
                <BottomSection
                    style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                <Cancel onClick={onClose}>Cancel</Cancel>
                {/* <Button
                    styles={{width: 120}}
                    onClick={handleUpate}
                    disabled={processing}
                >
                    {processing ? <ButtonSpinner /> : "Update"}
                </Button> */}
                </BottomSection>
            </Footer>
        </> 
    
};

export default PurchasedItemDetails;
