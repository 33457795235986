import React, {useState, useEffect} from "react";
import styled from "styled-components";
import ColumnBar from "../../../editDesign/canvasPanel/fields/charts/barCharts/barProps/ColumnBar";
import ValueLabel from "../../../editDesign/canvasPanel/fields/charts/barCharts/common/ValueLabel";
import ThemeGrid from "./ThemeGrid";

const greatestValue = (values) =>
  values.reduce((acc, cur) => (cur > acc ? cur : acc), -Infinity);

const getLongestLabelWidth = (data) =>
  data.reduce((item, cur) => (cur > item ? cur : item), 0);

const ThemeBuilderBarChart = ({size, styles, content}) => {
  const ratioDiff = 1;
  const {data, color} = content;
  const edgeSpace = 15 * ratioDiff;
  const {
    showBackground,
    background,
    gridColor,
    labelColor,
    valueColor,
    fontFamily,
    barSize,
    axisColor,
  } = styles;
  const [initChartWidth, setInitChartWidth] = useState(size.w);
  const [initBarWidth, setInitBarWidth] = useState(barSize);
  const [barWidth, setBarWidth] = useState(
    (size.w * initBarWidth) / initChartWidth
  );
  const barMargin =
    (size.w - (barWidth * data.length + 2 * edgeSpace)) / (data.length - 1);

  const width = size.w;
  const height = size.h;

  useEffect(() => {
    const miniumGap = 15 * ratioDiff;
    // when new row is added within the existing chart frame, bar width needs to be reduced
    if (
      content.data.length > 5 && // need to skip init update
      width - (edgeSpace * 2 + data.length * barWidth) < miniumGap
    ) {
      const newMiniumGap = 10 * ratioDiff;
      const mustHaveSpace = edgeSpace * 2 + (newMiniumGap * data.length - 1);
      const remainingSpace = size.w - mustHaveSpace;
      const newBarWidth = remainingSpace / data.length;
      setInitBarWidth(newBarWidth);
      setBarWidth((size.w * newBarWidth) / initChartWidth);
    } else {
      const marginAndEdge = (data.length - 1) * barMargin + edgeSpace * 2;
      const newBarWidth = (size.w - marginAndEdge) / data.length;
      setBarWidth(newBarWidth);
    }
  }, [content.data]);

  useEffect(() => {
    setInitBarWidth(barSize * ratioDiff);
    setBarWidth((size.w * barSize * ratioDiff) / initChartWidth);
  }, [barSize]);

  const lineOffsets = [
    {
      x1: 0,
      y1: height / 5,
      x2: width,
      y2: height / 5,
    },
    {
      x1: 0,
      y1: (height / 5) * 2,
      x2: width,
      y2: (height / 5) * 2,
    },
    {
      x1: 0,
      y1: (height / 5) * 3,
      x2: width,
      y2: (height / 5) * 3,
    },
    {
      x1: 0,
      y1: (height / 5) * 4,
      x2: width,
      y2: (height / 5) * 4,
    },
  ];

  const longestLabelWidth = getLongestLabelWidth(
    data.map((datum) => datum.width)
  );
  const [lines, setLines] = useState(lineOffsets);
  const [slope, setSlope] = useState(false);
  useEffect(() => {
    setLines(lineOffsets);
    setSlope(longestLabelWidth < barWidth + barMargin / 2 ? false : true);
  }, [size]);

  const tallest = greatestValue(data.map((datum) => datum.value));

  return (
    <Wrapper style={{width, height}}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{background: showBackground ? background : undefined}}
      >
        <g>
          {data.map((datum, index) => {
            return (
              <ColumnBar
                key={index}
                fill={datum.color}
                x={index * (barWidth + barMargin) + edgeSpace}
                y={(height * (tallest - datum.value)) / tallest}
                width={barWidth}
                height={(datum.value * height) / tallest}
                chartWidth={width}
              />
            );
          })}
        </g>

        <g>
          {lines.map((line, index) => (
            <ThemeGrid
              stroke={gridColor}
              x1={line.x1}
              x2={line.x2}
              y1={line.y1}
              y2={line.y2}
              key={index}
            />
          ))}
        </g>

        <g>
          {data.map((item, index) => (
            <ValueLabel
              key={index}
              x={edgeSpace + (barWidth + barMargin) * index + barWidth / 2}
              y={(height * (tallest - item.value)) / tallest - 3}
              color={valueColor}
              width={barWidth}
              height={20}
              value={item.value}
              fontSize={16}
              fontFamily={fontFamily}
              textAnchor="middle"
              alignmentBaseline="alphabetic"
            />
          ))}
        </g>

        <g>
          {data.map((item, index) => (
            //  For straight
            // <ValueLabel
            //   key={index}
            //   x={edgeSpace + (barWidth + barMargin) * index + barWidth / 2}
            //   y={height + 5}
            //   color={valueColor}
            //   width={barWidth}
            //   height={20}
            //   value={item.x}
            //   fontSize={fontSize}
            //   textAnchor="middle"
            //   alignmentBaseline="hanging"
            //
            // />

            // For curve
            <ValueLabel
              key={index}
              x={edgeSpace + (barWidth + barMargin) * index + barWidth / 2}
              y={height + 5}
              color={labelColor}
              width={barWidth}
              height={20}
              value={item.x}
              fontSize={16}
              fontFamily={fontFamily}
              textAnchor="end"
              alignmentBaseline="before-edge"
              rotate={-45}
            />
          ))}
        </g>

        <g>
          <line
            x1={0}
            x2={width}
            y1={height}
            y2={height}
            stroke={axisColor} // axisColor
            strokeWidth={1}
          />
        </g>

        <g>
          <line
            x1={0}
            x2={0}
            y1={0}
            y2={height}
            stroke={axisColor}
            strokeWidth={1}
          ></line>
        </g>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  position: relative;
  svg {
    pointer-events: none;
    overflow: visible;
    rect {
      pointer-events: none;
    }
    transform-box: fill-box;
  }
`;

export default ThemeBuilderBarChart;
