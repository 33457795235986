import {saveAs} from "file-saver";

export async function handleImgDownload({
  username,
  template,
  selectedSlide,
  tempInfo,
  extention,
  themes,
  slideGroupList,
  currentSlideOnly,
}) {
  try {
    const {tempID, tempRatio, tempSize, tempName, tempSourceID, tempLength} =
      tempInfo;

    // try {
    // Step 1: Send PUT request to update template info
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        extention,
        tempLength,
        selectedSlide,
      }),
    };
    const templateInfo = {
      ratio: tempRatio,
      size: tempSize,
      title: tempName,
      tempSourceID,
    };
    const response = await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/save-design-without-screenshot`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          tempInfo: {
            ...templateInfo,
            tempID,
          },
          tempDetails: {
            details: template,
            themes,
            slideGroupList,
            sectionList: "", // add later
            tempDescription: "", // add later
          },
        }),
      }
    );

    if (!response.ok) {
      return false;
      // throw new Error("Failed to update template info");
    }

    // Step 2: Send POST request to get download URL
    await response.json();
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/export/image/${tempID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        await fetch(data, {
          headers: {
            "Content-Type": currentSlideOnly
              ? `image/${extention}`
              : `application/zip`,
          },
          responseType: "blob",
        })
          .then((response) => response.blob())
          .then((blob) => {
            const tempNameAsSlug = tempName.replace(/[\s;]+/g, "-").toLowerCase();
            saveAs(
              blob,
              currentSlideOnly
                ? `${tempNameAsSlug}.${extention}`
                : `${tempNameAsSlug}.zip`
            );
            return true;
          });
      });
     return await true;
  } catch(e) {
    return false;
  }
}
