import React from "react";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import {InputName} from "../common/WrapColorStyles";
import {connect} from "react-redux";
import SelectLineTypes from "./SelectLineTypes";
import styled from "styled-components";
import DropDown from "../../../../../../ui/inputs/DropDown";
import StraightenLine from "./StraightenLine";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import EditOpacity from "../common/EditOpacity";

const lineTypes = [
  {id: "ma312be", value: "line", size: { w: 80, h: 18 }},
  {
    id: "rny52xm",
    value: "line-with-right-arrow",
    size: { w: 80, h: 18 }
  },
  {
    id: "gc442c1",
    value: "line-with-left-arrow",
    size: { w: 80, h: 18 }
  },
  {
    id: "af145c5",
    value: "line-with-arrows",
    size: { w: 80, h: 18 }
  },
  {
    id: "rty14mm",
    value: "line-with-right-circle",
    size: { w: 80, h: 18 }
  },
  {
    id: "de342cv",
    value: "line-with-left-circle",
    size: { w: 80, h: 18 }
  },
  {
    id: "vb419t1",
    value: "line-with-circles",
    size: { w: 80, h: 18 }
  },
];

export const lineStyles = [
  {display: "Straight", value: "straight"},
  {display: "Dashed", value: "dashed"},
  {display: "Dotted", value: "dotted"},
];

const lineThickness = [
  {display: 1, value: 1},
  {display: 2, value: 2},
  {display: 3, value: 3},
  {display: 4, value: 4},
  {display: 5, value: 5},
  {display: 6, value: 6},
  {display: 7, value: 7},
  {display: 8, value: 8},
];
export const lineThicknessForRange = {
  max: 6,
  min: 1,
};

const EditShapeLine = ({
  field,
  slideID,
  updateFieldStyles,
  storeFieldUndoRedo,
  stableStyles,
  updateFieldCollaboration,
}) => {
  const {styles} = field || {};

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: field?.key},
      type,
      value,
    });
  };

  const handleEmitValue = () => {
    updateFieldCollaboration();
  };

  const onChangeLineColor = (value) => {
    handleStyleUpdate("color1", value);
  };

  const onChangeMarkerColor = (value) => {
    handleStyleUpdate("color2", value);
  };

  const lineColorDebounce = (value) => {
    storeFieldUndoRedo("color1", value);
    handleEmitValue();
  };

  const markerColorDebounce = (value) => {
    storeFieldUndoRedo("color2", value);
    handleEmitValue();
  };

  const chooseLineThickness = (value) => {
    handleStyleUpdate("lineThickness", value);
    storeFieldUndoRedo("lineThickness", value);
    handleEmitValue();
  };

  const chooseLineStyle = (value) => {
    handleStyleUpdate("lineStyle", value);
    storeFieldUndoRedo("lineThickness", value);
    handleEmitValue();
  };

  const chooseLineType = (value) => {
    handleStyleUpdate("lineType", value);
    storeFieldUndoRedo("lineThickness", value);
    handleEmitValue();
  };

  return (
    <>
      <WarpItem>
        <InputName>Line Color</InputName>
        <PopoverColorBox
          value={styles?.color1}
          updateFieldStyles={onChangeLineColor}
          onDebounce={lineColorDebounce}
        />
      </WarpItem>

      <WarpItem>
        <InputName>Line Thickness</InputName>
        <div style={{flex: 1, display: "flex"}}>
          <DropDown
            onSelect={chooseLineThickness}
            selected={Math.round(styles?.lineThickness)}
            options={lineThickness}
            minWidth={120}
            maxWidth={120}
            boxWidth={120}
          />
        </div>
      </WarpItem>

      <WarpItem>
        <InputName>Line Style</InputName>
        <div style={{flex: 1, display: "flex"}}>
          <DropDown
            onSelect={chooseLineStyle}
            selected={styles?.lineStyle}
            options={lineStyles}
            minWidth={120}
            maxWidth={120}
            boxWidth={120}
          />
        </div>
      </WarpItem>

      <WarpItem>
        <InputName>Line Type</InputName>
        <div style={{flex: 1, display: "flex"}}>
          <SelectLineTypes
            onSelect={chooseLineType}
            selected={styles?.lineType}
            options={lineTypes}
          />
        </div>
      </WarpItem>

      {styles.lineType !== "line" ? (
        <WarpItem>
          <InputName>Marker Color</InputName>
          <PopoverColorBox
            value={styles?.color2}
            updateFieldStyles={onChangeMarkerColor}
            onDebounce={markerColorDebounce}
          />
        </WarpItem>
      ) : undefined}

      <div style={{paddingBottom: "2em"}}>
        <EditOpacity value={styles.opacity} />
      </div>

      {/* <div style={{padding: "2em 0"}}>
        <StraightenLine>
          <Button>Straighten Line</Button>
        </StraightenLine>
      </div> */}
    </>
  );
};

const WarpItem = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 2em;
`;

const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 6px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  margin: 0 1rem;
  cursor: pointer;
  box-shadow: ${({theme}) => theme.boxShadow1};
`;

export default connect(null, {
  updateFieldStyles,
  updateFieldCollaboration,
  storeFieldUndoRedo,
})(EditShapeLine);
