import React from "react";
import ConfirmDelete from "./actions/ConfirmDelete";
import ModalProcessing from "./actions/ModalProcessing";
import RenameTemplate from "./actions/RenameTemplate";
import ToAnotherWorkspace from "./actions/shareTo/ToAnotherWorkspace";
import ShareLink from "./actions/ShareLink";
import DisplayMessage from "./actions/DisplayMessage";
import AddTemplateToProfile from "./actions/AddTemplateToProfile";
import ShareAsGuest from "./actions/shareTo/ShareAsGuest";

const ModalTypes = ({type, close, selectedItem, message}) => {
  let displayModal = null;
  switch (type) {
    case "rename-template":
      displayModal = <RenameTemplate close={close} item={selectedItem} />;
      break;
    case "confirm-template-delete":
      displayModal = <ConfirmDelete close={close} item={selectedItem} />;
      break;
    case "processing":
      displayModal = <ModalProcessing close={close} />;
      break;
    case "to-another-workspace":
      displayModal = <ToAnotherWorkspace close={close} item={selectedItem} />;
      break;
    case "invite-as-guest":
      displayModal = <ShareAsGuest close={close} item={selectedItem} />;
      break;
    case "share-link":
      displayModal = <ShareLink close={close} item={selectedItem} />;
      break;
    case "display-message":
      displayModal = (
        <DisplayMessage close={close} message={message} item={selectedItem} />
      );
      break;
    case "add-to-profile":
      displayModal = <AddTemplateToProfile close={close} item={selectedItem} />;
      break;
    case "create-temp-profile-not-enough-permission":
      displayModal = (
        <DisplayMessage
          close={close}
          message="You don't have enough permission to create template on profile."
          item={selectedItem}
        />
      );
      break;
    default:
      displayModal = "";
      break;
  }

  return displayModal;
};

export default ModalTypes;
