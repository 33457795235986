import React from "react";
import PieChart from "./pieCharts/PieChart";
import BarChart from "./barCharts/BarChart";
import GaugeChart from "./pieCharts/GaugeChart";
import StackedColumn from "./barCharts/StackedColumn";
import ColumnChart from "./barCharts/ColumnChart";
import LineChart from "./barCharts/LineChart";
import AreaChart from "./barCharts/AreaChart";
import StackedAreaChart from "./barCharts/StackedAreaChart";

const DisplayChart = ({field}) => {
  const {size, subtype, styles, dataSeries, colorSet, pos} = field || {};
  let chart = null;

  switch (subtype) {
    case "column-chart":
      chart = (
        <ColumnChart
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          colorSet={colorSet}
        />
      );
      break;
    case "bar-chart":
      chart = (
        <BarChart
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          colorSet={colorSet}
        />
      );
      break;
    case "line-chart":
      chart = (
        <LineChart
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          colorSet={colorSet}
        />
      );
      break;
    case "area-chart":
      chart = (
        <AreaChart
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          fieldKey={field.key}
          colorSet={colorSet}
        />
      );
      break;
    case "stacked-area-chart":
      chart = (
        <StackedAreaChart
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          fieldKey={field.key}
          colorSet={colorSet}
        />
      );
      break;
    case "pie":
      chart = (
        <PieChart
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          donut={false}
          colorSet={colorSet}
          chartType={subtype}
        />
      );
      break;
    case "donut":
      chart = (
        <PieChart
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          donut={true}
          colorSet={colorSet}
        />
      );
      break;
    case "gauge":
      chart = (
        <GaugeChart size={size.w} styles={styles} dataSeries={dataSeries} />
      );
      break;
    case "stacked-column-chart":
      chart = (
        <StackedColumn
          size={size}
          styles={styles}
          dataSeries={dataSeries}
          colorSet={colorSet}
        />
      );
      break;
    default:
      chart = "";
  }

  return chart;
};

export default DisplayChart;
