import React from "react";
import Alpha from "./Alpha";
import styled from "styled-components";
import AlphaPointer from "./AlphaPointer";

export const AlphaPicker = ({
  hsl,
  rgb,
  direction,
  onChange,
  onSelectionEnd,
  pointer,
}) => {
  return (
    <Wrapper>
      <Alpha
        rgb={rgb}
        hsl={hsl}
        pointer={pointer}
        onChange={onChange}
        onSelectionEnd={onSelectionEnd}
        direction={direction}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
`;

AlphaPicker.defaultProps = {
  direction: "horizontal",
  pointer: AlphaPointer,
};

export default AlphaPicker;
