import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {detectClickOutside} from "../../../../../oat-ui";
import {editTemplateName} from "../../../../store/actions/template/template.action";
import {
  selectTemplateName,
  selectTemplateOwner,
  selectFolder,
  selectWorkspaceID,
} from "../../../../store/selectors/template/template.selector";
import {Link, useNavigate, useParams} from "react-router-dom";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {useSocket} from "../../../../webSocket/useSocket";
import { selectUndoRedoHistory } from "../../../../store/selectors/fields.selector";

const LeftPanel = ({
  tempName,
  editTemplateName,
  user,
  folder,
  workspaceID,
  owner,
  undoState,
  isSaving
}) => {
  const slideTitleRef = useRef();
  const [newTitle, setNewTitle] = useState(tempName);
  const [onEdit, setOnEdit] = useState(false);
  let params = useParams();
  const {tempID, category} = params;
  const {emitSocketEvents} = useSocket() || {};
  const [hasUnsavedChanges, setUnsavedChanges] = useState({
    status: false,
    history: 0
  });
  let navigate = useNavigate();

  useEffect(() => {
    setNewTitle(tempName !== undefined ? tempName : "Untitled Template");
  }, [tempName]);

  const handleClick = () => {
      setOnEdit(!onEdit);
  };

  const handleInputChange = (e) => {
    const {value} = e.target;
    setNewTitle(value);
  };

  const updateTemplateTitle = async (newTitle) => {
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${owner}/template/name`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tempID,
          folderID: folder.ID,
          workspaceID,
          tempTitle: newTitle,
        }),
      }
    )
      .then(() => {
        const item = {type: "", value: newTitle};
        if (emitSocketEvents) {
          emitSocketEvents({actionType: "changed-temp-title", item});
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isSaving) {
      setUnsavedChanges({
        status: false,
        history: undoState.history.length
      })
    } 
  }, [isSaving, undoState.history.length]);

  useEffect(() => {
    if (undoState.history.length !== hasUnsavedChanges.history) {
      setUnsavedChanges({
        status: true,
        history: undoState.history.length
      })
    } 
  }, [undoState.history.length]);

  const rerouteLink = (e) => {
    let confirmed = true;
    if (undoState.history.length >= 1 && hasUnsavedChanges.status) {
      confirmed = window.confirm("You have unsaved changes. Are you sure you want to leave?");
    } 

    if (confirmed) {
      if (user.username === null) {
        navigate("/")
      } else if (user.staff.status && window.location.href.toString().includes("/admin/designs/")) {
        navigate("/admin/designs/presentation")
      } else if (workspaceID) {
        navigate(`/dashboard/${workspaceID}/designs/home`)
      } else {
        navigate(`/dashboard/shared-to-me`)
      }
    }
  }

  detectClickOutside(slideTitleRef, () => {
    setOnEdit(!onEdit);
    editTemplateName(newTitle); // redux
    updateTemplateTitle(newTitle);
  });
  
  return (
    <WrapLeftSideContents>
      <BrandName
        onClick={rerouteLink}
        // to={
        //   user.staff.status &&
        //   window.location.href.toString().includes("/admin/designs/")
        //     ? "/admin/designs/presentation"
        //     : workspaceID ? `/dashboard/${workspaceID}/designs/home` :
        //     `/dashboard/shared-to-me`
        // }
      >
        Oat All
      </BrandName>
      {!onEdit ? (
        <TemplateTitle onClick={handleClick}>{newTitle}</TemplateTitle>
      ) : user.username ? (
        <Input
          value={newTitle}
          onChange={handleInputChange}
          ref={slideTitleRef}
        />
      ) : undefined}
    </WrapLeftSideContents>
  );
};

const WrapLeftSideContents = styled.div`
  margin-left: 42px;
  display: flex;
  align-items: center;
`;

const BrandName = styled.div`
  font-size: 1.1rem;
  font-wieght: 700;
  padding-right: 62px;
  box-sizing: border-box;
  cursor: pointer;
  color: ${({theme}) => theme.deskPrimaryTxt};
  white-space: pre;
  user-select: none;
  text-decoration: none;
`;

const reuse = css`
  width: 140px;
  padding: 6px 2px;
  box-sizing: border-box;
`;

const TemplateTitle = styled.div`
  font-size: 0.85rem;
  cursor: text;
  width: 140px;
  color: ${({theme}) => theme.deskSecondaryTxt};
  padding: 6px 2px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  ${reuse};
`;

const Input = styled.input`
  border: none;
  background: transparent;
  color: ${({theme}) => theme.deskSecondaryTxt};
  border: 1px solid ${({theme}) => theme.inputBorder};
  ${reuse};
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempName: selectTemplateName(designTemplate),
    user: selectUserInfo(state.authUser),
    folder: selectFolder(designTemplate),
    workspaceID: selectWorkspaceID(designTemplate),
    owner: selectTemplateOwner(designTemplate),
    undoState: selectUndoRedoHistory(designTemplate),
  };
};

export default connect(mapStateToProps, {editTemplateName})(LeftPanel);
