import React, {useState} from "react";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {selectThemeBuilder} from "../../store/selectors/template/theme.selector";
import SpecialButton from "../../ui/inputs/SpecialButton";
import EditThemeBuilder from "./EditThemeBuilder";
import ThemeCanvas from "./ThemeCanvas";
import {
  toggleBuildingThemeStatus,
  updateThemeName,
} from "../../store/actions/template/theme.action";

const ThemeBuilder = ({
  updateThemeName,
  themeName,
  onSubmit,
  processing,
  buttonTitle,
  toggleBuildingThemeStatus,
}) => {
  const [selectedTab, setSelectedTab] = useState("Text");
  let navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const onSaveTheme = async () => {
    onSubmit();
  };

  const handleThemeTitle = (e) => {
    const {value} = e.target;
    updateThemeName(value);
  };

  const handleBackButton = (e) => {
    e.stopPropagation();
    toggleBuildingThemeStatus();
    return navigate(`${location.pathname}`, { replace: true });
  };

  return (
    <Layout>
      <ThemeNavBar>
        <ThemeTitle>
          <input placeholder="Theme Name" value={themeName} onChange={handleThemeTitle} />
        </ThemeTitle>
        <ThemeActions style={{zIndex: 99999}}>
          <div style={{zIndex: 99999}} onClick={handleBackButton}>
            Cancel
          </div>
          <SpecialButton
            onClick={onSaveTheme}
            style={{opacity: !processing ? 1 : 0.5}}
          >
            {buttonTitle}
          </SpecialButton>
        </ThemeActions>
      </ThemeNavBar>

      <Wrapper>
        <EditThemeBuilder
          selectedTab={selectedTab}
          onChangeTab={handleTabChange}
        />
        <ThemeCanvas selectedTab={selectedTab} />
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  min-height: ${window.innerHeight}px;
  position: relative;
  background: ${({theme}) => theme.deskPrimaryBg2};
  z-index: 9999;
`;

const Wrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const buttonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 32px;
  cursor: pointer;
  font-size: 0.85rem;
`;

const ThemeNavBar = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.deskGap};
  padding: 0 32px;
  div {
    color: ${({theme}) => theme.deskPrimaryTxt};
    ${buttonStyles};
  }
`;

const ThemeTitle = styled.div`
  input {
    border: none;
    background: transparent;
    color: ${({theme}) => theme.deskSecondaryTxt};
    border: 1px solid ${({theme}) => theme.inputBorder};
    width: 140px;
    padding: 6px 2px;
    box-sizing: border-box;
  }
`;

const ThemeActions = styled.div``;

ThemeBuilder.defaultProps = {
  buttonTitle: "Save",
};

const mapStateToProps = (state) => {
  return {
    themeName: selectThemeBuilder(state.designTemplate).name,
  };
};

export default connect(mapStateToProps, {
  toggleBuildingThemeStatus,
  updateThemeName,
})(ThemeBuilder);
