import React, {useState} from "react";
import {Item} from "../../sidebar/reusableStyles/sidebarItem";
import {UserSidebarPanel} from "../../sidebar/UserSidebar";
import ProfileModals from "./modals/ProfileModals";
import ShowAddTempProfile from "./modals/ShowAddTempProfile";
import ProfileCollections from "./tags/ProfileCollections";

const ModalTypes = ({type, close}) => {
  let displayModal = null;
  switch (type) {
    case "edit-profile-data":
      displayModal = <ProfileModals close={close} />;
      break;
    default:
      displayModal = <ShowAddTempProfile close={close} />;
  }
  return displayModal;
};

const ProfileSettingSidebar = () => {
  const [openModal, setOpenModal] = useState({
    staus: false,
    type: null,
  });

  const changeModal = (modal) => {
    setOpenModal({
      status: true,
      type: modal,
    });
  };

  const onSelectProfileImageCover = () => {
    changeModal("edit-profile-data");
  };

  const onAddTempProfile = () => {
    changeModal("on-add-temp-profile");
  };

  const handleCloseModal = () => {
    setOpenModal({
      staus: false,
      type: null,
    });
  };

  return (
    <UserSidebarPanel>
      <Item style={{marginTop: 12}} onClick={onSelectProfileImageCover}>
        <p>Profile Cover & Image</p>
      </Item>
      <Item onClick={onAddTempProfile}>
        <p>Add Template to Profile</p>
      </Item>

      <ProfileCollections />

      {openModal.status ? (
        <ModalTypes type={openModal.type} close={handleCloseModal} />
      ) : undefined}
    </UserSidebarPanel>
  );
};

export default ProfileSettingSidebar;
