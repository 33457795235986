import React from "react";

export const HorizontalFlip = ({fill}) => {
  return (
    <svg width="26" height="26" viewBox="0 0 24 24" fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 5.72386 11.7761 5.5 11.5 5.5C11.2239 5.5 11 5.72386 11 6L11 19C11 19.2761 11.2239 19.5 11.5 19.5C11.7761 19.5 12 19.2761 12 19L12 6ZM7.25 7C8.2165 7 9 7.7835 9 8.75L9 16.25C9 17.2165 8.2165 18 7.25 18H5.75C4.7835 18 4 17.2165 4 16.25V8.75C4 7.7835 4.7835 7 5.75 7H7.25ZM17.25 7C18.2165 7 19 7.7835 19 8.75V16.25C19 17.2165 18.2165 18 17.25 18H15.75C14.7835 18 14 17.2165 14 16.25V8.75C14 7.7835 14.7835 7 15.75 7H17.25ZM18 8.75C18 8.33579 17.6642 8 17.25 8H15.75C15.3358 8 15 8.33579 15 8.75V16.25C15 16.6642 15.3358 17 15.75 17H17.25C17.6642 17 18 16.6642 18 16.25V8.75Z"
      ></path>
    </svg>
  );
};

export const VerticalFlip = ({fill}) => {
  return (
    <svg width="26" height="26" viewBox="0 0 24 24" fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 13C18.2761 13 18.5 12.7761 18.5 12.5C18.5 12.2239 18.2761 12 18 12L5 12C4.72386 12 4.5 12.2239 4.5 12.5C4.5 12.7761 4.72386 13 5 13L18 13ZM17 8.25C17 9.2165 16.2165 10 15.25 10L7.75 10C6.7835 10 6 9.2165 6 8.25L6 6.75C6 5.7835 6.7835 5 7.75 5L15.25 5C16.2165 5 17 5.7835 17 6.75L17 8.25ZM17 18.25C17 19.2165 16.2165 20 15.25 20L7.75 20C6.7835 20 6 19.2165 6 18.25L6 16.75C6 15.7835 6.7835 15 7.75 15L15.25 15C16.2165 15 17 15.7835 17 16.75L17 18.25ZM15.25 19C15.6642 19 16 18.6642 16 18.25L16 16.75C16 16.3358 15.6642 16 15.25 16L7.75 16C7.33579 16 7 16.3358 7 16.75L7 18.25C7 18.6642 7.33579 19 7.75 19L15.25 19Z"
      ></path>
    </svg>
  );
};

HorizontalFlip.defaultProps = {
  fill: "#4a4949",
};

VerticalFlip.defaultProps = {
  fill: "#4a4949",
};
