import React from "react";
import styled from "styled-components";
import {Option} from "../../../../../oat-ui/styles/SelectOption";

const FontActions = ({onDelete}) => {
  return (
    <Wrapper>
      <Option onClick={onDelete}>
        <div style={{color: "#ff2f2f"}}>Delete Font</div>
      </Option>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 8px 0;
`;

export default FontActions;
