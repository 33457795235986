import styled from "styled-components";
import NavBar from "../components/layout/NavBar";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {lightViolet} from "../../oat-color-variables";

const Hoc = ({children}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <body style="overflow: hidden;" />
      </Helmet>
      <Layout>
        <NavBar />

        <Wrapper>{children}</Wrapper>
      </Layout>
    </HelmetProvider>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightViolet};
  cursor: default;
`;

const Wrapper = styled.div`
  display: flex;
`;

export default Hoc;
