import {
    handleContentChange,
    moveContentBefore,
    wrapRangeInNestedWordSpan,
    wrapRangeInWordSpan,
  } from "../../../../../../utils/rangeSelector";

export const onTextSelection = async ({content, color, subtype}) => {
    const sel = window.getSelection();
    if (!sel || sel.isCollapsed) {
      // const spanTagsInside = document.querySelectorAll(".innerTextStyle");
      let spanTagsInside = document.createElement('div');
      spanTagsInside.innerHTML = content;
      // Remove the 'style' attribute from the span element

      const elementsWithClassName = spanTagsInside.querySelectorAll('.innerTextStyle');
      elementsWithClassName.forEach(function(element) {
        // Remove the 'style' attribute from each element
        // Get the current style attribute value
        let currentStyle = element.getAttribute('style');
        // Create a new style string without the 'color' property
        let newStyle = currentStyle.replace(/color\s*:\s*[^;]*;?/gi, '');
        // Set the updated style attribute value
        element.setAttribute('style', newStyle.trim());
      });

    //   setColor(value);
    //   await handleUpdates("color1", value);
    //   await updateTextContent(activeSlideFieldInfo, spanTagsInside.innerHTML);
    // update type one - changing color
      return spanTagsInside.innerHTML
      
    } else if (!sel.isCollapsed) {
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0).cloneRange();
        if (range.collapsed) {
          return;
        }

        const parentElement =
          sel.getRangeAt(0).commonAncestorContainer.parentElement;
        // first pass
        wrapRangeInWordSpan(range, "color", color);

        // second pass, find the nested .innerTextStyle
        // and wrap the content before and after it in their own spans
        const inner = document.querySelector(".innerTextStyle .innerTextStyle");
        const classNameAlreadyExistsWithLink = document.querySelector(".innerTextStyle .innerTextLink");
        if (!inner) {
          // when selecting two .innerTextStyle start to end, where the empty spans stick around
          // we remove them here
          range.startContainer.parentNode
            .querySelectorAll(".innerTextStyle:empty")
            .forEach((node) => node.remove());

          const updatedContent = await handleContentChange(
            range,
            subtype
          );

        //   await updateTextContent(activeSlideFieldInfo, updatedContent);
        //   await storeFieldUndoRedo();
        //   await updateFieldCollaboration();
        //   window.getSelection().removeAllRanges();
        //   window.getSelection().addRange(range);

          const newRange = document.createRange();
          // Identify the new start and end positions after color modification
          const newStartContainer = range.startContainer;
          const newEndContainer = range.endContainer;
          // Create a new range and set it to the desired portion
          newRange.setStart(newStartContainer, range.startOffset);
          newRange.setEnd(newEndContainer, range.endOffset);
          // Set the start and end points of the range to select the current selection
          sel.removeAllRanges();
          sel.addRange(newRange);
        //   setColor(value);
          return updatedContent;

        } else {
          const parent = inner.closest(".innerTextStyle:not(:scope)");
          const extractingRange = document.createRange();
          // wrap the content before
          extractingRange.selectNode(parent);
          extractingRange.setEndBefore(inner);

          const payload = {
            "font-style": parentElement.style?.fontStyle, //"italic",
            "font-weight": parentElement.style?.fontWeight,
            color: parentElement.style?.color,
          };
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // wrap the content after
          extractingRange.selectNode(parent);

          // Capture the selection offsets
          // Code order matters here
          const selectionStartOffset = extractingRange.startOffset;
          const selectionEndOffset = extractingRange.endOffset;

          extractingRange.setStartAfter(inner);
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // finally, extract all the contents from parent
          // to its own parent and remove it, now that it's empty
          moveContentBefore(parent);

          const updatedContent = await handleContentChange(
            range,
            subtype
          );
        //   setColor(value);
        //   await updateTextContent(activeSlideFieldInfo, updatedContent);

          const newRange = document.createRange();
          // Identify the new start and end positions after color modification
          const newStartContainer = range.startContainer;
          const newEndContainer = range.endContainer;
          // Create a new range and set it to the desired portion
          newRange.setStart(newStartContainer, selectionStartOffset);
          newRange.setEnd(newEndContainer, selectionEndOffset);
          // Set the start and end points of the range to select "World"
          sel.removeAllRanges();
          sel.addRange(newRange);

          return updatedContent;
        }
      }
    }
}