import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import SelectFontSize from "../common/SelectFontSize";
import {WrapColorBox} from "../common/WrapColorStyles";
import LegendPosition from "./reuse/LegendPosition";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {round} from "../../../../canvasPanel/fields/charts/barCharts/common/getFactors";
import {chartDefaultFontSize} from "../../../../canvasPanel/fields/charts/chartDefaultFontSize";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const EditChartLegend = ({
  legendFontSize,
  legendColor,
  showLegend,
  updateFieldStyles,
  legendPosition,
  scale,
  storeFieldUndoRedo,
  slideID,
  fieldID,
  updateFieldCollaboration,
}) => {
  const handleDataEmit = (type, value) => {
    updateFieldCollaboration();
  };

  const onUpdateStyle = (type, value) => {
    updateFieldStyles({active: {slideID, fieldID}, type, value});
  };

  const toggleButton = () => {
    if (!showLegend) {
      onUpdateStyle("showLegend", true);
      handleDataEmit("showLegend", true);
    } else if (showLegend) {
      onUpdateStyle("showLegend", false);
      handleDataEmit("showLegend", false);
    }
    storeFieldUndoRedo();
  };

  const updateLegendFontSize = (value) => {
    onUpdateStyle("legendFontSize", Number(value / chartDefaultFontSize));
    handleDataEmit("legendFontSize", Number(value / chartDefaultFontSize));
  };

  const updateLegendColor = (value) => {
    onUpdateStyle("legendColor", value);
  };

  const handleColorDebounce = (value) => {
    handleDataEmit("legendColor", value);
    storeFieldUndoRedo();
  };

  return (
    <>
      <div style={{paddingBottom: showLegend ? "2em" : 0}}>
        <Switch
          title="Show Legend"
          value={showLegend}
          onItemChange={toggleButton}
          checked={showLegend}
        />
      </div>

      {showLegend ? (
        <>
          <div style={{paddingBottom: showLegend ? "2em" : 0}}>
            <LegendPosition value={legendPosition} />
          </div>

          <div style={{paddingBottom: "2rem"}}>
            <SelectFontSize
              value={round(legendFontSize * chartDefaultFontSize)}
              onSelect={updateLegendFontSize}
              scale={scale}
            />
          </div>

          <div style={{display: "flex"}}>
            <Title>Color</Title>
            <WrapColorBox>
              <PopoverColorBox
                value={legendColor}
                updateFieldStyles={updateLegendColor}
                onDebounce={handleColorDebounce}
              />
            </WrapColorBox>
          </div>
        </>
      ) : undefined}

      {/* {showLegend ? <LegendPosition value={legendPosition} /> : undefined} */}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  updateFieldCollaboration,
  storeFieldUndoRedo,
})(EditChartLegend);
