import React from "react";
import {createPortal} from "react-dom";
import FieldAlign from "../smartAlign/FieldAlign";

const DisplayAlignments = ({field, tempScale}) => {
  return createPortal(
    field ? <FieldAlign tempScale={tempScale} /> : undefined,
    document.getElementById("oat-item-drag-resize")
  );
};

export default DisplayAlignments;
