import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {LeftArrow} from "../../../../../../oat-svg-icons";
import {selectWorkspaceID} from "../../../../../store/selectors/template/template.selector";
import FolderList from "./FolderList";
import WorkspaceList from "./WorkspaceList";
import {
  createDesignSwitchTab,
  fetchUserTempForSelection,
} from "../../../../../store/actions/template/create/templateList.action";

const ProjectDirectory = ({
  user,
  workspaceID,
  fetchUserTempForSelection,
  createDesignSwitchTab,
  onSelectFolder,
  onClose,
}) => {
  const [currentTab, setCurrentTab] = useState("current-workspace");
  const [selectedWorkspace, setSelectedWorkspace] = useState({
    ID: workspaceID,
    name: null,
  });

  const switchFolderTab = () => {
    setCurrentTab("folder-list");
  };

  const loadLibraryTemplates = async () => {
    onClose();
    await fetchUserTempForSelection({
      api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${workspaceID}&folderID=library-templates`,
      paginate: false
    });
    createDesignSwitchTab("template-collection");
  };

  const handleFolderSelection = async (folderID) => { 
    onSelectFolder(folderID);
    onClose();
    if (folderID !== "home") {
      await fetchUserTempForSelection({
        api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${selectedWorkspace.ID}&folderID=${folderID}`,
        paginate: false
      });
    } else {
      // retrieve recent templates
      await fetchUserTempForSelection({
        api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${selectedWorkspace.ID}&folderID=home`,
        paginate: false
      });
    }
    createDesignSwitchTab("template-collection");
  };

  const onSelectWorkspace = (workspace) => {
    setCurrentTab("current-workspace");
    setSelectedWorkspace({
      ID: workspace.ID,
      name: workspace.name,
    });
  };

  let displayView = null;
  switch (currentTab) {
    case "current-workspace":
      displayView = (
        <>
          <Item onClick={switchFolderTab}>Folders</Item>
          <Item onClick={loadLibraryTemplates}>Library</Item>
        </>
      );
      break;
    case "folder-list":
      displayView = (
        <FolderList
          username={user.username}
          workspace={selectedWorkspace.ID}
          onSelect={handleFolderSelection}
        />
      );
      break;
    case "workspace-list":
      displayView = (
        <WorkspaceList username={user.username} onSelect={onSelectWorkspace} />
      );
      break;
    default:
      displayView = "";
  }

  const navigateBack = () => {
    if (currentTab === "current-workspace") {
      setCurrentTab("workspace-list");
    } else {
      setCurrentTab("current-workspace");
    }
  };

  return (
    <Layout>
      {currentTab === "current-workspace" || currentTab === "folder-list" ? (
        <Header>
          <WrapSvg onClick={navigateBack}>{LeftArrow}</WrapSvg>
          <div>
            {selectedWorkspace.name ? selectedWorkspace.name : "Workspace"}
          </div>
        </Header>
      ) : undefined}
      <Body>
        <div>{displayView}</div>
      </Body>
    </Layout>
  );
};

const Layout = styled.div`
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 0.95rem;
`;

const Header = styled.div`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  font-weight: 700;
  border-bottom: 1px solid ${({theme}) => theme.deskGap};
  margin-bottom: 6px;
`;

const Body = styled.div`
  overflow-y: scroll;
  height: 188px;
  padding: 5px 24px 0;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
  }
`;

const Item = styled.div`
  padding: 8px 0px;
  cursor: pointer;
`;

const WrapSvg = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
  padding-right: 12px;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspaceID: selectWorkspaceID(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  fetchUserTempForSelection,
  createDesignSwitchTab,
})(ProjectDirectory);
