import React from "react";
import {OatImagesIcons} from "../../../packages/oat-templates";
import styled from "styled-components";
import {LayoutHoc} from "../../../packages/oat-public";

const ImagesIcons = () => {
  return (
    <LayoutHoc>
      <Wrapper>
        <OatImagesIcons />
      </Wrapper>
    </LayoutHoc>
  );
};

const Wrapper = styled.div``;

export default ImagesIcons;
