import styled from "styled-components";

export const ActiveDot = styled.div`
  display: block;
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #5e65c1;
  margin-right: 2px;
`;
