import styled from "styled-components";

export const WrapCollection = styled.div`
  height: 100%;
  padding: 15px;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Collection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 0 22px;
  border-bottom: ${({theme}) => `1px solid ${theme.deskGap}`};
  &:last-child {
    border: none;
    margin: 6px 0 0;
  }
`;

export const Header = styled.div`
  padding: 0 0 0.7rem 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({theme}) => theme.deskSecondaryTxt};
`;

export const XScrollItems = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemName = styled.p`
  font-size: 0.85rem;
  text-transform: capitalize;
  padding: 8px 0;
  width: 130px;
  color: ${({theme}) => theme.deskPrimaryTxt};
  text-align: center;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  @media only screen and (min-width: 850px) {
    &:hover {
      background: ${({theme}) => theme.secondaryThemeHover};
    }
  }
`;
