import React from "react";
import Hoc from "../../hoc/Hoc";
import styled from "styled-components";
import {Button, Spinner} from "../../../oat-ui";
import {HeaderSection, Layout, WrapButton} from "../../reuseStyles/reuseStyles";
import {workspaceWidth} from "../../components/layout/WorkSpace";
import {
  Items,
  Header,
} from "../../components/dashboardArea/templates/templateListStyles";
import CustomerList from "../../components/dashboardArea/customers/CustomerList";

const MyanmarCusomters = () => {
  const toggleModal = () => {};

  return (
    <Hoc>
      <Layout>
        <HeaderSection>
          <Header>Myanmar Customer List</Header>
          <WrapButton>
            <Button onClick={toggleModal}>Add Customer</Button>
          </WrapButton>
        </HeaderSection>
        <Items style={{width: window.innerWidth - workspaceWidth - 15}}>
          <CustomerList />
        </Items>
      </Layout>
    </Hoc>
  );
};

export default MyanmarCusomters;
