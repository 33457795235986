import {genUID} from "../../../../../oat-utility";

export const getHighestZIndex = (template, activeSlide) => {
  let highestZIndex = 0;
  const slide = template[activeSlide];
  Object.entries(slide.fields).map(([id, field]) => {
    if (field.styles.zIndex > highestZIndex) {
      highestZIndex = field.styles.zIndex;
    }
  });
  return highestZIndex;
};

export const createShortKey = (length) => {
  return genUID(length ? length : 6);
};

export const createSlideID = () => {
  // only want slideID to be short 5 digits without capitals
  return Math.random().toString(36).substring(2, 7);
};
