import React, {useRef} from "react";
import styled from "styled-components";
import {calcGradient} from "../utils/calcGradient";
import tinycolor from "tinycolor2";

const ColorPanel = ({hsv, hsl, onChange, onSelectionEnd}) => {
  const panelRef = useRef();
  const lastTouchEventRef = useRef(null);

  const handleChange = (e) => {
    const getColor = calcGradient(e, hsl, panelRef?.current);
    const newColor = tinycolor.fromRatio({
      h: getColor?.h,
      s: getColor?.s,
      v: getColor?.v,
    });
    onChange(newColor);
    e.stopPropagation();
    lastTouchEventRef.current = e;
  };

  const onChangeStart = (e) => {
    // !important only for desktop
    if (window.innerWidth >= 850) {
      e.preventDefault();
    }
    handleChange(e);
    window.addEventListener("touchmove", handleChange);
    window.addEventListener("touchend", handleMouseUp);
    window.addEventListener("mousemove", handleChange);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mouseleave", handleMouseUp);
    lastTouchEventRef.current = e;
  };

  const handleMouseUp = (e) => {
    if (lastTouchEventRef.current) {
      const getColor = calcGradient(
        lastTouchEventRef.current,
        hsl,
        panelRef?.current
      );
      const newColor = tinycolor.fromRatio({
        h: getColor?.h,
        s: getColor?.s,
        v: getColor?.v,
      });
      onSelectionEnd(newColor);
      removeEventListeners();
    }
  };

  const removeEventListeners = () => {
    window.removeEventListener("touchmove", handleChange);
    window.removeEventListener("touchend", handleMouseUp);
    window.removeEventListener("mousemove", handleChange);
    window.removeEventListener("mouseup", handleMouseUp);
    window.removeEventListener("mouseleave", handleMouseUp);
  };

  return (
    <Wrapper
      ref={panelRef}
      onMouseDown={onChangeStart}
      onTouchMove={onChangeStart}
      onTouchStart={onChangeStart}
    >
      <div
        style={{
          background: `hsl(${hsl.h}, 100%, 50%)`,
          width: "100%",
          height: "97%",
          position: "relative",
        }}
      >
        <BrightSaturate />
        <DarkSaturate />
        <WhiteGradient>
          <DarkGradient />
          <Selector
            style={{
              top: `${-(hsv.v * 100) + 100}%`,
              left: `${hsv.s * 100}%`,
            }}
          >
            <div />
          </Selector>
        </WhiteGradient>
      </div>
    </Wrapper>
  );
};

const BrightSaturate = styled.div`
  position: absolute;
  background: --linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 849) {
    outline: 1px solid #231e40;
  }
`;

const DarkSaturate = styled.div`
  position: absolute;
  background: -webkit-linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  user-select: none;
  @media only screen and (max-width: 849px) {
    height: 90px;
  }
`;

const WhiteGradient = styled.div`
  absolute: 0px 0px 0px 0px;
`;

const DarkGradient = styled.div`
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15),
    inset 0 0 4px rgba(0, 0, 0, 0.25);
  absolute: 0px 0px 0px 0px;
`;

const Selector = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 50%;
  transform: translate(-3px, -3px);
  > div {
    box-shadow: 0 0 4px 1px rgb(10 10 10 / 38%), 0 0 0 1px rgb(67 79 123 / 18%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
`;

export default ColorPanel;
