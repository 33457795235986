import {setSmartAignmentPoints} from "../utils";

export function collaboratorActiveField() {
  return function (state, action) {
    const newState = {...state.usersOnline.users};
    const {slideID, fieldID, username} = action.active;
    newState[username] = {
      ...newState[username],
      activeSlide: slideID,
      activeField: fieldID,
      active: true,
      groupSelection: {
        keys: [],
        selection: {
          show: false,
          offset: {x: null, y: null},
          size: {w: 0, h: 0},
          orgOffset: {x: null, y: null},
          orgSize: {w: 0, h: 0},
        },
        initFieldValues: {},
      },
    };
    return {
      ...state,
      usersOnline: {
        ...state.usersOnline,
        users: {
          ...newState,
        },
      },
    };
  };
}

export function collaboratorDeselectField() {
  return function (state, action) {
    return {
      ...state,
      usersOnline: {
        ...state.usersOnline,
        users: {
          ...state.usersOnline.users,
          [action.username]: {
            ...state.usersOnline.users[action.username],
            activeField: null,
            active: false,
            groupSelection: {
              keys: [],
              selection: {
                show: false,
                offset: {x: null, y: null},
                size: {w: 0, h: 0},
                orgOffset: {x: null, y: null},
                orgSize: {w: 0, h: 0},
              },
              initFieldValues: {},
            },
          },
        },
      },
    };
  };
}

export function collaborationFieldChanged() {
  return function (state, action) {
    const {slideID, fieldID} = action?.active;
    const newState = [...state.template];
    const slideIndex = newState.findIndex((slide) => slide.id === slideID);
    if (slideIndex !== -1 || slideIndex !== null || slideIndex !== undefined) {
      newState[slideIndex].fields[fieldID] = action.payload;
      const {xAlign, yAlign} = setSmartAignmentPoints({
        template: state.template,
        slideID: slideIndex,
        fieldIDs: [action.payload.key],
        tempScale: state.layout.tempScale,
        tempSize: state.size,
      });

      return {
        ...state,
        template: newState,
        smartAlignment: {
          ...state.smartAlignment,
          points: {
            x: xAlign,
            y: yAlign,
          },
          x: {
            display: false,
            value: null,
          },
          y: {
            display: false,
            value: null,
          },
        },
      };
    } else {
      return state;
    }
  };
}

export function updateFieldCollaboration() {
  return function (state) {
    if (state.activeField !== -1 && state.activeSlide !== -1) {
      const newState = [...state.template];
      return {
        ...state,
        fieldToEmitFromSocket: {
          type: "update",
          field: newState[state.activeSlide].fields[state.activeField],
        },
      };
    } else {
      return state;
    }
  };
}

export function deleteFieldsCollaboration() {
  return function (state, action) {
    const updatedItems = action.payload;
    const newState = [...state.template];
    const {slideID, username} = action.active;
    const currentSlideIndex = newState.findIndex(
      (slide) => slide.id === slideID
    );
    if (updatedItems.length === 1) {
      const field = newState[currentSlideIndex].fields[updatedItems[0]];
      newState[currentSlideIndex].fields[updatedItems[0]] = {
        ...field,
        deleted: true,
      };
      return {
        ...state,
        template: newState,
        usersOnline: {
          ...state.usersOnline,
          users: {
            ...state.usersOnline.users,
            [username]: {
              ...state.usersOnline.users[username],
              activeField: null,
              active: false,
              groupSelection: {
                keys: [],
                selection: {
                  show: false,
                  offset: {x: null, y: null},
                  size: {w: 0, h: 0},
                  orgOffset: {x: null, y: null},
                  orgSize: {w: 0, h: 0},
                },
                initFieldValues: {},
              },
            },
          },
        },
      };
    } else {
      // REMOVE Multiple Fields (group select)
      const updateFields = Object.entries(
        newState[currentSlideIndex].fields
      ).map(([id, field]) => {
        if (updatedItems?.includes(field.key)) {
          return {
            ...field,
            deleted: true,
          };
        } else {
          return field;
        }
      });
      newState[currentSlideIndex] = {
        ...newState[currentSlideIndex],
        fields: updateFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
      return {
        ...state,
        template: newState,
        usersOnline: {
          ...state.usersOnline,
          users: {
            ...state.usersOnline.users,
            [username]: {
              ...state.usersOnline.users[username],
              activeField: null,
              active: false,
              groupSelection: {
                keys: [],
                selection: {
                  show: false,
                  offset: {x: null, y: null},
                  size: {w: 0, h: 0},
                  orgOffset: {x: null, y: null},
                  orgSize: {w: 0, h: 0},
                },
                initFieldValues: {},
              },
            },
          },
        },
      };
    }
  };
}

export function updateUndoRedoFieldsOfCollaboration() {
  return function (state, action) {
    const newState = [...state.template];
    const {payload, active} = action;
    const {slideID, type, item} = payload;
    const getSlideIndex = newState.findIndex((item) => item.id === slideID);
    if (type === "field-update") {
      newState[getSlideIndex] = {
        ...newState[getSlideIndex],
        fields: {
          ...newState[getSlideIndex].fields,
          [item.key]: item,
        },
      };
    } else if (type === "create") {
      newState[getSlideIndex] = {
        ...newState[getSlideIndex],
        fields: {
          ...newState[getSlideIndex].fields,
          [item.key]: item,
        },
      };
    } else if (type === "delete") {
      Object.entries(newState[getSlideIndex].fields).map(([id, item]) => {
        if (id === item.key) {
        }
      });
      delete newState[getSlideIndex].fields[item.key];
    } else if (type === "background") {
      newState[getSlideIndex] = {
        ...newState[getSlideIndex],
        bg: item,
      };
    } else if (type === "group-delete") {
      const {groupedKeys} = payload;
      const updateFields = Object.entries(newState[getSlideIndex].fields).map(
        ([id, field]) => {
          if (groupedKeys?.includes(field.key)) {
            return {
              ...field,
              deleted: true,
            };
          } else {
            return field;
          }
        }
      );
      newState[getSlideIndex] = {
        ...newState[getSlideIndex],
        fields: updateFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    } else if (type === "group-create") {
      const {groupedKeys} = payload;
      const updateFields = Object.entries(newState[getSlideIndex].fields).map(
        ([id, field]) => {
          if (groupedKeys?.includes(field.key)) {
            return {
              ...field,
              deleted: false,
            };
          } else {
            return field;
          }
        }
      );
      newState[getSlideIndex] = {
        ...newState[getSlideIndex],
        fields: updateFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    } else if (type === "group-fields-drag-resize") {
      const {groupedKeys} = payload;
      for (let i = 0; i < groupedKeys.length; i++) {
        newState[getSlideIndex].fields[groupedKeys[i]] = item[groupedKeys[i]];
      }
    }

    return {
      ...state,
      template: newState,
      usersOnline: {
        ...state.usersOnline,
        users: {
          ...state.usersOnline.users,
          [active.username]: {
            ...state.usersOnline.users[active.username],
            activeField: null,
            active: false,
            groupSelection: {
              keys: [],
              selection: {
                show: false,
                offset: {x: null, y: null},
                size: {w: 0, h: 0},
                orgOffset: {x: null, y: null},
                orgSize: {w: 0, h: 0},
              },
              initFieldValues: {},
            },
          },
        },
      },
    };
  };
}

export const createFieldFromSocket = () => {
  return function (state, action) {
    const {template} = state;
    const {slideID, fieldID, username} = action.active;
    const newState = [...state.template];
    const slideIndex = template.findIndex((item) => item.id === slideID);
    newState[slideIndex].fields = {
      ...newState[slideIndex].fields,
      [fieldID]: action.field,
    };

    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID: slideIndex,
      fieldIDs: [fieldID],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });

    return {
      ...state,
      template: newState,
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
      usersOnline: {
        ...state.usersOnline,
        users: {
          ...state.usersOnline.users,
          [username]: {
            ...state.usersOnline.users[username],
            activeSlide: slideIndex,
            activeField: fieldID,
            active: true,
          },
        },
      },
    };
  };
};

export function pasteFieldFromSocket() {
  return function (state, action) {
    const {template} = state;
    const {active, payload} = action;
    const {slideID, fieldID} = active;
    const newState = [...template];
    const slideIndex = template.findIndex((slide) => slide.id === slideID);
    if (!payload.groupedFields) {
      newState[slideIndex].fields = {
        ...newState[slideIndex].fields,
        [fieldID]: payload.updatedItems[0],
      };
      return {
        ...state,
        template: newState,
        usersOnline: {
          ...state.usersOnline,
          users: {
            ...state.usersOnline.users,
            [active.username]: {
              ...state.usersOnline.users[active.username],
              activeSlide: slideIndex,
              activeField: fieldID,
              groupSelection: {
                ...state.usersOnline.users[active.username].groupSelection,
                selection: {
                  show: false,
                  size: {w: null, h: null},
                  offset: {x: null, y: null},
                  orgSize: {w: null, h: null},
                  orgOffset: {x: null, y: null},
                },
              },
            },
          },
        },
      };
    } else {
      const {groupedArea, updatedItems} = payload;
      const addedItems = updatedItems.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      );
      newState[slideIndex].fields = {
        ...newState[slideIndex].fields,
        ...addedItems,
      };
      return {
        ...state,
        template: newState,
        usersOnline: {
          ...state.usersOnline,
          users: {
            ...state.usersOnline.users,
            [active.username]: {
              ...state.usersOnline.users[active.username],
              activeSlide: slideID,
              activeField: -1,
              groupSelection: {
                ...state.usersOnline.users[active.username].groupSelection,
                selection: {
                  show: true,
                  ...groupedArea,
                },
              },
            },
          },
        },
      };
    }
  };
}

export const resetFieldToEmitFromSocket = () => {
  return function (state) {
    return {
      ...state,
      fieldToEmitFromSocket: {},
    };
  };
};

export function addNewSlideCollaboration() {
  return function (state, action) {
    const newState = [...state.template];
    const {insertAfterSlideID, payload} = action;
    const getSlideIndex = newState.findIndex(
      (item) => item.id === insertAfterSlideID
    );
    newState.splice(getSlideIndex + 1, 0, payload);
    return {
      ...state,
      template: newState,
    };
  };
}

export function collaborateGroupFieldsAsOne() {
  return function (state, action) {
    const {template} = state;
    const {payload, active} = action;
    const { username, slideID } = active;
    const groupSelect = payload;
    const slideIndex = template.findIndex((item) => item.id === slideID);
    if (groupSelect.selection.show && !groupSelect?.groupAsOne) {
      const newState = [...template];
      const updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (groupSelect.keys?.includes(field.key)) {
            return {
              ...field,
              groupAsOne: {
                keys: groupSelect.keys,
                size: groupSelect.selection.size,
                offset: groupSelect.selection.offset
              }
            }
          } else {
            return field;
          }
      });

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    
      return {
        ...state,
        template: newState,
        usersOnline: {
          ...state.usersOnline,
          users: {
            ...state.usersOnline.users,
            [username]: {
              ...state.usersOnline.users[username],
              activeField: null,
              active: false,
              groupSelection: {
                keys: [],
                selection: groupSelect.selection,
                initFieldValues: {},
              },
            },
          },
        },
      };
    } else if (groupSelect?.groupAsOne) {
      // ungroup
      const newState = [...template];
      const updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (groupSelect.keys?.includes(field.key)) {
            return {
              ...field,
              groupAsOne: {
                keys: [],
              }
            }
          } else {
            return field;
          }
      });

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        )
      };
    
      return {
        ...state,
        template: newState,
        users: {
          ...state.usersOnline.users,
          [username]: {
            ...state.usersOnline.users[username],
            activeField: null,
            active: false,
            groupSelection: {
              keys: [],
              selection: {
                show: false,
                offset: {x: null, y: null},
                size: {w: 0, h: 0},
                orgOffset: {x: null, y: null},
                orgSize: {w: 0, h: 0},
              },
              initFieldValues: {},
            },
          },
        },
      };
    }
    return state;
  };
}