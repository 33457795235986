import React, {useEffect, useState} from "react";
import styled from "styled-components";
import ColorPickerPopper from "./ColorPickerPopper";
import DeskColorOptions from "./DeskColorOptions";
import { selectActiveFieldProps } from "../../../../../../store/selectors/fields.selector";

// let delayDebounce;
const PopoverColorBox = ({value, updateFieldStyles, onDebounce}) => {
  const [box, setBox] = useState({
    open: false,
    offset: {x: null, y: null},
  });
  const [firstTimeChanges, setFirstTimeChanges] = useState(true);

  const onColorChange = (value) => {
    if (firstTimeChanges) {
      setFirstTimeChanges(!firstTimeChanges);
    }
    updateFieldStyles(value);

    // clearTimeout(delayDebounce);
    // delayDebounce = setTimeout(() => {
    //   onDebounce(value);
    // }, 5000);
    // return () => clearTimeout(delayDebounce);
  };

  useEffect(() => {
    if (!box.open && !firstTimeChanges) {
      setFirstTimeChanges(!firstTimeChanges);
    }
  }, [box.open]);

  const openColorBox = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setBox({
      open: true,
      offset: {x: e.clientX, y: e.clientY},
    });
  };

  const closeColorBox = () => {
    setBox({
      ...box,
      open: false,
    });
  };

  return (
    <Wrapper>
      <div style={{flex: 1, position: "relative"}}>
        <SelectColor
          onClick={openColorBox}
          style={{backgroundColor: value}}
        />
        <div style={{flex: 1}}>
          {box.open ? (
            <ColorPickerPopper offset={box.offset} onClose={closeColorBox}>
              <DeskColorOptions
                activeColor={value}
                updateFieldStyles={onColorChange}
                onSelectionEnd={onDebounce}
                onClose={closeColorBox}
              />
            </ColorPickerPopper>
          ) : (
            ""
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const SelectColor = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #c5c5c5c2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

PopoverColorBox.defaultProps = {
  enableUndo: true,
};

export default PopoverColorBox;
