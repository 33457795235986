import React from "react";
import styled from "styled-components";
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
} from "../../../../../../ui/svg/TextAligns";
import {Title} from "../../shared/reuseStyles/Title";

const TextAlign = ({onSelect}) => {
  return (
    <>
      <Title>Text Align</Title>
      <div style={{flex: 1, display: "flex"}}>
        <WrapTxtAlign onClick={() => onSelect("left")}>
          <AlignLeft />
        </WrapTxtAlign>

        <WrapTxtAlign onClick={() => onSelect("center")}>
          <AlignCenter />
        </WrapTxtAlign>

        <WrapTxtAlign onClick={() => onSelect("right")}>
          <AlignRight />
        </WrapTxtAlign>
      </div>
    </>
  );
};

const WrapTxtAlign = styled.div`
  fill: ${({theme}) => theme.deskSecondaryTxt};
  cursor: pointer;
  margin: 0 12px;
  &:first-child {
    margin-left: 0;
  }
`;

export default TextAlign;
