import React, {useState} from "react";
import {
  Button,
  ButtonSpinner,
  ShowError,
  ToggleSwitch,
} from "../../../../oat-ui";
import {Field, Value} from "./DisplayUserInfo";
import {
  FieldName,
  Header,
  Footer,
  BottomSection,
  ContentSection,
  Cancel,
  TextField,
} from "../../reuse/dashboardUI/modalStyles";
import {Auth} from "aws-amplify";

const UpdatePassword = ({onCancel}) => {
  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState({
    old: "",
    new: "",
    showPassword: false,
  });
  const [error, setError] = useState({status: false, message: null});

  const handleOldPassword = (e) => {
    const {value} = e.target;
    setPassword({
      ...password,
      old: value,
    });
  };

  const handleNewPassword = (e) => {
    if (password.new.length < 98) {
      const {value} = e.target;
      setPassword({
        ...password,
        new: value,
      });
    }
  };

  const handleSubmit = () => {
    if (error.status) {
      setError({status: false, message: null});
    }
    if (password.new.length < 6) {
      setError({
        status: true,
        message: "Password length cannot be less than 6.",
      });
      return;
    }
    if (!processing) {
      setProcessing(true);
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, password.old, password.new);
        })
        .then((data) => {
          setProcessing(false);
          onCancel("display-user-info");
        })
        .catch((err) => {
          setError({status: true, message: err.message});
          setProcessing(false);
        });
    }
  };

  const handleCancel = () => {
    onCancel("display-user-info");
  };

  const toggleShowPassword = () => {
    setPassword({...password, showPassword: !password.showPassword});
  };

  return (
    <>
      <ContentSection>
        <Header>Edit Password</Header>
        <Field>
          <FieldName>Old Password</FieldName>
          <Value>
            <TextField
              type={!password.showPassword ? "password" : "text"}
              value={password.old}
              onChange={handleOldPassword}
            />
          </Value>
        </Field>
        <Field>
          <FieldName>New Password</FieldName>
          <Value>
            <TextField
              type={!password.showPassword ? "password" : "text"}
              value={password.new}
              onChange={handleNewPassword}
            />
          </Value>
        </Field>
        <Field>
          <FieldName>Show</FieldName>
          <Value>
            <ToggleSwitch
              value={password.showPassword}
              onItemChange={toggleShowPassword}
            />
          </Value>
        </Field>

        {error.status ? <ShowError message={error.message} /> : undefined}
      </ContentSection>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Cancel onClick={handleCancel}>Cancel</Cancel>
          <div style={{width: 120, height: "100%"}}>
            <Button disabled={processing} onClick={handleSubmit}>
              {!processing ? "Edit" : <ButtonSpinner />}
            </Button>
          </div>
        </BottomSection>
      </Footer>
    </>
  );
};

export default UpdatePassword;
