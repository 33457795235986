import React, {useRef} from "react";
import styled from "styled-components";
import {detectClickOutside} from "../../../../../../oat-ui";
import {Bouncy} from "../../../../../../oat-ui/animation/bouncy";

// Popper inside of modal must create its own popper to reside in modal.
// else clicking will create a problem
const DirectoryPopper = ({children, onClose, width, height, offset}) => {
  const popUpRef = useRef(null);

  detectClickOutside(popUpRef, () => onClose());

  return (
    <Wrapper ref={popUpRef}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 58px;
  right: 10px;
  width: 200px;
  height: 200px;
  border-radius: 7px;
  z-index: 999999;
  height: auto;
  overflow: hidden;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1}; // 0px 0px 3px 0px #f0eaff;
  ${Bouncy};
`;

const Content = styled.div`
  overflow-y: scroll;
  box-sizing: content-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

DirectoryPopper.defaultProps = {
  width: 260,
  height: 220,
  offset: {
    x: 0,
    y: 0,
  },
};

export default DirectoryPopper;
