import styled from "styled-components";

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

export const Title = styled.div`
  color: #fff;
  font-size: 0.9em;
  letter-spacing: 0.5px;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
`;

export const Item = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5em 1.5em;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
  outline: none;
  flex-shrink: 0;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none; 
`;
