import React from "react";
import {Modal} from "../../../../../oat-ui";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";

const DisplayMessage = ({message, close}) => {
  return (
    <Modal width={270} height="auto" close={close}>
      <Wrapper>
        <WrapMessage>{message}</WrapMessage>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  padding: 26px 12px;
  box-sizing: border-box;
`;

const WrapMessage = styled.div`
  font-size: 1rem;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(DisplayMessage);
