import React, {useRef, useEffect, useState} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {detectClickOutside} from "../../../../../../oat-ui";
import {timeSince} from "../../../../../../oat-utility";
import {sendChat} from "../../../../../store/actions/template/chat.action";
import {
  selectChats,
  selectUsersOnLine,
} from "../../../../../store/selectors/common.selector";
import {selectTemplateID} from "../../../../../store/selectors/template/template.selector";
import CanvasPopper from "../../../../../ui/CanvasPopper";
import {useSocket} from "../../../../../webSocket/useSocket";

const popperSize = {
  width: 310,
  height: 308,
};

const ChatOnTemplate = ({
  user,
  tempID,
  chats,
  noActiveSlideExists,
  sendChat,
  usersOnline,
}) => {
  const [message, setMessage] = useState("");
  const [messageCount, setMessageCount] = useState({
    unopened: 0,
    viewed: 0,
    total: 0,
  });
  const [usersAvailableOnline, setUsersAvailableOnline] = useState(true);
  const [popper, setPopper] = useState({
    open: false,
    offset: {x: 0, y: 0},
  });
  const {socket} = useSocket() || {};
  const layoutRef = useRef();

  const changeMsgInput = (e) => {
    const {value} = e.target;
    setMessage(value);
  };

  const onSubmitMessage = (event) => {
    if (event.keyCode == 13 && socket?.current) {
      setMessage("");
      sendChat({user: user.name, message});
      socket?.current?.send(
        JSON.stringify({
          action: "chatOnTemplate",
          tempID,
          payload: {
            user: user.name,
            message,
          },
        })
      );
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onSubmitMessage, false);
    return () => {
      document.removeEventListener("keydown", onSubmitMessage, false);
    };
  });

  useEffect(() => {
    const messagesSentByOthers =
      chats && chats.filter((chat) => chat.user !== user.name);
    if (messagesSentByOthers.length > 0 && !popper.open) {
      const numberOfNewMessages = chats.length - messageCount.viewed;
      setMessageCount({
        ...messageCount,
        unopened: numberOfNewMessages,
        total: chats.length,
      });
    } else if (messagesSentByOthers.length > 0 && popper.open) {
      setMessageCount({
        ...messageCount,
        unopened: 0,
        viewed: chats.length,
        total: chats.length,
      });
    }
  }, [chats]);

  const openChatPopper = (e) => {
    if (!noActiveSlideExists && !popper.open) {
      setPopper({
        open: true,
        offset: {x: e.pageX - popperSize.width / 2, y: 60},
      });
      setMessageCount({
        ...messageCount,
        viewed: chats.length,
        unopened: 0,
      });
    }
  };

  const closePopper = () => {
    setPopper({
      ...popper,
      open: false,
    });
    setMessageCount({
      ...messageCount,
      unopened: 0,
    });
  };

  detectClickOutside(layoutRef, () => {
    setPopper({open: false});
  });

  const calculateTimeSince = (itemTimestamp) => {
    const currentTimeStamp = new Date().getTime();
    return timeSince(currentTimeStamp, itemTimestamp);
  };

  return (
    <>
      <UserListSection ref={layoutRef} onClick={openChatPopper}>
        <WrapUserIcon>
          {/* a single user online */}
          {Object.keys(usersOnline.users).length === 0 ? (
            <CurrentUserAvatar alt="avatar" src={user?.avatar} />
          ) : undefined}

          {/* two users online  usersOnline.users */}
          {Object.keys(usersOnline.users).length === 1
            ? Object.entries(usersOnline.users).map(([id, field], index) => {
                if (user.username === id) {
                  return (
                    <CurrentUserAvatar
                      key={id}
                      alt="avatar"
                      src={field?.avatar}
                    />
                  );
                } else if (index === 0) {
                  return (
                    <div key={id}>
                      <CurrentUserAvatar alt="avatar" src={field?.avatar} />
                      <UserAvatar
                        alt="avatar"
                        src={user?.avatar}
                        style={{
                          position: "absolute",
                          left: 18,
                        }}
                      />
                    </div>
                  );
                }
              })
            : undefined}

          {/* multiple users */}
          {Object.keys(usersOnline.users).length > 1 ? (
            <div style={{display: "flex"}}>
              <CurrentUserAvatar
                alt="avatar"
                src={user?.avatar}
                style={{zIndex: 1}}
              />
              <NumberOfUsers
                style={{position: "absolute", left: 18, zIndex: 2}}
              >
                +{Object.keys(usersOnline.users).length - 1}
              </NumberOfUsers>
            </div>
          ) : undefined}
        </WrapUserIcon>

        {messageCount.unopened > 0 ? (
          <MessageCount>
            {messageCount.unopened > 9 ? "+9" : messageCount.unopened}
          </MessageCount>
        ) : undefined}
      </UserListSection>

      {popper.open ? (
        <CanvasPopper
          width={popperSize.width}
          height={popperSize.height}
          offset={{x: popper.offset.x, y: popper.offset.y}}
          styles={{boxShadow: "0px 0px 3px 0px #827f8d"}}
          onClose={closePopper}
        >
          {usersAvailableOnline ? (
            <Layout>
              <ChatArea>
                {chats.length === 0 ? (
                  <ShowChatInfo>
                    Chats are temporary & only available to users currently
                    online.
                  </ShowChatInfo>
                ) : undefined}
                {chats &&
                  chats.map((chat, i) => (
                    <Message key={`${i}-${chat.user}`}>
                      <div className="chat-username">
                        {chat.user} :{" "}
                        <span>{calculateTimeSince(chat.timestamp)}</span>
                      </div>
                      <div className="chat-message">{chat.message}</div>
                    </Message>
                  ))}
              </ChatArea>
              <EnterMessage>
                <input
                  autoFocus
                  value={message}
                  placeholder=""
                  onChange={changeMsgInput}
                />
              </EnterMessage>
            </Layout>
          ) : (
            <NoOneOnline>Nobody is online.</NoOneOnline>
          )}
        </CanvasPopper>
      ) : undefined}
    </>
  );
};

const UserListSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const WrapUserIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const reuseAvatarStyles = css`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  user-select: none;
`;

const CurrentUserAvatar = styled.img`
  ${reuseAvatarStyles};
  border: 2px solid #7c80ff;
  object-fit: cover;
`;

const UserAvatar = styled.img`
  ${reuseAvatarStyles};
  border: 2px solid #eb9f06;
  object-fit: cover;
`;

const NumberOfUsers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  ${reuseAvatarStyles};
  background: #d1d4ff;
  border: 2px solid #eb9f06;
  box-shadow: 1px 1px 1px 1px #232323;
`;

const Layout = styled.div`
  padding: 2px 0;
  box-sizing: border-box;
  background: ${({theme}) => theme.deskPrimaryBg};
`;

const ChatArea = styled.div`
  height: 228px;
  margin-bottom: 12px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ShowChatInfo = styled.div`
  font-size: 0.91rem;
  font-style: italic;
  color: ${({theme}) => theme.deskSecondaryTxt2};
`;

const EnterMessage = styled.div`
  input {
    width: 100%;
    height: 40px;
    color: ${({theme}) => theme.deskPrimaryTxt};
    border: 1px solid ${({theme}) => theme.deskGap};
    border-radius: 6px;
    background: transparent;
    box-sizing: border-box;
    font-family: inherit;
  }
`;

const Message = styled.div`
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 20px;
  .chat-username {
    display: flex;
    justify-content: space-between;
    color: ${({theme}) => theme.deskTertiaryTxt};
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
      display: none;
      font-size: 0.77rem;
      color: #8b8b8b;
    }
  }
  .chat-message {
  }
  &:hover {
    .chat-username {
      span {
        display: flex;
      }
    }
  }
`;

const NoOneOnline = styled.div`
  font-size: 0.9rem;
  text-align: center;
  padding-top: 3.2rem;
`;

const MessageCount = styled.div`
  position: absolute;
  top: 25px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid #e07882;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 700;
  background: #ffb7d2;
  color: #322e2e;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    tempID: selectTemplateID(state.designTemplate),
    chats: selectChats(state.designTemplate),
    usersOnline: selectUsersOnLine(state.designTemplate),
  };
};

export default connect(mapStateToProps, {sendChat})(ChatOnTemplate);
