import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
  FolderSection,
  FolderHeader,
  WrapSvg,
  FolderLayout,
} from "../folderTypes/folderStyles";
import {ArrowDropDown, ArrowDropUp, ExpandArrow, LessArrow, Plus} from "../../../../oat-svg-icons";
import {ActiveDot, Item} from "../reusableStyles/sidebarItem";
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {selectActiveWorkSpace, selectFolders} from "../../../store/selectors/userDashboard.selector";
import {deselectActiveFolder, onFolderChange} from "../../../store/actions/folder.action";
import LibFolderImages from "./LibFolderImages";
import {ExpandFolderSvg} from "../folderTypes/Folder";
import {Popper} from "../../../../oat-ui";
import CreateImageFolder from "./CreateImageFolder";
import FolderActions from "../folderTypes/FolderActions";
import {libraryList} from "../../../containers/UserDashboard";

const DisplayItem = ({
    item, 
    active, 
    activeFolder, 
    libImageFolders, 
    onSelect, 
    onRightClick,
    toCreateFolder
}) => {
  const [expandFolders, setExpandFolders] = useState();

  const handleSelectMainFolder = () => {
    onSelect(item);
  };

  const handleSelectSubfolders = (item) => {
    onSelect(item);
  };

  const onExpandSubfolder = () => {
    setExpandFolders(!expandFolders);
  };

  const onSelectFolderCreation = (e) => {
    toCreateFolder(e);
  };

  return (
    <>
      <Item>
        <p onClick={handleSelectMainFolder}>{item.folderName}</p>
        <ActiveDot style={{right: 16}} $active={active} />

        {item.folderID === "images" ? 
          <PlusSvg style={{marginLeft: 8}} onClick={onSelectFolderCreation}>{Plus}</PlusSvg> 
        : undefined}

        {libImageFolders && libImageFolders.length > 0 && !expandFolders && item.folderID === 'images' ? (
          <ExpandFolderSvg onClick={onExpandSubfolder}>{ExpandArrow}</ExpandFolderSvg>
        ) : undefined} 
        {libImageFolders && libImageFolders.length > 0 && expandFolders && item.folderID === 'images' ? (
          <ExpandFolderSvg onClick={onExpandSubfolder}>{LessArrow}</ExpandFolderSvg>
        ) : undefined}
      </Item>

      {expandFolders && item.folderID === 'images' && libImageFolders && libImageFolders.map((folder) => (
        <LibFolderImages 
          key={folder.folderID}
          item={folder} 
          active={activeFolder === folder.folderID} 
          onSelect={handleSelectSubfolders} 
          onRightClick={onRightClick}
        />
      ))}
    </>
  );
};

const UserLibrary = ({workspaceID, onFolderChange, libImageFolders}) => {
  let navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [expand, setExpand] = useState(true);
  const {section, folderID} = useParams();
  const [createFolder, setCreateFolder] = useState({
    status: false, // popper open
    name: "",
    private: false,
    processing: false, // sending request
    error: false,
    errorMessage: null,
    offset: {x: 0, y: 0},
  });
  const [displayActionsPopper, setDisplayActionsPopper] = useState({
    status: false,
    clickedItem: null,
    offset: {x: 0, y: 0},
  });

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const handleFolderChange = (item) => {
    const payload = {
      workspaceID,
      folderID: item.folderID,
      folderType: 'library',
    };
    onFolderChange(payload);
    if (item.folderID === "library-templates") {
      return navigate(`/dashboard/${workspaceID}/designs/${item.folderID}`);
    } else {
      return navigate(`/dashboard/${workspaceID}/library/${item.folderID}`);
    }
  };

  useEffect(() => {
    if (workspaceID && folderID && section === "library") {
      if (folderID === "images") {
        setActiveTab("images");
        setExpand(true);
      } else if (folderID === "fonts") {
        setActiveTab("fonts");
        setExpand(true);
      } else if (folderID === "templates") {
        setActiveTab("templates");
        setExpand(true);
      } else {
        setActiveTab("");
      }
    } else {
      setActiveTab("");
    }
  }, [section, folderID, workspaceID]);

  const handleRightClick = (e, item) => {
    if (e.type === "contextmenu") {
      const YOffset = e.clientY + 160 > window.innerHeight ? e.clientY - 70 : e.clientY;
      setDisplayActionsPopper({
        ...displayActionsPopper,
        status: true,
        clickedItem: {
          ...item, 
          folderType: 'library-images'
        },
        offset: {x: e.clientX, y: YOffset},
      });
      e.preventDefault();
    };
  };

  const toCreateFolder = (e) => {
    setCreateFolder({
      ...createFolder,
      status: true,
      offset: {x: e.clientX, y: e.clientY},
    });
    e.preventDefault();
  };

  const closeCreatePopper = () => {
    setCreateFolder({
      status: false,
      name: "",
      private: false,
      error: false,
      errorMessage: null,
      processing: false,
      accessibleUsers: [],
    });
    if (displayActionsPopper.status) {
      setDisplayActionsPopper({
        ...displayActionsPopper,
        status: false,
        clickedItem: null,
        offset: {x: 0, y: 0},
      });
    }
  };

  const closeActionsPopper = () => {
    setDisplayActionsPopper({
      ...displayActionsPopper,
      status: false,
      clickedItem: null,
      offset: {x: 0, y: 0},
    });
  };

  return (
    <FolderLayout style={{margin: `0 2px ${!expand ? 0 : 12}px 8px`}}>
      <FolderSection style={{paddingBottom: 18}}>
        <WrapSvg style={{paddingRight: 5}} onClick={toggleExpand}>
          {expand ? ArrowDropUp : ArrowDropDown}
        </WrapSvg>
        <FolderHeader onClick={toggleExpand}>
          <p>Library</p>
        </FolderHeader>
      </FolderSection>

      {expand && libraryList &&
        libraryList.map((item, i) => (
          <DisplayItem
            key={item.folderID}
            item={item}
            onSelect={handleFolderChange}
            active={folderID === item.folderID}
            activeFolder={folderID}
            libImageFolders={libImageFolders}
            onRightClick={handleRightClick}
            toCreateFolder={toCreateFolder}
          />
      ))}

      {createFolder.status ? (
        <Popper
          width="auto"
          height="auto"
          offset={{
            x: 100,
            y: createFolder.offset.y + 20,
          }}
          onClose={closeCreatePopper}
        >
          {/* Subfolder creation */}
          <CreateImageFolder
            isParentFolder={false} // hide private access button
            close={closeCreatePopper}
          />
        </Popper>
      ) : undefined}

      {displayActionsPopper.status ? (
        <Popper
          width="auto"
          height="auto"
          offset={{
            x: 200,
            y: displayActionsPopper.offset.y,
          }}
          onClose={closeActionsPopper}
        >
          <FolderActions
            selectedFolder={displayActionsPopper.clickedItem}
            workspaceID={workspaceID}
            libraryFolder={true}
            close={closeActionsPopper}
          />
        </Popper>
      ) : undefined}
    </FolderLayout>
  );
};

const mapStateToProps = (state) => {
  const {userDashboard} = state;
  return {
    workspaceID: selectActiveWorkSpace(userDashboard).ID,
    libImageFolders: selectFolders(userDashboard)['library-images'],
  };
};

const PlusSvg = styled.div`
  width: 18px;
  height: 18px;
  fill: #858383;
`;

export default connect(mapStateToProps, {onFolderChange, deselectActiveFolder})(UserLibrary);
