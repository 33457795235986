import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Title} from "../../shared/reuseStyles/Title";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {
  selectAnimationType,
  applyAnimationOrder,
  onPreviewAnimation,
  applyAnimationOnSlideLoad,
  applyAnimationDelay,
  setAnimationPreviewOption,
  applyAnimationOnExit
} from "../../../../../../store/actions/fields/animation.action";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../../../store/selectors/fields.selector";
import {
  selectActiveSlideID,
  selectActiveSlideInfo,
  selectSlideAnimationOrder,
} from "../../../../../../store/selectors/template/slide.selector";
import DropDown from "../../../../../../ui/inputs/DropDown";
import {redMessage} from "../../../../../../themes";
import {PanelHeader} from "../panelStyles";
import {selectInterceptedFields} from "../../../../../../store/selectors/common.selector";
import {useSocket} from "../../../../../../webSocket/useSocket";
import { Radio, RadioButton } from "../../../../../../../oat-ui/styles/RadioStyles";
import {SplitLine} from "../common/SplitLine";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import DisplayAnimationIcons from "./DisplayAnimationIcons";
import {selectPageTheme} from "../../../../../../store/selectors/layout/layout.selector";
import Switch from "../../shared/inputs/Switch";
import RangeSlider from "../../shared/inputs/RangeSlider";

const animationTypes = [
  {value: "none", name: "None"},
  {value: "no-animate", name: "No Animate"},
  {value: "bottom-to-top", name: "To Top"},
  {value: "top-to-bottom", name: "To Bottom"},
  {value: "left-to-right", name: "To Right"},
  {value: "right-to-left", name: "To Left"},
  {value: "zoom-in", name: "Zoom In"},
  {value: "zoom-out", name: "Zoom Out"},
  {value: "fade-in", name: "Fade In"},
];

const delayOptions = [
  {display: "0.5", value: 0.5},
  {display: "1", value: 1},
  {display: "1.5", value: 1.5},
  {display: "2", value: 2},
  {display: "2.5", value: 2.5},
  {display: "3", value: 3},
  {display: "3.5", value: 3.5},
];

// do not change values
const previewAnimationTypes = [
  {display: "On Slide Display", value: "on-slide-display"},
  {display: "By Block on Click ", value: "by-block-on-click"},
];

const DisplayItem = ({item, active, pageTheme, onSelect}) => {
  const handleClick = () => {
    onSelect(item);
  };

  return (
    <Item active={active} pageTheme={pageTheme} onClick={handleClick} style={{justifyContent: 'space-around', fontSize: 12}}>
      <div div style={{ 
        flex: 2, 
        width: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
     }}>
        <div style={{ width: 25, height: 25 }}>
          <DisplayAnimationIcons type={item?.value} />
        </div>
      </div>
      
      <AnimationTitle>
        {item?.name}
      </AnimationTitle>
    </Item>
  );
};

const ApplyAnimations = ({
  slideID,
  fieldID,
  selectAnimationType,
  animationOrder,
  slideAnimationOrder,
  applyAnimationOrder,
  groupedKeys,
  onPreviewAnimation,
  previewAnimationOn,
  applyAnimationOnSlideLoad,
  applyAnimationDelay,
  styles,
  setAnimationPreviewOption,
  previewAnimationOption,
  pageTheme,
  updateFieldStyles,
  applyAnimationOnExit,
  slideInfo
}) => {
  const animateOnSlideDisplay = styles?.animateOnSlideDisplay;
  const animationDelay = styles?.animationDelay;
  const animationType = styles?.animationType;
  const [error, setError] = useState(false);
  // to display value back to ui after grouped items select a value
  const [selectedGroupedValue, setSelectedGroupedValue] = useState({
    type: [],
    order: null,
    animateOnSlideDisplay: null,
    animationDelay: null,
    enableExitAnimation: false
  });
  const active = {slideID, fieldID};
  const {emitSocketEvents} = useSocket() || {};
  const [reindexOrder, setReindexOrder] = useState(0);
  const animateOnSlideDisplayHighestOrder = slideAnimationOrder?.animateOnSlideDisplayHighestOrder;
  const animateByClickHighestOrder = slideAnimationOrder?.highestOrder;

  useEffect(() => {
    let animationTypes = [];
    Object.entries(slideInfo.fields).map(
      ([id, field]) => {
        if (groupedKeys.includes(id)) {
          animationTypes.push(field?.styles?.animationType);
        } 
      }
    );
    setSelectedGroupedValue({
      ...selectedGroupedValue,
      type: animationTypes
    })
  }, [slideInfo, groupedKeys]);

  const handleSelection = async (item) => {
    if ((fieldID && fieldID !== -1) || groupedKeys.length > 0) {
      if (error) {
        setError(false);
      }
      const params = {
        active,
        type: item.value,
        groupedKeys,
      };
      await selectAnimationType({...params});

      const payload = {type: "animation-type", value: params};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "applied-animation", item: payload});
      }

      // if none, reorder the animation order as it has been removed from animation list
      if (item.value === "none") {
        setReindexOrder(true);
      }

      if (groupedKeys.length > 0) {
        setSelectedGroupedValue({
          ...selectedGroupedValue,
          type: [item.value],
          order: item.value === "none" ? 0 : selectedGroupedValue.order,
        });
      }
    } else {
      setError(true);
    }
  };

  const onSelectAnimationOrder = (value) => {
    const highestIndex = orderOptions[orderOptions.length - 1]?.value === value;
    const params = {
      active,
      value,
      highestIndex,
      groupedKeys,
    };
    applyAnimationOrder({...params});

    const payload = {
      type: "animation-order",
      value: params,
    };
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "applied-animation", item: payload});
    }

    if (groupedKeys.length > 0) {
      setSelectedGroupedValue({
        ...selectedGroupedValue,
        order: value,
        animationDelay: null
      });
    }
  };

  // animate on slide display
  const onSelectAnimationDelay = (value) => {
    const params = {
      active,
      value,
      groupedKeys,
    };
    applyAnimationDelay({...params});

    const payload = {
      type: "animation-delay-duration",
      value: params,
    };
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "applied-animation", item: payload});
    }

    if (groupedKeys.length > 0) {
      setSelectedGroupedValue({
        ...selectedGroupedValue,
        animationDelay: value,
        order: null
      });
    }
  }

  const [orderOptions, setOrderOptions] = useState([]);
  useEffect(() => {
    let options = [];
    if ((fieldID && fieldID !== -1) || groupedKeys.length > 0) {
      let increment = 0;
      if(styles?.animationOrder !== 1 && styles?.animationOrder === slideAnimationOrder?.highestOrder) {
        if (slideAnimationOrder?.orderFrequencies[slideAnimationOrder?.highestOrder] === 1){
          increment = 0;
        } else {
          increment = 1;
        }
      } else {
        increment = 1;
      }
      for (let i = 1; i <= slideAnimationOrder?.highestOrder + increment; i++) {
        if (
          !slideAnimationOrder?.highestOrderIDs.includes(fieldID) &&
          i <= slideAnimationOrder?.highestOrder + increment
        ) {
          options.push({value: i, display: i});
        } else {
          options.push({value: i, display: i});
        }
      }
      setOrderOptions(options);
    }
  }, [fieldID, slideAnimationOrder?.orderFrequencies, styles?.animationOrder, styles?.animationType]);

  useEffect(() => {
    if (reindexOrder) {
      onSelectAnimationOrder(0); // 1
    } else {
      setReindexOrder(false);
    }
  }, [reindexOrder]);

  const onSelectAnimationType = async (value) => {
    if (error) {
      setError(false);
    };
  
    if (groupedKeys.length > 0) {
      setSelectedGroupedValue({
        ...selectedGroupedValue,
        animateOnSlideDisplay: value,
        animationDelay: 0.5
      });
    };

    const params = {
      active,
      value,
      groupedKeys,
    };
    await applyAnimationOnSlideLoad({...params});

    const payload = {
      type: "animate-on-slide-display",
      value: params,
    };
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "applied-animation", item: payload});
    }
   
    if (slideAnimationOrder && (slideAnimationOrder !== 1 || slideAnimationOrder !== 0)) {
      const highestIndex = orderOptions?.length === 0 ? orderOptions[orderOptions?.length - 1]?.value === slideAnimationOrder : 0;
      const reOrderIndex = {
        active,
        value: 1, // !value ? styles?.animationOrder !== 0 ? styles?.animationOrder : 1 : 1, // if clicking the same animation type on radio button, keep the current order
        highestIndex,
        groupedKeys,
      };
      await applyAnimationOrder({...reOrderIndex});

      const payload = {
        type: "animation-order",
        value: reOrderIndex,
      };
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "applied-animation", item: payload});
      }
    }
  };

  const onSelectPreviewOption = (value) => {
    setAnimationPreviewOption(value);
  };

  const onInitPreview = (e) => {
    e.preventDefault();
    e.stopPropagation(); // important else animation won't work when group selection is on
    if (animateOnSlideDisplayHighestOrder !== 0 || animateByClickHighestOrder !== 0) {
      onPreviewAnimation(true);
    };
  };

  const handleExitAnimation = (value) => {
    if (groupedKeys?.length > 0) {
      const params = {
        active,
        value,
        groupedKeys,
      };
      applyAnimationOnExit({...params});

      if (groupedKeys?.length > 0) {
        setSelectedGroupedValue({
          ...selectedGroupedValue,
          enableExitAnimation: !selectedGroupedValue?.enableExitAnimation,
        });
      }

    } else {
      const params = {
        active,
        value,
        groupedKeys: [fieldID],
      };
      applyAnimationOnExit({...params});
    }
  }

  return (
    <Layout>
      <PanelHeader>Animations</PanelHeader>
      <Wrapper>
        {animationTypes.map((item) => (
          <DisplayItem
            key={item?.value}
            active={
              groupedKeys?.length > 0
                ? selectedGroupedValue?.type.includes(item?.value) // item.value === selectedGroupedValue.type
                : item?.value === animationType
            }
            item={item}
            onSelect={handleSelection}
            pageTheme={pageTheme}
          />
        ))}
      </Wrapper>

      {(fieldID && fieldID !== -1) || groupedKeys.length > 0 ? (
        <WrapSelection style={{alignItems: 'flex-start', marginTop: 12}}>
          <Title>Type</Title>
          <WrapRadio style={{flex: window.innerWidth >= 850 ? 1.7 : 1}}>
            <Radio onClick={() => onSelectAnimationType(true)}>
              <RadioButton active={animateOnSlideDisplay ? animateOnSlideDisplay : 
                selectedGroupedValue?.animateOnSlideDisplay !== null ? 
                selectedGroupedValue?.animateOnSlideDisplay : undefined}>
                <div />
              </RadioButton>
              <span>On Slide Display</span>
            </Radio>
            <Radio onClick={() => onSelectAnimationType(false)}>
              <RadioButton active={animateOnSlideDisplay === false ? !animateOnSlideDisplay : 
                selectedGroupedValue?.animateOnSlideDisplay !== null ? 
                !selectedGroupedValue?.animateOnSlideDisplay : undefined}>
                <div />
              </RadioButton>
              <span>By Block on Click</span>
            </Radio>
          </WrapRadio>
        </WrapSelection>
      ) : undefined}

      {(animateOnSlideDisplay === false && fieldID && fieldID !== -1) || 
        selectedGroupedValue.animateOnSlideDisplay === false && groupedKeys.length > 0 ? (
        <WrapSelection style={{alignItems: 'flex-start', marginTop: 22}}>
          <Title>Order</Title>
          <WrapDropDown style={{flex: 1}}>
            <DropDown
              onSelect={onSelectAnimationOrder}
              selected={
                groupedKeys.length > 0
                  ? selectedGroupedValue.order
                  : animationOrder
              }
              options={orderOptions}
              minWidth={75}
              boxWidth={60}
              height={150}
              background={window.innerWidth < 850 ? "#36364b" : undefined}
            />
          </WrapDropDown>
        </WrapSelection>
      ) : undefined}

      {(animateOnSlideDisplay && fieldID && fieldID !== -1) || 
        selectedGroupedValue?.animateOnSlideDisplay && groupedKeys?.length > 0 ? (
        <WrapSelection style={{flex: 1, alignItems: 'flex-start', marginTop: 22}}>
          {/* <Title>Delay</Title> */}
          <RangeSlider
            title="Delay"
            value={groupedKeys?.length > 0
              ? selectedGroupedValue?.animationDelay
              : animationDelay}
            max={6}
            min={0.5}
            step={0.1}
            onItemChange={onSelectAnimationDelay}
            fieldType="animationDelay"
            displayValueInPercent={false}
          />  
          {/* <WrapDropDown style={{flex: 1}}>
            <DropDown
              onSelect={onSelectAnimationDelay}
              selected={
                groupedKeys?.length > 0
                  ? selectedGroupedValue?.animationDelay
                  : animationDelay
              }
              options={delayOptions}
              minWidth={85}
              boxWidth={65}
              height={150}
              background={window.innerWidth < 850 ? "#36364b" : undefined}
            />
          </WrapDropDown> */}
        </WrapSelection>
      ) : undefined}

      {error ? (
        <Message>Please select a field to apply transition.</Message>
      ) : undefined}

      {/* {animateOnSlideDisplay === false || (groupedKeys.length > 0 && selectedGroupedValue.animateOnSlideDisplay === false) ?  */}
        <div style={{margin: '2rem 0'}}>
          <Switch
            title="On Exit Animation"
            value={groupedKeys.length > 0
              ? selectedGroupedValue?.enableExitAnimation
              : styles?.enableExitAnimation}
            onItemChange={handleExitAnimation}
            checked={groupedKeys?.length > 0
              ? selectedGroupedValue?.enableExitAnimation
              : styles?.enableExitAnimation}
          />
        </div> 
        {/* : 
      // undefined} */}

      <SplitLine />

      <WrapSelection style={{alignItems: 'flex-start', marginTop: 22}}>
        <div style={{display: 'flex', flex: 1}}>
          <DropDown
            onSelect={onSelectPreviewOption}
            selected={previewAnimationOption}
            options={previewAnimationTypes}
            minWidth={window.innerWidth >= 850 ? 175 : 220}
            maxWidth={window.innerWidth >= 850 ? 185 : 220}
            boxWidth={window.innerWidth >= 850 ? 175 : 220}
            background={window.innerWidth < 850 ? "#36364b" : undefined}
          />
        </div>
        <div style={{
          display: window.innerWidth >= 850 ? undefined : 'flex', 
          flex: window.innerWidth >= 850 ? undefined : 1, 
          width: 110, 
          height: 30}}
        >
          <SpecialButton onClick={onInitPreview} disabled={previewAnimationOn}>
            Preview
          </SpecialButton>
        </div>
      </WrapSelection>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100%;
  @media only screen and (min-width: 850px) {
    margin: 12px 12px 190px 12px;
  }
  @media only screen and (max-width: 849px) {
    margin-bottom: 150px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex: 1;
  padding: 0.7em 0;
  justify-content: space-evenly;
  @media only screen and (max-width: 849px) {
    justify-content: space-between;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85px;
  flex-wrap: wrap;
  flex: 0 30%;
  background: ${({theme, active}) =>
    active ? theme.activeAnimationItem : theme.deskSecondaryBg2};
  margin-bottom: 5%;
  border-radius: 6px;
  box-shadow: ${({theme}) => theme.boxShadow1};
  cursor: pointer;
  @media only screen and (max-width: 849px) {
    flex: 0 30%;
    font-size: 0.85rem;
    background: ${({theme, active}) =>
      active ? theme.activeSlide : theme.deskSecondaryBg};
  }
`;

const WrapSelection = styled.div`
  display: flex;
  align-items: center;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const Message = styled.div`
  text-align: center;
  color: ${redMessage};
`;

const WrapRadio = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const AnimationTitle = styled.div`
  flex: 1;
  width: 100%;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlideID(designTemplate),
    animationOrder:
      selectActiveFieldProps(designTemplate).styles?.animationOrder,
    slideAnimationOrder: selectSlideAnimationOrder(designTemplate),
    styles: selectActiveFieldProps(designTemplate).styles,
    groupedKeys: selectInterceptedFields(designTemplate).keys,
    previewAnimationOn: state.designTemplate.previewAnimation,
    previewAnimationOption: state.designTemplate.previewAnimationOption,
    pageTheme: selectPageTheme(designTemplate),
    slideInfo: selectActiveSlideInfo(designTemplate)
  };
};

export default connect(mapStateToProps, {
  selectAnimationType,
  applyAnimationOrder,
  onPreviewAnimation,
  applyAnimationOnSlideLoad,
  applyAnimationDelay,
  setAnimationPreviewOption,
  updateFieldStyles,
  applyAnimationOnExit
})(ApplyAnimations);