import React, {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import {blue3, red3, red4, secondaryBlack} from "../../../oat-color-variables";
import {selectUserInfo} from "../../../../redux/user/authUser";
import {useNavigate} from "react-router";
import {Spinner} from "../../../oat-ui";

const DisplayFolder = ({onSelect, item}) => {
  const handleClick = () => {
    onSelect(item.folderID);
  };
  return <List onClick={handleClick}>{item.folderName}</List>;
};

const DisplayWorkspace = ({onSelect, item}) => {
  const handleClick = () => {
    onSelect(item.workspaceID);
  };
  return <List onClick={handleClick}>{item.name}</List>;
};

const LoggedInUserNavContents = ({
  carouselIndex,
  incrementCarousel,
  close,
  user,
}) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selected, setSelected] = useState({
    workspaceID: "",
    // folderID: "",
  });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  let navigate = useNavigate();

  const initFunc = () => {
    incrementCarousel();
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
  };

  const fetchFolders = async (workspaceID) => {
    initFunc();
    setSelected({...selected, workspaceID});
    setTimeout(() => {
      setProcessing(true);
    }, 200);

    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspaceID}`
    )
      .then((res) => res.json())
      .then((data) => {
        setFolders(data);
        setProcessing(false);
      })
      .catch((e) => {
        setError({
          status: true,
          tab: "folder",
          message: "Couldn't load data",
        });
      });
  };

  const fetchWorkspaces = async () => {
    initFunc();

    setTimeout(() => {
      setProcessing(true);
    }, 200);

    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces`
    )
      .then((res) => res.json())
      .then((data) => {
        setProcessing(false);
        setWorkspaces(data);
      })
      .catch((e) => {
        setError({
          status: true,
          tab: "workspace",
          message: "Couldn't load data",
        });
      });
  };

  const redirectToTemplates = async (folderID) => {
    close();
    return navigate(`/dashboard/${selected.workspaceID}/${folderID}`);
  };

  return (
    <NavLayout
      style={{
        transform: `translate(${
          carouselIndex !== 0 ? -100 * carouselIndex : 0
        }%, 0px)`,
      }}
    >
      <Section>
        <Wrapper>
          <List onClick={close}>
            <GoTo to="/">Home</GoTo>
          </List>
          <List onClick={close}>
            <WrapNavLinks>
              <div>Templates</div>
              <GoTo to="/templates/presentation/16x9">Presentations</GoTo>
              {/* <GoTo to="/templates/social-media/1x1">Social Media (1:1)</GoTo> */}
            </WrapNavLinks>
          </List>
          <List onClick={close}>
            <GoTo to="/illustrations">Illustrations</GoTo>
          </List>
          {/* <List onClick={fetchWorkspaces}>
            <GoTo to="/blogs">Blog</GoTo>
          </List> */}
          <List onClick={close}>
            <GoTo to="/faq">FAQ</GoTo>
          </List>
          <List onClick={close}>
            <GoTo to="/pricing">Pricing</GoTo>
          </List>
        </Wrapper>

        {/* <Wrapper>
          <List onClick={close}>
            <GoTo to="/">Link 1</GoTo>
          </List>
          <List onClick={close}>
            <GoTo to="/">Link 2</GoTo>
          </List>
          <List onClick={close}>
            <GoTo to="/">Link 3</GoTo>
          </List>
          <List onClick={close}>
            <GoTo to="/">Link 4</GoTo>
          </List>
          <List onClick={close}>
            <GoTo to="/">Link 5</GoTo>
          </List>
          <List onClick={close}>
            <GoTo to="/">Link 4</GoTo>
          </List>
          <List onClick={close}>
            <GoTo to="/">Link 5</GoTo>
          </List>
        </Wrapper> */}

        <Wrapper></Wrapper>
      </Section>

      {/* <Section>
        <div style={{padding: "6%"}}>
          {processing ? (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          ) : undefined}

          {!processing &&
            workspaces &&
            workspaces.map((item, i) => (
              <DisplayWorkspace item={item} key={i} onSelect={fetchFolders} />
            ))}

          {error.status && error.tab === "workspace" ? (
            <WrapErrMsg>
              <Error>{error.message}</Error>
            </WrapErrMsg>
          ) : undefined}
        </div>
      </Section>

      <Section>
        <div style={{padding: "6%"}}>
          {processing ? (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          ) : undefined}

          {!processing &&
            folders &&
            folders.map((item, i) => (
              <DisplayFolder
                item={item}
                key={i}
                onSelect={redirectToTemplates}
              />
            ))}

          {error.status && error.tab === "workspace" ? (
            <WrapErrMsg>
              <Error>{error.message}</Error>
            </WrapErrMsg>
          ) : undefined}
        </div>
      </Section> */}
    </NavLayout>
  );
};

export const NavLayout = styled.div`
  font-size: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  direction: ltr;
  will-change: transform;
  transition: all 0.3s ease;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Section = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6%;
  flex: 1;
  margin-left: 24px;
  @media only screen and (max-width: 600px) {
    padding: 25px 0;
  }
`;

const reuse = css`
  color: ${secondaryBlack};
  &:hover {
    color: ${blue3};
  }
`;

const List = styled.h3`
  cursor: pointer;
  padding: 18px 0;
  ${reuse};
`;

const GoTo = styled(Link)`
  color: ${secondaryBlack};
  text-decoration: none;
  ${reuse};
`;

const WrapSpinner = styled.div`
  margin: 0 18px;
  display: flex;
  justify-content: center;
  margin-top: 90px;
`;

const WrapErrMsg = styled.div`
  display: flex;
  justify-content: center;
  background: ${red3};
  color: ${red4};
  padding: 9px 12px;
  border-radius: 6px;
`;

export const WrapNavLinks = styled.div`
  cursor: pointer;
  ${reuse};
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 10px;
  }
  a {
    margin: 20px 0 10px 24px !important;
    &:last-child {
      margin: 20px 0 0px 24px !important;
    }
  }
`


const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(LoggedInUserNavContents);
