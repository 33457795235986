import React from "react";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {Layout, WrapHeader, maxWidthPanel} from "../FeatureList";
import AnimateIn from "../../../../oat-ui/animation/Animate";
import {HomeHeader} from "../FeaturesShowcase2";
import styled from "styled-components";
import {homePageBackground2} from "../styles/layoutStyles";
import Check from "../../../../oat-svg-icons/icons/Check";
import { Section, WrapImage, CheckSvg, ContentList, wrapImgStyles } from "./homeCoreStyles";
import Marquee from "react-fast-marquee";
import { black3 } from "../../../../oat-color-variables";

const SelectTemplates = () => {
  const windowSize = getWindowSize();
  return (
    <Layout style={{background: homePageBackground2, paddingTop: window.innerWidth <= 600 ? 64 : 100}}>
      <Section>
        {/* <WrapHeader>
          <AnimateIn>
            <HomeHeader><span>1 -</span>Select Templates</HomeHeader>
          </AnimateIn>
        </WrapHeader> */}

        <Wrapper>
          <AnimateIn>
            <ImageItem
              loading="lazy"
              style={{
                background: `linear-gradient(rgb(215 215 248), rgb(220 225 255))`,
                backgroundImage: `url(https://dnaeh50kru9rd.cloudfront.net/backgrounds/orange-bg-1.jpg)`,
                width: `100%`,
                height: `100%`,
                backgroundSize: `cover`,
                overflow: 'hidden',
                maxHeight: window.innerWidth > 601 ? 580 : 400
              }}
            >
                <WrapImage style={{ padding: '20px 0', boxSizing: 'borderBox' }}>
                  <Image
                    src={
                      `https://dnaeh50kru9rd.cloudfront.net/home-page/feature-guide-section/select-templates.png`
                      // windowSize.width > 600
                      // ? `https://oat-default-init.s3.amazonaws.com/home-page/sharing-and-export-800.png`
                      // : `https://oat-default-init.s3.amazonaws.com/home-page/sharing-and-export-500.png`
                    }
                    alt="template-sharing"
                    loading="lazy"
                  />
                </WrapImage>
            </ImageItem>
          </AnimateIn>
           
          <AnimateIn>
            <Item>
              <div style={{display: 'flex', alignItems: 'center', width: '92%'}}>
                {/* <ShortTitle>
                Easily share as link or export to desire format
                </ShortTitle> */}
                <ContentList>
                <HomeHeader><span>1 -</span>Select Templates</HomeHeader>
                <>
                  <div className="content-list-item">
                  <CheckSvg>{Check}</CheckSvg>
                  <div>
                    <p className="feature">Professional Templates</p>
                    <p className="feature-description">Choose from our selection of premade templates</p>
                  </div>
                  </div>
                </>
                <>
                  <div className="content-list-item">
                  <CheckSvg>{Check}</CheckSvg>
                  <div>
                    <p className="feature">Themes & Layouts</p>
                    <p className="feature-description">
                      Apply branded themes to stay consistent & professional
                    </p>
                  </div>
                  </div>
                </>
                <>
                  <div className="content-list-item">
                  <CheckSvg>{Check}</CheckSvg>
                  <div>
                    <p className="feature">Resize Template</p>
                    <p className="feature-description">
                      Convert to any desirable size in a snap
                    </p>
                  </div>
                  </div>
                </>
                </ContentList>
              </div>
            </Item>
          </AnimateIn>
        </Wrapper>
      </Section>
    </Layout>
  );
};

const Item = styled.div`
  ${wrapImgStyles};
  @media only screen and (min-width: 901px) {
    margin-left: 24px;
  }
`;

const ImageItem = styled.div`
  ${wrapImgStyles};
  padding: 0px 32px;
  @media only screen and (max-width: 900px){
    padding: 0px 28px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  ${maxWidthPanel};
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  -webkit-animation: select-templates-animate 5s 0.2s linear infinite alternate;
  animation: select-templates-animate 5s linear 0.2s infinite alternate;
  @-webkit-keyframes select-templates-animate {
    0% {
      @media only screen and (max-width: 600px) {
        -webkit-transform: translateY(-10%);
      }
      -webkit-transform: translateY(-20%);
    }
  }
  @keyframes select-templates-animate {
    0% {
      @media only screen and (max-width: 600px) {
        transform: translateY(-10%);
      }
      transform: translateY(-20%);
    }
  }
`

export default SelectTemplates;
