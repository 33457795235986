import React, {useState} from "react";
import styled, {css} from "styled-components";
import {Trash, Plus} from "../../../../../../oat-svg-icons";
import {tableTabStyles, WrapSvg, cellGapColor} from "./EditRowCells";
import {red1} from "../../../../../../oat-color-variables";
import { editShadowStyle } from "./tableStyles";

const svgWidth = 50;
const DisplayItem = ({
  numberOfColumns,
  column,
  index,
  openPopper,
  onHoverToDelete,
  removeTableColumn,
  insertNewCell,
  tempScale,
  closePopper,
  displayPopper,
  borderWidth,
}) => {
  const displayDeleteIcon = (e) => {
    openPopper(index, "delete");
    e.stopPropagation();
  };

  const onHoverDeleteIcon = (e) => {
    onHoverToDelete(index);
  };

  const onHoverLeaveDeleteIcon = () => {
    onHoverToDelete(null);
  };

  const handleRemoveColumn = () => {
    removeTableColumn(index);
  };

  const displayAddIcon = (e) => {
    openPopper(index, "add");
    e.stopPropagation();
  };

  const onHoverAddIcon = (e) => {
    openPopper(index);
    e.stopPropagation();
  };

  const onInsertCell = () => {
    insertNewCell(index - 1);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <WrapItem
        style={{
          width: column.width - borderWidth / 2,
          // ((1 / tempScale) * index) / 2,
          height: "100%",
          // column.width - (index === 0 ? 3 / 2 : 3),
        }}
        tempScale={tempScale}
        showGap={index !== numberOfColumns - 1} // remove add line for the last item
        borderWidth={borderWidth}
      >
        <div
          style={{
            width: svgWidth,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onMouseEnter={displayDeleteIcon}
          onMouseLeave={closePopper}
        >
          <Column style={{transform: `scale(${1 / tempScale})`}}>
            {column.index}
          </Column>

          {displayPopper.status &&
          displayPopper.type === "delete" &&
          displayPopper.index === index ? (
            <Actions
              tempScale={tempScale}
              onClick={handleRemoveColumn}
              style={{
                width: 35 / tempScale,
                left: column.width / 2 - 35 / 2 / tempScale,
                display: numberOfColumns === 1 ? "none" : undefined,
                boxShadow: editShadowStyle
              }}
            >
              <Action tempScale={tempScale}>
                <WrapSvg 
                  onMouseEnter={onHoverDeleteIcon} 
                  onMouseLeave={onHoverLeaveDeleteIcon} 
                  style={{fill: red1}} 
                  tempScale={tempScale}
                >
                  {Trash}
                </WrapSvg>
              </Action>
            </Actions>
          ) : undefined} 
        </div>

        {/* <div
          style={{
            height: "100%",
            width: "0.8487654320987652px",
            background: "#d2d2d2",
          }}
        /> */}
      </WrapItem>

      <AddCellGap
        style={{
          width: 25 / tempScale,
          height: "100%",
          position: "absolute",
          top: 0,
          left: -12.5 / tempScale,
        }}
        onMouseEnter={displayAddIcon}
        onMouseLeave={closePopper}
      >
        {displayPopper.status &&
        displayPopper.type === "add" &&
        displayPopper.index === index ? (
          <Actions
            tempScale={tempScale}
            style={{
              width: 35 / tempScale,
              left: -12 / 2 / tempScale,
              display: numberOfColumns === 1 ? "none" : undefined,
              boxShadow: editShadowStyle
            }}
          >
            <Action onClick={onInsertCell} tempScale={tempScale}>
              <WrapSvg style={{fill: "#2b2b30"}} tempScale={tempScale}>
                {Plus}
              </WrapSvg>
            </Action>
          </Actions>
        ) : undefined}
      </AddCellGap>
    </div>
  );
};
let clearFunc;
const EditColumnCells = ({
  columns,
  onCreate,
  onHoverToDelete,
  tempScale,
  onRemove,
  borderWidth,
}) => {
  const [displayPopper, setDisplayPopper] = useState({
    index: null,
    status: false,
    type: null,
  });

  const openPopper = (i, type) => {
    setDisplayPopper({
      index: i,
      status: true,
      type,
    });
    clearTimeout(clearFunc);
  };

  const closePopper = (e) => {
    clearFunc = setTimeout(() => {
      setDisplayPopper({
        index: null,
        status: false,
        type: null,
      });
    }, 200);
    // e.stopPropagation();
  };

  const insertNewCell = async (i) => {
    await onCreate(i);
    await closePopper();
  };

  const handleColumnDeletion = (id) => {
    onRemove(id);
  };

  const openLastCellToAddCol = () => {
    openPopper(columns.length, "add");
  };

  return (
    <Wrapper tempScale={tempScale}>
      {columns &&
        columns.map((column, i) => (
          <DisplayItem
            key={i}
            index={i}
            numberOfColumns={columns.length}
            column={column}
            displayPopper={displayPopper}
            openPopper={openPopper}
            closePopper={closePopper}
            removeTableColumn={handleColumnDeletion}
            onHoverToDelete={onHoverToDelete}
            insertNewCell={insertNewCell}
            tempScale={tempScale}
            borderWidth={borderWidth}
          />
        ))}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
        onMouseEnter={openLastCellToAddCol}
        onMouseLeave={closePopper}
      >
        <AddCellGap
          style={{
            width: 25 / tempScale,
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0 / tempScale,
          }}
        >
          {displayPopper.status &&
          displayPopper.type === "add" &&
          displayPopper.index === columns.length ? (
            <Actions tempScale={tempScale}>
              <Action
                onClick={() => insertNewCell(columns.length)}
                tempScale={tempScale}
              >
                <WrapSvg style={{fill: "#2b2b30"}} tempScale={tempScale}>
                  {Plus}
                </WrapSvg>
              </Action>
            </Actions>
          ) : undefined}
        </AddCellGap>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: ${({tempScale}) => -28 / tempScale}px;
  width: 100%;
  height: ${({tempScale}) => 20 / tempScale}px;
  background: #fff;
  border-radius: ${({tempScale}) => 4 / tempScale}px;
  user-select: none;
  box-shadow: 0px 0px 4px #b0b0b0;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${({tempScale}) => -28 / tempScale}px;
  height: ${({tempScale}) => 22 / tempScale}px;
  color: #d8fb1e;
  line-height: 10px;
  background: #fff;
  border-radius: ${({tempScale}) => 3 / tempScale}px;
`;

const WrapItem = styled.div`
  width: 100%;
  ${tableTabStyles};
  height: ${({tempScale}) => 15 / tempScale}px;
  position: relative;
  border-right: ${({borderWidth, showGap, tempScale}) =>
    showGap ? `${borderWidth}px solid  ${cellGapColor}` : "0px"};
  p {
    width: 50%;
    font-size: 0.75rem;
  }
`;

const reuseStyle = css`
  font-weight: 700;
  color: #6e6e6e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const Action = styled.div`
  ${reuseStyle};
  padding: ${({tempScale}) => `${6 / tempScale}px ${10 / tempScale}`}px;
`;

const AddCellGap = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const Column = styled.div`
  transform-origin: 50% 50%;
  width: 50px;
  font-family: "Nunito", sans-serif;
`;

export default EditColumnCells;
