import React, {useState} from "react";
import {WrapTabPanel, Tab, WrapRowTabs} from "../../reusableStyles/TabStyles";
import styled from "styled-components";
import {dispatchEmbedVideoLink} from "../../../../../store/actions/fields/video.action";
import {connect} from "react-redux";
import {storeActionsUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {createShortKey} from "../../../../../store/reducers/functions/fields/utils";
import {closeDrawer} from "../../../../../store/actions/layout.action";

const CreateVideo = ({
  dispatchEmbedVideoLink,
  storeActionsUndoRedo,
  slideID,
  closeModal,
  closeDrawer,
}) => {
  const [activeTab, setActiveTab] = useState("embed-link");
  const [insertLink, setInsertLink] = useState("");
  const validVideoExtensions = ['mp4', 'webm', 'ogg'];

  const onSelectEmbedLink = () => {
    setActiveTab("embed-link");
  };

  const onSelectUploadVideo = () => {
    setActiveTab("upload-video");
  };

  const handleInsertLink = (e) => {
    const {value} = e.target;
    setInsertLink(value);
  };

  const checkVideoLink = async (link) => {
    try {
      const url = new URL(link);
      const extension = url.pathname.split('.').pop();

      if (!validVideoExtensions.includes(extension.toLowerCase())) {
        throw new Error('Invalid video format');
      }

      // Make a HEAD request to check content type
      const response = await fetch(link, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.startsWith('video/')) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  

  const onAddEmbedLink = () => {
    let updatedLink = insertLink;
    const key = createShortKey();
    const youtubeMatched = insertLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*(?:[\?&]v=|\/embed\/|\/\d\w*\/|\/vi?\/|\?vi?=)([^"&?\/\s]{11})/);
    const vimeoMatched = insertLink.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);

    if (youtubeMatched) {
      const videoId = youtubeMatched ? youtubeMatched[1] : null;
      updatedLink = `https://www.youtube.com/embed/${videoId}`;
    } else if (vimeoMatched) {
      const videoId = vimeoMatched ? vimeoMatched[1] : null;
      updatedLink = `https://player.vimeo.com/video/${videoId}`;
    }
    else if (
      // https://vimeo.com/849709356
      insertLink.includes("vimeo.com") &&
      !insertLink.includes("player.vimeo.com/") &&
      !insertLink.includes("<iframe")
    ) {
      const transformIntoEmbedFormat = insertLink.split("vimeo.com/")[1];
      updatedLink = `https://player.vimeo.com/video/${transformIntoEmbedFormat}`;
    } else if (
      // https://www.loom.com/share/6afdebc56f4542e7bfaf3ae35ccffe96?sid=b4394a25-5c77-4091-9f7b-fa8ed3c24c14
      insertLink.includes("loom.com/share/") &&
      !insertLink.includes("<iframe")
    ) {
      const transformIntoEmbedFormat = insertLink.split("loom.com/share/")[1];
      updatedLink = `https://www.loom.com/embed/${transformIntoEmbedFormat}`;
    } else if (
      // <iframe src="https://www.loom.com/embed/6afdebc56f4542e7bfaf3ae35ccffe96?sid=b4394a25-5c77-4091-9f7b-fa8ed3c24c14"
      insertLink.includes("loom.com/embed/") &&
      insertLink.includes("<iframe")
    ) {
      const regexLoom = /embed\/([a-zA-Z0-9_-]+)\?/g;
      const loomMatches = regexLoom.exec(insertLink);
      updatedLink = `https://www.loom.com/embed/${loomMatches[1]}`;
    } else {
      if (checkVideoLink(insertLink)) {
        updatedLink = insertLink;
      }
    }

    dispatchEmbedVideoLink({slideID, key, link: updatedLink});
    storeActionsUndoRedo("create");
    if (window.innerWidth >= 850) {
      closeModal();
    } else {
      closeDrawer();
    }
  };

  return (
    <>
      <div>
        <WrapTabPanel>
          <WrapRowTabs>
            <Tab
              onClick={onSelectEmbedLink}
              selected={activeTab === "embed-link" ? true : false}
            >
              Embed Link
            </Tab>
            {/* <Tab
              onClick={onSelectUploadVideo}
              selected={activeTab === "upload-video" ? true : false}
            >
              Upload Video
            </Tab> */}
          </WrapRowTabs>
        </WrapTabPanel>
      </div>

      <WrapContent>
        {activeTab === "embed-link" ? (
          <>
            <Header>Embed an external video</Header>
            <WrapForm>
              <Input
                value={insertLink}
                onChange={handleInsertLink}
                placeholder="Youtube, Vimeo, Loom.."
                autoFocus
              />
              <Button onClick={onAddEmbedLink}>Embed</Button>
            </WrapForm>
          </>
        ) : (
          <>
            <Header>Upload Video</Header>
            <WrapForm>
              <Input value="" />
              <Button>Upload</Button>
            </WrapForm>
          </>
        )}
      </WrapContent>
    </>
  );
};

const WrapContent = styled.div`
  padding: 18px;
`;

const WrapForm = styled.div`
  display: flex;
`;

const Header = styled.div`
  font-size: 1rem;
  padding-bottom: 18px;
  box-sizing: border-box;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  outline: none;
  border: 1px solid  ${({theme}) => theme.deskGap};
  box-sizing: border-box;
`;

const Button = styled.div`
  width: 180px;
  height: 32px;
  background: ${({theme}) => theme.deskButton};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  color: #fff;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  dispatchEmbedVideoLink,
  storeActionsUndoRedo,
  closeDrawer,
})(CreateVideo);
