import {
  DESIGN_LIST_INIT,
  DESIGN_LIST_SUCCESS,
  DESIGN_LIST_ERR,
} from "../../actionTypes";

export function designListInit(selectedTempID) {
  return {
    type: DESIGN_LIST_INIT,
    selectedTempID,
  };
}

export function designListSuccess(designs) {
  return {
    type: DESIGN_LIST_SUCCESS,
    designs,
  };
}

export function designListErr(error) {
  return {
    type: DESIGN_LIST_ERR,
    error,
  };
}

export const fetchDesignList = ({url, selectedTempID}) => {
  return async (dispatch) => {
    dispatch(designListInit(selectedTempID));
    try {
      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          dispatch(designListSuccess(data));
        })
        .catch((e) => dispatch(designListErr(e)));
    } catch (e) {
      dispatch(designListErr(e));
    }
  };
};
