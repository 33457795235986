export function switchSection() {
  return function (state, action) {
    return {
      ...state,
      activeSection: action.payload,
      activeCategory: null,
      tempCategories: [],
      templates: [],
      templateDetails: {},
    };
  };
}
