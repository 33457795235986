import React from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import DashboardPanel from "../components/dashboardPanel/DashboardPanel";
import UserDashboardHoc from "../hoc/UserDashboardHoc";
import UserFolderHoc from "../hoc/UserFolderHoc";
import ProfileSettingSidebar from "../components/profile-setting/sidebar/ProfileSettingSidebar";
import UserProfileTemplates from "../components/profile-setting/profileTemplates/UserProfileTemplates";

const UserProfileSetting = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <body style="overflow: hidden;" />
      </Helmet>
      <UserDashboardHoc>
        <UserFolderHoc>
          <ProfileSettingSidebar />
        </UserFolderHoc>
        <DashboardPanel>
          <UserProfileTemplates />
        </DashboardPanel>
      </UserDashboardHoc>
    </HelmetProvider>
  );
};

export default UserProfileSetting;
