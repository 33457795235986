import React, {useEffect, useRef} from "react";
import styled, {css} from "styled-components";
import {Map} from "../../../oat-svg-icons";
import ProfileTags from "./ProfileTags";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";
import ProfileBio, { textOverflowCSS } from "./ProfileBio";

const desktopWidth = 250;
const tabletWidth = 200;

const UserInfo = ({item}) => {
  const accInfo = useRef();
  const windowSize = getWindowSize();

  const [height, setHeight] = React.useState(150);
  useEffect(() => {
    setHeight(accInfo.current?.offsetHeight)
  },[accInfo.current?.offsetHeight, windowSize.width])

  return (
    <>
      <Layout>
        <Wrapper>
          <LeftProfileSection style={{ height: windowSize.width <= 600 ? 130 : undefined}}>
            <UserAvatar>
              <img src={'https://oat-users.s3.amazonaws.com/testing/output-abcd.png'} alt="oatall-avatar" />
            </UserAvatar>

            <WrapAccInfo ref={accInfo} style={{ height}}>
              <AccInfo >
                <Name>
                  {/* {item.profileName} */}
                  Aung Khant
                </Name>
                {/* {item.slug ? (
                  <UserName>
                    <span style={{paddingRight: 2}}>@</span>
                    <p>
                      {item.slug}
                      dingo
                    </p>
                  </UserName>
                ) : undefined} */}
                <AccCategory>Entrepreneur | Web Developer</AccCategory>

                {windowSize.width > 600 ? <ProfileBio /> : undefined}
              </AccInfo>
            </WrapAccInfo>
          </LeftProfileSection>

          {/* {width >= 900 ? (
            <RightProfileSection>
              <Location>
                Mandalay
                <div>{Map}</div>
              </Location>
              <WebsiteLink>Links</WebsiteLink>
            </RightProfileSection>
          ) : undefined} */}
        </Wrapper>

        {/* {width < 900 ? (
          <RightProfileSection>
            <Description>{item.bio}</Description>
            <WebsiteLink>morgan.com</WebsiteLink>
          </RightProfileSection>
        ) : undefined} */}
      </Layout>

      {/* {item.tags && item.tags.length > 0 ? (
        <WrapProfileTags>
          <ProfileTags tags={item.tags} />
        </WrapProfileTags>
      ) : undefined} */}
    </>
  );
};

const Layout = styled.div`
  display: block;
  @media only screen and (min-width: 1050px) {
    margin: 0 5%;
  }
  @media only screen and (min-width: 800px) and (max-width: 1049px) {
    margin: 0 2%;
  }
`;

const LeftProfileSection = styled.div`
  display: flex;
  margin: 0 5% 0 0;
  @media only screen and (max-width: 900px) and (min-width: 601px) {
    margin: 0 3%;
  }
  @media only screen and (max-width: 600px) {
    margin: 0 1% 0 0;
    // display: block;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0fr;
  // height: 160px;
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const UserAvatar = styled.div`
  position: relative;
  width: ${window.innerWidth >= 600 ? 210 : 120}px;
  height: ${window.innerWidth >= 600 ? 210 : 120}px;
  border-radius: 50%;
  background: #dae2ff;
  overflow: hidden;
  border: 8px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${({lockDiv}) => (lockDiv ? 55 : -45)}px;
  @media only screen and (min-width: 1600px) {
    width: ${desktopWidth}px;
    height: ${desktopWidth}px;
  }
  @media only screen and (max-width: 768px) {
    width: ${tabletWidth}px;
    height: ${tabletWidth}px;
  }
  @media only screen and (max-width: 600px) {
    width: 140px;
    height: 140px;
    top: -40px;
  }
  img {
    position: absolute;
    object-fit: cover;
    transform: translate(5px, 26px) scale(0.8);
    max-width: 100%;
    overflow: visible;
    @media only screen and (min-width: 1600px) {
      width: ${desktopWidth}px;
      height: ${desktopWidth}px;
    }
    @media only screen and (max-width: 600px) {
      width: 240px;
      height: 240px;
      transform: translate(5px, 20px) scale(0.8);
    }
  }
`;

const AccInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  padding-top: 10px;
  @media only screen and (max-width: 999px) {
    align-items: flex-start;
  }
  @media only screen and (max-width: 600px) {
    margin-left: 10px;
  }
`;

const Name = styled.div`
  font-size: 2rem;
  font-weight: 700;
  padding: 7px 0;
  box-sizing: border-box;
  color: #556078;
  ${textOverflowCSS};
  @media only screen and (min-width: 767px) and (max-width: 1050px) {
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const AccCategory = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
  padding-right: 18px;
  color: #7c7ec8;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 22px 0 16px;
  color: #404040;
  div {
    width: 16px;
    height: 16px;
    fill: #676363;
    margin-left: 8px;
  }
`;

const UserName = styled.div`
  display: flex;
  font-size: 0.92rem;
  font-weight: 600;
  padding: 4px 0;
  box-sizing: border-box;
`;

const WrapAccInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media only screen and (max-width: 600px) {
    position: relative;
    top: 10px;
    // height: 150px;
  }
`;

const RightProfileSection = styled.div`
  flex: 1;
  @media only screen and (min-width: 1000px) {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-top: 12px;
  }
  @media only screen and (max-width: 999px) {
    margin: 0 5%;
  }
`;

const WebsiteLink = styled.a`
  font-size: 1rem;
  color: #353538;
  cursor: pointer;
  text-decoration: underline;
`;

const WrapProfileTags = styled.div`
  margin: 0 0 0 5%;
  @media only screen and (max-width: 999px) {
    margin: 0 0 0 5%;
  }
`;

export default UserInfo;
