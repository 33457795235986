import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import {WrapColorBox} from "../common/WrapColorStyles";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const SelectAxisColor = ({
  slideID,
  fieldID,
  value,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const updateAxisLineColor = (color) => {
    updateFieldStyles({
      active: {
        slideID,
        fieldID,
      },
      type: "axisColor",
      value: color,
    });
  };

  const handleDebounced = () => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <div style={{display: "flex"}}>
      <Title>Axis Line</Title>
      <WrapColorBox>
        <PopoverColorBox
          value={value}
          updateFieldStyles={updateAxisLineColor}
          onDebounce={handleDebounced}
        />
      </WrapColorBox>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(SelectAxisColor);
