import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {darkGrey1, red1, red5} from "../../../../../oat-color-variables";
import {Trash} from "../../../../../oat-svg-icons";
import {connect} from "react-redux";
import {Spinner} from "../../../../../oat-ui";
import {BodyScroll, Error} from "../../../reuse/dashboardUI/modalStyles";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import {Header} from "../workspaceStyles";
import AddWorkspaceMember from "./AddWorkspaceMember";

const DisplayItem = ({
  item,
  onRemoveUser,
  currentUser,
  deleting,
  isOwner,
  isAdmin,
}) => {
  const [requestConfirm, setRequestConfirm] = useState(false);

  const onRequestConfirm = () => {
    setRequestConfirm(true);
  };

  const onConfirmRemoval = () => {
    onRemoveUser(item);
  };

  const cancelRemoval = () => {
    setRequestConfirm(false);
  };

  useEffect(() => {
    if (requestConfirm) setRequestConfirm(false);
  }, [item.username]);

  return (
    <WrapItem style={{opacity: !deleting ? 1 : 0.4}}>
      <Item>{item.name}</Item>

      {/* For admin */}
      {!requestConfirm &&
      item.role !== "owner" &&
      item.role !== "admin" &&
      !currentUser &&
      isAdmin ? (
        <WrapSvg onClick={onRequestConfirm}>{Trash}</WrapSvg>
      ) : undefined}

      {/* For owner */}
      {!requestConfirm && isOwner && !currentUser ? (
        <WrapSvg onClick={onRequestConfirm}>{Trash}</WrapSvg>
      ) : undefined}

      {requestConfirm &&
      item.role !== "owner" &&
      !currentUser &&
      (isOwner || isAdmin) ? (
        <div style={{display: "flex", alignItems: "center"}}>
          <p
            onClick={cancelRemoval}
            style={{
              marginRight: 15,
              fontSize: "0.8rem",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Cancel
          </p>
          <Remove onClick={onConfirmRemoval}>Confirm Remove</Remove>
        </div>
      ) : undefined}

      {!requestConfirm ? <Role>{item.role}</Role> : undefined}
    </WrapItem>
  );
};

const WorkspaceMembers = ({user, workspace, close}) => {
  const [memberList, setMemberList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  useEffect(() => {
    setProcessing(true);
    if (error) setError(false);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspace.ID}/members`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.type === "success") {
          const membersAsObj = data.response.items;
          const membersAsArr = Object.entries(membersAsObj).map(
            ([username, obj]) => ({
              username,
              ...obj,
            })
          );
          setMemberList(membersAsArr);
        }
        setProcessing(false);
      })
      .catch(() => setError(true));
  }, []);

  const removeUser = async (userToRemove) => {
    setDeleting(true);
    if (!deleting) {
      if (error.status) {
        setError({
          status: false,
          message: null,
        });
      }
      const requestOptions = {
        method: "DELETE",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          memberToLeave: userToRemove.username,
          // workspaceOwner: user.username,
          // removedByOwner: true,
        }),
      };
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspace.ID}/members`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setMemberList((prev) =>
              prev.filter((item) => item !== userToRemove)
            );
          } else {
            setError({
              status: true,
              message: data.message,
            });
          }
          setDeleting(false);
        })
        .catch((e) => {
          setError({
            status: true,
            message: "Something went wrong.",
          });
          setDeleting(false);
        });
    }
  };

  return (
    <Layout>
      <AddWorkspaceMember />

      <Header>Member List</Header>

      {processing ? (
        <div style={{paddingTop: 75}}>
          <Spinner />
        </div>
      ) : undefined}

      <BodyScroll style={{minHeight: 200}} height="auto">
        <ScrollContainer>
          {!processing && memberList.length !== 0
            ? memberList &&
              memberList.map((item, i) => (
                <DisplayItem
                  item={item}
                  key={i}
                  onRemoveUser={removeUser}
                  deleting={deleting}
                  isOwner={workspace.role === "owner"}
                  isAdmin={workspace.role === "admin"}
                  currentUser={user.username === item.username}
                />
              ))
            : undefined}

          {error.status ? <Error>{error.message}</Error> : undefined}
        </ScrollContainer>
      </BodyScroll>
    </Layout>
  );
};

const Layout = styled.div`
  @media only screen and (max-width: 600px) {
    padding-bottom: 155px;
  }
`;

const Role = styled.div`
  font-size: 0.85rem;
  text-transform: capitalize;
`;

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: ${red1};
  display: none;
  margin-right: 12px;
`;

const Item = styled.div`
  width: 100%;
  font-size: 1rem;
  padding: 10px 0px 10px 6px;
  border-radius: 4px;
  text-transform: capitalize;
`;

const WrapItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  color: ${darkGrey1};
  border-radius: 4px;
  border-bottom: 1px solid #ededed;
  &:hover ${WrapSvg} {
    display: block;
  }
`;

const Remove = styled.div`
  width: 108px;
  text-align: center;
  background: ${red5};
  padding: 6px 12px;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  font-size: 0.8rem;
  margin-right: 8px;
  cursor: pointer;
`;

const ScrollContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(WorkspaceMembers);
