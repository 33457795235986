import React from "react";
import styled from "styled-components";
import {red4} from "../../oat-color-variables";

const tagsList = [
  {
    display: "Travel / Tour",
    value: "travel-and-tour",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48",
  },
  {
    display: "Retail",
    value: "retail",
    icon: "https://images.ctfassets.net/5gvckmvm9289/3i1BzpUMk1Tyu3K4oAOTpb/8a7c5447045936afa8c8b852f77cfa8f/E-Commerce.svg?w=48&q=100",
  },
  {
    display: "Driver",
    value: "driver",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48&q=100",
  },
  {
    display: "Doctor",
    value: "doctor",
    icon: "https://images.ctfassets.net/5gvckmvm9289/3i1BzpUMk1Tyu3K4oAOTpb/8a7c5447045936afa8c8b852f77cfa8f/E-Commerce.svg?w=48&q=100",
  },
  {
    display: "Graphic Designer",
    value: "graphic-designer",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48&q=100",
  },
  {
    display: "Teacher",
    value: "teacher",
    icon: "https://images.ctfassets.net/5gvckmvm9289/3i1BzpUMk1Tyu3K4oAOTpb/8a7c5447045936afa8c8b852f77cfa8f/E-Commerce.svg?w=48&q=100",
  },
  {
    display: "Business Owner",
    value: "business-owner",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48&q=100",
  },
  {
    display: "Hair Stylist",
    value: "hair-stylist",
    icon: "https://images.ctfassets.net/5gvckmvm9289/3i1BzpUMk1Tyu3K4oAOTpb/8a7c5447045936afa8c8b852f77cfa8f/E-Commerce.svg?w=48&q=100",
  },
  {
    display: "Software Developer",
    value: "software-developer",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48&q=100",
  },
  {
    display: "Child Care",
    value: "child-care",
    icon: "https://images.ctfassets.net/5gvckmvm9289/3i1BzpUMk1Tyu3K4oAOTpb/8a7c5447045936afa8c8b852f77cfa8f/E-Commerce.svg?w=48&q=100",
  },
  {
    display: "Tailoring",
    value: "tailoring",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48&q=100",
  },
  {
    display: "Hair Stylist",
    value: "hair-stylist",
    icon: "https://images.ctfassets.net/5gvckmvm9289/3i1BzpUMk1Tyu3K4oAOTpb/8a7c5447045936afa8c8b852f77cfa8f/E-Commerce.svg?w=48&q=100",
  },
  {
    display: "Software Developer",
    value: "software-developer",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48&q=100",
  },
  {
    display: "Child Care",
    value: "child-care",
    icon: "https://images.ctfassets.net/5gvckmvm9289/3i1BzpUMk1Tyu3K4oAOTpb/8a7c5447045936afa8c8b852f77cfa8f/E-Commerce.svg?w=48&q=100",
  },
  {
    display: "Tailoring",
    value: "tailoring",
    icon: "https://images.ctfassets.net/5gvckmvm9289/18ae1EkPa8ZNCak1ezG8dR/abc4b0fac1e3f205fe3a70d78e256259/Cosmetics___Skincare.svg?w=48&q=100",
  },
];

const Item = ({tag, onSelectTag, active}) => {
  const handleTagSelection = () => {
    onSelectTag(tag.value);
  };

  return (
    <Tag onClick={handleTagSelection} active={active}>
      <img src={tag.icon} />
      <p>{tag.display}</p>
    </Tag>
  );
};

const TemplateTags = ({onSelectTag, selectedTag}) => {
  return (
    <Wrapper>
      {tagsList &&
        tagsList.map((tag, index) => (
          <Item
            key={index}
            active={tag.value === selectedTag}
            tag={tag}
            onSelectTag={onSelectTag}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f9f9f9;
  display: flex;
  overflow-x: scroll;
  font-size: 0.9rem;
  padding: 28px 32px;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  @media only screen and (max-width: 850px) {
    padding: 16px 0;
  }
  @media only screen and (min-width: 1500px) {
    padding: 28px 5%;
  }
`;

const Tag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 100%;
  cursor: pointer;
  padding: 7px 20px 7px;
  box-sizing: border-box;
  color: #2b2b30;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  text-align: center;
  img {
    margin-bottom: 18px;
  }
  @media only screen and (min-width: 768px) {
    padding: 12px 10px;
    width: 128px;
  }
  &:hover {
    fill: ${red4};
    p {
      color: ${red4};
    }
  }
`;

export default TemplateTags;
