import React, {useState} from "react";
import styled from "styled-components";
import {Button, Spinner} from "../../../../../oat-ui";

const UpdatePaymentDetails = ({
  username,
  workspaceRole,
  workspaceID,
  subscriptionID,
  paymentMethod,
}) => {
  const [processing, setProcessing] = useState(false);

  const updatePaymentDetails = async (event) => {
    if (
      subscriptionID &&
      (workspaceRole === "owner" || workspaceRole === "admin")
    ) {
      event.preventDefault();
      setProcessing(true);
      return await fetch(
        `https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/user-subscription/${username}/workspaces/${workspaceID}/update-payment-details`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currentURL: window.location.href,
            subscriptionID,
          }),
        }
      )
        .then((response) => response.json())
        .then(({url}) => (window.location = url))
        .catch((error) => setProcessing(false));
    }
  };

  return (
    <Layout>
      <Wrapper className="billing-customer__update-payment-details">
        <CardList>
          <Header>
            <h4>Card Details</h4>

            <div style={{display: "flex", alignItems: "center"}}>
              {processing ? (
                <div style={{transform: `scale(0.5)`, marginRight: 18, marginTop: 40}}>
                  <Spinner />
                </div>
              ) : undefined}
              <Button
                styles={{
                  width: 120,
                  height: 34,
                  opacity: !subscriptionID ? 0.5 : 1,
                  pointerEvents: !subscriptionID ? "none" : "auto",
                }}
                onClick={updatePaymentDetails}
                disabled={processing}
              >
                Edit Card
              </Button>
            </div>
          </Header>

          {paymentMethod?.card ? (
            <div style={{margin: "28px 16px"}}>
              <Item>
                <p>Name: {paymentMethod?.billing_details.name}</p>
                <p>Last 4 Digits :{paymentMethod?.card.last4} </p>
                <p>
                  Expires On : {paymentMethod?.card.exp_month} /{" "}
                  {paymentMethod?.card.exp_year}
                </p>
              </Item>
            </div>
          ) : <div style={{transform: `scale(1)`}}>
              <Spinner />
            </div>}
        </CardList>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
  color: #626161;
  cursor: pointer;
`;

const CardList = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h4 {
    font-weight: 700;
    font-size: 1rem;
    color: #232323;
  }
`;

const Item = styled.div`
  margin-bottom: 14px;
  p {
    padding-bottom: 8px;
  }
`;

export default UpdatePaymentDetails;
