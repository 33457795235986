import React, {useState, useEffect, useMemo, useCallback, useLayoutEffect} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {transformImgOffset} from "../../../../../store/actions/fields/image.action";
import tinycolor from "tinycolor2";
import {storeFieldUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {shadowTypeOptions} from "../../../editingPanel/panelTypes/desktop/image/ImageShadow";
import {Spinner} from "../../../../../../oat-ui";
import {updateFieldCollaboration} from "../../../../../store/actions/collaboration/collaboration.action";
import {getSelectedShadow} from "./getSelectedShadow";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";

const ImageField = ({
  slideIndex,
  field,
  onDragStart,
  onSelect,
  croppingImage, // has alredy picked the selected one
  transformImgOffset,
  selected,
  storeFieldUndoRedo,
  tempScale,
  updateFieldCollaboration,
}) => {
  const {styles, content, imgPos, size, fileType, imageBy, external} = field || {};
  const [imageSrc, setImageSrc] = useState(content);
  const {x, y} = imgPos || {};
  const [pan, setPan] = useState({x, y});
  const [panStatus, setPanStatus] = useState(false);
  const [org, setOrg] = useState({x: 0, y: 0});
  const [emitSocket, setEmitSocket] = useState(null); // set null to initial
  let panXPos = null;
  let panYPos = null;
  const windowSize = getWindowSize();

  useLayoutEffect(() => {
    setPan({
      x,
      y,
    });
  }, [imgPos]);

  const isTouchEvent = (e) => {
    if (e.touches && e.touches.length) return true;
    return false;
  };

  const onPanTouchStart = (e) => {
    e.preventDefault(); // important mozila
    if (croppingImage && selected) {
      storeFieldUndoRedo();
      setPanStatus(true);
      setOrg({
        x: (isTouchEvent(e) ? e.touches[0].clientX : e.clientX) * 1 - pan.x,
        y: (isTouchEvent(e) ? e.touches[0].clientY : e.clientY) * 1 - pan.y,
      });
      e.stopPropagation();
    }
  };

  const onPanning = (e) => {
    if (panStatus && selected) {
      const xOffset = Math.round(
        (isTouchEvent(e) ? e.touches[0].clientX : e.clientX) * 1 - org.x // * 1.2 - org.x
      );
      const yOffset = Math.round(
        (isTouchEvent(e) ? e.touches[0].clientY : e.clientY) * 1 - org.y // * 1.2 - org.y
      );
      setPan({
        x: xOffset,
        y: yOffset,
      });
      e.stopPropagation();
    }
  };

  const confinedPanX = size.w - size.w2;
  if (pan.x > 0) {
    panXPos = 0;
  } else if (pan?.x < confinedPanX) {
    panXPos = confinedPanX;
  } else {
    panXPos = pan?.x;
  }

  const confinedPanY = size.h - size.h2;
  if (pan?.y > 0) {
    panYPos = 0;
  } else if (pan?.y < confinedPanY) {
    panYPos = confinedPanY;
  } else {
    panYPos = pan?.y;
  }

  const onPanEnd = (e) => {
    setPanStatus(false);
    storeFieldUndoRedo();
    setEmitSocket(true);
    e.stopPropagation();
  };

  useLayoutEffect(() => {
    if (!panStatus && croppingImage) {
      const active = {slideID: slideIndex, fieldID: field?.key};
      const imgOffset = {x: panXPos, y: panYPos};
      transformImgOffset(active, imgOffset);
    }
  }, [panStatus, croppingImage]);

  useEffect(() => {
    if (emitSocket) {
      updateFieldCollaboration();
      setEmitSocket(false);
    }
  }, [emitSocket]);

  useEffect(() => {
    if (panStatus && selected) {
      window.addEventListener("touchmove", onPanning);
      window.addEventListener("touchend", onPanEnd);
      window.addEventListener("mouseup", onPanEnd);
      window.addEventListener("mousemove", onPanning);
      window.addEventListener("mouseleave", onPanEnd);
    } else {
      window.removeEventListener("touchmove", onPanning);
      window.removeEventListener("touchend", onPanEnd);
      window.removeEventListener("mouseup", onPanEnd);
      window.removeEventListener("mousemove", onPanning);
      window.removeEventListener("mouseleave", onPanEnd);
    }
    return () => {
      window.removeEventListener("touchmove", onPanning);
      window.removeEventListener("touchend", onPanEnd);
      window.removeEventListener("mouseup", onPanEnd);
      window.removeEventListener("mousemove", onPanning);
      window.removeEventListener("mouseleave", onPanEnd);
    };
  }, [panStatus, selected]);

  const {
    blur,
    contrast,
    brightness,
    saturate,
    sepia,
    hueRotate,
    grayScale,
    shadowSize,
    shadowColor,
    shadowBlur,
    scaleX,
    scaleY,
    opacity,
    borderRadius,
    enableOverlay,
    overlayColor,
    overlayType,
    overlayDegree,
    borderWidth,
    borderColor,
    enabledShadow,
    shadowType,
  } = styles || {};

  const handleTouchStart = (e) => {
    if (!croppingImage & selected) {
      onDragStart(e);
    } else {
      onSelect(e);
    }
  };

  const hexToRgb = (hex) => {
    let color = tinycolor(hex);
    color.setAlpha(0.7);
    return tinycolor(color).toRgbString();
  };

  const calcBorderRadius = Number.isInteger(borderRadius)
    ? borderRadius + "px"
    : borderRadius;

  // const onPanMouseStart = (e) => {
  //   e.preventDefault(); // important mozila
  //   onPanTouchStart(e);
  // };

  const handleDragStart = (e) => {
    e.preventDefault(); // Important! Moz
    onDragStart(e);
  };

  let selectedShadowValue = "";
  if (enabledShadow) {
    selectedShadowValue = getSelectedShadow({
      shadowType, 
      shadowTypeOptions, 
      shadowColor, 
      shadowSize,
      shadowBlur,
      tempScale
    })
  }

  const [loadingImage, setLoadingImage] = useState(true);
  useEffect(() => {
    if (typeof content === 'object' && Object.values(content).length > 0) {
      if (content?.small !== imageSrc) {
        setImageSrc(content.small);
        setLoadingImage(true);
      };
    } else if (typeof content === 'string'){
        if (content !== imageSrc) {
          setImageSrc(content);
          setLoadingImage(true);
        }
    }
  }, [content]);
  
  // useEffect(() => {
  //   if (loadingImage) {
  //     setTimeout(() => {
  //       setLoadingImage(false);
  //     }, 1000)
  //   };
  // }, [loadingImage]);
  //  const [replacing, setReplacing] = useState(false);
  const finishedLoading = useCallback(() => {
    setLoadingImage(false);
  }, [content]);

  const filter = `contrast(${contrast}%) brightness(${brightness}%) saturate(${saturate}%) sepia(${sepia}%) grayscale(${grayScale}%) hue-rotate(${hueRotate}deg) blur(${blur}px)`;
  // const pngShadow = enabledShadow && fileType === "png"
  // ? `drop-shadow(${shadowColor} -${shadowSize}px ${
  //     shadowSize + 0
  //   }px 0px) drop-shadow(${shadowColor} ${
  //     shadowSize + 0
  //   }px ${shadowSize}px 0px) drop-shadow(${shadowColor} ${shadowSize}px -${shadowSize}px 0px) drop-shadow(${shadowColor} -${shadowSize}px -${shadowSize}px 0px)`
  // : undefined;

  const applyPngShadow = useMemo(() => {
    let dropShadow = undefined;
    if (enabledShadow && field?.styles?.cutoutImg) {
      dropShadow = `drop-shadow(${shadowColor} -${shadowSize}px ${shadowSize + 0}px 0px) 
        drop-shadow(${shadowColor} ${shadowSize + 0}px ${shadowSize}px 0px) 
        drop-shadow(${shadowColor} ${shadowSize}px -${shadowSize}px 0px) 
        drop-shadow(${shadowColor} -${shadowSize}px -${shadowSize}px 0px)`
    } 
    return dropShadow;
  }, [
    enabledShadow, field?.styles?.cutoutImg, shadowColor, shadowSize
  ]);

  const [imgSrc, setImgSrc] = useState("");
  useEffect(() => {
    if (imageSrc !== null && typeof imageSrc === 'object' && imageBy === "oat-all") {
      setImgSrc(`https://oat-images-icons.s3.amazonaws.com/${imageSrc[windowSize.width <= 600 ? 'small' : 'medium' ]}`)
    } else if (imageBy === "oat-all" && fileType === 'svg') {
      setImgSrc(`https://oat-images-icons.s3.amazonaws.com/${content}`)
    } // if it wasn't an object 
    else if (imageSrc !== null && imageBy === "oat-all") {
      setImgSrc(`https://oat-images-icons.s3.amazonaws.com/${imageSrc}`)
    } else if (external) {
      setImgSrc(imageSrc)
    } else {
      setImgSrc(`https://oat-users.s3.amazonaws.com/${imageSrc}`)
    }
  }, [external, imageBy, fileType, imageSrc]);

  // IMPORTANT !!
  // CHANGE IN HERE, SHOULD ALSO REFLECT IN DisplayImage.js
  return (
    <>
      <DarkColorBg
        width={size?.w2}
        height={size?.h2}
        x={panXPos}
        y={panYPos}
        cutoutImg={!field?.cutoutImg}
        scaleX={scaleX}
        scaleY={scaleY}
        style={{
          visibility: croppingImage ? "visible" : "hidden",
        }}
      >
        <CropFullSizeImg
          onMouseDown={onPanTouchStart}
          onTouchStart={onPanTouchStart}
          draggable="false"
          style={{
            width: size?.w2,
            height: size?.h2,
            transform: `translate(${panXPos}px, ${panYPos}px) scaleX(${scaleX}) scaleY(${scaleY})`,
            WebkitFilter: filter,
            MozFilter: filter,
            MsFilter: filter,
            filter: filter,
          }}
          src={imgSrc}
          loading="lazy"
          onLoad={finishedLoading}
        />
      </DarkColorBg>

      {/* <svg width="0" height="0">
        <defs>
          <clipPath id="__id398">
            <path d="M747.3932691816954,0L0,92.94278361533773L214.35362302765554,943.0010002011554L797.02544975929,935.2901236699806L747.3932691816954,0Z">
            </path>
          </clipPath>
        </defs>
      </svg> */}
      <ImgWrapper
        onTouchStart={handleTouchStart}
        onMouseDown={handleDragStart}
        style={{
          // clipPath: `url(#__id398)`,
          height: "100%",
          opacity: loadingImage ? 0.4 : 1,
          zIndex: croppingImage ? 1000 : undefined,
          borderRadius: calcBorderRadius,
          border: `${borderWidth * tempScale}px solid ${borderColor}`,
          transform: `scale(1) translateZ(0px)`,
          MozBoxShadow: !croppingImage && enabledShadow && !field?.cutoutImg ? selectedShadowValue : undefined,
          WebkitBoxShadow: !croppingImage && enabledShadow && !field?.cutoutImg ? selectedShadowValue : undefined,
          boxShadow: !croppingImage && enabledShadow && !field?.cutoutImg ? selectedShadowValue : undefined,
          WebkitPrintColorAdjust: "exact",
          MozFilter: applyPngShadow,
          WebkitFilter: applyPngShadow,
          filter: applyPngShadow,

          // svg
          // position: 'absolute',
          // top: 0,
          // left: 0,
          // transform: `scaleX(${size.w2 / size.w}) scaleY(${size.h2 / size.h}) translateZ(0px)`,
        }}
      >
        <Image
          draggable="false"
          style={{
            // width: '100%',
            // height: '100%',
            // clipPath: `url(#__id398)`,
            width: size?.w2,
            height: size?.h2,
            transform: `translate(${panXPos}px, ${panYPos}px) scaleX(${scaleX}) scaleY(${scaleY})`,
            opacity: !croppingImage ? opacity : 1,
            // deleted blur
            WebkitFilter: filter,
            MozFilter: filter,
            MsFilter: filter,
            filter: filter,
          }}
          src={imgSrc}
          loading="lazy"
          onLoad={finishedLoading}
        />
        {loadingImage ? (
          <WrapSpinner>
            <Spinner />
          </WrapSpinner>
        ) : undefined}
        {enableOverlay ? (
          <Overlay
            style={{borderRadius: calcBorderRadius}}
            type={overlayType}
            color={overlayColor ? hexToRgb(overlayColor) : undefined}
            degree={overlayDegree}
          />
        ) : undefined}
      </ImgWrapper>
    </>
  );
};

export const ImgWrapper = styled.div`
  transform-origin: left top;
  // transform: translate3d(0, 0, 0);
  // -webkit-transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-appearance: none;
`;

export const imageStyles = css`
  backface-visibility: hidden;
  // -webkit-filter: blur(0);
  // for blur
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  // disable pop up on mobile
  user-drag: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
`

export const Image = styled.img`
  user-select: none;
  width: 100%;
  ${imageStyles};
`;

const CropFullSizeImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
  backface-visibility: hidden;
`;

const DarkColorBg = styled.div`
  &:after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    background: ${({cutoutImg}) =>
      cutoutImg ? "#131313" : "rgba(215, 217, 216, 0.5)"};
    width: ${({width}) => width}px;
    height: ${({height}) => height}px;
    transform: ${({x, y}) => `translate(${x}px, ${y}px)`};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: ${({color, type, degree}) =>
    type === "full"
      ? color
      : `linear-gradient(${degree}deg, hsla(0,0%,100%,0),hsla(0,0%,100%,0) 25%, ${color})`};
`;

const WrapSpinner = styled.div`
  position: absolute;
  top: 48%;
  left: 48%;
  z-index: 555;
`;

// `linear-gradient(${degree}deg, rgb(255 255 255 / 0%), ${color})`};

export default connect(null, {
  transformImgOffset,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ImageField);
