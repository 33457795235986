import React from "react";
import WrapTextList from "./text/WrapTextList";
import WrapShapeList from "./shapes/WrapShapeList";
import WrapCreateVideo from "./video/WrapCreateVideo";
import WrapCreateChart from "./chart/WrapCreateChart";

const CreateFieldsModal = ({type, close, mode}) => {
  let displayModals = "";
  if (type === "text") {
    return (displayModals = <WrapTextList close={close} mode={mode} />);
  } else if (type === "shape") {
    return (displayModals = <WrapShapeList close={close} mode={mode} />);
  }  else if (type === "chart") {
    return (displayModals = <WrapCreateChart close={close} mode={mode} />);
  } else if (type === "video") {
    return (displayModals = <WrapCreateVideo close={close} mode={mode} />);
  } 

  return displayModals;
};

export default CreateFieldsModal;
