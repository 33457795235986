import React from "react";
import {Section} from "../workspaceStyles";
import LeaveDeleteWorkspace from "./LeaveDeleteWorkspace";
import RenameWorkspace from "./RenameWorkspace";
import styled from "styled-components";

const WorkspaceSetting = ({close}) => {
  return (
    <Layout>
      <Section>
        <RenameWorkspace close={close} />
      </Section>

      <LeaveDeleteWorkspace close={close} />
    </Layout>
  );
};

const Layout = styled.div`
  @media only screen and (max-width: 600px) {
    padding-bottom: 200px;
  }
`;

export default WorkspaceSetting;
