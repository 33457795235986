import styled from "styled-components";
import {black1, red1} from "../../../../oat-color-variables";
import {Bouncy} from "../../../../oat-ui";
import {sidebarHeader} from "../reusableStyles/sidebarItem";

export const FolderLayout = styled.div`
  font-size: 0.9rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const FolderSection = styled.div`
  ${sidebarHeader}
`;

export const FolderHeader = styled.div`
  display: flex;
  flex: 1;
  font-size: 0.95rem;
  font-weight: 700;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

export const WrapSvg = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: #858383;
  margin: 0;
`;

export const TextField = styled.input`
  width: 100%;
  height: 32px;
  color: #232323;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #cdcdcd;
  padding: 6px 3px;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
`;

export const WrapButton = styled.div`
  height: 32px;
`;

export const Cancel = styled.div`
  font-size: 0.82rem;
  cursor: pointer;
  color: ${black1};
`;

export const Error = styled.div`
  color: ${red1};
  font-size: 0.9rem;
  font-weight: 700;
  padding-top: 12px;
  text-align: right;
`;
