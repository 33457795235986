import React, {useState} from "react";
import {ButtonSpinner, Modal, ShowError} from "../../../../../oat-ui";
import {renameTemplate} from "../../../../store/actions/template.action";
import {connect} from "react-redux";
import {Button} from "../../../../../oat-ui";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {useParams} from "react-router-dom";
import {
  Footer,
  BottomSection,
  WrapField,
  ContentSection,
  TextField,
} from "./modalStyles";
import {Cancel, Header, Layout} from "../../../reuse/dashboardUI/modalStyles";

const RenameTemplate = ({renameTemplate, close, item, user}) => {
  const [value, setValue] = useState(item.title);
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    err: null,
  });

  let params = useParams();
  const {section, folderID, workspaceID} = params;

  const changeValue = (e) => {
    setValue(e.target.value);
  };

  const handleRename = async () => {
    setSubmitStatus({
      loading: true,
      err: null,
    });
    const requestOptions = {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        user: user.username,
        tempID: item.id,
        section,
        workspaceID,
        folderID,
        tempTitle: value,
      }),
    };
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/name`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        renameTemplate({
          updatedTitle: value,
          tempID: item.id,
        });
        setSubmitStatus({
          loading: false,
          err: null,
        });
        close();
      });
  };

  return (
    <Modal width={320} height="auto" close={close}>
      <Layout>
        <ContentSection>
          <Header style={{flex: 1}}>Rename</Header>

          <div>
            <WrapField>
              <TextField value={value} onChange={changeValue} autoFocus />
            </WrapField>

            {submitStatus.err ? (
              <ShowError message="Something went wrong." />
            ) : undefined}
          </div>
        </ContentSection>
        <Footer>
          <BottomSection
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Cancel onClick={close}>Cancel</Cancel>
            <Button
              styles={{width: 94}}
              onClick={handleRename}
              disabled={submitStatus.loading}
            >
              {!submitStatus.loading ? "Rename" : <ButtonSpinner />}
            </Button>
          </BottomSection>
        </Footer>
      </Layout>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {renameTemplate})(RenameTemplate);
