import styled from "styled-components";

export const WrapItem = styled.div`
  width: 100%;
  margin: 15px 0;
`;

export const ColorsSection = styled.div`
  display: flex;
  align-items: center;
`;
