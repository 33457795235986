import React, {useLayoutEffect, useState} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import styled from "styled-components";
import {green} from "../../../themes";
import {sidebarWidth} from "../../../layoutSizes";
import {
  CanvasLayout,
  WrapFrame,
  Card,
} from "../canvasPanel/canvas/CanvasStyles";
import ViewOnlyFields from "../canvasPanel/viewOnlyFields/ViewOnlyFields";

const MemoizedViewOnlyFields = React.memo(
  ({editingDesign, field, activeSlide}) => (
    <ViewOnlyFields
      editingDesign={editingDesign}
      field={field}
      activeSlide={activeSlide}
    />
  )
);

export const SortableItem = ({
  id,
  $activeSlide,
  currentDragSlide,
  slide,
  canvasSize,
  index,
  onRightClick,
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: currentDragSlide ? 10 : 2,
    margin: "0 0 6px",
  };

  const width = canvasSize.w,
    height = canvasSize.h;
  const sidebarFrame = sidebarWidth - 15;
  const [frame, setFrame] = useState({
    scale: 0,
    frameWidth: 0,
    frameHeight: 0,
  });

  useLayoutEffect(() => {
    if (height > width) {
      const frameHeight = (80 * sidebarFrame) / 100;
      const frameWidth = (frameHeight * width) / height;
      const scale = Math.max(frameHeight / height, frameWidth / width);
      setFrame({
        scale,
        frameWidth,
        frameHeight,
      });
    } else if (height === width) {
      const frameWidth = (70 * sidebarFrame) / 100;
      const frameHeight = (70 * sidebarFrame) / 100;
      const scale = Math.min(frameHeight / height, frameWidth / width);
      setFrame({
        scale,
        frameWidth,
        frameHeight,
      });
    } else {
      const frameWidth = (80 * sidebarFrame) / 100;
      const frameHeight = (frameWidth * height) / width;
      const scale = Math.min(frameHeight / height, frameWidth / width);
      setFrame({
        scale,
        frameWidth,
        frameHeight,
      });
    }
  }, [canvasSize.w, canvasSize.h]);

  const handleClick = (e) => {
    if (e.type === "contextmenu") {
      onRightClick(e, slide.id);
      e.preventDefault();
    }
  };

  const ignoreEvent = (e) => {
    e.stopPropgation();
  };

  const {scale, frameWidth, frameHeight} = frame;

  return (
    <Item
      onClick={ignoreEvent}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <WrapTemplate $activeSlide={$activeSlide}>
        <Section>
          <SlideNo>{index + 1}</SlideNo>
          <div
            onContextMenu={handleClick}
            style={{background: "#fff", boxShadow: "0 0 1px 0px #565656"}}
          >
            <CanvasLayout
              style={{
                width: frameWidth,
                height: frameHeight, // remove white space
                overflow: "hidden",
                background: !slide.bg.gradient
                  ? slide.bg.bg1
                  : slide.bg.type !== "center"
                  ? `linear-gradient(${slide.bg.bgAng}deg, ${slide.bg.bg1}, ${slide.bg.bg2})`
                  : `radial-gradient(${slide.bg.bg1}, ${slide.bg.bg2})`,
              }}
            >
              <WrapFrame
                width={width}
                height={height}
                style={{transform: `scale(${scale})`}}
              >
                <Card style={{overflow: "hidden"}}>
                  {slide?.fields
                    ? Object.entries(slide?.fields).map(([id, field], i) => (
                        <div
                          style={{pointerEvents: "none", userSelect: "none"}}
                          key={"view-" + field.key + "-" + i}
                        >
                          <MemoizedViewOnlyFields
                            editingDesign={false}
                            field={field}
                            $activeSlide={$activeSlide}
                          />
                        </div>
                      ))
                    : undefined}
                </Card>
              </WrapFrame>
            </CanvasLayout>
          </div>

          {slide.markedDone ? <MarkedAsFinished /> : undefined}
          {/* <MoreOptions onClick={(e) => onRightClick(e, 2)}>
            <svg viewBox="0 0 14 24">
              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
          </MoreOptions> */}

          <$activeSlideMark style={{display: $activeSlide ? "block" : "none"}} />
        </Section>
      </WrapTemplate>
    </Item>
  );
};

const Item = styled.div`
  pointer-events: none;
`;

const WrapMoreSvg = styled.div`
  position: absolute;
  bottom: 3px;
  right: 12px;
  display: none;
  div {
    display: flex;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background: #6d7173;
    margin-left: 5px;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  &:hover ${WrapMoreSvg} {
    display: flex;
  }
`;

const SlideNo = styled.div`
  color: ${({theme}) => theme.deskSecondaryTxt};
  width: 12px;
  padding-right: 3px;
`;

const MoreOptions = styled.div`
  width: 12px;
  height: 20px;
  fill: #fff;
  display: none;
  position: absolute;
  right: 9px;
`;

const WrapTemplate = styled.div`
  display: flex;
  justify-content: center;
  user-select: none;
  overflow: hidden;
  background: ${({theme, $activeSlide}) =>
    $activeSlide ? theme.activeSlide : undefined};
  padding: 10px 0;
  margin: 0;
  position: relative;
  &:hover ${MoreOptions} {
    display: block;
  }
  &:hover {
    cursor: pointer;
  }
`;

const MarkedAsFinished = styled.div`
  position: absolute;
  right: 15px;
  height: 11px;
  width: 11px;
  background: ${green};
  border-radius: 50%;
  border-color: #fff;
  border: 2px solid #fff;
`;

const $activeSlideMark = styled.div`
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  background: #9696ff;
`;

export default SortableItem;
