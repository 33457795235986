import React from "react";
import RangeSlider from "../../../../shared/inputs/RangeSlider";
import {textEditProps} from "../../../../desktop/text/EditText";

const EditSpacing = ({
  letterSpacing,
  lineHeight,
  onChangeLineHeight,
  onChangeLetterSpacing,
}) => {
  return (
    <>
      <div style={{paddingBottom: "2rem"}}>
        <RangeSlider
          title="Spacing"
          fieldType="letterSpacing"
          value={letterSpacing}
          max={textEditProps.letterSpacing.max}
          min={textEditProps.letterSpacing.min}
          step={textEditProps.letterSpacing.step}
          onItemChange={onChangeLetterSpacing}
        />
      </div>

      <div style={{paddingBottom: "2rem"}}>
        <RangeSlider
          title="Line Height"
          fieldType="lineHeight"
          value={lineHeight}
          max={textEditProps.lineHeight.max}
          min={textEditProps.lineHeight.min}
          step={textEditProps.lineHeight.step}
          onItemChange={onChangeLineHeight}
        />
      </div>
    </>
  );
};

export default EditSpacing;
