const ExpandArrow = (
  // <svg viewBox="0 0 24 24">
  //   <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
  // </svg>
  <svg viewBox="0 0 18 23">
    <g>
      <polyline
        points="17 8.1 9 14.9 1 8.1"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
    </g>
  </svg>
);

export default ExpandArrow;
