const StackedColumnIcon = (
  <svg width="100%" height="100%" viewBox="0 0 857.5 514.5">
    <g>
      <g>
        <rect
          fill="#7d5de0"
          x="56.595000000000006"
          y="308.7"
          height="40%"
          width="88.60000000000001"
        ></rect>
      </g>
      <g>
        <rect
          fill="#5de097"
          x="56.595000000000006"
          y="154.34999999999997"
          height="30%"
          width="88.60000000000001"
        ></rect>
      </g>
      <g>
        <rect
          fill="#7eb6e8e3"
          x="56.595000000000006"
          y="154.35000000000002"
          height="0%"
          width="88.60000000000001"
        ></rect>
      </g>
      <g>
        <rect
          fill="#7d5de0"
          x="281.8316666666667"
          y="205.8"
          height="60%"
          width="88.60000000000001"
        ></rect>
      </g>
      <g>
        <rect
          fill="#5de097"
          x="281.8316666666667"
          y="0"
          height="40%"
          width="88.60000000000001"
        ></rect>
      </g>
      <g>
        <rect
          fill="#7eb6e8e3"
          x="281.8316666666667"
          y="0"
          height="0%"
          width="88.60000000000001"
        ></rect>
      </g>
      <g>
        <rect
          fill="#7d5de0"
          x="507.0683333333334"
          y="308.7"
          height="40%"
          width="88.60000000000001"
        ></rect>
      </g>
      <g>
        <rect
          fill="#5de097"
          x="507.0683333333334"
          y="0"
          height="60%"
          width="88.60000000000001"
        ></rect>
      </g>
    </g>
  </svg>
);

export default StackedColumnIcon;
