import styled from "styled-components";

export const columnGap = 12;
export const Grid = styled.div`
  img {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 20px;
    cursor: pointer;
  }
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;
  -webkit-column-gap: ${columnGap}px;
  column-gap: ${columnGap}px;
  @media only screen and (max-width: 849px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
