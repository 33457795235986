import React from "react";
import {connect} from "react-redux";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";

const DonutChartGap = ({
  slideID,
  fieldID,
  tempScale,
  gapSize,
  updateFieldStyles,
}) => {
  const handleChange = (value) => {
    updateFieldStyles({
      active: {
        slideID,
        fieldID,
      },
      type: "donutGap",
      value: Number(value),
    });
  };

  return (
    <div>
      <RangeSlider
        title="Gap Distance"
        value={gapSize}
        max={10}
        min={0}
        step={1}
        onItemChange={handleChange}
        fieldType="gapSize"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
    tempScale: selectTempScale(designTemplate),
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(DonutChartGap);
