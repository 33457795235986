import React from "react";
import DisplayUserInfo from "./userInfo/DisplayUserInfo";
import MemberModals from "./members/MemberModals";
import SearchTemplates from "./searchTemplates/SearchTemplates";
import LoggingOut from "./logout/LoggingOut";

const DisplayNavModal = ({type, close, error}) => {
  let displayModal = null;

  switch (type) {
    case "general-user-info":
      displayModal = <DisplayUserInfo close={close} />;
      break;
    case "search-templates":
      displayModal = <SearchTemplates close={close} />;
      break;
    case "member-list":
      displayModal = <MemberModals close={close} />;
      break;
    case "logging-out":
      displayModal = <LoggingOut error={error} close={close} />;
      break;
    default:
      displayModal = "";
  }

  return displayModal;
};

export default DisplayNavModal;
