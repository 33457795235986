import React from 'react';

const AlignmentIcons = ({pageTheme, type}) => {
    const lineColor = pageTheme === 'light' ? '#727272' : '#dedede'; 
    let display = null;
    switch (type){
        case "center-center":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="76.2" y="76.1" width="75.3" height="74.95" fill="#ffdf40"/>
                    <line x1="1.5" y1="114.3" x2="227.1" y2="114.3" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="114.3" y1="1.5" x2="114.3" y2="227.1" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        case "top-center":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="76.1" y="2.9" width="75.3" height="74.95" fill="#ffdf40"/>
                    <line x1="1.9" y1="40.4" x2="227.6" y2="40.4" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="114.3" y1="1.5" x2="114.3" y2="227.1" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        case "bottom-center":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="76.7" y="150.7" width="75.3" height="74.95" fill="#ffdf40"/>
                    <line x1="1.5" y1="189.3" x2="227.1" y2="189.3" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="114.3" y1="1.5" x2="114.3" y2="227.1" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        case "center-start":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="115" y="76.1" width="75.3" height="74.95" fill="#ffdf40"/>
                    <line x1="1.5" y1="114.3" x2="227.1" y2="114.3" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="114.3" y1="1.5" x2="114.3" y2="227.1" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
            </g></svg>
        break;
        case "center-left":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="3.2" y="75.5" width="75.3" height="74.95" fill="#ffdf40"/>
                    <line x1="2" y1="114.3" x2="227.6" y2="114.3" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="40.4" y1="0.1" x2="40.4" y2="225.8" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        case "top-left":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="3" y="3" width="75.3" height="74.95" fill="#ffdf40"/>
                    <line x1="0.4" y1="40.3" x2="226.1" y2="40.3" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="40.4" y1="0.1" x2="40.4" y2="225.8" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        case "bottom-left":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="3" y="150.8" width="75.3" height="74.95" fill="#ffdf40"/>
                    <line x1="1.5" y1="189.6" x2="227.1" y2="189.6" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="40.4" y1="0.1" x2="40.4" y2="225.8" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        case "center-right":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.6 230.6">
                <g>
                    <rect x="2.5" y="2.5" width="225.6" height="225.65" transform="translate(230.6 230.6) rotate(-180)" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="5"/>
                    <rect x="150.3" y="76.5" width="75.3" height="74.95" transform="translate(376 227.9) rotate(-180)" fill="#ffdf40"/>
                    <line x1="227.7" y1="115.3" x2="2" y2="115.3" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="5"/>
                    <line x1="188.5" y1="1.1" x2="188.5" y2="226.8" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="5"/>
                </g>
            </svg>
        break;
        case "top-right":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" transform="translate(228.6 228.6) rotate(-180)" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="150.4" y="3" width="75.3" height="74.95" transform="translate(376 80.9) rotate(-180)" fill="#ffdf40"/><line x1="228.2" y1="40.3" x2="2.6" y2="40.3" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/><line x1="188.3" y1="0.1" x2="188.3" y2="225.8" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        case "bottom-right":
            display = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.6 228.6">
                <g>
                    <rect x="1.5" y="1.5" width="225.6" height="225.65" transform="translate(228.6 228.6) rotate(-180)" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <rect x="150.3" y="150.8" width="75.3" height="74.95" transform="translate(376 376.6) rotate(-180)" fill="#ffdf40"/>
                    <line x1="227.1" y1="189.6" x2="1.5" y2="189.6" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                    <line x1="188.3" y1="0.1" x2="188.3" y2="225.8" fill="none" stroke={lineColor} stroke-miterlimit="10" stroke-width="3"/>
                </g>
            </svg>
        break;
        default:

    }
    return display;
};

export default AlignmentIcons;