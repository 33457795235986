import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  selectActiveWorkSpace,
  selectFolders,
} from "../../store/selectors/userDashboard.selector";
import {greyLine, userPrimaryGrey} from "../../userDashboardStyles";
import Folders from "./folderTypes/Folders";
import Library from "./library/UserLibrary";
import ActiveWorkspace from "./workspace/ActiveWorkspace";
import {useNavigate, useParams} from "react-router-dom";
import {ActiveDot} from "./reusableStyles/sidebarItem";
import {userDashboardNavPanelHeight} from "../navigation/UserDashboardNav";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";

export const dashboardSidebarWidth = 280;
const UserSidebar = ({folders, workspaceID}) => {
  let navigate = useNavigate();
  let params = useParams();
  const {folderID} = params;

  const { height } = getWindowSize();

  const redirectToHome = () => {
    return navigate(`/dashboard/${workspaceID}/designs/home`);
  };

  const redirectToProfile = () => {
    return navigate(`/dashboard/${workspaceID}/designs/profile`);
  }

  return (
    <UserSidebarPanel>
      <ActiveWorkspace />
      <SplitLine />

      <Home onClick={redirectToHome}>
        {/* <div>
          <WrapSvg>
            <svg viewBox="0 0 24 24">
              <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8h5z"></path>
            </svg>
          </WrapSvg>
        </div> */}
        <div
          style={{
            display: "flex",
            marginLeft: 8,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "0.95rem",
            fontWeight: 700,
          }}
        >
          <p>Team Recents</p>
          <ActiveDot
            style={{margin: "4px 18px 0 0"}}
            $active={folderID === "home" ? true : false}
          />
        </div>
      </Home>
      <SplitLine />

      <Folders sectionTitle="Designs" folderType="designs" folders={folders} />
      <SplitLine />

      <Library />

      {/* <ProfileInfo panelHeight={height} onClick={redirectToProfile}>
        <div>Profile Page</div>
      </ProfileInfo> */}
    </UserSidebarPanel>
  );
};

export const UserSidebarPanel = styled.div`
  position: relative;
  width: ${dashboardSidebarWidth}px;
  height: ${window.innerHeight - 57}px;
  padding-top: 0px;
  background: #fff;
  top: 57px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 849px) {
    display: none;
  }
`;

export const SplitLine = styled.div`
  height: 1px;
  background: ${greyLine};
`;

const Home = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  padding: 12px 10px;
  align-items: center;
  font-size: 0.9rem;
  box-sizing: border-box;
  cursor: pointer;
  p {
    padding-top: 3px;
    font-weight: 600;
    color: ${userPrimaryGrey};
  }
`;

const ProfileInfo = styled.div`
  position: absolute;
  top: ${({panelHeight}) => panelHeight ? panelHeight - 108 : panelHeight}px;
  left: 0;
  width: 100%;
  font-size: 14px;
  height: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 111;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    margin: 12px;
    background: #e7e6ff;
    color: #686767;
    font-weight: 700;
    border-radius: 6px;
    cursor: pointer;
  }
`

const mapStateToProps = (state) => {
  const {userDashboard} = state;
  return {
    workspaceID: selectActiveWorkSpace(userDashboard).ID,
    folders: selectFolders(userDashboard).designs,
  };
};

export default connect(mapStateToProps, null)(UserSidebar);
