import React from "react";
import {BottomUpDrawer, Modal} from "../../../../oat-ui";
import GuestComments from "./GuestComments";

const modalHeight = 520;
const GuestCommentSection = ({close}) => {
  return window.innerWidth > 600 ? (
    <Modal
      width={520}
      height={modalHeight}
      top={(window.innerHeight - modalHeight) / 2.8}
      overflowY="hidden"
      close={close}
    >
      <GuestComments />
    </Modal>
  ) : (
    <BottomUpDrawer
      open={true}
      height={window.innerHeight - 60}
      styles={{overflowY: "hidden"}}
      close={close}
    >
      <GuestComments />
    </BottomUpDrawer>
  );
};

export default GuestCommentSection;
