import React from "react";
import {Option} from "../../../../../../../ui/inputs/DropDown";

const DisplayFont = ({item, onSelectItem, selected}) => {
  const handleClick = () => {
    onSelectItem(item);
  };

  return (
    <Option active={selected === item?.fontFamily} onClick={handleClick}>
      <p
        style={{
          fontFamily: item?.fontFamily,
          fontWeight: 400,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          width: "100%",
        }}
      >
        {item?.fontFamily}
      </p>
    </Option>
  );
};

export default DisplayFont;
