import React, { useState, useEffect } from 'react';
import { WrapIconItem, WrapItem, WrapSpinner } from '../reusableStyles/reusableStyles';
import { Spinner } from '../../../../../../oat-ui';
import SearchInput from '../../../../../ui/inputs/SearchInput';
import { Error, WrapItems } from './CreateIcon';

const BrandFetch = ({onCreate}) => {
  const [brandData, setBrandData] = useState();
  const [error, setError] = useState({message: null});
  const [loading, setLoading] = useState(false);
  const [searchDomain, setSearchDomain] = useState("");
//   const [searchQuery, setSearchQuery] = useState("apple");
//   useEffect(() => {
//   }, [searchQuery]);

  const handleQueryChange = (e) => {
    const domain = e.target.value
    setSearchDomain(domain);
    if (domain === "") return;

    // Clear the previous timeout
    if (handleQueryChange.timeoutId) {
        clearTimeout(handleQueryChange.timeoutId);
    }

    handleQueryChange.timeoutId = setTimeout(async () => {
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                Authorization: 'Bearer ewvzHvKKMg1rXo7MD4trOURjWDxau7dQpySfWeRBtIE='
            }
        };
        try {
            setLoading(true);
            setError({message: null});
            setBrandData();
            // https://api.brandfetch.io/v2/search/${searchQuery}
            await fetch(`https://api.brandfetch.io/v2/brands/${domain}`, options)
                .then((res) => res.json())
                .then((data) => {
                    if (data?.logos) {
                        setBrandData(data);
                    }
                    //  else {
                    //     setError({message: "Invalid Domain"});
                    // }
                    setLoading(false);
                })
                // .catch((e) => {
                //     setLoading(false);
                //     setError({message: 'Something went wrong'});
                // })
        } catch (error) {
            setError({message: 'Something went wrong'});
            setLoading(false);
        }    
    }, 3000);
    // return () => clearTimeout(delayDebounceFn);
  }
  
  return (
    <>
        <SearchInput value={searchDomain} placeholder="Search Brand's Domain" onChange={handleQueryChange} />

        {/* {brandData && brandData.map((item, index) => (
            (
                <DisplayBrand index={index} item={item} onCreate={onCreate} />
            )
        ))} */}

        <WrapItems style={{width: '100%'}}>
            {loading ? 
                <WrapSpinner>
                    <Spinner />
                </WrapSpinner> 
            : undefined}

            {!loading && error.message !== null ?
                <Error>{error.message}</Error> 
            : undefined}

            {!loading && brandData?.logos && brandData?.logos.map((logo) => (
                logo.formats && logo.formats.map((format, i) => {
                    if (format.format === 'png') {
                        return (
                            <DisplayLogos index={i} item={format} onCreate={onCreate} />
                        )
                    }
                })
            ))}
        </WrapItems>
    </>
  )
  
};

const DisplayLogos = ({item, onCreate}) => {
    const handleClick = () => {
        const payload = {
            image: item.src,
            imgType: item.format,
            imgSize: {
                w: item.width, h: item.height
            }
        }
        onCreate(payload);
    }
    return (
        <WrapIconItem onClick={handleClick}>
            <WrapItem>
                <img style={{width: 100, height: 100}} src={item.src} loading='lazy'  />
            </WrapItem>
        </WrapIconItem>
    )
}

export default BrandFetch;