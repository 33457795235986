import React, {useState} from "react";
import {connect} from "react-redux";
import {groupFieldsAsOne, onSelectField, setFieldToAlignPosition} from "../../../../../store/actions/fields/common.action";
import DropDown from "../../../../../ui/inputs/DropDown";
import {Title} from "../shared/reuseStyles/Title";
import styled from "styled-components";
import {PanelLayout} from "./panelStyles";
import SpecialButton from "../../../../../ui/inputs/SpecialButton";
import { selectInterceptedFields } from "../../../../../store/selectors/common.selector";
import { useSocket } from "../../../../../webSocket/useSocket";

const aligns = [
  {display: "Center", value: "center-center"},
  {display: "Left Side Center", value: "center-left"},
  {display: "Left Top", value: "top-left"},
  {display: "Center Start", value: "center-middle-edge"},
  {display: "Top Center Start", value: "top-middle-edge"},
];
const GroupedFieldsPanel = ({setFieldToAlignPosition, groupSelect, groupFieldsAsOne, onSelectField}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const {emitSocketEvents} = useSocket() || {};

  const changeFieldAlignment = (value) => {
    setSelectedOption(value);
    setFieldToAlignPosition({active: null, payload: value});
  };

  const handleGroupSelection = async () => {
    // else ungroup
    await groupFieldsAsOne();
    if (emitSocketEvents) { 
      await emitSocketEvents({
        actionType: "group-fields-as-one",
        item: {
          type: "",
          value: groupSelect,
        },
      });
    }

    if (groupSelect.keys.length > 0)
      await onSelectField(groupSelect.keys[0])
  }

  return (
    <PanelLayout style={{padding: '2em 0px 2em', minHeight: 'auto'}}>
      <div style={{display: "flex", paddingBottom: 12}}>
        <div style={{display: "flex", flex: 1, alignItems: "center"}}>
          <Title>Set Position</Title>
        </div>
        <WrapDropDown>
          <DropDown
            onSelect={changeFieldAlignment}
            selected={selectedOption}
            options={aligns}
            minWidth={150}
            boxWidth={150}
            height={210}
          />
        </WrapDropDown>
      </div>

      <div style={{display: "flex", margin: '16px 12px 18px 0'}}>
        <div style={{display: "flex", flex: 1, alignItems: "center"}}>
          <Title>Group as One</Title>
        </div>
        
        <div style={{width: 150, height: 30}}>
          <SpecialButton 
            styles={{
              background: groupSelect.groupAsOne ? '#ef7888' : undefined
            }} 
            onClick={handleGroupSelection}
          >
            {groupSelect.groupAsOne ? "Ungroup Items" : "Group Items"}
          </SpecialButton>
        </div>
      </div>
    </PanelLayout>
  );
};

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const mapStateToProps = (state) => {
  return {
    groupSelect: selectInterceptedFields(state.designTemplate),
  }
}

export default connect(mapStateToProps, {setFieldToAlignPosition, onSelectField, groupFieldsAsOne})(GroupedFieldsPanel);
