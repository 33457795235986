import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {darkGrey1, paleBlue} from "../../oat-color-variables";
import {Refresh} from "../../oat-svg-icons";
import NotificationItem from "./NotificationItem";
import {Spinner} from "../../oat-ui";

const NotificationLayout = ({
  items,
  selectedTab,
  user,
  fetching,
  onSelectNotifications,
  onSelectInvitations,
  onSelectItem,
  handlePopperClose,
  acceptWsInvitation,
  handleWsInvitation,
  fetchNotifications,
  error,
  $panelHeight,
}) => {
  const [invitationList, setInvitationList] = useState([]);

  useEffect(() => {
    const invitationItems = items.filter(
      (item, i) => item.type === "invitation"
    );
    setInvitationList(invitationItems);
  }, [items]);

  return (
    <Wrapper>
      <Header>
        <Tabs>
          <div onClick={onSelectNotifications}>Notifications</div>
          <div onClick={onSelectInvitations}>
            Invitations
            {invitationList.length > 0 ? (
              <span>{invitationList.length}</span>
            ) : undefined}
          </div>
        </Tabs>
        <TabLine active={selectedTab} />
      </Header>
      <Items
        style={{
          paddingBottom: $panelHeight ? 100 : undefined,
          height: $panelHeight ? $panelHeight : undefined,
        }}
      >
        {invitationList &&
          selectedTab === "invitation" &&
          !fetching &&
          invitationList.map((item, i) => (
            <NotificationItem
              key={item.ID}
              item={item}
              isInvitation={true}
              onSelect={onSelectItem}
              onClose={handlePopperClose}
              acceptInvitationInfo={acceptWsInvitation}
              onAcceptWsInvitation={handleWsInvitation}
            />
          ))}

        {!fetching &&
        invitationList &&
        selectedTab === "invitation" &&
        invitationList.length === 0 ? (
          <Message>No Invitations Yet.</Message>
        ) : undefined}

        {fetching ? (
          <WrapSpinner>
            <Spinner />
          </WrapSpinner>
        ) : undefined}

        {items && items?.length > 0 && selectedTab === "notification"
          ? items.map(
              (item, i) => (
                // item.type !== "invitation" ? (
                <NotificationItem
                  key={item.ID}
                  item={item}
                  username={user.username}
                  onClose={handlePopperClose}
                  acceptInvitationInfo={acceptWsInvitation}
                  onAcceptWsInvitation={handleWsInvitation}
                />
              )
              // ) : undefined
            )
          : undefined}

        {!fetching &&
        items &&
        selectedTab === "notification" &&
        items?.length === 0 ? (
          <Message>No Notifications.</Message>
        ) : undefined}

        {/* {invitations &&
              selectedTab === "invitation" &&
              invitations.length === 0 ? (
                <Message>No New Invitations.</Message>
              ) : undefined} */}

        {error.status ? (
          <Message>
            {error.message}
            <WrapSvg onClick={fetchNotifications}>{Refresh}</WrapSvg>
          </Message>
        ) : undefined}
      </Items>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const Header = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${paleBlue};
  text-transform: capitalize;
  width: 100%;
`;

const Items = styled.div`
  padding: 12px 12px 0;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tabs = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;
    span {
      width: 20px;
      display: flex;
      height: 20px;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      background: linear-gradient(345deg, #6681ff, #dd94d4);
      color: #fff;
      border-radius: 50%;
      margin-left: 8px;
      box-sizing: border-box;
    }
  }
`;

const TabLine = styled.div`
  height: 0.12rem;
  width: 50%;
  margin: 0;
  background: #6b71f8;
  border: none;
  transition: 0.3s ease-in-out;
  ${({active}) => {
    switch (active) {
      case "invitation":
        return `margin-left: 50%;`;
      default:
        return `margin-left: 0%;`;
    }
  }}
`;

const Message = styled.div`
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  color: ${darkGrey1};
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  fill: #595959;
  cursor: pointer;
`;

const WrapSpinner = styled.div`
  margin-top: 100px;
`;

export default NotificationLayout;
