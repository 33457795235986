import React from "react";
import {Modal} from "../../../../../../oat-ui";
import {royalBlue, white} from "../../../../../themes";
import CreateVideo from "./CreateVideo";

const WrapCreateVideo = ({close, mode}) => {
  return (
    <Modal
      width={620}
      height={440}
      top={70}
      close={close}
      background={mode === "dark" ? royalBlue : white}
    >
      <CreateVideo closeModal={close} />
    </Modal>
  );
};

export default WrapCreateVideo;
