import React, {useState} from "react";
import {red1, red5} from "../../../../../oat-color-variables";
import {Button, ButtonSpinner} from "../../../../../oat-ui";
import {Header, Section, Message, Error} from "../workspaceStyles";
import styled from "styled-components";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import {useNavigate} from "react-router-dom";

const LeaveDeleteWorkspace = ({user, workspace, close}) => {
  const [displayDeleteWSButton, setDisplayDeleteWSButton] = useState({
    status: false,
    type: null, // leave ws or delete ws
  });
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  function proceedStatus(type) {
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
    setDisplayDeleteWSButton({
      status: true,
      type,
    });
  }

  function proceedToLeave() {
    proceedStatus("leave");
  }

  function proceedToDelete() {
    proceedStatus("delete");
  }

  async function handleLeave() {
    if (!processing) {
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspace.ID}/members`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            memberToLeave: user.username,
          }),
        }
      )
        .then((response) => response.json())
        .then(async (data) => {
          setProcessing(false);
          if (data.status === "success") {
            await close();
            return navigate(
              `/dashboard/${data.redirectedWorkspace}/designs/home`
            );
          } else {
            setError({
              status: true,
              message: data.message,
            });
          }
        })
        .catch((e) => {
          setError({
            status: true,
            message: "Something went wrong.",
          });
          setProcessing(false);
        });
    }
  }

  const handleDelete = async () => {
    if (!processing) {
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspace.ID}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then(async (data) => {
          setProcessing(false);
          if (data.status === "success") {
            await close();
            return navigate(
              `/dashboard/${data.response.reRoutedWorkspace.ID}/designs/home`
            );
          } else {
            setError({
              status: true,
              message: data.message,
            });
          }
        })
        .catch((e) => {
          setError({
            status: true,
            message: "Something went wrong.",
          });
          setProcessing(false);
        });
    }
  };

  const handleOneTimePayment = async () => {
    await fetch(
      `https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/one-time-checkout/workspaces/${workspace.ID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: user.username,
          unit_amount: 2000,
          productName: "Premium Stickers",
        }),
      }
    )
      .then((res) => {
        if (res.status == 303) {
          return res.json();
        }
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({url}) => (window.location = url))
      .catch((e) => {});
  };

  return (
    <Layout>
      {workspace.role === "admin" ||
      workspace.role === "collaborator" ||
      workspace.role === "owner" ? (
        <Section style={{marginBottom: 42}}>
          <Header style={{color: red1}}>Leave Workspace</Header>

          <WrapMessage>
            <Message>Are you sure you want to leave the workspace? </Message>
            <Proceed onClick={proceedToLeave}>Proceed</Proceed>
          </WrapMessage>

          {displayDeleteWSButton.status &&
          displayDeleteWSButton.type === "leave" ? (
            <WrapDeleteButton onClick={handleLeave}>
              <Button styles={{background: red5}} disabled={processing}>
                {!processing ? "Leave Workspace" : <ButtonSpinner />}
              </Button>
            </WrapDeleteButton>
          ) : undefined}

          {error.status && displayDeleteWSButton.type === "leave" ? (
            <Error>{error.message}</Error>
          ) : undefined}
        </Section>
      ) : undefined}

      {workspace.role === "owner" ? (
        <Section style={{marginBottom: 42}}>
          <Header style={{color: red1}}>Delete Workspace</Header>

          <WrapMessage
            style={{flexDirection: "column", alignItems: "flex-start"}}
          >
            <Message>
              Deleting the workspace would remove all the data within it. This
              action cannot be undo.
            </Message>
            <div style={{marginTop: 18, display: "flex"}}>
              <Message>Are you sure you want to proceed?</Message>
              <Proceed onClick={proceedToDelete}>Proceed</Proceed>
            </div>
            <Message style={{fontSize: "0.85rem", marginTop: 15}}>
              * If you are on a paid plan, you will automatically be downgraded.
            </Message>
          </WrapMessage>

          {displayDeleteWSButton.status &&
          displayDeleteWSButton.type === "delete" ? (
            <WrapDeleteButton onClick={handleDelete}>
              <Button styles={{background: red5}} disabled={processing}>
                {!processing ? "Delete Workspace" : <ButtonSpinner />}
              </Button>
            </WrapDeleteButton>
          ) : undefined}

          {error.status && displayDeleteWSButton.type === "delete" ? (
            <Error>{error.message}</Error>
          ) : undefined}
        </Section>
      ) : undefined}

      {/* Test */}
      {/* <button onClick={handleOneTimePayment}>Buy</button> */}
    </Layout>
  );
};

const Layout = styled.div`
  margin-bottom: 42px;
`;

const WrapMessage = styled.div`
  display: flex;
  align-items: center;
`;

const Proceed = styled.div`
  text-decoration: underline;
  font-size: 0.95rem;
  margin-left: 22px;
  cursor: pointer;
`;

const WrapDeleteButton = styled.div`
  width: 180px;
  height: 36px;
  margin-top: 22px;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(LeaveDeleteWorkspace);
