import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {UploadFile} from "../../../../reuseStyles/reuseStyles";
import UploadImagesInPack from "./UploadImagesInPack";
import {Image} from "../../../dashboardArea/templates/templateListStyles";
import {Spinner} from "../../../../../oat-ui";

const DisplayItem = ({item}) => {
  return item.imgType === "svg" ? (
    <WrapImage>
      {/* <WrapIconItem>
        <WrapSvg>
          <svg
            data-src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`}
            width="100%"
            height="100%"
            style={{
              ...item?.displayedColor,
            }}
          />
        </WrapSvg>
      </WrapIconItem> */}
      <Image
        style={{height: "100%", objectFit: "contain"}}
        src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`}
        alt="" 
      />
      <p>{item.key}</p>
    </WrapImage>
  ) : (
    <WrapImage>
      <Image
        style={{height: "100%", objectFit: "contain"}}
        src={typeof item.image === 'object' 
          ? `https://oat-images-icons.s3.amazonaws.com/${item.image?.small}` 
          : `https://oat-images-icons.s3.amazonaws.com/${item.image}`} 
        alt="" 
      />
      <p>{item.key}</p>
    </WrapImage>
  );
};

const DisplayPackImages = ({type, category, selectedPackID, username}) => {
  const [images, setImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);

  const toggleUploadModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    setLoadingImages(true);
    fetch(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${type}/${category}/${selectedPackID}`
    )
      .then((res) => res.json())
      .then((data) => {
        setLoadingImages(false);
        setImages(data);
      })
      .catch((e) => { });
  }, [type]);

  const onUploadComplete = (payload) => {
    setImages([...images, payload]);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Wrapper
        style={{
          background: openModal ? "#3a35354d" : undefined,
          opacity: openModal ? "0.8" : 1,
        }}
      >
        <div
          style={{
            width: "100%",
            padding: 12,
            boxSizing: "border-box",
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{fontSize: 16}}>{selectedPackID}</div>
          <UploadNav>
            <UploadFile onClick={toggleUploadModal}>Upload</UploadFile>
          </UploadNav>
        </div>

        <WrapItems>
          {!loadingImages &&
            images &&
            images.map((item) => <DisplayItem key={item.key} item={item} />)}
        </WrapItems>

        {loadingImages ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: 120,
            }}
          >
            <Spinner />
          </div>
        ) : undefined}
      </Wrapper>

      {openModal ? (
        <UploadImagesInPack
          selectedPackID={selectedPackID}
          packImages={images}
          onUploadComplete={onUploadComplete}
          type={type}
          category={category}
          onClose={toggleUploadModal}
        />
      ) : undefined}
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const UploadNav = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
  flex: 1;
  width: 100%;
`;

const WrapIconItem = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  flex: 0 46%;
  background: ${({theme}) => theme.deskSecondaryBg3};
  border-radius: 10px;
  margin-bottom: 16px;
  @media only screen and (min-width: 850px) {
    flex: 0 31%;
  }
`;

const WrapSvg = styled.div`
  height: 150px;
  display: flex;
  cursor: pointer;
  @media only screen and (max-width: 849px) {
    flex: 0 80%;
    height: 120px;
  }
`;

const WrapImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  height: 200px;
  flex: 0 33%;
  margin-bottom: 16px;
`;

const WrapItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default DisplayPackImages;
