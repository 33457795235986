import React, {useEffect, useState} from "react";
import {batch, connect} from "react-redux";
import styled from "styled-components";
import SelectFontFamily from "../common/fontFamily/SelectFontFamily";
import SelectFontSize from "../common/SelectFontSize";
import {
  updateFieldStyles,
  updateMultipleFieldStyles,
} from "../../../../../../store/actions/fields/common.action";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {chartDefaultFontSize} from "../../../../canvasPanel/fields/charts/chartDefaultFontSize";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const EditAllChartLabels = ({
  updateFieldStyles,
  tempScale,
  field,
  fieldID,
  slideID,
  updateMultipleFieldStyles,
  updateFieldCollaboration,
}) => {
  const [applyToAllChecked, setApplyToAllChecked] = useState(true);
  const {fontSize, fontFamily, fontStyle, fontWeight} = field?.styles || {};

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const changeFontSize = (value) => {
    if (applyToAllChecked) {
      const active = {slideID, fieldID};
      const payload = {
        fontSize: Number(value),
        legendFontSize: Number(value / chartDefaultFontSize),
        measurementFontSize: Number(value),
        categoryFontSize: Number(value),
      };
      updateMultipleFieldStyles(active, payload);
      updateFieldCollaboration();
    } else {
      handleStyleUpdate("fontSize", Number(value));
      updateFieldCollaboration();
    }
  };

  const changeFontFamily = (value) => {
    handleStyleUpdate("fontFamily", value);
    updateFieldCollaboration();
  };

  const toggleApplyToAllStatus = () => {
    setApplyToAllChecked(!applyToAllChecked);
  };

  useEffect(() => {
    setApplyToAllChecked(true);
  }, [fieldID]);

  return (
    <>
      <Wrapper>
        <input
          type="checkbox"
          id="chart-apply-all-labels"
          name="chart-apply-all-labels"
          checked={applyToAllChecked}
          onChange={toggleApplyToAllStatus}
        />
        <span style={{fontSize: "0.8rem", fontStyle: "italic"}}>
          Applies to all labels
        </span>
      </Wrapper>

      <div style={{paddingBottom: "2em"}}>
        <SelectFontSize
          value={Math.round(fontSize)}
          onSelect={changeFontSize}
        />
      </div>

      <div>
        <SelectFontFamily
          fontFamily={fontFamily}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
          onSelect={changeFontFamily}
        />
      </div>
    </>
  );
};

const Wrapper = styled.div`
  padding: 0 0 2em 1em;
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempScale: selectTempScale(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
    field: selectActiveFieldProps(designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateFieldStyles,
    storeFieldUndoRedo,
    updateMultipleFieldStyles,
    updateFieldCollaboration,
  }))
)(EditAllChartLabels);
