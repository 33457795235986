import React, {useState, useRef} from "react";
import styled from "styled-components";
import {Button, Modal} from "../../../../../oat-ui";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {connect} from "react-redux";
import {Header, BottomSection} from "../../../reuse/dashboardUI/modalStyles";

const ShareLink = ({item, close, user}) => {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  const handleCopy = () => {
    const urlLink = inputRef.current.innerHTML;
    navigator.clipboard.writeText(urlLink);
    setCopied(true);
  };

  return (
    <Modal width={380} height="auto" close={close}>
      <Wrapper>
        <Header>Shareable Link</Header>
        <WrapInput>
          <LinkInput ref={inputRef}>
            {window.location.origin.toString() + `/p/${item.id}`}
          </LinkInput>
          <BottomSection style={{width: 100, flex: 0}}>
            <Button
              styles={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              onClick={handleCopy}
            >
              {!copied ? "Copy" : "Copied"}
            </Button>
          </BottomSection>
        </WrapInput>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: center;
`;

const WrapInput = styled.div`
  display: flex;
  width: 100%;
  padding-top: 12px;
  background: ;
`;

const LinkInput = styled.div`
  background: #f4f4f4;
  flex: 1;
  padding: 5px;
  color: #232323;
  width: 350px;
  white-space: pre;
  overflow: hidden;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(ShareLink);
