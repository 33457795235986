export const textShadowEffects = [
  {
    id: "none",
    display: "None",
    style: {},
    effects: {
      id: "none",
    },
  },
  {
    id: "single-shadow",
    display: "Single shadow",
    style: {
      textShadow: "#ea0e0e -0.035em 0.035em 0em",
      // "-1.5px 1.5px 0px #ea0e0e ",
    },
    effects: {
      id: "single-shadow",
      shadow: [{h: -0.04, v: 0.04, b: 0, c: "#ea0e0e"}],
    },
  },
  {
    id: "double-shadow",
    display: "Double Shadow",
    style: {
      textShadow: "#b11eb7ff 0.02em 0.02em 0em, #3bc6d0ff 0.035em 0.035em 0em",
      // textShadow: "2px 2px 0 #b11eb7ff, 3px 3px 0 #3bc6d0ff",
    },
    effects: {
      id: "double-shadow",
      shadow: [
        {h: 0.03, v: 0.03, b: 0, c: "#b11eb7ff"},
        {h: 0.06, v: 0.06, b: 0, c: "#3bc6d0ff"},
      ],
    },
  },
  {
    id: "stroke",
    display: "Stroke",
    style: {
      WebkitTextStroke: "1px #fff",
      WebkitTextFillColor: "transparent",
    },
    effects: {
      id: "stroke",
      strokeWidth: 0.5,
    },
  },
  {
    id: "shadow-stroke",
    display: "Stroke",
    style: {
      WebkitTextStroke: "1px #fff",
      WebkitTextFillColor: "transparent",
      textShadow:
        "3px 2px 13px rgb(57 246 255 / 72%), -2px 2px 12px rgb(245 250 255 / 46%)",
    },
    effects: {
      id: "shadow-stroke",
      strokeWidth: 0.5,
      shadow: [
        {h: 0.035, v: 0.035, b: 0.07, c: "rgb(57 246 255 / 72%)"},
        {h: -0.035, v: 0.035, b: 0.07, c: "rgb(245 250 255 / 46%)"},
        // {h: 3, v: 2, b: 13, c: "rgb(57 246 255 / 72%)"},
        // {h: -2, v: 2, b: 12, c: "rgb(245 250 255 / 46%)"},
      ],
    },
  },
  {
    id: "glow",
    display: "Glow",
    style: {
      textShadow:
        "3px 2px 13px rgb(57 246 255 / 72%), -2px 2px 12px rgb(245 250 255 / 46%)",
    },
    effects: {
      id: "glow",
      shadow: [
        {h: 0.035, v: 0.035, b: 0.07, c: "rgb(57 246 255 / 72%)"},
        {h: -0.035, v: 0.035, b: 0.07, c: "rgb(245 250 255 / 46%)"},
      ],
    },
  },
  {
    id: "txt-bg",
    display: "Text Background",
    style: {
      fontFamily: "Do Hyeon",
      textShadow:
        "#f16464 0.015em 0.015em 0em, #f16464 -0.015em -0.015em 0em, #f16464 0.015em -0.015em 0em, #f16464 -0.015em 0.015em 0em",
      // "2px 2px 0px  #f16464, -2px -2px 0px  #f16464, 2px -2px 0px  #f16464, -2px 2px 0px  #f16464",
    },
    effects: {
      id: "txt-bg",
      shadow: [
        {h: 0.025, v: 0.025, b: 0, c: "#f16464"},
        {h: -0.025, v: -0.025, b: 0, c: "#f16464"},
        {h: 0.025, v: -0.025, b: 0, c: "#f16464"},
        {h: -0.025, v: 0.025, b: 0, c: "#f16464"},
      ],
    },
  },
  {
    id: "thick-bg",
    display: "Thick Background",
    style: {
      fontFamily: "Do Hyeon",
      textShadow:
        "#f16464 0.015em 0.015em 0em, #f16464 -0.015em -0.015em 0em, #f16464 0.015em -0.015em 0em, #f16464 -0.015em 0.015em 0em",
      // "2px 2px 0px  #f16464, -2px -2px 0px  #f16464, 2px -2px 0px  #f16464, -2px 2px 0px  #f16464",
    },
    effects: {
      id: "thick-bg",
      shadow: [
        {h: 0.005, v: 0.005, b: 0, c: "#232323"},
        {h: -0.005, v: 0.005, b: 0, c: "#232323"},
        {h: 0.005, v: -0.005, b: 0, c: "#232323"},
        {h: -0.005, v: -0.005, b: 0, c: "#232323"},
        {h: 0.01, v: 0.01, b: 0, c: "#232323"},
        {h: -0.01, v: 0.01, b: 0, c: "#232323"},
        {h: 0.01, v: -0.01, b: 0, c: "#232323"},
        {h: -0.01, v: -0.01, b: 0, c: "#232323"},
        {h: 0.02, v: 0.02, b: 0, c: "#232323"},
        {h: -0.02, v: 0.02, b: 0, c: "#232323"},
        {h: 0.02, v: -0.02, b: 0, c: "#232323"},
        {h: -0.02, v: -0.02, b: 0, c: "#232323"},
        {h: 0, v: 0.04, b: 0, c: "#232323"},
        {h: 0, v: 0.05, b: 0, c: "#232323"},
        {h: 0.01, v: 0.06, b: 0, c: "#232323"},
        {h: 0.015, v: 0.07, b: 0, c: "#232323"},
        {h: 0.02, v: 0.08, b: 0, c: "#232323"},
        {h: 0.025, v: 0.09, b: 0, c: "#232323"},
        {h: 0.03, v: 0.1, b: 0, c: "#232323"},
        {h: 0.035, v: 0.11, b: 0, c: "#232323"},
        {h: 0.04, v: 0.12, b: 0, c: "#232323"}
      ],
    },
  },
];
