import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../store/selectors/fields.selector";
import {selectTemplateSize} from "../../../../../store/selectors/template/template.selector";
import {getItemCorners} from "./getRotatedOffsets";
import {TooltipStyles} from "../../../../../ui/styles/TooltipStyles";
import {paleBlue4, primaryGrey} from "../../../../../../oat-color-variables";
import {createPortal} from "react-dom";
import TooltipTypes from "./types/TooltipTypes";
import {deskNavHeight} from "../../../../../layoutSizes";
import {createContext} from "react";
import {selectCanvasPanelWidth, selectEditPanelStatus} from "../../../../../store/selectors/layout/layout.selector";

export const tooltipContext = createContext({
  offset: {x: null, y: null},
  tooltipAboveField: false,
});

const Tooltip = ({
  activeFieldProps,
  templateScale,
  tempSize,
  displayEditPanel,
  canvasPanelWidth,
}) => {
  const [offset, setOffset] = useState({x: null, y: null});
  const [tooltipSize, setTooltipSize] = useState({width: 240, height: 35});
  const {pos, size, styles} = activeFieldProps || {};

  const tooltipPosition = useCallback(() => {
    const toolTipWidth = tooltipRef?.current
      ? tooltipRef?.current?.offsetWidth
      : tooltipSize?.width; // (scale * tooltipSize.width) / 1;
    const toolTipHeight = tooltipSize?.height; // (scale * tooltipSize.height) / 1;
    const space = 10; // * (tempSize.w / 536); //12; // space from item to pos of tooltip
    const edgeSpace = 12; // edge padding 12 from panel, not starting from canvas 0
    const gapHeight = (toolTipHeight + 15);

    if (styles?.rotate === 0 || styles?.rotate === 360) {
      const combinedWidth = pos?.x + toolTipWidth / templateScale;
      // exceededWidth -> when pos.x + tooltip width is exceeding the template's width
      const exceededWidth = combinedWidth - tempSize.w;
      const combinedHeight = pos?.y + size?.h + toolTipHeight + gapHeight; // gapHeight
      // if (combinedWidth > tempSize.w && pos.y < 0) {
      //   const xOffset =
      //     (pos.x - exceededWidth) * templateScale - space + edgeSpace;
      //   const yOffset = (pos.y + size.h) * templateScale + space + edgeSpace;
      //   return {xOffset, yOffset};
      // } else
       if (combinedWidth > tempSize.w && pos?.y < 0) {
        const xOffset =
          (pos?.x - exceededWidth) * templateScale - space + edgeSpace;
        const yOffset = (pos?.y + size?.h) * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      } else if (combinedWidth > tempSize.w && combinedHeight > tempSize.h) {
        const xOffset =
          (pos?.x - exceededWidth) * templateScale - space + edgeSpace;
        const yOffset = pos?.y * templateScale - toolTipHeight - space;
        return {xOffset, yOffset};
      } else if (pos?.x < 0 && pos?.y + size?.h + gapHeight > tempSize.h) {
        const extraWidth = -pos?.x;
        const xOffset = (pos?.x + extraWidth) * templateScale + edgeSpace;
        const yOffset = (pos?.y) * templateScale - space - edgeSpace - toolTipHeight; //-toolTipHeight - space;
        // pos.y * templateScale - toolTipHeight - space;
        return {xOffset, yOffset};
      } else if (pos?.x < 0 && pos?.y < 0) {
        const extraWidth = -pos.x;
        const xOffset = (pos?.x + extraWidth) * templateScale + edgeSpace;
        const yOffset = (pos?.y + size?.h) * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      } else if (combinedWidth > tempSize.w) {
        const xOffset =
          (pos?.x - exceededWidth) * templateScale - space + edgeSpace;
        const yOffset = (pos?.y + size?.h) * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      } else if (pos?.x < 0) {
        const extraWidth = -pos?.x;
        const xOffset = (pos?.x + extraWidth) * templateScale + edgeSpace;
        const yOffset = (pos?.y + size?.h) * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      } else if (pos?.y < 0 && combinedWidth > tempSize.w) {
        const xOffset = combinedWidth - space;
        const yOffset = (pos?.y + size?.h) * templateScale + space;
        return {xOffset, yOffset};
      } else if (pos?.y < 0) {
        const xOffset = pos?.x * templateScale + edgeSpace;
        const yOffset = (size?.h + pos?.y) * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      } else if ((pos?.y + size?.h) / templateScale > (tempSize.h / templateScale) / 1.5) {
        const xOffset = pos?.x * templateScale;
        const yOffset = pos?.y * templateScale - toolTipHeight - space;
        // tempSize.h * templateScale + (toolTipHeight / 2); // pos.y * templateScale - toolTipHeight - space;
        return {xOffset, yOffset};
      } else {
        const xOffset = pos?.x * templateScale;
        const yOffset = (pos?.y + size?.h) * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      }
    } else {
      // from center to tooltip start pt.
      const x1y1 = {x: pos?.x, y: pos?.y};
      // right bottom corner of img
      const x2y2 = {x: pos?.x + size?.w, y: pos?.y + size?.h};
      const points = {
        offset: {x: (x1y1.x + x2y2.x) / 2, y: (x2y2.y + x1y1.y) / 2},
        size: {width: size?.w, height: size?.h},
        angle: (styles?.rotate * Math.PI) / 180,
      };
      const {minX, maxX, minY, maxY} = getItemCorners(points);
      const combinedWidth = minX + toolTipWidth / templateScale;
      if (minX < 0 && maxY + gapHeight > tempSize.h) {
        const xOffset = 12;
        const yOffset = minY * templateScale - gapHeight + edgeSpace;
        return {xOffset, yOffset};
      } else if (maxX > tempSize.w && maxY + gapHeight + 35 > tempSize.h) {
        const extraWidth = combinedWidth - tempSize.w;
        const xOffset = (minX - extraWidth) * templateScale - space;
        const yOffset = minY * templateScale - gapHeight + edgeSpace;
        return {xOffset, yOffset};
      } else if (maxX > tempSize.w) {
        const extraWidth = combinedWidth - tempSize.w;
        const xOffset = (minX - extraWidth) * templateScale - space;
        const yOffset = maxY * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      } else if (maxY + gapHeight + toolTipHeight > tempSize.h) {
        const xOffset = pos?.x * templateScale;
        const yOffset = minY * templateScale - gapHeight + edgeSpace;
        return {xOffset, yOffset};
      } else if (minX < 0) {
        const xOffset = 12;
        const yOffset = maxY * templateScale + 10 + edgeSpace;
        return {xOffset, yOffset};
      } else {
        const xOffset = pos?.x * templateScale;
        const yOffset = maxY * templateScale + space + edgeSpace;
        return {xOffset, yOffset};
      }
    }
  },[tempSize.w, tempSize.h, tooltipSize?.width, tooltipSize?.height, pos, size, styles?.rotate, displayEditPanel, templateScale]);

  useLayoutEffect(() => {
    const {xOffset, yOffset} = tooltipPosition();
    setOffset({
      x: xOffset + (canvasPanelWidth - tempSize.w * templateScale) / 2, //+ 1163 / 2 - 200,
      y: yOffset + deskNavHeight + 10,
    });
  }, [
    activeFieldProps?.content,
    templateScale,
    activeFieldProps?.size,
    activeFieldProps?.pos,
    displayEditPanel    
  ]);

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      const {offsetWidth, offsetHeight} = tooltipRef.current;
      setTooltipSize({
        width: offsetWidth,
        height: offsetHeight,
      });
    }
  }, [tempSize.w, templateScale]);

  const onClickedLayout = (e) => {
    e.stopPropagation();
  };

  const tooltipRef = useRef();

  return createPortal(
    window.innerWidth >= 850 && offset?.x !== null && !displayEditPanel ? (
      <Wrapper
        ref={tooltipRef}
        onMouseDown={onClickedLayout}
        style={{
          transform: `translate(${offset?.x}px, ${offset?.y}px)`,
          width: "auto",
          height: tooltipSize?.height,
        }}
      >
        {/* div important */}
        <div style={{height: "100%"}}>
          <tooltipContext.Provider
            value={{
              offset,
              size: tooltipSize,
              tooltipAboveField:
                // 12 gap
                pos?.y > 0 &&
                (pos?.y + 12 + tooltipSize?.height) * templateScale < offset?.y
                  ? false
                  : true,
            }}
          >
            <TooltipTypes
              tooltipOffset={{
                x: offset?.x,
                y: offset?.y,
              }}
            />
          </tooltipContext.Provider>
        </div>
      </Wrapper>
    ) : (
      ""
    ),
    document.getElementById("canvas-field-item-tooltip")
  );
};

const Wrapper = styled.div`
  background: #fff;
  color: ${primaryGrey};
  font-size: 0.85rem;
  transform-origin: 0 0;
  font-weight: 700;
  border-radius: 40px;
  position: absolute;
  z-index: 4444;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  padding: 0 20px;
  box-sizing: border-box;
  box-shadow: 0 0.1rem 0.2rem rgb(120 116 156 / 20%),
    0 0 0 0.1rem rgb(124 114 144 / 5%);
  top: 0;
`;

export const OnHoverContent = styled.div`
  width: auto;
  bottom: -30px;
  left: auto;
  ${TooltipStyles}
`;

export const contentStyles = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  margin-right: 4px;
  fill: ${primaryGrey};
  cursor: pointer;
  white-space: pre;
  float: left;
  &:last-child {
    margin-right: 0px;
  }
  @media only screen and (min-width: 850px) {
    &:hover {
      background: ${paleBlue4};
    }
    &:hover > div {
      display: flex;
    }
  }
`;

export const Content = styled.div`
  ${contentStyles}
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeFieldProps: selectActiveFieldProps(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    canvasPanelWidth: selectCanvasPanelWidth(designTemplate),
    // displayEditPanel: selectEditPanelStatus(designTemplate),
  };
};

export default connect(mapStateToProps, null)(Tooltip);
