import {
  CREATE_TEXT,
  UPDATE_TEXT_EFFECTS,
  APPLY_TEXT_EFFECTS,
  UPDATE_TEXT_CONTENT,
  ENABLE_TEXT_EDIT,
  ADD_TEXT_FAMILY_SOURCE,
  ADD_LINK_TO_TEXT,
  CHANGE_HIGHLIGHTED_TEXT_COLOR,
} from "../actionTypes";

export const createText = ({
  key,
  slideID,
  content,
  size,
  subtype,
  styles,
  selectFieldOnCreate = true,
}) => {
  return {
    type: CREATE_TEXT,
    key,
    slideID,
    content,
    size,
    subtype,
    styles,
    selectFieldOnCreate,
  };
};

export const updateTextEffects = ({
  active,
  key,
  value,
  arrKey,
  doubleShadow,
}) => {
  return {
    type: UPDATE_TEXT_EFFECTS,
    active,
    value,
    key,
    arrKey,
    doubleShadow,
  };
};

export const applyTextEffects = (active, value) => {
  return {
    type: APPLY_TEXT_EFFECTS,
    active,
    value,
  };
};

export const updateTextContent = (active, value) => {
  return {
    type: UPDATE_TEXT_CONTENT,
    active,
    value,
  };
};

// for text & table
export function enableTextEdit() {
  return {
    type: ENABLE_TEXT_EDIT,
  };
}

export function addTextFamilySource(payload) {
  return {
    type: ADD_TEXT_FAMILY_SOURCE,
    payload,
  };
}

export function addHightlightedTextColor({startingIndex, endingIndex, color}) {
  return {
    type: CHANGE_HIGHLIGHTED_TEXT_COLOR,
    startingIndex,
    endingIndex,
    color,
  };
}
