import React, {useState, useEffect, useRef, useCallback} from "react";
import styled from "styled-components";
import {black1, greyText3, paleBlue} from "../../../../oat-color-variables";
import Select from "../../../../oat-ui/core/Select";
import {Heart} from "../../../../oat-svg-icons";
import SuggestUsernames from "./SuggestUsernames";
import ContentEditable from "react-contenteditable";

export const boxShadow = "1px 1px 7px 1px #dedede";
const usernames = [
  {name: "Aung", username: "aktn"},
  {name: "Bo Bo", username: "bobo"},
  {name: "Thuka", username: "thuka"},
  {name: "Khant", username: "khant"},
  {name: "Joeo", username: "joe"},
  {name: "Thuta", username: "thuta"},
];

const comments = [
  {
    id: "abc",
    comment: "This looks fantastic.",
    commentedBy: {
      username: "aktn",
      name: "Aung",
    },
    timestamp: "12 / 2 / 2024",
    likes: 4,
    subcomments: [
      {
        id: "boe",
        comment: "This looks fantastic.",
        commentedBy: {
          username: "aktn",
          name: "Aung",
        },
        timestamp: "12 / 2 / 2024",
      },
      {
        id: "xoa",
        comment: "This looks amazing.",
        commentedBy: {
          username: "aktn",
          name: "Aung",
        },
        timestamp: "12 / 2 / 2024",
      },
    ],
  },
  {
    id: "efg",
    comment: "Looking great.",
    commentedBy: {
      username: "aktn",
      name: "Aung",
    },
    timestamp: "12 / 2 / 2024",
    likes: 0,
    subcomments: [],
  },
  {
    id: "2132",
    comment: "This looks fantastic.",
    commentedBy: {
      username: "aktn",
      name: "Aung",
    },
    timestamp: "12 / 2 / 2024",
    likes: 1,
    subcomments: [],
  },
  {
    id: "goae",
    comment:
      "I'm building a web app which sells several products, each product corresponds to a pricing plan. All the payment system is managed by Stripe. It happens very often that a user has a subscription that contains Product A (i.e., one pricing plan), and then he wants to add Product B (i.e., another pricing plan) to the same subscription. I want to know how to achieve this by APIs of Stri.",
    commentedBy: {
      username: "aktn",
      name: "Aung",
    },
    timestamp: "12 / 2 / 2024",
    likes: 0,
    subcomments: [],
  },
];

const DisplayComment = ({item}) => {
  const [viewReplies, setViewReplies] = useState(false);
  const addSubcomment = () => {};

  const toggleViewRepliesStatus = () => {
    setViewReplies(!viewReplies);
  };

  return (
    <Item>
      <Comment>
        <UserAvatar>
          <img
            src="https://images.unsplash.com/photo-1543466835-00a7907e9de1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
            alt="user-avatar"
          />
        </UserAvatar>

        <CommentItem>
          <div className="guest-comment">
            <div className="guest-comment__name">{item.commentedBy.name}</div>
            <div className="guest-comment__timestamp">{item.timestamp}</div>
          </div>

          <div className="guest-comment">
            <div className="guest-comment__comment">{item.comment}</div>
            <HeartShape>
              <WrapSvg liked={true}>{Heart}</WrapSvg>
            </HeartShape>
          </div>

          <div className="guest-comment__responses">
            {item.likes !== 0 ? (
              <div>
                {item.likes} {item.likes === 1 ? "Like" : "Likes"}
              </div>
            ) : undefined}

            <div className="guest-comment__reply">Reply</div>
          </div>

          {item.subcomments.length !== 0 ? (
            <ViewHideReplies onClick={toggleViewRepliesStatus}>
              {viewReplies ? <div>Hide Replies</div> : <div>View Replies</div>}
            </ViewHideReplies>
          ) : undefined}
        </CommentItem>
      </Comment>

      {viewReplies && item.subcomments && item.subcomments.length > 0 ? (
        <Subcomment>
          {item.subcomments &&
            item.subcomments.map((subcomment) => (
              <Comment style={{marginTop: 26}}>
                <UserAvatar>
                  <img
                    src="https://images.unsplash.com/photo-1543466835-00a7907e9de1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                    alt="user-avatar"
                  />
                </UserAvatar>
                <CommentItem>
                  <div className="guest-comment">
                    <div className="guest-comment__name">
                      {subcomment.commentedBy.name}
                    </div>
                    <div className="guest-comment__timestamp">
                      {subcomment.timestamp}
                    </div>
                  </div>

                  <div className="guest-comment">
                    <div className="guest-comment__comment">
                      {subcomment.comment}
                    </div>
                    <HeartShape>
                      <WrapSvg>{Heart}</WrapSvg>
                    </HeartShape>
                  </div>

                  <div className="guest-comment__responses">
                    {item.likes !== 0 ? (
                      <div>
                        {item.likes} {item.likes === 1 ? "Like" : "Likes"}
                      </div>
                    ) : undefined}

                    <div className="guest-comment__reply">Reply</div>
                  </div>
                </CommentItem>
              </Comment>
            ))}

          {/* <Textarea value="text" style={{height: 74, marginTop: 20}} />
          <div
            style={{display: "flex", justifyContent: "flex-end", marginTop: 14}}
          >
            <div onClick={addSubcomment}>Post</div>
          </div> */}
        </Subcomment>
      ) : undefined}
    </Item>
  );
};

const filterList = [
  {display: "All Comments", value: "all-comments"},
  {display: "Current Design", value: "current-design"},
];
const GuestComments = () => {
  const [selectedFilter, setSelectedFilter] = useState("all-comments");
  const [commentInput, setCommentInput] = useState("");
  const [openUsernameSuggestion, setOpenUsernameSuggestion] = useState({
    status: false,
    list: usernames,
  });
  const textRef = useRef();

  function getCharacterPrecedingCaret(containerEl) {
    let precedingChar = "",
      sel,
      range,
      precedingRange;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount > 0) {
        range = sel.getRangeAt(0).cloneRange();
        range.collapse(true);
        range.setStart(containerEl, 0);
        precedingChar = range.toString().slice(-1);
      }
    } else if ((sel = document.selection) && sel.type != "Control") {
      range = sel.createRange();
      precedingRange = range.duplicate();
      precedingRange.moveToElementText(containerEl);
      precedingRange.setEndPoint("EndToStart", range);
      precedingChar = precedingRange.text.slice(-1);
    }
    return precedingChar;
  }

  function getMentionedUserBeforeCaret(containerEl) {
    let precedingChar = "",
      sel,
      range,
      precedingRange;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount > 0) {
        range = sel.getRangeAt(0).cloneRange();
        range.collapse(true);
        range.setStart(containerEl, 0);
        precedingChar = range.toString();
      }
    } else if ((sel = document.selection) && sel.type != "Control") {
      range = sel.createRange();
      precedingRange = range.duplicate();
      precedingRange.moveToElementText(containerEl);
      precedingRange.setEndPoint("EndToStart", range);
      precedingChar = precedingRange.text;
    }
    return precedingChar;
  }

  const selectFilter = (value) => {
    setSelectedFilter(value);
  };

  const onChangeCommentInput = (e) => {
    const {value} = e.target;
    const sel = window.getSelection();
    let range = sel.getRangeAt(0);
    if (range.startContainer.parentElement.tagName.toLowerCase() === "span") {
      range.commonAncestorContainer.parentElement.remove();

      // const tnode = range.commonAncestorContainer;
      // const start = range.startOffset;
      // const text = tnode.textContent;
      // const lastTextnode = document.createTextNode(text.substring(start));
      // range.setEnd(lastTextnode, 4);
      // range.collapse();

      setCommentInput(range.commonAncestorContainer.parentElement.textContent);
    } else {
      setCommentInput(value);
    }
  };

  const addComment = () => {};

  const addMentionedUser = (item) => {
    const sel = window.getSelection();
    let range = sel.getRangeAt(0);
    const tnode = range.commonAncestorContainer;
    const start = range.startOffset;
    const text = tnode.textContent;
    const spanEl = document.createElement("span");
    spanEl.innerHTML = "@" + item.username;
    if (
      sel.anchorNode.parentElement.localName === "span" &&
      sel.focusNode.parentElement.localName === "span"
    ) {
      let caretOffset = 0;
      let rangy = window.getSelection().getRangeAt(0);
      let selected = rangy.toString().length;
      let preCaretRange = rangy.cloneRange();
      preCaretRange.selectNodeContents(textRef.current);
      preCaretRange.setEnd(rangy.endContainer, rangy.endOffset);
      caretOffset = preCaretRange.toString().length - selected;
      const parentText =
        range.commonAncestorContainer.parentElement.parentElement.textContent;
      const firstTextnode = document.createTextNode(
        parentText.substring(0, caretOffset)
      );
      const lastTextnode = document.createTextNode(text.substring(start));
      const fragment = new DocumentFragment();

      fragment.append(firstTextnode, spanEl, lastTextnode);
      tnode.replaceWith(fragment);
      range.setEnd(lastTextnode, 0);
      range.collapse();
    } else {
      // const charactersAfterAtSign = commentInput.substring(
      //   commentInput.lastIndexOf("@") + 1,
      //   commentInput.lastIndexOf("")
      // );
      const currentMentionedUser = getMentionedUserBeforeCaret(textRef.current);
      const currentMentionedUserLength = currentMentionedUser.substring(
        currentMentionedUser.lastIndexOf("@"),
        currentMentionedUser.lastIndexOf("")
      );

      const firstTextnode = document.createTextNode(
        text.substring(0, start - currentMentionedUserLength.length)
      );

      const lastTextnode = document.createTextNode(text.substring(start));
      const fragment = new DocumentFragment();
      // to split between span tags
      const empty = document.createTextNode("\uFEFF");

      fragment.append(firstTextnode, spanEl, empty, lastTextnode);
      tnode.replaceWith(fragment);
      range.setEnd(lastTextnode, 0);
      range.collapse();
    }
    setCurrentMentionedChars({
      status: false,
    });
    setCommentInput(range.commonAncestorContainer.parentElement.innerHTML);
  };

  const [currentMentionedChars, setCurrentMentionedChars] = useState({
    status: false,
    characters: "",
  });

  useEffect(() => {
    const charFrontOfCursor = getCharacterPrecedingCaret(textRef.current);

    const currentMentionedUser = getMentionedUserBeforeCaret(textRef.current);
    const charactersAfterAtSign = currentMentionedUser.substring(
      currentMentionedUser.lastIndexOf("@") + 1,
      currentMentionedUser.lastIndexOf("")
    );

    if (currentMentionedChars.status) {
      setCurrentMentionedChars({
        status: true,
      });

      let filterUsers = [];
      for (let i = 0; i < usernames.length; i++) {
        if (usernames[i].username.includes(charactersAfterAtSign)) {
          // filter usernames here
          filterUsers.push(usernames[i]);
        }
      }
      if (filterUsers.length > 0) {
        setOpenUsernameSuggestion({
          ...openUsernameSuggestion,
          list: filterUsers,
        });
        setCurrentMentionedChars({
          status: true,
        });
      } else {
        setOpenUsernameSuggestion({
          status: false,
          list: usernames,
        });
        setCurrentMentionedChars({
          status: false,
        });
      }
    } else if (charFrontOfCursor === "@") {
      setOpenUsernameSuggestion({
        ...openUsernameSuggestion,
        status: true,
      });
      setCurrentMentionedChars({
        status: true,
        characters: "",
      });
    } else if (openUsernameSuggestion.status) {
      setOpenUsernameSuggestion({
        status: false,
        list: usernames,
      });
    }
  }, [commentInput]);

  const detectShortcutKeys = useCallback(
    (event) => {
      let s, r, el;
      if (event.keyCode == 8) {
        s = window.getSelection();
        r = s.getRangeAt(0);
        el = r.startContainer.parentElement;
        if (r.startContainer.parentElement.tagName.toLowerCase() === "span") {
          if (
            r.startOffset == r.endOffset &&
            r.endOffset == el.textContent.length
          ) {
            el.remove();
            event.preventDefault();
            return;
          }
        }
      }
    },
    [commentInput]
  );

  useEffect(() => {
    document.addEventListener("keydown", detectShortcutKeys, false);
    return () => {
      document.removeEventListener("keydown", detectShortcutKeys, false);
    };
  }, [commentInput]);

  return (
    <Layout>
      <Header>
        <Navigation>
          <span>Comments</span>

          {/* <FilterComments>
            <Select
              onSelect={selectFilter}
              selected={selectedFilter}
              options={filterList}
              minWidth={140}
              maxWidth={140}
              height={78}
            />
          </FilterComments> */}
        </Navigation>
      </Header>

      <List>
        {comments &&
          comments.map((comment) => (
            <DisplayComment key={comment.id} item={comment} />
          ))}
      </List>

      {openUsernameSuggestion.status ? (
        <SuggestUsernames
          usernames={openUsernameSuggestion.list}
          onSelectUser={addMentionedUser}
        />
      ) : undefined}

      <Footer>
        <WrapCommentInput>
          <Textarea>
            <ContentEditable
              innerRef={textRef}
              html={commentInput}
              disabled={false}
              style={{height: "100%"}}
              onChange={onChangeCommentInput}
            />
          </Textarea>

          <AddComment onClick={addComment}>Post</AddComment>
        </WrapCommentInput>
      </Footer>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 4px 10px;
  span {
    color: #555353;
    font-size: 0.92rem;
    font-weight: 700;
    padding-left: 12px;
  }
`;

const Header = styled.div`
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    margin: 14px 0;
  }
  @media only screen and (min-width: 601px) {
    box-shadow: ${boxShadow};
  }
`;

const List = styled.div`
  overflow-y: scroll;
  height: 350px;
  padding-right: 16px;
  padding: 16px 18px 54px 12px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    height: ${window.innerHeight - 265}px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.95rem;
  margin-bottom: 22px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dedede;
`;

const Comment = styled.div`
  display: flex;
`;

const Subcomment = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  box-sizing: border-box;
`;

const UserAvatar = styled.div`
  width: 64px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const CommentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .guest-comment {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    &__name {
      padding-right: 12px;
      color: ${black1};
      font-size: 0.82rem;
      font-weight: 700;
    }
    &__timestamp {
      color: #302e39;
      font-size: 0.75rem;
    }
    &__comment {
      display: flex;
      justify-content: space-between;
      font-size: 0.93rem;
      line-height: 1.6em;
      max-width: 82%;
    }
    &__responses {
      display: flex;
      font-size: 0.82rem;
      color: ${greyText3};
      div {
        padding-right: 12px;
      }
    }
    &__reply {
      cursor: pointer;
    }
  }
`;

const FilterComments = styled.div`
  margin-right: 10px;
`;

const AddComment = styled.div`
  width: 120px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.92rem;
  font-weight: 700;
  padding: 0 8px;
  background: ${paleBlue};
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 98px;
  position: absolute;
  bottom: 0px;
  overflow: hidden;
  z-index: 100;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  box-sizing: border-box;
`;

const HeartShape = styled.div`
  display: flex;
  justify-content: center;
  width: 25px;
`;

const WrapSvg = styled.div`
  width: 16px;
  height: 16px;
  fill: ${({liked}) => (liked ? "#ed6262" : "transparent")};
  stroke: ${({liked}) => (liked ? "#f1efef" : "#141313")};
  cursor: pointer;
`;

const ViewHideReplies = styled.div`
  font-size: 0.85rem;
  margin: 16px 0 8px;
  cursor: pointer;
`;

const WrapCommentInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
`;

const Textarea = styled.div`
  width: 100%;
  height: 72px;
  margin-right: 12px;
  box-sizing: border-box;
  border: 1px solid #d5d5d8;
  border-radius: 6px;
  background: #f4e8ff;
  font-family: inherit;
  font-size: 1rem;
  padding: 8px;
  resize: none;
  span {
    color: #2848f7;
  }
`;

export default GuestComments;
