import styled from "styled-components";

export const WrapError = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1.1rem;
  margin-top: 7rem;
`;

export const DashboardSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  height: ${window.innerHeight - 380}px;
`;

export const Error = styled.div`
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 1.1rem;
`;
