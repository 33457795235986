import React from "react";
import styled from "styled-components";

const DisplayTheme = ({theme, onCreate}) => {
  const addNewSlide = () => {
    onCreate(theme.id);
  };
  return (
    <Theme
      onClick={addNewSlide}
      style={{
        background: theme.bg.gradient
          ? `linear-gradient(${theme.bg.bgAng}deg, ${theme.bg.bg1}, ${theme.bg.bg2})`
          : theme.bg.bg1,
      }}
    />
  );
};

const Theme = styled.div`
  div {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
`;

export default DisplayTheme;
