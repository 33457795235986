import React, {useState, useEffect, useRef, useCallback} from 'react';
import styled from 'styled-components';
import {
  Header, 
  Image,
  ItemName,
  MoreOption, 
  Layout, 
  WrapDashAreaSpinner,
  itemStyles,
  Items,
  WrapTempInfo,
  WrapImage
} from "../userTemplates/userTemplatesStyles";
import { DashboardSection, Error, WrapError } from '../dashboardPanelStyles';
import { BottomUpDrawer, Modal, Popper, Spinner } from '../../../../oat-ui';
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {getDashboardLayout} from "../userTemplates/getDashboardLayout";
import PurchasedItemActions from './modals-popper/PurchasedItemActions';
import PurchasedItemDetails from './modals-popper/PurchasedItemDetails';
import { connect } from 'react-redux';
import { selectUserInfo } from '../../../../../redux/user/authUser';

const popperSize = {width: 200, height: 90};
export const becomesDrawerPoint = 650; // after 650px modal -> drawer
const DisplayItem = ({onSelect, product, size, onPopperOpen}) => {

  const handlePopperOpen = (e) => {
    onPopperOpen(e, product);
  };

  const handleSelection = () => {
    onSelect(product);
  };

  return (
    <WrapItem style={{width: size.width, height: size.height + 44}}>
      <WrapImage onClick={handleSelection} style={{height: size.height}}>
        <Image
          style={{width: "auto", height: size.height}}
          src={`https://oat-images-icons.s3.amazonaws.com/${product.item.productImg}`}
        />
      </WrapImage>
      <WrapTempInfo style={{height: 44}}>
        <ItemName style={{flex: 4}} onClick={handleSelection}>
          {product.item.productName}
        </ItemName>
        <MoreOption onClick={handlePopperOpen}>
          <div />
          <div />
          <div />
        </MoreOption>
      </WrapTempInfo>
    </WrapItem>
  );
};

const PurchasedPanel = ({username}) => {
  const [items, setItems] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [fetching, setFetching] = useState({
    initialLoading: true, // first time being loaded
    status: false,
    error: false,
    page: 1,
    lastEvaluatedKey: null,
  });
  const [itemSize, setItemSize] = useState({
    width: 0,
    height: 0,
  });
  const [popper, setPopper] = useState({
    open: false,
    offset: {x: null, y: null},
  });
  const [openModal, setOpenModal] = useState({
    status: false,
    processing: false,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const windowSize = getWindowSize();
  const modalHeight = windowSize.height > 980 ? 920 : windowSize.height - 150;

  const fetchPurchasedItems = (url) => {
    if(!fetching.status){
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          if(data?.items) {
            setItems([...items, ...data.items]);
            setFetching({
              ...fetching,
              status: false,
            });
            if (data?.paginationToken) {
              setLastEvaluatedKey(data.paginationToken);
            } else {
              setLastEvaluatedKey(null);
            }
          }
        })
        .catch((error) => {
          setFetching({
            ...fetching,
            status: false,
            error: true,
          });
        });
    }
  }

  useEffect(() => {
    setFetching({
      ...fetching,
      status: true,
    });
    if(fetching.page === 1) {
      fetchPurchasedItems(`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/purchased-items`);
    }
  }, [fetching.page]);

  useEffect(() => {
    const {width, height} = getDashboardLayout(windowSize.width);
    setItemSize({
      width,
      height,
    });
  }, [windowSize.width]);

  useEffect(() => {
    // skip first loading and if there is no items left
    if (fetching.page !== 1 && lastEvaluatedKey !== null) {
      if (!fetching.status) {
        fetchPurchasedItems(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/purchased-items?paginationToken=${lastEvaluatedKey}`
        );
      }
    }
  }, [fetching.page, fetching.status, lastEvaluatedKey]);

  const handlePopperOpen = (e, item) => {
    if (e.clientY + popperSize.width > window.innerHeight) {
      const extraSpace = 20;
      setPopper({
        open: !popper.open,
        offset: {
          x: e.pageX - popperSize.width + 9,
          y: e.pageY - popperSize.height - extraSpace,
        },
      });
    } else {
      setPopper({
        open: !popper.open,
        offset: {x: e.pageX - popperSize.width, y: e.pageY - 20},
      });
    }
    setSelectedItem(item);
    e.preventDefault();
  };

  const handlePopperClose = () => {
    setPopper({
      open: false,
      offset: {x: 0, y: 0},
    });
  };

  const handleItemSelection = (item) => {
    setSelectedItem(item);
    setOpenModal({
      status: true,
      processing: false,
      type: "view-image-details",
      error: false,
    });
    setPopper({
      open: false,
      offset: {x: null, y: null},
    });
  };

  const closeModal = () => {
    if (openModal.status) {
      setOpenModal({
        status: false,
        processing: false,
        type: null,
        error: {
          status: false,
          message: null,
        },
      });
    }
  };

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (fetching.initialLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && lastEvaluatedKey !== null) {
          setFetching({
            ...fetching,
            page: fetching.page + 1
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [fetching]
  );

  return (
    <Layout>
      <Header>
        <h3>Purchased</h3>
      </Header>

      {fetching.error ? (
        <WrapError>
          <Error>Couldn't load data.</Error>
        </WrapError>
      ) : undefined}

      {fetching.status && fetching.page === 1 ? (
        <WrapDashAreaSpinner>
          <Spinner />
        </WrapDashAreaSpinner>
      ) : !fetching.status && items && items.length === 0 ? (
        <DashboardSection>
          Purchased items will display here.
        </DashboardSection>
      ) : undefined}

      <div style={{width: "100%", minHeight: windowSize.height - 200}}>
        <Items>
          {!fetching.status &&
            items && items.map((item, i) => {
              return (
                <DisplayItem
                  key={item.key}
                  product={item}
                  onSelect={handleItemSelection}
                  size={itemSize}
                  onPopperOpen={handlePopperOpen}
                />
              );
            })}
        </Items>

        {items && items.length > 0 ? 
          <>
            <div ref={lastItemRef} />
            <div style={{height: 220}} />
          </> 
        : undefined}

        {openModal.status && openModal.type === "view-image-details" && windowSize.width > becomesDrawerPoint ? (
          <Modal
            width={windowSize.width > 950 ? 900 : windowSize.width - 60} 
            height={modalHeight} 
            close={closeModal}>
            <PurchasedItemDetails
              selectedItem={selectedItem}
              processing={openModal.processing}
              modalHeight={modalHeight}
              onClose={closeModal}
              error={openModal.error}
            />  
          </Modal> 
        ) : undefined}

        {windowSize.width <= becomesDrawerPoint ?
          <BottomUpDrawer 
            open={openModal.status} 
            close={closeModal} 
            height={window.innerHeight - 100}
            styles={{overflowY: "hidden"}}
          > 
            <PurchasedItemDetails
              selectedItem={selectedItem}
              processing={openModal.processing}
              onClose={closeModal}
              error={openModal.error}
            /> 
          </BottomUpDrawer> 
        : undefined}

        {popper.open ? (
          <Popper
            width={popperSize.width}
            height="auto"
            offset={{x: popper.offset.x, y: popper.offset.y}}
            onClose={handlePopperClose}
          >
            <PurchasedItemActions 
              selectedItem={selectedItem} 
              onView={handleItemSelection} 
              onClose={handlePopperClose}              
            />
          </Popper>
        ) : undefined}

      </div>
    </Layout>
  );
};

const WrapItem = styled.div`
  ${itemStyles};
`;

const mapStateToProps = (state) => {
  const {authUser} = state;
  return {
    username: selectUserInfo(authUser).username,
  };
};

export default connect(mapStateToProps, null)(PurchasedPanel);