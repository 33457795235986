import React, {
    useState,
    useEffect,
    createContext,
    useLayoutEffect,
    useRef
  } from "react";
  import styled, {ThemeProvider} from "styled-components";
  import {
    fetchTemplateCanvas,
    dispatchResetState,
    initPlayGround,
  } from "../store/actions/template/template.action";
  import {connect} from "react-redux";
  import {
    selectEditPanelStatus,
    selectModalStatus,
  } from "../store/selectors/layout/layout.selector";
  import {
    selectBuildingTheme,
    selectTemplateThemes,
  } from "../store/selectors/template/theme.selector";
  import {selectPageTheme} from "../store/selectors/layout/layout.selector";
  import {lightTheme, darkTheme, mobDarkTheme} from "../themes";
  import {getWindowSize} from "../../oat-window-size/getWindowSize";
  import MobileNav from "../components/editDesign/navBar/MobileNav";
  import DesktopNav from "../components/editDesign/navBar/desktop/DesktopNav";
  import {changePageTheme} from "../store/actions/layout.action";
  import SlideListPanel from "../components/editDesign/slideListPanel/SlideListPanel";
  import {deskNavHeight} from "../layoutSizes";
  import CanvasPanel from "../components/editDesign/canvasPanel/CanvasPanel";
  import DeskEditingPanel from "../components/editDesign/editingPanel/DeskEditingPanel";
  import MobEditingPanel from "../components/editDesign/editingPanel/MobEditingPanel";
  import SlideActionsPanel from "../components/editDesign/slideActionsPanel/SlideActionsPanel";
  import {
    selectTemplate,
    selectTemplateSize,
    selectTemplateRatio,
    selectTemplateName,
    selectTempSourceID,
    selectTempLng,
    selectUsedFontList,
    selectFolder,
    selectWorkspaceID,
    selectTemplateID,
    selectOrgTempSize,
  } from "../store/selectors/template/template.selector";
  import {useLocation, useNavigate} from "react-router-dom";
  import {selectUserInfo} from "../../../redux/user/authUser";
  import {selectActiveSlide} from "../store/selectors/template/slide.selector";
  import {selectSectionList} from "../store/selectors/template/groupedSection.selector";
  import WrapThemeBuilder from "../components/themeBuilder/WrapThemeBuilder";
  import {loadUsedFonts} from "../utils/loadUsedFonts";
  import SocketProvider from "../webSocket/Socket";
  import {toggleBuildingThemeStatus} from "../store/actions/template/theme.action";
  import {Helmet, HelmetProvider} from "react-helmet-async";
  import {fetchLibraryFontsSuccess} from "../store/actions/fields/common.action";
  import {loadOatAllFonts} from "../utils/loadOatAllFonts";
  import { selectUndoRedoHistory } from "../store/selectors/fields.selector";
  import { NetworkProvider } from "../../oat-hooks/detectOnlineStatus/detectOnlineStatus";
  
  //ready, value, send
  export const WebsocketContext = createContext(false, null, () => {});
  
  const PlayGround = ({
    fetchTemplateCanvas,
    template,
    modal,
    displayEditPanel,
    user,
    changePageTheme,
    mode,
    onSave,
    apiEndpoint,
    buttonTitle,
    tempSize,
    tempRatio,
    themes,
    tempName,
    slideGroupList,
    displaySlideListOnLoad,
    activeSlide,
    sectionList,
    tempSourceID,
    tempLng,
    dispatchResetState,
    usedFontList,
    tempID,
    workspaceID,
    folder,
    buildingTheme,
    toggleBuildingThemeStatus,
    isSaving,
    fetchLibraryFontsSuccess,
    orgTempSize,
    undoState,
    initPlayGround
  }) => {
    const windowSize = getWindowSize();
    const query = new URLSearchParams(useLocation().search);
    const isMobileView = query.get("mobileView");
    const buildingThemeParam = query.get("building-theme") === "true";
    const editingThemeParam = query.get("editing-theme") === "true";
    const slideID = query.get("slide");
    let navigate = useNavigate();
    const location = useLocation();
    // save data before closing window
    const [hasSavedBeforeClosing, setHasSavedBeforeClosing] = useState(true);
    const fetchOnce = useRef(true);
  
    useEffect(() => {
      // cutout params if initial loading url is containing params
      if (!buildingTheme.status && (buildingThemeParam || editingThemeParam)) {
        navigate(`${location.pathname}`);
      };
      try {
        if (fetchOnce.current) {
            initPlayGround()
        //   fetchTemplateCanvas({
        //     url: apiEndpoint, // + user.username + 'template/' + tempID,
        //     // isMobileView
        //     // `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/hein/template/0.782`
        //     // `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template/12345678/pink`
        //     mobileViewEditing: isMobileView ? true : false,
        //     displaySlideListOnLoad,
        //     slideID,
        //     username: user.username
        //   })
        //   .then(() => {
        //     fetchOnce.current = false;
        //   })
        }
      } catch (e) {}
    }, []);
  
    useEffect(() => {
      return function cleanup() {
        dispatchResetState();
      };
    }, []);
  
    const handleUpdateTemplate = async ({isDraft}) => {
      if (activeSlide !== -1 && template?.length > 0) {
        const templateInfo = {
          ratio: tempRatio,
          size: tempSize,
          title: tempName,
          tempSourceID,
          workspaceID,
          folderID: folder.ID,
          folderType: "designs",
        };
  
        const removeDeletedFields =
          template &&
          template.map((slide) => {
            return {
              ...slide,
              fields: Object.entries(slide.fields)
                .filter(([id, item]) => item.deleted === false)
                .reduce((acc, [id, item]) => ({...acc, [id]: item}), {}),
            };
          });
  
        onSave({
          template: {
            details: removeDeletedFields,
            themes,
            sections: sectionList,
            orgTempSize,
            // tempDescription: "", // add later
          },
          templateInfo,
          admin: {
            tempLng,
            isDraft,
          },
        });
        setHasSavedBeforeClosing(true);
      }
    };
    
    const updateTheme = () => {
      changePageTheme(mode === "light" ? "dark" : "light");
    };
  
    let applyTheme = lightTheme;
    if (mode === "dark" && window.innerWidth >= 850) {
      applyTheme = darkTheme;
    } else if (mode === "light" && window.innerWidth >= 850) {
      applyTheme = lightTheme;
    } else if (mode === "light" && window.innerWidth < 850) {
      applyTheme = mobDarkTheme;
    } else if (mode === "dark" && window.innerWidth < 850) {
      applyTheme = darkTheme;
    } else {
      applyTheme = darkTheme;
    }
  
    useEffect(() => {
      document.body.style.overflow = "hidden";
      document.body.style.overscrollBehaviorX = "none";
      return () => {
        document.body.style.overflow = "";
        document.body.style.overscrollBehaviorX = "";
        fetchOnce.current = true;
      };
    }, [user.username, tempID]);
  
    useEffect(() => {
      loadUsedFonts(usedFontList);
    }, [usedFontList]);
  
    const [fontsLoaded, setFontsLoaded] = useState(false);
    useEffect(() => {
      if (user.username && workspaceID && !fontsLoaded) {
        fetch(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/fonts/${workspaceID}?paginationToken=&fetchFolders=false`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              setFontsLoaded(true);
              fetchLibraryFontsSuccess(data.response.fonts);
            }
          })
          .catch((e) => {});
      }
    }, [user.username, workspaceID]);
  
    useEffect(() => {
        loadOatAllFonts();
    }, []);
    
    // DO NOT DELETE
    // confirm leaving before closing window tab
    useEffect(() => {
      function listener(e) {
        if (!hasSavedBeforeClosing && user.username !== null) {
          e.preventDefault();
          const message = "Changes you made may not be saved.";
          e.returnValue = message;
          return message;
        }
      }
      window.addEventListener("beforeunload", listener);
      return () => {
        window.removeEventListener("beforeunload", listener);
      };
    }, [user.username, hasSavedBeforeClosing]);
    useEffect(() => {
      if (undoState.history.length > 3 && user.username !== null) {
         setHasSavedBeforeClosing(false);
      }
    }, [undoState.history, user.username]); // template
  
    // if undo length is > 12
    // & has been for 3 minutes
    // Save user data 
    useEffect(() => {
      let url = window.location.href;;
      if (user.username !== null && undoState.history.length > 10 && !url.includes("admin/designs/")) 
      {
        const intervalId = setInterval(() => {
          handleUpdateTemplate({isDraft: null})
        }, 3 * 60 * 1000); 
        return () => clearInterval(intervalId); 
      }
    }, [user.username, undoState.history]);
  
    return (
      <HelmetProvider>
        <Helmet>
          <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
          <link
            rel="stylesheet"
            type="text/css"
            href="css/landscape.css"
            media="screen and (orientation: landscape)"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="css/portrait.css"
            media="screen and (orientation: portrait)"
          />
        </Helmet>
        <ThemeProvider theme={applyTheme}>
          <SocketProvider
            username={user.username}
            name={user.name}
            avatar={user.avatar}
            tempID={tempID}
            enableSocket={true}
            templateAPI={apiEndpoint}
          >
            <NetworkProvider>
              <Container>
                <div style={{position: "absolute", top: 0, left: 0}}>Hello</div>
                {!buildingTheme.status && (
                  <Layout style={{opacity: modal.status ? 0.3 : 1}}>
                    {windowSize.width < 850 ? (
                      <MobileNav onSave={handleUpdateTemplate} />
                    ) : (
                      <DesktopNav
                        processDesign={handleUpdateTemplate} //reformatStyles checkUserType
                        mode={mode}
                        onChangeTheme={updateTheme}
                        buttonTitle={buttonTitle}
                        isSaving={isSaving}
                      />
                    )}
  
                    <Wrapper>
                      {window.innerWidth >= 850 ? (
                        <SlideListPanel slides={template} />
                      ) : undefined}
  
                      <CanvasPanel username={user.username} tempID={tempID} />
  
                      {windowSize.width >= 850 && displayEditPanel ? (
                        <DeskEditingPanel theme={mode} />
                      ) : undefined}
  
                      {windowSize.width >= 850 && !displayEditPanel ? (
                        <SlideActionsPanel
                          theme={mode}
                          displayPanel={displayEditPanel}
                        />
                      ) : undefined}
  
                      {windowSize.width < 850 ? <MobEditingPanel /> : undefined}
                    </Wrapper>
                  </Layout>
                )}
  
                <div id="edit-mobile-text-field-content" />
  
                {buildingTheme.status && (
                  <WrapThemeBuilder buildingTheme={buildingTheme} />
                )}
  
                {/* {modal.status && windowSize.width >= 850 ? (
                <ModalWrapper adminCreateTemp={reformatStyles} />
              ) : undefined} */}
                {/* <DisplayError layoutTheme={mode} /> */}
              </Container>
            </NetworkProvider>
          </SocketProvider>
        </ThemeProvider>
      </HelmetProvider>
    );
  };
  
  const Layout = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.deskPrimaryBg2};
    @media only screen and (min-width: 850px) {
      // transition: all 0.5s ease;
    }
    @media only screen and (max-width: 849px) {
      overflow: hidden;
      background: #1c1c2f;
    }
  `;
  
  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 850px) {
      flex-direction: row;
      height: ${({printProof}) =>
        printProof ? window.innerHeight : window.innerHeight - deskNavHeight}px;
    }
  `;
  
  export const Container = styled.div`
    position: relative;
    overflow: hidden;
    // overflow-y: hidden;
  `;
  
  const mapStateToProps = (state) => {
    const {authUser, designTemplate} = state;
    return {
      buildingTheme: selectBuildingTheme(designTemplate),
      modal: selectModalStatus(designTemplate),
      displayEditPanel: selectEditPanelStatus(designTemplate),
      user: selectUserInfo(authUser),
      mode: selectPageTheme(designTemplate),
      template: selectTemplate(designTemplate),
      tempSize: selectTemplateSize(designTemplate),
      orgTempSize: selectOrgTempSize(designTemplate),
      tempRatio: selectTemplateRatio(designTemplate),
      themes: selectTemplateThemes(designTemplate),
      tempName: selectTemplateName(designTemplate),
      activeSlide: selectActiveSlide(designTemplate),
      sectionList: selectSectionList(designTemplate),
      tempID: selectTemplateID(designTemplate),
      tempSourceID: selectTempSourceID(designTemplate),
      tempLng: selectTempLng(designTemplate),
      usedFontList: selectUsedFontList(designTemplate),
      folder: selectFolder(designTemplate),
      workspaceID: selectWorkspaceID(designTemplate),
      undoState: selectUndoRedoHistory(designTemplate),
    };
  };
  
  PlayGround.defaultProps = {
    displaySlideListOnLoad: false,
  };
  
  export default connect(mapStateToProps, {
    fetchTemplateCanvas,
    changePageTheme,
    dispatchResetState,
    toggleBuildingThemeStatus,
    fetchLibraryFontsSuccess,
    initPlayGround
  })(PlayGround);
  