import React, {useState} from "react";
import {connect} from "react-redux";
import {Items, Item} from "./MobilePanelStyles";
import {updateEditingPanel} from "../../../../../../store/actions/layout.action";
import MobileDrawer from "../../../../drawer/MobileDrawer";
import {
  selectActiveFieldProps,
  selectCachedFieldStyles,
} from "../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {Title} from "../../shared/reuseStyles/Title";
import {DrawerLayout} from "./edit/layoutStyles";
import EditIconSet from "../../desktop/icons/EditIconSet";
import EditOpacity from "../../desktop/common/EditOpacity";
import EditRotate from "./edit/common/EditRotate";
import ZIndex from "../../shared/ZIndex";
import LockField from "./edit/common/LockField";
import ApplyAnimations from "../../desktop/animations/ApplyAnimations";

const IconPanel = ({activeField}) => {
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: null,
    height: 0,
  });
  const {styles} = activeField || {};

  const handleDrawerClose = () => {
    setOpenDrawer({
      status: false,
      type: null,
    });
  };

  let displayView = null;
  switch (openDrawer.type) {
    case "edit-colors":
      displayView = <EditIconSet />;
      break;
    case "opacity":
      displayView = <EditOpacity value={styles?.opacity} />;
      break;
    case "rotate":
      displayView = <EditRotate value={styles?.rotate} />;
      break;
    case "layer":
      displayView = <ZIndex />;
      break;
    case "animation":
      displayView = <ApplyAnimations />;
      break;
    default:
  }

  const onSelectColorChange = () => {
    setOpenDrawer({status: true, type: "edit-colors", height: 200});
  };

  const onSelectOpacity = () => {
    setOpenDrawer({status: true, type: "opacity", height: 80});
  };

  const onSelectRotate = () => {
    setOpenDrawer({status: true, type: "rotate", height: 80});
  };

  const onSelectLayer = () => {
    setOpenDrawer({status: true, type: "layer", height: 80});
  };

  const onSelectAnimations = () => {
    setOpenDrawer({
      status: true,
      type: "animation",
      height: (window.innerHeight * 35) / 100,
    });
  };

  return (
    <>
      <Items>
        <Item onClick={onSelectColorChange}>
          <Title>Colors</Title>
        </Item>
        <Item onClick={onSelectOpacity}>
          <Title>Opacity</Title>
        </Item>

        <LockField />

        <Item onClick={onSelectRotate}>
          <Title>Rotate</Title>
        </Item>

        <Item onClick={onSelectLayer}>
          <Title>Layer</Title>
        </Item>

        <Item onClick={onSelectAnimations}>
          <Title>Animate</Title>
        </Item>
      </Items>

      <MobileDrawer
        open={openDrawer.status}
        height={openDrawer.height}
        onClose={handleDrawerClose}
      >
        <DrawerLayout>{displayView}</DrawerLayout>
      </MobileDrawer>
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveFieldProps(designTemplate),
    stableStyles: selectCachedFieldStyles(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateEditingPanel,
  storeFieldUndoRedo,
})(IconPanel);
