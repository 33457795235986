import React from 'react';
import { WrapIconItem, WrapItem } from '../../reusableStyles/reusableStyles';

const DisplayPackItem = ({item, onCreate}) => {
  const handleClick = () => {
    onCreate(item);
  };

  return (
    <WrapIconItem>
      <WrapItem onClick={handleClick}>
        <img 
          src={typeof item.image === 'object' 
              ? `https://oat-images-icons.s3.amazonaws.com/${item.image?.small}` 
              : `https://oat-images-icons.s3.amazonaws.com/${item.image}`} 
          alt="" 
      />
      </WrapItem>
    </WrapIconItem>
  );
};

export default DisplayPackItem;