import {
  UPDATE_USER_AUTH_STATUS,
  DISPATCH_SIGN_OUT,
  UPDATE_LOADING_STATUS,
  SUBTRACTED_CREDIT_AMOUNT,
} from "./actions/authUser.action";

const initialState = {
  loading: true,
  user: {
    username: null,
    email: null, 
    name: null, 
    token: {},
    avatar: "",
    region: null,
    lng: null,
    account: {
      plan: "",
      active: false, // if user subscription is active
      profile: {},
      // business: {
      //   profile: false,
      //   slug: null,
      //   permission: null,
      //   ownerUsername: null,
      // },
    },
    loggedIn: {
      status: false, // false true
      token: null,
      type: null, // null "user", / admin or staff
      provider: null,
    },
    staff: {
      status: false,
      type: "admin", // "admin"
      role: null,
    },
    defaultWorkspaceID: null,
  },
};

const handlers = {};
handlers[UPDATE_USER_AUTH_STATUS] = updateUserAuthStatus();
handlers[UPDATE_LOADING_STATUS] = updateLoadingStatus();
handlers[DISPATCH_SIGN_OUT] = signOut();

handlers[SUBTRACTED_CREDIT_AMOUNT] = subtractCredit();

function updateUserAuthStatus() {
  return function (state, action) {
    const {attributes, signInUserSession} = action.cognito;
    const username = attributes["custom:acc-username"];
    const name = attributes["name"];
    // const accType = attributes["custom:acc-type"]; // user or admin
    const email = attributes["email"];
    const avatar = attributes["picture"];
    const cognitoUsername = action.cognito.username;

    return {
      ...state,
      loading: false,
      user: {
        username, 
        cognitoUsername,
        name, 
        email,
        avatar, // action.dynamo.userItems?.tinyAvatar,
        region: action.dynamo.userItems?.region,
        lng: action.dynamo.userItems?.lng,
        account: {
          plan: "free",
          active: true, // if user subscription is active
          profile: action.dynamo.userItems?.account?.profile,
          credit: action.dynamo.userItems?.credit,
          // business: {
          //   // mainUser: account.business.mainUser,
          //   // profile: account.business.profile, // true
          //   // slug: account.business.slug, // "profile-gogo-desu",
          //   // permission: account.business.permission,
          //   // ownerUsername: account.business.profileOwnerUsername,
          // },
        },
        loggedIn: {
          status: true,
          token: signInUserSession,
          type: action.dynamo.userItems?.accType, // "admin", // accType // user or staff
          provider: "", // gmail
        },
        staff: {
          status: action.dynamo.userItems?.accType === "admin" ? true : false,
          type: "super-admin", // super-admin, manager, designer, super-admin
          position: "graphic-designer",
          permission: {},
        },
        defaultWorkspaceID: action.dynamo.workspaceID, //account.defaultWorkspace, // default workspace to redirect after delete or leave ws
      },
    };
  };
}

export const selectUserInfo = (state) => state.user;
export const selectUserWorkspaceForRedirect = (state) => {
  return state.user.defaultWorkspaceID;
};

function updateLoadingStatus() {
  return function (state) {
    return {
      ...state,
      loading: false,
    };
  };
}

function signOut() {
  return function (state) {
    return {
      ...initialState,
      loading: false,
    };
  };
}

function subtractCredit() {
  return function (state, action) {
    return {
      ...state,
      user: {
        ...state.user,
        account: {
          ...state.user.account,
          credit: state.user.account.credit - action.amount > 0 ?
            state.user.account.credit - action.amount : 0
        }
      },
    };
  };
}

export function reducerFactory(initialState, handlers) {
  return function (state = initialState, action) {
    const handler = handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };
}

export default reducerFactory(initialState, handlers);
