import {
  RENAME_TEMPLATE,
  USER_SEND_SHARE_TEMPLATE,
  DISPLAY_MODAL_ERROR,
  ON_REMOVE_TEMPLATE_SUCCESS,
} from "./actionTypes";

export function renameTemplate({updatedTitle, tempID}) {
  return {
    type: RENAME_TEMPLATE,
    updatedTitle,
    tempID,
  };
}

// to filter out from the existing templates state
export function onRemoveTemplateSuccess(tempID) {
  return {
    type: ON_REMOVE_TEMPLATE_SUCCESS,
    tempID,
  };
}

export function userSendShareTemplate({id, username}) {
  return {
    type: USER_SEND_SHARE_TEMPLATE,
    id,
    username,
  };
}

export function modalError(error) {
  return {
    type: DISPLAY_MODAL_ERROR,
    error,
  };
}

// CHANGE LATER
// export const changeTemplateName = (updatedName, tempID, workspaceID) => {
//   return (dispatch) => {
//     dispatch(changeFolderInit());
//     try {
//       setTimeout(() => {
//         fetch(`http://localhost:4000/${folderID}`)
//           .then((res) => res.json())
//           .then((data) => dispatch(changeFolderSuccess(data, folderID)));
//       }, 10);
//     } catch (e) {
//       dispatch(changeFolderErr(e));
//     }
//   };
// };

// export const userSendShareTemplate = (id, username) => {
//   return (dispatch) => {
//     dispatch(changeFolderInit());
//     try {
//       setTimeout(() => {
//         fetch(`http://localhost:4000/${folderID}`)
//           .then((res) => res.json())
//           .then((data) => dispatch(changeFolderSuccess(data, folderID)));
//       }, 10);
//     } catch (e) {
//       dispatch(changeFolderErr(e));
//     }
//   };
// };

// export const userDeleteTemplate = (id) => {
//   return (dispatch) => {
//     dispatch(changeFolderInit());
//     try {
//       setTimeout(() => {
//         fetch(`http://localhost:4000/${folderID}`)
//           .then((res) => res.json())
//           .then((data) => dispatch(changeFolderSuccess(data, folderID)));
//       }, 10);
//     } catch (e) {
//       dispatch(changeFolderErr(e));
//     }
//   };
// };
