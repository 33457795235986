import React from "react";
import {Minus, RemoveRow} from "./chartAddRemove";

const RemoveChartData = ({index, onRemove, disabled}) => {
  const handleRemoveRow = () => {
    onRemove(index);
  };
  return (
    <RemoveRow onClick={handleRemoveRow} disabled={disabled}>
      <Minus />
    </RemoveRow>
  );
};

export default RemoveChartData;
