import React from "react";
import {connect} from "react-redux";
import {selectTempScale} from "../../../../../../../store/selectors/template/template.selector";

const ScaleGrid = ({color, width, item, gap, tempScale, gridStyle}) => {
  return [...Array(item.lines)].map((x, i) => {
    if (i !== 0)
      return (
        <g key={i}>
          <line
            x1={0}
            x2={width}
            y1={gap * i}
            y2={gap * i}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray={gridStyle === "dashed" ? 5 / tempScale : 0}
          />
        </g>
      );
  });
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(ScaleGrid);
