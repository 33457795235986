import React, {useState, useEffect} from "react";
import {
  getTemplateErr,
  selectTemplateStatus,
  selectTempPasscode,
  selectUsedFontList,
} from "../store/selectors/template/template.selector";
import {fetchTemplate} from "../store/actions/template/template.action";
import {connect} from "react-redux";
import styled from "styled-components";
import Present from "../components/viewTemplate/Present";
import {useParams} from "react-router";
import {Modal, Spinner} from "../../oat-ui";
import {selectSlideTransition} from "../store/selectors/template/slide.selector";
import {loadUsedFonts} from "../utils/loadUsedFonts";
import {selectUserInfo} from "../../../redux/user/authUser";
import EnterPasscode from "../components/viewTemplate/passcode/EnterPasscode";
import {getWindowSize} from "../../oat-window-size/getWindowSize";
import {useLocation} from "react-router-dom";

const PresentTemplate = ({
  fetchTemplate,
  loading,
  slideTransition,
  error,
  usedFontList,
  authUser,
  passcode,
}) => {
  let params = useParams();
  const tempID = params?.tempID;
  const windowSize = getWindowSize();
  const query = new URLSearchParams(useLocation().search);
  const isEmbedded = query.get("embed") === "true";
  const slideID = query.get("slide");

  const fetchOnce = React.useRef(true);
  useEffect(() => {
    if (authUser?.username) {
      if (fetchOnce.current) {
        fetchTemplate({
          api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${authUser.username}/template/${tempID}/view`,
          embedded: isEmbedded,
          slideID
        });
        fetchOnce.current = false;
      }
    } 
    else {
      // if username is not provided
      if (fetchOnce.current) {
        fetchTemplate({
          api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/oat/template/${tempID}/view`,
          embedded: isEmbedded,
          slideID
        });
        fetchOnce.current = false;
      }
    }
  }, [authUser?.username]); // don't add slideID (else navigating to ?slide=123 will rerender),  adding authUser causes double re-render

  const [usedFontsLoaded, setUsedFontsLoaded] = useState(false);
  useEffect(() => {
    if (!usedFontsLoaded && usedFontList && usedFontList.length > 0) {
      loadUsedFonts(usedFontList);
      setUsedFontsLoaded(true);
    }
  }, [usedFontList]);

  const handleModalClose = () => {};

  const displayView = () => {
    if (!passcode.enabled || (passcode.enabled && passcode.auth)) {
      return (
        <>
          {!loading ? (
            <Present transition={slideTransition} panelWidth={windowSize.width} />
          ) : (
            // do not remove this div
            // will cause overflow error on scroll content
            <div style={{width: "100%", height: windowSize.height}}>
              <WrapSpinner>
                <Spinner />
              </WrapSpinner>
            </div>
          )}

          {!loading && error && error.status ? (
            <Modal
              width="18rem"
              height="4.6rem"
              top="20%"
              close={handleModalClose}
            >
              <div
                style={{display: "flex", alignItems: "center", height: "100%"}}
              >
                <Error>{error.message}</Error>
              </div>
            </Modal>
          ) : undefined}
        </>
      );
    } else if (passcode.enabled && !passcode.auth) {
      return <EnterPasscode tempID={tempID} isCustomLink={false} />;
    }
  };

  return <Layout $loading={loading} style={{width: window.innerWidth}}>{displayView()}</Layout>;
};

const Layout = styled.div`
  display: flex;
  background: ${({$loading}) => $loading ? '#fff' : '#121217'};
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  @media only screen and (min-width: 850px) {
    width: ${window.innerWidth}px; //99.9%;
  }
`;

const WrapSpinner = styled.div`
  width: 100%;
  height: ${window.innerHeight - 66}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Error = styled.div`
  padding: 12px;
  box-sizing: border-box;
  font-size: 1.05rem;
  color: #2f2a41;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    loading: selectTemplateStatus(designTemplate),
    slideTransition: selectSlideTransition(designTemplate),
    error: getTemplateErr(designTemplate),
    usedFontList: selectUsedFontList(designTemplate),
    authUser: selectUserInfo(state.authUser),
    passcode: selectTempPasscode(designTemplate),
  };
};

export default connect(mapStateToProps, {fetchTemplate})(PresentTemplate);
