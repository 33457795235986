import React, {useState} from 'react';
import { Layout, Header, Item, Field, WrapSelectInput, Footer, WrapButton, Error } from '../styles/formStyles';
import { Radio, RadioButton } from '../../../oat-ui/styles/RadioStyles';
import styled from "styled-components";
import { Button, ButtonSpinner, Spinner } from '../../../oat-ui';
import Select from '../../../oat-ui/core/Select';
import { connect } from 'react-redux';
import { selectUserInfo } from '../../../../redux/user/authUser';
import {useNavigate} from "react-router";
import { red1 } from '../../../oat-color-variables';
import { subtractCredit } from '../../../../redux/user/actions/authUser.action';

const ONE_TIME_PAYMENT = 'one-time-payment';
const PREPAID_CREDITS = 'prepaid-credits';
const fillCreditAmounts = [
    {display: 5, value: 5},
    {display: 10, value: 10},
    {display: 15, value: 15},
    {display: 20, value: 20},
    {display: 30, value: 30},
    {display: 40, value: 40},
    {display: 50, value: 50},
    {display: 75, value: 75},
    {display: 100, value: 100},
    {display: 150, value: 150},
    {display: 200, value: 200},
    {display: 300, value: 300}
]

const PurchaseOptions = ({user, selectedItem, onBuyCredit, subtractCredit, close}) => {
    const [form, setForm] = useState({
        type: 'prepaid-credits',
        processing: false
    });
    const [error, setError] = useState({
        status: false,
        message: null
    });
    const [buyCreditAmount, setBuyCreditAmount] = useState(5);
    let navigate = useNavigate();

    const onSelectImageType = (value) => {
        setForm({
            type: value,
            processing: false
        })
    };

    const redirectToRegister = () => {
        navigate(`/register`);
    };

    const onSelectBuy = async () => {
        if (error.status) {
            setError({status: false, message: null});
        }
        if(!form.processing && user.loggedIn?.status) {
            if (form.type === ONE_TIME_PAYMENT) {    
                // setForm({...form, processing: true});         
                // await fetch(`https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/checkout/one-time-purchase`,
                //     {
                //         method: "POST",
                //         headers: {
                //         Accept: "application/json",
                //         "Content-Type": "application/json",
                //     },
                //     body: JSON.stringify({
                //         username: user.username,
                //         email: user.email,
                //         unit_amount: (selectedItem.price.value * 100),
                //         productID: selectedItem.key,
                //         productName: selectedItem.packName,
                //         productType: selectedItem.type,
                //         productImg: selectedItem.image
                //     }),
                // })
                // .then((res) => {
                //     if (res.status == 303) {
                //         return res.json();
                //     }
                //     return res.json().then((json) => Promise.reject(json));
                // })
                // .then(({url}) => (window.location = url))
                // .catch((e) => {
                //     setError({
                //         status: true, message: 'Something went wrong.'
                //     })
                // });
            } else {
                setForm({...form, processing: true});
                await fetch(`https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/checkout/purchase-with-credit`,
                    {
                        method: "POST",
                        headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: user.username,
                        email: user.email,
                        unit_amount: Number(selectedItem.price.value),
                        product: {
                            productID: selectedItem.key,
                            productName: selectedItem.packName,
                            productType: selectedItem.type,
                            productImg: selectedItem.image
                        }
                    }),
                })
                .then((res) => res.json())
                .then(async(data) => {
                    if (data.status === "success") {
                        // close();
                        await subtractCredit(Number(selectedItem.price.value))
                        await navigate(`/dashboard/purchased`);
                        // await window.location.reload();
                    } else {
                        setError({
                            status: true, message: 'Insufficient Credit.'
                        });
                        setForm({...form, processing: false});
                    }   
                })
                .catch((e) => {
                    setError({
                        status: true, message: 'Something went wrong.'
                    });
                    setForm({...form, processing: false});
                });
            }
        }
    }


    const changeFillCreditView = () => {
        if (form.type === 'prepaid-credits') {
            setForm({
                ...form,
                type: ONE_TIME_PAYMENT,
            })
        } else {
            setForm({
                ...form,
                type: 'prepaid-credits',
            })
        }
    }

    const handleFillCredit = async () => {
        setForm({...form, processing: true});         
        if (user?.loggedIn?.status) {
            await fetch(`https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/checkout/one-time-purchase`,
                {
                    method: "POST",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: user.username,
                    email: user.email,
                    fill_credit_amount: buyCreditAmount
                }),
            })
            .then((res) => {
                if (res.status == 303) {
                    return res.json();
                }
                return res.json().then((json) => Promise.reject(json));
            })
            .then(({url}) => (window.location = url))
            .catch((e) => {
                setError({
                    status: true, message: 'Something went wrong.'
                })
            });
        }
    }

    const onBuyCreditAmount = (item) => {
        setBuyCreditAmount(item.value)
    }

    return (
        <>
            <Layout>
                {form.type === "prepaid-credits" ?
                <>
                    <Header>Prepaid Credit</Header>

                    {/* <Item>
                        <div style={{flex: 0.8}}>
                            <p>Available Credit</p>
                        </div>
                        <WrapRadio>
                            <Radio onClick={() => onSelectImageType(PREPAID_CREDITS)}>
                                <RadioButton active={form.type === PREPAID_CREDITS}>
                                    <div />
                                </RadioButton>
                                <span>Prepaid credits</span>
                            </Radio>

                            <Radio onClick={() => onSelectImageType(ONE_TIME_PAYMENT)}>
                                <RadioButton active={form.type === ONE_TIME_PAYMENT}>
                                    <div />
                                </RadioButton>
                                <span>One-time payment</span>
                            </Radio>
                        </WrapRadio>
                    </Item> */}


                    <Item>
                        <div style={{flex: 1}}>
                            <p>Available Credit</p>
                        </div>
                        <div style={{flex: 1}}>
                            $ {user?.account?.credit ? user?.account?.credit : 0}
                        </div>
                    </Item>

                    <div style={{ width: '100%', height: 1, background: '#d0d0d0', marginTop: 30}} />

                    <Item style={{
                        display: 'flex', 
                        marginTop: '28px',
                        color: red1,
                    }}>
                        <div style={{flex: 1}}>Total amount </div>
                        <div style={{flex: 1, fontSize: 18}}><b style={{fontSize: 18}}>${selectedItem.price.value}</b></div>
                    </Item>

                    {error.status ? <Error style={{ marginBottom: 54}}>{error.message}</Error> : undefined}  
                </> : 
                <>
                    <Header>Purchase Credit</Header>

                    <Item>
                        <div style={{flex: 1}}>
                            <p>Select Amount <span style={{fontSize: 12, color: '#787878'}}>(USD)</span></p>
                        </div>
                        <div style={{flex: 1}}>
                            <Select 
                                selected={buyCreditAmount} 
                                options={fillCreditAmounts} 
                                onSelect={onBuyCreditAmount}
                                height={130} 
                                overflowY={'scroll'} 
                            />
                        </div>
                    </Item>
                </>}
            </Layout>

           

            <Footer>
                <div onClick={changeFillCreditView} 
                    style={{
                        display: 'flex', 
                        alignItems: 'center', 
                        fontSize: 13, 
                        cursor: 'pointer'
                        }}
                    >
                    {form.type === "prepaid-credits" ? "Buy Credit" : "Back"}
                </div>
                <WrapButton>
                    {user.loggedIn?.status && selectedItem.premium ? 
                        form.type === "prepaid-credits" ?
                        <Button onClick={onSelectBuy} disabled={form.processing}>
                            {!form.processing ? "Purchase" : <ButtonSpinner />}
                        </Button> : 
                        <Button onClick={handleFillCredit} disabled={form.processing}>
                            {!form.processing ? "Buy Credit" : <ButtonSpinner />}
                        </Button>
                    : !user.loggedIn?.status ?
                        (<Button onClick={redirectToRegister} disabled={form.processing}>
                            Register
                        </Button>) 
                        : undefined
                    }
                </WrapButton>
            </Footer>
        </>
    )
}

const WrapRadio = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const mapStateToProps = (state) => {
    return {
      user: selectUserInfo(state.authUser),
    };
};

export default connect(mapStateToProps, {subtractCredit})(PurchaseOptions);