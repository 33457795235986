import React from "react";
import styled from "styled-components";
import {red1} from "../../../../oat-color-variables";
import {Modal, Spinner} from "../../../../oat-ui";

const LoggingOut = ({error, close}) => {
  return (
    <Modal width={200} height={92} close={close}>
      <WrapSpinner>
        {!error.status ? <Spinner /> : <Error>{error.message}</Error>}
      </WrapSpinner>
    </Modal>
  );
};

const Error = styled.div`
  font-size: 1rem;
  color: ${red1};
  padding: 16px 6px;
  font-weight: 700;
  text-align: center;
`;

const WrapSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoggingOut;
