import {useState, useEffect, useCallback} from "react";
import {useDispatch} from "react-redux";
import {updateFieldStyles} from "../../../../../../../store/actions/fields/common.action";
import {rotatedDeg} from "../common/CategoryLabels";

export function useRotateLabels({
  active,
  dataSet,
  fontSize,
  fontFamily,
  barMargin,
  barSeriesWidth,
  tempLayout,
  rotateText,
  groupedResize,
}) {
  const [longestLabel, setLongestLabel] = useState({width: 0, index: null});
  const [labelsFontWidth, setLabelsFontWidth] = useState([]);
  const dispatch = useDispatch();

  const getLongestLabel = useCallback(() => {
    if (!groupedResize) {
      let arr = [];
      let longestItem = {};
      let currentlyLongest = 0;
      for (let i = 0; i < dataSet.length; i++) {
        const font = fontSize + "px" + " " + fontFamily;
        const element = document.createElement("canvas");
        element.width = tempLayout.size.w;
        element.height = tempLayout.size.h;
        element.scale = tempLayout.scale;
        let context = element.getContext("2d");
        context.font = font;
        const metrics = context.measureText(dataSet[i]);
        let getLabelWidth = metrics.width;
        // for rotation's height
        let getLabelHeight =
          metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        // height of the whole rotated area if being rotated
        const rotatedHeight =
          getLabelHeight * Math.abs(Math.cos(rotatedDeg)) +
          getLabelWidth * Math.abs(Math.sin(rotatedDeg));

        if (getLabelWidth > currentlyLongest) {
          currentlyLongest = getLabelWidth;
          longestItem = {
            width: getLabelWidth,
            height: getLabelHeight,
            rotatedHeight,
            index: i,
          };
        }

        arr.push({
          x:
            (barSeriesWidth + barMargin) * i +
            barMargin / 2 +
            barSeriesWidth / 2 -
            getLabelWidth / 2,
          width: getLabelWidth,
        });
      }
      setLongestLabel(longestItem);
      setLabelsFontWidth(arr);
    }
  }, [dataSet, fontSize, fontFamily, barMargin]);

  // get longest category width to check for rotation
  useEffect(() => {
    getLongestLabel();
  }, [barMargin, fontSize, fontFamily]);

  const handleUpdates = (type, value) => {
    dispatch(
      updateFieldStyles({
        active,
        type,
        value,
      })
    );
  };

  useEffect(() => {
    if (labelsFontWidth.length > 0 && !groupedResize) {
      if (longestLabel.index !== 0) {
        if (
          labelsFontWidth[longestLabel.index - 1]?.x +
            labelsFontWidth[longestLabel.index - 1]?.width >
          labelsFontWidth[longestLabel.index]?.x
        ) {
          handleUpdates("categoryHeight", longestLabel.rotatedHeight);
          handleUpdates("rotateCategory", true);
        } else if (
          labelsFontWidth[longestLabel.index]?.x +
            labelsFontWidth[longestLabel.index]?.width >
          labelsFontWidth[longestLabel?.index + 1]?.x
        ) {
          handleUpdates("categoryHeight", longestLabel?.rotatedHeight);
          handleUpdates("rotateCategory", true);
        } else {
          if (rotateText) {
            handleUpdates("categoryHeight", longestLabel?.height);
            handleUpdates("rotateCategory", false);
          }
        }
      }
      // first item on array
      else if (longestLabel.index === 0 && dataSet.length > 1) {
        if (
          labelsFontWidth[longestLabel.index].x +
            labelsFontWidth[longestLabel.index]?.width >
          labelsFontWidth[longestLabel.index + 1].x
        ) {
          handleUpdates("categoryHeight", longestLabel.rotatedHeight);
          handleUpdates("rotateCategory", true);
        } else {
          if (rotateText) {
            handleUpdates(longestLabel.height, "categoryHeight");
            handleUpdates("rotateCategory", false);
          }
        }
      }
    }
  }, [longestLabel, barMargin]);

  return false;
}
