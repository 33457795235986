export function setProfileTags() {
  return function (state, action) {
    return {
      ...state,
      profile: {
        ...state.profile,
        tags: action.tags,
      },
    };
  };
}

export function addProfileTag() {
  return function (state, action) {
    return {
      ...state,
      profile: {
        ...state.profile,
        tags: [...state.profile.tags, action.payload],
      },
    };
  };
}

export function editProfileTag() {
  return function (state, action) {
    const {payload} = action;
    return {
      ...state,
      profile: {
        ...state.profile,
        tags: state.profile.tags.map((tag) =>
          tag.id === payload.id
            ? {
                ...tag,
                display: payload.display,
              }
            : tag
        ),
      },
    };
  };
}

export function removeProfileTag() {
  return function (state, action) {
    return {
      ...state,
      profile: {
        ...state.profile,
        tags: state.profile.tags.filter((tag) => tag.id !== action.id),
      },
    };
  };
}
