import {OpenSans} from "../../../ui/fonts/googleFontLinks";
import {createShortKey} from "../functions/fields/utils";
export const OatDefaultTheme = "oat-default-theme";

const textStyles = {
  bg1: "#6483ecff",
  bg2: "",
  bgdirection: "to bottom right",
  shadowColor: "#97989bff",
  shadowType: "",
};

export const themeBuilder = {
  id: createShortKey(),
  name: "Untitled Theme",
  bg: {
    bg1: "#f2ed51ff",
    bg2: "#fff",
    type: "linear",
    bgAng: 0,
    gradient: false,
  },
  heading: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  subheading: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  normalText: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  smallText: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  bulletContent: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.8,
    letterSpacing: 0,
    bulletColor: "#232323"
  },
  listContent: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.8,
    letterSpacing: 0,
  },
  listContentWithBackground: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.8,
    letterSpacing: 0,
    listBackground: "#fff",
    listColor: "#7385f6",
    listBgShadow: "#92a1c9",
    ...textStyles,
  },
  button: {
    color1: "#232323",
    color2: "",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    lineHeight: 1.4,
    letterSpacing: 0,
    // background: "#6483ecff",
    // shadowColor: "#97989bff",
    ...textStyles,
  },
  image: {
    shadowColor: "#666060ff",
    overlayColor: "#705a5f",
  },
  shape: {
    color1: "#dedede",
    color2: "#4287f5",
    gradientAngle: 0,
    stroke: "#747979ff",
    strokeWidth: 0,
    lineColor: "#232323",
    shadowColor: "#666060ff",
  },
  chart: {
    valueColor: "#232323",
    gridColor: "#232323",
    colorSet: ["#ff2828ff", "#45f6f6ff", "#5252f0ff", "#f6f645ff", "#77f68dff"],
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    showBackground: false,
    background: "#847f7f61",
    legendColor: "#232323",
    measurementColor: "#232323",
    categoryColor: "#232323",
    axisColor: "#232323",
  },
  table: {
    headerBg: "#dedede",
    headerColor: "#505553ff",
    fontFamily: "Open Sans",
    src: OpenSans,
    format: null,
    custom: false,
    color: "#484853ff",
    borderColor: "#bdc1aeff",
    background: "#fff",
    lineHeight: 2.2,
    letterSpacing: 0,
    stripeBackground: "#dde3a9", // stripe bg color
    stripeColor: "#484853ff",
  },
  video: {
    borderColor: "#bdc1aeff",
  },
};

export const themes = [
  {
    id: OatDefaultTheme,
    name: "Default Theme",
    bg: {
      bg1: "#f2ed51ff",
      bg2: "#fff",
      type: "linear",
      bgAng: 0,
      gradient: false,
    },
    heading: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    subheading: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    normalText: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    smallText: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    bulletContent: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.8,
      letterSpacing: 0,
      bulletColor: "#232323"
    },
    listContent: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.8,
      letterSpacing: 0,
      ...textStyles,
    },
    listContentWithBackground: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.8,
      letterSpacing: 0,
      ...textStyles,
    },
    button: {
      color1: "#232323",
      color2: "",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      lineHeight: 1.3,
      letterSpacing: 0,
      background: "#6483ecff",
      shadowColor: "#97989bff",
    },
    image: {
      shadowColor: "#666060ff",
      overlayColor: "#705a5f",
    },
    shape: {
      color1: "#dedede",
      color2: "#4287f5",
      gradientAngle: 0,
      stroke: "#747979ff",
      strokeWidth: 0,
      lineColor: "#232323",
      shadowColor: "#666060ff",
    },
    chart: {
      valueColor: "#2d1818ff",
      labelColor: "#2d1818ff",
      gridColor: "#232323",
      colorSet: [
        "#ff2828ff",
        "#45f6f6ff",
        "#5252f0ff",
        "#f6f645ff",
        "#77f68dff",
      ],
      fontFamily: "Open Sans",
      src: OpenSans,
      custom: false,
      format: null,
      background: "#847f7f61",
      showBackground: false,
      axisColor: "#232323",
      legendColor: "#232323"
    },
    table: {
      headerBg: "#dedede",
      headerColor: "#505553ff",
      fontFamily: "Open Sans",
      src: OpenSans,
      format: null,
      custom: false,
      color: "#484853ff",
      borderColor: "#c6babaff",
      background: "#fff",
      lineHeight: 2.2,
      letterSpacing: 0,
      stripeBackground: "#dde3a9", 
      stripeColor: "#484853ff",
    },
    video: {
      borderColor: "#bdc1aeff",
    },
  },
];
