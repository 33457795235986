import React, {useEffect} from "react";
import {
  getTemplateErr,
  selectTemplateStatus,
  selectTempPasscode,
  selectUsedFontList,
} from "../store/selectors/template/template.selector";
import {fetchTemplate} from "../store/actions/template/template.action";
import {connect} from "react-redux";
import styled from "styled-components";
import Present from "../components/viewTemplate/Present";
import {useParams} from "react-router";
import {Modal, Spinner} from "../../oat-ui";
import {selectSlideTransition} from "../store/selectors/template/slide.selector";
import {loadUsedFonts} from "../utils/loadUsedFonts";
import {selectUserInfo} from "../../../redux/user/authUser";
import EnterPasscode from "../components/viewTemplate/passcode/EnterPasscode";

const CustomLinkTemplate = ({
  fetchTemplate,
  loading,
  slideTransition,
  error,
  usedFontList,
  authUser,
  passcode,
}) => {
  let params = useParams();
  const {slug} = params;

  useEffect(() => {
    fetchTemplate({
      api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/custom-link/${slug}`,
    });
  }, [authUser]);

  useEffect(() => {
    loadUsedFonts(usedFontList);
  }, [usedFontList]);

  const handleModalClose = () => {};

  const displayView = () => {
    if (!passcode.enabled || (passcode.enabled && passcode.auth)) {
      return (
        <>
          {!loading ? (
            <Present transition={slideTransition} />
          ) : (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          )}

          {!loading && error && error.status ? (
            <Modal width={300} height={80} close={handleModalClose}>
              <Error>{error.message}</Error>
            </Modal>
          ) : undefined}
        </>
      );
    } else if (passcode.enabled && !passcode.auth) {
      return (
        <EnterPasscode
          tempID={passcode.tempID}
          isCustomLink={true}
          customLinkSlug={slug}
        />
      );
    }
  };

  return <Layout>{displayView()}</Layout>;
};

const Layout = styled.div`
  display: flex;
  background: #121217;
  width: 100%;
  height: ${window.innerHeight}px;
  aling-items: center;
  @media only screen and (min-width: 850px) {
    width: 100%; //99.9%;
  }
  @media screen and (orientation: landscape) {
    min-height: none;
  }
`;

const WrapSpinner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 35vh;
  box-sizing: border-box;
`;

const Error = styled.div`
  padding: 12px;
  box-sizing: border-box;
  font-size: 1rem;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    loading: selectTemplateStatus(designTemplate),
    slideTransition: selectSlideTransition(designTemplate),
    error: getTemplateErr(designTemplate),
    usedFontList: selectUsedFontList(designTemplate),
    authUser: selectUserInfo(state.authUser),
    passcode: selectTempPasscode(designTemplate),
  };
};

export default connect(mapStateToProps, {fetchTemplate})(CustomLinkTemplate);
