import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  selectTemplateID,
  selectWorkspaceID,
  selectWorkspacePlan,
} from "../../../../../store/selectors/template/template.selector";
import GuestList from "../../guests/GuestList";
import CustomLinks from "./customLinks/CustomLinks";
import DownloadAsImage from "./DownloadAsImage";
import GeneratePDF from "./GeneratePDF";
import ShareTempLink from "./ShareTempLink";
import {selectPageTheme} from "../../../../../store/selectors/layout/layout.selector";
import {requiresUpgradeToProStyles} from "../../../parts/upgradeToPro";

const tabAreaWidth = 240;

const DisplayItem = ({tab, onSelect, selected, workspacePlan, workspaceID}) => {
  const handleClick = () => {
    onSelect(tab.value);
  };
  return (
    <Tab onClick={handleClick} selected={selected}>
      <div>{tab.name}</div>
      {workspacePlan === "free" && tab.requiresUpgrade ? (
        <RequiresUpgradeToPro href={`/pricing?workspaceID=${workspaceID}`} target="_blank">Pro</RequiresUpgradeToPro>
      ) : undefined}
    </Tab>
  );
};

const linkTab = [
  {name: "Share a Link", value: "share-a-link", requiresUpgrade: false},
  {
    name: "Friendly URL Builder",
    value: "friendly-url-builder",
    requiresUpgrade: true,
  },
];

const guestSharingTab = [
  {name: "Share as Guest", value: "share-as-guest", requiresUpgrade: false},
];

const exportTab = [
  {
    name: "Generate PDF",
    value: "generate-pdf",
    requiresUpgrade: true,
  },
  {
    name: "Download as Image",
    value: "download-as-image",
    requiresUpgrade: true,
  },
];

const ShareTemplate = ({
  width,
  workspacePlan,
  close,
  processDesign,
  layoutTheme,
  workspaceID
}) => {
  const [selectedTab, setSelectedTab] = useState("share-a-link");

  const onSelectTab = (tab) => {
    setSelectedTab(tab);
  };

  let displaySections = null;
  switch (selectedTab) {
    case "share-as-guest":
      displaySections = (
        <GuestList processDesign={processDesign} close={close} />
      );
      break;
    case "share-a-link":
      displaySections = <ShareTempLink processDesign={processDesign} />;
      break;
    case "generate-pdf":
      displaySections = <GeneratePDF close={close} />;
      break;
    case "friendly-url-builder":
      displaySections = <CustomLinks close={close} />;
      break;
    default:
      displaySections = <DownloadAsImage close={close} />;
  }

  return (
    <Layout>
      <div style={{overflow: "hidden", display: "flex", width: "100%"}}>
        <Wrapper>
          <Tabs layoutTheme={layoutTheme} $freeWorkspace={workspacePlan === "free"}>
            {linkTab.map((tab, i) => (
              <DisplayItem
                key={i}
                tab={tab}
                selected={tab.value === selectedTab}
                onSelect={onSelectTab}
                workspacePlan={workspacePlan}
                workspaceID={workspaceID}
              />
            ))}

            <Line layoutTheme={layoutTheme} />

            {guestSharingTab.map((tab, i) => (
              <DisplayItem
                key={i}
                tab={tab}
                selected={tab.value === selectedTab}
                onSelect={onSelectTab}
                workspacePlan={workspacePlan}
                workspaceID={workspaceID}
              />
            ))}

            <Line layoutTheme={layoutTheme} />

            {exportTab.map((tab, i) => (
              <DisplayItem
                key={i}
                tab={tab}
                selected={tab.value === selectedTab}
                onSelect={onSelectTab}
                workspacePlan={workspacePlan}
                workspaceID={workspaceID}
              />
            ))}
          </Tabs>

          <ContentArea style={{width: width - tabAreaWidth}}>
            {displaySections}
          </ContentArea>
        </Wrapper>
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  justify-content: center;
  font-size: 1rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  box-sizing: border-box;
  background: ${({theme}) => theme.deskPrimaryBg};
`;

const Wrapper = styled.div`
  display: flex;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({$freeWorkspace}) => $freeWorkspace ? tabAreaWidth + 30 : tabAreaWidth}px;
  height: 100%;
  padding-bottom: 12px;
  background: ${({theme}) => theme.deskPrimaryBg2};
  padding: 12px 0;
  box-sizing: border-box;
  box-shadow: ${({theme}) => theme.boxShadow3};
  color: ${({theme, layoutTheme}) =>
    layoutTheme === "light" ? "#707072" : theme.deskPrimaryTxt};
  @media only screen and (max-width: 850px) {
    border-bottom: 1px solid ${({theme}) => theme.deskGap};
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  font-weight: 600;
  font-size: 0.93rem;
  background: ${({theme, selected}) =>
    selected ? theme.secondaryThemeHover : undefined};
  padding: 0 12px;
  margin: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
`;

const ContentArea = styled.div`
  scrollbar-gutter: unset;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ShareSvgIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const WrapSvg = styled.div`
  width: 200px;
  height: 200px;
`;

export const WrapButton = styled.div`
  width: 140px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({layoutTheme}) => layoutTheme === 'light' ? '#d8d8d8' : '#484254'};
`

const RequiresUpgradeToPro = styled.a`
  ${requiresUpgradeToProStyles}
`;

const mapStateToProps = (state) => {
  return {
    layoutTheme: selectPageTheme(state.designTemplate),
    tempID: selectTemplateID(state.designTemplate),
    workspacePlan: selectWorkspacePlan(state.designTemplate),
    workspaceID: selectWorkspaceID(state.designTemplate),
    theme: selectPageTheme(state.designTemplate)
  };
};

export default connect(mapStateToProps, null)(ShareTemplate);
