import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {colorList} from "./colorList";
import {ColorPicker} from "../../../../../../../oat-color-picker";
import {Clear} from "../../../../../../../oat-svg-icons";

const DisplayColor = ({color, onChangeColor, active}) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeColor(color);
  };
  return (
    <Color onMouseDown={handleClick} style={{backgroundColor: color}}>
      <ActiveMark active={active} />
    </Color>
  );
};
const types = ["Hex", "RBG"];
const DeskColorOptions = ({
  activeColor,
  updateFieldStyles,
  onClose,
  useInTooltip,
  onSelectionEnd, // later use as "onDebounce" // to only emit the mouseUp value
}) => {
  const [stableColor, setStableColor] = useState(activeColor);
  const [inputColor, setInputColor] = useState(activeColor);

  const selectCurrentStableColor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateFieldStyles(stableColor);
    onSelectionEnd(stableColor); // for debounce event
  };

  const handleColorPickerSelection = (color) => {
    updateFieldStyles(color?.hex);
  };

  const handleColorInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {value} = e.target;
    setInputColor(value);
  };

  const applyInputColor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateFieldStyles(inputColor);
    onSelectionEnd(inputColor); // for debounce event
  };

  const handleBlurInput = (e) => {
    e.stopPropagation();
    // input coping the panel into field
    if (e.target.tagName !== "INPUT") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setInputColor(activeColor);
  }, [activeColor]);

  const handleSelectionEnd = (color) => {
    updateFieldStyles(color.hex);
    onSelectionEnd(color.hex);
  };

  const handleColorSelection = (color) => {
    updateFieldStyles(color);
    onSelectionEnd(color); // for debounce event
  };

  return (
    <Wrapper onMouseDown={handleBlurInput} useInTooltip={useInTooltip}>
      <OrgColor style={{useSelect: 'none'}}>
        <div style={{display: "flex", alignItems: "center"}}>
          <Header>Original Color</Header>
          {stableColor !== "" ? (
            <Color
              onMouseDown={selectCurrentStableColor}
              style={{backgroundColor: stableColor}}
            />
          ) : undefined}
        </div>

        <div style={{position: "absolute", top: -5, right: 0}}>
          <WrapSvg onMouseDown={onClose}>{Clear}</WrapSvg>
        </div>
      </OrgColor>

      <ColorPicker
        activeColor={activeColor}
        onChange={handleColorPickerSelection}
        onSelectionEnd={handleSelectionEnd}
      />

      <ActiveColorCode>
        <div style={{flex: 4}}>
          <ColorCodeSection>
            <div style={{flex: 2, height: '100%'}}>
              <InputCode
                type="text"
                value={inputColor}
                // onBlur={handleBlurInput}
                onChange={handleColorInput}
              />
            </div>
            <ColorType onMouseDown={applyInputColor}>Apply</ColorType>
          </ColorCodeSection>
        </div>
        <div style={{flex: 1}}>
          <SelectedColor style={{background: activeColor}} />
        </div>
      </ActiveColorCode>

      {window.innerWidth >= 850 ? (
        <Colors>
          {colorList &&
            colorList.map((color, index) => (
              <DisplayColor
                key={index}
                color={color}
                active={color === activeColor}
                onChangeColor={handleColorSelection}
              />
            ))}
        </Colors>
      ) : undefined}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({theme, useInTooltip}) =>
    !useInTooltip ? theme.popUpBg : theme.deskPrimaryBgTransparent};
  padding: 10px;
  @media only screen and (max-width: 849px) {
    background: rgba(20 20 34 / 1);
  }
`;

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: ${({theme}) => theme.deskSecondaryTxt};
  cursor: pointer;
`;

const Colors = styled.div`
  display: flex;
  outline: none;
  scrollbar-width: none;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  justify-content: center;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const Color = styled.button`
  padding: 10px;
  border-radius: 50%;
  box-sizing: border-box;
  background: ${({bgColor}) => (bgColor ? bgColor : undefined)};
  flex: 0 0 auto;
  margin: 5px 5px 10px;
  outline: none;
  height: 100%;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 1px solid #7575758c;
`;

export const ActiveMark = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  border-radius: 50%;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255 255 255 / 64%);
  outline: none;
`;

const Header = styled.h5`
  font-size: 0.8rem;
  padding-right: 20px;
  color: ${({theme}) => theme.deskSecondaryTxt};
`;

const OrgColor = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
  margin-bottom: 12px;
  justify-content: space-between;
  position: relative;
`;

const ActiveColorCode = styled.div`
  display: flex;
  padding: 1em 0;
  height: 30px;
  z-index: 1;
`;

const ColorCodeSection = styled.div`
  background: ${({theme}) => theme.deskPrimaryBg2};
  border-radius: 4px;
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const InputCode = styled.input`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background: transparent;
  padding-left: 5px;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

const ColorType = styled.button`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  z-index: 9;
  color: ${({theme}) => theme.deskPrimaryTxt};
  background: ${({theme}) => theme.deskSecondaryBg};
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
`;

const SelectedColor = styled.div`
  box-shadow: inset 0 0 0 1px #c7bbbbbf;
  width: 100%;
  height: 100%;
`;

DeskColorOptions.defaultProps = {
  useInTooltip: false, // in tooltip, add opacity to bg
};

export default DeskColorOptions;
