export function setSmartAlignment() {
  return function (state, action) {
    let mainPoint = action.value;
    if (state.smartAlignment.points[action.key].hasOwnProperty(action.value)) {
      // for instance, a field with an offset of 100 -> we will have something like 100, 101, 102 etc. to make the scaled pixels. So if we have 101 or 102 -> we will forward back to original point 100
      if (
        state.smartAlignment.points[action.key].hasOwnProperty(
          action.value + 1
        ) &&
        state.smartAlignment.points[action.key].hasOwnProperty(action.value + 2)
      ) {
        mainPoint = action.value;
      } else if (
        state.smartAlignment.points[action.key].hasOwnProperty(action.value + 1)
      ) {
        mainPoint = action.value - 1;
      } else {
        mainPoint = action.value - 2;
      }
    }
    return {
      ...state,
      smartAlignment: {
        ...state.smartAlignment,
        [action.key]: {
          display: action.value !== null ? true : false,
          value: mainPoint,
        },
      },
    };
  };
}
