export function AlignLeft() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9 7C22.1843 7 22.4 6.78427 22.4 6.5C22.4 6.21573 22.1843 6 21.9 6H6.1C5.81573 6 5.6 6.21573 5.6 6.5C5.6 6.78427 5.81573 7 6.1 7H21.9ZM17.9 12C18.1843 12 18.4 11.7843 18.4 11.5C18.4 11.2157 18.1843 11 17.9 11H6.1C5.81573 11 5.6 11.2157 5.6 11.5C5.6 11.7843 5.81573 12 6.1 12H17.9ZM11.9 17C12.1843 17 12.4 16.7843 12.4 16.5C12.4 16.2157 12.1843 16 11.9 16H6.1C5.81573 16 5.6 16.2157 5.6 16.5C5.6 16.7843 5.81573 17 6.1 17H11.9Z">
      </path>
    </svg>
  );
}

export function AlignCenter() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 7.5C19.5 7.22386 19.2761 7 19 7H9C8.72386 7 8.5 7.22386 8.5 7.5C8.5 7.77614 8.72386 8 9 8H19C19.2761 8 19.5 7.77614 19.5 7.5Z" />
      <path d="M22.5 12.5C22.5 12.2239 22.2761 12 22 12H5C4.72386 12 4.5 12.2239 4.5 12.5C4.5 12.7761 4.72386 13 5 13H22C22.2761 13 22.5 12.7761 22.5 12.5Z" />
      <path d="M19.5 17.5C19.5 17.2239 19.2761 17 19 17H9C8.72386 17 8.5 17.2239 8.5 17.5C8.5 17.7761 8.72386 18 9 18H19C19.2761 18 19.5 17.7761 19.5 17.5Z" />
    </svg>
  );
}

export function AlignRight() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 7.5C21 7.22386 20.7761 7 20.5 7H7.5C7.22386 7 7 7.22386 7 7.5C7 7.77614 7.22386 8 7.5 8H20.5C20.7761 8 21 7.77614 21 7.5ZM21 12.5C21 12.2239 20.7761 12 20.5 12H11.5C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13H20.5C20.7761 13 21 12.7761 21 12.5ZM20.5 17C20.7761 17 21 17.2239 21 17.5C21 17.7761 20.7761 18 20.5 18H15.5C15.2239 18 15 17.7761 15 17.5C15 17.2239 15.2239 17 15.5 17H20.5Z"/>
    </svg>
  );
}
