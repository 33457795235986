import React, {useEffect, useRef} from "react";
import {Spinner} from "../../../../../../../oat-ui";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import styled from "styled-components";
import {AddComment, WrapCommentArea, WrapCommentBtn} from "./commentStyles";
import {darkTheme, lightTheme} from "./inputStyles";
import {
  Mention,
  MentionsInput,
} from "react-mentions/dist/react-mentions.cjs.prod";

const CommentRespondInput = ({
  value,
  onChange,
  processing,
  onSubmit,
  enableCancel,
  onCancel,
  theme,
  searchList,
  onAddMentionedUsers,
}) => {
  return (
    <WrapCommentArea style={{borderRadius: 8}}>
      <MentionsInput
        style={theme === "light" ? lightTheme : darkTheme}
        value={value}
        onChange={onChange}
        appendspaceonadd="true"
      >
        <Mention
          displayTransform={(id, name) => `@${name}`}
          style={{
            backgroundColor: theme === "light" ? "#b7b9ff" : "#171dff",
          }}
          trigger="@"
          data={searchList}
          onAdd={onAddMentionedUsers}
        />
      </MentionsInput>

      <AddComment style={{justifyContent: "space-between"}}>
        {enableCancel ? (
          <p style={{marginRight: 14, cursor: "pointer"}} onClick={onCancel}>
            Cancel
          </p>
        ) : undefined}

        <WrapCommentBtn>
          {processing ? (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          ) : undefined}
          <SpecialButton disabled={processing} onClick={onSubmit}>
            Reply
          </SpecialButton>
        </WrapCommentBtn>
      </AddComment>
    </WrapCommentArea>
  );
};

const WrapSpinner = styled.div`
  margin-right: 18px;
  transform: scale(0.7);
`;

CommentRespondInput.defaultProps = {
  enableCancel: false,
};
export default CommentRespondInput;
