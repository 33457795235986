import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {resizeTableColumnCell} from "../../../../../../store/actions/fields/table.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import {
  getItemXOffset,
  getItemYOffset,
} from "../../../../../../utils/getItemOffset";
import {calcOnResize} from "../../../dragResize/resize/utils/calcOnResize";
import {centerToTL} from "../../../dragResize/utils";
import {tableResizerBarColor} from "./ResizeRowCells";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const ShowColumnResizer = ({
  index,
  column,
  tempScale,
  onCellResizeStart,
  showBar,
}) => {
  const initResize = (e) => {
    onCellResizeStart(e, index);
  };

  return (
    <ColumnHandleBar
      onMouseDown={initResize}
      onTouchStart={initResize}
      style={{left: column.width + column.xOffset - 1.5 / tempScale}}
      tempScale={tempScale}
      showBar={showBar}
    />
  );
};

const ResizeColumnCells = ({
  columns,
  fieldID,
  slideID,
  resizeTableColumnCell,
  tableSize,
  tempScale,
  updateTableHeight,
  table,
  storeFieldUndoRedo,
  onResizeEnd,
  updateFieldCollaboration,
  minColumWidth,
}) => {
  const [columnResize, setColumnResize] = useState({});
  const delayDebounce = useRef(null);

  const onResizeStart = (e, index) => {
    const clientX = getItemXOffset(e, tempScale);
    const clientY = getItemYOffset(e, tempScale);
    setColumnResize({
      columnIndex: index,
      orig: {
        mouseX: Math.round(clientX),
        mouseY: Math.round(clientY),
      },
      resizing: true,
    });
    e.stopPropagation();
  };

  const onResize = (e) => {
    const {columnIndex, orig} = columnResize;
    const clientX = getItemXOffset(e, tempScale);
    const direction = orig.mouseX > clientX ? "left" : "right";
    const {centerX, centerY, width, height, rotate} = calcOnResize({
      e,
      size: {
        w: columns[columnIndex].width,
        h: 0,
      },
      pos: {
        x: 0,
        y: 0,
      },
      initialProps: columnResize,
      tempScale,
      rotate: 0,
      direction: "right",
      propotion: false,
      balanceRatio: false,
    });
    const resize = centerToTL({
      centerX,
      centerY,
      width,
      height,
      rotate,
    });
    const sizeDiff = resize.width - columns[columnIndex].width; // added size

    let resizeTableProps = {};
    if (direction === "right") {
      resizeTableProps = {
        active: {slideID, fieldID},
        colIndex: columnResize.columnIndex,
        payload: resize.width,
        direction,
        sizeDiff, // to extend field width
        initTableSize: {
          w: tableSize.w,
          h: tableSize.h,
        },
      };
      resizeTableColumnCell({...resizeTableProps});
    } else if (
      direction === "left" &&
      table.current.rows[0].cells[columnIndex].offsetWidth > minColumWidth
    ) {
      const sizeDiff =
        resize.width > minColumWidth
          ? resize.width - columns[columnIndex].width
          : minColumWidth - columns[columnIndex].width;
      resizeTableProps = {
        active: {slideID, fieldID},
        colIndex: columnResize.columnIndex,
        payload: resize.width > minColumWidth ? resize.width : minColumWidth,
        direction,
        sizeDiff,
        initTableSize: {
          w: tableSize.w,
          h: tableSize.h,
        },
      };
      resizeTableColumnCell({...resizeTableProps});
    }
    updateTableHeight();

    clearTimeout(delayDebounce.current);
    delayDebounce.current = setTimeout(() => {
      updateFieldCollaboration();
    }, 4000);

    e.stopPropagation();
  };

  const handleResizeEnd = (e) => {
    setColumnResize({
      ...columnResize,
      resizing: false,
    });
    // updateTableHeight();
    onResizeEnd(columnResize.columnIndex);
    storeFieldUndoRedo();
    e.stopPropagation();
  };

  useEffect(() => {
    if (columnResize.resizing) {
      window.addEventListener("touchmove", onResize);
      window.addEventListener("touchend", handleResizeEnd);
      window.addEventListener("mouseup", handleResizeEnd);
      window.addEventListener("mousemove", onResize);
      window.addEventListener("mouseleave", handleResizeEnd);
    }
    return () => {
      window.removeEventListener("touchmove", onResize);
      window.removeEventListener("touchend", handleResizeEnd);
      window.removeEventListener("mouseup", handleResizeEnd);
      window.removeEventListener("mousemove", onResize);
      window.removeEventListener("mouseleave", handleResizeEnd);
    };
  }, [columnResize.resizing]);

  return (
    columns &&
    columns.map((column, index) => (
      <ShowColumnResizer
        key={index}
        index={index}
        column={column}
        onCellResizeStart={onResizeStart}
        tempScale={tempScale}
        showBar={index === columnResize.columnIndex && columnResize.resizing}
      />
    ))
  );
};

const ColumnHandleBar = styled.div`
  position: absolute;
  top: 0px;
  width: ${({tempScale}) => 3 / tempScale}px;
  height: 100%;
  background: ${({showBar}) => (showBar ? tableResizerBarColor : undefined)};
  cursor: ew-resize;
  z-index: ${({showBar}) => (showBar ? 999 : 1)};
  &:hover {
    background: ${({showBar}) => (!showBar ? tableResizerBarColor : undefined)};
  }
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    tempScale: selectTempScale(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
  };
};

export default connect(mapStateToProps, {
  resizeTableColumnCell,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ResizeColumnCells);
