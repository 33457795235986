export function setSmartAignmentPoints({
  template,
  slideID,
  fieldIDs,
  tempScale,
  tempSize,
}) {
  const fields = template[slideID]?.fields;
  if(!fields) {
    return { x: {} , y: {}}
  }

  let fillPixelGap = 1;
  fillPixelGap = Math.round(1 / tempScale);
  let xAlign = {},
    yAlign = {};
  
  Object.entries(fields).map(([id, item], i) => {
    if (!fieldIDs?.includes(id) && !item.deleted) {
      if (item.type === "shape" && item.subtype === "line" && (item.styles.rotate === 0 || item.styles.rotate === 360)) {
        const {pos, size} = item;
        // org value
        // xAlign[Math.ceil(pos.x)] = "corner";
        // xAlign[Math.ceil(pos.x + size.w)] = "corner";
        xAlign[Math.ceil(pos.x - fillPixelGap)] = "center";
        xAlign[Math.ceil(pos.x)] = "center";
        xAlign[Math.ceil(pos.x + fillPixelGap)] = "center";

        // yAlign
        yAlign[Math.ceil(pos.y + (size.h / 2) - fillPixelGap)] = "center";
        yAlign[Math.ceil(pos.y + (size.h / 2))] = "center";
        yAlign[Math.ceil(pos.y + (size.h / 2) + fillPixelGap)] = "center";



        // xAlign[Math.ceil(pos.x - fillPixelGap)] = "corner";
        // xAlign[Math.ceil(pos.x)] = "corner";
        // xAlign[Math.ceil(pos.x + fillPixelGap)] = "corner";

        // // yAlign
        // yAlign[Math.ceil(pos.y + size.h /2 - fillPixelGap)] = "corner";
        // yAlign[Math.ceil(pos.y + size.h /2)] = "corner";
        // yAlign[Math.ceil(pos.y + size.h /2 + fillPixelGap)] = "corner";

        if (pos.rotatedOffsets?.startPoint) {
          xAlign[Math.ceil(pos.rotatedOffsets?.startPoint - fillPixelGap)] = "center";
          xAlign[Math.ceil(pos.rotatedOffsets?.startPoint)] = "center";
          xAlign[Math.ceil(pos.rotatedOffsets?.startPoint + fillPixelGap)] = "center";

          yAlign[Math.ceil(pos.rotatedOffsets?.endPoint - fillPixelGap)] = "center";
          yAlign[Math.ceil(pos.rotatedOffsets?.endPoint)] = "center";
          yAlign[Math.ceil(pos.rotatedOffsets?.endPoint + fillPixelGap)] = "center";
        }

        // xAlign[Math.floor(pos.x + fillPixelGap)] = "corner";
        // xAlign[Math.floor(pos.x + size.w + fillPixelGap)] = "corner";
        // // xAlign[Math.floor(pos.x - fillPixelGap)] = "corner";
        // xAlign[Math.floor(pos.x + size.w - fillPixelGap)] = "corner";

        // yAlign[Math.ceil(pos.y + ((size.h / 2) + fillPixelGap))] = "corner";
      } else if (
        item.styles.rotate === 0
      ) {
        const {pos, size} = item;
        // org value
        xAlign[Math.ceil(pos.x)] = "corner";
        xAlign[Math.ceil(pos.x + size.w)] = "corner";

        // org value
        yAlign[Math.ceil(pos.y)] = "corner";
        yAlign[Math.ceil(pos.y + size.h)] = "corner";

        // mid field offsets
        const midXOffset = Math.ceil(pos.x + size.w / 2);
        const midYOffset = Math.ceil(pos.y + size.h / 2);

        xAlign[midXOffset] = "center";
        yAlign[midYOffset] = "center";

        // temp has been scaled & e.clientX been divided by tempScale
        // for eg, when dragging on temp scale of 0.4 -> 1-3-5 will only be showed
        // and 2, 4, 6 been removed.
        // thus for point 2 to be included, we need to add extra points
        
        if (tempScale < 1) {
          for (let i = 0; i <= Math.ceil(fillPixelGap); i++) {
            // xAlign[Math.ceil(pos.x - i)] = "corner";
            // xAlign[Math.ceil(pos.x + size.w - i)] = "corner";
            
            xAlign[Math.ceil(pos.x + i)] = "corner";
            xAlign[Math.ceil(pos.x - i)] = "corner";
            xAlign[Math.ceil(pos.x + size.w + i)] = "corner";
            xAlign[Math.ceil(pos.x + size.w - i)] = "corner";

            yAlign[Math.ceil(pos.y + i)] = "corner";
            yAlign[Math.ceil(pos.y - i)] = "corner";
            yAlign[Math.ceil(pos.y + size.h + i)] = "corner";
            yAlign[Math.ceil(pos.y + size.h - i)] = "corner";

            xAlign[midXOffset + i] = "center";
            yAlign[midYOffset + i] = "center";
            xAlign[midXOffset - i] = "center";
            yAlign[midYOffset - i] = "center";
          }
        }
      }
    }
  });

  // temp center points
  const tempXMidPoint = Math.ceil(tempSize.w / 2);
  const tempYMidPoint = Math.ceil(tempSize.h / 2);
  xAlign[tempXMidPoint] = "field-centred-template";
  yAlign[tempYMidPoint] = "field-centred-template";

  if (tempScale < 1) {
    for (let i = 0; i <= Math.ceil(fillPixelGap); i++) {
      xAlign[tempXMidPoint - i] = "field-centred-template";
      yAlign[tempYMidPoint - i] = "field-centred-template";
      xAlign[tempXMidPoint + i] = "field-centred-template";
      yAlign[tempYMidPoint + i] = "field-centred-template";
    }
  }
  return {xAlign, yAlign};
}

export function areObjectsIdentical(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}