export const getLoadingStatus = (state) => state.fetchData;
export const selectUserTemplates = (state) => state.templates;

export const selectUserDashboardErr = (state) => state.fetchData.error;
export const selectDashboardModal = (state) => state.modal;

// profile
export const selectUserProfileTags = (state) => state.profile.tags;
export const selectProfileTemplates = (state) => state.templates;

// workspace
export const selectWorkspaceList = (state) => state.workspace.items;
export const selectActiveWorkSpaceInfo = (state) => state.workspace;
export const selectActiveWorkSpace = (state) => state.workspace.active;
export const selectWSSwitchingStatus = (state) =>
  state.workspace.onSwitchWorkspace;
export const selecWorkSpaceLoadingStatus = (state) => state.workspace.loading;
export const selecWorkSpaceError = (state) => state.workspace.error;
export const selectWorkspacePlan = (state) => state.workspace.active.plan;

// folder
export const selectFolders = (state) => state.folders;
export const selectActiveFolder = (state) => state.activeFolder;
