import React from "react";

const Comment = (
  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  //   <rect
  //     x="1.5"
  //     y="5"
  //     width="21"
  //     height="14"
  //     rx="7"
  //     ry="7"
  //     fill="none"
  //     stroke="#dedede"
  //   />
  //   <circle cx="6.5" cy="12" r="1.5" />
  //   <circle cx="12" cy="12" r="1.5" />
  //   <circle cx="17.5" cy="12" r="1.5" />
  // </svg>

  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  //   <filter id="inset-shadow">
  //     <feOffset dx="0" dy="0"></feOffset>
  //     <feGaussianBlur stdDeviation="1" result="offset-blur"></feGaussianBlur>
  //     <feComposite
  //       operator="out"
  //       in="SourceGraphic"
  //       in2="offset-blur"
  //       result="inverse"
  //     ></feComposite>
  //     <feFlood flood-color="black" flood-opacity=".95" result="color"></feFlood>
  //     <feComposite
  //       operator="in"
  //       in="color"
  //       in2="inverse"
  //       result="shadow"
  //     ></feComposite>
  //     <feComposite
  //       operator="over"
  //       in="shadow"
  //       in2="SourceGraphic"
  //     ></feComposite>
  //   </filter>

  //   <defs>
  //     <radialGradient
  //       id="radical-gradient-comment-313a3"
  //       cx="50%"
  //       cy="40%"
  //       r="50%"
  //       fx="50%"
  //       fy="30%"
  //     >
  //       <stop offset="8%" stopColor="#29abe2" />
  //       <stop offset="100%" stopColor="#f0f" />
  //     </radialGradient>
  //   </defs>

  //   <g>
  //     <use
  //       href="#VH3HWDshapedbl_stroke"
  //       stroke="#747979ff"
  //       stroke-width="0"
  //       fill="url(#VH3HWDshape)"
  //       clip-path="url(#VH3HWDshapeclip)"
  //     ></use>
  //   </g>

  //   <circle class="cls-2" cx="7.2" cy="12" r="1.3" />
  //   <circle class="cls-2" cx="12" cy="12" r="1.3" />
  //   <circle class="cls-2" cx="16.8" cy="12" r="1.3" />
  //   <path
  //     filter="url(#inset-shadow)"
  //     fill="url(#radical-gradient-comment-313a3)"
  //     class="cls-3"
  //     d="M15.1,5.1h-6a6.4,6.4,0,0,0-6.3,5.4,6.2,6.2,0,0,0,6.1,7h.7L7.8,20.4c-.2.2.2.6.5.4l5.8-3.3h.8a6.4,6.4,0,0,0,6.3-5.4A6.2,6.2,0,0,0,15.1,5.1Z"
  //   />
  // </svg>

  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   id="eVwHCApzX541"
  //   viewBox="0 0 128 128"
  //   shapeRendering="geometricPrecision"
  //   textRendering="geometricPrecision"
  //   style={{transform: `scale(2.7)`}}
  // >
  //   <defs>
  //     <filter
  //       id="eVwHCApzX543-filter"
  //       x="-400%"
  //       width="600%"
  //       y="-400%"
  //       height="600%"
  //     >
  //       <feGaussianBlur
  //         id="eVwHCApzX543-filter-blur-0"
  //         stdDeviation="4,4"
  //         result="result"
  //       ></feGaussianBlur>
  //     </filter>
  //     <linearGradient
  //       id="eVwHCApzX544-fill"
  //       x1="0"
  //       y1="0"
  //       x2="1"
  //       y2="0"
  //       spreadMethod="pad"
  //       gradientUnits="userSpaceOnUse"
  //       gradientTransform="matrix(-45.631312 -30.901204 30.901204 -45.631312 86.387037 75.232797)"
  //     >
  //       <stop id="eVwHCApzX544-fill-0" offset="0%" stopColor="#ffdede"></stop>
  //       <stop id="eVwHCApzX544-fill-1" offset="33%" stopColor="#4242f4"></stop>
  //       <stop id="eVwHCApzX544-fill-2" offset="100%" stopColor="#7a8ef6"></stop>
  //     </linearGradient>
  //   </defs>
  //   <g id="eVwHCApzX542">
  //     <path
  //       id="eVwHCApzX543"
  //       d="M92,60C92,48.40202,82.59798,39,71,39L57,39C45.40202,38.859167,35.885833,48.14702,35.745,59.745C35.604168,71.34298,44.89202,80.859167,56.49,81L64,88.49L71.51,81C82.908334,80.723102,92.003363,71.401697,92,60Z"
  //       transform="matrix(1 0 0 1 3.998269 3.995775)"
  //       opacity="0.4"
  //       filter="url(#eVwHCApzX543-filter)"
  //       fill="rgb(0,0,0)"
  //       stroke="none"
  //       strokeWidth="1"
  //     ></path>
  //     <path
  //       id="eVwHCApzX544"
  //       d="M92,60C92,48.40202,82.59798,39,71,39C66.8,39,61.199923,39.025499,57,39C45.401379,38.929582,35.885833,48.14702,35.745,59.745C35.604168,71.34298,44.401165,81,56,81C57,83,64,88.49,64,88.49C64,88.49,70,83,71.51,81C82.911697,81,92.003363,71.401697,92,60Z"
  //       fill="url(#eVwHCApzX544-fill)"
  //       stroke="none"
  //       strokeWidth="1"
  //     ></path>
  //     <g id="eVwHCApzX545">
  //       <g id="eVwHCApzX546_to" transform="translate(50,60)">
  //         <g id="eVwHCApzX546_ts" transform="scale(0.8,0.8)">
  //           <circle
  //             id="eVwHCApzX546"
  //             r="4"
  //             transform="translate(0,0)"
  //             fill="rgb(255,255,255)"
  //             stroke="none"
  //             strokeWidth="1"
  //           ></circle>
  //         </g>
  //       </g>
  //       <g id="eVwHCApzX547_to" transform="translate(63.771142,60.103126)">
  //         <g id="eVwHCApzX547_ts" transform="scale(0.8,0.8)">
  //           <circle
  //             id="eVwHCApzX547"
  //             r="4"
  //             transform="translate(0,0)"
  //             fill="rgb(255,255,255)"
  //             stroke="none"
  //             strokeWidth="1"
  //           ></circle>
  //         </g>
  //       </g>
  //       <g id="eVwHCApzX548_to" transform="translate(78.055998,60.053648)">
  //         <g id="eVwHCApzX548_ts" transform="scale(0.8,0.8)">
  //           <circle
  //             id="eVwHCApzX548"
  //             r="4"
  //             transform="translate(0,0)"
  //             fill="rgb(255,255,255)"
  //             stroke="none"
  //             strokeWidth="1"
  //           ></circle>
  //         </g>
  //       </g>
  //     </g>
  //   </g>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    style={{transform: `scale(2.7)`}}
  >
    <defs>
      <radialGradient
        id="eVwHCApzX544-fill"
        cx="42.88"
        cy="86.53"
        r="112.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#e186fe8f" />
        <stop offset="0.35" stopColor="#6b70f9" />
        <stop offset="0.85" stopColor="#fff" />
      </radialGradient>
      {/* <linearGradient
        id="eVwHCApzX544-fill"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-45 -30 30 -45 86 75)"
      >
        <stop id="eVwHCApzX544-fill-0" offset="0%" stopColor="#f4f9d7"></stop>
        <stop id="eVwHCApzX544-fill-1" offset="33%" stopColor="#4242f4"></stop>
        <stop id="eVwHCApzX544-fill-2" offset="100%" stopColor="#7a8ef6"></stop>
      </linearGradient> */}
    </defs>
    <g id="eVwHCApzX542">
      <path
        id="eVwHCApzX544"
        fill="url(#eVwHCApzX544-fill)"
        d="M68.4,83.2a17.5,17.5,0,0,1,6.7-2.5A21.1,21.1,0,0,0,70.3,39c-4.5.2-9,0-13.5,0A21.1,21.1,0,0,0,35.7,59.7c-.1,10.8,7.4,19.8,17.8,21.1a2.8,2.8,0,0,1,2.4,3.1,12.5,12.5,0,0,1-1.1,4.8.4.4,0,0,0,.5.6A52.7,52.7,0,0,0,68.4,83.2Z"
      />
      <g id="eVwHCApzX545">
        <g id="eVwHCApzX546_to" data-name="eVwHCApzX546 to">
          <g id="eVwHCApzX546_ts" data-name="eVwHCApzX546 ts">
            <circle
              id="eVwHCApzX546"
              style={{fill: `#fff`}}
              cx="50"
              cy="60"
              r="3.2"
            />
          </g>
        </g>
        <g id="eVwHCApzX547_to" data-name="eVwHCApzX547 to">
          <g id="eVwHCApzX547_ts" data-name="eVwHCApzX547 ts">
            <circle
              id="eVwHCApzX547"
              style={{fill: `#fff`}}
              cx="63.8"
              cy="60.1"
              r="3.2"
            />
          </g>
        </g>
        <g id="eVwHCApzX548_to" data-name="eVwHCApzX548 to">
          <g id="eVwHCApzX548_ts" data-name="eVwHCApzX548 ts">
            <circle
              id="eVwHCApzX548"
              style={{fill: `#fff`}}
              cx="78.1"
              cy="60.1"
              r="3.2"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Comment;
