import React, {useEffect, useState, useRef} from "react";
import {
  CanvasLayout,
  WrapFrame,
  Card,
} from "./../../editDesign/canvasPanel/canvas/CanvasStyles";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../store/selectors/template/template.selector";
import ViewOnlyFields from "./../../editDesign/canvasPanel/viewOnlyFields/ViewOnlyFields";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {selectActiveSlide} from "../../../store/selectors/template/slide.selector";

const DisplayItem = ({
  slide,
  onSelectItem,
  width,
  height,
  tempSize,
  scale,
  active,
  index
}) => {
  const handleClick = () => {
    onSelectItem(slide);
  };

  return (
    <WrapCanvas
      id={`present-slide-i-${index}`}
      style={{
        width,
        height,
        // testing for potrait mode
        // width: 315,
        // height: 177,
        // display: "flex",
        // justifyContent: "center",
        // background: "transparent",
      }}
    >
      <CanvasLayout
        style={{
          width,
          height,
          overflow: "hidden",
          cursor: "pointer",
          background: !slide.bg.gradient
            ? slide.bg.bg1
            : slide.bg.type !== "center"
            ? `linear-gradient(${slide.bg.bgAng}deg, ${slide.bg.bg1}, ${slide.bg.bg2})`
            : `radial-gradient(${slide.bg.bg1}, ${slide.bg.bg2})`,
          border: active ? "4px solid #6a6af8" : undefined,
          boxSizing: "border-box",
        }}
        onClick={handleClick}
      >
        <WrapFrame
          width={tempSize.w}
          height={tempSize.h}
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <Card style={{overflow: "hidden"}}>
            {slide && slide.fields
              ? Object.entries(slide.fields).map(([id, field]) => (
                  <div key={id}>
                    <ViewOnlyFields field={field} />
                  </div>
                ))
              : undefined}
          </Card>
        </WrapFrame>
      </CanvasLayout>
    </WrapCanvas>
  );
};

const PresentSlideList = ({
  slides,
  tempSize,
  activeSlide,
  showLayout,
  onSlideChange,
  columnsPerScreen,
  styles,
}) => {
  const layoutRef = useRef();
  const [size, setSize] = useState({width: 0, height: 0, scale: 0});
  const windowSize = getWindowSize();

  useEffect(() => {
    let itemWidth = 311;
    if (windowSize.width < 600) {
      itemWidth = 150;
    } else if (windowSize.width < 850) {
      itemWidth = 210;
    }

    if (layoutRef.current) {
      const activeItemElement = document.getElementById(`present-slide-i-${activeSlide}`);
      if (activeItemElement && activeItemElement?.offsetLeft > window.innerWidth) {
        layoutRef.current.scrollTo({
          left: activeItemElement.offsetLeft - layoutRef.current.offsetLeft - 15,
          behavior: 'smooth',
        });
      } 
      else if (activeItemElement && activeItemElement.offsetLeft >= layoutRef.current.offsetLeft) {
        layoutRef.current.scrollTo({
          left: -activeItemElement.offsetLeft,
          behavior: 'smooth',
        });
      }

      // layoutRef.current.scrollLeft += 500;
      const panelWidth = itemWidth;
      //windowSize.width / columnsPerScreen; // 311
      const panelHeight = (panelWidth * tempSize.h) / tempSize.w;
      const calcScale = Math.min(
        panelHeight / tempSize.h,
        panelWidth / tempSize.w
      );
      setSize({
        width: panelWidth, // 150,
        height: panelHeight, // 210,
        scale: calcScale,
      });
    }
  }, [tempSize, windowSize.width, activeSlide]);

  const handleSlideChange = (item) => {
    onSlideChange(item.id);
  };

  // Seems like keydown detect only works in Present.js ??

  return (
    <Wrapper
      id="present-view-horizontal-slide-list"
      ref={layoutRef}
      style={{
        ...styles,
        width: windowSize.width,
        visibility: showLayout ? "visible" : "hidden",
      }}
    >
      {slides &&
        slides.map((slide, i) => (
          <DisplayItem
            slide={slide}
            key={i}
            index={i}
            onSelectItem={handleSlideChange}
            width={size.width}
            height={size.height}
            tempSize={tempSize}
            scale={size.scale}
            active={i === activeSlide}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding-left: 20px;
  position: absolute;
  left: 0px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrapCanvas = styled.div`
  margin: 0 10px 0px 0px;
  background: #fff;
  &:last-child {
    margin: 0px 50px 0px 0px;
  }
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    tempSize: selectTemplateSize(designTemplate),
    tempScale: selectTempScale(designTemplate),
    activeSlide: selectActiveSlide(designTemplate),
  };
};

PresentSlideList.defaultProps = {
  columnsPerScreen: 5, //display no of items on visible screen, 5 for desktop
};

export default connect(mapStateToProps, null)(PresentSlideList);
