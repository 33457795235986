export function buildingTheme() {
  return function (state) {
    return {
      ...state,
      activeField: -1,
      selectedFieldType: "",
      buildingTheme: {
        status: !state.buildingTheme.status,
        updatingTheme: false,
        selectedIndexToBeUpdated: null,
      },
    };
  };
}

// loading theme from server
export function setThemeInfo() {
  return function (state, action) {
    return {
      ...state,
      activeField: -1,
      selectedFieldType: "",
      themeBuilder: action.payload,
      buildingTheme: {
        status: !state.buildingTheme.status,
        updatingTheme: true,
        selectedIndexToBeUpdated: null,
      },
    };
  };
}

export function updateThemeBuilderFields() {
  return function (state, action) {
    if (action.category === "listContent") {
      return {
        ...state,
        themeBuilder: {
          ...state.themeBuilder,
          [action.category]: {
            ...state.themeBuilder[action.category],
            [action.key]: action.value,
          },
          ["listContentWithBackground"]: {
            ...state.themeBuilder["listContentWithBackground"],
            [action.key]: action.value,
          },
        },
      };
    } else {
      return {
        ...state,
        themeBuilder: {
          ...state.themeBuilder,
          [action.category]: {
            ...state.themeBuilder[action.category],
            [action.key]: action.value,
          }
        }
      };
    }
  };
}

export function createTheme() {
  return function (state, action) {
    return {
      ...state,
      themes: [
        ...state.themes,
        {
          ...state.themeBuilder,
          name: action.name,
          id: action.key,
        },
      ],
    };
  };
}

export function updateTheme() {
  return function (state, action) {
    return {
      ...state,
      themes: state.themes.map((theme, index) =>
        index === action.index ? state.themeBuilder : theme
      ),
    };
  };
}

export function changeSlideTheme() {
  return function (state, action) {
    const {themes, template} = state;
    const {applyTo, selectedTheme} = action;
    const {themeID, prevValue} = selectedTheme;
    const themeIndex = themes.findIndex((item) => item.id === themeID);
    const applyThemeProps = (field) => {
      if (field.type === "text" && !field.styles?.disableApplyingTheme) {
        return {
          ...field,
          ["styles"]: {
            ...field.styles,
            fontFamily: themes[themeIndex][field.subtype]?.fontFamily,
            color1: themes[themeIndex][field.subtype]?.color1,
            bg1: themes[themeIndex][field.subtype]?.bg1,
            letterSpacing: themes[themeIndex][field.subtype]?.letterSpacing,
            lineHeight: themes[themeIndex][field.subtype]?.lineHeight,
            bulletColor: themes[themeIndex][field.subtype]?.bulletColor
            // themes[themeIndex][field.subtype]?.bg,
            // color2: themes[themeIndex][field.subtype].color2,
          },
          // dont' merge with chart & table, they don't have  subtype
          font: {
            fontFamily: themes[themeIndex][field.subtype]?.fontFamily,
            src: themes[themeIndex][field.subtype]?.src,
            // fontType: selectedFontType.fontType,
            format: themes[themeIndex][field.subtype]?.format,
            custom: themes[themeIndex][field.subtype]?.custom,
          },
        };
      } else if (field.type === "shape" && !field.styles?.disableApplyingTheme) {
        if (field.subtype === "line") {
          return {
            ...field,
            ["styles"]: {
              ...field.styles,
              color1: themes[themeIndex].shape?.lineColor,
            },
          };
        } else {
          return {
            ...field,
            ["styles"]: {
              ...field.styles,
              color1: themes[themeIndex].shape.color1,
              color2: themes[themeIndex].shape.color2,
              stroke: themes[themeIndex].shape.stroke,
            },
          };
        }
      } else if (field.type === "chart") {
        const chartStyles = {
          categoryColor: themes[themeIndex]?.chart.labelColor,
          legendColor: themes[themeIndex]?.chart.legendColor,
          measurementColor: themes[themeIndex]?.chart.labelColor,
          valueColor: themes[themeIndex]?.chart.valueColor,
          gridColor: themes[themeIndex]?.chart.gridColor,
          fontFamily: themes[themeIndex]?.chart.fontFamily,
          background: themes[themeIndex]?.chart.background,
          showBackground: themes[themeIndex]?.chart.showBackground,
          axisColor: themes[themeIndex]?.chart.axisColor,
        };
        const fontInfo = {
          fontFamily: themes[themeIndex]["chart"]?.fontFamily,
          src: themes[themeIndex]["chart"]?.src,
          format: themes[themeIndex]["chart"]?.format,
          custom: themes[themeIndex]["chart"]?.custom,
        };
        if (field.subtype === "column-chart" || field.subtype === "bar-chart") {
          return {
            ...field,
            colorSet: field.colorSet.map(
              (color, i) => themes[themeIndex].chart.colorSet[i]
            ),
            ["styles"]: {...field.styles, ...chartStyles},
            font: fontInfo,
          };
        } else if (
          field.subtype === "stacked-area-chart" ||
          field.subtype === "line-chart" ||
          field.subtype === "area-chart"
        ) {
          return {
            ...field,
            colorSet: field.colorSet.map(
              (color, i) => themes[themeIndex].chart.colorSet[i]
            ),
            ["styles"]: {...field.styles, ...chartStyles},
            font: fontInfo,
          };
        } else if (field.subtype === "stacked-column-chart") {
          return {
            ...field,
            colorSet: field.colorSet.map(
              (color, i) => themes[themeIndex].chart.colorSet[i]
            ),
            ["styles"]: {...field.styles, ...chartStyles},
            font: fontInfo,
          };
        } else if (field.subtype === "pie" || field.subtype === "donut") {
          return {
            ...field,
            colorSet: field.colorSet.map(
              (color, i) => themes[themeIndex].chart.colorSet[i]
            ),
            ["styles"]: {...field.styles, ...chartStyles},
            font: fontInfo,
          };
        }
      } else if (field.type === "table") {
        return {
          ...field,
          ["styles"]: {
            ...field.styles,
            headerBg: themes[themeIndex]?.table.headerBg,
            headerColor: themes[themeIndex]?.table.headerColor,
            fontFamily: themes[themeIndex]?.table.fontFamily,
            color: themes[themeIndex]?.table.color,
            borderColor: themes[themeIndex]?.table.borderColor,
            background: themes[themeIndex]?.table.background,
            stripeBackground: themes[themeIndex]?.table.stripeBackground,
          },
          font: {
            fontFamily: themes[themeIndex]["table"]?.fontFamily,
            src: themes[themeIndex]["table"]?.src,
            format: themes[themeIndex]["table"]?.format,
            custom: themes[themeIndex]["table"]?.custom,
          },
        };
      } else {
        return {
          ...field,
        };
      }
    };
    
    return {
      ...state,
      template: template && template.map((item, index) => {
        if (item.id === action.slideID && applyTo === "single") {
          const updateFields = Object.entries(item.fields).map(([id, field]) =>
            applyThemeProps(field)
          );
          return {
            ...item,
            fields: updateFields.reduce(
              (acc, item) => ({...acc, [item.key]: item}),
              {}
            ),
            bg: themes[themeIndex].bg,
            theme: themeID,
          };
        } else if (applyTo === "all") {
          const updateFields = Object.entries(item.fields).map(([id, field]) =>
            applyThemeProps(field)
          );
          return {
            ...item,
            fields: updateFields.reduce(
              (acc, item) => ({...acc, [item.key]: item}),
              {}
            ),
            bg: themes[themeIndex].bg,
            theme: themeID,
          };
        } else if (
          applyTo === "selected-theme" &&
          item.theme === prevValue.themeID
        ) {
          const updateFields = Object.entries(item.fields).map(([id, field]) =>
            applyThemeProps(field)
          );
          return {
            ...item,
            fields: updateFields.reduce(
              (acc, item) => ({...acc, [item.key]: item}),
              {}
            ),
            bg: themes[themeIndex].bg,
            theme: themeID,
          };
        } else {
          return {
            ...item,
          };
        }
      }),
      activeTheme: themeID,
    };
  };
}

export function updateThemeTitle() {
  return function (state, action) {
    return {
      ...state,
      themeBuilder: {
        ...state.themeBuilder,
        name: action.name,
      },
    };
  };
}

export function editTheme() {
  return function (state, action) {
    const themeIndex = state.themes.findIndex(
      (themes) => themes.id === action.id
    );
    return {
      ...state,
      buildingTheme: {
        status: true,
        updatingTheme: true,
        selectedIndexToBeUpdated: themeIndex,
      },
      themeBuilder: state.themes[themeIndex],
    };
  };
}

export function cloneTheme() {
  return function (state, action) {
    const themeIndex = state.themes.findIndex(
      (themes) => themes.id === action.id
    );
    return {
      ...state,
      themes: [
        ...state.themes,
        {
          ...state.themes[themeIndex],
          name: state.themes[themeIndex].name + " Clone",
          id: action.newSlideID,
        },
      ],
    };
  };
}

export function deleteTheme() {
  return function (state, action) {
    return {
      ...state,
      themes: state.themes.filter((theme) => theme.id !== action.id),
    };
  };
}

export function applyToAllTextType() {
  return function (state, action) {
    return {
      ...state,
      themeBuilder: {
        ...state.themeBuilder,
        ["heading"]: {
          ...state.themeBuilder["heading"],
          [action.key]: action.value,
        },
        ["subheading"]: {
          ...state.themeBuilder["subheading"],
          [action.key]: action.value,
        },
        ["normalText"]: {
          ...state.themeBuilder["normalText"],
          [action.key]: action.value,
        },
        ["smallText"]: {
          ...state.themeBuilder["smallText"],
          [action.key]: action.value,
        },
        ["button"]: {
          ...state.themeBuilder["button"],
          [action.key]: action.value,
        },
        ["bulletContent"]: {
          ...state.themeBuilder["bulletContent"],
          [action.key]: action.value,
        },
        ["listContent"]: {
          ...state.themeBuilder["listContent"],
          [action.key]: action.value,
        },
        ["listContentWithBackground"]: {
          ...state.themeBuilder["listContentWithBackground"],
          [action.key]: action.value,
        },
        ["chart"]: {
          ...state.themeBuilder["chart"],
          [action.key]: action.value,
        },
        ["table"]: {
          ...state.themeBuilder["table"],
          [action.key]: action.value,
        },
      },
    };
  };
}

export function createThemeFromSocket() {
  return function (state, action) {
    return {
      ...state,
      themes: [...state.themes, action.payload],
    };
  };
}

export function updateThemeFromSocket() {
  return function (state, action) {
    return {
      ...state,
      themes: state.themes.map((theme) =>
        theme.id === action.payload.id ? action.payload : theme
      ),
    };
  };
}
