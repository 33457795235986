import styled from "styled-components";
import {black1} from "../../oat-color-variables";
import { paleBlue } from "../../oat-user-dashboard/userDashboardStyles";

export const Dot = styled.div`
  border-radius: 50%;
  background: #5090f6cf;
  width: 10px;
  height: 10px;
  visibility: hidden;
`;

export const Option = styled.div`
  color: ${black1};
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 6px;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 2px 10px;
  max-height: 35px;
  p {
    width: 100%;
  }
  &:hover {
    background: ${paleBlue};
  }
  &:hover ${Dot} {
    visibility: visible;
  }
`;
