import {EMBED_VIDEO_LINK} from "../actionTypes";

export const dispatchEmbedVideoLink = ({
  slideID,
  key,
  link,
  selectFieldOnCreate = true,
  videoBy,
  uploaded = false,
  format
}) => {
  return {
    type: EMBED_VIDEO_LINK,
    slideID,
    key,
    link,
    selectFieldOnCreate,
    videoBy,
    uploaded,
    format
  };
};
