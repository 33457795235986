import React, {useRef} from "react";
import {detectClickOutside} from "../../../../oat-ui";
import styled from "styled-components";
import {createPortal} from "react-dom";

export const optionDrawerHeight = 500;
export const defaultDrawerHeight = 290;

const MobileDrawer = ({open, height, onClose, children}) => {
  const drawerRef = useRef();

  detectClickOutside(drawerRef, (e) => {
    if (open) {
      onClose();
      e.stopPropagation();
    }
  });

  return createPortal(
    <>
      <BackLayer
        style={{
          width: "100vw",
          height: open ? "100vh" : 0,
        }}
      />
      <Layout ref={drawerRef} style={{height: open ? height : 0, zIndex: 1111}}>
        {children}
      </Layout>
    </>,
    document.getElementById("bottomUp-drawer")
  );
};

const Layout = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  color: ${({theme}) => theme.deskPrimaryTxt};
  box-sizing: border-box;
  font-size: 1.1rem;
  transition: all 0.4s ease;
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .drawer-container {
    height: 100%;
    margin-bottom: 32px;
  }
`;

const BackLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;

export default MobileDrawer;
