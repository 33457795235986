import React, {useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {applyTextEffects} from "../../../../../../store/actions/fields/text.action";
import {textShadowEffects} from "../../shared/textShadowEffects";
import {Accordion, Expand, WrapBox} from "../common/AccordionStyles";
import {Title} from "../../shared/reuseStyles/Title";
import SelectedTextEffects from "./SelectedTextEffects";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {selectPageTheme} from "../../../../../../store/selectors/layout/layout.selector";

const DisplayItem = ({onApply, item, layoutTheme}) => {
  const handleClick = () => {
    onApply(item?.effects);
  };
  return (
    <Effect
      onClick={handleClick}
      layoutTheme={layoutTheme}
      style={{
        ...item?.style,
        WebkitTextStroke:
          layoutTheme === "light" && item?.id.includes("stroke")
            ? "1px #434242"
            : item?.style?.WebkitTextStroke,
      }}
    >
      Hello
    </Effect>
  );
};
const TextEffects = ({
  effects,
  storeFieldUndoRedo,
  slideID,
  fieldID,
  applyTextEffects,
  updateFieldCollaboration,
  layoutTheme,
}) => {
  const [openBox, setOpenBox] = useState(false);
  const windowSize = getWindowSize();

  const handleClick = () => {
    setOpenBox(!openBox);
  };

  const selectTextEffects = async (effect) => {
    const active = {slideID, fieldID};
    await applyTextEffects(active, effect);
    storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  return (
    <>
      {windowSize.width >= 850 ? (
        <Accordion onClick={handleClick}>
          <Title>Effects</Title>
          <Expand>{openBox ? "-" : "+"}</Expand>
        </Accordion>
      ) : undefined}
      {openBox || windowSize.width < 850 ? (
        <>
          <WrapBox
            style={{
              padding: windowSize.width >= 850 ? "15px 0 15px" : "0 0 5px",
            }}
          >
            <Wrapper>
              {textShadowEffects &&
                textShadowEffects.map((item, i) => {
                  return (
                    <DisplayItem
                      key={i}
                      item={item}
                      onApply={selectTextEffects}
                      layoutTheme={layoutTheme}
                    />
                  );
                })}
            </Wrapper>
          </WrapBox>
          <SelectedTextEffects type={effects ? effects?.id : ""} />
        </>
      ) : undefined}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  font-size: 32px;
  padding: 0 1.5rem 1rem 1rem;
  @media only screen and (max-width: 849px) {
    padding: 0.5em 0.5rem 0.9rem 1rem;
  }
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8d91dfff; /* Thumb color */
  }
  &::-webkit-scrollbar-track {
    background: #dedede; /* Track color */
  }
`;

const Effect = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 100px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  font-size: 30px;
  margin-right: 10px;
  background: ${({theme, layoutTheme}) =>
    layoutTheme === "light" ? theme.deskSecondaryBg2 : theme.deskSecondaryBg2};
  border: ${({layoutTheme}) =>
    layoutTheme === "light" ? "1px solid #dadada" : undefined};
`;

const mapStateToProps = (state) => {
  return {
    fieldID: selectActiveField(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
    layoutTheme: selectPageTheme(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  applyTextEffects,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(TextEffects);
