import React, {useState, useRef, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {detectClickOutside} from "../../../../../../../oat-ui";
import {setFieldToAlignPosition} from "../../../../../../store/actions/fields/common.action";
import {Wrapper, Options} from "./TooltipSelectionHoc";
import {alignmentTypes} from "../../../../editingPanel/panelTypes/desktop/common/LayoutPosition";
import {Option} from "../../../../../../ui/inputs/DropDown";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import { selectPageTheme } from "../../../../../../store/selectors/layout/layout.selector";
import AlignmentIcons from "../../../../editingPanel/panelTypes/desktop/common/alignment/AlignmentIcons";

const DisplayItem = ({item, onSelect, pageTheme}) => {
  const onSelectItem = () => {
    onSelect(item);
  };

  return (
    <Option onClick={onSelectItem} style={{justifyContent: 'stretch'}}> 
      <div style={{ width: 22, height: 22, marginRight: 12}}>
        <AlignmentIcons type={item?.value} pageTheme={pageTheme} />
      </div>
      {item.display}
    </Option>
  )
};

const TooltipSetPosition = ({
  width,
  height,
  slideID,
  fieldID,
  offset,
  setFieldToAlignPosition,
  onClose,
  pageTheme
}) => {
  const popUpRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState("");
  const active = {slideID, fieldID};

  detectClickOutside(popUpRef, () => onClose());

  const handleItemSelect = async (item) => {
    setSelectedItem(item.value);
    await setFieldToAlignPosition({active, payload: item.value});
    await onClose();
  };

  return (
    <Layout
      ref={popUpRef}
      style={{
        overflow: "hidden",
        transform: `translate(${offset?.x}px, ${offset?.y}px)`,
      }}
    >
      <Wrapper style={{width, height}}>
        <Options>
          {alignmentTypes &&
            alignmentTypes.map((item, i) => (
              <DisplayItem
                key={i}
                item={item}
                onSelect={handleItemSelect}
                selected={selectedItem}
                pageTheme={pageTheme}
              >  
                <div>{item?.display}</div>
              </DisplayItem>
            ))}
        </Options>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4444;
`;

TooltipSetPosition.defaultProps = {
  width: 125,
  height: 150,
  offset: {
    x: -20,
    y: 45,
  },
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    pageTheme: selectPageTheme(designTemplate)
  };
};

export default connect(mapStateToProps, {setFieldToAlignPosition})(
  TooltipSetPosition
);
