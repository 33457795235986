import React, {useState, useEffect, useRef, useCallback} from "react";
import {Spinner} from "../../../../../../oat-ui";
import {columnGap, Grid} from "./ReuseStyles";
import styled from "styled-components";
import {Trash} from "../../../../../../oat-svg-icons";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import { ImageList, WrapImage, WrapDelete, Error, WrapError } from "./styles/userImageStyles";
import LibSubfolder from "./library/LibSubfolder";

const DisplayItem = ({onSelect, onDelete, item, processing}) => {
  // const handleImageDelete = () => {
  //   onDelete(item);
  // };

  const handleSelection = () => {
    onSelect(item);
  };

  return (
    <WrapImage style={{opacity: !processing ? 1 : 0.5}}>
      <img
        src={`https://oat-users.s3.amazonaws.com/${item.image}`}
        onClick={handleSelection}
        loading="lazy"
      />
      {/* <WrapDelete onClick={handleImageDelete}>
        <WrapIcon>{Trash}</WrapIcon>
      </WrapDelete> */}
    </WrapImage>
  );
};

const LibraryImages = ({
  replaceImg,
  onUploadImgByLink,
  onReplaceImgByLink,
  user,
  workspace
}) => {
  const [images, setImages] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [imageWidth, setImageWidth] = useState();
  const [paginationToken, setPaginationToken] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [libraryFolders, setLibraryFolders] = useState([]);
  const [selectedLibTab, setSelectedLibTab] = useState([]);
  const panelRef = useRef();
  const windowSize = getWindowSize();
  const noOfColumns = windowSize.width >= 850 ? 3 : 2;

  useEffect(() => {
    const panelWidth = panelRef.current.offsetWidth;
    setImageWidth((panelWidth - columnGap * 2) / noOfColumns);
  }, [noOfColumns, windowSize.width]);

  useEffect(() => {
    if (error) {
      setError(false);
    }
    if (paginationToken === null && images.length === 0) {
      setFetching(true);
      setInitialLoading(false);
      fetch(`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace}/images?paginationToken=&fetchFolders=false`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setImages([...images, ...data.response.images]);
            setPaginationToken(data.response.paginationToken)
            const libSubfolders = data.response.folders.libraryImagesFolders;
            if (libSubfolders?.length > 0) {
              setLibraryFolders([
                {folderID: "home", folderName: "Home"},
                ...data.response.folders.libraryImagesFolders
              ])
            }
          }
          setFetching(false);
        })
        .catch((error) => {
          setError(true);
          setFetching(false);
        });
      };
  }, [user.username, workspace]);

  const handleSelectImage = (item) => {
    const payload = {
      naturalWidth: item.imgSize.w,
      naturalHeight: item.imgSize.h,
      src: item.image,
      imageBy: 'user',
      imgType: item.format
    };
    if (!replaceImg) {
      onUploadImgByLink(payload);
    } else {
      onReplaceImgByLink(payload);
    }
  };

  const handleDeleteImage = async (item) => {
    if (!processing) {
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/images`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileSize: item.fileSize,
            imgType: item.imgType,
            key: item.key,
          }),
        }
      )
        .then(() => {
          setProcessing(false);
          setImages((images) =>
            images.filter((image) => image.key !== item.key)
          );
        })
        .catch((error) => {
          // logic
        });
    }
  };

  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const observer = useRef();
  const lastItemRef = useCallback((node) => {
    if (initialLoading) {
      return;
    }
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && paginationToken) {
        setInfiniteLoading(true);
        fetch(`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace}/images?paginationToken=${paginationToken}&fetchFolders=false`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              setImages([...images, ...data.response.images]);
              setPaginationToken(data.response.paginationToken)
              setInfiniteLoading(false);
            }
          })
          .catch((error) => {
            setError(true);
          });
      }
    }, { threshold: 0.5 });
    if (node) observer.current.observe(node);
  }, [initialLoading, paginationToken]);

  const onSelectTab = useCallback((value) => {
    setSelectedLibTab(value)
    setFetching(true);
    setInitialLoading(false);
    setError(false);
    
    fetch(value === "home" ? 
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace}/images` : 
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace}/images?paginationToken=&folderID=${value}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setImages(data.response.images);
            setPaginationToken(data.response.paginationToken)
          }
          setFetching(false);
        })
        .catch((error) => {
          setError(true);
          setFetching(false);
        });
  }, [selectedLibTab, images])

  return (
    <Wrapper ref={panelRef}>
      {/* <a href="/admin/library/private" target="_blank">
        Upload Image Here
      </a> */}

      {libraryFolders?.length > 0 ?
        <Tabs style={{width: panelRef.current && panelRef.current.offsetWidth}}>
          {libraryFolders && libraryFolders.map((item, i) => (
            <LibSubfolder
              key={i}
              onSelect={onSelectTab} 
              item={item} 
              active={item.folderID === selectedLibTab}
            />
          ))}
        </Tabs> 
      : undefined}

      {error && !fetching ? (
        <WrapError>
          <Error>Couldn't load images.</Error>
        </WrapError>
      ) : undefined}

      {images?.length === 0 && !fetching ? (
        <Message>There is nothing here.</Message>
      ) : undefined}

      <ImageList>
        {!fetching ? (
          <Grid>
            <div>
              {images &&
                images.map((item, i) => {
                  if ((i + 1) % 3 === 1) {
                    return (
                      <DisplayItem
                        key={i}
                        item={item}
                        onSelect={handleSelectImage}
                        onDelete={handleDeleteImage}
                        imageWidth={imageWidth}
                      />
                    );
                  }
                })}
            </div>
            <div>
              {images &&
                images.map((item, i) => {
                  if ((i + 1) % 3 === 2) {
                    return (
                      <DisplayItem
                        key={i}
                        item={item}
                        onSelect={handleSelectImage}
                        onDelete={handleDeleteImage}
                        imageWidth={imageWidth}
                      />
                    );
                  }
                })}
            </div>
            <div>
              {images &&
                images.map((item, i) => {
                  // if (images.length === i + 1) {
                  //   return <div key={i} ref={lastItemRef} />;
                  // } else
                  if ((i + 1) % 3 === 0) {
                    return (
                      <DisplayItem
                        key={i}
                        item={item}
                        onSelect={handleSelectImage}
                        onDelete={handleDeleteImage}
                        imageWidth={imageWidth}
                      />
                    );
                  }
                })}
            </div>
            <div ref={lastItemRef} />
            <div style={{height: 300}} />
          </Grid>
        ) : (
          <div style={{width: "100%", marginTop: "20%"}}>
            <Spinner />
          </div>
        )}

        {infiniteLoading ? 
          <div style={{width: "100%", margin: "50px 0"}}>
            <Spinner />
          </div> 
        : undefined}
      </ImageList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
    font-size: 1.1rem;
    color: ${({theme}) => theme.deskPrimaryTxt};
  }
`;

const Tabs = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Message = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  margin-top: 20%;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

export default LibraryImages;
