import React, {useState, useRef} from "react";
import {connect} from "react-redux";
import {
  selectActiveWorkSpace,
  selectFolders,
} from "../../../../../store/selectors/userDashboard.selector";
import {WrapItems, Item} from "./folderSectionStyles";
import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import {
  dispatchDeleteFolder,
  onFolderChange,
} from "../../../../../store/actions/folder.action";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {black1, paleBlue4, red4} from "../../../../../../oat-color-variables";
import LibraryLinks from "./LibraryLinks";
import {Lock} from "../../../../../../oat-svg-icons";
import {LockIcon} from "../../../../sidebar/folderTypes/Folder";
import {detectClickOutside} from "../../../../../../oat-ui";
import {handleDeleteFolder} from "../../../../sidebar/folderTypes/func/handleDeleteFolder";

const DisplaySubFolders = ({
  parentFolderID,
  subfolder,
  onSelect,
  onOpenPopper,
  openPopper,
  onClosePopper,
  onDeleteFolder,
}) => {
  const popUpRef = useRef();
  const item = {
    ...subfolder,
    folderID: parentFolderID,
    isSubfolder: true,
    subfolderID: subfolder.folderID,
  };

  const handleSelect = (e) => {
    onSelect(item);
    e.stopPropagation();
  };

  const onSelectOption = (e) => {
    e.stopPropagation();
    onOpenPopper(subfolder.folderID, true);
  };

  const onSelectDelete = () => {
    onDeleteFolder(item);
  };

  detectClickOutside(popUpRef, () => onClosePopper());

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 0 0 32px",
        position: "relative",
      }}
    >
      <p onClick={handleSelect}>{subfolder.folderName}</p>
      <OptionDots onClick={onSelectOption}>
        <div />
        <div />
        <div />
      </OptionDots>

      {openPopper.status &&
      openPopper.folderID === subfolder.folderID &&
      openPopper.isSubfolder ? (
        <FolderActionPopper ref={popUpRef}>
          <div onClick={onSelectDelete} style={{color: red4}}>
            Delete Folder
          </div>
        </FolderActionPopper>
      ) : undefined}
    </div>
  );
};

const DisplayItem = ({
  item,
  onSelect,
  toCreateSubfolder,
  onOpenPopper,
  openPopper,
  onDeleteFolder,
  onClosePopper,
}) => {
  const popUpRef = useRef();
  const payload = {...item, isSubfolder: false};

  const handleSelect = () => {
    onSelect(payload);
  };

  const onSelectOption = (e) => {
    e.stopPropagation();
    onOpenPopper(item.folderID, false);
  };

  const onSelectDelete = () => {
    onDeleteFolder(payload);
  };

  detectClickOutside(popUpRef, () => onClosePopper());

  return (
    <Item style={{position: "relative"}}>
      <ParentFolder>
        <div style={{display: "flex"}}>
          <p onClick={handleSelect}>{item.folderName}</p>
          {item.private ? (
            <LockIcon style={{marginLeft: 18}}>{Lock}</LockIcon>
          ) : undefined}
        </div>

        <OptionDots onClick={onSelectOption}>
          <div />
          <div />
          <div />
        </OptionDots>
      </ParentFolder>
      <div>
        {item.subfolders &&
          item.subfolders.map((subfolder, i) => (
            <DisplaySubFolders
              key={subfolder.folderID}
              parentFolderID={item.folderID}
              subfolder={subfolder}
              onSelect={onSelect}
              onOpenPopper={onOpenPopper}
              openPopper={openPopper}
              onDeleteFolder={onDeleteFolder}
              onClosePopper={onClosePopper}
            />
          ))}
      </div>

      {openPopper.status &&
      openPopper.folderID === item.folderID &&
      !openPopper.isSubfolder ? (
        <FolderActionPopper ref={popUpRef}>
          <div onClick={toCreateSubfolder}>Create Subfolder</div>
          <div onClick={onSelectDelete} style={{color: red4}}>
            Delete Folder
          </div>
        </FolderActionPopper>
      ) : undefined}
    </Item>
  );
};

const MobileFolders = ({
  username,
  folders,
  workspaceID,
  onFolderChange,
  close,
  dispatchDeleteFolder,
  onInitToCreateFolder,
}) => {
  let navigate = useNavigate();
  let params = useParams();
  const [folderType, setFolderType] = useState("designs");
  const [toCreateSubfolder, setToCreateSubfolder] = useState(false);
  const [openActionsPopper, setOpenActionsPopper] = useState({
    status: false,
    folderID: null,
    isSubfolder: false,
  });
  const [deleting, setDeleting] = useState({
    folderID: null,
    status: false,
    isSubfolder: false,
  });

  const handleSelect = async (item) => {
    const payload = {
      username,
      workspaceID,
      folderID: item.isSubfolder ? item.subfolderID : item.folderID,
      folderName: item.folderName,
    };
    await onFolderChange(payload);
    close();
    navigate(`/dashboard/${workspaceID}/designs/${item.isSubfolder ? item.subfolderID : item.folderID}`);
  };

  const changeToLibrary = () => {
    setFolderType("library");
  };

  const changeToDesigns = () => {
    setFolderType("designs");
  };

  const openCreateSubfolderModal = () => {
    onInitToCreateFolder({
      folderID: openActionsPopper.folderID,
      folderType,
      isSubfolder: true,
    });
    close();
  };

  const openCreateFolderModal = () => {
    onInitToCreateFolder({
      folderID: null,
      folderType,
      isSubfolder: false,
    });
    close();
  };

  const handleFolderDelete = async (selectedFolder) => {
    if (!deleting.status) {
      const {folderID, subfolderID, isSubfolder} = selectedFolder;

      if (!isSubfolder) {
        setDeleting({
          folderID,
          status: true,
          isSubfolder: false,
        });
      } else {
        setDeleting({
          folderID: subfolderID,
          status: true,
          isSubfolder: true,
        });
      }

      const payload = {
        folderID,
        folderType,
        subfolderID,
      };
      await handleDeleteFolder({
        workspaceID,
        username,
        payload,
        selectedFolder,
      })
        .then((data) => {
          if (data.status === "success") {
            dispatchDeleteFolder({
              ...payload,
              isSubfolder,
            });

            if (params.folderID === folderID) {
              navigate(`/dashboard/${workspaceID}/designs/home`);
            } else if (params.folderID === subfolderID) {
              navigate(`/dashboard/${workspaceID}/designs/home`);
            }

            setDeleting({
              folderID: null,
              status: false,
              isSubfolder: null,
              error: {status: false, message: null},
            });

            close();
          } else {
            setDeleting({
              folderID: null,
              status: false,
              isSubfolder: null,
              error: {status: true, message: data.message},
            });
          }
        })
        .catch((err) => {
          setDeleting({
            folderID: subfolderID,
            status: true,
            isSubfolder: true,
            error: {status: true, message: "Something went wrong"},
          });
        });
    }
  };

  const openActionPopper = (folderID, isSubfolder) => {
    setOpenActionsPopper({
      status: true,
      folderID,
      isSubfolder,
    });
  };

  const closeActionPopper = () => {
    setOpenActionsPopper({
      status: false,
      folderID: null,
      isSubfolder: null,
    });
  };


  // const item = {
  //   ...subfolder,
  //   folderID: parentFolderID,
  //   isSubfolder: true,
  //   subfolderID: subfolder.folderID,
  // };

  const onRedirectHomeDashboard = async(e) => {
    const payload = {
      username,
      workspaceID,
      folderID: 'home',
      folderName: 'Home',
    };
    await onFolderChange(payload);
    close();
    navigate(`/dashboard/${workspaceID}/designs/home`);
  };

  return (
    <Layout>
      <FolderTypes>
        <Type active={folderType === "designs"} onClick={changeToDesigns}>
          Designs
        </Type>
        <Type active={folderType === "library"} onClick={changeToLibrary}>
          Library
        </Type>
      </FolderTypes>
      <WrapItems
        style={{
          opacity: deleting.status ? 0.5 : 1,
          pointerEvents: deleting.status ? "none" : undefined,
        }}
      >
        {folderType === "designs" ? 
          <WrapNewFolder>
            <div onClick={openCreateFolderModal}>New Folder</div>
          </WrapNewFolder> 
        : undefined}

        {folderType === "designs" ? 
          <p onClick={onRedirectHomeDashboard} style={{fontSize: '15px', marginBottom: 12}}>
            Home
          </p> 
        : undefined}

        {folderType === "designs" &&
          folders.designs &&
          folders.designs.map((item) => (
            <DisplayItem
              item={item}
              key={item.folderID}
              onSelect={handleSelect}
              toCreateSubfolder={openCreateSubfolderModal}
              onOpenPopper={openActionPopper}
              openPopper={openActionsPopper}
              onDeleteFolder={handleFolderDelete}
              onClosePopper={closeActionPopper}
            />
          ))}

        {folderType === "library" ? <LibraryLinks close={close} /> : undefined}
      </WrapItems>
    </Layout>
  );
};

const Layout = styled.div`
  display; flex;
  flex-direction: column;
`;

const FolderTypes = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const Type = styled.div`
  background: ${({active}) => (active ? paleBlue4 : "inherit")};
  border-radius: 22px;
  color: ${black1};
  font-size: 0.95rem;
  margin: 0 12px 0 0;
  padding: 8px 22px;
  box-sizing: border-box;
  &:first-child {
    margin: 0 12px 0 0;
  }
`;

export const Option = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: left 1.3s ease;
  cursor: pointer;
  padding: 14px 8px;
  background: ${({theme}) => theme.deskPrimaryBg2};
  span {
    font-size: inherit;
    font-weight: 600;
  }
`;

const ParentFolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionDots = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  div {
    width: 4px;
    height: 4px;
    background: #999999;
    margin-bottom: 3px;
    border-radius: 50%;
  }
`;

const FolderActionPopper = styled.div`
  position: absolute;
  top: 0px;
  right: 30px;
  width: 160px;
  height: auto;
  background: #fff;
  box-shadow: 0px 0px 2px 0.5px #cfcfcf;
  border-radius: 12px;
  animation: bouncy 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  z-index: 9;
  div {
    margin: 12px;
    padding: 3px 5px;
  }
  @-webkit-keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const WrapNewFolder = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.9rem;
  color: ${black1};
  margin: 0 12px 12px 0;
`;

const mapStateToProps = (state) => {
  return {
    folders: selectFolders(state.userDashboard),
    username: selectUserInfo(state.authUser).username,
    workspaceID: selectActiveWorkSpace(state.userDashboard).ID,
  };
};

export default connect(mapStateToProps, {onFolderChange, dispatchDeleteFolder})(
  MobileFolders
);
