import React, {useState} from "react";
import styled from "styled-components";
import {Modal} from "../../../../oat-ui";
import {sidebarWidth} from "../../../layoutSizes";
import SlideList from "./SlideList";
import ThemeOptions from "./ThemeOptions";
import {connect} from "react-redux";
import {toggleTemplateSelectionModal} from "../../../store/actions/template/template.action";
import {selectTemplateSelection} from "../../../store/selectors/template/template.selector";
import NewDesignList from "./newDesignList/NewDesignList";
import {selectPageTheme} from "../../../store/selectors/layout/layout.selector";
import {getWindowSize} from "../../../../oat-window-size";

export const panelHeight = window.innerHeight > 1000 ? 900 : window.innerHeight - 150;
const SlideListPanel = ({
  slides,
  toggleTemplateSelectionModal,
  templateSelection,
}) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const windowSize = getWindowSize();

  const displaySlideOptions = () => {
    setDisplayOptions(!displayOptions);
  };

  const handleModalClose = () => {
    toggleTemplateSelectionModal();
  };

  return (
    <Sidebar $sidebarWidth={sidebarWidth}>
      <WrapSidebar>
        <SlideList slides={slides} $sidebarWidth={sidebarWidth} />
      </WrapSidebar>

      <AddDesign onClick={displaySlideOptions}>
        <span>+</span>
        <div style={{fontSize: 16}}>New</div>
      </AddDesign>

      {displayOptions ? (
        <ThemeOptions close={displaySlideOptions} />
      ) : undefined}

      {templateSelection.status ? (
        <Modal
          width={windowSize.width >= 1100 ? 1000 : windowSize.width - 50}
          height={panelHeight} // 600
          close={handleModalClose}
          top={76}
          overflowX="hidden"
          overflowY="hidden"
        >
          <div style={{padding: "15px", boxSizing: "border-box"}}>
            <NewDesignList />
          </div>
        </Modal>
      ) : undefined}
    </Sidebar>
  );
};

const Sidebar = styled.div`
  display: none;
  @media only screen and (min-width: 850px) {
    display: flex;
    flex-direction: column;
    width: ${sidebarWidth}px;
    position: relative;
    overflow-y: scroll;
    background: ${({theme}) => theme.deskPrimaryBg2};
    box-sizing: border-box;
    box-shadow: ${({theme}) => theme.boxShadow1};
    z-index: 9;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const AddDesign = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${({theme}) => theme.deskPrimaryBg2};
  bottom: 0;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border-top: 1px solid ${({theme}) => theme.deskGap};
  color: ${({theme}) => theme.deskPrimaryTxt};
  z-index: 10;
  span {
    font-size: 1.55rem;
    padding: 0 8px;
    color: ${({theme}) => theme.plusButton};
  }
`;

const WrapSidebar = styled.div`
  max-width: 100%;
  height: 100%;
  z-index: 1;
  overflow: scroll;
  padding: 0 0 1.7rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  @media only screen and (max-width: 849px) {
    display: none;
  }
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    mode: selectPageTheme(designTemplate),
    templateSelection: selectTemplateSelection(designTemplate),
  };
};

export default connect(mapStateToProps, {
  toggleTemplateSelectionModal,
})(SlideListPanel);
