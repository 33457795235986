import React, {useEffect} from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../redux/user/authUser";
import {getWindowSize} from "../../oat-window-size/getWindowSize";
import UserDashboardNav from "../components/navigation/UserDashboardNav";
import Sections from "../components/sections/Sections";

const UserDashboardHoc = ({userLoggedIn, children}) => {
  const windowSize = getWindowSize();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.overscrollBehaviorX = "none";
    return () => {
      document.body.style.overflow = "";
      document.body.style.overscrollBehaviorX = "";
    };
  }, []);

  return (
    <div>
      {userLoggedIn ? <UserDashboardNav /> : undefined}

      <div style={{display: "flex"}}>
        {windowSize.width >= 850 ? <Sections /> : undefined}

        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userLoggedIn: selectUserInfo(state.authUser).loggedIn.status,
  };
};

export default connect(mapStateToProps, null)(UserDashboardHoc);
