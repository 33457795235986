import React from "react";
import {useParams} from "react-router";
import {Screenshot} from "../../../../packages/oat-design-template";

const UserScreenshot = () => {
  let params = useParams();
  const {tempID, username} = params;
  return (
    <Screenshot
      api={`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}`}
    />
  );
};

export default UserScreenshot;
