import React from "react";
import {connect, batch} from "react-redux";
import styled from "styled-components";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import DropDown from "../../../../../../ui/inputs/DropDown";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import RangeSlider from "../../shared/inputs/RangeSlider";
import Switch from "../../shared/inputs/Switch";
import {Title} from "../../shared/reuseStyles/Title";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";
import SelectionBox from "../../mobile/ui/SelectionBox";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

export const overlayTypes = [
  {value: "full", display: "Full"},
  {value: "half", display: "Half"},
];

const Overlay = ({
  updateFieldStyles,
  color,
  degree,
  type,
  activeField,
  stableStyles,
  storeFieldUndoRedo,
  slideID,
  enableOverlay,
  updateFieldCollaboration,
}) => {
  const windowSize = getWindowSize();

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField},
      type,
      value,
    });
  };

  const handleEmitValue = () => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const handleSwitch = async () => {
    if (enableOverlay) {
      await handleStyleUpdate("enableOverlay", false);
      await handleEmitValue();
    } else {
      await handleStyleUpdate("enableOverlay", true);
      await handleEmitValue();
    }
  };

  const selectOverlay = async (value) => {
    if (value === "half") {
      await handleStyleUpdate("overlayType", value);
      await handleEmitValue();
    } else {
      await handleStyleUpdate("overlayType", value);
      await handleEmitValue();
    }
  };

  const onColorDebounce = (value) => {
    handleEmitValue();
  };

  const updateOverlayColor = (value) => {
    handleStyleUpdate("overlayColor", value);
  };

  const updateOverlayDegree = (value) => {
    handleStyleUpdate("overlayDegree", value);
  };

  return (
    <>
      <div style={{paddingBottom: !enableOverlay ? "0em" : "2em"}}>
        <Switch
          title="Overlay"
          value={enableOverlay}
          onItemChange={handleSwitch}
          checked={enableOverlay}
        />
      </div>
      {enableOverlay ? (
        <>
          {windowSize.width >= 850 ? (
            <div style={{display: "flex", paddingBottom: "2em"}}>
              <div style={{display: "flex", alignItems: "center", flex: 1}}>
                <Title>Overlay Type</Title>
              </div>
              <WrapDropDown>
                <DropDown
                  onSelect={selectOverlay}
                  selected={type}
                  options={overlayTypes}
                />
              </WrapDropDown>
            </div>
          ) : (
            <div style={{paddingBottom: "2em"}}>
              <SelectionBox
                title="Overlay Type"
                selected={type}
                onSelect={selectOverlay}
                options={overlayTypes}
              />
            </div>
          )}

          <Wrapper>
            <Title>Color</Title>
            <PopoverColorBox
              value={color}
              updateFieldStyles={updateOverlayColor}
              onDebounce={onColorDebounce}
            />
          </Wrapper>

          {type === "half" ? (
            <div>
              <RangeSlider
                title="Position"
                value={degree}
                max={360}
                min={0}
                step={1}
                stableValue={stableStyles?.overlayDegree}
                onItemChange={updateOverlayDegree}
                fieldType="overlayDegree"
              />
            </div>
          ) : undefined}
        </>
      ) : undefined}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 2em;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  z-index: 100;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateFieldStyles,
    storeFieldUndoRedo,
    updateFieldCollaboration,
  }))
)(Overlay);
