import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import SelectFontSize from "../common/SelectFontSize";
import {WrapColorBox} from "../common/WrapColorStyles";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const EditChartMeasurement = ({
  measurementColor,
  measurementFontSize,
  showMeasurement,
  updateFieldStyles,
  storeFieldUndoRedo,
  slideID,
  fieldID,
  updateFieldCollaboration,
}) => {
  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const handleSocketEmit = () => {
    updateFieldCollaboration();
  };

  const toggleButton = () => {
    if (!showMeasurement) {
      handleStyleUpdate("showMeasurement", true);
      handleSocketEmit();
    } else if (showMeasurement) {
      handleStyleUpdate("showMeasurement", false);
      handleSocketEmit();
    }
    storeFieldUndoRedo();
  };

  const updateFontSize = (value) => {
    handleStyleUpdate("measurementFontSize", Number(value));
    handleSocketEmit();
  };

  const updateMeasurementColor = (value) => {
    handleStyleUpdate("measurementColor", value);
  };

  const handleDebounced = (value) => {
    handleSocketEmit();
    storeFieldUndoRedo();
  };

  return (
    <>
      <div style={{paddingBottom: showMeasurement ? "2em" : 0}}>
        <Switch
          title="Show Measurements"
          value={showMeasurement}
          onItemChange={toggleButton}
          checked={showMeasurement}
        />
      </div>
      {showMeasurement ? (
        <>
          <div style={{paddingBottom: "2rem"}}>
            <SelectFontSize
              value={Math.round(measurementFontSize)} // * tempScale
              onSelect={updateFontSize}
            />
          </div>

          <div style={{display: "flex"}}>
            <Title>Color</Title>
            <WrapColorBox>
              <PopoverColorBox
                value={measurementColor}
                updateFieldStyles={updateMeasurementColor}
                onDebounce={handleDebounced}
              />
            </WrapColorBox>
          </div>
        </>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditChartMeasurement);
