// Temporarily save template design
export const CHANGE_PAGE_THEME = "CHANGE_PAGE_THEME";

// Background
export const SET_SINGLE_BG_COLOR = "SET_SINGLE_BG_COLOR";
export const SET_GRADIENT_BG_PROPS = "SET_GRADIENT_BG_PROPS";
export const SELECT_BACKGROUND = "SELECT_BACKGROUND";

// Template
export const FETCH_TEMPLATE_INIT = "FETCH_TEMPLATE_INIT";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_ERR = "FETCH_TEMPLATE_ERR";
export const SET_TEMP_SCALE = "SET_TEMP_SCALE";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const EDIT_TEMPLATE_NAME = "EDIT_TEMPLATE_NAME";
export const SET_USER_TEMPLATE_ID = "SET_USER_TEMPLATE_ID";
export const TOGGLE_TEMPLATE_SELECTION_MODAL =
  "TOGGLE_TEMPLATE_SELECTION_MODAL";
export const SET_SEARCHED_TEMPLATES = "SET_SEARCHED_TEMPLATES";
export const CHANGE_TEMP_VISIBILITY = "CHANGE_TEMP_VISIBILITY";
export const SET_TEMP_PASSCODE = "SET_TEMP_PASSCODE";
export const MANAGE_TEMPLATE_NOTIFICATION = "MANAGE_TEMPLATE_NOTIFICATION";
export const SHARE_AS_GUEST = "SHARE_AS_GUEST";
export const UPDATE_GUEST_ACCESS_LEVEL = "UPDATE_GUEST_ACCESS_LEVEL";
export const SET_GUEST_LIST = "SET_GUEST_LIST";
export const CANVAS_SIZE = "CANVAS_SIZE";
export const SET_USED_FONTS = "SET_USED_FONTS";

// Template List
export const TEMPLATE_LIST_INIT = "TEMPLATE_LIST_INIT";
export const TEMPLATE_LIST_SUCCESS = "TEMPLATE_LIST_SUCCESS";
export const TEMPLATE_LIST_ERR = "TEMPLATE_LIST_ERR";
export const DESIGN_LIST_INIT = "DESIGN_LIST_INIT";
export const DESIGN_LIST_SUCCESS = "DESIGN_LIST_SUCCESS";
export const DESIGN_LIST_ERR = "DESIGN_LIST_ERR";
export const CREATE_DESIGN_SWITCH_TAB = "CREATE_DESIGN_SWITCH_TAB";

export const DRAGGING_FIELD = "DRAGGING_FIELD";
export const RESIZING_FIELD = "RESIZING_FIELD";

export const SET_ACTIVE_FIELD = "SET_ACTIVE_FIELD";
export const DESELECT_ACTIVE_FIELD = "DESELECT_ACTIVE_FIELD";
export const UPDATE_FIELD_POSITION = "UPDATE_FIELD_POSITION";
export const UPDATE_FIELD_SIZE = "UPDATE_FIELD_SIZE";
export const UPDATE_FIELD_STYLES = "UPDATE_FIELD_STYLES";

// Group Selection
export const UPDATE_GROUP_OFFSET = "UPDATE_GROUP_OFFSET";
export const UPDATE_GROUP_SIZE = "UPDATE_GROUP_SIZE";
export const GET_ORIGINAL_OFFSET = "GET_ORIGINAL_OFFSET";
export const INTERCEPT_GROUP_SELECTION = "INTERCEPT_GROUP_SELECTION";
export const STORE_GROUP_FIELDS_INFO_UNDO_REDO =
  "STORE_GROUP_FIELDS_INFO_UNDO_REDO";

export const UPDATE_SINGLE_COLOR = "UPDATE_SINGLE_COLOR";

// Icon
export const CREATE_ICON = "CREATE_ICON";
export const UPDATE_ICON_COLORS = "UPDATE_ICON_COLORS";

// Chart
export const CREATE_CHART = "CREATE_CHART";
export const UPDATE_SINGLE_COLUMN_BAR_CHART = "UPDATE_SINGLE_COLUMN_BAR_CHART";
export const UPDATE_PIE_CHART = "UPDATE_PIE_CHART";
export const UPDATE_DYNAMIC_COLUMNS_HEADER = "UPDATE_DYNAMIC_COLUMNS_HEADER";
export const UPDATE_DYNAMIC_COLUMNS_DATA = "UPDATE_DYNAMIC_COLUMNS_DATA";
export const CREATE_CHART_COLUMN = "CREATE_CHART_COLUMN";
export const REMOVE_DYNAMIC_COLS_AND_ROWS = "REMOVE_DYNAMIC_COLS_AND_ROWS";
export const CREATE_CHART_ROW = "CREATE_CHART_ROW";
export const REMOVE_ROW_FIXED_COL = "REMOVE_ROW_FIXED_COL";
export const UPDATE_SINGLE_COLOR_CHART = "UPDATE_SINGLE_COLOR_CHART";
export const UPDATE_CHART_COLOR_SET = "UPDATE_CHART_COLOR_SET";
export const UPDATE_CHART_INFO = "UPDATE_CHART_INFO";
export const DELETE_CHART_ROW = "DELETE_CHART_ROW";
export const DELETE_CHART_COLUMN = "DELETE_CHART_COLUMN";
export const CHANGE_CHART_TYPE = "CHANGE_CHART_TYPE";
export const VIEW_CHART_INFO = "VIEW_CHART_INFO";

// Common
export const FETCH_LIBRARY_FONTS_SUCCESS = "FETCH_LIBRARY_FONTS_SUCCESS";
export const ADD_LIBRARY_FONT = "ADD_LIBRARY_FONT";
export const SELECT_FONT_FAMILY = "SELECT_FONT_FAMILY";
export const SELECT_FONT_TYPE = "SELECT_FONT_TYPE";
export const UPDATE_MULTIPLE_FIELD_STYLES = "UPDATE_MULTIPLE_FIELD_STYLES";
export const STORE_FIELD_AS_ACTIVE_UNDO_REDO =
  "STORE_FIELD_AS_ACTIVE_UNDO_REDO";
export const STORE_FIELD_AS_HISTORY_UNDO_REDO =
  "STORE_FIELD_AS_HISTORY_UNDO_REDO";
export const GROUP_FIELDS_AS_ONE = "GROUP_FIELDS_AS_ONE";
export const COLLABORATE_GROUP_FIELDS_AS_ONE = "COLLABORATE_GROUP_FIELDS_AS_ONE";
export const APPLY_LINK_IMAGE_ICON = "APPLY_LINK_IMAGE_ICON";

  // Animation
export const SELECT_ANIMATION_TYPE = "SELECT_ANIMATION_TYPE";
export const APPLY_ANIMATION = "APPLY_ANIMATION";
export const PREVIEW_ANIMATION = "PREVIEW_ANIMATION";
export const APPLY_ANIMATION_ON_SLIDE_LOAD = "APPLY_ANIMATION_ON_SLIDE_LOAD";
export const APPLY_ANIMATION_DELAY = "APPLY_ANIMATION_DELAY";
export const SET_CURRENT_ANIMATION_ORDER = "SET_CURRENT_ANIMATION_ORDER";
export const SET_ANIMATION_PREVIEW_OPTION = "SET_ANIMATION_PREVIEW_OPTION";
export const APPLY_ANIMATION_ON_EXIT = "APPLY_ANIMATION_ON_EXIT";

// Layout
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SHOW_EDIT_PANEL = "SHOW_EDIT_PANEL";
export const RESIZE_TEMPLATE = "RESIZE_TEMPLATE";
export const RESET_EDITING_PANEL = "RESET_EDITING_PANEL";
export const UPDATE_EDITING_PANEL = "UPDATE_EDITING_PANEL";
export const SELECT_PANEL = "SELECT_PANEL";
export const TOGGLE_TOOLTIP_POPPER = "TOGGLE_TOOLTIP_POPPER";
export const SET_CANVAS_PANEL_WIDTH = "SET_CANVAS_PANEL_WIDTH";
export const FETCH_LAYOUTS_INIT = "FETCH_LAYOUTS_INIT";
export const FETCH_LAYOUTS_SUCCESS = "FETCH_LAYOUTS_SUCCESS";
export const FETCH_LAYOUTS_ERROR = "FETCH_LAYOUTS_ERROR";
export const SELECT_LAYOUT_TYPE = "SELECT_LAYOUT_TYPE";
export const ADMIN_FILTER_LAYOUTS = "ADMIN_FILTER_LAYOUTS";

// Text
export const UPDATE_TEXT_CONTENT = "UPDATE_TEXT_CONTENT";
export const CREATE_TEXT = "CREATE_TEXT";
export const ENABLE_TEXT_EDIT = "ENABLE_TEXT_EDIT";
export const UPDATE_TEXT_EFFECTS = "UPDATE_TEXT_EFFECTS";
export const APPLY_TEXT_EFFECTS = "APPLY_TEXT_EFFECTS";
export const ADD_TEXT_FAMILY_SOURCE = "ADD_TEXT_FAMILY_SOURCE";
export const ADD_LINK_TO_TEXT = "ADD_LINK_TO_TEXT";
export const CHANGE_HIGHLIGHTED_TEXT_COLOR = "CHANGE_HIGHLIGHTED_TEXT_COLOR";

export const DUPLICATE_FIELD = "DUPLICATE_FIELD";
export const REMOVE_FIELD = "REMOVE_FIELD";
export const COPY_FIELD = "COPY_FIELD";
export const PASTE_FIELD = "PASTE_FIELD";

// shape
export const CREATE_SHAPE = "CREATE_SHAPE";
export const STRAIGHTEN_SHAPE_LINE = "STRAIGHTEN_SHAPE_LINE";

// video
export const EMBED_VIDEO_LINK = "EMBED_VIDEO_LINK";

export const SET_DESIGN_PROOF = "SET_DESIGN_PROOF";

export const REVERT_STYLES = "REVERT_STYLES"; // ???
export const SET_CREATE_PANEL = "SET_CREATE_PANEL"; // ???
export const TO_CREATE_TEXT = "TO_CREATE_TEXT"; // ???
export const REVERT_BG_COLOR = "REVERT_BG_COLOR"; // ?????

export const ZINDEX_TO_FRONT = "ZINDEX_TO_FRONT";
export const ZINDEX_TO_BACK = "ZINDEX_TO_BACK";
export const LOCK_FIELD_POSITION = "LOCK_FIELD_POSITION";

// Image
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const APPLY_IMAGE_FILTER = "APPLY_IMAGE_FILTER";
export const CROP_IMAGE = "CROP_IMAGE";
export const TRANSFORM_IMG_OFFSET = "TRANSFORM_IMG_OFFSET";
export const REPLACE_IMG = "REPLACE_IMG";
export const REPLACE_IMG_STATUS = "REPLACE_IMG_STATUS";

// Slide
export const ADD_NEW_SLIDE = "ADD_NEW_SLIDE";
export const CLONE_SLIDE = "CLONE_SLIDE";
export const DELETE_SLIDE = "DELETE_SLIDE";
export const CHANGE_SLIDE_INDEX = "CHANGE_SLIDE_INDEX";
export const CHANGE_SLIDE_BY_ID = "CHANGE_SLIDE_BY_ID";
export const CHANGE_SLIDE_ID_PRESENT_VIEW = "CHANGE_SLIDE_ID_PRESENT_VIEW";
export const SWITCH_SLIDE_INDEX = "SWITCH_SLIDE_INDEX";
export const TOGGLE_SLIDE_WORKING_STATUS = "TOGGLE_SLIDE_WORKING_STATUS";
export const ADD_SLIDE_NOTES = "ADD_SLIDE_NOTES";
export const UPDATE_SLIDE_TITLE = "UPDATE_SLIDE_TITLE";
export const SET_SLIDE_TRANSITION = "SET_SLIDE_TRANSITION";
export const ADD_NEW_SECTION_GROUP_SLIDES = "ADD_NEW_SECTION_GROUP_SLIDES";
export const SET_SLIDE_SECTION_NAME = "SET_SLIDE_SECTION_NAME";
export const CREATE_SLIDE_FROM_TEMPLATE = "CREATE_SLIDE_FROM_TEMPLATE";
export const TOGGLE_TEMPORARILY_DISABLE_SLIDE_TRANSITION =
  "TOGGLE_TEMPORARILY_DISABLE_SLIDE_TRANSITION";

// Comment
export const ADD_SLIDE_COMMENT = "ADD_SLIDE_COMMENT";
export const UPDATE_SLIDE_COMMENT = "UPDATE_SLIDE_COMMENT";
export const DELETE_SLIDE_COMMENT = "DELETE_SLIDE_COMMENT";
export const RESPOND_TO_THREAD = "RESPOND_TO_THREAD";
export const ON_RESOLVED_ISSUE = "ON_RESOLVED_ISSUE";

// Table
export const CREATE_TABLE = "CREATE_TABLE";
export const CREATE_TABLE_ROW = "CREATE_TABLE_ROW";
export const CREATE_TABLE_COLUMN = "CREATE_TABLE_COLUMN";
export const REMOVE_TABLE_ROW = "REMOVE_TABLE_ROW";
export const REMOVE_TABLE_COLUMN = "REMOVE_TABLE_COLUMN";
export const UPDATE_TABLE_CONTENT = "UPDATE_TABLE_CONTENT";
export const UPDATE_TABLE_HEIGHT = "UPDATE_TABLE_HEIGHT";
export const RESIZE_TABLE = "RESIZE_TABLE";
export const SET_INIT_CELL_SIZES = "SET_INIT_CELL_SIZES";
export const REMOVE_INIT_CELL_SIZES = "REMOVE_INIT_CELL_SIZES";
export const UPDATE_TABLE_SIZE = "UPDATE_TABLE_SIZE";
export const RESIZE_TABLE_COLUMN_CELL = "RESIZE_TABLE_COLUMN_CELL";
export const RESIZE_TABLE_ROW_CELL = "RESIZE_TABLE_ROW_CELL";
export const BATCH_UPDATE_TABLE_ROW_HEIGHT = "BATCH_UPDATE_TABLE_ROW_HEIGHT";
export const TOGGLE_TABLE_ROWS_COLUMNS_DRAWER =
  "TOGGLE_TABLE_ROWS_COLUMNS_DRAWER";
export const ACTIVE_TABLE_CELL = "ACTIVE_TABLE_CELL";
export const CLONE_TABLE_ROW_COLUMN = "CLONE_TABLE_ROW_COLUMN";

// Undo - Redo
export const DISPATCH_UNDO_ITEM = "DISPATCH_UNDO_ITEM";
export const DISPATCH_REDO_ITEM = "DISPATCH_REDO_ITEM";
export const STORE_ACTIVE_UNDO_REDO = "STORE_ACTIVE_UNDO_REDO";
export const STORE_BACKGROUND_UNDO_REDO = "STORE_BACKGROUND_UNDO_REDO";
export const STORE_ACTIONS_UNDO_REDO = "STORE_ACTIONS_UNDO_REDO";

// Theme builder
export const UPDATE_THEME_BUILDER_FIELDS = "UPDATE_THEME_BUILDER_FIELDS";
export const CREATE_THEME = "CREATE_THEME";
export const UPDATE_THEME = "UPDATE_THEME";
export const BUILDING_THEME = "BUILDING_THEME";
export const CHANGE_SLIDE_THEME = "CHANGE_SLIDE_THEME";
export const UPDATE_THEME_NAME = "UPDATE_THEME_NAME";
export const EDIT_THEME = "EDIT_THEME";
export const CLONE_THEME = "CLONE_THEME";
export const DELETE_THEME = "DELETE_THEME";
export const APPLY_TO_ALL_TEXT_TYPE = "APPLY_TO_ALL_TEXT_TYPE";
export const SET_THEME_INFO = "SET_THEME_INFO";

// group section
export const ADD_GROUP_SECTION = "ADD_GROUP_SECTION";
export const DELETE_GROUP_SECTION = "DELETE_GROUP_SECTION";
export const EDIT_GROUP_SECTION_CONTENT = "EDIT_GROUP_SECTION_CONTENT";
export const EDIT_GROUP_SECTION_LINK = "EDIT_GROUP_SECTION_LINK";
export const GROUPED_ITEMS_FOR_COLLABORATION =
  "GROUPED_ITEMS_FOR_COLLABORATION";
export const COLLABORATION_FIELD_CHANGED = "COLLABORATION_FIELD_CHANGED";

// online users
export const SET_USER_ONLINE = "SET_USER_ONLINE";
export const REMOVE_USER_ONLINE = "REMOVE_USER_ONLINE";
export const SET_USER_CONNECTION_ID = "SET_USER_CONNECTION_ID";

export const RESET_STATE = "RESET_STATE";

// Chat
export const SEND_CHAT_TEMPLATE = "SEND_CHAT_TEMPLATE";

// Collaboration
export const CREATE_FIELD_FROM_SOCKET = "CREATE_FIELD_FROM_SOCKET";
export const COLLABORATOR_SELECT_FIELD = "COLLABORATOR_SELECT_FIELD";
export const COLLABORATOR_DESELECT_FIELD = "COLLABORATOR_DESELECT_FIELD";
export const PASTE_FIELD_FROM_SOCKET = "PASTE_FIELD_FROM_SOCKET";
export const CREATE_THEME_FROM_SOCKET = "CREATE_THEME_FROM_SOCKET";
export const UPDATE_THEME_FROM_SOCKET = "UPDATE_THEME_FROM_SOCKET";
export const SET_EXISTING_USERS_ONLINE = "SET_EXISTING_USERS_ONLINE";
export const RESET_FIELD_TO_EMIT_FROM_SOCKET =
  "RESET_FIELD_TO_EMIT_FROM_SOCKET";
export const UPDATE_GROUP_FIELDS_COLLABORATION =
  "UPDATE_GROUP_FIELDS_COLLABORATION";
export const UPDATE_UNDOREDO_FIELDS_OF_COLLABORATION =
  "UPDATE_UNDOREDO_FIELDS_OF_COLLABORATION";
export const DELETE_FIELDS_COLLABORATION = "DELETE_FIELDS_COLLABORATION";
export const UPDATE_FIELD_COLLABORATION = "UPDATE_FIELD_COLLABORATION";
export const ADD_NEW_SLIDE_COLLABORATION = "ADD_NEW_SLIDE_COLLABORATION";

// mobile
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

// smart alignment
export const SET_SMART_ALIGNMENT = "SET_SMART_ALIGNMENT";

// field align
export const SET_FIELD_TO_ALIGN_POSITION = "SET_FIELD_TO_ALIGN_POSITION";

export const INIT_PLAY_GROUND = "INIT_PLAY_GROUND"