import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import ColumnCell from "./ColumnCell";

const TableRows = ({
  item,
  styles,
  fieldSelected,
  tempScale,
  hoverToDeleteRow,
  hoverToDeleteCol,
  enableEditing,
  emitTimesClicked,
  rowIndex,
  onSelectCell,
  onUpdateContent,
  selectedRow,
  activeColumn,
  ratioDiff
}) => {
  const handleContentUpdate = (payload) => {
    onUpdateContent({...payload, rowIndex: rowIndex});
  };

  return (
    <Row
      key={item.row.id}
      style={{
        minHeight: item.row.height,
        fontSize: styles.fontSize / 16 + "em",
      }}
      borderWidth={styles.borderWidth}
      borderColor={styles.borderColor}
      showRowColor={styles.showRowColor}
      showColumnColor={styles.showColumnColor}
      roundedBorder={styles.roundedBorder}
      tempScale={tempScale}
      showBorderEdge={styles.showBorderEdge}
      rowIndex={rowIndex}
      firstIndex={rowIndex === 0}
      header={{
        type: styles.headerType,
        color: styles.headerColor,
        background: styles.headerBg,
      }}
      hoverToDelete={hoverToDeleteRow === rowIndex}
      stripe={{
        enabled: styles.stripeRows,
        color: styles.stripeColor,
        background: styles.stripeBackground,
      }}
      $ratioDiff={ratioDiff}
    >
      {item.columns &&
        item.columns.map((column, colIndex) => (
          <ColumnCell
            key={colIndex}
            colIndex={colIndex}
            fieldSelected={fieldSelected}
            rowIndex={rowIndex}
            column={column}
            styles={styles}
            hoverToDeleteRow={hoverToDeleteRow === rowIndex}
            hoverToDeleteCol={hoverToDeleteCol}
            enableEditing={enableEditing}
            onSelectCell={onSelectCell}
            emitTimesClicked={emitTimesClicked}
            selectedCell={selectedRow && activeColumn === colIndex}
            onUpdateContent={handleContentUpdate}
            lastColumn={item.columns.length - 1 === colIndex}
          />
        ))}
    </Row>
  );
};

const Row = styled.tr`
  position: relative;
  display: flex;
  box-sizing: border-box;
  border-right: ${({borderWidth, borderColor, showBorderEdge}) =>
    showBorderEdge
      ? `${borderWidth}px solid ${borderColor}`
      : `${borderWidth}px solid transparent`};
  border-left: ${({borderWidth, borderColor, showBorderEdge}) =>
    showBorderEdge
      ? `${borderWidth}px solid ${borderColor}`
      : `${borderWidth}px solid transparent`};
  overflow: hidden;
  &:nth-child(odd) {
    td {
      color: ${({stripe, firstIndex}) =>
        !firstIndex && stripe.enabled
          ? `${stripe.color} !important`
          : undefined};
      background: ${({stripe, hoverToDelete, firstIndex}) =>
        !firstIndex && stripe.enabled && !hoverToDelete
          ? `${stripe.background} !important`
          : undefined};
      &:first-child {
        color: ${({stripe, header, firstIndex}) =>
          firstIndex || header.type === "top-left" || header.type === "left"
            ? `${header.color} !important`
            : stripe.enabled
            ? `${stripe.color} !important`
            : undefined};
        background: ${({stripe, header, hoverToDelete, firstIndex}) =>
          (header.type === "top-left" || header.type === "left") &&
          stripe.enabled &&
          !hoverToDelete
            ? `${header.background} !important`
            : undefined};
      }
    }
  }
  &:first-child {
    border-top-left-radius: ${({roundedBorder, $ratioDiff}) =>
      roundedBorder ? 10 * $ratioDiff : "0"}px;
    border-top-right-radius: ${({roundedBorder, $ratioDiff}) =>
      roundedBorder ? 10 * $ratioDiff : "0"}px;
    border-top: ${({borderWidth, tempScale, borderColor, showBorderEdge}) =>
      showBorderEdge
        ? `${borderWidth}px solid ${borderColor}`
        : `${borderWidth}px solid transparent`};
    td {
      border-top: 0px;
    }
  }
  &:last-child {
    border-bottom-left-radius: ${({roundedBorder, $ratioDiff}) =>
      roundedBorder ? 10 * $ratioDiff : "0"}px;
    border-bottom-right-radius: ${({roundedBorder, $ratioDiff}) =>
      roundedBorder ? 10 * $ratioDiff : "0"}px;
    border-bottom: ${({borderWidth, tempScale, borderColor, showBorderEdge}) =>
      showBorderEdge
        ? `${borderWidth}px solid ${borderColor}`
        : `${borderWidth}px solid transparent`};
    td {
      border-bottom: 0px;
    }
  }
  td {
    border-bottom: ${({borderWidth, borderColor, tempScale, showRowColor}) =>
      `${borderWidth}px solid  ${showRowColor ? borderColor : "transparent"}`};
    border-right: ${({borderWidth, borderColor, tempScale, showColumnColor}) =>
      `${borderWidth}px solid ${
        showColumnColor ? borderColor : "transparent"
      }`};
  }
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    tempScale: selectTempScale(designTemplate),
  };
};

export default connect(mapStateToProps, {})(TableRows);
