import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {selectUserInfo} from "../../../../redux/user/authUser";
import {greyText1, paleBlue2, darkGrey1} from "../../../oat-color-variables";
import {Lock, Refresh, Team} from "../../../oat-svg-icons";
import {Spinner} from "../../../oat-ui";
import {
  fetchWorkspaces,
  switchWorkspace,
} from "../../store/actions/workspace.action";
import {
  selectWorkspaceList,
  selecWorkSpaceLoadingStatus,
  selecWorkSpaceError,
  selectWSSwitchingStatus,
  selectActiveWorkSpace,
} from "../../store/selectors/userDashboard.selector";
import {Header, ModalBody, BodyScroll} from "../reuse/dashboardUI/modalStyles";
import {ActiveDot} from "../sidebar/reusableStyles/sidebarItem";

const DisplayItem = ({item, onSelect, disabled, active}) => {
  const handleClick = () => {
    onSelect(item);
  };

  return (
    <Item onClick={handleClick} disabled={disabled}>
      <WorkspaceName>{item.name}</WorkspaceName>
      <ActiveDot style={{position: "absolute", right: 24}} $active={active} />
      {/* <WorkspaceType>{item.type === "team" ? Team : Lock}</WorkspaceType> */}
    </Item>
  );
};

const WorkspaceList = ({
  workspaces,
  user,
  switchWorkspace,
  loading,
  error,
  closeModal,
  workspaceSwitching,
  onCreate,
  activeWorkspaceID,
  fetchWorkspaces,
}) => {
  let navigate = useNavigate();

  const onSelectItem = async (workspace) => {
    if (workspace.ID === activeWorkspaceID) {
      return;
    }
    if (!workspaceSwitching) {
      await switchWorkspace({user: user.username, workspace});
    }
    await closeModal();
    return await navigate(`/dashboard/${workspace.ID}/designs/home`);
  };

  const reload = () => {
    fetchWorkspaces(user.username);
  };

  const initCreateWorkspace = () => {
    onCreate();
  };

  useEffect(() => {
    if (workspaces.length === 0) {
      fetchWorkspaces(user.username);
    }
  }, [workspaces]);

  return (
    <Layout>
      <ModalBody>
        <Header style={{marginBottom: 6}}>Workspaces</Header>

        <BodyScroll height={284}>
          <Wrapper>
            {!loading &&
              workspaces &&
              workspaces.map((workspace) => (
                <DisplayItem
                  key={workspace.ID}
                  active={workspace.ID === activeWorkspaceID}
                  item={workspace}
                  onSelect={onSelectItem}
                  disabled={workspaceSwitching}
                />
              ))}

            {loading ? (
              <WrapSpinner>
                <Spinner />
              </WrapSpinner>
            ) : undefined}
          </Wrapper>
        </BodyScroll>

        {error ? <WrapSvg onClick={reload}>{Refresh}</WrapSvg> : undefined}
      </ModalBody>

      <CreateSection>
        <ToCreateWorkspace onClick={initCreateWorkspace}>
          Create Workspace
        </ToCreateWorkspace>
      </CreateSection>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${darkGrey1};
`;

const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.div`
  width: 100%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 14px;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  background: ${({active}) => (active ? "#d2d7ff" : undefined)};
  opacity: ${({disabled}) => (disabled ? 0.4 : 1)};
  &:hover {
    background: ${paleBlue2};
  }
`;

const WrapSpinner = styled.div`
  margin-top: 45px;
`;

const WrapSvg = styled.div`
  display: flex;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-top: 125px;
  cursor: pointer;
  fill: ${greyText1};
`;

const CreateSection = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
`;

const ToCreateWorkspace = styled.button`
  width: 100%;
  padding: 10px 0;
  background: #d2d7ff;
  border: none;
  font-size: 0.9rem;
  border-radius: 6px;
  color: #575dd3;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
`;

const WorkspaceType = styled.div`
  width: 18px;
  height: 18px;
  fill: #bdbdc0;
`;

const WorkspaceName = styled.div`
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  width: 240px;
`;

const mapStateToProps = (state) => {
  const {userDashboard} = state;
  return {
    workspaces: selectWorkspaceList(userDashboard),
    user: selectUserInfo(state.authUser),
    loading: selecWorkSpaceLoadingStatus(userDashboard),
    error: selecWorkSpaceError(userDashboard),
    activeWorkspaceID: selectActiveWorkSpace(userDashboard).ID,
    workspaceSwitching: selectWSSwitchingStatus(userDashboard).loading,
  };
};

export default connect(mapStateToProps, {switchWorkspace, fetchWorkspaces})(
  WorkspaceList
);
