import React from "react";
import {
  Image,
  ItemName,
  MoreOption,
  WrapTempInfo,
} from "./../userTemplatesStyles";

const TemplateThumbnails = ({item, size, onItemClick, onPopperOpen}) => {
  const timestamp = new Date().getTime();
  return (
    <>
      <Image
        style={{height: size.height}}
        src={`https://oat-users.s3.amazonaws.com/${item.image}?v=${timestamp}`}
        loading="lazy"
      />
      <WrapTempInfo>
        <ItemName style={{flex: 4}} onClick={onItemClick}>
          {item.title}
        </ItemName>
        <MoreOption onClick={onPopperOpen}>
          <div />
          <div />
          <div />
        </MoreOption>
      </WrapTempInfo>
    </>
  );
};

export default TemplateThumbnails;
