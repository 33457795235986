import React from "react";
import styled from "styled-components";
import {Spinner} from "../../../../oat-ui";

const ProcessingOverlay = ({message}) => {
  return (
    <Layout>
      <Container>
        <Message>{message ? message : "Member joining in..."}</Message>
        <div>
          <Spinner />
        </div>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  background: #272838a3;
`;

const Container = styled.div`
  background: #fff;
  width: 320px;
  height: 146px;
  padding: 30px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  margin-top: 120px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    width: 82%;
    height: 140px;
  }
`;

const Message = styled.div`
  font-size: 1rem;
  padding: 0px 20px;
  text-align: center;
`;

export default ProcessingOverlay;
