const PenLightTheme = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    style={{transform: `scale(2.7)`}}
  >
    <defs>
      <linearGradient
        id="y6HArBdaROh24-fill"
        x1="40.71"
        y1="280.17"
        x2="60.53"
        y2="197.03"
        gradientTransform="translate(0 -170)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          id="y6HArBdaROh24-fill-0"
          offset="1%"
          stopColor="rgb(187 54 249)"
        ></stop>
        <stop
          id="y6HArBdaROh24-fill-1"
          offset="100%"
          stopColor="#ffff9c"
        ></stop>
      </linearGradient>
      <linearGradient
        id="y6HArBdaROh24-fill-2"
        x1="83.47"
        y1="248.69"
        x2="70.29"
        y2="191.55"
        href="#y6HArBdaROh24-fill"
      />
    </defs>
    <g>
      <path
        fill="url(#y6HArBdaROh24-fill)"
        d="M62.2,50.1,46.4,57.7a3.3,3.3,0,0,0-1.7,2.1c-1.5,5.8-6.9,27.2-7.1,27.4L53.9,70.9a3.7,3.7,0,0,0,1-3,10.3,10.3,0,0,1,.1-1.7,5.3,5.3,0,0,1,4.1-3.9,5.2,5.2,0,0,1,6.1,5.4A5.3,5.3,0,0,1,62,72.1a4.3,4.3,0,0,1-2.4.4,3.2,3.2,0,0,0-2.7,1.1L40.6,90.3l27.3-7.4a3.1,3.1,0,0,0,2-1.7l7.6-15.8a3.4,3.4,0,0,0-.6-3.7l-11-11A3.2,3.2,0,0,0,62.2,50.1Z"
      />
      <path
        fill="url(#y6HArBdaROh24-fill-2)"
        d="M89.3,57.8l-19-19a3.7,3.7,0,0,0-5.3,0h-.1a3.7,3.7,0,0,0,0,5.3l19,19a3.7,3.7,0,0,0,5.3,0h.1A3.7,3.7,0,0,0,89.3,57.8Z"
      />
    </g>
  </svg>
);

export default PenLightTheme;
