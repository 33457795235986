export function wrapRangeInWordSpan(range, attribute, style) {
  if (
    !range.toString().length && // empty text content
    !range.cloneContents().querySelector("img") // and not an <img>
  ) {
    return; // empty range
  }

  const selection = window.getSelection();

  if (selection.rangeCount > 0) {
    let styleToAdd = '';
    const range = selection.getRangeAt(0);
    const parentElement = range.commonAncestorContainer.parentElement;
    if(attribute === 'font-weight') {
      styleToAdd = `font-weight: ${style}; 'color': f6b6b6ff`;;      
    }

    const commonAncestorContainer = range.commonAncestorContainer;

    // Check if the common ancestor has the specified class
    if (
      commonAncestorContainer?.nodeType === 3 &&
      commonAncestorContainer?.classList?.contains('innerTextStyle')
    ) {
      const existingElement = commonAncestorContainer;
      const existingStyle = existingElement.getAttribute('style') || '';
      existingElement.setAttribute('style', `${existingStyle} ${styleToAdd}`);
      return;
    }
    
  }


  const content = range.extractContents();
  const newParent = document.createElement("span");
  const parentElement = range.commonAncestorContainer.parentElement;
  newParent.classList.add("innerTextStyle");
  newParent.style.fontWeight = parentElement.style.fontWeight;
  newParent.style.fontStyle = parentElement.style.fontStyle;
  newParent.style.color = parentElement.style.color;
  newParent.style[attribute] = style;
  newParent.appendChild(content);
  range.insertNode(newParent);
  // if our content was wrapping .innerTextStyle spans,
  // move their content into a new parent
  // and remove them now as they're empty
  newParent.querySelectorAll(".innerTextStyle").forEach(moveContentBefore);
}

export function moveContentBefore(parent) {
  const iterator = document.createNodeIterator(parent);
  let currentNode;
  // walk through all nodes
  while ((currentNode = iterator.nextNode())) {
    // move them to the grand-parent
    parent.before(currentNode);
  }
  // remove the now empty parent
  parent.remove();
}

export function wrapRangeInNestedWordSpan(range, payload) {
  if (
    !range.toString().length && // empty text content
    !range.cloneContents().querySelector("img") // and not an <img>
  ) {
    return;
  }
  const content = range.extractContents();
  const newParent = document.createElement("span");
  newParent.classList.add("innerTextStyle");

  for (const [key, value] of Object.entries(payload)) {
    newParent.style[key] = value;
  }

  newParent.appendChild(content);
  range.insertNode(newParent);
  newParent.querySelectorAll(".innerTextStyle").forEach(moveContentBefore);
}

export const handleContentChange = async (range, textType) => {
  let updatedValue = "";
  if (
    textType === "bulletContent" ||
    textType === "listContent" ||
    textType === "listContentWithBackground"
  ) {
    updatedValue = range.commonAncestorContainer.parentNode.parentNode.outerHTML;
  } else {
    updatedValue = range.commonAncestorContainer.parentNode.innerHTML
    // range.commonAncestorContainer.outerHTML;
  }
  return updatedValue;
};

export function wrapATagLink(range, payload) {
  const selection = window.getSelection();

  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const commonAncestorContainer = range.commonAncestorContainer;
    const parentElement = range.commonAncestorContainer.parentElement;
    // Check if the common ancestor is a <span> with the specified color
    if (
      commonAncestorContainer.nodeType === 1 &&
      commonAncestorContainer.classList.contains('innerTextStyle')
    ) {
      // Add the <a> tag inside the existing <span>
      const linkElement = document.createElement('a');
      linkElement.href = 'ABC';
      linkElement.textContent = selection.toString();
      commonAncestorContainer.appendChild(linkElement);
    } else {
      // Create a new <span> with the specified color and add the <a> tag inside it
      const newSpan = document.createElement('span');
      // newSpan.style.color = parentElement.style.color;
      newSpan.classList.add("innerTextStyle");

      const linkElement = document.createElement('a');
      linkElement.href = 'ABC';
      linkElement.textContent = selection.toString();
      linkElement.classList.add("innerTextLink");
     
      newSpan.appendChild(linkElement);

      // Replace the selected text with the new <span>
      range.deleteContents();
      range.insertNode(newSpan);
    }
  }


  // if (
  //   !range.toString().length && // empty text content
  //   !range.cloneContents().querySelector("img") // and not an <img>
  // ) {
  //   return;
  // }
  // const content = range.extractContents(); 
  // const newParent = document.createElement("a");
  // newParent.classList.add("innerTextLink");

  // // for (const [key, value] of Object.entries(payload)) {
  //   newParent.href = 'heyo';
  // // }

  // newParent.appendChild(content);
  // range.insertNode(newParent);
  // newParent.querySelectorAll(".innerTextLink").forEach(moveContentBefore);
}
