import React from "react";
import styled from "styled-components";
import {Option, Dot} from "../../../../oat-ui/styles/SelectOption";

const UserTempPopper = ({openModal, onAddProfile}) => {
  const onRenameTitle = () => {
    openModal("rename-template");
  };

  const inviteAsGuest = () => {
    openModal("invite-as-guest");
  };

  const toAnotherWorkspace = () => {
    openModal("to-another-workspace");
  };

  const onConfirmTemplate = () => {
    openModal("confirm-template-delete");
  };

  const addToProfile = () => {
    onAddProfile();
  };

  return (
    <Wrapper>
      <Option onClick={onRenameTitle}>
        <div>Rename Title</div>
        <Dot />
      </Option>
      <Option onClick={inviteAsGuest}>
        <p>Invite Guest</p>
        <Dot />
      </Option>
      <Option onClick={toAnotherWorkspace}>
        <p>Copy To</p>
        <Dot />
      </Option>
      {/* <Option onClick={addToProfile}>
        <p>Add To Profile</p>
        <Dot />
      </Option> */}
      <Option onClick={onConfirmTemplate}>
        <p style={{color: "#ff2f2f"}}>Delete</p>
        <Dot />
      </Option>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 8px 0;
`;

export default UserTempPopper;
