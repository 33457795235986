import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {ButtonSpinner} from "../../../../../oat-ui";
import {openDrawer, closeDrawer} from "../../../../store/actions/layout.action";
import {
  selectActiveSlide,
  selectSlideGroupList,
} from "../../../../store/selectors/template/slide.selector";
import {
  selectTemplate,
  selectTemplateLength,
  selectTemplateName,
  selectTemplateRatio,
  selectTempSourceID,
  selectTemplateSize,
  selectTemplateID,
} from "../../../../store/selectors/template/template.selector";
import {selectTemplateThemes} from "../../../../store/selectors/template/theme.selector";
import SpecialButton from "../../../../ui/inputs/SpecialButton";
import Switch from "../../../editDesign/editingPanel/panelTypes/shared/inputs/Switch";
import {handleImgDownload} from "../../modal/customizeSlides/exporting/handleImgDownload";
import GoBack from "../common/GoBack";
import {optionDrawerHeight} from "../MobileDrawer";

const ExportAsImage = ({
  tempLength,
  tempRatio,
  tempSize,
  user,
  themes,
  slideGroupList,
  tempName,
  template,
  activeSlide,
  tempSourceID,
  tempID,
  closeDrawer,
  openDrawer,
}) => {
  const [processing, setProcessing] = useState(false);
  const [currentSlideOnly, setCurrentSlideOnly] = useState(false);
  const [extention, setExtention] = useState("jpg");

  const onChangeCurrentDesignOnly = () => {
    setCurrentSlideOnly(!currentSlideOnly);
  };

  const handleDownload = () => {
    if (!processing) {
      setProcessing(true);
      const selectedSlide = {
        status: currentSlideOnly,
        index: activeSlide,
      };

      const tempInfo = {
        tempID,
        tempRatio,
        tempSize,
        tempName,
        tempSourceID,
        tempLength,
      };

      handleImgDownload({
        username: user.username,
        template,
        selectedSlide,
        tempInfo,
        extention,
        themes,
        slideGroupList,
        currentSlideOnly,
      }).then(() => {
        setProcessing(false);
        closeDrawer();
      });
    }
  };

  const goBack = () => {
    openDrawer({type: "slide-options", height: optionDrawerHeight});
  };

  return (
    <>
      <GoBack onBackward={goBack} />

      <Layout>
        <div style={{margin: "20px 0"}}>
          <Switch
            title="Current Page"
            value={currentSlideOnly}
            checkValue={currentSlideOnly}
            onItemChange={onChangeCurrentDesignOnly}
            checked={currentSlideOnly}
          />
        </div>

        <div className="wrap-button">
          <SpecialButton onClick={handleDownload}>
            {!processing ? "Download" : <ButtonSpinner />}
          </SpecialButton>
        </div>
      </Layout>
    </>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  padding: 12px;
  box-sizing: border-box;
  .export-image-field {
    margin: 12px 0;
    .field-name {
      flex: 1;
    }
    .field-input {
      flex: 1;
    }
  }
  .wrap-button {
    width: 100%;
    height: 42px;
    margin-top: 12px;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    user: selectUserInfo(state.authUser),
    tempID: selectTemplateID(state.designTemplate),
    tempLength: selectTemplateLength(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    tempRatio: selectTemplateRatio(designTemplate),
    themes: selectTemplateThemes(designTemplate),
    slideGroupList: selectSlideGroupList(designTemplate),
    tempName: selectTemplateName(designTemplate),
    template: selectTemplate(designTemplate),
    activeSlide: selectActiveSlide(designTemplate),
    tempSourceID: selectTempSourceID(designTemplate),
  };
};

export default connect(mapStateToProps, {openDrawer, closeDrawer})(
  ExportAsImage
);
