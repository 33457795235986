import React from "react";
import {connect} from "react-redux";
import {TooltipLayout, WrapTooltipSvg} from "../reuseTooltipStyles";
import {Content, OnHoverContent} from "../Tooltip";
import {
  cropImage,
  updateReplaceImgStatus,
} from "../../../../../../store/actions/fields/image.action";
import {selectCropImage} from "../../../../../../store/selectors/fields.selector";
import {Crop} from "../../../../../../../oat-svg-icons";
import TooltipItemsHoc from "../tooltipHocs/TooltipItemsHoc";

const ImageTooltip = ({croppingActive, cropImage, updateReplaceImgStatus}) => {
  const initCropImage = (e) => {
    e.stopPropagation();
    cropImage(true);
  };

  const endCropImage = (e) => {
    e.stopPropagation();
    cropImage(false);
  };

  const changeReplaceImageStatus = (e) => {
    e.stopPropagation();
    updateReplaceImgStatus();
  };

  return (
    <TooltipLayout>
      <TooltipItemsHoc>
        {!croppingActive ? (
          <>
            <Content onMouseDown={initCropImage}>
              <WrapTooltipSvg>{Crop}</WrapTooltipSvg>
              <OnHoverContent style={{width: 60, left: -25}}>
                Crop & Adjust
              </OnHoverContent>
            </Content>
            <Content onMouseDown={changeReplaceImageStatus}>Replace</Content>
          </>
        ) : (
          <Content onMouseDown={endCropImage}>Done</Content>
        )}
      </TooltipItemsHoc>
    </TooltipLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    croppingActive: selectCropImage(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  cropImage,
  updateReplaceImgStatus,
})(ImageTooltip);
