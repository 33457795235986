import {Link} from "react-router-dom";
import styled from "styled-components";
import {
  primaryGrey,
  paleBlue,
  primaryBlack,
} from "../../../../oat-color-variables";

export const Layout = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 2;
  min-height: ${window.innerHeight}px;
`;

export const TopPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkTitle = styled.div`
  font-size: 0.9rem;
  position: relative;
  height: 22px;
  color: ${primaryBlack};
`;

export const Items = styled.div`
  display: flex;
  flex-flow: wrap;
  overflow-y: scroll;
  justify-content: flex-start;
  height: ${window.innerHeight - 180}px;
  padding-top: 10px;
  padding-bottom: 220px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WrapItem = styled(Link)`
  font-size: 0.92rem;
  color: ${primaryBlack};
  width: 24.2%;
  margin-right: 1%;
  text-decoration: none;
  margin-bottom: 10px;
  &:nth-child(4n) {
    margin-right: 0px;
  }
`;

export const Header = styled.h3`
  font-size: 1.24rem;
  margin: 0.8rem 0 1.4rem;
  color: ${paleBlue};
  user-select: none;
  width: 50%;
`;

export const Divider = styled.div`
  height: 1px;
  background: ${primaryGrey};
  margin: 18px 0;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  // width: 100%;
  // height: auto;
  object-fit: cover;
  margin-right: 18px;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
`;

export const WrapSvg = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
  fill: #858383;
  margin: 0;
  border-radius: 3px;
  margin-right: 28px;
  &:hover {
    background: #f0eeee;
  }
`;

export const PopUpDiv = styled.div`
  position: absolute;
  width: 240px;
  height: 200px;
  left: -210px;
  background: white;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 0px #d3d3d3;
  padding: 12px 14px;
  box-sizing: border-box;
`;

export const ShowError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  height: 320px;
  color: #232323;
`;
