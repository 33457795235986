import {
  DISPATCH_REDO_ITEM,
  DISPATCH_UNDO_ITEM,
  STORE_ACTIVE_UNDO_REDO,
  STORE_BACKGROUND_UNDO_REDO,
  STORE_ACTIONS_UNDO_REDO,
  STORE_FIELD_AS_ACTIVE_UNDO_REDO,
  STORE_FIELD_AS_HISTORY_UNDO_REDO,
} from "../actionTypes";

export const storeFieldUndoRedo = () => {
  return {
    type: STORE_ACTIVE_UNDO_REDO,
  };
};

export const storeBackgroundUndoRedo = (key, value) => {
  return {
    type: STORE_BACKGROUND_UNDO_REDO,
    key,
    value,
  };
};

// for creating, deleting & duplicating fields
export const storeActionsUndoRedo = (actionType) => {
  return {
    type: STORE_ACTIONS_UNDO_REDO,
    actionType,
  };
};

export const dispatchUndo = () => {
  return {
    type: DISPATCH_UNDO_ITEM,
  };
};

export const dispatchRedo = () => {
  return {
    type: DISPATCH_REDO_ITEM,
  };
};

export const storeFieldAsActiveUndoRedo = () => {
  return {
    type: STORE_FIELD_AS_ACTIVE_UNDO_REDO,
  };
};

export const storeFieldAsHistoryUndoRedo = () => {
  return {
    type: STORE_FIELD_AS_HISTORY_UNDO_REDO,
  };
};

export const dispatchCurrentUndoState = () => {
  // return {
  //   type: DISPATCH_CURRENT_UNDO_STATE,
  // };
};

export const dispatchPrevState = (action) => {
  // return {
  //   type: STORE_PREV_ITEM_STATE,
  //   action,
  // };
};
