import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {ArrowLeft, ArrowRight} from "../../../ui/svg/Arrow";
import {changeSlideOnPresentView} from "../../../store/actions/template/slide.action";
import {
  selectActiveSlide,
  selectCurrentSlideOnClickAnimationOrder,
  selectSlideAnimationOrder,
  selectTotalSlides,
} from "../../../store/selectors/template/slide.selector";

const PresentViewSlideBar = ({
  activeSlide,
  changeSlideOnPresentView,
  slideLength,
  currentAnimationOrder,
  animationOrder
}) => {
  const { 
    highestOrder: highestAnimationOrder, 
    lastOnClickAnimationOrderHasEnabledExit: exitAnimationOnLastItem,
    highestOrderIsNotTypeAnimateOnClick
  } = animationOrder;

  const onBackwardSlide = () => {
    if (activeSlide === 0 && currentAnimationOrder === 0) return;
    changeSlideOnPresentView("backward");
  };

  const onForwardSlide = async () => {
    if (
      activeSlide === slideLength - 1 &&
      currentAnimationOrder >= highestAnimationOrder
    ) {
      return;
    }
    
    changeSlideOnPresentView("forward");
    if (exitAnimationOnLastItem && 
      highestAnimationOrder !== 0 &&
      (highestOrderIsNotTypeAnimateOnClick ||
      currentAnimationOrder === highestAnimationOrder)) {
      setTimeout(async() => {
        await changeSlideOnPresentView("forward");
      }, 800)
    }
  };

  return (
    <Wrapper>
      <WrapSvg
        edge={activeSlide === 0 && currentAnimationOrder === 0}
        onClick={onBackwardSlide}
      >
        {ArrowLeft}
      </WrapSvg>
      <Text>
        {activeSlide + 1}
        <span> / </span> {slideLength}
      </Text>
      <WrapSvg
        edge={
          slideLength - 1 === activeSlide &&
          currentAnimationOrder >= highestAnimationOrder
        }
        onClick={onForwardSlide}
      >
        {ArrowRight}
      </WrapSvg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 145px;
  display: flex;
  justify-content: space-around;
  fill: #d0c9ff;
`;

const WrapSvg = styled.div`
  width: 38px;
  height: 38px;
  fill: ${({theme}) => theme.svgIconColor};
  opacity: ${({edge}) => (!edge ? 1 : 0.3)};
  cursor: ${({edge}) => (!edge ? "pointer" : "not-allowed")};
`;

const Text = styled.p`
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  min-width: 70px;
  user-select: none;
  span {
    padding: 0 10px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeSlide: selectActiveSlide(designTemplate),
    slideLength: selectTotalSlides(designTemplate),
    currentAnimationOrder: selectCurrentSlideOnClickAnimationOrder(designTemplate),
    animationOrder: selectSlideAnimationOrder(designTemplate)
  };
};

export default connect(mapStateToProps, {changeSlideOnPresentView})(
  PresentViewSlideBar
);
