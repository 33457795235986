import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {blue3, red4} from "../../../../oat-color-variables";
import {Spinner} from "../../../../oat-ui";
import {useParams} from "react-router";
import {loadTemplate} from "../../../store/actions/template/template.action";
import {connect} from "react-redux";
import {RightArrow} from "../../../../oat-svg-icons";

const EnterPasscode = ({
  tempID,
  isCustomLink,
  customLinkSlug,
  loadTemplate,
}) => {
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [processing, setProcessing] = useState(false);
  const params = useParams();

  const handleChange = (e) => {
    const {value} = e.target;
    setPasscode(value);
  };

  const handleSubmit = async (e) => {
    if (passcode !== "" && !processing) {
      if (error.status) {
        setError({
          status: false,
          message: null,
        });
      }
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/oat/template/passcode/check`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempID,
            passcode, // parseInt(passcode),
            isCustomLink,
            customLinkSlug,
          }),
        }
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "success") {
            await loadTemplate(data);
            await setProcessing(false);
          } else {
            setProcessing(false);
            setPasscode("");
            setError({
              status: true,
              message: "Incorrect Passcode",
            });
          }
        })
        .catch((e) => {
          setProcessing(false);
          setPasscode("");
          setError({
            status: true,
            message: "Something went wrong",
          });
        });
    } else {
      setError({
        status: true,
        message: "Please enter a passcode",
      });
    }
  };

  const onEnterEvent = (event) => {
    if (event.keyCode == 13) {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onEnterEvent, false);
    return () => {
      document.removeEventListener("keydown", onEnterEvent, false);
    };
  });

  return (
    <Layout>
      <Wrapper>
        <Header>Enter Passcode</Header>
        <WrapInputs>
          <Input
            type="text"
            value={passcode}
            onChange={handleChange}
            autoFocus
          />

          <Submit>
            {processing ? (
              <WrapSpinner>
                <Spinner />
              </WrapSpinner>
            ) : undefined}
            <div onClick={handleSubmit}>{RightArrow}</div>
          </Submit>

          {error.status ? (
            <Error>
              <div>{error.message}</div>
            </Error>
          ) : undefined}
        </WrapInputs>
      </Wrapper>

      <Company>
        <PoweredBy>
          Powered by{" "}
          <a href="https://oatall.com" target="_blank">
            Oat All
          </a>
        </PoweredBy>
      </Company>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: ${window.innerHeight / 2 - 200}px;
  margin-bottom: 56px;
`;

const Header = styled.h2`
  color: #fff;
  font-size: 1.9rem;
  margin: 16px 0 22px;
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
    margin: 16px 0 28px;
  }
`;

const WrapInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 250px;
  height: 50px;
  margin: 0 8px;
  font-size: 2rem;
  outline: ${({active}) => (active ? `solid ${blue3}` : undefined)};
  box-sizing: border-box;
  border-radius: 3px;
  border: none;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 34px;
    font-size: 1.3rem;
  }
`;

const Company = styled.div`
  display: flex;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  position: absolute;
  bottom: 160px;
`;

const WrapSpinner = styled.div`
  margin-bottom: 42px;
  position: absolute;
  right: 60px;
  margin-bottom: 56px;
  transform: scale(0.8);
`;

const PoweredBy = styled.div`
  a {
    font-weight: 700;
    text-decoration: none;
    color: #fff;
  }
`;

const Error = styled.div`
  font-size: 1.1rem;
  margin-top: 32px;
  color: ${red4};
  text-align: center;
`;

const Submit = styled.div`
  width: 24px;
  height: 24px;
  fill: #dccece;
  margin-top: 35px;
  border: 1px solid #dccece;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
`;

export default connect(null, {loadTemplate})(EnterPasscode);
