import React, {useState, useRef} from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {Button, ButtonSpinner, detectClickOutside} from "../../../../../oat-ui";
import {editProfileTag} from "../../../../store/actions/userProfile.action";
import {
  TextField,
  Wrapper,
  WrapButton,
} from "../../../sidebar/folderTypes/folderStyles";
import {Popper} from "./ProfileCollections";

const EditProfileCollection = ({collection, editProfileTag, user, close}) => {
  const [input, setInput] = useState(collection.display);
  const [processing, setProcessing] = useState();
  const popperRef = useRef();

  const onChangeInput = (e) => {
    const {value} = e.target;
    setInput(value);
  };

  const handleEdit = () => {
    setProcessing(true);
    const requestOptions = {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        payload: {id: collection.id, display: input},
      }),
    };
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.account.business.slug}/userInfo/profile/tags`,
      requestOptions
    )
      .then((response) => response.json())
      .then(() => {
        editProfileTag({id: collection.id, display: input});
        setProcessing(false);
        close();
      })
      .catch((e) => console.log(e));
  };

  detectClickOutside(popperRef, () => {
    close();
  });

  const onIgnoreEvent = (e) => {
    e.stopPropagation();
  };

  return (
    <Popper
      style={{height: 94, left: 80, zIndex: 999999}}
      onClick={onIgnoreEvent}
      ref={popperRef}
    >
      <TextField
        value={input}
        placeholder="Enter Collection"
        onChange={onChangeInput}
      />
      <Wrapper style={{justifyContent: "flex-end"}}>
        <WrapButton>
          <Button onClick={handleEdit} disabled={processing}>
            {!processing ? "Update" : <ButtonSpinner />}
          </Button>
        </WrapButton>
      </Wrapper>
    </Popper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {editProfileTag})(
  EditProfileCollection
);
