import React from 'react';

const PremiumImagesCovers = ({item, panelWidth}) => {
  return (
    <div style={{background: '#f2f7f2ed', overflow: 'hidden'}}>
      <img style={{
        boxSizing: 'border-box',
        cursor: 'default',
        maxWidth: '100%', 
        width: '100%',
        height: 'auto',
        boxShadow: `0px 0px 0px 0px #fff`,
        objectFit: 'contain'
      }} 
        src={`https://oat-images-icons.s3.amazonaws.com/${item}`} 
        loading="lazy" 
        alt="" 
      />
    </div>
  );
}

export default PremiumImagesCovers;