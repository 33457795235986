import React from "react";
import styled from "styled-components";

const ShowError = ({message}) => {
  return <Wrapper>{message}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  background: #f7b0c65c;
  padding: 6px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ff1616;
  font-size: 0.9rem;
  text-align: right;
`;

export default ShowError;
