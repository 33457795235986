import React, {useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import EditImage from "./EditImage";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import ImageTabs from "./ImageTabs";
import ImageFilters from "./ImageFilters";
import ImageFrames from "./ImageFrames";
import {ScrollY} from "../../../../../../../oat-ui/styles/ScrollY";

let displayElements = null;
const EditImagePanel = () => {
  const [activeTab, setActiveTab] = useState("deskImgEffects");

  const handleSelection = (tab) => {
    setActiveTab(tab);
  };

  switch (activeTab) {
    case "deskImgEffects":
      displayElements = (
        <Wrapper>
          <EditImage />
        </Wrapper>
      );
      break;
    case "deskImgFilters":
      displayElements = (
        <div style={{paddingBottom: "2em"}}>
          <ImageFilters />
        </div>
      );
      break;
    case "deskImgFrames":
      displayElements = (
        <div style={{paddingBottom: "2em"}}>
          <ImageFrames />
        </div>
      );
      break;
    default:
      displayElements = (
        <Wrapper>
          <EditImage />
        </Wrapper>
      );
  }

  return (
    <>
      <ImageTabs onSelectTab={handleSelection} selectedTab={activeTab} />
      {displayElements}
    </>
  );
};

const Wrapper = styled.div`
  flex-direction: column;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  ${ScrollY}
`;

const mapStateToProps = (state) => {
  return {
    activeField: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(EditImagePanel);
