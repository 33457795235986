import React from "react";
import {Header, Layout} from "../userTemplates/userTemplatesStyles";
import { DashboardSection } from "../dashboardPanelStyles";

const CalendarPanel = () => {
  return (
    <Layout>
      <Header>
        <h3>Calendar</h3>
      </Header>

      <DashboardSection>
        Calendar is for later release.
      </DashboardSection>
    </Layout>
  );
};

export default CalendarPanel;
