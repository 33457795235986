import React, {useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import ThemeBuilder from "../../components/themeBuilder/ThemeBuilder";
import {lightTheme} from "../../themes";
import {selectThemeBuilder} from "../../store/selectors/template/theme.selector";
import {Button, Modal} from "../../../oat-ui";
import styled from "styled-components";

const tagsColorList = [
  "yellow",
  "red",
  "orange",
  "blue",
  "cyan",
  "green",
  "pink",
  "black",
  "white",
  "purple",
];

const DisplayItem = ({onSelect, color, selected}) => {
  function handleClick() {
    onSelect(color);
  }
  return (
    <TagColor
      onClick={handleClick}
      style={{
        background: color,
        border: !selected ? "1px solid #acacac" : `3px solid rgb(75 208 53)`,
      }}
    />
  );
};

const CreateTempTheme = ({themeInfo}) => {
  let navigate = useNavigate();
  const [ui, setUi] = useState({
    openModal: false,
    submitting: false,
  });
  const [selectedTag, setSelectedTag] = useState("");
  const [addedTags, setAddedTags] = useState("");

  const handleBackButton = () => {
    return navigate(`/admin/template-themes`);
  };

  const addTag = () => {
    setUi({openModal: true, submitting: false});
  };

  const handleThemeCreate = async () => {
    const {bg, heading, subheading, smallText, normalText, name} = themeInfo;
    setUi({...ui, submitting: true});
    await fetch(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template-themes`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          themeDetails: themeInfo,
          themeName: name,
          tag: "yellow",
          themeParams: {
            bg1: bg.bg1,
            bg2: bg.bg2,
            heading: heading.color1,
            subheading: subheading.color1,
            normalText: normalText.color1,
            smallText: smallText.color1,
            font: normalText.fontFamily,
          },
        }),
      }
    )
      .then((res) => res.json())
      .then(() => {
        setUi({openModal: false, submitting: false});
      })
      .catch((error) => {});
  };

  const toggleModal = () => {
    setUi({...ui, openModal: !ui.openModal});
  };

  const selectColorTag = (value) => {
    setSelectedTag(value);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <ThemeBuilder
        goBack={handleBackButton}
        buildingTheme={true}
        onSubmit={addTag}
        processing={ui.submitting}
        buttonTitle="Create"
      />
      {ui.openModal ? (
        <Modal width={400} height={300} close={toggleModal}>
          <ModalContent>
            <WrapField>
              <Field>Tags</Field>
              <Value>
                {tagsColorList.map((color, i) => (
                  <DisplayItem
                    color={color}
                    key={i}
                    onSelect={selectColorTag}
                    selected={color === selectedTag}
                  />
                ))}
              </Value>
            </WrapField>
            <CreateSection>
              <WrapButton style={{display: "flex", justifyContent: "flex-end"}}>
                <Button onClick={handleThemeCreate}>Create</Button>
              </WrapButton>
            </CreateSection>
          </ModalContent>
        </Modal>
      ) : undefined}
    </ThemeProvider>
  );
};

const ModalContent = styled.div`
  padding: 12px;
  box-sizing: content-box;
`;

const WrapField = styled.div`
  display: flex;
  font-size: 1rem;
  padding-top: 18px;
`;

const Field = styled.div`
  flex: 1;
`;

const Value = styled.div`
  display: flex;
  flex: 2;
  flex-wrap: wrap;
`;

const TagColor = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 8px 8px 0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
`;

const CreateSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const WrapButton = styled.div`
  width: 120px;
  height: 38px;
`;

const mapStateToProps = (state) => {
  return {
    themeInfo: selectThemeBuilder(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(CreateTempTheme);
