import React from "react";
import styled from "styled-components";
import {paleBlue4} from "../../../../oat-color-variables";

const DisplayItem = ({item, onSelect}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onSelect(item);
    e.stopPropagation();
  };
  return <Item onMouseDown={handleClick}>{item.username}</Item>;
};

const SuggestUsernames = ({usernames, onSelectUser}) => {
  return (
    <Layout>
      {usernames &&
        usernames.map((item) => (
          <DisplayItem
            key={item.username}
            item={item}
            onSelect={onSelectUser}
          />
        ))}
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  left: 13px;
  bottom: 100px;
  width: 200px;
  height: 200px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 1px 1px 7px 1px #dedede;
  overflow-y: scroll;
  z-index: 9999;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.div`
  font-size: 0.9rem;
  padding: 6px;
  margin: 8px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: ${paleBlue4};
  }
`;

export default SuggestUsernames;
