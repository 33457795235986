import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import Switch from "../../shared/inputs/Switch";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import { WrapColorBox } from "../common/WrapColorStyles";

const ShowAxis = ({
  fieldID,
  slideID,
  visible,
  fieldValue,
  label,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const updateVisibility = (value) => {
    updateFieldStyles({
      active: {
        slideID,
        fieldID,
      },
      type: fieldValue,
      value,
    });
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <div style={{display: "flex"}}>
      <WrapColorBox style={{ flex: 1}}>
        <Switch
          title={label}
          value={visible}
          onItemChange={updateVisibility}
          checked={visible}
        />
      </WrapColorBox>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ShowAxis);
