import styled from 'styled-components';

export const WrapShadowTypes = styled.div`
  display: flex;
  overflow-x: scroll;
  font-size: 32px;
  padding: 0 1.5rem 2rem 1rem;
  margin-bottom: 2rem;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8d91dfff; /* Thumb color */
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0; /* Track color */
  }
`;

export const ChooseShadowType = styled.div`
  cursor: pointer;
  p {
    font-size: 0.9rem;
    color: ${({theme, active}) => active  ? theme.deskTertiaryTxt : undefined};
  }
`;

export const ShadowType = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 0 0 auto;
  background: #fff;
  outline: 1px solid ${({theme, active}) => active ? theme.deskPrimaryBg : '#dedede'};
  border-radius: 6px;
  outline: ${({theme, active}) => active ? `1px solid ${theme.secondaryThemeBorder}` : undefined};
  box-sizing: border-box;
  margin: 12px 12px 12px 0;
`;

export const ShadowBox = styled.div`
  width: 45px;
  height: 45px;
  background: #7580f9;
`;