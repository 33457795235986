import React from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {onFolderChange} from "../../../store/actions/folder.action";

const SearchResults = ({item, user, onFolderChange, close}) => {
  let params = useParams();
  let link = "/";
  if (item.type === "design") {
    link = `/design/${item.SK}`;
  } else if (item.type === "folder") {
    link = `/dashboard/${params.workspaceID}/designs/${item.folderID}`;
  }
  // library link hasn't set up
  // else if (item.type === "library") {
  //   link = `/dashboard/${params.workspaceID}/designs/${item.folderID}`;
  // }

  const handleSelection = async () => {
    const payload = {
      username: user.username,
      workspaceID: params.workspaceID,
      folderID: item.folderID,
      folderType: item.folderType,
    };
    await onFolderChange(payload);
    await close();
  };

  return (
    <Item to={link} onClick={handleSelection}>
      <Title>{item.display}</Title>
      <Info>
        <p>{item.src}</p>
        <span> - </span>
        <p style={{textTransform: "capitalize"}}>{item.type}</p>
      </Info>
    </Item>
  );
};

const Item = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 10px 6px;
  font-size: 0.9rem;
  border-top: 1px solid #e7e5f6;
  color: #333030;
  text-decoration: none;
  user-select: none;
  &:first-child {
    border-top: none;
  }
  &:hover {
    cursor: pointer;
    color: #6270c9;
  }
`;

const Title = styled.div``;

const Info = styled.div`
  display: flex;
  font-size: 0.9rem;
  span {
    padding: 0 5px;
  }
`;

const mapStateToProps = (state) => {
  const {userDashboard} = state;
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {onFolderChange})(SearchResults);
