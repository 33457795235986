import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {updateThemeBuilderFields} from "../../../store/actions/template/theme.action";
import {selectThemeBuilder} from "../../../store/selectors/template/theme.selector";
import {InputName} from "../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
import PopoverColorBox from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import Switch from "../../editDesign/editingPanel/panelTypes/shared/inputs/Switch";
import {WrapItem, ColorsSection} from "./buildThemeStyle";
import ThemeBuilderFontFamily from "./reusable/ThemeBuilderFontFamily";

const DisplayColorSet = ({item, index, onChange, onDebounce}) => {
  const handleChange = (color) => {
    onChange(index, color);
  };

  const handleDebounce = () => {
    // leave empty debounce
  };

  return (
    <div style={{marginBottom: 8}}>
      <PopoverColorBox
        value={item}
        updateFieldStyles={handleChange}
        onDebounce={handleDebounce}
      />
    </div>
  );
};

const ChartThemeBuilder = ({chart, updateThemeBuilderFields}) => {
  const [multiSeriesColorSet, setMultiSeriesColorSet] = useState([
    chart.colorSet[0],
    chart.colorSet[1],
    chart.colorSet[2],
    chart.colorSet[3],
    chart.colorSet[4],
  ]);

  useEffect(() => {
    setMultiSeriesColorSet([
      chart.colorSet[0],
      chart.colorSet[1],
      chart.colorSet[2],
      chart.colorSet[3],
      chart.colorSet[4],
    ]);
  }, [chart]);

  const handleFieldUpdate = (key, value) => {
    updateThemeBuilderFields({
      category: "chart",
      key,
      value,
    });
  };

  const changeLabelColor = (value) => {
    handleFieldUpdate("labelColor", value);
    handleFieldUpdate("legendColor", value);
    handleFieldUpdate("categoryColor", value);
  };

  const changeValueColor = (value) => {
    handleFieldUpdate("valueColor", value);
  };

  const changeFontFamily = (item) => {
    handleFieldUpdate("fontFamily", item.fontFamily);
    handleFieldUpdate("src", item.src);
    if (item.custom) handleFieldUpdate("custom", item.custom);
    if (item.format) {
      handleFieldUpdate("format", item.format);
    }
  };

  const changeGridColor = (value) => {
    handleFieldUpdate("gridColor", value);
  };

  const changeChartBackground = (value) => {
    handleFieldUpdate("background", value);
  };

  const changeMultiColorSet = (key, value) => {
    const updateColorSet = chart.colorSet.map((item, i) =>
      i === key ? value : item
    );
    handleFieldUpdate("colorSet", updateColorSet);
  };

  const changeAxisColor = (value) => {
    handleFieldUpdate("axisColor", value);
  };

  const changeLegendColor = (value) => {
    handleFieldUpdate("legendColor", value);
  }

  const handleDebounceColor = () => {
    // leave empty debounce
  };

  const toggleShowBgStatus = (value) => {
    handleFieldUpdate("showBackground", value);
  };

  return (
    <>
      <WrapItem>
        <ColorsSection>
          <InputName>Label Color</InputName>
          <PopoverColorBox
            value={chart.labelColor}
            updateFieldStyles={changeLabelColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Value Color</InputName>
          <PopoverColorBox
            value={chart.valueColor}
            updateFieldStyles={changeValueColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Legend Color</InputName>
          <PopoverColorBox
            value={chart.legendColor}
            updateFieldStyles={changeLegendColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ThemeBuilderFontFamily
          fontFamily={chart.fontFamily}
          fontWeight={chart.fontWeight}
          fontStyle={chart.fontStyle}
          onChange={changeFontFamily}
        />
      </WrapItem>

      <WrapItem>
        <ColorsSection style={{alignItems: "flex-start"}}>
          <InputName>Bar/Line Color</InputName>
          <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
            {multiSeriesColorSet.map((item, i) => (
              <DisplayColorSet
                key={i}
                item={item}
                index={i}
                keyItem={multiSeriesColorSet[i]}
                onChange={changeMultiColorSet}
              />
            ))}
          </div>
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Grid Color</InputName>
          <PopoverColorBox
            value={chart.gridColor}
            updateFieldStyles={changeGridColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Axis Color</InputName>
          <PopoverColorBox
            value={chart.axisColor}
            updateFieldStyles={changeAxisColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <div style={{paddingBottom: "2em"}}>
          <Switch
            title="Turn Background"
            value={chart.showBackground}
            onItemChange={toggleShowBgStatus}
            checked={chart.showBackground}
            displayTooltip={{
              status: true,
              info: "Background will only be applied if the field has enabled.",
            }}
          />
        </div>

        <ColorsSection>
          <InputName>Background Color</InputName>
          <PopoverColorBox
            value={chart.background}
            updateFieldStyles={changeChartBackground}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    chart: selectThemeBuilder(state.designTemplate)["chart"],
  };
};

export default connect(mapStateToProps, {updateThemeBuilderFields})(
  ChartThemeBuilder
);
