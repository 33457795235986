import {themes} from "../../reducers/data/themeBuilder.data";

export const selectThemeBuilder = (state) => state.themeBuilder;
export const selectTemplateThemes = (state) => state.themes;
export const selectBuildingTheme = (state) => state.buildingTheme;
export const selectThemeProps = (state) => {
  const currentSlide =
    state.activeSlide !== -1 &&
    state.template &&
    state.template[state.activeSlide].theme;

  if (currentSlide) {
    const themeExists =
      state.themes && state.themes.find((theme) => theme.id === currentSlide);
    if (themeExists) {
      return themeExists;
    } else {
      return themes[0];
    }
  } else {
    // in case any theme is not available
    return themes[0];
  }
};
