import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import ThemeBuilder from "../../components/themeBuilder/ThemeBuilder";
import {selectThemeBuilder} from "../../store/selectors/template/theme.selector";
import {lightTheme} from "../../themes";
import {dispatchThemeInfo} from "../../store/actions/template/theme.action";

const UpdateTempTheme = ({themeInfo, dispatchThemeInfo}) => {
  let navigate = useNavigate();
  let params = useParams();
  const {tagColor, themeID} = params;
  const [processing, setProcessing] = useState(false);

  const handleBackButton = () => {
    return navigate(`/admin/template-themes`);
  };

  const handleThemeUpdate = async () => {
    const {bg, heading, subheading, normalText, smallText, name} = themeInfo;
    setProcessing(true);
    await fetch(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template-themes`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          themeDetails: themeInfo,
          themeName: name,
          tag: tagColor,
          themeID: themeID,
          themeParams: {
            bg1: bg.bg1,
            bg2: bg.bg2,
            heading: heading.color1,
            normalText: normalText.color1,
            font: normalText.fontFamily,
          },
        }),
      }
    )
      .then((res) => res.json())
      .then(() => {
        setProcessing(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetch(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template-themes/${tagColor}/${themeID}`
    )
      .then((res) => res.json())
      .then((item) => {
        fetch(item.themeSrc)
          .then((res) => res.json())
          .then((data) => {
            dispatchThemeInfo(data);
          });
      })
      .catch((error) => {});
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <ThemeBuilder
        goBack={handleBackButton}
        buildingTheme={true}
        onSubmit={handleThemeUpdate}
        processing={processing}
        buttonTitle="Update"
      />
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    themeInfo: selectThemeBuilder(state.designTemplate),
  };
};

export default connect(mapStateToProps, {dispatchThemeInfo})(UpdateTempTheme);
