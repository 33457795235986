import React, {useEffect, useState, useRef} from 'react';
import {EmbedSection, embedWindowPanelSize} from '../home/components/EmbedTemplate';
import { getWindowSize } from '../../oat-window-size/getWindowSize';
import styled from 'styled-components'
import { black1, blue3 } from '../../oat-color-variables';
import { defaultMaxWidthDesktop, defaultMaxWidthLaptop2, layoutPadding } from '../home/layoutVariables';
import AvailableServices from './AvailableServices';
import { useLocation } from 'react-router-dom';
import EmbedMode from '../../oat-design-template/containers/EmbedMode';

const IllustrationServices = () => {
    const [selectedItem, setSelectedItem] = useState('illustrations');
    const query = new URLSearchParams(useLocation().search);
    const type = query.get("type");
    const contentSectionRef = useRef(null);
    const [embedSectionWidth, setEmbedSectionWidth] = useState(0)

    useEffect(() => {
        if (type === "illustrations") {
            setSelectedItem("illustrations");
        } else {
            setSelectedItem("sales-deck");
        }
    },[type])

    const onSelectItem = (value) => {
        setSelectedItem(value);
    }

    const windowSize = getWindowSize();

    useEffect(() => {
        if (contentSectionRef?.current) {
            setEmbedSectionWidth(contentSectionRef?.current?.offsetWidth)
        }
    },[windowSize.width])

    return (
        <Container>
            <ContentSection ref={contentSectionRef} $width={windowSize.width}>
                <AvailableServices selectedItem={selectedItem} onSelectItem={onSelectItem} />

                <Content>
                    {selectedItem === "illustrations" ? 
                    <>
                        <Title>Bespoke Illustrations</Title>
                        <Description>
                            If you're looking to enhance your brand's identity, create eye-catching marketing materials, or add a touch of creativity to your projects, our team of talented illustrators is here to bring your vision to life.
                        </Description>
                    </> : 
                    <>
                        <Title>Sales Deck</Title>
                        <Description>
                            From dynamic graphics to compelling storytelling, we'll help you elevate your presentations to the next level, helping you stand out in today's competitive landscape and achieve your business goals with confidence.
                        </Description>
                    </>}
                </Content>
            </ContentSection>
            
            {selectedItem === "illustrations" ? 
                <EmbedMode transition={null} tempID="NRFzetsBWP3" panelWidth={embedSectionWidth} /> : 
                <EmbedMode transition={null} tempID="CfXFOTaa6s" panelWidth={embedSectionWidth} />
            }

            <div style={{marginBottom: 300}} />
            
            {/* <EmbedSection style={{zIndex: 1, marginBottom: 165}} width={windowSize.width}>  
                 {selectedItem === "illustrations" ? 
                    <iframe
                        title="Oat All Bespoke Illustrations"
                        src="https://www.oatall.com/p/R6Jfe2V2mra?embed=true"
                        allow="fullscreen"
                        width={560}
                        height={315}
                    ></iframe> : 
                    <iframe
                        title="Oat All Sales Decks"
                        src="https://www.oatall.com/p/CfXFOTaa6s?embed=true"
                        allow="fullscreen"
                        width={560}
                        height={315}
                    ></iframe>
                } 
            </EmbedSection> */}
            
            <div style={{zIndex: 2222}} />
        </Container>
    )
}

const ContentSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0 42px 0;
    @media only screen and (min-width: 1080px) and (max-width: 1400px) {
        max-width: ${defaultMaxWidthLaptop2 + 80}px;
    }
    @media only screen and (min-width: 1401px) and (max-width: 1500px) {
        max-width: 1040px;
    }
    @media only screen and (min-width: 1501px) and (max-width: 1600px) {
        max-width: 1140px;
    }
    @media only screen and (min-width: 1601px) {
        max-width: ${defaultMaxWidthDesktop}px;
    }
    @media only screen and (min-width: 851px) and (max-width: 1079px) {
        max-width: ${({$width}) => $width - 140}px;
    }
    @media only screen and (max-width: 850px) {
        padding-top: 48px;
        flex-direction: column;
        margin: 0px 0 42px 0;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 3.2em ${layoutPadding.desktop} 4.2em;
  box-sizing: border-box;
  @media only screen and (min-width: 601px) and (max-width: 1000px) {
    padding: 3.2em ${layoutPadding.desktop} 4.2em;
  }
  @media only screen and (max-width: 850px) {
    padding: 0.9em ${layoutPadding.mobile};
  }
`;

const Title = styled.h3`
    flex: 1;
    font-size: 26px;
    color: ${blue3};
    padding: 0 14px 16px 0;
    box-sizing: border-box;
    @media only screen and (max-width: 850px) {
        margin-bottom: 18px;
    }
`

const Description = styled.p`
    flex: 1.5;
    font-size: 16px;
    line-height: 30px;
    color: ${black1};
    @media only screen and (max-width: 850px) {
        margin-bottom: 15px;
    }
`

export default IllustrationServices;