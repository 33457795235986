import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Title} from "./../reuseStyles/Title";
import {
  storeFieldUndoRedo,
  storeBackgroundUndoRedo,
} from "../../../../../../store/actions/fields/undoRedo.action";
import {connect} from "react-redux";
import {selectBuildingTheme} from "../../../../../../store/selectors/template/theme.selector";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {useSocket} from "../../../../../../webSocket/useSocket";
import {selectPageTheme} from "../../../../../../store/selectors/layout/layout.selector";

const RangeSlider = ({
  value,
  fieldType,
  storeFieldUndoRedo,
  storeBackgroundUndoRedo,
  isBuildingTheme,
  onItemChange,
  title,
  min,
  max,
  step,
  activeField,
  updateFieldCollaboration,
  pageTheme,
  displayValueInPercent
}) => {
  const handleValue = (e) => {
    e.preventDefault(); // keep
    const {value} = e.target;
    onItemChange(Number(value));
  };

  const [tracked, setTracked] = useState((100 * (value - min)) / (max - min));

  useEffect(() => {
    setTracked((100 * (value - min)) / (max - min));
  }, [value]);

  const handleMouseDown = () => {
    // activeField - to negelect background
    if (!isBuildingTheme.status && activeField !== -1) {
      storeFieldUndoRedo();
    } else if (!isBuildingTheme.status && fieldType === "background-angle") {
      storeBackgroundUndoRedo("bgAng", Number(value));
    }
  };

  const {emitSocketEvents} = useSocket() || {};

  const handleMouseUp = (e) => {
    const {value} = e.target;
    if (!isBuildingTheme.status && activeField !== -1) {
      storeFieldUndoRedo();
      updateFieldCollaboration();
    } else if (!isBuildingTheme.status && fieldType === "background-angle") {
      storeBackgroundUndoRedo("bgAng", Number(value));

      const payload = {type: "bgAng", value: Number(value)};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "changed-background", item: payload});
      }
    }
  };

  const handleTouchMove = (e) => {
    // Prevent the default touchmove behavior, which can cause scrolling
    e.preventDefault();

    // Extract the new value from the touch event
    // const newValue = // Calculate the new value based on the touch event
    //   // Update the state or perform any actions based on the new value
    //   // For example, update the value in your component's state
    //   // or trigger some other behavior
    //   handleValue(newValue);
  };

  return (
    <Container>
      <Title showTitle={title !== undefined ? true : false}>{title}</Title>
      <Slider>
        <Tooltip style={{left: tracked - tracked / 8 + "%"}}>
          {displayValueInPercent ? Math.round(Number(((value - min) * 100) / (max - min))) : value}
        </Tooltip>
        <div
          style={{
            height: 2,
            background: `linear-gradient(90deg, #857aff ${
              tracked - tracked / 25
            }%, #dedede ${tracked - tracked / 25}%)`,
            zIndex: 1,
            width: "100%",
            position: "absolute",
            boxSizing: "border-box",
          }}
        />
        <Range
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleValue}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          // onTouchMove={handleTouchMove}
          onTouchEnd={handleMouseUp}
          style={{
            width: "100%",
            background: "#c3c3c3",
          }}
          left={tracked - tracked / 15}
          pageTheme={pageTheme}
        />
      </Slider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: 849px) {
    width: 100%;
    height: 100%;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  width: 30px;
  height: 18px;
  top: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${({theme}) => theme.tooltipBg};
  border-radius: 5px;
  font-size: 12px;
  display: none;
`;

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #d4d4d4;
  border-radius: 8px;
  flex: 1.2;
  margin: 0% 3%;
  box-sizing: border-box;
  &:hover {
    ${Tooltip} {
      display: flex;
      text-align: center;
    }
  }
  @media only screen and (max-width: 850px) {
    flex: 1;
  }
`;

const Range = styled.input`
  -webkit-appearance: none;
  height: 0px;
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: url("/iconSlider.svg"), #fff;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    border: ${({pageTheme}) =>
      pageTheme === "light" ? "1px solid #8d91dfff" : "0px"};
    border-radius: 50%;
    cursor: pointer;
    @media only screen and (max-width: 849px) {
      width: 12px;
      height: 12px;
    }
  }
  &::-moz-range-thumb {
    width: ${({pageTheme}) => pageTheme === "light" ? 6 : 10 }px;
    height: ${({pageTheme}) => pageTheme === "light" ? 6 : 10 }px;
    background: url("/iconSlider.svg"), #fff;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    border: ${({pageTheme}) =>
      pageTheme === "light" ? "1px solid #8d91dfff" : "0px"};
    border-radius: 50%;
    cursor: pointer;
    @media only screen and (max-width: 849px) {
      width: 12px;
      height: 12px;
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    activeField: selectActiveField(state.designTemplate),
    isBuildingTheme: selectBuildingTheme(state.designTemplate),
    pageTheme: selectPageTheme(state.designTemplate),
  };
};

RangeSlider.defaultProps = {
  displayValueInPercent: true
}

export default connect(mapStateToProps, {
  storeFieldUndoRedo,
  storeBackgroundUndoRedo,
  updateFieldCollaboration,
})(RangeSlider);
