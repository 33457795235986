import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {createPortal} from "react-dom";
import {detectClickOutside} from "../../oat-ui";

// haven't used yet
const CanvasPopper = ({children, onClose, width, height, offset}) => {
  const popUpRef = useRef(null);

  detectClickOutside(popUpRef, () => onClose());

  return createPortal(
    <div style={{width: "100%", height: "100%", position: "absolute", top: 0}}>
      <Wrapper
        style={{left: offset.x, top: offset.y, width, height}}
        ref={popUpRef}
      >
        <Content>{children}</Content>
      </Wrapper>
    </div>,
    document.getElementById("popUp-box")
  );
};

const Wrapper = styled.div`
  position: absolute;
  border-radius: 8px;
  z-index: 999999;
  height: auto;
  overflow: hidden;
  color: ${({theme}) => theme.deskPrimaryTxt};
  box-shadow: ${({theme}) => theme.boxShadow1};
  background: ${({theme}) => theme.deskPrimaryBg};
  padding: 10px;
  box-sizing: border-box;
  animation: bouncy 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  @-webkit-keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Content = styled.div`
  overflow-y: scroll;
  box-sizing: content-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

CanvasPopper.defaultProps = {
  width: 260,
  height: 220,
  offset: {
    x: 0,
    y: 0,
  },
};

export default CanvasPopper;
