import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import tinycolor from "tinycolor2";
import {selectTempScale} from "../../../../store/selectors/template/template.selector";
import {shadowTypeOptions} from "../../editingPanel/panelTypes/desktop/image/ImageShadow";
import {ImgWrapper, Image, Overlay} from "../fields/image/ImageField";
import {getSelectedShadow} from "../fields/image/getSelectedShadow";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import { useParams } from "react-router-dom";

const ViewOnlyImage = ({field, tempScale}) => {
  const windowSize = getWindowSize();
  const {styles, content, imgPos, fileType, size, imageBy, external} = field || {};
  const {
    blur,
    contrast,
    brightness,
    saturate,
    sepia,
    hueRotate,
    grayScale,
    shadowSize,
    shadowColor,
    shadowBlur,
    scaleX,
    scaleY,
    opacity,
    borderRadius,
    overlayColor,
    overlayType,
    overlayDegree,
    enableOverlay,
    enabledShadow,
    shadowType,
    borderWidth,
    borderColor
  } = styles || {};

  const calcBorderRadius = Number.isInteger(borderRadius)
    ? borderRadius + "px"
    : borderRadius;

  let selectedShadowValue = "";
  if (enabledShadow) {
    selectedShadowValue = getSelectedShadow({
      shadowType, 
      shadowTypeOptions, 
      shadowColor, 
      shadowSize,
      shadowBlur,
      tempScale
    });
  }
  const filter = `contrast(${contrast}%) brightness(${brightness}%) saturate(${saturate}%) sepia(${sepia}%) grayscale(${grayScale}%) hue-rotate(${hueRotate}deg) blur(${blur}px)`;

  const hexToRgb = (hex) => {
    let color = tinycolor(hex);
    color.setAlpha(0.7);
    return tinycolor(color).toRgbString();
  };

  const [imgSrc, setImgSrc] = useState("");
  useEffect(() => {
    if (content !== null && typeof content === 'object' && imageBy === "oat-all") {
      setImgSrc(`https://oat-images-icons.s3.amazonaws.com/${content[windowSize.width <= 600 ? 'small' : 'medium' ]}`)
    } else if (imageBy === "oat-all" && fileType === 'svg') {
      setImgSrc(`https://oat-images-icons.s3.amazonaws.com/${content}`)
    } // if it wasn't an object 
    else if (content !== null && imageBy === "oat-all") {
      setImgSrc(`https://oat-images-icons.s3.amazonaws.com/${content}`)
    } else if (external) {
      setImgSrc(content)
    } else {
      setImgSrc(`https://oat-users.s3.amazonaws.com/${content}`)
    }
  }, [external, imageBy, fileType, content]);

  // initial image load for better ui
  const [imageLoaded, setImageLoaded] = useState(false);
  const onImageLoaded = () => {
    setImageLoaded(true)
  }

  let params = useParams();
  const {screenshot} = params;

  // IMPORTANT !!
  // CHANGE IN HERE, SHOULD ALSO REFLECT IN Draggable/ImageField.js
  return (
    <ImgWrapper
      style={{
        borderRadius: calcBorderRadius,
        MozBoxShadow: enabledShadow ? selectedShadowValue : undefined,
        WebkitBoxShadow: enabledShadow ? selectedShadowValue : undefined,
        boxShadow: enabledShadow ? selectedShadowValue : undefined,
        WebkitPrintColorAdjust: "exact",
        border: `${borderWidth * tempScale}px solid ${borderColor}`,
        transform: `scale(1) translateZ(0px)`,
        // MozFilter: pngShadow,
        // WebkitFilter: pngShadow,
        // filter: pngShadow
      }}
    >
      <Image
        draggable="false"
        style={{
          width: size.w2,
          // height: imageLoaded ? size.h2 : 'auto',
          transform: `translate(${imgPos.x}px, ${imgPos.y}px) rotate(0deg) scaleX(${scaleX}) scaleY(${scaleY})`,
          opacity: opacity,
          WebkitFilter: filter,
          MozFilter: filter,
          MsFilter: filter,
          filter,
        }}
        src={imgSrc}
        onLoad={onImageLoaded}
        loading={screenshot ? "eager" : "lazy"} // screenshot ? "eager" : "lazy"
      />
      {enableOverlay ? (
        <Overlay
          style={{borderRadius: calcBorderRadius}}
          type={overlayType}
          color={overlayColor ? hexToRgb(overlayColor) : undefined}
          degree={overlayDegree}
        />
      ) : undefined}
    </ImgWrapper>
  );
};

ViewOnlyImage.defaultProps = {
  cropImage: false,
};

const mapStateToProps = ({designTemplate}) => {
  return {
    tempScale: selectTempScale(designTemplate),
  };
};

export default connect(mapStateToProps, null)(ViewOnlyImage);
