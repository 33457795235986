import {connect} from "react-redux";
import React from "react";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../../../store/selectors/template/template.selector";
import {useRotateLabels} from "../hooks/useRotateLabels";
import ValueLabel from "./ValueLabel";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {selectInterceptedFields} from "../../../../../../../store/selectors/common.selector";
import {chartDefaultFontSize} from "../../chartDefaultFontSize";

export const rotatedDeg = -45;
const CategoryLabels = ({
  labels,
  barMargin,
  barWidth,
  seriesLength,
  styles,
  tempSize,
  tempScale,
  chartHeight,
  slideID,
  fieldID,
  edgeSpace,
  groupSelect,
}) => {
  const {fontFamily, categoryColor, categoryFontSize, rotateCategory} = styles;
  const categoryLabelHeightGap = 10 / tempScale;

  useRotateLabels({
    active: {
      slideID,
      fieldID,
    },
    dataSet: labels,
    fontSize: categoryFontSize,
    fontFamily,
    barMargin,
    barSeriesWidth: barWidth * seriesLength,
    rotateText: styles.rotateCategory,
    tempLayout: {
      size: {
        w: tempSize.w,
        h: tempSize.h,
      },
      scale: tempScale,
    },
    groupedResize: groupSelect.keys.length > 0 ? true : false,
  });

  return (
    <g>
      {labels.map((item, index) => {
        return !rotateCategory ? (
          // STRAIGNT
          <ValueLabel
            key={index}
            x={
              (barWidth * seriesLength + barMargin) * index +
              edgeSpace +
              barWidth * (seriesLength / 2)
            }
            y={chartHeight + categoryLabelHeightGap}
            color={categoryColor}
            value={item}
            fontSize={`${categoryFontSize / chartDefaultFontSize}em`} // categoryFontSize
            fontFamily={fontFamily}
            textAnchor="middle"
            dominantBaseline="text-before-edge"
            alignmentBaseline="before-edge"
          />
        ) : (
          // FOR CURVE
          <ValueLabel
            key={index}
            x={
              index !== 0
                ? (barWidth * seriesLength + barMargin) * index +
                  edgeSpace +
                  barWidth * (seriesLength / 2)
                : edgeSpace + barWidth * (seriesLength / 2)
            }
            y={chartHeight + categoryLabelHeightGap}
            color={categoryColor}
            value={item}
            fontSize={`${categoryFontSize / chartDefaultFontSize}em`}
            fontFamily={fontFamily}
            textAnchor="end"
            alignmentBaseline="before-edge"
            rotate={rotatedDeg}
            dominantBaseline="text-before-edge"
          />
        );
      })}
    </g>
  );
};

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    groupSelect: selectInterceptedFields(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(CategoryLabels);
