const SingleBarChartIcon = (
  <svg width="100%" height="100%" viewBox="0 0 610.5400000000001 528.22">
    <g>
      <rect
        fill="#6e61e5ff"
        x="0"
        y="51.45"
        height="68.60000000000002"
        width="162.09026548672568"
      ></rect>
      <rect
        fill="#6e61e5ff"
        x="0"
        y="170.35666666666668"
        height="68.60000000000002"
        width="378.2106194690266"
      ></rect>
      <rect
        fill="#6e61e5ff"
        x="0"
        y="289.2633333333333"
        height="68.60000000000002"
        width="610.5400000000001"
      ></rect>
      <rect
        fill="#6e61e5ff"
        x="0"
        y="408.17"
        height="68.60000000000002"
        width="226.92637168141596"
      ></rect>
    </g>
    <g>
      <line
        x1="0"
        x2="0"
        y1="0"
        y2="528.22"
        stroke="#232323"
        strokeWidth="1"
      ></line>
    </g>
    <g>
      <line
        x1="0"
        x2="610.5400000000001"
        y1="528.22"
        y2="528.22"
        stroke="#232323"
        strokeWidth="1"
      ></line>
    </g>
  </svg>
);

export default SingleBarChartIcon;
