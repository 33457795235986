import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {createIcon} from "../../../../../store/actions/fields/icon.action";
import {XScrollItems, WrapCollection} from "../../reusableStyles/ModalContent";
import {selectTemplateSize, selectWorkspacePlan} from "../../../../../store/selectors/template/template.selector";
import {storeActionsUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {createShortKey} from "../../../../../store/reducers/functions/fields/utils";
import {closeDrawer} from "../../../../../store/actions/layout.action";
import { Spinner } from "../../../../../../oat-ui";
import { ArrowBack } from "../../../../../../oat-svg-icons";
import { GoBack, WrapSpinner, WrapItem, WrapIconItem, svgStyles } from "../reusableStyles/reusableStyles";
import { red1 } from "../../../../../../oat-color-variables";
import { selectUserInfo } from "../../../../../../../redux/user/authUser";
import PremiumImgCover from "./PremiumImgCover";
import { requiresUpgradeToProStyles } from "../../../parts/upgradeToPro";
import { ColumnTab, ColumnTabs, WrapColumnTabs, WrapTabs } from "../../reusableStyles/TabStyles";
import { SplitLine } from "../../../editingPanel/panelTypes/desktop/common/SplitLine";
import { getWindowSize } from "../../../../../../oat-window-size/getWindowSize";
import BrandFetch from "./BrandFetch";

export const iconCategories = [
  {value: "illustrations", display: "Illustrations"},
  {value: "shapes-patterns", display: "Shapes & Patterns"},
  {value: "brushes", display: "Brushes"},
  {value: "quotations", display: "Quotations"}
];

export const imageCategories = [
  {value: "transparent-items", display: "Transparent Items"},
  {value: "background", display: "Background"}
];

// here display the ready made svg without having color variables
const DisplayCollection = ({item, onSelect, workspacePlan}) => {
  const handleClick = () => {
    const isPremium = 
      item?.premium ? {
        status: true,
        premiumCover: item?.premiumDisplayImages
      } : {
        status: false,
        premiumCover: []
      };
    onSelect(item.key, item.category, isPremium);
  };

  return (
    <WrapIconItem>
      <WrapItem onClick={handleClick}>
        <img loading="lazy" src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`} alt="" />
      </WrapItem>
    </WrapIconItem>
  );
};

const DisplayItem = ({item, onCreate}) => {
  const handleClick = () => {
    onCreate(item);
  };

  return (
    <WrapIconItem>
      <WrapItem onClick={handleClick}>
        <img src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`} alt="icon" loading="lazy" />
        {/* <svg
          data-src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`}
          width="100%"
          height="100%"
        /> */}
      </WrapItem>
    </WrapIconItem>
  );
};

const Category = ({category, active, onSelect}) => {
  const handleSelect = () => {
    onSelect(category);
  };
  return (
    <CategoryItem active={active} onClick={handleSelect}>
      {category.display}
    </CategoryItem>
  );
};

const CreateIcon = ({
  createIcon,
  tempSize,
  storeActionsUndoRedo,
  slideID,
  closeModal,
  closeDrawer,
  user,
  workspacePlan
}) => {
  const [displaySection, setDisplaySection] = useState("pack-list"); // collection or pack image list
  const [selectedCategory, setSelectedCategory] = useState("illustrations");
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [selectedTab, setSelectedTab] = useState("svg");

  const handleCreate = async (item) => {
    const ratioDiff = tempSize.w / 536;
    const iconProps = {
      key: createShortKey(),
      colorSet: item?.colorSet ? item?.colorSet : [],
      content: item.image,
      type: "icon",
      format: item.imgType,
      size: {w: item.imgSize.w * ratioDiff, h: item.imgSize.h * ratioDiff}, // item.size,
      resizer: "corners",
    };
    createIcon(slideID, iconProps);
    storeActionsUndoRedo("create");
    if (window.innerWidth >= 850) {
      await closeModal();
    } else {
      await closeDrawer();
    }
  };

  const onSelectCategory = (item) => {
    setSelectedCategory(item.value);
  };

  const [iconsInsidePack, setIconsInsidePack] = useState([]);
  const [iconIsPremium, setIconIsPremium] = useState({status: false, seperatePurchase: false});
  const onSelectIconCollection = (packID, category, isPremium) => {
    setError(false);
    setIconsInsidePack([]);
    setDisplaySection("images-inside-pack");
    
    if (isPremium?.status && workspacePlan === 'free') {
      setIconIsPremium({
        status: isPremium?.status, 
        premiumCover: isPremium?.premiumCover,
        seperatePurchase: false
      });
      return;
    } 
      
    try {
      const elementType = selectedTab === 'png' ? 'images' : 'icons';
      setIconIsPremium({
        status: isPremium?.status, 
        premiumCover: isPremium?.premiumCover,
        seperatePurchase: false
      });
      setFetching(true);
      fetch(
        // `https://oat-images-icons.s3.amazonaws.com/1707325134684-q1ns899qqd8.json`
        // `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/icons/${packID}`
        `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${elementType}/${category}/${packID}`
        )
        .then((res)=> res.json())
        .then((data)=> {
          setFetching(false)
          setIconsInsidePack(data) // data
        })
        .catch ((e) => {
          setFetching(false);
          setError(true);
        })
    } catch (e) {
      setFetching(false)
      // don't remove catch
    }
  }

  const [iconPack, setIconPack] = useState([]);
  useEffect(()=>{
    const elementType = selectedTab === 'png' ? 'images' : 'icons';
    setIconPack([]);
    setFetching(true);
    setError(false);
    try {
      fetch(
        `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${elementType}?category=${selectedCategory}`)
        .then((res)=> res.json())
        .then((data)=> {
          setFetching(false)
          setIconPack(data.items)
        })
        .catch ((e) => {
          setFetching(false);
          setError(true);
        })  // don't remove catch
    } catch (e) {
      setFetching(false);
      setError(true);
    }
  }, [selectedCategory, selectedTab]);
 
  const handleGoBackToOPackages = () =>{
    setDisplaySection("pack-list")
  };

  const windowSize = getWindowSize();
  const modalHeight = (windowSize.height * 30) / 100;

  const handleTabSelection = (value) => {
    setSelectedTab(value);
    setDisplaySection("pack-list");

    if(value === 'png') {
      setSelectedCategory("transparent-items")
    } else if (value === 'svg') {
      setSelectedCategory("illustrations")
    } else if (value === 'brand-fetch') {
      setSelectedCategory("brand-fetch")
    }
  }
  
  return (
    <Wrapper style={{height: window.innerWidth >= 850 ? '100%' : undefined}}>
      <WrapColumnTabs 
        style={{
          flex: window.innerWidth >= 850 ? 1 : undefined, 
          overflow: 'hidden', 
          height: window.innerWidth >= 850 ? '100%' : undefined
        }}>
        <ColumnTabs style={{justifyContent: "space-between"}}>
          <WrapTabs>
              <ColumnTab
                onClick={() => handleTabSelection("svg")}
                selected={selectedTab === "svg" ? true : false}
              >
                SVG
              </ColumnTab>
              <ColumnTab
                onClick={() => handleTabSelection("png")}
                selected={selectedTab === "png" ? true : false}
              >
                PNG
              </ColumnTab>
              {/* <ColumnTab
                onClick={() => handleTabSelection("brand-fetch")}
                selected={selectedTab === "brand-fetch" ? true : false}
              >
                Brand Fetch
              </ColumnTab> */}
          </WrapTabs>
        </ColumnTabs>
      </WrapColumnTabs>

      {window.innerWidth < 850 ?  
        <SplitLine style={{ width: '100%', margin: 0}} /> : 
      undefined}

      <List>
        {/* <Header>Icons</Header> */}
        {/*  USE LATER FOR SPLITTING CATEGORIES
        {displaySection === "pack-list" ? (
          <Categories>
            {categories &&
              categories.map((category, index) => (
                <Category
                  key={index}
                  category={category}
                  onSelect={onSelectCategory}
                  active={selectedCategory === category.value}
                />
            ))}
          </Categories>) : 
          <GoBack onClick={handleGoBackToOPackages}>
            {ArrowBack}
          </GoBack>
        } */}

        {displaySection === "pack-list" && selectedTab === "svg" ? (
          <Categories>
            {iconCategories &&
              iconCategories.map((category, index) => (
                <Category
                  key={index}
                  category={category}
                  onSelect={onSelectCategory}
                  active={selectedCategory === category.value}
                />
            ))}
          </Categories>
        ) : undefined}

        {displaySection === "images-inside-pack" ? (
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <GoBack onClick={handleGoBackToOPackages}>
              {ArrowBack}
            </GoBack>
            {workspacePlan === 'free' && iconIsPremium.status ? 
              <RequiresUpgradeToPro href='/pricing' target="_blank">Pro</RequiresUpgradeToPro> 
            : undefined}
          </div>
        ) : undefined}

          {/* do not move position */}
        {error ? <Error>Something went wrong</Error> : undefined}

        {!fetching ? (
          <>
            <ListIcons modalHeight={modalHeight}>
              <WrapItems
                style={{
                  zIndex: displaySection === "pack-list" ? 1000 : 0,
                  opacity: displaySection === "pack-list" ? 1 : 0,
                  width: '100%',
                  height: displaySection === "pack-list" ? 'auto' : 0                 
                }}
              >
                {iconPack && iconPack.map((item, i) => {
                  if (user.staff.status) {
                    return ( 
                      <DisplayCollection 
                        key={item.key} 
                        item={item} 
                        onSelect={onSelectIconCollection} 
                        workspacePlan={workspacePlan}
                      /> )
                  } else if (item.key !== "1707542393714-ae80vune0ym") {
                    return (
                      <DisplayCollection 
                        key={item.key} 
                        item={item} 
                        onSelect={onSelectIconCollection} 
                        workspacePlan={workspacePlan} 
                      />
                    );
                  }
                })}
              </WrapItems>

              <WrapItems
                style={{
                  zIndex: displaySection === "images-inside-pack" ? 1000 : 0,
                  opacity: displaySection === "images-inside-pack" ? 1 : 0,              
                  width: '100%',
                  height: displaySection === "images-inside-pack" ? 'auto' : 0 
                }}
              >
                {iconsInsidePack && iconsInsidePack.map((item, i) => {
                  return (
                    <DisplayItem key={i} item={item} onCreate={handleCreate} />
                  );
                })}
              </WrapItems>

              {selectedTab === "brand-fetch" ? (
                <WrapItems
                  style={{
                    zIndex: 1000,
                    opacity: 1,              
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    justifyContent: 'flex-start'
                  }}
                >
                  <BrandFetch onCreate={handleCreate} />
                </WrapItems> 
              ) : undefined}

              {displaySection === "images-inside-pack" && iconIsPremium.status && workspacePlan === 'free' ? (
                iconIsPremium.premiumCover && iconIsPremium.premiumCover.map((item, i) => (
                    <PremiumImgCover index={i} key={i} item={item} />
                  )
                )
              ) : undefined}
            </ListIcons>
          </>
        ) : undefined}

        {fetching ? (
          <WrapSpinner>
            <Spinner />
          </WrapSpinner>
        ) : undefined}

      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  @media only screen and (max-width: 849px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
`;

const Header = styled.h3`
  font-size: 1.1rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  margin: 12px 0 20px 22px;
`;

const WrapCollectionSvg = styled.div`
  margin-right: 18px;
  ${svgStyles};
  background: ${({theme}) => theme.deskSecondaryBg4};
  border-radius: 10px;
  flex: 0 100%;
  padding: 6%;
  @media only screen and (max-width: 849px) {
    flex: 0 100%;
    height: 120px;
  }
`;


const WrapCollectionItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const List = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 1% 0;
  box-sizing: border-box;
  @media only screen and (min-width: 85px) {
    flex: 3;
  }
`;

const ListIcons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: ${({modalHeight}) => window.innerHeight - modalHeight}px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0 15px 12px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RequiresUpgradeToPro = styled.a`
  ${requiresUpgradeToProStyles};
  margin: 0 16px 15px 0;
`;

export const CategoryItem = styled.div`
  color: ${({theme}) => theme.deskSecondaryTxt};
  font-size: 0.85rem;
  border-radius: 15px;
  margin-right: 10px;
  padding: 6px 24px;
  background: ${({theme, active}) =>
    active ? theme.deskSecondaryBg4 : theme.deskSecondaryBg3};
  flex: 0 0 auto;
  cursor: pointer;
`;

export const Error = styled.div`
  color: ${red1};
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
`;

export const WrapItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
    user: selectUserInfo(state.authUser),
    workspacePlan: selectWorkspacePlan(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  createIcon,
  storeActionsUndoRedo,
  closeDrawer,
})(CreateIcon);
