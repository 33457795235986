import React from "react";
import styled from "styled-components";
import {mainDarkBlueTransparent1} from "../../oat-color-variables";

// not working , not fully completed
const Tooltip = ({children}) => {
  return (
    <Wrapper>
      {/* in
      <DisplayTooltip>{children}</DisplayTooltip> */}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const DisplayTooltip = styled.div``;

export default Tooltip;
