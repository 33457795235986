const DonutChartIcon = (
  <svg width="100%" height="100%" viewBox="0 0 514.5 514.5">
    <g>
      <path
        className="donut-chart-segment"
        fill="#ef8181"
        stroke="none"
        d="M258.24999433343646 85.75291544363475 L258.2499974815249 0.0019436321492207753 A257.25 257.25 0 0 1 393.5238657042027 475.4402748200133 L348.3817934680883 402.5333308376763 A171.5 171.5 0 0 0 258.24999433343646 85.75291544363475"
      ></path>
    </g>
    <g>
      <path
        className="donut-chart-segment"
        fill="#f0de6fff"
        stroke="none"
        d="M346.681368832248 403.5861891973084 L391.8234357152447 476.49313649415546 A257.25 257.25 0 0 1 52.56425245275594 413.07460412600403 L120.99500331719645 361.39809589699274 A171.5 171.5 0 0 0 346.681368832248 403.5861891973084"
      ></path>
    </g>
    <g>
      <path
        className="donut-chart-segment"
        fill="#a8a4f8"
        stroke="none"
        d="M119.78974087417299 359.8020704864631 L51.35898621543814 411.47857369101666 A257.25 257.25 0 0 1 256.25000251847473 0.0019436321492207753 L256.2500056665634 85.75291544363475 A171.5 171.5 0 0 0 119.78974087417299 359.8020704864631"
      ></path>
    </g>
  </svg>
);

export default DonutChartIcon;
