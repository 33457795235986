import styled from "styled-components";

export const ErrorMsg = styled.div`
  font-size: 0.95rem;
  color: rgb(212 68 68);
  text-align: right;
  padding: 8px 0;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-bottom: 12px;
`;
