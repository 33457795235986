import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {Bold, Italic} from "../../../../../../../../oat-svg-icons";
import {
  updateFieldStyles,
  onSelectFontType,
} from "../../../../../../../store/actions/fields/common.action";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";
import {selectLibraryCustomFonts} from "../../../../../../../store/selectors/common.selector";
import {selectActiveFieldProps} from "../../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {googleFonts} from "../../../../../../../ui/fonts/googleFontLinks";
import {Title} from "../../../shared/reuseStyles/Title";
import SelectFontTypes from "./SelectFontTypes";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {updateTextContent} from "../../../../../../../store/actions/fields/text.action";
import {
  moveContentBefore,
  wrapRangeInWordSpan,
  wrapRangeInNestedWordSpan,
  handleContentChange,
} from "../../../../../../../utils/rangeSelector";

const ItalicBold = ({
  slideIndex,
  activeField,
  updateFieldStyles,
  onSelectFontType,
  library,
  storeFieldUndoRedo,
  updateFieldCollaboration,
  updateTextContent,
}) => {
  const {fontFamily, fontStyle, fontWeight} = activeField?.styles || {};
  const activeSlideFieldInfo = {
    slideIndex: slideIndex,
    fieldID: activeField?.key,
  };

  const changeFontType = (item) => {
    const {fontType, fontWeight, fontStyle, src, format} = item;
    onSelectFontType({
      fontWeight,
      fontStyle,
      fontType,
      src,
      format,
    });
    updateFieldCollaboration();
  };

  const handleStyleChanges = (type, value) => {
    updateFieldStyles({
      active: {
        slideID: slideIndex,
        fieldID: activeField?.key,
      },
      type,
      value,
    });
  };

  const onSelectBold = async () => {
    const fontWeightAsNumber = Number(fontWeight);
    const value =
      fontWeightAsNumber <= 500 || fontWeight === "normal" ? "700" : "400";

    if (window.getSelection) {
      const sel = window.getSelection();

      if (sel.rangeCount === 0 || sel.isCollapsed) {
        handleStyleChanges("fontWeight", value);
      } else if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0).cloneRange();
        if (range.collapsed) {
          return;
        }

        const parentElement =
          sel.getRangeAt(0).commonAncestorContainer.parentElement;
        // first pass
        if (Number(parentElement.style.fontWeight) === 0) {
          wrapRangeInWordSpan(range, "font-weight", value);
        } else if (
          parentElement &&
          (parentElement.style.fontWeight === "bold" ||
            parentElement.style.fontWeight > 500)
        ) {
          wrapRangeInWordSpan(range, "font-weight", "normal");
        } else {
          wrapRangeInWordSpan(range, "font-weight", "bold");
        }

        // second pass, find the nested .innerTextStyle
        // and wrap the content before and after it in their own spans
        const inner = document.querySelector(".innerTextStyle .innerTextStyle");
        if (!inner) {
          // when selecting two .innerTextStyle start to end, where the empty spans stick around
          // we remove them here
          range.startContainer.parentNode
            .querySelectorAll(".innerTextStyle:empty")
            .forEach((node) => node.remove());

          const updatedContent = await handleContentChange(
            range,
            activeField?.subtype
          );
          await updateTextContent(activeSlideFieldInfo, updatedContent);
          await storeFieldUndoRedo();
          await updateFieldCollaboration();

          // const newRange = document.createRange();
          // // Identify the new start and end positions after color modification
          // const newStartContainer = range.startContainer;
          // const newEndContainer = range.endContainer;
          // // Create a new range and set it to the desired portion
          // newRange.setStart(newStartContainer, range.startOffset);
          // newRange.setEnd(newEndContainer, range.endOffset);
          // // Set the start and end points of the range to select the current selection
          // sel.removeAllRanges();
          // sel.addRange(newRange);
        } else {
          const parent = inner.closest(".innerTextStyle:not(:scope)");
          const extractingRange = document.createRange();
          // wrap the content before
          extractingRange.selectNode(parent);
          extractingRange.setEndBefore(inner);

          const payload = {
            "font-weight": parentElement.style.fontWeight, // "bold",
            "font-style": parentElement.style.fontStyle,
            color: parentElement.style.color,
          };
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // wrap the content after
          extractingRange.selectNode(parent);

          // Capture the selection offsets
          // Code order matters here
          const selectionStartOffset = extractingRange.startOffset;
          const selectionEndOffset = extractingRange.endOffset;

          extractingRange.setStartAfter(inner);
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // finally, extract all the contents from parent
          // to its own parent and remove it, now that it's empty
          moveContentBefore(parent);
          const updatedContent = await handleContentChange(
            range,
            activeField?.subtype
          );
          await updateTextContent(activeSlideFieldInfo, updatedContent);

          // const newRange = document.createRange();
          // // Identify the new start and end positions after color modification
          // const newStartContainer = range.startContainer;
          // const newEndContainer = range.endContainer;
          // // Create a new range and set it to the desired portion
          // newRange.setStart(newStartContainer, selectionStartOffset);
          // newRange.setEnd(newEndContainer, selectionEndOffset);
          // // Set the start and end points of the range to select "World"
          // sel.removeAllRanges();
          // sel.addRange(newRange);
        }
      }
    }
    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  const onSelectItalic = async () => {
    if (window.getSelection) {
      const sel = window.getSelection();
      const value = fontStyle === "italic" ? "normal" : "italic";
      if (sel.rangeCount === 0 || sel.isCollapsed) {
        handleStyleChanges("fontStyle", value);
      } else if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0).cloneRange();
        if (range.collapsed) {
          return;
        }

        const parentElement =
          sel.getRangeAt(0).commonAncestorContainer.parentElement;
        // first pass
        if (parentElement.style.fontStyle == "") {
          wrapRangeInWordSpan(range, "font-style", value);
        } else if (
          parentElement &&
          parentElement.style.fontStyle === "italic"
        ) {
          wrapRangeInWordSpan(range, "font-style", "normal");
        } else {
          wrapRangeInWordSpan(range, "font-style", "italic");
        }

        // second pass, find the nested .innerTextStyle
        // and wrap the content before and after it in their own spans
        const inner = document.querySelector(".innerTextStyle .innerTextStyle");
        if (!inner) {
          // when selecting two .innerTextStyle start to end, where the empty spans stick around
          // we remove them here
          range.startContainer.parentNode
            .querySelectorAll(".innerTextStyle:empty")
            .forEach((node) => node.remove());

          const updatedContent = await handleContentChange(
            range,
            activeField?.subtype
          );
          await updateTextContent(activeSlideFieldInfo, updatedContent);
          await storeFieldUndoRedo();
          await updateFieldCollaboration();

          // const newRange = document.createRange();
          // // Identify the new start and end positions after color modification
          // const newStartContainer = range.startContainer;
          // const newEndContainer = range.endContainer;
          // // Create a new range and set it to the desired portion
          // newRange.setStart(newStartContainer, range.startOffset);
          // newRange.setEnd(newEndContainer, range.endOffset);
          // // Set the start and end points of the range to select the current selection
          // sel.removeAllRanges();
          // sel.addRange(newRange);
        } else {
          const parent = inner.closest(".innerTextStyle:not(:scope)");
          const extractingRange = document.createRange();
          // wrap the content before
          extractingRange.selectNode(parent);
          extractingRange.setEndBefore(inner);

          const payload = {
            "font-style": parentElement.style.fontStyle, //"italic",
            "font-weight": parentElement.style.fontWeight,
            color: parentElement.style.color,
          };
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // wrap the content after
          extractingRange.selectNode(parent);

          // Capture the selection offsets
          // Code order matters here
          const selectionStartOffset = extractingRange.startOffset;
          const selectionEndOffset = extractingRange.endOffset;

          extractingRange.setStartAfter(inner);
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // finally, extract all the contents from parent
          // to its own parent and remove it, now that it's empty
          moveContentBefore(parent);

          const updatedContent = await handleContentChange(
            range,
            activeField?.subtype
          );
          await updateTextContent(activeSlideFieldInfo, updatedContent);

          // const newRange = document.createRange();
          // // Identify the new start and end positions after color modification
          // const newStartContainer = range.startContainer;
          // const newEndContainer = range.endContainer;
          // // Create a new range and set it to the desired portion
          // newRange.setStart(newStartContainer, selectionStartOffset);
          // newRange.setEnd(newEndContainer, selectionEndOffset);
          // // Set the start and end points of the range to select "World"
          // sel.removeAllRanges();
          // sel.addRange(newRange);
        }
      }
      await storeFieldUndoRedo();
      await updateFieldCollaboration();
    }
  };

  return (
    <div style={{display: "flex"}}>
      <Title>Font Style</Title>
      <Wrapper style={{flexDirection: "column"}}>
        <Wrapper style={{marginBottom: "1.5rem"}}>
          {/* no italic available */}
          <WrapSvg 
            onClick={googleFonts[fontFamily]?.types?.length <= 2 ? undefined : onSelectItalic} 
            style={{opacity: googleFonts[fontFamily]?.types?.length <= 2 ? 0.4 : 1}}>
              {Italic}
          </WrapSvg> 
          <WrapSvg onClick={onSelectBold}>{Bold}</WrapSvg>
        </Wrapper>

        {library && library.fonts && Object.keys(library.fonts).length !== 0 ? (
          <Wrapper>
            {!activeField?.font?.custom ? (
              <SelectFontTypes
                onSelect={changeFontType}
                selected={activeField?.font?.fontType}
                options={googleFonts[fontFamily]?.types}
                overflowY="scroll"
                height={160}
                fontFamily={fontFamily}
              />
            ) : (
              <SelectFontTypes
                onSelect={changeFontType}
                selected={activeField?.font?.fontType}
                options={
                  library.fonts[fontFamily] && library.fonts[fontFamily]?.types
                }
                overflowY="scroll"
                height={160}
                fontFamily={fontFamily}
              />
            )}
          </Wrapper>
        ) : undefined}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

const WrapSvg = styled.div`
  fill: ${({theme}) => theme.deskSecondaryTxt};
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 24px;
  font-weight: 700;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideIndex: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    library: selectLibraryCustomFonts(designTemplate),
  };
};

export default connect(mapStateToProps, {
  storeFieldUndoRedo,
  onSelectFontType,
  updateFieldStyles,
  updateFieldCollaboration,
  updateTextContent,
})(ItalicBold);
