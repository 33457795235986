import React from "react";
import styled from "styled-components";
import {Option} from "../../../../../oat-ui/styles/SelectOption";

const ImageActions = ({onUpdateInfo, onDelete}) => {
  return (
    <Wrapper>
      <Option onClick={onUpdateInfo}>
        <div>Edit Info</div>
      </Option>
      <Option onClick={onDelete}>
        <div style={{color: "#ff2f2f"}}>Delete</div>
      </Option>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 8px 0;
`;

export default ImageActions;
