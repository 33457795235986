import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {Modal} from "../../../../oat-ui";
import {
  cloneSlide,
  deleteSlide,
} from "../../../store/actions/template/slide.action";
import {Dot, Option} from "../../../ui/styles/SelectStyles";
import {useSocket} from "../../../webSocket/useSocket";
import {storeActionsUndoRedo} from "../../../store/actions/fields/undoRedo.action";

const SlideListPopper = ({
  deleteSlide,
  cloneSlide,
  selectedSlide,
  onClose,
  slidesLength,
  storeActionsUndoRedo,
}) => {
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const {emitSocketEvents} = useSocket() || {};

  const handleSlideDeletion = () => {
    if (slidesLength !== 1) {
      deleteSlide(selectedSlide);
      const item = {type: "", value: selectedSlide};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "delete-slide", item});
      }
      onClose();
    } else {
      setError({
        status: true,
        message: "Last remaining slide cannot be deleted.",
      });
    }
  };

  const handleSlideDuplication = () => {
    cloneSlide(selectedSlide);
    // storeActionsUndoRedo("duplicate");
    onClose();
  };

  const handleModalClose = () => {
    setError({
      status: false,
      message: null,
    });
    onClose();
  };

  return (
    <BoxContent>
      <Option onClick={handleSlideDuplication}>
        <p>Duplicate Slide</p>
        <Dot />
      </Option>
      <Option onClick={handleSlideDeletion}>
        <p style={{color: "#ff7070", fontWeight: 600}}>Delete Slide</p>
        <Dot />
      </Option>

      {error.status ? (
        <Modal width={390} height={80} close={handleModalClose}>
          <Error>{error.message}</Error>
        </Modal>
      ) : undefined}
    </BoxContent>
  );
};

const BoxContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${({theme}) => theme.deskPrimaryBg};
  box-sizing: border-box;
`;

const Error = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  background: ${({theme}) => theme.deskPrimaryBg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default connect(null, {deleteSlide, storeActionsUndoRedo, cloneSlide})(
  SlideListPopper
);
