export const sidePadding = window.innerWidth > 768 ? 32 : 22;

export const layoutPadding = {
  mobile: "1.8em",
  desktop: "2.2em",
};

export const descriptionFontSize = {
  mobile: "1.2em",
  desktop: "0.95em",
};

export const defaultMaxWidthLaptop = 1040;
export const defaultMaxWidthLaptop2 = 920;
export const defaultMaxWidthDesktop = 1320;

export const headerFontSize = "1.7em";
