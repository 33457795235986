import React, {useEffect, useState} from "react";
import {WrapResizer, CornerResizer} from "./../resize/Resizer";
import styled from "styled-components";
import "./../resize/resizer.css";
import {
  getOriginalOffset,
  updateMultiplePosition,
  updateGroupFieldSize,
  storeGroupFieldsInfoUndoRedo,
} from "../../../../../store/actions/fields/groupSelection.action";
import {batch, connect} from "react-redux";
import {centerToTL, getAngle} from "../utils";
import {selectEmitItemsToSocket} from "../../../../../store/selectors/common.selector";
import {calcGroupSelection} from "./../groupSelection/calcGroupSelection";
import {calcOnResize} from "./../resize/utils/calcOnResize";
import {selectTempRatioDiff} from "../../../../../store/selectors/layout/layout.selector";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {useSocket} from "../../../../../webSocket/useSocket";
import {selectTemplateSize} from "../../../../../store/selectors/template/template.selector";
import {resizerColor} from "../../../../../themes";
import {selectCoordinates} from "../../../../../store/selectors/smartAlign.selector";
import {setSmartAlignment} from "../../../../../store/actions/template/slide.action";
import {
  getItemXOffset,
  getItemYOffset,
} from "../../../../../utils/getItemOffset";
import { handleAlignmentY } from "./groupAlignments";
import { Rotate, WrapRotate } from "../resize/Resizable";
import { RotateSvg } from "../../../../../ui/svg/RotateSvg";

const directions = [
  {className: "resizer top-left", dir: "top-left", cursor: "nwse-resize"},
  {className: "resizer top-right", dir: "top-right", cursor: "nesw-resize"},
  {className: "resizer bottom-left", dir: "bottom-left", cursor: "nesw-resize"},
  {
    className: "resizer bottom-right",
    dir: "bottom-right",
    cursor: "nwse-resize",
  },
];

let alignment = {
  x: {
    value: null,
    display: false,
  },
  y: {
    value: null,
    display: false,
  },
};

const DisplayResizer = ({item, onResizeInit}) => {
  const handleResize = (e) => {
    onResizeInit(e, item);
  };
  return (
    <WrapResizer
      onMouseDown={handleResize}
      className={item.className}
      style={{display: "flex"}}
    >
      <CornerResizer
        style={{
          cursor: item.cursor,
        }}
      />
    </WrapResizer>
  );
};

const GroupDragResize = ({
  tempScale,
  updateMultiplePosition,
  getOriginalOffset,
  slide,
  updateGroupFieldSize,
  username,
  collaboration,
  groupedItems,
  usersAvailableOnline,
  tempSize,
  smartAlignments,
  setSmartAlignment,
  storeGroupFieldsInfoUndoRedo,
  itemsToEmitSocket,
}) => {
  const [groupDrag, setGroupDrag] = useState({
    dragging: false,
    orig: {},
  });
  const [groupResize, setGroupResize] = useState({
    resizing: false,
    orig: {},
    size: {},
    fields: [],
    direction: "",
  });
  const [emitToSocket, setEmitToSocket] = useState(false);
  const {emitSocketEvents} = useSocket() || {};

  const onDragStart = (e) => {
    if (!collaboration) {
      const {x, y} = groupedItems.selection.offset;
      const clientX = e.clientX / tempScale;
      const clientY = e.clientY / tempScale;

      const {size, offset, initValues} = calcGroupSelection(
        slide,
        groupedItems.keys
      );
      getOriginalOffset(size, offset, initValues, username);

      setGroupDrag({
        ...groupDrag,
        dragging: true, //if lock will be checked in redux
        orig: {
          initialX: Math.round(clientX - x),
          initialY: Math.round(clientY - y),
          mouseX: Math.round(clientX),
          mouseY: Math.round(clientY),
          width: groupedItems.selection.size.w,
          height: groupedItems.selection.size.h,
        },
        orgFieldPos: {
          x,
          y,
        },
      });
      storeGroupFieldsInfoUndoRedo("start");
      e.stopPropagation();
    }
  };

  const onDrag = (e) => {
    const {orig, dragging, orgFieldPos} = groupDrag;
    if (dragging) {
      const clientX = getItemXOffset(e, tempScale);
      const clientY = getItemYOffset(e, tempScale);
      const {initialX, initialY} = orig;
      const currentX = Math.round(clientX - initialX);
      const currentY = Math.round(clientY - initialY);

      // to continue dragging on while the alignment line is showing
      const dragXWithinRange = (1 * tempSize.w) / 100;
      const dragYWithinRange = (1 * tempSize.h) / 100;
      // const currentX = Math.round(e.clientX - initialX * tempScale),

      const size = {w: orig.width, h: orig.height};

      // only allows shape and image to show center alignment
      // for eg, if shape is being dragged and trying to position its starting edge corner
      // to that of image's center, alignment will be shown
      if (
        smartAlignments.x[currentX] &&
        smartAlignments.x[currentX] === "corner" &&
        !alignment.x.display
      ) {
        alignment.x.value = currentX;
        alignment.x.display = true;
      } else if (
        smartAlignments.x[Math.round(currentX + size.w)] === "corner" &&
        !alignment.x.display
      ) {
        alignment.x.value = currentX + size.w;
        alignment.x.display = true;
      } else if (
        smartAlignments.x[Math.round(currentX + size.w / 2)] === "center" &&
        !alignment.x.display
      ) {
        alignment.x.value = currentX + size.w / 2;
        alignment.x.display = true;
      } else if (
        smartAlignments.x[Math.round(currentX + (size.w / 2))] === "field-centred-template" &&
        !alignment.x.display
      ) {
        alignment.x.value = currentX + (size.w / 2);
        alignment.x.display = true;
        // we don't wanna show field edging the mid point of a template
        alignment.x.type = "field-centred-template";
      }

      if (
        smartAlignments.y[currentY] &&
        smartAlignments.y[currentY] === "corner" &&
        !alignment.y.display
      ) {
        alignment.y.value = currentY;
        alignment.y.display = true;
      } else if (
        smartAlignments.y[Math.round(currentY + size.h)] === "corner" &&
        !alignment.y.display
      ) {
        alignment.y.value = currentY + size.h;
        alignment.y.display = true;
      } else if (
        smartAlignments.y[Math.round(currentY + size.h / 2)] === "center" &&
        !alignment.y.display
      ) {
        alignment.y.value = currentY + size.h / 2;
        alignment.y.display = true;
      } else if (
        smartAlignments.y[Math.round(currentY + size.h / 2)] === "field-centred-template" &&
        !alignment.x.display
      ) {
        alignment.y.value = currentY + size.h / 2;
        alignment.y.display = true;
        // we don't wanna show field edging the mid point of a template
        alignment.y.type = "field-centred-template";
      } 

      if (
        currentX > 0 &&
        currentX > alignment.x.value - dragXWithinRange && // 160
        currentX < alignment.x.value + dragXWithinRange
      ) {
        const yValue = handleAlignmentY(currentY, dragYWithinRange, alignment, size, orgFieldPos);
        setSmartAlignment({type: "y", value: alignment.y.value});
        
        updateMultiplePosition(
          alignment.x.value - orgFieldPos.x,
          yValue ? yValue : currentY - orgFieldPos.y
        );
        setSmartAlignment({
          type: "x",
          value: alignment.x.value,
        });
        
        if (!yValue) {
          setSmartAlignment({
            type: "y",
            value: null
          });
        }        
        return;
      } else if (
        currentX + size.w > alignment.x.value - dragXWithinRange && // 160
        currentX + size.w < alignment.x.value + dragXWithinRange
      ) {
        const yValue = handleAlignmentY(currentY, dragYWithinRange, alignment, size, orgFieldPos);
        setSmartAlignment({type: "y", value: alignment.y.value});

        // if two lines are not intercepted
        updateMultiplePosition(
          alignment.x.value - size.w - orgFieldPos.x,
          yValue ? yValue : currentY - orgFieldPos.y
        );
        setSmartAlignment({
          type: "x",
          value: alignment.x.value,
        });

        if (!yValue) {
          setSmartAlignment({
            type: "y",
            value: null
          });
        }        
        return;
      } else if (
        currentX + size.w / 2 > alignment.x.value - dragXWithinRange && // 160
        currentX + size.w / 2 < alignment.x.value + dragXWithinRange
      ) {
        const yValue = handleAlignmentY(currentY, dragYWithinRange, alignment, size, orgFieldPos);
        setSmartAlignment({type: "y", value: alignment.y.value});

        updateMultiplePosition(
          alignment.x.value - size.w / 2 - orgFieldPos.x,
          yValue ? yValue : currentY - orgFieldPos.y
        );
        setSmartAlignment({
          type: "x",
          value: alignment.x.value,
        });

        if (!yValue) {
          setSmartAlignment({
            type: "y",
            value: null
          });
        }
        return;
      } else {
        setSmartAlignment({
          type: "x",
          value: null
        });
      }

      
      if (
        currentY > alignment.y.value - dragYWithinRange &&
        currentY < alignment.y.value + dragYWithinRange
      ) {
        updateMultiplePosition(
          currentX - orgFieldPos.x,
          alignment.y.value - orgFieldPos.y
        );
        setSmartAlignment({type: "y", value: alignment.y.value});
        return;
      } else if (
        currentY + size.h > alignment.y.value - dragYWithinRange &&
        currentY + size.h < alignment.y.value + dragYWithinRange
      ) {
        const yOffset = alignment.y.value - size.h - orgFieldPos.y;
        updateMultiplePosition(currentX - orgFieldPos.x, yOffset);
        setSmartAlignment({type: "y", value: alignment.y.value});
        return;
      } else {
        setSmartAlignment({
          type: "y",
          value: null
        });
      }



      // Calculate the field that has been dragged to get addedX & addedY
      updateMultiplePosition(
        currentX - orgFieldPos.x,
        currentY - orgFieldPos.y
      );

      alignment.x.display = false;
      setSmartAlignment({type: "x", value: null});

      alignment.y.display = false;
      setSmartAlignment({type: "y", value: null});

      e.stopPropagation();
    }
  };

  const onDragEnd = async (e) => {
    await setGroupDrag({
      ...groupDrag,
      dragging: false,
    });
    storeGroupFieldsInfoUndoRedo("end");
    setEmitToSocket(true);
    e.preventDefault();
    e.stopPropagation();
  };

  // Group resize start
  const onResizeStart = (e, direction) => {
    if (!collaboration) {
      let arr = [];
      const {x, y} = groupedItems.selection.offset;
      const clientX = getItemXOffset(e, tempScale);
      const clientY = getItemYOffset(e, tempScale);

      Object.entries(slide.fields).map(([id, field]) => {
        if (groupedItems?.keys?.includes(field.key)) {
          arr.push(field);
          setGroupResize({
            ...groupResize,
            fields: arr,
            size: {
              w: groupedItems.selection.size.w,
              h: groupedItems.selection.size.h,
            },
            orig: {
              initailX: Math.round(clientX - x),
              initialY: Math.round(clientY - y),
              mouseX: Math.round(clientX),
              mouseY: Math.round(clientY),
              width: groupedItems.selection.size.w,
              height: groupedItems.selection.size.h,
              x: groupedItems.selection.offset.x,
              y: groupedItems.selection.offset.y,
            },
            resizing: true,
            direction,
          });
        }
      });
      storeGroupFieldsInfoUndoRedo("start");
      e.stopPropagation();
    }
  };

  const onResize = (e) => {
    const {centerX, centerY, width, height, rotate} = calcOnResize({
      e,
      size: {
        w: groupResize.orig.width,
        h: groupResize.orig.height,
      },
      pos: {x: groupResize.orig.x, y: groupResize.orig.y},
      initialProps: groupResize,
      tempScale,
      rotate: 0,
      direction: groupResize.direction,
    });
    const resize = centerToTL({centerX, centerY, width, height, rotate});

    const updatedOffset = {
      x: resize.left,
      y: resize.top,
    };
    const updatedSize = {
      w: resize.width,
      h: resize.height,
    };

    updateGroupFieldSize({
      updatedOffset,
      updatedSize,
    });
  };

  const onResizeEnd = (e) => {
    setGroupResize({
      ...groupResize,
      resizing: false,
    });
    storeGroupFieldsInfoUndoRedo("end");
    setEmitToSocket(true);
    e.stopPropagation();
  };

  useEffect(() => {
    if (emitToSocket && usersAvailableOnline) {
      if (itemsToEmitSocket.type === "emit-grouped-fields-resize") {
        const item = {
          type: "grouped-size-updated",
          value: itemsToEmitSocket.payload,
        };
        if (emitSocketEvents) {
          emitSocketEvents({actionType: "update-grouped-fields", item});
          setEmitToSocket(false);
        }
      } else if (itemsToEmitSocket.type === "emit-grouped-fields-drag") {
        const item = {
          type: "grouped-position-updated",
          value: itemsToEmitSocket.payload,
        };
        if (emitSocketEvents) {
          emitSocketEvents({actionType: "update-grouped-fields", item});
          setEmitToSocket(false);
        }
      }
    }
  }, [usersAvailableOnline, emitToSocket, itemsToEmitSocket]);

  useEffect(() => {
    if (groupDrag.dragging === true && window) {
      window.addEventListener("touchmove", onDrag);
      window.addEventListener("touchend", onDragEnd);
      window.addEventListener("mouseup", onDragEnd);
      window.addEventListener("mousemove", onDrag);
    }
    return () => {
      window.removeEventListener("touchmove", onDrag);
      window.removeEventListener("touchend", onDragEnd);
      window.removeEventListener("mouseup", onDragEnd);
      window.removeEventListener("mousemove", onDrag);
    };
  }, [groupDrag.dragging]);

  useEffect(() => {
    if (groupResize.resizing === true && window) {
      window.addEventListener("touchmove", onResize);
      window.addEventListener("touchend", onResizeEnd);
      window.addEventListener("mouseup", onResizeEnd);
      window.addEventListener("mousemove", onResize);
    }
    return () => {
      window.removeEventListener("touchmove", onResize);
      window.removeEventListener("touchend", onResizeEnd);
      window.removeEventListener("mouseup", onResizeEnd);
      window.removeEventListener("mousemove", onResize);
    };
  }, [groupResize.resizing]);

  const {size, offset} = groupedItems.selection;

  const handleResize = (e, item) => {
    onResizeStart(e, item.dir);
  };


  // ROTATE SECTION
  // const [rotate, setRotate] = useState({
  //   rotating: false,
  //   center: {},
  //   startVector: {},
  // });
  // const onRotateStart = (e) => {
  //   const clientX = getItemXOffset(e, tempScale);
  //   const clientY = getItemYOffset(e, tempScale);
  //   // const itemRect = fieldRef.getBoundingClientRect();
  //   // const {size} = selectedProps;
  //   const size = {w: 0, h: 0};
  //   const rect = {
  //     // top: itemRect.top,
  //     // left: itemRect.left,
  //   };
  //   const center = {
  //     x: rect.left / tempScale + size.w / 2,
  //     y: rect.top / tempScale + size.h / 2,
  //   };
  //   const startPt = {
  //     x: clientX - center.x,
  //     y: clientY - center.y,
  //   };
  //   setRotate({
  //     rotating: true,
  //     center,
  //     startPt,
  //   });
  //   // storeFieldUndoRedo();
  //   e.stopPropagation();
  // };

  // const onRotate = (e) => {
  //   e.stopImmediatePropagation();
  //   const {rotating, startPt, center} = rotate;
  //   if (rotating) {
  //     const clientX = getItemXOffset(e, tempScale);
  //     const clientY = getItemYOffset(e, tempScale);
  //     const rotateVector = {
  //       x: clientX - center.x,
  //       y: clientY - center.y,
  //     };
  //     let rotatedAngle = getAngle(startPt, rotateVector);
  //     let angle = Math.round(rotatedAngle + selectedProps?.styles.rotate);
  //     if (angle >= 360) {
  //       angle -= 360;
  //     } else if (angle < 0) {
  //       angle += 360;
  //     }
  //     if (angle > 356 || angle < 4) {
  //       angle = 0;
  //     } else if (angle > 86 && angle < 94) {
  //       angle = 90;
  //     } else if (angle > 176 && angle < 184) {
  //       angle = 180;
  //     } else if (angle > 266 && angle < 274) {
  //       angle = 270;
  //     }
  //     // onUpdateStyles("rotate", Math.round(angle));
  //   }
  // };

  // const onRotateStop = (e) => {
  //   setRotate({
  //     ...rotate,
  //     rotating: false,
  //   });
  //   e.stopPropagation();
  // };

  // useEffect(() => {
  //   if (rotate.rotating && window) {
  //     window.addEventListener("touchmove", onRotate);
  //     window.addEventListener("touchend", onRotateStop);
  //     window.addEventListener("mouseup", onRotateStop);
  //     window.addEventListener("mousemove", onRotate);
  //     window.addEventListener("mouseleave", onRotateStop);
  //   }
  //   return () => {
  //     window.removeEventListener("touchmove", onRotate);
  //     window.removeEventListener("touchend", onRotateStop);
  //     window.removeEventListener("mouseup", onRotateStop);
  //     window.removeEventListener("mousemove", onRotate);
  //     window.removeEventListener("mouseleave", onRotateStop);
  //   };
  // }, [rotate.rotating]);
  
  return (
    <WrapResizer
      className="printMel resizers"
      onMouseDown={onDragStart}
      style={{
        position: "absolute",
        width: size?.w * tempScale, // remove gap white space
        height: size?.h * tempScale,
        border: `1px solid ${resizerColor}`,
        transform: `translate(${offset?.x * tempScale}px, ${
          offset?.y * tempScale
        }px)`,
        outline: `rgba(234, 243, 247, 0.5) solid 1px`,
        zIndex: 9999, // important (for group selection overlap)
      }}
    >
      {directions && directions.map((item, i) => {
        return (
          <DisplayResizer key={i} item={item} onResizeInit={handleResize} />
        );
      })}

      {/* <WrapRotate
          style={{
            transform: `scale(${1})`,
            transformOrigin: "0 0",
            left: -30,
            top: -22,
          }}
          onMouseDown={onRotateStart}
          onTouchStart={onRotateStart}
        >
          <Rotate>{RotateSvg}</Rotate>
      </WrapRotate> */}
    </WrapResizer>
  );
};

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  z-index: 19999;
  transform-origin: 50% 50%;
  &:after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
`;

const mapStateToProps = (state) => {
  return {
    ratioDiff: selectTempRatioDiff(state.designTemplate),
    username: selectUserInfo(state.authUser).username,
    tempSize: selectTemplateSize(state.designTemplate),
    smartAlignments: selectCoordinates(state.designTemplate),
    itemsToEmitSocket: selectEmitItemsToSocket(state.designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateMultiplePosition,
    getOriginalOffset,
    updateGroupFieldSize,
    setSmartAlignment,
    storeGroupFieldsInfoUndoRedo,
  }))
)(GroupDragResize);