import { calcRotatedOffsets } from "../../../../components/editDesign/canvasPanel/dragResize/resize/utils/calcRotatedOffsets";
import {setSmartAignmentPoints} from "../utils";
import {calcGroupResize} from "./../../../../components/editDesign/canvasPanel/dragResize/groupSelection/calcGroupResize";

export function interceptGroupSelection() {
  return function (state, action) {
    const {activeSlide, template, layout, size} = state;
    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID: activeSlide,
      fieldIDs: action.keys,
      tempScale: layout.tempScale,
      tempSize: size,
    });
    return {
      ...state,
      // usersOnline: {
      //   ...state.usersOnline,
      //   selectedIDs: action.keys,
      // },
      groupSelect: {
        ...state.groupSelect,
        groupAsOne: false, 
        keys: action.keys,
      },
      smartAlignment: {
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
        points: {
          x: xAlign,
          y: yAlign,
        },
      },
    };
  };
}

export function getOriginalOffset() {
  return function (state, action) {
    const {size, offset, initValues} = action;
    return {
      ...state,
      activeField: -1,
      selectedFieldType: "animations",
      groupSelect: {
        ...state.groupSelect,
        initFieldValues: initValues,
        selection: {
          show: true,
          size: {w: size.width, h: size.height},
          offset: {x: offset.x, y: offset.y},
          orgSize: {w: size.width, h: size.height},
          orgOffset: {x: offset.x, y: offset.y},
        },
      },
    };
  };
}

export function updateGroupOffset() {
  return function (state, action) {
    const {addedX, addedY} = action;
    const {groupSelect, activeSlide} = state;
    const {keys, selection, initFieldValues} = groupSelect;
    const newState = [...state.template];
    const currentSlide = state.template.find(
      (slide, index) => index === activeSlide
    );
    let prevFields = [],
      updatedFieldsToEmit = [];
    
    const updateSelectedArea = {
      x: selection.orgOffset.x + addedX,
      y: selection.orgOffset.y + addedY,
    };

    let ifGroupItems = {};
    const updatedFields = Object.entries(newState[activeSlide].fields).map(
      ([id, field]) => {
        if (keys?.includes(field.key) && !field.styles.lock) {
          prevFields.push(field);
          let updateOffset;
          
          // if (field?.groupAsOne?.keys?.length > 0) {
          //   updateOffset = {
          //     ...field.pos,
          //     x: field?.groupAsOne?.offset.x + addedX,
          //     y: field?.groupAsOne?.offset.y + addedY,
          //   };
          // } else {
            updateOffset = {
              ...field.pos,
              x: initFieldValues[field.key].offset.x + addedX,
              y: initFieldValues[field.key].offset.y + addedY,
            };
          // };

          updatedFieldsToEmit.push({
            key: field.key,
            offset: updateOffset,
          });
          if (field?.groupAsOne?.keys?.length > 0) {
            ifGroupItems = {
              ...field?.groupAsOne,
              offset: updateOffset
            }
            if (field.styles?.rotate !== 0 || field.styles?.rotate !== 360) {
              const {
                topLeft, topRight, btmRight, btmLeft
              } = calcRotatedOffsets(field?.pos, field?.size, field?.styles.rotate)
              return {
                ...field,
                ["pos"]: updateOffset,
                groupAsOne: ifGroupItems,
                rotatedOffsets: {
                  topLeft, topRight, btmRight, btmLeft
                }
              };
            } else {
              return {
                ...field,
                ["pos"]: updateOffset,
                groupAsOne: ifGroupItems,
              };
            }
          } else {
            if (field.styles?.rotate !== 0 || field.styles?.rotate !== 360) {
              const {
                topLeft, topRight, btmRight, btmLeft
              } = calcRotatedOffsets(field?.pos, field?.size, field?.styles.rotate)
              return {
                ...field,
                ["pos"]: updateOffset,
                groupAsOne: ifGroupItems,
                rotatedOffsets: {
                  topLeft, topRight, btmRight, btmLeft
                }
              };
            } else {
              return {
                ...field,
                ["pos"]: updateOffset,
              };
            }
          }
        } else {
          return field;
        }
      }
    );
    newState[activeSlide] = {
      ...newState[activeSlide],
      fields: updatedFields.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      ),
    };

    const updatedFieldsToEmitAsObject = updatedFieldsToEmit.reduce(
      (acc, item) => ({...acc, [item.key]: item}),
      {}
    );

    return {
      ...state,
      template: newState,
      groupSelect: {
        ...groupSelect,
        selection: {
          ...selection,
          offset: updateSelectedArea,
        },
      },
      emitItemsToSocket: {
        payload: {
          slideID: currentSlide.id,
          groupedArea: {
            offset: updateSelectedArea,
            size: selection.orgSize,
          },
          updatedItems: updatedFieldsToEmitAsObject,
        },
        type: "emit-grouped-fields-drag",
      },
    };
  };
}

export function updateGroupSize() {
  return function (state, action) {
    const {activeSlide, groupSelect} = state;
    const {updatedOffset, updatedSize} = action;
    const newState = [...state.template];
    const {keys, selection, initFieldValues} = groupSelect;
    const currentSlide = state.template.find(
      (slide, index) => index === activeSlide
    );
    let updatedFieldsToEmit = [];

    const updatedFields = Object.entries(newState[activeSlide].fields).map(
      ([id, field]) => {
        let ifGroupItems = {};
        if (field?.groupAsOne?.keys?.length > 0) {
          ifGroupItems = {
            ...field?.groupAsOne,
            size: updatedSize,
            offset: updatedOffset
          }
        };
        
        let newRotatedOffsets = { ...field?.rotatedOffsets }; 
        
        // if (field?.styles?.rotate !== 0) {
        //   newRotatedOffsets = calcRotatedOffsets(field?.pos, field?.size, field?.styles.rotate);
        // }
        if (field.styles?.rotate !== 0 || field.styles?.rotate !== 360) {
          newRotatedOffsets = calcRotatedOffsets(field?.pos, field?.size, field?.styles.rotate);
        } else {
          newRotatedOffsets = {
            topLeft: 0, topRight: 0, btmRight: 0, btmLeft: 0
          };
        }

        if (keys?.includes(field.key)) {
          const updatedField = calcGroupResize({
            field,
            initFieldValues: initFieldValues[field.key],
            orgSize: selection.orgSize,
            newSize: updatedSize,
            newOffset: updatedOffset,
          });
          const {size, pos} = updatedField;
          const sizeAndOffset = {
            size,
            pos,
          };
          if (field.type !== "image" && field.type !== "text") {
            updatedFieldsToEmit.push({
              key: field.key,
              size: size,
              offset: pos,
            });
            return {
              ...field,
              ...sizeAndOffset,
              groupAsOne: ifGroupItems,
              rotatedOffsets: newRotatedOffsets
            };
          } else if (field.type === "image") {
            const {imgSize, imgPos} = updatedField;
            const w2h2 = {
              ...size,
              w2: imgSize.w,
              h2: imgSize.h,
            };
            updatedFieldsToEmit.push({
              key: field.key,
              size: w2h2,
              offset: pos,
              imgPos,
            });
            return {
              ...field,
              size: w2h2,
              pos,
              imgPos,
              groupAsOne: ifGroupItems,
              rotatedOffsets: newRotatedOffsets
            };
          } else if (field.type === "text") {
            const {w2, h2} = field.size;
            const scale = Math.min(size.w / w2, size.h / h2);
            const updatedSize = {
              ...field.size,
              w: size.w,
              h: size.h,
            };
            updatedFieldsToEmit.push({
              key: field.key,
              size: updatedSize,
              offset: pos,
              scale,
            });
            return {
              ...field,
              size: updatedSize,
              pos,
              ["styles"]: {
                ...field.styles,
                scale,
              },
              groupAsOne: ifGroupItems,
              rotatedOffsets: newRotatedOffsets
            };
          } else if (field.type === "table") {
            return {
              ...field,
              rotatedOffsets: newRotatedOffsets
            }
          }
        } else {
          return field;
        }
      }
    );
    newState[activeSlide] = {
      ...newState[activeSlide],
      fields: updatedFields.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      ),
    };

    const updatedFieldsToEmitAsObject = updatedFieldsToEmit.reduce(
      (acc, item) => ({...acc, [item.key]: item}),
      {}
    );
    return {
      ...state,
      template: newState,
      groupSelect: {
        ...groupSelect,
        selection: {
          ...selection,
          offset: {
            x: updatedOffset.x,
            y: updatedOffset.y,
          },
          size: {
            w: updatedSize.w,
            h: updatedSize.h,
          },
        },
      },
      emitItemsToSocket: {
        payload: {
          slideID: currentSlide.id,
          groupedArea: {
            offset: {
              x: updatedOffset.x,
              y: updatedOffset.y,
            },
            size: {
              w: updatedSize.w,
              h: updatedSize.h,
            },
          },
          updatedItems: updatedFieldsToEmitAsObject,
        },
        type: "emit-grouped-fields-resize",
      },
    };
  };
}

export function updateGroupFieldsCollaboration() {
  return function (state, action) {
    const {active, payload, updateType} = action;
    const newState = [...state.template];
    const {updatedItems, groupedArea, slideID} = payload;
    const slideIndex = newState.findIndex((slide) => slide.id === slideID);
    const updatedFields = Object.entries(newState[slideIndex].fields).map(
      ([id, field]) => {
        if (
          updatedItems[field.key] &&
          field.key === updatedItems[field.key].key
        ) {
          if (updateType === "grouped-size-updated") {
            if (field.type === "text") {
              return {
                ...field,
                size: updatedItems[field.key].size,
                pos: updatedItems[field.key].offset,
                ["styles"]: {
                  ...field.styles,
                  scale: updatedItems[field.key].scale,
                },
              };
            } else if (field.type === "image") {
              return {
                ...field,
                size: updatedItems[field.key].size,
                pos: updatedItems[field.key].offset,
                imgPos: updatedItems[field.key].imgPos,
              };
            } else {
              return {
                ...field,
                size: updatedItems[field.key].size,
                pos: updatedItems[field.key].offset,
              };
            }
          } else if (updateType === "grouped-position-updated") {
            return {
              ...field,
              pos: updatedItems[field.key].offset,
            };
          }
        } else {
          return field;
        }
      }
    );
    newState[slideIndex] = {
      ...newState[slideIndex],
      fields: updatedFields.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      ),
    };

    const {offset, size} = groupedArea;
    return {
      ...state,
      template: newState,
      usersOnline: {
        ...state.usersOnline,
        users: {
          ...state.usersOnline.users,
          [active.username]: {
            activeSlide: slideID,
            ...state.usersOnline.users[active.username],
            groupSelection: {
              ...state.usersOnline.users[active.username].groupSelection,
              selection: {
                show: true,
                size: {w: size.w, h: size.h},
                offset: {x: offset.x, y: offset.y},
                orgSize: {w: size.w, h: size.h},
                orgOffset: {x: offset.x, y: offset.y},
              },
            },
          },
        },
      },
    };
  };
}

// for online collaboration
export function setGroupedItemsCollaboration() {
  return function (state, action) {
    const {username, keys, slideID, size, offset} = action;
    return {
      ...state,
      usersOnline: {
        ...state.usersOnline,
        users: {
          ...state.usersOnline.users,
          [username]: {
            ...state.usersOnline.users[username],
            activeSlide: slideID,
            groupSelection: {
              keys,
              selection: {
                show: true,
                size: {w: size.w, h: size.h},
                offset: {x: offset.x, y: offset.y},
                orgSize: {w: size.w, h: size.h},
                orgOffset: {x: offset.x, y: offset.y},
              },
              // initFieldValues: {},\\
            },
          },
        }
      }
    }
  }
}

// store group fields' info before and after being dragged or resized
export function storeGroupFieldsInfoUndoRedo() {
  return function (state, action) {
    const {groupSelect, activeSlide} = state;
    const {keys} = groupSelect;
    const newState = [...state.template];
    const slideID = newState[activeSlide].id;
    let groupedFields = {};
    let groupedKeys = [];
    Object.entries(newState[activeSlide].fields).map(([id, field]) => {
      if (keys?.includes(field.key) && !field.styles.lock) {
        groupedFields[id] = field;
        groupedKeys.push(id);
      }
    });
    if (action.updateType === "start") {
      return {
        ...state,
        undoRedo: {
          ...state.undoRedo,
          active: {
            slideID,
            type: "group-fields-drag-resize",
            item: groupedFields,
            groupedKeys,
          },
          history: [...state.undoRedo.history],
        },
      };
    } else if (action.updateType === "end") {
      const prevActive = state.undoRedo.active;
      return {
        ...state,
        undoRedo: {
          ...state.undoRedo,
          active: {
            slideID,
            type: "group-fields-drag-resize",
            item: groupedFields,
            groupedKeys,
          },
          history: [...state.undoRedo.history, prevActive],
        },
      };
    }
  };
}
