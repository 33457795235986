import React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {
  selectCropImage,
  selectTableResizeProps,
} from "../../../../store/selectors/fields.selector";
import Resizable from "../dragResize/resize/Resizable";
import {createPortal} from "react-dom";
import ResizeImage from "../fields/image/ResizeImage";
import LineResizable from "../dragResize/resize/LineResizable";

export const tempPaddingTop = 14, //14 //0 for screenshot pdf ..window.innerWidth < 850 ? 20 : 20,
  tempPaddingBtm = 0,
  mobTopPanel = 55,
  mobBtmPanel = 50;
const ResizeHandles = ({
  scale,
  activeField,
  selectedProps,
  dragging,
  resizing,
  onResizeStart,
  onDragStart,
  tableResizeProps,
  onRotateStart,
  direction,
  cropImage,
}) => {
  const onDragInit = (e) => {
    onDragStart(e, activeField, selectedProps);
  };

  const onResizeInit = (e, dir) => {
    onResizeStart(e, selectedProps, dir);
  };

  return createPortal(
    <ResizeLayer>
      {!cropImage ? (
        selectedProps?.type === "shape" && selectedProps?.subtype === "line" ? (
          <LineResizable
            key={selectedProps?.key}
            field={selectedProps}
            tempScale={scale}
            dragging={dragging}
            resizing={resizing}
            dragStart={onDragInit}
            resizeStart={onResizeInit}
            rotateStart={onRotateStart}
            direction={direction}
            hideBorder={tableResizeProps.hideBorder}
          />
        ) : (
          <Resizable
            key={selectedProps?.key}
            field={selectedProps}
            tempScale={scale}
            dragging={dragging}
            resizing={resizing}
            dragStart={onDragInit}
            resizeStart={onResizeInit}
            rotateStart={onRotateStart}
            direction={direction}
            hideBorder={tableResizeProps.hideBorder}
          />
        )
      ) : (
        <ResizeImage field={selectedProps} />
      )}
    </ResizeLayer>,
    document.getElementById("oat-item-drag-resize")
  );
};

export const ResizeLayer = styled.div`
  position: relative;
  height: 100%;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tableResizeProps: selectTableResizeProps(designTemplate),
    cropImage: selectCropImage(designTemplate),
  };
};

export default connect(mapStateToProps, null)(ResizeHandles);
