import React from "react";
import {connect} from "react-redux";
import {drawLine} from "../../../../canvasPanel/dragResize/resize/utils/drawLine";
import {calcRotatedOffsets} from "../../../../canvasPanel/dragResize/resize/utils/calcRotatedOffsets";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {straightenShapeLine} from "../../../../../../store/actions/fields/shape.action";

const StraightenLine = ({
  field,
  children,
  updateFieldCollaboration,
  straightenShapeLine,
}) => {
  const {styles, pos, size} = field || {};

  async function handleClick() {
    const endPoint = {
      x: pos?.x + size?.w,
      y: pos?.y,
    };
    
    const {
      topLeft, topRight, btmRight, btmLeft
    } = calcRotatedOffsets(pos, size, styles.rotate)

    // const combinedXEndPoint =
    //   newEndPoint?.x > newStartPoint?.x
    //     ? newEndPoint?.x - newStartPoint?.x
    //     : newStartPoint?.x - newEndPoint?.x;
    // const combinedYEndPoint =
    //   newEndPoint?.y > newStartPoint?.y
    //     ? newEndPoint?.y - newStartPoint?.y
    //     : newStartPoint?.y - newEndPoint?.y;

    const combinedXEndPoint =
      btmRight?.x > topLeft?.x
        ? btmRight?.x - topLeft?.x
        : topLeft?.x - btmRight?.x;
    const combinedYEndPoint =
      btmRight?.y > topLeft?.y
        ? btmRight?.y - topLeft?.y
        : topLeft?.y - btmRight?.y;

    if (combinedXEndPoint > combinedYEndPoint) {
      straightenShapeLine({
        lineType: "horizontal",
        payload: {
          offset: {x: topLeft?.x, y: topLeft?.y},
        },
      });
    } else {
      const {x, y, distance, angle} = drawLine(
        topLeft?.x - size?.h /2,
        topLeft?.y,
        topLeft?.x - size?.h /2,
        topLeft?.y + size?.w
      );
      straightenShapeLine({
        lineType: "vertical",
        payload: {
          offset: {x, y: y - size.h /2},
          size: {
            w: distance,
            h: size?.h,
          },
          angle,
        },
      });
    }
    updateFieldCollaboration();
  }

  return (
    <div 
      style={{    
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }} 
      onClick={handleClick}
    >
        {children}
    </div>
  )
};

const mapStateToProps = ({designTemplate}) => {
  return {
    field: selectActiveFieldProps(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldCollaboration,
  straightenShapeLine,
})(StraightenLine);
