import styled from "styled-components";

export const WrapSpinner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  transform: scale(0.8);
`;

export const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
  cursor: pointer;
`;

export const Item = styled.div`
  padding: 8px 0px;
  cursor: pointer;
`;
