import React, {useState} from "react";
import {Cancel, WrapButton, Error} from "./folderStyles";
import Button from "../../../../oat-ui/core/Button";
import styled from "styled-components";
import {changeFolderName} from "../../../store/actions/folder.action";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {selectActiveWorkSpace} from "../../../store/selectors/userDashboard.selector";
import {
  BottomSection,
  ContentSection,
  Footer,
  Header,
  TextField,
} from "../../reuse/dashboardUI/modalStyles";
import {ButtonSpinner} from "../../../../oat-ui";

const RenameFolder = ({
  close,
  selectedFolder,
  changeFolderName,
  workspaceID,
  user,
}) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [folderName, setFolderName] = useState(selectedFolder.folderName);

  const handleRename = async (e) => {
    if (!processing) {
      setProcessing(true);
      const updatedItem = {
        folderID: selectedFolder.folderID,
        folderType: selectedFolder.folderType,
        newFolderName: folderName,
        subfolderID: selectedFolder?.subfolderID,
      };
      if (error.status)
        setError({
          status: false,
          message: null,
        });

      const requestOptions = {
        method: "PATCH",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          workspaceID,
          ...updatedItem,
        }),
      };

      await fetch(
        !selectedFolder.isSubfolder
          ? `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/folders`
          : `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/folders/subfolders`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            const payload = {
              ...updatedItem,
              isSubfolder: selectedFolder?.isSubfolder,
            };
            changeFolderName(payload);
            close();
          } else {
            setError({
              status: true,
              message: data.message,
            });
            setProcessing(false);
          }
        })
        .catch(() => {
          setError({
            status: true,
            message: "Something went wrong",
          });
          setProcessing(false);
        });
      e.stopPropagation();
    }
  };

  const onChangeName = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const {value} = e.target;
    setFolderName(value);
  };

  const handleClose = (e) => {
    close();
    e.stopPropagation();
  };

  return (
    <Layout>
      <ContentSection>
        <Header>Rename Folder</Header>
        <TextField value={folderName} onChange={onChangeName} autoFocus />

        {error.status ? <Error>{error.message}</Error> : undefined}
      </ContentSection>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Cancel onClick={handleClose}>Cancel</Cancel>
          <WrapButton>
            <Button
              styles={{width: 86}}
              onClick={handleRename}
              disabled={processing}
            >
              {!processing ? "Update" : <ButtonSpinner />}
            </Button>
          </WrapButton>
        </BottomSection>
      </Footer>
    </Layout>
  );
};

const Layout = styled.div`
  width: 272px;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspaceID: selectActiveWorkSpace(state.userDashboard).ID,
  };
};

export default connect(mapStateToProps, {changeFolderName})(RenameFolder);
