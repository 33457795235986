import React, {useState} from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {Pencil} from "../../../../../../oat-svg-icons";
import {
  selectTemplateID,
  selectTempPasscode,
  selectWorkspacePlan,
} from "../../../../../store/selectors/template/template.selector";
import {Field, Value} from "./ShareTempLink";
import styled from "styled-components";
import SpecialButton from "../../../../../ui/inputs/SpecialButton";
import {Spinner} from "../../../../../../oat-ui";
import {passcodeStatus} from "../../../../../store/actions/template/template.action";
import {redMessage} from "../../../../../themes";
import Switch from "../../../editingPanel/panelTypes/shared/inputs/Switch";
import {Title} from "../../../editingPanel/panelTypes/shared/reuseStyles/Title";
import {requiresUpgradeToProStyles} from "../../../parts/upgradeToPro";

const AddPasscode = ({
  user,
  tempID,
  tempPasscode,
  passcodeStatus,
  workspacePlan,
}) => {
  const [passcodeEnabled, setPasscodeEnabled] = useState(tempPasscode.enabled);
  const [passcode, setPasscode] = useState(tempPasscode.value);
  const [editPasscode, setEditPasscode] = useState(
    tempPasscode.value !== "" ? false : true
  );
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  const activatePasscodeStatus = async () => {
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
    if (workspacePlan === "free") {
      setError({
        status: false,
        message: "Workspace needs to be upgraded.",
      });
      return;
    } else if (passcodeEnabled) {
      setPasscodeEnabled(false);
      setPasscode("");
      await updatePasscodeChange({
        enabled: false,
        passcode: "",
      });
      await passcodeStatus({
        enabled: false,
        value: "",
        auth: true, // only checks when viewing
      });
    } else {
      setPasscodeEnabled(true);
      setEditPasscode(true);
      await passcodeStatus({
        enabled: true,
        value: passcode,
        auth: true, // only checks when viewing
      });
    }
  };
  const onPasscodeChange = (e) => {
    const {value} = e.target;
    setPasscode(value);
  };

  const updatePasscodeChange = async ({enabled, passcode}) => {
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/passcode`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tempID,
          passcode,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setProcessing(false);
          setEditPasscode(false);
          passcodeStatus({
            enabled,
            value: passcode,
            auth: true, // only checks when viewing
          });
        } else {
          setProcessing(false);
          setError({
            status: true,
            message: data.message,
          });
        }
      })
      .catch((error) => {
        setProcessing(false);
        setError({
          status: true,
          message: "Something went wrong",
        });
      });
  };

  const onSavePasscode = async () => {
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
    if (passcode !== "" && !processing) {
      setProcessing(true);
      await updatePasscodeChange({enabled: true, passcode});
    }
  };

  const toggleEditPasscode = () => {
    setEditPasscode(!editPasscode);
  };

  return (
    <div style={{position: "relative"}}>
      {workspacePlan === "free" ? (
        <RequiresUpgradeToPro>Pro</RequiresUpgradeToPro>
      ) : undefined}
      <Switch
        title="Activate Passcode"
        value={passcode}
        onItemChange={activatePasscodeStatus}
        checked={passcodeEnabled}
        displayTooltip={{
          status: true,
          info: "Only users with the passcode can access.",
        }}
        disabledPadding={true}
      />
      {/* <Field>
        <Name>Activate Passcode</Name>
        <Value style={{flex: 1.5}}>
          <div>
            <label className="oatall-input-switch">
              <input
                type="checkbox"
                value={passcode}
                onChange={activatePasscodeStatus}
                checked={passcodeEnabled}
              />
              <span className="slider round" />
            </label>
          </div>
        </Value>
      </Field> */}
      {passcodeEnabled ? (
        <Field
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginTop: 12,
          }}
        >
          {!editPasscode ? (
            <>
              <Title disabledPadding={true}>Passcode</Title>
              <WrapPasscode>
                <Passcode>{passcode}</Passcode>
                <WrapPencil onClick={toggleEditPasscode}>{Pencil}</WrapPencil>
              </WrapPasscode>
            </>
          ) : undefined}

          {editPasscode ? (
            <>
              <Title disabledPadding={true} style={{userSelect: "none"}}>
                Passcode
              </Title>
              <Value
                style={{
                  display: "flex",
                  height: 76,
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <TextInput
                  type="text"
                  onChange={onPasscodeChange}
                  value={passcode}
                  placeholder="Passcode"
                />
                <WrapButton>
                  {processing ? (
                    <WrapSpinner>
                      <Spinner />
                    </WrapSpinner>
                  ) : undefined}
                  <Cancel onClick={toggleEditPasscode}>Cancel</Cancel>
                  <SpecialButton
                    styles={{width: 74, height: 34}}
                    onClick={onSavePasscode}
                    disabled={processing}
                  >
                    Save
                  </SpecialButton>
                </WrapButton>
              </Value>
            </>
          ) : undefined}
        </Field>
      ) : undefined}
      {error.status ? <Error>{error.message}</Error> : undefined}
    </div>
  );
};

const WrapPencil = styled.div`
  display: flex;
  flex: 1.5;
  width: 20px;
  height: 20px;
  fill: #b3b3b3;
  cursor: pointer;
  margin-left: 18px;
`;

const WrapButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const TextInput = styled.input`
  background: ${({theme}) => theme.deskSecondaryBg};
  color: ${({theme}) => theme.deskPrimaryTxt};
  flex: 1;
  padding: 5px;
  width: 100%;
  height: 34px;
  margin-bottom: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
`;

const WrapPasscode = styled.div`
  display: flex;
  flex: 1;
  white-space: pre;
  overflow: hidden;
`;

const Passcode = styled.div`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WrapSpinner = styled.div`
  transform: scale(0.7);
  margin-right: 18px;
`;

const Cancel = styled.div`
  margin-right: 18px;
  font-size: 0.85rem;
  cursor: pointer;
`;

const Error = styled.div`
  color: ${redMessage};
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  margin: 18px;
`;

const RequiresUpgradeToPro = styled.div`
  position: absolute;
  left: 62%;
  ${requiresUpgradeToProStyles}
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    tempID: selectTemplateID(state.designTemplate),
    tempPasscode: selectTempPasscode(state.designTemplate),
    workspacePlan: selectWorkspacePlan(state.designTemplate),
  };
};
export default connect(mapStateToProps, {passcodeStatus})(AddPasscode);
