import {
  APPLY_ANIMATION,
  SELECT_ANIMATION_TYPE,
  PREVIEW_ANIMATION,
  APPLY_ANIMATION_ON_SLIDE_LOAD,
  APPLY_ANIMATION_DELAY,
  SET_CURRENT_ANIMATION_ORDER,
  SET_ANIMATION_PREVIEW_OPTION,
  APPLY_ANIMATION_ON_EXIT
} from "../actionTypes";

export function selectAnimationType({active, type, groupedKeys}) {
  return {
    type: SELECT_ANIMATION_TYPE,
    active,
    payload: type,
    groupedKeys,
  };
}

export function setCurrentAnimationOrder({order, animateOnSlideDisplay}) {
  return {
    type: SET_CURRENT_ANIMATION_ORDER,
    order,
    animateOnSlideDisplay
  };
}

export function applyAnimationOrder({
  active,
  value,
  highestIndex,
  groupedKeys,
}) {
  return {
    type: APPLY_ANIMATION,
    active,
    value,
    highestIndex,
    groupedKeys,
  };
};

export function applyAnimationOnSlideLoad({
  active,
  value,
  groupedKeys,
}) {
  return {
    type: APPLY_ANIMATION_ON_SLIDE_LOAD,
    active,
    value,
    groupedKeys,
  };
};


// export function applyAnimationOrderForCollaboration(
//   active,
//   value,
//   highestIndex,
//   groupedItems
// ) {
//   return {
//     type: APPLY_ANIMATION_ORDER_COLLABORATION,
//     active,
//     value,
//     highestIndex,
//     groupedItems,
//   };
// }

export function onPreviewAnimation(status) {
  return {
    type: PREVIEW_ANIMATION,
    status,
  };
};

export function applyAnimationDelay({
  active,
  value,
  groupedKeys,
}) {
  return {
    type: APPLY_ANIMATION_DELAY,
    active,
    value,
    groupedKeys,
  };
};

export function setAnimationPreviewOption(value) {
  return {
    type: SET_ANIMATION_PREVIEW_OPTION,
    value,
  };
};

export function applyAnimationOnExit({active, value, groupedKeys}) {
  return {
    type: APPLY_ANIMATION_ON_EXIT,
    active,
    value,
    groupedKeys,
  }
}