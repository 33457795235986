import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {Items, Item, Title} from "./MobilePanelStyles";
import MobileDrawer from "../../../../drawer/MobileDrawer";
import TableDrawers from "./edit/table/TableDrawers";
import LockField from "./edit/common/LockField";
import {toggleTableRowsColumnsDrawer} from "../../../../../../store/actions/fields/table.action";

const TablePanel = ({
  activeField,
  actionDrawerStatus,
  toggleTableRowsColumnsDrawer,
}) => {
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: null,
    height: 0,
  });

  const handleDrawerClose = () => {
    setOpenDrawer({
      status: false,
      type: null,
      height: 0,
    });
    if (actionDrawerStatus.status) {
      toggleTableRowsColumnsDrawer(null, null);
    }
  };

  const onOpenDrawer = (type, height) => {
    setOpenDrawer({status: true, type, height});
  };

  const onSelectHeader = () => {
    onOpenDrawer("table-header", 200);
  };

  const onSelectBody = () => {
    onOpenDrawer("table-body", 200);
  };

  const onSelectSpacing = () => {
    onOpenDrawer("spacing", 125);
  };

  const onSelectBorder = () => {
    onOpenDrawer("table-border", 125);
  };

  const onSelectOpacity = () => {
    onOpenDrawer("opacity", 80);
  };

  const onSelectRotate = () => {
    onOpenDrawer("rotate", 80);
  };

  const onSelectLayer = () => {
    onOpenDrawer("layer", 80);
  };

  const onSelectAnimations = () => {
    onOpenDrawer("animation", (window.innerHeight * 35) / 100);
  };

  useEffect(() => {
    if (actionDrawerStatus.status) {
      onOpenDrawer("edit-table-rows-columns", 80);
    }
  }, [actionDrawerStatus.status]);

  return (
    <>
      <Items>
        <Item onClick={onSelectHeader}>
          <Title>Header</Title>
        </Item>

        <Item onClick={onSelectBody}>
          <Title>Body</Title>
        </Item>

        <Item onClick={onSelectSpacing}>
          <Title>Spacing</Title>
        </Item>

        <Item onClick={onSelectBorder}>
          <Title>Border</Title>
        </Item>

        <Item style={{padding: "0 10px 3px 0"}} onClick={onSelectOpacity}>
          <Title>Opacity</Title>
        </Item>

        <Item onClick={onSelectSpacing}>
          <Title>Spacing</Title>
        </Item>

        <LockField />

        <Item onClick={onSelectRotate}>
          <Title>Rotate</Title>
        </Item>

        <Item onClick={onSelectLayer}>
          <Title>Layer</Title>
        </Item>

        <Item onClick={onSelectAnimations}>
          <Title>Animate</Title>
        </Item>
      </Items>

      {openDrawer ? (
        <MobileDrawer
          open={openDrawer.status}
          height={openDrawer.height}
          onClose={handleDrawerClose}
        >
          {activeField?.key ? (
            <TableDrawers
              drawerType={openDrawer.type}
              onClose={handleDrawerClose}
            />
          ) : undefined}
        </MobileDrawer>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveFieldProps(designTemplate),
    actionDrawerStatus: state.designTemplate.toggleTableRowsColumnsDrawer,
  };
};

export default connect(mapStateToProps, {toggleTableRowsColumnsDrawer})(
  TablePanel
);
