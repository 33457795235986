import React from "react";
import styled from "styled-components";
import BgThemeBuilder from "./panelTypes/BgThemeBuilder";
import ChartThemeBuilder from "./panelTypes/ChartThemeBuilder";
import ShapeThemeBuilder from "./panelTypes/ShapeThemeBuilder";
import TableThemeBuilder from "./panelTypes/TableThemeBuilder";
import TextThemeBuilder from "./panelTypes/TextThemeBuilder";
import ImageThemeBuilder from "./panelTypes/ImageThemeBuilder";
import VideoThemeBuilder from "./panelTypes/VideoThemeBuilder";

const itemTypes = [
  "Background",
  "Text",
  "Image",
  "Shape",
  "Chart",
  "Table",
  "Video",
];

const DisplayItem = ({item, onChangeTab}) => {
  const handleClick = () => {
    onChangeTab(item);
  };
  return <Option onClick={handleClick}>{item}</Option>;
};
const EditThemeBuilder = ({selectedTab, onChangeTab}) => {
  let showPanel = null;

  if (selectedTab === "Background") {
    showPanel = <BgThemeBuilder />;
  } else if (selectedTab === "Text") {
    showPanel = <TextThemeBuilder />;
  } else if (selectedTab === "Image") {
    showPanel = <ImageThemeBuilder />;
  } else if (selectedTab === "Shape") {
    showPanel = <ShapeThemeBuilder />;
  } else if (selectedTab === "Chart") {
    showPanel = <ChartThemeBuilder />;
  } else if (selectedTab === "Table") {
    showPanel = <TableThemeBuilder />;
  } else if (selectedTab === "Video") {
    showPanel = <VideoThemeBuilder />;
  }

  return (
    <Layout>
      <Title>Edit</Title>
      <ItemTypes>
        {itemTypes.map((item, i) => (
          <DisplayItem key={i} item={item} onChangeTab={onChangeTab} />
        ))}
      </ItemTypes>
      <Wrapper>{showPanel}</Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (min-width: 850px) {
    width: 380px;
    padding-right: 12px;
    box-sizing: border-box;
    height: ${window.innerHeight}px;
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  color: ${({theme}) => theme.deskSecondaryTxt};
  padding: 1em;
`;

const Wrapper = styled.div`
  color: #fff;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 100px;
  height: ${window.innerHeight}px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ItemTypes = styled.div`
  display: flex;
  font-size: 0.9rem;
  padding: 1em;
  flex-wrap: wrap;
`;

const Option = styled.div`
  background: ${({theme}) => theme.deskSecondaryBg};
  color: ${({theme}) => theme.deskSecondaryTxt};
  padding: 4px 18px;
  margin: 0 10px 10px 0;
  border-radius: 4px;
  cursor: pointer;
`;

export default EditThemeBuilder;
