import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import {WrapColorBox} from "../common/WrapColorStyles";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const ChartBackground = ({
  background,
  showBackground,
  updateFieldStyles,
  fieldID,
  slideID,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const handleEmitSocket = (type, value) => {
    updateFieldCollaboration();
  };

  // need to place storeFieldUndoRedo() two line
  // for undo-redo
  const toggleButton = async () => {
    await storeFieldUndoRedo();
    if (!showBackground) {
      await updateFieldStyles({
        active: {slideID, fieldID},
        type: "showBackground",
        value: true,
      });
      await handleEmitSocket("showBackground", true);
    } else if (showBackground) {
      await updateFieldStyles({
        active: {slideID, fieldID},
        type: "showBackground",
        value: false,
      });
      await handleEmitSocket("showBackground", false);
    }
    await storeFieldUndoRedo();
  };

  const changeBgColor = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type: "background",
      value,
    });
  };

  const handleColorDebounce = (value) => {
    storeFieldUndoRedo();
    handleEmitSocket("background", value);
  };

  return (
    <>
      <div style={{paddingBottom: showBackground ? "2em" : 0}}>
        <Switch
          title="Add Background"
          value={showBackground}
          onItemChange={toggleButton}
          checked={showBackground}
        />
      </div>
      {showBackground ? (
        <div style={{display: "flex"}}>
          <Title>Color</Title>
          <WrapColorBox>
            <PopoverColorBox
              value={background}
              updateFieldStyles={changeBgColor}
              onDebounce={handleColorDebounce}
            />
          </WrapColorBox>
        </div>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ChartBackground);
