import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import DropDown from "../../../../ui/inputs/DropDown";
import SpecialButton from "../../../../ui/inputs/SpecialButton";
import {Name} from "../customizeSlides/exporting/ShareTempLink";
import {shareAsGuest} from "../../../../store/actions/template/template.action";
import {red4} from "../../../../../oat-color-variables";
import {ButtonSpinner} from "../../../../../oat-ui";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {selectTemplateID} from "../../../../store/selectors/template/template.selector";

const options = [
  {display: "Read Only", value: "read-only"},
  // {display: "Able to Comment", value: "able-to-comment"},
  {display: "Read & Write", value: "read-write"},
];

const ShareTempAsGuest = ({shareAsGuest, tempID, username}) => {
  const [accessLevel, setAccessLevel] = useState("read-only");
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState({status: false, message: null});
  const [submiting, setSubmiting] = useState(false);

  const onChangeEmail = (e) => {
    const {value} = e.target;
    setEmailAddress(value.trim());
  };

  const handleSelection = (value) => {
    setAccessLevel(value);
  };

  const handleSubmit = async () => {
    const payload = {
      email: emailAddress,
      accessLevel: accessLevel,
    };

    if (!submiting) {
      if (
        emailAddress.length < 4 ||
        emailAddress.indexOf("@") === -1 ||
        emailAddress.indexOf(".") === -1
      ) {
        setError({
          status: true,
          message: "Please enter a valid email address.",
        });
      } else {
        setSubmiting(true);
        if (error.status) {
          setError({
            status: false,
            message: null,
          });
        }

        await fetch(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/guests`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sharedUserEmail: emailAddress.trim(),
              accessLevel: accessLevel,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              shareAsGuest(payload);
              setEmailAddress("");
              setAccessLevel(null);
            } else {
              setError({
                status: true,
                message: data.response.message,
              });
            }
            setSubmiting(false);
          })
          .catch((error) => {
            setError({
              status: true,
              message: "Something went wrong",
            });
            setSubmiting(false);
          });
      }
    }
  };

  return (
    <Layout>
      <div style={{display: "flex", flexDirection: "column"}}>
        <Name style={{marginBottom: 16}}>Share as Guest</Name>

        <div style={{display: "flex"}}>
          <WrapField style={{marginRight: 14}}>
            <Input
              type="text"
              value={emailAddress}
              onChange={onChangeEmail}
              placeholder="Email Address"
              autoFocus
            />
          </WrapField>

          <WrapField>
            <DropDown
              onSelect={handleSelection}
              selected={accessLevel && accessLevel.replace(/-/g, " ")}
              options={options}
              boxWidth={180}
              minWidth="100%"
              maxWidth="100%"
            />
          </WrapField>
        </div>
      </div>

      <ButtonSection>
        <WrapButton>
          <SpecialButton
            disabled={submiting}
            onClick={handleSubmit}
            styles={{width: 100}}
          >
            {submiting ? <ButtonSpinner /> : "Share"}
          </SpecialButton>
        </WrapButton>
      </ButtonSection>

      {error.status ? <Error>{error.message}</Error> : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px 62px 0px;
`;

const WrapField = styled.div`
  flex: 1;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  color: ${({theme}) => theme.deskPrimaryTxt};
  border: none;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  padding-left: 6px;
  border-radius: 8px;
  box-sizing: border-box;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 12px 0;
`;

const WrapButton = styled.div`
  height: 32px;
`;

const Error = styled.div`
  width: 100%;
  color: ${red4};
  font-size: 0.9rem;
  text-align: right;
`;

const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
    tempID: selectTemplateID(state.designTemplate),
  };
};

export default connect(mapStateToProps, {shareAsGuest})(ShareTempAsGuest);
