export const primaryGrey = "#787878";
export const veryLightGrey = "#c7c7c7";
export const grey1 = "#989898";
export const grey2 = "#c7c7c7";
export const grey3 = "#e6e6e6";
export const grey4 = "#646464";
export const grey5 = "#ccccd3"; // input border
export const greyText1 = "#343232";
export const greyText2 = "#36363e";
export const greyText3 = "#6b6b6b";

export const mainDarkBlueTransparent1 = "#1b1b34d1";
export const mainBlue1 = "#464762d4";
export const mainBlue2 = "#424355eb"; //less transparency, use with dark theme hover

export const primaryLightPurple = "#e6ddff";

export const selectImage = "#e6ddff";

export const darkGrey1 = "#303030";
export const darkGrey2 = "#2d2c30";

export const paleBlue = "#8996f2"; // "#898cf6";
export const paleBlue2 = "#c7c7ef7a";
export const paleBlue3 = "#dbcafff0";
export const paleBlue4 = "#b9c3f27a"; // hover
export const paleBlue5 = "#ebebf5ab";
export const primaryBlue = "#7c8bff"; //"#7175f1"; // header, dashboard
export const primaryBlueBtn = "#7e91ff";

export const primaryBlack = "#232323";
export const black1 = "#232323";
export const black2 = "#24262b";
export const black3 = "#525259";
export const secondaryBlack = "#494242"; 

export const brown1 = "#4e4646";

export const primaryRed = "#f17575";
export const primaryWhite = "#fff";

export const primaryPurple = "#807df9";
export const purple1 = "#715fff";
export const purple2 = "#674bf9"; // brighter
export const purple3 = "#f5eaff"; // pale (hover)

export const red1 = "#ef7888"; // pale red (text)
export const red2 = "rgb(255, 181, 181)"; // super pale red (bg)
export const red3 = "#ffe6e6"; // pale hover
export const red4 = "#f65353"; // error message
export const red5 = "#f27081"; // button
export const red6 = "#e54b4b"; // bright red for text

export const blue1 = "#8996f2"; //8488ff
export const blue2 = "#4c5dee";
export const blue3 = "#728aff";
export const blue4 = "#4669ec;"; // mid blue 787bf4
export const darkBlue1 = "#37376e";
export const blue5 = "#e5dffd5e"; // pale blue hover 1
export const blue6 = "rgba(236, 240, 255, 0.53)"; // pale blue hover 1


export const lightViolet = "#f7f2ff"; // dashboard

export const superLightPurple = "#f4eafff0"; // bg hover

export const paleGreen1 = "#e6ecef";
