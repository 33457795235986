import React, {useEffect, useState} from "react";
import {fontTypes, googleFonts} from "../../../../../../../ui/fonts/googleFontLinks";
import DisplayFont from "./DisplayFont";
import { selectUsedFonts } from "../../../../../../../store/selectors/template/template.selector";
import { connect, useDispatch } from "react-redux";
import styled from 'styled-components';
import { setUsedFonts } from "../../../../../../../store/actions/template/template.action";

const OatAllFontList = ({usedFonts, selected, onSelectFont, fontWeight, fontStyle}) => {
  const [fontList, setFontList] = useState(Object.values(googleFonts));
  const dispatch = useDispatch();

  const handleItemSelect = (item) => {
    const selectedFontType = item.types.find(
      (type) => type.fontWeight == fontWeight && type.fontStyle == fontStyle
    );

    if (selectedFontType) {
      const payload = {
        fontFamily: item.fontFamily,
        src: selectedFontType.src,
        fontType: selectedFontType.fontType,
        format: null,
        custom: item?.custom ? true : false,
      };
      onSelectFont(payload);
    } else {
      // if no matched font weight & font style found
      // also if switched from custom fonts
      const payload = {
        fontFamily: item.fontFamily,
        src: item.types[0].src,
        fontType: item.types[0].fontType,
        format: null,
        custom: false,
      };
      onSelectFont(payload);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup logic (equivalent to componentWillUnmount)
      dispatch(setUsedFonts()); // adding this custom fonts do not load properly after second opening
    };
  }, []); 

  return (
    <>
      {usedFonts && usedFonts.map((item, i) => (
        <DisplayFont
          key={i}
          item={{
            fontFamily: item?.custom ? item?.fontFamily + "-" + item?.fontWeight + "-" + item?.fontStyle : item?.fontFamily,
            types: fontTypes
          }}
          onSelectItem={handleItemSelect}
          selected={selected}
        />
      ))}

      <Gap />

      {fontList &&
      fontList.map((item, i) => (
        <DisplayFont
          key={i}
          item={item}
          onSelectItem={handleItemSelect}
          selected={selected}
        />
      ))}
    </>
  );
};

const Gap = styled.div`
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.layoutGapColor};
`

const mapStateToProps = (state) => {
  return {
    usedFonts: selectUsedFonts(state.designTemplate)
  }
}

export default connect(mapStateToProps, null)(OatAllFontList);
