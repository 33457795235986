import React, {useState, useEffect, useRef, useCallback} from "react";
import {connect} from "react-redux";
import {
  getLoadingStatus,
  selectUserTemplates,
  selectUserDashboardErr,
  selectActiveWorkSpace,
} from "../../../store/selectors/userDashboard.selector";
import {
  Header,
  WrapItem,
  Layout,
  Items,
  CenterDiv,
  WrapDashAreaSpinner,
  WrapTempThumbnailForGuest,
} from "./userTemplatesStyles";
import Popper from "../../../../oat-ui/core/Popper";
import UserTempPopper from "./UserTempPopper";
import Spinner from "../../../../oat-ui/core/Spinner";
import ModalTypes from "./ModalTypes";
import {useNavigate, useParams} from "react-router-dom";
import {
  selectUserInfo,
  selectUserWorkspaceForRedirect,
} from "../../../../../redux/user/authUser";
import EmptyTemplates from "./emptyTemplate/EmptyTemplates";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import CreateTemplate from "./createTemplate/CreateTemplate";
import {fetchDashboardItems} from "../../../store/actions/dashboard.action";
import {selectActiveFolder} from "../../../store/selectors/userDashboard.selector";
import {WrapError} from "../dashboardPanelStyles";
import {getDashboardLayout} from "./getDashboardLayout";
import TemplateThumbnails from "./templateThumbnails/TemplateThumbnails";
import {onFolderChange} from "../../../store/actions/folder.action";
import { UpgradeToPro } from "../userLibrary/images/ImagesLibrary";

const DisplayItem = ({
  workspaceRole,
  item,
  onSelectItem,
  onPopperOpen,
  size,
}) => {
  const handleItemClick = () => {
    onSelectItem(item);
  };

  const handlePopperOpen = (e) => {
    onPopperOpen(e, item);
  };

  return workspaceRole === "guest" ? (
    <WrapTempThumbnailForGuest
      style={{
        width: size.width,
        height: size.height + 46,
      }}
      href={`/p/${item.tempID}`}
      target="_blank"
    >
      <TemplateThumbnails
        item={item}
        size={size}
        onItemClick={handleItemClick}
        onPopperOpen={handlePopperOpen}
      />
    </WrapTempThumbnailForGuest>
  ) : (
    <WrapItem
      style={{
        width: size.width,
        height: size.height + 46,
      }}
      to={`/design/${item.tempID}`}
    >
      <TemplateThumbnails
        item={item}
        size={size}
        onItemClick={handleItemClick}
        onPopperOpen={handlePopperOpen}
      />
    </WrapItem>
  );
};

const UserTemplates = ({
  process,
  templates,
  error,
  user,
  fetchDashboardItems,
  acitveFolder,
  workspace,
  redirectWorkspaceID,
  onFolderChange,
  paginationToken
}) => {
  let params = useParams();
  const {workspaceID, section, folderID} = params;
  const popperSize = {width: 200, height: 124};
  // {width: 200, height: !user.businessProfile ? 120 : 158};
  const [selectedItem, setSelectedItem] = useState({
    id: null,
    title: null,
    tempSourceID: null,
    folderID: null, // if under home, the source folder would be unknown
  });
  const [popper, setPopper] = useState({
    open: false,
    offset: {x: null, y: null},
  });
  const [modal, setModal] = useState({
    type: "",
    status: false,
  });
  const [itemSize, setItemSize] = useState({
    width: 0,
    height: 0,
  });
  const windowSize = getWindowSize();

  const handlePopperOpen = (e, item) => {
    setSelectedItem({
      id: item.tempID,
      title: item.title,
      tempSourceID: item.tempSourceID,
      folderID: item.folderID,
      workspaceID: item.workspaceID,
    });
    // if (windowSize.width >= 850) {
    if (e.clientY + popperSize.width > window.innerHeight) {
      const extraSpace = 20;
      setPopper({
        open: !popper.open,
        offset: {
          x: e.pageX - popperSize.width + 9,
          y: e.pageY - popperSize.height - extraSpace,
        },
      });
    } else {
      setPopper({
        open: !popper.open,
        offset: {x: e.pageX - popperSize.width, y: e.pageY - 20},
      });
    }
    // }
    // for drawer
    //  else {
    //   setPopper({
    //     open: !popper.open,
    //     type: "template-actions",
    //   });
    // }
    e.preventDefault();
  };

  const handlePopperClose = () => {
    setPopper(false);
  };

  const openModal = (value) => {
    setModal({
      type: value,
      status: true,
    });
    setPopper({
      open: false,
      offset: {x: null, y: null},
    });
  };

  const closeModal = () => {
    setModal({
      type: "",
      status: false,
    });
  };

  const addToProfile = () => {
    if (!selectedItem.onProfile) {
      setModal({
        type: "add-template-to-profile",
        status: true,
      });
    } else {
      setModal({
        type: "display-message",
        status: true,
      });
    }
  };

  let navigate = useNavigate();
  const redirectToTemplateDetails = (item) => {
    const {tempID} = item;
    return navigate(`/design/${tempID}`);
  };

  useEffect(() => {
    const {width, height} = getDashboardLayout(windowSize.width);
    setItemSize({
      width,
      height,
    });
  }, [windowSize.width]);

  useEffect(() => {
    if (!workspaceID) {
      return navigate(`/dashboard/${redirectWorkspaceID}/designs/home`, { replace: true });
    } 
    // else if (section === "library" && folderID === "templates") { // library-templates
    //   navigate(`/dashboard/${workspaceID}/library/templates`);
    //   fetchDashboardItems({
    //     api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${workspaceID}&folderID=library-templates`,
    //     paginate: false
    //   }, {cache: "no-store"});
    // } 
    else {
      navigate(`/dashboard/${workspaceID}/designs/${folderID}`, { replace: true });
      fetchDashboardItems({
        api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${workspaceID}&folderID=${folderID}`,
        paginate: false 
      }, {cache: "no-store"});
    } 
    // to avoid invoking when user changes folder from sidebar panel
    // if (folderID !== acitveFolder) {
    //   const payload = {
    //     username: user.username,
    //     workspaceID,
    //     folderID,
    //     folderType: "designs",
    //   };
    //   onFolderChange(payload);
    // }
    // setFetching({...fetching, initialLoading: false });
  }, [workspaceID]); // workspaceID, folderID // folderID remove

  // only for library-templates
  // re-clicking again from design folder to library-templates causing not loading error
  useEffect(() => {
    if (folderID === "library-templates" || folderID === "home") {
      navigate(`/dashboard/${workspaceID}/designs/${folderID}`, { replace: true });
      fetchDashboardItems({
        api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${workspaceID}&folderID=${folderID}`,
        paginate: false 
      }, {cache: "no-store"});
    }
  }, [folderID]);

  const changeDrawerSection = (type) => {
    setPopper({...popper, type, height: windowSize.height - 200});
  };

  const [fetching, setFetching] = useState({
    initialLoading: true, // first time being loaded
    status: false,
    error: false,
    page: 1,
    lastEvaluatedKey: null,
  });
  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (fetching.initialLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && paginationToken !== null) {
          setFetching((prev) => {
            return {
              ...prev,
              page: prev.page + 1,
            };
          });
        }
      }, { threshold: 0.5 });
      if (node) observer.current.observe(node);
    },
    [fetching.initialLoading, paginationToken]
  );

  useEffect(() => {
    // skip first loading and if there is no items left
    if (fetching.page !== 1 && paginationToken !== null) {
      if (!fetching.status) {
        const paginationParts = paginationToken.split("#");
        const timestamp = paginationParts[0];
        const tempID = paginationParts[1]; 

        fetchDashboardItems({
          api: `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/dashboard/templates?workspaceID=${workspaceID}&folderID=${folderID}&paginationToken=${timestamp}&tempID=${tempID}`,
          paginate: true
        }, {
            cache: "no-store",
          }
        );
      }
    }
  }, [fetching.page]);

  const onUpgradeToPro = () => {
    navigate(`?upgrade=true`, { replace: true });
  };

  return (
    <Layout>
      <Header>
        <div className="user-dashboard-header">
          <h3>{folderID === "home" ? "Team Recent" : "Templates"}</h3>
          {workspace.plan === "free" && folderID === "library-templates" ? (
            <UpgradeToPro onClick={onUpgradeToPro}>Pro</UpgradeToPro>
          ) : undefined}
        </div>

        <div>{workspace.ID ? <CreateTemplate /> : undefined}</div>
      </Header>

      {error ? (
        <CenterDiv>
          <WrapError>{error ? "Couldn't load data." : undefined}</WrapError>
        </CenterDiv>
      ) : undefined}

      {process.loading ? (
        <WrapDashAreaSpinner>
          <Spinner />
        </WrapDashAreaSpinner>
      ) : undefined}
      
      {!process.loading && templates && templates.length > 0 ?
      /* Height important for setting infinite scroll to load properly */
        <div style={{width: "100%", minHeight: windowSize.height - 200}}>
          <Items>
            {templates && templates.map((item) => (
              <DisplayItem
                key={item.tempID}
                item={item}
                onSelectItem={redirectToTemplateDetails}
                onPopperOpen={handlePopperOpen}
                size={itemSize}
                workspaceRole={workspace.role}
              />
            ))}
          </Items> 
        </div>
      : undefined}

      {templates && templates.length > 0 ? 
        <>
          <div ref={lastItemRef} />
          <div style={{height: 220}} />
        </> 
      : undefined}

      {!process.loading && !error && templates && templates.length === 0 ? (
        <CenterDiv style={{width: "100%"}}>
          <EmptyTemplates />
        </CenterDiv>
      ) : undefined}

      {popper.open ? (
        // windowSize.width >= 601
        // ? (
        <Popper
          width={popperSize.width}
          height="auto"
          offset={{x: popper.offset.x, y: popper.offset.y}}
          styles={{boxShadow: "0px 0px 3px 0px #d1d1d2"}}
          onClose={handlePopperClose}
        >
          <UserTempPopper openModal={openModal} onAddProfile={addToProfile} />
        </Popper>
      ) : // ) : undefined
      undefined}

      {/* {windowSize.width < 850 ? (
        <BottomUpDrawer
          open={popper.open}
          height={popper.height ? popper.height : 230}
          close={handlePopperClose}
          background="#fff"
        >
          <TempDrawerContents
            onOpenModal={openModal}
            type={popper.type}
            onSelect={changeDrawerSection}
          />
        </BottomUpDrawer>
      ) : undefined} */}

      {modal.status ? (
        <ModalTypes
          type={modal.type}
          close={closeModal}
          selectedItem={selectedItem}
          changeModal={openModal}
        />
      ) : undefined}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const {userDashboard, authUser} = state;
  return {
    process: getLoadingStatus(userDashboard),
    templates: selectUserTemplates(userDashboard),
    error: selectUserDashboardErr(userDashboard),
    user: selectUserInfo(authUser),
    acitveFolder: selectActiveFolder(userDashboard).ID,
    workspace: selectActiveWorkSpace(userDashboard),
    redirectWorkspaceID: selectUserWorkspaceForRedirect(authUser),
    paginationToken: state.userDashboard.paginationToken
  };
};

export default connect(mapStateToProps, {fetchDashboardItems, onFolderChange})(
  UserTemplates
);
