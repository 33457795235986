import React from "react";
import styled from "styled-components";
import LayoutHoc from "../../layout/LayoutHoc";
import MainHeader from "../components/MainHeader";
import TemplateThemes from "../components/TemplateThemes";
import TemplatesShowcase from "../components/TemplatesShowcase";
import EmbedTemplate from "../components/EmbedTemplate";
import FeatureBySection from "../components/FeatureBySection";
import FeatureList from "../components/FeatureList";
import StickersIconsSection from "../components/StickersIconsSection";
import SharingAndExport from "../components/core/SharingAndExport";
import FeatureNightTheme from "../components/FeatureNightTheme";
import AnimationAndCollaboration from "../components/AnimationAndCollaboration";
import SelectTemplates from "../components/core/SelectTemplates";
import EditTemplates from "../components/core/EditTemplates";
import BespokeServices from "../components/BespokeServices";
import CookiePolicyNoti from "../components/cookie/CookiePolicyNoti";

const Home = () => {
  return (
    <LayoutHoc>
      <WrapLayout>
        <Layout>
          <MainHeader />

          <SelectTemplates />
          <EditTemplates />
          <SharingAndExport />

          <StickersIconsSection />

          <FeatureBySection />

          <EmbedTemplate />

          <BespokeServices />

          {/* <FeatureList /> */}

          {/* <FeatureNightTheme /> */}

          {/* <FeatureCarousel /> */}

          {/* <FeaturesShowcase2 /> */}

          {/* <TemplateThemes /> */}

          {/* <AnimationAndCollaboration /> */}

          <TemplatesShowcase />

          <CookiePolicyNoti />
        </Layout>
      </WrapLayout>
    </LayoutHoc>
  );
};

const WrapLayout = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  @media only screen and (min-width: 601px) and (max-width: 999px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1499px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1500px) {
    font-size: 21px;
  }
  @media only screen and (max-width: 380px) {
    font-size: 13px;
  }
`;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

export default Home;
