import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import {ButtonSpinner, Popper, Spinner} from "../../../oat-ui";
import {getWindowSize} from "../../../oat-window-size";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {selectUserInfo} from "../../../../redux/user/authUser";
import {connect} from "react-redux";
import {
    ImageSection, 
    Item, 
    WrapImages,
    Image, 
    BottomPanel, 
    BtmChildSection,
    Avatar,
    MadeBy,
    WrapSpinner,
    UseTemplate,
    Creator,
    BackButton,
    Error,
    PricingType, 
} from "./../styles/itemDetailsStyles";
import PremiumImagesCovers from "./PremiumImageCovers";
import { requiresUpgradeToProStyles } from "../../../oat-design-template/components/editDesign/parts/upgradeToPro";

const numberOfItemsInRow = 4;

const DisplayItem = ({item, panelWidth, index}) => {
  const [itemSize, setItemSize] = useState({w: 0, h: 0});

  useEffect(() => {
    const width = (panelWidth - (10 * numberOfItemsInRow)) / numberOfItemsInRow;
    setItemSize({w: width, h: width});
  }, [panelWidth]);

  return (
    <Item style={{background: '#f2f7f2ed', overflow: 'hidden'}}>
      <Image style={{
        height: '100%', 
        boxSizing: 'border-box',
        cursor: 'default',
        minWidth: itemSize.w, 
        minHeight: window.innerWidth > 600 ? itemSize.h : 180,
        maxHeight: window.innerWidth > 600 ? 120 : 180, 
        padding: 15,
        boxShadow: `0px 0px 0px 0px #fff`
      }} 
        src={typeof item.image === 'object' 
          ? `https://oat-images-icons.s3.amazonaws.com/${item.image?.small}` 
          : `https://oat-images-icons.s3.amazonaws.com/${item.image}`} 
        loading="lazy" 
        alt="" 
      />
    </Item>
  );
};

const ImagesIconsPackDetails = ({
  selectedItem,
  user,
  modalOpen,
  height,
  onSelectPurchaseOption
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({status: false, message: null});
  const [stripeProcessing, setStripeProcessing] = useState(false);
  let params = useParams();
  const imageSectionRef = useRef(null);
  const listPanelRef = useRef(null);
  let navigate = useNavigate();
//   const query = new URLSearchParams(useLocation().search);
//   const folderID = query.get("folder");

// console.log(selectedItem)
  useEffect(() => {
    if (selectedItem) {
      setLoading(true);
      try {
        const imgFormat = selectedItem?.format === 'png' ? 'images' : 'icons';
        fetch(
          `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${imgFormat}/${selectedItem.category}/${selectedItem.key}`
          // `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/icons/${selectedItem.key}` 
        )
        .then((response) => response.json())
        .then((data) => {
          setItems(data ? data : []);
          setLoading(false);
        })
        .catch((err) => {
          setError({
            status: true, message: 'Something went wrong.'
          })
        });
      } catch (e) {
        setLoading(false);
        setError({
          status: true, message: 'Something went wrong.'
        })
      }
    }
  }, [selectedItem]);

  const [panelHeight, setPanelHeight] = useState(null);
  const windowSize = getWindowSize();
  useEffect(() => {
    if (imageSectionRef.current && imageSectionRef.current.offsetHeight) {
      setPanelHeight(imageSectionRef.current.offsetHeight);
    }
  }, [windowSize.width]);

  const [openPurchaseBox, setOpenPurchaseBox] = useState(true);
  const handlePurchase = async () => {
    onSelectPurchaseOption();
    // if(!stripeProcessing) {
    //   setStripeProcessing(true);
    //   await fetch(`https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/one-time-checkout/workspaces/JtTufAqK24`,
    //   {
    //     method: "POST",
    //     headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       username: user.username,
    //       email: user.email,
    //       unit_amount: selectedItem.price.value,
    //       productID: selectedItem.key,
    //       productName: selectedItem.packName,
    //       productType: selectedItem.type,
    //       productImg: selectedItem.image
    //     }),
    //   })
    //   .then((res) => {
    //     if (res.status == 303) {
    //       return res.json();
    //     }
    //     return res.json().then((json) => Promise.reject(json));
    //   })
    //   .then(({url}) => (window.location = url))
    //   .catch((e) => {
    //     setError({
    //       status: true, message: 'Something went wrong.'
    //     })
    //   });
    // }
  };

  const redirectToRegister = () => {
    navigate(`/register`);
  };

  const [panelWidth, setPanelWidth] = useState(0);
  useEffect(() => {
    if (listPanelRef?.current) {
      const width = listPanelRef?.current.offsetWidth;
      setPanelWidth(width);
    }
  }, [loading, windowSize.width]);

  return (
    <Layout $height={windowSize.height}>
      {!loading && items?.length > 0 ? (
        <>
          <ImageSection style={{
            height: height - 60,
            padding: windowSize.width > 600 ? '12px 12px 0 12px' : undefined
          }}>        
            <WrapImages ref={listPanelRef} panelHeight={panelHeight}>
              <ImageList $premiumImages={selectedItem?.premium} style={{ padding : '0px 1px'}}>
                {!selectedItem?.premium && items && items.map((item, i) => (
                  <DisplayItem
                    key={i}
                    item={item}
                    index={i}
                    panelWidth={panelWidth}
                  />
                ))}

                {selectedItem?.premium && selectedItem?.premiumDisplayImages && selectedItem?.premiumDisplayImages.map((item, i) => (
                  <PremiumImagesCovers
                    key={i}
                    item={item}
                    index={i}
                    panelWidth={panelWidth}
                  />
                ))}
              </ImageList>
            </WrapImages>

            {/* {imageDisplayReady && windowSize.width <= 600 ? (
              <Creator>
                <Avatar loading="lazy" src="https://images.unsplash.com/photo-1635322641095-682ca42e2b3d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" />
                <MadeBy to={`/u/oat-all`}>
                  By <span>Aung Khant</span>
                </MadeBy>
              </Creator>
            ) : undefined} */}
          </ImageSection>

          {modalOpen ? ( //imageDisplayReady &&
            <BottomPanel style={{height: 60}}>
              {/* {windowSize.width >= 600 ? (
                <BtmChildSection>
                  <Avatar loading="lazy" src="https://images.unsplash.com/photo-1635322641095-682ca42e2b3d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" />
                  <MadeBy to={`/u/aung`}>
                    By <span>Aung Khant</span>
                  </MadeBy>
                </BtmChildSection>
              ) : undefined} */}

              <Error>{error.status ? error.message : undefined}</Error>

              <BtmChildSection style={{display: "flex", justifyContent: 'flex-end', width: '100%'}}>
                <div style={{
                  fontSize: '1rem', 
                  marginRight: selectedItem.premium ? 30 : 0}}
                >
                  {!selectedItem.premium ? 
                    <PricingType style={{ marginRight: 20 }}>Free</PricingType> : 
                    // do not delete "$ " + `${selectedItem?.price?.value}`
                    <RequiresUpgradeToPro>Pro</RequiresUpgradeToPro>
                  }
                </div>
                {user.loggedIn?.status && selectedItem.premium ? 
                  <UseTemplate
                    onClick={handlePurchase}
                    style={{
                      width: 80,
                      minWidth: 80,
                      opacity: stripeProcessing ? 0.6 : 1,
                      pointerEvents: stripeProcessing ? "none" : undefined,
                    }}>
                    {!stripeProcessing ? 
                      // "Buy" 
                      "Upgrade"
                      : <ButtonSpinner />}
                  </UseTemplate> 
                  : !user.loggedIn?.status ?
                    (<UseTemplate onClick={redirectToRegister}>
                      Register
                    </UseTemplate>) 
                  : undefined
                }
              </BtmChildSection>
            </BottomPanel>
          ) : undefined}
        </>
      ) : (
        <WrapSpinner style={{marginTop: 120}}>
          <Spinner />
        </WrapSpinner>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  align-items: center;
  min-height: 300px;
  overfow-y: scroll;
`;

const ImageList = styled.div`
  columns: 4;
  grid-gap: 10px 15px;
  display: grid;
  grid-template-columns: ${({$premiumImages}) => $premiumImages ? `1fr` : `1fr 1fr 1fr 1fr`};
  width: 100%;
  padding-left: 10px;
  margin-top: 1px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 949px) {
    padding-left: 0px;
  }
  @media only screen and (max-width: 850px) {
    columns: 3;
    grid-template-columns: ${({$premiumImages}) => $premiumImages ? `1fr` : `1fr 1fr 1fr`};
  }
  @media only screen and (max-width: 600px) {
    columns: 2;
    grid-template-columns: ${({$premiumImages}) => $premiumImages ? `1fr` : `1fr 1fr`};
  }
`;

const RequiresUpgradeToPro = styled.div`
  ${requiresUpgradeToProStyles}
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(ImagesIconsPackDetails);
