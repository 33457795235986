import {
  TEMPLATE_LIST_INIT,
  TEMPLATE_LIST_SUCCESS,
  TEMPLATE_LIST_ERR,
  CREATE_DESIGN_SWITCH_TAB,
} from "../../actionTypes";

export function tempListInit(paginate) {
  return {
    type: TEMPLATE_LIST_INIT,
    paginate
  };
}

export function tempListSuccess({
  templates,
  userMadeTemplates,
  lastEvaluatedKey,
  paginate
}) {
  return {
    type: TEMPLATE_LIST_SUCCESS,
    templates,
    userMadeTemplates,
    lastEvaluatedKey,
    paginate
  };
}

export function tempListErr() {
  return {
    type: TEMPLATE_LIST_ERR,
  };
}

export function createDesignSwitchTab(activeTab) {
  return {
    type: CREATE_DESIGN_SWITCH_TAB,
    activeTab,
  };
}

export const fetchOatTemplateList = ({api, paginate}) => {
  return async (dispatch) => {
    dispatch(tempListInit(paginate));
    try {
      await fetch(api)
        .then((response) => response.json())
        .then((data) => {
          const refineData = data.items.map((item) => {
            return {
              ...item,
              image: item.thumbs[0],
            };
          });
          dispatch(
            tempListSuccess({
              templates: refineData,
              userMadeTemplates: false, // oat temp
              lastEvaluatedKey: data.paginationToken,
              paginate
            })
          );
        })
        .catch((e) => {
          dispatch(tempListErr(e))
        });
    } catch (e) {
      dispatch(tempListErr(e));
    }
  };
};

export const fetchUserTempForSelection = ({api, paginate}) => {
  return async (dispatch) => {
    dispatch(tempListInit(paginate));
    try {
      await fetch(api)
        .then((response) => response.json())
        .then((data) => {
          dispatch(
            tempListSuccess({
              templates: data.response.templates,
              userMadeTemplates: true, // user made template
              lastEvaluatedKey: data.response.paginationToken,
              paginate
            })
          );
        })
        .catch((e) => dispatch(tempListErr(e)));
    } catch (e) {
      dispatch(tempListErr(e));
    }
  };
};
