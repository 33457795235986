import {getHighestZIndex} from "./utils";
import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import { themeBuilder } from "../../data/themeBuilder.data";

// export function resizeImageField() {
//   return function (state, action) {
//     const {activeSlide, activeField} = state;
//     const newState = [...state.template];
//     const getItem = newState[activeSlide].fields[activeField];

//     newState[activeSlide].fields[activeField] = {
//       ...getItem,
//       size: action.size,
//       ["styles"]: {
//         ...getItem.styles,
//         width: action.styles.fieldWidth,
//         height: action.styles.fieldHeight,
//       },
//     };
//     return {
//       ...state,
//       template: newState,
//     };
//   };
// }

export function transformImgOffset() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...getItem,
      imgPos: action.offset,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function uploadImage() {
  return function (state, action) {
    const {template, size} = state;
    const {slideID, key, imgSize, fieldSize, imageBy, selectFieldOnCreate} = action;
    const {fileType, fileSize, signedUrl, imgKey, external} = action.imgInfo;
    let highestZIndex = getHighestZIndex(template, slideID);
    const currentSlide = template[slideID];
    Object.entries(currentSlide.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });

    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: size,
    });
    let activeTheme = state.themes.find(
      (theme) => theme.id === state.activeTheme
    );
    if(typeof activeTheme === 'undefined') {
      activeTheme = themeBuilder
    }
    const adjustedPos = (10 * size.w) / 100;
    const addNewField = {
      key,
      pos: {x: adjustedPos, y: adjustedPos},
      imgPos: {x: 0, y: 0},
      content: signedUrl,
      type: "image",
      deleted: false,
      resizer: "all",
      ["styles"]: {
        rotate: 0,
        brightness: 100,
        blur: 0,
        opacity: 1,
        contrast: 100,
        saturate: 100,
        sepia: 0,
        grayScale: 0,
        hueRotate: 0,
        enabledShadow: false,
        shadowType: "classic",
        shadowSize: 0.8,
        shadowBlur: 7,
        shadowColor: activeTheme?.image?.shadowColor,
        zIndex: highestZIndex + 1,
        enableOverlay: false,
        overlayColor: "#f0a7a7ff",
        overlayType: "half",
        overlayDegree: 180,
        scaleX: 1,
        scaleY: 1,
        borderRadius: 0,
        lock: false,
        animateOnSlideDisplay: null,
        animationType: "none",
        animationSpeed: 0.5,
        animationDelay: 0,
        animationOrder: 0,
        enableExitAnimation: false,
      },
      size: {
        w: fieldSize.width,
        h: fieldSize.height,
        w2: imgSize.width,
        h2: imgSize.height,
      },
      // frame: frame !== undefined ? frame : undefined,
      // effects: {}, // CHECK
      // effectsOn: false, // CHECK
      fileType,
      fileSize,
      imgKey,
      imageBy,
      external
    };
    const newState = [...state.template];
    newState[slideID].fields = {
      ...newState[slideID].fields,
      [key]: addNewField,
    };
    return {
      ...state,
      template: newState,
      activeField: key,
      selectedFieldType: "image",
      cachedFieldStyles: newState[slideID].fields[key].styles,
      replaceImage: false,
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      fieldToEmitFromSocket: {
        type: "create",
        field: {
          ...addNewField,
          key,
        },
      },
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
    };
  };
}

export function applyImageFilters() {
  return function (state, action) {
    const {active, value} = action;
    const newState = [...state.template];
    const getItem = newState[active.slideID].fields[active.fieldID];
    newState[active.slideID].fields[active.fieldID] = {
      ...getItem,
      ["styles"]: {
        ...getItem.styles,
        ...value,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function replaceImage() {
  return function (state, action) {
    const newState = [...state.template];
    const {imgSize, fieldSize, active, imageBy} = action;
    const getItem = newState[active.slideID].fields[active.fieldID];
    const {fileType, fileSize, signedUrl, imgKey, external} = action.imgInfo;
    newState[active.slideID].fields[active.fieldID] = {
      ...getItem,
      content: signedUrl,
      imgPos: {x: 0, y: 0},
      size: {
        w: fieldSize.width,
        h: fieldSize.height,
        w2: imgSize.width,
        h2: imgSize.height,
      },
      imageBy, 
      imgKey,
      fileType,
      fileSize,
      external
    };
    return {
      ...state,
      template: newState,
      replaceImage: false,
      openModal: {
        status: false,
        type: "",
      },
      fieldToEmitFromSocket: {
        type: "update",
        field: newState[active.slideID].fields[active.fieldID],
      },
    };
  };
}

export function replaceImageStatus() {
  return function (state) {
    return {
      ...state,
      replaceImage: !state.replaceImage,
    };
  };
}

export function cropImageStatus() {
  return function (state, action) {
    return {
      ...state,
      cropImage: action.value,
    };
  };
}
