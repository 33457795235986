import React, {useState} from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {Title} from "../../shared/reuseStyles/Title";
import {Accordion, Expand, WrapBox} from "../common/AccordionStyles";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";

const AdjustImg = ({
  slideID,
  fieldID,
  styles,
  updateFieldStyles,
  stableStyles,
}) => {
  const [openBox, setOpenBox] = useState(false);
  const windowSize = getWindowSize();

  const onUpdateStyles = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const updateBrightness = (value) => {
    onUpdateStyles("brightness", value);
  };

  const updateContrast = (value) => {
    onUpdateStyles("contrast", value);
  };

  const updateSaturate = (value) => {
    onUpdateStyles("saturate", value);
  };

  const updateSepia = (value) => {
    onUpdateStyles("sepia", value);
  };

  const updateGrayScale = (value) => {
    onUpdateStyles("grayScale", value);
  };

  const updateHueRotate = (value) => {
    onUpdateStyles("hueRotate", value);
  };

  return (
    <>
      {windowSize.width >= 850 ? (
        <Accordion onClick={() => setOpenBox(!openBox)}>
          <Title>Effects</Title>
          <Expand>{openBox ? "-" : "+"}</Expand>
        </Accordion>
      ) : undefined}
      {openBox || windowSize.width < 850 ? (
        <WrapBox>
          <div style={{paddingBottom: "2em"}}>
            <RangeSlider
              title="Brightness"
              value={styles?.brightness}
              min={50}
              max={200}
              step={1}
              stableValue={stableStyles?.brightness}
              onItemChange={updateBrightness}
              fieldType="brightness"
            />
          </div>
          <div style={{paddingBottom: "2em"}}>
            <RangeSlider
              title="Contrast"
              value={styles?.contrast}
              min={50}
              max={200}
              step={1}
              stableValue={stableStyles?.contrast}
              onItemChange={updateContrast}
              fieldType="contrast"
            />
          </div>
          <div style={{paddingBottom: "2em"}}>
            <RangeSlider
              title="Saturate"
              value={styles?.saturate}
              min={0}
              max={200}
              step={1}
              stableValue={stableStyles?.saturate}
              onItemChange={updateSaturate}
              fieldType="saturate"
            />
          </div>
          <div style={{paddingBottom: "2em"}}>
            <RangeSlider
              title="Sepia"
              value={styles?.sepia}
              min={0}
              max={200}
              step={1}
              stableValue={stableStyles?.sepia}
              onItemChange={updateSepia}
              fieldType="sepia"
            />
          </div>
          <div style={{paddingBottom: "2em"}}>
            <RangeSlider
              title="Gray Scale"
              value={styles?.grayScale}
              min={0}
              max={200}
              step={1}
              stableValue={stableStyles?.grayScale}
              onItemChange={updateGrayScale}
              fieldType="grayScale"
            />
          </div>
          <div>
            <RangeSlider
              title="Hue Rotate"
              value={styles?.hueRotate}
              min={0}
              max={200}
              step={1}
              stableValue={stableStyles?.grayScale}
              onItemChange={updateHueRotate}
              fieldType="hueRotate"
            />
          </div>
        </WrapBox>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(AdjustImg);
