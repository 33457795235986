import styled from "styled-components";
import {black1, greyText1, paleBlue5} from "../../../../oat-color-variables";
import {red, userPrimaryGrey} from "../../../userDashboardStyles";

export const Layout = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  z-index: 9999;
  box-shadow: 0px 0px 5px 0px #cbc3de;
`;

export const ContentSection = styled.div`
  padding: 15px 12px;
  box-sizing: border-box;
`;

export const Header = styled.h3`
  font-size: 1rem;
  color: #7275f4;
  text-transform: capitalize;
  padding-bottom: 0.8rem;
`;

export const ModalBody = styled.div`
  padding: 15px 12px;
`;

export const BodyScroll = styled.div`
  height: ${({height}) => height}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  &::-webkit-scrollbar {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WrapField = styled.div`
  margin: 2px 0 8px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  padding: 0.3rem 0 0.7rem;
  color: #535353;
  position: relative;
`;

export const FieldName = styled.div`
  flex: 1;
  color: ${userPrimaryGrey};
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 58px;
  flex-direction: column;
  background: ${paleBlue5};
  border-top: 1px solid #dddde1;
  padding: 10px;
  box-sizing: border-box;
`;

export const FooterBtn = styled.div`
  display: flex;
  width: auto;
  height: 34px;
  margin-right: 8px;
`;

export const BottomSection = styled.div`
  display: flex;
  width: auto;
  height: 34px;
`;

export const Error = styled.div`
  color: ${red};
  font-size: 0.9rem;
  float: right;
  margin: 4px 6px 8px;
`;

export const TextField = styled.input`
  width: 100%;
  height: 32px;
  color: #232323;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #cdcdcd;
  padding: 6px 3px;
  box-sizing: border-box;
`;

export const WrapForm = styled.div`
  padding: 12px 0;
  box-sizing: border-box;
`;

export const Message = styled.p`
  font-size: 0.92rem;
  color: ${black1};
  padding: 4px 0;
  box-sizing: border-box;
  line-height: 22px;
`;

export const Cancel = styled.div`
  font-size: 0.85rem;
  cursor: pointer;
  color: ${greyText1};
`;
