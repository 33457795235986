import React, {useState} from "react";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {toggleBuildingThemeStatus} from "../../store/actions/template/theme.action";
import {
  createTheme,
  updateTheme,
} from "../../store/actions/template/theme.action";
import {createShortKey} from "../../store/reducers/functions/fields/utils";
import {selectThemeBuilder} from "../../store/selectors/template/theme.selector";
import {useSocket} from "../../webSocket/useSocket";
import ThemeBuilder from "./ThemeBuilder";

const WrapThemeBuilder = ({
  buildingTheme,
  createTheme,
  updateTheme,
  toggleBuildingThemeStatus,
  currentTheme,
}) => {
  const [processing, setProcessing] = useState(false);
  const {emitSocketThemeChanges} = useSocket() || {};
  let navigate = useNavigate();
  const location = useLocation();

  const handleBackButton = () => {
    toggleBuildingThemeStatus();
  };

  const saveTheme = async () => {
    const {updatingTheme, selectedIndexToBeUpdated} = buildingTheme;
    if (updatingTheme) {
      await updateTheme(selectedIndexToBeUpdated);
      await updateThemeEmitSocket();
    } else {
      const newkey = createShortKey();
      await createTheme(currentTheme.name, newkey);
      await createThemeEmitSocket(newkey);
    }
    return navigate(`${location.pathname}`);
  };

  const updateThemeEmitSocket = async () => {
    const item = {type: "update-theme", value: currentTheme};
    await emitSocketThemeChanges({actionType: "theme-changed", item});
    await handleBackButton();
  };

  const createThemeEmitSocket = async (themeKey) => {
    const item = {
      type: "create-theme",
      value: {
        ...currentTheme,
        id: themeKey,
      },
    };
    await emitSocketThemeChanges({actionType: "theme-changed", item});
    await handleBackButton();
  };

  return (
    <ThemeBuilder
      goBack={handleBackButton}
      buildingTheme={buildingTheme}
      onSubmit={saveTheme}
      processing={processing}
      buttonTitle="Save"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    currentTheme: selectThemeBuilder(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateTheme,
  createTheme,
  toggleBuildingThemeStatus,
})(WrapThemeBuilder);
