import React from 'react';
import LayoutHoc from '../../layout/LayoutHoc';
import IllustrationServices from '../../bespokeServices/IllustrationServices';

const BespokeIllustrations = () => {
    return (
        <LayoutHoc>
            <IllustrationServices />
        </LayoutHoc>
    )
}

export default BespokeIllustrations;