import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {selectUserInfo} from "../../../../redux/user/authUser";
import {
  black1,
  grey4,
  greyText1,
  greyText2,
  purple2,
  red1,
  red4,
} from "../../../oat-color-variables";
import {Clear} from "../../../oat-svg-icons";
import Check from "../../../oat-svg-icons/icons/Check";
import {Button, ButtonSpinner, Spinner} from "../../../oat-ui";
import {Radio, RadioButton} from "../../../oat-ui/styles/RadioStyles";
import {selectActiveWorkSpace} from "../../../oat-user-dashboard/store/selectors/userDashboard.selector";
import FAQuestions from "../../faq/components/FAQuestions";

const featureList = [
  {
    feature: "Workspace Roles ",
    description: "Assign members to roles and access levels",
    free: "Owners Only",
    pro: "Owners & Admins",
  },
  {
    feature: "Guest Users",
    description: "Invite users to edit designs without having them to inivite to your workspace as members.",
    free: "3",
    pro: "50",
  },
  {
    feature: "Unlimited Designs",
    description: "Create as many designs as you want.",
    free: true,
    pro: true,
  },
  {
    feature: "Folders",
    description: "Create unlimited folders to organize your projects.",
    free: false,
    pro: true,
  },
  {
    feature: "Private Folders",
    description: "Restrict access levels only to specific users.",
    free: false,
    pro: true,
  },
  {
    feature: "Workspace Library",
    description: "Centrally store fonts, images and resuable templates.",
    free: false,
    pro: true,
  },
  {
    feature: "Workspace Storage",
    description: "Storage space allocated for each workspace.",
    free: "1GB per workspace",
    pro: "15GB per member",
  },
  {
    feature: "Exporting Designs",
    description: "Export your designs in JPG, PNG or PDF formats.",
    free: false,
    pro: true,
  },
  {
    feature: "Passcode Protected",
    description: "Grant access to content only to users with access to the code.",
    free: false,
    pro: true,
  },
  {
    feature: "Design Analytics",
    description: "Analyze how your users view and consume your contents. (Coming soon)",
    free: false,
    pro: true,
  },
];

const PlansComparison = ({workspace, user}) => {
  const [planType, setPlanType] = useState("yearly");
  const [subscribedInterval, setSubscribedInterval] = useState("month");
  const [isSubscriptionCanceled, setIsSubscriptionCanceled] = useState({});
  const [fetching, setFetching] = useState(false);
  const [processing, setProcessing] = useState({
    type: null,
    status: false,
    error: false,
    errorMsg: null,
  });

  const selectAnnualPlan = () => {
    setPlanType("yearly");
  };

  const selectMonthlyPlan = () => {
    setPlanType("monthly");
  };

  const upgradeSubscription = async () => {
    if (workspace.role === "owner" && !processing.status) {
      setProcessing({type: "pro", status: true, error: false, errorMsg: null});
      await fetch(
        `https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/user-subscription/${user.username}/workspaces/${workspace.ID}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plan: planType === "yearly" ? "pro-yearly" : "pro-monthly", // "pro-monthly",
            url: window.location.href
          }),
        }
      )
        .then((res) => {
          if (res.status == 303) {
            return res.json();
          }
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({url}) => (window.location = url))
        .catch((error) => {
          setProcessing({
            type: null,
            status: false,
            error: true,
            errorMsg: error.error,
          });
        });
    } else {
      setProcessing({
        type: "pro",
        status: false,
        error: true,
        errorMsg: "Only workspace owners can upgrade the workspace.",
      });
    }
  };

  const switchPlan = async () => {
    if (workspace.role === "owner" && !processing.status) {
      setProcessing({type: "pro", status: true, error: false, errorMsg: null});
      await fetch(
        `https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/user-subscription/${user.username}/workspaces/${workspace.ID}/update-plan`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plan:
              subscribedInterval === "year"
                ? "monthly"
                : subscribedInterval === "month"
                ? "yearly"
                : undefined,
            // "price_1NClDKGDq2wjwmJgwEHl6Cti",
            // subscribedInterval === "yearly"
            //   ? "price_1LefLLGDq2wjwmJgSmclom86" // monthly
            //   : "price_1LefLLGDq2wjwmJgiicPlNf3", // yearly
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            window.location.reload();
            // setProcessing({type: null, status: false, error: false});
          } else {
            setProcessing({
              type: null,
              status: false,
              error: true,
              errorMsg: "Something went wrong.",
            });
          }
        })
        .catch((error) => {
          setProcessing({
            type: null,
            status: false,
            error: true,
            errorMsg: error.message,
          });
        });
    } else {
      setProcessing({
        type: "pro",
        status: false,
        error: true,
        errorMsg: "Only workspace owners can switch plans.",
      });
    }
  };

  const cancelSubscription = async () => {
    if (workspace.role === "owner" && !processing.status) {
      setProcessing({type: "free", status: true, error: false});
      await fetch(
        `https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/user-subscription/${user.username}/workspaces/${workspace.ID}/cancel-plan`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            window.location.reload();
            // setProcessing({type: null, status: false, error: false});
          } else {
            setProcessing({
              type: null,
              status: false,
              error: true,
              errorMsg: "Something went wrong.",
            });
          }
        })
        .catch((error) => {
          setProcessing({
            type: null,
            status: false,
            error: true,
            errorMsg: error,
          });
        });
    } else {
      setProcessing({
        type: "pro",
        status: false,
        error: true,
        errorMsg: "Only workspace owners can cancel a plan.",
      });
    }
  };

  useEffect(() => {
    if (workspace.plan === "pro") {
      setFetching(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspace.ID}/payments/invoices`
      )
        .then((response) => response.json())
        .then((data) => {
          setFetching(false);
          if (data.status === "success") {
            setSubscribedInterval(data.response.subscriptionInterval);

            if (data.response?.isSubscriptionCanceled?.cancel_at) {
              const date = new Date(
                data.response.isSubscriptionCanceled.cancel_at * 1000
              );
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
              const day = String(date.getDate()).padStart(2, "0");

              setIsSubscriptionCanceled({
                ...data.response.isSubscriptionCanceled,
                cancel_at: `${day} / ${month} / ${year}`,
              });
            } else {
              setIsSubscriptionCanceled({
                status: false,
              });
            }
          }
        })
        .catch((error) => { setFetching(false) });
    }
  }, [workspace.plan]);

  return (
    <Layout>
      <Header>
        <MainColumn>Features</MainColumn>
        <Column style={{color: red1}}>Free</Column>
        <Column style={{color: purple2}}>Pro</Column>
      </Header>

      <Row>
        <MainColumn>
          <WrapInput>
            <Radio onClick={selectMonthlyPlan}>
              <RadioButton active={planType === "monthly"}>
                <div />
              </RadioButton>
              <span>Monthly</span>
            </Radio>

            <Radio onClick={selectAnnualPlan} style={{marginBottom: 0}}>
              <RadioButton active={planType === "yearly"}>
                <div />
              </RadioButton>
              <span>Yearly</span>
            </Radio>
          </WrapInput>
        </MainColumn>

        <Column>
          <div className="plan">
            <div className="plan-price"> $ 0 </div>
          </div>
          <div className="plan-interval">
            <span> - </span>
          </div>

          <div className="plan">
            <div className="plan-interval">
              {workspace.plan === "pro" &&
              !isSubscriptionCanceled?.status &&
              (subscribedInterval === "month" ||
                subscribedInterval === "year") ? (
                <Button
                  styles={{
                    minWidth: 90,
                    height: 32,
                    background: 'transparent',
                    color: '#6a6a6a',
                    textDecoration: 'underline',
                    opacity: workspace.role === "owner" ? 1 : 0.6,
                  }}
                  onClick={cancelSubscription}
                  disabled={processing.status && processing.type === "free"}
                >
                  {processing.status && processing.type === "free" ? (
                    <ButtonSpinner />
                  ) : (
                    "Downgrade"
                  )}
                </Button>
              ) : workspace.plan === "free" ? (
                <p> Current Plan</p>
              ) : undefined}
            </div>
          </div>
        </Column>

        <Column>
          <div className="plan">
            <div className="plan-price">
              $ {planType === "yearly" ? 7 * 12 : 9}
            </div>
            <div className="plan-interval">
              {planType === "yearly" ? (
                <span>annually</span>
              ) : (
                <span>per month</span>
              )}
            </div>
          </div>

          <div className="plan-interval">
            {planType === "yearly" ? <span>($7 per month)</span> : undefined}
          </div>

          <Column style={{margin: 8}}>
            {workspace?.plan === "free" ? (
              <Button
                styles={{
                  minWidth: 90,
                  height: 32,
                  opacity: workspace.role === "owner" ? 1 : 0.6,
                }}
                onClick={upgradeSubscription}
                disabled={processing.status && processing.type === "pro"}
              >
                {processing.status && processing.type === "pro" ? (
                  <ButtonSpinner />
                ) : (
                  "Upgrade"
                )}
              </Button>
            ) : undefined}

            {workspace.plan === "pro" &&
            ((subscribedInterval === "month" && planType === "monthly") ||
              (subscribedInterval === "year" && planType === "yearly")) ? (
              <div
                className="plan-interval"
                style={{marginTop: 8, fontSize: "0.9rem"}}
              >
                <span>Current Plan</span>
              </div>
            ) : undefined}

            {workspace?.plan === "pro" &&
            !isSubscriptionCanceled?.status &&
            ((subscribedInterval === "month" && planType === "yearly") ||
              (subscribedInterval === "year" && planType === "monthly")) 
              ? (
              <Button
                styles={{
                  minWidth: 90,
                  height: 32,
                  opacity: workspace.role === "owner" ? 1 : 0.6,
                }}
                onClick={switchPlan}
                disabled={processing.status && processing.type === "pro"}
              >
                {processing.status && processing.type === "pro" ? (
                  <ButtonSpinner />
                ) : (
                  "Change"
                )}
              </Button>
            ) : undefined}
          </Column>
        </Column>
      </Row>


      {fetching ? ( 
        <Row style={{ justifyContent: 'center' }}>
          <div><Spinner /></div>
        </Row>
      ) : undefined} 

      {isSubscriptionCanceled.status ? (
        <Row>
          <div style={{marginTop: 8, fontSize: "0.97rem", color: "#404040"}}>
            <p>
              Cancellation will take effect on{" "}
              {isSubscriptionCanceled.cancel_at}
            </p>
          </div>
        </Row>
      ) : undefined}
      
      {/* {workspace.role !== "owner" ? (
        <Row style={{color: red4, display: "flex", justifyContent: "center"}}>
          Only owners can upgrade/downgrade the workspace
        </Row>
      ) : undefined} */}

      {processing.error ? (
        <Row>
          <MainColumn></MainColumn>
          <Column style={{flex: 2}}>
            <div
              style={{
                color: red4,
                fontSize: "0.95rem",
                fontWeight: "500",
                width: "100%",
                textAlign: "center",
              }}
            >
              {processing.errorMsg}
            </div>
          </Column>
        </Row>
      ) : undefined}

      {featureList.map((item, i) => (
        <Row key={i}>
          <MainColumn>
            <Feature>{item.feature}</Feature>
            <Description>{item.description}</Description>
          </MainColumn>
          <Column>
            {"boolean" === typeof item.free ? (
              <WrapSvg style={{fill: item.free ? "#f3475d" : "#898989"}}>
                {item.free ? Check : Clear}
              </WrapSvg>
            ) : (
              <p className="comparison-text">{item.free}</p>
            )}
          </Column>
          <Column>
            {"boolean" === typeof item.free ? (
              <WrapSvg style={{fill: purple2}}>{Check}</WrapSvg>
            ) : (
              <p className="comparison-text">{item.pro}</p>
            )}
          </Column>
        </Row>
      ))}

      <div style={{marginTop: 160}}>
        <FAQuestions disableFlex={true} showOnlyPayment={true} />
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const WrapInput = styled.div`
  flex: 1.2;
`;

const mainColStyles = css`
  display: flex;
  border-bottom: 1px solid #dedede;
  padding: 25px 0;
  @media only screen and (max-width: 768px) {
    min-width: 520px;
  }
`;

const Header = styled.div`
  font-size: 1.2rem;
  ${mainColStyles};
`;

const MainColumn = styled.div`
  flex: 2;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  .comparison-text {
    font-weight: 500;
    text-align: center;
  }
  .plan {
    display: flex;
    align-items: center;
  }
  .plan-price {
    padding: 0 8px;
  }
  .plan-interval {
    display: flex;
    flex-direction: column;
    p {
      font-size: 0.9rem;
      color: ${grey4};
    }
    span {
      font-size: 0.8rem;
      color: ${grey4};
    }
  }
`;

const Row = styled.div`
  font-size: 1rem;
  ${mainColStyles};
`;

const Feature = styled.div`
  padding-bottom: 12px;
  font-weight: 600;
  color: ${black1};
`;

const Description = styled.div`
  color: ${greyText2};
  font-size: 0.95rem;
`;

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: #fff;
  padding-right: 6px;
`;

const SwitchPlan = styled.div`
  font-size: 0.85rem;
  color: ${greyText1};
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(PlansComparison);
