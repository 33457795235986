import React, {useMemo} from "react";
import styled, {css} from "styled-components";

const nameTabPadding = 12;
const nameTabLeftOffset = 8;

const SelectedOnlineUsers = ({field, username, item, tempScale}) => {
  const {pos, size, zIndex, styles} = field || {};
  const fieldStyles = useMemo(
    () => ({
      width: size?.w,
      height: size?.h,
      transform: `translate(${pos?.x}px, ${pos?.y}px) rotate(${styles?.rotate}deg)`,
    }),
    [size, pos, zIndex, styles.rotate]
  );

  // cannot select if another user has already selected
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return;
  };

  return (
    <Layout
      onClick={handleClick}
      style={{
        ...fieldStyles,
        border: `${1 / tempScale}px solid ${item?.color}`,
      }}
    >
      <NameTab
        tempScale={tempScale}
        color={item.color}
      >
        {item?.name}
      </NameTab>
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  z-index: 9999;
`;

export const nameTabStyles = css`
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  user-select: none;
  width: auto;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const NameTab = styled.div`
  ${nameTabStyles};
  top: ${({tempScale}) => -22 / tempScale}px;
  left: ${({tempScale}) => nameTabLeftOffset / tempScale}px;
  font-size: ${({tempScale}) => 13.5 / tempScale}px;
  height: ${({tempScale}) => 20 / tempScale}px;
  background: ${({color}) => color};
  text-transform: capitalize;
  padding: ${({tempScale}) => `2px ${nameTabPadding / tempScale}`}px;
  border-top-left-radius: ${({tempScale}) => 4 / tempScale}px;
  border-top-right-radius: ${({tempScale}) => 4 / tempScale}px;
`;

export default SelectedOnlineUsers;
