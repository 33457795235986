import React from "react";
import {OatTemplates} from "../../../packages/oat-templates";
import styled from "styled-components";
import {LayoutHoc} from "../../../packages/oat-public";

const Templates = () => {
  return (
    <LayoutHoc>
      <Wrapper>
        <OatTemplates />
      </Wrapper>
    </LayoutHoc>
  );
};

const Wrapper = styled.div``;

export default Templates;
