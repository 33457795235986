import React, {useState} from 'react';
import { dashboardSidebarWidth } from '../../sidebar/UserSidebar';
import { userDashboardNavPanelHeight } from '../../navigation/UserDashboardNav';
import styled from 'styled-components';
import { ActiveDot, Item } from '../../sidebar/reusableStyles/sidebarItem';

const items = [
    { key: "all-items", display: "All Items"  },
];

const DisplayItem = ({item, onSelect, selectedTab}) => {
    const handleClick = () => {
        onSelect(item.key);
    };

    return (
        <Item onClick={handleClick}>
            <p>{item.display}</p>
            {item.key === selectedTab ? (
                <ActiveDot active={true} />
            ) : undefined}
        </Item>
    )
}

const PurchasedByDate = () => {
    const [selectedTab, setSelectedTab] = useState("all-items");

    const handleSelect = (value) => {
        setSelectedTab(value);  
    };

    return (
        <Layout style={{
            width: dashboardSidebarWidth,
            height: window.innerHeight - 57,
        }}>           
            {items && items.map((item) => (
                <DisplayItem key={item.key} item={item} selectedTab={selectedTab} onSelect={handleSelect}/>
            ))}
        </Layout>
    )
};

const Layout = styled.div`
    width: ${dashboardSidebarWidth}px;
    height: ${window.innerHeight - 57}px;
    padding-top: ${userDashboardNavPanelHeight + 16}px;
    background: #fff;
    top: 57px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 849px) {
        display: none;
    }
    > div {
        margin-bottom: 12px;
    }
`

export default PurchasedByDate;