import React, { useState, useLayoutEffect } from "react";
import Resizer from "./Resizer";
import styled from "styled-components";
import {resizerColor} from "../../../../../themes";
import {connect} from "react-redux";
import {selectTemplateSize} from "../../../../../store/selectors/template/template.selector";
import {selectCanvasPanelWidth} from "../../../../../store/selectors/layout/layout.selector";
import {RotateSvg} from "../../../../../ui/svg/RotateSvg";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import { selectInterceptedFields } from "../../../../../store/selectors/common.selector";

const resizeDirections = [
  {
    className: "resizer top-left",
    dir: "top-left",
    corners: true,
    cursor: "nwse", // "nw",
  },
  {
    className: "resizer top-right",
    dir: "top-right",
    corners: true,
    cursor: "nesw", //"ne",
  },
  {
    className: "resizer bottom-left",
    dir: "bottom-left",
    corners: true,
    cursor: "nesw", //"sw",
  },
  {
    className: "resizer bottom-right",
    dir: "bottom-right",
    corners: true,
    cursor: "nwse", //"se",
  },
  {className: "left", dir: "left", corners: false, cursor: "ew"}, //"w"},
  {className: "right", dir: "right", corners: false, cursor: "ew"}, //"e"},
  {className: "top", dir: "top", corners: false, cursor: "ns"}, //"n"},
  {className: "bottom", dir: "bottom", corners: false, cursor: "ns"}, //"s"},
];

// Changes in these varibales should also reflect in Resizer.js
export const topBtmWidth = 15;
export const topBtmHeight = 5;
export const leftRightWidth = 5;
export const leftRightHeight = 15;
export const cornerSize = 10;

const Resizable = ({
  field,
  dragging,
  resizing,
  tempScale,
  displayResizers,
  direction,
  hideBorder,
  rotateStart,
  dragStart,
  resizeStart,
  tempSize,
  canvasWidth,
  adjustXOffset,
  groupedItems
  // editingText,
}) => {
  const windowSize = getWindowSize();
  const {pos, styles, resizer, groupAsOne, type} = field || {};
  const adjustedXOffset =
    adjustXOffset && windowSize.width >= 850
      ? Math.round(canvasWidth - tempSize.w * tempScale) / 2
      : 0;

  const [size, setSize] = useState({w: 0, h: 0});
  const [offset, setOffset] = useState({x: 0, y: 0});
  useLayoutEffect(() => {
    let width = 0, height = 0;
    if (groupAsOne?.keys && groupAsOne?.keys?.length > 0 && groupedItems?.selection?.show) {
      setSize({
        w: Math.round((groupAsOne?.size?.w) * tempScale),
        h: Math.round((groupAsOne?.size?.h) * tempScale),
      });
      setOffset({
        x: groupAsOne?.offset?.x * tempScale + adjustedXOffset,
        y: groupAsOne?.offset?.y * tempScale
      });
    } else {
      width = field?.size?.w !== "auto" ? Math.round((field?.size?.w) * tempScale) : 0;
      height = field?.size?.h !== "auto" ? Math.round((field?.size?.h) * tempScale) : 0;
      setSize({
        w: width,
        h: height
      });
      setOffset({
        x: pos?.x * tempScale + adjustedXOffset,
        y: pos?.y * tempScale
      });
    }
  }, [field?.size, pos, groupAsOne?.keys, tempScale, adjustedXOffset, groupedItems?.selection?.show]);

  return (
    <WrapResizer
      className="oat-all resizers"
      style={{
        position: "absolute",
        width: size?.w,
        // size.w !== "auto" ? Math.round((size.w + 0) * tempScale) : 0, //2
        height: size?.h,
        // size.h !== "auto" ? Math.round((size.h + 0) * tempScale) : 0, //2
        // height: 1 * tempScale,
        // WebkitBorderBefore: `1px solid ${resizerColor}`,
        border: !hideBorder ? `1px solid ${resizerColor}` : undefined,
        outline:
          !dragging && !resizing
            ? `rgba(234, 243, 247, 0.5) solid 1.5px`
            : undefined,
        transform: `translate(${offset?.x}px, ${offset?.y}px) rotate(${styles?.rotate}deg)`,
        // `translate(${pos.x * tempScale + adjustedXOffset}px, ${
        //   pos.y * tempScale 
        // }px) rotate(${
        //   styles.rotate
        // }deg)`,
        transformOrigin: "50% 50%",
      }}
      onMouseDown={dragStart}
    >
      {!displayResizers ||
        (!dragging &&
          // !textEditStatus &&
          resizeDirections.map((item, i) => {
            if (direction === item.dir && resizing) {
              return (
                <Resizer
                  item={item}
                  key={i}
                  resizer={resizer}
                  resizeStart={resizeStart}
                  posLock={styles?.lock}
                  angle={styles?.rotate}
                  onRotateStart={rotateStart}
                />
              );
            } else if (!resizing) {
              return (
                <Resizer
                  item={item}
                  key={i}
                  resizer={resizer}
                  resizeStart={resizeStart}
                  posLock={styles?.lock}
                  angle={styles?.rotate}
                  onRotateStart={rotateStart}
                  hideResizers={
                    item.dir !== "bottom-left" &&
                    item.dir !== "right" &&
                    field?.type !== "icon" &&
                    (size?.w * tempScale < 18 || size?.h * tempScale < 12)
                  }
                />
              );
            }
          }))}

      {(!displayResizers || (!dragging && !styles?.lock)) && !groupedItems?.keys?.length > 0 ? (
        <WrapRotate
          style={{
            transform: `scale(${1})`,
            transformOrigin: "0 0",
            left: styles?.rotate > 0 && styles?.rotate < 80  ? '48%' : -30,
            top: styles?.rotate > 0 && styles?.rotate < 80  ? '105%' : -22,
          }}
          onMouseDown={rotateStart}
          onTouchStart={rotateStart}
        >
          <Rotate>{RotateSvg}</Rotate>
        </WrapRotate>
      ) : (
        ""
      )}
    </WrapResizer>
  );
};

const WrapResizer = styled.div`
  width: 100%;
  box-sizing: border-box;
  z-index: 19999;
  transform-origin: 50% 50%;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

export const WrapRotate = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Rotate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  width: 20px;
  height: 20px;
  fill: #3a3a61;
  border-radius: 50%;
  background: #fff;
`;

Resizable.defaultProps = {
  displayResizers: true,
  adjustXOffset: true, // readjust the resize handles and tooltip's offset to that of the field, due to scaling template
};

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    canvasWidth: selectCanvasPanelWidth(state.designTemplate),
    groupedItems: selectInterceptedFields(state.designTemplate),
    // editingText: selectEnableTextEdit(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(Resizable);
