import React, {useRef, useEffect, useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectSectionList} from "../../../../../store/selectors/template/groupedSection.selector";
import {
  selectTemplate,
  selectTemplateSize,
} from "../../../../../store/selectors/template/template.selector";
import {
  CanvasLayout,
  Card,
  WrapFrame,
} from "../../../canvasPanel/canvas/CanvasStyles";
import ViewOnlyFields from "../../../canvasPanel/viewOnlyFields/ViewOnlyFields";
import {
  editGroupSectionContent,
  deleteGroupSection,
} from "../../../../../store/actions/template/groupSection.action";
import {red1} from "../../../../../../oat-color-variables";
import {Pencil, Trash} from "../../../../../../oat-svg-icons";

const DisplayItem = ({
  item,
  index,
  onSelectItem,
  onDelete,
  onEditName,
  selectedDesign,
}) => {
  const [editing, setEditing] = useState(false);

  const handleClick = (e) => {
    if (!editing) {
      onSelectItem(item.startingSlideNo);
    }
    e.stopPropagation();
  };

  const toggleEditStatus = () => {
    setEditing(!editing);
  };

  const handleDelete = () => {
    onDelete(item.startingSlideNo);
  };

  const onUpdateTitle = (e) => {
    const {value} = e.target;
    onEditName(value, index);
  };

  return (
    <WrapItem
      editing={editing}
      selected={item.startingSlideNo === selectedDesign.id}
    >
      <Item onClick={handleClick} style={{}}>
        <span>{index + 1}. </span>
        {!editing ? (
          <Title>{item.title}</Title>
        ) : (
          <Input value={item.title} onChange={onUpdateTitle} />
        )}
      </Item>
      <Actions>
        {!editing ? (
          <>
            <WrapSvg onClick={toggleEditStatus}>{Pencil}</WrapSvg>
            <WrapSvg
              style={{marginLeft: 12, fill: red1}}
              onClick={handleDelete}
            >
              {Trash}
            </WrapSvg>
          </>
        ) : undefined}
      </Actions>
      {editing ? <Done onClick={toggleEditStatus}>Done</Done> : undefined}
    </WrapItem>
  );
};

const AddedContentList = ({
  template,
  tempSize,
  sectionList,
  editGroupSectionContent,
  deleteGroupSection,
}) => {
  const layoutRef = useRef();
  const [size, setSize] = useState({width: 0, height: 0, scale: 0});
  const [selectedDesign, setSelectedDesign] = useState({
    status: false,
    id: null,
  });

  useEffect(() => {
    if (layoutRef.current) {
      const newWidth = layoutRef.current.offsetWidth;
      const newHeight = (newWidth * tempSize.h) / tempSize.w;
      const calcScale = Math.min(newHeight / tempSize.h, newWidth / tempSize.w);
      setSize({
        width: newWidth,
        height: newHeight,
        scale: calcScale,
      });
    }
  }, [tempSize]);

  const onSelectDesign = (item) => {
    setSelectedDesign({
      status: true,
      id: item,
    });
  };

  const handleNameChange = (value, index) => {
    editGroupSectionContent({
      title: value,
      index,
    });
  };

  const handleDelete = (value) => {
    if (selectedDesign.id === value) {
      setSelectedDesign({
        status: false,
        id: null,
      });
    }
    deleteGroupSection(value);
  };

  const {width, height, scale} = size;
  return (
    <Wrapper>
      <ContentList>
        {sectionList &&
          sectionList.map((content, i) => (
            <DisplayItem
              item={content}
              key={i}
              index={i}
              onSelectItem={onSelectDesign}
              onDelete={handleDelete}
              onEditName={handleNameChange}
              selectedDesign={selectedDesign}
            />
          ))}
      </ContentList>
      <ContentDetails>
        <DisplaySelectedSlide ref={layoutRef}>
          {selectedDesign.status &&
            template &&
            template.map((slide, i) => {
              if (slide.id === selectedDesign.id) {
                return (
                  <CanvasLayout
                    key={i}
                    style={{
                      marginBottom: 15,
                      height,
                      width,
                      overflow: "hidden",
                      cursor: "pointer",
                      background: !slide.bg.gradient
                        ? slide.bg.bg1
                        : slide.bg.type !== "center"
                        ? `linear-gradient(${slide.bg.bgAng}deg, ${slide.bg.bg1}, ${slide.bg.bg2})`
                        : `radial-gradient(${slide.bg.bg1}, ${slide.bg.bg2})`,
                    }}
                  >
                    <WrapFrame
                      width={tempSize.w}
                      height={tempSize.h}
                      style={{
                        transform: `scale(${scale})`,
                      }}
                    >
                      <Card style={{overflow: "hidden"}}>
                        {slide && slide.fields
                          ? slide.fields.map((field, i) => (
                              <div key={i}>
                                <ViewOnlyFields field={field} />
                              </div>
                            ))
                          : undefined}
                      </Card>
                    </WrapFrame>
                  </CanvasLayout>
                );
              }
            })}
        </DisplaySelectedSlide>
      </ContentDetails>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 1.1rem;
  padding: 12px;
  display: flex;
`;

const Actions = styled.div`
  display: none;
`;

const WrapItem = styled.div`
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 12px;
  margin-right: 8px;
  display: flex;
  height: 42px;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  span {
    padding-right: 8px;
  }
  background: ${({theme, selected}) =>
    selected ? theme.deskSecondaryBg : undefined};
  &:hover {
    ${Actions} {
      display: flex;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const ContentList = styled.div`
  flex: 1;
`;

const ContentDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DisplaySelectedSlide = styled.div`
  flex: 1;
  width: 100%;
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  color: ${({theme}) => theme.deskSecondaryTxt};
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid ${({theme, error}) => (!error ? theme.g : red1)};
`;

const WrapSvg = styled.div`
  width: 18px;
  height: 18px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
`;

const Done = styled.div`
  font-size: 0.9rem;
  background: ${({theme}) => theme.deskSecondaryBg};
  padding: 4px 12px;
  border-radius: 6px;
  font-weight: 700;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    template: selectTemplate(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    sectionList: selectSectionList(designTemplate),
  };
};

export default connect(mapStateToProps, {
  editGroupSectionContent,
  deleteGroupSection,
})(AddedContentList);
