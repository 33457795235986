import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Spinner} from "../../../../oat-ui";
import MagnifyingGlass from "../../../../oat-svg-icons/icons/MagnifyingGlass";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import SearchResults from "./SearchResults";
import {selectActiveWorkSpace} from "../../../store/selectors/userDashboard.selector";

const SearchTemplates = ({close, user, workspace}) => {
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState({
    items: [],
    loading: false,
    error: {
      status: false,
      message: null,
    },
  });
  let params = useParams();
  const {folderID} = params;

  const handleInputChange = (e) => {
    const {value} = e.target;
    setSearchValue(value);
  };

  useEffect(() => {
    if (searchValue !== "") {
      setResults({...results, loading: true});
      const searchItem = searchValue.toLowerCase();
      const delayDebounceFn = setTimeout(() => {
        fetch(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates/search-by-title?searchValue=${searchItem}&workspaceID=${workspace.ID}&folderID=${folderID}`
        )
          .then((res) => res.json())
          .then((data) => {
            setResults({
              ...results,
              loading: false,
              items: data,
            });
          })
          .catch((err) =>
            setResults({
              ...results,
              loading: false,
              error: {
                status: true,
                message: err,
              },
            })
          );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setResults({
        items: [],
        loading: false,
        error: {
          status: false,
          message: null,
        },
      });
    }
  }, [searchValue, workspace]);

  return (
    <Layout>
      {!workspace.ID ? (
        <NoResults style={{ marginTop: 32 }}>You are not inside a workspace.</NoResults> 
      ) : undefined}

      {workspace.ID ? (
        <SearchBox>
          <SearchIcon>
            <WrapSvg>{MagnifyingGlass}</WrapSvg>
          </SearchIcon>
          <Input
            autoFocus
            type="text"
            autoComplete="off"
            value={searchValue}
            onChange={handleInputChange}
            placeholder="Search Templates"
          />
        </SearchBox>
      ) : undefined}

      {results.loading ? (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      ) : undefined}

      {!results.loading && results.items && searchValue !== "" ? (
        <ResultSection>
          <WrapList>
            {workspace.ID && results.items &&
              results.items.map((result, i) => (
                <SearchResults key={i} item={result} close={close} />
              ))}
            
            {workspace.ID && results.items.length === 0 ? (
              <NoResults>Nothing in this workspace :(</NoResults>
            ) : undefined}
          </WrapList>
        </ResultSection>
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  padding: 14px;
  box-sizing: border-box;
`;

const SearchBox = styled.div`
  margin-bottom: 18px;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 38px;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding-left: 38px;
  font-size: 1rem;
  color: #404041;
  ::placeholder {
    color: #777777;
  }
`;

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: #9e9a9a;
`;

const ResultSection = styled.div`
  border-radius: 6px;
  overflow-y: scroll;
  height: auto;
  max-height: 220px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrapList = styled.div`
  &:last-child {
    margin-bottom: 45px;
  }
`

const WrapSpinner = styled.div`
  height: 42px;
  margin-top: 62px;
`;

const NoResults = styled.div`
  font-size: 1.1rem;
  text-align: center;
  padding: 22px 0;
  color: #4d4545;
`;

const SearchIcon = styled.div`
  width: 20px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  left: 8px;
`;

const mapStateToProps = (state) => {
  const {authUser} = state;
  return {
    user: selectUserInfo(authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(SearchTemplates);
