import React from "react";
import styled from "styled-components";
import UserDashboardHoc from "../hoc/UserDashboardHoc";
import UserFolderHoc from "../hoc/UserFolderHoc";
import {Helmet, HelmetProvider} from "react-helmet-async";
import DashboardPanel from "../components/dashboardPanel/DashboardPanel";
import SharedToUserSidebar from "../components/sidebar/sharedToUser/SharedToUserSidebar";
import SharedTemplates from "../components/dashboardPanel/sharedTemplates/SharedTemplates";

const SharedToMe = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <body style="overflow: hidden;" />
      </Helmet>

      <UserDashboardHoc>
        <UserFolderHoc>
          <SharedToUserSidebar />
        </UserFolderHoc>
        <DashboardPanel>
          <SharedTemplates />
        </DashboardPanel>
      </UserDashboardHoc>
    </HelmetProvider>
  );
};

const Layout = styled.div``;

export default SharedToMe;
