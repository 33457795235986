import {
  FETCH_LAYOUTS_INIT,
  FETCH_LAYOUTS_SUCCESS,
  FETCH_LAYOUTS_ERROR,
  SELECT_LAYOUT_TYPE,
  ADMIN_FILTER_LAYOUTS,
} from "../actionTypes";

// use by admin when creating layouts
export function onSelectLayoutType(payload) {
  return {
    type: SELECT_LAYOUT_TYPE,
    payload,
  };
}

export function fetchLayoutsInit() {
  return {
    type: FETCH_LAYOUTS_INIT,
  };
}

export function fetchLayoutsSuccess(payload) {
  return {
    type: FETCH_LAYOUTS_SUCCESS,
    payload,
  };
}

export function fetchLayoutsError(error) {
  return {
    type: FETCH_LAYOUTS_ERROR,
    error,
  };
}

export function fetchLayouts(selectedRatio, tempCategory) {
  return async (dispatch) => {
    dispatch(fetchLayoutsInit());
    try {
      const templateInfo = await fetch(
        `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/layouts/lng/en/ratio/${selectedRatio}?tag=${tempCategory}`
      )
        .then((res) => res.json())
        .catch((e) => dispatch(fetchLayoutsError(e)));

      if (templateInfo.status === "success") {
        const layoutLinks = templateInfo.response;
        for (let i = 0; i < layoutLinks.length; i++) {
          await fetch(
            `https://oat-admin-templates.s3.amazonaws.com/${layoutLinks[i].tempID}-main.json`
          )
            .then((res) => res.json())
            .then((content) => {
              dispatch(fetchLayoutsSuccess(content.details));
            })
            .catch((e) => dispatch(fetchLayoutsError(e)));
        }
      } else {
        dispatch(fetchLayoutsError("Hmm.. something isn't right."));
      }
    } catch (e) {
      dispatch(fetchLayoutsError(e));
    }
  };
}

export function adminFilterLayouts(type) {
  return {
    type: ADMIN_FILTER_LAYOUTS,
    filterType: type,
  };
}
