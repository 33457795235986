import React from "react";
import Switch from "../../../../shared/inputs/Switch";
import PopoverColorBox from "../../../../shared/colorPicker/PopoverColorBox";
import {Title} from "../../../../shared/reuseStyles/Title";
import {WrapColorBox} from "../../../../desktop/common/WrapColorStyles";
import {connect} from "react-redux";
import {storeFieldUndoRedo} from "../../../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../../../store/selectors/template/slide.selector";
import {updateFieldStyles} from "../../../../../../../../store/actions/fields/common.action";
import {selectActiveFieldProps} from "../../../../../../../../store/selectors/fields.selector";
import {updateFieldCollaboration} from "../../../../../../../../store/actions/collaboration/collaboration.action";

const TableBorder = ({
  slideID,
  fieldKey,
  borderColor,
  roundedBorder,
  storeFieldUndoRedo,
  updateFieldStyles,
  updateFieldCollaboration,
}) => {
  const changeRoundedBorder = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID: fieldKey},
      type: "roundedBorder",
      value,
    });
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const updateBorderColor = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID: fieldKey},
      type: "borderColor",
      value,
    });
  };

  const onDebounceColor = (value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <>
      <div style={{paddingBottom: "1.5rem"}}>
        <WrapColorBox>
          <Title>Border Color</Title>
          <PopoverColorBox
            value={borderColor}
            updateFieldStyles={updateBorderColor}
            onDebounce={onDebounceColor}
          />
        </WrapColorBox>
      </div>

      {/* <div>
        <RangeSlider
          title="Border Width"
          fieldType="borderWidth"
          value={styles.borderWidth}
          max={7}
          min={0}
          step={1}
          onItemChange={changeBorderWidth}
        />
      </div> */}

      <div style={{paddingBottom: "1.5rem"}}>
        <Switch
          title="Rounded Border"
          value={roundedBorder}
          onItemChange={changeRoundedBorder}
          checked={roundedBorder}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldKey: selectActiveFieldProps(designTemplate).key,
    borderColor: selectActiveFieldProps(designTemplate).styles.borderColor,
    roundedBorder: selectActiveFieldProps(designTemplate).styles.roundedBorder,
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(TableBorder);
