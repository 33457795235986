const AreaChartIcon = (
  <svg width="100%" height="100%" viewBox="0 0 857.5 514.5">
    <g>
      <g>
        <defs>
          <linearGradient
            id="q89t6q2-0"
            gradientTransform="rotate(90 0.5 0.5) "
          >
            <stop offset="0%" stopOpacity="1" stopColor="#683cf2ff"></stop>
            <stop offset="100%" stopOpacity="0.25" stopColor="#683cf2ff"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#q89t6q2-0)"
          stroke="#683cf2ff"
          strokeWidth="3"
          d="M00, 514.5, 0,171.5,171.5,171.5,343,171.5,514.5,0,686,85.75,857.5,0, 857.5 514.5"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <defs>
          <linearGradient
            id="q89t6q2-1"
            gradientTransform="rotate(90 0.5 0.5) "
          >
            <stop offset="0%" stopOpacity="1" stopColor="#ce7b7bff"></stop>
            <stop offset="100%" stopOpacity="0.25" stopColor="#ce7b7bff"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#q89t6q2-1)"
          stroke="#ce7b7bff"
          strokeWidth="3"
          d="M00, 514.5, 0,343,171.5,257.25,343,257.25,514.5,85.75,686,257.25,857.5,85.75, 857.5 514.5"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <defs>
          <linearGradient
            id="q89t6q2-2"
            gradientTransform="rotate(90 0.5 0.5) "
          >
            <stop offset="0%" stopOpacity="1" stopColor="#48d243ff"></stop>
            <stop offset="100%" stopOpacity="0.25" stopColor="#48d243ff"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#q89t6q2-2)"
          stroke="#48d243ff"
          strokeWidth="3"
          d="M00, 514.5, 0,428.75,171.5,343,343,343,514.5,171.5,686,343,857.5,171.5, 857.5 514.5"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <defs>
          <linearGradient
            id="q89t6q2-3"
            gradientTransform="rotate(90 0.5 0.5) "
          >
            <stop offset="0%" stopOpacity="1" stopColor="#f6a712ff"></stop>
            <stop offset="100%" stopOpacity="0.25" stopColor="#f6a712ff"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#q89t6q2-3)"
          stroke="#f6a712ff"
          strokeWidth="3"
          d="M00, 514.5, 0,428.75,171.5,514.5,343,428.75,514.5,343,686,428.75,857.5,257.25, 857.5 514.5"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <line
          x1="0"
          x2="0"
          y1="0"
          y2="514.5"
          stroke="#232323"
          strokeWidth="1"
        ></line>
      </g>
      <g>
        <line
          x1="-10"
          x2="0"
          y1="40"
          y2="40"
          stroke="#232323"
          strokeWidth="1"
        ></line>
      </g>
    </g>
    <g>
      <g>
        <line
          x1="0"
          x2="857.5"
          y1="514.5"
          y2="514.5"
          stroke="#232323"
          strokeWidth="1"
        ></line>
      </g>
    </g>
  </svg>
);

export default AreaChartIcon;
