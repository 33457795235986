export function addGroupSection() {
  return function (state, action) {
    return {
      ...state,
      sectionList: [...state.sectionList, action.payload],
    };
  };
}

export function deleteGroupSection() {
  return function (state, action) {
    return {
      ...state,
      sectionList: state.sectionList.filter(
        (item) => item.startingSlideNo !== action.id
      ),
    };
  };
}

export function editGroupSectionContent() {
  return function (state, action) {
    const {title, index} = action.payload;
    return {
      ...state,
      sectionList: state.sectionList.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            title,
          };
        } else {
          return item;
        }
      }),
    };
  };
}

export function editGroupSectionLink() {
  return function (state, action) {
    const {startingSlideNo, id} = action.payload;
    return {
      ...state,
      sectionList: state.sectionList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            startingSlideNo,
          };
        } else {
          return item;
        }
      }),
    };
  };
}
