import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {CommentList} from "./commentStyles";
import {connect} from "react-redux";
import {selectActiveSlideInfo} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectTemplateID,
  selectTempSourceID,
} from "../../../../../../store/selectors/template/template.selector";
import {deleteSlideComment} from "../../../../../../store/actions/template/comment.action";
import DisplayComment from "./DisplayComment";
import {useSocket} from "../../../../../../webSocket/useSocket";

const Comments = ({
  onViewResponse,
  comments,
  height,
  username,
  tempID,
  tempSourceID,
  slideID,
  deleteSlideComment,
  highLightComment,
  theme,
  searchList,
}) => {
  const [updateTimeDiff, setUpdateTimeDiff] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [replying, setReplying] = useState({
    status: false,
    username: null,
  });
  const {emitSocketEvents} = useSocket() || {};

  const handleReply = (item) => {
    onViewResponse(item);
    onUpdateTimeDiff();
  };

  const handleDelete = (item) => {
    if (!deleting && username === item.commentedBy.username) {
      setDeleting(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/comments/${item.ID}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            tempSourceID,
            slideID,
          }),
        }
      )
        .then(() => {
          setDeleting(false);
          deleteSlideComment({
            commentID: item.ID,
            deletingSubComment: false,
          });
          if (emitSocketEvents) {
            emitSocketEvents({
              actionType: "delete-comment",
              item: {
                type: null,
                value: {
                  commentID: item.ID,
                  deletingSubComment: false,
                },
              },
            });
          }
        })
        .catch((e) => setDeleting(false));
    }
  };

  const onUpdateTimeDiff = () => {
    setUpdateTimeDiff(true);
  };

  useEffect(() => {
    onUpdateTimeDiff();
  }, [comments, replying]);

  useEffect(() => {
    if (updateTimeDiff) {
      setUpdateTimeDiff(false);
    }
  }, [updateTimeDiff]);

  return (
    <Wrapper height={height}>
      <CommentList>
        {comments &&
          comments.map((item, index) => (
            <DisplayComment
              key={item.ID}
              index={index}
              item={item}
              onViewResponse={onViewResponse}
              onReply={handleReply}
              onDelete={handleDelete}
              username={username}
              updateTimeDiff={updateTimeDiff}
              hideReplySection={replying.status}
              deleting={deleting}
              highLightComment={highLightComment.id === item.ID}
              theme={theme}
              searchList={searchList}
            />
          ))}
      </CommentList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: ${({height}) => height}px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideInfo(state.designTemplate).id,
    tempSourceID: selectTempSourceID(state.designTemplate),
    tempID: selectTemplateID(state.designTemplate),
  };
};

export default connect(mapStateToProps, {deleteSlideComment})(Comments);
