import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import {ExpandArrowForCanvas} from "../../../oat-svg-icons";

const SelectOption = ({item, handleSelectItem, selected}) => {
  const onSelectItem = () => {
    handleSelectItem(item);
  };
  return (
    <Option active={selected === item.value} onClick={onSelectItem}>
      <div>{item.display}</div>
    </Option>
  );
};

const DropDown = ({
  top,
  onSelect,
  selected,
  options,
  minWidth,
  maxWidth,
  height,
  overflowY,
  boxWidth,
  background,
}) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelectItem = (item) => {
    onSelect(item.value);
    handleToggle();
  };

  const [selectedValue, setSelectedValue] = useState(selected);
  useEffect(() => {
    if (typeof selected === 'string') {
      const words = selected.split('-');
      // Capitalize the first letter of each word
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      const updatedString = capitalizedWords.join(' ');
      setSelectedValue(updatedString);
    } else {
      setSelectedValue(selected);
    }
  }, [selected])
  
  return (
    <Wrapper>
      <SelectionBox
        onClick={handleToggle}
        style={{width: minWidth, maxWidth, background}}
      >
        <Selected>{selectedValue}</Selected>
        <div style={{marginRight: 10, cursor: "pointer"}}>
          <DropArrow>{ExpandArrowForCanvas}</DropArrow>
        </div>
      </SelectionBox>
      {open ? (
        <PopUp
          ref={selectRef}
          style={{top, width: boxWidth, height, overflowY, background}}
        >
          {options &&
            options.map((item, i) => (
              <SelectOption
                key={i}
                item={item}
                handleSelectItem={handleSelectItem}
                selected={selected}
              />
            ))}
        </PopUp>
      ) : undefined}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  text-transform: capitalize;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

export const PopUp = styled.div`
  border-radius: 8px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  position: absolute;
  left: 0px;
  top: 40px;
  box-sizing: border-box;
  z-index: 9999;
  box-shadow: ${({theme}) => theme?.boxShadow1 ? theme?.boxShadow1 : '0px 0px 1px 1px #dedede'};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  animation: bouncy 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  @-webkit-keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 30px;
  border-radius: 4px;
  margin: 6px;
  font-weight: 500;
  padding-left: 10px;
  box-sizing: border-box;
  cursor: pointer;
  div {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:hover {
    background: ${({theme}) => theme.hover};
  }
  &:last-child {
    margin-bottom: 12px;
  }
`;

export const SelectionBox = styled.div`
  display: flex;
  height: 30px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme?.boxShadow1 ? theme?.boxShadow1 : '0px 0px 1px 1px #dedede' };
  fill: ${({theme}) => theme.deskPrimaryTxt};
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  box-sizing: border-box;
`;

export const Selected = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px 0 3px;
`;

const DropArrow = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg};
  }
`;

DropDown.defaultProps = {
  minWidth: 90,
  maxWidth: 132,
  height: "auto",
  overflowY: "auto",
  boxWidth: 110,
  top: 40, // selection box position
};

export default DropDown;
