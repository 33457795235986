import {
  UPLOAD_IMAGE,
  CROP_IMAGE,
  TRANSFORM_IMG_OFFSET,
  APPLY_IMAGE_FILTER,
  REPLACE_IMG,
  REPLACE_IMG_STATUS,
} from "../actionTypes";

export function createImage({
  slideID,
  key,
  imgInfo,
  imgSize,
  fieldSize,
  selectFieldOnCreate = true,
  imageBy
}) {
  return {
    type: UPLOAD_IMAGE,
    slideID,
    key,
    imgSize,
    imgInfo,
    fieldSize,
    selectFieldOnCreate,
    imageBy
  };
}

export function transformImgOffset(active, offset) {
  return {
    type: TRANSFORM_IMG_OFFSET,
    active,
    offset,
  };
}

export const applyImageFilter = (active, value) => {
  return {
    type: APPLY_IMAGE_FILTER,
    active,
    value,
  };
};

export const onReplaceImg = ({active, imgInfo, imgSize, fieldSize, imageBy}) => {
  return {
    type: REPLACE_IMG,
    active,
    imgSize,
    imgInfo,
    fieldSize,
    imageBy
  };
};

export const updateReplaceImgStatus = () => {
  return {
    type: REPLACE_IMG_STATUS,
  };
};

export function cropImage(value) {
  return {
    type: CROP_IMAGE,
    value,
  };
}
