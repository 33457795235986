import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {black1, paleBlue4} from "../../../../oat-color-variables";
import {paleBlue} from "../../../userDashboardStyles";
import WorkspaceInvoice from "./billing/WorkspaceInvoice";
import WorkspaceMembers from "./members/WorkspaceMembers";
import WorkspaceSetting from "./setting/WorkspaceSetting";
import UpgradeWorkspace from "./upgrade/UpgradeWorkspace";
import { useLocation } from "react-router-dom";

const SidebarItem = ({item, onSelect, active}) => {
  const handleClick = () => {
    onSelect(item.value);
  };
  return (
    <Item onClick={handleClick} active={active}>
      <div>{item.display}</div>
    </Item>
  );
};

const sidebarOptions = [
  {display: "Setting", value: "workspace-setting"},
  {display: "Members", value: "members"},
  {
    display: "Billing",
    value: "billing",
  },
  {display: "Upgrade", value: "upgrade-workspace"},
];

const WorkspaceInfo = ({close}) => {
  const [selectedTab, setSelectedTab] = useState("workspace-setting");

  const handleSidebarSelection = (item) => {
    setSelectedTab(item);
  };

  const displayPanel = () => {
    if (selectedTab === "workspace-setting") {
      return <WorkspaceSetting close={close} />;
    } else if (selectedTab === "members") {
      return <WorkspaceMembers close={close} />;
    } else if (selectedTab === "billing") {
      return <WorkspaceInvoice close={close} />;
    } else if (selectedTab === "upgrade-workspace") {
      return <UpgradeWorkspace close={close} />;
    }
  };

  const query = new URLSearchParams(useLocation().search);
  const toUpgrade = query.get("upgrade") == "true" ? true : false;

  useEffect(() => {
    if (toUpgrade) {
      setSelectedTab("upgrade-workspace")
    }
  },[toUpgrade])

  return (
    <Layout>
      <Sidebar>
        {sidebarOptions.map((item, i) => (
          <SidebarItem
            key={i}
            active={item.value === selectedTab}
            item={item}
            onSelect={handleSidebarSelection}
          />
        ))}
      </Sidebar>

      <ContentPanel>{displayPanel()}</ContentPanel>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @media only screen and (min-width: 601px) {
    min-height: 420px;
    flex-direction: row;
  }
`;

const Sidebar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  @media only screen and (min-width: 601px) {
    flex-direction: column;
    background: ${paleBlue};
    padding: 12px;
    box-sizing: border-box;
    border-right: 1px solid #d8d8d8;
  }
  @media only screen and (max-width: 600px) {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;

const ContentPanel = styled.div`
  flex: 3;
  background: #fff;
  @media only screen and (min-width: 601px) {
    height: 570px;
    padding: 12px 18px;
    box-sizing: border-box;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Item = styled.div`
  font-size: 0.92rem;
  margin-bottom: 10px;
  color: ${black1};
  padding: 8px;
  box-sizing: border-box;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 6px;
  background: ${({active}) => (active ? paleBlue4 : undefined)};
  &:hover {
    background: #c4ccf07a;
  }
  @media only screen and (max-width: 600px) {
    margin-right: 18px;
    flex: 0 0 auto;
    border-radius: 20px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
  }
`;

export default WorkspaceInfo;
