import styled from "styled-components";

export const WrapError = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 84px;
`;

export const Error = styled.div`
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 1.1rem;
`;

export const WrapDelete = styled.div`
  position: absolute;
  width: 50px;
  height: 30px;
  background: #e1514894;
  bottom: 20px;
  right: 10px;
  border-radius: 4px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const WrapImage = styled.div`
  position: relative;
  height: 240px; // set height here, else infinite scroll not working properly
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 14px;
  img, video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  &:hover ${WrapDelete} {
    display: flex;
  }
`;

export const ImageList = styled.div`
  width: 100%;
  margin: 25px 0;
`;

export const WrapImagePanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;