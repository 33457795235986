import React, {useState, useEffect} from "react";
import Hoc from "../hoc/Hoc";
import {
  Image as Img,
  Items,
  Header,
  ShowError,
} from "../components/dashboardArea/templates/templateListStyles";
import {Modal, Spinner} from "../../oat-ui";
import ImageDetails from "../components/images/ImageDetails";
import {
  Layout,
  HeaderSection,
  WrapButton,
  WrapItem,
} from "../reuseStyles/reuseStyles";
import {
  getOatAllMedia,
  toggleAdminMediaModal,
} from "../store/actions/media.action";
import {connect} from "react-redux";
import {selectAdminMedia} from "../store/selectors/adminMedia.selector";
import UploadPublicMedia from "../components/images/publicLibrary/UploadPublicMedia";
import ImageCollectionPanel from "../components/images/publicLibrary/ImageCollectionPanel";
import CreateImagePack from "../components/images/publicLibrary/pack/CreateImagePack";
import {useNavigate, useParams} from "react-router-dom";
import DisplayPackImages from "../components/images/publicLibrary/pack/DisplayPackImages";
import styled from "styled-components";
import { iconCategories } from "../../oat-design-template/components/editDesign/modal/create/icons/CreateIcon";

const DisplayPackImage = ({item, onOpenPackImages}) => {
  const handleClick = () => {
    onOpenPackImages(item);
  };

  return (
    <WrapItem>
      <Img
        src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`}
        onClick={handleClick}
      />
      <Title>{item.packName}</Title>
    </WrapItem>
  );
};

const PublicImages = ({media, toggleAdminMediaModal, getOatAllMedia}) => {
  const {modal} = media;
  const [selectedImage, setSelectedImage] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null); // emoji, social-media
  let navigate = useNavigate();
  let params = useParams();
  const [type, setType] = useState("icons");
  

  const changeType = () => {
    setType(type === 'images' ? "icons" : 'images');
  };

  const selectImage = (item) => {
    toggleAdminMediaModal(true, "show-image-details");
    setSelectedImage(item);
  };

  const openNewImageModal = () => {
    toggleAdminMediaModal(true, "upload-media");
  };

  const closeModal = () => {
    toggleAdminMediaModal(false, "");
  };

  useEffect(() => {
    if (!type) {
      return navigate(`/admin/library/public/icons`);
    }
    if (selectedCategory) {
      // navigate(`/admin/library/public/icons/${category}`);
      getOatAllMedia(
        `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${type}?category=${selectedCategory}`
      );
    } else {
      // navigate(`/admin/library/public/icons/${iconCategories[0].value}`);
      getOatAllMedia(
        `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${type}/?category=${iconCategories[0].value}`
      );
    }
  }, [type]);

  const [selectedPackID, setSelectedPackID] = useState();
  const onOpenPackImages = (item) => {
    setSelectedCategory(item.category);
    setSelectedPackID(item.key);
    toggleAdminMediaModal(true, "pack-images");
  };

  const onSelectCategory = (item) => {
    setSelectedCategory(item.value);
    navigate(`/admin/library/public/icons/${item.value}`);
    getOatAllMedia(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${type}?category=${item.value}`
    );
  };

  const onUploadFinished = () => {
    getOatAllMedia(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/${type}?category=${selectedCategory}`
    );
  };

  return (
    <Hoc>
      <ImageCollectionPanel onSelectTab={onSelectCategory} />

    
      <Layout>
        <HeaderSection>
          <div style={{display: 'flex'}}>
            <Header onClick={changeType} style={{opacity: type === "images" ? 1 : 0.5, cursor: 'pointer'}}>Images</Header>
            <div style={{padding: '0 15px' }}> </div>
            <Header onClick={changeType} style={{opacity: type === "icons" ? 1 : 0.5, cursor: 'pointer'}}>Icons</Header>
          </div>

          <WrapButton>
            <CreateImagePack onUploadFinished={onUploadFinished} />
            {/* <UploadFile onClick={openNewImageModal}>Add Image</UploadFile> */}
          </WrapButton>
        </HeaderSection>

        {/* <div style={{ display: 'flex'}}> 
          {iconCategories.map((category, i) => (
            <div index={i} onClick={onSelectCategory} style={{ margin: '6px 12px', fontSize: 14}}>{category.display}</div>
          ))}
        </div> */}

        <Items style={{minWidth: window.innerWidth}}>
          {!media.loading ? (
            media.items &&
            media.items.map((item, i) => (
              <DisplayPackImage
                item={item}
                key={i}
                onSelectItem={selectImage}
                onOpenPackImages={onOpenPackImages}
              />
            ))
          ) : (
            <div style={{marginTop: "6%", width: "100%"}}>
              <Spinner />
            </div>
          )}
        </Items>

        {!media.loading && media.error.status ? (
          <ShowError>Couldn't fetch data.</ShowError>
        ) : undefined}
      </Layout>

      {modal.status && modal.type === "show-image-details" ? (
        <Modal close={closeModal}>
          <div>
            <ImageDetails selectedImage={selectedImage} />
          </div>
        </Modal>
      ) : undefined}

      {modal.status && modal.type === "upload-media" ? (
        <Modal width={720} height={300} close={closeModal}>
          <UploadPublicMedia closeModal={closeModal} />
        </Modal>
      ) : undefined}

      {modal.status && modal.type === "pack-images" ? (
        <Modal top={50} width={1000} height={540} close={closeModal}>
          <DisplayPackImages
            type={type}
            category={selectedCategory}
            selectedPackID={selectedPackID}
          />
        </Modal>
      ) : undefined}
    </Hoc>
  );
};

const Title = styled.div`
  padding: 6px;
  font-size: 0.95rem;
`;

const mapStateToProps = (state) => {
  return {
    media: selectAdminMedia(state.adminMedia),
  };
};

export default connect(mapStateToProps, {
  toggleAdminMediaModal,
  getOatAllMedia,
})(PublicImages);
