import React, {useState} from "react";
import styled from "styled-components";
import AvailableTemplateSizes from "./AvailableTemplateSizes";
import {Close} from "../../../../../oat-svg-icons";
import {BottomUpDrawer, Popper} from "../../../../../oat-ui";
import CreateButton from "../../../reuse/CreateButton";
import {connect} from "react-redux";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import RequiresUpgradeToPro from "../../../reuse/requiresUpgradeToPro/RequiresUpgradeToPro";
import { selectUserInfo } from "../../../../../../redux/user/authUser";
import { useParams } from "react-router-dom";

const CreateTemplate = ({user, workspace}) => {
  const [openPopper, setOpenPopper] = useState(false);
  const [requiresUpgrade, setRequiresUpgrade] = useState(false);
  const windowSize = getWindowSize();
  let params = useParams();
  const {folderID} = params;

  const handlePopperOpen = () => {
    if (workspace.plan === "free" && folderID === "library-templates") {
      setRequiresUpgrade(true);
    } else if (workspace.ID) {
      setOpenPopper(true);
    }
  };

  const handlePopperClose = () => {
    setOpenPopper(false);
  };

  const onCloseUpgradeModal = () => {
    setRequiresUpgrade(false);
  }

  return (
    <Layout>
      <CreateButton
        onClick={handlePopperOpen}
        title={windowSize.width >= 850 ? "Create Template" : "Create"}
      />

      {openPopper && windowSize.width > 600 ? (
        <Popper
          width={450}
          height={274}
          offset={{x: windowSize.width - 472, y: 142}}
          onClose={handlePopperClose}
        >
          <AvailableTemplateSizes />
          <WrapSvg
            onClick={handlePopperClose}
            style={{
              position: "absolute",
              top: 15,
              right: -25,
            }}
          >
            {Close}
          </WrapSvg>
        </Popper>
      ) : undefined}

      {windowSize.width <= 600 ? (
        <BottomUpDrawer
          open={openPopper}
          height={350}
          close={handlePopperClose}
          background="#fff"
          styles={{overflowY: "hidden"}}
        >
          <AvailableTemplateSizes />
        </BottomUpDrawer>
      ) : undefined}

      {requiresUpgrade ? (
        <RequiresUpgradeToPro close={onCloseUpgradeModal} />
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  height: auto;
`;

export const DashboardCreateBtn = styled.div`
  width: 420px;
  position: absolute;
  height: 220px;
  background: #fff;
  top: 75px;
  left: -233px;
  border-radius: 6px;
  z-index: 10000;
  box-shadow: #c8bedb 0px 0px 3px 0px;
`;

const WrapSvg = styled.div`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(CreateTemplate);
