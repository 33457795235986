// Modal top section tabs
import styled from "styled-components";

export const WrapTabPanel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({theme}) => theme.deskGap};
  @media only screen and (max-width: 849px) {
    height: 66px;
  }
`;

// For vertical
export const WrapTabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 849px) {
    flex-direction: row;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const WrapRowTabs = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WrapColumnTabs = styled.div`
  @media only screen and (min-width: 850px) {
    display: flex;
    padding: 14px;
    width: 100%;
    height: 100%;
    flex: 1;
    background: ${({theme}) => theme.deskPrimaryBg2};
    box-shadow: ${({theme}) => theme.boxShadow3};
  }
`;

export const ColumnTabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({theme}) => theme.deskBoldText};
  @media only screen and (max-width: 849px) {
    padding: 14px 14px 14px 14px;
    box-sizing: border-box;
    .wrap-image-tabs {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      overflow-x: scroll;
      padding: 14px;
      height: 100%;
      border-bottom: 1px solid ${({theme}) => theme.deskGap};
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const ColumnTab = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 0.9rem;
  font-weight: 700;
  color: ${({theme}) => theme.deskBoldText};
  background: ${({theme, selected}) =>
    selected ? theme.secondaryThemeHover : undefined};
  cursor: pointer;
  border-radius: 6px;
  padding: 8px 22px;
  margin-bottom: 10px;
  user-select: none;
  &:hover {
    background: ${({theme}) => theme.secondaryThemeHover};
  }
  @media only screen and (max-width: 849px) {
    flex: 0 0 auto;
    border-radius: 38px;
  }
`;

export const Tab = styled.div`
  height: 40px;
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-weight: 600;
  font-size: 14px;
  background: ${({theme, selected}) =>
    selected ? theme.secondaryThemeHover : undefined};
  display: flex;
  align-items: center;
  padding: 0px 22px;
  margin: 14px 0 0 14px;
  border-radius: 38px;
  margin-bottom: 12px;
  cursor: pointer;
  @media only screen and (max-width: 849px) {
    flex: 0 0 auto;
  }
`;
