import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import PlansComparison from "../../../../../oat-public/pricing/components/PlansComparison";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import {Header} from "../workspaceStyles";

const UpgradeWorkspace = ({workspace}) => {
  return (
    <Layout>
      <Header>Upgrade</Header>

      <PricingTable>
        <PlansComparison />
      </PricingTable>
    </Layout>
  );
};

const Layout = styled.div``;

const PricingTable = styled.div`
  padding-bottom: 120px;
  @media only screen and (min-width: 601px) {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 200px;
    &::-webkit-scrollbar {
      display: none;
    }
    height: 420px;
  }
  @media only screen and (max-width: 600px) {
    height: ${window.innerHeight}px;
  }
`;

const mapStateToProps = (state) => {
  return {
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(UpgradeWorkspace);
