import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {Modal} from "../../../../oat-ui";
import {Pencil} from "../../../../oat-svg-icons";
import ChangeEmail from "./ChangeEmail";
import EditUserName from "./EditUserName";
import UpdatePassword from "./UpdatePassword";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {grey1} from "../../../../oat-color-variables";
import {Header, FieldName} from "../../reuse/dashboardUI/modalStyles";
import {userPrimaryBlack} from "../../../userDashboardStyles";
import ChangeAvatar from "./ChangeAvatar";

const DisplayUserInfo = ({user, close}) => {
  const [fields, setFields] = useState({
    avatar: user.avatar,
    username: user.username,
    name: user.name,
    email: user.email,
    password: "password",
  });
  const [modalType, setModalType] = useState("display-user-info");

  const handleFieldChange = (e, type) => {
    const {value} = e.target;
    setFields({
      ...fields,
      [type]: value,
    });
  };

  const initEditName = () => {
    setModalType("edit-name");
  };

  const initChangeEmail = () => {
    setModalType("change-email");
  };

  const changeModalType = (type) => {
    setModalType(type);
  };

  const initChangePassword = () => {
    setModalType("change-password");
  };

  const updateEmailValue = (value) => {
    setFields({
      ...fields,
      email: value,
    });
  };

  const changeAvatar = () => {
    setModalType("change-avatar");
  };

  const updateAvatarValue = (value) => {
    setFields({
      ...fields,
      avatar: value,
    });
  };

  let displayView = null;
  switch (modalType) {
    case "edit-name":
      displayView = (
        <Modal width={375} height="auto" close={close}>
          <EditUserName
            name={fields.name}
            handleFieldChange={handleFieldChange}
            onCancel={changeModalType}
          />
        </Modal>
      );
      break;
    case "change-password":
      displayView = (
        <Modal width={400} height="auto" close={close}>
          <UpdatePassword onCancel={changeModalType} />
        </Modal>
      );
      break;
    case "change-email":
      displayView = (
        <Modal width={400} height="auto" close={close}>
          <ChangeEmail
            email={fields.email}
            onChangedEmail={updateEmailValue}
            onCancel={changeModalType}
          />
        </Modal>
      );
      break;
    case "change-avatar":
      displayView = (
        <Modal width={400} height={430} close={close}>
          <ChangeAvatar
            onChangedAvatar={updateAvatarValue}
            username={user.username}
            email={user.email}
            cognitoUsername={user.cognitoUsername}
            onCancel={changeModalType}
            onClose={close}
          />
        </Modal>
      );
      break;
    default:
      displayView = (
        <Modal width={375} height={290} close={close}>
          <Wrapper>
            {/* <Field>
              <UploadAvatar user={user} userAvatar={fields.avatar}/>
            </Field> */}

            <Header>Account</Header>

            <Field>
              <FieldName style={{cursor: "pointer"}} onClick={changeAvatar}>
                Avatar
              </FieldName>
              <Value onClick={changeAvatar}>
                <WrapImage>
                  <img src={fields.avatar} alt="" />
                </WrapImage>
                <WrapSvg>{Pencil}</WrapSvg>
              </Value>
            </Field>

            <Field>
              <FieldName>Name</FieldName>
              <Value onClick={initEditName}>
                <div>{fields.name}</div>
                <WrapSvg>{Pencil}</WrapSvg>
              </Value>
            </Field>

            <Field>
              <FieldName>Email</FieldName>
              <Value>
                {/* onClick={initChangeEmail} */}
                <div>{fields.email}</div>
                {/* <WrapSvg>{Pencil}</WrapSvg> */}
              </Value>
            </Field>

            <Field>
              <FieldName>Password</FieldName>
              <Value onClick={initChangePassword}>
                <HiddenPassword
                  type="password"
                  value={fields.password}
                  readOnly
                />
                <WrapSvg>{Pencil}</WrapSvg>
              </Value>
            </Field>
          </Wrapper>
        </Modal>
      );
  }

  return displayView;
};

export const Wrapper = styled.div`
  margin: 12px;
  font-size: 0.95rem;
`;

export const WrapSvg = styled.div`
  width: 16px;
  height: 16px;
  fill: ${grey1};
  cursor: pointer;
  display: none;
`;

export const Field = styled.div`
  display: flex;
  padding: 15px 0;
`;

export const Name = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
`;

export const Value = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover ${WrapSvg} {
    display: block;
  }
`;

const HiddenPassword = styled.input`
  width: 100%;
  color: ${userPrimaryBlack};
  border: none;
  padding: 4px;
  box-sizing: border-box;
  cursor: default;
`;

const WrapImage = styled.div`
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(DisplayUserInfo);
