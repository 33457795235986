import React, {useState, useEffect} from "react";
import ValueLabel from "./common/ValueLabel";
import {connect} from "react-redux";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../../store/selectors/template/template.selector";
import ScaleLabels from "./barProps/ScaleLabels";
import ScaleGrid from "./barProps/ScaleGrid";
import {
  fixFloatingPoint,
  getRemainingDigitsLength,
  useScaleLine,
} from "./hooks/useScaleLine";
import ShowLegends from "./common/ShowLegends";
import {ScaledChart, WrapChart} from "../chartStyles";
import CategoryLabels from "./common/CategoryLabels";

const LineChart = ({
  size,
  styles,
  dataSeries,
  fieldKey,
  colorSet,
  tempScale,
  tempSize,
}) => {
  const {
    showBackground,
    background,
    gridColor,
    showGrid,
    showValue,
    valueColor,
    fontSize,
    fontFamily,
    showLegend,
    showMeasurement,
    showYAxis,
    measurementFontSize,
    measurementColor,
    axisColor,
    showXAxis,
    gridStyle,
    showDots,
    dotSize,
    lineSize,
    showCategory,
    scaleDistance,
    currencyUnit,
    scaleType,
    scaleFormat,
  } = styles;
  const edgeSpace = (size.w * 5) / 100;
  const dataSet = dataSeries.slice(1);
  const barMargin = (size.w - 2 * edgeSpace) / (dataSeries.length - 2);
  const width = size.w,
    height = size.h;
  let highestPositiveValue = 0,
    lowestNegativeValue = 0;
  let plotChart = [];
  const [totalValue, setTotalValue] = useState(0);
  let extractInputs = [],
    categoryLabels = [];

  const arrayColumn = (arr, n) => arr.map((x) => x[n]);
  for (let i = 0; i < dataSet[0].length; i++) {
    extractInputs.push(arrayColumn(dataSet, i));
    for (let j = 0; j < dataSet.length; j++) {
      if (i !== 0) {
        const value = dataSet[j][i] === "" ? 0 : dataSet[j][i];
        if (value > highestPositiveValue) {
          const digitLength = getRemainingDigitsLength(value);
          highestPositiveValue = fixFloatingPoint(value);
        } else if (value < lowestNegativeValue) {
          const digitLength = getRemainingDigitsLength(value);
          lowestNegativeValue = fixFloatingPoint(value);
        }
      }
    }
  }

  for (let i = 0; i < dataSet.length; i++) {
    categoryLabels.push(dataSet[i][0]);
  }

  const {chartSpectrum, scaleLine, digitsLength} = useScaleLine({
    highestPositiveValue,
    lowestNegativeValue,
  });

  const chartInputs = extractInputs.slice(1); // remove categories
  totalValue !== 0 &&
    chartInputs.map((data, i) => {
      let drawLine = [],
        dotOffsets = [],
        edgeLine = [],
        lastAddedIndex = 0;

      return data.map((item, index) => {
        if (item !== "") {
          // to track if the item with value is added at the end
          // if item [0, 1, "", ""] -> the line will be ended at 1
          lastAddedIndex = index;
          // initial plot offset - if values were empty
          if (drawLine.length === 0) {
            drawLine.push(
              edgeSpace + (0 + barMargin) * index,
              item > 0
                ? (chartSpectrum.positive * height) / totalValue
                : (height * chartSpectrum.positive) / totalValue
            );
          }

          drawLine.push(
            edgeSpace + (0 + barMargin) * index,
            (height * (chartSpectrum.positive - item)) / totalValue
          );

          dotOffsets.push({
            x: edgeSpace + (0 + barMargin) * index,
            y: (height * (chartSpectrum.positive - item)) / totalValue,
            value: item,
          });

          edgeLine.push(
            edgeSpace + (0 + barMargin) * index,
            (height * (chartSpectrum.positive - item)) / totalValue
          );
        }

        if (item !== "") {
          lastAddedIndex = i;
        }
        if (index === data.length - 1) {
          if (item !== "") {
            drawLine.push(
              edgeSpace + (0 + barMargin) * (data.length - 1) + 0 / 2,
              (height * (chartSpectrum.positive - item)) / totalValue
            );
          } else {
            drawLine.push(
              barMargin * lastAddedIndex,
              (chartSpectrum.positive * height) / totalValue
            );
          }
          plotChart.push({
            line: `M${drawLine.toString()}`,
            dots: dotOffsets,
            color: colorSet[i],
            edgeLine: `M${edgeLine.toString()}`,
          });
        }
      });
    });

  useEffect(() => {
    setTotalValue(chartSpectrum.positive + Math.abs(chartSpectrum.negative));
  }, [chartSpectrum, dataSeries]);

  const valueHeightGap =
    tempSize.w > tempSize.h ? (2 * tempSize.h) / 100 : (1.5 * tempSize.h) / 100;

  return (
    <WrapChart>
      <ScaledChart>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          style={{background: showBackground ? background : undefined}}
        >
          <g>
            {plotChart && plotChart.map((item, i) => {
              return (
                <g key={i + "path"}>
                  <path
                    fill={`url(#${fieldKey + "-" + i})`}
                    stroke={item.color}
                    strokeWidth={0}
                    d={`${item.line}`}
                    style={{opacity: 0.7}}
                  />
                  <path
                    fill="transparent"
                    stroke={item.color}
                    strokeWidth={lineSize}
                    d={item.edgeLine}
                  />
                </g>
              );
            })}
          </g>

          <g>
            {plotChart && plotChart.map((item, i) => {
              return item.dots.map((circle, index) => {
                let valueXPos = circle.x;
                return (
                  <g key={index}>
                    {showDots ? (
                      <circle
                        cx={circle.x}
                        cy={circle.y}
                        fill={colorSet[i]}
                        r={dotSize} // * (tempSize.w / 536)
                      />
                    ) : undefined}
                    {showValue ? (
                      <ValueLabel
                        x={valueXPos}
                        y={circle.y - valueHeightGap}
                        color={valueColor}
                        width={30}
                        height={20}
                        value={circle.value}
                        fontSize={fontSize} //* (tempSize.w / 536)
                        fontFamily={fontFamily}
                        scaleFormat={scaleFormat}
                        textAnchor={"middle"}
                        alignmentBaseline="alphabetic"
                        dominantBaseline="alphabetic"
                      />
                    ) : undefined}
                  </g>
                );
              });
            })}
          </g>

          {showYAxis ? (
            <g>
              <line
                x1={0}
                x2={0}
                y1={0}
                y2={height}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              ></line>
            </g>
          ) : undefined}

          {showXAxis && totalValue ? (
            <g>
              <line
                x1={0}
                x2={width}
                y1={(height * (chartSpectrum.positive - 0)) / totalValue}
                y2={(height * (chartSpectrum.positive - 0)) / totalValue}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              />
            </g>
          ) : undefined}

          {showMeasurement ? (
            <g>
              {scaleLine.acc &&
              (chartSpectrum.positive || chartSpectrum.negative) &&
              Object.keys(scaleLine).length !== 0 ? (
                <ScaleLabels
                  fontSize={measurementFontSize}
                  fontFamily={fontFamily}
                  color={measurementColor}
                  tickColor={axisColor}
                  item={scaleLine}
                  gap={(scaleLine.acc * height) / scaleLine.total}
                  highestValue={chartSpectrum.positive}
                  digitsLength={digitsLength}
                  scaleDistance={scaleDistance}
                  scaleType={scaleType}
                  scaleFormat={scaleFormat}
                  currencyUnit={currencyUnit}
                />
              ) : undefined}
            </g>
          ) : undefined}

          {showGrid ? (
            scaleLine && Object.keys(scaleLine).length !== 0 ? (
              <g>
                <ScaleGrid
                  color={gridColor}
                  gridStyle={gridStyle}
                  item={scaleLine}
                  width={width}
                  gap={(scaleLine.acc * height) / scaleLine.total}
                />
              </g>
            ) : undefined
          ) : undefined}

          {showCategory ? (
            <CategoryLabels
              styles={styles}
              chartHeight={size.h}
              labels={categoryLabels}
              barMargin={barMargin}
              barWidth={0}
              edgeSpace={edgeSpace}
              seriesLength={0}
            />
          ) : undefined}
        </svg>

        {showLegend ? (
          <ShowLegends
            items={dataSeries[0].slice(1)}
            chartSize={size}
            styles={styles}
            colorSet={colorSet}
          />
        ) : undefined}
      </ScaledChart>
    </WrapChart>
  );
};

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(LineChart);
