import React, {useRef, useState, useEffect} from "react";
import {connect} from "react-redux";
import {
  Option,
  PopUp,
  Selected,
  SelectionBox,
  Wrapper,
} from "../../../../../../../ui/inputs/DropDown";
import {ExpandArrow} from "../../../../../../../../oat-svg-icons";

const SelectOption = ({item, handleSelectItem, selected, fontFamily}) => {
  const onSelectItem = () => {
    handleSelectItem(item);
  };
  return (
    <Option active={selected === fontFamily} onClick={onSelectItem}>
      <p
        style={{
          fontFamily: fontFamily + "-" + item.fontWeight + "-" + item.fontStyle,
          fontWeight: item.fontWeight,
          fontStyle: item.fontStyle,
        }}
      >
        {item.fontType}
      </p>
    </Option>
  );
};

const SelectFontTypes = ({
  onSelect,
  selected,
  options,
  minWidth,
  height,
  overflowY,
  fontFamily,
}) => {
  const [open, setOpen] = useState(false);
  const [sortList, setSortList] = useState([]);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelectItem = (item) => {
    onSelect(item);
    handleToggle();
  };

  useEffect(() => {
    const types =
      options && options.sort((a, b) => a.fontWeight - b.fontWeight);
    setSortList(types);
  }, [options]);

  return (
    <Wrapper>
      <SelectionBox onClick={handleToggle} minWidth={minWidth}>
        <Selected>{selected ? selected : " - "}</Selected>
        <div style={{marginRight: 10, cursor: "pointer"}}>
          <div style={{width: 18, height: 18}}>{ExpandArrow}</div>
        </div>
      </SelectionBox>
      {open ? (
        <PopUp ref={selectRef} style={{height, overflowY, minWidth: 132}}>
          {sortList &&
            sortList.map((item, i) => (
              <SelectOption
                key={i}
                item={item}
                handleSelectItem={handleSelectItem}
                selected={selected}
                fontFamily={fontFamily}
              />
            ))}
        </PopUp>
      ) : undefined}
    </Wrapper>
  );
};

SelectFontTypes.defaultProps = {
  minWidth: 132,
  height: "auto",
  overflowY: "auto",
};

export default connect(null, null)(SelectFontTypes);
