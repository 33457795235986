import React from "react";
import styled from "styled-components";
import {Option, Dot} from "../../../../oat-ui/styles/SelectOption";

const ProfileTemplateActions = ({onRemoveTemplate, onGetTempLink}) => {
  const handleShareTemp = () => {};

  const getTempLink = () => {
    onGetTempLink();
  };

  const handleRemoveTemp = () => {
    onRemoveTemplate();
  };

  return (
    <Wrapper>
      <Option onClick={handleShareTemp}>
        <p>Send To</p>
        <Dot />
      </Option>
      <Option onClick={getTempLink}>
        <p>Share a Link</p>
        <Dot />
      </Option>
      <Option onClick={handleRemoveTemp}>
        <p style={{color: "#ff2f2f"}}>Remove</p>
        <Dot />
      </Option>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 8px 0;
`;

export default ProfileTemplateActions;
