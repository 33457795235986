import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {
  selectUserInfo,
  selectUserWorkspaceForRedirect,
} from "../../../redux/user/authUser";
import {primaryBlack, purple3} from "../../oat-color-variables";
import {Bar, WrapBar} from "../../oat-ui/styles/HamburgerStyles";
import {getWindowSize} from "../../oat-window-size/getWindowSize";
import {Register, CompanyName, displayNavAt, navBtnBoxShadow, navBtnBackground} from "./NavBar";
import NavSlider from "./NavSlider";

const MobileNav = ({user, redirectWorkspaceID}) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const {width} = getWindowSize();

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    if (width > displayNavAt && openSidebar) {
      setOpenSidebar(false);
    }
  }, [width]);

  return (
    <>
      <Layout>
        <Menu onClick={toggleSidebar}>
          <WrapBar>
            <Bar />
            <Bar />
            <Bar />
          </WrapBar>
        </Menu>

        <Item style={{justifyContent: "flex-start", paddingLeft: 16}}>
          <CompanyName to="/">
            {/* <img style={{width: 45, height: 45, borderRadius: '50%'}} src={`${process.env.PUBLIC_URL}/oat-all-favicon.png`} alt="oat-all" /> */}
            Oat All
          </CompanyName>
        </Item>

        <Item style={{justifyContent: "flex-end"}}>
          {!user.loggedIn.status ? (
            <Register to="/register">Register</Register>
          ) : (
            <>
              {/* <WrapSvg style={{fill: "#848484", paddingRight: "14%"}}>
                {Bell}
              </WrapSvg> */}
              <div style={{display: "flex", alignItems: "center"}}>
                <LinkTo
                  to={
                    redirectWorkspaceID
                      ? `/dashboard/${redirectWorkspaceID}/designs/home`
                      : "/"
                  }
                  style={{
                    background: navBtnBackground,
                    boxShadow: navBtnBoxShadow
                  }}
                >
                  Dashboard
                </LinkTo>
              </div>
            </>
          )}
        </Item>
      </Layout>

      <NavSlider open={openSidebar} onClose={toggleSidebar} />
    </>
  );
};

const Layout = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 899px) {
    justify-content: space-between;
    margin: 0 3% 0 1%;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 52px;
  height: 56px;
  padding-left: 14px;
  box-sizing: border-box;
`;

const Item = styled.div`
  width: 20px;
  flex: 1;
  display: flex;
  flex: 1;
  align-items: center;
`;

const LinkTo = styled(Link)`
  font-size: 0.95rem;
  cursor: pointer;
  padding: 5px 12px;
  border-radius: 4px;
  text-decoration: none;
  color: ${primaryBlack};
  &:hover {
    background: ${purple3};
  }
`;

const WrapSvg = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    redirectWorkspaceID: selectUserWorkspaceForRedirect(state.authUser),
  };
};

export default connect(mapStateToProps, null)(MobileNav);
