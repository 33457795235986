import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {
  Header,
  Image,
  ItemName,
  MoreOption,
  WrapTempInfo,
  WrapItem,
  Layout,
  Items,
} from "./../../dashboardPanel/userTemplates/userTemplatesStyles";
import Popper from "../../../../oat-ui/core/Popper";
import Spinner from "../../../../oat-ui/core/Spinner";
import {useLocation, useNavigate} from "react-router-dom";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import styled from "styled-components";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import ProfileTemplateActions from "./ProfileTemplateActions";
import ModalTypesProfile from "./ModalTypesProfile";
import EmptyTemplates from "../../dashboardPanel/userTemplates/emptyTemplate/EmptyTemplates";
import {setProfileTags} from "../../../store/actions/userProfile.action";

const DisplayItem = ({slug, item, onSelectItem, onPopperOpen}) => {
  const handleItemClick = () => {
    onSelectItem(item);
  };

  const handlePopperOpen = (e) => {
    onPopperOpen(e, item);
  };

  return (
    <WrapItem to={`/design/profile/${slug}/${item.tempID}`}>
      <Image src={item.image} />
      <WrapTempInfo>
        <ItemName style={{flex: 4}} onClick={handleItemClick}>
          {item.title}
        </ItemName>
        <MoreOption onClick={handlePopperOpen}>
          <div />
          <div />
          <div />
        </MoreOption>
      </WrapTempInfo>
    </WrapItem>
  );
};

const popperSize = {width: 200, height: 122};
const UserProfileTemplates = ({user, setProfileTags}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [popper, setPopper] = useState({
    open: false,
    offset: {x: null, y: null},
  });
  const [modal, setModal] = useState({
    type: "",
    status: false,
  });
  const [items, setItems] = useState({
    templates: [],
    processing: false,
  });

  const windowSize = getWindowSize();
  const query = new URLSearchParams(useLocation().search);
  const tag = query.get("tag");

  useEffect(() => {
    if (user.account && user.account.profile) {
      setItems({
        ...items,
        processing: true,
      });
      fetch(
        !tag
          ? `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.account.profile.slug}/profile?tag=`
          : `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.account.profile.slug}/profile?tag=${tag}`
      )
        .then((response) => response.json())
        .then((data) => {
          setProfileTags(data.userProfile.tags);
          setItems({
            templates: data.templates,
            processing: false,
          });
        })
        .catch((err) => {
          setItems({
            templates: [],
            processing: false,
          });
        });
    }
  }, [tag]);

  const handlePopperOpen = (e, item) => {
    setSelectedItem(item);
    if (e.clientY + popperSize.width > window.innerHeight) {
      const extraSpace = 25;
      setPopper({
        open: !popper.open,
        offset: {
          x: e.pageX - popperSize.width,
          y: e.pageY - popperSize.height - extraSpace,
        },
      });
    } else {
      setPopper({
        open: !popper.open,
        offset: {x: e.pageX - popperSize.width, y: e.pageY - 20},
      });
    }
    e.preventDefault();
  };

  const handlePopperClose = () => {
    setPopper(false);
  };

  const openModal = (value) => {
    setModal({
      type: value,
      status: true,
    });
    setPopper({
      open: false,
      offset: {x: null, y: null},
    });
  };

  const closeModal = () => {
    setModal({
      type: "",
      status: false,
    });
  };

  const handleRemoveTemplate = () => {
    const {tempID, tempSourceID} = selectedItem;
    setModal({
      type: "processing",
      status: true,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        tempID,
        tempSourceID,
        profileSlug: user.account.profile.slug,
      }),
    };
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/profile`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setItems({
          processing: false,
          templates: items.templates.filter((temp) => temp.tempID !== tempID),
        });
        setPopper({
          open: false,
          offset: {x: null, y: null},
        });
        setModal({
          type: "",
          status: false,
        });
      });
  };

  const showTemplateLink = () => {
    setModal({
      type: "show-template-link",
      status: true,
    });
    setPopper({
      open: false,
      offset: {x: null, y: null},
    });
  };

  let navigate = useNavigate();
  const redirectToTemplateDetails = (item) => {
    const {tempID} = item;
    return navigate(`/design/profile/${user.account.profile.slug}/${tempID}`);
  };

  const {templates, processing} = items;

  return (
    <Layout>
      {windowSize.width >= 849 ? (
        <Header>
          <h3>Templates on Profile</h3>
        </Header>
      ) : undefined}
      {!processing ? (
        <Items>
          {templates && templates.length > 0
            ? templates.map((item, i) => (
                <DisplayItem
                  key={i}
                  item={item}
                  onSelectItem={redirectToTemplateDetails}
                  onPopperOpen={handlePopperOpen}
                  slug={user.account.profile.slug}
                />
              ))
            : undefined}
          {/* {error !== null ? (
            <CenterDiv>
              <div style={{width: "100%"}}>{error}</div>
            </CenterDiv>
          ) : (
            undefined
          )} */}
        </Items>
      ) : (
        <div style={{height: "40vh", display: "flex", alignItems: "center"}}>
          <Spinner />
        </div>
      )}

      {!processing && templates.length === 0 ? (
        <CenterDiv style={{width: "100%"}}>
          <EmptyTemplates />
        </CenterDiv>
      ) : undefined}

      {popper.open ? (
        <Popper
          width={popperSize.width}
          height={popperSize.height}
          offset={{x: popper.offset.x, y: popper.offset.y}}
          styles={{
            boxShadow: "0px 0px 3px 0px #827f8d",
          }}
          onClose={handlePopperClose}
        >
          <ProfileTemplateActions
            openModal={openModal}
            onRemoveTemplate={handleRemoveTemplate}
            onGetTempLink={showTemplateLink}
          />
        </Popper>
      ) : undefined}

      {modal.status ? (
        <ModalTypesProfile
          type={modal.type}
          close={closeModal}
          selectedItem={selectedItem.tempID}
          user={user.username}
        />
      ) : undefined}
    </Layout>
  );
};

const CenterDiv = styled.div`
  height: ${window.innerHeight - 150}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5%;
`;

const mapStateToProps = (state) => {
  const {authUser, userDashboard} = state;
  return {
    user: selectUserInfo(authUser),
  };
};

export default connect(mapStateToProps, {
  setProfileTags,
})(UserProfileTemplates);
