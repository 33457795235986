import styled from "styled-components";

export const Accordion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18px;
  cursor: pointer;
`;

export const Expand = styled.div`
  padding-right: 1.5em;
  font-size: 1.5rem;
`;

export const WrapBox = styled.div`
  padding: 15px 0;
  @media only screen and (min-width: 850px) {
    margin-top: 1em;
    background: ${({theme}) => theme.deskSecondaryBg};
  }
`;
