import { googleFonts } from "../ui/fonts/googleFontLinks";

export function loadOatAllFonts() {
    const oatAllFonts = document.createElement("style");
    
    Object.entries(googleFonts).map(([id, item]) => {
      item.types &&
        item.types.map((item, i) => {
          if (i === 0) {
            // changes in here, should also reflect in loadUsedFonts.js
            const fontFamily = id.replace(/\s/g, '+');
            oatAllFonts.appendChild(
              document.createTextNode(`@import url(https://fonts.googleapis.com/css2?family=${fontFamily}:ital,wght@0,400;0,700;1,400;1,700&display=swap);`)
            );
          }
        });
    });
    document.head.appendChild(oatAllFonts);
}