/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:9a93b725-5cce-4d8b-af7b-c3a5b4ef51bb",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_OFSrJkn6u",
  aws_user_pools_web_client_id: "21ato2utpmgosfbt9umfh5lkj1",
  oauth: {
    domain: "oatusers400ce5b1-400ce5b1-dev.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://www.oatall.com/dashboard",
    redirectSignOut: "https://www.oatall.com",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ["FACEBOOK", "GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
