import {
  ADD_NEW_SLIDE,
  APPLY_TEXT_EFFECTS,
  FETCH_TEMPLATE_INIT,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_ERR,
  CHANGE_SLIDE_INDEX,
  CLOSE_MODAL,
  SET_ACTIVE_FIELD,
  SET_SINGLE_BG_COLOR,
  SET_TEMP_SCALE,
  SET_GRADIENT_BG_PROPS,
  SET_CREATE_PANEL,
  UPDATE_FIELD_POSITION,
  UPDATE_FIELD_SIZE,
  UPDATE_GROUP_SIZE,
  UPDATE_FIELD_STYLES,
  UPDATE_TEXT_CONTENT,
  UPDATE_SINGLE_COLOR,
  UPDATE_ICON_COLORS,
  UPDATE_TEXT_EFFECTS,
  // UPDATE_SLIDE_THEME,
  DESELECT_ACTIVE_FIELD,
  OPEN_MODAL,
  DUPLICATE_FIELD,
  REMOVE_FIELD,
  SELECT_BACKGROUND,
  TO_CREATE_TEXT,
  REVERT_STYLES,
  REVERT_BG_COLOR,
  UPLOAD_IMAGE,
  APPLY_IMAGE_FILTER,
  ZINDEX_TO_FRONT,
  ZINDEX_TO_BACK,
  CROP_IMAGE,
  TRANSFORM_IMG_OFFSET,
  UPDATE_EDITING_PANEL,
  RESET_EDITING_PANEL,
  REPLACE_IMG_STATUS,
  REPLACE_IMG,
  LOCK_FIELD_POSITION,
  UPDATE_GROUP_OFFSET,
  SWITCH_SLIDE_INDEX,
  GET_ORIGINAL_OFFSET,
  INTERCEPT_GROUP_SELECTION,
  CHANGE_SLIDE_THEME,
  COPY_FIELD,
  PASTE_FIELD,
  SHOW_EDIT_PANEL,
  RESIZE_TEMPLATE,
  CHANGE_SLIDE_BY_ID,
  DELETE_SLIDE,
  CLONE_SLIDE,
  CREATE_CHART_ROW,
  CREATE_CHART_COLUMN,
  CREATE_TABLE_ROW,
  CREATE_TABLE_COLUMN,
  REMOVE_TABLE_COLUMN,
  REMOVE_TABLE_ROW,
  UPDATE_TABLE_CONTENT,
  RESIZE_TABLE_COLUMN_CELL,
  ENABLE_TEXT_EDIT,
  DISPATCH_REDO_ITEM,
  DISPATCH_UNDO_ITEM,
  TOGGLE_VIEW_TABLE_EDIT_BAR,
  UPDATE_TABLE_HEIGHT,
  RESIZE_TABLE,
  SET_INIT_CELL_SIZES,
  REMOVE_INIT_CELL_SIZES,
  UPDATE_TABLE_SIZE,
  UPDATE_THEME_BUILDER_FIELDS,
  CREATE_THEME,
  UPDATE_THEME,
  CREATE_TABLE,
  CHANGE_PAGE_THEME,
  BUILDING_THEME,
  CREATE_TEXT,
  CREATE_CHART,
  CREATE_ICON,
  CREATE_SHAPE,
  TOGGLE_SLIDE_WORKING_STATUS,
  ADD_SLIDE_NOTES,
  UPDATE_SLIDE_TITLE,
  SET_SLIDE_TRANSITION,
  EMBED_VIDEO_LINK,
  EDIT_TEMPLATE_NAME,
  SET_SLIDE_SECTION_NAME,
  ADD_NEW_SECTION_GROUP_SLIDES,
  SET_USER_TEMPLATE_ID,
  UPDATE_THEME_NAME,
  EDIT_THEME,
  CLONE_THEME,
  DELETE_THEME,
  CREATE_SLIDE_FROM_TEMPLATE,
  TOGGLE_TEMPLATE_SELECTION_MODAL,
  ADD_TEXT_FAMILY_SOURCE,
  TOGGLE_TEMPORARILY_DISABLE_SLIDE_TRANSITION,
  ADD_LINK_TO_TEXT,
  SET_SEARCHED_TEMPLATES,
  APPLY_TO_ALL_TEXT_TYPE,
  ADD_GROUP_SECTION,
  DELETE_GROUP_SECTION,
  EDIT_GROUP_SECTION_CONTENT,
  EDIT_GROUP_SECTION_LINK,
  TEMPLATE_LIST_INIT,
  TEMPLATE_LIST_SUCCESS,
  TEMPLATE_LIST_ERR,
  DESIGN_LIST_INIT,
  DESIGN_LIST_SUCCESS,
  DESIGN_LIST_ERR,
  CREATE_DESIGN_SWITCH_TAB,
  SET_THEME_INFO,
  CHANGE_TEMP_VISIBILITY,
  RESET_STATE,
  CHANGE_HIGHLIGHTED_TEXT_COLOR,
  FETCH_LIBRARY_FONTS_SUCCESS,
  ADD_LIBRARY_FONT,
  SELECT_FONT_FAMILY,
  SELECT_FONT_TYPE,
  UPDATE_SINGLE_COLOR_CHART,
  UPDATE_CHART_COLOR_SET,
  UPDATE_CHART_INFO,
  DELETE_CHART_ROW,
  DELETE_CHART_COLUMN,
  SET_TEMP_PASSCODE,
  ADD_SLIDE_COMMENT,
  RESPOND_TO_THREAD,
  DELETE_SLIDE_COMMENT,
  TOGGLE_TOOLTIP_POPPER,
  SET_CANVAS_PANEL_WIDTH,
  STORE_ACTIVE_UNDO_REDO,
  STORE_BACKGROUND_UNDO_REDO,
  STORE_ACTIONS_UNDO_REDO,
  UPDATE_SLIDE_COMMENT,
  CHANGE_CHART_TYPE,
  GROUPED_ITEMS_FOR_COLLABORATION,
  SET_USER_ONLINE,
  REMOVE_USER_ONLINE,
  RESIZE_TABLE_ROW_CELL,
  RESIZING_FIELD,
  DRAGGING_FIELD,
  BATCH_UPDATE_TABLE_ROW_HEIGHT,
  SEND_CHAT_TEMPLATE,
  MANAGE_TEMPLATE_NOTIFICATION,
  ON_RESOLVED_ISSUE,
  UPDATE_THEME_FROM_SOCKET,
  CREATE_THEME_FROM_SOCKET,
  PASTE_FIELD_FROM_SOCKET,
  COLLABORATOR_DESELECT_FIELD,
  SET_EXISTING_USERS_ONLINE,
  COLLABORATOR_SELECT_FIELD,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  SET_SMART_ALIGNMENT,
  STORE_GROUP_FIELDS_INFO_UNDO_REDO,
  UPDATE_MULTIPLE_FIELD_STYLES,
  STORE_FIELD_AS_ACTIVE_UNDO_REDO,
  STORE_FIELD_AS_HISTORY_UNDO_REDO,
  SHARE_AS_GUEST,
  UPDATE_GUEST_ACCESS_LEVEL,
  COLLABORATION_FIELD_CHANGED,
  CREATE_FIELD_FROM_SOCKET,
  RESET_FIELD_TO_EMIT_FROM_SOCKET,
  FETCH_LAYOUTS_SUCCESS,
  UPDATE_GROUP_FIELDS_COLLABORATION,
  UPDATE_UNDOREDO_FIELDS_OF_COLLABORATION,
  DELETE_FIELDS_COLLABORATION,
  SELECT_ANIMATION_TYPE,
  CHANGE_SLIDE_ID_PRESENT_VIEW,
  APPLY_ANIMATION,
  UPDATE_FIELD_COLLABORATION,
  SET_FIELD_TO_ALIGN_POSITION,
  ADD_NEW_SLIDE_COLLABORATION,
  PREVIEW_ANIMATION,
  SELECT_LAYOUT_TYPE,
  STRAIGHTEN_SHAPE_LINE,
  SET_GUEST_LIST,
  ADMIN_FILTER_LAYOUTS,
  CANVAS_SIZE,
  TOGGLE_TABLE_ROWS_COLUMNS_DRAWER,
  ACTIVE_TABLE_CELL,
  CLONE_TABLE_ROW_COLUMN,
  VIEW_CHART_INFO,
  APPLY_ANIMATION_ON_SLIDE_LOAD,
  APPLY_ANIMATION_DELAY,
  SET_CURRENT_ANIMATION_ORDER,
  SET_ANIMATION_PREVIEW_OPTION,
  GROUP_FIELDS_AS_ONE,
  COLLABORATE_GROUP_FIELDS_AS_ONE,
  APPLY_ANIMATION_ON_EXIT,
  SET_USED_FONTS,
  APPLY_LINK_IMAGE_ICON,
  SET_USER_CONNECTION_ID,
  INIT_PLAY_GROUND
  // SET_CURRENT_ANIMATION_ORDER,
} from "../actions/actionTypes";
import {changePageTheme} from "../reducers/functions/layout/pageTheme";
import {
  resizeTemplate,
  fetchTemplate,
  fetchTemplateErr,
  fetchTemplateSuccess,
  setTempScale,
  editTemplateName,
  setUserTemplateID,
  toggleTemplateSelectionModal,
  setSearchedTemplates,
  changeTempVisibility,
  setPasscode,
  manageTemplateNotification,
  shareAsGuest,
  updateGuestAccessLevel,
  setGuestList,
  setCanvasSize,
  setUsedFonts,
  setUserConnectionId,
  setInitPlayGround,
} from "./functions/template/template";
import {
  createTheme,
  updateTheme,
  updateThemeBuilderFields,
  changeSlideTheme,
  buildingTheme,
  updateThemeTitle,
  editTheme,
  cloneTheme,
  deleteTheme,
  applyToAllTextType,
  setThemeInfo,
  updateThemeFromSocket,
  createThemeFromSocket,
} from "./functions/template/templateTheme";
import {
  setActiveField,
  copyField,
  deselectField,
  dragField,
  duplicateField,
  pasteField,
  removeField,
  resizeField,
  zIndexToBack,
  zIndexToFront,
  updateFieldStyles,
  enableTextEdit,
  updateSingleColor,
  storeCustomFonts,
  addLibraryFont,
  selectFontFamily,
  selectFontType,
  draggingField,
  resizingField,
  updateMultipleFieldStyles,
  setFieldToAlignPosition,
  groupFieldsAsOne,
  applyLinkToImageIcon,
  addLinkToField
} from "./functions/fields/common";
import {
  selectAnimationType,
  applyAnimationOrder,
  previewAnimation,
  applyAnimationOnSlideLoad,
  applyAnimationDelay,
  setCurrentAnimationOrder,
  setAnimationPreviewOption,
  applyAnimationOnExit,
} from "./functions/fields/animation";
import {
  addHighlightedTextColor,
  addTextFamilySource,
  applyTextEffects,
  createText,
  updateTextContent,
  updateTextEffects,
} from "./functions/fields/text";
import {
  applyImageFilters,
  cropImageStatus,
  replaceImage,
  replaceImageStatus,
  transformImgOffset,
  uploadImage,
} from "./functions/fields/image";
import {
  createChartColumn,
  createChart,
  createChartRow,
  updateChartColorSet,
  updateChartInfo,
  updateSingleColorChart,
  deleteChartRow,
  deleteChartColumn,
  changeChartType,
  onViewChartInfo,
} from "./functions/fields/chart";
import {createIcon, updateIconColors} from "./functions/fields/icon";
import {createShape, straightenShapeLine} from "./functions/fields/shape";
import {
  addNewSlide,
  changeSlideIndex,
  cloneSlide,
  deleteSlide,
  switchSlideIndex,
  changeSlideByID,
  toggleSlideWorkingStatus,
  addSlideNotes,
  updateSlideTitle,
  setSlideTransition,
  addNewSectionGroupSlides,
  setSlideSectionName,
  createSlideFromTemplate,
  temporarilyDisableSlideTransition,
  changeSlideOnPresentView,
} from "./functions/template/slide";
import {
  handleRedo,
  handleUndo,
  storeActionsUndoRedo,
  storeFieldUndoRedo,
  storeBackgroundUndoRedo,
  storeFieldAsActiveUndoRedo,
  storeFieldAsHistoryUndoRedo,
} from "./functions/fields/undoRedo";
import {
  closeDrawer,
  openDrawer,
  resetEditingPanel,
  updateEditingPanel,
} from "./functions/layout/mobileLayout";
import {
  revertBgColor,
  selectBackground,
  setBackgroundColor,
  updateBackgroundProps,
} from "./functions/fields/background";
import {
  addNewTableColumn,
  addNewTableRow,
  batchUpdateTableRowsHeight,
  cloneTableRowColumn,
  createTable,
  removeInitCellSizes,
  removeTableColumn,
  removeTableRow,
  resizeTable,
  resizeTableColumnCell,
  resizeTableRowCell,
  setActiveTableCell,
  setInitialCellSizes,
  toggleTableRowsColumnsDrawer,
  updateTableContent,
  updateTableContentSize,
  updateTableHeight,
  updateTableSize,
} from "./functions/fields/table";
import {
  getOriginalOffset,
  interceptGroupSelection,
  setGroupedItemsCollaboration,
  storeGroupFieldsInfoUndoRedo,
  updateGroupOffset,
  updateGroupSize,
  updateGroupFieldsCollaboration,
  getOverlapItemsNotInsideGroup,
} from "./functions/fields/groupSelection";
import {
  setCanvasPanelWidth,
  showEditPanel,
  toggleTooltipPopper,
} from "./functions/layout/desktopLayout";
import {embedVideoLink} from "./functions/fields/video";
import {themeBuilder, themes, OatDefaultTheme} from "./data/themeBuilder.data";
import {
  addGroupSection,
  editGroupSectionContent,
  deleteGroupSection,
  editGroupSectionLink,
} from "./functions/template/groupSection";
import {
  tempListInit,
  tempListError,
  tempListSuccess,
  designListInit,
  designListSuccess,
  designListError,
  createDesignSwitchTab,
} from "./functions/template/templateList";
import {
  addSlideComment,
  deleteSlideComment,
  onResolvedIssue,
  respondToThread,
  updateSlideComment,
} from "./functions/template/comment";
import {
  removeUserOnline,
  setExistingOnlineUsers,
  setUserOnline,
} from "./functions/template/usersOnline";
import {sendChatTemplate} from "./functions/template/chat";
import {
  collaboratorDeselectField,
  collaboratorActiveField,
  collaborationFieldChanged,
  updateUndoRedoFieldsOfCollaboration,
  deleteFieldsCollaboration,
  pasteFieldFromSocket,
  createFieldFromSocket,
  resetFieldToEmitFromSocket,
  updateFieldCollaboration,
  addNewSlideCollaboration,
  collaborateGroupFieldsAsOne,
} from "./functions/collaboration/collaboration";
import {setSmartAlignment} from "./functions/template/smartAlignment";
import {
  adminFilterLayouts,
  fetchLayouts,
  onSelectLayoutType,
} from "./functions/template/layouts";
import { createSlideID } from "./functions/fields/utils";

export const initGroupSelect = {
  keys: [],
  slideID: null,
  initFieldValues: {},
  groupAsOne: false,  // group multi fields as one
  selection: {
    show: false,
    size: {w: null, h: null},
    offset: {x: null, h: null},
    orgSize: {w: null, h: null},
    orgOffset: {x: null, h: null},
  },
};

export const smartAlignmentInitState = {
  x: {
    display: false,
    value: null,
  },
  y: {
    display: false,
    value: null,
  },
  points: {
    x: {},
    y: {},
  },
};

export const fullCanvasWidth = 100; // set 100 for screenshot

// default temp size for ratio stuff -> 536
const defaultTempSize = 535;
const initialState = {
  tempID: null,
  tempSourceID: null,
  workspaceID: null,
  refTemp: null,
  owner: null,
  pageTheme: "dark",
  userConnectionId: null, 
  loading: false,
  size: {w: 1920, h: 1080},
  orgTempSize: {w: 1920, h: 1080},
  canvasSize: 0,
  ratio: "16x9",
  category: "presentation", // set default presentation, social-media
  folder: {
    ID: null,
    type: null, // team or private
  },
  template: [],
  templateName: "",
  visibility: "private",
  tempLng: null,
  activeSlide: -1, // init set -1 index
  prevSlide: -1, 
  currentSlideOnClickAnimationOrder: 0, // slide's animation order index
  currentSlideOnDisplayAnimationOrder: 0,
  previewAnimationOption: "on-slide-display",
  previewAnimation: false,
  activeSlideID: null,
  activeField: -1,
  layout: {
    tempScale: 1,
    showEditPanel: false,
    canvasPanel: fullCanvasWidth, // canvas panel in percentage
    canvasPanelWidth: 0, // full canvas panel with padding
  },
  ratioDiff: 0, //(size.w / 560).toFixed(2),
  activeTheme: OatDefaultTheme,
  selectedFieldType: "",
  openModal: {status: false, type: "", children: null},
  resizing: {
    status: false,
    orig: {},
    direction: null,
  },
  dragging: {
    status: false,
    orig: {},
  },
  viewOnDesignPage: false,
  fieldToEmitFromSocket: {}, // for collaboration, when new items are being created emit to socket
  tableResizeProps: {
    hideBorder: false,
    callTableHeight: null,
  },
  activeTableCell: {row: null, column: null},
  toggleTableRowsColumnsDrawer: {status: false, type: null, index: null}, // mobile table action drawer
  viewChartInfo: false, // modal chart to edit
  updatedIconColorIndex: { // for icons when updating colors
    row: null,
    column: null
  }, 
  templateSelection: {
    templates: [], 
    lastEvaluatedKey: "",
    selectedTempID: null,
    status: false, // modal status
    activeTab: "design-list", // template list or design list
    designs: [], // aka slides inside a template
    chosenTempDesigns: [], // design initially selected by user
    loading: false,
    size: {w: 1920, h: 1080},
    themes: null,
    searchedKeyWords: "",
    error: {
      status: false,
      message: null,
    },
  },
  error: null,
  cachedFieldStyles: {},
  cachedFieldProps: {},
  cropImage: false,
  replaceImage: false,
  mobEditingPanel: {
    addedHeight: 0,
    type: "",
  },
  mobile: {
    drawer: {
      open: false,
      type: "",
      height: 0,
    },
  },
  sizesOffsets: [],
  groupSelect: initGroupSelect,
  copiedItems: "",
  enableTextEdit: false,
  undoRedo: {
    history: [],
    active: {},
    future: [],
    firstTextInput: false, // text, table to track first char input
  },
  embedType: "",
  slideTransition: "", // for presnting read only
  temporarilyDisableSlideTransition: false, // for presnting temp, read only
  slideGroupList: [
    {name: "Introduction", value: "231da"},
    {name: "Context", value: "21a21f"},
  ],
  // theme builder stuff
  buildingTheme: {
    status: false,
    updatingTheme: false, // not creating new one
    selectedIndexToBeUpdated: null, // for updating
  },
  // active theme
  themeBuilder: themeBuilder,
  themes: themes,
  sectionList: [],
  usedCustomFonts: [], // used within template
  libraryCustomFonts: {
    fonts: {},
    isLoadedFirstTime: false, // notify event to the connected component
  },
  passcode: {
    enabled: false,
    value: "",
    auth: false, // presenting only to check against user's passcode
  },
  openTooltipPopper: {
    status: false,
    type: null,
  },
  chats: [],
  emitItemsToSocket: {
    payload: {},
    type: null,
  },
  emitUndoRedoItemToSocket: {},
  smartAlignment: smartAlignmentInitState,
  guestUsers: [],
  workspaceMembers: {}, // or []
  workspacePlan: "free",
  notification: [],
  usedFontList: [], // for displaying
  tempNotiInfo: {}, // manage noti subscription status
  layouts: {}, // for user layout selection ?
  versions: {},
  currentAnimationOrder: 0,
  adminLayouts: {
    layoutTemplates: [], // to store full template list before filtering out by type
    selectedLayoutType: "",
    creatingLayouts: false, // status if admin is creating layouts
  },
  isDraft: false, // for admin creating templates
  usedFonts: [], // for font selection
  isGuestUser: false,
  usersOnline: {
    noOfUsers: 0,
    selectedIDs: [], // for easy filter out
    // "DHNv0w", "ic9nI8"
    users: {
      // xkiXQE3r1GN: {
      //   name: null,
      //   activeSlide: 0, // "evAgTG", // 0
      //   activeField: null, //  "ic9nI8", // null
      //   active: true,
      //   groupSelection: {
      //     keys: [], // "ic9nI8", "re7RZV"
      //     selection: {
      //       show: false,
      //       offset: {x: 155, y: 291},
      //       size: {w: 1742, h: 767},
      //       orgOffset: {x: 155, y: 291},
      //       orgSize: {w: 1742, h: 767},
      //     },
      //     color: "#ff5656",
      //     initFieldValues: {
      //     },
      //   },
      // },
    },
  },
};

const handlers = {};

handlers[SET_ACTIVE_FIELD] = setActiveField();
handlers[DESELECT_ACTIVE_FIELD] = deselectField();
handlers[DUPLICATE_FIELD] = duplicateField();
handlers[REMOVE_FIELD] = removeField();
handlers[COPY_FIELD] = copyField();
handlers[PASTE_FIELD] = pasteField();
handlers[ZINDEX_TO_FRONT] = zIndexToFront();
handlers[ZINDEX_TO_BACK] = zIndexToBack();
handlers[UPDATE_FIELD_SIZE] = resizeField();
handlers[UPDATE_FIELD_POSITION] = dragField();
handlers[UPDATE_SINGLE_COLOR] = updateSingleColor();
handlers[UPDATE_FIELD_STYLES] = updateFieldStyles();
// handlers[REVERT_STYLES] = revertStyles();

// text & table
handlers[ENABLE_TEXT_EDIT] = enableTextEdit();

handlers[CHANGE_PAGE_THEME] = changePageTheme();

// text
handlers[CREATE_TEXT] = createText();
handlers[APPLY_TEXT_EFFECTS] = applyTextEffects();
handlers[UPDATE_TEXT_EFFECTS] = updateTextEffects();
handlers[UPDATE_TEXT_CONTENT] = updateTextContent();
handlers[ADD_TEXT_FAMILY_SOURCE] = addTextFamilySource();
handlers[ADD_LINK_TO_TEXT] = addLinkToField();
handlers[CHANGE_HIGHLIGHTED_TEXT_COLOR] = addHighlightedTextColor();

// image
handlers[TRANSFORM_IMG_OFFSET] = transformImgOffset();
handlers[UPLOAD_IMAGE] = uploadImage();
handlers[APPLY_IMAGE_FILTER] = applyImageFilters();
handlers[REPLACE_IMG] = replaceImage();
handlers[REPLACE_IMG_STATUS] = replaceImageStatus(); // if replacing
handlers[CROP_IMAGE] = cropImageStatus();

// icon
handlers[CREATE_ICON] = createIcon();
handlers[UPDATE_ICON_COLORS] = updateIconColors();

// shape
handlers[CREATE_SHAPE] = createShape();
handlers[STRAIGHTEN_SHAPE_LINE] = straightenShapeLine();

// group selection
handlers[INTERCEPT_GROUP_SELECTION] = interceptGroupSelection();
handlers[GET_ORIGINAL_OFFSET] = getOriginalOffset();
handlers[UPDATE_GROUP_OFFSET] = updateGroupOffset();
handlers[UPDATE_GROUP_SIZE] = updateGroupSize();
handlers[GROUPED_ITEMS_FOR_COLLABORATION] = setGroupedItemsCollaboration();

// slide
handlers[CHANGE_SLIDE_INDEX] = changeSlideIndex();
handlers[CHANGE_SLIDE_BY_ID] = changeSlideByID();
handlers[CHANGE_SLIDE_ID_PRESENT_VIEW] = changeSlideOnPresentView();
handlers[ADD_NEW_SLIDE] = addNewSlide();
handlers[DELETE_SLIDE] = deleteSlide();
handlers[CLONE_SLIDE] = cloneSlide();
handlers[SWITCH_SLIDE_INDEX] = switchSlideIndex();
handlers[TOGGLE_SLIDE_WORKING_STATUS] = toggleSlideWorkingStatus();
handlers[ADD_SLIDE_NOTES] = addSlideNotes();
handlers[UPDATE_SLIDE_TITLE] = updateSlideTitle();
handlers[SET_SLIDE_TRANSITION] = setSlideTransition();
handlers[ADD_NEW_SECTION_GROUP_SLIDES] = addNewSectionGroupSlides();
handlers[SET_SLIDE_SECTION_NAME] = setSlideSectionName();
handlers[CREATE_SLIDE_FROM_TEMPLATE] = createSlideFromTemplate();
handlers[TOGGLE_TEMPORARILY_DISABLE_SLIDE_TRANSITION] =
  temporarilyDisableSlideTransition();

// comment
handlers[ADD_SLIDE_COMMENT] = addSlideComment();
handlers[RESPOND_TO_THREAD] = respondToThread();
handlers[DELETE_SLIDE_COMMENT] = deleteSlideComment();
handlers[UPDATE_SLIDE_COMMENT] = updateSlideComment();
handlers[ON_RESOLVED_ISSUE] = onResolvedIssue();

// template
handlers[FETCH_TEMPLATE_INIT] = fetchTemplate();
handlers[FETCH_TEMPLATE_SUCCESS] = fetchTemplateSuccess();
handlers[FETCH_TEMPLATE_ERR] = fetchTemplateErr();
handlers[RESIZE_TEMPLATE] = resizeTemplate();
handlers[SET_TEMP_SCALE] = setTempScale();
handlers[EDIT_TEMPLATE_NAME] = editTemplateName();
handlers[SET_USER_TEMPLATE_ID] = setUserTemplateID();
handlers[TOGGLE_TEMPLATE_SELECTION_MODAL] = toggleTemplateSelectionModal();
handlers[SET_SEARCHED_TEMPLATES] = setSearchedTemplates();
handlers[CHANGE_TEMP_VISIBILITY] = changeTempVisibility();
handlers[SET_TEMP_PASSCODE] = setPasscode();
handlers[MANAGE_TEMPLATE_NOTIFICATION] = manageTemplateNotification();
handlers[SHARE_AS_GUEST] = shareAsGuest();
handlers[UPDATE_GUEST_ACCESS_LEVEL] = updateGuestAccessLevel();
handlers[SET_GUEST_LIST] = setGuestList();
handlers[CANVAS_SIZE] = setCanvasSize();
handlers[SET_USED_FONTS] = setUsedFonts();

// template list
handlers[TEMPLATE_LIST_INIT] = tempListInit();
handlers[TEMPLATE_LIST_SUCCESS] = tempListSuccess();
handlers[TEMPLATE_LIST_ERR] = tempListError();
handlers[DESIGN_LIST_INIT] = designListInit();
handlers[DESIGN_LIST_SUCCESS] = designListSuccess();
handlers[DESIGN_LIST_ERR] = designListError();
handlers[CREATE_DESIGN_SWITCH_TAB] = createDesignSwitchTab();

// background
handlers[SELECT_BACKGROUND] = selectBackground();
handlers[SET_SINGLE_BG_COLOR] = setBackgroundColor();
handlers[SET_GRADIENT_BG_PROPS] = updateBackgroundProps();
handlers[REVERT_BG_COLOR] = revertBgColor(); // ?

// desktop layout
handlers[SHOW_EDIT_PANEL] = showEditPanel();
handlers[TOGGLE_TOOLTIP_POPPER] = toggleTooltipPopper();
handlers[SET_CANVAS_PANEL_WIDTH] = setCanvasPanelWidth();
// handlers[OPEN_MODAL] = openModal();
// handlers[CLOSE_MODAL] = closeModal();
// handlers[SET_CREATE_PANEL] = setCreatePanel();

// mobile layout
handlers[UPDATE_EDITING_PANEL] = updateEditingPanel();
handlers[RESET_EDITING_PANEL] = resetEditingPanel();

// chart
handlers[CREATE_CHART] = createChart();
handlers[CREATE_CHART_ROW] = createChartRow();
handlers[CREATE_CHART_COLUMN] = createChartColumn();
handlers[UPDATE_SINGLE_COLOR_CHART] = updateSingleColorChart();
handlers[UPDATE_CHART_COLOR_SET] = updateChartColorSet();
handlers[UPDATE_CHART_INFO] = updateChartInfo();
handlers[DELETE_CHART_ROW] = deleteChartRow();
handlers[DELETE_CHART_COLUMN] = deleteChartColumn();
handlers[CHANGE_CHART_TYPE] = changeChartType();
handlers[VIEW_CHART_INFO] = onViewChartInfo();

// table
handlers[CREATE_TABLE_ROW] = addNewTableRow();
handlers[CREATE_TABLE_COLUMN] = addNewTableColumn();
handlers[REMOVE_TABLE_COLUMN] = removeTableColumn();
handlers[UPDATE_TABLE_CONTENT] = updateTableContent();
handlers[REMOVE_TABLE_ROW] = removeTableRow();
handlers[RESIZE_TABLE_COLUMN_CELL] = resizeTableColumnCell();
handlers[RESIZE_TABLE] = resizeTable();
handlers[REMOVE_INIT_CELL_SIZES] = removeInitCellSizes();
handlers[SET_INIT_CELL_SIZES] = setInitialCellSizes();
handlers[UPDATE_TABLE_SIZE] = updateTableSize();
handlers[CREATE_TABLE] = createTable();
handlers[UPDATE_TABLE_HEIGHT] = updateTableHeight();
handlers[RESIZE_TABLE_ROW_CELL] = resizeTableRowCell();
handlers[BATCH_UPDATE_TABLE_ROW_HEIGHT] = batchUpdateTableRowsHeight();
handlers[TOGGLE_TABLE_ROWS_COLUMNS_DRAWER] = toggleTableRowsColumnsDrawer();
handlers[ACTIVE_TABLE_CELL] = setActiveTableCell();
handlers[CLONE_TABLE_ROW_COLUMN] = cloneTableRowColumn();

// video
handlers[EMBED_VIDEO_LINK] = embedVideoLink();

// undo - redo
handlers[DISPATCH_UNDO_ITEM] = handleUndo();
handlers[DISPATCH_REDO_ITEM] = handleRedo();
handlers[STORE_ACTIVE_UNDO_REDO] = storeFieldUndoRedo();
handlers[STORE_BACKGROUND_UNDO_REDO] = storeBackgroundUndoRedo();
handlers[STORE_ACTIONS_UNDO_REDO] = storeActionsUndoRedo();
handlers[STORE_FIELD_AS_ACTIVE_UNDO_REDO] = storeFieldAsActiveUndoRedo();
handlers[STORE_FIELD_AS_HISTORY_UNDO_REDO] = storeFieldAsHistoryUndoRedo();

// theme builder
handlers[UPDATE_THEME_BUILDER_FIELDS] = updateThemeBuilderFields();
handlers[CREATE_THEME] = createTheme();
handlers[UPDATE_THEME] = updateTheme();
handlers[CHANGE_SLIDE_THEME] = changeSlideTheme(); //UPDATE_SLIDE_THEME
handlers[BUILDING_THEME] = buildingTheme();
handlers[UPDATE_THEME_NAME] = updateThemeTitle();
handlers[EDIT_THEME] = editTheme();
handlers[CLONE_THEME] = cloneTheme();
handlers[DELETE_THEME] = deleteTheme();
handlers[APPLY_TO_ALL_TEXT_TYPE] = applyToAllTextType();
handlers[SET_THEME_INFO] = setThemeInfo();

// group section
handlers[ADD_GROUP_SECTION] = addGroupSection();
handlers[DELETE_GROUP_SECTION] = deleteGroupSection();
handlers[EDIT_GROUP_SECTION_CONTENT] = editGroupSectionContent();
handlers[EDIT_GROUP_SECTION_LINK] = editGroupSectionLink();
handlers[STORE_GROUP_FIELDS_INFO_UNDO_REDO] = storeGroupFieldsInfoUndoRedo();

// common
handlers[FETCH_LIBRARY_FONTS_SUCCESS] = storeCustomFonts();
handlers[ADD_LIBRARY_FONT] = addLibraryFont();
handlers[RESET_STATE] = handleResetState();
handlers[SELECT_FONT_FAMILY] = selectFontFamily();
handlers[SELECT_FONT_TYPE] = selectFontType();
handlers[RESIZING_FIELD] = resizingField();
handlers[DRAGGING_FIELD] = draggingField();
handlers[UPDATE_MULTIPLE_FIELD_STYLES] = updateMultipleFieldStyles();
handlers[GROUP_FIELDS_AS_ONE] = groupFieldsAsOne();
handlers[APPLY_LINK_IMAGE_ICON] = applyLinkToImageIcon(); 

// animation
handlers[SELECT_ANIMATION_TYPE] = selectAnimationType();
handlers[APPLY_ANIMATION] = applyAnimationOrder();
handlers[PREVIEW_ANIMATION] = previewAnimation();
handlers[APPLY_ANIMATION_ON_SLIDE_LOAD] = applyAnimationOnSlideLoad();
handlers[APPLY_ANIMATION_DELAY] = applyAnimationDelay();
handlers[SET_CURRENT_ANIMATION_ORDER] = setCurrentAnimationOrder();
handlers[SET_ANIMATION_PREVIEW_OPTION] = setAnimationPreviewOption();
handlers[APPLY_ANIMATION_ON_EXIT] = applyAnimationOnExit();

// online users
handlers[SET_USER_ONLINE] = setUserOnline();
handlers[REMOVE_USER_ONLINE] = removeUserOnline();
handlers[SET_USER_CONNECTION_ID] = setUserConnectionId();

// chat
handlers[SEND_CHAT_TEMPLATE] = sendChatTemplate();

// collaboration
handlers[CREATE_FIELD_FROM_SOCKET] = createFieldFromSocket();
handlers[COLLABORATOR_SELECT_FIELD] = collaboratorActiveField();
handlers[COLLABORATOR_DESELECT_FIELD] = collaboratorDeselectField();
handlers[PASTE_FIELD_FROM_SOCKET] = pasteFieldFromSocket();
handlers[CREATE_THEME_FROM_SOCKET] = createThemeFromSocket();
handlers[UPDATE_THEME_FROM_SOCKET] = updateThemeFromSocket();
handlers[SET_EXISTING_USERS_ONLINE] = setExistingOnlineUsers();
handlers[COLLABORATION_FIELD_CHANGED] = collaborationFieldChanged();
// handlers[COLLABORATION_FIELD_REMOVED] = removeFieldCollab();
handlers[RESET_FIELD_TO_EMIT_FROM_SOCKET] = resetFieldToEmitFromSocket();
handlers[UPDATE_GROUP_FIELDS_COLLABORATION] = updateGroupFieldsCollaboration();
handlers[UPDATE_UNDOREDO_FIELDS_OF_COLLABORATION] =
  updateUndoRedoFieldsOfCollaboration();
handlers[DELETE_FIELDS_COLLABORATION] = deleteFieldsCollaboration();
handlers[UPDATE_FIELD_COLLABORATION] = updateFieldCollaboration();
handlers[ADD_NEW_SLIDE_COLLABORATION] = addNewSlideCollaboration();
handlers[COLLABORATE_GROUP_FIELDS_AS_ONE] = collaborateGroupFieldsAsOne();

// mobile
handlers[OPEN_DRAWER] = openDrawer();
handlers[CLOSE_DRAWER] = closeDrawer();

// smart alignment
handlers[SET_SMART_ALIGNMENT] = setSmartAlignment();

// layouts
handlers[FETCH_LAYOUTS_SUCCESS] = fetchLayouts();
handlers[SELECT_LAYOUT_TYPE] = onSelectLayoutType();
handlers[ADMIN_FILTER_LAYOUTS] = adminFilterLayouts();

// field align
handlers[SET_FIELD_TO_ALIGN_POSITION] = setFieldToAlignPosition();

handlers[INIT_PLAY_GROUND] = setInitPlayGround();

function handleResetState() {
  return function (state, action) {
    return {
      ...initialState,
    };
  };
}

function reducerFactory(initialState, handlers) {
  return function (state = initialState, action) {
    const handler = handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };
}

export default reducerFactory(initialState, handlers);

// // export const selectModalStatus = (state) => state.openModal;

// export const getCanvasPanelWidth = (state) => state.layout.canvasPanel;
// export const getEditPanelSize = (state) => state.layout.editingPanel;
// export const selectCreatePanel = (state) => state.createPanel;

// // export const selectDragStatus = (state) => state.dragging;
// // export const selectResizeStatus = (state) => state.resizing;
