import React, {useState} from "react";
import styled from "styled-components";
import AddSlideContents from "./AddSlideContents";
import AddedContentList from "./AddedContentList";
import {
  addGroupSection,
  deleteGroupSection,
  editGroupSectionContent,
  editGroupSectionLink,
} from "../../../../../store/actions/template/groupSection.action";
import {connect} from "react-redux";
import {selectTemplate} from "../../../../../store/selectors/template/template.selector";

const SlideContents = ({addGroupSection}) => {
  const [section, setSection] = useState({
    title: "",
    startingSlideNo: null,
  });
  const [displayForm, setDisplayForm] = useState(true);

  const onAddContent = () => {
    addGroupSection({
      title: section.title,
      startingSlideNo: section.startingSlideNo,
    });
    // editGroupSectionLink({
    //   id: "a1231",
    //   startingSlideNo: "bbc",
    // });
  };

  const toggleDisplayForm = () => {
    setDisplayForm(!displayForm);
  };

  const changeSectionTitle = (e) => {
    setSection({
      ...section,
      title: e.target.value,
    });
  };

  return (
    <Layout>
      <LeftSection>
        <AddSlideContents
          onChangeTitle={changeSectionTitle}
          onAddContent={onAddContent}
          displayForm={displayForm}
          toggleDisplayForm={toggleDisplayForm}
        />
      </LeftSection>

      {displayForm ? <AddedContentList /> : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftSection = styled.div`
  flex: 1.5;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    slides: selectTemplate(designTemplate),
  };
};

export default connect(mapStateToProps, {
  addGroupSection,
  editGroupSectionContent,
  editGroupSectionLink,
  deleteGroupSection,
})(SlideContents);
