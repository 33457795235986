import React from "react";
import styled from "styled-components";
import "./resizer.css";
import {getCursor} from "../utils";

// Changes in these varibales should also reflect in Resizable.js
export const CornerSize = 10;

const Resizer = ({
  item,
  resizer,
  resizeStart,
  posLock,
  angle,
  onRotateStart,
  hideResizers,
}) => {
  const cursor =
    item.cursor === "move" ? "move" : `${getCursor(angle, item.cursor)}-resize`;

  const onResizeStart = (e) => {
    resizeStart(e, item.dir);
  };

  const displaySidesResizer = () => {
    if (item.dir === "left" || item.dir === "right") {
      return (
        <WrapResizer
          style={{
            top: "50%",
            left: item.dir === "left" ? 0 : undefined,
            right: item.dir === "right" ? 0 : undefined,
            transform:
              item.dir === "right"
                ? "translate(50%, -50%)"
                : "translate(-50%, -50%)",
            display: !posLock ? "flex" : "none",
            opacity: hideResizers ? 0 : 1,
            cursor,
          }}
          onMouseDown={onResizeStart}
          onTouchStart={onResizeStart}
          className={item.className}
        >
          <LeftRightResizer />
        </WrapResizer>
      );
    }
  };

  // corners, sides & top, bottom
  const displayAllResizer = () => {
    if (item.dir === "left" || item.dir === "right") {
      return (
        <WrapResizer
          style={{
            top: "50%",
            left: item.dir === "left" ? 0 : undefined,
            right: item.dir === "right" ? -1 : undefined,
            transform:
              item.dir === "right"
                ? "translate(50%, -50%)"
                : "translate(-50%, -50%)",
            display: !posLock ? "flex" : "none",
            opacity: hideResizers ? 0 : 1,
            cursor,
          }}
          onMouseDown={onResizeStart}
          onTouchStart={onResizeStart}
          className={item.className}
        >
          <LeftRightResizer />
        </WrapResizer>
      );
    } else if (item.dir === "top" || item.dir === "bottom") {
      return (
        <WrapResizer
          style={{
            left: "50%",
            top: item.dir === "top" ? -1 : undefined,
            bottom: item.dir === "bottom" ? -6 : undefined,
            transform:
              item.dir === "right"
                ? "translate(50%, -50%)"
                : "translate(-50%, -50%)",
            display: !posLock ? "flex" : "none",
            opacity: hideResizers ? 0 : 1,
            width: 20,
            height: 5,
            cursor,
          }}
          onMouseDown={onResizeStart}
          onTouchStart={onResizeStart}
          className={item.className}
        >
          <TopBtmResizer />
        </WrapResizer>
      );
    }
  };

  const displayCorners = () => {
    // const cursor = `${getCursor(angle, item.cursor)}-resize`;
    return (
      <WrapResizer
        onMouseDown={onResizeStart}
        onTouchStart={onResizeStart}
        className={item.className}
        style={{
          display: !posLock ? "flex" : "none",
          opacity: hideResizers ? 0 : 1,
          cursor,
        }}
      >
        <CornerResizer />
      </WrapResizer>
    );
  };

  return (
    <>
      {resizer === "corners-side" ? displaySidesResizer() : undefined}
      {item.corners ? displayCorners() : undefined}
      {resizer === "all" ? displayAllResizer() : undefined}
    </>
  );
};

export const WrapResizer = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 849px) {
    width: 30px;
    height: 30px;
  }
`;

const LeftRightResizer = styled.div`
  width: 5px;
  height: 20px;
  background: #fff;
  border-radius: 2px;
  left: 45%;
  box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
`;

const TopBtmResizer = styled.div`
  width: 20px;
  height: 5px;
  background: #fff;
  border-radius: 2px;
  top: 42%;
  box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
`;

export const CornerResizer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  &:after {
    content: "";
    width: ${CornerSize}px;
    height: ${CornerSize}px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
  }
`;

export default Resizer;
