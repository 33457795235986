import React from "react";
import styled from "styled-components";

const DisplayItem = ({field, id, onSelect}) => {
  const handleClick = () => {
    onSelect({
      ...field,
      id,
    });
  };
  return <Item onClick={handleClick}>Aung aunga dfasd dfas dfasd dfas</Item>;
};

const GetWorkspaceMembers = ({wsMembers, onSelect}) => {
  const handleUserSelection = (user) => {
    onSelect(user);
  };

  return wsMembers && Object.keys(wsMembers).length > 0 ? (
    <PopperBox>
      {wsMembers &&
        Object.entries(wsMembers).map(([id, field]) => (
          <DisplayItem
            key={id}
            id={id}
            field={field}
            onSelect={handleUserSelection}
          />
        ))}
    </PopperBox>
  ) : undefined;
};

const PopperBox = styled.div`
  position: absolute;
  top: -180px;
  left: 15px;
  width: 240px;
  height: 180px;
  border-radius: 6px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  padding-top: 2px;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.div`
  padding: 8px;
  box-sizing: border-box;
  color: ${({theme}) => theme.deskPrimaryTxt};
  cursor: pointer;
  background: ${({theme}) => theme.deskSecondaryBg3};
  margin: 10px;
  border-radius: 6px;
`;

export default GetWorkspaceMembers;
