import React, {useState, useRef} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {detectClickOutside} from "../../../oat-ui/utils/detectClickOutside";
import {ArrowBack, MagnifyingGlass} from "../../../oat-svg-icons";
import {useNavigate} from "react-router-dom";
import DisplayNavModal from "./DisplayNavModal";
import UserInfoOptions from "./userInfo/UserInfoOptions";
import {Auth} from "aws-amplify";
import {Option, Dot} from "../../../oat-ui/styles/SelectOption";
import {
  selectActiveWorkSpace,
  selectDashboardModal,
} from "../../store/selectors/userDashboard.selector";
import {Popper} from "../../../oat-ui";
import SearchTemplates from "./searchTemplates/SearchTemplates";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";
import Notification from "../../../oat-notification/Notification";
import {Bar, WrapBar} from "../../../oat-ui/styles/HamburgerStyles";
import {OatMobileNavigationSlider} from "../../../oat-public";
import {dispatchSignOut} from "../../../../redux/user/actions/authUser.action";
import {createWorkspaceSuccess} from "../../store/actions/workspace.action";
import {selectUserInfo} from "../../../../redux/user/authUser";

export const userDashboardNavPanelHeight = 54;
const userOptions = [{value: "general-user-info", display: "Account"}];
const UserDashboardNav = ({
  user,
  workspace,
  createWorkspaceSuccess,
  dispatchSignOut,
}) => {
  const popperRef = useRef();
  const [openPopper, setOpenPopper] = useState({
    status: false,
    type: null,
    offset: {x: 0, y: 0},
  });
  const [openModal, setOpenModal] = useState({
    status: false,
    type: null,
  });
  const [openNavSlider, setOpenNavSlider] = useState(false);
  const [error, setError] = useState({status: false, message: null});
  let navigate = useNavigate();
  const windowSize = getWindowSize();
  const notificationRef = useRef();
  const searchIconRef = useRef();
  const avatarRef = useRef();
  const adminPanelRef = useRef();

  const toggleNavSlider = () => {
    setOpenNavSlider(!openNavSlider);
  };

  const togglePopper = (type, offset) => {
    setOpenPopper({
      status: true,
      type,
      offset,
    });
  };

  const onOpenModal = (type) => {
    setOpenPopper(false);
    setOpenModal({
      type,
      status: true,
    });
  };

  const handleClose = () => {
    setOpenPopper({
      type: null,
      status: false,
    });
    setOpenModal({
      type: null,
      status: false,
    });
    setError({status: false, message: null});
  };

  const onSelectSearch = () => {
    const rect = searchIconRef.current.getBoundingClientRect();
    togglePopper("search", {x: rect.left, y: rect.top});
  };

  const onSelectNotification = () => {
    const rect = notificationRef.current.getBoundingClientRect();
    togglePopper("notification", {x: rect.left, y: rect.top});
  };

  const onSelectAvatar = () => {
    const rect = avatarRef.current.getBoundingClientRect();
    togglePopper("avatar", {x: rect.left, y: rect.top});
  };

  const handleLogOut = async () => {
    setOpenModal({
      type: "logging-out",
      status: true,
    });

    await Auth.signOut()
      .then(async () => {
        await dispatchSignOut();
        await navigate(`/`);
      })
      .catch((err) => {
        setError({status: true, message: "An Error Occured!"});
      });
  };

  const handlePopperClose = () => {
    setOpenPopper({
      type: null,
      status: false,
    });
  };

  const addAcceptedWorkspace = (item) => {
    const payload = {
      ID: item.workspaceID,
      name: item.workspaceName,
      role: item.role,
      type: "team",
    };
    createWorkspaceSuccess(payload);
  };

  const onUpgradeToPro = () => {
    navigate(`?upgrade=true`, { replace: true });
  }

  detectClickOutside(popperRef, () => handleClose());

  return (
    <NavPanel>
      <Fixed>
        <LeftSide>
          <WrapBar onClick={toggleNavSlider}>
            <Bar />
            <Bar />
            <Bar />
          </WrapBar>

          <div onClick={() => navigate(`/`)} className="title-oatall">
            Oat All
          </div>
        </LeftSide>

        <RightSide>
          <WrapIcons>
            {windowSize.width >= 850 ? (
              <>
                {/* <Section>
                  <Item onClick={openWorkspaceModal}>
                    <GradientText>Upgrade</GradientText>
                  </Item>
                </Section> */}

                {user.staff.status ? (
                  <Section ref={adminPanelRef} onClick={onSelectNotification}>
                    <Item>
                      <a
                        href="/admin/designs/presentation/16x9"
                        target="_blank"
                      >
                        Admin Panel
                      </a>
                    </Item>
                  </Section>
                ) : undefined}

                {workspace.plan === 'free'  ? (
                  <Section ref={adminPanelRef} onClick={onSelectNotification}>
                    <Item>
                      <b className="upgrade-to-pro" onClick={onUpgradeToPro}>
                        Upgrade
                      </b>
                    </Item>
                  </Section>
                ) : undefined}

                <Section style={{padding: 0}} ref={notificationRef} onClick={onSelectNotification}>
                  <Item>
                    <Notification
                      emitAcceptedWorkspace={addAcceptedWorkspace}
                    />
                    {/* <div style={{fill: "#848484"}}>{Bell}</div> */}
                  </Item>
                </Section>

                <Section ref={searchIconRef} onClick={onSelectSearch}>
                  <Item>
                    <WrapSvg style={{fill: "#4a4a4a"}}>
                      {MagnifyingGlass}
                    </WrapSvg>
                  </Item>
                </Section>

                <Section ref={avatarRef} onClick={onSelectAvatar}>
                  <Item style={{display: "flex", alignItems: "center"}}>
                    {/* <WrapSvg style={{fill: "#4a4a4a"}}>{Avatar}</WrapSvg> */}
                    <Avatar src={user?.avatar} alt="user-avatar" />
                  </Item>
                </Section>
              </>
            ) : (
              <>
                <Section ref={notificationRef} onClick={onSelectNotification}>
                  <Item>
                    <Notification />
                    {/* <div style={{fill: "#848484"}}>{Bell}</div> */}
                  </Item>
                </Section>

                <Section ref={searchIconRef} onClick={onSelectSearch}>
                  <Item>
                    <WrapSvg style={{fill: "#4a4a4a"}}>
                      {MagnifyingGlass}
                    </WrapSvg>
                  </Item>
                </Section>
              </>
            )}
          </WrapIcons>

          {openPopper.status && openPopper.type === "search" ? (
            windowSize.width >= 850 ? (
              <Popper
                width={450}
                height={300}
                // 60 icon width
                offset={{x: openPopper.offset.x - 450 + 60, y: 68}}
                enableScroll={false}
                onClose={handlePopperClose}
                background="#fff"
              >
                <SearchTemplates close={handlePopperClose} />
              </Popper>
            ) : (
              <MobileLayer>
                <BackButton onClick={handlePopperClose}>{ArrowBack}</BackButton>
                <SearchTemplates close={handlePopperClose} />
              </MobileLayer>
            )
          ) : undefined}

          {/* {openPopper.status && openPopper.type === "notification" ? (
            <Popper
              width={450}
              height={340}
              offset={{x: windowSize.width - 450 - 260, y: 68}}
              onClose={handlePopperClose}
            >
              <Notifications />
            </Popper>
          ) : undefined} */}

          {openPopper.status && openPopper.type === "avatar" ? (
            <Popper
              width={220}
              height={94}
              offset={{
                // 60 icon width
                x: openPopper.offset.x - 220 + 60,
                // windowSize.width - 220 - 140,
                y: 68,
              }}
              onClose={handlePopperClose}
            >
              <div style={{paddingTop: 10}}>
                {userOptions.map((option, i) => (
                  <UserInfoOptions
                    key={i}
                    option={option}
                    onSelect={onOpenModal}
                  />
                ))}
                <Option onClick={handleLogOut}>
                  <div>Sign Out</div>
                  <Dot />
                </Option>
              </div>
            </Popper>
          ) : undefined}
        </RightSide>
      </Fixed>

      {openModal.status ? (
        <DisplayNavModal
          type={openModal.type}
          close={handleClose}
          openModal={onOpenModal}
          error={error}
        />
      ) : undefined}

      <OatMobileNavigationSlider
        open={openNavSlider}
        onClose={toggleNavSlider}
      />
    </NavPanel>
  );
};

const NavPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${userDashboardNavPanelHeight}px;
  z-index: 100;
  background: rgb(255 255 255 / 60%);
  backdrop-filter: blur(4px);
`;

const Fixed = styled.div`
  // position: fixed;
  // top: 0;
  width: 100%;
  height: ${userDashboardNavPanelHeight}px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 1px 1px rgb(46 41 41 / 10%);
  .title-oatall {
    font-size: 1rem;
    padding-left: 48px;
    cursor: pointer;
    @media only screen and (max-width: 849px) {
      padding-left: 26px;
    }
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 28px;
  height: 48px;
`;

const RightSide = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 8px;
`;

const WrapIcons = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5.6%;
  @media only screen and (min-width: 1000px) {
    padding-right: 6.2rem;
  }
`;

const Section = styled.div`
  font-size: 0.85rem;
  padding: 1rem;
  cursor: pointer;
`;

const Item = styled.div`
  padding: 0px 5px;
  position: relative;
  user-select: none;
  a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
  .upgrade-to-pro {
    background-image: linear-gradient(75deg, #e19df8, #6686ea);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
`;

const ProfilePopper = styled.div`
  width: 200px;
  height: 128px;
  padding-top: 12px;
  position: absolute;
  background: #fff;
  top: 30px;
  right: 0;
  box-shadow: rgb(130 127 141) 0px 0px 1.5px 0px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const WrapSvg = styled.div`
  width: 18px;
  height: 18px;
`;

const GradientText = styled.p`
  font-weight: 700;
  background-image: linear-gradient(75deg, #cf83ba, rgb(106 137 227));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Avatar = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
`;

const MobileLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${window.innerHeight}px;
  z-index: 999;
  background: rgb(255 255 255 / 90%);
  backdrop-filter: blur(4px);
`;

const BackButton = styled.div`
  width: 22px;
  height: 22px;
  margin: 1.2rem;
  fill: #4a4a4a;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
    workspaceModal: selectDashboardModal(state.userDashboard),
  };
};

export default connect(mapStateToProps, {
  dispatchSignOut,
  createWorkspaceSuccess,
})(UserDashboardNav);
