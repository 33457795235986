import {isTouchEvent} from "./isTouchEvent";

export const calcGradient = (e, hsl, panel) => {
  if (panel) {
    const {left: orgPanelX, top: orgPanelY} = panel?.getBoundingClientRect();

    const width = panel.clientWidth;
    const height = panel.clientHeight;

    const clientX = isTouchEvent(e) ? e.touches[0].clientX : e.clientX;
    const clientY = isTouchEvent(e) ? e.touches[0].clientY : e.clientY;

    let newXOffset = clientX - (orgPanelX + window.pageXOffset);
    let newYOffset = clientY - (orgPanelY + window.pageYOffset);

    if (newXOffset < 0) {
      newXOffset = 0;
    } else if (newXOffset > width) {
      newXOffset = width;
    }
    const s = newXOffset / width;

    if (newYOffset < 0) {
      newYOffset = 0;
    } else if (newYOffset > height) {
      newYOffset = height;
    }
    const v = 1 - newYOffset / height;

    return {
      h: hsl.h,
      s,
      v,
      a: hsl.a,
      source: "hsv",
    };
  }
};
