import React from "react";
import styled from "styled-components";

export const AlphaPointer = () => {
  return <Pointer />;
};

const Pointer = styled.div`
  width: 4px;
  height: 14px;
  background-color: #fff;
  box-shadow: rgb(51 50 50) 0px 0px 2px;
`;

export default AlphaPointer;
