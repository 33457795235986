import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {Button, ButtonSpinner, Modal} from "../../../../../oat-ui";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import CreateButton from "../../../reuse/CreateButton";
import styled, {css} from "styled-components";
import {
  BottomSection,
  Cancel,
  Footer,
  Header,
} from "../../../reuse/dashboardUI/modalStyles";
import {grey4, greyText1, paleGreen1} from "../../../../../oat-color-variables";
import {ErrorMessage, LibraryErrMsg} from "../userLibraryStyles";
import {imageUploadSizeLimits} from "../../../../../oat-utility/variables/uploadSizeLimits";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import RequiresUpgradeToPro from "../../../reuse/requiresUpgradeToPro/RequiresUpgradeToPro";
import { useParams } from "react-router-dom";

const previewPanelHeight = 300;
const UploadImage = ({user, workspace, onClose, onCreated}) => {
  const inputFileRef = useRef(null);
  const windowSize = getWindowSize();
  const [uploading, setUploading] = useState({
    status: false,
    openModal: false,
    error: {
      status: false,
      message: null,
    },
  });
  const [image, setImage] = useState({
    file: null,
    preview: "",
    name: "",
    description: "",
  });
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [requiresUpgrade, setRequiresUpgrade] = useState(false);
  let params = useParams();
  const {folderID} = params;

  const onInitUploadImg = () => {
    if (workspace.plan === "free") {
      setRequiresUpgrade(true);
    } else {
      setImage({
        file: null,
        preview: null,
        name: "",
        description: "",
      });
      inputFileRef.current.click();
    }
  };

  const onFileUpload = async (event) => {
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
    // 10 MB
    if (event.target.files[0].size > imageUploadSizeLimits) {
      setError({
        status: true,
        message: "File size cannot be bigger than 10MB.",
      });
      event.target.value = null;
    } else {
      setUploading({
        ...uploading,
        openModal: true,
      });

      setImage({
        file: event.target.files[0],
        preview: null,
        name: "",
        description: "",
      });
    }
    // event.target.value = null;
  };

  const handleInputChange = (e, inputType) => {
    const {value} = e.target;
    setImage({
      ...image,
      [inputType]: value,
    });
  };

  const handleImgNameChange = (e) => {
    handleInputChange(e, "name");
  };

  const handleImgDescriptionChange = (e) => {
    handleInputChange(e, "description");
  };

  useEffect(() => {
    if (!image.file) {
      setImage({
        file: null,
        preview: null,
        name: null,
        description: null,
      });
      return;
    }

    if (image.file !== null && image.preview === null) {
      const objectUrl = URL.createObjectURL(image.file);
      setImage({
        ...image,
        preview: objectUrl,
      });
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [image.file]);

  const handleUpload = async () => {
    if (workspace?.ID && !uploading.status && !requiresUpgrade) {
      setUploading({
        ...uploading,
        status: true,
      });
      if (image.file) {
        let format = image.file.type.split("image/")[1];
        if (format === "svg+xml") {
          format = "svg";
        }
        const img = new Image();
        let fileReader = new FileReader();
        let imgSize = {w: 0, h: 0};

        fileReader.onload = () => (img.src = fileReader.result);
        fileReader.onerror = () => {
          setError({
            status: true,
            message: "Couldn't identify image size.",
          });
        };
        fileReader.readAsDataURL(image.file);

        img.onload = async () => {
          imgSize = {
            w: img.width,
            h: img.height,
          };

          const {url, fields, error, message} = await fetch(
            `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace.ID}/images`,
            {
              method: "POST",
              body: JSON.stringify({
                contentType: image.file.type,
                format,
                type: "image",
                fileSize: image.file.size,
                workspaceID: workspace.ID,
                imgSize,
                imgInfo: {
                  name: image.name,
                  description: image.description,
                },
                uploadToLibrary: true,
                // folderID images -> default library folder, doesn't require folderID
                folderID: folderID !== 'images' ? folderID : undefined
              }),
            }
          )
            .then((response) => response.json())
            .catch((e) => {
              setUploading({
                status: false,
                openModal: true,
                error: {
                  status: true,
                  message: message,
                },
              });
            });

          const data = {
            bucket: "oat-users",
            ...fields,
            "Content-Type": image.file.type,
            file: image.file,
          };

          const formData = new FormData();
          for (const name in data) {
            formData.append(name, data[name]);
          }

          if (!error && url) {
            await fetch(url, {
              method: "POST",
              body: formData,
            })
              .then(async () => {
                URL.revokeObjectURL(image.preview);
                setUploading({
                  status: false,
                  openModal: false,
                  error: {
                    status: false,
                    message: null,
                  },
                });
                setImage({
                  file: null,
                  preview: null,
                  name: "",
                  description: "",
                });
                await onCreated();
              })
              .catch((e) => {});
          } else {
            setUploading({
              status: false,
              openModal: true,
              error: {
                status: true,
                message,
              },
            });
          }
        };
      }
    }
  };

  const handleModalClose = () => {
    setUploading({
      status: false,
      openModal: false,
      error: {
        status: false,
        message: null,
      },
    });
    setError({
      status: false,
      message: null,
    });
    onClose();
  };

  const onCloseRequiresUpgradeModal = () => {
    setRequiresUpgrade(false);
  };

  return (
    <>
      <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
        <CreateButton
          title={windowSize.width >= 850 ? "Upload Image" : "Upload"}
          onClick={onInitUploadImg}
        />
        <input
          ref={inputFileRef}
          value={""}
          type="file"
          onChange={onFileUpload}
          hidden
        />
      </div>

      {error.status ? (
        <Modal width={360} height="auto" close={handleModalClose}>
          <ErrorMessage>{error.message}</ErrorMessage>
        </Modal>
      ) : undefined}

      {uploading.openModal ? (
        <Modal width={640} height="auto" close={handleModalClose}>
          <Header style={{margin: "12px 0 0px 20px"}}>Upload Media</Header>
          <WrapModalContents>
            <ImageInfo>
              <PreviewPanel>
                <PreviewImage src={image.preview} />
              </PreviewPanel>
              <Form>
                <Field>
                  <p>Image Name (Optional)</p>
                  <Input
                    type="text"
                    value={image.name}
                    onChange={handleImgNameChange}
                    spellCheck="false"
                    autoFocus
                  />
                </Field>

                <Field>
                  <p>Short Description (Optional)</p>
                  <Textarea
                    type="text"
                    value={image.description}
                    onChange={handleImgDescriptionChange}
                    spellCheck="false"
                    resize="false"
                  />
                </Field>

                {uploading.error.status ? (
                  <LibraryErrMsg>{uploading.error.message}</LibraryErrMsg>
                ) : undefined}
              </Form>
            </ImageInfo>

            <Footer>
              <BottomSection
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Cancel onClick={handleModalClose}>Cancel</Cancel>
                <Button
                  styles={{width: 120}}
                  onClick={handleUpload}
                  disabled={uploading.status}
                >
                  {!uploading.status ? "Create" : <ButtonSpinner />}
                </Button>
              </BottomSection>
            </Footer>
          </WrapModalContents>
        </Modal>
      ) : undefined}

      {requiresUpgrade ? (
        <RequiresUpgradeToPro close={onCloseRequiresUpgradeModal} />
      ) : undefined}
    </>
  );
};

const WrapModalContents = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageInfo = styled.div`
  display: flex;
  height: ${previewPanelHeight + 24}px; // 30 for padding down below
`;

export const PreviewPanel = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${paleGreen1};
  padding: 12px;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const PreviewImage = styled.img`
  max-width: 100%;
  max-height: ${previewPanelHeight}px;
`;

export const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Field = styled.div`
  font-size: 0.85rem;
  width: 100%;
  margin-bottom: 24px;
  p {
    margin-bottom: 8px;
    color: ${grey4};
    font-weight: 700;
  }
`;

const inputStyles = css`
  width: 100%;
  padding-left: 6px;
  color: ${greyText1};
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const Input = styled.input`
  height: 28px;
  ${inputStyles};
`;

export const Textarea = styled.textarea`
  height: 62px;
  resize: none;
  font-family: inherit;
  ${inputStyles};
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(UploadImage);
