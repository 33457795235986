import React, {useEffect, useState} from "react";
import Select from "../../oat-ui/core/Select";
import {selectUserInfo} from "../../../redux/user/authUser";
import {connect} from "react-redux";
import {Button, Modal, Spinner} from "../../oat-ui";
import {useNavigate, useParams} from "react-router-dom";
import {createTemplate} from "./createTemplate";
import {Layout, Header, Item, Field, WrapSelectInput, Footer, WrapButton, Error } from "./styles/formStyles";
import {greyText3} from "../../oat-color-variables";
import { getWindowSize } from "../../oat-window-size/getWindowSize";

const RequestTempLocation = ({
  user,
  selectedLocation,
  onSelectWorkspace,
  onSelectFolder,
  tempInfo,
  onClose
}) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [folders, setFolders] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  let params = useParams();
  let navigate = useNavigate();
  const {workspaceID, workspaceName, folderID, folderName} = selectedLocation;
  const windowSize = getWindowSize();

  useEffect(() => {
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces`
    )
      .then((res) => res.json())
      .then((data) => {
        let workspaceItems = [];
        workspaceItems = [];
        data.map((item) =>
          workspaceItems.push({
            display: item.name,
            value: item.ID,
          })
        );
        setWorkspaces(workspaceItems);
      })
      .catch((e) => {});
  }, []);

  const handleWorkspaceSelection = (item) => {
    onSelectWorkspace(item);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${item.value}/folders`
    )
      .then((res) => res.json())
      .then((data) => {
        let folderItems = [];
        data.designFolders.map((item) =>
          folderItems.push({
            display: item.folderName,
            value: item.folderID,
          })
        );
        setFolders(folderItems);
      })
      .catch((e) => {});
  };

  const handleCreate = async () => {
    if (error.status) {
      setError({status: false, message: null});
    }
    if (!processing) {
      setProcessing(true);
      await createTemplate({
        tempInfo,
        username: user.username,
        folderID,
        workspaceID,
        category: params.category,
        requiresUpgrade: tempInfo.requiresUpgrade
      })
        .then(async(data) => {
          if (data.status === "success") {
            setProcessing(false);
            await sessionStorage.setItem('tempNewlyCreated', JSON.stringify({newlyCreated: true}));
            return await navigate(`/design/${data.tempID}`, {
              state: {newlyLoaded: true},
            });
          } else {
            setError({status: true, message: data.message});
            setProcessing(false);
          }
        })
        .catch((e) => {
          setError({status: true, message: e.message});
          setProcessing(false);
        });
    }
  };

  return (
    <Modal
      top={(10 * windowSize.height) / 100}
      width={420}
      height={260}
      close={onClose}
      overflowX="visible"
      overflowY="visible"
    >
      <Layout>
        <Header>Template Location</Header>

        <Item>
          <Field>Workspace</Field>
          <WrapSelectInput>
            <Select
              onSelect={handleWorkspaceSelection}
              selected={workspaceName}
              options={workspaces}
              height={200}
              overflowY={true}
            />
          </WrapSelectInput>
        </Item>

        <Item>
          <Field>Folder <span style={{fontSize: 13, color: greyText3}}>(Optional)</span></Field>
          <WrapSelectInput>
            <Select
              onSelect={onSelectFolder}
              selected={folderName}
              options={folders}
              height={200}
              overflowY={true}
            /> 
          </WrapSelectInput>
        </Item>
      </Layout>

      {error.status ? <Error>{error.message}</Error> : undefined}

      <Footer style={{ justifyContent: 'flex-end'}}>
        <WrapButton>
          <Button onClick={handleCreate} disabled={processing}>
            Create
          </Button>
        </WrapButton>
      </Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(RequestTempLocation);
