import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {paleBlue} from "../../../../../oat-color-variables";
import {selectTempCategories} from "../../../../store/selectors/adminPanel.selector";

const FilterTemplates = ({categories, onSelectSize}) => {
  return (
    <>
      <Title>Filter By Tags</Title>
      {/* {categories.map((category, i) => (
        <SizeByCategory item={category.size} onSelectSize={onSelectSize} />
      ))} */}
    </>
  );
};

const Title = styled.div`
  font-size: 0.9rem;
  padding: 4px 0 12px;
  font-weight: 600;
  color: ${paleBlue};
`;

const mapStateToProps = (state) => {
  return {
    categories: selectTempCategories(state.adminPanel),
  };
};

export default connect(mapStateToProps, null)(FilterTemplates);
