import React, {useRef, useEffect, useState, useLayoutEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../../../store/selectors/template/template.selector";
// import FontFaceObserver from 'fontfaceobserver';

const ShowLegends = ({tempSize, chartSize, items, colorSet, styles, scale}) => {
  const {legendColor, legendFontSize, fontFamily, legendPosition, showLegend} = styles;
  const legendRef = useRef();
  const [legend, setLegend] = useState([]);
  const [legendSize, setLegendSize] = useState({
    width: 0,
    height: 0,
    gapFromChart: 0,
  });

  // const [isFontLoaded, setIsFontLoaded] = useState(false);
  // useEffect(() => {
  //   const font = new FontFaceObserver(fontFamily);
  //   font.load().then(() => {
  //     setIsFontLoaded(true);
  //   });
  // }, [fontFamily]);

  useLayoutEffect(() => {
    let gapValue; // = tempSize.w > tempSize.h ? 2.5 : 1.5;
    if (tempSize.w > tempSize.h) {
      if (legendPosition === "right") {
        gapValue = 5;
      } else if (legendPosition === "top") {
        gapValue = 2.5;
      }
    } else {
      if (legendPosition === "right") {
        gapValue = 3;
      } else if (legendPosition === "top") {
        gapValue = 1.5;
      }
    }

    if (legendRef && legendRef?.current && legendRef.current?.offsetWidth) {
      // timeout for legend position right 
      // to adjust its width - else it is overlapping chart
      // setTimeout(() => {
        setLegendSize({
          width: legendRef.current?.offsetWidth, // ? !important
          height: legendRef.current?.offsetHeight,
          gapFromChart: (gapValue * tempSize.h) / 100,
        });
      // }, 250);
    }
  }, [
    legendPosition,
    legendFontSize,
    chartSize.w,
    scale,
    tempSize.w,
    legendRef.current?.offsetWidth, // !important
    // isFontLoaded,
    legendSize?.gapFromChart,
    showLegend
  ]);

  useLayoutEffect(() => {
    const legendValues = [];
    items &&
      items.map((item, i) => {
        legendValues.push({
          value: item,
          color: colorSet[i],
        });
      });
    setLegend(legendValues);
  }, [items, colorSet])

  return (
    <Wrapper
      ref={legendRef}
      style={{
        top:
          legendPosition === "right"
            ? 0
            : -legendSize.height - legendSize.gapFromChart,
        width: legendPosition === "right" ? "auto" : "100%", // chartSize.w * scale,
        bottom: legendPosition === "right" ? 0 : "auto", // 0 will place center
        // legendPosition === "right-bottom"
        //   ? 0
        //   : legendPosition === "bottom"
        //   ? -legendSize.height - (34 * tempWidth) / 536
        //   : undefined,
        right:
          legendPosition === "right"
            ? -legendSize.width - legendSize.gapFromChart
            : "auto",
        // legendPosition === "right-top" || legendPosition === "right-bottom"
        //   ? -legendSize.width - (10 * tempWidth) / 536
        //   : undefined,
        flexDirection: legendPosition === "right" ? "column" : "row",
        flexWrap: "wrap",
        display: "flex",
        justifyContent: "center",
        fontFamily,
        // chartSize.w > legendSize.width ? "center" : "flex-start",
      }}
    >
      {legend &&
        legend.map((item, index) => {
          return (
            <WrapLegend
              key={index}
              style={{
                fontSize: `${legendFontSize}em`, // legendFontSize / scale,
                marginBottom:
                  legendPosition === "right" 
                    ? 5 * (tempSize.w / 536)
                    : 0,
                marginRight: legendSize.gapFromChart * 2,
                marginBottom: legendSize.gapFromChart / 2
              }}
            >
              <CategoryColor
                style={{
                  width: "0.8em",
                  height: "0.8em",
                  background: item.color,
                  marginRight: legendSize.gapFromChart / 2,
                }}
              />
              <div style={{color: legendColor}}>{item.value}</div>
            </WrapLegend>
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: auto;
  position: absolute;
  display: flex;
  // pointer-events: none;
`;

const CategoryColor = styled.div`
  min-width: 2px;
  min-height: 2px;
`;

const WrapLegend = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const mapStateToProps = (state) => {
  return {
    scale: selectTempScale(state.designTemplate),
    tempSize: selectTemplateSize(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(ShowLegends);

// import React, {useRef, useEffect, useState, useCallback} from "react";
// import {connect} from "react-redux";
// import {
//   selectTemplateSize,
//   selectTempScale,
// } from "../../../../../../../store/selectors/template/template.selector";

// const ShowLegends = ({
//   items,
//   chartSize,
//   styles,
//   tempWidth,
//   tempScale,
//   colorSet,
// }) => {
//   const {legendColor, legendFontSize, legendPosition, fontFamily} = styles;
//   const legendRef = useRef();
//   const [legendSize, setLegendSize] = useState({
//     width: 0,
//     height: 0,
//   });
//   const [legends, setLegends] = useState([]);
//   const [getLegendSize, setGetLegendSize] = useState(false);

//   const getLegends = useCallback(() => {
//     const legendValues = [];
//     items &&
//       items.map((item, i) => {
//         legendValues.push({
//           value: item,
//           color: colorSet[i],
//         });
//       });
//     setLegends(legendValues);
//     setGetLegendSize(true);
//   }, [items, colorSet]);

//   useEffect(() => {
//     getLegends();
//   }, [items, colorSet]);

//   const labelPadding = (tempWidth * 2) / 100;
//   const iconWidth = 15 + (tempWidth * 0.5) / 100;
//   const [iconSize, setIconSize] = useState(0);

//   useEffect(() => {
//     if (legendRef && legendRef.current && getLegendSize) {
//       let updateLegends = [];
//       let totalWidth = 0,
//         totalHeight = 0;
//       const childText = legendRef.current.children;

//       // line splits when legend size is bigger than chart size
//       let splitLine = {
//         currentLine: 1,
//         lines: [0],
//       };

//       for (let i = 0; i < legendRef.current.children.length; i++) {
//         // legend content width
//         const itemWidth =
//           childText.item(i).firstChild.scrollWidth + labelPadding + iconWidth;
//         const itemHeight = childText.item(0).firstChild.clientHeight;

//         if (i === 0) setIconSize(childText.item(0).firstChild.clientHeight);

//         if (legendPosition === "top") {
//           //  if the legend item content is larger than chart width
//           // break another line
//           if (
//             splitLine.lines[splitLine.currentLine - 1] + itemWidth >
//             chartSize.w
//           ) {
//             const currentLine = splitLine.currentLine + 1;
//             if (splitLine.lines[currentLine] + itemWidth > chartSize.w) {
//               const dropLine = splitLine.currentLine++;
//               splitLine.currentLine = splitLine.currentLine + 1;
//               updateLegends.push({
//                 ...legends[i],
//                 width: itemWidth,
//                 height: totalHeight,
//                 x: splitLine.lines[dropLine] ? splitLine.lines[dropLine] : 0,
//                 y:
//                   (itemHeight + labelPadding / 1.5) * -splitLine.lines.length -
//                   1,
//                 // labelPadding * -splitLine.lines.length - 1,
//               });
//               // check if data exists, if not, it is the initial breaking point and only record its width,
//               // else add its width to the previous item's width
//               splitLine.lines[dropLine] = splitLine.lines[dropLine]
//                 ? splitLine.lines[dropLine] + itemWidth
//                 : itemWidth;
//             } else {
//               updateLegends.push({
//                 ...legends[i],
//                 width: itemWidth,
//                 height: totalHeight,
//                 x: splitLine.lines[currentLine]
//                   ? splitLine.lines[currentLine]
//                   : 0,
//                 y: (itemHeight + labelPadding / 1.5) * -currentLine,
//               });
//               // check if data exists, if not, it is the initial breaking point and only record its width,
//               // else add its width to the previous item's width
//               splitLine.lines[currentLine] =
//                 itemWidth && splitLine.lines[currentLine]
//                   ? splitLine.lines[currentLine] + itemWidth
//                   : itemWidth;
//             }
//           }
//           // initial without needing to break the line
//           else {
//             totalWidth = totalWidth + itemWidth;
//             totalHeight = childText.item(i).firstChild.scrollHeight;
//             updateLegends.push({
//               ...legends[i],
//               width: itemWidth,
//               height: totalHeight,
//               x: i === 0 ? 0 : totalWidth - itemWidth,
//               y:
//                 legendPosition === "right"
//                   ? (itemHeight + labelPadding / 2) * -1
//                   : -itemHeight - labelPadding / 1.5,
//             });
//             if (itemWidth) {
//               splitLine.lines[0] = splitLine.lines[0] + itemWidth;
//             }
//           }
//         } else if (legendPosition === "right") {
//           const textHeight =
//             childText.item(0).firstChild.clientHeight + labelPadding;

//           updateLegends.push({
//             ...legends[i],
//             width: itemWidth,
//             height: textHeight, // itemHeight,
//             x: chartSize.w + labelPadding,
//             y:
//               (chartSize.h - textHeight * legends.length) / 2 +
//               (textHeight + 20) * i + // in the place of 20 using another + textHeight seems bettter but cause problem between chrome and firefox, creating gaps
//               1,
//           });
//         }
//       }
//       setLegends(updateLegends);
//       setLegendSize({
//         width: totalWidth,
//         height: totalHeight, //childText.item(0).firstChild.scrollHeight,
//         noOfSplitLines: splitLine.lines.length,
//       });
//       setGetLegendSize(false);
//     }
//   }, [legendRef.current, getLegendSize, chartSize, legendFontSize, tempScale]);

//   // const iconSize = 12 / tempScale; //legendSize.height; //18;
//   return (
//     <g ref={legendRef}>
//       {legends &&
//         legends.map((item, index) => {
//           const x =
//             legendSize.noOfSplitLines >= legends.length
//               ? 0
//               : legendPosition === "right"
//               ? item.x
//               : chartSize.w > legendSize.width
//               ? (chartSize.w - legendSize.width) / 2 + item.x
//               : item.x;

//           return (
//             <g key={index}>
//               <text
//                 style={{
//                   fontSize: legendFontSize,
//                   fontFamily,
//                   fill: legendColor,
//                   transform: `translate(${x + iconSize}px, ${item.y}px)`,
//                 }}
//               >
//                 <tspan
//                   style={{
//                     textAnchor: "initial",
//                     alignmentBaseline: "unset", // unset
//                     dominantBaseline: "unset",
//                   }}
//                 >
//                   {item.value}
//                 </tspan>
//               </text>
//               <rect
//                 x={x ? x - iconSize / 3 : 0}
//                 y={
//                   item.y ? item.y - iconSize : 0
//                   // item.y ? item.y - iconSize / 2 - item.height / 2 : 0
//                   // item.y ? item.y - iconSize / 2 - item.height / 2 : 0
//                   // item.y
//                   //   ? item.y - iconSize / 2 - item.height / 2
//                   //   : legendPosition === "right"
//                   //   ? 0
//                   //   : item.y
//                   //   ? -item.height / 2
//                   //   : 0
//                 }
//                 width={iconSize}
//                 height={iconSize}
//                 fill={item.color}
//                 rx={iconSize / 2}
//                 ry={iconSize / 2}
//               ></rect>
//             </g>
//           );
//         })}
//     </g>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     tempWidth: selectTemplateSize(state.designTemplate).w,
//     tempScale: selectTempScale(state.designTemplate),
//   };
// };

// export default connect(mapStateToProps, null)(ShowLegends);
