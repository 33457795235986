import React, {useEffect, useState} from "react";
import DropDown from "../../../../../../../ui/inputs/DropDown";
import {connect} from "react-redux";
import {
  selectActiveField,
  selectActiveFieldStyles,
} from "../../../../../../../store/selectors/fields.selector";
import styled from "styled-components";
import {updateFieldStyles} from "../../../../../../../store/actions/fields/common.action";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {getWindowSize} from "../../../../../../../../oat-window-size/getWindowSize";
import SelectionBox from "../../../mobile/ui/SelectionBox";
import {InputName} from "../../common/WrapColorStyles";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";

const formatList = [
  {display: "1000", value: "1000"},
  {display: "1,000", value: "1,000"},
  {display: "1k", value: "1k"}, // compact
];

const unitsOption = [
  {display: "Number", value: "number"},
  {display: "Percentage", value: "percentage"},
  {display: "Currency", value: "currency"},
];

const ScaleTypeFormat = ({
  slideID,
  fieldID,
  scaleFormat,
  scaleType,
  currencyUnit,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const [currencyInput, setCurrencyInput] = useState(currencyUnit);
  const active = {slideID, fieldID};

  const windowSize = getWindowSize();

  useEffect(() => {
    setCurrencyInput(currencyUnit);
  }, [currencyUnit]);

  const changeScaleUnit = (value) => {
    updateFieldStyles({
      active,
      type: "scaleType",
      value,
    });
    handleSocketEmit("scaleType", value);
  };

  const onChangeCurrencySign = (e) => {
    const item = e.target.value;
    setCurrencyInput(item);
    updateFieldStyles({
      active,
      type: "currencyUnit",
      value: item,
    });
  };

  const onBlurCurrencySign = (e) => {
    const item = e.target.value;
    handleSocketEmit("currencyUnit", item);
  };

  const changeFormat = (value) => {
    updateFieldStyles({
      active,
      type: "scaleFormat",
      value,
    });
    handleSocketEmit("scaleFormat", value);
  };

  const handleSocketEmit = (type, value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <>
      {windowSize.width >= 850 ? (
        <>
          <div style={{display: "flex", padding: "2em 0"}}>
            <InputName>Scale Unit</InputName>
            <WrapDropDown>
              <DropDown
                onSelect={changeScaleUnit}
                selected={scaleType}
                options={unitsOption}
                minWidth={142}
                boxWidth={140}
                overflowY="scroll"
                height="auto"
              />
            </WrapDropDown>
          </div>

          {scaleType === "currency" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "2em",
              }}
            >
              <InputName>Currency Sign</InputName>
              <WrapInput>
                <Input
                  type="text"
                  placeholder=""
                  value={currencyInput}
                  onChange={onChangeCurrencySign}
                  onBlur={onBlurCurrencySign}
                  maxLength={4}
                />
              </WrapInput>
            </div>
          ) : undefined}

          <div style={{display: "flex", paddingBottom: "2em"}}>
            <InputName>Format</InputName>
            <WrapDropDown>
              <DropDown
                onSelect={changeFormat}
                selected={scaleFormat}
                options={formatList}
                minWidth={142}
                boxWidth={140}
                overflowY="scroll"
                height="auto"
              />
            </WrapDropDown>
          </div>
        </>
      ) : (
        <>
          <div style={{margin: "2em 0"}}>
            <SelectionBox
              title="Scale Unit"
              selected={scaleType}
              onSelect={changeScaleUnit}
              options={unitsOption}
            />
          </div>

          {scaleType === "currency" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "2em",
              }}
            >
              <InputName
                style={{
                  paddingLeft: window.innerWidth < 850 ? 0 : undefined,
                }}
              >
                Currency Sign
              </InputName>
              <WrapInput>
                <Input
                  type="text"
                  placeholder=""
                  value={currencyInput}
                  onChange={onChangeCurrencySign}
                  onBlur={onBlurCurrencySign}
                  maxLength={4}
                />
              </WrapInput>
            </div>
          ) : undefined}

          <SelectionBox
            title="Format"
            selected={scaleFormat}
            onSelect={changeFormat}
            options={formatList}
          />
        </>
      )}
    </>
  );
};

const WrapInput = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
  @media only screen and (max-width: 850px) {
    flex: 1.2;
  }
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 0.9rem;
`;

const Input = styled.input`
  width: 122px;
  height: 30px;
  color: ${({theme}) => theme.deskPrimaryTxt};
  background: ${({theme}) => theme.deskSecondaryBg2};
  border-radius: 8px;
  padding: 4px 0 0 14px;
  box-shadow: ${({theme}) => theme.boxShadow1};
  border: none;
  @media only screen and (max-width: 850px) {
    background: ${({theme}) => theme.deskSecondaryBg};
  }
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    scaleType: selectActiveFieldStyles(state.designTemplate)?.scaleType,
    scaleFormat: selectActiveFieldStyles(state.designTemplate)?.scaleFormat,
    currencyUnit: selectActiveFieldStyles(state.designTemplate)?.currencyUnit,
  };
};

export default connect(mapStateToProps, {
  storeFieldUndoRedo,
  updateFieldStyles,
  updateFieldCollaboration,
})(ScaleTypeFormat);
