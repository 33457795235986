import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {createPortal} from "react-dom";
import {detectClickOutside} from "../../../../../../../oat-ui";

const ColorPickerPopper = ({children, onClose, width, offset}) => {
  const popUpRef = useRef(null);
  const [newOffset, setNewOffset] = useState({
    x: null,
    y: null,
  });

  useEffect(() => {
    let newXOffset = null,
      newYOffset = null;
    if (window.innerWidth < offset?.x + width) {
      const availableX = window.innerWidth - offset?.x;
      newXOffset = width + 15 - availableX;
    } else {
      newXOffset = 0;
    }

    if (window.innerHeight < offset?.y + popUpRef.current.offsetHeight) {
      const availableY = window.innerHeight - offset?.y;
      newYOffset = popUpRef.current.offsetHeight + 15 - availableY;
    } else {
      newYOffset = 20;
    }
    setNewOffset({x: offset?.x - newXOffset, y: offset?.y - newYOffset});
  }, []);

  detectClickOutside(popUpRef, () => onClose());

  return createPortal(
    <Wrapper
      style={{left: newOffset?.x, top: newOffset?.y, width}}
      ref={popUpRef}
    >
      <Content>{children}</Content>
    </Wrapper>,
    document.getElementById("popUp-box")
  );
};

const Wrapper = styled.div`
  position: absolute;
  border-radius: 14px;
  z-index: 9999;
  height: auto;
  overflow: hidden;
  box-shadow: ${({theme}) => theme.boxShadow1}; // 0px 0px 3px 0px #f0eaff;
`;

const Content = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  padding-right: 17px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

ColorPickerPopper.defaultProps = {
  width: 260,
  offset: {
    x: 0,
    y: 0,
  },
};

export default ColorPickerPopper;
