import React from "react";
import TempThemeList from "../../components/templateThemes/TempThemeList";
import Hoc from "../../hoc/Hoc";

const TempThemes = () => {
  return (
    <Hoc>
      <div style={{width: "100%", height: "100vh"}}>
        <TempThemeList />
      </div>
    </Hoc>
  );
};

export default TempThemes;
