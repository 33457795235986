import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import {WrapColorBox} from "../common/WrapColorStyles";
import {dispatchIconColorUpdate} from "../../../../../../store/actions/fields/icon.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import { Title } from "../../shared/reuseStyles/Title";

const DisplayItem = ({
  onChange,
  onEmitValue,
  item,
  outerIndex,
  innerIndex,
}) => {
  const handleClick = (value) => {
    onChange({outerIndex, innerIndex, value});
    
  };

  const onColorDebounce = (value) => {
    onEmitValue({outerIndex, innerIndex, value});
  };

  return (
    <WrapColorBox style={{margin: "0px 12px 0px 0"}}>
      <PopoverColorBox
        value={item.color}
        updateFieldStyles={handleClick}
        onDebounce={onColorDebounce}
      />
    </WrapColorBox>
  );
};

const EditIconSet = ({
  activeField,
  slideID,
  dispatchIconColorUpdate,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const {colorSet} = activeField || {};

  const handleUpdate = ({outerIndex, innerIndex, value}) => {
    dispatchIconColorUpdate({
      active: {slideID, fieldID: activeField?.key},
      row: outerIndex,
      column: innerIndex,
      value,
    });
  };

  const handleDebounceValue = async ({outerIndex, innerIndex, value}) => {
    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  return (
    <ColorSet>
      {colorSet &&
        colorSet.map((item, outerIndex) => {
        return(
        <div style={{display: 'flex', width: '100%'}} key={outerIndex}>
          {colorSet.length > 1 ?
            <WrapTitle>
              <Title>{item?.name}</Title>
            </WrapTitle> 
          : <WrapTitle>
              <Title>Icon</Title>
            </WrapTitle> 
          }
          <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}>
            {item?.colors &&
              item?.colors.map((color, innerIndex) => {
                return (
                  <Category key={innerIndex}>
                    <DisplayItem
                      outerIndex={outerIndex}
                      innerIndex={innerIndex}
                      onChange={handleUpdate}
                      onEmitValue={handleDebounceValue}
                      item={color}
                    />
                  </Category>
                );
            })}
          </div>
        </div>
        )
      })}
    </ColorSet>
  );
};

const WrapTitle = styled.div`
  flex: 1;
`

const Category = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media only screen and (max-width: 849px) {
    margin-bottom: 12px;
  }
`;

const ColorCategory = styled.div`
  width: 140px;
  text-transform: capitalize;
`;

const ColorSet = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0.5rem 0rem 0;
  @media only screen and (max-width: 849px) {
    padding: 0.5rem 0rem 0;
  }
`;

const mapStateToProps = (state) => {
  return {
    activeField: selectActiveFieldProps(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  dispatchIconColorUpdate,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditIconSet);
