import React, {useState, useEffect, useRef, useCallback} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  createDesignSwitchTab,
  fetchOatTemplateList,
} from "../../../../store/actions/template/create/templateList.action";
import {
  selectTemplateCategory,
  selectTemplateRatio,
  selectTemplateSelection,
  selectTemplateSize,
} from "../../../../store/selectors/template/template.selector";
import ChooseTemplateCollection from "./ChooseTemplateCollection";
import DesignListOptions from "../../modal/DesignListOptions";
import {createSlideFromTemplate} from "../../../../store/actions/template/slide.action";
import ProjectDirectory from "./projectDirectory/ProjectDirectory";
import DirectoryPopper from "./projectDirectory/DirectoryPopper";
import LayoutsTemplate from "./layouts/LayoutsTemplate";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import {loadUsedFonts} from "../../../../utils/loadUsedFonts";
import { selectUserInfo } from "../../../../../../redux/user/authUser";

const NewDesignList = ({
  fetchOatTemplateList,
  createDesignSwitchTab,
  selection,
  createSlideFromTemplate,
  tempSize,
  ratio,
  category,
  username
}) => {
  const [openPopper, setOpenPopper] = useState({
    status: false,
    offset: {x: 0, y: 0},
  });
  const [selectedFolder, setSelectedFolder] = useState(null);
  const dirLayoutRef = useRef();
  const windowSize = getWindowSize();

  const onSelectChosenTemplate = (e) => {
    e.stopPropagation();
    createDesignSwitchTab("design-list");
  };

  const onCreateSlide = (slide) => {
    createSlideFromTemplate(slide);
  };

  const togglePopper = (e) => {
    if (dirLayoutRef.current) {
      const {x, y, width} = dirLayoutRef.current.getBoundingClientRect();
      setOpenPopper({
        status: !openPopper.status,
        offset: {x: x - (200 - width), y: y + 62},
      });
    }
  };

  const closePopper = () => {
    setOpenPopper({
      status: !openPopper.status,
      offset: {x: 0, y: 0},
    });
  };

  // const onSelectTemplateList = useCallback(()=> {
  //   if (selection.activeTab) {
  //     createDesignSwitchTab("template-collection");
  //     fetchOatTemplateList({
  //       api: `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category}/${ratio}/en?tag=`,
  //       pagniate: false
  //     });
  //   }
  // }, [selection.activeTab]);
  const onSelectTemplateList = () => {
    createDesignSwitchTab("template-collection");
    fetchOatTemplateList({
      api: `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category}/${ratio}/en?tag=`,
      pagniate: false
    });
  };

  const onSelectLayouts = () => {
    createDesignSwitchTab("layouts");
  };

  const onSelectFolder = (folderID) => {
    setSelectedFolder(folderID);
  };

  const [tempColumns, setTempColumns] = useState(3);
  useEffect(() => {
    if (windowSize.width >= 850) {
      if (selection.size.w < selection.size.h) {
        setTempColumns(4);
      }
      setTempColumns(3);
    } else {
      setTempColumns(2);
    }
  }, [selection.size, windowSize.width]);

  useEffect(() => {
    if (window.location.href.includes("/admin/designs/")) {
      createDesignSwitchTab("layouts");
    } else if (window.location.href.includes("/design/playground")) {
      createDesignSwitchTab("template-collection");
      onSelectTemplateList();
    }
  }, [username]);

  useEffect(() => {
    if(selection?.usedFontList)
      loadUsedFonts(selection.usedFontList);
  }, [selection.usedFontList]);

  const goBackToTempList = () => {
    createDesignSwitchTab("template-collection");
  }

  return (
    <Layout>
      <Navigation>
        {/* <Breadcrumbs>
          <Item onClick={selectTempTab}>Templates</Item>
          {selection.activeTab === "design-list" ? (
            <>
              <span> / </span>
              <Item>Designs</Item>
            </>
          ) : undefined}
        </Breadcrumbs> */}

        <NavItems>
          {!window.location.href.includes("/admin/designs/") ? (
            <>
              {!window.location.href.includes("/design/playground")? 
                <NavItem
                  onClick={onSelectChosenTemplate}
                  active={selection.activeTab === "design-list"}
                >
                  Chosen Template
                </NavItem> 
              : undefined}
              <NavItem
                onClick={onSelectTemplateList}
                active={
                  selection.activeTab === "template-collection" ||
                  selection.activeTab === "slides-from-template-list"
                }
              >
                Template List
              </NavItem>
            </>
          ) : undefined}

          {(tempSize.w === 1920 && tempSize.h === 1080) ?
            <NavItem
              onClick={onSelectLayouts}
              active={selection.activeTab === "layouts"}
            >
              Layouts
            </NavItem> 
          : undefined}
        </NavItems>

        {windowSize.width >= 850 && username !== null ? (
          <WrapDirectory>
            <Directory ref={dirLayoutRef} onClick={togglePopper}>
              / Folder
            </Directory>
          </WrapDirectory>
        ) : undefined}
      </Navigation>

      {openPopper.status ? (
        <DirectoryPopper onClose={closePopper}>
          <ProjectDirectory
            onSelectFolder={onSelectFolder}
            onClose={closePopper}
          />
        </DirectoryPopper>
      ) : undefined}

      {/* oat all template list */}
      {selection.activeTab === "design-list" ? (
        // current selected template list
        <DesignListOptions
          slides={selection.chosenTempDesigns}
          orgTempSize={selection.size}
          onSelectSlide={onCreateSlide}
          noOfColumns={tempColumns}
        />
      ) : undefined}
      
      {selection.activeTab === "template-collection" || selection.activeTab === "slides-from-template-list" ? (
        <>
          <div style={{ 
            visibility: selection.activeTab === "template-collection" ? "visible" : "hidden",
            zIndex: selection.activeTab === "template-collection" ? 999 : 0
          }}> 
            <ChooseTemplateCollection
              templates={selection.templates}
              paginationToken={selection.lastEvaluatedKey}
              loading={selection.loading}
              userMadeTemplates={selection.userMadeTemplates}
              tempSize={
                selection.userMadeTemplates ? {w: 1920, h: 1080} : selection.size
              } //selection.size
              noOfColumns={tempColumns}
              selectedFolder={selectedFolder}
            />
          </div>
          
          <div style={{ 
            visibility: selection.activeTab === "slides-from-template-list" ? "visible" : "hidden",
            zIndex: selection.activeTab === "slides-from-template-list" ? 999 : 0,
            position: 'absolute',
            top: 50,
            width: '100%'
          }}>
            <DesignListOptions
              slides={selection.designs}
              orgTempSize={selection.size}
              onSelectSlide={onCreateSlide}
              noOfColumns={tempColumns}
              enableBackButton={true}
              goBack={goBackToTempList}
            />
          </div>
        </>
      ) : undefined}

      {selection.activeTab === "layouts" ? (
        <LayoutsTemplate
          isLayoutTab={selection.activeTab === "layouts"}
          loading={selection.loading}
        />
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 999;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 8px 12px;
  color: ${({theme}) => theme.deskPrimaryTxt};
  background: ${({theme}) => theme.deskModalBg};
  position: sticky;
  top: 0;
  margin-bottom: 12px;
  z-index: 999;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 849px) {
    padding: 12px 0;
  }
`;

const Breadcrumbs = styled.div`
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  flex: 1;
  align-items: center;
  height: 36px;
  z-index: 10;
  box-sizing: border-box;
  span {
    padding: 0 12px;
  }
`;

const WrapDirectory = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.97rem;
`;

const Directory = styled.div`
  width: 170px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 4px 12px;
  box-sizing: border-box;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  cursor: pointer;
`;

const NavItems = styled.div`
  display: flex;
  font-size: 0.92rem;
  width: auto;
  white-space: pre;
  user-select: none;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 22px;
  margin-right: 18px;
  background: ${({theme, active}) =>
    active ? theme.deskSecondaryBg4 : theme.deskSecondaryBg2};
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
`;

const mapStateToProps = (state) => {
  const {designTemplate, authUser} = state;
  return {
    selection: selectTemplateSelection(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    ratio: selectTemplateRatio(designTemplate),
    category: selectTemplateCategory(designTemplate),
    username: selectUserInfo(authUser).username,
  };
};

export default connect(mapStateToProps, {
  fetchOatTemplateList,
  createDesignSwitchTab,
  createSlideFromTemplate,
})(NewDesignList);
