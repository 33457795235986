import BlogList from "./blog/BlogList";
import BlogDetails from "./blog/BlogDetails";
import Faq from "./faq/Faq";
import Templates from "./templates/Templates";
import Pricing from "./pricing/Pricing";
import Home from "./home/Home";
import AdminScreenshot from "./system/screenshot/AdminScreenshot";
import UserScreenshot from "./system/screenshot/UserScreenshot";
import ShowError from "./pages/ShowError";
import {
  OatAboutUs,
  OatPrivacyPolicy,
  OatRequestLogin,
  OatTermsOfUse,
  OatUnknownRoute,
} from "../../packages/oat-public";
import ImagesIcons from "./imagesIcons/ImagesIcons";
import TempDetails from "./templates/TempDetails";

export default [
  {
    routeProps: {
      path: "/",
      exact: true,
      element: <Home />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Oat All",
  },
  {
    routeProps: {
      path: "/about-us",
      exact: true,
      element: <OatAboutUs />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "About Us",
  },
  {
    routeProps: {
      path: "/templates/:category/:ratio",
      exact: true,
      element: <Templates />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Templates",
  },
  {
    routeProps: {
      path: "/templates/:category/:ratio/:id",
      exact: true,
      element: <TempDetails />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Templates",
  },
  {
    routeProps: {
      path: "/illustrations",
      exact: true,
      element: <ImagesIcons />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Illustrations",
  },
  {
    routeProps: {
      path: "/faq",
      exact: true,
      element: <Faq />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "FAQ",
  },
  {
    routeProps: {
      path: "/blogs",
      exact: true,
      element: <BlogList />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Blog List",
  },
  {
    routeProps: {
      path: "/blogs/:id",
      exact: true,
      element: <BlogDetails />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Blog Details",
  },
  {
    routeProps: {
      path: "/pricing",
      exact: true,
      element: <Pricing />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Pricing",
  },
  {
    routeProps: {
      path: "/backend/screenshot/:screenshot/:username/:tempID",
      exact: true,
      element: <UserScreenshot />,
    },
    restricted: {
      status: false,
      role: "",
    },
    name: "User Screenshot",
  },
  {
    routeProps: {
      path: "/backend/admin/screenshot/:screenshot/:tempID",
      exact: true,
      element: <AdminScreenshot />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Admin Screenshot",
  },
  {
    routeProps: {
      path: "/error",
      exact: true,
      element: <ShowError />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Errroor...",
  },
  {
    routeProps: {
      path: "/404",
      exact: true,
      element: <OatUnknownRoute />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "404",
  },
  {
    routeProps: {
      path: "/login-to-continue",
      exact: true,
      element: <OatRequestLogin />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Please Login",
  },
  {
    routeProps: {
      path: "/terms-of-use",
      exact: true,
      element: <OatTermsOfUse />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Terms of Use",
  },
  {
    routeProps: {
      path: "/privacy-policy",
      exact: true,
      element: <OatPrivacyPolicy />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Privacy Policy",
  }
];
