import React from "react";
import styled from "styled-components";
import {toggleTableRowsColumnsDrawer} from "../../../../../../store/actions/fields/table.action";
import {connect} from "react-redux";

const EditTableRowOption = ({
  selectedRow,
  startingOffset,
  toggleTableRowsColumnsDrawer,
}) => {
  const onClickTableActions = () => {
    toggleTableRowsColumnsDrawer("row", selectedRow.index, selectedRow.id);
  };

  return (
    <EditRowColumnOption
      style={{
        top: startingOffset,
        left: -90,
      }}
      onClick={onClickTableActions}
    >
      <div />
      <div />
    </EditRowColumnOption>
  );
};

export const EditRowColumnOption = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 75px;
  height: 75px;
  background: #93b6ed;
  box-shadow: 0px 0px 3px #b0b0b0;
  border-radius: 6px;
  cursor: pointer;
  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    margin-bottom: 10px;
  }
`;

export default connect(null, {toggleTableRowsColumnsDrawer})(
  EditTableRowOption
);
