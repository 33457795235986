import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import {getHighestZIndex} from "./utils";

export function createIcon() {
  return function (state, action) {
    const {template} = state;
    const {slideID, selectFieldOnCreate, payload} = action;
    const {key, content, size, type, resizer, colorSet, format} = payload;
    let width = 0, height = 0;
    if(size.h > size.w) {
      height = (35 * state.size.h) / 100;
      width = (height * size.w) / size.h;
    } else {
      width = (35 * state.size.w) / 100;
      height = (width * size.h) / size.w;
    }
    let highestZIndex = getHighestZIndex(template, slideID);
    const currentSlide = template[slideID];
    Object.entries(currentSlide.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });
    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });
    const adjustedPos = (16 * state.size.w) / 100;
    const newFieldProps = {
      key,
      pos: {x: adjustedPos, y: adjustedPos},
      content,
      size: {
        w: width,
        h: height
      },
      type,
      resizer,
      deleted: false,
      format,
      ["styles"]: {
        ...action.payload.styles,
        lock: false,
        zIndex: highestZIndex + 1,
        opacity: 1,
        rotate: 0,
        animateOnSlideDisplay: null,
        animationType: "none",
        animationSpeed: 0.5,
        animationDelay: 0,
        animationOrder: 0,
        enableExitAnimation: false
      },
      colorSet,
    };
    const newState = [...state.template];
    newState[slideID].fields = {
      ...newState[slideID].fields,
      [key]: newFieldProps,
    };
    console.log(format)
    return {
      ...state,
      template: newState,
      activeField: key,
      selectedFieldType: action.payload.type,
      cachedFieldStyles: newState[slideID].fields[key].styles,
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      fieldToEmitFromSocket: {
        type: "create",
        field: {
          ...newFieldProps,
          key,
        },
      },
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
    };
  };
}

export function updateIconColors() {
  return function (state, action) {
    const newState = [...state.template];
    const {row, column, value, active} = action;
    const getItem = newState[active.slideID].fields[active.fieldID];

    newState[active.slideID].fields[active.fieldID] = {
      ...getItem,
      colorSet: newState[active.slideID].fields[active.fieldID].colorSet.map((colorSet, index) => {
        if (index === row) {
          return {
            ...colorSet,
            colors: colorSet.colors.map((color, colorIndex) => {
              if (colorIndex === column) { // Assuming there's only one color in each color set
                return { 
                  ...color, 
                  color: value 
                };
              }
              return color;
            })
          };
        }
        return colorSet;
      })
    };

    return {
      ...state,
      template: newState,
      updatedIconColorIndex: {
        row,
        column
      }
    };
  };
}
