export function fetchLayouts() {
  return function (state, action) {
    return {
      ...state,
      layouts: action.payload,
    };
  };
}

// use by admin when creating layouts
export function onSelectLayoutType() {
  return function (state, action) {
    const newState = [...state.template];
    newState[state.activeSlide] = {
      ...newState[state.activeSlide],
      layoutType: action.payload,
    };
    return {
      ...state,
      template: newState,
      adminLayouts: {
        ...state.adminLayouts,
        creatingLayouts: true,
        selectedLayoutType: action.payload,
      },
    };
  };
}

export function adminFilterLayouts() {
  return function (state, action) {
    const layoutTemplates = [...state.adminLayouts.layoutTemplates];
    const filterByLayoutTypes = layoutTemplates.filter(
      (item) => item.layoutType === action.filterType
    );
    return {
      ...state,
      // template: filterByLayoutTypes,
      // activeSlide: filterByLayoutTypes.length > 0 ? 0 : -1,
    };
  };
}
