import React from "react";
import {Modal} from "../../../../../../oat-ui";
import {royalBlue, white} from "../../../../../themes";
import CreateShape from "./CreateShape";

const WrapShapeList = ({mode, close}) => {
  return (
    <Modal
      width={820}
      height={520}
      top={70}
      close={close}
      background={mode === "dark" ? royalBlue : white}
    >
      <CreateShape closeModal={close} />
    </Modal>
  );
};

export default WrapShapeList;
