import { calcRotatedOffsets } from "../../../../components/editDesign/canvasPanel/dragResize/resize/utils/calcRotatedOffsets";
import {OatDefaultTheme, themes} from "../../data/themeBuilder.data";
import {initGroupSelect} from "../../designTemplate";
import {createShortKey, createSlideID} from "../fields/utils";

export function fetchTemplate() {
  return function (state) {
    return {
      ...state,
      loading: true,
    };
  };
}

export function fetchTemplateSuccess() {
  return function (state, action) {
    const {
      displaySlideListOnLoad,
      size,
      ratio,
      payload,
      tempID,
      tempSourceID,
      refTemp,
      tempTitle,
      slideTransition,
      sectionList,
      owner,
      folderID,
      folderType,
      workspaceID,
      visibility,
      tempLng,
      usedFontList,
      slideID,
      workspaceMembers,
      workspacePlan,
      tempNotiInfo,
      embedType,
      guestUsers,
      category,
      versions,
      isDraft,
      viewOnDesignPage,
      isGuestUser
    } = action;
    const slideIndex =
      slideID && payload.findIndex((item) => item?.id == slideID);

    let renameShapeKey,
      initialSlide = payload[0];
    if (displaySlideListOnLoad) { // for newly created template
      renameShapeKey = Object.entries(payload[0]?.fields).map(([id, field]) => {
        return field.type === "shape"
          ? {
              ...field,
              key: createShortKey(), // to have a unique key for each svg
            }
          : field;
      });

      initialSlide = {
        ...payload[0],
        fields: renameShapeKey?.reduce(
          (acc, item) => ({...acc, [item?.key]: item}),
          {}
        ),
        id: createSlideID(),
      };
    }
    
    if (slideID) {
      initialSlide = {
        ...payload[slideIndex],
        fields: renameShapeKey?.reduce(
          (acc, item) => ({...acc, [item?.key]: item}),
          {}
        ),
        id: createSlideID(),
      };
    }

    sessionStorage.removeItem('tempNewlyCreated');
    const activeSlideIndex = slideID ? slideIndex : 0;

    // only for presenting page
    let ifSlideHasExistAnimation = payload;
    if (!viewOnDesignPage) {
      ifSlideHasExistAnimation = payload.map((slide, i) => {
        let hasExitAnimation = false;
        // for (const [key, field] of Object.entries(
        //   slide.fields
        // )) {
        //   if (
        //     field.styles?.animationType && 
        //     field.styles?.animationType !== "none" && 
        //     field.styles?.enableExitAnimation) 
        //   {
        //     if (field.styles?.animationOrder) {

        //     }
        //     hasExitAnimation = true;
        //   }
        // }
        const maxAnimationOrder = Math.max(...Object.values(slide.fields).map(item => item?.styles?.animationType !== 'none' && item?.styles?.animationOrder));
        const objectsWithHighestOrder = Object.values(slide.fields)
          .filter(item => item?.styles?.animationType !== 'none' && item?.styles?.animationOrder === maxAnimationOrder);

        // Filter objects with enableExitAnimation === true
        const filteredObjects = objectsWithHighestOrder
          .filter(item => item?.styles?.enableExitAnimation === true);
        hasExitAnimation = filteredObjects.length > 0 ? true : false;

        return {
          ...slide,
          hasExitAnimation
        }
      });
    }

    let usedFontsInSet = new Set();
    let uniqueFontFamilies = new Set();
    if (viewOnDesignPage && payload) {
      payload.forEach((slide) => {
        Object.values(slide.fields).forEach((item) => {
          if (item?.styles?.fontFamily && !uniqueFontFamilies.has(item.styles.fontFamily)) {
            usedFontsInSet.add({
              fontFamily: item.styles.fontFamily, 
              custom: item.font.custom,
              fontWeight: item.styles.fontWeight,
              fontStyle: item.styles.fontStyle,
            });
            uniqueFontFamilies.add(item.styles.fontFamily);
          }
        });
      });
    }
    const usedFonts = Array.from(usedFontsInSet);
    return {
      ...state,
      loading: false,
      activeField: -1,
      activeSlide: activeSlideIndex,
      activeSlideID: slideID ? slideID : initialSlide?.id,
      currentSlideOnClickAnimationOrder: 0,
      groupSelect: initGroupSelect,
      size: {w: Number(size.w), h: Number(size.h)},
      orgTempSize: {w: Number(size.w), h: Number(size.h)},
      ratio,
      ratioDiff: (size.w / 563).toFixed(2),
      template: displaySlideListOnLoad ? [initialSlide] : ifSlideHasExistAnimation, // payload,
      themes:
        action.themes !== undefined || action.payload.themes.length > 0
          ? action.themes
          : themes,
      templateName: tempTitle !== undefined ? tempTitle : "Untitled Template",
      tempID, //user tempID
      tempSourceID,
      refTemp,
      slideTransition,
      sectionList,
      templateSelection: displaySlideListOnLoad
        ? {
            ...state.templateSelection,
            status: true, // true will show panel after template creation
          }
        : {
            ...state.templateSelection,
            status: false,
          },
      owner,
      tempCoverLocation: {
        folderID,
        workspaceID,
      },
      folder: {
        ID: folderID,
        type: folderType,
      },
      workspaceID,
      tempLng,
      visibility,
      usedFontList,
      error: {
        status: false,
        message: null,
      },
      passcode: {
        ...state.passcode,
        enabled: action.visibility === "protected" ? true : false,
        value: action.passcode,
        auth: true,
      },
      workspaceMembers,
      workspacePlan,
      tempNotiInfo,
      embedType,
      guestUsers,
      category,
      versions,
      isDraft,
      viewOnDesignPage,
      usedFonts,
      isGuestUser
    };
  };
}

export function fetchTemplateErr() {
  return function (state, action) {
    return {
      ...state,
      loading: false,
      error: {
        status: true,
        message: action.error,
      },
    };
  };
}

export function setSearchedTemplates() {
  return function (state, action) {
    const {designs, themes, refTempID, size, orgTempSize, usedFontList} = action;
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        templates: [],
        refTempID,
        designs: [],
        chosenTempDesigns: designs,
        size: {
          w: orgTempSize?.w ? orgTempSize?.w : 1920, 
          h: orgTempSize?.h ? orgTempSize?.h : 1080
        },
        usedFontList,
        themes: designs !== undefined ? themes : state.themes,
        searchedKeyWords: "",
      },
    };
  };
}

export function resizeTemplate() {
  return function (state, action) {
    // CHANGES HERE SHOULD ALSO REFLECT IN ./resizeFields.js
    const ratioDiff = Math.min(
      action.resizeTo.w / action.resizeFrom.w,
      action.resizeTo.h / action.resizeFrom.h
    );

    let dynamicSize = 12;
    if (action.resizeTo.w < 1000) {
      dynamicSize = 12;
    } else if (action.resizeTo.w < 1000) {
      dynamicSize = 20;
    } else if (action.resizeTo.w < 2000) {
      dynamicSize = 28;
    } else if (action.resizeTo.w < 3000) {
      dynamicSize = 54;
    } else if (action.resizeTo.w < 4000) {
      dynamicSize = 68;
    } else if (action.resizeTo.w < 5000) {
      dynamicSize = 84;
    }
    const updatedFields = state.template.map((slide) => {
      return {
        ...slide,
        fields: Object.entries(slide.fields).map(([id, field]) => {
          const {styles, size, pos} = field;

          const newPos = {
            x: pos.x * ratioDiff,
            y: pos.y * ratioDiff,
          };
          const newSize = {
            w: size.w * ratioDiff,
            h: size.h * ratioDiff,
          };
          let rotatedOffsets = calcRotatedOffsets(newPos, newSize, styles.rotate)

          if (field.type === "text") {
            const w1 = size.w * ratioDiff;
            const h1 = size.h * ratioDiff;
            const updateSizePos = {
              size: {
                ...field.size,
                w: w1,
                h: h1,
              },
              pos: {
                x: pos.x * ratioDiff,
                y: pos.y * ratioDiff,
              },
            };
            return {
              ...field,
              ...updateSizePos,
              ["styles"]: {
                ...field.styles,
                scale: Math.min(w1 / size.w2, h1 / size.h2), //styles.scale * ratioDiff,
              },
              rotatedOffsets
            };
          } else if (field.type === "image") {
            return {
              ...field,
              ["styles"]: {
                ...field.styles,
                shadowBlur: field.styles.shadowBlur * ratioDiff,
                shadowSize: field.styles.shadowSize * ratioDiff,
              },
              size: {
                w: size.w * ratioDiff,
                h: size.h * ratioDiff,
                w2: size.w2 * ratioDiff,
                h2: size.h2 * ratioDiff,
              },
              pos: {
                x: pos.x * ratioDiff,
                y: pos.y * ratioDiff,
              },
              rotatedOffsets,
              imgPos: {
                x: field.imgPos.x * ratioDiff,
                y: field.imgPos.y * ratioDiff,
              }
            };
          } else if (field.type === "shape") {
            if (field.subtype !== "line") {
              const updateSizePos = {
                size: {
                  w: size.w * ratioDiff,
                  h: size.h * ratioDiff,
                },
                pos: {
                  x: pos.x * ratioDiff,
                  y: pos.y * ratioDiff,
                },
                rotatedOffsets
              };
              return {
                ...field,
                ...updateSizePos,
                ["styles"]: {
                  ...field.styles,
                  borderRadius: field.styles?.borderRadius * ratioDiff,
                  scale: 1,
                  strokeWidth: field.styles?.strokeWidth * ratioDiff
                },
              };
            } else if (field.subtype === "line")  {
              const updateSizePos = {
                size: {
                  w: size.w * ratioDiff,
                  h: size.h * ratioDiff,
                },
                pos: {
                  x: pos.x * ratioDiff,
                  y: pos.y * ratioDiff,
                },
                rotatedOffsets
              };
              return {
                ...field,
                ...updateSizePos,
                ["styles"]: {
                  ...field.styles,
                  lineThickness: field.styles?.lineThickness * ratioDiff
                },
              };
            }
          } else if (field.type === "table") {
            const tableWidth = size.w * ratioDiff;
            let combinedColumnWidth = 0;
            return {
              ...field,
              size: {
                w: tableWidth,
                h: (size.h - 2) * ratioDiff,
              },
              pos: {
                x: pos.x * ratioDiff,
                y: pos.y * ratioDiff,
              },
              rotatedOffsets,
              ["styles"]: {
                ...field.styles,
                fontSize: styles.fontSize * ratioDiff,
                borderWidth: styles.borderWidth * ratioDiff,
              },
              content: field.content.map((item, rowIndex) => ({
                row: {
                  height: item.row.height * ratioDiff,
                },
                columns: item.columns.map((column, colIndex) => {
                  let lastColumnWidth = 0,
                    colWidth =
                      Math.round(tableWidth * column.width) / field.size.w;
                  if (colIndex === item.columns.length - 1) {
                    lastColumnWidth = Math.ceil(
                      tableWidth - combinedColumnWidth
                    );
                  } else if (rowIndex === 0) {
                    combinedColumnWidth = Math.ceil(
                      combinedColumnWidth + colWidth
                    );
                  }
                  return {
                    ...column,
                    width:
                      colIndex === item.columns.length - 1
                        ? lastColumnWidth
                        : colWidth,
                    // Math.round(tableWidth * column.width) / field.size.w,
                    // (column.width + 2) * ratioDiff,
                  };
                }),
              })),
            };
          } else if (field.type === "chart") {
            // const scaledItem = Math.min(
            //   (size.w * ratioDiff) / size.w,
            //   (size.h * ratioDiff) / size.h
            // );
            // const tempScale = Math.min(
            //   (window.innerHeight - 150) / action.resizeTo.h,
            //   1152 / action.resizeTo.w
            // );
            const w1 = size.w * ratioDiff;
            const h1 = size.h * ratioDiff;

            const updateSizePos = {
              size: {
                w: size.w * ratioDiff,
                h: size.h * ratioDiff,
              },
              pos: {
                x: pos.x * ratioDiff,
                y: pos.y * ratioDiff,
              },
              rotatedOffsets,
              ["styles"]: {
                ...field.styles,
                scale: 1,
                fontSize: styles.fontSize * ratioDiff,
                barSize: styles.barSize * ratioDiff,
                legendFontSize: styles.legendFontSize * ratioDiff,
                measurementFontSize: styles.measurementFontSize * ratioDiff,
                categoryFontSize: styles.categoryFontSize * ratioDiff,
                scaleDistance: 10 * ratioDiff,
                dotSize: styles?.dotSize * ratioDiff,
                lineSize: styles?.lineSize * ratioDiff
              },
            };
            return {
              ...field,
              ...updateSizePos,
            };
          } else if (field.type === "embed" || field.type === "video") {
            const updateSizePos = {
              size: {
                w: size.w * ratioDiff,
                h: size.h * ratioDiff,
                w2: size.w2 * ratioDiff,
                h2: size.h2 * ratioDiff,
              },
              pos: {
                x: pos.x * ratioDiff,
                y: pos.y * ratioDiff,
              },
              rotatedOffsets,
            };
            return {
              ...field,
              ...updateSizePos,
              imgPos: {
                x: field?.imgPos.x * ratioDiff,
                y: field?.imgPos.y * ratioDiff,
              },
              ["styles"]: {
                ...field.styles,
                scale: 1,
                borderRadius: field.styles?.borderRadius * ratioDiff,
              },
            };
          } else {
            const updateSizePos = {
              size: {
                w: size.w * ratioDiff,
                h: size.h * ratioDiff,
              },
              pos: {
                x: pos.x * ratioDiff,
                y: pos.y * ratioDiff,
              },
              rotatedOffsets,
            };
            return {
              ...field,
              ...updateSizePos,
              ["styles"]: {
                ...field.styles,
                scale: 1,
              },
            };
          }
        }),
      };
    });
    return {
      ...state,
      template: updatedFields.map((slide) => {
        return {
          ...slide,
          fields: slide.fields.reduce(
            (acc, item) => ({...acc, [item.key]: item}),
            {}
          ),
        };
      }),
      activeField: -1,
      size: {w: Number(action.resizeTo.w), h: Number(action.resizeTo.h)},
      ratioDiff: ratioDiff, // (state.size.w / 500).toFixed(2) set mobile in 2.5
      undoRedo: {
        history: [],
        active: {},
        future: [],
        firstTextInput: false, // text, table to track first char input
      },
      groupSelect: initGroupSelect,
    };
  };
}

export function setTempScale() {
  return function (state, action) {
    return {
      ...state,
      layout: {
        ...state.layout,
        tempScale: action.value,
      },
    };
  };
}

export function editTemplateName() {
  return function (state, action) {
    return {
      ...state,
      templateName: action.value,
    };
  };
}

export function setUserTemplateID() {
  return function (state, action) {
    return {
      ...state,
      tempID: action.tempID,
    };
  };
}

export function toggleTemplateSelectionModal() {
  return function (state) {
    const {templateSelection} = state;
    return {
      ...state,
      templateSelection: {
        ...templateSelection,
        status: !templateSelection.status,
      },
    };
  };
}

export function changeTempVisibility() {
  return function (state, action) {
    return {
      ...state,
      visibility: action.payload,
    };
  };
}

export function setPasscode() {
  return function (state, action) {
    return {
      ...state,
      passcode: action.payload,
      // {
      //   enabled: action.payload.enabled,
      //   value: action.payload.value,
      //   auth: action.payload.auth,
      //   ...action.payload,
      // }//action.payload,
    };
  };
}

export function manageTemplateNotification() {
  return function (state, action) {
    return {
      ...state,
      tempNotiInfo: action.payload,
    };
  };
}

export function setGuestList() {
  return function (state, action) {
    return {
      ...state,
      guestUsers: action.payload,
    };
  };
}

export function shareAsGuest() {
  return function (state, action) {
    return {
      ...state,
      guestUsers: [
        {
          email: action.payload.email,
          accessLevel: action.payload.accessLevel,
        },
        ...state.guestUsers,
      ],
    };
  };
}

export function updateGuestAccessLevel() {
  return function (state, action) {
    return {
      ...state,
      guestUsers: state.guestUsers.map((item) =>
        item.email === action.payload.email ? action.payload : item
      ),
    };
  };
}

export function setCanvasSize() {
  return function (state, action) {
    return {
      ...state,
      canvasSize: action.payload,
    };
  };
}

export function setUserConnectionId() {
  return function (state, action) {
    return {
      ...state,
      userConnectionId: action.payload,
    };
  };
}

export function setInitPlayGround() {
  return function (state, action) {
    const id = createSlideID();
    return {
      ...state,
      activeSlide: 0,
      activeSlideID: id,
      templateSelection:  {
        ...state.templateSelection,
        status: true, // true will show panel after template creation
      },
      tempSourceID: "c8de0e87-b2c5-4540-b27b-c1402d7caf92",
      template: [
        {
          id,
          bg: {
            bg1: "rgb(77, 235, 222)",
            bg2: "#fff",
            type: "linear",
            bgAng: 130,
            gradient: false,
          },
          fields: [],
          markedDone: false,
          theme: OatDefaultTheme,
          title: "",
          notes: "",
          markedDone: false,
          comments: [],
        }
      ]
    };
  };
}

export function setUsedFonts() {
  return function (state) {
    const template = [...state.template];
    let usedFontsInSet = new Set();
    let uniqueFontFamilies = new Set(); 
    template && template.forEach((slide) => {
        Object.values(slide.fields).forEach((item) => {
            if (item?.styles?.fontFamily && !uniqueFontFamilies.has(item.styles.fontFamily)) {
              usedFontsInSet.add({
                fontFamily: item.styles.fontFamily, 
                custom: item.font.custom,
                fontWeight: item.styles.fontWeight,
                fontStyle: item.styles.fontStyle
              });
              uniqueFontFamilies.add(item.styles.fontFamily);
            }
        });
    });
    const fonts =  Array.from(usedFontsInSet);

    return {
      ...state,
      usedFonts: fonts,
    };
  };
}

