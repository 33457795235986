import React, { useState, useEffect } from 'react';
import DisplayPack from './DisplayPack';
import { ArrowBack } from '../../../../../../../oat-svg-icons';
import { GoBack } from '../../reusableStyles/reusableStyles';
import DisplayPackItem from './DisplayPackItem';
import { Spinner } from '../../../../../../../oat-ui';
import { WrapImagePanel } from '../styles/userImageStyles';

const OatAllPublicImages = ({
    replaceImg, 
    onUploadImgByLink,
    onReplaceImgByLink
}) => {
    const [covers, setCovers] = useState([]);
    const [packItems, setPackItems] = useState([]);
    const [displaySection, setDisplaySection] = useState("pack-list");
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        fetch(`https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/images?category=`)
            .then((res) => res.json())
            .then((data) => setCovers(data))
            .catch((e) => ({}))
    }, []);

    const onSelectIconCollection = (packID) => {
        setDisplaySection("items-inside-pack");
        setFetching(true);
        fetch(`https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/images/${packID}`)
            .then((res)=> res.json())
            .then((data)=> {
                setFetching(false)
                setPackItems(data)
            })
            .catch ((e) => {})
    }

    const handleGoBackToOPackages = () => {
        setDisplaySection("pack-list");
    };

    const handleCreate = (item) => {
        const payload = {
            naturalWidth: item.imgSize?.w,
            naturalHeight: item.imgSize?.h,
            src: item.image,
            imageBy: item.imageBy,
            imgType: item.imgType,
            imgSize: item?.imgSize,
            colorSet: item?.colorSet
        };
        
        if (!replaceImg) {
            onUploadImgByLink(payload);
        } else {
            onReplaceImgByLink(payload);
        }
    };

    return (
        <div>
            {displaySection === "items-inside-pack" ? (
                <GoBack onClick={handleGoBackToOPackages}>
                    {ArrowBack}
                </GoBack>
            ) : undefined}

            {fetching ?
                <WrapImagePanel>
                    {displaySection === "pack-list" && covers && covers.map((item, i) => {
                        return (
                            <DisplayPack key={i} item={item} onSelect={onSelectIconCollection} />
                        );
                    })}

                    {displaySection === "items-inside-pack" && packItems && packItems.map((item, i) => {
                        return (
                            <DisplayPackItem key={i} item={item} onCreate={handleCreate} />
                        );
                    })}
                </WrapImagePanel>
            : (
                <div style={{width: "100%", marginTop: "10%"}}>
                    <Spinner />
                </div>
            )}
        </div>
    )
};

export default OatAllPublicImages;