import { themeBuilder } from "../../data/themeBuilder.data";
import {initGroupSelect, smartAlignmentInitState} from "../../designTemplate";
import {createShortKey, createSlideID} from "../fields/utils";
import {resizeFields} from "./resizeFields";

export function changeSlideIndex() {
  return function (state, action) {
    let selectedFieldType = null;
    if (
      state.selectedFieldType === "comment" ||
      state.selectedFieldType === "notes" ||
      state.selectedFieldType === "animations"
    ) {
      selectedFieldType = state.selectedFieldType;
    } else {
      selectedFieldType = "";
    }
    return {
      ...state,
      prevSlideIndex: state.activeSlide,
      activeSlide: action.value,
      activeSlideID: state.template[action.value]?.id,
      activeField: -1,
      activeTheme: state.template[action.value]?.theme,
      cropImage: false,
      selectedFieldType,
      enableTextEdit: false,
      mobEditingPanel: {
        addedHeight: 0,
        type: "",
      },
      groupSelect: {
        ...state.groupSelect,
        keys: [],
        status: false, //  Group select activated until being deslected
        selection: {
          ...state.groupSelect.selection,
          show: false,
        },
      },
      smartAlignment: smartAlignmentInitState,
      // undoRedo: {
      //   ...state.undoRedo,
      //   history: [],
      //   active: {},
      //   future: [],
      //   firstTextInput: false, // text, table to track first char input
      // },
    };
  };
}

export function changeSlideByID() {
  return function (state, action) {
    const slideIndex = state.template.findIndex(
      (slide) => slide.id === action.value
    );

    let highestAnimationOrder = 0;
    for (const [key, field] of Object.entries(
      state.template[slideIndex].fields
    )) {
      if (
        field.styles?.animationType &&
        field.styles?.animationType !== "none"
        // &&
        // !field.styles?.animateOnSlideDisplay
      ) {
        if (highestAnimationOrder < field.styles?.animationOrder) {
          highestAnimationOrder = field.styles?.animationOrder;
        }
      }
    }
    return {
      ...state,
      prevSlideIndex: state.activeSlide,
      activeSlide: slideIndex, //? slideIndex : state.activeSlide,
      activeSlideID: action.value,
      currentSlideOnClickAnimationOrder: 0, // highestAnimationOrder,
      temporarilyDisableSlideTransition: true,
      activeField: -1,
      activeTheme: state.template[slideIndex]?.theme,
      cropImage: false,
      selectedFieldType: "",
      enableTextEdit: false,
      groupSelect: {
        ...state.groupSelect,
        keys: [],
        status: false, //  Group select activated until being deslected
        selection: {
          ...state.groupSelect.selection,
          show: false,
        },
      },
      smartAlignment: smartAlignmentInitState,
    };
  };
}

export function changeSlideOnPresentView() {
  return function (state, action) {
    const {template} = state;
    const newState = [...template];
    let slideIndex = state.activeSlide,
      slideAnimationOrder = state.currentSlideOnClickAnimationOrder,
      highestAnimationOrder = 1,
      ifAnimatedFieldsExist = [];

    for (const [key, field] of Object.entries(newState[slideIndex].fields)) {
      if (
        field.styles?.animationType &&
        field.styles?.animationType !== "none" && 
        !field.styles?.animateOnSlideDisplay
      ) {
        ifAnimatedFieldsExist.push(key);
        if (highestAnimationOrder < field.styles?.animationOrder) {
          highestAnimationOrder = field.styles?.animationOrder;
        }
      } else if (field.styles?.animationType !== 'none' && field.styles?.enableExitAnimation && field.styles?.animateOnSlideDisplay) {
        ifAnimatedFieldsExist.push(key);
      }
    };

    // Check if there's at least one item with the highest order and exit set to true
    // if there is an exit animation in the last highest order item, add extra + 1
    // and call twice in PresentViewSlideArrows.js and Present.js 
    // if exitAnimation is enabled in the last higest order item
    const lastOnClickAnimationOrderHasEnabledExit =  
      Object.values(template[slideIndex].fields)
        .some(field => field.styles?.animationOrder === highestAnimationOrder 
            && field.styles?.enableExitAnimation);

    if (lastOnClickAnimationOrderHasEnabledExit) {
      highestAnimationOrder = highestAnimationOrder + (lastOnClickAnimationOrderHasEnabledExit ? 1 : 0);
    }

    if (
      template.length - 1 > slideIndex &&
      (ifAnimatedFieldsExist.length === 0 || highestAnimationOrder === slideAnimationOrder) &&
      action.direction === "forward"
    ) {
      const newSlideIndex = state.activeSlide + 1;
      let slideAnimatedFields = [];
      for (const [key, field] of Object.entries(
        newState[newSlideIndex].fields
      )) {
        if (
          field.styles?.animationType &&
          field.styles?.animationType !== "none" &&
          !field.styles?.animateOnSlideDisplay
        ) {
          slideAnimatedFields.push(key);
        }
      }

      slideIndex = newSlideIndex;
      slideAnimationOrder = 0;
    } else if (
      slideIndex !== 0 &&
      (ifAnimatedFieldsExist.length === 0 || slideAnimationOrder <= 0) &&
      action.direction === "backward"
    ) {
      let checkIfHighestOrder = 0;
      const newSlideIndex = state.activeSlide - 1;
      for (const [key, field] of Object.entries(
        newState[newSlideIndex].fields
      )) {
        if (
          field.styles?.animationType &&
          field.styles?.animationType !== "none" && 
          !field.styles?.animateOnSlideDisplay
        ) {
          ifAnimatedFieldsExist.push(key);
          if (checkIfHighestOrder < field.styles?.animationOrder) {
            checkIfHighestOrder = field.styles?.animationOrder;
          }
        }
      }
      slideIndex = newSlideIndex;
      slideAnimationOrder = checkIfHighestOrder;
    } else if (ifAnimatedFieldsExist.length > 0) {
      if (
        action.direction === "backward" &&
        (slideIndex !== 0 || slideAnimationOrder >= 1)
      ) {
        // set -1 here to avoid field being animated on initial slide change
        slideAnimationOrder =
          slideAnimationOrder <= 0 ? -1 : slideAnimationOrder - 1;
      } else if (
        action.direction === "forward" &&
        template.length - 1 >= slideIndex &&
        highestAnimationOrder > slideAnimationOrder
      ) {
        // set -1 here to avoid field being animated on initial slide change
        // turn -1 to 1, skipping zero
        slideAnimationOrder = slideAnimationOrder === -1 ? 1 : slideAnimationOrder + 1;
      }
    }

    return {
      ...state,
      template: newState,
      prevSlideIndex: state.activeSlide,
      activeSlide: slideIndex,
      activeSlideID: newState[slideIndex]?.id,
      currentSlideOnClickAnimationOrder: slideAnimationOrder,
      temporarilyDisableSlideTransition: false,
      activeField: -1,
      cropImage: false,
      selectedFieldType: "",
      enableTextEdit: false,
      groupSelect: {
        ...state.groupSelect,
        keys: [],
        status: false, //  Group select activated until being deslected
        selection: {
          ...state.groupSelect.selection,
          show: false,
        },
      },
    };
  };
}

export function createSlideFromTemplate() {
  return function (state, action) {
    const {activeSlide, template} = state;
    const newState = [...template];

    const tempSelectionWidth = state.templateSelection?.size.w
      ? state.templateSelection?.size.w
      : state.size.w;
    const tempSelectionHeight = state.templateSelection?.size.w
      ? state.templateSelection?.size.h
      : state.size.h;
    const ratioDiff = Math.min(
      state.size.w / tempSelectionWidth,
      state.size.h / tempSelectionHeight
    );

    const updateSlideInfo = Object.entries(action.slide.fields).map(
      ([id, field]) => {
        return field.type === "shape"
          ? {
              ...field,
              key: createShortKey(),
            }
          : field;
      }
    );
    // for layout slides, id will be created in its component for collaboration
    const slideID = action?.newSlideID ? action?.newSlideID : createSlideID();
    const newSlide = resizeFields({
      slide: {
        ...action.slide,
        fields: updateSlideInfo.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
        id: slideID,
      },
      tempWidth: state.size.w,
      ratioDiff,
      theme: state.activeTheme,
    });
    newState.splice(activeSlide + 1, 0, newSlide);    
    const getSlide = newState && newState.find((slide, index) => slide.id === state.activeSlideID);
    
    return {
      ...state,
      template: newState,
      activeSlide: template.length === 0 ? 0 : activeSlide + 1,
      activeSlideID: newState[activeSlide + 1]?.id,
      // activeTheme:
      //   action.slide.theme !== "" ? action.slide.theme : OatDefaultTheme,
      // themes:
      //   getTheme !== undefined && !checkThemeAlreadyAdded
      //     ? [...state.themes, getTheme]
      //     : state.themes,
      selectedFieldType: "background",
      activeField: -1,
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      smartAlignment: smartAlignmentInitState,
      fieldToEmitFromSocket : {
        type: "create-slide",
        slideInfo: {
          insertAfterSlideID: getSlide?.id,
          newSlide,
          updateTheme: { // for layout template to change user's current theme
            status: action.autoApplyCurrentTheme,
            activeTheme: state.template[activeSlide].theme //state.activeTheme
          }
        },
      }
    }
  };
}

export function addNewSlide() {
  return function (state, action) {
    const {themes, template, adminLayouts} = state;
    const newState = [...template];
    const {selectSlideOnCreate, themeID, insertAfterSlideIndex} = action;
    const index = themes.findIndex((theme) => theme.id === themeID);
    const getSlide =
      template &&
      template.find((slide, index) => index === insertAfterSlideIndex);
    const bg = themes[index]?.bg ? themes[index]?.bg : themeBuilder?.bg;
    const addNewSlide = {
      id: createSlideID(),
      bg: {
        bg1: bg.bg1,
        bg2: bg?.bg2 ? bg.bg2 : '#fff',
        type: bg.type,
        bgAng: bg.bgAng,
        gradient: bg.gradient,
      },
      theme: themeID,
      fields: {},
      title: "",
      notes: "",
      markedDone: false,
      comments: [],
    };
    // if (adminLayouts.creatingLayouts) {
    //   addNewSlide.layoutType = adminLayouts.selectedLayoutType;
    // }
    newState.splice(insertAfterSlideIndex + 1, 0, addNewSlide);
    if (selectSlideOnCreate) {
      return {
        ...state,
        template: newState,
        activeSlide: insertAfterSlideIndex + 1,
        activeSlideID: newState[insertAfterSlideIndex + 1]?.id,
        activeTheme: themeID,
        selectedFieldType: "background",
        activeField: -1,
        groupSelect: initGroupSelect,
        enableTextEdit: false,
        fieldToEmitFromSocket: {
          type: "create-slide",
          slideInfo: {
            insertAfterSlideID: getSlide?.id,
            newSlide: addNewSlide,
          },
        },
        smartAlignment: smartAlignmentInitState,
        // adminLayouts: {
        //   ...adminLayouts,
        //   layoutTemplates: adminLayouts.creatingLayouts
        //     ? [...adminLayouts.layoutTemplates, ...newState]
        //     : [],
        // },
      };
    } else {
      return {
        ...state,
        template: newState,
        smartAlignment: smartAlignmentInitState,
      };
    }
  };
}

export function deleteSlide() {
  return function (state, action) {
    const {activeSlide} = state;
    const slideIndex = state.template.findIndex(
      (slide) => slide.id === action.id
    );
    const updateUndoRedo = {
      ...state.undoRedo,
      active:
        state.undoRedo.active.slideID === action.id
          ? {}
          : state.undoRedo.active,
      history: state.undoRedo.history.filter(
        (item) => item.slideID !== action.id
      ),
      future: state.undoRedo.future.filter(
        (item) => item.slideID !== action.id
      ),
    };

    if (state.activeSlide === slideIndex) {
      return {
        ...state,
        template: state.template.filter((item) => item.id !== action.id),
        activeSlide: activeSlide === 0 ? 0 : activeSlide - 1,
        activeSlideID: activeSlide === 0 ? 
          state.template[1]?.id : 
          state.template[activeSlide - 1]?.id,
        selectedFieldType: "background",
        activeField: -1,
        groupSelect: initGroupSelect,
        undoRedo: updateUndoRedo,
      };
    } else {
      let newSlideIndex = activeSlide, getSlideInfo = activeSlide;
      if (activeSlide - 1 === slideIndex) {
        newSlideIndex = activeSlide - 1;
        getSlideInfo = activeSlide;
      } else if (activeSlide > slideIndex) {
        newSlideIndex = activeSlide - 1;
        getSlideInfo = activeSlide;
      } else if (activeSlide === state.template.length - 1) {
        newSlideIndex = activeSlide - 1;
        getSlideInfo = activeSlide - 1;
      } else {
        newSlideIndex = activeSlide;
        getSlideInfo = activeSlide;
      };
      return {
        ...state,
        template: state.template.filter((item) => item.id !== action.id),
        activeSlide: newSlideIndex,
        activeSlideID: state.template[getSlideInfo]?.id,
        undoRedo: updateUndoRedo,
      };
    }
  };
}

export function cloneSlide() {
  return function (state, action) {
    const {template} = state;
    const slideIndex = template.findIndex(
      (slide) => slide.id === action.slideID
    );
    const updateFieldsFormat = Object.entries(template[slideIndex].fields).map(
      ([id, field]) => {
        return {
          ...field,
          key: createShortKey(),
        };
      }
    );
    const newSlideID = createSlideID();
    const duplicateSlide = {
      ...template[slideIndex],
      fields: updateFieldsFormat.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      ),
      id: newSlideID,
    };
    if (action.selectSlideOnCreate) {
      return {
        ...state,
        template: [
          ...template.slice(0, slideIndex + 1),
          duplicateSlide,
          ...template.slice(slideIndex + 1),
        ],
        activeSlide: slideIndex + 1,
        activeSlideID: newSlideID,
        selectedFieldType: "background",
        activeField: -1,
        groupSelect: initGroupSelect,
        fieldToEmitFromSocket: {
          type: "create-slide",
          slideInfo: {
            insertAfterSlideID: action.slideID,
            newSlide: duplicateSlide,
          },
        },
      };
    } else {
      return {
        ...state,
        template: [
          ...template.slice(0, slideIndex + 1),
          duplicateSlide,
          ...template.slice(slideIndex + 1),
        ],
        fieldToEmitFromSocket: {
          type: "create-slide",
          slideInfo: {
            insertAfterSlideID: action.slideID,
            newSlide: duplicateSlide,
          },
        },
      };
    }
  };
}

export function switchSlideIndex() {
  return function (state, action) {
    const {
      fromIndex,
      toIndex,
      activeSlideID,
      placeUnderSlideID,
      emitForCollaboration,
    } = action;
    const newState = [...state.template];
    // const getCurrentSlide = newState.find(
    //   (x, index) => index === state.activeSlide
    // );
    const getCurrentSlide = newState.find(
      (x, index) => x.id === activeSlideID
    );
    const movedSlide = state.template[fromIndex];

    if (placeUnderSlideID !== undefined) {
      const placeUnderSlideIndex = newState.findIndex(
        (x) => x.id === placeUnderSlideID
      );
      newState.splice(fromIndex, 1);
      newState.splice(placeUnderSlideIndex + 1, 0, movedSlide);
      let activeSlide, newSlideID;
      if (!emitForCollaboration) {
        activeSlide = newState.findIndex((x) => x.id === activeSlideID);
        newSlideID = activeSlideID;
      } else {
        activeSlide = newState.findIndex((x) => x.id === state.activeSlideID);
        newSlideID = state.activeSlideID; // getCurrentSlide.id;
      }
      return {
        ...state,
        template: newState,
        activeSlide,
        activeSlideID: newSlideID
      };
    } else {
      newState.splice(fromIndex, 1);
      newState.splice(toIndex, 0, movedSlide);
      let activeSlide, newSlideID;
      if (!emitForCollaboration) {
        activeSlide = newState.findIndex((x) => x.id === activeSlideID);
        newSlideID = activeSlideID;
      } else {
        activeSlide = newState.findIndex((x) => x.id === state.activeSlideID);
        newSlideID = state.activeSlideID; // getCurrentSlide.id;
      }
      return {
        ...state,
        template: newState,
        activeSlide,
        activeSlideID: newSlideID
      };
    }
  };
}

export function toggleSlideWorkingStatus() {
  return function (state, action) {
    const {slideID} = action;
    const slideIndex = state.template.findIndex(
      (slide) => slide.id === slideID
    );
    const newState = [...state.template];
    newState[slideIndex] = {
      ...newState[slideIndex],
      markedDone: !newState[slideIndex].markedDone,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function addSlideNotes() {
  return function (state, action) {
    const {template} = state;
    const newState = [...template];
    const slideIndex = template.findIndex(
      (item, i) => item.id === action.slideID
    );
    newState[slideIndex] = {
      ...newState[slideIndex],
      notes: action.notes,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function updateSlideTitle() {
  return function (state, action) {
    const {template, activeSlide} = state;
    const newState = [...template];
    newState[activeSlide] = {
      ...newState[activeSlide],
      title: action.title,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function setSlideTransition() {
  return function (state, action) {
    return {
      ...state,
      slideTransition: action.payload,
    };
  };
}

export function addNewSectionGroupSlides() {
  return function (state, action) {
    return {
      ...state,
      slideGroupList: [...state.slideGroupList, action.value],
    };
  };
}

export function setSlideSectionName() {
  return function (state, action) {
    const {template, activeSlide} = state;
    const newState = [...template];
    newState[activeSlide] = {
      ...newState[activeSlide],
      groupedSection: action.value,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function temporarilyDisableSlideTransition() {
  return function (state) {
    return {
      ...state,
      temporarilyDisableSlideTransition:
        !state.temporarilyDisableSlideTransition,
    };
  };
}
