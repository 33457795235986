import React, {useEffect, useState} from "react";
import {UserAuth} from "../../../packages/oat-auth";
import {Auth} from "@aws-amplify/auth";
import {useNavigate} from "react-router-dom";
import {updateUserAuthStatus} from "../../../redux/user/actions/authUser.action";
import {connect} from "react-redux";
import {genUID} from "../../../packages/oat-utility";
import {selectUserWorkspaceForRedirect} from "../../../redux/user/authUser";

const Register = ({updateUserAuthStatus, redirectWorkspaceID}) => {
  let navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) =>
        navigate(`/dashboard/${redirectWorkspaceID}/designs/home`)
      )
      .catch((err) => {});
  }, []);

  const userCredentials = async () => {
    // const credentials = await Auth.currentUserCredentials();
  };

  const setErrorMessage = (message) => {
    setProcessing(false);
    setError({
      status: true,
      message,
    });
  };

  const handleSubmit = async (email, password, name) => {
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
    if (!processing) {
      setProcessing(true);
      // const userExists = await fetch(
      //   `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username.value}/checkusername`
      // )
      //   .then((res) => res.json())
      //   .catch((err) => {
      //     setProcessing(false);
      //     setError({
      //       status: true,
      //       message: "Something went wrong.",
      //     });
      //   });
      const updatedEmail = email.value.trim();
      const username = genUID(11);
      await Auth.signUp({
        username: updatedEmail, // for email
        password: password.value,
        attributes: {
          email: updatedEmail,
          name: name.value,
          "custom:acc-username": username,
          // "custom:acc-type": "user",
        },
      })
        .then(async (userInfo) => {
          await Auth.signIn(updatedEmail, password.value)
            .then(async (user) => {
              await fetch(
                `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/userInfo`
              )
                .then((res) => res.json())
                .then(async (data) => {
                  const {userItems, workspaceID} = data;
                  setProcessing(false);
                  await updateUserAuthStatus({
                    cognito: user,
                    dynamo: userItems,
                  });
                  await navigate(`/dashboard/${workspaceID}/designs/home`); // homeDefault
                  return await window.location.reload();
                })
                .catch((err) => {
                  setErrorMessage(
                    "Something went wrong. Please try again later."
                  );
                });
            })
            .catch((err) => {
              setErrorMessage(err.message);
            });
        })
        .catch((err) => {
          if (err.message.includes("User with that email already exists.")) {
            setErrorMessage("User with that email already exists.");
          } else {
            setErrorMessage(err.message);
          }
        });
    }
  };

  return (
    <UserAuth
      shopName="Oat All"
      formType="register"
      context="Create an Account"
      onSubmit={handleSubmit}
      processing={processing}
      error={error}
      //   onSignUp={(provider) => Auth.federatedSignIn({provider})}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    redirectWorkspaceID: selectUserWorkspaceForRedirect(state.authUser),
  };
};

export default connect(mapStateToProps, {updateUserAuthStatus})(Register);
