export const selectModalStatus = (state) => state.openModal;
export const selectPageTheme = (state) => state.pageTheme;
export const selectEditPanelStatus = (state) => state.layout.showEditPanel;

// mobile
export const selectMobEditingPanel = (state) => state.mobEditingPanel;
export const selectDrawerStatus = (state) => state.mobile.drawer;

// use on template resize from one size to another
export const selectTempRatioDiff = (state) => state.ratioDiff;

export const selectTooltipPopperStatus = (state) => state.openTooltipPopper;

export const selectCanvasPanelWidth = (state) => state.layout.canvasPanelWidth;

export const selectAdminLayouts = (state) => state.adminLayouts;
