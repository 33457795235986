import React, {useState, useLayoutEffect, useEffect} from "react";
import ValueLabel from "./common/ValueLabel";
import ShowLegends from "./common/ShowLegends";
import {useScaleLine} from "./hooks/useScaleLine";
import {ScaledChart, WrapChart} from "../chartStyles";
import {connect} from "react-redux";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import YScaleGrid from "./barProps/YScaleGrid";
import XScaleLabels from "./barProps/XScaleLabels";
import YCategoryLabels from "./common/YCategoryLabels";
import ColumnBar from "./barProps/ColumnBar";

const edgeWidthPercent = 20;
const BarChart = ({size, dataSeries, styles, tempScale, colorSet}) => {
  const {
    showBackground,
    background,
    gridColor,
    showGrid,
    gridStyle,
    showValue,
    showLegend,
    valueColor,
    fontSize,
    fontFamily,
    barSize,
    showXAxis,
    showYAxis,
    axisColor,
    showCategory,
    measurementColor,
    measurementFontSize,
    showMeasurement,
    scaleDistance,
    scaleType,
    scaleFormat,
    currencyUnit,
  } = styles;
  const [barHeight, setBarHeight] = useState(barSize);
  const [dataSet, setDataSet] = useState(dataSeries.slice(1));
  const seriesLength = dataSet[0].length - 1; // excluding the initial category label
  const [edgeSpace, setEdgeSpace] = useState((size.w * edgeWidthPercent) / 100);
  // series bar width + edges
  const [seriesBarWidth, setSeriesBarWidth] = useState(
    dataSet.length * (barHeight * seriesLength) + edgeSpace * 2
  );
  const [barMargin, setBarMargin] = useState(
    (size.h - seriesBarWidth) / (dataSet.length - 1)
  );
  const [highestPositiveValue, setHighestPositiveValue] = useState(0);
  const [lowestNegativeValue, setLowestNegativeValue] = useState(0);
  const [categoryLabels, setCategoryLabels] = useState([]);

  useLayoutEffect(() => {
    let arrValues = [];
    let categories = [];
    const dataSet = dataSeries.slice(1);
    setDataSet(dataSet);
    dataSet &&
      dataSet.map((datum, index) => {
        categories.push(datum[0]);
        datum.map((item, i) => {
          if (i !== 0 && item !== "") {
            arrValues.push(item);
          }
        });
      });

    setCategoryLabels(categories);
    const getMaxValue = Math.max(...arrValues);
    const getMinValue = Math.min(...arrValues);
    // highest must either be positive value or starts with zero
    const highestWithoutNegative = getMaxValue < 0 ? 0 : getMaxValue;
    setHighestPositiveValue(highestWithoutNegative);

    if (getMinValue < 0) {
      setLowestNegativeValue(getMinValue);
    } else {
      setLowestNegativeValue(0);
    }
  }, [dataSeries]);

  useLayoutEffect(() => {
    setBarHeight(barSize);
    setBarMargin(
      (size.h - (dataSet.length * (barSize * seriesLength) + edgeSpace * 2)) /
        (dataSet.length - 1)
    );
  }, [barSize, dataSet, fontSize]);

  useLayoutEffect(() => {
    if (dataSet.length > 2) {
      const calcMargin = size.h - dataSet.length * (barSize * seriesLength);
      setBarMargin(calcMargin / dataSet.length);
      setEdgeSpace(calcMargin / dataSet.length / 2);
    } else {
      const remaingSpace = size.h / 2 - barSize * seriesLength;
      setEdgeSpace(remaingSpace / 2);
      setBarMargin(remaingSpace);
    }
  }, [size.h, dataSet, barSize]);

  const {chartSpectrum, scaleLine, digitsLength} = useScaleLine({
    highestPositiveValue,
    lowestNegativeValue,
  });

  const [totalValueHeight, setTotalValueHeight] = useState(0);
  const [totalWidthInPx, setTotalWidthInPx] = useState({
    positive: 0,
    negative: 0,
  });

  useLayoutEffect(() => {
    const totalValueHeight = chartSpectrum.positive + chartSpectrum.negative;
    setTotalValueHeight(totalValueHeight);
    if (
      (chartSpectrum.positive || chartSpectrum.negative) &&
      totalValueHeight
    ) {
      setTotalWidthInPx({
        positive: (chartSpectrum.positive * size.w) / totalValueHeight,
        negative: (chartSpectrum.negative * size.w) / totalValueHeight,
      });
    }
  }, [size, chartSpectrum]);

  return (
    <WrapChart>
      <ScaledChart>
        <svg
          width={size.w}
          height={size.h}
          viewBox={`0 0 ${size.w} ${size.h}`}
          style={{background: showBackground ? background : undefined}}
        >
          {chartSpectrum &&
          // totalWidthInPx.positive &&
          (chartSpectrum.negative || chartSpectrum.positive) &&
          (totalWidthInPx.negative || totalWidthInPx.positive) ? (
            <g>
              {dataSet &&
                dataSet.map((items, index) => {
                  const datum = items.slice(1);
                  return datum.map((item, i) => {
                    const barY =
                      index *
                        (barSize + barSize * (seriesLength - 1) + barMargin) +
                      i * barSize;
                    const barX =
                      item > 0
                        ? totalWidthInPx.negative
                        : (totalWidthInPx.negative *
                            (chartSpectrum.negative + Number(item))) /
                          chartSpectrum.negative;
                    const itemWidth =
                      item > 0
                        ? (item * totalWidthInPx.positive) /
                          chartSpectrum.positive
                        : (Math.abs(item) * totalWidthInPx.negative) /
                          chartSpectrum.negative;
                    return (
                      <g key={i}>
                        <ColumnBar
                          fill={colorSet[i]}
                          x={barX}
                          y={barY + edgeSpace}
                          width={itemWidth}
                          height={barSize}
                          chartWidth={size.w}
                        />
                        {showValue && item !== 0 ? (
                          <ValueLabel
                            key={index}
                            x={barX + itemWidth + scaleDistance}
                            y={barY + edgeSpace + barSize / 2}
                            color={valueColor}
                            width={barSize}
                            value={item}
                            fontSize={fontSize}
                            fontFamily={fontFamily}
                            scaleFormat={scaleFormat}
                            textAnchor="start"
                            alignmentBaseline="central"
                            dominantBaseline="central"
                          />
                        ) : undefined}
                      </g>
                    );
                  });
                })}
            </g>
          ) : undefined}

          {showCategory ? (
            <YCategoryLabels
              styles={styles}
              labels={categoryLabels}
              barMargin={barMargin}
              barWidth={barSize}
              edgeSpace={barMargin / 2}
              seriesLength={seriesLength}
            />
          ) : undefined}

          {showGrid ? (
            scaleLine && Object.keys(scaleLine).length !== 0 ? (
              <g>
                <YScaleGrid
                  color={gridColor}
                  gridStyle={gridStyle}
                  item={scaleLine}
                  height={size.h}
                  gap={(scaleLine.acc * size.w) / scaleLine.total}
                />
              </g>
            ) : undefined
          ) : undefined}

          {showXAxis && totalValueHeight ? (
            <g>
              <line
                x1={0}
                x2={size.w}
                y1={size.h}
                y2={size.h}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              />
            </g>
          ) : undefined}

          {showYAxis ? (
            <g>
              <line
                x1={totalWidthInPx.negative}
                x2={totalWidthInPx.negative}
                y1={0}
                y2={size.h}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              ></line>
            </g>
          ) : undefined}

          {showMeasurement &&
          (chartSpectrum.positive || chartSpectrum.negative) ? (
            scaleLine.acc && Object.keys(scaleLine).length !== 0 ? (
              <g>
                <XScaleLabels
                  fontSize={measurementFontSize}
                  fontFamily={fontFamily}
                  color={measurementColor}
                  tickColor={axisColor}
                  item={scaleLine}
                  gap={(scaleLine.acc * size.w) / scaleLine.total}
                  lowestValue={
                    -Math.abs(chartSpectrum.negative) < 0
                      ? -Math.abs(chartSpectrum.negative)
                      : 0
                  }
                  digitsLength={digitsLength}
                  scaleDistance={scaleDistance}
                  scaleType={scaleType}
                  currencyUnit={currencyUnit}
                  scaleFormat={scaleFormat}
                  height={size.h}
                />
              </g>
            ) : undefined
          ) : undefined}
        </svg>

        {showLegend ? (
          <ShowLegends
            items={dataSeries[0].slice(1)}
            chartSize={size}
            styles={styles}
            colorSet={colorSet}
          />
        ) : undefined}
      </ScaledChart>
    </WrapChart>
  );
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(BarChart);
