import React from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import UserDashboardHoc from "../hoc/UserDashboardHoc";
import UserFolderHoc from "../hoc/UserFolderHoc";
import DashboardPanel from "../components/dashboardPanel/DashboardPanel";
import {dashboardSidebarWidth} from "../components/sidebar/UserSidebar";
import CalendarPanel from "../components/dashboardPanel/calendar/CalendarPanel";

const Calendar = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <body style="overflow: hidden;" />
      </Helmet>

      <UserDashboardHoc>
        <UserFolderHoc>
          <div
            style={{
              width: dashboardSidebarWidth,
              height: window.innerHeight - 57,
            }}
          />
        </UserFolderHoc>
        <DashboardPanel>
          <CalendarPanel />
        </DashboardPanel>
      </UserDashboardHoc>
    </HelmetProvider>
  );
};

export default Calendar;
