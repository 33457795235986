import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {red4} from "../../../../../oat-color-variables";
import {ArrowBack} from "../../../../../oat-svg-icons";
import {ButtonSpinner} from "../../../../../oat-ui";
import {Radio} from "../../../../../oat-ui/styles/RadioStyles";
import {
  updateGuestAccessLevel,
  setGuestList,
} from "../../../../store/actions/template/template.action";
import {selectTemplateID} from "../../../../store/selectors/template/template.selector";
import SpecialButton from "../../../../ui/inputs/SpecialButton";

const EditGuestAccessLevel = ({
  guest,
  username,
  tempID,
  updateGuestAccessLevel,
  setGuestList,
  goBack,
  close,
}) => {
  const [processing, setProcessing] = useState({
    status: false,
    type: null,
  });
  const {email, accessLevel, sharedID, removedUser} = guest;
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(
    accessLevel ? accessLevel : "read-write"  // "write-comment"
  );
  const [error, setError] = useState({status: false, message: null});
  const [activateRemoveAccess, setActivateRemoveAccess] = useState();

  const selectReadOnly = () => {
    setSelectedAccessLevel("read-only");
  };

  const selectReadComment = () => {
    setSelectedAccessLevel("read-comment");
  };

  const selectWriteComment = () => {
    setSelectedAccessLevel("read-write"); // "write-comment"
  };

  const updateChanges = async () => {
    if (!processing.status) {
      setProcessing({status: true, type: "update"});

      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/guests`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            accessLevel: selectedAccessLevel,
          }),
        }
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "failed") {
            setError({
              status: true,
              message: data.message,
            });
            setProcessing({status: false, type: null});
          } else {
            setProcessing({status: false, type: null});
            const payload = {email, accessLevel: selectedAccessLevel};
            await updateGuestAccessLevel(payload);
            await close();
          }
        })
        .catch((error) => {
          setError({
            status: true,
            message: error.message,
          });
          setProcessing({status: false, type: null});
        });
    }
  };

  const handleRemoveAccess = async () => {
    if (!processing.status) {
      setProcessing({status: true, type: "remove-access"});
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/shared/designs/${sharedID}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempID,
            removedUserEmail: guest.email,
          }),
        }
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "success") {
            setGuestList(data.guestList);
            setProcessing({status: false, type: null});
            await close();
          } else {
            setError({
              status: true,
              message: data.message,
            });
            setProcessing({status: false, type: null});
          }
        })
        .catch((error) => {
          setError({
            status: true,
            message: error.message,
          });
          setProcessing({status: false, type: null});
        });
    }
  };

  return (
    <Layout>
      <WrapSvg onClick={goBack}>{ArrowBack}</WrapSvg>

      <Field>
        <div className="guest-field-name">Email</div>
        <div className="guest-field-value">{email}</div>
      </Field>

      <Field>
        <div className="guest-field-name">Access Level</div>
        <div className="guest-field-value">
          <Radio onClick={selectReadOnly}>
            <RadioButton active={selectedAccessLevel === "read-only"}>
              <div />
            </RadioButton>
            <span>Read Only</span>
          </Radio>
          {/*  <Radio onClick={selectReadComment}>
            <RadioButton active={selectedAccessLevel === "read-comment"}>
              <div />
            </RadioButton>
            <span>Read & Comment</span>
          </Radio> */}
          <Radio onClick={selectWriteComment}>
            <RadioButton active={selectedAccessLevel === "read-write"}>
              <div />
            </RadioButton>
            <span>Read & Write</span>
          </Radio>
        </div>
      </Field>

      <ButtonSection>
        <RemoveAccess onClick={handleRemoveAccess}>
          <div style={{marginRight: 12}}>Remove Access</div>
          {processing.status && processing.type === "remove-access" ? (
            <ButtonSpinner />
          ) : undefined}
        </RemoveAccess>
        <SpecialButton
          onClick={updateChanges}
          disabled={processing.status && processing.type === "update"}
          styles={{width: 120}}
        >
          {processing.status && processing.type === "update" ? (
            <ButtonSpinner />
          ) : (
            "Update"
          )}
        </SpecialButton>
      </ButtonSection>

      {error.status ? <Error>{error.message}</Error> : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: 12px;
`;

const Field = styled.div`
  display: flex;
  margin-bottom: 34px;
  .guest-field-value,
  .guest-field-name {
    flex: 1;
  }
`;

const RadioButton = styled.div`
  width: 16px;
  height: 16px;
  background: #fff;
  margin-right: 12px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1px ${({active}) => (active ? "#b1b8f7" : "#c9ccd7")};
  div {
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${({active}) => (active ? "#5065ff" : "#b2b4bd")};
    border-radius: 50%;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const WrapSvg = styled.div`
  width: 25px;
  height: 25px;
  fill: ${({theme}) => theme.svgIconColor};
  margin-bottom: 32px;
  cursor: pointer;
`;

const Error = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${red4};
  font-size: 0.9rem;
  margin: 22px 0;
`;

const RemoveAccess = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  color: #ff7a7a;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    tempID: selectTemplateID(state.designTemplate),
    username: selectUserInfo(state.authUser).username,
  };
};

export default connect(mapStateToProps, {updateGuestAccessLevel, setGuestList})(
  EditGuestAccessLevel
);
