import styled from "styled-components";

export const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  align-items: flex-start;
  height: 65px;
  background: ${({theme}) => theme.deskPrimaryBg2};
`;

export const Tabs = styled.ul`
  width: 100%;
  text-align: center;
  display: flex;
  padding: 0;
  list-style: none;
  text-decoration: none;
`;

export const TabItem = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: left 1.3s ease;
  cursor: pointer;
  padding: 14px 0;
  background: ${({theme}) => theme.deskPrimaryBg2};
  span {
    font-size: inherit;
    font-weight: 600;
  }
`;

export const WrapTabs = styled.div`
  z-index: 400;
  width: 100%;
`;
