import React from "react";
import {Button, Modal} from "../../../../oat-ui";
import styled from "styled-components";
import {black1} from "../../../../oat-color-variables";
import { useNavigate } from "react-router-dom";

const RequiresUpgradeToPro = ({close}) => {
  let navigate = useNavigate();

  const onUpgradeToPro = () => {
    navigate(`?upgrade=true`, { replace: true });
    close();
  }

  return (
    <Modal width={390} height="auto" close={close}>
      <Layout>
        <Header>Upgrade to Pro</Header>
        <Description>
          Get complete features and more storage by upgrading.
        </Description>
        <WrapButton>
          <Button onClick={onUpgradeToPro} styles={{height: 36}}>Upgrade</Button>
        </WrapButton>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
  text-align: center;
`;

const Header = styled.div`
  font-size: 1.6rem;
  margin: 12px 6px 28px;
  color: ${black1};
`;

const Description = styled.div`
  font-size: 0.92rem;
  margin-bottom: 14px;
  color: ${black1};
  max-width: 75%;
`;

const WrapButton = styled.div`
  margin: 32px 0 0;
  width: 100%;
`;

export default RequiresUpgradeToPro;
