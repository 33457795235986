import React, {useState} from "react";
import {Cancel, WrapButton} from "./folderStyles";
import Button from "../../../../oat-ui/core/Button";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {dispatchDeleteFolder} from "../../../store/actions/folder.action";
import {red1, red5} from "../../../../oat-color-variables";
import {Error} from "./folderStyles";
import {selectActiveWorkSpace} from "../../../store/selectors/userDashboard.selector";
import {ButtonSpinner} from "../../../../oat-ui";
import {
  BottomSection,
  ContentSection,
  Footer,
  Header,
  Message,
} from "../../reuse/dashboardUI/modalStyles";
import {handleDeleteFolder} from "./func/handleDeleteFolder";

const DeleteFolder = ({
  user,
  selectedFolder,
  workspaceID,
  dispatchDeleteFolder,
  close,
}) => {
  let navigate = useNavigate();
  let params = useParams();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const { folderType } = selectedFolder;

  const handleRemove = async () => {
    if (!processing) {
      setProcessing(true);
      if (error.status) {
        setError({status: false, message: null});
      }
      const payload = {
        folderID: selectedFolder.folderID,
        folderType,
        subfolderID: selectedFolder?.subfolderID,
      };

      await handleDeleteFolder({
        workspaceID,
        username: user.username,
        payload,
        selectedFolder,
      })
        .then((data) => {
          if (data.status === "success") {
            dispatchDeleteFolder({
              ...payload,
              isSubfolder: selectedFolder?.isSubfolder,
            });
            setProcessing(false);

            if (params.folderID === selectedFolder.folderID) {
              navigate(`/dashboard/${workspaceID}/designs/home`);
            } else if (params.folderID === selectedFolder.subfolderID) {
              navigate(`/dashboard/${workspaceID}/designs/home`);
            };

            close();
          } else {
            setProcessing(false);
            setError({status: true, message: data.message});
          }
        })
        .catch((err) => {
          setProcessing(false);
          setError({status: true, message: "Something went wrong"});
        });
    }
  };

  const handleClose = (e) => {
    close();
    e.stopPropagation();
  };

  return (
    <div style={{width: 266}}>
      <ContentSection>
        <Header style={{color: red1}}>Confirm Delete</Header>

        <Message>All data within it will be deleted.</Message>

        {error.status ? <Error>{error.message}</Error> : undefined}
      </ContentSection>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Cancel onClick={handleClose}>Cancel</Cancel>
          <WrapButton>
            <Button
              styles={{width: 82, background: red5}}
              onClick={handleRemove}
              disabled={processing}
            >
              {!processing ? "Delete" : <ButtonSpinner />}
            </Button>
          </WrapButton>
        </BottomSection>
      </Footer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspaceID: selectActiveWorkSpace(state.userDashboard).ID,
  };
};

export default connect(mapStateToProps, {dispatchDeleteFolder})(DeleteFolder);
