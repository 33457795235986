import React, {useState} from "react";
import styled from "styled-components";
import {red1} from "../../../../../../../oat-color-variables";
import {LeftArrow, Trash} from "../../../../../../../oat-svg-icons";
import {Spinner} from "../../../../../../../oat-ui";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import {CustomLinkError, domainLink, WrapSpinner} from "./CreateCustomLink";
import CustomLinkPasscode from "./CustomLinkPasscode";

const CustomLinkDetails = ({
  item,
  onUpdate,
  onDelete,
  back,
  error,
  processing,
}) => {
  const [link, setLink] = useState({
    linkSlug: item.linkSlug,
    linkName: item.linkName,
    slugID: item.slugID,
    uid: item.uid,
    passcode: item.passcode,
  });
  const [processingType, setProcessingType] = useState("update");

  const handleLinkInputChange = (e) => {
    const {value} = e.target;
    const newSlug = value.replace(/\W+/g, "-").toLowerCase();
    setLink({
      ...link,
      linkName: value,
      linkSlug: newSlug + "-" + item.slugID,
    });
  };

  const togglePasscodeStatus = async() => { 
    const updatedItem = {
      ...link,
      passcode: {
        ...link.passcode,
        enabled: !link.passcode.enabled,
      },
    };
    setLink(updatedItem);
    if(link.passcode.enabled) { // if disable
      if (!processing) {
        await onUpdate(updatedItem);
        setProcessingType("update");
      }
    } 
  };

  const handlePasscodeChange = (e) => {
    const {value} = e.target;
    setLink({
      ...link,
      passcode: {
        ...link.passcode,
        value,
      },
    });
  };

  const handleUpdate = () => {
    if (link.linkName !== "" && !processing) {
      onUpdate(link);
      setProcessingType("update");
    }
  };

  const handleDelete = () => {
    if (!processing) {
      onDelete(link.uid);
      setProcessingType("delete");
    }
  };

  return (
    <Layout>
      <WrapSvg onClick={back}>{LeftArrow}</WrapSvg>
      <WrapItem>
        <LinkName
          value={link.linkName}
          placeholder="Link Name"
          onChange={handleLinkInputChange}
        />

        <Link>
          <span style={{paddingRight: 18}}>Link:</span>{" "}
          {domainLink + link.linkSlug}
        </Link>

        <CustomLinkPasscode
          passcode={link.passcode}
          onToggleStatus={togglePasscodeStatus}
          onPasscodeChange={handlePasscodeChange}
        />

        <SaveSection>
          <div style={{display: "flex", alignItems: "center"}}>
            <WrapDeleteSvg onClick={handleDelete} processing={processing}>
              {Trash}
            </WrapDeleteSvg>
            {processing && processingType === "delete" ? (
              <WrapSpinner style={{marginRight: 22}}>
                <Spinner />
              </WrapSpinner>
            ) : undefined}
          </div>

          <SaveBtn>
            {processing && processingType === "update" ? (
              <WrapSpinner style={{marginRight: 22}}>
                <Spinner />
              </WrapSpinner>
            ) : undefined}
            <SpecialButton
              onClick={handleUpdate}
              disabled={link.linkName === "" || processing}
            >
              Update
            </SpecialButton>
          </SaveBtn>
        </SaveSection>

        {error.status ? (
          <CustomLinkError>{error.message}</CustomLinkError>
        ) : undefined}
      </WrapItem>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 26px;
`;

const WrapSvg = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: ${({theme}) => theme.svgIconColor};
`;

const WrapItem = styled.div`
  font-size: 0.92rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  margin-top: 32px;
`;

export const LinkName = styled.input`
  width: 100%;
  font-size: 1rem;
  margin-bottom: 22px;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid #4355b1;
`;

const Link = styled.div`
  font-size: 1rem;
  color: ${({theme}) => theme.deskSecondaryTxt2};
`;

const SaveSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

const SaveBtn = styled.div`
  height: 36px;
  display: flex;
`;

const WrapDeleteSvg = styled.div`
  width: 18px;
  height: 18px;
  fill: ${red1};
  margin-right: 14px;
  opacity: ${({processing}) => (processing ? 0.4 : 1)};
  cursor: ${({processing}) => (!processing ? "pointer" : "not-allowed")};
`;

export default CustomLinkDetails;
