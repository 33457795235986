import React from 'react';
import { WrapIconItem, WrapItem } from '../../reusableStyles/reusableStyles';

const DisplayPurchasedPack = ({item, onSelect}) => {    
    const handleClick = () => {
      onSelect(item.item.productID, item.category);
    };
  
    return (
      <WrapIconItem>
        <WrapItem onClick={handleClick}>
          <img src={`https://oat-images-icons.s3.amazonaws.com/${item.item.productImg}`} alt="" />
        </WrapItem>
      </WrapIconItem>
    );
  };

export default DisplayPurchasedPack;