import React from "react";

const DisplayChartData = ({column, outerIndex, innerIndex, onSelectCell}) => {
  const handleClick = () => {
    onSelectCell({row: outerIndex, col: innerIndex});
  };
  return (
    <p
      style={{
        width: outerIndex !== 0 ? "100%" : undefined,
        minWidth: 100,
        minHeight: 22,
        userSelect: "none",
      }}
      onClick={handleClick}
    >
      {column === undefined || column === null || column === ""
        ? "N/A"
        : column}
    </p>
  );
};

export default DisplayChartData;
