const groupSelectionColors = [
  "#ff5656",
  "#5671ff",
  "#6499a0",
  "#b8609e",
  "#42774e",
  "#848282",
  "#c68149",
];

function getColorForUser(indexOrder) {
  let setColor = groupSelectionColors[0];
  if (indexOrder === 0) {
    setColor = groupSelectionColors[0];
  } else if (indexOrder <= groupSelectionColors.length) {
    setColor = groupSelectionColors[indexOrder];
  } else {
    setColor =
      groupSelectionColors[
        Math.floor(Math.random() * groupSelectionColors.length)
      ];
  }

  return setColor;
}

export function setUserOnline() {
  return function (state, action) {
    const {username, name, connectionId, noOfUsers, avatar} = action.payload;
    const setColor = getColorForUser(Object.keys(state.usersOnline).length);
    return {
      ...state,
      usersOnline: {
        ...state.usersOnline,
        noOfUsers,
        users: {
          ...state.usersOnline.users,
          [username]: {
            connectionId,
            name,
            avatar,
            activeSlide: 0, // "evAgTG",
            activeField: null, //  "ic9nI8", // null
            active: false,
            color: setColor,
            groupSelection: {
              keys: [], // "ic9nI8", "re7RZV"
              selection: {
                show: false,
                offset: {x: null, y: null},
                size: {w: 0, h: 0},
                orgOffset: {x: null, y: null},
                orgSize: {w: 0, h: 0},
              },
              initFieldValues: {},
            },
          },
        },
      },
    };
  };
}

export function setExistingOnlineUsers() {
  return function (state, action) {
    const newState = {...state.usersOnline.users};

    Object.entries(action.users).map(([id, user], i) => {
      const setColor = getColorForUser(i);
      newState[user.username] = {
        connectionId: id,
        name: user.name,
        avatar: user.avatar,
        activeSlide: 0,
        activeField: null,
        active: false,
        color: setColor,
        groupSelection: {
          keys: [],
          selection: {
            show: false,
            offset: {x: null, y: null},
            size: {w: 0, h: 0},
            orgOffset: {x: null, y: null},
            orgSize: {w: 0, h: 0},
          },
          initFieldValues: {},
        },
      };
    });
    return {
      ...state,
      // activeField: -1,
      usersOnline: {
        ...state.usersOnline,
        users: {
          ...newState,
        },
      },
    };
  };
}

export function removeUserOnline() {
  return function (state, action) {
    const newState = {...state.usersOnline};

    let currentConnections = [];
    Object.entries(action.payload).map(([id, user], i) => {
      currentConnections.push(id);
    });

    Object.entries(state.usersOnline.users).map(([id, user], i) => {
      if (!currentConnections.includes(user.connectionId)) {
        delete newState.users[id];
        newState.noOfUsers = state.usersOnline.noOfUsers - 1;
      }
    });

    return {
      ...state,
      usersOnline: newState,
    };
  };
}
