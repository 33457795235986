import React from "react";
import {connect} from "react-redux";
import {selectThemeBuilder} from "../../../store/selectors/template/theme.selector";
import PopoverColorBox from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import {ColorsSection, WrapItem} from "./buildThemeStyle";
import {updateThemeBuilderFields} from "../../../store/actions/template/theme.action";
import {InputName} from "../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
import ThemeBuilderFontFamily from "./reusable/ThemeBuilderFontFamily";
import RangeSlider from "../../editDesign/editingPanel/panelTypes/shared/inputs/RangeSlider";
import {textEditProps} from "../../editDesign/editingPanel/panelTypes/desktop/text/EditText";

const TableThemeBuilder = ({table, updateThemeBuilderFields}) => {
  const handleFieldUpdate = (key, value) => {
    updateThemeBuilderFields({
      category: "table",
      key,
      value,
    });
  };

  const changeHeaderBg = (value) => {
    handleFieldUpdate("headerBg", value);
  };

  const changeHeaderColor = (value) => {
    handleFieldUpdate("headerColor", value);
  };

  const changeTextColor = (value) => {
    handleFieldUpdate("color", value);
  };

  const changeBorderColor = (value) => {
    handleFieldUpdate("borderColor", value);
  };

  const changeBgColor = (value) => {
    handleFieldUpdate("background", value);
  };

  const changeFontFamily = (item) => {
    handleFieldUpdate("fontFamily", item.fontFamily);
    handleFieldUpdate("src", item.src);
    if (item.custom) handleFieldUpdate("custom", item.custom);
    if (item.format) {
      handleFieldUpdate("format", item.format);
    }
  };

  const handleDebounceColor = () => {
    // leave empty
  };

  const onUpdateLetterSpacing = (value) => {
    handleFieldUpdate("letterSpacing", Number(value));
  };

  const onUpdateLineHeight = (value) => {
    handleFieldUpdate("lineHeight", Number(value));
  };

  const changeStripeColor = (value) => {
    handleFieldUpdate("stripeColor", value);
  };

  const changeStripeBg = (value) => {
    handleFieldUpdate("stripeBackground", value);
  };

  return (
    <>
      <WrapItem>
        <ColorsSection>
          <InputName>Header Background</InputName>
          <PopoverColorBox
            value={table.headerBg}
            updateFieldStyles={changeHeaderBg}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Header Text</InputName>
          <PopoverColorBox
            value={table.headerColor}
            updateFieldStyles={changeHeaderColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Text Color</InputName>
          <PopoverColorBox
            value={table.color}
            updateFieldStyles={changeTextColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ThemeBuilderFontFamily
          fontFamily={table.fontFamily}
          fontWeight={table.fontWeight}
          fontStyle={table.fontStyle}
          onChange={changeFontFamily}
        />
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Border Color</InputName>
          <PopoverColorBox
            value={table.borderColor}
            updateFieldStyles={changeBorderColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem style={{paddingBottom: "2rem"}}>
        <ColorsSection>
          <InputName>Table Background</InputName>
          <PopoverColorBox
            value={table.background}
            updateFieldStyles={changeBgColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <div style={{paddingBottom: "2rem"}}>
        <RangeSlider
          title="Letter Spacing"
          fieldType="letterSpacing"
          value={table.letterSpacing}
          max={textEditProps.letterSpacing.max}
          min={textEditProps.letterSpacing.min}
          step={textEditProps.letterSpacing.step}
          onItemChange={onUpdateLetterSpacing}
        />
      </div>

      <div style={{paddingBottom: "2rem"}}>
        <RangeSlider
          title="Line Height"
          fieldType="lineHeight"
          value={table.lineHeight}
          max={textEditProps.lineHeight.max}
          min={textEditProps.lineHeight.min}
          step={textEditProps.lineHeight.step}
          onItemChange={onUpdateLineHeight}
        />
      </div>

      <WrapItem>
        <ColorsSection>
          <InputName>Stripe Background</InputName>
          <PopoverColorBox
            value={table.stripeBackground}
            updateFieldStyles={changeStripeBg}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Stripe Text</InputName>
          <PopoverColorBox
            value={table.stripeColor}
            updateFieldStyles={changeStripeColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    table: selectThemeBuilder(state.designTemplate)["table"],
  };
};

export default connect(mapStateToProps, {updateThemeBuilderFields})(
  TableThemeBuilder
);
