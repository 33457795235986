import React, {useCallback, useEffect, useState, useRef} from "react";
import nodeFetch from "node-fetch";
import {Spinner} from "../../../../../../oat-ui";
import {columnGap, Grid} from "./ReuseStyles";
import styled from "styled-components";
import SearchImage from "./SearchImage";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import {createClient} from 'pexels';

const client = createClient('JXYCN1c9X6gpKwKU8HqgqNQ3s6JDayRRobxQcWCfgQb3nRpOfmtKjtWz');

const DisplayImage = ({item, onSelect, imageWidth}) => {
  const handleClick = () => {
    onSelect(item);
  };

  const newHeight = (item.height * imageWidth) / item.width;

  return (
    <WrapItem style={{width: imageWidth, height: newHeight}}>
      <img src={item.src.medium} onClick={handleClick} />
      <a href={item.photographer_url} target="_blank">
        by {item.photographer}
      </a>
    </WrapItem>
  );
};

const Pexels = ({
  replaceImg,
  onUploadImgByLink,
  onReplaceImgByLink,
  scrollListToTop,
}) => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [isLastItem, setIsLastItem] = useState(false);
  const [searchQuery, setSearchQuery] = useState("cat");
  const [error, setError] = useState("");
  const [fetching, setFetching] = useState(false);
  const panelRef = useRef();
  const windowSize = getWindowSize();

  const fetchPexelsImages = () => {
    client.photos.search({ query: searchQuery, page, per_page: 30 })
      .then((result) => {
        if(result?.photos.length > 0) {
          setFetching(false);                           
          if (error !== "") setError("");
          if (result?.next_page && result?.next_page !== "") {
              setIsLastItem(false);
          }
          setImages([...images, ...result.photos]);
          return {response: "success"};         
        } else {
          setError(`Nothing was found.`);
          return {response: "error"};
        }
      })
      .catch((e) => {
        setError(`Something went wrong.`);
      });
  };

  useEffect(() => {
    setFetching(true);
    fetchPexelsImages();
  }, []);

  const handleSelection = (item) => {
    const payload = {
      naturalWidth: item.width,
      naturalHeight: item.height,
      src: item.src.original,
      external: true
    };
    if (!replaceImg) {
      onUploadImgByLink(payload);
    } else {
      onReplaceImgByLink(payload);
    }
  };

  const [imageWidth, setImageWidth] = useState();
  const noOfColumns = windowSize.width >= 850 ? 3 : 2;
  useEffect(() => {
    const panelWidth = panelRef.current.offsetWidth;
    setImageWidth((panelWidth - columnGap * 2) / noOfColumns);
  }, [noOfColumns, windowSize.width]);

  useEffect(() => {
    fetchPexelsImages();
  }, [page]);

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (fetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastItem) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [fetching]
  );

  const handleQueryChange = (e) => {
    const {value} = e.target;
    setSearchQuery(value);
    setImages([]);
    scrollListToTop();
  };

  useEffect(() => {
    if (searchQuery !== "") {
      setFetching(true);
      const delayDebounceFn = setTimeout(() => {
        setPage(1);
        client.photos.search({ query: searchQuery, per_page: 30 })
          .then((result) => {
            setFetching(false);
            if(result?.photos.length > 0) {
                setFetching(false);                           
                if (error !== "") setError("");
                if (result?.next_page && result?.next_page !== "") {
                    setIsLastItem(false);
                }
                setImages([...images, ...result.photos]);
                return {response: "success"};         
            } else {
                setError(`Nothing was found.`);
                return {response: "error"};
            }
          })
          .catch((e) => {});
      }, 3000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchQuery]);

  return (
    <div ref={panelRef} style={{width: "100%"}}>
      <SearchImage value={searchQuery} onChange={handleQueryChange} />
      {!fetching ? (
        <Grid>
          {windowSize.width >= 850 ? (
            <>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 1) {
                      return (
                        <DisplayImage
                          key={i}
                          item={item}
                          onSelect={handleSelection}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 2) {
                      return (
                        <DisplayImage
                          key={i}
                          item={item}
                          onSelect={handleSelection}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
              <div>
                {images &&
                  images.map((item, i) => {
                    // if (images.length === i + 1) {
                    //   return <div key={i} ref={lastItemRef} />;
                    // } else
                    if ((i + 1) % 3 === 0) {
                      return (
                        <DisplayImage
                          key={i}
                          item={item}
                          onSelect={handleSelection}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
            </>
          ) : (
            <>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 1) {
                      return (
                        <DisplayImage
                          key={i}
                          item={item}
                          onSelect={handleSelection}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 2) {
                      return (
                        <DisplayImage
                          key={i}
                          item={item}
                          onSelect={handleSelection}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
            </>
          )}
          <div ref={lastItemRef} />
          <div style={{height: 300}} />
        </Grid>
      ) : (
        <div style={{width: "100%", marginTop: "20%", height: 500}}>
          <Spinner />
        </div>
      )}

      {error !== "" ? (
        <WrapError>
          <Error> {error} </Error>
        </WrapError>
      ) : undefined}
    </div>
  );
};

const WrapError = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 84px;
`;

const Error = styled.div`
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 1.1rem;
`;

const WrapItem = styled.div`
  position: relative;
  margin-bottom: 30px;
  background: #dedede;
  a {
    font-size: 0.8rem;
    color: ${({theme}) => theme.deskPrimaryTxt};
    position: absolute;
    bottom: -20px;
    left: 0px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export default Pexels;
