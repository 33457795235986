const FinishDarkTheme = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    style={{transform: `scale(2.5)`}}
  >
    <defs>
      <radialGradient
        id="finish-33ad-radial-gradient"
        cx="88.66"
        cy="96.46"
        r="55.35"
        gradientTransform="matrix(1, 0, 0, -1, 0, 130)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f3f348" />
        <stop offset="0.22" stopColor="#c8e82e" />
        <stop offset="0.82" stopColor="#6db96d" />
        <stop offset="1" stopColor="#31b46c" />
      </radialGradient>
    </defs>
    <title>Mark as Finish</title>
    <g>
      <g>
        <path
          fill="url(#finish-33ad-radial-gradient)"
          d="M90.4,47.9h0l-1.2-1.8a30.9,30.9,0,1,0,1.2,1.8Z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#fff"
          d="M54.2,75.6,52.7,74,77.4,50.2a3,3,0,0,1,4.3.1h0a3,3,0,0,1-.1,4.3L59.7,75.7A3.9,3.9,0,0,1,54.2,75.6Z"
        />
        <path
          fill="#fff"
          d="M61.3,74.2l-2.4,2.3a2.9,2.9,0,0,1-4.1-.1l-8.4-8.7a3,3,0,0,1,.1-4.3h.1a3,3,0,0,1,4.3.1Z"
        />
      </g>
    </g>
  </svg>
);

export default FinishDarkTheme;
