import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import LayoutPosition from "../common/LayoutPosition";
import {SplitLine} from "../common/SplitLine";
import {WrapColorBox} from "../common/WrapColorStyles";
import {Title} from "../../shared/reuseStyles/Title";
import EditOpacity from "../common/EditOpacity";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import { HorizontalFlip } from "../../../../../../ui/svg/Flip";
import { transformImgOffset } from "../../../../../../store/actions/fields/image.action";
import RangeSlider from "../../shared/inputs/RangeSlider";
import FlipVideo from "./FlipVideo";
import IncrementDropDown from "../../../../../../ui/inputs/IncrementDropDown";

const options = [
  {value: "10px", display: "10px"},
  {value: "30px", display: "30px"},
  {value: "50px", display: "50px"},
  {value: "100px", display: "100px"},
  {value: "120px", display: "120px"},
  {value: "150px", display: "150px"},
  {value: "100px", display: "100%"}
]
const EditVideo = ({
  field,
  slideID,
  updateFieldStyles,
  updateFieldCollaboration,
  storeFieldUndoRedo,
  transformImgOffset
}) => {
  const {styles, subtype,} = field || {};
  const {rotate, zIndex, roundedBorder, showBorder, borderColor, lock, scaleX, borderRadius, autoPlay} = styles || {};

  const handleStyleUpdate = (type, value) => {
    storeFieldUndoRedo();
    updateFieldStyles({
      active: {slideID, fieldID: field?.key},
      type,
      value,
    });
  };

  const handleEmitValue = () => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const changeRoundedBorder = async (value) => {
    await handleStyleUpdate("roundedBorder", value);
    await handleEmitValue();
  };

  const changeShowBorderStatus = async (value) => {
    await handleStyleUpdate("showBorder", value);
    await handleEmitValue();
  };

  const changeBorderColor = (value) => {
    handleStyleUpdate("borderColor", value);
  };

  const onColorDebounce = (value) => {
    handleEmitValue();
  };

  const updateBorderRadius = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID: field?.key},
      type: 'borderRadius',
      value: Number(value),
    });
  }

  const changeAutoPlayStatus = async (value) => {
    await handleStyleUpdate("autoPlay", value);
    await handleEmitValue();
  }

  return (
    <Wrapper>
      <LayoutPosition rotate={rotate} locked={lock} />

      <SplitLine />

      {subtype === "uploaded" ?
        <div style={{paddingBottom: "2em"}}>
          <Switch
            title="Auto Play"
            value={autoPlay}
            onItemChange={changeAutoPlayStatus}
            checked={autoPlay}
          />
        </div>
      : undefined}

      {subtype === "embedded" ? 
        <Switch
          title="Rounded Border"
          value={roundedBorder}
          onItemChange={changeRoundedBorder}
          checked={roundedBorder}
        /> 
      : undefined}

      {subtype === "uploaded" ?
        <div>
          <RangeSlider
            title="Rounded"
            value={borderRadius ? borderRadius : 0}
            min={0}
            max={100}
            step={1}
            onItemChange={updateBorderRadius}
            fieldType="borderRadius"
          />
          {/* <Wrapper>
            <Title>Rounded</Title>

            <WrapDropDown>
              <IncrementDropDown
                onSelect={changeFontSize}
                selected={
                  borderRadius ? borderRadius : 0
                  // valueSet[1] == 0
                  //   ? Number(valueSet[0])
                  //   : Number((value * scale).toFixed(1))
                }
                options={options}
                minWidth={70}
                overflowY="scroll"
                height={160}
                onClose={onClose}
              />
            </WrapDropDown>
          </Wrapper> */}
        </div> 
      : undefined}

      <div style={{paddingTop: "2em"}}>
        <Switch
          title="Display Border"
          value={showBorder}
          onItemChange={changeShowBorderStatus}
          checked={showBorder}
        />
      </div>

      {showBorder ? (
        <WrapColorBox style={{paddingTop: "2em"}}>
          <Title>Color</Title>
          <PopoverColorBox
            value={borderColor}
            updateFieldStyles={changeBorderColor}
            onDebounce={onColorDebounce}
          />
        </WrapColorBox>
      ) : undefined}

      <SplitLine />

      {subtype === "uploaded" ? (
        <>
          <FlipVideo />
          <SplitLine /> 
        </>
      ) : undefined}

      <div style={{paddingBottom: "2em"}}>
        <EditOpacity value={styles?.opacity} />
      </div>

      {/* add shadow later */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 120px;
`;

const mapStateToProps = (state) => {
  return {
    field: selectActiveFieldProps(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
  transformImgOffset
})(EditVideo);
