import React, {useState, useEffect} from "react";
import {DesignTemplate} from "../../../packages/oat-design-template";
import {selectUserInfo} from "../../../redux/user/authUser";
import {connect} from "react-redux";
import {useParams, useLocation} from "react-router";

const CustomerEditTemp = ({user}) => {
  let params = useParams();
  const {tempID} = params;
  const query = new URLSearchParams(useLocation().search);
  const mobileView = query.get("mobileView");
  const [isSaving, setIsSaving] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;
  // timeoutId = setTimeout(() => {
  //   // If the request is taking too long, cancel it
  //   abortController.abort();
  //   setIsSaving(false);
  // }, 45000); // 45 seconds

  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const saveUserTemplate = async (item) => {
    if (!isSaving) {
      setIsSaving(true);

      const newTimeoutId = setTimeout(() => {
        // If the request is taking too long, cancel it
        abortController.abort();
        setIsSaving(false);
      }, 45000); // 45 seconds
      setTimeoutId(newTimeoutId);

      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates`,
        // !mobileView
        //   ? `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates`
        //   : `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates/mobile-template`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: user.name,
            tempInfo: {
              ...item.templateInfo,
              tempID,
            },
            tempDetails: item.template,
          }),
          signal,
        }
      )
        .then((response) => {
          clearTimeout(timeoutId);
          if (!response.ok) {
            throw new Error("Network response error");
          }
          return response.json();
        })
        .then((data) => {
          setIsSaving(false);
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          setIsSaving(false);
        });
    }
  };

  const isNewlyCreated = sessionStorage.getItem('tempNewlyCreated');

  return (
    <DesignTemplate
      onSave={saveUserTemplate}
      apiEndpoint={`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}`}
      buttonTitle="Save"
      displaySlideListOnLoad={isNewlyCreated ? true : false}
      isSaving={isSaving}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(CustomerEditTemp);
