import React, {useState, useEffect, useLayoutEffect} from "react";
import ValueLabel from "./common/ValueLabel";
import {connect} from "react-redux";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../../store/selectors/template/template.selector";
import ScaleLabels from "./barProps/ScaleLabels";
import ScaleGrid from "./barProps/ScaleGrid";
import {
  fixFloatingPoint,
  getRemainingDigitsLength,
  useScaleLine,
} from "./hooks/useScaleLine";
import ShowLegends from "./common/ShowLegends";
import {ScaledChart, WrapChart} from "../chartStyles";
import CategoryLabels from "./common/CategoryLabels";

const StackedAreaChart = ({
  size,
  styles,
  dataSeries,
  fieldKey,
  tempSize,
  colorSet,
  tempScale,
}) => {
  const {
    showBackground,
    background,
    gridColor,
    showGrid,
    showValue,
    valueColor,
    fontSize,
    fontFamily,
    showLegend,
    showMeasurement,
    showYAxis,
    measurementFontSize,
    measurementColor,
    axisColor,
    showXAxis,
    gridStyle,
    showDots,
    dotSize,
    lineSize,
    showCategory,
    scaleDistance,
    currencyUnit,
    scaleType,
    scaleFormat,
  } = styles;

  const [dataSet, setDataSet] = useState(dataSeries.slice(1));
  const barMargin = size.w / (dataSeries.length - 2);
  const width = size.w;
  const height = size.h;
  const barWidth = 0;
  // let plotChart = [];
  // let highestPositiveValue = 0,
  //   lowestNegativeValue = 0;
  const [totalValue, setTotalValue] = useState(0);

  // let extractInputs = [];
  const [extractInputs, setExtractInputs] = useState([]);
  const [highestPositiveValue, setHighestPositiveValue] = useState(0);
  const [lowestNegativeValue, setLowestNegativeValue] = useState(0);
  const [categoryLabels, setCategoryLabels] = useState([]);
  const [plotChart, setPlotChart] = useState([]);

  function removeLabelsFromArray(arr) {
    return arr.map(function (x) {
      return x.filter(function (y, index) {
        return index !== 0;
      });
    });
  }

  useLayoutEffect(() => {
    let highestPositiveValue = 0,
      lowestNegativeValue = 0,
      extractInputs = [];

    const dataSet = dataSeries.slice(1);
    setDataSet(dataSet);

    const dataSeriesItems = removeLabelsFromArray(dataSet);
    const arrayColumn = (arr, n) => arr.map((x) => x[n]);

    for (let i = 0; i < dataSet[0].length; i++) {
      extractInputs.push(arrayColumn(dataSet, i));
    }

    let categoryLabels = [];

    for (let i = 0; i < dataSeriesItems.length; i++) {
      let sumByCategory = 0,
        lowestByCategory = 0,
        accumulate = 0; // get the highest value from a particular row

      for (let j = 0; j < dataSeriesItems[0].length; j++) {
        const value = dataSeriesItems[i][j] === "" ? 0 : dataSeriesItems[i][j];
        // to prevent from cases like 0.2 + 0.4 !== 0.6 -> creates 0.600000001
        // will cause error
        const remaingDigitsLength = getRemainingDigitsLength(value);
        sumByCategory = Number(
          (value + sumByCategory).toFixed(remaingDigitsLength)
        );
        if (value < 0) {
          lowestByCategory = lowestByCategory + value;
        }

        if (sumByCategory > highestPositiveValue) {
          highestPositiveValue = sumByCategory;
        }

        if (lowestByCategory < lowestNegativeValue) {
          lowestNegativeValue = accumulate + value;
        }

        accumulate = accumulate + value;
      }
    }

    for (let i = 0; i < dataSet.length; i++) {
      categoryLabels.push(dataSet[i][0]);
    }

    setCategoryLabels(categoryLabels);
    setExtractInputs(extractInputs);

    const setPositive = fixFloatingPoint(highestPositiveValue);
    setHighestPositiveValue(setPositive);

    const setNegative = fixFloatingPoint(lowestNegativeValue);
    setLowestNegativeValue(setNegative);
  }, [dataSeries]);

  const {chartSpectrum, scaleLine, digitsLength} = useScaleLine({
    highestPositiveValue,
    lowestNegativeValue,
  });

  useLayoutEffect(() => {
    setTotalValue(chartSpectrum.positive + Math.abs(chartSpectrum.negative));
  }, [chartSpectrum.positive, chartSpectrum.negative]);

  useLayoutEffect(() => {
    if (extractInputs.length > 0) {
      const chartInputs = extractInputs.slice(1);
      let plotChart = [];
      let accumulate = Array(chartInputs[0].length).fill(0);
      totalValue !== 0 &&
        chartInputs &&
        chartInputs.map((datum, i) => {
          let drawPath = [],
            plotDots = [],
            edgeLine = [],
            lastAddedIndex = 0;
          datum.map((item, index) => {
            if (item === "") {
              if (index === 0) {
                drawPath.push(
                  barMargin * index, // need to fix (only works on first one, second one not working)
                  (chartSpectrum.positive * height) / totalValue
                );
              }

              plotDots.push({
                x: barMargin * index,
                y:
                  (height *
                    (chartSpectrum.positive - (0 + accumulate[index]))) /
                  totalValue,
                color: colorSet[i],
                value: "",
                leftEdge: index === 0, // bring inner side to labels on the left edge
                rightEdge: datum.length - 1 === index, // bring inner side to labels on the right edge
              });

              edgeLine.push(
                barMargin * index + 0 / 2,
                (height * (chartSpectrum.positive - (0 + accumulate[index]))) /
                  totalValue
              );

              drawPath.push(
                barMargin * index + 0 / 2,
                (height * (chartSpectrum.positive - (0 + accumulate[index]))) /
                  totalValue
              );

              // plotDots.push({
              //   x: barMargin * index,
              //   y:
              //     (height *
              //       (chartSpectrum.positive - (0 + accumulate[index]))) /
              //     totalValue,
              //   color: colorSet[i],
              //   value: "",
              //   leftEdge: index === 0, // bring inner side to labels on the left edge
              //   rightEdge: datum.length - 1 === index, // bring inner side to labels on the right edge
              // });

              // edgeLine.push(
              //   barMargin * index + 0 / 2,
              //   (height * (chartSpectrum.positive - (0 + accumulate[index]))) /
              //     totalValue
              // );
            } else if (drawPath.length === 0) {
              drawPath.push(
                barMargin * index, // need to fix (only works on first one, second one not working)
                item + accumulate[index] >= 0
                  ? (chartSpectrum.positive * height) / totalValue
                  : (height * chartSpectrum.positive) / totalValue
              );
            }

            if (item !== "") {
              // to track if the item with value is added at the end
              // if item [0, 1, "", ""] -> the line will be ended at 1
              lastAddedIndex = index;

              drawPath.push(
                barMargin * index,
                (height *
                  (chartSpectrum.positive - (item + accumulate[index]))) /
                  totalValue
              );

              plotDots.push({
                x: barMargin * index,
                y:
                  (height *
                    (chartSpectrum.positive - (item + accumulate[index]))) /
                  totalValue,
                color: colorSet[i],
                value: item,
                leftEdge: index === 0, // bring inner side to labels on the left edge
                rightEdge: datum.length - 1 === index, // bring inner side to labels on the right edge
              });

              edgeLine.push(
                barMargin * index + 0 / 2,
                (height *
                  (chartSpectrum.positive - (item + accumulate[index]))) /
                  totalValue
              );

              accumulate[index] = item + accumulate[index];
            }

            if (index === datum.length - 1) {
              if (item !== "") {
                drawPath.push(
                  `${width} ${(chartSpectrum.positive * height) / totalValue}`
                );
              } else {
                drawPath.push(
                  barMargin * lastAddedIndex,
                  (chartSpectrum.positive * height) / totalValue
                );

                edgeLine.push(
                  `${width} ${(chartSpectrum.positive * height) / totalValue}`
                );
              }
              plotChart.push({
                path: `M${drawPath.toString()}`,
                dots: plotDots,
                color: colorSet[i],
                edgeLine: `M${edgeLine.toString()}`,
              });
            }
          });
        });
      setPlotChart(plotChart);
    }
  }, [extractInputs, totalValue, size, chartSpectrum, dataSeries, colorSet]);

  const valueHeightGap =
    tempSize.w > tempSize.h ? (2 * tempSize.h) / 100 : (1.5 * tempSize.h) / 100;

  return (
    <WrapChart>
      <ScaledChart>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          style={{background: showBackground ? background : undefined}}
        >
          <g>
            {plotChart &&
              plotChart.map((item, i) => {
                return (
                  <g key={i}>
                    <g key={i + "path"}>
                      <defs>
                        <linearGradient
                          id={fieldKey + "-" + i}
                          gradientTransform={`rotate(90 0.9 0.9) `}
                        >
                          <stop
                            offset="0%"
                            stopOpacity="1"
                            stopColor={item.color}
                          />
                          <stop
                            offset="100%"
                            stopOpacity="0.25"
                            stopColor={item.color}
                          />
                        </linearGradient>
                      </defs>
                      <path
                        fill={`url(#${fieldKey + "-" + i})`}
                        stroke={item.color}
                        strokeWidth={0}
                        d={`${item.path}`}
                        style={{opacity: 0.8}}
                      />
                      <path
                        fill="transparent"
                        stroke={item.color}
                        strokeWidth={lineSize}
                        d={item.edgeLine}
                      />
                    </g>
                  </g>
                );
              })}
          </g>

          <g>
            {plotChart &&
              plotChart.map((item, i) => (
                <g key={i}>
                  {item.dots.map((dot, index) => {
                    let valueXPos = dot.x;
                    if (dot.leftEdge) valueXPos = dot.x + 7;
                    else if (dot.rightEdge) valueXPos = dot.x - 7;

                    return (
                      <g key={index}>
                        {showDots ? (
                          <circle
                            cx={dot.x}
                            cy={dot.y}
                            fill={dot.color}
                            r={dotSize} // * (tempSize.w / 536)
                          />
                        ) : undefined}
                        {showValue ? (
                          <ValueLabel
                            x={valueXPos}
                            y={dot.y - valueHeightGap}
                            color={valueColor}
                            width={30}
                            height={20}
                            value={dot.value}
                            fontSize={fontSize}
                            fontFamily={fontFamily}
                            scaleFormat={scaleFormat}
                            textAnchor="middle"
                            alignmentBaseline="alphabetic"
                            dominantBaseline="alphabetic"
                          />
                        ) : undefined}
                      </g>
                    );
                  })}
                </g>
              ))}
          </g>

          {showMeasurement ? (
            <g>
              {scaleLine.acc &&
              (chartSpectrum.positive || chartSpectrum.negative) &&
              Object.keys(scaleLine).length !== 0 ? (
                <ScaleLabels
                  fontSize={measurementFontSize}
                  fontFamily={fontFamily}
                  color={measurementColor}
                  item={scaleLine}
                  gap={(scaleLine.acc * height) / scaleLine.total}
                  highestValue={chartSpectrum.positive}
                  digitsLength={digitsLength}
                  scaleDistance={scaleDistance}
                  scaleType={scaleType}
                  scaleFormat={scaleFormat}
                  currencyUnit={currencyUnit}
                />
              ) : undefined}
            </g>
          ) : undefined}

          {showXAxis && totalValue ? (
            <g>
              <line
                x1={0}
                x2={width}
                y1={(height * (chartSpectrum.positive - 0)) / totalValue}
                y2={(height * (chartSpectrum.positive - 0)) / totalValue}
                stroke={axisColor}
                strokeWidth={1.5}
              />
            </g>
          ) : undefined}

          {showYAxis ? (
            <g>
              <line
                x1={0}
                x2={0}
                y1={0}
                y2={height}
                stroke={axisColor}
                strokeWidth={1.5}
              ></line>
            </g>
          ) : undefined}

          {showGrid ? (
            scaleLine && Object.keys(scaleLine).length !== 0 ? (
              <g>
                <ScaleGrid
                  color={gridColor}
                  gridStyle={gridStyle}
                  item={scaleLine}
                  width={width}
                  gap={(scaleLine.acc * height) / scaleLine.total}
                />
              </g>
            ) : undefined
          ) : undefined}

          {showCategory ? (
            <CategoryLabels
              styles={styles}
              chartHeight={size.h}
              labels={categoryLabels}
              barMargin={barMargin}
              barWidth={0}
              edgeSpace={0}
              seriesLength={0}
            />
          ) : undefined}
        </svg>

        {showLegend ? (
          <ShowLegends
            items={dataSeries[0].slice(1)}
            chartSize={size}
            styles={styles}
            colorSet={colorSet}
          />
        ) : undefined}
      </ScaledChart>
    </WrapChart>
  );
};

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(StackedAreaChart);
