import React from "react";
import {deskNavHeight} from "../../../../layoutSizes";
import {createPortal} from "react-dom";
import styled from "styled-components";
import {connect} from "react-redux";
import {selectTemplateSize} from "../../../../store/selectors/template/template.selector";
import {selectCanvasPanelWidth} from "../../../../store/selectors/layout/layout.selector";

const FieldRatio = ({templateScale, offset, size, tempSize, canvasWidth}) => {
  const fieldRatio = calcFieldRatio(size);
  const adjustedXOffset =
    Math.round(canvasWidth - tempSize.w * templateScale) / 2;
  return createPortal(
    <Layer
      style={{
        transform: `translate(${
          offset?.x * templateScale + adjustedXOffset - 60
        }px, ${offset?.y * templateScale + deskNavHeight + 12}px)`,
      }}
    >
      {fieldRatio}
    </Layer>,
    document.getElementById("canvas-field-item-field-ratio-tooltip")
  );
};

const Layer = styled.div`
  position: absolute;
  top: 0px;
  padding: 4px 6px;
  border-radius: 4px;
  color: #fff;
  background: #232349de;
  z-index: 9999;
`;

const calcFieldRatio = (size) => {
  if (size) {
    const {w, h} = size || {};
    const width = w;
    const height = h;

    const aspectRatio = width / height;

    // Find the nearest whole number ratio within the 1 to 10 range
    let simplifiedWidth = 1;
    let simplifiedHeight = 1;

    for (let i = 1; i <= 10; i++) {
      for (let j = 1; j <= 10; j++) {
        if (
          Math.abs(aspectRatio - i / j) <
          Math.abs(aspectRatio - simplifiedWidth / simplifiedHeight)
        ) {
          simplifiedWidth = i;
          simplifiedHeight = j;
        }
      }
    }
    return `W: ${simplifiedWidth}, H: ${simplifiedHeight}`;
  }
  // Return null or another JSX element if size is not available
  return null;
};

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    canvasWidth: selectCanvasPanelWidth(state.designTemplate),
    // editingText: selectEnableTextEdit(state.designTemplate),
  };
};

export default connect(mapStateToProps)(FieldRatio);
