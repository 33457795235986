import React, { useState, useEffect, useRef } from "react";
import styled, {css} from "styled-components";
import {blue5, greyText2} from "../../../oat-color-variables";
import {
  defaultMaxWidthDesktop,
  defaultMaxWidthLaptop,
  layoutPadding,
} from "../layoutVariables";
import {isTablet, isDesktop, isMobile} from "react-device-detect";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";

const MainHeader = () => {
  const layoutRef = useRef(null);
  const windowSize = getWindowSize();
  const [imageMinSize, setImageMinSize] = useState({width: 0, height: 0});
  useEffect(() => {
    const width = layoutRef?.current.offsetWidth;
    const height = 333 * width / 560; 
    setImageMinSize({
      width, height
    });
  }, [windowSize.width, windowSize.height]);

  return (
    <Layout isTablet={isTablet} isDesktop={isDesktop}>
      <Wrapper ref={layoutRef}>
        <HeaderContent>
          <Header style={{paddingBottom: 14}}>Embellish your Designs</Header>
          <Description>
            Create stunning presentations in a snap with our
            pre-made templates and illustrations
          </Description>
        </HeaderContent>

        <picture>
          <source srcset="https://dnaeh50kru9rd.cloudfront.net/home-page/main-header/home-page-1600x.avif" media="(max-width: 600px)" />
          <source srcset="https://dnaeh50kru9rd.cloudfront.net/home-page/main-header/home-page-1600x.avif" media="(min-width: 601px)" />
          <Image
            alt="home-cover"
            style={{
              minWidth: window.innerWidth <= 860 || window.innerWidth < window.innerHeight ? imageMinSize.width : undefined,
              maxHeight: window.innerWidth <= 860 || window.innerWidth < window.innerHeight ? imageMinSize.height : undefined,
            }}
            src={"https://dnaeh50kru9rd.cloudfront.net/home-page/main-header/home-page-1600x.avif"}
            loading="lazy"
          />
        </picture>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  // background: ${blue5};
  background: linear-gradient(45deg, #ffffff, #dde8ff5e);
  box-sizing: border-box;
  min-height: ${window.innerHeight - 56}px;
  @media screen and (min-width: 900px) and (max-width: 1599px) {
    padding-top: 38px;
    padding-bottom: ${({isDesktop}) => (isDesktop ? 124 : undefined)}px;
  }
  @media screen and (min-width: 1000px) and (max-width: 1150px) {
    padding: 0 3.6%;
  }
  @media screen and (min-width: 1600px) {
    padding-top: 4%;
  }
  @media only screen and (max-width: 899px) {
    padding: 26px ${layoutPadding.mobile} 0 ${layoutPadding.mobile};
    min-height: ${({isTablet, isMobile}) =>  isTablet || isMobile ? 0 : window.innerHeight - 56}px;
  }
  @media only screen and (max-width: 768px) {
    padding: 56px ${layoutPadding.mobile} 0 ${layoutPadding.mobile};
    min-height: 0px;
  }
  @media screen and (orientation: portrait) {
    padding: 26px ${layoutPadding.mobile} 0 ${layoutPadding.mobile};
    min-height: ${({isTablet, isMobile}) =>  isTablet || isMobile || window.innerWidth <= 600 ? 0 : window.innerHeight - 56}px;
  }
  @media only screen 
    and (min-device-width: 600px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) {
      min-height: 0px;
      margin-bottom: 75px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${defaultMaxWidthLaptop + 100}px;
  font-display: block;
  flex-direction: column;
  align-self: flex-start; 
  justify-content: center;
  align-items: center;
  padding-bottom: 38px;
  @media only screen and (max-width: 1140px) and (min-width: 900px) {
    max-width: 100%;
  }
  @media only screen and (min-width: 1500px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
  @media only screen and (max-width: 600px) {
    padding-bottom: 64px;
  }
`;

const animate = css`
  0% {
    opacity: 0;
    transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1),
      transform 1s cubic-bezier(0.16, 1, 0.3, 1);
    transform: translateY(60px);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeaderContent = styled.div`
  max-width: 75%;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 900px) {
    margin-top: 64px;
    max-width: 60%;
    padding-bottom: 1.8em;
  }
  @media only screen and (max-width: 899px) {
    margin-top: 46px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 80%;
    margin-top: 0px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 90%;
    margin-top: 0px;
}
`;

const Header = styled.h1`
  font-size: 2.3em;
  font-weight: 700;
  background-image: linear-gradient(4deg, rgb(102 140 255), rgb(251 161 255));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 0.6s ease-out;
  @-webkit-keyframes animate {
    ${animate};
  }
  @keyframes animate {
    ${animate};
  }
  @media only screen and (min-width: 750px) and (max-width: 1000px) {
    font-size: 2.2em;
  }
  @media only screen and (max-width: 749px) {
    font-size: 2.2em;
    width: 100%;
  }
`;

const Description = styled.div`
  font-size: 1.15em;
  line-height: 1.7em;
  padding: 1rem 0 1.5rem;
  color: ${greyText2};
  width: 90%;
  text-align: center;
  animation: animate 0.9s ease-out;
  @-webkit-keyframes animate {
    ${animate};
  }
  @keyframes animate {
    ${animate};
  }
  @media only screen and (max-width: 600px) {
    padding: 1rem 0 0;
    font-size: 1.1rem;
    width: 100%;
  }
  @media only screen 
    and (min-device-width: 600px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) {
      margin-bottom: 25px;
  }
`;

const styleImage = css`
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  @media only screen and (min-width: 601px) {
    // filter: drop-shadow(-82px 6px 92px rgba(149, 160, 160, 0.5));
    filter: drop-shadow(2px 5px 9px #eaeaf7);
    color: #eaeaf7;
  }
  @media only screen and (max-width: 1100px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 600px) {
    transform-origin: 0 0;
  }
`;

const Image = styled.img`
  ${styleImage};
  animation: animate-image 1.5s ease-in-out;
  transition: 0.5s ease-in-out;
  @-webkit-keyframes animate-image {
    0% {
      opacity: 0;
      transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1),
        transform 1s cubic-bezier(0.16, 1, 0.3, 1);
      transform: scale(0.8);
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes animate-image {
    0% {
      opacity: 0;
      transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1),
        transform 1s cubic-bezier(0.16, 1, 0.3, 1);
      transform: scale(0.8);
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @media only screen and (max-width: 600px) {
    ${styleImage};
    position: relative;
    margin-top: 3.5em;
  }
`;

export default MainHeader;
