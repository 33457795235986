import React from "react";
import styled from "styled-components";
import {darkGrey1, darkGrey2} from "../../../oat-color-variables";
import LayoutHoc from "../../layout/LayoutHoc";

const applicationQuestions = [
  {
    question: "How can I share my designs?",
    answer:
      "You can share your designs using shareable links, export them as PDFs and images, or create custom, human-readable links for specific use cases.",
    open: false,
  },
  {
    question: "How can I safeguard my designs?",
    answer:
      "You have the option to control the privacy of each of your designs, either setting as private to limit access only to your workspace members or enabling passcode protection for external users.",
    open: false,
  },
  {
    question: "Is there a limit on folder creation?",
    answer:
      "The pro plan offers the ability to create an unlimited number of folders and subfolders, along with the flexibility to manage access control among your members.",
    open: false,
  },
  {
    question: "Do you provide free templates and illustrations?",
    answer:
      "While we do offer a selection of free templates and illustrations, it's important to note that certain premium contents may be available exclusively with the pro plan. Additionally, more intricate and demanding designs may require separate purchase.",
    open: false,
  }
];

const paymentQuestions = [
  {
    question: "How do subscriptions work?",
    answer:
      "Subscriptions are charged by number of members (guests excluded) per workspace. When new users are added, prorated amount will be charged automatically to reflect an actual amount of time being used.",
    open: false,
  },
  {
    question: "Do I need to pay for guests?",
    answer:
      "Guests will not be charged but the limitation applies per workspace.",
    open: false,
  },
  {
    question: "What would happen if a member is removed from a paid plan?",
    answer:
      "If a member is removed, the subscription for that user will end. Any remaining unused subscription will automatically be applied as a credit when a new member is added or it will be subtracted on the next invoice.",
    open: false,
  },
  {
    question: "Do you offer a free plan?",
    answer:
      "We do allow users to use our interface and play around with most of the features. To export designs or access advanced features, upgrading to a pro plan is required.",
    open: false,
  },
  {
    question: "What would happen when the subscription ends?",
    answer:
      "The subscription will automatically renew itself when it ends unless cancellation is invoked before the end of the period.",
    open: false,
  },
];

const Item = ({item}) => {
  return (
    <WrapQuestion>
      <Question>{item.question}</Question>
      <Answer>{item.answer}</Answer>
    </WrapQuestion>
  );
};

const FAQuestions = ({disableFlex, showOnlyPayment}) => {
  return (
    <Layout disableFlex={disableFlex}>
    <Header>Common Questions</Header>
    <WrapQuestions>
        {!showOnlyPayment ?
            <Questions>
                <Section>Application</Section>
                <Grid>
                    {applicationQuestions.map((item, i) => (
                    <Item key={i} item={item} />
                    ))}
                </Grid>
            </Questions> 
        : undefined}

        <Questions>
            <Section>Payment</Section>
            <Grid>
                {paymentQuestions.map((item, i) => (
                <Item key={i} item={item} />
                ))}
            </Grid>
        </Questions>
    </WrapQuestions>
    </Layout>
  );
};

const Layout = styled.div`
  display: ${({disableFlex}) => !disableFlex ? 'flex' : undefined};
  margin: ${({disableFlex}) => !disableFlex ? '1% 2%' : undefined}; 
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: ${({disableFlex}) => !disableFlex ? '10px 24px' : undefined};
  }
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  width: 30%;
  font-size: 1.7rem;
  font-weight: 700;
  color: #8670db;
  margin-top: 22px;
  @media only screen and (max-width: 900px) {
    columns: 1;
    width: 100%;
  }
`;

const WrapQuestions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-top: 12px;
`;

const Questions = styled.div`
  flex: 3;
  margin-top: 22px;
`;

const Question = styled.div`
  font-size: 1.3rem;
  color: ${darkGrey2};
  font-weight: 500;
`;

const Grid = styled.div`
  columns: 2;
  margin-bottom: 66px;
  grid-gap: 32px;
  @media only screen and (max-width: 900px) {
    columns: 1;
    margin-bottom: 26px;
    width: 100%;
  }
`;

const WrapQuestion = styled.div`
  font-size: 1rem;
  margin-bottom: 38px;
  display: inline-block;
  padding-top: 27px;
  padding-bottom: 38px;
  box-sizing: content-box;
  border-bottom: 1px solid #dedede;
  @media only screen and (max-width: 900px) {
    margin-bottom: 26px;
  }
`;

const Answer = styled.div`
  padding-top: 22px;
  font-size: 1.05rem;
  line-height: 32px;
  color: #524f4f;
  @media only screen and (max-width: 600px) {
    font-size: 1.06rem;
  }
`;

const Section = styled.h2`
  font-size: 1.4rem;
  margin: 1.2rem 0;
  color: ${darkGrey1};
  @media only screen and (max-width: 900px) {
    margin: 2rem 0 1.2rem 0;
  }
`;

FAQuestions.defaultProps = {
    disableFlex: false,
    showOnlyPayment: false
};

export default FAQuestions;
