import React, {useState} from "react";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import {updateUserAuthStatus} from "../../../redux/user/actions/authUser.action";
import {connect} from "react-redux";
import {
  Form,
  Layout,
  Wrapper,
  Header,
  LoginTxt,
  Line,
  WrapInput,
  Error,
  WrapButton,
} from "./UserAuth";
import {Button, ButtonSpinner} from "../../oat-ui";
import {primaryPurple, red1} from "../../oat-color-variables";
import styled from "styled-components";
import {homeDefault} from "../../../data/route-links";

const ForgotPassword = ({updateUserAuthStatus}) => {
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState({
    value: "",
    error: false,
  });
  const [activeDisplay, setActiveDisplay] = useState("enter-email");
  const [code, setCode] = useState({
    value: "",
    error: false,
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    error: false,
  });
  let navigate = useNavigate();

  const checkEmail = async () => {
    if (
      email.value.length > 5 &&
      email.value.indexOf("@") !== -1 &&
      email.value.indexOf(".") !== -1
    ) {
      if (email.error) {
        setEmail({
          ...email,
          error: false,
        });
      }
      setProcessing(true);
      await Auth.forgotPassword(email.value)
        .then(() => {
          setProcessing(false);
          setActiveDisplay("enter-new-password");
        })
        .catch((error) => {
          setProcessing(false);
          setEmail({
            ...email,
            error: true,
          });
        });
    } else {
      setEmail({
        ...email,
        error: true,
      });
    }
  };

  const handleEmailChange = (e) => {
    const {value} = e.target;
    setEmail({
      ...email,
      value,
    });
  };

  const handleCodeChange = (e) => {
    const {value} = e.target;
    setCode({
      ...code,
      value,
    });
  };

  const handleNewPasswordChange = (e) => {
    const {value} = e.target;
    setNewPassword({
      ...newPassword,
      value,
    });
  };

  const checkNewPassword = () => {
    if (newPassword.value.length >= 8) {
      if (newPassword.error) {
        setNewPassword({
          ...newPassword,
          error: false,
        });
      }
      setActiveDisplay("enter-code");
    } else {
      setNewPassword({
        ...newPassword,
        error: true,
      });
    }
  };

  const handleCodeSubmit = async () => {
    if (code.error) {
      setCode({...code, error: false});
    }
    setProcessing(true);
    const updatedEmail = email.value.trim();
    await Auth.forgotPasswordSubmit(updatedEmail, code.value, newPassword.value)
      .then(async () => {
        await Auth.signIn(updatedEmail, newPassword.value)
          .then(async (user) => {
            const dynamoUsername = user.attributes["custom:acc-username"];
            await fetch(
              `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${dynamoUsername}/userInfo`
            )
              .then((res) => res.json())
              .then((data) => {
                const {userItems, workspaceID} = data;
                setProcessing(false);
                updateUserAuthStatus({
                  cognito: user,
                  dynamo: userItems,
                });
                navigate(`/dashboard/${workspaceID}/designs/home`);
              })
              .catch((e) => {
                setProcessing(false);
                setCode({...code, error: true});
              });
          })
          .catch((e) => {
            setProcessing(false);
            setCode({...code, error: true});
          });
      })
      .catch((e) => {
        setProcessing(false);
        setCode({...code, error: true});
      });
  };

  return (
    <Layout>
      <Wrapper>
        <Form>
          <Header>Oat</Header>
          <LoginTxt>Forgot Password</LoginTxt>
          <Line></Line>
          {activeDisplay === "enter-email" ? (
            <>
              <WrapInput>
                <Input
                  value={email.value}
                  onChange={handleEmailChange}
                  placeholder="Email"
                  type="text"
                  style={{
                    border: `1px solid ${!email.error ? "#c7c7c7" : red1}`,
                  }}
                />
                {email.error ? (
                  <Error>Please enter a valid email.</Error>
                ) : undefined}
              </WrapInput>

              <WrapButton>
                <Button
                  onClick={checkEmail}
                  styles={{
                    borderRadius: 0,
                    fontSize: "1rem",
                    background: primaryPurple,
                  }}
                >
                  {!processing ? "Send" : <ButtonSpinner />}
                </Button>
              </WrapButton>
            </>
          ) : undefined}

          {activeDisplay === "enter-new-password" ? (
            <>
              <WrapInput>
                <Input
                  value={newPassword.value}
                  onChange={handleNewPasswordChange}
                  placeholder="New Password"
                  type="password"
                  style={{
                    border: `1px solid ${
                      !newPassword.error ? "#c7c7c7" : red1
                    }`,
                  }}
                />
                {code.error ? (
                  <Error>
                    Password length should be at least 8 characters.
                  </Error>
                ) : undefined}
              </WrapInput>

              <WrapButton>
                <Button
                  onClick={checkNewPassword}
                  styles={{
                    borderRadius: 0,
                    fontSize: "1rem",
                    background: primaryPurple,
                  }}
                >
                  {!processing ? "Next" : <ButtonSpinner />}
                </Button>
              </WrapButton>
            </>
          ) : undefined}

          {activeDisplay === "enter-code" ? (
            <>
              <Message>Enter code has been sent to your email.</Message>
              <WrapInput>
                <Input
                  value={code.value}
                  onChange={handleCodeChange}
                  placeholder="Enter Code"
                  type="text"
                  style={{
                    border: `1px solid ${!code.error ? "#c7c7c7" : red1}`,
                  }}
                />
                {code.error ? <Error>The code is not valid.</Error> : undefined}
              </WrapInput>

              <WrapButton>
                <Button
                  onClick={handleCodeSubmit}
                  styles={{
                    borderRadius: 0,
                    fontSize: "1rem",
                    background: primaryPurple,
                  }}
                >
                  {!processing ? "Verify Code" : <ButtonSpinner />}
                </Button>
              </WrapButton>
            </>
          ) : undefined}
        </Form>
      </Wrapper>
    </Layout>
  );
};

const Input = styled.input`
  outline: none;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c7c6d1;
`;

const Message = styled.div`
  font-size: 1.1rem;
  width: 100%;
  padding: 7px 0;
`;

const SuccessMessage = styled.div`
  font-size: 1.1rem;
  color: #656565;
  font-size: 1.1rem;
  color: #3f3838;
  padding: 14px 0;
`;

export default connect(null, {updateUserAuthStatus})(ForgotPassword);
