import React, {useState} from "react";
import styled from "styled-components";
import {greyText1} from "../../../../oat-color-variables";
import {Button} from "../../../../oat-ui";
import {
  Header,
  TextField,
  Footer,
  BottomSection,
  ContentSection,
  Cancel,
} from "../../reuse/dashboardUI/modalStyles";

const AddMember = ({openModal, onCancel}) => {
  const [value, setValue] = useState();

  const onInputChange = (e) => {
    const {value} = e.target;
    setValue(value);
  };

  const handleMemberCreate = () => {
    openModal("member-list");
  };

  return (
    <Wrapper>
      <ContentSection>
        <Header>Add User</Header>

        <Form>
          <TextField
            value={value}
            onChange={onInputChange}
            placeholder="Enter User's Email"
            autoFocus
          />
        </Form>

        <Message>* You will be charged on behalf of the user.</Message>
      </ContentSection>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Cancel>Cancel</Cancel>
          <WrapButton>
            <Button
              styles={{width: 64, height: 34}}
              onClick={handleMemberCreate}
            >
              Add
            </Button>
          </WrapButton>
        </BottomSection>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Form = styled.div``;

const WrapButton = styled.div`
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapSvg = styled.div`
  width: 14px;
  height: 14px;
  background: #b7a8ee59;
  padding: 6px;
  border-radius: 50%;
  fill: #6d6d6d;
  cursor: pointer;
`;

const Message = styled.div`
  font-size: 0.85rem;
  text-align: right;
  margin: 12px 0 0;
  color: ${greyText1};
`;

export default AddMember;
