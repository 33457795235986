import React from "react";
import {DrawerLayout} from "./layoutStyles";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {Title} from "../../../shared/reuseStyles/Title";
import PopoverColorBox from "../../../shared/colorPicker/PopoverColorBox";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {
  selectFontFamily,
  updateFieldStyles,
} from "../../../../../../../store/actions/fields/common.action";
import {textEditProps} from "../../../desktop/text/EditText";
import RangeSlider from "../../../shared/inputs/RangeSlider";
import TextEffects from "../../../desktop/text/TextEffects";
import FontFamilyList from "./common/FontFamilyList";
import EditSpacing from "./common/EditSpacing";
import EditOpacity from "../../../desktop/common/EditOpacity";
import EditRotate from "./common/EditRotate";
import ZIndex from "../../../shared/ZIndex";
import ApplyAnimations from "../../../desktop/animations/ApplyAnimations";
import TextBackground from "../../../desktop/text/TextBackground";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";
import SelectUnOrderedListBullet from "../../../desktop/text/SelectUnOrderedListBullet";

const TextDrawers = ({
  propsType,
  updateFieldStyles,
  slideID,
  activeField,
  storeFieldUndoRedo,
  selectFontFamily,
  updateFieldCollaboration,
}) => {
  const {styles} = activeField || {};
  const active = {slideID, fieldID: activeField?.key};
  const {color1} = styles || {};

  const handleUpdates = (type, value) => {
    updateFieldStyles({
      active,
      type,
      value,
    });
  };

  const changeFontSize = (value) => {
    handleUpdates("fontSize", value);
  };

  const changeLetterSpacing = (value) => {
    handleUpdates("letterSpacing", Number(value));
  };

  const changeLineHeight = (value) => {
    handleUpdates("lineHeight", Number(value));
  };

  const onChangeFontFamily = async (item) => {
    const payload = {type: null, value: item};
    const active = {slideID, fieldID: activeField?.key};
    await selectFontFamily(active, item);
    updateFieldCollaboration();
  };

  const onChangeColorOne = (value) => {
    handleUpdates("color1", value);
    updateFieldCollaboration();
  };

  const onColorOneDebounce = () => {};

  let displayView = null;
  switch (propsType) {
    case "color":
      displayView = (
        <div>
          {!styles.enableGradient ? (
            <div style={{flex: 1, display: "flex", alignItems: "center"}}>
              <Title>Colors</Title>
              <PopoverColorBox
                value={color1}
                updateFieldStyles={onChangeColorOne}
                onDebounce={onColorOneDebounce}
              />
            </div>
          ) : undefined}
        </div>
      );
      break;
    case "spacing":
      displayView = (
        <EditSpacing
          lineHeight={styles?.lineHeight}
          letterSpacing={styles?.letterSpacing}
          onChangeLetterSpacing={changeLetterSpacing}
          onChangeLineHeight={changeLineHeight}
        />
      );
      break;
    case "fontSize":
      displayView = (
        <RangeSlider
          title="Font Size"
          fieldType="fontSize"
          value={styles?.fontSize}
          max={textEditProps.fontSize.max}
          min={textEditProps.fontSize.min}
          step={textEditProps.fontSize.step}
          onItemChange={changeFontSize}
        />
      );
      break;
    case "effects":
      displayView = (
        <div style={{paddingBottom: "2rem"}}>
          <TextEffects effects={activeField?.effects} />
        </div>
      );
      break;
    case "fontFamily":
      displayView = (
        <div style={{paddingBottom: "2rem"}}>
          <FontFamilyList field={activeField} onSelect={onChangeFontFamily} />
        </div>
      );
      break;
    case "opacity":
      displayView = <EditOpacity value={styles?.opacity} />;
      break;
    case "text-background":
      displayView = <TextBackground />;
      break;
    case "rotate":
      displayView = <EditRotate value={styles?.rotate} />;
      break;
    case "layer":
      displayView = <ZIndex />;
      break;
    case "bullet-content":
      displayView = <SelectUnOrderedListBullet />;
      break;
    case "animation":
      displayView = <ApplyAnimations />;
      break;
    default:
      displayView = "";
  }

  return <DrawerLayout>{displayView}</DrawerLayout>;
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveFieldProps(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  selectFontFamily,
  updateFieldCollaboration,
})(TextDrawers);
