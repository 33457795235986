import React from "react";
import {Modal} from "../../../../../../oat-ui";
import {royalBlue, white} from "../../../../../themes";
import CreateText from "./CreateText";

const TextListModal = ({close, mode}) => {
  return (
    <Modal
      width={820}
      height={520}
      top={70}
      close={close}
      background={mode === "dark" ? royalBlue : white}
    >
      <CreateText closeModal={close} />
    </Modal>
  );
};

export default TextListModal;
