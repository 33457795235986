export function calcCanvasWidth(windowWidth) {
  let canvasWidth = windowWidth;
  if (windowWidth > 1500) {
    canvasWidth = 1400;
  } else if (windowWidth > 1200) {
    canvasWidth = windowWidth - 220;
  } else if (windowWidth > 850) {
    canvasWidth = windowWidth - 50;
  }
  return canvasWidth;
}
