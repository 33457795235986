import React from "react";
import styled from "styled-components";
import {LeftArrow} from "../../../../../oat-svg-icons";

const GoBack = ({onBackward}) => {
  return (
    <>
      <MobileNav>
        <div onClick={onBackward} className="drawer-back-button">
          <div className="wrap-svg">{LeftArrow}</div>
          <h3>Back</h3>
        </div>
      </MobileNav>
      <Line />
    </>
  );
};

const MobileNav = styled.div`
  display: none;
  @media only screen and (max-width: 849px) {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 10px 14px 10px;
  }
  .drawer-back-button {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
  }
  .wrap-svg {
    display: flex;
    width: 16px;
    height: 16px;
    fill: ${({theme}) => theme.deskPrimaryTxt};
  }
  h3 {
    font-size: 1.1rem;
    padding-left: 8px;
    color: ${({theme}) => theme.deskPrimaryTxt};
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.deskGap};
`;

export default GoBack;
