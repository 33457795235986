import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import {ExpandArrow} from "../../oat-svg-icons";
import {black1, grey5, paleBlue4} from "../../oat-color-variables";
import {Bouncy} from "../animation/bouncy";

const SelectOption = ({item, handleSelectItem, selected}) => {
  const onSelectItem = () => {
    handleSelectItem(item);
  };
  return (
    <Option active={selected === item.value} onClick={onSelectItem}>
      <p>{item.display}</p>
    </Option>
  );
};

const Select = ({onSelect, selected, options, minWidth, height, overflowY}) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelectItem = (item) => {
    onSelect(item);
    handleToggle();
  };

  return (
    <Wrapper>
      <SelectionBox onClick={handleToggle} minWidth={minWidth}>
        <Selected>{selected}</Selected>
        <div style={{marginRight: 8, cursor: "pointer"}}>
          <div style={{width: 13, height: 13, stroke: "#a2a2a2"}}>
            {ExpandArrow}
          </div>
        </div>
      </SelectionBox>
      {open ? (
        <PopUp ref={selectRef} style={{height, overflowY}}>
          {options &&
            options.map((item, i) => (
              <SelectOption
                key={i}
                item={item}
                handleSelectItem={handleSelectItem}
                selected={selected == item.value}
              />
            ))}
        </PopUp>
      ) : undefined}
    </Wrapper>
  );
};

export const boxShadowStyle = "0px 0px 2px 1px #d1d1d1;";

export const Wrapper = styled.div`
  position: relative;
  text-transform: capitalize;
`;

export const PopUp = styled.div`
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
  z-index: 9999;
  box-shadow: ${boxShadowStyle};
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${Bouncy};
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  height: 28px;
  font-size: 0.85rem;
  cursor: pointer;
  margin: 6px;
  border-radius: 8px;
  flex: 1;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background: ${paleBlue4};
  }
`;

export const SelectionBox = styled.div`
  display: flex;
  width: auto;
  min-width: ${({minWidth}) => minWidth}px;
  height: 28px;
  background: #fff;
  border: 1px solid ${grey5};
  //   box-shadow: ${boxShadowStyle};
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
`;

export const Selected = styled.div`
  font-size: 0.86rem;
  color: ${black1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0 3px;
  user-select: none;
`;

export const OnHoverDot = styled.div`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #5e65c1;
  margin-right: 5px;
`;

Select.defaultProps = {
  minWidth: 132,
  height: "auto",
  overflowY: "auto",
};

export default Select;
