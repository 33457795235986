import styled from 'styled-components'
import { black3, primaryBlack } from '../../oat-color-variables';

export const Layout = styled.div`
    display: flex;
    justify-content: center;
    min-height: ${window.innerHeight}px;
    padding-top: 65px;
    margin-bottom: 24px;
    @media only screen and (max-width: 600px) {
        padding: 50px 20px;
    }
`;

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 900px;
    @media only screen and (min-width: 769px) and (max-width: 999px) {
        max-width: 90%;
    }
    @media only screen and (min-width: 1000px) and (max-width: 1399)px {
        max-width: 900px;
    }
    @media only screen and (min-width: 1400px){
        max-width: 1120px;
    }
    @media only screen and (max-width: 768px){
        max-width: 95%;
    }
`;

export const Header = styled.h2`
    font-size: 2.2rem;
    margin-bottom: 45px;
    color: ${black3};
    @media only screen and (max-width: 600px) {
        font-size: 1.9rem;
    }
`;

export const Section = styled.div`
    line-height: 2;
    margin-bottom: 65px;
    h1 {
        font-size: 1.8rem;
        color: ${black3};
        margin-bottom: 22px;
        @media only screen and (max-width: 600px) {
            font-size: 1.7rem;
        }
    }
    h2 {
        font-size: 1.6rem;
        color: ${black3};
        margin-bottom: 22px;
        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
        }
    }
    h3 {
        font-size: 1.4rem;
        color: ${black3};
        margin: 26px 0 16px;
        @media only screen and (max-width: 600px) {
            font-size: 1.3rem;
        }
    }
    h4 {
        font-size: 1.2rem;
        color: ${black3};
        margin: 22px 0 16px;
        @media only screen and (max-width: 600px) {
            font-size: 1.1rem;
        }
    }
    p {
        font-size: 1.1rem;
        color: ${primaryBlack};
        margin-bottom: 14px;
        @media only screen and (max-width: 600px) {
            font-size: 1rem;
        }
    }
    ul {
        margin-bottom: 35px;
        @media only screen and (max-width: 600px) {
            font-size: 25px;
        }
    }
    li {
        font-size: 1.1rem;
        color: ${black3};
        @media only screen and (max-width: 600px) {
            font-size: 1rem;
        }
    }
`;
