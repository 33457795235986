import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

const ShowError = () => {
  return (
    <Layout>
      <Message>
        Something went wrong. Please contact support if the error persists.
      </Message>
      <LinkToHome to="/">To Home</LinkToHome>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${window.innerHeight}px;
`;

const Message = styled.div`
  font-size: 1.1rem;
  max-width: 50%;
  text-align: center;
  @media only screen and (max-width: 768px) {
    max-width: 80%;
  }
`;

const LinkToHome = styled(Link)`
  margin: 14px;
  font-size: 14px;
`;

export default ShowError;
