export const PencilSvg = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style={{fontWeight: 900, strokeWidth: 0.3}}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 6C6.22386 6 6 6.22386 6 6.5C6 6.77614 6.22386 7 6.5 7H17.5C17.7761 7 18 6.77614 18 6.5C18 6.22386 17.7761 6 17.5 6H6.5ZM6.5 9C6.22386 9 6 9.22386 6 9.5C6 9.77614 6.22386 10 6.5 10H14.5C14.7761 10 15 9.77614 15 9.5C15 9.22386 14.7761 9 14.5 9H6.5ZM6.5 12C6.22386 12 6 12.2239 6 12.5C6 12.7761 6.22386 13 6.5 13H11.5C11.7761 13 12 12.7761 12 12.5C12 12.2239 11.7761 12 11.5 12H6.5ZM18.7668 11.0143C18.0923 10.3397 16.9986 10.3397 16.3241 11.0143L11.0059 16.3324C10.682 16.6564 10.5 17.0957 10.5 17.5538V18.6818C10.5 19.4098 11.0902 20 11.8182 20H12.9462C13.4043 20 13.8436 19.818 14.1676 19.4941L19.4857 14.1759C20.1603 13.5014 20.1603 12.4077 19.4857 11.7332L18.7668 11.0143ZM17.0312 11.7214C17.3152 11.4373 17.7757 11.4373 18.0597 11.7214L18.7786 12.4403C19.0627 12.7243 19.0627 13.1848 18.7786 13.4688L13.4605 18.787C13.3241 18.9234 13.1391 19 12.9462 19H11.8182C11.6425 19 11.5 18.8575 11.5 18.6818V17.5538C11.5 17.3609 11.5766 17.1759 11.713 17.0395L17.0312 11.7214Z"
    />
  </svg>
);

export default PencilSvg;
