import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {useParams, useLocation} from "react-router-dom";
import {BlankTemplate} from "../../../packages/oat-design-template";
import {setTemplateID} from "../../../packages/oat-design-template/store/actions/template/template.action";
import {selectUserInfo} from "../../../redux/user/authUser";

const CustomerBlankTemp = ({user}) => {
  let params = useParams();
  const query = new URLSearchParams(useLocation().search);
  const folder = query.get("folder");
  const [isSaving, setIsSaving] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const saveUserTemplate = async (item) => {
    if (!isSaving) {
      setIsSaving(true);

      const newTimeoutId = setTimeout(() => {
        // If the request is taking too long, cancel it
        abortController.abort();
        setIsSaving(false);
      }, 45000); // 45 seconds
      setTimeoutId(newTimeoutId);

      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: user.name,
            workspaceID: "myWorkspace",
            folder: {
              ID: folder ? folder : "dashboard",
              index: 1,
            },
            tempInfo: item.templateInfo,
            tempDetails: item.template,
          }),
          signal,
        }
      )
        .then((response) => {
          clearTimeout(timeoutId);
          if (!response.ok) {
            throw new Error("Network response error");
          }
          return response.json();
        })
        .then((data) => {
          setIsSaving(false);
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          setIsSaving(false);
        });
    }
  };

  return (
    <BlankTemplate
      onSave={saveUserTemplate}
      apiEndpoint={`http://localhost:4000/flyers`}
      buttonTitle="Update"
      isSaving={isSaving}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(CustomerBlankTemp);
