import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import { TableHeaderTop, TableHeaderLeft, TableHeaderTopLeft } from "./tableHeaderIcons";

const HeaderTypes = ({
  slideID,
  fieldID,
  storeFieldUndoRedo,
  updateFieldStyles,
  updateFieldCollaboration,
}) => {
  const handleUpdates = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const selectTop = () => {
    handleUpdates("headerType", "top");
  };

  const selectLeft = () => {
    handleUpdates("headerType", "left");
  };

  const selectTopLeft = () => {
    handleUpdates("headerType", "top-left");
  };

  return (
    <Wrapper>
      <Name>Type</Name>
      <Options>
        <WrapSvg onClick={selectTop}>
          {TableHeaderTop}
        </WrapSvg>
        <WrapSvg onClick={selectLeft}>
          {TableHeaderLeft}
        </WrapSvg>
        <WrapSvg onClick={selectTopLeft}>
          {TableHeaderTopLeft}
        </WrapSvg>
      </Options>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const Name = styled.div`
  flex: 1;
  padding: 0 1em;
  @media only screen and (max-width: 850px) {
    padding: 0;
  }
`;

const WrapSvg = styled.div`
  width: 38px;
  height: 20px;
  cursor: pointer;
`

const Options = styled.div`
  flex: 1;
  display: flex;
  div {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(HeaderTypes);
