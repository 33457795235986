import React, {useEffect} from "react";
import modules, {userAuthModules, adminModules} from "./modules";
import {Route, useLocation, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Routes} from "react-router-dom";
import "./App.css";
import {selectUserInfo} from "./redux/user/authUser";
import ScrollToTop from "./utils/ScrollToTop";
import {Auth} from "aws-amplify";
import {
  updateUserAuthStatus,
  dispatchSignOut,
  updateLoadingStatus,
} from "./redux/user/actions/authUser.action";
import {Spinner} from "./packages/oat-ui";
import styled from "styled-components";
import {OatUnknownRoute} from "./packages/oat-public";
import RequestLogin from "./packages/oat-public/handleRoutes/RequestLogin";
// import ReactGA from 'react-ga4';

const App = ({
  authUser,
  updateUserAuthStatus,
  updateLoadingStatus,
  loading,
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  // ReactGA.initialize('G-CCQ45911PH');
  // ReactGA.send({ hitType: "pageview", page: location.pathname });  

  // inital loading status is true (show spinner)
  // then switched to false after being checked user's auth status
  useEffect(() => {
    //https://stackoverflow.com/questions/55739848/what-is-the-difference-between-auth-currentauthenticateduser-and-auth-currents

    // Auth.currentSession()
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));
    const asyncFn = async () => {
      if (!authUser.loggedIn.status) {
        // authUser &&
        try {
          const currentUser = await Auth.currentAuthenticatedUser();
          const currentSession = currentUser.signInUserSession;
          currentUser.refreshSession(
            currentSession.refreshToken,
            (err, session) => {
              // do something with the new session
            }
          );

          // await Auth.currentAuthenticatedUser()
          //   .then(async (user) => {
          const dynamoUsername = currentUser.attributes["custom:acc-username"];
          await fetch(
            `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${dynamoUsername}/userInfo`
          )
            .then((res) => res.json())
            .then((data) => {
              updateUserAuthStatus({
                cognito: currentUser,
                dynamo: data,
              });
              return () => {};
            })
            .catch((e) => {});
        } catch (e) {
          updateLoadingStatus();
        }
      } else {
        updateLoadingStatus();
      }
    }
    asyncFn();
  }, [authUser]);

  const withoutAuthRoutes = modules.map((module) => {
    return module.map((route) => {
      if (route.restricted && !route.restricted.status) {
        return <Route {...route.routeProps} key={route.name} />;
      }
    });
  });

  const authUserRoutes = userAuthModules.map((module) => {
    return module.map((route) => {
      if (
        route.restricted &&
        route.restricted.status &&
        authUser &&
        authUser.loggedIn.status
      ) {
        return <Route {...route.routeProps} key={route.name} />;
      } else if (
        route.restricted &&
        route.restricted.status &&
        authUser &&
        !authUser.loggedIn.status
      ) {
        return <Route {...route.routeProps} element={<RequestLogin />} />;
      }
    });
  });

  const adminRoutes = adminModules.map((module) => {
    return module.map((route) => {
      if (
        route.restricted &&
        route.restricted.status &&
        route.restricted.role === "super-admin" &&
        authUser &&
        authUser.staff.type === "super-admin" &&
        authUser.loggedIn.status
      ) {
        return (
          <Route {...route.routeProps} key={route.name} />
        );
      } else if (
        route.restricted &&
        route.restricted.status &&
        route.restricted.role === "admin" &&
        authUser &&
        authUser.loggedIn.status
      ) {
        return <Route {...route.routeProps} key={route.name} />;
      } else if (route.restricted && authUser && authUser.loggedIn.status) {
        return <Route {...route.routeProps} key={route.name} />;
      }
    });
  });

  let routes = null; //{withoutAuthRoutes};
  const loadingSpinner = (
    <Route
      path="*"
      element={
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      }
    />
  );

  if (
    authUser &&
    authUser.loggedIn.status &&
    (authUser.loggedIn.type === "super-admin" ||
      authUser.loggedIn.type === "admin")
  ) {
    routes = (
      <Routes>
        {adminRoutes}
        {withoutAuthRoutes}
        <Route path="*" element={<OatUnknownRoute />} />
        {loading ? (
          loadingSpinner
        ) : (
          <Route path="*" element={<OatUnknownRoute />} />
        )}
      </Routes>
    );
  } else if (
    authUser &&
    authUser.loggedIn.status &&
    authUser.loggedIn.type === "user"
  ) {
    routes = (
      <Routes>
        {authUserRoutes}
        {withoutAuthRoutes}
        {loading ? (
          loadingSpinner
        ) : (
          <Route path="*" element={<OatUnknownRoute />} />
        )}
      </Routes>
    );
  } else if (loading || !window.navigator.onLine) {
    routes = <Routes>{loadingSpinner}</Routes>;
  } else {
    routes = (
      <Routes>
        {authUserRoutes}
        {withoutAuthRoutes}
        <Route path="*" element={<OatUnknownRoute />} />
      </Routes>
    );
  }

  return <ScrollToTop>{routes}</ScrollToTop>;
};

const WrapSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${window.innerHeight - 20}px;
`;

const mapStateToProps = (state) => {
  return {
    authUser: selectUserInfo(state.authUser),
    loading: state.authUser.loading,
  };
};

export default connect(mapStateToProps, {
  updateUserAuthStatus,
  dispatchSignOut,
  updateLoadingStatus,
})(App);
