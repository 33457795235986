import React, {useState} from "react";
import styled from "styled-components";
// import EnglishFonts from "../../../editDesign/editingPanel/panelTypes/shared/fonts/EnglishFonts";
import {InputName} from "../../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
// import {CloseLayout} from "../../../editDesign/editingPanel/panelTypes/desktop/common/DisplayFontList";
// import {Close} from "../../../../../oat-svg-icons";
import FontList from "../../../editDesign/editingPanel/panelTypes/desktop/common/fontFamily/FontList";

const ThemeBuilderFontFamily = ({
  fontFamily,
  fontWeight,
  fontStyle,
  onChange,
}) => {
  const [showFontsPanel, setShowFontsPanel] = useState(false);

  // const toggleFontPanel = () => {
  //   setShowFontsPanel(!showFontsPanel);
  // };

  return (
    <>
      <Wrapper>
        <InputName>Choose Fonts</InputName>

        <div style={{flex: 1}}>
          <FontList
            onSelect={onChange}
            selected={fontFamily}
            overflowY="scroll"
            height={160}
            minWidth={132}
            fontWeight={fontWeight}
            fontStyle={fontStyle}
          />
        </div>
        {/* <Button onClick={toggleFontPanel}>
          <div>
            Select <span style={{paddingLeft: 7, fontWeight: 700}}>+</span>
          </div>
        </Button>

        <Slider style={{left: showFontsPanel ? 0 : -350, width: 350}}>
          <CloseLayout style={{width: 350}}>
            <WrapSvg onClick={toggleFontPanel}>{Close}</WrapSvg>
          </CloseLayout>

          <div
            style={{
              width: "100%",
              height: window.innerHeight,
              overflowY: "scroll",
              margin: "60px 0",
            }}
          >
            <EnglishFonts
              showPanel={showFontsPanel}
              fontFamily={value}
              onChangeStyle={onChange}
            />
          </div>
        </Slider> */}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const Button = styled.div`
  flex: 1;
  cursor: pointer;
  div {
    flex: 1;
    color: ${({theme}) => theme.deskPrimaryTxt};
    background: ${({theme}) => theme.deskPrimaryBg};
    box-shadow: ${({theme}) => theme.boxShadow1};
    padding: 8px 10px;
    box-sizing: border-box;
    max-width: 98px;
    border-radius: 8px;
  }
`;

const Slider = styled.div`
  position: fixed;
  top: 60px;
  transition: all 0.3s ease;
  background: ${({theme}) => theme.deskPrimaryBg2};
  z-index: 9999;
`;

const WrapSvg = styled.div`
  width: 18px;
  height: 18px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
  padding-right: 12px;
  cursor: pointer;
`;

export default ThemeBuilderFontFamily;
