import React from "react";
import styled from "styled-components";
import {primaryBlueBtn} from "../../../oat-color-variables";

const CreateButton = ({onClick, title}) => {
  return (
    <WrapSelection>
      <SelectWorkSpace onClick={onClick}>
        <span>{title}</span>
        <Circle />
      </SelectWorkSpace>
    </WrapSelection>
  );
};

const WrapSelection = styled.div`
  height: 36px;
  width: 100%;
  position: relative;
  margin-top: 8px;
  @media only screen and (min-width: 850px) {
    height: 44px;
  }
`;

const SelectWorkSpace = styled.div`
  background: ${primaryBlueBtn};
  height: 100%;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  font-size: 0.9rem;
  span {
    font-size: 1rem;
    padding: 8px 12px;
    margin: 6px;
    border-radius: 8px;
  }
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  margin-right: 12px;
`;

export default CreateButton;
