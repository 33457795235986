export function genUID(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  // Ensure first character is a lowercase letter (a-z);
  // important
  // icon querying classnames cannot start with number
  let result = characters.charAt(Math.floor(Math.random() * 26)); 
  
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
