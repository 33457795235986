import React from "react";
import ModalProcessing from "../../dashboardPanel/userTemplates/actions/ModalProcessing";
import ShowTemplateLink from "../../dashboardPanel/userTemplates/actions/ShowTemplateLink";

const ModalTypesProfile = ({type, close, selectedItem, user}) => {
  let displayModal = null;

  switch (type) {
    case "processing":
      displayModal = <ModalProcessing close={close} />;
      break;
    case "show-template-link":
      displayModal = (
        <ShowTemplateLink
          close={close}
          user={user}
          selectedItem={selectedItem}
        />
      );
      break;
    default:
      displayModal = "";
      break;
  }

  return displayModal;
};

export default ModalTypesProfile;
