import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  changeSlide,
  updateSlideTitle,
} from "../../../../store/actions/template/slide.action";
import {
  selectTotalSlides,
  selectActiveSlide,
  selectActiveSlideInfo,
  selectActiveSlideID,
} from "../../../../store/selectors/template/slide.selector";
import {ArrowLeft, ArrowRight} from "../../../../ui/svg/Arrow";
// import GroupSlideSection from "./GroupSlideSection";

const DeskToggleSlides = ({
  activeSlide,
  activeSlideID,
  changeSlide,
  slideInfo,
  slideLength,
  updateSlideTitle,
}) => {

  const onBackwardSlide = () => {
    if (activeSlide <= 0) return;
    changeSlide(activeSlide - 1);
  };

  const onForwardSlide = () => {
    if (activeSlide === slideLength - 1) return;
    changeSlide(activeSlide + 1);
  };

  const handleTitleChange = (e) => {
    updateSlideTitle(e.target.value);
  };

  return (
    <Section>
      {/* <GroupSlideSection
        slideInfo={slideInfo}
        onTitleChange={handleTitleChange}
      /> */}
      <WrapSlideToggle>
        <WrapSvg $edge={activeSlide === 0} onClick={onBackwardSlide}>
          {ArrowLeft}
        </WrapSvg>
        <Text>{activeSlide + 1 + ` / ` + slideLength}</Text>
        <WrapSvg
          $edge={slideLength - 1 === activeSlide}
          onClick={onForwardSlide}
        >
          {ArrowRight}
        </WrapSvg>
      </WrapSlideToggle>
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.deskBtmSectionBoxShadow};
  border: 1px solid ${({theme}) => theme.deskBtnSectionBorder};
  color: ${({theme}) => theme.deskPrimaryTxt};
  border-radius: 6px;
  justify-content: center;
  display: flex;
`;

const WrapSlideToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;
  width: 170px;
`;

const Text = styled.p`
  font-size: 0.9rem;
  padding: 0 18px;
  color: #747474;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
`;

const WrapSvg = styled.div`
  width: 30px;
  height: 30px;
  fill: ${({theme}) => theme.svgIconColor};
  opacity: ${({$edge}) => (!$edge ? 0.7 : 0.25)};
  cursor: ${({$edge}) => (!$edge ? "pointer" : "not-allowed")};
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeSlide: selectActiveSlide(designTemplate),
    activeSlideID: selectActiveSlideID(designTemplate),
    slideInfo: selectActiveSlideInfo(designTemplate),
    slideLength: selectTotalSlides(designTemplate),
  };
};

export default connect(mapStateToProps, {changeSlide, updateSlideTitle})(
  DeskToggleSlides
);
