import React, {useState} from "react";
import ColorPanel from "./ColorPanel";
import tinycolor from "tinycolor2";
import Hue from "./Hue";
import AlphaPicker from "./Alpha/AlphaPicker";

const ColorPicker = ({onChange, onSelectionEnd, activeColor}) => {
  const toHsl = tinycolor(activeColor).toHsl();
  const [alpha, setAlpha] = useState(toHsl);

  // HSV
  const handleHSVUpdate = (color) => {
    const hsl = color.toHsl();
    const hsla = tinycolor(`hsla ${hsl.h} ${hsl.s} ${hsl.l} ${alpha.a}`);
    const value = {
      hex: hsla.toHex8String(),
      rgb: hsla.toRgbString(),
      hsl: hsla,
      hsv: hsla.toHsv(),
    };
    return value;
  };

  const updateHSV = (color) => {
    const value = handleHSVUpdate(color);
    onChange({...value});
  };

  const updateHSVOnSelectionEnd = (color) => {
    const value = handleHSVUpdate(color);
    onSelectionEnd({...value});
  };

  // HSL
  const handleHSLUpdate = (color) => {
    if (color) {
      const hsl = color.toHsl();
      const hsla = tinycolor(`hsla ${hsl.h} ${hsl.s} ${hsl.l} ${alpha.a}`);
      const value = {
        hex: hsla.toHex8String(),
        rgb: hsla.toRgbString(),
        hsl: hsla,
        hsv: hsla.toHsv(),
      };
      return value;
    }
  };

  const updateHSL = (color) => {
    const newValue = handleHSLUpdate(color);
    onChange({...newValue});
  };

  const updateHSLOnSelectionEnd = (color) => {
    const newValue = handleHSLUpdate(color);
    onSelectionEnd({...newValue});
  };

  // alpha
  const handleAlphaUpdate = (color) => {
    const transform = tinycolor(
      `hsla ${color.h} ${color.s} ${color.l} ${color.a}`
    );
    const hex = transform.toHex8String();
    const rgb = transform.toRgbString();
    const hsl = transform;
    const hsv = transform.toHsv();
    setAlpha({
      ...alpha,
      a: color.a,
      h: color.h,
    });
    const newValue = {
      hex,
      rgb,
      hsl,
      hsv,
    };
    return newValue;
  };

  const updateAlpha = (color) => {
    const newValue = handleAlphaUpdate(color);
    onChange({...newValue});
  };

  const updateAlphaOnSelectionEnd = async (color) => {
    const newValue = handleAlphaUpdate(color);
    await onSelectionEnd({...newValue});
  };

  return (
    <>
      <ColorPanel
        hsv={tinycolor(activeColor).toHsv()}
        hsl={tinycolor(activeColor).toHsl()}
        onChange={updateHSV}
        onSelectionEnd={updateHSVOnSelectionEnd}
      />
      <div style={{width: "100%", height: 10}}>
        <Hue
          hsl={tinycolor(activeColor).toHsl()}
          onChange={updateHSL}
          onSelectionEnd={updateHSLOnSelectionEnd}
        />
      </div>
      <div style={{width: "100%", height: 10, margin: "10px 0 14px"}}>
        <AlphaPicker
          hsl={tinycolor(activeColor).toHsl()}
          rgb={tinycolor(activeColor).toRgb()}
          onChange={updateAlpha}
          onSelectionEnd={updateAlphaOnSelectionEnd}
        />
      </div>
    </>
  );
};

export default ColorPicker;
