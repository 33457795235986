const Lock = (
  // <svg viewBox="0 0 24 24">
  //   <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9"></path>
  // </svg>
  <svg viewBox="0 0 18 23">
    <rect
      x="1.2"
      y="9.4"
      width="15.5"
      height="11.64"
      rx="1.2"
      ry="1.2"
      fill="none"
      stroke="#787879"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <path
      d="M13.3,9.7v-4C13.3,3.6,11.3,2,9,2H9C6.7,2,4.7,3.6,4.7,5.7v4"
      fill="none"
      stroke="#787879"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
  </svg>
);

export default Lock;
