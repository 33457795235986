import React, {useState, useEffect, useRef} from "react";
import SpecialButton from "../../../../../ui/inputs/SpecialButton";
import styled from "styled-components";
import {connect} from "react-redux";
import {
  selectTemplateID,
  selectTemplateOwner,
  selectTempVisibility,
} from "../../../../../store/selectors/template/template.selector";
import {useParams} from "react-router";
import {red1} from "../../../../../../oat-color-variables";
import {changeTempVisibility} from "../../../../../store/actions/template/template.action";
import {Spinner} from "../../../../../../oat-ui";
import {tooltipInfo} from "../../../../../ui/styles/Tooltip";
import {TooltipStyles} from "../../../../../ui/styles/TooltipStyles";
import AddPasscode from "./AddPasscode";
import Switch from "../../../editingPanel/panelTypes/shared/inputs/Switch";
import {selectActiveSlideID} from "../../../../../store/selectors/template/slide.selector";

const ShareTempLink = ({owner, visibility, slideID, changeTempVisibility, tempID}) => {
  const [tempLink, setTempLink] = useState(null);
  const [copied, setCopied] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [currentSlideOnly, setCurrentSlideOnly] = useState(false);
  const [embedMode, setEmbedMode] = useState(false);
  const inputRef = useRef(null);
  const textareaRef = useRef(null);
  const [embedCode, setEmbedCode] = useState('<iframe src="https://example.com" width="600" height="400"></iframe>');

  const handleCopy = () => {
    const urlLink = inputRef.current.value;
    navigator.clipboard.writeText(urlLink);
    console.log(urlLink, 'hii')
    setCopied(true);
  };

  let params = useParams();
  const {profileSlug} = params;

  useEffect(() => {
    let link = "";
    if (embedMode && currentSlideOnly) {
      link = !profileSlug
      ? window.location.origin.toString() + `/p/${tempID}?embed=true&slide=${slideID}`
      : window.location.origin.toString() + `/${profileSlug}/${tempID}?embed=true&slide=${slideID}`
    }
    else if (embedMode) {
      link = !profileSlug
      ? window.location.origin.toString() + `/p/${tempID}?embed=true`
      : window.location.origin.toString() + `/${profileSlug}/${tempID}?embed=true`
    }
    else if(currentSlideOnly) {
      link = !profileSlug
      ? window.location.origin.toString() + `/p/${tempID}?slide=${slideID}`
      : window.location.origin.toString() + `/${profileSlug}/${tempID}?slide=${slideID}`
    } 
    else {
      link = !profileSlug
      ? window.location.origin.toString() + `/p/${tempID}`
      : window.location.origin.toString() + `/${profileSlug}/${tempID}`;
    }
    setTempLink(link);
  }, [tempID, slideID, currentSlideOnly, embedMode]);

  const changeVisibility = async () => {
    if (!processing) {
      const updateValue = visibility === "public" ? "private" : "public";
      setProcessing(true);

      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${owner}/template/visibility`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempID,
            visibility: updateValue,
          }),
        }
      )
        .then(() => {
          setProcessing(false);
          changeTempVisibility(updateValue);
        })
        .catch((e) => {
          setProcessing(false);
        });
    }
  };

  const toggleCurrentSlideOnly = () => {
    setCurrentSlideOnly(!currentSlideOnly);
  }

  const toggleEmbedMode = () => {
    setEmbedMode(!embedMode);
  }

  {
    /* <ShareSvgIcon style={{height: 150, marginTop: 25}}>
        <WrapSvg style={{height: "100%"}}>{tempSvg}</WrapSvg>
      </ShareSvgIcon> */
  }
  return (
    <Wrapper>
      <Field>
        <Name>
          {visibility === "public"
            ? "Anyone with the link can view it"
            : "Only you & collaborators can access it"}
        </Name>
      </Field>
      
      <WrapInput>
        <LinkInput ref={inputRef} value={tempLink} readOnly={true} />
        <SpecialButton
          onClick={handleCopy}
          styles={{
            width: 104,
            height: "100%",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            background: !copied ? undefined : "#26a769",
          }}
        >
          {!copied ? "Copy" : "Copied"}
        </SpecialButton>
      </WrapInput>

      <div style={{ display: 'flex' }}>
        <WrapCheckBox style={{ marginRight: 20}}>
          <input
            type="checkbox"
            id="link-to-current-slide-21"
            name="link-to-current-slide-21"
            value={currentSlideOnly}
            onChange={toggleCurrentSlideOnly}
          />
          <label htmlFor="link-to-current-slide-21">
            Current Slide
          </label>
        </WrapCheckBox>

        <WrapCheckBox>
          <input
            type="checkbox"
            id="embed-mode-41"
            name="embed-mode-41"
            value={embedMode}
            onChange={toggleEmbedMode}
          />
          <label htmlFor="embed-mode-41">
            Embed
          </label>
        </WrapCheckBox>
      </div>

      <Gap />

      <div
        style={{position: "relative", display: "flex", alignItems: "center"}}
      >
        <Switch
          title="Set as Private"
          value={visibility === "private" ? true : false}
          onItemChange={changeVisibility}
          checked={visibility === "private" ? true : false}
          displayTooltip={{
            status: true,
            info: "Only the workspace members can access.",
          }}
          disabledPadding={true}
        />
        {processing ? (
          <div
            style={{
              transform: `scale(0.65)`,
              position: "absolute",
              left: "64%",
            }}
          >
            <Spinner></Spinner>
          </div>
        ) : undefined}
      </div>

      <div style={{marginBottom: 28}} />

      <AddPasscode />

      <Gap />

      <Name>Embed</Name>
      
      <EmbedInput
        ref={textareaRef}
        value={`<iframe src=${tempLink} alt="template-link" width="600" height="400"></iframe>`}
        rows="4"
        cols="80"
        style={{ resize: 'none' }}
      />

    </Wrapper>
  );
};

const WrapInput = styled.div`
  display: flex;
  width: 100%;
  height: 34px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px 16px;
`;

export const LinkInput = styled.input`
  font-size: 0.9rem;
  background: ${({theme}) => theme.deskSecondaryBg};
  color: ${({theme}) => theme.deskPrimaryTxt};
  flex: 1;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 5px;
  width: 350px;
  white-space: pre;
  overflow: hidden;
  cursor: text;
  user-select: text;
  border: none;
  outline: none;
`;

export const Field = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
`;

export const Name = styled.div`
  flex: 2;
  font-weight: 700;
  position: relative;
  display: flex;
  position: relative;
  padding-bottom: 14px;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

export const Value = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Tooltip = styled.div`
  ${tooltipInfo};
  padding: 0;
  background: ${({theme}) => theme.deskSecondaryBg};
`;

const WrapLockSvg = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 36px;
  stroke: ${({visible}) => (visible ? "#74ff5c" : red1)};
  fill: ${({visible}) => (visible ? "#74ff5c" : red1)};
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  background: transparent;
  outline: none;
  border: none;
  opacity: ${({processing}) => (processing ? 0.5 : 1)};
  &:hover {
    background: ${({theme}) => theme.deskGap};
  }
  &:hover ${Tooltip} {
    display: flex;
    width: 50px;
    left: -18px;
    bottom: -26px;
  }
`;

const Gap = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0 28px;
  background: ${({theme}) => theme.deskGap};
`;

const WrapCheckBox = styled.div`
  margin: 20px 0 0;
  label {
    user-select: none;
    margin-left: 10px;
  }
`;

const EmbedInput = styled.textarea`
  border: 1px solid ${({theme}) => theme.deskGap};
  background: ${({theme}) => theme.deskSecondaryBg};;
  color: ${({theme}) => theme.deskPrimaryTxt};
  border-radius: 8px;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempID: selectTemplateID(designTemplate),
    owner: selectTemplateOwner(designTemplate),
    visibility: selectTempVisibility(designTemplate),
    slideID: selectActiveSlideID(designTemplate)
  };
};

export default connect(mapStateToProps, {changeTempVisibility})(ShareTempLink);
