import {
  OatPrivateImages,
  OatPublicImages,
} from "../../packages/oat-admin-panel";
import Auth from "./auth/Auth";
import AdminTemplates from "./dashboard/AdminTemplates";
import AdminTemplateDetails from "./dashboard/AdminTemplateDetails";
import AdminCreateTemplate from "./designTemplate/AdminCreateTemplate";
import AdminUpdateTemplate from "./designTemplate/AdminUpdateTemplate";
import OatCustomerList from "../../packages/oat-admin-panel/containers/customers/CustomerList";
import StaffList from "./staff/StaffList";
import AdminTempThemes from "./designTemplate/templateThemes/AdminTempThemes";
import AdminCreateTempTheme from "./designTemplate/templateThemes/AdminCreateTempTheme";
import AdminUpdateTempTheme from "./designTemplate/templateThemes/AdminUpdateTempTheme";
import AdminLayouts from "./dashboard/AdminLayouts";

export default [
  {
    routeProps: {
      path: "/auth/backend/signin",
      exact: true,
      element: <Auth />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/designs/:category",
      exact: true,
      element: <AdminTemplates />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  // keep both, above
  {
    routeProps: {
      path: "/admin/designs/:category/:ratio",
      exact: true,
      element: <AdminTemplates />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/designs/:category/:ratio/:tempID",
      exact: true,
      element: <AdminTemplateDetails />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/library/public",
      exact: true,
      element: <OatPublicImages />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/library/public/:type",
      exact: true,
      element: <OatPublicImages />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/library/public/:type/:category",
      exact: true,
      element: <OatPublicImages />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/library/public/:type/:category/:packID",
      exact: true,
      element: <OatPublicImages />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/library/private",
      exact: true,
      element: <OatPrivateImages />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  // create template
  {
    routeProps: {
      path: "/admin/designs/create/:category/:ratio",
      exact: true,
      element: <AdminCreateTemplate />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  // update template
  {
    routeProps: {
      path: "/admin/designs/edit/:category/:tempID",
      exact: true,
      element: <AdminUpdateTemplate />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/admin/customer-list",
      exact: true,
      element: <OatCustomerList />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "Customer List",
  },
  {
    routeProps: {
      path: "/admin/staff-list",
      exact: true,
      element: <StaffList />,
    },
    restricted: {
      status: true,
      role: "super-admin",
    },
    name: "Staff List",
  },
  {
    routeProps: {
      path: "/admin/template-themes",
      exact: true,
      element: <AdminTempThemes />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "Template Themes",
  },
  {
    routeProps: {
      path: "/admin/template-themes/create",
      exact: true,
      element: <AdminCreateTempTheme />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "Create Themes",
  },
  {
    routeProps: {
      path: "/admin/template-themes/update/:tagColor/:themeID",
      exact: true,
      element: <AdminUpdateTempTheme />,
    },
    restricted: {
      status: true,
      role: "admin",
    },
    name: "Update Themes",
  },
];
