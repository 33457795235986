export const ToTop = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>To Top</title>
            <circle cx="130.4" cy="158.9" r="74.7" fill="#e3e5f6"/>
            <circle cx="130.4" cy="84.2" r="74.7" fill="#91a5f3" opacity="0.8" />
        </svg>
    );
};

export const NoAnimation = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>No Animation</title>
            <circle cx="130.4" cy="121.5" r="113.8" fill="#91a5f3"/>
        </svg>
    );
};

export const ToBottom = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>To Bottom</title>
            <circle cx="130.4" cy="84.2" r="74.7" fill="#e3e5f6" stroke="grey"/>
            <circle cx="130.4" cy="158.9" r="74.7" fill="#91a5f3" opacity="0.8"/>
        </svg>
    );
};

export const ToRight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>To Right</title>
            <circle cx="93.1" cy="121.5" r="74.7" fill="#e3e5f6" stroke="grey"/>
            <circle cx="167.7" cy="121.5" r="74.7" fill="#91a5f3" opacity="0.8"/>
        </svg>
    )
}

export const ToLeft = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>To Left</title>
            <circle cx="167.7" cy="121.5" r="74.7" fill="#e3e5f6" stroke="grey"/>
            <circle cx="93.1" cy="121.5" r="74.7" fill="#91a5f3" opacity="0.8"/>
        </svg>
    )
}

export const ZoomOut = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>Zoom Out</title>
            <circle cx="130.4" cy="121.5" r="113.7" fill="#91a5f3" stroke="grey"/>
            <circle cx="130.4" cy="121.5" r="72.7" fill="#e3e5f6"/>
        </svg>
    )
}

export const ZoomIn = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>Zoom In</title>
            <circle cx="130.4" cy="121.5" r="113.7" fill="#e3e5f6" stroke="grey"/>
            <circle cx="130.4" cy="121.5" r="72.7" fill="#91a5f3"/>
        </svg>
    )
}

export const FadeIn = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.8 243.1">
            <title>Fade In</title>
            <path d="M184.3,21.3a113.8,113.8,0,1,0,59.9,100.2A113.9,113.9,0,0,0,184.3,21.3Z" fill="#e3e5f6" stroke="grey"/>
            <path d="M124.4,121.5A113.7,113.7,0,0,1,184.3,21.3a113.8,113.8,0,1,0,0,200.5A113.7,113.7,0,0,1,124.4,121.5Z" fill="#91a5f3" stroke="grey"/>
        </svg>
    )
}