import React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import placeholderImg from './placeholder-img.png';  // Import the local image

const frames = [
  {key: "f0", radius: 0},
  {key: "f01", radius: '100%'},
  {key: "f1", radius: "61% 39% 55% 0% / 61% 38% 62% 99%"},
  {key: "f2", radius: "199% 199% 199% 199% / 99% 99% 99% 99%"},
  {key: "f3", radius: "199% 39% 55% 0% / 61% 38% 62% 99%"},
  {key: "f4", radius: "4% 86% 38% 62% / 4% 90% 30% 51%"},
  {key: "f5", radius: "79% 79% 9% 9% / 79% 79% 9% 9%"},
  {key: "f6", radius: "79% 79% 15% 15% / 79% 79% 79% 79%"},
  {key: "f7", radius: "44% 66% 68% 24% / 44% 66% 63% 44%"},
  {key: "f8", radius: "66% 0% 0% 66% / 66% 0% 0% 66%"},
  {key: "f9", radius: "0% 66% 68% 0% / 0% 66% 63% 0%"},
  {key: "f10", radius: "66% 10% 10% 10% / 66% 10% 10% 10%"},
  {key: "f11", radius: "98% 10% 10% 10% / 98% 10% 10% 10%"},
  {key: "f12", radius: "10% 98% 10% 10% / 10% 98% 10% 10%"},
  {key: "f13", radius: "58% 10% 98% 10% / 98% 10% 98% 10%"},
  {key: "f14", radius: "18% 10% 10% 98% / 18% 10% 10% 98%"},
  {key: "f15", radius: "18% 10% 98% 10% / 18% 10% 98% 10%"},
  {key: "f16", radius: "45% 0% 0% 0%/45% 0% 0% 0%"},
  {key: "f17", radius: "0% 45% 0% 0%/0% 45% 0% 100%"},
  {key: "f18", radius: "0% 0% 0% 45%/0% 0% 0% 45%"},
  {key: "f19", radius: "0% 0% 45% 0%/0% 0% 45% 100%"},
];

const DisplayItem = ({frame, image, onChange}) => {
  const onValueChange = (value) => {
    onChange(frame?.radius);
  };
  return (
    <WrapFrame onClick={onValueChange}>
      <Image src={placeholderImg} radius={frame?.radius} />
    </WrapFrame>
  );
};
const ImageFrames = ({
  field,
  updateFieldStyles,
  slideID,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const updateFrame = async (value) => {
    await updateFieldStyles({
      active: {slideID, fieldID: field?.key},
      type: "borderRadius",
      value,
    });
    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  return (
    <Wrapper>
      {frames &&
        frames.map((frame) => (
          <DisplayItem
            key={frame?.key}
            frame={frame}
            image={field?.content}
            onChange={updateFrame}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex: 1;
  padding: 0.7em 0;
  justify-content: space-evenly;
`;

const WrapFrame = styled.div`
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 30%;
  cursor: pointer;
`;

const Image = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: ${({radius}) => radius};
  box-sizing: border-box;
  padding: 5px;
  @media only screen and (min-width: 850px) {
    padding: 10px;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    field: selectActiveFieldProps(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ImageFrames);
