import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../../../redux/user/authUser";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";
import {deskNavHeight} from "../../../../../../layoutSizes";
import {addSlideComment} from "../../../../../../store/actions/template/comment.action";
import {selectActiveSlideInfo} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectTempSourceID,
  selectTemplateID,
  selectWorkspaceMembers,
} from "../../../../../../store/selectors/template/template.selector";
import CommentRespones from "./CommentResponses";
import Comments from "./Comments";
import {createShortKey} from "../../../../../../store/reducers/functions/fields/utils";
import CommentInput from "./CommentInput";
import {useLocation, useNavigate} from "react-router-dom";
import {PanelHeader} from "../panelStyles";
import {useSocket} from "../../../../../../webSocket/useSocket";
import { red4 } from "../../../../../../../oat-color-variables";

export const commentPanelPadding = "12px";
const commentSectionHeight = 175;

const CommentSection = ({
  user,
  comments,
  addSlideComment,
  tempID,
  tempSourceID,
  slideID,
  wsMembers,
  theme,
}) => {
  const windowSize = getWindowSize();
  const [input, setInput] = useState("");
  const [actionRequire, setActionRequire] = useState(false);
  const [processing, setProcessing] = useState("");
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [viewThread, setViewThread] = useState({
    status: false,
    respondingTo: null,
    isResponding: false, // if responding show textarea
  });
  const {emitSocketEvents} = useSocket() || {};

  const handleInput = (e) => {
    const {value} = e.target;
    setInput(value);
  };

  const handleSubmit = async (mentionedUsers) => {
    if (user.username === null) {
      setError({
        status: true,
        message: 'You need to register to comment.',
      })
      return;
    }
    const payload = {
      ID: createShortKey(),
      comment: input,
      actionRequire: {
        status: actionRequire,
        resolved: false,
      },
      commentedBy: {
        name: user.name,
        username: user.username,
      },
      tempID,
      tempSourceID,
      slideID,
      subComments: [],
      mentionedUsers,
    };

    setProcessing(true);
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }

    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/comments`,
      {
        method: "POST",
        body: JSON.stringify({
          payload,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          const item = {
            ...payload,
            createdAt: data.response.createdAt,
          };
          setInput("");
          setActionRequire(false);
          addSlideComment(item, slideID);
          if (emitSocketEvents) {
            emitSocketEvents({
              actionType: "add-comment",
              item: {
                type: "",
                value: item,
              },
            });
          }
        } else {
          setError({
            status: true,
            message: "Something went wrong.",
          });
        }
        setProcessing(false);
      })
      .catch((e) => {
        setProcessing(false);
        setError({
          status: true,
          message: "Something went wrong.",
        });
      });
  };

  const viewResponses = ({item, isResponding}) => {
    setViewThread({
      status: true,
      selectedCommentID: item.ID,
      respondingTo: {
        ID: item.ID,
        commentedBy: item.commentedBy,
        comment: item.comment,
        subComments: item.subComments,
        createdAt: item.createdAt,
      },
      isResponding, // item.responding, // replying to main comment
    });
  };

  const hideCommentResponses = () => {
    setViewThread({
      status: false,
      respondingTo: null,
      isResponding: false,
    });
  };

  const changeActionStatus = () => {
    setActionRequire(!actionRequire);
  };

  // hightlight comment from url coming from noti link
  // localhost:3000/design/l6U20FXtoc?slide=igsk6&comment=z9iI2g
  const query = new URLSearchParams(useLocation().search);
  const commentID = query.get("comment");
  const subcommentID = query.get("subcomment");
  const location = useLocation();
  let navigate = useNavigate();
  const [highLightComment, setHighLightComment] = useState({
    id: "",
    status: false,
    type: null,
  });
  useEffect(() => {
    if (comments && commentID && commentID !== "") {
      const commentExists =
        comments &&
        comments.findIndex(
          (comment) =>
            comment.ID === commentID &&
            // error occurs with subchild
            ((!comment.actionRequire.status &&
              comment.actionRequire.resolved) ||
              !comment.actionRequire.status)
        );
      if (commentExists !== -1 && subcommentID === null) {
        setHighLightComment({
          id: commentID,
          status: true,
          type: "comment",
        });
        navigate(`${location.pathname}`);
        // window.location.href = "#" + commentID;
      } else if (commentExists !== -1 && subcommentID !== "") {
        const subcommentExists = comments[commentExists].subComments.findIndex(
          (subcomment) => subcomment.ID === subcommentID
        );
        if (subcommentExists !== -1) {
          setViewThread({
            status: true,
            selectedCommentID: commentID,
            isResponding: false, // item.responding, // replying to main comment
          });
          setHighLightComment({
            id: subcommentID,
            status: true,
            type: "subcomment",
          });
        }
        navigate(`${location.pathname}`);
      } else {
        navigate(`${location.pathname}`);
      }
    }
  }, [comments]);

  const [formatWsMemberList, setFormatWsMemberList] = useState([]);
  useEffect(() => {
    if (wsMembers && Object.keys(wsMembers).length > 0) {
      let items = [];
      Object.entries(wsMembers).map(([id, member]) => {
        if (id !== user.username) {
          items.push({
            id,
            display: member.name,
            email: member.email,
          });
          setFormatWsMemberList(items);
        }
      });
    }
  }, [wsMembers]);

  return (
    <Layout height={windowSize.width >= 850 ? windowSize.height : "100%"}>
      <Wrapper style={{marginTop: 4}}>
        {!viewThread.status ? (
          <>
            <PanelHeader>Comments</PanelHeader>
            <Comments
              username={user.username}
              comments={comments}
              height={
                // 58 - "comment" header
                windowSize.height - deskNavHeight - commentSectionHeight - 38
              }
              onViewResponse={viewResponses}
              highLightComment={highLightComment}
              theme={theme}
              searchList={formatWsMemberList}
            />
          </>
        ) : (
          comments &&
          comments.map((comment) =>
            comment.ID === viewThread.selectedCommentID ? (
              <CommentRespones
                user={user}
                key={comment.ID}
                commentThread={comment}
                isRespondingComment={viewThread.isResponding}
                $panelHeight={windowSize.height - deskNavHeight - 58}
                backToComments={hideCommentResponses}
                highLightComment={highLightComment}
                theme={theme}
                searchList={formatWsMemberList}
              />
            ) : undefined
          )
        )}
      </Wrapper>

      {/* display textarea  */}
      {!viewThread.status ? (
        <CommentInputSection>
          <div style={{position: "relative"}}>
            <p style={{fontSize: 14, color: red4, paddingLeft: 12}}>{error.message}</p>

            <CommentInput
              searchList={formatWsMemberList}
              theme={theme}
              value={{
                input,
                actionRequire,
              }}
              onChangeInput={handleInput}
              onChangeActionRequire={changeActionStatus}
              onSubmit={handleSubmit}
              processing={processing}
            />
          </div>
        </CommentInputSection>
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  @media only screen and (min-width: 850px) {
    height: ${({height}) => height - deskNavHeight - 72}px;
  }
  @media only screen and (max-width: 849px) {
    height: ${({height}) => height};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CommentInputSection = styled.div`
  @media only screen and (min-width: 850px) {
    position: absolute;
    bottom: 0;
  }
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    comments: selectActiveSlideInfo(state.designTemplate).comments,
    slideID: selectActiveSlideInfo(state.designTemplate).id,
    tempSourceID: selectTempSourceID(state.designTemplate),
    tempID: selectTemplateID(state.designTemplate),
    wsMembers: selectWorkspaceMembers(state.designTemplate),
  };
};

export default connect(mapStateToProps, {addSlideComment})(CommentSection);
