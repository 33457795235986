import React, {useState} from "react";
import styled from "styled-components";
import {TooltipStyles} from "../../../oat-ui/styles/TooltipStyles";
import {useNavigate} from "react-router";
import {Modal, Popper} from "../../../oat-ui";
import WorkspaceList from "./WorkspaceList";
import CreateWorkspace from "../sidebar/workspace/actions/CreateWorkspace";
import {paleBlue} from "../../userDashboardStyles";
import {userDashboardNavPanelHeight} from "../navigation/UserDashboardNav";

export const sectionPanelWidth = 70;
const sectionList = [
  {
    value: "workspaces",
    display: "Workspaces",
    iconSrc:
      "https://oat-default-init.s3.amazonaws.com/oat-all-web-icons/workspace-icon.png",
  },
  // {
  //   value: "calendar",
  //   display: "Calendar",
  //   iconSrc:
  //     "https://oat-default-init.s3.amazonaws.com/oat-all-web-icons/calendar.png",
  // },
  {
    value: "shared-to-me",
    display: "Share to Me",
    iconSrc:
      "https://oat-default-init.s3.amazonaws.com/oat-all-web-icons/paper-plane-lending.png",
  },
  // {
  //   value: "profile",
  //   display: "Profile",
  //   iconSrc:
  //     "https://oat-default-init.s3.amazonaws.com/oat-all-web-icons/user-avatar.png",
  // },
  {
    value: "purchased",
    display: "Purchased",
    iconSrc: 
      "https://oat-default-init.s3.amazonaws.com/oat-all-web-icons/purchased.png"
  }
  // {value: "library", display: "Library", temp: "L"},
];

const DisplayItem = ({item, index, onChange}) => {
  const onChangeWorkSpace = () => {
    onChange(item.value);
  };

  return (
    <WrapItem key={index} onClick={onChangeWorkSpace}>
      <Item style={{width: "100%", height: "100%"}}>
        {/* {item.value === "workspaces" ? CalendarIcon : ProfileIcon} */}
        <img style={{maxWidth: "100%"}} src={item.iconSrc} alt="" />
        {/* {item.value === "calendar" ? <img src={Calendar} alt="" /> : undefined}
        {item.value === "shared-to-me" ? (
          <img src={Profile} alt="" />
        ) : undefined} */}
      </Item>

      <Tooltip>{item.display}</Tooltip>
    </WrapItem>
  );
};

const Sections = () => {
  let navigate = useNavigate();
  const [openModal, setOpenModal] = useState({
    status: false,
    type: "workspace-list",
  });

  const switchSection = (item) => {
    if (item === "workspaces") {
      setOpenModal({
        status: true,
        type: "workspace-list",
      });
    } else {
      return navigate(`/dashboard/${item}`);
    }
  };

  const initCreateWorkspace = () => {
    setOpenModal({
      status: true,
      type: "create-workspace",
    });
  };

  const closeModal = () => {
    setOpenModal({
      status: false,
      type: null,
    });
  };

  return (
    <WrapSidebar>
      {sectionList &&
        sectionList.map((workspace, i) => (
          <DisplayItem
            item={workspace}
            index={i}
            key={i}
            onChange={switchSection}
          />
        ))}

      {openModal.status && openModal.type === "workspace-list" ? (
        <Popper
          width={300}
          height="auto"
          offset={{x: 78, y: 74}}
          styles={{
            border: "2px solid #e8e7e7",
            boxShadow: "0px 0px 0px 0px transparent",
          }}
          onClose={closeModal}
        >
          <WorkspaceList
            closeModal={closeModal}
            onCreate={initCreateWorkspace}
          />
        </Popper>
      ) : undefined}

      {openModal.status && openModal.type === "create-workspace" ? (
        <Modal width={380} height="auto" close={closeModal}>
          <CreateWorkspace closeModal={closeModal} close={closeModal} />
        </Modal>
      ) : undefined}
    </WrapSidebar>
  );
};

const WrapSidebar = styled.div`
  width: ${sectionPanelWidth}px;
  height: 100vh;
  background: ${paleBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${userDashboardNavPanelHeight + 12}px;
`;

const Tooltip = styled.div`
  ${TooltipStyles};
`;

const WrapItem = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  // background: #fff;
  // border-radius: 5px;
  // box-shadow: 0px 0px 3px #d1c6f5;
  margin: 10px 0;
  cursor: pointer;
  user-select: none;
  &:hover ${Tooltip} {
    display: flex;
    width: 82px;
    left: 52px;
  }
`;

const Item = styled.div`
  font-size: 1.2rem;
  font-family: "Courgette", cursive;
`;

export default Sections;
