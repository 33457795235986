import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {storeFieldUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {
  selectActiveField,
  selectActiveFieldStyles,
} from "../../../../../store/selectors/fields.selector";
import {selectActiveSlideID} from "../../../../../store/selectors/template/slide.selector";
import {ToBack} from "../../../../../ui/svg/ToBack";
import {ToFront} from "../../../../../ui/svg/ToFront";
import {useSocket} from "../../../../../webSocket/useSocket";
import {Title} from "./reuseStyles/Title";
import {
  zIndexToBack,
  zIndexToFront,
} from "../../../../../store/actions/fields/common.action";

const ZIndex = ({
  fieldID,
  slideID,
  value,
  zIndexToBack,
  zIndexToFront,
  storeFieldUndoRedo,
}) => {
  const {emitSocketEvents} = useSocket() || {};

  const toFront = () => {
    const active = {slideID, fieldID};
    zIndexToFront(active, value);
    const item = {type: "zIndex-to-front", value};
    storeFieldUndoRedo();
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "zIndex-changed", item});
    }
  };

  const toBack = () => {
    const active = {slideID, fieldID};
    zIndexToBack(active, value);
    const item = {type: "zIndex-to-back", value};
    storeFieldUndoRedo();
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "zIndex-changed", item});
    }
  };

  return (
    <Wrapper>
      {window.innerWidth >= 850 ? (
        <Field>
          <Title>Layer</Title>
        </Field>
      ) : undefined}
      <WrapSlider>
        <Button style={{marginRight: 10}} onClick={toFront}>
          <Content>To Front</Content>
          <Icon>{ToFront}</Icon>
        </Button>
        <Button onClick={toBack}>
          <Content>To Back</Content>
          <Icon>{ToBack}</Icon>
        </Button>
      </WrapSlider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

const WrapSlider = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0% 3%;
  flex: 2;
  @media only screen and (min-width: 850px) {
    margin: 0;
  }
`;

const Field = styled.div`
  display: flex;
  flex: 1;
`;

const Content = styled.div`
  padding-right: 8px;
  font-size: 0.8rem;
`;

const Icon = styled.div`
  display: flex;
  fill: ${({theme}) => theme.svgIconColor};
`;

const Button = styled.div`
  padding: 7px 0px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  border-radius: 6px;
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 849px) {
    background: ${({theme}) => theme.deskSecondaryBg};
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlideID(designTemplate),
    value: selectActiveFieldStyles(designTemplate).zIndex,
  };
};

export default connect(mapStateToProps, {
  storeFieldUndoRedo,
  zIndexToFront,
  zIndexToBack,
})(ZIndex);
