import {
  getItemXOffset,
  getItemYOffset,
} from "../../../../../utils/getItemOffset";
import {degToRadian, calcResize, getLength} from "../../dragResize/utils";

export const cropImageResize = ({
  e,
  size,
  pos,
  initialProps,
  tempScale,
  rotate,
  direction,
}) => {
  const clientX = getItemXOffset(e, tempScale);
  const clientY = getItemYOffset(e, tempScale);
  const deltaX = clientX - initialProps.orig.mouseX;
  const deltaY = clientY - initialProps.orig.mouseY;
  const minSize = 10;
  const rect = {
    width: size.w,
    height: size.h,
    centerX: pos.x + size.w / 2,
    centerY: pos.y + size.h / 2,
    rotateAngle: rotate,
  };

  const alpha = Math.atan2(deltaY, deltaX);
  const deltaL = getLength(deltaX, deltaY);
  const beta = alpha - degToRadian(rotate + 0);
  const deltaW = Math.cos(beta) * deltaL;
  const deltaH = Math.sin(beta) * deltaL;
  const ratio = 0;

  const {
    position: {centerX, centerY},
    size: {width, height},
    delta,
  } = calcResize(direction, rect, deltaW, deltaH, ratio, minSize, minSize);
  return {centerX, centerY, width, height, rotate, deltaX, deltaY, delta};
};
