import React from "react";
import styled from "styled-components";
import LayoutHoc from "../../layout/LayoutHoc";
import { brown1 } from "../../../oat-color-variables";

const AboutUs = () => {
  return (
    <LayoutHoc>
      <Layout>
        {/* <h2>Launched in Nov 2023</h2>
        <h2>Still a small Team</h2>
        <h2>With a passion for Code & Design</h2>
        <img src="https://oat-default-init.s3.amazonaws.com/about-us/medium-b2fa8ac0-6f43-11ee-afde-e9b508ca1b9b.png" alt="" /> */}

        <ProfileInfo>
          <WrapImg>
            <UserImg src="" alt="founder-1" />
          </WrapImg>
          <UserInfo>
            <h3>Name</h3>
            <p>Description</p>
          </UserInfo>
        </ProfileInfo>

        <ProfileInfo>
          <UserInfo>
            <h3>Name</h3>
            <p>Description</p>
          </UserInfo>
          <WrapImg>
            <UserImg src="" alt="founder-2" />
          </WrapImg>
        </ProfileInfo>
      </Layout>
    </LayoutHoc>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 120px 32px 32px;
  box-sizing: border-box;
  min-width: 500px;
  width: 100%;
  min-height: ${window.innerHeight}px;
  // h2 {
  //   font-size: 2.6rem;
  //   margin-bottom: 18px;
  //   color: ${brown1};
  // }
  // img {
  //   width: 500px;
  //   max-width: 100%;
  //   height: auto;
  //   margin: 55px 0 120px;
  // }


  @media only screen and (max-width: 850px) {
    align-items: flex-start;
    margin: 75px 32px 32px;
    h2 {
      font-size: 1.5rem;
      margin-bottom: 12px;
    }
  }
`;

const UserImg = styled.img`
  // max-width: 100%;
  // object-fit: cover;
`;

const WrapImg = styled.div`
  flex: 1;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex: 1;
  width: 60%;
`

export default AboutUs;
