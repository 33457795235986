import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {black1} from "../../../../../oat-color-variables";
import {BottomUpDrawer, Modal} from "../../../../../oat-ui";
import {
  selectActiveFolder,
  selectActiveWorkSpace,
} from "../../../../store/selectors/userDashboard.selector";
import WorkspaceInfo from "../../../sidebar/workspace/WorkspaceInfo";
import MobileFolders from "./folderSection/MobileFolders";
import DisplayWorkspaces from "./workspaces/DisplayWorkspaces";
import CreateFolder from "../../../sidebar/folderTypes/CreateFolder";

const WorkspaceTabs = ({activeWorkspace, folder}) => {
  const [toCreateFolder, setToCreateFolder] = useState({
    status: false,
    folderID: null,
    folderType: null,
    isSubfolder: null,
  });
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: "",
    height: 0,
  });

  const onSelectWorkspaces = () => {
    setOpenDrawer({
      status: true,
      type: "workspaces",
      height: window.innerHeight / 2 + 130,
    });
  };

  const onSelectFolders = () => {
    setOpenDrawer({
      status: true,
      type: "folders",
      height: window.innerHeight / 2 + 130,
    });
  };

  const onSelectSetting = () => {
    setOpenDrawer({
      status: true,
      type: "setting",
      height: window.innerHeight - 70,
    });
  };

  const onClose = () => {
    setOpenDrawer({...openDrawer, status: false, height: 0});
  };

  const handleToCreateFolder = ({folderID, folderType, isSubfolder}) => {
    setToCreateFolder({
      status: true,
      folderID,
      folderType,
      isSubfolder,
    });
  };

  const closeToCreateFolder = () => {
    setToCreateFolder({
      status: false,
      folderID: null,
      folderType: null,
      isSubfolder: null,
    });
  };

  const displayDrawer = () => {
    if (openDrawer.status) {
      if (openDrawer.type === "folders") {
        return (
          <MobileFolders
            onInitToCreateFolder={handleToCreateFolder}
            close={onClose}
          />
        );
      } else if (openDrawer.type === "workspaces") {
        return (
          <DisplayWorkspaces
            open={openDrawer.status}
            onSelectSetting={onSelectSetting}
            close={onClose}
          />
        );
      } else if (openDrawer.type === "setting") {
        return <WorkspaceInfo />;
      }
    }
  };

  return (
    <Layout>
      <WorkspaceAndFolder>
        <div style={{display: "flex"}}>
          <div className="workspace-name" onClick={onSelectWorkspaces}>
            {activeWorkspace.name}
          </div>
          <span> {"/"} </span>
          {folder.folderName ? (
            <div className="folder-name" onClick={onSelectFolders}>
              {folder.folderName}
            </div>
          ) : (
            <div className="folder-name" onClick={onSelectFolders}>
              Home
            </div>
          )}
        </div>
      </WorkspaceAndFolder>

      <BottomUpDrawer
        open={openDrawer.status}
        height={openDrawer.height}
        close={onClose}
        background="#fff"
      >
        <WrapDrawerContent style={{height: openDrawer.height}}>
          {displayDrawer()}
        </WrapDrawerContent>
      </BottomUpDrawer>

      {toCreateFolder.status ? (
        <Modal width={"auto"} height={"auto"} close={closeToCreateFolder}>
          <CreateFolder
            width={"auto"}
            close={closeToCreateFolder}
            folderType={toCreateFolder.folderType}
            isParentFolder={toCreateFolder.isSubfolder ? false : true}
            parentFolderID={toCreateFolder.folderID}
          />
        </Modal>
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;
  padding: 24px 0px 4px;
  transition: 0.3s ease-in-out;
  @media only screen and (max-width: 849px) {
    margin: 55px 0 12px;
  }
`;

const WrapDrawerContent = styled.div`
  margin: 22px;
`;

const WorkspaceAndFolder = styled.div`
  display: flex;
  font-size: 1rem;
  margin: 0 18px;
  color: ${black1};
  .folder-name,
  .workspace-name {
    text-transform: capitalize;
  }
  span {
    margin: 0 12px;
  }
`;

const mapStateToProps = (state) => {
  return {
    activeWorkspace: selectActiveWorkSpace(state.userDashboard),
    folder: selectActiveFolder(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(WorkspaceTabs);
