import React from "react";
import {resizerColor} from "../../../../../themes";

const ActiveTableCell = ({
  rows,
  columns,
  selectedRow,
  selectedColumn,
  tempScale,
}) => {
  const zIndexStyle = {zIndex: 9999};
  return (
    rows &&
    rows.map(
      (row, rowIndex) =>
        columns &&
        columns.map((column, colIndex) =>
          rowIndex === selectedRow && colIndex === selectedColumn ? (
            <div key={rowIndex + colIndex} className="active-table-cell">
              {/* left */}
              <div
                style={{
                  position: "absolute",
                  width: 1 / tempScale,
                  height: row.height - 2,
                  background: resizerColor,
                  transform: `translate(${column.xOffset - 1}px, ${
                    row.yOffset - 1
                  }px)`,
                  ...zIndexStyle,
                }}
              />
              {/* top */}
              <div
                style={{
                  position: "absolute",
                  width: column.width,
                  height: 1 / tempScale,
                  background: resizerColor,
                  transform: `translate(${column.xOffset - 1}px, ${
                    row.yOffset - 1
                  }px)`,
                  ...zIndexStyle,
                }}
              />
              {/* right */}
              <div
                style={{
                  position: "absolute",
                  width: 1 / tempScale,
                  height: row.height - 1,
                  background: resizerColor,
                  transform: `translate(${
                    column.width + column.xOffset - 1
                  }px, ${row.yOffset - 1}px)`,
                  ...zIndexStyle,
                }}
              />
              {/* bottom */}
              <div
                style={{
                  position: "absolute",
                  width: column.width,
                  height: 1 / tempScale,
                  background: resizerColor,
                  transform: `translate(${column.xOffset - 1}px, ${
                    row.height + row.yOffset - 3
                  }px)`,
                  ...zIndexStyle,
                }}
              />
            </div>
          ) : undefined
        )
    )
  );
};

export default ActiveTableCell;
