import React, {useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {Button, ButtonSpinner} from "../../../../../oat-ui";
import {
  DisplayInfo,
  tooltipInfo,
} from "../../../../../oat-ui/core/TooltipStyles";
import {Radio, RadioButton} from "../../../../../oat-ui/styles/RadioStyles";
import {createWorkspaceSuccess} from "../../../../store/actions/workspace.action";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import {
  Layout,
  Header,
  ModalBody,
  BottomSection,
  Footer,
  WrapField,
  FieldName,
  TextField,
  Error,
} from "../../../reuse/dashboardUI/modalStyles";
// import EmojiPicker from "emoji-picker-react";

const CreateWorkspace = ({createWorkspaceSuccess, user, close}) => {
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceType, setWorkspaceType] = useState("personal");
  const [workspaceIcon, setWorkspaceIcon] = useState("");
  const [openIconBox, setOpenIconBox] = useState(false);
  const [error, setError] = useState({status: false, message: null});
  const [processing, setProcessing] = useState(false);
  let navigate = useNavigate();

  const handleWorkspaceName = (e) => {
    const {value} = e.target;
    setWorkspaceName(value);
  };

  const selectPersonal = () => {
    setWorkspaceType("personal");
  };

  const selectTeam = () => {
    setWorkspaceType("team");
  };

  const handleCreate = async () => {
    if (workspaceName === "") {
      setError({
        status: true,
        message: "Workspace name cannot be empty.",
      });
    } else if (!processing) {
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspaceName,
            workspaceType,
            name: user.name,
            email: user.email,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (data) => {
          if (data.status === "success") {
            const newWorkspaceID = data.response.workspaceID;
            const payload = {
              ID: newWorkspaceID,
              name: workspaceName,
              role: "owner",
              owner: user.username,
              type: workspaceType,
              icon: workspaceIcon,
            };
            await createWorkspaceSuccess(payload);
            setProcessing(false);
            await navigate(`/dashboard/${newWorkspaceID}/designs/home`);
            await close();
          } else {
            setProcessing(false);
            setError({
              status: false,
              message: data.message,
            });
          }
        })
        .catch((e) => {
          setProcessing(false);
          setError({
            status: false,
            message: e,
          });
        });
    }
  };

  const toggleIconBoxStatus = () => {
    setOpenIconBox(!openIconBox);
  };

  const selectEmojiIcon = (value) => {
    setWorkspaceIcon(value.unified);
  };

  return (
    <Layout>
      <ModalBody>
        <Header>Create Workspace</Header>

        <WrapField>
          <FieldName>Name</FieldName>
          <WrapInput>
            <TextField
              value={workspaceName}
              placeholder="New Workspace"
              onChange={handleWorkspaceName}
              autoFocus
            />
          </WrapInput>
        </WrapField>

        {/* <WrapField>
          <FieldName>Icon</FieldName>
          <WrapInput>
            <AddIcon onClick={toggleIconBoxStatus}>+ {workspaceIcon}</AddIcon>
            {openIconBox ? (
              <WrapPopper>
                <PopperBox>
                  <EmojiPicker
                    width={400}
                    height={400}
                    onEmojiClick={selectEmojiIcon}
                  />
                </PopperBox>
              </WrapPopper>
            ) : undefined}
          </WrapInput>
        </WrapField> */}

        {/* <WrapField style={{alignItems: "flex-start"}}>
          <FieldName>
            Type
            <Tooltip>
              ?
              <DisplayInfo>
                <div style={{paddingBottom: 12}}>
                  Personal workspace can only be accessible by you.
                </div>

                <div>
                  Team workspace, enables you to add members from your team to
                  access and collaborate.
                </div>
              </DisplayInfo>
            </Tooltip>
          </FieldName>

          <WrapInput>
            <Radio onClick={selectPersonal}>
              <RadioButton active={workspaceType === "personal"}>
                <div />
              </RadioButton>
              <span>Personal</span>
            </Radio>
            <Radio onClick={selectTeam}>
              <RadioButton active={workspaceType === "team"}>
                <div />
              </RadioButton>
              <span>Team</span>
            </Radio>
          </WrapInput>
        </WrapField> */}

        {error.status ? <Error>{error.message}</Error> : undefined}
      </ModalBody>

      <Footer>
        <BottomSection>
          <Button styles={{ width: 100 }} onClick={handleCreate} disabled={processing}>
            {!processing ? "Create" : <ButtonSpinner />}
          </Button>
        </BottomSection>
      </Footer>
    </Layout>
  );
};

const WrapInput = styled.div`
  flex: 1.2;
`;

const Tooltip = styled.div`
  ${tooltipInfo}
`;

const AddIcon = styled.div`
  width: 25px;
  height: 20px;
  padding: 5px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
`;

const WrapPopper = styled.div`
  position: relative;
`;

const PopperBox = styled.div`
  position: absolute;
  left: -140px;
  width: 400px;
  height: 400px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px #cbc3de;
  z-index: 9999;
`;

const mapStateToProps = (state) => {
  const {userDashboard} = state;
  return {
    user: selectUserInfo(state.authUser),
    workspaceID: selectActiveWorkSpace(userDashboard).ID,
  };
};

export default connect(mapStateToProps, {createWorkspaceSuccess})(
  CreateWorkspace
);
