export function fetchMediaInit() {
  return function (state, action) {
    return {
      ...state,
      loading: true,
      items: [],
    };
  };
}

export function fetchMediaSuccess() {
  return function (state, action) {
    return {
      ...state,
      loading: false,
      items: [
        ...state.items,
        ...action.payload,
      ],
      paginationToken: action.paginationToken
    };
  };
}

export function fetchMediaError() {
  return function (state, action) {
    return {
      ...state,
      loading: false,
      error: {
        status: true,
        message: action.error,
      },
    };
  };
}

export function removeImageItem() {
  return function (state, action) {
    return {
      ...state,
      items: state.items.filter((item) => (
        item?.key !== action.id
      ))
    };
  };
}

// upload
export function uploadMediaInit() {
  return function (state, action) {
    return {
      ...state,
      modal: {
        status: true,
        type: "upload-media",
        message: null,
        processing: true,
      },
      error: {
        status: false,
        message: null,
      },
    };
  };
}

export function uploadMediaSuccess() {
  return function (state, action) {
    return {
      ...state,
      items: [...state.items, action.payload],
      modal: {
        status: false,
        type: null,
        message: null,
        processing: false,
      },
    };
  };
}

export function uploadMediaError() {
  return function (state, action) {
    return {
      ...state,
      modal: {
        status: true,
        type: "error",
        message: null,
        processing: false,
      },
      error: {
        status: true,
        message: action.error,
      },
    };
  };
}

export function toggleAdminMediaModal() {
  return function (state, action) {
    return {
      ...state,
      modal: {
        status: action.status,
        type: action.modalType,
      },
    };
  };
}
