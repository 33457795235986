import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {selectTempNotiInfo} from "../../../../store/selectors/common.selector";
import {selectTemplateID} from "../../../../store/selectors/template/template.selector";
import Switch from "../../editingPanel/panelTypes/shared/inputs/Switch";
import {manageTemplateNotification} from "../../../../store/actions/template/template.action";

const ManageNotification = ({
  user,
  tempID,
  notiInfo,
  manageTemplateNotification,
}) => {
  const changeSubscriptionStatus = () => {
    const payload = {
      getUpdates: !notiInfo.getUpdates,
      notifiedByEmail: notiInfo.getUpdates ? notiInfo.notifiedByEmail : false,
    };
    manageTemplateNotification(payload);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/notification`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventType: "subscribeForNotification",
          subscribed: !notiInfo.getUpdates,
          payload: {
            name: user.name,
            email: user.email,
          },
        }),
      }
    )
      .then((response) => response.json())
      .then(() => {})
      .catch(() => {});
  };

  const onSubscribeByEmail = () => {
    const payload = {
      getUpdates: notiInfo.getUpdates,
      notifiedByEmail: !notiInfo.notifiedByEmail,
    };
    manageTemplateNotification(payload);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/notification`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventType: "notifiedByEmail",
          payload: !notiInfo.notifiedByEmail,
        }),
      }
    )
      .then((response) => response.json())
      .then(() => {})
      .catch(() => {});
  };

  return (
    <Layout>
      <Field>
        <div className="form-header">Subscribed for Notifications</div>
        <div className="form-body">
          <div className="form-description">
            Enable notifications for templates that you have created or assigned
            to you.
          </div>
          <div className="form-input">
            <Switch
              displayTitle={false}
              value={notiInfo.getUpdates}
              onItemChange={changeSubscriptionStatus}
              checked={notiInfo.getUpdates}
            />
          </div>
        </div>
      </Field>

      <SplitLine />

      <Field disable={notiInfo.getUpdates ? false : true}>
        <div className="form-header">Notified by Email</div>
        <div className="form-body">
          <div className="form-description">Get emails for every updates.</div>
          <div className="form-input">
            <Switch
              displayTitle={false}
              value={notiInfo.notifiedByEmail}
              onItemChange={onSubscribeByEmail}
              checked={notiInfo.notifiedByEmail}
            />
          </div>
        </div>
      </Field>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 28px 20px;
  box-sizing: border-box;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.deskPrimaryTxt};
  margin-bottom: 26px;
  font-size: 0.95rem;
  opacity: ${({disable}) => (disable ? 0.5 : 1)};
  pointer-events: ${({disable}) => (disable ? "none" : undefined)};
  .form-header {
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 12px;
  }
  .form-body {
    display: flex;
    .form-description {
      flex: 4;
      font-size: 0.92em;
      color: ${({theme}) => theme.deskSecondaryTxt2};
    }
    .form-input {
      flex: 1;
    }
  }
`;

const SplitLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.deskGap};
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    tempID: selectTemplateID(state.designTemplate),
    notiInfo: selectTempNotiInfo(state.designTemplate),
  };
};

export default connect(mapStateToProps, {manageTemplateNotification})(
  ManageNotification
);
