import {
  CREATE_CHART_ROW,
  CREATE_CHART_COLUMN,
  CREATE_CHART,
  UPDATE_SINGLE_COLOR_CHART,
  UPDATE_CHART_COLOR_SET,
  UPDATE_CHART_INFO,
  DELETE_CHART_ROW,
  DELETE_CHART_COLUMN,
  CHANGE_CHART_TYPE,
  VIEW_CHART_INFO,
} from "../actionTypes";

// selectFieldOnCreate - for collaboration
export const createChart = (slideID, payload, selectFieldOnCreate = true) => {
  return {
    type: CREATE_CHART,
    slideID,
    payload,
    selectFieldOnCreate,
  };
};

// update single color
export const updateSingleColorChart = ({active, color, index}) => {
  return {
    type: UPDATE_SINGLE_COLOR_CHART,
    active,
    color,
    index,
  };
};

// for entirely changing color set
export const updateChartColorSet = (payload) => {
  return {
    type: UPDATE_CHART_COLOR_SET,
    payload,
  };
};

export const updateChartInfo = ({active, row, column, value}) => {
  return {
    type: UPDATE_CHART_INFO,
    active,
    row,
    column,
    value,
  };
};

export const createChartRow = (active) => {
  return {
    type: CREATE_CHART_ROW,
    active,
  };
};

export const createChartColumn = (active) => {
  return {
    type: CREATE_CHART_COLUMN,
    active,
  };
};

export const deleteChartRow = (active, index) => {
  return {
    type: DELETE_CHART_ROW,
    active,
    index,
  };
};

export const deleteChartColumn = (active, index) => {
  return {
    type: DELETE_CHART_COLUMN,
    active,
    index,
  };
};

export const changeChartType = (payload) => {
  return {
    type: CHANGE_CHART_TYPE,
    payload,
  };
};

export const onViewChartInfo = () => {
  return {
    type: VIEW_CHART_INFO,
  };
};
