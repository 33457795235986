import {createSelector} from "reselect";
import {selectTemplate} from "./template.selector";

export const selectActiveSlide = (state) => state.activeSlide;
export const selectActiveSlideIndex = (state) => state.activeSlide;
// later should be same for selectActiveSlide
export const selectActiveSlideID = (state) => {
  if (state.activeSlide === -1) return {bg: null};
  // const slideID = state.template && state.template.find((item) => item.id === state.);
  return state.activeSlideID; 
  // state.template && state.template[state.activeSlide].id;
};

export const selectIfNoActiveSlide = (state) =>
  state.activeSlide === -1 ? true : false;
export const selectTotalSlides = (state) => {
  return state.template && state.template.length;
};
export const selectSlideTransition = (state) => state.slideTransition;

export const selectActiveSlideInfo = createSelector(
  selectActiveSlide,
  selectTemplate,
  (activeSlide, template) => {
    if (activeSlide === -1) return {bg: null};
    return template && template.find((item, i) => i === activeSlide);
  }
);

export const selectSectionGroupSlides = createSelector(
  selectActiveSlide,
  selectTemplate,
  (activeSlide, template) => {
    return template[activeSlide].groupedSection;
  }
);

export const selectCurrentSlideOnClickAnimationOrder = (state) =>
  state.currentSlideOnClickAnimationOrder;

// may delete later
export const selectSlideGroupList = (state) => state.slideGroupList;
export const selectTemporarilyDisableTransition = (state) =>
  state.temporarilyDisableSlideTransition;

export const selectSlideAnimationOrder = createSelector(
  selectActiveSlide,
  selectTemplate,
  (activeSlideIndex, template) => {
    if (activeSlideIndex === -1)
      return {
        highestOrder: 0,
      };

    let highestOrder = 0,
      orderFrequencies = {}, // {{1:3},{2: 4}} // {aniOrder: frequency}
      highestOrderAnimateOnClick = 0,
      highestOrderIDs = [],
      highestOrderIsNotTypeAnimateOnClick = false,
      animatedFields = [],
      animateOnSlideDisplayHighestOrder = 0,
      exitAnimationOnSlideDisplay = false;

    for (const [key, field] of Object.entries(
      template[activeSlideIndex].fields
    )) {
      if (field.styles?.animationType && field.styles?.animationType !== "none" && field.styles?.animationType !== "none") {
        if (field.styles?.animateOnSlideDisplay && animateOnSlideDisplayHighestOrder < field.styles?.animationDelay){
          animateOnSlideDisplayHighestOrder = field.styles?.animationDelay;
        }
        if (highestOrder === 0 && field.styles?.animateOnSlideDisplay && field.styles?.enableExitAnimation) {
          exitAnimationOnSlideDisplay = true;
        } 
        if (!field.styles?.animateOnSlideDisplay) {
          if (highestOrder < field.styles?.animationOrder) {
            highestOrder = field.styles?.animationOrder;
            highestOrderIDs.push(key);
            highestOrderAnimateOnClick = highestOrder; // highestOrderAnimateOnClick + 1;
            highestOrderIsNotTypeAnimateOnClick = false;
          }
          if (field.styles?.animationOrder in orderFrequencies){
            orderFrequencies[field.styles?.animationOrder] += 1;
          } else {
            orderFrequencies[field.styles?.animationOrder] = 1;  
          }
          if (field.styles?.animationOrder > 0) animatedFields.push(field);
        }
      }
    };

    // check if  highest order is the one with animate on slide display
    // to use on slide forward 
    if (highestOrder === 0 && exitAnimationOnSlideDisplay) {
      highestOrderAnimateOnClick = 1;
      highestOrderIsNotTypeAnimateOnClick = true;
    };

    // Check if there's at least one item with the highest order and exit set to true
    let prevSlideHasExitAnimation = false, currentSlideHasExitAnimation = false;
    if (template.length > activeSlideIndex && activeSlideIndex !== 0 && template[activeSlideIndex - 1]?.hasExitAnimation){
      prevSlideHasExitAnimation = template[activeSlideIndex - 1]?.hasExitAnimation;
    };
    if (template.length > activeSlideIndex && activeSlideIndex !== 0 && template[activeSlideIndex]?.hasExitAnimation) {
      currentSlideHasExitAnimation = template[activeSlideIndex]?.hasExitAnimation;
    }

    const lastOnClickAnimationOrderHasEnabledExit = Object.values(template[activeSlideIndex].fields)
      .some(
        field => field.styles?.animationOrder === highestOrder && 
        field.styles?.enableExitAnimation && 
        field.styles?.animationType !== "none"
      );
      
    const animatedItemsGroupedByOrder = animatedFields.reduce((acc, item) => {
      const animationOrder = item.styles?.animationOrder;
      if (acc[animationOrder]) {
        acc[animationOrder].push(item);
      } else {
        acc[animationOrder] = [item];
      }
      return acc;
    }, {});

    return {
      highestOrder: highestOrderAnimateOnClick,
      highestOrderIDs,
      orderFrequencies,
      animatedItemsGroupedByOrder,
      animateOnSlideDisplayHighestOrder,
      lastOnClickAnimationOrderHasEnabledExit,
      prevSlideHasExitAnimation,
      currentSlideHasExitAnimation,
      highestOrderIsNotTypeAnimateOnClick
    };
  }
);
