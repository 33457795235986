import React, {Component, PureComponent} from "react";
import reactCSS from "reactcss";
import styled from "styled-components";
import * as alpha from "./helpers/alpha";
import CheckBoard from "./CheckBoard";

export class Alpha extends (PureComponent || Component) {
  componentWillUnmount() {
    this.unbindEventListeners();
  }

  handleChange = (e) => {
    const change = alpha.calculateChange(
      e,
      this.props.hsl,
      this.props.direction,
      this.props.a,
      this.container
    );
    change &&
      typeof this.props.onChange === "function" &&
      this.props.onChange(change, e);

    // e.preventDefault(); // causing problem in mobile
    e.stopPropagation();
  };

  handleMouseDown = (e) => {
    e.preventDefault();
    window.addEventListener("mousemove", this.handleChange);
    window.addEventListener("mouseup", this.handleMouseUp);
    window.addEventListener("mouseleave", this.handleMouseUp);
    window.addEventListener("touchmove", this.handleChange);
    window.addEventListener("touchend", this.handleMouseUp);
  };

  handleMouseUp = (e) => {
    const change = alpha.calculateChange(
      e,
      this.props.hsl,
      this.props.direction,
      this.props.a,
      this.container
    );
    change && this.props.onSelectionEnd(change, e);
    this.unbindEventListeners();
  };

  unbindEventListeners = () => {
    window.removeEventListener("mousemove", this.handleChange);
    window.removeEventListener("mouseup", this.handleMouseUp);
    window.removeEventListener("mouseleave", this.handleMouseUp);
    window.removeEventListener("touchmove", this.handleChange);
    window.removeEventListener("touchend", this.handleMouseUp);
  };

  render() {
    const rgb = this.props.rgb;
    const styles = reactCSS(
      {
        default: {
          alpha: {
            absolute: "0px 0px 0px 0px",
          },
          gradient: {
            background: `linear-gradient(to right, rgba(${rgb.r},${rgb.g},${rgb.b}, 0) 0%,
           rgba(${rgb.r},${rgb.g},${rgb.b}, 1) 100%)`,
            absolute: "0px 0px 0px 0px",
            boxShadow: this.props.shadow,
          },
          pointer: {
            position: "absolute",
            top: -2,
            left: `${rgb.a * 100}%`,
          },
        },
        vertical: {
          gradient: {
            background: `linear-gradient(to bottom, rgba(${rgb.r},${rgb.g},${rgb.b}, 0) 0%,
           rgba(${rgb.r},${rgb.g},${rgb.b}, 1) 100%)`,
          },
          pointer: {
            left: 0,
            top: `${rgb.a * 100}%`,
          },
        },
      },
      {
        vertical: this.props.direction === "vertical",
        overwrite: true,
      }
    );

    return (
      <div style={{...styles.alpha, zIndex: 9999}}>
        <WrapCheckBoard>
          <CheckBoard renderers={this.props.renderers} />
        </WrapCheckBoard>
        <div style={styles.gradient} />
        <Container
          ref={(container) => (this.container = container)}
          onMouseDown={this.handleMouseDown}
          onTouchMove={this.handleChange}
          onTouchStart={this.handleChange}
        >
          <div style={styles.pointer}>
            <Slider />
          </div>
        </Container>
      </div>
    );
  }
}

const WrapCheckBoard = styled.div`
  absolute: 0px 0px 0px 0px;
  overflow: hidden;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  margin: 0 2px;
  box-shadow: 0 0 1px #0c0c0c;
  @media only screen and (min-width: 850px) {
    box-shadow: 0 0 1px #989898;
  }
`;

const Slider = styled.div`
  width: 4px;
  height: 16px;
  background: #fff;
  box-shadow: 0 0 3px rgb(0 0 0 / 61%);
`;

export default Alpha;
