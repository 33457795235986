import React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {changeSlide} from "../../../../oat-design-template/store/actions/template/slide.action";
import {
  selectActiveSlide,
  selectTotalSlides,
} from "../../../../oat-design-template/store/selectors/template/slide.selector";
import {
  ArrowLeft,
  ArrowRight,
} from "../../../../oat-design-template/ui/svg/Arrow";
import {mainBlue1} from "../../../../oat-color-variables";

const DesktopToggleSlides = ({activeSlide, changeSlide, slideLength}) => {
  const onBackwardSlide = () => {
    if (activeSlide === 0) return;
    changeSlide(activeSlide - 1);
  };

  const onForwardSlide = () => {
    if (activeSlide === slideLength - 1) return;
    changeSlide(activeSlide + 1);
  };

  return (
    <Layout>
      <Wrapper>
        <WrapSvg edge={activeSlide === 0} onClick={onBackwardSlide}>
          {ArrowLeft}
        </WrapSvg>
        <Text>{activeSlide + 1 + ` / ` + slideLength}</Text>
        <WrapSvg
          edge={slideLength - 1 === activeSlide}
          onClick={onForwardSlide}
        >
          {ArrowRight}
        </WrapSvg>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  bottom: 16px;
  width: 138px;
  height: 38px;
  background: ${mainBlue1};
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1rem;
  @media only screen and (max-width: 768px) {
    bottom: 10px;
    height: 32px;
    border-radius: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 130px;
  display: flex;
  justify-content: space-around;
`;

const WrapSvg = styled.div`
  width: 30px;
  height: 30px;
  fill: ${({edge}) => (!edge ? "#fff" : "#abababb3")};
  cursor: ${({edge}) => (!edge ? "pointer" : "not-allowed")};
`;

const Text = styled.p`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  color: #fff;
  user-select: none;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeSlide: selectActiveSlide(designTemplate),
    slideLength: selectTotalSlides(designTemplate),
  };
};

export default connect(mapStateToProps, {changeSlide})(DesktopToggleSlides);
