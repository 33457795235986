import React, {useState, useCallback, useEffect, useRef} from "react";
import Hoc from "../hoc/Hoc";
import {
  Image as Img,
  Items,
  Header,
  ShowError,
} from "../components/dashboardArea/templates/templateListStyles";
import {workspaceWidth} from "../components/layout/WorkSpace";
import {Modal, Spinner} from "../../oat-ui";
import ImageDetails from "../components/images/ImageDetails";
import UploadPrivateMedia from "../components/images/privateLibrary/UploadPrivateMedia";
import {
  Layout,
  HeaderSection,
  WrapButton,
  WrapItem,
} from "../reuseStyles/reuseStyles";
import {
  getOatAllMedia,
  toggleAdminMediaModal,
} from "../store/actions/media.action";
import {connect} from "react-redux";
import {selectAdminMedia, selectPaginationToken} from "../store/selectors/adminMedia.selector";
import { useNavigate } from "react-router-dom";

const DisplayImage = ({item, onSelectItem}) => {
  const handleImageClick = () => {
    onSelectItem(item);
  };

  const [imageUrl, setImageUrl] = useState(`https://oat-images-icons.s3.amazonaws.com/${item.image}`);
  useEffect(() => {
    if (item?.image !== null && typeof item?.image === 'object') {
      setImageUrl(`https://oat-images-icons.s3.amazonaws.com/${item?.image['small']}`);
    } else {
      setImageUrl(`https://oat-images-icons.s3.amazonaws.com/${item?.image}`);
    }
  }, []);

  return (
    <WrapItem>
      <Img
        src={imageUrl}
        onClick={handleImageClick}
        loading="lazy"
      />
    </WrapItem>
  );
};

const PrivateImages = ({media, toggleAdminMediaModal, getOatAllMedia, paginationToken}) => {
  const [mediaType, setMediaType] = useState("images");
  const [selectedImage, setSelectedImage] = useState();
  const [page, setPage] = useState(1);
  const [isLastItem, setIsLastItem] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  let navigate = useNavigate();

  const selectImage = (item) => {
    toggleAdminMediaModal(true, "show-image-details");
    setSelectedImage(item);
  };

  const closeModal = () => {
    toggleAdminMediaModal(false, "");
  };

  useEffect(() => {
    if (!mediaType) {
      return navigate(`/admin/library/private`);
    }
    if (paginationToken === null && media.items.length === 0) {
      getOatAllMedia(
        `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/private-media-library/${mediaType}`
      );
      setInitialLoading(false);
    } 
  }, [paginationToken, media.items.length]);

  const {modal} = media;

  // const observer = useRef();
  // const lastItemRef = useCallback(
  //   (node) => {
  //     if (fetching) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       // if (entries[0].isIntersecting && !isLastItem) {
  //       //   setPage((prev) => prev + 1);
  //       // }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [fetching]
  // );
  const observer = useRef();
  const lastItemRef = useCallback((node) => {
    if (initialLoading) {
      return;
    }
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && paginationToken !== null && media.items.length > 0) {
        getOatAllMedia(
          `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/private-media-library/${mediaType}?paginationToken=${paginationToken}`
        );
      }
    }, { threshold: 0.5 });
    if (node) observer.current.observe(node);
  }, [initialLoading, paginationToken]);

  // useEffect(() => {
  //   if(page !== 1) {
  //     getOatAllMedia(
  //       `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/private-media-library/${mediaType}?paginationToken=${paginationToken}`
  //     );
  //   }
  // }, [page]);

  return (
    <Hoc>
      <Layout>
        <HeaderSection>
          <Header>Images</Header>
          <WrapButton>
            <UploadPrivateMedia />
          </WrapButton>
        </HeaderSection>

        <Items style={{width: window.innerWidth - workspaceWidth - 15}}>
          {!media.loading ? (
            media.items &&
            media.items.map((item, i) => (
              <DisplayImage item={item} key={item.key} onSelectItem={selectImage} />
            ))
          ) : (
            <div style={{marginTop: "6%", width: "100%"}}>
              <Spinner />
            </div>
          )}

          <div ref={lastItemRef} />
          <div style={{height: 300}} />
        </Items>

        {!media.loading && media.error.status ? (
          <ShowError>Couldn't fetch data.</ShowError>
        ) : undefined}
      </Layout>

      {modal.status && modal.type === "show-image-details" ? (
        <Modal close={closeModal}>
          <div>
            <ImageDetails selectedImage={selectedImage} closeModal={closeModal} />
          </div>
        </Modal>
      ) : undefined}

      {modal.status && modal.type === "upload-image" ? (
        <Modal width={520} height={"auto"} close={closeModal}>
          <div>
            <UploadPrivateMedia closeModal={closeModal} />
          </div>
        </Modal>
      ) : undefined}
    </Hoc>
  );
};

const mapStateToProps = (state) => {
  return {
    media: selectAdminMedia(state.adminMedia),
    paginationToken: selectPaginationToken(state.adminMedia)
  };
};

export default connect(mapStateToProps, {
  toggleAdminMediaModal,
  getOatAllMedia,
})(PrivateImages);
