import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import Switch from "../../shared/inputs/Switch";
import LayoutPosition from "../common/LayoutPosition";
import {SplitLine} from "../common/SplitLine";
import EditTableBody from "./EditTableBody";
import EditTableHeader from "./EditTableHeader";
import {WrapColorBox, InputName} from "../common/WrapColorStyles";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import EditOpacity from "../common/EditOpacity";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {textEditProps} from "../text/EditText";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const EditTablePanel = ({
  activeField,
  updateFieldStyles,
  slideID,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  // *** CHANGES MADE HERE SHOULD ALSO REFLECT IN MOBILE ONES ***
  const {styles} = activeField || {};

  const changeRoundedBorder = async (value) => {
    await updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type: "roundedBorder",
      value,
    });
    storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  const updateBorderColor = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type: "borderColor",
      value,
    });
  };

  const changeLetterSpacing = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type: "letterSpacing",
      value,
    });
  };

  const changeLineHeight = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type: "lineHeight",
      value,
    });
  };

  const changeBorderWidth = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type: "borderWidth",
      value,
    });
  };

  const onDebounceColor = (value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <div style={{padding: "1.5em 0 6em"}}>
      <LayoutPosition rotate={styles?.rotate} locked={styles?.lock} />
      <SplitLine />

      <EditTableHeader styles={styles} />
      <SplitLine />

      <EditTableBody styles={styles} />
      <SplitLine />

      <div style={{paddingBottom: "1.5rem"}}>
        <RangeSlider
          title="Letter Spacing"
          fieldType="letterSpacing"
          value={styles?.letterSpacing}
          max={textEditProps.letterSpacing.max}
          min={textEditProps.letterSpacing.min}
          step={textEditProps.letterSpacing.step}
          onItemChange={changeLetterSpacing}
        />
      </div>

      <div>
        <RangeSlider
          title="Line Height"
          fieldType="lineHeight"
          value={styles?.lineHeight}
          max={textEditProps.lineHeight.max}
          min={textEditProps.lineHeight.min}
          step={textEditProps.lineHeight.step}
          onItemChange={changeLineHeight}
        />
      </div>
      <SplitLine />

      <div style={{paddingBottom: "1.5rem"}}>
        <WrapColorBox>
          <InputName>Border Color</InputName>
          <PopoverColorBox
            value={styles?.borderColor}
            updateFieldStyles={updateBorderColor}
            onDebounce={onDebounceColor}
          />
        </WrapColorBox>
      </div>

      {/* <div>
        <RangeSlider
          title="Border Width"
          fieldType="borderWidth"
          value={styles.borderWidth}
          max={7}
          min={0}
          step={1}
          onItemChange={changeBorderWidth}
        />
      </div> */}

      <div style={{paddingBottom: "1.5rem"}}>
        <Switch
          title="Rounded Border"
          value={styles?.roundedBorder}
          onItemChange={changeRoundedBorder}
          checked={styles?.roundedBorder}
        />
      </div>

      <div style={{paddingBottom: "1.5rem"}}>
        <EditOpacity value={styles?.opacity} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveFieldProps(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditTablePanel);
