import React, {useState} from "react";
import styled from "styled-components";
import {Option} from "../../../../../oat-ui/styles/SelectOption";
import { Modal, Spinner } from "../../../../../oat-ui";
import { getWindowSize } from "../../../../../oat-window-size/getWindowSize";
import { connect } from "react-redux";
import { selectUserInfo } from "../../../../../../redux/user/authUser";
import {saveAs} from "file-saver";

const PurchasedItemActions = ({username, onClose, selectedItem, onView}) => {
  const [processing, setProcessing] = useState(false);
  const windowSize = getWindowSize();

  const onSelectView = () => {
    onView(selectedItem);
  };

  const onDownload = async () => {
    setProcessing(true);
    try {
      const response = await fetch(`https://oat-images-icons.s3.amazonaws.com/${selectedItem?.item?.downloadLink}`);
      const blob = await response.blob();
      const productName = selectedItem?.item?.productName ? selectedItem?.item?.productName : 'oat-all-illustrations';
      const slug = productName.replace(/[\s;]+/g, "-").toLowerCase();
      saveAs(blob, `${slug}.zip`);
      await setProcessing(false);
      await onClose();
      return true;
    } catch (error) {
      await setProcessing(false);
      return false;
    }
  }

  const closeModal = () => {
    setProcessing(false);
  }

  return (
    <>
      {!processing ? <Wrapper>
        <Option onClick={onSelectView}>
          <div>View</div>
        </Option>
        
        <Option onClick={onDownload}>
          <div>Download</div>
        </Option>
      </Wrapper> : undefined}
      
      {processing ? (
        <Modal
          width={windowSize.width > 950 ? 300 : windowSize.width - 70} 
          height={150} 
          top={100}
          close={closeModal}
        >
          <div 
            style={{
              width: '100%',
              height: 140,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Spinner /> 
          </div>
        </Modal> 
        ) : undefined}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 8px 0;
`;

const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
  }
}

export default connect(mapStateToProps, null)(PurchasedItemActions);
