import React, {useState, useEffect} from "react";
import {Refresh} from "../../../../../../oat-svg-icons";
import {Spinner} from "../../../../../../oat-ui";
import {Item, WrapSpinner, WrapSvg} from "./reuseStyles";

const DisplaySubFolder = ({item, onSelect}) => {
  const handleClick = () => {
    onSelect(item.folderID);
  };
  return (
    <Item style={{paddingLeft: 22}} onClick={handleClick}>
      {item.folderName}
    </Item>
  );
};

const DisplayItem = ({item, onSelect}) => {
  const chooseRootFolder = () => {
    onSelect(item.folderID);
  };

  const chooseSubfolder = (folderID) => {
    onSelect(folderID);
  };

  return (
    <>
      <Item onClick={chooseRootFolder}>{item.folderName}</Item>
      {item.subfolders &&
        item.subfolders.map((item, i) => (
          <DisplaySubFolder key={i} item={item} onSelect={chooseSubfolder} />
        ))}
    </>
  );
};

const FolderList = ({username, workspace, onSelect}) => {
  const [folders, setFolders] = useState([]);
  const [fetching, setFetching] = useState({
    status: false,
    error: false,
  });

  const loadFolders = async () => {
    setFetching({
      status: true,
      error: false,
    });
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces/${workspace}/folders`
    )
      .then((res) => res.json())
      .then((data) => {
        setFolders([
          {folderID: "home", folderName: "Recents", subfolders: []}, // retrieve recent list
          ...data.designFolders
        ]);
        setFetching({
          status: false,
          error: false,
        });
      })
      .catch((e) => {
        setFetching({
          status: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    loadFolders();
  }, []);

  const handleSelection = (folderID) => {
    onSelect(folderID);
  };

  const reloadFolders = () => {
    loadFolders();
  };

  return (
    <div style={{marginBottom: 42}}>
      {folders &&
        folders.map((folder, i) => (
          <DisplayItem key={folder.folderID} item={folder} onSelect={handleSelection} />
        ))}

      {fetching.status ? (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      ) : undefined}

      {fetching.error ? (
        <WrapSvg onClick={reloadFolders}>{Refresh}</WrapSvg>
      ) : undefined}
    </div>
  );
};

export default FolderList;
