import React, {useState, useRef} from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {Button, ButtonSpinner, detectClickOutside} from "../../../../../oat-ui";
import {removeProfileTag} from "../../../../store/actions/userProfile.action";
import {Wrapper, WrapButton} from "../../../sidebar/folderTypes/folderStyles";
import {Popper} from "./ProfileCollections";

const DeleteProfileCollection = ({
  collection,
  removeProfileTag,
  user,
  close,
}) => {
  const popperRef = useRef();
  const [processing, setProcessing] = useState();

  const handleDelete = () => {
    setProcessing(true);
    const requestOptions = {
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        payload: collection.id,
      }),
    };
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.account.business.slug}/userInfo/profile/tags`,
      requestOptions
    )
      .then((response) => response.json())
      .then(() => {
        removeProfileTag(collection.id);
        setProcessing(false);
        close();
      })
      .catch((e) => console.log(e));
  };

  detectClickOutside(popperRef, () => {
    close();
  });

  const onIgnoreEvent = (e) => {
    e.stopPropagation();
  };

  return (
    <Popper
      style={{height: 90, left: 80, zIndex: 99999}}
      onClick={onIgnoreEvent}
      ref={popperRef}
    >
      <div>Confirm Delete?</div>
      <Wrapper style={{justifyContent: "flex-end"}}>
        <WrapButton>
          <Button onClick={handleDelete} disabled={processing}>
            {!processing ? "Delete" : <ButtonSpinner />}
          </Button>
        </WrapButton>
      </Wrapper>
    </Popper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {removeProfileTag})(
  DeleteProfileCollection
);
