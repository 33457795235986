import React from "react";
import reactCSS from "reactcss";

export const CheckBoard = () => {
  const styles = reactCSS({
    default: {
      grid: {
        absolute: "0px 0px 0px 0px",
        boxShadow: "#afacac 0px 0px 1px",
        background: `linear-gradient(270deg,transparent, #ffffff)`,
      },
    },
  });
  return <div style={styles.grid} />;
};

CheckBoard.defaultProps = {
  size: 8,
  white: "transparent",
  grey: "rgba(0,0,0,.08)",
  renderers: {},
};

export default CheckBoard;
