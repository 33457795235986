import React from "react";
import { CategoryItem } from "../../icons/CreateIcon";

const LibSubfolder = ({onSelect, item, active}) => {

    const handleSelection = () => {
      onSelect(item.folderID);
    };
  
    return (
      <CategoryItem onClick={handleSelection} active={active}>
        {item.folderName}
      </CategoryItem>
    );
};


export default LibSubfolder;