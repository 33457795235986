import styled, {css} from "styled-components";
import {paleBlue2} from "../../../oat-color-variables";

export const DisplayInfo = styled.div`
  position: absolute;
  background: ${({theme}) => theme.deskSecondaryBg};
  color: #fff;
  position: absolute;
  top: 10px;
  left: 65px;
  padding: 12px 8px;
  display: none;
  z-index: 99;
  border-radius: 6px;
  font-size: 0.9rem;
`;

export const tooltipInfo = css`
  width: 18px;
  height: 18px;
  font-size: 0.8rem;
  border-radius: 50%;
  background: ${paleBlue2};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  &:hover ${DisplayInfo} {
    display: block;
  }
`;
