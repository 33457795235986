import React, {useState, useRef, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {greyText1} from "../../../../../oat-color-variables";
import {Modal, Popper, Spinner} from "../../../../../oat-ui";
import {
  selectActiveWorkSpace,
  selectFolders,
} from "../../../../store/selectors/userDashboard.selector";
import {WrapError, UpgradeToPro} from "../images/ImagesLibrary";
import {loadFoldersAndWorkspace} from "../../../../store/actions/dashboard.action";
import {useNavigate, useParams} from "react-router-dom";
import {
  ItemName,
  MoreOption,
  WrapTempInfo,
  Items,
  Header,
  CenterDiv,
  itemStyles,
  WrapDashAreaSpinner,
} from "../../userTemplates/userTemplatesStyles";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import {getDashboardLayout} from "../../userTemplates/getDashboardLayout";
import FontActions from "./FontActions";
import UploadLibraryFonts from "./UploadLibraryFonts";
import CreateButton from "../../../reuse/CreateButton";
import {paleGreen1} from "../../../../../oat-color-variables";
import {ErrorMessage, WrapSpinner} from "../userLibraryStyles";
import EmptyTemplates from "../../userTemplates/emptyTemplate/EmptyTemplates";
import RequiresUpgradeToPro from "../../../reuse/requiresUpgradeToPro/RequiresUpgradeToPro";
import { Error } from "../../dashboardPanelStyles";

const DisplayItem = ({font, fontSlug, fontFamily, size, onPopperOpen}) => {
  const handlePopperOpen = (e) => {
    onPopperOpen(e, font, fontSlug);
  };

  function loadFonts() {
    const newFont = document.createElement("style");
    newFont.appendChild(
      document.createTextNode(
        `@font-face {
          font-family: "${
            fontFamily + "-" + font.fontWeight + "-" + font.fontStyle
          }";
          src: url(${`https://oat-users.s3.amazonaws.com/${font.src}`});
          format("${font.format}");
          font-weight: ${font.fontWeight};
          font-style: "${font.fontStyle}";
        }`
      )
    );
    document.head.appendChild(newFont);
  }

  useEffect(() => {
    loadFonts();
  }, []);

  return (
    <WrapItem
      style={{
        width: size.width,
        height: size.height + 46,
      }}
    >
      <DisplayFont
        style={{
          height: size.height,
          fontFamily: fontFamily + "-" + font.fontWeight + "-" + font.fontStyle,
          fontWeight: font.fontWeight,
          fontStyle: font.fontStyle,
        }}
      >
        Hello Goodie People
      </DisplayFont>
      <WrapTempInfo
        style={{
          height: 46,
        }}
      >
        <ItemName style={{flex: 4}}>
          {font.fontStyle} - {font.fontWeight}
        </ItemName>
        <MoreOption onClick={handlePopperOpen}>
          <div />
          <div />
          <div />
        </MoreOption>
      </WrapTempInfo>
    </WrapItem>
  );
};

const popperSize = {width: 200, height: 54};
const FontsLibrary = ({user, folders, loadFoldersAndWorkspace, workspace}) => {
  const [fonts, setFonts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fetchedErr, setFetchedError] = useState(false);
  const [popper, setPopper] = useState({
    open: false,
    offset: {x: null, y: null},
  });
  const [selectedItem, setSelectedItem] = useState();
  const [itemSize, setItemSize] = useState({
    width: 0,
    height: 0,
  });
  const [deleting, setDeleting] = useState({status: false, error: false});
  const [uploading, setUploading] = useState({
    status: false,
    openModal: false,
    file: null,
    error: false,
  });
  const [requiresUpgrade, setRequiresUpgrade] = useState(false);
  const {workspaceID} = useParams();
  const windowSize = getWindowSize();
  const inputFileRef = useRef(null);
  let navigate = useNavigate();

  const fetchFonts = () => {
    setFetching(true);
    if (fetchedErr) setFetchedError(false);

    const fetchFolders = folders && folders.designs.length === 0 ? true : false;
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/fonts/${workspaceID}?paginationToken=&fetchFolders=${fetchFolders}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFonts(data.response.fonts);
          if (fetchFolders) {
            loadFoldersAndWorkspace({
              folders: data.response.folders,
              workspace: data.response.workspace,
            });
          }
        } else {
          setFetchedError(true);
        }
        setFetching(false);
      })
      .catch(() => {
        setFetchedError(true);
        setFetching(false);
      });
  };

  useEffect(() => {
    fetchFonts();
  }, [workspaceID]);

  const handleFontDelete = async () => {
    if (!deleting.processing) {
      setDeleting({
        processing: true,
        openModal: true,
        error: false,
      });

      setPopper({
        open: false,
        offset: {x: null, y: null},
      });

      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace.ID}/fonts/${selectedItem.fontSlug}/fontType`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({fontTypeKey: selectedItem.fontTypeKey}),
        }
      )
        .then((res) => res.json())
        .then(async (data) => {
          if (data.status === "success") {
            const getFontCollection = fonts.find(
              (item) => item.fontSlug === selectedItem.fontSlug
            );
            if (getFontCollection.fontTypes.length === 1) {
              setFonts((prev) =>
                prev.filter((item) => item.fontSlug !== selectedItem.fontSlug)
              );
            } else {
              setFonts((prev) =>
                prev.map((item) =>
                  item.fontSlug === selectedItem.fontSlug
                    ? {
                        ...item,
                        fontTypes: item.fontTypes.filter(
                          (types) => types.key !== selectedItem.fontTypeKey
                        ),
                      }
                    : item
                )
              );
            }
            setDeleting({
              processing: false,
              openModal: false,
              error: false,
            });
            await handlePopperClose();
          } else {
            setDeleting({
              processing: false,
              openModal: true,
              error: true,
            });
          }
        })
        .catch((e) => {
          setDeleting({
            processing: false,
            openModal: true,
            error: true,
          });
        });
    }
  };

  useEffect(() => {
    const {width, height} = getDashboardLayout(windowSize.width);
    setItemSize({
      width,
      height,
    });
  }, [windowSize.width]);

  const handlePopperOpen = (e, item, fontSlug) => {
    setSelectedItem({
      fontSlug,
      fontTypeKey: item.key,
    });
    if (e.clientY + popperSize.width > window.innerHeight) {
      const extraSpace = 20;
      setPopper({
        open: !popper.open,
        offset: {
          x: e.pageX - popperSize.width + 9,
          y: e.pageY - popperSize.height - extraSpace,
        },
      });
    } else {
      setPopper({
        open: !popper.open,
        offset: {x: e.pageX - popperSize.width, y: e.pageY},
      });
    }
    e.preventDefault();
  };

  const handlePopperClose = () => {
    setPopper({
      open: false,
      offset: {x: 0, y: 0},
    });
  };

  const blankFunc = () => {
    if (!deleting.processing) {
      setDeleting({
        ...deleting,
        openModal: false,
        error: false,
      });
    }
  };

  const onInitUploadFont = () => {
    if (workspace.plan === "free") {
      setRequiresUpgrade(true);
    } else {
      inputFileRef.current.click();
    }
  };

  const onFileUpload = async (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploading({
        ...uploading,
        openModal: true,
        file: e.target.files[0],
      });
      e.target.value = null;
    }
  };

  const closeModal = () => {
    if (!uploading.status) {
      setUploading({
        status: false, // processing on server
        openModal: false,
        file: null,
        error: false,
      });
    }
  };

  const onFinishUploading = () => {
    closeModal();
    fetchFonts();
  };

  const onCloseRequiresUpgradeModal = () => {
    setRequiresUpgrade(false);
  };

  const onUpgradeToPro = () => {
    navigate(`?upgrade=true`, { replace: true });
  }
  
  const togglePopperToDeleteCollection = () => {};

  return (
    <div>
      <Header>
        <div className="user-dashboard-header">
          <h3>Fonts</h3>
          {workspace.plan === "free" ? (
            <UpgradeToPro onClick={onUpgradeToPro}>Pro</UpgradeToPro>
          ) : undefined}
        </div>
        <div>
          {workspace.ID ? (
            <>
              <CreateButton onClick={onInitUploadFont} title="Upload Font" />
              <input
                ref={inputFileRef}
                type="file"
                onChange={onFileUpload}
                hidden
              />
            </>
          ) : undefined}
        </div>
      </Header>

      {fetchedErr ? (
        <WrapError>
          <Error>Couldn't load data.</Error>
        </WrapError>
      ) : undefined}

      {!fetching && !fetchedErr && fonts && fonts.length === 0 ? (
        <CenterDiv
          style={{
            width: "100%",
          }}
        >
          <EmptyTemplates
            message="Add your branded fonts"
            smallText="Upload fonts that you regularly use to make your creation process easier"
          />
        </CenterDiv>
      ) : undefined}

      {!fetching ? (
        fonts &&
        fonts.map((font, index) => {
          return (
            <div key={index}>
              <div style={{display: "flex"}}>
                <FontFamily>{font.fontFamily}</FontFamily>
                {/* <MoreOption onClick={togglePopperToDeleteCollection}>
                  <div />
                  <div />
                  <div />
                </MoreOption> */}
              </div>

              <ShortDescription>
                {font.description}
                {/* Font weight 700 Bold is to use in brand heading and 500 medium
                is for regular paragraph. */}
              </ShortDescription>
              <Items>
                {font.fontTypes.map((fontTypes, i) => (
                  <DisplayItem
                    key={i}
                    fontFamily={font.fontFamily}
                    fontSlug={font.fontSlug}
                    font={fontTypes}
                    size={itemSize}
                    onPopperOpen={handlePopperOpen}
                  />
                ))}
              </Items>
            </div>
          );
        })
      ) : (
        <WrapDashAreaSpinner>
          <Spinner />
        </WrapDashAreaSpinner>
      )}

      {popper.open ? (
        <Popper
          width={popperSize.width}
          height={popperSize.height}
          offset={{x: popper.offset.x, y: popper.offset.y}}
          onClose={handlePopperClose}
        >
          <FontActions onDelete={handleFontDelete} />
        </Popper>
      ) : undefined}

      {deleting.openModal ? (
        <Modal
          width={!deleting.error ? 320 : 390}
          height="auto"
          close={blankFunc}
        >
          <WrapSpinner>
            {!deleting.error ? (
              <Spinner />
            ) : (
              <ErrorMessage>
                Something went wrong. Please try again.
              </ErrorMessage>
            )}
          </WrapSpinner>
        </Modal>
      ) : undefined}

      {uploading.openModal ? (
        <UploadLibraryFonts
          uploadedFile={uploading.file}
          user={user}
          workspace={workspace}
          onClose={closeModal}
          onCreated={onFinishUploading}
        />
      ) : undefined}

      {requiresUpgrade ? (
        <RequiresUpgradeToPro close={onCloseRequiresUpgradeModal} />
      ) : undefined}
    </div>
  );
};

const ShortDescription = styled.div`
  font-size: 1rem;
  color: ${greyText1};
  margin: 6px 0 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const DisplayFont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${paleGreen1};
  font-size: 24px;
`;

const FontFamily = styled.h2`
  font-size: 1.1rem;
  color: #232323;
  margin: 14px 0 12px;
  @media only screen and (min-width: 850) {
    margin: 32px 0 12px;
  }
`;

const WrapItem = styled.div`
  ${itemStyles}
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    folders: selectFolders(state.userDashboard),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, {loadFoldersAndWorkspace})(
  FontsLibrary
);
