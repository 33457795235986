import React from "react";
import styled, {css} from "styled-components";

const Carousel = ({
  children,
  activeIndex,
  duration,
  timing,
  styles,
  carouselType,
}) => {
  return (
    <Wrapper>
      <SwipeView
        $activeIndex={activeIndex}
        duration={duration}
        timing={timing}
        $carouselType={carouselType}
        style={{
          ...styles,
        }}
      >
        {children}
      </SwipeView>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const styles = css`
  flex-direction: row;
  direction: ltr;
  display: flex;
  will-change: transform;
  width: 100%;
`;

// width transtion
// use in template section of mobile & order options page
const SwipeView = styled.div`
  ${styles};
  -webkit-transition: ${({$carouselType, duration, timing}) =>
    $carouselType === "classic-transition"
      ? `all ${duration}s ${timing}`
      : undefined};
  -moz-transition: ${({$carouselType, duration, timing}) =>
    $carouselType === "classic-transition"
      ? `all ${duration}s ${timing}`
      : undefined};
  -ms-transition: ${({$carouselType, duration, timing}) =>
    $carouselType === "classic-transition"
      ? `all ${duration}s ${timing}`
      : undefined};
  transition: ${({$carouselType, duration, timing}) =>
    $carouselType === "classic-transition"
      ? `all ${duration}s ${timing}`
      : undefined};
  -moz-transform: ${({$activeIndex, $carouselType}) =>
    $carouselType === "classic-transition"
      ? `translate(${$activeIndex * -100}%, 0px)`
      : undefined};
  -webkit-transform: ${({$activeIndex, $carouselType}) =>
    $carouselType === "classic-transition"
      ? `translate(${$activeIndex * -100}%, 0px)`
      : undefined};
  -o-transform: ${({$activeIndex, $carouselType}) =>
    $carouselType === "classic-transition"
      ? `translate(${$activeIndex * -100}%, 0px)`
      : undefined};
  -ms-transform: ${({$activeIndex, $carouselType}) =>
    $carouselType === "classic-transition"
      ? `translate(${$activeIndex * -100}%, 0px)`
      : undefined};
  -webkit-transform: ${({$activeIndex, $carouselType}) =>
    $carouselType === "classic-transition"
      ? `translate(${$activeIndex * -100}%, 0px)`
      : undefined};
  transform: ${({$activeIndex, $carouselType}) =>
    $carouselType === "classic-transition"
      ? `translate(${$activeIndex * -100}%, 0px)`
      : undefined};
`;

Carousel.defaultProps = {
  duration: 0.6,
  timing: "ease-in-out",
  carouselType: "no-transition",
};

export default Carousel;
