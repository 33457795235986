import React from "react";
import styled from "styled-components";
import {LeftArrow} from "../../../../oat-svg-icons";
import SpecialButton from "../../../ui/inputs/SpecialButton";
import {useNavigate} from "react-router-dom";
import UndoRedo from "../canvasPanel/bottomSection/UndoRedo";
import { selectWorkspaceID } from "../../../store/selectors/template/template.selector";
import { connect } from "react-redux";

const TopMobPanel = ({workspaceID, onSave}) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    return navigate(`/dashboard/${workspaceID}/designs/home`);
  };
  
  const handleSave = () => {
    onSave();
  };

  return (
    <Wrapper>
      <div className="mobile_nav-left">
        <div onClick={handleBackButton} style={{fontSize: 12, fontWeight: 600, width: 40}}>
          <WrapSvg>{LeftArrow}</WrapSvg>
        </div>
      </div>
      <div className="mobile_nav-right">
        <UndoRedo />
        <SpecialButton
          onClick={handleSave}
          styles={{width: 80, height: 32, fontSize: "0.95rem"}}
        >
          Save
        </SpecialButton>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 1;
  @media only screen and (max-width: 849px) {
    height: 45px;
    background: #1c1c2f;
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  .mobile_nav-left {
    flex: 1;
    padding-left: 5%;
  }
  .mobile_nav-right {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    padding: 5%;
  }
`;

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {   
    workspaceID: selectWorkspaceID(designTemplate),
  };
};

export default connect(mapStateToProps, null)(TopMobPanel);
