import React, {useState, useEffect} from "react";
import Select from "../../../../../../oat-ui/core/Select";
import {
  ContentSection,
  Header,
  FieldName,
  Footer,
  BottomSection,
  Cancel,
} from "../../../../reuse/dashboardUI/modalStyles";
import styled from "styled-components";
import {
  Button,
  ButtonSpinner,
  ShowError,
  Spinner,
} from "../../../../../../oat-ui";
import {fetchWorkspaces} from "../../../../../store/actions/workspace.action";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {selectWorkspaceList} from "../../../../../store/selectors/userDashboard.selector";
import {connect} from "react-redux";

const ShareToWorkspace = ({
  user,
  template,
  fetchWorkspaces,
  workspaces,
  close,
}) => {
  const [selected, setSelected] = useState({
    workspace: "",
    folder: "",
    subfolder: "",
  });
  const [isMovingToSubfolder, setIsMovingToSubfolder] = useState({
    status: false,
    subfolder: null,
  });
  const [displayWorkspaces, setDisplayWorkspaces] = useState([]);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState({status: false, type: null});
  const [error, setError] = useState({status: false, message: null});
  const [submitting, setSubmiting] = useState(false);

  const handleWorkspaceSelection = async (workspace) => {
    setError({status: false, message: null});
    setSelected({
      workspace: workspace,
      folder: "",
    });
    setLoading({status: true, type: "folders"});
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${workspace.value}/folders`
    )
      .then((res) => res.json())
      .then((data) => {
        let folderList = [];
        const {designFolders} = data;
        for (let i = 0; i < designFolders.length; i++) {
          let subfolders = [];
          if (
            designFolders[i]?.subfolders &&
            designFolders[i].subfolders.length > 0
          ) {
            designFolders[i]?.subfolders.map((subfolder) =>
              subfolders.push({
                display: subfolder.folderName,
                value: subfolder.folderID,
              })
            );
          }
          folderList.push({
            display: designFolders[i].folderName,
            value: designFolders[i].folderID,
            subfolders: subfolders,
          });
        }
        folderList.push({
          display: "Library",
          value: "library-templates",
        });
        setFolders(folderList);
        setIsMovingToSubfolder({status: false, subfolder: {}});
      })
      .catch((e) => setError({status: true, message: "Something went wrong."}));
    await setLoading({status: false, type: null});
  };

  const handleFolderSelection = (folder) => {
    setSelected({...selected, folder});
    setIsMovingToSubfolder({status: false, subfolder: {}});
  };

  const handleSubfolderSelection = (subfolder) => {
    setIsMovingToSubfolder({status: true, subfolder});
  };

  const handleSubmit = async () => {
    if (!submitting) {
      if (error.status) {
        setError({status: false, message: null});
      }
      setSubmiting(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/copy-to-another-workspace`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            toWorkspaceID: selected.workspace.value,
            toFolderID: isMovingToSubfolder.status
              ? isMovingToSubfolder.subfolder.value
              : selected.folder.value,
            toFolderName: isMovingToSubfolder.status
              ? isMovingToSubfolder?.subfolder?.display
              : selected.folder.display,
            fromWorkspaceID: template.workspaceID,
            fromFolderID: template.folderID,
            tempID: template.id,
            // movingToSubfolder: {
            //   status: isMovingToSubfolder.status,
            //   folderID: isMovingToSubfolder.subfolder.value,
            // },
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            close();
          } else {
            setSubmiting(false);
            setError({status: true, message: "Something went wrong."});
          }
        })
        .catch((e) => {
          setSubmiting(false);
          setError({status: true, message: "Something went wrong."});
        });
    }
  };

  const loadWorkspaces = () => {
    setDisplayWorkspaces([]);
    setLoading({status: true, type: "workspaces"});
    fetchWorkspaces(user.username);
  };

  const clickFolderSelectionBox = (e) => {
    if (
      selected.workspace.value === undefined ||
      selected.workspace.value === null
    ) {
      e.preventDfault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (workspaces.length > 0) {
      let items = [];
      for (let i = 0; i < workspaces.length; i++) {
        items.push({
          display: workspaces[i].name,
          value: workspaces[i].ID,
        });
      }
      setDisplayWorkspaces(items);
    }
    setLoading({status: false, type: null});
  }, [workspaces]);

  return (
    <>
      <ContentSection>
        <Header>Copy To</Header>

        <WrapField>
          <FieldName>Select Workspace</FieldName>

          <MarginForMobile />

          <WrapInput onClick={loadWorkspaces} style={{position: "relative"}}>
            <Select
              onSelect={handleWorkspaceSelection}
              selected={selected.workspace.display}
              options={displayWorkspaces}
              height={200}
              overflowY={true}
            />
            {loading.status && loading.type === "workspaces" ? (
              <WrapSpinner>
                <Spinner />
              </WrapSpinner>
            ) : undefined}
          </WrapInput>
        </WrapField>

        <WrapField
          style={{
            position: "relative",
            opacity: selected.workspace.value ? 1 : 0.5,
            pointerEvents: !selected.workspace.value ? "none" : undefined,
          }}
        >
          <FieldName>
            Select Folder<span style={{fontSize: 14, fontWeight: 500, paddingLeft: 8}}>(Optional)</span>
            {loading.status && loading.type === "folders" ? (
              <WrapSpinner style={{top: 0, left: 160}}>
                <Spinner />
              </WrapSpinner>
            ) : undefined}
          </FieldName>

          <MarginForMobile />

          <WrapInput onClick={clickFolderSelectionBox}>
            <Select
              onSelect={handleFolderSelection}
              selected={selected.folder.display}
              options={folders}
              height={200}
              overflowY={true}
            />
          </WrapInput>
        </WrapField>

        {selected.folder?.subfolders &&
        selected.folder?.subfolders.length > 0 ? (
          <WrapField
            style={{
              position: "relative",
              opacity: selected.workspace.value ? 1 : 0.5,
              pointerEvents: !selected.workspace.value ? "none" : undefined,
            }}
          >
            <FieldName>
              Select Subfolder{" - "}
              <span style={{fontSize: 13, fontStyle: "italic"}}>
                (Optional)
              </span>
              {loading.status && loading.type === "folders" ? (
                <WrapSpinner style={{top: 0, left: 160}}>
                  <Spinner />
                </WrapSpinner>
              ) : undefined}
            </FieldName>

            <MarginForMobile />

            <WrapInput onClick={clickFolderSelectionBox}>
              <Select
                onSelect={handleSubfolderSelection}
                selected={
                  isMovingToSubfolder?.status
                    ? isMovingToSubfolder?.subfolder.display
                    : "N/A"
                }
                options={selected.folder?.subfolders}
                height={200}
                overflowY={true}
              />
            </WrapInput>
          </WrapField>
        ) : undefined}

        {error.status ? <ShowError message={error.message} /> : undefined}
      </ContentSection>

      <Footer style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}>
        <BottomSection
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <SharedList onClick={onSwitchDisplay}>Shared List</SharedList> */}
          <Cancel onClick={close}>Cancel</Cancel>
          <BottomSection>
            <Button
              styles={{width: 84}}
              disabled={submitting}
              onClick={handleSubmit}
            >
              {!submitting ? (
                "Send"
              ) : (
                <ButtonSpinner width={15} height={15} color={"#fff"} />
              )}
            </Button>
          </BottomSection>
        </BottomSection>
      </Footer>
    </>
  );
};

const WrapField = styled.div`
  display: flex;
  margin: 0.9rem 0 1.4rem;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const WrapInput = styled.div`
  flex: 1;
`;

const WrapSpinner = styled.div`
  transform: scale(0.7);
  position: absolute;
  top: 84px;
  left: 48%;
  z-index: 9999;
`;

const MarginForMobile = styled.div`
  @media only screen and (max-width: 600px) {
    margin-bottom: 12px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspaces: selectWorkspaceList(state.userDashboard),
  };
};

export default connect(mapStateToProps, {fetchWorkspaces})(ShareToWorkspace);
