import React, {useRef} from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {Modal, Spinner} from "../../../../oat-ui";
import {UploadFile} from "../../../reuseStyles/reuseStyles";
import {
  toggleAdminMediaModal,
  uploadAdminMedia,
  uploadAdminMediaError,
} from "../../../store/actions/media.action";
import {selectAdminMedia} from "../../../store/selectors/adminMedia.selector";

const UploadPrivateMedia = ({
  authUser,
  modal,
  uploadAdminMedia,
  toggleAdminMediaModal,
  uploadAdminMediaError,
}) => {
  const handleUpload = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let imgType = event.target.files[0].type.split("image/")[1];
      if (imgType === "svg+xml") {
        imgType = "svg";
      }
      const img = new Image();
      let fileReader = new FileReader();
      let imgSize = {w: 0, h: 0};

      fileReader.onload = () => (img.src = fileReader.result);
      fileReader.onerror = () => {
        uploadAdminMediaError("Couldn't identify image size.");
      };
      fileReader.readAsDataURL(event.target.files[0]);

      img.onload = async () => {
        imgSize = {
          w: img.width,
          h: img.height,
        };

        const imgInfo = {
          createdBy: authUser.username, // later change to []
          uploadedBy: authUser.username,
          imgType,
          imgSize,
          // packID
        };
        await uploadAdminMedia(
          `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/private-media-library`,
          event,
          imgInfo
        );
        event.target.value = null;
      };
    }
  };

  const inputFileRef = useRef(null);

  const onInitUploadImg = (event) => {
    event.target.value = null;
    inputFileRef.current.click();
  };

  const closeModal = () => {
    toggleAdminMediaModal(false, null);
  };

  return (
    <div>
      <UploadFile onClick={onInitUploadImg}>Upload Image</UploadFile>
      <input ref={inputFileRef} type="file" onChange={handleUpload} hidden />

      {modal.status && modal.type === "upload-media" ? (
        <Modal width={300} height={180} close={closeModal}>
          <div style={{marginTop: 40}}>
            <Spinner />
          </div>
        </Modal>
      ) : undefined}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: selectUserInfo(state.authUser),
    modal: selectAdminMedia(state.adminMedia).modal,
  };
};

export default connect(mapStateToProps, {
  toggleAdminMediaModal,
  uploadAdminMedia,
  uploadAdminMediaError,
})(UploadPrivateMedia);
