import React, {useRef} from "react";
import {connect} from "react-redux";
import {
  ThemeName,
  ThemeColor,
  ThemeOptionBox,
} from "../parts/templateThemes/ThemeStyles";
import {createSlide} from "../../../store/actions/template/slide.action";
import {detectClickOutside} from "../../../../oat-ui";
import {selectTemplateThemes} from "../../../store/selectors/template/theme.selector";
import {Dot, Option} from "../../../ui/styles/SelectStyles";
import {toggleTemplateSelectionModal} from "../../../store/actions/template/template.action";
import {selectActiveSlide} from "../../../store/selectors/template/slide.selector";
import { themeBuilder } from "../../../store/reducers/data/themeBuilder.data";

const DisplayItem = ({theme, onCreate}) => {
  const handleClick = () => {
    onCreate(theme);
  };
  return (
    <Option onClick={handleClick}>
      <ThemeName>
        <ThemeColor bg={theme.bg} />
        <span>{theme.name}</span>
      </ThemeName>
      <Dot />
    </Option>
  );
};

const ThemeOptions = ({
  themes,
  createSlide,
  close,
  toggleTemplateSelectionModal,
  slideID,
}) => {
  const selectRef = useRef(null);

  const handleSlideCreate = (theme) => {
    createSlide({insertAfterSlideIndex: slideID, themeID: theme.id});
    close();
  };

  detectClickOutside(selectRef, () => close());

  const toggleSelectionModal = () => {
    toggleTemplateSelectionModal();
    close();
  };

  return (
    <ThemeOptionBox
      style={{
        position: "fixed",
        left: 15,
        bottom: 52,
        boxShadow: "-5px 5px 20px 0px rgb(0 0 0 / 20%)",
      }}
      ref={selectRef}
    >
      <div style={{margin: 12, width: "100%", height: "100%"}}>
        {themes && themes.map((theme) => (
          <DisplayItem
            key={theme.id}
            theme={theme}
            onCreate={handleSlideCreate}
          />
        ))}
        
        {themes.length === 0 ? (
          <DisplayItem
            key={themeBuilder?.id}
            theme={themeBuilder}
            onCreate={() => handleSlideCreate(themeBuilder)}
          />
        ) : undefined}

        <Option onClick={toggleSelectionModal}>
          <ThemeName>
            <span>Select Templates + </span>
          </ThemeName>
          <Dot />
        </Option>
      </div>
    </ThemeOptionBox>
  );
};

const mapStateToProps = ({designTemplate}) => {
  return {
    themes: selectTemplateThemes(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  createSlide,
  toggleTemplateSelectionModal,
})(ThemeOptions);
