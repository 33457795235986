import React from "react";

const ThemeGrid = ({stroke = "#e7e7e77d", x1, x2, y1, y2}) => (
  <line
    x1={x1}
    x2={x2}
    y1={y1}
    y2={y2}
    stroke={stroke}
    strokeWidth={1}
    strokeDasharray={6}
  ></line>
);

export default ThemeGrid;
