import styled from "styled-components";
import {darkGrey1} from "../../../../../../oat-color-variables";

export const WrapItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  font-size: 0.95rem;
  color: ${darkGrey1};
  margin: 12px 0;
`;
