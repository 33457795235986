import React, {useState, useEffect, useCallback, useRef} from "react";
import {connect} from "react-redux";
import Carousel from "../../../oat-ui/core/Carousel";
import {getWindowSize} from "../../../oat-window-size";
import {
  selectEmbeddingType,
  selectTempScale,
  selectTemplate,
  selectTemplateSize,
} from "../../store/selectors/template/template.selector";
import {
  selectActiveSlide,
  selectActiveSlideID,
  selectCurrentSlideOnClickAnimationOrder,
  selectActiveSlideIndex,
  selectSlideAnimationOrder,
  selectTemporarilyDisableTransition,
} from "../../store/selectors/template/slide.selector";
import ShowCanvas from "./ShowCanvas";
import {
  Layout,
  WrapCanvas,
  PanelArea,
  CarouselFrame,
  CanvasSection,
  WrapCloseSvg,
  MobileClosBtn,
} from "../editDesign/canvasPanel/CanvasPanelStyles";
import SlideNavPanel from "./presentViewSlideBar/SlideNavPanel";
import {
  changeSlide,
  changeSlideOnPresentView,
  toggleTemporarilyDisableSlideTransition,
} from "../../store/actions/template/slide.action";
import {Close} from "../../../oat-svg-icons";
import {useFullscreen} from "../../../oat-hooks";
// import {DesktopToggleSlides, MobileToggleSlides} from "../..";
import {isMobile, isTablet, isDesktop} from "react-device-detect";
import GuestCommentSection from "./guestComment/GuestCommentSection";
import {setTempScale} from "../../store/actions/template/template.action";
import {useLocation, useNavigate, useParams} from "react-router-dom";

export const canvasPanelHeight = window.innerWidth > 600 ? 70 : 48;

// classic-transition /overlay-transition / no-transition
// const transition = "classic-transition";
const Present = ({
  activeSlide,
  activeSlideID,
  currentSlideIndex,
  slides,
  canvasSize,
  transition,
  changeSlide,
  temporaryDisableTransition,
  toggleTemporarilyDisableSlideTransition,
  embedType,
  changeSlideOnPresentView,
  currentAnimationOrder,
  setTempScale,
  tempScale,
  animationOrder,
  panelWidth,
}) => {
  const windowSize = getWindowSize();
  const [navBarPanel, setNavBarPanel] = useState(0);
  const [showSlideList, setShowSlideList] = useState(false);
  const [displayPanel, setDisplayPanel] = useState({
    width: 0,
    height: 0,
  });
  const [viewCommentPanel, setViewCommentPanel] = useState(false);
  const fullScreenRef = useRef(null);
  const panelAreaRef = useRef(null);
  const [isFullscreen, setFullscreen] = useFullscreen(fullScreenRef);
  const { 
    highestOrder: highestAnimationOrder, 
    lastOnClickAnimationOrderHasEnabledExit: exitAnimationOnLastItem,
    highestOrderIsNotTypeAnimateOnClick
  } = animationOrder;
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const isEmbedded = query.get("embed") === "true";

  // checking for landscape
  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? "landscape" : "portrait"
    ),
    updateOrientation = () => {
      clearTimeout(window.resizeLag);
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 200);
    };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, [windowSize]);

  let params = useParams();
  useEffect(() => {
    let calcScale = null;
    // params?.tempID -> normal present view 
    // without -> embed view style within the app
    const canvasWidth = params?.tempID ? windowSize.width : panelWidth;
    // const canvasWidth = panelWidth; // windowSize.width;
    const canvasHeight = (canvasSize.h * panelWidth) / canvasSize.w;

    if ((isMobile || isTablet) && orientation === "landscape") {
      if (canvasHeight >= windowSize.height) {
        const canvasWidth = (canvasSize.w * windowSize.height) / canvasSize.h;
        calcScale = Math.min(
          (windowSize.height - navBarPanel) / canvasSize.h,
          canvasWidth / canvasSize.w
        );
      } else {
        const canvasHeight = (canvasSize.h * windowSize.width) / canvasSize.w;
        const reduceNavPanelHeight = (isMobile || isTablet) && orientation === "landscape" ?
          navBarPanel : 0;
        calcScale = Math.min(
          (canvasHeight - reduceNavPanelHeight) / canvasSize.h,
          windowSize.width / canvasSize.w
        );
      }
    } else if (!document.fullscreenElement && isDesktop) {
      calcScale = Math.min(
        (windowSize.height - navBarPanel) / canvasSize.h,
        canvasWidth / canvasSize.w
      );
    } else {
      // change width and height on fullscreen
      if (!document.fullscreenElement) {
        const canvasHeight = (canvasSize.h * panelWidth) / canvasSize.w;
        calcScale = Math.min(
          canvasHeight / canvasSize.h,
          panelWidth / canvasSize.w
        );
      } else {
        const canvasHeight = (canvasSize.h * windowSize.width) / canvasSize.w;
        calcScale = Math.min(
          canvasHeight / canvasSize.h,
          windowSize.width / canvasSize.w
        );
      }
    }

    if (calcScale !== Infinity) setTempScale(calcScale);
    // setScale(calcScale);
  }, [
    windowSize.height,
    windowSize.width,
    canvasSize,
    transition,
    isFullscreen,
    orientation,
    navBarPanel,
    panelWidth
  ]);

  useEffect(() => {
    if (
      transition === "no-transition" ||
      transition === "fade-in-transition" ||
      transition === "overlay-transition"
    ) {
      setDisplayPanel({
        width: canvasSize.w * tempScale,
        height: canvasSize.h * tempScale,
      });
    }
  }, [tempScale, isFullscreen, canvasSize.w, transition]);

  const [showSlideNavPanel, setShowSlideNavPanel] = useState(false);
  const onMouseMoving = (e) => {
    const onMove = (e) => {
      e.stopImmediatePropagation();
      const {clientY} = e;
      if (clientY > window.innerHeight - 95) {
        setShowSlideNavPanel(true);
      } else {
        setShowSlideNavPanel(false);
      }
    };
    if (embedType === "") {
      const onLeave = () => {
        setShowSlideNavPanel(false);
        document.removeEventListener("mousemove", onMove);
        document.removeEventListener("mouseleave", onLeave);
      };
      document.addEventListener("mousemove", onMove);
      document.addEventListener("mouseleave", onLeave);
    }
  };

  const detectShortcutKeys = useCallback(
    (event) => {
      // const slideListElement = document.getElementById(
      //   "present-view-horizontal-slide-list"
      // );
      if (!viewCommentPanel) {
        if (event.keyCode === 37) {
          if (activeSlide === 0 && currentAnimationOrder === 0) return;
          changeSlideOnPresentView("backward");
          setShowSlideNavPanel(false);
          // changeSlide(currentSlideIndex - 1);
          // slideListElement.scrollLeft = -330;
        } else if (event.keyCode === 39) {
          if (
            activeSlide === slides.length - 1 &&
            currentAnimationOrder >= highestAnimationOrder
          ) {
            return;
          }
          changeSlideOnPresentView("forward");
          setShowSlideNavPanel(false);
          
          if (exitAnimationOnLastItem && 
            highestAnimationOrder !== 0 && 
            (highestOrderIsNotTypeAnimateOnClick ||
             (currentAnimationOrder === highestAnimationOrder))
            ) {
            setTimeout(async() => {
              await changeSlideOnPresentView("forward");
            }, 800)
          };
        }
      }
    },
    [
      activeSlide, 
      viewCommentPanel, 
      exitAnimationOnLastItem, 
      currentAnimationOrder, 
      highestAnimationOrder,
      highestOrderIsNotTypeAnimateOnClick
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", detectShortcutKeys, false);
    return () => {
      document.removeEventListener("keydown", detectShortcutKeys, false);
    };
  });

  useEffect(() => {
    setShowSlideNavPanel(true);
  }, []);

  useEffect(() => {
    if (temporaryDisableTransition) {
      setTimeout(() => {
        toggleTemporarilyDisableSlideTransition();
      }, 100);
    }
  }, [temporaryDisableTransition]);

  const handleExitFullscreen = () => {
    document.exitFullscreen();
  };

  const toggleSlideListStatus = (value) => {
    setShowSlideList(value);
  };

  useEffect(() => {
    if (document.fullscreenElement) {
      setNavBarPanel(orientation === "landscape" && (isMobile || isTablet) ? canvasPanelHeight : 0 );
    } else {
      setNavBarPanel(embedType === "with-panel" ? canvasPanelHeight : orientation === "landscape" && (isMobile || isTablet) ? canvasPanelHeight : 0); // 70 // (windowSize.width > 600 ? 70 : 52)
    }
  }, [embedType, orientation, document.fullscreenElement]);

  useEffect(() => {
    if (params?.tempID) {
      if (isEmbedded && typeof activeSlideID === 'string') {
        navigate(`?embed=true&slide=${activeSlideID}`, { replace: true });
      } else if (typeof activeSlideID === 'string') {
        navigate(`?slide=${activeSlideID}`, { replace: true });
      } else if (isEmbedded) {
        navigate(`?embed=true`, { replace: true });
      }
    }
  }, [activeSlideID])

  const onToggleCommentPanel = () => {
    setViewCommentPanel(!viewCommentPanel);
  };

  const handleFullscreenClick = () => {
    if (!isFullscreen) {
      setFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const [trackSlides, setTrackSlides] = useState({});
  const [entryTime, setEntryTime] = useState(Date.now());
  const hasMountedRef = useRef(false);
  useEffect(() => {
    const handleSlideClick = () => {
      const currentTime = Date.now();
      const timeSpent = (currentTime - entryTime) / 1000;

      setTrackSlides(prevSlide => ({
        ...prevSlide,
        [activeSlideID]: {
          duration: (prevSlide[activeSlideID]?.duration || 0) + timeSpent,
          times: (prevSlide[activeSlideID]?.times || 0) + 1,
        }
      }));
      setEntryTime(currentTime);
    }
    
    return () => {
      handleSlideClick()
    }
  }, [activeSlideID]);

  

  // const handleBeforeUnload = async (e) => {
  //   // Make a POST request before the browser closes
  //   try {

  //     const url = 'https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/track-template'; // Replace with your actual endpoint

  //     // try {
  //     //   // Convert the data to JSON string
  //     //   const body = JSON.stringify({payload: trackSlides,
  //     //         tempID: 'ADO49pO1xJ'});

  //     //   // Use navigator.sendBeacon to send the data
  //     //   const blob = new Blob([body], { type: 'application/json' });
  //     //   const beaconResult = navigator.sendBeacon(url, blob);

  //     //   console.log("Beacon result:", beaconResult);
  //     // } catch (error) {
  //     //   console.error("Error sending beacon:", error);
  //     // }

  //     await fetch('https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/track-template', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ 
  //         payload: trackSlides, // slidesDataRef?.current,
  //         tempID: 'ADO49pO1xJ'
  //       })
  //     })
  //     .then((res)=> res.json())
  //     .then((data) => console.log(data))
  //     .catch((e) => console.log(e));

  //     console.log('2')


  //     // This part is necessary to ensure that the function can prevent the unload
  //     if (e) {
  //       e.preventDefault();
  //       e.returnValue = '';
  //     }
  //   } catch (e) {
  //     console.log(e.message)
  //   }
  // };

  // const slidesDataRef = useRef(trackSlides);
  // useEffect(() => {
  //   slidesDataRef.current = trackSlides;
  // }, [trackSlides]);

  // useEffect(() => {
  //   // Set the hasMountedRef to true after the first render
  //   hasMountedRef.current = true;

  //   const onBeforeUnload = (event) => {
  //     if (hasMountedRef.current) {
  //       handleBeforeUnload(event);
  //     }
  //   };

  //   window.addEventListener('unload', onBeforeUnload);
  //   return () => {
  //     window.removeEventListener('unload', onBeforeUnload);
  //   };
  // }, []); // trackSlides

  return (
    <Layout
      ref={fullScreenRef}
      animate={true}
      style={{
        width: params?.tempID ? windowSize.width : panelWidth, // windowSize.width,
        height: params?.tempID ? windowSize.height : 'auto', // windowSize.height,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 0,
        boxSizing: "border-box",
        overflow: "hidden",
        background: '#fff !important'
        // smoother in overlay transition
        // transition === "overlay-transition" ? windowSize.height : undefined,
      }}
      onMouseMove={onMouseMoving}
    >
      <PanelArea cardType="card" ref={panelAreaRef}>
        {transition === "classic-transition" ? (
          <Carousel
            activeIndex={currentSlideIndex}
            duration={!temporaryDisableTransition ? 0.7 : 0}
            timing="ease-in-out"
            carouselType={transition}
            // styles={{height: windowSize.height - navBarPanel}}
          >
            {slides &&
              slides.map((slide, index) => {
                if (transition === "classic-transition") {
                  return (
                    <CarouselFrame
                      aria-hidden={index === currentSlideIndex ? false : true}
                      key={index}
                      style={{alignItems: "center"}}
                    >
                      <WrapCanvas>
                        <CanvasSection>
                          <ShowCanvas
                            canvasIndex={index}
                            slide={slide}
                            canvasSize={canvasSize}
                            scale={tempScale}
                            activeSlide={index === currentSlideIndex}
                            activeSlideIndex={currentSlideIndex}
                            lastSlide={index === slides.length - 1}
                            showChromeBar={showSlideNavPanel}
                            enableAnimation={true}
                          />
                        </CanvasSection>
                      </WrapCanvas>
                    </CarouselFrame>
                  );
                }
              })}
          </Carousel>
        ) : (
          <Carousel
            activeIndex={currentSlideIndex}
            duration={0}
            timing="ease-in-out"
            carouselType={transition}
            styles={{
              width: displayPanel.width,
              height: displayPanel.height,
              margin: 0,
              overflow: "hidden",
              borderRadius: 0,
            }}
          >
            {slides &&
              slides.map((slide, index) => {
                if (
                  transition === "no-transition" ||
                  transition === "fade-in-transition"
                ) {
                  return (
                    <CarouselFrame
                      aria-hidden={index === currentSlideIndex ? false : true}
                      key={index}
                      style={{
                        WebkitTransition:
                          !temporaryDisableTransition &&
                          transition === "fade-in-transition"
                            ? "opacity 0.5s linear"
                            : undefined,
                        transition:
                          !temporaryDisableTransition &&
                          transition === "fade-in-transition"
                            ? "opacity 0.5s linear"
                            : undefined,
                        opacity: index === currentSlideIndex ? 1 : 0,
                        position: "absolute",
                        top: 0,
                        zIndex: index === currentSlideIndex ? 100 : 1,
                        alignItems: "center",
                      }}
                    >
                      <WrapCanvas>
                        <CanvasSection>
                          <ShowCanvas
                            canvasIndex={index}
                            slide={slide}
                            canvasSize={canvasSize}
                            scale={tempScale}
                            activeSlide={index === currentSlideIndex}
                            activeSlideIndex={currentSlideIndex}
                            lastSlide={index === slides.length - 1}
                            showChromeBar={showSlideNavPanel}
                            enableAnimation={true}
                          />
                        </CanvasSection>
                      </WrapCanvas>
                    </CarouselFrame>
                  );
                } else if (transition === "overlay-transition") {
                  return (
                    <CarouselFrame
                      aria-hidden={index === currentSlideIndex ? false : true}
                      key={index}
                      style={{
                        alignItems: "center",
                        transition: `all ${
                          !temporaryDisableTransition ? 0.7 : 0
                        }s ease-out`,
                        transform: `translate(${
                          index === currentSlideIndex
                            ? currentSlideIndex * -100
                            : index < currentSlideIndex
                            ? index * -100
                            : (index - 1) * -99
                        }%, 0px)`,
                      }}
                    >
                      <WrapCanvas style={{justifyContent: "flex-start"}}>
                        <CanvasSection>
                          <ShowCanvas
                            canvasIndex={index}
                            slide={slide}
                            canvasSize={canvasSize}
                            scale={tempScale}
                            activeSlide={index === currentSlideIndex}
                            activeSlideIndex={index}
                            lastSlide={index === slides.length - 1}
                            showChromeBar={showSlideNavPanel}
                            enableAnimation={true}
                          />
                        </CanvasSection>
                      </WrapCanvas>
                    </CarouselFrame>
                  );
                }
              })}
          </Carousel>
        )}
      </PanelArea>

      {viewCommentPanel ? (
        <GuestCommentSection close={onToggleCommentPanel} />
      ) : undefined}

      {/* Test before deleting this */}
      {/* {orientation === "portrait" && (windowSize.width < 850 || isMobile) ? (
        <MobileToggleSlides
          tempScale={scale}
          setIsFullscreen={setIsFullscreen}
        />
      ) : undefined} */}

      {isFullscreen && (isMobile || isTablet) ? (
        <MobileClosBtn>
          <WrapCloseSvg onClick={handleExitFullscreen}>{Close}</WrapCloseSvg>
        </MobileClosBtn>
      ) : undefined}

      {/* Test before deleting this - better not delete yet */}
      {/* for orientation mode on mobile devices */}
      {/* {orientation === "landscape" && (isMobile || isTablet) ? (
        <DesktopToggleSlides />
      ) : undefined} */}

      <SlideNavPanel
        showSlideList={showSlideList}
        onDisplayView={toggleSlideListStatus}
        displayBar={showSlideNavPanel}
        onViewComment={onToggleCommentPanel}
        activateFullScreen={handleFullscreenClick}
        isFullscreen={isFullscreen}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeSlide: selectActiveSlide(designTemplate),
    activeSlideID: selectActiveSlideID(designTemplate),
    currentSlideIndex: selectActiveSlideIndex(designTemplate),
    slides: selectTemplate(designTemplate),
    canvasSize: selectTemplateSize(designTemplate),
    embedType: selectEmbeddingType(designTemplate),
    temporaryDisableTransition:
      selectTemporarilyDisableTransition(designTemplate),
    currentAnimationOrder: selectCurrentSlideOnClickAnimationOrder(designTemplate),
    animationOrder: selectSlideAnimationOrder(designTemplate),
    tempScale: selectTempScale(designTemplate),
  };
};

export default connect(mapStateToProps, {
  changeSlide,
  toggleTemporarilyDisableSlideTransition,
  changeSlideOnPresentView,
  setTempScale,
})(Present);
