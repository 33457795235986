import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../redux/user/authUser";
import {Close, LeftArrow} from "../../oat-svg-icons";
import {getWindowSize} from "../../oat-window-size/getWindowSize";
import LoggedInUserNavContents from "./sliderContents/LoggedInUserNavContents";
import GuestNavContents from "./sliderContents/GuestNavContents";

const NavSlider = ({open, user, onClose}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const {width, height} = getWindowSize();

  const goBackSliderTab = () => {
    if (carouselIndex === 0) onClose();
    else {
      setCarouselIndex(carouselIndex - 1);
    }
  };

  const onCloseSlider = () => {
    onClose();
  };

  const incrementCarousel = () => {
    setCarouselIndex(carouselIndex + 1);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      document.body.style.overscrollBehaviorX = "none";
      return () => {
        document.body.style.overflow = "";
        document.body.style.overscrollBehaviorX = "";
      };
    }
  }, [open]);

  return (
    <Slider style={{left: open ? 0 : -width, width: width, height: height}}>
      <TopSection>
        <CloseBtn onClick={goBackSliderTab}>
          <WrapSvg>{carouselIndex === 0 ? Close : LeftArrow}</WrapSvg>
        </CloseBtn>
        <CloseBtn onClick={onCloseSlider}>
          <WrapSvg>{carouselIndex !== 0 ? Close : undefined}</WrapSvg>
        </CloseBtn>
      </TopSection>

      <ContentLinks>
        {user.loggedIn.status ? (
          <LoggedInUserNavContents
            carouselIndex={carouselIndex}
            incrementCarousel={incrementCarousel}
            close={onCloseSlider}
          />
        ) : (
          <GuestNavContents close={onCloseSlider} />
        )}
      </ContentLinks>
    </Slider>
  );
};

const Slider = styled.div`
  position: absolute;
  background: #fffffff0;
  transition: all 0.3s ease;
  top: 0px;
  overflow: hidden;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
`;

const CloseBtn = styled.div`
  width: 72px;
  height: 100%;
  position: relative;
  left: 6px;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const WrapSvg = styled.div`
  width: 25px;
  height: 25px;
`;

const ContentLinks = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: ${window.innerHeight}px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

// const TabSection = styled.div`
//   display: flex;
//   justify-content: center;
//   font-size: 1.3rem;
// `;

// const Tab = styled.div`
//   border-bottom: 2px solid ${({active}) => (active ? blue3 : grey2)};
//   color: ${({active}) => (active ? "#5d5d5d" : "#7f7f7f")};
//   font-weight: ${({active}) => (active ? "700" : "500")};
//   padding: 0 0 5px;
//   width: 180px;
//   text-align: center;
//   cursor: pointer;
// `;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(NavSlider);
