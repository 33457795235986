import React, {useState, useRef} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {paleBlue} from "../../../oat-color-variables";
import {Button, Spinner, ToggleSwitch} from "../../../oat-ui";
import {selectAdminMedia} from "../../store/selectors/adminMedia.selector";
import {ErrorMsg} from "./uploadStyles";

const UploadMedia = ({
  onAddTag,
  onUpdateTag,
  onUpload,
  onLocationChange,
  onToggleCutout,
  formItems,
  uploading,
  error,
}) => {
  const previewFileRef = useRef(null);
  const [preview, setPreview] = useState({
    event: null,
    link: null,
  });
  const {tags, inputTag, cutout} = formItems;

  const handleImagePreview = (event) => {
    if (event.target.files && event.target.files[0]) {
      const objectUrl = URL.createObjectURL(event.target.files[0]);
      setPreview({event, link: objectUrl});

      return () => URL.revokeObjectURL(objectUrl);
    }
  };

  const handleTagInput = (e) => {
    onUpdateTag(e);
  };

  const addTag = () => {
    onAddTag();
  };

  const onInitUploadImg = () => {
    onUpload(preview.event);
  };

  const onInitPreviewImg = () => {
    previewFileRef.current.click();
  };

  return (
    <Layout>
      <ImagePanel>
        {preview.link === null ? (
          <>
            <div onClick={onInitPreviewImg}>Upload</div>
            <input
              ref={previewFileRef}
              type="file"
              onChange={handleImagePreview}
              hidden
            />
          </>
        ) : undefined}
        <PreviewImage src={preview.link} alt="" />
      </ImagePanel>
      <ContentPanel>
        {/* <WrapInput>
          <FieldTitle>Location</FieldTitle>
          <Select onChange={onLocationChange}>
            <option value="global">Global</option>
            <option value="mm">Myanmar</option>
          </Select>
        </WrapInput> */}
        {/* <WrapInput>
          <FieldTitle>Png Cutout</FieldTitle>
          <div>
            <ToggleSwitch value={cutout} onItemChange={onToggleCutout} />
          </div>
        </WrapInput> */}
        <WrapInput>
          <FieldTitle>Add Tag</FieldTitle>
          <Input
            type="text"
            value={inputTag}
            onChange={handleTagInput}
            placeholder="Add Tag"
          />
          <WrapButton>
            <Button
              styles={{
                marginLeft: 14, 
                fontSize: "1.4rem",  
              }}
              onClick={addTag}
            >
              +
            </Button>
          </WrapButton>
        </WrapInput>
        <Tags>{tags && tags.map((tag, i) => <Tag key={i}>{tag}</Tag>)}</Tags>
        <UploadSection>
          {uploading ? (
            <div style={{marginRight: 28, transform: "scale(0.8)"}}>
              <WrapSpinner>
                <Spinner />
              </WrapSpinner>
            </div>
          ) : undefined}
          <WrapButton>
            <UploadFile onClick={onInitUploadImg}>Upload</UploadFile>
          </WrapButton>
        </UploadSection>
        {error.status ? <ErrorMsg>{error.message}</ErrorMsg> : undefined}
      </ContentPanel>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  margin: 12px;
`;

const ImagePanel = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #676767;
  font-size: 0.95rem;
  font-weight: 700;
  margin-right: 14px;
  background: #dfdff57a;
  border: 2px solid #99a4f9;
  border-radius: 6px;
  margin: 12px;
  box-sizing: border-box;
  cursor: pointer;
`;

const ContentPanel = styled.div`
  flex: 1;
  margin-bottom: 14px;
  padding: 0 12px;
  box-sizing: border-box;
`;

const WrapInput = styled.div`
  display: flex;
  flex: 2;
  margin-top: 22px;
`;

const FieldTitle = styled.div`
  font-size: 0.93rem;
  flex: 1;
  color: #232323;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #a7a8d7;
`;

const Select = styled.select`
  padding: 4px 12px;
  border: 1px solid #a7a8d7;
`;

const WrapButton = styled.div`
  display: flex;
`;

const Tags = styled.div`
  margin: 16px 0;
  display: flex;
`;

const Tag = styled.div`
  background: #dcc5ff;
  font-size: 0.8rem;
  padding: 6px 12px;
  border-radius: 15px;
  margin-right: 12px;
  color: #232323;
  text-transform: lowercase;
`;

const UploadFile = styled.button`
  height: 38px;
  padding: 0 16px;
  background: ${paleBlue};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
`;

const UploadSection = styled.div`
  margin: 12px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  padding-top: 16px;
  border-top: 1px solid #cecece;
`;

const PreviewImage = styled.img`
  height: 228px;
  max-width: 100%;
`;

const WrapSpinner = styled.div``;

const mapStateToProps = (state) => {
  return {
    error: selectAdminMedia(state.adminMedia).error,
    uploading: selectAdminMedia(state.adminMedia).modal.processing,
  };
};

export default connect(mapStateToProps, null)(UploadMedia);
