import React, {useState, useEffect, useRef, useCallback} from "react";
import styled from "styled-components";
import {
  updateTextContent,
  enableTextEdit,
} from "../../../../store/actions/fields/text.action";
import {
  draggingField,
  resizingField,
  updateFieldStyles,
} from "../../../../store/actions/fields/common.action";
import {
  updateFieldSize,
  updateFieldPosition,
  onSelectField,
} from "../../../../store/actions/fields/common.action";
import {
  selectCropImage,
  selectEnableTextEdit,
} from "../../../../store/selectors/fields.selector";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../store/selectors/fields.selector";
import {
  selectDragging,
  selectInterceptedFields,
  selectResizing,
  selectUsersOnLine,
} from "../../../../store/selectors/common.selector";
import {connect, batch} from "react-redux";
import Tooltip from "../canvas/tooltip/Tooltip";
import {transformImgOffset} from "../../../../store/actions/fields/image.action";
import {centerToTL, getAngle, sanitizeInput} from "./utils";
import {calcOnResize} from "./resize/utils/calcOnResize";
import {getItemXOffset, getItemYOffset} from "../../../../utils/getItemOffset";
import {storeFieldUndoRedo} from "../../../../store/actions/fields/undoRedo.action";
import {
  dispatchInitCellSizes,
  resizeTableActions,
  removeInitCellSizes,
} from "../../../../store/actions/fields/table.action";
import {
  selectTemplateID,
  selectTemplateSize,
} from "../../../../store/selectors/template/template.selector";
import DisplayFields from "../fields/DisplayFields";
import ResizeHandles from "../canvas/ResizeHandles";
import TextBorderOnEdit from "../fields/text/TextBorderOnEdit";
import {
  selectCanvasPanelWidth,
  selectEditPanelStatus,
} from "../../../../store/selectors/layout/layout.selector";
import {toggleTooltipPopper} from "../../../../store/actions/layout.action";
import {useSocket} from "../../../../webSocket/useSocket";
import {
  selectActiveSlide,
  selectActiveSlideID,
} from "../../../../store/selectors/template/slide.selector";
import SelectedOnlineUsersItems from "./SelectedOnlineUsersItems";
import {isDesktop} from "react-device-detect";
import {setSmartAlignment} from "../../../../store/actions/template/slide.action";
import {selectCoordinates} from "../../../../store/selectors/smartAlign.selector";
import DisplayAlignments from "../canvas/DisplayAlignments";
import {drawLine} from "./resize/utils/drawLine";
import {calcShapeLineOffsets, calcRotatedOffsets} from "./resize/utils/calcRotatedOffsets";
import {canvasAreaPadding, canvasPaddingTop} from "../CanvasPanel";
import {mobTopPanel} from "../canvas/Canvas";
import {sidebarWidth} from "../../../../layoutSizes";
import {updateFieldCollaboration} from "../../../../store/actions/collaboration/collaboration.action";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import FieldRatio from "./FieldRatio";
import { onInterceptFields } from "../../../../store/actions/fields/groupSelection.action";

let fieldRef = null;
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
function useHookWithRefCallback() {
  const setRef = useCallback((node) => {
    fieldRef = node;
    return;
  }, []);
  return [setRef];
}

let alignment = {
  x: {
    value: null,
    display: false,
  },
  y: {
    value: null,
    display: false,
  },
};

export function getLastTextNode(element) {
  if (!element) {
    return null;
  }
  const lastChild = element?.lastChild;
  if (lastChild?.nodeType === Node.TEXT_NODE) {
    return lastChild;
  }
  return getLastTextNode(lastChild);
}

let delayDebounce;
let delayWhiteSpaceDebounce;

const isFirefox = navigator.userAgent.includes("Firefox");
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const DragResize = ({
  slide,
  slideIndex,
  activeField,
  activeSlide, // true false
  tempScale,
  onSelectField,
  updateFieldSize,
  updateFieldPosition,
  selectedProps,
  croppingImage,
  transformImgOffset,
  updateFieldStyles,
  groupSelect,
  updateTextContent,
  tempSize,
  textEditStatus,
  dispatchInitCellSizes,
  resizeTableActions,
  removeInitCellSizes,
  enableTextEdit,
  displayEditPanel,
  storeFieldUndoRedo,
  toggleTooltipPopper,
  usersOnline,
  draggingField,
  resizingField,
  dragging,
  resizing,
  setSmartAlignment,
  smartAlignments,
  canvasWidth,
  updateFieldCollaboration,
  activeSlideID,
  onInterceptFields
}) => {
  const [ref] = useHookWithRefCallback();
  const {emitSocketEvents} = useSocket() || {};

  // original image offset position
  const [orgImgPos, setOrgImgPos] = useState({x: 0, y: 0});
  const windowSize = getWindowSize();

  const onOffsetChange = (x, y, rotatedOffsets) => {
    updateFieldPosition({
      active: {slideID: slideIndex, fieldID: activeField},
      payload: {x, y, rotatedOffsets},
    });
  };

  const onSizeChange = (w, h, w2, h2, scale) => {
    updateFieldSize({
      active: {slideID: slideIndex, fieldID: activeField},
      payload: {
        outerWidth: w,
        outerHeight: h !== 0 ? h : selectedProps?.size?.h,
        innerWidth: w2,
        innerHeight: h !== 0 ? h2 : selectedProps?.size?.h2,
        scale,
        // rotate,
      },
    });
  };

  const onUpdateStyles = (type, value) => {
    updateFieldStyles({
      active: {slideID: slideIndex, fieldID: activeField},
      type,
      value: value,
    });

    if (type === "whiteSpace") {
      clearTimeout(delayWhiteSpaceDebounce);
      delayWhiteSpaceDebounce = setTimeout(() => {
        // const payload = {type: "whiteSpace", value};
        // emitSocketEvents({actionType: "style-changed", item: payload});
        updateFieldCollaboration();
      }, 3500);
      return () => clearTimeout(delayWhiteSpaceDebounce);
    }
  };

  /* DRAG SECTION */
  const onDragStart = (e, field) => {
    const {pos} = field || {};  
    // if (e.shiftKey) {
    //   e.preventDefault();
    //   onInterceptFields(field?.key)
    //   // return;
    // } 
    // if (field?.styles?.lock || groupSelect.keys.includes(field?.key)) {
    //   console.log('xxxx')
    //   e.preventDefault();
    //   return;
    // }
    // if user is on edit text and select another
    if (
      textEditStatus &&
      selectedProps?.key &&
      selectedProps?.key !== field?.key
    ) {
      onSelectField(field?.key, field?.type);
    } else if (!textEditStatus) {
      let x = pos?.x;
      let y = pos?.y;
      const clientX = getItemXOffset(e, tempScale);
      const clientY = getItemYOffset(e, tempScale);
      if (
        activeField !== field.key &&
        (window.innerWidth >= 850 || isDesktop)
      ) {
         onSelectField(field?.key, field?.type);
      }
      toggleTooltipPopper(false);
      if (!field?.groupAsOne?.keys?.length > 0) {
        draggingField({
          dragging: true, //field.styles.lock === false ? true : false,
          orig: {
            initialX: Math.round(clientX - x),
            initialY: Math.round(clientY - y),
          },
        });
      }

      alignment.x.value = null;
      alignment.x.display = false;
      alignment.y.value = null;
      alignment.y.display = false;
      setSmartAlignment({type: "x", value: null});
      setSmartAlignment({type: "y", value: null});

      storeFieldUndoRedo();

      if (window.innerWidth >= 850 && isSafari) {
        e.preventDefault(); // only for safari cursor on drag
        // becomes cursor setting error for table on chrome
      }
    }
    e.stopPropagation();
  };

  const onDrag = (e) => {
    if(Object.keys(selectedProps)?.length === 0) {
      return
    }
    e.stopImmediatePropagation();

    const {orig, status} = dragging || {};
    const {size, type, subtype, styles, rotatedOffsets} = selectedProps || {};

    // dragTest &&
    if (croppingImage === false && !styles?.lock) {
      draggingField({
        ...dragging,
        dragging: true, //field.styles.lock === false ? true : false,
      });

      const scaledClientX = getItemXOffset(e, tempScale);
      const scaledClientY = getItemYOffset(e, tempScale);
      const {initialX, initialY} = orig;
      const currentX = Math.round(scaledClientX - initialX);
      const currentY = Math.round(scaledClientY - initialY);

      // to continue dragging on while the alignment line is showing
      const dragXWithinRange = (0.6 * tempSize.w) / 100;
      const dragYWithinRange = (0.6 * tempSize.h) / 100;
      // const currentX = Math.round(e.clientX - initialX * tempScale),

      // only allows shape and image to show center alignment
      // for eg, if shape is being dragged and trying to position its starting edge corner
      // to that of image's center, alignment will be shown
      if (
        (smartAlignments.x[currentX] &&
          (type === "image" || type === "shape")) && subtype !== "line" ||
        (smartAlignments.x[currentX] === "corner" && !alignment.x.display)
      ) {
        alignment.x.value = currentX;
        alignment.x.display = true;
      } else if (
        smartAlignments.x[Math.round(currentX + size.w)] === "corner" &&
        !alignment.x.display
      ) {
        alignment.x.value = currentX + size.w;
        alignment.x.display = true;
      } else if (
        smartAlignments.x[Math.round(currentX + size.w / 2)] === "center" &&
        !alignment.x.display
      ) {
        alignment.x.value = currentX + size.w / 2;
        alignment.x.display = true;
      } else if (
        smartAlignments.x[Math.round(currentX + size.w / 2)] ===
        "field-centred-template"
      ) {
        alignment.x.value = tempSize.w / 2;
        alignment.x.display = true;
        // we don't wanna show field edging the mid point of a template
        alignment.x.type = "field-centred-template";
      }

      if (
        (smartAlignments.y[currentY] &&
          ((type === "image" || type === "shape"))) && subtype !== "line" ||
          // don't math.round here
        (smartAlignments.y[currentY] === "corner" && !alignment.y.display)
      ) {
        alignment.y.value = currentY;
        alignment.y.display = true;
      } else if (
        smartAlignments.y[Math.round(currentY + size.h)] === "corner" &&
        !alignment.y.display 
      ) {
        alignment.y.value = currentY + size.h;
        alignment.y.display = true;
      } else if (
          smartAlignments.y[Math.round(currentY + (size.h / 2))] === "center" &&
          !alignment.y.display
          //  &&
          // only allow field's mid point alignment to image or shape or if not to the corner points
          // (type === "image" || type === "shape")
      ) {
        alignment.y.value = currentY + (size.h / 2);
        alignment.y.display = true;        
        // display x align when on drag alignment is on Y
        setSmartAlignment({type: "y", value: currentY + (size.h / 2)});
      }  else if (
        smartAlignments.y[Math.round(currentY + size.h / 2)] === "field-centred-template" &&
        subtype !== "line"
      ) {
        alignment.y.value = tempSize.h / 2; //currentY + size.h / 2;
        alignment.y.display = true;
        // we don't wanna show field edging the mid point of a template
        alignment.y.type = "field-centred-template";
      } 

      if (!groupSelect.status) {
        // handleXSmartAlignments(currentX, currentY);
        function hideHorizontalAlignment(offsetX) {
          if (smartAlignments.y[currentY]) {
            alignment.y.display = true;
            onOffsetChange(offsetX, currentY);
            setSmartAlignment({type: "y", value: currentY});
          } 
          else {
            alignment.y.display = false;
            setSmartAlignment({type: "y", value: null});
          }
        }

        function hideVerticalAlignment(offsetY) {
          if (smartAlignments.x[currentX]) {
            alignment.x.display = true;
            onOffsetChange(currentX, offsetY); // alignment.x.value
            setSmartAlignment({type: "x", value: currentX});
          } else {
            alignment.x.display = false;
            setSmartAlignment({type: "x", value: null});
          }
        }

        // if uncomment, dragging while horizontal line is showing and then later intercepted with vertical is fine
        // but the contrary is getting the bump
        // if (!alignment.y.display) {
        if (
          currentX > alignment.x.value - dragXWithinRange && // 160
          currentX < alignment.x.value + dragXWithinRange  &&
          subtype !== "line"
        ) {
          if (alignment.x.value - dragXWithinRange > 0) {
            if (!alignment.y.display) {
              // const yValue = handleAlignmentY(currentY, dragYWithinRange, alignment, size, orgFieldPos);
              // setSmartAlignment({type: "y", value: alignment.y.value});

              onOffsetChange(alignment.x.value, currentY); // 
              setSmartAlignment({type: "x", value: alignment.x.value}); //  alignment.x.value
            }
            alignment.y.display = false;
            setSmartAlignment({type: "y", value: null});
            hideHorizontalAlignment(alignment.x.value);
            return;
          }
        } else if (
          currentX + size.w > alignment.x.value - dragXWithinRange && // 160
          currentX + size.w < alignment.x.value + dragXWithinRange &&
          subtype !== "line"
          // &&
          // alignment.x.type !== "field-centred-template"
        ) {
          if (!alignment.y.display) {
            onOffsetChange(alignment.x.value - size.w, currentY);
            setSmartAlignment({type: "x", value: alignment.x.value});
          }
          hideHorizontalAlignment(alignment.x.value - size.w);
          return;
        } else if (
          currentX + size.w / 2 > alignment.x.value - dragXWithinRange && // 160
          currentX + size.w / 2 < alignment.x.value + dragXWithinRange
        ) {
          if (!alignment.y.display) {
            // const yValue = handleAlignmentY(currentY, dragYWithinRange, alignment, size, orgFieldPos);
            // setSmartAlignment({type: "y", value: alignment.y.value});

            onOffsetChange(alignment.x.value - size.w / 2, currentY);
            setSmartAlignment({type: "x", value: alignment.x.value});
          }
          // if (smartAlignments.y[Math.round(currentY + (size.h / 2))] !== 'center') {
            hideHorizontalAlignment(alignment.x.value - (size.w / 2));
          // } 
          return;
        } else {
          setSmartAlignment({
            type: "x",
            value: null
          });
        }
      
        if (
          alignment.y.value &&
          currentY > alignment.y.value - dragYWithinRange &&
          currentY < alignment.y.value + dragYWithinRange && 
          subtype !== "line"
        ) {
          if (!alignment.x.display) {
            onOffsetChange(currentX, alignment.y.value);
            setSmartAlignment({type: "y", value: alignment.y.value});
          }
          hideVerticalAlignment(alignment.y.value);
          return;
        } else if (
          currentY + size.h > alignment.y.value - dragYWithinRange &&
          currentY + size.h < alignment.y.value + dragYWithinRange &&
          subtype !== "line"
         // && alignment.y.type !== "field-centred-template"
        ) {
          if (!alignment.x.display) {
            onOffsetChange(currentX, alignment.y.value - size.h);
            setSmartAlignment({type: "y", value: alignment.y.value});
          }
          hideVerticalAlignment(alignment.y.value - size.h);
          return;
        } else if (
          currentY + size.h / 2 > alignment.y.value - dragYWithinRange &&
          currentY + size.h / 2 < alignment.y.value + dragYWithinRange &&
          subtype !== "line" 
        ) {
          if (!alignment.x.display) {
            onOffsetChange(currentX, alignment.y.value - size.h / 2);
            setSmartAlignment({type: "y", value: alignment.y.value});
          }
          hideVerticalAlignment(alignment.y.value - size.h / 2);
          return;
        } else if (
          currentY + size.h / 2 > alignment.y.value - dragYWithinRange &&
          currentY + size.h / 2 < alignment.y.value + dragYWithinRange &&
          subtype === "line" && 
          (styles.rotate === 0 || styles.rotate === 360)
        ) {
          if (!alignment.x.display) {
            onOffsetChange(currentX, alignment.y.value - size.h / 2);
            setSmartAlignment({type: "y", value: alignment.y.value});
          }
          hideVerticalAlignment(alignment.y.value - size.h / 2);
          return;
        } 

        // disable alignment for line with rotation degree
        if (type === "shape" && subtype === "line") {
          if (styles?.rotate === 0 || styles?.rotate === 360 || styles?.rotate === 90 || styles?.rotate === 180) {}
          else {
            alignment.x.value = null;
            alignment.x.display = false;
            alignment.y.value = null;
            alignment.y.display = false;
          }
        }

        onOffsetChange(currentX, currentY);

        alignment.x.display = false;
        setSmartAlignment({type: "x", value: null});

        alignment.y.display = false;
        setSmartAlignment({type: "y", value: null});
      }
      // if (!groupSelect.status) {
      //   onOffsetChange(currentX, currentY);
      // }
      // e.stopPropagation();
    }
  };

  const onDragEnd = async (e) => {
    await draggingField({dragging: false, orig: {}});
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      activeSlide &&
      !dragging.status &&
      Object.keys(selectedProps).length > 0
    ) {
      const payload = {type: null, value: selectedProps};
      storeFieldUndoRedo();
      // to emit collaborator status, cannot replace with updateFieldCollab
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "position-changed", item: payload});
      }
    }
  }, [dragging.status, activeSlide]);

  /* RESIZE SECTION */
  const onResizeStart = (e, field, dir) => {
    if(Object.keys(selectedProps)?.length === 0) {
      return
    }
    const {size, pos} = field || {};
    const offset = {x: pos?.x, y: pos?.y};
    if (activeField !== -1) {
      const clientX = getItemXOffset(e, tempScale);
      const clientY = getItemYOffset(e, tempScale);
      if (field?.type !== "image" && (field.type === "embed" || field?.type !== "video")) {
        resizingField({
          orig: {
            mouseX: Math.round(clientX),
            mouseY: Math.round(clientY),
          },
          resizing: true,
          direction: dir,
        });
        if (
          field?.type === "text" &&
          field?.styles?.whiteSpace === "pre" &&
          (dir === "left" || dir === "right")
        ) {
          onUpdateStyles("whiteSpace", "pre-wrap");
        }
      } else if (field?.type === "image" || (field.type === "embed" || field?.type === "video")) {
        const {w2: innerWidth, h2: innerHeight} = size || {};
        resizingField({
          orig: {
            mouseX: Math.round(clientX),
            mouseY: Math.round(clientY),
            outerWidth: size?.w,
            outerHeight: size?.h,
            initialX: offset?.x,
            initialY: offset?.y,
            innerWidth,
            innerHeight,
          },
          resizing: true,
          direction: dir,
        });
        setOrgImgPos({
          x: Math.round(field?.imgPos?.x),
          y: Math.round(field?.imgPos?.y),
        });
      }

      toggleTooltipPopper(false);

      if (field?.type === "table") {
        dispatchInitCellSizes();
      }
      storeFieldUndoRedo();
      e.stopPropagation();
    }
  };

  // For shapes, icons, charts, table
  const onResize = (e) => {
    if(Object.keys(selectedProps).length === 0) {
      return
    }
    e.stopImmediatePropagation();
    const {styles, pos, size, type, subtype} = selectedProps || {};
    if (type !== "table") {
      if (
        type === "chart" &&
        subtype !== "pie" &&
        subtype !== "donut" &&
        subtype !== "gauge"
      ) {
        const {centerX, centerY, width, height, rotate} = calcOnResize({
          e,
          size,
          pos,
          initialProps: resizing,
          tempScale,
          rotate: styles?.rotate,
          direction: resizing.direction,
          proportion: false, // <- difference here
        });

        const resize = centerToTL({centerX, centerY, width, height, rotate});
        const scale = Math.min(width / size.w2, height / size.h2);

        if (
          scale < styles.scale &&
          (resizing.direction === "left" || resizing.direction === "right")
        ) {
          onSizeChange(resize.width, resize.height);
        } else {
          onSizeChange(resize.width, resize.height);
        }
        //  resize.width / styles.scale, size.h2, styles.scale;
        onOffsetChange(resize.left, resize.top);
        handleAlignment({
          offset: resize,
          size: {w: resize.width, h: resize.height},
          direction: resizing.direction,
        });
      } else if (type === "chart") {
        const {centerX, centerY, width, height, rotate} = calcOnResize({
          e,
          size,
          pos,
          initialProps: resizing,
          tempScale,
          rotate: styles.rotate,
          direction: resizing.direction,
        });
        const resize = centerToTL({
          centerX,
          centerY,
          width,
          height,
          rotate,
        });

        const scale = Math.min(width / size.w2, height / size.h2);
        // remove size.w2, h2 scale later
        onSizeChange(resize.width, resize.height, size.w2, size.h2, scale);
        onOffsetChange(resize.left, resize.top);
        handleAlignment({
          offset: resize,
          size: {w: resize.width, h: resize.height},
          direction: resizing.direction,
        });
      } else {
        const {centerX, centerY, width, height, rotate} = calcOnResize({
          e,
          size,
          pos,
          initialProps: resizing,
          tempScale,
          rotate: styles.rotate,
          direction: resizing.direction,
        });
        const resize = centerToTL({
          centerX,
          centerY,
          width,
          height,
          rotate,
        });
        if (subtype !== "line") {
          onSizeChange(resize.width, resize.height);
          onOffsetChange(resize.left, resize.top);
          handleAlignment({
            offset: resize,
            size: {w: resize.width, h: resize.height},
            direction: resizing.direction,
          });
        } else {
          const clientX = getItemXOffset(e, tempScale);
          const clientY = getItemYOffset(e, tempScale);
          // not working
          // const {orig} = resizing;
          // const scaledClientX = getItemXOffset(e, tempScale);
          // const scaledClientY = getItemYOffset(e, tempScale);
          // const clientX = Math.round(scaledClientX - orig.mouseX);
          // const clientY = Math.round(scaledClientY - orig.mouseY);

          const adjustedXOffset =
            window.innerWidth >= 850
              ? Math.round(canvasWidth - tempSize.w * tempScale) / 2
              : 0;
          const endPoint = {
            x: pos.x + size.w,
            y: pos.y,
          };
          const {newStartPoint, newEndPoint} = calcShapeLineOffsets(
            pos,
            endPoint,
            styles.rotate
          );
          const {
            topLeft, topRight, btmRight, btmLeft
          } = calcRotatedOffsets(pos, size, styles.rotate)

          const slideListPanelWidth =
            window.innerWidth >= 850 ? sidebarWidth : canvasAreaPadding;

          const fromTopBorderEdgeToCanvas = canvasPaddingTop + mobTopPanel;
          if (resizing.direction === "left") {
            const {
              x,
              y,
              distance,
              angle: updatedAngle,
            } = drawLine(
              clientX - (adjustedXOffset + slideListPanelWidth) / tempScale, // resizing.orig.mouseX,
              clientY - fromTopBorderEdgeToCanvas / tempScale,
              newEndPoint.x,
              newEndPoint.y
            );
            const rotatedPoints = {
              topLeft, topRight, btmRight, btmLeft
            };

            onOffsetChange(x, y, rotatedPoints);
            onSizeChange(distance, size?.h);
            onUpdateStyles("rotate", Math.round(updatedAngle));
            if(updatedAngle === 0 || updatedAngle === 360 || updatedAngle === 90 || updatedAngle === 180) {
              handleAlignment({
                offset: {x, y},
                size: {w: distance, h: size?.h},
                direction: resizing.direction,
              });
            }
          } else {
            const {
              x,
              y,
              distance,
              angle: updatedAngle,
            } = drawLine(
              newStartPoint.x,
              newStartPoint.y,
              clientX - (adjustedXOffset + slideListPanelWidth) / tempScale, // resizing.orig.mouseX,
              clientY - fromTopBorderEdgeToCanvas / tempScale
            );
            const rotatedPoints = {
              topLeft, topRight, btmLeft, btmRight,
            };
            onOffsetChange(x, y, rotatedPoints);
            onSizeChange(distance, size?.h);
            onUpdateStyles("rotate", Math.round(updatedAngle));
            if(updatedAngle === 0 || updatedAngle === 360 || updatedAngle === 90 || updatedAngle === 180) {
              handleAlignment({
                offset: {x, y},
                size: {w: distance, h: size.h},
                direction: resizing.direction,
              });
            }
          }
        }
      }
    } else if (type === "table") {
      const {centerX, centerY, width, height, rotate} = calcOnResize({
        e,
        size,
        pos,
        initialProps: resizing,
        tempScale,
        rotate: styles?.rotate,
        direction: resizing.direction,
        proportion: false,
      });
      const resize = centerToTL({centerX, centerY, width, height, rotate});
      resizeTableActions({
        newTableSize: {
          w: resize?.width,
          h: resize?.height,
        },
      });
      onOffsetChange(resize?.left, resize?.top);
      handleAlignment({
        offset: resize,
        size: {w: resize?.width, h: resize?.height},
        direction: resizing.direction,
      });
    }
  };

  const handleAlignment = ({offset, size, direction}) => {
    const {left, top} = offset || {};
    if (
      smartAlignments.x[left] &&
      !alignment.x.display &&
      (direction === "top-left" ||
        direction === "left" ||
        direction === "bottom-left")
    ) {
      alignment.x.value = left;
      alignment.x.display = true;
      setSmartAlignment({type: "x", value: left});
    } else if (
      // only show if right has been dragged
      (direction === "top-right" ||
        direction === "right" ||
        direction === "bottom-right") &&
      smartAlignments.x[Math.round(left + size?.w)] &&
      !alignment.x.display
    ) {
      alignment.x.value = left + size?.w;
      alignment.x.display = true;
      setSmartAlignment({type: "x", value: left + size?.w});
    } else if (
      smartAlignments.x[Math.round(left + size?.w / 2)] &&
      !alignment.x.display &&
      direction === "" // leave blank for center
    ) {
      alignment.x.value = left + size.w / 2;
      alignment.x.display = true;
      setSmartAlignment({type: "x", value: left + size?.w / 2});
    } else {
      alignment.x.value = null;
      alignment.x.display = false;
      setSmartAlignment({type: "x", value: null});
    }

    if (
      smartAlignments.y[top] &&
      !alignment.y.display &&
      (direction === "top-left" ||
        direction === "top" ||
        direction === "top-right")
    ) {
      alignment.y.value = top;
      alignment.y.display = true;
      setSmartAlignment({type: "y", value: top});
    } else if (
      (direction === "bottom-right" ||
        direction === "bottom" ||
        direction === "bottom-left") &&
      smartAlignments.y[Math.round(top + size?.h)] &&
      !alignment.y.display
    ) {
      alignment.y.value = top + size.h;
      alignment.y.display = true;
      setSmartAlignment({type: "y", value: top + size?.h});
    } else if (
      smartAlignments.y[Math.round(top + size.h / 2)] &&
      !alignment.y.display &&
      direction === ""
    ) {
      alignment.y.value = top + size.h / 2;
      alignment.y.display = true;
      setSmartAlignment({type: "y", value: top + size?.h / 2});
    } else {
      alignment.y.value = null;
      alignment.y.display = false;
      setSmartAlignment({type: "y", value: null});
    }
  };

  const onResizeText = (e) => {
    if(Object.keys(selectedProps).length === 0) {
      return
    }
    e.stopImmediatePropagation();
    const {styles, pos, size} = selectedProps || {};
    // const childText = fieldRef.children;
    // let combiedTextHeight = 0;
    // for (let i = 0; i < fieldRef.children.length; i++) {
    //   combiedTextHeight = combiedTextHeight + childText.item(i).offsetHeight;
    // }

    const {centerX, centerY, width, height, rotate} = calcOnResize({
      e,
      size,
      // to fix text moving on drag while being rotate is here
      //  {
      //   w: size.w,
      //   h: size.h + (combiedTextHeight - size.h),
      //   // size.h < combiedTextHeight * styles.scale
      //   //   ? size.h + (combiedTextHeight * styles.scale - size.h)
      //   //   : size.h,
      // },
      pos,
      initialProps: resizing,
      tempScale,
      rotate: styles?.rotate,
      direction: resizing.direction,
      whiteSpacePre: styles?.whiteSpace === "pre" ? true : false,
      controlResizeSpeed: size?.w / size?.h > 4 && 
        resizing.direction !== 'left' && 
        resizing.direction !== 'right' ? true : false,
    });
    const resize = centerToTL({centerX, centerY, width, height, rotate});
    const scale = Math.min(width / size?.w2, height / size?.h2);
    const {direction} = resizing;

    if (direction !== "right" && direction !== "left") {
      onOffsetChange(resize.left, resize.top);
      onSizeChange(resize.width, resize.height, size.w2, size.h2, scale);
      handleAlignment({
        offset: resize,
        size: {
          w: resize.width,
          h: resize.height,
        },
        direction,
      });
    } else {
      const updateFieldWidth = resize.width / styles.scale;
      onOffsetChange(resize.left, resize.top);

      const childText = fieldRef.children;
      let combiedTextHeight = 0;
      for (let i = 0; i < fieldRef.children.length; i++) {
        combiedTextHeight = combiedTextHeight + childText.item(i).offsetHeight;
      }
      // if (styles.rotate === 0 || styles.rotate === 360) {
      onSizeChange(
        resize.width,
        combiedTextHeight * styles?.scale,
        updateFieldWidth,
        combiedTextHeight,
        styles.scale
      );

      // this will not move rotated text while being resized
      // } else {
      //   onSizeChange(
      //     resize.width,
      //     resize.height, //combiedTextHeight * styles.scale,
      //     updateFieldWidth,
      //     combiedTextHeight, // combiedTextHeight, //fieldRef.offsetHeight,
      //     styles.scale
      //   );
      // }

      handleAlignment({
        offset: resize,
        size: {w: resize?.width, h: resize?.height},
        direction,
      });
    }
  };

  const onResizeImg = (e) => {
    if(Object.keys(selectedProps)?.length === 0) {
      return
    }
    e.stopImmediatePropagation(); // necessary for mobile
    const {styles, pos, size, imgPos} = selectedProps || {};
    const {orig, direction} = resizing || {};
    const {centerX, centerY, width, height, rotate, deltaX} = calcOnResize({
      e,
      size,
      pos,
      initialProps: resizing,
      tempScale,
      rotate: styles.rotate,
      direction: direction,
    });
    const resize = centerToTL({centerX, centerY, width, height, rotate});
    const active = {slideID: slideIndex, fieldID: activeField};
    if (
      direction === "bottom-right" ||
      direction === "bottom-left" ||
      direction === "top-right" ||
      direction === "top-left"
    ) {
      const imgWidth = (orig.innerWidth * resize.width) / orig.outerWidth;
      const imgHeight = (orig.innerHeight * resize.height) / orig.outerHeight;
      const imgOffset = {
        x: (imgWidth * imgPos.x) / orig.innerWidth,
        y: (imgHeight * imgPos.y) / orig.innerHeight,
      };
      transformImgOffset(active, imgOffset);
      onOffsetChange(resize.left, resize.top);
      onSizeChange(
        resize.width,
        resize.height,
        imgWidth,
        imgHeight,
        styles.scale
      );
    } else if (resizing.direction === "left") {
      const calcXOffset = imgPos.x - (orig.outerWidth - resize.width);
      if (calcXOffset > 0) {
        const imgWidth =
          (orig.innerWidth * resize.width) / (orig.outerWidth - orgImgPos.x);
        const imgHeight = (imgWidth * orig.innerHeight) / orig.innerWidth;
        const splitImgHeight = (size.h2 - imgHeight) / 2;
        const imgOffset = {
          x: 0,
          y: orgImgPos.y + splitImgHeight,
        };
        transformImgOffset(active, imgOffset);
        onOffsetChange(resize.left, resize.top);
        onSizeChange(
          resize.width,
          resize.height,
          imgWidth,
          imgHeight,
          styles.scale
        );
      } else {
        const imgOffset = {
          x: calcXOffset,
          y: imgPos.y,
        };
        transformImgOffset(active, imgOffset);
        onOffsetChange(resize.left, resize.top);
        onSizeChange(
          resize.width,
          resize.height,
          size.w2,
          size.h2,
          styles.scale
        );
      }
    } else if (resizing.direction === "right") {
      if (resize.width - orgImgPos.x > orig.innerWidth) {
        const calcOuterWidth =
          resize.width - orgImgPos.x <= orig.innerWidth
            ? orig.outerWidth + deltaX
            : orig.innerWidth + orgImgPos.x;
        const imgWidth = (orig.innerWidth * resize.width) / calcOuterWidth;
        const imgHeight = (imgWidth * orig.innerHeight) / orig.innerWidth;
        const splitImgHeight = (size.h2 - imgHeight) / 2;
        const imgOffset = {
          x: (imgWidth * orgImgPos.x) / orig.innerWidth,
          y: orgImgPos.y + splitImgHeight,
        };
        transformImgOffset(active, imgOffset);
        onOffsetChange(resize.left, resize.top);
        onSizeChange(
          resize.width,
          resize.height,
          imgWidth,
          imgHeight,
          styles.scale
        );
      } else {
        const imgOffset = {
          x: imgPos.x,
          y: imgPos.y,
        };
        transformImgOffset(active, imgOffset);
        onOffsetChange(resize?.left, resize?.top);
        onSizeChange(
          resize?.width,
          resize?.height,
          size?.w2,
          size?.h2,
          styles?.scale
        );
      }
    } else if (resizing.direction === "bottom") {
      if (resize.height - orgImgPos.y > orig.innerHeight) {
        const calcOuterWidth =
          height - orgImgPos?.y <= orig?.innerHeight
            ? orig.outerHeight + deltaX
            : size?.h2 + orgImgPos.y;
        const imgHeight = (size?.h2 * height) / calcOuterWidth;
        const imgWidth = (imgHeight * orig.innerWidth) / size?.h2;
        onOffsetChange(resize?.left, resize?.top);
        onSizeChange(
          resize?.width,
          resize?.height,
          imgWidth,
          imgHeight,
          styles?.scale
        );
        const splitImgWidth = (orig.innerWidth - imgWidth) / 2;
        const imgOffset = {
          x: orgImgPos?.x + splitImgWidth,
          y: (imgHeight * orgImgPos.y) / size?.h2,
        };
        transformImgOffset(active, imgOffset);
      } else {
        const imgOffset = {x: imgPos?.x, y: imgPos?.y};
        transformImgOffset(active, imgOffset);
        onOffsetChange(resize?.left, resize?.top);
        onSizeChange(
          resize?.width,
          resize?.height,
          size?.w2,
          size?.h2,
          styles?.scale
        );
      }
    } else if (resizing.direction === "top") {
      const calcYOffset = imgPos?.y - (orig.outerHeight - resize.height);
      if (calcYOffset > 0) {
        const imgHeight =
          (orig.innerHeight * resize?.height) / (orig.outerHeight - orgImgPos.y);
        const imgWidth = (imgHeight * orig.innerWidth) / size?.h2;
        const splitImgWidth = (orig.innerWidth - imgWidth) / 2;
        const imgOffset = {x: imgPos.x + splitImgWidth, y: 0};
        transformImgOffset(active, imgOffset);
        onOffsetChange(resize?.left, resize?.top);
        onSizeChange(
          resize?.width,
          resize?.height,
          imgWidth,
          imgHeight,
          styles?.scale
        );
      } else {
        const imgOffset = {x: imgPos?.x, y: calcYOffset};
        transformImgOffset(active, imgOffset);
        onOffsetChange(resize.left, resize.top);
        onSizeChange(
          resize.width,
          resize.height,
          size?.w2,
          size?.h2,
          styles?.scale
        );
      }
    }
    if (croppingImage === false) {
      handleAlignment({
        offset: resize,
        size: {w: resize.width, h: resize.height},
        direction,
      });
    }
  };

  const onResizeEnd = (e) => {
    if (
      selectedProps?.type === "text" &&
      (resizing.direction === "right" || resizing.direction === "left")
    ) {
      const childText = fieldRef.children;
      let combiedTextHeight = 0;
      let longestTextWidth = 0;
      for (let i = 0; i < fieldRef.children.length; i++) {
        longestTextWidth = childText.item(i).offsetWidth;
        combiedTextHeight = combiedTextHeight + childText.item(i).offsetHeight;
      }
      onSizeChange(
        longestTextWidth * selectedProps?.styles?.scale,
        combiedTextHeight * selectedProps?.styles?.scale, //combiedTextHeight * styles.scale,
        longestTextWidth,
        combiedTextHeight, // combiedTextHeight, //fieldRef.offsetHeight,
        selectedProps?.styles.scale
      );
    }
    if (selectedProps?.type === "table") {
      removeInitCellSizes();
    }
    resizingField({resizing: false});
    storeFieldUndoRedo();
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      activeSlide &&
      !resizing.status &&
      Object.keys(selectedProps)?.length > 0
    ) {
      const {size, styles, pos, type} = selectedProps || {};
      if (type !== "image") {
        // storeFieldUndoRedo();
        updateFieldCollaboration();
        // emitSocketEvents({actionType: "size-changed", item: payload});
      } else if (type === "image") {
        // storeFieldUndoRedo();
        updateFieldCollaboration();
        // emitSocketEvents({actionType: "size-changed", item: payload});
      }
    }
  }, [resizing.status, activeSlide]);

  // ROTATE SECTION
  const [rotate, setRotate] = useState({
    rotating: false,
    center: {},
    startVector: {},
  });
  const onRotateStart = (e) => {
    if(Object.keys(selectedProps).length === 0) {
      return
    }
    const clientX = getItemXOffset(e, tempScale);
    const clientY = getItemYOffset(e, tempScale);
    const itemRect = fieldRef.getBoundingClientRect();
    const {size} = selectedProps || {};
    const rect = {
      top: itemRect.top,
      left: itemRect.left,
    };
    const center = {
      x: rect.left / tempScale + size?.w / 2,
      y: rect.top / tempScale + size?.h / 2,
    };
    const startPt = {
      x: clientX - center?.x,
      y: clientY - center?.y,
    };
    setRotate({
      rotating: true,
      center,
      startPt,
    });
    storeFieldUndoRedo();
    e.stopPropagation();
  };

  const onRotate = (e) => {
    if(Object.keys(selectedProps).length === 0) {
      return
    }
    e.stopImmediatePropagation();
    const {rotating, startPt, center} = rotate || {};
    if (rotating) {
      const clientX = getItemXOffset(e, tempScale);
      const clientY = getItemYOffset(e, tempScale);
      const rotateVector = {
        x: clientX - center?.x,
        y: clientY - center?.y,
      };
      let rotatedAngle = getAngle(startPt, rotateVector);
      let angle = Math.round(rotatedAngle + selectedProps?.styles?.rotate);
      if (angle >= 360) {
        angle -= 360;
      } else if (angle < 0) {
        angle += 360;
      }
      if (angle > 356 || angle < 4) {
        angle = 0;
      } else if (angle > 86 && angle < 94) {
        angle = 90;
      } else if (angle > 176 && angle < 184) {
        angle = 180;
      } else if (angle > 266 && angle < 274) {
        angle = 270;
      }
      onUpdateStyles("rotate", Math.round(angle));
    }
  };

  const onRotateStop = (e) => {
    setRotate({
      ...rotate,
      rotating: false,
    });
    e.stopPropagation();
  };

  useEffect(() => {
    if (!rotate.rotating && Object.keys(selectedProps)?.length > 0) {
      storeFieldUndoRedo();
      updateFieldCollaboration();
    }
  }, [rotate.rotating]);

  useEffect(() => {
    if (activeSlide && dragging.status && window && groupSelect.keys.length === 0) {
      window.addEventListener("touchmove", onDrag);
      window.addEventListener("touchend", onDragEnd);
      window.addEventListener("mouseup", onDragEnd);
      window.addEventListener("mousemove", onDrag);
      window.addEventListener("mouseleave", onDragEnd);
    }
    return () => {
      window.removeEventListener("touchmove", onDrag);
      window.removeEventListener("touchend", onDragEnd);
      window.removeEventListener("mouseup", onDragEnd);
      window.removeEventListener("mousemove", onDrag);
      window.removeEventListener("mouseleave", onDragEnd);
    };
  }, [dragging.status, activeSlide, groupSelect.keys]);

  useEffect(() => {
    if (activeSlide && resizing.status && window && !croppingImage && groupSelect.keys.length === 0) {
      const {type} = selectedProps || {};
      if (type === "text") {
        window.addEventListener("touchmove", onResizeText);
        window.addEventListener("mousemove", onResizeText);
      } else if (type === "image" || (type === "embed" || type === "video")) {
        window.addEventListener("touchmove", onResizeImg);
        window.addEventListener("mousemove", onResizeImg);
      } else {
        window.addEventListener("touchmove", onResize);
        window.addEventListener("mousemove", onResize);
      }
      window.addEventListener("touchend", onResizeEnd);
      window.addEventListener("mouseup", onResizeEnd);
      window.addEventListener("mouseleave", onResizeEnd);
    }
    return () => {
      window.removeEventListener("touchend", onResizeEnd);
      window.removeEventListener("mouseup", onResizeEnd);
      window.removeEventListener("mouseleave", onResizeEnd);

      window.removeEventListener("touchmove", onResize);
      window.removeEventListener("mousemove", onResize);

      window.removeEventListener("touchmove", onResizeText);
      window.removeEventListener("mousemove", onResizeText);

      window.removeEventListener("touchmove", onResizeImg);
      window.removeEventListener("mousemove", onResizeImg);
    };
  }, [resizing.status, activeSlide, groupSelect.keys]);

  useEffect(() => {
    if (activeSlide && rotate.rotating === true && window && groupSelect.keys.length === 0) {
      window.addEventListener("touchmove", onRotate);
      window.addEventListener("touchend", onRotateStop);
      window.addEventListener("mouseup", onRotateStop);
      window.addEventListener("mousemove", onRotate);
      window.addEventListener("mouseleave", onRotateStop);
    }
    return () => {
      window.removeEventListener("touchmove", onRotate);
      window.removeEventListener("touchend", onRotateStop);
      window.removeEventListener("mouseup", onRotateStop);
      window.removeEventListener("mousemove", onRotate);
      window.removeEventListener("mouseleave", onRotateStop);
    };
  }, [rotate.rotating, activeSlide, groupSelect.keys]);

  const prevValue = usePrevious(selectedProps);
  const prevActiveField = usePrevious(activeField);

  useEffect(() => {
    // no callback here
    if (activeSlide) {
      const updateAutoHeight = async (size, styles, pos) => {
        // const newHeight = fieldRef.offsetHeight * styles.scale;

        const childText = fieldRef.children;
        let combiedTextHeight = 0;
        for (let i = 0; i < fieldRef.children.length; i++) {
          combiedTextHeight =
            combiedTextHeight + childText.item(i).offsetHeight;
        }

        await Promise.all([
          onSizeChange(
            size?.w,
            combiedTextHeight * styles?.scale, //newHeight,
            size?.w2,
            combiedTextHeight, // "auto",
            styles?.scale,
            pos?.x,
            pos?.y
          ),
        ]);
        await adjustFieldSize();
      };

      const updateHeightForPreText = async (size, styles, pos) => {
        const childText = fieldRef.children;
        let combiedTextHeight = 0;
        let longestWidth = 0;
        for (let i = 0; i < fieldRef.children.length; i++) {
          longestWidth =
            longestWidth > childText.item(i).scrollWidth
              ? longestWidth
              : childText.item(i).scrollWidth;
          combiedTextHeight =
            combiedTextHeight + childText.item(i).scrollHeight;
        }
        await Promise.all([
          onSizeChange(
            longestWidth * styles?.scale,
            combiedTextHeight * styles?.scale,
            longestWidth,
            combiedTextHeight,
            styles?.scale,
            pos?.x,
            pos?.y
          ),
        ]);
        await adjustFieldSize();
      };

      if (
        selectedProps !== undefined &&
        prevValue !== undefined &&
        selectedProps?.type === "text" &&
        prevActiveField === activeField
      ) {
        const {styles, pos, size, content} = selectedProps || {};
        if (
          prevValue?.styles?.lineHeight !== styles?.lineHeight ||
          prevValue?.styles?.fontWeight !== styles?.fontWeight ||
          prevValue?.styles?.fontFamily !== styles?.fontFamily
        ) {
          updateAutoHeight(size, styles, pos);
        }
        if (prevValue?.styles?.letterSpacing !== styles?.letterSpacing) {
          if (
            prevValue?.styles?.letterSpacing > styles?.letterSpacing &&
            styles?.whiteSpace === "pre"
          ) {
            updateHeightForPreText(size, styles, pos);
          } else {
            updateAutoHeight(size, styles, pos);
          }
        }

        if (fieldRef && prevValue.styles.fontSize !== styles.fontSize) {
          if (styles.whiteSpace === "pre" && !textEditStatus) {
            // when user changes font size from big to small (transforming from longer width to shorter one)
            // we want to bring down the width size as well to fit its small size
            // for that "auto" has been applied and then update its numerical value
            onSizeChange(
              "auto",
              "auto",
              "auto",
              "auto",
              selectedProps?.styles.scale,
              selectedProps?.pos.x,
              selectedProps?.pos.y
            );
            setTimeout(() => {
              const childText = fieldRef.children;
              let combiedTextHeight = 0;
              let longestWidth = 0;
              for (let i = 0; i < fieldRef.children.length; i++) {
                longestWidth =
                  longestWidth < childText.item(i).scrollWidth
                    ? childText.item(i).scrollWidth
                    : longestWidth;
                combiedTextHeight =
                  combiedTextHeight + childText.item(i).scrollHeight;
              }
              onSizeChange(
                longestWidth * selectedProps?.styles?.scale + 20, // warning here
                combiedTextHeight * selectedProps?.styles?.scale,
                longestWidth,
                combiedTextHeight,
                selectedProps?.styles?.scale,
                selectedProps?.pos?.x,
                selectedProps?.pos?.y
              );
            }, 1);
          } else {
            updateAutoHeight(size, styles, pos);
          }
        }
      }
    }
  }, [selectedProps, activeSlide, selectedProps?.styles?.fontFamily]);

  // readjust text height after editing is done
  // to remove extra spaces from scale height
  // cannot be mixed with the above hook
  useEffect(() => {
    if (
      activeSlide &&
      selectedProps !== undefined &&
      prevValue !== undefined &&
      selectedProps?.type === "text" && 
      !textEditStatus 
    ) {
      const {styles} = selectedProps || {};
      const childText = fieldRef.children;
      let combiedTextHeight = 0;
      for (let i = 0; i < fieldRef.children.length; i++) {
        combiedTextHeight = combiedTextHeight + childText.item(i).offsetHeight; // used to be scrollHeight
      }
      // const extraSpace = 12 * styles.scale;
      onSizeChange(
        fieldRef.scrollWidth * styles.scale, // + extraSpace, // size.w,
        combiedTextHeight * styles.scale,
        fieldRef.scrollWidth, // size.w2,
        combiedTextHeight,
        selectedProps?.styles?.scale,
        selectedProps?.pos?.x,
        selectedProps?.pos?.y
      );
      if (selectedProps?.key && selectedProps?.type === 'text') {
        // on select field to adjust height properly in italic, font weight change
        onSelectField(selectedProps?.key, selectedProps?.type);
      }
    } 
  }, [
    textEditStatus, 
    activeField, 
    activeSlide, 
    selectedProps?.styles?.fontStyle, 
    selectedProps?.styles?.fontWeight,
    selectedProps?.styles?.fontFamily,
  ]);
  //activeField - for readjusting lineheight & letterspacing values from a theme after creation

  const adjustFieldSize = async () => {
    const field = selectedProps || {};
    const {size, styles, pos} = field || {};
    const childText = fieldRef.children;
    let combiedTextHeight = 0;
    let longestWidth = 0;
    for (let i = 0; i < fieldRef.children.length; i++) {
      longestWidth =
        longestWidth > childText.item(i).scrollWidth
          ? longestWidth
          : childText.item(i).scrollWidth;
      combiedTextHeight = combiedTextHeight + childText.item(i).scrollHeight;
    }
    if (styles.whiteSpace !== "pre") {
      await onSizeChange(
        size?.w,
        combiedTextHeight !== 0 ? combiedTextHeight * styles?.scale : size.h,
        size?.w2,
        combiedTextHeight !== 0 ? combiedTextHeight : size?.h2,
        styles.scale,
        pos?.x,
        pos?.y
      );
      debounceSocketTextUpdate();
    } else {
      await onSizeChange(
        fieldRef.scrollWidth * styles?.scale,
        combiedTextHeight !== 0 ? combiedTextHeight * styles.scale : size.h,
        fieldRef.scrollWidth,
        combiedTextHeight !== 0 ? combiedTextHeight : size.h2,
        styles.scale,
        pos.x,
        pos.y
      );
      debounceSocketTextUpdate();
    }
    // if (!textEditStatus) {
    //   storeFieldUndoRedo();
    // }
  };

  const debounceSocketTextUpdate = () => {
    clearTimeout(delayDebounce);
    delayDebounce = setTimeout(() => {
      updateFieldCollaboration();
    }, 5000);
    return () => clearTimeout(delayDebounce);
  };

  const handleEditingText = (e) => {
    const {size, styles, pos, type} = selectedProps || {};
    const {scale, whiteSpace} = styles || {};
    // check if exceeds the template's boundary width when hit enter button
    const exceedsTempWidth = pos?.x + fieldRef.scrollWidth * scale;
    // check if exceeds the template's boundary height when hit enter button
    const exceedsTempHeight = pos?.y + fieldRef.scrollHeight * scale;
    if (textEditStatus && type === "text") {
      const active = {slideIndex, fieldID: activeField};
      let textContent = e.target.innerHTML;
      // remove <br> tag in firefox
      if (isFirefox) {
        let lastChild = fieldRef?.lastElementChild;

        const repositionCursor = (newTag) => {
          // Move the cursor to the end of the newly created <p> tag
          const range = document.createRange();
          const selection = window.getSelection();
          range.selectNodeContents(newTag);
          range.collapse(false); // Collapse the range to the end
          selection.removeAllRanges();
          selection.addRange(range);
        }

        // case for when making a selection to one particular li and then insert a text
        if (lastChild?.lastChild?.outerHTML === "" || lastChild?.lastChild?.outerHTML === null){
          lastChild.lastChild.innerHTML = '';  
          const newTag = document.createElement("span");
          newTag.textContent = e.nativeEvent.data ? e.nativeEvent.data : '';
          fieldRef.lastChild.lastChild.appendChild(newTag);
          textContent = fieldRef.lastChild.outerHTML
          repositionCursor(newTag);
        }
        // To prevent spans and p tags from being removed,
        // when user make a whole selection, 
        // and then remove all inputs or insert new input (ul/li).  
        else if (lastChild?.lastChild?.innerHTML == "<br>") {
          lastChild.lastChild.innerHTML = '';  
          const newTag = document.createElement("span");
          newTag.textContent = e.nativeEvent.data ? e.nativeEvent.data : '';
          fieldRef.lastChild.lastChild.appendChild(newTag);
          repositionCursor(newTag);
          // const sanitizedContent = sanitizeInput(fieldRef.lastChild.outerHTML);
          // updateTextContent(active, sanitizedContent);
        }
        // To prevent spans and p tags from being removed,
        // when user make a whole selection, 
        // and then remove all inputs or insert new input (p). 
        else if (lastChild ===  null) {
          fieldRef.innerHTML = '';
          const eleTag = fieldRef?.lastChild?.tagName ? fieldRef.lastChild.tagName : "p";
          const newTag = document.createElement(eleTag);
          newTag.textContent = textContent;
          fieldRef.appendChild(newTag);
          // lastChild = fieldRef.lastElementChild;

          textContent = fieldRef.lastChild.outerHTML;
          repositionCursor(newTag);
        } 
        // for li list
        else if (lastChild?.lastChild && lastChild?.lastChild.tagName === "LI") {
          const lastChildLastChild =
            lastChild?.lastChild?.lastChild?.lastElementChild;
          if (lastChildLastChild && lastChildLastChild?.tagName === "BR") {
            lastChildLastChild.remove();
            const removeLastBrRegex = /(<br>)(?![\s\S]*<\/span>[\s\S]*<span>)/;
            const modifiedString = textContent.replace(removeLastBrRegex, "");
            textContent = modifiedString;
          }
        } else if (
          lastChild?.lastChild &&
          lastChild?.lastChild?.tagName === "BR"
        ) {
          const brElement = lastChild?.lastChild;
          const textNodeBeforeBr = brElement.previousSibling;
          const textNodeAfterBr = brElement.nextSibling;
          if (
            brElement.tagName === "BR" &&
            ((textNodeBeforeBr && textNodeBeforeBr.nodeName === "#text") ||
              (textNodeAfterBr && textNodeAfterBr.nodeName === "#text"))
          ) {
            brElement.remove();
            const removeLastBrRegex = /(<br>)(?![\s\S]*<\/p>[\s\S]*<p>)/;
            const modifiedString = textContent.replace(removeLastBrRegex, "");
            textContent = modifiedString;      
          }
        }
        const sanitizedContent = sanitizeInput(textContent);
        updateTextContent(active, sanitizedContent);
      } else {
        const sanitizedContent = sanitizeInput(textContent);
        updateTextContent(active, sanitizedContent);
      }

      const childText = fieldRef.children;
      let combiedTextHeight = 0;
      let longestTextWidth = 0;
      for (let i = 0; i < fieldRef.children.length; i++) {
        longestTextWidth = childText.item(i).offsetWidth;
        combiedTextHeight = combiedTextHeight + childText.item(i).offsetHeight;
      }

      if (exceedsTempHeight < tempSize.h && whiteSpace === "pre-wrap") {
        onSizeChange(
          size?.w,
          combiedTextHeight * scale, // fieldRef.scrollHeight * scale,
          size?.w2,
          combiedTextHeight,
          scale
        );
      } else if (exceedsTempHeight >= tempSize.h && whiteSpace === "pre-wrap") {
        // onOffsetChange(pos.x, pos.y - lineHeightToPx * scale);
        onSizeChange(
          size?.w,
          combiedTextHeight * scale,
          // fieldRef.scrollHeight * scale,
          size?.w2,
          combiedTextHeight,
          scale
        );
      } else if (exceedsTempWidth < tempSize.w && whiteSpace === "pre") {
        const extraSpace = 12 * scale;
        onSizeChange(
          fieldRef.scrollWidth * scale + extraSpace,
          size?.h,
          fieldRef.scrollWidth,
          size?.h2,
          scale
        );
      } else if (exceedsTempWidth >= tempSize.w && whiteSpace === "pre") {
        onUpdateStyles("whiteSpace", "pre-wrap");
        onSizeChange(
          size?.w,
          combiedTextHeight * scale,
          size?.w2,
          combiedTextHeight, 
          scale
        );
      }

      clearTimeout(delayDebounce);
      delayDebounce = setTimeout(() => {
        updateFieldCollaboration();
        storeFieldUndoRedo();
      }, 5000);
      return () => clearTimeout(delayDebounce);
    }
  };

  const detectShortcutKeys = useCallback(
    (event) => {
      if (event.keyCode == 13) {
        const {scale} = selectedProps?.styles || {};
        onUpdateStyles("whiteSpace", "pre-wrap");
        onSizeChange(
          fieldRef.scrollWidth * scale,
          fieldRef.scrollHeight * scale,
          fieldRef.scrollWidth,
          fieldRef.scrollHeight,
          scale
        );
      } else if (event.keyCode == 27) {
        // exit text edit
        enableTextEdit();
        // storeFieldUndoRedo();
      }
    },
    [selectedProps]
  );

  const exitTextEdit = useCallback((event) => {
    if (event.keyCode == 27) {
      enableTextEdit();
    }
  }, []);

  useEffect(() => {
    if (
      activeSlide &&
      fieldRef &&
      textEditStatus &&
      selectedProps?.styles?.whiteSpace === "pre" &&
      selectedProps?.type === "text"
    ) {
      document.addEventListener("keydown", detectShortcutKeys, false);
      return () => {
        document.removeEventListener("keydown", detectShortcutKeys, false);
      };
    } else if (fieldRef && textEditStatus && selectedProps?.type === "text") {
      document.addEventListener("keydown", exitTextEdit, false);
      return () => {
        document.removeEventListener("keydown", exitTextEdit, false);
      };
    }
  }, [textEditStatus, activeSlide]);

  const onSelectItem = (e, field) => {
    // if (isMobile || isTablet) {
    if (
      (e.type === "touchstart" ||
      e.type === "touchmove" ||
      e.type === "touchend") && groupSelect?.keys?.length === 0
    ) {
      onSelectField(field.key, field.type);
      const payload = {type: null, value: selectedProps};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "position-changed", item: payload});
      }
      e.stopPropagation(); // important for mobile drag & select
    }
  };

  const [textCursorToEnd, setTextCursorToEnd] = useState(false);
  useEffect(() => {
    let selection, range;
    // only for window width >= 850 !important
    if (
      activeSlide &&
      selectedProps?.type === "text" &&
      textEditStatus &&
      windowSize.width >= 850 &&
      !textCursorToEnd
    ) {
      setTextCursorToEnd(true);
      setTimeout(() => {
        if (fieldRef && document.createRange && window.getSelection) {
          const lastChild = fieldRef.lastElementChild;
          const lastTextNode = getLastTextNode(lastChild);
          selection = window.getSelection();

          if (
            // range.collapsed &&
            fieldRef &&
            fieldRef.lastElementChild &&
            (fieldRef.lastChild.tagName === "P" ||
              fieldRef.lastChild.tagName === "SPAN") &&
            fieldRef.lastChild.innerHTML === "<br>" //&&
            // range.startOffset === 0
          ) {
            const brNode = fieldRef.lastChild.querySelector("br");
            // const brNode = fieldRef.lastElementChild.lastChild;
            // const brNode = fieldRef.lastChild;
            brNode.remove();
            const newRange = document.createRange();
            const selection = window.getSelection();
            // const lastTextNode = fieldRef.lastChild.lastChild;

            newRange.selectNodeContents(fieldRef.lastChild);
            newRange.collapse(false);

            selection.removeAllRanges();
            selection.addRange(newRange);
          } else if (lastTextNode) {
            const range = document.createRange();
            const selection = window.getSelection();

            range.selectNodeContents(lastTextNode);
            range.collapse(false);

            selection.removeAllRanges();
            selection.addRange(range);
          }
        } else if (fieldRef && document.selection) {
          range = document.body.createTextRange();
          range.moveToElementText(fieldRef);
          range.collapse(false);
          range.select();
        }
      }, 50);
    }
  }, [textEditStatus, selectedProps?.type, textCursorToEnd, activeSlide]);

  // emit when text editing has done
  useEffect(() => {
    if (
      activeSlide &&
      selectedProps !== undefined &&
      prevValue !== undefined &&
      !textEditStatus
    ) {
      // storeFieldUndoRedo();
      // clear any existing timeout here
      clearTimeout(delayDebounce);
      if (selectedProps?.type === "text") {
        updateFieldCollaboration();
      } else if (selectedProps?.type === "table") {
        updateFieldCollaboration();
      }
      setTextCursorToEnd(false);
      return () => clearTimeout(delayDebounce);
    }
  }, [textEditStatus, activeSlide]);

  return (
    <DragLayer>
      {slide && slide.fields
        ? Object.entries(slide.fields).map(([id, field], index) => (
          <DisplayFields
            key={id}
            field={field}
            selected={activeField === field?.key && activeSlide}
            ref={activeField === id && activeSlide ? ref : undefined}
            onSelectItem={onSelectItem}
            onDragStart={onDragStart}
            onEditText={handleEditingText}
            tempScale={tempScale}
            slideID={slide.id}
            activeSlide={slide.id === activeSlideID}
            slideIndex={slideIndex}
            dragging={activeField === field?.key && dragging.status}
            resizing={activeField === field?.key && resizing.status}
            croppingImage={activeField === field?.key && croppingImage}
            tempSize={tempSize}
          />
        ))
      : undefined}

      {Object.keys(usersOnline).length > 0 &&
      activeSlide &&
      slide &&
      slide.fields
        ? Object.entries(slide.fields).map(([id, field]) =>
            Object.entries(usersOnline).map(([username, item]) =>
              item.activeField === field.key ? (
                <SelectedOnlineUsersItems
                  key={username + item.activeField}
                  field={field}
                  username={username}
                  item={item}
                  tempScale={tempScale}
                />
              ) : undefined
            )
          )
        : undefined}

      {activeSlide && !textEditStatus && activeField !== -1 ? (
        <ResizeHandles
          scale={tempScale}
          dragging={dragging.status}
          resizing={resizing.status}
          direction={resizing.direction}
          onResizeStart={onResizeStart}
          onDragStart={onDragStart}
          onRotateStart={onRotateStart}
          activeField={activeField}
          selectedProps={selectedProps}
        />
      ) : undefined}

      {activeSlide &&
      (activeField !== -1 || groupSelect.keys.length !== 0) &&
      slide && slide.fields
        ? Object.entries(slide.fields).map(([id, field], i) => {
            if (!field.deleted && 
                (id === selectedProps?.key || groupSelect?.keys.includes(id))) {
              return (
                <DisplayAlignments field={field} tempScale={tempScale} key={id} />
              );
            }
          })
        : undefined}

      {textEditStatus && activeField !== -1 && activeSlide ? (
        <TextBorderOnEdit
          dragging={dragging.status}
          resizing={resizing.status}
          field={selectedProps}
          tempScale={tempScale}
          tempSize={tempSize}
        />
      ) : undefined}

      {groupSelect.keys.length === 0 &&
      activeField !== -1 &&
      !dragging.status &&
      !resizing.status &&
      !rotate?.rotating &&
      activeSlide ? (
        <Tooltip
          templateScale={tempScale}
          dragging={dragging.status}
          resizing={resizing.status}
          displayEditPanel={displayEditPanel}
        />
      ) : undefined}

      {groupSelect.keys.length === 0 &&
      activeField !== -1 &&
      (selectedProps?.type === "shape" || selectedProps?.type === "image") &&
      selectedProps?.subtype !== "line" &&
      !dragging.status &&
      resizing.status &&
      (croppingImage || resizing.direction === 'left' || resizing.direction === 'right' || resizing.direction === 'top' || resizing.direction === 'bottom') &&
      !rotate.rotating &&
      activeSlide ? (
        <FieldRatio
          templateScale={tempScale}
          offset={selectedProps?.pos}
          size={selectedProps?.size}
        />
      ) : undefined}
    </DragLayer>
  );
};

export const DragLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

export const ResizeLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 4444;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveField(designTemplate),
    selectedProps: selectActiveFieldProps(designTemplate),
    croppingImage: selectCropImage(designTemplate),
    groupSelect: selectInterceptedFields(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    textEditStatus: selectEnableTextEdit(designTemplate),
    displayEditPanel: selectEditPanelStatus(designTemplate),
    tempID: selectTemplateID(designTemplate),
    slideIndex: selectActiveSlide(designTemplate),
    activeSlideID: selectActiveSlideID(designTemplate),
    usersOnline: selectUsersOnLine(designTemplate).users,
    resizing: selectResizing(designTemplate),
    dragging: selectDragging(designTemplate),
    smartAlignments: selectCoordinates(designTemplate),
    canvasWidth: selectCanvasPanelWidth(designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    onSelectField,
    updateFieldPosition,
    updateFieldSize,
    transformImgOffset,
    updateFieldStyles,
    updateTextContent,
    dispatchInitCellSizes,
    resizeTableActions,
    removeInitCellSizes,
    enableTextEdit,
    storeFieldUndoRedo,
    toggleTooltipPopper,
    draggingField,
    resizingField,
    setSmartAlignment,
    updateFieldCollaboration,
    onInterceptFields
  }))
)(DragResize);