import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {onFolderChange} from "../../../store/actions/folder.action";
import {useNavigate, useParams} from "react-router";
import {Item, ActiveDot} from "../reusableStyles/sidebarItem";
import styled from "styled-components";
import {ExpandArrow, LessArrow, Lock} from "../../../../oat-svg-icons";

const Subfolder = ({
  folder,
  currentFolderID,
  index,
  onSelect,
  onRightClick,
}) => {
  const handleRightClick = (e) => {
    onRightClick(e, folder, index);
  };

  const onSelectFolder = () => {
    onSelect(folder);
  };

  return (
    <Item
      onContextMenu={handleRightClick}
      onClick={onSelectFolder}
      style={{padding: "0px 16px 0 24px"}}
    >
      <p>{folder.folderName}</p>

      {currentFolderID === folder.folderID ? (
        <ActiveDot $active={true} />
      ) : undefined}
    </Item>
  );
};

const Folder = ({
  username,
  index,
  folder,
  onRightClick,
  folderType,
  currentFolderID,
  onFolderChange,
}) => {
  const [expandSubfolders, setExpandSubfolders] = useState();
  let navigate = useNavigate();
  let params = useParams();
  const {workspaceID} = params;

  const handleFolderChange = (folderID) => {
    const payload = {
      username,
      workspaceID,
      folderID,
      folderType,
    };
    onFolderChange(payload);
    return navigate(`/dashboard/${workspaceID}/designs/${folderID}`);
  };

  const handleRightClick = ({e, item, folderIndex}) => {
    onRightClick(e, item, folderIndex);
  };

  const onRightClickfolder = (e) => {
    handleRightClick({
      e,
      item: {
        folderID: folder.folderID,
        folderType,
        isSubfolder: false,
        subfolderID: null,
        folderName: folder.folderName,
      },
      folderIndex: index,
    });
  };

  const onRightClickSubfolder = (e, item, subfolderIndex) => {
    handleRightClick({
      e,
      item: {
        folderID: folder.folderID,
        folderType,
        isSubfolder: true,
        subfolderID: item.folderID,
        folderName: item.folderName,
      },
      folderIndex: subfolderIndex,
    });
  };

  const onExpandSubfolder = () => {
    setExpandSubfolders(!expandSubfolders);
  };

  const onSelectFolder = () => {
    handleFolderChange(folder.folderID);
  };

  const onSelectSubfolder = (subfolder) => {
    handleFolderChange(subfolder.folderID);
  };

  useEffect(() => {
    setExpandSubfolders(true);
  }, [folder.subfolders.length]);

  useEffect(() => {
    folder.subfolders &&
      folder.subfolders.find((folder) =>
        folder.folderID === currentFolderID
          ? setExpandSubfolders(true)
          : undefined
      );
  }, []);

  return (
    <>
      <Item onContextMenu={onRightClickfolder}>
        <p onClick={onSelectFolder}>{folder.folderName}</p>

        {currentFolderID === folder.folderID ? (
          <ActiveDot
            style={{right: folder.subfolders.length > 0 ? 36 : undefined}}
            $active={true}
          />
        ) : undefined}

        {folder.private ? <LockIcon>{Lock}</LockIcon> : undefined}

        {folder.subfolders.length > 0 && !expandSubfolders ? (
          <ExpandFolderSvg onClick={onExpandSubfolder}>{ExpandArrow}</ExpandFolderSvg>
        ) : undefined}
        {folder.subfolders.length > 0 && expandSubfolders ? (
          <ExpandFolderSvg onClick={onExpandSubfolder}>{LessArrow}</ExpandFolderSvg>
        ) : undefined}
      </Item>

      {expandSubfolders ? (
        <SubFolders>
          {folder.subfolders &&
            folder.subfolders.map((folder, i) => (
              <Subfolder
                key={i}
                index={i}
                folder={folder}
                currentFolderID={currentFolderID}
                onRightClick={onRightClickSubfolder}
                onSelect={onSelectSubfolder}
              />
            ))}
        </SubFolders>
      ) : undefined}
    </>
  );
};

const SubFolders = styled.div`
  margin-left: 2px;
`;

export const ExpandFolderSvg = styled.div`
  width: 18px;
  height: 23px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  fill: #858383;
  stroke: #858383;
  margin-left: 6px;
  margin-right: 5px;
  transform: scale(0.7);
`;

export const LockIcon = styled.div`
  width: 18px;
  height: 23px;
  transform: scale(0.6);
  margin: 0 4px 0 8px;
`;

export default connect(null, {onFolderChange})(Folder);
