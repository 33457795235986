import styled from "styled-components";
import {chartDefaultFontSize} from "./chartDefaultFontSize";

export const WrapChart = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  position: relative;
  svg {
    pointer-events: none;
    overflow: visible;
    rect {
      pointer-events: none;
    }
    transform-box: fill-box;
  }
`;

export const ScaledChart = styled.div`
  transform: scale(${({scale}) => (scale ? scale : 1)});
  transform-origin: 0px 0px;
  position: relative;
  font-size: ${chartDefaultFontSize}px;
`;
