import React from "react";
import styled from "styled-components";
import {createPortal} from "react-dom";
import {selectCanvasPanelWidth} from "../../../../../store/selectors/layout/layout.selector";
import {connect} from "react-redux";
import {selectEnableTextEdit} from "../../../../../store/selectors/fields.selector";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";

const TextBorderOnEdit = ({
  tempScale,
  canvasWidth,
  field,
  dragging,
  tempSize,
  resizing,
  // editingText,
}) => {
  const {size, pos, styles} = field || {};
  const windowSize = getWindowSize();
  const adjustedXOffset =
    windowSize.width >= 850
      ? Math.round(canvasWidth - tempSize.w * tempScale) / 2
      : 0;

  return createPortal(
    <WrapResizer
      style={{
        position: "absolute",
        width: size?.w * tempScale,
        height: size?.h * tempScale - 2.5,
        border: `1px solid #c4beff`,
        outline:
          !dragging && !resizing
            ? `rgba(234, 243, 247, 0.5) solid 1.5px`
            : undefined,
        transform: `translate(${pos?.x * tempScale + adjustedXOffset}px, ${
          pos?.y * tempScale 
        }px) rotate(${
          // editingText && field.type === "text" ? 0 :
          styles?.rotate
        }deg)`,
      }}
    ></WrapResizer>,
    document.getElementById("oat-item-drag-resize")
  );
};

const WrapResizer = styled.div`
  width: 100%;
  transform-origin: 50% 50%;
  z-index: 9999; // important
  pointer-events: none;
`;

const mapStateToProps = (state) => {
  return {
    canvasWidth: selectCanvasPanelWidth(state.designTemplate),
    editingText: selectEnableTextEdit(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(TextBorderOnEdit);
