import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import {
  dispatchCurrentUndoState,
  dispatchPrevState,
  storeFieldAsActiveUndoRedo,
  storeFieldAsHistoryUndoRedo,
} from "../../../../../../../store/actions/fields/undoRedo.action";
import {connect} from "react-redux";
import {
  ExpandArrowForCanvas,
  Plus,
} from "../../../../../../../../oat-svg-icons";
import {
  Wrapper,
  PopUp,
  SelectionBox,
  Selected,
} from "../../../../../../../ui/inputs/DropDown";
import MyFontList from "./MyFontList";
import OatAllFontList from "./OatAllFontList";
import UploadFont from "./UploadFont";
import {fetchLibraryFontsSuccess} from "../../../../../../../store/actions/fields/common.action";
import {
  selectActiveField,
  selectActiveFieldStyles,
} from "../../../../../../../store/selectors/fields.selector";
import {selectUserInfo} from "../../../../../../../../../redux/user/authUser";
import {selectWorkspaceID} from "../../../../../../../store/selectors/template/template.selector";
import {selectBuildingTheme} from "../../../../../../../store/selectors/template/theme.selector";
import {selectLibraryCustomFonts} from "../../../../../../../store/selectors/common.selector";

const FontList = ({
  onSelect,
  selected,
  height,
  overflowY,
  minWidth,
  fontWeight,
  fontStyle,
  fieldID,
  storeFieldAsActiveUndoRedo,
  storeFieldAsHistoryUndoRedo,
  buildingTheme,
}) => {
  const [openPopper, setOpenPopper] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [uploadedFontFile, setUploadedFontFile] = useState();
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [tab, setTab] = useState("oat-all-font-list");
  const selectRef = useRef(null);
  const boxRef = useRef(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        boxRef.current &&
        selectRef.current &&
        !boxRef.current.contains(event.target) &&
        !selectRef.current.contains(event.target)
      ) {
        setOpenPopper(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    setOpenPopper(false);
  }, [fieldID]);

  const handleToggle = () => {
    if (!openPopper) {
      setOpenPopper(true);
      if (!buildingTheme) {
        storeFieldAsHistoryUndoRedo();
      }
    }
  };

  const onSelectFont = (item) => {
    // changes here should also reflect for mobile code
    onSelect(item);
    if (!buildingTheme) {
      storeFieldAsActiveUndoRedo();
    }
  };

  const onInitUploadFont = () => {
    inputFileRef.current.click();
  };

  const onFileUpload = async (e) => {
    if (e.target.files && e.target.files[0]) {
      toggleUploadModal();
      setUploadedFontFile(e.target.files[0]);
      e.target.value = null;
    }
  };

  const switchMyFontsTab = () => {
    if (tab === "oat-all-font-list") {
      setTab("my-font-list");
    } else {
      setTab("oat-all-font-list");
    }
  };

  const toggleUploadModal = () => {
    setOpenUploadModal(!openUploadModal);
  };

  return (
    <Wrapper>
      <SelectionBox
        style={{width: 132}}
        ref={boxRef}
        minWidth={minWidth}
        onClick={handleToggle}
      >
        <Selected>{selected}</Selected>
        <div style={{marginRight: 10, cursor: "pointer"}}>
          <WrapExapandSvg>{ExpandArrowForCanvas}</WrapExapandSvg>
        </div>
      </SelectionBox>

      {openPopper ? (
        <PopperVisibility visible={openPopper}>
          <PopUp ref={selectRef} style={{width: "100%"}}>
            <div style={{padding: "4px 0 8px"}}>
              <WrapListItem style={{height, overflowY}}>
                {tab === "oat-all-font-list" ? (
                  <OatAllFontList
                    onSelectFont={onSelectFont}
                    selected={selected}
                    close={handleToggle}
                    fontWeight={fontWeight} // activeStyles.fontWeight
                    fontStyle={fontStyle} // activeStyles.fontStyle
                  />
                ) : (
                  <MyFontList
                    onSelectFont={onSelectFont}
                    selected={selected}
                    close={handleToggle}
                  />
                )}
              </WrapListItem>
            </div>

            <BottomSection>
              <MyFonts onClick={switchMyFontsTab}>
                {tab === "oat-all-font-list" ? "My Fonts" : "Back"}
              </MyFonts>
              <div>
                <UploadButton onClick={onInitUploadFont}>{Plus}</UploadButton>
                <input
                  ref={inputFileRef}
                  type="file"
                  onChange={onFileUpload}
                  hidden
                />
              </div>
            </BottomSection>
          </PopUp>
          {/* </Popper> */}
        </PopperVisibility>
      ) : undefined}

      {openUploadModal ? (
        <UploadFont
          uploadedFile={uploadedFontFile}
          onClose={toggleUploadModal}
        />
      ) : undefined}
    </Wrapper>
  );
};

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
  border-top: 1px solid ${({theme}) => theme.deskGap};
`;

const MyFonts = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 5px;
  font-weight: 700;
  color: ${({theme}) => theme.deskTertiaryTxt};
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg3};
  }
`;

const UploadButton = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: ${({theme}) => theme.deskSecondaryTxt};
  border-radius: 5px;
  padding: 4px;
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg3};
  }
`;

const PopperVisibility = styled.div`
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
`;

const WrapExapandSvg = styled.div`
  width: 18px;
  height: 18px;
  fill: ${({theme}) => theme.deskBoldText};
  stroke: ${({theme}) => theme.deskBoldText};
  border-radius: 4px;
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg};
  }
`;

const WrapListItem = styled.div`
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

FontList.defaultProps = {
  height: "auto",
  overflowY: "auto",
};

const mapStateToProps = (state) => {
  return {
    activeStyles: selectActiveFieldStyles(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    buildingTheme: selectBuildingTheme(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  dispatchCurrentUndoState,
  dispatchPrevState,
  fetchLibraryFontsSuccess,
  storeFieldAsActiveUndoRedo,
  storeFieldAsHistoryUndoRedo,
})(FontList);
