import {createSlideID} from "../fields/utils";

// use in slide or template.js in redux when creating a slide from the existing template.
// resize fields before adding on the template from folder
export const resizeFields = ({slide, tempWidth, ratioDiff}) => {
  let dynamicSize = 12;
  if (tempWidth < 1000) {
    dynamicSize = 12;
  } else if (tempWidth < 1000) {
    dynamicSize = 20;
  } else if (tempWidth < 2000) {
    dynamicSize = 28;
  } else if (tempWidth < 3000) {
    dynamicSize = 54;
  } else if (tempWidth < 4000) {
    dynamicSize = 68;
  } else if (tempWidth < 5000) {
    dynamicSize = 84;
  }
  const updateFields = Object.entries(slide.fields).map(([id, field]) => {
    const {styles, size, pos} = field;
    if (field.type === "text") {
      const w1 = size.w * ratioDiff;
      const h1 = size.h * ratioDiff;
      const updateSizePos = {
        size: {
          ...field.size,
          w: w1,
          h: h1,
        },
        pos: {
          x: pos.x * ratioDiff,
          y: pos.y * ratioDiff,
        },
      };
      return {
        ...field,
        ...updateSizePos,
        ["styles"]: {
          ...field.styles,
          scale: Math.min(w1 / size.w2, h1 / size.h2), //styles.scale * ratioDiff,
        },
      };
    } else if (field.type === "image") {
      return {
        ...field,
        size: {
          w: size.w * ratioDiff,
          h: size.h * ratioDiff,
          w2: size.w2 * ratioDiff,
          h2: size.h2 * ratioDiff,
        },
        pos: {
          x: pos.x * ratioDiff,
          y: pos.y * ratioDiff,
        },
        imgPos: {
          x: field.imgPos.x * ratioDiff,
          y: field.imgPos.y * ratioDiff,
        },
      };
    } else if (field.type === "table") {
      return {
        ...field,
        size: {
          w: size.w * ratioDiff,
          h: (size.h - 2) * ratioDiff,
        },
        pos: {
          x: pos.x * ratioDiff,
          y: pos.y * ratioDiff,
        },
        ["styles"]: {
          ...field.styles,
          fontSize: styles.fontSize * ratioDiff,
        },
        content: field.content.map((item) => ({
          row: {
            height: item.row.height * ratioDiff,
          },
          columns: item.columns.map((column) => ({
            ...column,
            width: column.width * ratioDiff,
          })),
        })),
      };
    } else if (field.type === "chart") {
      // const scaledItem = Math.min(
      //   (size.w * ratioDiff) / size.w,
      //   (size.h * ratioDiff) / size.h
      // );
      // const tempScale = Math.min(
      //   (window.innerHeight - 150) / action.resizeTo.h,
      //   1152 / action.resizeTo.w
      // );
      const updateSizePos = {
        size: {
          w: size.w * ratioDiff,
          h: size.h * ratioDiff,
        },
        pos: {
          x: pos.x * ratioDiff,
          y: pos.y * ratioDiff,
        },
        ["styles"]: {
          ...field.styles,
          scale: 1,
          fontSize: styles.fontSize * ratioDiff,
          barSize: styles.barSize * ratioDiff,
          legendFontSize: styles.legendFontSize * ratioDiff,
          measurementFontSize: styles.measurementFontSize * ratioDiff,
          categoryFontSize: styles.categoryFontSize * ratioDiff,
          donutGap: 5 * ratioDiff,
          dotSize: 4 * ratioDiff,
          lineSize: 1 * ratioDiff,
          scaleDistance: 12 * ratioDiff,
        },
      };
      return {
        ...field,
        ...updateSizePos,
      };
    } else {
      const updateSizePos = {
        size: {
          w: size.w * ratioDiff,
          h: size.h * ratioDiff,
        },
        pos: {
          x: pos.x * ratioDiff,
          y: pos.y * ratioDiff,
        },
      };
      return {
        ...field,
        ...updateSizePos,
        ["styles"]: {
          ...field.styles,
          scale: 1,
        },
      };
    }
  });
  const newSlide = {
    ...slide,
    activeField: -1,
    // id: createSlideID(),
    fields: updateFields.reduce(
      (acc, item) => ({...acc, [item.key]: item}),
      {}
    ),
  };
  return newSlide;
};
