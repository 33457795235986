import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {WrapSpinner} from "../reusableStyles/reusableStyles";
import {Spinner} from '../../../../../../oat-ui';

const PremiumImgCover = ({index, item}) => {
    const [loaded, setLoaded] = useState(index === 0 ? false: true)

    useEffect(() => {
        setLoaded(index === 0 ? false: true)
    },[index])

    const handleImgLoad = () => {
        setLoaded(true);
    }

    return (
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {!loaded ? <WrapSpinner style={{ position: 'absolute' }}><Spinner /></WrapSpinner> : undefined}
            <Image loading="lazy" onLoad={handleImgLoad} src={`https://oat-images-icons.s3.amazonaws.com/${item}`} alt="" />
        </div>
    )
};

const Image = styled.img`
  max-width: 100%;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
`

export default PremiumImgCover;