import React from "react";
import {EnterFullScreen, ExitFullScreen} from "../../../oat-svg-icons";
import styled from "styled-components";

const ToggleFullScreen = ({
  isFullscreen,
  setIsFullscreen,
  handleExitFullscreen,
  rightOffset,
}) => {
  return isFullscreen ? (
    <WrapSvg
      onClick={handleExitFullscreen}
      style={{padding: 8, right: rightOffset}}
    >
      {ExitFullScreen}
    </WrapSvg>
  ) : (
    <WrapSvg onClick={setIsFullscreen} style={{padding: 4, right: rightOffset}}>
      {EnterFullScreen}
    </WrapSvg>
  );
};

const WrapSvg = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 16px;
  fill: #ffffff;
  background: #1d1b1b52;
  border-radius: 50%;
  boxsizing: border-box;
  cursor: pointer;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

export default ToggleFullScreen;
