import React, {useRef} from "react";
import styled from "styled-components";
import {createPortal} from "react-dom";
import {detectClickOutside} from "../utils/detectClickOutside";
import {Bouncy} from "../animation/bouncy";

const Popper = ({
  width,
  height,
  offset,
  background,
  styles,
  children,
  onClose,
  enableScroll
}) => {
  const popUpRef = useRef(null);
  detectClickOutside(popUpRef, () => {
    onClose();
  });

  return createPortal(
    <Wrapper
      style={{left: offset.x, top: offset.y, width, height, ...styles}}
      ref={popUpRef}
      background={background}
    >
      <Content style={{overflowY: enableScroll ? 'scroll' : undefined}}>{children}</Content>
    </Wrapper>,
    document.getElementById("popUp-box")
  );
};

const Wrapper = styled.div`
  position: absolute;
  border-radius: 10px;
  z-index: 999999;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: #d1d1d2 0px 0px 3px 0px;
  background: ${({background}) => background};
  ${Bouncy};
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

Popper.defaultProps = {
  width: 260,
  height: "auto",
  background: "#fff",
  offset: {
    x: 0,
    y: 0,
  },
  boxShadow: "",
  enableScroll: true
};

export default Popper;
