import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {Button, ButtonSpinner} from "../../../../../oat-ui";
import {
  selectActiveWorkSpace,
  selecWorkSpaceLoadingStatus,
} from "../../../../store/selectors/userDashboard.selector";
import {BottomSection} from "../../../reuse/dashboardUI/modalStyles";
import {CollaboratorAccessLevel, Error, Header} from "../workspaceStyles";
import {renameWorkspace} from "../../../../store/actions/workspace.action";
import {darkGrey1} from "../../../../../oat-color-variables";

const RenameWorkspace = ({
  user,
  workspace,
  loading,
  renameWorkspace,
  close,
}) => {
  const [workspaceName, setWorkspaceName] = useState(workspace.name);
  const [error, setError] = useState({status: false, message: null});

  const handleValueChange = (e) => {
    const {value} = e.target;
    setWorkspaceName(value);
  };

  const handleRename = async () => {
    if (workspaceName === "") {
      setError({
        status: true,
        message: "Workspace title cannot be empty.",
      });
    }
    if (workspace.role === "owner" || workspace.role === "admin") {
      if (error.status) {
        setError({
          status: false,
          message: null,
        });
      }
      await renameWorkspace({
        username: user.username,
        payload: {
          workspaceID: workspace.ID,
          workspaceName: workspaceName,
        },
      });
      await close();
    }
  };

  return (
    <>
      <Header>Rename Workspace</Header>

      <Wrapper
        style={{
          pointerEvents: workspace.role === "collaborator" ? "none" : undefined,
          opacity:
            workspace.role === "owner" || workspace.role === "admin" ? 1 : 0.5,
        }}
      >
        <div style={{flex: 2}}>
          <Input
            value={workspaceName}
            placeholder="Workspace Name"
            onChange={handleValueChange}
            autoFocus={window.innerWidth >= 850 ? true : false}
          />
        </div>

        <BottomSection style={{flex: 1, marginLeft: 12}}>
          <WrapButton>
            <Button onClick={handleRename} disabled={loading}>
              {!loading ? "Rename" : <ButtonSpinner />}
            </Button>
          </WrapButton>
        </BottomSection>

        {error.status ? <Error>{error.message}</Error> : undefined}
      </Wrapper>

      {workspace.role === "collaborator" ? (
        <CollaboratorAccessLevel
          style={{
            textAlign: "left",
            marginTop: 12,
            fontSize: "0.84rem",
            color: darkGrey1,
          }}
        >
          * Collaborator cannot change the workspace title.
        </CollaboratorAccessLevel>
      ) : undefined}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  color: #232323;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #cdcdcd;
  padding: 6px 3px;
  box-sizing: border-box;
`;

const WrapButton = styled.div`
  width: 96px;
  height: 100%;
`;

const mapStateToProps = (state) => {
  return {
    workspace: selectActiveWorkSpace(state.userDashboard),
    loading: selecWorkSpaceLoadingStatus(state.userDashboard),
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {renameWorkspace})(RenameWorkspace);
