import React from "react";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import LayoutPosition from "../common/LayoutPosition";
import {SplitLine} from "../common/SplitLine";
import {dispatchIconColorUpdate} from "../../../../../../store/actions/fields/icon.action";
import EditOpacity from "../common/EditOpacity";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import EditIconSet from "./EditIconSet";
import AddLink from "../common/AddLink";

const EditIconPanel = ({activeField}) => {
  const {styles} = activeField || {};

  return (
    <div style={{padding: "2em 0"}}>
      <LayoutPosition rotate={styles?.rotate} locked={styles?.lock} />
      <SplitLine />

      <EditOpacity value={styles?.opacity} />
      <SplitLine />

      {activeField?.format !== 'png' ? 
        <>
          <EditIconSet />
          <SplitLine />
        </> 
      : undefined}

      <div style={{paddingBottom: "2em"}}>
        <AddLink />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeField: selectActiveFieldProps(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  dispatchIconColorUpdate,
  storeFieldUndoRedo,
})(EditIconPanel);
