import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {openDrawer} from "../../../../../../store/actions/layout.action";
import {addSlideNotes} from "../../../../../../store/actions/template/slide.action";
import {
  selectActiveSlideID,
  selectActiveSlideInfo,
} from "../../../../../../store/selectors/template/slide.selector";
import GoBack from "../../../../drawer/common/GoBack";
import {optionDrawerHeight} from "../../../../drawer/MobileDrawer";
import {PanelHeader} from "../panelStyles";
import {useSocket} from "../../../../../../webSocket/useSocket";

const SlideNotes = ({notes, slideID, addSlideNotes, openDrawer}) => {
  const {emitSocketEvents} = useSocket() || {};

  const handleInput = (e) => {
    addSlideNotes({slideID, notes: e.target.value});
  };

  const goBack = () => {
    openDrawer({type: "slide-options", height: optionDrawerHeight});
  };

  const handleModalBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      const item = {type: "", value: notes};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "slide-notes-added", item});
      }
    }
  };

  return (
    <>
      <GoBack onBackward={goBack} />
      <PanelHeader>Notes</PanelHeader>
      <Layout onBlur={handleModalBlur} tabIndex={0}>
        <NoteArea>
          <textarea value={notes} onChange={handleInput} />
        </NoteArea>
      </Layout>
    </>
  );
};

const Layout = styled.div`
  @media only screen and (max-width: 849px) {
    margin-top: 24px;
  }
`;

const NoteArea = styled.div`
  box-sizing: border-box;
  height: 120px;
  background: transparent;
  border: 1px solid ${({theme}) => theme.deskGap};
  border-radius: 10px;
  margin: 12px;
  font-size: 1rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  padding: 10px;
  textarea {
    font-family: inherit;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    color: inherit;
    resize: none;
  }
  @media only screen and (max-width: 849px) {
    border-radius: 16px;
  }
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideID(state.designTemplate),
    notes: selectActiveSlideInfo(state.designTemplate).notes,
  };
};

export default connect(mapStateToProps, {
  openDrawer,
  addSlideNotes,
})(SlideNotes);
