import React, {useState} from "react";
import {ButtonSpinner, Modal} from "../../../../../oat-ui";
import {connect} from "react-redux";
import styled from "styled-components";
import {Button} from "../../../../../oat-ui";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {onRemoveTemplateSuccess} from "../../../../store/actions/template.action";
import {red5, red1} from "../../../../../oat-color-variables";
import {
  Header,
  ContentSection,
  BottomSection,
  Error,
  Footer,
  Message,
  Cancel,
} from "../../../reuse/dashboardUI/modalStyles";

const ConfirmDelete = ({item, user, onRemoveTemplateSuccess, close}) => {
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    err: null,
  });

  const handleDelete = async () => {
    if (!submitStatus.loading) {
      setSubmitStatus({
        loading: true,
        err: null,
      });
      const requestOptions = {
        method: "DELETE",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          section: "templates",
          workspaceID: item.workspaceID,
          folderID: item.folderID,
          tempSourceID: item.tempSourceID,
        }),
      };
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${item.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setSubmitStatus({
            loading: false,
            err: null,
          });
          onRemoveTemplateSuccess(item.id);
          close();
        });
    }
  };

  return (
    <Modal width={320} height="auto" close={close}>
      <ContentSection>
        <Header style={{flex: 1, color: red1}}>Confirm Delete</Header>

        <Message>Are you sure you want to delete?</Message>
      </ContentSection>

      <Footer style={{height: "auto"}}>
        <BottomSection
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Cancel onClick={close}>Cancel</Cancel>
          <Button
            styles={{width: 100, height: 34, background: red5}}
            onClick={handleDelete}
            disabled={submitStatus.loading}
          >
            {!submitStatus.loading ? "Delete" : <ButtonSpinner />}
          </Button>
        </BottomSection>

        {submitStatus.err ? (
          <ErrorContainer>
            <Error>Something went wrong.</Error>
          </ErrorContainer>
        ) : undefined}
      </Footer>
    </Modal>
  );
};
const ErrorContainer = styled.div`
  padding: 6px;
  box-sizing: border-box;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {onRemoveTemplateSuccess})(
  ConfirmDelete
);
