import {
  COLLABORATOR_SELECT_FIELD,
  COLLABORATOR_DESELECT_FIELD,
  SET_EXISTING_USERS_ONLINE,
  COLLABORATION_FIELD_CHANGED,
  UPDATE_UNDOREDO_FIELDS_OF_COLLABORATION,
  UPDATE_GROUP_FIELDS_COLLABORATION,
  DELETE_FIELDS_COLLABORATION,
  UPDATE_FIELD_COLLABORATION,
  ADD_NEW_SLIDE_COLLABORATION,
} from "../actionTypes";

export const collaboratorSelectField = (active) => {
  return {
    type: COLLABORATOR_SELECT_FIELD,
    active,
  };
};

export const collaboratorDeselectField = (username) => {
  return {
    type: COLLABORATOR_DESELECT_FIELD,
    username,
  };
};

export const setExistingOnlineUsers = (users) => {
  return {
    type: SET_EXISTING_USERS_ONLINE,
    users,
  };
};

export const updateFieldCollaboration = () => {
  return {
    type: UPDATE_FIELD_COLLABORATION,
  };
};

export const collaborationFieldChanged = ({active, payload}) => {
  return {
    type: COLLABORATION_FIELD_CHANGED,
    active,
    payload,
  };
};

export const updateGroupFieldsCollaboration = (payload) => {
  return {
    type: UPDATE_GROUP_FIELDS_COLLABORATION,
    active: payload.active,
    payload: payload.value,
    updateType: payload.type, // resize or drag
  };
};

export const updateUndoRedoFieldsOfCollaboration = ({active, payload}) => {
  return {
    type: UPDATE_UNDOREDO_FIELDS_OF_COLLABORATION,
    active,
    payload,
  };
};

export const deleteFieldsCollaboration = ({active, payload}) => {
  return {
    type: DELETE_FIELDS_COLLABORATION,
    active,
    payload,
  };
};

export const addNewSlideCollaboration = (insertAfterSlideID, payload) => {
  return {
    type: ADD_NEW_SLIDE_COLLABORATION,
    insertAfterSlideID,
    payload
  };
};
