import React from "react";
import styled from "styled-components";
import {LinkName} from "./CustomLinkDetails";

const CustomLinkPasscode = ({passcode, onToggleStatus, onPasscodeChange}) => {
  return (
    <Passcode>
      <EnablePasscode>
        <Title>Enable Passcode</Title>
        <WrapSwitch>
          <label className="oatall-input-switch">
            <input
              type="checkbox"
              value={passcode.enabled}
              onChange={onToggleStatus}
              checked={passcode.enabled}
            />
            <span className="slider round" />
          </label>
        </WrapSwitch>
      </EnablePasscode>

      {passcode.enabled ? (
        <div style={{position: "relative"}}>
          <LinkName
            type="text"
            value={passcode.value}
            placeholder="Enter Passcode"
            onChange={onPasscodeChange}
          />
          {/* <p style={{position: "absolute", right: 0}}>Show</p> */}
        </div>
      ) : undefined}
    </Passcode>
  );
};

const Passcode = styled.div`
  margin: 24px 0 6px;
`;

const EnablePasscode = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0 22px;
`;

const WrapSwitch = styled.div`
  display: flex;
  flex: 1;
  width: 60px;
  align-items: center;
`;

const Title = styled.h4`
  display: flex;
  flex: 1;
`;

export default CustomLinkPasscode;
