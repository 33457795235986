import React from "react";
import {createPortal} from "react-dom";
import SearchInput from "../../../../../ui/inputs/SearchInput";

const SearchImage = ({value, placeholder, onChange}) => {
  return createPortal(
    <SearchInput value={value} placeholder={placeholder} onChange={onChange} />,
    document.getElementById('unsplash-search-image-input')
  );
};
export default SearchImage;
