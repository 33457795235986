import React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {selectTemplateSize} from "../../../../../store/selectors/template/template.selector";
import {selectCanvasPanelWidth} from "../../../../../store/selectors/layout/layout.selector";
import {CornerSize} from "./Resizer";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";

const LineResizable = ({
  field,
  dragging,
  tempScale,
  dragStart,
  resizeStart,
  tempSize,
  canvasWidth,
  adjustXOffset,
}) => {
  const {size, pos, styles} = field || {};
  const windowSize = getWindowSize();
  const adjustedXOffset =
    adjustXOffset && windowSize.width >= 850
      ? Math.round(canvasWidth - tempSize.w * tempScale) / 2
      : 0;

  const onResizeLeft = (e) => {
    resizeStart(e, "left");
  };

  const onResizeRight = (e, item) => {
    resizeStart(e, "right");
  };

  return (
    <Wrapper
      className="oat-all resizers"
      style={{
        position: "absolute",
        width: size?.w !== "auto" ? (size?.w + 0) * tempScale : 0, //2
        height: size?.h * tempScale,
        transform: `translate(${pos?.x * tempScale + adjustedXOffset}px, ${
          pos?.y * tempScale
        }px) rotate(${styles?.rotate}deg)`,
        transformOrigin: "50% 50%",
      }}
      onMouseDown={dragStart}
    >
      {!dragging ? (
        <>
          <WrapResizer
            style={{
              top: windowSize.width >= 850 ? (size?.h * tempScale) / 2 - 5 : 0,
              left: windowSize.width >= 850 ? -2 / tempScale : -5,
              display: !styles?.lock ? "flex" : "none",
            }}
            onMouseDown={onResizeLeft}
            onTouchStart={onResizeLeft}
          >
            <Resizer />
          </WrapResizer>
          <WrapResizer
            style={{
              top: windowSize.width >= 850 ? (size?.h * tempScale) / 2 - 5 : 0,
              right: windowSize.width >= 850 ? -2 / tempScale : -5,
              display: !styles?.lock ? "flex" : "none",
            }}
            onMouseDown={onResizeRight}
            onTouchStart={onResizeRight}
          >
            <Resizer />
          </WrapResizer>
        </>
      ) : undefined}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  z-index: 19999;
  transform-origin: 50% 50%;
  &:after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
`;

const WrapResizer = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  cursor: move;
  @media only screen and (max-width: 849px) {
    width: 10px;
    height: 100%;
  }
`;

const Resizer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  &:after {
    content: "";
    width: ${CornerSize}px;
    height: ${CornerSize}px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
  }
`;

LineResizable.defaultProps = {
  adjustXOffset: true, // readjust the resize handles and tooltip's offset to that of the field, due to scaling template
};

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    canvasWidth: selectCanvasPanelWidth(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(LineResizable);
