import BlankTemplate from "./design-template/CustomerBlankTemp";
import PresentTemplate from "./present/PresentTemplate";
import UserDashboard from "./dashboard/UserDashboard";
import Register from "./auth/Register";
import Login from "./auth/Login";
import CustomerEditTemp from "./design-template/CustomerEditTemp";
import UserProfile from "./profile/UserProfile";
import ShowProfileTemplate from "./profile/ShowProfileTemplate";
import ProfileSetting from "./dashboard/ProfileSetting";
import SharedToMe from "./dashboard/SharedToMe";
import ForgotPassword from "./auth/ForgotPassword";
// import DesignSharedTemplate from "./design-template/DesignSharedTemplate";
import DesignProfileTemplate from "./design-template/DesignProfileTemplate";
import ViewCustomLinkTemplate from "./present/ViewCustomLinkTemplate";
import Calendar from "./dashboard/Calendar";
import { OatPurchased } from "../../packages/oat-user-dashboard";
import { OatBespokeServices } from "../../packages/oat-public";
import { PlayGround } from "../../packages/oat-design-template";

export default [
  {
    routeProps: {
      path: "/design/:tempID",
      exact: true,
      element: <CustomerEditTemp />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "Design",
  },
  {
    routeProps: {
      path: "/design/playground",
      exact: true,
      element: <PlayGround />,
    },
    restricted: {
      status: false,
      role: "user",
    },
    name: "Design",
  },
  // {
  //   routeProps: {
  //     path: "/design/edit/:tempID",
  //     exact: true,
  //     element: <CustomerEditTemp />,
  //   },
  //   name: "Update Design",
  // },
  {
    // design/blank/presentation/16x9?folder=nmwu1if
    routeProps: {
      path: "/design/blank/:type/:size",
      exact: true,
      element: <BlankTemplate />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "Start Designing",
  },
  // {
  //   routeProps: {
  //     path: "/preview/:username/:templateID",
  //     exact: true,
  //     element: <PresentTemplate />,
  //   },
  //   name: "",
  // },
  {
    routeProps: {
      path: "/dashboard/shared-to-me",
      exact: true,
      element: <SharedToMe />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/dashboard/calendar",
      exact: true,
      element: <Calendar />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  {
    // don't delete - cognito redirect
    routeProps: {
      path: "/dashboard",
      exact: true,
      element: <UserDashboard />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/dashboard/:workspaceID",
      exact: true,
      element: <UserDashboard />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/dashboard/:workspaceID/:section",
      exact: true,
      element: <UserDashboard />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/dashboard/:workspaceID/:section/:folderID",
      exact: true,
      element: <UserDashboard />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/dashboard/profile",
      exact: true,
      element: <ProfileSetting />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  // {
  //   routeProps: {
  //     path: "/dashboard/:workspaceID/library/:libType",
  //     exact: true,
  //     element: <UserLibrary />,
  //   },
  //   restricted: {
  //     status: true,
  //     role: "user",
  //   },
  //   name: "",
  // },
  // {
  //   routeProps: {
  //     path: "/dashboard/:workspaceID/library/:libType/:folderID",
  //     exact: true,
  //     element: <UserLibrary />,
  //   },
  //   restricted: {
  //     status: true,
  //     role: "user",
  //   },
  //   name: "",
  // },
  {
    routeProps: {
      path: "/login",
      exact: true,
      element: <Login />,
    },
    restricted: {
      status: false,
      role: "",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/register",
      exact: true,
      element: <Register />,
    },
    restricted: {
      status: false,
      role: "",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/forgot-password",
      exact: true,
      element: <ForgotPassword />,
    },
    restricted: {
      status: false,
      role: "",
    },
    name: "",
  },
  // {
  //   routeProps: {
  //     path: "/:slug/:tempID",
  //     exact: true,
  //     element: <ShowProfileTemplate />,
  //   },
  //   restricted: {
  //     status: false,
  //     role: "",
  //   },
  //   name: "",
  // },
  {
    routeProps: {
      path: "/u/:username",
      exact: true,
      element: <UserProfile />,
    },
    restricted: {
      status: false,
      role: "",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/u/:username/:id",
      exact: true,
      element: <ShowProfileTemplate />,
    },
    restricted: {
      status: false,
      role: "",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/p/:tempID",
      exact: true,
      element: <PresentTemplate />,
    },
    restricted: {
      status: false,
      role: "",
    },
    name: "",
  },
  // {
  //   routeProps: {
  //     path: "/design/shared/:owner/:tempID",
  //     exact: true,
  //     element: <DesignSharedTemplate />,
  //   },
  //   restricted: {
  //     status: true,
  //     role: "user",
  //   },
  //   name: "",
  // },
  {
    routeProps: {
      // user owner as opposed to profile slug due to s3 temp source issue
      path: "/design/profile/:profileSlug/:tempID",
      exact: true,
      element: <DesignProfileTemplate />,
    },
    restricted: {
      status: true,
      role: "user",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/link/:slug",
      exact: true,
      element: <ViewCustomLinkTemplate />,
    },
    restricted: {
      status: false,
      role: "user",
    },
    name: "",
  },
  {
    routeProps: {
      path: "/dashboard/purchased",
      exact: true,
      element: <OatPurchased />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Purchased",
  },
  {
    routeProps: {
      path: "/bespoke-services",
      exact: true,
      element: <OatBespokeServices />,
    },
    restricted: {
      status: false,
      role: null,
    },
    name: "Purchased",
  },
];
