import styled from "styled-components";
import {editingPanelWidth} from "../../../layoutSizes";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 850px) {
    width: ${({openEditChartInfo, editingPanelWidthEditingChart}) =>
      openEditChartInfo ? editingPanelWidthEditingChart : editingPanelWidth}px;
    justify-content: center;
    align-items: flex-start;
    color: ${({theme}) => theme.deskPrimaryTxt};
  }
  @media only screen and (max-width: 849px) {
    background: #1c1c2f;
    font-size: 0.9rem;
  }
`;

export const WrapPanel = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 850px) {
    height: 100%;
    // overflow-y: scroll;
    overflow: hidden;
    top: 63px;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding-top: 0em;
    display: flex;
    align-items: flex-start;
    border-left: 1px solid ${({theme}) => theme.rightPanelBorder};
    flex-direction: column;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const EditPanel = styled.div`
  width: 100%;
  height: 95%;
  user-select: none;
  position: relative;
  @media only screen and (min-width: 850px) {
    font-size: 0.9rem;
  }
  @media only screen and (max-width: 849px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    user-select: none;
  }
`;

export const Section = styled.div`
  display: ${(props) => props.display};
  flex-direction: column;
  flex: 1;
  height: ${({height}) => (height ? height : "auto")};
  justify-content: center;
  overflow-x: ${({overflowX}) => (overflowX ? overflowX : "hidden")};
  scrollbar-width: none;
  z-index: 10;
  &::-webkit-scrollbar {
    display: none;
    background: transparent;
    width: 0;
    height: 0;
  }
  @media only screen and (min-width: 850px) {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;
