import React from "react";
import styled from "styled-components";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import ChangeSlideTheme from "./ChangeSlideTheme";
import DeskToggleSlides from "./DeskToggleSlides";
import UndoRedo from "./UndoRedo";
import {connect} from "react-redux";
import {selectEditPanelStatus} from "../../../../store/selectors/layout/layout.selector";

const BottomSection = ({displayEditPanel}) => {
  const windowSize = getWindowSize();

  return (
    <Layout>
      <WrapDiv>
        <ChangeSlideTheme />
      </WrapDiv>

      {(displayEditPanel && windowSize.width > 1050) ||
      (!displayEditPanel && windowSize.width > 940) ? (
        <WrapDiv style={{flex: 1, display: "flex", justifyContent: "center"}}>
          <DeskToggleSlides />
        </WrapDiv>
      ) : undefined}

      <WrapDiv style={{display: "flex", justifyContent: "flex-end", flex: 0.8}}>
        <UndoRedo />
      </WrapDiv>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  z-index: 9;
`;

const WrapDiv = styled.div`
  flex: 1;
`;

const mapStateToProps = (state) => {
  return {
    displayEditPanel: selectEditPanelStatus(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(BottomSection);
