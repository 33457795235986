import React from "react";
import {connect} from "react-redux";
import {Duplicate, Trash} from "../../../../../../../oat-svg-icons";
import {
  duplicateField,
  removeField,
  zIndexToBack,
  zIndexToFront,
} from "../../../../../../store/actions/fields/common.action";
import {
  toggleEditPanel,
  toggleTooltipPopper,
} from "../../../../../../store/actions/layout.action";
import {Content, OnHoverContent} from "../Tooltip";
import styled from "styled-components";
import {
  selectEditPanelStatus,
  selectTooltipPopperStatus,
} from "../../../../../../store/selectors/layout/layout.selector";
import {WrapTooltipSvg} from "../reuseTooltipStyles";
import {storeActionsUndoRedo, storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {
  selectActiveFieldProps,
  selectEnableTextEdit,
} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide, selectActiveSlideID} from "../../../../../../store/selectors/template/slide.selector";
import {createShortKey} from "../../../../../../store/reducers/functions/fields/utils";
import TooltipSetPosition from "./TooltipSetPosition";
import { ToFront } from "../../../../../../ui/svg/ToFront";
import { ToBack } from "../../../../../../ui/svg/ToBack";
import { useSocket } from "../../../../../../webSocket/useSocket";

const TooltipItemsHoc = ({
  activeSlide,
  fieldID,
  slideID,
  zIndex,
  displayEditPanel,
  duplicateField,
  removeField,
  toggleEditPanel,
  children,
  storeActionsUndoRedo,
  textEditing,
  popperOpen,
  toggleTooltipPopper,
  zIndexToFront,
  zIndexToBack,
  storeFieldUndoRedo
}) => {
  const {emitSocketEvents} = useSocket() || {};
  const active = {slideIndex: activeSlide, fieldID};

  const handleDuplicate = async (e) => {
    e.stopPropagation();

    const newKey = createShortKey();
    // order matters
    await duplicateField(active, newKey);
    await storeActionsUndoRedo("create");
  };

  const handleDeletion = (e) => {
    e.stopPropagation();
    storeActionsUndoRedo("delete");
    removeField(active);
  };

  const onToggleEditPanel = (e) => {
    e.stopPropagation();
    toggleEditPanel();
  };

  const openLayoutList = (e) => {
    e.stopPropagation();
    toggleTooltipPopper("set-position");
  };

  const sendToFront = (e) => {
    e.stopPropagation();
    const active = {slideID, fieldID};
    zIndexToFront(active, zIndex);
    const item = {type: "zIndex-to-front", zIndex};
    storeFieldUndoRedo();
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "zIndex-changed", item});
    }
  };

  const sendToBack = (e) => {
    e.stopPropagation();
    const active = {slideID, fieldID};
    zIndexToBack(active, zIndex);
    const item = {type: "zIndex-to-back", zIndex};
    storeFieldUndoRedo();
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "zIndex-changed", item});
    }
  };

  const onClosePopper = () => {
    toggleTooltipPopper();
  };

  return (
    <div style={{display: "flex"}}>
      <Layout>
        {children}

        {!textEditing ? (
          <>
            <Content onMouseDown={sendToFront}>
              <Icon>
                {ToFront}
              </Icon>
              {/* <OnHoverContent style={{width: 70, right: 85}}>
                To Front
              </OnHoverContent> */}
            </Content>
            <Content onMouseDown={sendToBack}>
              <Icon>
                {ToBack}
              </Icon>
              {/* <OnHoverContent style={{width: 70, right: 85}}>
                To Front
              </OnHoverContent> */}
            </Content>
            <Content onMouseDown={openLayoutList}>
              <p style={{whiteSpace: "pre"}}>Change Position</p>
              <OnHoverContent style={{width: 70, right: 85}}>
                Apply Position
              </OnHoverContent>
            </Content>
            <Content onMouseDown={handleDuplicate}>
              <WrapTooltipSvg>{Duplicate}</WrapTooltipSvg>
              <OnHoverContent style={{width: 70, right: 35}}>
                Duplicate
              </OnHoverContent>
            </Content>
            <Content onMouseDown={handleDeletion}>
              <WrapTooltipSvg>{Trash}</WrapTooltipSvg>
              <OnHoverContent style={{width: 70, right: -5}}>
                Delete
              </OnHoverContent>
            </Content>
          </>
        ) : undefined}
      </Layout>

      {!textEditing ? (
        <Content
          style={{
            right: -14,
            width: 20,
            margin: 0,
            padding: "0 4px",
            borderLeft: "2px solid #cdcdcd",
          }}
          onMouseDown={onToggleEditPanel}
        >
          <WrapTooltipSvg>
            {!displayEditPanel ? (
              <svg viewBox="0 0 24 24" strokeWidth={0.5}>
                <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"></path>
              </svg>
            ) : (
              <svg viewBox="0 0 24 24">
                <path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z"></path>
              </svg>
            )}
          </WrapTooltipSvg>
          {!displayEditPanel ? (
            <OnHoverContent style={{width: 90, right: 0}}>
              More Options
            </OnHoverContent>
          ) : undefined}
        </Content>
      ) : undefined}

      {popperOpen.status && popperOpen.type === "set-position" ? (
        <TooltipSetPosition
          offset={{x: 40, y: 50}}
          width={170}
          height={200}
          onClose={onClosePopper}
        />
      ) : undefined}
    </div>
  );
};

const Layout = styled.div`
  display: flex;
  max-width: 180px;
  overflow-x: scroll;
  height: 35px;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // // important
  // &:after {
  //   content: "";
  //   display: table;
  //   clear: both;
  // }
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8d91dfff; /* Thumb color */
  }
  &::-webkit-scrollbar-track {
    background: #dedede; /* Track color */
  }
`;

const Icon = styled.div`
  display: flex;
  fill: ${({theme}) => theme.svgIconColor};
`;

// const OnHoverContent = styled.div`
//   width: auto;
//   bottom: -30px;
//   ${TooltipStyles}
// `;

// // Create a new styled component by extending the base component and overriding the left property
// const CustomOnHoverContent = styled.div`
//   width: auto;
//   bottom: -30px;
//   left: auto;
//   ${TooltipStyles}
// `;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeSlide: selectActiveSlide(designTemplate),
    fieldID: selectActiveFieldProps(designTemplate).key,
    slideID: selectActiveSlideID(designTemplate),
    zIndex: selectActiveFieldProps(designTemplate)?.styles?.zIndex,
    textEditing: selectEnableTextEdit(designTemplate),
    displayEditPanel: selectEditPanelStatus(designTemplate),
    popperOpen: selectTooltipPopperStatus(designTemplate),
  };
};

export default connect(mapStateToProps, {
  duplicateField,
  removeField,
  toggleEditPanel,
  storeActionsUndoRedo,
  toggleTooltipPopper,
  zIndexToFront,
  zIndexToBack,
  storeFieldUndoRedo
})(TooltipItemsHoc);
