import React, {useState} from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {Accordion, Expand, WrapBox} from "../common/AccordionStyles";
import HeaderTypes from "./HeaderTypes";
import {Title} from "../../shared/reuseStyles/Title";
import {WrapColorBox} from "../common/WrapColorStyles";
import Switch from "../../shared/inputs/Switch";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const EditTableHeader = ({
  styles,
  slideID,
  fieldID,
  updateFieldStyles,
  updateFieldCollaboration,
  storeFieldUndoRedo,
}) => {
  const [openBox, setOpenBox] = useState(true);
  const windowSize = getWindowSize();

  const onUpdateStyles = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const onEmitValue = (type, value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onChangeBoldness = () => {
    const fontWeightAsNumber = Number(styles?.headerFontWeight);
    const value = fontWeightAsNumber <= 500 ? "700" : "400";
    onUpdateStyles("headerFontWeight", value);
    onEmitValue("headerFontWeight", value);
  };

  const onChangeBgColor = (value) => {
    onUpdateStyles("headerBg", value);
  };

  const onChangeHeaderColor = (value) => {
    onUpdateStyles("headerColor", value);
  };

  const onTxtColorDebounce = (value) => {
    onUpdateStyles("headerColor", value);
    onEmitValue("headerColor", value);
  };

  const onBgColorDebounce = (value) => {
    onUpdateStyles("headerBg", value);
    onEmitValue("headerBg", value);
  };

  return (
    <>
      {windowSize.width >= 850 ? (
        <Accordion onClick={() => setOpenBox(!openBox)}>
          <Title>Header</Title>
          <Expand>{openBox ? "-" : "+"}</Expand>
        </Accordion>
      ) : undefined}
      {openBox || windowSize.width < 850 ? (
        <WrapBox>
          <div style={{paddingBottom: "1.5em"}}>
            <Switch
              title="Bold"
              value={styles.headerFontWeight == "700" ? true : false}
              onItemChange={onChangeBoldness}
              checked={styles.headerFontWeight == "700" ? true : false}
            />
          </div>

          <div style={{paddingBottom: "1.5em"}}>
            <WrapColorBox>
              <Title>Color</Title>
              <PopoverColorBox
                value={styles?.headerColor}
                updateFieldStyles={onChangeHeaderColor}
                onDebounce={onTxtColorDebounce}
              />
            </WrapColorBox>
          </div>

          <div style={{paddingBottom: "1.5em"}}>
            <WrapColorBox>
              <Title>Background</Title>
              <PopoverColorBox
                value={styles?.headerBg}
                updateFieldStyles={onChangeBgColor}
                onDebounce={onBgColorDebounce}
              />
            </WrapColorBox>
          </div>

          <HeaderTypes />
        </WrapBox>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditTableHeader);
