import React, {useRef, useState} from "react";
import styled from "styled-components";
import {Modal} from "../../../../../oat-ui";
import {Button} from "../../../../../oat-ui";

const ShowTemplateLink = ({user, selectedItem, close}) => {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  const handleCopy = () => {
    const urlLink = inputRef.current.innerHTML;
    navigator.clipboard.writeText(urlLink);
    setCopied(true);
  };

  return (
    <Modal width={440} height={80} close={close}>
      <Wrapper>
        <WrapInput>
          <LinkInput ref={inputRef}>
            {window.location.origin.toString() + `/u/${user}/p/${selectedItem}`}
          </LinkInput>
          <WrapButton>
            <Button
              styles={{
                width: 104,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                background: !copied ? undefined : "#26a769",
              }}
              onClick={handleCopy}
            >
              {!copied ? "Copy" : "Copied"}
            </Button>
          </WrapButton>
        </WrapInput>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const WrapInput = styled.div`
  display: flex;
  padding: 0 16px;
  border-radius: 8px;
`;

const LinkInput = styled.div`
  background: #efefef;
  font-size: 1rem;
  flex: 1;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 5px;
  color: #232323;
  white-space: pre;
  overflow: hidden;
  flex: 3;
  max-width: 295px;
`;

const WrapButton = styled.div`
  flex: 1;
`;

export default ShowTemplateLink;
