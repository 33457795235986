import {connect} from "react-redux";
import React from "react";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../../../store/selectors/template/template.selector";
import {useRotateLabels} from "../hooks/useRotateLabels";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {chartDefaultFontSize} from "../../chartDefaultFontSize";

export const rotatedDeg = -45;
const YCategoryLabels = ({
  labels,
  barMargin,
  barWidth,
  seriesLength,
  styles,
  tempSize,
  tempScale,
  slideID,
  fieldID,
  edgeSpace,
}) => {
  const {fontFamily, categoryColor, categoryFontSize, scaleDistance} = styles || {};
  const categoryLabelHeightGap = 4 / tempScale;

  useRotateLabels({
    active: {
      slideID,
      fieldID,
    },
    dataSet: labels,
    fontSize: categoryFontSize,
    fontFamily,
    barMargin,
    barSeriesWidth: barWidth * seriesLength,
    rotateText: styles.rotateCategory,
    tempLayout: {
      size: {
        w: tempSize.w,
        h: tempSize.h,
      },
      scale: tempScale,
    },
  });

  return (
    <g>
      {labels && labels.map((item, index) => {
        return (
          <g key={index}>
            <text
              style={{
                fontSize: `${categoryFontSize / chartDefaultFontSize}em`, // categoryFontSize
                fontFamily,
                alignmentBaseline: "central",
                dominantBaseline: "central",
                textAnchor: "end",
                fill: categoryColor,
                transform: `translate(${0 - scaleDistance}px, ${
                  index !== 0
                    ? (barWidth * seriesLength + barMargin) * index +
                      edgeSpace +
                      barWidth * (seriesLength / 2)
                    : edgeSpace + barWidth * (seriesLength / 2)
                }px)`,
              }}
              data-z-index="10"
            >
              {item}
            </text>
          </g>
        );
      })}
    </g>
  );
};

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(YCategoryLabels);
