import React from "react";
import styled from "styled-components";
import {connect, batch} from "react-redux";
import {selectActiveBgColor} from "../../../../../store/selectors/fields.selector";
import {updateBackgroundProps} from "../../../../../store/actions/fields/background.action";
import DeskGradientColors from "../shared/colorPicker/DeskGradientColors";
import {InputName, WrapColorType} from "./common/WrapColorStyles";
import {Title} from "../shared/reuseStyles/Title";
import DropDown from "../../../../../ui/inputs/DropDown";
import PopoverColorBox from "../shared/colorPicker/PopoverColorBox";
import {storeBackgroundUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlideID} from "../../../../../store/selectors/template/slide.selector";
import Switch from "../shared/inputs/Switch";
import {useSocket} from "../../../../../webSocket/useSocket";
import {PanelHeader} from "./panelStyles";

const bgTypes = [
  {value: "center", display: "Center"},
  {value: "linear", display: "Linear"},
];

const BackgroundPanel = ({
  slideID,
  colors,
  updateBackgroundProps,
  storeBackgroundUndoRedo,
}) => {
  const {emitSocketEvents} = useSocket() || {};

  const handleColorChange = (type, value) => {
    updateBackgroundProps({
      slideID,
      type,
      value,
    });
  };

  const handleSocketEmit = (type, value) => {
    const payload = {type, value};
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "changed-background", item: payload});
    }
  };

  const toggleGradientStatus = () => {
    const value = colors?.gradient ? false : true;
    handleColorChange("gradient", value);
    storeBackgroundUndoRedo("gradient", value);
    handleSocketEmit("gradient", value);
  };

  const handleBg1 = (value) => {
    handleColorChange("bg1", value);
  };

  const handleBg2 = (value) => {
    handleColorChange("bg2", value);
  };

  const handleGradientAng = (value) => {
    handleColorChange("bgAng", value);
  };

  const handleGradientType = async (value) => {
    await handleColorChange("type", value);
    await storeBackgroundUndoRedo("type", value);
    handleSocketEmit("type", value);
  };

  const onColorSelectionEndBg1 = (value) => {
    storeBackgroundUndoRedo("bg1", value);
    handleSocketEmit("bg1", value);
  };

  const onColorSelectionEndBg2 = (value) => {
    storeBackgroundUndoRedo("bg2", value);
    handleSocketEmit("bg2", value);
  };

  return (
    <>
      <div style={{padding: "0.7em 0 0"}}>
        <PanelHeader style={{paddingBottom: "1.6em"}}>Background</PanelHeader>
        <WrapColorType>
          <Switch
            title="Gradient"
            value={colors?.gradient}
            checkValue={colors?.gradient}
            onItemChange={toggleGradientStatus}
            checked={colors?.gradient}
          />
        </WrapColorType>

        <div style={{paddingBottom: "2em"}}>
          {!colors?.gradient ? (
            <ColorsSection>
              <InputName>Colors</InputName>
              <PopoverColorBox
                value={colors?.bg1}
                updateFieldStyles={handleBg1}
                onDebounce={onColorSelectionEndBg1}
              />
            </ColorsSection>
          ) : (
            <DeskGradientColors
              colorOne={colors?.bg1}
              colorTwo={colors?.bg2}
              angle={colors?.bgAng}
              updateColorOne={handleBg1}
              updateColorTwo={handleBg2}
              updateFieldStyles={handleGradientAng}
              gradientType={colors?.type}
              onDebounceColorOne={onColorSelectionEndBg1}
              onDebounceColorTwo={onColorSelectionEndBg2}
            />
          )}
        </div>

        {colors?.gradient ? (
          <div style={{display: "flex", paddingBottom: "1em"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <Title>Gradient Type</Title>
            </div>
            <WrapDropDown>
              <DropDown
                onSelect={handleGradientType}
                selected={colors?.type}
                options={bgTypes}
                minWidth={120}
              />
            </WrapDropDown>
          </div>
        ) : undefined}
      </div>
    </>
  );
};

const ColorsSection = styled.div`
  display: flex;
  align-items: center;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.deskGap};
  margin-top: 1rem;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideID(state.designTemplate),
    colors: selectActiveBgColor(state.designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    storeBackgroundUndoRedo,
    updateBackgroundProps,
  }))
)(BackgroundPanel);
