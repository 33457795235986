import {OatDefaultTheme, themes} from "../../reducers/data/themeBuilder.data";
import {createSlideID} from "../../reducers/functions/fields/utils";
import {
  FETCH_TEMPLATE_ERR,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_INIT,
  SET_TEMP_SCALE,
  RESIZE_TEMPLATE,
  EDIT_TEMPLATE_NAME,
  SET_USER_TEMPLATE_ID,
  TOGGLE_TEMPLATE_SELECTION_MODAL,
  SET_SEARCHED_TEMPLATES,
  CHANGE_TEMP_VISIBILITY,
  RESET_STATE,
  SET_TEMP_PASSCODE,
  MANAGE_TEMPLATE_NOTIFICATION,
  SHARE_AS_GUEST,
  UPDATE_GUEST_ACCESS_LEVEL,
  SET_GUEST_LIST,
  CANVAS_SIZE,
  SET_USED_FONTS,
  INIT_PLAY_GROUND
} from "../actionTypes";

export function setCanvasSize(payload) {
  return {
    type: CANVAS_SIZE,
    payload,
  };
}

export function fetchTemplateInit() {
  return {
    type: FETCH_TEMPLATE_INIT,
  };
}

export function fetchTemplateSuccess({
  template,
  size,
  orgTempSize,
  ratio,
  themes,
  tempTitle,
  tempID,
  tempSourceID,
  refTemp,
  device,
  mobileSize,
  displaySlideListOnLoad,
  slideTransition,
  sectionList,
  owner,
  workspaceID,
  folderID,
  folderType,
  visibility,
  tempLng,
  tempInfo,
  usedFontList,
  slideID,
  passcode,
  workspaceMembers,
  workspacePlan,
  tempNotiInfo,
  embedType,
  guestUsers,
  category,
  versions,
  isDraft,
  viewOnDesignPage,
  isGuestUser
}) {
  return {
    type: FETCH_TEMPLATE_SUCCESS,
    payload: template,
    size: device === "desktop" ? size : mobileSize,
    // {w: 1080, h: 1400}, // 536, 297
    orgTempSize,
    ratio,
    themes,
    tempTitle,
    tempID,
    tempSourceID,
    refTemp,
    displaySlideListOnLoad,
    slideTransition,
    sectionList,
    owner,
    workspaceID,
    folderID,
    folderType,
    visibility,
    tempLng,
    tempInfo,
    usedFontList,
    slideID,
    passcode,
    workspaceMembers,
    workspacePlan,
    tempNotiInfo,
    embedType,
    guestUsers,
    category,
    versions,
    isDraft,
    viewOnDesignPage,
    isGuestUser
  };
}

export function createBlankTemplate(size) {
  return {
    type: FETCH_TEMPLATE_SUCCESS,
    payload: [
      {
        id: createSlideID(),
        bg: {
          bg1: "rgb(77, 235, 222)",
          bg2: "#fff",
          type: "linear",
          bgAng: 130,
          gradient: false,
        },
        fields: [],
        markedDone: false,
        theme: OatDefaultTheme,
        title: "",
        notes: "",
        markedDone: false,
        comments: [],
      },
    ],
    size,
    tempTitle: "Untitled Template",
    themes: themes,
  };
}

export function fetchTemplateErr(error) {
  return {
    type: FETCH_TEMPLATE_ERR,
    error,
  };
}

// place where initializing chosen template desings
export function setSearchedTemplates({template, refTempID, size, orgTempSize}) {
  const {details, themes, usedFontList} = template;
  return {
    type: SET_SEARCHED_TEMPLATES,
    designs: details,
    themes,
    refTempID,
    size,
    orgTempSize,
    usedFontList
  };
}

export function passcodeStatus(payload) {
  return {
    type: SET_TEMP_PASSCODE,
    payload,
  };
}

const fetchTemplateDetails = async (item, device) => {
  try {
    let templateLayout;
    // if premium variable is either true or false & PK has words "#details#" it is coming from template creator (admin)
    if (
      item.PK.includes("#details#") &&
      (item.premium !== undefined || item.premium !== null)
    ) {
      // if admin creating from admin panel, no cdn needed
      templateLayout =
        device === "desktop"
          ? `https://oat-admin-templates.s3.amazonaws.com/${item.template}`
          : `https://oat-admin-templates.s3.amazonaws.com/${item.mobileTemp}`;
    } else {
      templateLayout =
        device === "desktop"
          ? `https://oat-users.s3.amazonaws.com/${item.template}`
          : `https://oat-users.s3.amazonaws.com/${item.mobileTemp}`;
    }
    const data = await fetch(templateLayout, {
      cache: "no-store",
    })
      .then((res) => res.json())
      // .then((data) => )
      .catch((e) => {
        // if detected different CORS
        return {
          status: "failed",
        };
      });
    return data;
  } catch (e) {
    // handle error logic
    // console.log(e)
  }
};

export const resizeTemplate = ({resizeFrom, resizeTo}) => {
  return {
    type: RESIZE_TEMPLATE,
    resizeFrom,
    resizeTo,
  };
};

export const fetchProfileTemplate = (url) => {
  return async (dispatch) => {
    dispatch(fetchTemplateInit());
    try {
      const templateInfo = await fetch(url)
        .then((res) => res.json())
        .catch((e) => dispatch(fetchTemplateErr(e)));

      if (templateInfo.status === "success") {
        const tempInfo = templateInfo.response;
        const device =
          tempInfo.mobileView && window.innerWidth < 768 ? "mobile" : "desktop";
        const size = tempInfo.size;
        await fetchTemplateDetails(tempInfo, device)
          .then((content) => {
            if (content.status === "failed") {
              dispatch(
                fetchTemplateErr("You don't have permission to access.")
              );
            } else {
              dispatch(
                fetchTemplateSuccess({
                  template: content.details,
                  size,
                  orgTempSize: content.orgTempSize,
                  ratio: tempInfo.ratio,
                  themes: content.themes,
                  tempTitle: tempInfo.title,
                  tempDescription: content.tempDescription, // for profile
                  tempID: tempInfo.tempID,
                  device,
                  // mobileSize: templateInfo.mobileSize,
                  slideTransition: tempInfo.transition,
                  sectionList: content.sectionList,
                  usedFontList: content.usedFontList,
                })
              );
            }
          })
          .catch((e) => dispatch(fetchTemplateErr(e)));
      } else {
        dispatch(fetchTemplateErr("Hmm.. something isn't right."));
      }
    } catch (e) {
      dispatch(fetchTemplateErr(e));
    }
  };
};

// fetching template besides canvas page
export const fetchTemplate = ({api, embedded, slideID}) => {
  return async (dispatch) => {
    dispatch(fetchTemplateInit());
    try {
      const templateInfo = await fetch(api)
        .then((res) => res.json())
        .catch((e) => {
          dispatch(fetchTemplateErr("Something went wrong"))
        });

      if (templateInfo.status === "requires-auth") {
        dispatch(
          passcodeStatus({
            enabled: true,
            auth: false,
            tempID:
              templateInfo.response && templateInfo.response.tempID
                ? templateInfo.response.tempID
                : templateInfo.tempID,
          })
        );
        return;
      } else if (templateInfo.status === "success") {
        const tempInfo = templateInfo.response;
        const device =
          tempInfo.mobileView && window.innerWidth < 768 ? "mobile" : "desktop";
        const mobileTempSize = {w: 1080, h: 1400};
        // only load mobile layout on canvas layout page: (mobileView is only true on canvas page)
        const size = tempInfo.size;

        await fetchTemplateDetails(tempInfo, device)
          .then((content) => {
            if (content.status === "failed") {
              dispatch(
                // S3 permission denided
                fetchTemplateErr("Something went wrong.")
              );
            } else {
              dispatch(
                fetchTemplateSuccess({
                  template: content.details,
                  size,
                  orgTempSize: content.orgTempSize,
                  ratio: tempInfo.ratio,
                  themes: content.themes,
                  tempTitle: tempInfo.title,
                  tempDescription: content.tempDescription, // for profile
                  tempID: tempInfo.tempID,
                  device,
                  // mobileSize: templateInfo.mobileSize,
                  slideTransition: tempInfo.transition,
                  sectionList: content.sectionList,
                  usedFontList: content.usedFontList,
                  slideID,
                  embedType: embedded ? "with-panel" : "", // tempInfo.embedType,
                })
              );
            }
          })
          .catch((e) => dispatch(fetchTemplateErr(e)));
      } else if (templateInfo.status === "private") {
        dispatch(fetchTemplateErr(templateInfo.response));
      } else {
        dispatch(fetchTemplateErr("Hmm.. something isn't right."));
      }
    } catch (e) {
      dispatch(fetchTemplateErr("Something went wrong."));
    }
  };
};

// fetching template on canvas page
export const fetchTemplateCanvas = ({
  url,
  mobileViewEditing = false,
  displaySlideListOnLoad = false,
  slideID,
  username
}) => {
  return async (dispatch) => {
    dispatch(fetchTemplateInit());
    try {
      const templateInfo = await fetch(url, {
        cache: "no-store",
      })
        .then((res) => res.json())
        .catch((e) => dispatch(fetchTemplateErr(e)));

      if (templateInfo.status === "success") {
        const tempInfo = templateInfo.response;
        const device =
          tempInfo.mobileView && window.innerWidth < 768 ? "mobile" : "desktop";
        const mobileTempSize = {w: 1080, h: 1400};
        // only load mobile layout on canvas layout page: (mobileView is only true on canvas page)
        const size = !mobileViewEditing ? tempInfo.size : mobileTempSize;
        let orgTempSize;

        // checking length !important
        const findGuestUser = tempInfo?.guestUsers?.length > 0 && tempInfo?.guestUsers.find((item) => item.username === username)
        const isGuestUser = typeof findGuestUser === 'object' && Object.keys(findGuestUser)?.length > 0 ? true : false;
        await fetchTemplateDetails(tempInfo, device)
          .then((content) => {
            if (content.status === "failed") {
              dispatch(
                fetchTemplateErr("You don't have permission to access.")
              );
            } else {
              orgTempSize = content.orgTempSize;
              dispatch(
                fetchTemplateSuccess({
                  ...tempInfo.ratio,
                  template: content.details, // testdata.details,
                  size,
                  orgTempSize: content.orgTempSize,
                  ratio: tempInfo.ratio,
                  themes: content.themes,
                  sections: [],
                  usedFontList: [
                    {
                      fontFamily: "Open Sans",
                      src: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
                      format: null,
                      fontType: "Regular",
                      custom: false,
                    },
                  ],
                  tempTitle: tempInfo.title,
                  tempDescription: content.tempDescription, // for profile
                  tempID: tempInfo.tempID,
                  tempSourceID: tempInfo.tempSourceID,
                  refTemp: tempInfo.refTemp,
                  device,
                  mobileSize: tempInfo.mobileSize,
                  displaySlideListOnLoad,
                  slideTransition: tempInfo.transition,
                  sectionList: content.sectionList,
                  sharedUserList: tempInfo.sharedTo,
                  owner: tempInfo.owner,
                  workspaceID: !isGuestUser ? tempInfo.workspaceID : undefined,
                  isGuestUser,
                  folderID: tempInfo.folderID,
                  folderType: tempInfo.folderType,
                  visibility: tempInfo.visibility,
                  tempLng: tempInfo.lng, // for admin update temp
                  usedFontList: content.usedFontList,
                  slideID,
                  passcode: tempInfo.passcode,
                  workspaceMembers: tempInfo.wsMembers,
                  workspacePlan: tempInfo.wsPlan,
                  tempNotiInfo: tempInfo.tempNotiInfo,
                  guestUsers: tempInfo.guestUsers,
                  category: tempInfo.category,
                  versions: tempInfo.versions,
                  isDraft: tempInfo.isDraft, // FOR Admin
                  viewOnDesignPage: true,
                })
              );
            }
          })
          .then(() => {
            if (mobileViewEditing && tempInfo.mobileTemp !== "") {
              dispatch(
                resizeTemplate({
                  resizeFrom: tempInfo.size,
                  resizeTo: mobileTempSize,
                })
              );
            }
          })
          .then(() =>
            fetch(
              `https://d1nryft0rd5acr.cloudfront.net/${tempInfo.refTemp.ID}-${tempInfo.refTemp.themeSlug}.json`
              // `https://oat-admin-templates.s3.amazonaws.com/${tempInfo.refTemp.ID}-${tempInfo.refTemp.themeSlug}.json`,
              // {
              //   cache: "no-store",
              // }
            )
              .then((res) => res.json())
              .then((data) => {
                dispatch(
                  setSearchedTemplates({
                    template: data,
                    refTempID: tempInfo.refTemp.ID,
                    size,
                    orgTempSize,
                  })
                );
              })
              .catch((e) => {
                dispatch(
                  fetchTemplateErr("You don't have permission to view.")
                );
              })
          )
          .catch((e) => {
            dispatch(fetchTemplateErr(e))
          });
      } else {
        dispatch(fetchTemplateErr("Hmm.. something isn't right."));
      }
    } catch (e) {
      dispatch(
        fetchTemplateErr(
          "Something went wrong. If the problem persists, please contact the support team."
        )
      );
    }
  };
};

export const setTempScale = (value) => {
  return {
    type: SET_TEMP_SCALE,
    value,
  };
};

export const editTemplateName = (value) => {
  return {
    type: EDIT_TEMPLATE_NAME,
    value,
  };
};

export const setUserTemplateID = (tempID) => {
  return {
    type: SET_USER_TEMPLATE_ID,
    tempID,
  };
};

export const toggleTemplateSelectionModal = () => {
  return {
    type: TOGGLE_TEMPLATE_SELECTION_MODAL,
  };
};

export const changeTempVisibility = (payload) => {
  return {
    type: CHANGE_TEMP_VISIBILITY,
    payload,
  };
};

export const dispatchResetState = () => {
  return {
    type: RESET_STATE,
  };
};

export const loadTemplate = (templateInfo) => {
  return async (dispatch) => {
    dispatch(fetchTemplateInit());
    try {
      if (templateInfo.status === "success") {
        const tempInfo = templateInfo.response;
        const device =
          tempInfo.mobileView && window.innerWidth < 768 ? "mobile" : "desktop";

        await fetchTemplateDetails(tempInfo, device)
          .then((content) => {
            if (content.status === "failed") {
              dispatch(
                fetchTemplateErr("You don't have permission to access.")
              );
            } else {
              dispatch(
                passcodeStatus({
                  enabled: true,
                  auth: true,
                })
              );
              dispatch(
                fetchTemplateSuccess({
                  template: content.details,
                  size: tempInfo.size,
                  orgTempSize: content.orgTempSize,
                  ratio: tempInfo.ratio,
                  themes: content.themes,
                  tempTitle: tempInfo.title,
                  tempDescription: content.tempDescription, // for profile
                  tempID: tempInfo.tempID,
                  device,
                  // mobileSize: templateInfo.mobileSize,
                  slideTransition: tempInfo.transition,
                  sectionList: content.sectionList,
                  usedFontList: content.usedFontList,
                })
              );
            }
          })
          .catch((e) => dispatch(fetchTemplateErr(e.message)));
      } else {
        dispatch(fetchTemplateErr("Hmm.. something isn't right."));
      }
    } catch (e) {
      dispatch(fetchTemplateErr("Something went wrong."));
    }
  };
};

export const manageTemplateNotification = (payload) => {
  return {
    type: MANAGE_TEMPLATE_NOTIFICATION,
    payload,
  };
};

export const shareAsGuest = (payload) => {
  return {
    type: SHARE_AS_GUEST,
    payload,
  };
};

export const updateGuestAccessLevel = (payload) => {
  return {
    type: UPDATE_GUEST_ACCESS_LEVEL,
    payload,
  };
};

export const setGuestList = (payload) => {
  return {
    type: SET_GUEST_LIST,
    payload,
  };
};

export function setUsedFonts() {
  return {
    type: SET_USED_FONTS,
  };
}

export const initPlayGround = () => {
  return {
    type: INIT_PLAY_GROUND
  };
};