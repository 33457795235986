import React from "react";
import styled from "styled-components";
import {Wrapper, Tabs, TabItem, WrapTabs} from "../common/TabStyles";

const ImageTabs = ({onSelectTab, selectedTab}) => {
  return (
    <Wrapper>
      <WrapTabs>
        <Tabs>
          <TabItem
            selectedTool={"deskImgEffects" === selectedTab}
            onClick={() => onSelectTab("deskImgEffects")}
          >
            <span>Effects</span>
          </TabItem>
          <TabItem
            selectedTool={"deskImgFilters" === selectedTab}
            onClick={() => onSelectTab("deskImgFilters")}
          >
            <span>Filters</span>
          </TabItem>
          <TabItem
            selectedTool={"deskImgFrames" === selectedTab}
            onClick={() => onSelectTab("deskImgFrames")}
          >
            <span>Frames</span>
          </TabItem>
        </Tabs>
        <TabLine active={selectedTab} role="presentation" />
      </WrapTabs>
    </Wrapper>
  );
};

const TabLine = styled.hr`
  height: 0.12rem;
  width: 33.3%;
  margin: 0;
  background: #4b50ea;
  border: none;
  transition: 0.3s ease-in-out;
  ${({active}) => {
    switch (active) {
      case "deskImgFilters":
        return `margin-left: 33.3%;`;
      case "deskImgFrames":
        return `margin-left: 66.6%;`;
      default:
        return `margin-left: 0%;`;
    }
  }}
`;

export default ImageTabs;
