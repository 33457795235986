import {createSelector} from "reselect";
import {selectTemplate} from "./template/template.selector";

export const selectActiveField = (state) => state.activeField;
export const selectedFieldType = (state) => state.selectedFieldType;

export const selectActiveSlideAndField = (state) => {
  return {
    activeField: state.activeField,
    activeSlide: state.activeSlide,
    activeSlideID: state.activeSlideID,
    tempScale: state.layout.tempScale,
  };
};

export const selectActiveFieldProps = createSelector(
  selectActiveSlideAndField,
  selectTemplate,
  (activeItems, template) => {
    const {activeField, activeSlide, activeSlideID} = activeItems;
    const slideIndex = template && template.findIndex((item, i) => item.id === activeSlideID);
    return (slideIndex !== null || slideIndex !== undefined) && 
      activeField !== -1 && template[slideIndex]?.fields?.[activeField]?.styles 
        ? template[slideIndex].fields[activeField] : {};
  }
);

export const selectActiveFieldStyles = createSelector(
  selectActiveSlideAndField,
  selectTemplate,
  (activeItems, template) => {
    const {activeField, activeSlide, activeSlideID} = activeItems;
    const slideIndex = template && template.findIndex((item, i) => item.id === activeSlideID);
    return (slideIndex !== null || slideIndex !== undefined) && activeField !== -1
     && template[slideIndex]?.fields?.[activeField]?.styles  ? template[slideIndex].fields?.[activeField]?.styles : {};
  }
);

export const selectCachedFieldStyles = (state) => state.cachedFieldStyles;

// text
export const selectEnableTextEdit = (state) => state.enableTextEdit;

// image
export const selectCropImage = (state) => state.cropImage;
export const selectReplaceImage = (state) => state.replaceImage;

// chart
export const selectEditChartInfoStatus = (state) => state.viewChartInfo;

// table
export const selectTableResizeProps = (state) => state.tableResizeProps;

// undo - redo
export const selectUndoRedoHistory = (state) => state.undoRedo;
export const selectUndoRedoToEmitSocket = (state) =>
  state.emitUndoRedoItemToSocket;

// background
export const selectActiveBgColor = ({template, activeSlide}) => {
  return activeSlide !== -1 && template[activeSlide].bg
    ? template[activeSlide].bg
    : "";
};
