import React, {useState} from "react";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import SelectedChart from "./SelectedChartPanel";
// import EditChartTable from "./editChartData/EditChartTable";
// import ChartTabs from "./ChartTabs";
import styled from "styled-components";
import {SplitLine} from "../common/SplitLine";
import LayoutPosition from "../common/LayoutPosition";

const EditChartPanel = ({activeField}) => {
  const {styles, subtype} = activeField || {};
  const [activeTab, setActiveChart] = useState("addChartData");

  const handleTabSelection = (value) => {
    setActiveChart(value);
  };

  return (
    <Layout>
      {/* <ChartTabs selectedTab={activeTab} onSelectTab={handleTabSelection} /> */}
      <div style={{padding: "1.5em 0"}}>
        <LayoutPosition rotate={styles?.rotate} locked={styles?.lock} />

        <SplitLine />
        <SelectedChart chartType={subtype} styles={styles} />
        {/* </>
        )} */}
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  flex-direction: column;
  z-index: 10;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-bottom: 100px;
`;

const mapStateToProps = (state) => {
  return {
    activeField: selectActiveFieldProps(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(EditChartPanel);
