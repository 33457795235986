import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SpecialButton from "../../../../ui/inputs/SpecialButton";
import {ArrowLeft, ArrowRight} from "../../../../ui/svg/Arrow";
import {Carousel, Spinner} from "../../../../../oat-ui";
import {
  CarouselFrame,
  WrapCanvas,
  CanvasSection,
} from "../../canvasPanel/CanvasPanelStyles";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {setSlideTransition} from "../../../../store/actions/template/slide.action";
import {selectSlideTransition} from "../../../../store/selectors/template/slide.selector";
import {useParams} from "react-router-dom";
import {red4} from "../../../../../oat-color-variables";

const transitionOptions = [
  {value: "no-transition", display: "No Transition"},
  {value: "classic-transition", display: "Classic Transition"},
  {value: "overlay-transition", display: "Overlay Transition"},
  {value: "fade-in-transition", display: "Fade-in Transition"},
];

const slides = [
  {
    id: "oat-121",
    bg: {bg1: "#fffa98cf", bg2: "", type: "", bgAng: 0},
    fields: [],
  },
  {
    id: "oat-122",
    bg: {bg1: "pink", bg2: "", type: "", bgAng: 0},
    fields: [],
  },
  {
    id: "oat-123",
    bg: {bg1: "cyan", bg2: "", type: "", bgAng: 0},
    fields: [],
  },
];

const TransitionType = ({selected, option, onSelectTransition}) => {
  const handleClick = () => {
    onSelectTransition(option);
  };
  return (
    <Option
      selected={selected === option.value ? true : false}
      onClick={handleClick}
    >
      {option.display}
    </Option>
  );
};

const SelectTransition = ({
  setSlideTransition,
  slideTransition,
  user,
  close,
}) => {
  const [processing, setProcessing] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [selectedTransition, setSelectedTransition] = useState(
    slideTransition ? slideTransition : "classic-transition"
  );
  let params = useParams();
  const {tempID} = params;

  useEffect(() => {
    setActiveIndex(0);
  }, [slides, selectedTransition]);

  const handleBackward = () => {
    if (activeIndex === 0) return;
    changeIndex(activeIndex - 1);
  };

  const handleForward = () => {
    if (activeIndex === slides.length - 1) return;
    changeIndex(activeIndex + 1);
  };

  const changeIndex = (value) => {
    setActiveIndex(value);
  };

  const saveSelection = async () => {
    setSlideTransition(selectedTransition);
    if (!processing) {
      setProcessing(true);
      if (error.status) {
        setError({
          status: false,
          message: null,
        });
      }
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates/slide-transition`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempID,
            transition: selectedTransition,
          }),
        }
      )
      .then((res) => res.json())
      .then(async(data) => {
        if (data.status === "success") {
          setProcessing(false);
          await close();
        } else {
          setProcessing(false);
          setError({
            status: true,
            message: data.message,
          });
        }
      })
      .catch((e) => {
        setProcessing(false);
        setError({
          status: true,
          message: "Something went wrong.",
        });
      });
    }
  };

  const onSelectTransition = (option) => {
    setSelectedTransition(option.value);
  };

  return (
    <Layout>
      <Wrapper>
        <WrapItem>
          {selectedTransition === "classic-transition" ? (
            <Carousel
              activeIndex={activeIndex}
              duration={0.4}
              timing="ease-in-out"
              $carouselType={selectedTransition}
            >
              {slides &&
                slides.map((slide, index) => {
                  if (selectedTransition === "classic-transition") {
                    return (
                      <CarouselFrame key={slide.id}>
                        <WrapCanvas style={{width: "100%", height: "100%"}}>
                          <CanvasSection style={{width: "100%"}}>
                            <WrapFields style={{background: slide.bg.bg1}}>
                              <h1>Design {index + 1}</h1>
                            </WrapFields>
                          </CanvasSection>
                        </WrapCanvas>
                      </CarouselFrame>
                    );
                  }
                })}
            </Carousel>
          ) : (
            <Carousel
              activeIndex={activeIndex}
              duration={0.6}
              timing="ease-in-out"
              $carouselType={selectedTransition}
              styles={{
                width: "100%",
                height: 350,
                margin: 0,
                overflow: "hidden",
                borderRadius: 0,
              }}
            >
              {slides &&
                slides.map((slide, index) => {
                  if (
                    selectedTransition === "no-transition" ||
                    selectedTransition === "fade-in-transition"
                  ) {
                    return (
                      <CarouselFrame
                        key={slide.id}
                        style={{
                          WebkitTransition:
                            selectedTransition === "fade-in-transition"
                              ? "opacity 0.5s linear"
                              : undefined,
                          transition:
                            selectedTransition === "fade-in-transition"
                              ? "opacity 0.5s linear"
                              : undefined,
                          opacity: index === activeIndex ? 1 : 0,
                          position: "absolute",
                          top: 0,
                          zIndex: index === activeIndex ? 100 : 1,
                        }}
                      >
                        <WrapCanvas style={{width: "100%", height: "100%"}}>
                          <CanvasSection style={{width: "100%"}}>
                            <WrapFields style={{background: slide.bg.bg1}}>
                              <h1>Slide {index + 1}</h1>
                            </WrapFields>
                          </CanvasSection>
                        </WrapCanvas>
                      </CarouselFrame>
                    );
                  } else if (selectedTransition === "overlay-transition") {
                    return (
                      <CarouselFrame
                        aria-hidden={index === activeIndex ? false : true}
                        key={slide.id}
                        style={{
                          transition: `all ${0.6}s ease-out`,
                          transform: `translate(${
                            index === activeIndex
                              ? activeIndex * -100
                              : index < activeIndex
                              ? index * -100
                              : (index - 1) * -99
                          }%, 0px)`,
                        }}
                      >
                        <WrapCanvas
                          style={{
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <CanvasSection style={{width: "100%"}}>
                            <WrapFields style={{background: slide.bg.bg1}}>
                              <h1>Slide {index + 1}</h1>
                            </WrapFields>
                          </CanvasSection>
                        </WrapCanvas>
                      </CarouselFrame>
                    );
                  }
                })}
            </Carousel>
          )}

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TitleSection>
                <WrapSvg edge={activeIndex === 0} onClick={handleBackward}>
                  {ArrowLeft}
                </WrapSvg>
                <SlideToggleBar>
                  {activeIndex + 1} / {slides.length}
                </SlideToggleBar>
                <WrapSvg
                  edge={slides.length - 1 === activeIndex}
                  onClick={handleForward}
                >
                  {ArrowRight}
                </WrapSvg>
              </TitleSection>
            </div>
          </div>
        </WrapItem>

        <BottomSection>
          <SelectTransitionSection>
            {transitionOptions.map((option, i) => (
              <TransitionType
                option={option}
                key={i}
                selected={selectedTransition}
                onSelectTransition={onSelectTransition}
              />
            ))}
          </SelectTransitionSection>

          <div style={{display: "flex", alignItems: "center"}}>
            {processing ? (
              <WrapSpinner>
                <Spinner />
              </WrapSpinner>
            ) : undefined}

            {error.status ? <Error>{error.message}</Error> : undefined}

            <WrapButton>
              <SpecialButton onClick={saveSelection} disabled={processing}>
                Save
              </SpecialButton>
            </WrapButton>
          </div>
        </BottomSection>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  background: ${({theme}) => theme.deskPrimaryBg};
`;

const Wrapper = styled.div`
  user-select: none;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding: 8px 0;
  box-sizing: border-box;
  height: 100%;
`;

const WrapButton = styled.div`
  display: flex;
  width: 100px;
  height: 36px;
`;

const WrapItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SlideToggleBar = styled.div`
  font-size: 14px;
  color: #fff;
  padding: 6px 0px;
  min-width: 84px;
  display: flex;
  justify-content: center;
`;

const WrapFields = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  h1 {
    font-weight: 500;
  }
`;

const TitleSection = styled.div`
  background: #232323c4;
  border-radius: 18px;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
`;

const WrapSvg = styled.div`
  width: 30px;
  height: 30px;
  fill: #fff;
  opacity: ${({edge}) => (!edge ? 1 : 0.5)};
  cursor: ${({edge}) => (!edge ? "pointer" : "not-allowed")};
`;

const SelectTransitionSection = styled.div`
  margin-bottom: 24px;
  display: flex;
`;

const Option = styled.div`
  height: 32px;
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-weight: 500;
  font-size: 0.95rem;
  background: ${({theme, selected}) =>
    selected ? theme.secondaryThemeHover : undefined};
  opacity: ${({selected}) => (selected ? 1 : 1)};
  display: flex;
  align-items: center;
  padding: 2px 22px;
  border-radius: 38px;
  margin-bottom: 12px;
  cursor: pointer;
`;

const WrapSpinner = styled.div`
  transform: scale(0.7);
  margin-right: 14px;
`;

const Error = styled.div`
  color: ${red4};
  font-size: 0.95rem;
  margin-right: 18px;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    slideTransition: selectSlideTransition(state.designTemplate),
  };
};

export default connect(mapStateToProps, {setSlideTransition})(SelectTransition);
