import React, {useEffect} from "react";
import {CategorySection, WrapCategory} from "./folderStyles";
import {connect} from "react-redux";
import TemplateCategory from "./TemplateCategory";
import {selectActiveCategory} from "../../../store/selectors/adminPanel.selector";
import {
  fetchTempCategories,
  onCategoryChange,
} from "../../../store/actions/tempCategory.action";
import {useParams} from "react-router";

const categories = [
  {
    name: "Presentation (16x9)",
    ratio: "16x9",
    size: {w: 1920, h: 1080},
    value: "presentation",
  },
  {
    name: "Social Media (1x1)",
    ratio: "1x1",
    size: {w: 1080, h: 1080},
    value: "social-media",
  },
  {
    name: "Story (9x16)",
    ratio: "9x16",
    size: {w: 1080, h: 1920},
    value: "story",
  },
  // {
  //   name: "Layouts (16x9)",
  //   ratio: "16x9",
  //   size: {w: 1920, h: 1080},
  //   value: "layouts",
  // },
];

const Folders = ({activeCategory}) => {
  return (
    <CategorySection>
      <WrapCategory>
        {categories &&
          categories.map((item, i) =>
            item.value !== "layouts" ? (
              <TemplateCategory
                key={i}
                category={item}
                active={activeCategory}
              />
            ) : undefined
          )}
      </WrapCategory>
    </CategorySection>
  );
};

const mapStateToProps = (state) => {
  const {adminPanel} = state;
  return {
    activeCategory: selectActiveCategory(adminPanel),
  };
};

export default connect(mapStateToProps, null)(Folders);
