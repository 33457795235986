import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import ArrowBack from "../../../../../../../oat-svg-icons/icons/ArrowBack";
import {
  respondToThread,
  deleteSlideComment,
} from "../../../../../../store/actions/template/comment.action";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import {
  Line,
  WrapCommentArea,
  AddComment,
  WrapCommentBtn,
  CommentList,
  WrapSpinner,
} from "./commentStyles";
import {selectActiveSlideInfo} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectTempSourceID,
  selectTemplateID,
} from "../../../../../../store/selectors/template/template.selector";
import {createShortKey} from "../../../../../../store/reducers/functions/fields/utils";
import {Spinner} from "../../../../../../../oat-ui";
import DisplayComment from "./DisplayComment";
import DisplaySubcomment from "./DisplaySubcomment";
import {
  Mention,
  MentionsInput,
} from "react-mentions/dist/react-mentions.cjs.prod";
import {useSocket} from "../../../../../../webSocket/useSocket";

const lightTheme = {
  control: {
    backgroundColor: "transparent",
    fontWeight: "normal",
  },
  "&multiLine": {
    control: {
      fontFamily: "inherit",
      minHeight: 90,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      maxHeight: 90,
    },
    input: {
      height: 90,
      padding: 9,
      borderRadius: 6,
      resize: "none",
      boxSizing: "border-box",
      color: "inherit",
      fontFamily: "inherit",
      overflowY: "scroll",
      border: "1px solid #d2d2d2",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    width: 160,
    top: 85,
    left: 10,
    borderRadius: 6,
    height: 150,
    overflowY: "scroll",
    boxShadow: "0px 0px 2px 0px #939494",
    list: {
      backgroundColor: "#fff",
      fontSize: 14,
      margin: 10,
    },
    item: {
      padding: "5px 10px",
      boxSizing: "border-box",
      color: "#232323",
      borderRadius: 6,
      fontWeight: "normal",
      marginBottom: 6,
      "&focused": {
        backgroundColor: "#d3deff",
      },
    },
  },
};

export const darkTheme = {
  control: {
    backgroundColor: "transparent",
    fontWeight: "normal",
  },
  "&multiLine": {
    control: {
      fontFamily: "inherit",
      minHeight: 90,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      maxHeight: 90,
    },
    input: {
      height: 90,
      padding: 9,
      borderRadius: 6,
      resize: "none",
      boxSizing: "border-box",
      color: "inherit",
      fontFamily: "inherit",
      overflowY: "scroll",
      border: "1px solid #434255",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,
    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    width: 160,
    top: 85,
    left: 10,
    borderRadius: 6,
    height: 150,
    overflowY: "scroll",
    boxShadow: "0px 0px 2px 0px #939494",
    list: {
      backgroundColor: "#fff",
      fontSize: 14,
      margin: 10,
    },
    item: {
      padding: "5px 10px",
      boxSizing: "border-box",
      color: "#232323",
      borderRadius: 6,
      fontWeight: "normal",
      marginBottom: 6,
      "&focused": {
        backgroundColor: "#d3deff",
      },
    },
  },
};

const padding = "0 12px";
const CommentRespones = ({
  $panelHeight,
  respondToThread,
  backToComments,
  user,
  commentThread,
  isRespondingComment,
  deleteSlideComment,
  tempID,
  tempSourceID,
  slideID,
  highLightComment,
  theme,
  searchList,
}) => {
  const [commentInput, setCommentInput] = useState("");
  const [responding, setResponding] = useState({
    status: false,
    commentID: null,
    commentOwner: null,
  });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    messsage: null,
  });
  const [updateTimeDiff, setUpdateTimeDiff] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState({
    status: false,
    commentID: null,
  });
  const {emitSocketEvents} = useSocket() || {};

  useEffect(() => {
    // if responding to main thread
    if (isRespondingComment) {
      setResponding({
        status: isRespondingComment,
        commentID: commentThread.ID,
        mainThread: true,
        commentOwner: commentThread.commentedBy.name,
      });
    }
  }, [isRespondingComment]);

  const handleCommentInput = (e) => {
    const {value} = e.target;
    setCommentInput(value);
  };

  const handleReply = (commentID) => {
    setResponding({
      status: true,
      commentID,
      mainThread: false,
      commentOwner: commentThread.commentedBy.name,
    });
    setCommentInput("");
    onUpdateTimeDiff();
  };

  const exitResponding = () => {
    setResponding({
      status: false,
      commentID: null,
      mainThread: false,
      commentOwner: null,
    });
    setCommentInput("");
  };

  const handleDelete = async (item) => {
    if (!deleting && user.username === item.commentedBy.username) {
      setDeleting(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/comments/${commentThread.ID}/subcomments/${item.ID}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            tempSourceID,
            slideID,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (data) => {
          if (commentThread.ID === item.ID) {
            setDeleting(false);
            await backToComments();
            const payload = {
              commentID: commentThread.ID,
              deletingSubComment: false,
            };
            await deleteSlideComment({...payload});
            if (emitSocketEvents) {
              await emitSocketEvents({
                actionType: "delete-comment",
                item: {
                  type: null,
                  value: {...payload},
                },
              });
            }
          } else {
            setDeleting(false);
            const payload = {
              commentID: commentThread.ID,
              deletingSubComment: true,
              subCommentID: item.ID,
            };
            await deleteSlideComment({...payload});
            if (emitSocketEvents) {
              await emitSocketEvents({
                actionType: "delete-comment",
                item: {
                  type: null,
                  value: {...payload},
                },
              });
            }
          }
        })
        .catch((e) => {
          setDeleting(false);
        });
    }
  };

  const submitComment = async () => {
    const currentTimeStamp = new Date().getTime();

    // changes here should also reflect in RespondingToComment.js
    if (!processing) {
      // if user remove the user from input, update the list here before submitting
      let finalUserList = [],
        finalizedUsernames = [];
      mentionedUsers.map((user) => {
        if (commentInput.includes(user.id)) {
          finalUserList.push({
            username: user.id,
            name: user.display,
            email: user.email,
          });
          finalizedUsernames.push(user.id);
        }
      });

      // mention the main thread owner if not the mentioned user
      if (!finalizedUsernames.includes(commentThread.commentedBy.username) &&
        commentThread.commentedBy.username !== user.username) {
        finalUserList.push({
          username: commentThread.commentedBy.username,
          name: commentThread.commentedBy.name,
        });
      }

      let payload = {
        ID: createShortKey(),
        comment: commentInput,
        commentedBy: {
          name: user.name,
          username: user.username,
        },
        // no @name if responding to main thread
        mentionedUsers: finalUserList,
        createdAt: currentTimeStamp,
      };
      setProcessing(true);
      const mainCommentID = commentThread.ID;
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/comments/${mainCommentID}/subcomments`,
        {
          method: "POST",
          body: JSON.stringify({
            tempSourceID,
            slideID,
            payload,
            respondingTo: commentThread.commentedBy,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (data) => {
          if (data.status === "success") {
            const item = {
              mainThreadID: commentThread.ID,
              payload,
              slideID,
            };
            await respondToThread({...item});
            await onUpdateTimeDiff();
            if (emitSocketEvents) {
              await emitSocketEvents({
                actionType: "add-subcomment",
                item: {
                  type: null,
                  value: item,
                },
              });
            }
            await exitResponding();
          } else {
            setError({
              status: true,
              message: "Something went wrong.",
            });
          }
          setProcessing(false);
        })
        .catch((e) => {
          setProcessing(false);
          setError({
            status: true,
            message: "Something went wrong.",
          });
        });
    }
  };

  const onUpdateTimeDiff = () => {
    setUpdateTimeDiff(true);
    setTimeout(() => {
      setUpdateTimeDiff(false);
    }, 200);
  };

  const onEditCommentStatus = (item) => {
    setEditing(item);
  };

  const [mentionedUsers, setMentionedUsers] = useState([]);
  const handleAdd = (userID) => {
    const user = searchList.find((member) => member.id === userID);
    const ifAlreadyAdded = mentionedUsers.find(
      (member) => member.id === userID
    );

    if (!ifAlreadyAdded) {
      setMentionedUsers([...mentionedUsers, user]);
    }
  };

  return (
    <div>
      <WrapSvg onClick={backToComments}>{ArrowBack}</WrapSvg>

      <Wrapper $panelHeight={$panelHeight}>
        <CommentThread>
          <DisplayComment
            item={commentThread}
            onViewResponse={handleReply}
            onDelete={handleDelete}
            onCancel={exitResponding}
            hideReplySection={responding.status}
            user={user}
            updateTimeDiff={updateTimeDiff}
            deleting={deleting}
          />

          {responding.status ? (
            <WrapCommentArea
              style={{position: "relative", padding: "0 12px 4px"}}
            >
              <MentionsInput
                style={theme === "light" ? lightTheme : darkTheme}
                value={commentInput}
                onChange={handleCommentInput}
                appendspaceonadd="true"
              >
                <Mention
                  displayTransform={(id, name) => `@${name}`}
                  style={{
                    backgroundColor: theme === "light" ? "#b7b9ff" : "#171dff",
                  }}
                  trigger="@"
                  data={searchList}
                  onAdd={handleAdd}
                />
              </MentionsInput>

              <AddComment>
                {processing ? (
                  <WrapSpinner>
                    <Spinner />
                  </WrapSpinner>
                ) : undefined}
                <div onClick={exitResponding} className="cancel-comment">
                  Cancel
                </div>
                <WrapCommentBtn>
                  <SpecialButton disabled={processing} onClick={submitComment}>
                    Add
                  </SpecialButton>
                </WrapCommentBtn>
              </AddComment>
            </WrapCommentArea>
          ) : undefined}
        </CommentThread>

        <Line />

        <CommentList>
          {commentThread.subComments &&
            commentThread.subComments.map((item) => (
              <div key={item.ID}>
                <DisplaySubcomment
                  key={item.ID}
                  item={item}
                  onDelete={handleDelete}
                  hideReplySection={responding.status}
                  user={user}
                  updateTimeDiff={updateTimeDiff}
                  deleting={deleting}
                  commentThread={commentThread}
                  editing={editing.status && editing.commentID === item.ID}
                  onEditStatus={onEditCommentStatus}
                  highLightComment={highLightComment.id === item.ID}
                  theme={theme}
                  searchList={searchList}
                />
                {responding.status && responding.commentID === item.ID ? (
                  <WrapCommentArea
                    style={{position: "relative", padding: "0 12px 4px"}}
                  >
                    {/* <CommentArea
                      autoFocus
                      value={commentInput}
                      placeholder="Add Comment"
                      onChange={handleCommentInput}
                      username={user.username}
                    /> */}

                    <MentionsInput
                      style={theme === "light" ? lightTheme : darkTheme}
                      value={commentInput}
                      onChange={handleCommentInput}
                      appendspaceonadd="true"
                    >
                      <Mention
                        displayTransform={(id, name) => `@${name}`}
                        style={{
                          backgroundColor:
                            theme === "light" ? "#b7b9ff" : "#171dff",
                        }}
                        trigger="@"
                        data={searchList}
                        onAdd={handleAdd}
                      />
                    </MentionsInput>

                    <AddComment>
                      {processing ? (
                        <WrapSpinner>
                          <Spinner />
                        </WrapSpinner>
                      ) : undefined}
                      <div onClick={exitResponding} className="cancel-comment">
                        Cancel
                      </div>
                      <WrapCommentBtn>
                        <SpecialButton
                          disabled={processing}
                          onClick={submitComment}
                        >
                          Add
                        </SpecialButton>
                      </WrapCommentBtn>
                    </AddComment>
                  </WrapCommentArea>
                ) : undefined}
              </div>
            ))}
        </CommentList>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  overflow-y: scroll;
  height: ${({$panelHeight}) => $panelHeight}px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrapSvg = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  fill: ${({theme}) => theme.svgIconColor};
  margin: 18px 14px 22px;
  padding: 4px;
  background: ${({theme}) => theme.deskSecondaryBg};
  border-radius: 50%;
  cursor: pointer;
`;

const CommentThread = styled.div`
  padding: ${padding};
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideInfo(state.designTemplate).id,
    tempSourceID: selectTempSourceID(state.designTemplate),
    tempID: selectTemplateID(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  respondToThread,
  deleteSlideComment,
})(CommentRespones);
