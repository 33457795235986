export const lightTheme = {
  deskPrimaryBg: "#fff",
  deskPrimaryBg2: "#f9f6fd", //"#f7f2fdf0",
  deskPrimaryBgTransparent: "#fff",
  deskSecondaryBg: "#eceaf8", // "#e9e9ff", // "#f5f5ff",
  deskSecondaryBg2: "#fff",
  deskSecondaryBg3: "#f6f5ff",
  deskSecondaryBg4: "#e3e3ff",
  deskGap: "#d2d2d2",
  deskModalHeader: "#f9f6fd",
  deskPrimaryTxt: "#454759", //#3a3939",
  deskSecondaryTxt: "#454966",
  deskSecondaryTxt2: "#37394a",
  deskModalBg: "#fff", // "#f7f2fd",
  deskTertiaryTxt: "#5258ff", // add design text, bottom section
  deskBoldText: "#676a87",
  deskEditPanelBg: "#dcdde6",
  deskEditPanelTxt: "#676767",
  boxShadow1: "0px 0px 2px 0px #bcbcbf",
  boxShadow2: "0px 0px 1.2px 0px #3e4454",
  boxShadow3: "0px 0px 2px 0px #939494",
  boxShadow4: "0 0.5px 0.5px #6b6b6b", // for top bar
  topPanelBoxShadow: "0 0.5px 0.5px #d2d2d2",
  leftPanelBoxShadow: "0px 1px 2.5px #d6d6d6",
  rightPanelBoxShadow: "0px 0px 2px 0.1px #545454",
  rightPanelBorder: "#d6d6de", // on open field options panel
  midBottomBoxShadow: "0px 1px 4px 1px #d7cae8",
  rightPanelBoxShadow: "0 0.5px 2.5px #d1d1d1",
  popUpBg: "#fff",
  accordionBg: "#e4e4fd",
  accordionButton: "#fff",
  svgIconColor: "#5a5959",
  activeSlideList: "#d3deff",
  designListHover: "#dacdff",
  deskButton: "#8695ff", //"#9b80ff", 
  plusButton: "#5d61f8",
  deskBtnTxtColor: "#fff",
  deskBtmSectionBg: "#fff",
  deskBtnSectionBorder: "#d5d5d5",
  deskBtmSectionText: "#946bf5",
  deskBtmSectionBoxShadow: "0px 0px 5px 0px rgb(233 233 233)",
  rightPanelSvgBg: "#fff", // hasn't applied yet
  rightPanelSvgFill: "#9b96f1",
  rightPanelSvgBoxShadow: "0px 0px 3px 0px #d2d4fea6",
  secondaryThemeBg: "",
  secondaryThemeBorder: "#9b96f1",
  secondaryThemeHover: "#e6e6ff",
  modalItemBg: "#bbb8c91c", // create field modal
  modalTabText: "#666",
  modalTabBg: "#898cf675",
  activeSlide: "#e8ecff",
  activeAnimationItem: "#e9edff",
  hover: "#e8ecff",
  createSvgFill: "#f7f7ff", // create fields icons
  createSvgStroke: "#7c7e9e",
  inputBorder: "#c4c4c4",
  tooltip: "#343455f5",
  layoutGapColor: "#c5c2d8", // line that seperate panels
  undoRedoGapline: "#aaa7bc",
  tooltipBg: "#5a5a98",
};

export const royalBlue = "rgb(29 29 48)";
export const white = "#f7f2fd";
export const green = "#6ee41a";
export const grey = "#9a9a9a";
export const redMessage = "#e76767";

export const resizerColor = "#7d6ff5";
//  #1f1f2d
// #31313e
export const darkTheme = {
  deskPrimaryBg: "#1f1f2d", // "#241d2a",
  deskPrimaryBg2: "#1b1b28", // "#241d2a",
  deskPrimaryBgTransparent: "#141422",
  deskSecondaryBg: "#2f2f42", // "#343449d1",
  deskSecondaryBg2: "#26263b",
  deskSecondaryBg3: "#35395082", // "#474b5f82",
  deskSecondaryBg4: "#2d2d4d", // use in modal - eg: icon's bg
  deskModalBg: "rgb(29 29 48)",
  deskGap: "#434255",
  deskModalHeader: "#31313e",
  deskPrimaryTxt: "#b7b7b7",
  deskSecondaryTxt: "#d8d8d8", // lighter grey
  deskSecondaryTxt2: "#9b9595", // light grey
  deskBoldText: "#d0d0d0",
  deskTertiaryTxt: "#837eff",
  deskEditPanelBg: "#27272b",
  deskBtnSectionBorder: "#3b3d4c",
  deskEditPanelTxt: "#cccccc",
  boxShadow1: "0px 0px 2px 0px #44404e",
  boxShadow2: "0px 0px 3px 0px #44404e",
  boxShadow3: "0px 0px 1px 0px #a3a1a6",
  topPanelBoxShadow: "0 0.5px 0.1px #4c4c4c",
  leftPanelBoxShadow: "0px 0px 2px 0px #828282",
  rightPanelBoxShadow: "0px 0px 2px 0.1px #606060",
  rightPanelBorder: "#434348",
  popUpBg: "#141422", //"#27272b",
  accordionBg: "#27272b",
  accordionButton: "rgb(59 59 70)",
  svgIconColor: "#bfbfbf",
  activeSlideList: "#4c535f26",
  designListHover: "#8278b730",
  deskButton: "#4355b1", //#d3c23ac4 //#2d525f
  plusButton: "#dedede",
  deskBtnTxtColor: "#fff",
  deskBtmSectionBg: "#241d2a8a",
  deskBtmSectionText: "#946bf5",
  deskBtmSectionBoxShadow: "0px 0px 0.5px 0px #373639",
  rightPanelSvgBg: "#3e35448c",
  rightPanelSvgFill: "#e4dded", //"#ffbdbd", //
  rightPanelSvgBoxShadow: "0px 0px 4px 0px #424242",
  secondaryThemeBg: "",
  secondaryThemeBorder: "#bfb0fb",
  secondaryThemeHover: "#8278b730",
  modalItemBg: "#bbb8c91c",
  modalTabText: "#fff",
  modalTabBg: "#453450",
  activeSlide: "#8278b730",
  activeAnimationItem: "#444782",
  hover: "#8278b730",
  createSvgFill: "#636363", // create fields icons
  createSvgStroke: "#636363",
  inputBorder: "#929292",
  tooltip: "#141422e3",
  layoutGapColor: "#434255", // line that seperate panels
  undoRedoGapline: "#434255",
  tooltipBg: "#3d3d5d",
};

export const mobDarkTheme = {
  primaryBgColor: "#13151a",
  itemBgColor1: "#1c1d2f",
  itemTxtColor1: "#fff",
  svgColor: "#cccccc",
};

export const classicStyles = {
  primaryPurple: "#282bd6c4;",
};
