import React, {useState} from "react";
import styled from 'styled-components';
import {ButtonSpinner} from "../../../../../../oat-ui";
import SpecialButton from "../../../../../ui/inputs/SpecialButton";
import {ShareSvgIcon, WrapSvg, WrapButton} from "./ShareTemplate";
import {tempSvg} from "./../tempSvg";
import {saveAs} from "file-saver";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {selectSlideGroupList} from "../../../../../store/selectors/template/slide.selector";
import {
  selectTemplate,
  selectTemplateName,
  selectTemplateRatio,
  selectTemplateSize,
  selectTempSourceID,
  selectWorkspacePlan,
} from "../../../../../store/selectors/template/template.selector";
import {connect} from "react-redux";
import {useParams} from "react-router";
import {selectTemplateThemes} from "../../../../../store/selectors/template/theme.selector";
import {Error} from "./DownloadAsImage";

const GeneratePDF = ({
  close,
  user,
  tempSize,
  tempRatio,
  tempName,
  template,
  themes,
  slideGroupList,
  tempSourceID,
  workspacePlan,
}) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({status: false, message: ""});
  const [enableCompress, setEnableCompress] = useState(false);
  let params = useParams();
  const {tempID} = params;

  const onHandleLink = async ({compress}) => {
    if (workspacePlan === "free") {
      setError({status: true, message: "Workspace needs to be upgraded."});
    } else if (!processing) {
      setProcessing(true);
      setEnableCompress(compress)
      if (error.status) {
        setError({status: false, message: null});
      }

      const templateInfo = {
        ratio: tempRatio,
        size: tempSize,
        title: tempName,
        tempSourceID,
      };

      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/save-design-without-screenshot`,
        // `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates`,
        // !mobileView
        //   ? `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates`
        //   : `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates/mobile-template`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // tempID,
            // tempDetails: {
            //   details: removeDeletedFields,
            //   themes,
            //   sections: [],
            // },
            // requestedConnectionId: null,
            username: user.username,
            tempInfo: {
              ...templateInfo,
              tempID,
            },
            tempDetails: {
              details: template,
              themes,
              slideGroupList,
              sectionList: "", // add later
              tempDescription: "", // add later
            },
          }),
        }
      )
        .then(async (data) => {
          await fetch(
            `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/generate-pdf/${tempID}?compress=${compress}`
          )
            .then(() => {
              fetch(`https://oat-user-pdfs.s3.amazonaws.com/${tempID}.pdf`, {
                headers: {
                  "Content-Type": "application/pdf",
                },
                responseType: "blob",
              })
                .then((response) => response.blob())
                .then(async (blob) => {
                  const tempNameAsSlug = tempName
                    .replace(/[\s;]+/g, "-")
                    .toLowerCase();
                  await saveAs(blob, `${tempNameAsSlug}.pdf`);
                  await setProcessing(false);
                  await close();
                })
                .catch((e) => {
                  setError({
                    status: true,
                    message: "Something went wrong.",
                  });
                });
            })
            .catch((error) => {
              setError({
                status: true,
                message: "Something went wrong.",
              });
            });
        })
        .catch((error) => {
          setError({
            status: true,
            message: "Something went wrong.",
          });
        });
      // add function here
    }
  };

  const onSelectCompress = () => {
    onHandleLink({compress: true});
  }

  const onSelectHighQuality = () => {
    onHandleLink({compress: false});
  }

  return (
    <>
      {/* <ShareSvgIcon>
        <WrapSvg>{tempSvg}</WrapSvg>
      </ShareSvgIcon> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 22,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Title>Generate Pdf</Title> */}
        <PDFSection>
          <WrapButton>
            <SpecialButton
              disabled={processing}
              onClick={onSelectCompress}
              styles={{height: 36}}
            >
              {processing ? <ButtonSpinner /> : "Generate Pdf"}
              {/* {processing && enableCompress ? <ButtonSpinner /> : "Compress"} */}
            </SpecialButton>
          </WrapButton>

          {/* <WrapButton>
            <SpecialButton
              disabled={processing}
              onClick={onSelectHighQuality}
              styles={{height: 36}}
            >
              {processing && !enableCompress ? <ButtonSpinner /> : "High Quality"}
            </SpecialButton>
          </WrapButton> */}
        </PDFSection>

        {error.status ? (
          <Error style={{marginTop: 16}}>{error.message}</Error>
        ) : undefined}
      </div>
    </>
  );
};

const PDFSection = styled.div`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: ${({theme}) => theme.deskSecondaryBg3};
  border: 2px solid ${({theme}) => theme.deskSecondaryBg4};
`

const Title = styled.h3`
  width: 90%;
  margin-bottom: 12px;
  font-size: 16px;
`

const mapStateToProps = (state) => {
  const {designTemplate, authUser} = state;
  return {
    user: selectUserInfo(authUser),
    tempSize: selectTemplateSize(designTemplate),
    tempRatio: selectTemplateRatio(designTemplate),
    template: selectTemplate(designTemplate),
    themes: selectTemplateThemes(designTemplate),
    slideGroupList: selectSlideGroupList(designTemplate),
    tempName: selectTemplateName(designTemplate),
    tempSourceID: selectTempSourceID(designTemplate),
    workspacePlan: selectWorkspacePlan(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(GeneratePDF);
