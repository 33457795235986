import React from "react";
import {connect, batch} from "react-redux";
import styled from "styled-components";
import {
  selectActiveFieldProps,
  selectCachedFieldStyles,
} from "../../../../../../store/selectors/fields.selector";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {SplitLine} from "../common/SplitLine";
import LayoutPosition from "../common/LayoutPosition";
import {WrapColorType} from "../common/WrapColorStyles";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import RangeSlider from "../../shared/inputs/RangeSlider";
import DeskGradientColors from "../../shared/colorPicker/DeskGradientColors";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import EditShapeLine from "./EditShapeLine";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {PanelLayout} from "../panelStyles";
import EditOpacity from "../common/EditOpacity";
import ShapeShadow from "./ShapeShadow";

export const shapeEditProps = {
  blur: {
    min: 0,
    max: 100,
    step: 1,
  },
  strokeWidth: {
    min: 0,
    max: 20,
    step: 0.1,
  },
  borderRadius: {
    // for square & rectangle
    min: 0,
    max: 100,
    step: 1,
  },
};

const EditShapePanel = ({
  stableStyles,
  updateFieldStyles,
  activeField,
  storeFieldUndoRedo,
  slideID,
  updateFieldCollaboration
}) => {
  const {styles, extraInfo} = activeField || {};
  const {color1, color2, enableStroke, gradientAngle, enableGradient, lock} =
    styles || {};

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const handleEmitValue = () => {
    updateFieldCollaboration();
  };

  const toggleGradientStatus = (type) => {
    if (type === true) {
      handleStyleUpdate("enableGradient", type);
      handleEmitValue();
    } else {
      handleStyleUpdate("enableGradient", type);
      handleEmitValue();
    }
  };

  const toggleStroke = () => {
    if (!enableStroke) {
      handleStyleUpdate("enableStroke", true);
      handleStyleUpdate("strokeWidth", 2);
      handleEmitValue();
    } else {
      handleStyleUpdate("enableStroke", false);
      handleEmitValue();
    }
  };

  const onChangeBlur = (value) => {
    handleStyleUpdate("blur", value);
  };

  const onChangeOpacity = (value) => {
    handleStyleUpdate("opacity", value);
  };

  const onChangeStrokeWidth = (value) => {
    handleStyleUpdate("strokeWidth", value);
  };

  const onChangeStroke = (value) => {
    handleStyleUpdate("stroke", value);
  };

  const onChangeColorOne = (value) => {
    handleStyleUpdate("color1", value);
  };

  const onChangeColorTwo = (value) => {
    handleStyleUpdate("color2", value);
  };

  const onColorOneDebounce = (value) => {
    storeFieldUndoRedo();
    handleEmitValue();
  };

  const onColorTwoDebounce = (value) => {
    storeFieldUndoRedo();
    handleEmitValue();
  };

  const onStrokeDebounce = (value) => {
    storeFieldUndoRedo();
    handleEmitValue();
  };

  const onChangeGradientAngle = (value) => {
    handleStyleUpdate("gradientAngle", value);
  };

  const onChangeBorderRadius = (value) => {
    handleStyleUpdate("borderRadius", value);
  };

  const toggleDisableApplyingTheme = (value) => {
    handleStyleUpdate("disableApplyingTheme", value);
    handleEmitValue();
  };

  return (
    <PanelLayout>
      <LayoutPosition rotate={styles?.rotate} locked={lock} />

      <SplitLine />

      {/* Colors */}
      {activeField?.subtype === "line" ? (
        <EditShapeLine
          field={activeField}
          slideID={slideID}
          stableStyles={stableStyles}
        />
      ) : (
        <>
          {/* {!activeField.styles.strokeOnly ? ( */}
          <>
            <WrapColorType>
              <Switch
                title="Gradient"
                value={enableGradient}
                onItemChange={toggleGradientStatus}
                checked={enableGradient}
              />
            </WrapColorType>

            <ColorsSection>
              {!enableGradient ? (
                <div style={{flex: 1, display: "flex"}}>
                  <Title>Colors</Title>
                  <PopoverColorBox
                    value={color1}
                    updateFieldStyles={onChangeColorOne}
                    onDebounce={onColorOneDebounce}
                  />
                </div>
              ) : (
                <DeskGradientColors
                  colorOne={color1}
                  colorTwo={color2}
                  angle={gradientAngle}
                  updateColorOne={onChangeColorOne}
                  updateColorTwo={onChangeColorTwo}
                  updateFieldStyles={onChangeGradientAngle}
                  onDebounceColorOne={onColorOneDebounce}
                  onDebounceColorTwo={onColorTwoDebounce}
                />
              )}
            </ColorsSection>
          </>
          {/* ) : undefined} */}
          <div style={{padding: "2em 0 1.7rem"}}>
            <EditOpacity value={styles?.opacity} />
          </div>

          <div style={{paddingBottom: "1.4rem"}}>
            <RangeSlider
              title="Blur"
              value={styles?.blur}
              max={shapeEditProps?.blur?.max}
              min={shapeEditProps?.blur?.min}
              step={shapeEditProps?.blur?.step}
              stableValue={stableStyles?.blur}
              onItemChange={onChangeBlur}
              fieldType="blur"
            />
          </div>

          {activeField?.name === "rectangle" ||
          activeField?.name === "rounded-border-rectangle1" ||
          activeField?.name === "rounded-border-rectangle2" ? (
            <div style={{paddingTop: "0.25rem", paddingBottom: "1rem"}}>
              <RangeSlider
                title="Rounded Corners"
                value={styles?.borderRadius}
                max={shapeEditProps?.borderRadius?.max}
                min={shapeEditProps?.borderRadius?.min}
                step={shapeEditProps?.borderRadius?.step}
                stableValue={stableStyles?.borderRadius}
                onItemChange={onChangeBorderRadius}
                fieldType="borderRadius"
              />
            </div>
          ) : undefined}

          <SplitLine />
          {/* Stroke */}
          {!activeField?.styles?.strokeOnly ? (
            <div style={{paddingTop: "0.5rem", paddingBottom: activeField?.styles?.enableStroke ? "2rem" : undefined}}>
              <Switch
                title="Border"
                value={enableStroke}
                checkValue={enableStroke}
                onItemChange={toggleStroke}
                checked={enableStroke}
              />
            </div>
          ) : undefined}
         
          {enableStroke ? (
            <>
              <div style={{paddingBottom: "2rem"}}>
                <RangeSlider
                  title="Border Width"
                  value={styles?.strokeWidth}
                  max={shapeEditProps?.strokeWidth.max}
                  min={shapeEditProps?.strokeWidth.min}
                  step={shapeEditProps?.strokeWidth.step}
                  stableValue={stableStyles?.strokeWidth}
                  onItemChange={onChangeStrokeWidth}
                  fieldType="strokeWidth"
                />
              </div>
              <ColorsSection>
                <Title>Border Color</Title>
                <PopoverColorBox
                  value={styles?.stroke}
                  updateFieldStyles={onChangeStroke}
                  onDebounce={onStrokeDebounce}
                />
              </ColorsSection>
            </>
          ) : undefined}

          {activeField?.subtype !== "line"  ? 
            <>
              <SplitLine />
              <ShapeShadow styles={styles} />
            </> 
          : undefined}

          <SplitLine />
          
          <div style={{paddingBottom: "2rem"}}>
            <Switch
              title="Disable Theme"
              value={styles?.disableApplyingTheme}
              checkValue={styles?.disableApplyingTheme}
              onItemChange={toggleDisableApplyingTheme}
              checked={styles?.disableApplyingTheme}
            />
          </div>
        </>
      )}
    </PanelLayout>
  );
};

const ColorsSection = styled.div`
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    stableStyles: selectCachedFieldStyles(designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateFieldStyles,
    storeFieldUndoRedo,
    updateFieldCollaboration,
  }))
)(EditShapePanel);
