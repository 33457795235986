import React from "react";
import "../helperCSS/buttonSpinner.css";
import styled from "styled-components";

const ButtonSpinner = ({width, height, color}) => {
  return <Loader style={{width, height}} color={color}></Loader>;
};

const Loader = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0px auto; // 30px auto
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid ${({color}) => color};
  border-left: 0.8em solid ${({color}) => color};
  border-right: 0.8em solid ${({color}) => color};
  border-bottom: 0.8em solid transparent;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

ButtonSpinner.defaultProps = {
  width: 12,
  height: 12,
  color: "#fff",
};

export default ButtonSpinner;
