import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import {UploadFile} from "../../../../reuseStyles/reuseStyles";
import {ButtonSpinner, Modal} from "../../../../../oat-ui";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {connect} from "react-redux";
import {ErrorMsg} from "../../uploadStyles";
import {useParams} from "react-router-dom";
import { Radio, RadioButton } from "../../../../../oat-ui/styles/RadioStyles";
import DropDown from "../../../../../oat-design-template/ui/inputs/DropDown";
import { iconCategories } from "../../../../../oat-design-template/components/editDesign/modal/create/icons/CreateIcon";

const imageCategories = [
  {display: "Backgrounds", value: "Backgrounds"},
  {display: "Transparent Items", value: "transparent-items"},
  {display: "People", value: "people"},
];

const CreateImagePack = ({username, onUploadFinished}) => {
  const [openModal, setOpenModal] = useState(false);
  const [uploading, setUploading] = useState({
    status: false,
    openModal: false,
    error: {
      status: false,
      message: null,
    },
  });
  const [form, setForm] = useState({
    packName: "",
    coverImage: {
      file: null,
      preview: null,
    },
    type: "icons",
    category: "illustrations", 
    keywords: [],
    displayOnSalesPage: false,
    price: 0,
  });
  const coverFileRef = useRef();
  let params = useParams();

  const onPackNameChange = (e) => {
    const {value} = e.target;
    setForm({
      ...form,
      packName: value,
    });
  };

  const onInitUploadImg = () => {
    setForm({
      ...form,
      coverImage: {
        file: null,
        preview: null,
      },
    });
    coverFileRef.current.click();
  };

  const toggleModal = () => {
    setUploading({
      status: false,
      openModal: false,
      error: {
        status: false,
        message: null,
      },
    });
    if (openModal) {
      setForm({
        packName: "",
        coverImage: {
          file: null,
          preview: null,
        },
        premium: false, 
        type: "icons",
        keywords: [],
        category: "illustrations", 
      });
    }
    setOpenModal(!openModal);
  };

  const onSelectImageType = (value) => {
    setForm({
      ...form,
      type: value,
    });
  };

  const selectCategory = (value) => {
    setForm({
      ...form,
      category: value,
    });
  }

  const onSelectPremium = (value) => {
    setForm({
      ...form,
      premium: value === "premium" ? true : false,
    });
  };

  const updatePrice = (e) => {
    setForm({
      ...form,
      price: e.target.value,
    });
  }

  const toggleDisplayOnSalesPage = () => {
    setForm({...form, displayOnSalesPage: !form.displayOnSalesPage});
  }

  const onUploadCover = (event, uploadingType) => {
    if (uploading.error.status) {
      setUploading({
        ...uploading,
        error: {
          status: false,
          message: null,
        },
      });
    }
    // 10 MB
    if (event.target.files[0].size > 10485760) {
      setUploading({
        ...uploading,
        error: {
          status: true,
          message: "File size cannot be bigger than 10MB.",
        },
      });
      event.target.value = null;
    } else {
      setUploading({
        ...uploading,
        openModal: true,
      });

      setForm({
        ...form,
        coverImage: {
          file: event.target.files[0],
          preview: null,
        },
      });
    }
  };

  const handleCreate = () => {
    const image = form.coverImage;
    if (!form.coverImage.file) {
      setUploading({
        ...uploading,
        error: {
          status: true,
          message: "Please attach a file",
        },
      });
      return;
    }
    if (!uploading.status) {
      setUploading({
        ...uploading,
        status: true,
      });
      if (image.file) {
        let format = image.file.type.split("image/")[1];
        if (format === "svg+xml") {
          format = "svg";
        }
        const img = new Image();
        let fileReader = new FileReader();
        fileReader.onload = () => (img.src = fileReader.result);
        fileReader.onerror = () => {
          setUploading({
            ...uploading,
            error: {
              status: true,
              message: "Couldn't identify image size.",
            },
          });
        };
        fileReader.readAsDataURL(image.file);

        img.onload = async () => {
          const {url, fields, error, message} = await fetch(
            `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/media-for-users/pack-name`,
            {
              method: "POST",
              body: JSON.stringify({
                category: form.category,
                packName: form.packName,
                keywords: [],
                location: {
                  locationBased: false,
                  country: "global",
                },
                owner: "oat-all",
                uploadedBy:  username, // '6BYg9lL22VY', //
                type: form.type,
                cover: {
                  contentType: image.file.type,
                  format,
                },
                isPremium: form.premium,
                price: Number(form.price),
                displayOnSalesPage: form.displayOnSalesPage
              }),
            }
          )
            .then((response) => response.json())
            .catch((e) => {
              setUploading({
                status: false,
                error: {
                  status: true,
                  message,
                },
              });
            });

          const data = {
            bucket: "oat-images-icons",
            ...fields,
            "Content-Type": image.file.type,
            file: image.file,
          };

          const formData = new FormData();
          for (const name in data) {
            formData.append(name, data[name]);
          }

          if (!error && url) {
            await fetch(url, {
              method: "POST",
              body: formData,
            })
              .then(async () => {
                URL.revokeObjectURL(image.preview);
                onUploadFinished();
                setUploading({
                  status: false,
                  error: {
                    status: false,
                    message: null,
                  },
                });
                setForm({
                  packName: "",
                  coverImage: {
                    file: null,
                    preview: null,
                  },
                  type: "icons",
                  keywords: [],
                  displayOnSalesPage: false
                });
                setOpenModal(false);
              })
              .catch((e) => {
                setUploading({
                  status: false,
                  error: {
                    status: true,
                    message,
                  },
                });
              });
          } else {
            setUploading({
              status: false,
              error: {
                status: true,
                message,
              },
            });
          }
        };
      }
    }
  };

  return (
    <>
      <UploadFile onClick={toggleModal}>Create Pack</UploadFile>

      {openModal ? (
        <Modal width={520} height={"auto"} close={toggleModal}>
          <UploadForm>
            <UploadFormField>
              <p>Pack Name</p>
              <input
                type="text"
                placeholder="Pack Name"
                value={form.packName}
                onChange={onPackNameChange}
              />
            </UploadFormField>

            <UploadFormField>
              <p>Pack Cover</p>
              <div
                style={{
                  textDecoration: "underline",
                  flex: 1,
                  cursor: "pointer",
                }}
                onClick={onInitUploadImg}
              >
                Upload {form.coverImage.file ? "+1 Attachment" : undefined}
              </div>
              <input
                ref={coverFileRef}
                value={""}
                type="file"
                onChange={(e) => onUploadCover(e, "packCover")}
                hidden
              />
            </UploadFormField>

            <UploadFormField>
              <p>Type</p>
              <WrapRadio>
                <Radio style={{marginRight: 22}} onClick={() => onSelectImageType("images")}>
                  <RadioButton active={form.type === "images"}>
                    <div />
                  </RadioButton>
                  <span>Image</span>
                </Radio>
                <Radio onClick={() => onSelectImageType("icons")}>
                  <RadioButton active={form.type === "icons"}>
                    <div />
                  </RadioButton>
                  <span>Icon</span>
                </Radio>
              </WrapRadio>
            </UploadFormField>

            <UploadFormField>
              <p>Category</p>
              <WrapRadio>
                <DropDown 
                  minWidth={140}
                  maxWidth={200} 
                  boxWidth={170} 
                  background="#fff" 
                  options={form.type === 'icons' ? iconCategories : imageCategories} // for images later
                  onSelect={selectCategory} 
                  selected={form.type === 'icons' ? form.category : form.category} 
                />
              </WrapRadio>
            </UploadFormField>

            <UploadFormField>
              <p>Premium</p>
              <WrapRadio>
                <Radio style={{marginRight: 22}} onClick={() => onSelectPremium("free")}>
                  <RadioButton active={!form.premium}>
                    <div />
                  </RadioButton>
                  <span>Free</span>
                </Radio>
                <Radio onClick={() => onSelectPremium("premium")}>
                  <RadioButton active={form.premium}>
                    <div />
                  </RadioButton>
                  <span>Premium</span>
                </Radio>
              </WrapRadio>
            </UploadFormField>

            {form.premium ? 
              <UploadFormField>
                <p>Price</p>
                <WrapRadio>
                  <input type="text" value={form.price} onChange={updatePrice} style={{height: 28}} />
                </WrapRadio>
              </UploadFormField>
            : undefined}

            <UploadFormField>
              <p></p>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <input
                  type="checkbox"
                  name={'display-on-sales-page'}
                  id={'display-on-sales-page'}
                  checked={form.displayOnSalesPage}
                  onChange={toggleDisplayOnSalesPage}
                />
                <label for={'display-on-sales-page'} style={{paddingLeft: 10}}>Display on Sales Page</label>
              </div>
            </UploadFormField>

            <WrapUploadBtn>
              <UploadFile
                style={{width: "100%", height: 34, opacity: uploading.status ? 0.6 : 1, pointerEvents: uploading.status ? 'none': undefined }}
                onClick={handleCreate}
              >
                {!uploading.status ? "Create" : <ButtonSpinner />}
              </UploadFile>
            </WrapUploadBtn>

            {uploading.error.status ? (
              <ErrorMsg>{uploading.error.message}</ErrorMsg>
            ) : undefined}
          </UploadForm>
        </Modal>
      ) : undefined}
    </>
  );
};

export const UploadForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;
`;

export const UploadFormField = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 25px;
  color: #292929;
  font-size: 0.95rem;
  p {
    flex: 1;
  }
  input {
    width: 100%;
    height: 24px;
    flex: 1;
    border: 1px solid #d3d3d3;
  }
`;

const WrapRadio = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
`;

const WrapUploadBtn = styled.div`
  margin: 14px 0;
`;

const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
  };
};

export default connect(mapStateToProps, null)(CreateImagePack);
