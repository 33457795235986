import React from "react";
import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Auth} from "aws-amplify";
import {dispatchSignOut} from "../../../../redux/user/actions/authUser.action";
import {connect} from "react-redux";

export const navPanelHeight = 57;
const NavBar = ({dispatchSignOut}) => {
  let navigate = useNavigate();

  function signOut() {
    Auth.signOut()
      .then(async() => {
        await dispatchSignOut()
        await navigate(`/`)
      })
      .catch((err) => {});
  }

  return (
    <Wrapper>
      <LeftSide to="/">Oat All</LeftSide>
      <RightSide>
        <Navigate>
          <Link to="/admin/designs/presentation">Templates</Link>
        </Navigate>
        <Navigate>
          <Link to="/admin/designs/layouts">Layouts</Link>
        </Navigate>
        <Navigate>
          <Link to="/admin/library/private">Private Images</Link>
        </Navigate>
        <Navigate>
          <Link to="/admin/library/public">Public Images</Link>
        </Navigate>
        <Navigate onClick={signOut}>Log out</Navigate>
      </RightSide>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: ${navPanelHeight}px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1f1c1c;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  z-index: 999;
`;

const LeftSide = styled(Link)`
  flex: 1;
  padding-left: 25px;
  font-size: 1rem;
  user-select: none;
  text-decoration: none;
  color: #232323;
`;

const RightSide = styled.div`
  display: flex;
  flex: 2.5;
  justify-content: flex-end;
  color: #232323;
  padding-right: 40px;
`;

const Navigate = styled.div`
  cursor: pointer;
  padding: 0 20px;
  font-size: 0.9rem;
  text-decoration: none;
  color: inherit;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default connect(null, {dispatchSignOut})(NavBar);
