import React from "react";
import {connect} from "react-redux";
import {updateThemeBuilderFields} from "../../../store/actions/template/theme.action";
import {selectThemeBuilder} from "../../../store/selectors/template/theme.selector";
import {InputName} from "../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
import PopoverColorBox from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import {ColorsSection, WrapItem} from "./buildThemeStyle";
import {Message} from "./ImageThemeBuilder";

const VideoThemeBuilder = ({video, updateThemeBuilderFields}) => {
  const handleFieldUpdate = (key, value) => {
    updateThemeBuilderFields({
      category: "video",
      key,
      value,
    });
  };

  const changeLineColor = (value) => {
    handleFieldUpdate("borderColor", value);
  };

  const handleDebounceColor = () => {
    // leave empty
  };

  return (
    <>
      <WrapItem style={{margin: 12}}>
        <Message style={{fontSize: "0.85rem"}}>
          Border effect will only apply if the option is enabled.
        </Message>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Border Color</InputName>
          <PopoverColorBox
            value={video.borderColor}
            updateFieldStyles={changeLineColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    video: selectThemeBuilder(state.designTemplate)["video"],
  };
};

export default connect(mapStateToProps, {updateThemeBuilderFields})(
  VideoThemeBuilder
);
