import {v1 as uuidv1} from "uuid";
import {genUID} from "../../oat-utility";

export async function createTemplate({
  tempInfo,
  username,
  folderID,
  workspaceID,
  category,
  requiresUpgrade
}) {
  const tempSourceID = uuidv1();
  const tempID = genUID(10);
  return await fetch(
    `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/templates`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: username,
        tempID, // only 8 digit - short
        tempSourceID, // uuid longer
        refTemp: {
          PK: tempInfo.PK,
          SK: tempInfo.SK,
          ID: tempInfo.tempID,
          themeSlug: "main",
          tempLink: tempInfo.template,
        },
        workspaceID: workspaceID,
        folderID: folderID ? folderID : "home",
        size: tempInfo.size,
        ratio: tempInfo.ratio,
        blankTemp: false,
        category: category,
        requiresUpgrade
      }),
    }
  ).then((res) => res.json());
}
