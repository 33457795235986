import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {
  selectActiveCategory,
  selectLoadingStatus,
  selectTemplates,
} from "../../../store/selectors/adminPanel.selector";
import {
  Header,
  Image,
  WrapItem,
  Layout,
  Items,
  TopPanel,
  Actions,
  LinkTitle,
  PopUpDiv,
} from "./templateListStyles";
import {detectClickOutside, Spinner} from "../../../../oat-ui";
import {useParams, useNavigate} from "react-router";
import {WrapSvg} from "./templateListStyles";
import {Plus} from "../../../../oat-svg-icons";
import FilterTemplates from "./topBar/FilterTemplates";
import {onCategoryChange} from "../../../store/actions/tempCategory.action";
import {designTypes} from "../../../../oat-design-template/designTypes/designTypes";

const Item = ({item, ratio, category}) => {
  return (
    <WrapItem to={`/admin/designs/${category}/${ratio}/${item.tempID}`}>
      <Image
        src={`https://oat-admin-templates.s3.amazonaws.com/${item.thumbs.medium}`}
      />
    </WrapItem>
  );
};

const TemplateList = ({
  templates,
  process,
  onCategoryChange,
  activeCategory,
}) => {
  let params = useParams();
  let navigate = useNavigate();
  const {category, ratio} = params;
  const popperRef = useRef(null);
  const [openPopper, setOpenPopper] = useState({
    status: false,
    type: null,
  });

  const redirectToCanvas = () => {
    if (category && ratio) {
      const ratio = designTypes[category][0].ratio;
      const size = designTypes[category][0].size;
      return navigate(
        `/admin/designs/create/${category}/${ratio}?width=${size.w}&height=${size.h}`
      );
    }
  };

  const openFilterPopper = () => {
    setOpenPopper({
      status: true,
      type: "filter-templates",
    });
  };

  const handleFilterTemplates = (size) => {
    if (activeCategory.value !== null || activeCategory.value === "") {
      setOpenPopper({
        status: false,
        type: null,
      });
      // CHECK & UPDATE LATER
      // onCategoryChange(category, ratio, size);
    }
  };

  const onRetrieveDraftTemplates = () => {
    const payload = {
      value: category,
      ratio: designTypes[category][0].ratio,
      draft: true,
    };
    onCategoryChange(payload);
  };

  detectClickOutside(popperRef, () => {
    setOpenPopper({status: false, type: null});
  });

  useEffect(() => {
    const item = {value: category, ratio, draft: false};
    onCategoryChange(item);
  }, []);

  useEffect(() => {
    if (!ratio) {
      const payload = {
        value: category,
        ratio: designTypes[category][0].ratio,
        draft: false,
      };
      onCategoryChange(payload);

      return navigate(
        `/admin/designs/${category}/${designTypes[category][0].ratio}?width=${designTypes[category][0].size.w}&height=${designTypes[category][0].size.h}`
      );
    }
  }, [ratio]);

  return (
    <Layout>
      {!process.loading ? (
        <>
          {activeCategory.value !== null || activeCategory.value === "" ? (
            // checking null important
            // without it category will be null while user can filter or select template sizes
            <TopPanel>
              <Header>Templates</Header>
              <Actions>
                <WrapSvg>
                  <div onClick={redirectToCanvas}>{Plus}</div>
                </WrapSvg>

                <LinkTitle>
                  <div
                    onClick={onRetrieveDraftTemplates}
                    style={{margin: "0px 20px 6px 0px", cursor: "pointer"}}
                  >
                    Draft
                  </div>
                </LinkTitle>

                <LinkTitle>
                  <div
                    onClick={openFilterPopper}
                    style={{margin: "0 0 6px 12px", cursor: "pointer"}}
                  >
                    Filter
                  </div>

                  {openPopper.status &&
                  openPopper.type === "filter-templates" ? (
                    <PopUpDiv ref={popperRef}>
                      <FilterTemplates onSelectSize={handleFilterTemplates} />
                    </PopUpDiv>
                  ) : undefined}
                </LinkTitle>
              </Actions>
            </TopPanel>
          ) : undefined}

          <Items style={{height: "auto"}}>
            {templates &&
              templates.map((item, i) => (
                <Item key={i} item={item} ratio={ratio} category={category} />
              ))}
          </Items>
        </>
      ) : (
        <div style={{height: "40vh", display: "flex", alignItems: "center"}}>
          <Spinner />
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const {adminPanel} = state;
  return {
    process: selectLoadingStatus(adminPanel),
    templates: selectTemplates(adminPanel),
    activeCategory: selectActiveCategory(adminPanel),
  };
};

export default connect(mapStateToProps, {onCategoryChange})(TemplateList);
