import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {selectTemplateID} from "../../../../store/selectors/template/template.selector";
import GoBack from "../common/GoBack";
import {optionDrawerHeight} from "../MobileDrawer";
import {openDrawer} from "../../../../store/actions/layout.action";
import Switch from "../../editingPanel/panelTypes/shared/inputs/Switch";

const ShareLink = ({tempID, openDrawer}) => {
  const [tempLink, setTempLink] = useState(null);
  const [passcode, setPasscode] = useState("");
  const inputRef = useRef(null);
  let params = useParams();
  const {profileSlug} = params;

  useEffect(() => {
    setTempLink(
      !profileSlug
        ? window.location.origin.toString() + `/p/${tempID}`
        : window.location.origin.toString() + `/${profileSlug}/${tempID}`
    );
  }, [tempID]);

  const goBack = () => {
    openDrawer({type: "slide-options", height: optionDrawerHeight});
  };

  const handleFocus = () => {
    inputRef.current.select();
  };

  return (
    <>
      <GoBack onBackward={goBack} />
      <Layout>
        <WrapField>
          <h3>Link</h3>
          <LinkInput ref={inputRef} onFocus={handleFocus} value={tempLink} />
        </WrapField>

        {/* <WrapField>
          <h3>Passcode</h3>
          <Switch
            title="Enable Passcode"
            value={currentSlideOnly}
            checkValue={currentSlideOnly}
            onItemChange={onChangeCurrentDesignOnly}
            checked={currentSlideOnly}
          />
        </WrapField> */}
      </Layout>
    </>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  padding: 0 22px;
  box-sizing: border-box;
`;

const WrapField = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  h3 {
    flex: 1;
    color: ${({theme}) => theme.deskPrimaryTxt};
  }
`;

const LinkInput = styled.input`
  background: ${({theme}) => theme.deskSecondaryBg};
  color: ${({theme}) => theme.deskPrimaryTxt};
  flex: 2;
  padding: 5px;
  white-space: pre;
  overflow: hidden;
  cursor: text;
  border: none;
  @media only screen and (max-width: 849px) {
    font-size: 1.1rem;
    border-radius: 8px;
    padding: 12px 7px;
  }
`;

const mapStateToProps = (state) => {
  return {
    tempID: selectTemplateID(state.designTemplate),
  };
};
export default connect(mapStateToProps, {openDrawer})(ShareLink);
