import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { blue1 } from '../../../../oat-color-variables';
import { Button, ButtonSpinner } from '../../../../oat-ui';
import { useParams } from 'react-router-dom';

const types = {
    "presentation": [
        {display: 'Report', value: 'report'},
        {display: 'Sales Deck', value: 'sales-deck'},
        {display: 'Educational', value: 'educational'},
        {display: 'Informative', value: 'informative'},
        {display: 'Entertainment', value: 'entertainment'},
        {display: 'Conference',value: 'conference'},
        {display: 'Proposal', value: 'proposal'},
        {display: 'Public Speaking',value: 'public-speaking'},
        {display: 'Pitch Deck', value: 'pitch-deck'},
        {display: 'Technical & Design', value: 'technical-&-design'},
        {display: 'Portfolio',value: 'portfolio'},
    ],
    "social-media": [
        {display: 'Sales', value: 'sales'},
        {display: 'Event', value: 'event'},
        {display: 'Educational', value: 'educational'},
        {display: 'Advertising', value: 'advertising'},
        {display: 'Engagement', value: 'engagement'},
        {display: 'News and Updates', value: 'news-and-updates'},
        {display: 'Inspirational', value: 'inspirational'},
        {display: 'Holiday and Seasonal Posts', value: 'holiday-and-seasonal-posts'},
        {display: 'Customer Reviews', value: 'customer-reviews'},
    ]
};

const  DisplayItem = ({item, active, onSelect}) => {
    const onClick = () => {
        onSelect(item);
    };

    return (
        <Item onClick={onClick} style={{color: active ? blue1 : undefined}}>
            {item.display}
        </Item>
    )
};

const TempDesignType = ({templateCategory, designType, close}) => {
    const [selectedType, setSelectedType] = useState("");
    const [fetching, setFetching] = useState(false);
    let params = useParams();

    const onUpdateDesignType = (value) => {
        if(value.display === selectedType){
            setSelectedType("");
        } else {
            setSelectedType(value.display);
        }
    };

    useEffect(() => {
        if (designType) {
            setSelectedType(designType)
        }
    },[designType]);

    const handleUpdate = async() => {
        setFetching(true)
        await fetch(
            `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/template/${params.tempID}`,
            {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    attributeName: 'designType',
                    attributeValue: selectedType,
                }),
            }
        )
        .then((res) => res.json())
        .then((data) => {
            setFetching(false);
            return window.location.reload();
        })
        .catch((e) => {});
    }

    return (
        <Layout>
            {types[templateCategory] && types[templateCategory].map((type, i) => (
                <DisplayItem key={i} item={type} onSelect={onUpdateDesignType} active={type.display === selectedType} />
            ))}

            <Button onClick={handleUpdate} disabled={fetching} styles={{height: 30, marginTop: 32}}>
                {!fetching ? 'Add' : <ButtonSpinner />}
            </Button>
        </Layout>
    )
}

const Layout = styled.div`
    margin: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`

const Item = styled.div`
    padding: 6px 16px;
    font-size: 15px;
    border-radius: 18px;
    background: #eeeeee;
    margin: 14px 12px 0 0;
    cursor: pointer;
`

export default TempDesignType