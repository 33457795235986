import React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {
  selectInterceptedFields,
  selectUsersOnLine,
} from "../../../../store/selectors/common.selector";
import Resizable from "../dragResize/resize/Resizable";
import GroupDragResize from "../dragResize/groupSelection/GroupDragResize";
import OnlineUsersGroupedItems from "../dragResize/groupSelection/OnlineUsersGroupedItems";
import {selectActiveSlideID} from "../../../../store/selectors/template/slide.selector";

const ShowGroupSelection = ({
  slide,
  activeSlideID,
  scale,
  groupedItems,
  usersOnline,
}) => {
  return (
    <ResizeLayer>
      {!groupedItems?.selection?.show
        ? slide &&
          Object.entries(slide.fields).map(([id, field], i) => {
            if (groupedItems.keys.includes(field.key)) {
              return (
                <Resizable
                  key={field.key}
                  field={field}
                  tempScale={scale}
                  selected={true}
                  dragging={false}
                  resizing={false}
                  displayResizers={false}
                  adjustXOffset={false}
                />
              );
            }
          })
        : undefined}

      {groupedItems?.selection?.show ? (
        <GroupDragResize
          slide={slide}
          tempScale={scale}
          groupedItems={groupedItems}
          usersAvailableOnline={Object.keys(usersOnline.users).length > 0}
          collaboration={false} // will not show name tab on current user's action
        />
      ) : undefined}

      {usersOnline &&
        Object.entries(usersOnline.users).map(([username, item]) =>
          usersOnline.users[username].activeSlide === activeSlideID &&
          usersOnline.users[username].groupSelection.selection.show ? (
            <OnlineUsersGroupedItems
              key={username}
              template={slide}
              tempScale={scale}
              onlineUser={usersOnline.users[username]}
              collaboration={true} // for events received from another online users
            />
          ) : undefined
        )}
    </ResizeLayer>
  );
};

export const ResizeLayer = styled.div`
  position: relative;
  height: 100%;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    groupedItems: selectInterceptedFields(designTemplate),
    usersOnline: selectUsersOnLine(designTemplate),
    activeSlideID: selectActiveSlideID(designTemplate),
  };
};

export default connect(mapStateToProps, null)(ShowGroupSelection);
// import React from "react";
// import styled from "styled-components";
// import {connect} from "react-redux";
// import {
//   selectInterceptedFields,
//   selectUsersOnLine,
// } from "../../../../store/selectors/common.selector";
// import Resizable from "../dragResize/resize/Resizable";
// import GroupDragResize from "../dragResize/groupSelection/GroupDragResize";
// import OnlineUsersGroupedItems from "../dragResize/groupSelection/OnlineUsersGroupedItems";
// import {selectActiveSlideID} from "../../../../store/selectors/template/slide.selector";

// const ShowGroupSelection = ({
//   slide,
//   activeSlideID,
//   scale,
//   groupedItems,
//   usersOnline,
// }) => {
//   return (
//     <ResizeLayer>
//       {!groupedItems?.selection?.show
//         ? slide &&
//           Object.entries(slide.fields).map(([id, field], i) => {
//             if (groupedItems.keys.includes(field.key)) {
//               return (
//                 <Resizable
//                   key={field.key}
//                   field={field}
//                   tempScale={1}
//                   selected={true}
//                   dragging={false}
//                   resizing={false}
//                   displayResizers={false}
//                   adjustXOffset={false}
//                 />
//               );
//             }
//           })
//         : undefined}

//       {groupedItems?.selection?.show ? (
//         <GroupDragResize
//           slide={slide}
//           tempScale={scale}
//           groupedItems={groupedItems}
//           usersAvailableOnline={Object.keys(usersOnline.users).length > 0}
//           collaboration={false} // will not show name tab on current user's action
//         />
//       ) : undefined}

//       {usersOnline &&
//         Object.entries(usersOnline.users).map(([username, item]) =>
//           usersOnline.users[username].activeSlide === activeSlideID &&
//           usersOnline.users[username].groupSelection.selection.show ? (
//             <OnlineUsersGroupedItems
//               key={username}
//               template={slide}
//               tempScale={scale}
//               onlineUser={usersOnline.users[username]}
//               collaboration={true} // for events received from another online users
//             />
//           ) : undefined
//         )}
//     </ResizeLayer>
//   );
// };

// export const ResizeLayer = styled.div`
//   position: relative;
//   height: 100%;
// `;

// const mapStateToProps = (state) => {
//   const {designTemplate} = state;
//   return {
//     groupedItems: selectInterceptedFields(designTemplate),
//     usersOnline: selectUsersOnLine(designTemplate),
//     activeSlideID: selectActiveSlideID(designTemplate),
//   };
// };

// export default connect(mapStateToProps, null)(ShowGroupSelection);
