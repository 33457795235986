import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../../../store/actions/fields/common.action";
import {selectActiveField} from "../../../../../../../../store/selectors/fields.selector";
import RangeSlider from "../../../../shared/inputs/RangeSlider";
import {selectActiveSlide} from "../../../../../../../../store/selectors/template/slide.selector";

const EditRotate = ({fieldID, slideID, value, updateFieldStyles}) => {
  const handleUpdate = (item) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type: "rotate",
      value: Number(item),
    });
  };

  return (
    <RangeSlider
      title="Rotate"
      value={value}
      max={360}
      min={0}
      step={1}
      onItemChange={handleUpdate}
      fieldType="rotate"
    />
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(EditRotate);
