import styled from "styled-components";

export const WrapBar = styled.div`
  width: 20px;
  cursor: pointer;
`;

export const Bar = styled.div`
  display: block;
  height: 2px;
  width: 100%;
  margin-bottom: 4px;
  border-radius: 2px;
  background: #556078;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s ease-out;
  transform: translateZ(0);
`;
