export function getTempCategoriesInit() {
  return function(state) {
    return {
      ...state,
      fetchData: {
        ...state.fetchData,
        loading: true,
      },
    };
  };
}

export function getTempCategoriesSuccess() {
  return function(state, action) {
    return {
      ...state,
      tempCategories: action.categories,
      fetchData: {
        ...state.fetchData,
        loading: false,
      },
    };
  };
}

export function getTempCategoriesError() {
  return function(state, action) {
    return {
      ...state,
      fetchData: {
        loading: false,
        error: true,
        errMsg: "Something went wrong.",
      },
    };
  };
}

// change template category
export function changeTemplateCategory() {
  return function(state, action) {
    return {
      ...state,
      activeCategory: action.payload,
    };
  };
}

// fetching templates
export function fetchTemplatesInit() {
  return function(state, action) {
    return {
      ...state,
      fetchData: {
        ...state.fetchData,
        loading: true,
      },
    };
  };
}

export function fetchTemplatesSuccess() {
  return function(state, action) {
    return {
      ...state,
      templates: action.payload,
      fetchData: {
        ...state.fetchData,
        loading: false,
      },
    };
  };
}

export function fetchTemplatesError() {
  return function(state, action) {
    return {
      ...state,
      fetchData: {
        loading: false,
        error: true,
        errMsg: "Something went wrong.",
      },
    };
  };
}

export function removeTemplate() {
  return function(state, action) {
    return {
      ...state,
      templates: state.templates.filter(
        (template) => template.tempID !== action.index
      ),
    };
  };
}
