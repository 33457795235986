import React, {useEffect, useState, useRef} from "react";
import {Spinner} from "../../../../../../../oat-ui";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import styled from "styled-components";
import {AddComment, WrapCommentArea, WrapCommentBtn} from "./commentStyles";
import GetWorkspaceMembers from "./GetWorkspaceMembers";
import {MentionsInput, Mention} from "react-mentions";
import {darkTheme, lightTheme} from "./inputStyles";

const CommentInput = ({
  searchList,
  value,
  onChangeInput,
  onChangeActionRequire,
  processing,
  onSubmit,
  enableCancel,
  onCancel,
  theme,
}) => {
  const textAreaRef = useRef();

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length
      );
    }
  }, [textAreaRef]);

  const [mentionedUsers, setMentionedUsers] = useState([]);
  const handleAdd = (userID) => {
    const getUser = searchList.find((member) => member.id === userID);
    setMentionedUsers([...mentionedUsers, getUser]);
  };

  const handleSubmit = () => {
    // if user remove the user from input, update the list here before submitting
    let finalUserList = [];
    mentionedUsers.map((user) => {
      if (value.input.includes(user.id)) {
        finalUserList.push({
          username: user.id,
          name: user.display,
          email: user.email,
        });
      }
    });
    onSubmit(finalUserList);
  };

  return (
    <WrapCommentArea>
      <MentionsInput
        style={theme === "light" ? lightTheme : darkTheme}
        value={value.input}
        onChange={onChangeInput}
        appendspaceonadd="true"
      >
        <Mention
          displayTransform={(id, name) => `@${name}`}
          style={{backgroundColor: theme === "light" ? "#b7b9ff" : "#171dff"}}
          trigger="@"
          data={searchList}
          onAdd={handleAdd}
        />
      </MentionsInput>

      <AddComment style={{justifyContent: "space-between"}}>
        {enableCancel ? (
          <p style={{marginRight: 14, cursor: "pointer"}} onClick={onCancel}>
            Cancel
          </p>
        ) : undefined}

        <WrapRadio>
          <input
            type="checkbox"
            id="comment-action-require"
            name="comment-action-require"
            checked={value.actionRequire}
            onChange={onChangeActionRequire}
          />
          <label htmlFor="comment-action-require">Action Require</label>
        </WrapRadio>

        <WrapCommentBtn>
          {processing ? (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          ) : undefined}

          <SpecialButton disabled={processing} onClick={handleSubmit}>
            Send
          </SpecialButton>
        </WrapCommentBtn>
      </AddComment>
    </WrapCommentArea>
  );
};

const WrapSpinner = styled.div`
  margin-right: 18px;
  transform: scale(0.7);
`;

const WrapRadio = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-left: 8px;
  }
`;

CommentInput.defaultProps = {
  enableCancel: false,
};
export default CommentInput;
