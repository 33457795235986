import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, Popper, Spinner} from "../../../../../oat-ui";
import styled from "styled-components";
import {
  Header,
  Image,
  ItemName,
  MoreOption,
  WrapTempInfo,
  Items,
  itemStyles,
  WrapDashAreaSpinner,
  CenterDiv,
  WrapImage
} from "../../userTemplates/userTemplatesStyles";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";
import {ErrorMessage, WrapSpinner} from "../userLibraryStyles";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import UploadImage from "./UploadImage";
import {
  selectActiveWorkSpace,
  selectFolders,
} from "../../../../store/selectors/userDashboard.selector";
import {Link, useNavigate, useParams} from "react-router-dom";
import {loadFoldersAndWorkspace} from "../../../../store/actions/dashboard.action";
import {getDashboardLayout} from "../../userTemplates/getDashboardLayout";
import ImageActions from "./ImageActions";
import ViewImageDetails from "./ViewImageDetails";
import EmptyTemplates from "../../userTemplates/emptyTemplate/EmptyTemplates";
import {proButtonStyles} from "../../../../../oat-ui/styles/proIconStyles";
import { Error } from "../../dashboardPanelStyles";

const DisplayItem = ({onSelect, item, size, onPopperOpen}) => {
  const handlePopperOpen = (e) => {
    onPopperOpen(e, item);
  };

  const handleSelection = () => {
    onSelect(item);
  };

  return (
    <WrapItem
      style={{
        width: size.width,
        height: size.height + 44,
      }}
    >
      <WrapImage onClick={handleSelection} style={{height: size.height}}>
        <Image
          style={{height: size.height, padding: 16, boxSizing: 'border-box'}}
          src={`https://oat-users.s3.amazonaws.com/${item.image}`}
        />
      </WrapImage>
      <WrapTempInfo style={{height: 44}}>
        <ItemName style={{flex: 4}} onClick={handleSelection}>
          {item.name}
        </ItemName>
        <MoreOption onClick={handlePopperOpen}>
          <div />
          <div />
          <div />
        </MoreOption>
      </WrapTempInfo>
    </WrapItem>
  );
};

const popperSize = {width: 200, height: 90};
const ImagesLibrary = ({
  user, 
  workspace, 
  loadFoldersAndWorkspace, 
  folders, 
}) => {
  const [images, setImages] = useState([]);
  const [fetching, setFetching] = useState({
    initialLoading: true, // first time being loaded
    status: false,
    error: false,
  });
  const [page, setPage] = useState(1);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [popper, setPopper] = useState({
    open: false,
    offset: {x: null, y: null},
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModal, setOpenModal] = useState({
    status: false,
    processing: false,
  });
  const [itemSize, setItemSize] = useState({
    width: 0,
    height: 0,
  });

  const windowSize = getWindowSize();
  const {workspaceID, section, folderID} = useParams();
  const fetchFolders = folders && folders?.['library-images']?.length === 0 ? true : false;
  const [currentFolder, setCurrentFolder] = useState(folderID);
  let navigate = useNavigate();

  useEffect(() => {
    const folder = folderID === 'images' ? undefined : folderID;
    setCurrentFolder(folder)
  }, [folderID]);

  const fetchImages = async (url) => {
    setFetching({
      ...fetching,
      status: true,
      error: false,
    });

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // first time loading
          if (page === 1) {
            setImages(data.response.images);
            if (fetchFolders) {
              loadFoldersAndWorkspace({
                folders: data.response.folders,
                workspace: data.response.workspace,
              });
            }
          } else {
            setImages([...images, ...data.response.images]);
          }
          if (data.response.paginationToken) {
            setLastEvaluatedKey(data.response.paginationToken);
          } else {
            setLastEvaluatedKey(null);
          }
          setFetching({
            ...fetching,
            initialLoading: false,
            status: false,
            error: false
          });
        } else {
          setFetching({
            ...fetching,
            status: false,
            error: true,
          });
        }
      })
      .catch((error) => {
        setFetching({
          ...fetching,
          status: false,
          error: true,
        });
      });
  };

  useEffect(() =>{
    if (folderID !== currentFolder) {
      setPage(1);
      setImages([]);
      setFetching({
        initialLoading: true, 
        status: true,
        error: false,
      });
    }
  }, [folderID, currentFolder])

  useEffect(() => {
    if (page === 1 && section === "library") {
      if (folderID === "images") {
        fetchImages(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspaceID}/images?paginationToken=&fetchFolders=${fetchFolders}`
        );
      } else if (folderID !== "images") { // retrive subfolders
        fetchImages(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspaceID}/images?paginationToken=&fetchFolders=${fetchFolders}&folderID=${folderID}`
        );
      }
    }
  }, [workspaceID, section, folderID, page]);

  useEffect(() => {
    // skip first loading and if there is no items left
    if (page !== 1 && section === "library" && lastEvaluatedKey !== null) {
      if (!fetching.status && folderID === "images") {
        fetchImages(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspaceID}/images?paginationToken=${lastEvaluatedKey}&fetchFolders=${fetchFolders}`
        );
      } else if (!fetching.status && folderID !== "images") {
        fetchImages(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspaceID}/images?paginationToken=${lastEvaluatedKey}&fetchFolders=${fetchFolders}&folderID=${folderID}`
        );
      }
    }
  }, [page, lastEvaluatedKey, folderID]);

  const closeModal = () => {
    if (openModal.status) {
      setOpenModal({
        status: false,
        processing: false,
        type: null,
        error: {
          status: false,
          message: null,
        },
      });
    }
  };

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (fetching.initialLoading) return; 
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async(entries) => {
        if (entries[0].isIntersecting && lastEvaluatedKey !== null) {
          setPage((prev) => prev + 1);
        }
      }, { threshold: 0.5 });
      if (node) observer.current.observe(node);
    },
    [fetching.initialLoading, lastEvaluatedKey]
  );

  useEffect(() => {
    const {width, height} = getDashboardLayout(windowSize.width);
    setItemSize({
      width,
      height,
    });
  }, [windowSize.width]);

  const [finishUploading, setFinishUploading] = useState(false);
  const onFinishUploading = async () => {
    setPage(1);
    setLastEvaluatedKey(null);
    setFinishUploading(true);  
    await closeModal();
  };

  useEffect(() => {
    if (finishUploading) {
      if (folderID === "images") {
        fetchImages(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspaceID}/images?fetchFolders=${fetchFolders}`,
        )
      } else {
        fetchImages(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspaceID}/images?fetchFolders=${fetchFolders}&folderID=${folderID}`,
        )
      }
      setFinishUploading(false);  
    }
  },[finishUploading]);

  const handleItemSelection = (item) => {
    setSelectedItem(item);
    viewImageDetails();
  };

  // image popper actions delete + edit
  const viewImageDetails = () => {
    setOpenModal({
      status: true,
      processing: false,
      type: "view-image-details",
      error: false,
    });
    setPopper({
      open: false,
      offset: {x: null, y: null},
    });
  };

  const handleUpdateImageInfo = (imgInfo) => {
    setOpenModal({
      status: true,
      processing: true,
      type: "view-image-details",
      error: {
        status: false,
        message: null,
      },
    });
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace.ID}/images/${selectedItem.key}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          imgInfo,
          folderID: currentFolder
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setImages((prev) =>
            prev.map((item) =>
              item.key === selectedItem.key
                ? {
                    ...item,
                    name: imgInfo.name,
                    description: imgInfo.description,
                  }
                : item
            )
          );
          setOpenModal({
            ...openModal,
            status: false,
            processing: false,
            type: null,
          });
        }
      })
      .catch((err) => {
        setOpenModal({
          ...openModal,
          processing: false,
          error: {
            status: true,
            message: "Something went wrong.",
          },
        });
      });
  };

  const handleDeleteImage = async () => {
    handlePopperClose();
    setOpenModal({
      status: true,
      processing: true,
      error: {
        status: false,
        message: null,
      },
    });
    const deleteUrl = `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace.ID}/images/${selectedItem.key}`;
    await fetch(
      currentFolder ?
        `${deleteUrl}?folderID=${currentFolder}` :
        `${deleteUrl}`,
        {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          const updateImages = images.filter(
            (item) => item.key !== selectedItem.key
          );
          setImages(updateImages);
          setOpenModal({
            ...openModal,
            status: false,
            processing: false,
            type: null,
          });
        } else {
          setOpenModal({
            ...openModal,
            processing: false,
            error: {
              status: true,
              message: "Something went wrong.",
            },
          });
        }
      })
      .catch((e) => {
        setOpenModal({
          ...openModal,
          processing: false,
          error: {
            status: true,
            message: "Something went wrong.",
          },
        });
      });
  };

  // popper
  const handlePopperOpen = (e, item) => {
    if (e.clientY + popperSize.width > window.innerHeight) {
      const extraSpace = 20;
      setPopper({
        open: !popper.open,
        offset: {
          x: e.pageX - popperSize.width + 9,
          y: e.pageY - popperSize.height - extraSpace,
        },
      });
    } else {
      setPopper({
        open: !popper.open,
        offset: {x: e.pageX - popperSize.width, y: e.pageY + 5},
      });
    }
    setSelectedItem(item);
    e.preventDefault();
  };

  const handlePopperClose = () => {
    setPopper({
      open: false,
      offset: {x: 0, y: 0},
    });
  };

  const onUpgradeToPro = () => {
    navigate(`?upgrade=true`, { replace: true });
  }

  return (
    <>
      <div>
        <Header>
          <div className="user-dashboard-header">
            <h3>Images</h3>
            {workspace.plan === "free" ? (
              <UpgradeToPro onClick={onUpgradeToPro}>Pro</UpgradeToPro>
            ) : undefined}
          </div>
          <div>
            {workspace.ID ? (
              <UploadImage
                workspace={workspace}
                onCreated={onFinishUploading}
                onClose={closeModal}
              />
            ) : undefined}
          </div>
        </Header>

        <HeaderDescription>
          Images for your team to easily reuse.
        </HeaderDescription>

        {fetching.error ? (
          <WrapError>
            <Error>Couldn't load data.</Error>
          </WrapError>
        ) : undefined}

        {fetching.status && fetching.initialLoading ? (
          <WrapDashAreaSpinner>
            <Spinner />
          </WrapDashAreaSpinner>
        ) : undefined} 

        {!fetching.status && images && images.length === 0 && !fetching.error ? (
          <CenterDiv
            style={{
              width: "100%",
              marginTop: windowSize.width < 850 ? "15%" : undefined,
            }}
          >
            <EmptyTemplates
              message="Media library is empty"
              smallText="Start uploading images to reuse throughout the creation process"
            />
          </CenterDiv>
        ) : undefined}

        {/* /* Height important for setting infinite scroll to load properly */}
        <div style={{width: "100%", minHeight: windowSize.height - 200}}>
          <Items>
            {images &&
              images.map((item, i) => {
                return (
                  <DisplayItem
                    key={item.key + i}
                    item={item}
                    onSelect={handleItemSelection}
                    size={itemSize}
                    onPopperOpen={handlePopperOpen}
                  />
                );
            })}  
          </Items>  
        </div> 

        {images && images.length > 0 ?
          <>
            <div ref={lastItemRef} />
            <div style={{height: 300}} />        
          </> 
        : undefined} 

          {/* {!fetching.initialLoading && fetching.status ? (
            <div style={{width: '100%', height: 50, display: 'flex', alignItems: 'center'}}>
              <Spinner />
            </div>
          ) : undefined} */}

      {openModal.status && openModal.type !== "view-image-details" ? (
        <Modal
          width={!openModal.error.status ? 240 : 340}
          height="auto"
          close={closeModal}
        >
          <WrapSpinner>
            {openModal.error.status ? (
              <ErrorMessage>
                Failed to delete, something went wrong.
              </ErrorMessage>
            ) : (
              <Spinner />
            )}
          </WrapSpinner>
        </Modal>
      ) : undefined}

      {openModal.status && openModal.type === "view-image-details" ? (
        <ViewImageDetails
          item={selectedItem}
          processing={openModal.processing}
          onUpdate={handleUpdateImageInfo}
          onClose={closeModal}
          error={openModal.error}
        />
      ) : undefined}

      {popper.open ? (
        <Popper
          width={popperSize.width}
          height="auto"
          offset={{x: popper.offset.x, y: popper.offset.y}}
          onClose={handlePopperClose}
        >
          <ImageActions
            onUpdateInfo={viewImageDetails}
            onDelete={handleDeleteImage}
          />
        </Popper>
      ) : undefined}

        {/* {openModal.status &&  ? (
          <Modal width={340} height={100} close={closeModal}>
            <WrapSpinner>
              <ErrorMessage>{error.message}</ErrorMessage>
            </WrapSpinner>
          </Modal>
        ) : undefined} */}
        
      </div>
    </>
  );
};

const WrapItem = styled(Link)`
  ${itemStyles};
`;

export const WrapError = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 184px;
`;

const HeaderDescription = styled.div`
  font-size: 0.95rem;
  color: #464444;
  padding: 0 0 18px 0;
`;

export const UpgradeToPro = styled.div`
  ${proButtonStyles};
  margin-left: 12px;
`;

const mapStateToProps = (state) => {
  const { userDashboard } = state;
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(userDashboard),
    folders: selectFolders(userDashboard),
  };
};

export default connect(mapStateToProps, {loadFoldersAndWorkspace})(
  ImagesLibrary
);
