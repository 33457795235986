import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {ColorSet, SelectedColor, TooltipLayout} from "../reuseTooltipStyles";
import {Content} from "../Tooltip";
import {toggleTooltipPopper} from "../../../../../../store/actions/layout.action";
import TooltipColorPickerHoc from "../tooltipHocs/TooltipColorPickerHoc";
import TooltipItemsHoc from "../tooltipHocs/TooltipItemsHoc";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import StraightenLine from "../../../../editingPanel/panelTypes/desktop/shapes/StraightenLine";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const ShapeTooltip = ({
  active,
  styles,
  subtype,
  updateFieldStyles,
  toggleTooltipPopper,
  popperOpen,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const handleColorOneChange = (e) => {
    e.stopPropagation();
    toggleTooltipPopper("color1");
  };

  const handleColorTwoChange = (e) => {
    e.stopPropagation();
    toggleTooltipPopper("color2");
  };

  const handleStrokeChange = (e) => {
    e.stopPropagation();
    toggleTooltipPopper("stroke");
  };

  const updateColor = (value) => {
    if (popperOpen.status) {
      updateFieldStyles({
        active,
        type: popperOpen.type,
        value,
      });
    }
  };

  const closeColorPicker = () => {
    toggleTooltipPopper();
  };

  const handleDebounceValue = (value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <TooltipLayout>
      <TooltipItemsHoc>
        {styles?.strokeOnly ? (
          <ColorSet>
            <Content style={{marginRight: 0}} onMouseDown={handleStrokeChange}>
              <SelectedColor color={styles?.stroke} />
            </Content>
          </ColorSet>
        ) : (
          <ColorSet>
            <Content
              style={{marginRight: 0}}
              onMouseDown={handleColorOneChange}
            >
              <SelectedColor color={styles?.color1} />
            </Content>

            {styles?.enableGradient ? (
              <Content
                style={{marginRight: 0}}
                onMouseDown={handleColorTwoChange}
              >
                {styles?.color2 === "" || styles?.color2 ? (
                  <SelectedColor color={styles?.color2} />
                ) : undefined}
              </Content>
            ) : undefined}
          </ColorSet>
        )}

        {/* {subtype === "line" ? (
          <Content style={{height: '100%', marginRight: 0}}>
            <StraightenLine>
              Straighten
            </StraightenLine>
          </Content>
        ) : undefined} */}
      </TooltipItemsHoc>

      {popperOpen.status &&
      (popperOpen.type === "color1" || popperOpen.type === "color2") ? (
        <TooltipColorPickerHoc
          onDebounce={handleDebounceValue}
          onClose={closeColorPicker}
          activeColor={styles?.[popperOpen.type]}
          onUpdate={updateColor}
        />
      ) : undefined}
    </TooltipLayout>
  );
};

export default connect(null, {
  updateFieldStyles,
  toggleTooltipPopper,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ShapeTooltip);
