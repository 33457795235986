import styled from "styled-components";

export const Radio = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;
`;

export const RadioButton = styled.div`
  width: 16px;
  height: 16px;
  background: #fff;
  margin-right: 12px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1px ${({active}) => (active ? "#b1b8f7" : "#c9ccd7")};
  div {
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${({active}) => (active ? "#7683cd" : "#b2b4bd")};
    border-radius: 50%;
  }
`;