import React from "react";
import {connect} from "react-redux";
import {
  selectedFieldType,
  selectActiveField,
  selectEditChartInfoStatus,
} from "../../../store/selectors/fields.selector";
import LazyLoad from "react-lazyload";
import {EditPanel, Section, Wrapper, WrapPanel} from "./EditPanelStyles";
import EditTextPanel from "./panelTypes/desktop/text/EditTextPanel";
import EditTablePanel from "./panelTypes/desktop/table/EditTablePanel";
import EditShapePanel from "./panelTypes/desktop/shapes/EditShapePanel";
import EditIconPanel from "./panelTypes/desktop/icons/EditIconPanel";
import EditChartPanel from "./panelTypes/desktop/charts/EditChartPanel";
import BackgroundPanel from "./panelTypes/desktop/BackgroundPanel";
import EditImagePanel from "./panelTypes/desktop/image/EditImagePanel";
import SlideNotes from "./panelTypes/desktop/slideNotes/SlideNotes";
import CommentSection from "./panelTypes/desktop/comments/CommentSection";
import EditVideoPanel from "./panelTypes/desktop/video/EditVideoPanel";
import styled from "styled-components";
import {RightArrow} from "../../../../oat-svg-icons";
import {
  toggleEditPanel,
  updateEditingPanel,
} from "../../../store/actions/layout.action";
import EditAnimation from "./panelTypes/desktop/animations/ApplyAnimations";
import {selectInterceptedFields} from "../../../store/selectors/common.selector";
import GroupedFieldsPanel from "./panelTypes/desktop/GroupedFieldsPanel";
import SlideActionItems from "../slideActionsPanel/SlideActionItems";
import EditChartTable from "./panelTypes/desktop/charts/editChartData/EditChartTable";
import {editingPanelWidthEditingChart} from "../../../layoutSizes";

const DeskEditingPanel = ({
  panelType,
  activeField,
  theme,
  groupedItemsSelected,
  toggleEditPanel,
  openEditChartInfo,
}) => {

  const renderPanels = () => {
    if (panelType === "comment") {
      return <CommentSection theme={theme} />;
    } else if (panelType === "animations" && groupedItemsSelected) { 
      return (
        <>
          <GroupedFieldsPanel />
          <EditAnimation />
        </>
      );
    } else if (panelType === "animations") {
      return <EditAnimation />;
    } else if (panelType === "notes") {
      return <SlideNotes />;
    } 
    // be aware any sections similar to like comment or notes
    // should be placed above this
    else if (
      panelType === "background" ||
      panelType === "" ||
      activeField === -1
    ) {
      return <BackgroundPanel />;
    } else if (activeField !== -1 || panelType === "designs") {
      if (panelType === "text" || panelType === "curve-text") {
        return <EditTextPanel />;
      } else if (panelType === "shape") {
        return <EditShapePanel />;
      } else if (panelType === "icon") {
        return <EditIconPanel />;
      } else if (panelType === "image") {
        return <EditImagePanel />;
      } else if (panelType === "chart") {
        if (openEditChartInfo) {
          return <EditChartTable />;
        } else {
          return <EditChartPanel />;
        }
      } else if (panelType === "table") {
        return <EditTablePanel />;
      } else if (panelType === "embed" || panelType === "video") {
        return <EditVideoPanel />;
      } else {
        return <BackgroundPanel />;
      }
    }
  };

  //  else {
  //   if (panelType === "comment") {
  //     displayOptions = <CommentSection />;
  //   } else if (panelType === "notes") {
  //     displayOptions = <SlideNotes />;
  //   } else {
  //     displayOptions = <BackgroundPanel />;
  //   }
  // }

  const hidePanel = () => {
    toggleEditPanel();
  };

  return (
    <LazyLoad height="100%" once>
      <Wrapper
        style={{position: "relative"}}
        openEditChartInfo={openEditChartInfo}
        editingPanelWidthEditingChart={editingPanelWidthEditingChart}
      >
        <WrapPanel>
          <PanelTab onClick={hidePanel}>
            <div>{RightArrow}</div>
          </PanelTab>
          
          <EditPanel>
            <PanelTypes>
              <SlideActionItems
                theme={theme}
                panelOpened={true}
                panelType={panelType}
              />
            </PanelTypes>

            <Section>{renderPanels()}</Section>
          </EditPanel>
        </WrapPanel>
      </Wrapper>
    </LazyLoad>
  );
};

const PanelTab = styled.div`
  position: absolute;
  top: 40%;
  left: 0px;
  width: 8px;
  height: 82px;
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.deskSecondaryBg};
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border: 1px solid ${({theme}) => theme.rightPanelBorder};
  border-left: 1px solid ${({theme}) => theme.deskSecondaryBg};
  cursor: pointer;
  z-index: 99;
  div {
    position: relative;
    left: -2px;
    width: 18px;
    height: 18px;
    transform: scale(1.2);
    fill: ${({theme}) => theme.deskBoldText};
  }
`;

const PanelTypes = styled.div`
  box-shadow: ${({theme}) => theme.boxShadow3};
`;

function mapStateToProps(state) {
  const {designTemplate} = state;
  return {
    panelType: selectedFieldType(designTemplate),
    activeField: selectActiveField(designTemplate),
    groupedItemsSelected:
      selectInterceptedFields(designTemplate).selection?.show,
    openEditChartInfo: selectEditChartInfoStatus(designTemplate),
  };
}

export default connect(mapStateToProps, {updateEditingPanel, toggleEditPanel})(
  DeskEditingPanel
);
