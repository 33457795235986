import React, {useRef, useEffect, useState, useMemo} from "react";
import {connect} from "react-redux";
import styled, { css } from "styled-components";
import {enableTextEdit} from "../../../../../store/actions/fields/text.action";
import {storeFieldAsActiveUndoRedo, storeFieldUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {selectEnableTextEdit} from "../../../../../store/selectors/fields.selector";
import {ulOlLi} from "../../../parts/ul-ol-li";
import {isDesktop} from "react-device-detect";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import EditTextTableContents from "../../../drawer/common/EditTextTableContents";
import { sanitizeInput } from "../../dragResize/utils";
import TypingTextField from "./TypingTextField";

const TextField = React.forwardRef(
  (
    {
      field,
      onSelectItem,
      handleDragStart,
      selected,
      onEditText,
      editingText,
      enableTextEdit,
      storeFieldAsActiveUndoRedo,
      dragging,
      disableLinkStyles,
    },
    fieldRef
  ) => {
    // start dispatching undo on first char input
    const [timesClicked, setTimesClicked] = useState(0);
    const [firstCharInput, setFirstCharInput] = useState(false);
    const textInput = useRef(field.content);
    const [prevFieldOffset, setPrevFieldOffset] = useState(field.pos);
    const {styles, size, effects, subtype} = field;
    const windowSize = getWindowSize();

    const handleInput = (e) => {
      if (!firstCharInput) {
        setFirstCharInput(true);
      }

      // const content = e.target.textContent; // field.content;
      // const isEmptyOrWhitespace = /^\s*$/.test(content);
      // if (isEmptyOrWhitespace) {
      //   // textInput.current = e.target.innerHTML
      //   e.preventDefault();
      //   e.stopPropagation();
      // } else {
        onEditText(e);
      // }  
    };

    useEffect(() => {
      textInput.current = field.content;
    }, [editingText]);

    // useEffect(() => {
    //   console.log(undoActiveState?.item?.content, textInput.current);
    //   if (undoActiveState?.item?.type === "text" &&
    //   field.key === undoActiveState?.item?.key && 
    //   undoActiveState?.item?.content !== textInput.current) {
    //     textInput.current = undoActiveState?.item?.content;
    //   }
    // }, [undoActiveState, field.key, textInput.current]);
    
    useEffect(() => {
      if (editingText) setFirstCharInput(false);
    }, [editingText]);

    const recordTimesClicked = (e) => {
      setTimesClicked(timesClicked + 1);
    };

    const cancelEvent = (e) => {
      e.stopPropagation();
    };

    useEffect(() => {
      // after first time selecting a txt field and then instantly moved
      // before the timeout editText frame, enableTextEdit will be activated
      // this code is to avoid that behaviour
      if (timesClicked === 1 && field.pos.x !== prevFieldOffset.x) {
        setTimesClicked(0);
      } else if (timesClicked === 1) {
        setTimeout(() => {
          setTimesClicked(0);
        }, 550);
      }
      if (timesClicked === 2 && field.pos.x === prevFieldOffset.x) {
        enableTextEdit();
      }
    }, [timesClicked, field.pos]);

    useEffect(() => {
      setPrevFieldOffset(field.pos);
    }, [field.pos]);

    const [fieldPrevContent, setFieldPrevContent] = useState(field.content);
    useEffect(() => {
      if (!editingText && field.content !== fieldPrevContent) {
        storeFieldAsActiveUndoRedo();
        // textInput.current = field.content;
      }
      if (editingText) {
        setFieldPrevContent(field.content);
        // textInput.current = field.content;
      }
    }, [editingText]);

    const textStyles = useMemo(
      () => ({
        fontFamily: field.font?.custom
          ? `${styles.fontFamily + "-" + styles.fontWeight + "-" + styles.fontStyle}, 'Noto Color Emoji' , sans-serif`
          : `${styles.fontFamily}, 'Noto Color Emoji', sans-serif`,
        fontSize: styles.fontSize + "px",
        fontStyle: styles.fontStyle,
        fontWeight: styles.fontWeight,
        letterSpacing: styles.letterSpacing,
        lineHeight: styles.lineHeight,
        opacity: styles.opacity,
        textAlign: styles.textAlign,
        textDecoration: styles.textDecoration,
        transform: `scale(${styles.scale})`,
        width: size.w2,
        height: size.h2,
        color: styles.color1,
        whiteSpace: styles.whiteSpace,
        WebkitTextStroke:
          effects &&
          effects.strokeWidth &&
          `${effects.strokeWidth}px ${styles.color1}`,
        WebkitTextFillColor: effects && effects.strokeWidth && "transparent",
        textShadow:
          effects &&
          effects.shadow &&
          effects.shadow.map(
            (item) => `${item.h}em ${item.v}em ${item.b}em ${item.c}`
          ),
        scale: 1, // for firefox
        // WebkitPrintColorAdjust: "exact",
      }),
      [styles, effects, size, styles.fontFamily, field.font?.custom]
    );

    const applyWillTransform = useMemo(() => {
      let willChangeStyle;
      if (dragging) {
        willChangeStyle = {
          willChange: !dragging ? "transform" : undefined, // else will cause blurry after text resize
        };
      }
      return willChangeStyle;
    }, [dragging]);

    const closeDrawer = (e) => {
      if (editingText) {
        enableTextEdit();
      }
      if (e?.stopPropagation()) {
        e.stopPropagation();
      }
    };

    const handlePaste = (event) => {
      event.preventDefault();

      const clipboardData = event.clipboardData || window.clipboardData;
      const getPastedHTMLContent = clipboardData.getData("text/html");
      const getPastedPlainTextContent = clipboardData.getData("text");
      const pastedHTMLContent = sanitizeInput(getPastedHTMLContent);
      const pastedPlainTextContent = sanitizeInput(getPastedPlainTextContent);

      if (pastedHTMLContent) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = pastedHTMLContent;

        const filteredSpans = Array.from(
          tempDiv.querySelectorAll("span")
        ).filter((span) => span.classList.contains("inside-text"));

        const plainText = tempDiv.textContent || tempDiv.innerText; // Extract plain text without HTML tags

        const filteredContent = document.createElement("div");
        filteredSpans.forEach((span) => {
          filteredContent.appendChild(span);
        });

        filteredContent.appendChild(document.createTextNode(plainText));
        document.execCommand("insertHTML", false, filteredContent.innerHTML);
      } else if (pastedPlainTextContent) {
        document.execCommand("insertHTML", false, pastedPlainTextContent);
        // not updating to state - TO REPLACE EXEC COMMAND
        // const selection = window.getSelection();
        // const range = selection.getRangeAt(0);
        // const textNode = document.createTextNode(pastedPlainTextContent);
        // range.insertNode(textNode);
        // range.setStartAfter(textNode);
        // range.collapse(true);
        // selection.removeAllRanges();
        // selection.addRange(range);
      }
    };

    // useEffect(() => {
    //   const restoreSelection = () => {
    //     if(fieldRef?.current) {
    //     const selection = window.getSelection();
    //     const range = document.createRange();
    //     range.setStart(fieldRef?.current.firstChild, field.content.length);
    //     range.collapse(true);
    //     selection.removeAllRanges();
    //     selection.addRange(range);
    //     }
    //   };
    //   restoreSelection();
    // }, [field.content]);

    const getTextType = () => {
      const {styles, size, pos, key} = field;
      if (editingText && selected) {
        return (
          <TypingTextField 
            ref={fieldRef} 
            name={`text-field-${field.key}`}
            styles={styles} 
            size={size}
            offset={pos}
            textStyles={textStyles}
            applyWillTransform={applyWillTransform}
            textInput={textInput} 
            onInputChange={handleInput} 
            subtype={subtype}
            onPaste={handlePaste}
            cancelEvent={cancelEvent}
            disableLinkStyles={disableLinkStyles}
          />
          // <Field
          //   id={`text-field-editing-active`}
          //   ref={fieldRef}
          //   spellCheck="true"
          //   fontSize={styles.fontSize}
          //   lineHeight={styles.lineHeight}
          //   style={{...textStyles, ...applyWillTransform}}
          //   onMouseDown={cancelEvent}
          //   onPaste={handlePaste}
          //   fontSource={styles.fontStyle}
          //   contentEditable={true}
          //   dangerouslySetInnerHTML={{
          //     __html: textInput.current,
          //   }}
          //   suppressContentEditableWarning={true}
          //   onInput={handleInput}
          //   subtype={subtype}
          //   background={styles.bg1}
          //   $unorderedBullet={{
          //     bulletType: styles.bulletType,
          //     bulletColor: styles.bulletColor
          //   }}
          //   disableLinkStyles={disableLinkStyles}
          // />
        );
      } else {
        return (
          <Field
            ref={fieldRef}
            name={`text-field-${field.key}`}
            spellCheck="false"
            onMouseUp={isDesktop ? recordTimesClicked : undefined}
            onTouchEnd={recordTimesClicked}
            fontSize={styles.fontSize}
            lineHeight={styles.lineHeight}
            style={{...textStyles, ...applyWillTransform}}
            contentEditable={false} // plaintext-only
            dangerouslySetInnerHTML={{
              __html: field.content,
            }}
            suppressContentEditableWarning={true}
            onMouseDown={handleDragStart}
            onTouchStart={selected ? handleDragStart : onSelectItem}
            subtype={subtype}
            background={styles.bg1}
            $unorderedBullet={{
              bulletType: styles.bulletType,
              bulletColor: styles.bulletColor
            }}
            disableLinkStyles={disableLinkStyles}
          />
        );
      }
    };

    const mobileTextRef = useRef(fieldRef);
    useEffect(() => {
      if (
        selected &&
        editingText &&
        mobileTextRef.current &&
        windowSize.width < 850
      ) {
        if (mobileTextRef.current) {
          const lastChild = mobileTextRef.current.lastElementChild;
          // first condition for firefox li tags
          if (lastChild.lastChild && lastChild.lastChild.tagName === "LI") {
            const newRange = document.createRange();
            const selection = window.getSelection();
            newRange.selectNodeContents(lastChild.lastChild.lastElementChild);
            newRange.collapse(false);
            selection.removeAllRanges();
            selection.addRange(newRange);
          } else if (lastChild) {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(lastChild);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      }
    }, [editingText, selected]);

    return (
      <>
        {getTextType()}

        {windowSize.width < 850 &&
        editingText &&
        selected &&
        field.type === "text" ? (
          <EditTextTableContents close={closeDrawer}>
            <Field
              ref={mobileTextRef}
              name={`text-field-${field.key}`}
              spellCheck="true"
              fontSize={styles.fontSize}
              lineHeight={styles.lineHeight}
              style={{
                fontStyle: styles.fontStyle,
                fontFamily: field.font?.custom
                  ? `${styles.fontFamily +
                    "-" +
                    styles.fontWeight +
                    "-" +
                    styles.fontStyle}, 'Noto Color Emoji', sans-serif`
                  : `${styles.fontFamily}, 'Noto Color Emoji', sans-serif`,
                letterSpacing: styles.letterSpacing,
                lineHeight: styles.lineHeight,
                paddingBottom: 100,
              }}
              autoFocus={true}
              onMouseDown={cancelEvent}
              onPaste={handlePaste}
              fontSource={styles.fontStyle}
              contentEditable={true}
              dangerouslySetInnerHTML={{
                __html: textInput.current,
              }}
              suppressContentEditableWarning={true}
              onInput={handleInput}
              subtype={subtype}
              $unorderedBullet={{
                bulletType: styles.bulletType,
                bulletColor: styles.bulletColor
              }}
              disableLinkStyles={disableLinkStyles}
            />
          </EditTextTableContents>
        ) : undefined}
      </>
    );
  }
);

export const Field = styled.div`
  height: 100%;
  width: 100%;
  overflow-wrap: break-word;
  transform-origin: left top;
  h2 {
    height: 100%; // for header
  }
  // word-wrap: break-word;
  outline: none;
  ${ulOlLi};
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-font-kerning: none;
  font-kerning: none;
  font-style: normal;
  word-break: normal;
  --para-spacing: 0;
  font-synthesis: none;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricPrecision;
  overflow: visible;
  // overflow-wrap: break-word;
  text-size-adjust: none;
  // -webkit-user-modify: read-write-plaintext-only;
  // white-space: break-spaces;
  span {
    a {
      color: inherit;
    }
  }
  ${props => props.disableLinkStyles &&
    css`
      a {
        cursor: default;
        user-select: none;
        pointer-events: none;
      }
    `}
`;

export const DrawerLayout = styled.div`
  width: 100%;
  height: ${({height}) => height}px;
  background: ${({theme}) => theme.deskPrimaryBg};
  font-size: 2.4rem;
`;

export const DrawerNavPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 52px;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 18px;
  box-sizing: border-box;
  z-index: 1111;
  div {
    padding: 2px 6px;
  }
`;

const mapStateToProps = (state) => {
  return {
    editingText: selectEnableTextEdit(state.designTemplate),
    // undoActiveState: selectUndoRedoHistory(state.designTemplate).active,
  };
};

export default connect(
  mapStateToProps,
  {enableTextEdit, storeFieldAsActiveUndoRedo},
  null,
  {
    forwardRef: true,
  }
)(TextField);
