const CommentLightTheme = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    style={{transform: `scale(2.7)`}}
  >
    <defs>
      <radialGradient
        id="gVwHCApzX544-fill"
        cx="42.88"
        cy="86.53"
        r="112.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffabfb" />
        <stop offset="0.35" stopColor="#6b70f9" />
        <stop offset="0.85" stopColor="#fff" />
      </radialGradient>
    </defs>
    <g id="gVwHCApzX544">
      <path
        id="gVwHCApzX544"
        fill="url(#gVwHCApzX544-fill)"
        d="M68.4,83.2a17.5,17.5,0,0,1,6.7-2.5A21.1,21.1,0,0,0,70.3,39c-4.5.2-9,0-13.5,0A21.1,21.1,0,0,0,35.7,59.7c-.1,10.8,7.4,19.8,17.8,21.1a2.8,2.8,0,0,1,2.4,3.1,12.5,12.5,0,0,1-1.1,4.8.4.4,0,0,0,.5.6A52.7,52.7,0,0,0,68.4,83.2Z"
      />
      <g id="gVwHCApzX544">
        <g id="gVwHCApzX544_to" data-name="gVwHCApzX544 to">
          <g id="gVwHCApzX544_ts" data-name="gVwHCApzX544 ts">
            <circle
              id="gVwHCApzX544"
              style={{fill: `#fff`}}
              cx="50"
              cy="60"
              r="3.2"
            />
          </g>
        </g>
        <g id="gVwHCApzX544_to" data-name="gVwHCApzX544 to">
          <g id="gVwHCApzX544_ts" data-name="gVwHCApzX544 ts">
            <circle
              id="gVwHCApzX544"
              style={{fill: `#fff`}}
              cx="63.8"
              cy="60.1"
              r="3.2"
            />
          </g>
        </g>
        <g id="gVwHCApzX544_to" data-name="gVwHCApzX544 to">
          <g id="gVwHCApzX544_ts" data-name="gVwHCApzX544 ts">
            <circle
              id="gVwHCApzX544"
              style={{fill: `#fff`}}
              cx="78.1"
              cy="60.1"
              r="3.2"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CommentLightTheme;
