import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {Popper, Spinner} from "../../../../oat-ui";
import {
  Header,
  Image,
  ItemName,
  MoreOption,
  WrapTempInfo,
  Layout,
  Items,
  itemStyles,
  WrapDashAreaSpinner,
} from "../userTemplates/userTemplatesStyles";
import {Link, useNavigate} from "react-router-dom";
import ModalTypes from "../../dashboardPanel/userTemplates/ModalTypes";
import EmptyTemplates from "../userTemplates/emptyTemplate/EmptyTemplates";
import SharedTempPopper from "./SharedTempPopper";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {getDashboardLayout} from "../userTemplates/getDashboardLayout";

const DisplayItem = ({item, size, onSelectItem, onPopperOpen}) => {
  const handleItemClick = async () => {
    onSelectItem(item);
  };

  const handlePopperOpen = (e) => {
    onPopperOpen(e, item);
  };

  return (
    <WrapItem style={{width: size.width, height: size.height + 46}}>
      <Image
        style={{height: size.height}}
        src={`https://oat-users.s3.amazonaws.com/${item.image}`}
        onClick={handleItemClick}
      />
      <WrapTempInfo>
        <ItemName style={{flex: 4}} onClick={handleItemClick}>
          {item.title}
        </ItemName>
        <MoreOption onClick={handlePopperOpen}>
          <div />
          <div />
          <div />
        </MoreOption>
      </WrapTempInfo>
    </WrapItem>
  );
};

const SharedTemplates = ({user}) => {
  const popperSize = {
    width: 200,
    height: 60, //!user.account.business.profile ? 60 : 92,
  };
  const [selectedItem, setSelectedItem] = useState({
    id: null,
    title: null,
  });
  const [popper, setPopper] = useState({
    open: false,
    offset: {x: null, y: null},
  });
  const [modal, setModal] = useState({
    type: "",
    status: false,
    message: null,
  });
  const [processing, setProcessing] = useState();
  const [templates, setTemplates] = useState({
    items: [],
    paginationToken: null,
  });
  const windowSize = getWindowSize();

  useEffect(() => {
    setProcessing(true);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/shared-to-me/templates?sharedFolder=&paginationToken=`
    )
      .then((response) => response.json())
      .then((data) => {
        setTemplates({
          items: data.sharedItems,
          paginationToken: data.paginationToken,
        });
        setProcessing(false);
      })
      .catch((e) => {});
  }, []);

  const handlePopperOpen = (e, item) => {
    setSelectedItem({
      id: item.tempID,
      tempSourceID: item.tempSourceID,
      title: item.title,
      owner: item.owner,
    });
    if (e.clientY + popperSize.width > window.innerHeight) {
      const extraSpace = 25;
      setPopper({
        open: !popper.open,
        offset: {
          x: e.pageX - popperSize.width,
          y: e.pageY - popperSize.height - extraSpace,
        },
      });
    } else {
      setPopper({
        open: !popper.open,
        offset: {x: e.pageX - popperSize.width, y: e.pageY - 20},
      });
    }
    e.preventDefault();
  };

  const handlePopperClose = () => {
    setPopper(false);
  };

  const openModal = (value) => {
    setModal({
      type: value,
      status: true,
    });
    setPopper({
      open: false,
      offset: {x: null, y: null},
    });
  };

  const closeModal = () => {
    setModal({
      type: "",
      status: false,
    });
  };

  let navigate = useNavigate();
  const selectTemplate = async (item) => {
    setModal({
      type: "processing",
      status: true,
      message: null,
    });
    await fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/shared-to-me/templates/${item.tempID}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.response === "read-write") {
          return navigate(`/design/${item.tempID}`);
        } else {
          return navigate(`/p/${item.tempID}`);
        }
      })
      .catch((error) => {
        setModal({
          type: "display-message",
          status: true,
          message: "Something went wrong.",
        });
      });
  };

  const [itemSize, setItemSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const {width, height} = getDashboardLayout(windowSize.width);
    setItemSize({
      width,
      height,
    });
  }, [windowSize.width]);

  return (
    <Layout>
      <Header>
        <div style={{marginTop: 8}} className="user-dashboard-header">
          <h3>Shared To You</h3>
        </div>
      </Header>

      {!processing ? (
        <Items>
          {templates.items &&
            templates.items.map((item, i) => (
              <DisplayItem
                key={i}
                item={item}
                onSelectItem={selectTemplate}
                onPopperOpen={handlePopperOpen}
                size={itemSize}
              />
            ))}
        </Items>
      ) : (
        <WrapDashAreaSpinner>
          <Spinner />
        </WrapDashAreaSpinner>
      )}

      {!processing && templates.items.length === 0 ? (
        <CenterDiv style={{width: "100%"}}>
          <EmptyTemplates
            message="Shared items will appear here."
            smallText=""
          />
        </CenterDiv>
      ) : undefined}

      {popper.open ? (
        <Popper
          width={popperSize.width}
          height={popperSize.height}
          offset={{x: popper.offset.x, y: popper.offset.y}}
          styles={{
            boxShadow: "0px 0px 3px 0px #827f8d",
          }}
          onClose={handlePopperClose}
        >
          <SharedTempPopper openModal={openModal} user={user} />
        </Popper>
      ) : undefined}

      {modal.status ? (
        <ModalTypes
          type={modal.type}
          close={closeModal}
          selectedItem={selectedItem}
          message={modal.message}
        />
      ) : undefined}
    </Layout>
  );
};

const CenterDiv = styled.div`
  height: ${window.innerHeight - 150}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapItem = styled(Link)`
  ${itemStyles}
`;

const mapStateToProps = (state) => {
  const {authUser} = state;
  return {
    user: selectUserInfo(authUser),
  };
};

export default connect(mapStateToProps, null)(SharedTemplates);
