import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import {resizeTableRowCell} from "../../../../../../store/actions/fields/table.action";
import {
  getItemXOffset,
  getItemYOffset,
} from "../../../../../../utils/getItemOffset";
import {calcOnResize} from "../../../dragResize/resize/utils/calcOnResize";
import {centerToTL} from "../../../dragResize/utils";
import {connect} from "react-redux";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

export const tableResizerBarColor = "#7385e3";
const ShowRowResizer = ({top, index, tempScale, initResize, showBar}) => {
  const handleResize = (e) => {
    initResize(e, index);
  };
  return (
    <RowResizerBar
      onMouseDown={handleResize}
      onTouchStart={handleResize}
      style={{top}}
      tempScale={tempScale}
      showBar={showBar}
    />
  );
};

const ResizeRowCells = ({
  rows,
  tempScale,
  slideID,
  activeField,
  tableSize,
  resizeTableRowCell,
  updateTableHeight,
  resizingRow,
  onResizeStart,
  onResizeEnd,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const [rowResize, setRowResize] = useState({});
  const delayDebounce = useRef();

  const handleResizeStart = (e, index) => {
    const clientX = getItemXOffset(e, tempScale);
    const clientY = getItemYOffset(e, tempScale);
    onResizeStart(index);
    setRowResize({
      orig: {
        mouseX: Math.round(clientX),
        mouseY: Math.round(clientY),
      },
    });
    e.stopPropagation();
  };

  const onResize = (e) => {
    const {orig} = rowResize;
    const cellIndex = resizingRow.index;
    const clientY = getItemYOffset(e, tempScale);
    let direction = orig.mouseY > clientY ? "top" : "bottom";
    const {centerX, centerY, width, height, rotate} = calcOnResize({
      e,
      size: {
        w: 0,
        h: rows[cellIndex].height,
      },
      pos: {
        x: 0,
        y: 0,
      },
      initialProps: rowResize,
      tempScale,
      rotate: 0,
      direction: "bottom", // don't confuse with resizing direction
      propotion: false,
      balanceRatio: false,
    });
    const resize = centerToTL({
      centerX,
      centerY,
      width,
      height,
      rotate,
    });
    const sizeDiff = resize.height - rows[cellIndex].height;
    let resizeTableProps = {};
    if (direction === "bottom") {
      resizeTableProps = {
        slideID,
        fieldID: activeField,
        row: {
          index: cellIndex,
          height: resize.height,
        },
        addedHeight: sizeDiff,
        initTableSize: {
          w: tableSize.w,
          h: tableSize.h,
        },
      };
      resizeTableRowCell({...resizeTableProps});
    } else if (direction === "top") {
      resizeTableProps = {
        slideID,
        fieldID: activeField,
        row: {
          index: cellIndex,
          height: resize.height,
        },
        addedHeight: sizeDiff,
        initTableSize: {
          w: tableSize.w,
          h: tableSize.h,
        },
      };
      resizeTableRowCell({...resizeTableProps});
    }
    updateTableHeight();

    clearTimeout(delayDebounce.current);
    delayDebounce.current = setTimeout(() => {
      updateFieldCollaboration();
      // const item = {type: "resize-table-row-cells", value: resizeTableProps};
      // emitSocketEvents({actionType: "update-chart", item});
    }, 4000);

    e.stopPropagation();
  };

  const handleResizeEnd = async (e) => {
    onResizeEnd();
    storeFieldUndoRedo();
    e.stopPropagation();
  };

  useEffect(() => {
    if (resizingRow.status) {
      window.addEventListener("touchmove", onResize);
      window.addEventListener("touchend", handleResizeEnd);
      window.addEventListener("mouseup", handleResizeEnd);
      window.addEventListener("mousemove", onResize);
      window.addEventListener("mouseleave", handleResizeEnd);
    }
    return () => {
      window.removeEventListener("touchmove", onResize);
      window.removeEventListener("touchend", handleResizeEnd);
      window.removeEventListener("mouseup", handleResizeEnd);
      window.removeEventListener("mousemove", onResize);
      window.removeEventListener("mouseleave", handleResizeEnd);
    };
  }, [resizingRow.status]);

  return (
    rows &&
    rows.map((row, index) => (
      <ShowRowResizer
        key={index}
        index={index}
        tempScale={tempScale}
        top={row.yOffset + row.height - 2}
        initResize={handleResizeStart}
        showBar={index === resizingRow.index && resizingRow.status}
      />
    ))
  );
};

const RowResizerBar = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: ${({tempScale}) => 2 / tempScale}px;
  background: transparent;
  cursor: ns-resize;
  background: ${({showBar}) => (showBar ? tableResizerBarColor : undefined)};
  z-index: 9999;
  &:hover {
    background: ${tableResizerBarColor};
  }
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    tempScale: selectTempScale(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveField(designTemplate),
  };
};

export default connect(mapStateToProps, {
  resizeTableRowCell,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ResizeRowCells);
