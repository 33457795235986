import React, { useCallback } from "react";
import {connect} from "react-redux";
import {selectTempRatioDiff} from "../../../../../../../store/selectors/layout/layout.selector";
import { compactFormatter, formatWithCommas } from "../barProps/ScaleLabels";

const ValueLabel = ({
  x,
  y,
  color,
  value,
  fontSize,
  fontFamily,
  textAnchor,
  alignmentBaseline,
  rotate,
  dominantBaseline,
  scaleFormat
}) => {
  const formatScaleValues = useCallback(
    (item) => {
      // compact notion
      if (scaleFormat === "1k") {
        return compactFormatter(item, 1);
      } else if (scaleFormat === "1,000") {
        return formatWithCommas(item);
      } else {
        return item;
      }
    },
    [scaleFormat]
  );

  return (
    <g style={{ transform:`translate(${x}px, ${y}px) `}}>
      <text
        x={0}
        y={0}   
        style={{
          textAnchor,
          fontSize,
          fontFamily,
          alignmentBaseline,
          fill: color,
          transform: `rotate(${rotate}deg)`,
          dominantBaseline: dominantBaseline,
        }}
        data-z-index="10"
      >
        {/* {value} */}
        {formatScaleValues(value)}
      </text>
    </g>
  );
};
ValueLabel.defaultProps = {
  alignmentBaseline: "middle",
  rotate: 0,
  fontFamily: "Nunito, sans-serif",
  dominantBaseline: "auto",
};

const mapStateToProps = (state) => {
  return {
    ratioDiff: selectTempRatioDiff(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(ValueLabel);
