import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {darkGrey1, paleBlue2, red1} from "../../../../../oat-color-variables";
import {Trash} from "../../../../../oat-svg-icons";
import {connect} from "react-redux";
import {Spinner} from "../../../../../oat-ui";

const DisplayItem = ({item, onRemoveUser, deleting}) => {
  const handleClick = () => {
    onRemoveUser(item);
  };

  return (
    <WrapItem style={{opacity: !deleting ? 1 : 0.4}}>
      <Item>{item}</Item>
      <WrapSvg onClick={handleClick}>{Trash}</WrapSvg>
    </WrapItem>
  );
};

const SharedUserList = ({user, tempID}) => {
  const [userList, setUserList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setProcessing(true);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/shared/templates/${tempID}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserList(data);
        setProcessing(false);
      })
      .catch((e) => {});
  }, []);

  const removeUser = (removeUser) => {
    setDeleting(true);
    const requestOptions = {
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        tempID,
        owner: user.username,
        section: "templates",
        removedUser: removeUser,
      }),
    };
    fetch(
      ` https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/shared/templates`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setDeleting(false);
        setUserList((items) => items.filter((item) => item !== removeUser));
      })
      .catch((e) => {});
  };

  return (
    <Wrapper>
      {!processing && userList.length !== 0
        ? userList &&
          userList.map((item, i) => (
            <DisplayItem
              item={item}
              key={i}
              onRemoveUser={removeUser}
              deleting={deleting}
            />
          ))
        : undefined}

      {processing ? (
        <div style={{paddingTop: 45}}>
          <Spinner />
        </div>
      ) : undefined}

      {!processing && userList.length === 0 ? (
        <Message>You haven't shared with anyone.</Message>
      ) : undefined}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 220px;
  overflow-y: scroll;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrapSvg = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  cursor: pointer;
  fill: ${red1};
`;

const Item = styled.div`
  font-size: 1rem;
  padding: 4px 0px 4px 14px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
`;

const WrapItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 8px 0;
  color: ${darkGrey1};
  border-radius: 4px;
  &:hover {
    background: ${paleBlue2};
  }
`;

const Message = styled.p`
  font-size: 0.9rem;
  color: inherit;
  padding: 8px 0;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(SharedUserList);
