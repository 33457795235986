import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  dispatchRedo,
  dispatchUndo,
} from "../../../../store/actions/fields/undoRedo.action";
import {
  selectUndoRedoHistory,
  selectUndoRedoToEmitSocket,
} from "../../../../store/selectors/fields.selector";
import {TooltipStyles} from "../../../../ui/styles/TooltipStyles";
import {useSocket} from "../../../../webSocket/useSocket";

const UndoRedo = ({dispatchRedo, dispatchUndo, undoState, undoRedoToEmit}) => {
  const [emitToSocket, setEmitToSocket] = useState(false);
  const {emitSocketEvents} = useSocket() || {};

  const handleUndo = async () => {
    if (undoState.history.length !== 0) {
      await dispatchUndo();
      await setEmitToSocket(true);
    }
  };

  const handleRedo = async () => {
    if (undoState.future.length !== 0) {
      await dispatchRedo();
      await setEmitToSocket(true);
    }
  };

  // will not deselect field on clicking the div
  const cancelEventPropagate = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (emitToSocket) {
      const item = {type: undoRedoToEmit?.type, value: undoRedoToEmit};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "grouped-fields-undoRedo", item});
      }
    }
  }, [emitToSocket, undoRedoToEmit]);

  return (
    <Layout onMouseDown={cancelEventPropagate}>
      <Item onClick={handleUndo}>
        <WrapSvg $edge={undoState.history.length === 0}>
          <svg viewBox="0 0 24 24">
            <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"></path>
          </svg>
        </WrapSvg>
        <Tooltip>Undo</Tooltip>
      </Item>

      <GapLine />

      <Item onClick={handleRedo}>
        <WrapSvg $edge={undoState.future.length === 0}>
          <svg viewBox="0 0 24 24">
            <path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"></path>
          </svg>
        </WrapSvg>
        <Tooltip>Redo</Tooltip>
      </Item>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 12px;
  user-select: none;
  @media only screen and (min-width: 850px) {
    background: ${({theme}) => theme.deskSecondaryBg2};
    box-shadow: ${({theme}) => theme.deskBtmSectionBoxShadow};  
    border: 1px solid ${({theme}) => theme.deskBtnSectionBorder};
    border-radius: 6px;
  }
`;

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: ${({theme}) => theme.svgIconColor};
  opacity: ${({$edge}) => (!$edge ? 0.8 : 0.4)};
  cursor: ${({$edge}) => (!$edge ? "pointer" : "not-allowed")};
`;

const GapLine = styled.div`
  height: 50%;
  width: 1px;
  background: ${({theme}) => theme.undoRedoGapline};
`;

const Tooltip = styled.div`
  width: 52px;
  top: -28px;
  right: -25px;
  font-size: 0.9rem;
  font-weight: 600;
  ${TooltipStyles};
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover ${Tooltip} {
    display: flex;
  }
`;

const mapStateToProps = (state) => {
  return {
    undoState: selectUndoRedoHistory(state.designTemplate),
    undoRedoToEmit: selectUndoRedoToEmitSocket(state.designTemplate),
  };
};

export default connect(mapStateToProps, {dispatchUndo, dispatchRedo})(UndoRedo);
