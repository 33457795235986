import React from "react";
import {connect} from "react-redux";
import {
  selectCachedFieldStyles,
  selectActiveFieldProps,
  selectCropImage,
} from "../../../../../../store/selectors/fields.selector";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import Overlay from "./Overlay";
import AdjustImage from "./AdjustImage";
import ImageShadow from "./ImageShadow";
import FlipImg from "./FlipImg";
import {
  cropImage,
  updateReplaceImgStatus,
} from "../../../../../../store/actions/fields/image.action";
import {SplitLine} from "../common/SplitLine";
import RangeSlider from "../../shared/inputs/RangeSlider";
import LayoutPosition from "../common/LayoutPosition";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import styled from "styled-components";
import {Crop} from "../../../../../../../oat-svg-icons";
import EditImageBlur from "./EditImageBlur";
import EditOpacity from "../common/EditOpacity";
import AddLink from "../common/AddLink";

const EditImage = ({
  stableStyles,
  updateFieldStyles,
  activeField,
  slideID,
  cropImage,
  updateReplaceImgStatus,
  croppingActive,
}) => {
  const {styles} = activeField || {};
  const {
    opacity,
    blur,
    borderRadius,
    scaleX,
    scaleY,
    overlayColor,
    overlayDegree,
    overlayType,
  } = styles || {};

  const onUpdateStyles = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const updateOpacity = (value) => {
    onUpdateStyles("opacity", Number(value));
  };

  const updateBlur = (value) => {
    onUpdateStyles("blur", value);
  };

  const updateBorderRadius = (value) => {
    onUpdateStyles("borderRadius", Number(value));
  };

  // const toggleCutoutStatus = () => {
  //   onUpdateStyles("cutoutImg", !activeField.styles?.cutoutImg);
  // }

  const handleReplace = () => {
    updateReplaceImgStatus();
  };

  const handleCrop = () => {
    if (!croppingActive) {
      cropImage(true);
    } else {
      cropImage(false);
    }
  };

  return (
    <div style={{margin: "1rem 0 6rem", minHeight: 1100 }}>
      <LayoutPosition rotate={styles?.rotate} locked={styles?.lock} />
      <SplitLine style={{margin: "1.2rem 0px 0.6em"}} />

      <div style={{display: "flex"}}>
        <Button onClick={handleReplace}>Replace</Button>

        <Button onClick={handleCrop}>
          {croppingActive ? "Done" : `Crop`}
          {!croppingActive ? <WrapSvg>{Crop}</WrapSvg> : undefined}
        </Button>
      </div>

      <SplitLine style={{margin: "0.6rem 0px 1.2em"}} />
      <div style={{paddingBottom: "2em"}}>
        <EditOpacity value={styles?.opacity} />
      </div>

      <div style={{paddingBottom: !activeField?.frame ? "2em" : 0}}>
        <EditImageBlur
          onChange={updateBlur}
          blur={blur}
          stableValue={stableStyles?.blur}
        />
      </div>

      {!activeField?.frame && Number.isInteger(borderRadius) ? (
        <div style={{paddingBottom: "2em"}}>
          <RangeSlider
            title="Rounded"
            value={borderRadius}
            min={0}
            max={100}
            step={1}
            stableValue={stableStyles?.borderRadius}
            onItemChange={updateBorderRadius}
            fieldType="borderRadius"
          />
        </div>
      ) : undefined}

      <div>
        <FlipImg scaleX={scaleX} scaleY={scaleY} activeField={activeField} />
      </div>
      <SplitLine />

      <AdjustImage styles={styles} stableStyles={stableStyles} />
      
      {/* <ImageBorder />
      <SplitLine /> */}

      {/* {activeField.fileType === "png" ? (
        <>
          <SplitLine />
          <div>
            <Switch
              title="Cutout Png"
              value={activeField.styles?.cutoutImg}
              onItemChange={toggleCutoutStatus}
              checked={activeField.styles?.cutoutImg}
            />
          </div>
        </>
      ) : undefined} */}

      {/* {!activeField.styles?.cutoutImg ? ( */}
        <>
          <SplitLine />
          <Overlay
            enableOverlay={styles?.enableOverlay}
            activeField={activeField?.key}
            color={overlayColor}
            degree={overlayDegree}
            type={overlayType}
            stableStyles={stableStyles}
          /> 
        </>
      {/* ) : undefined} */}

      <SplitLine />
      <ImageShadow fileType={activeField?.fileType} styles={styles} />

      <SplitLine />
      <div style={{paddingBottom: "2em"}}>
        <AddLink />
      </div>
    </div>
  );
};

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.deskSecondaryBg2};
  margin: 10px;
  border-radius: 4px;
  flex: 1;
  width: auto;
  height: 18px;
  padding: 6px;
  box-shadow: ${({theme}) => theme.boxShadow1};
  cursor: pointer;
`;

const WrapSvg = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  fill: ${({theme}) => theme.svgIconColor};
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveFieldProps(designTemplate),
    stableStyles: selectCachedFieldStyles(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    croppingActive: selectCropImage(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  cropImage,
  updateReplaceImgStatus,
})(EditImage);
