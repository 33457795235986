import {legacy_createStore as createStore, applyMiddleware, compose, combineReducers} from "redux";
import {thunk} from "redux-thunk";
import {batchedSubscribe} from "redux-batched-subscribe";
import designTemplate from "../packages/oat-design-template/store/reducers/designTemplate";
import userDashboard from "../packages/oat-user-dashboard/store/reducers/userDashboard";
import adminPanel from "../packages/oat-admin-panel/store/reducers/adminPanel";
import authUser from "./user/authUser";
import adminMedia from "../packages/oat-admin-panel/store/reducers/adminMedia.reducer";

const rootReducer = combineReducers({
  designTemplate,
  userDashboard,
  adminPanel,
  adminMedia,
  authUser,
});

const enhancer = compose(
  applyMiddleware(thunk),
  batchedSubscribe((notify) => {
    notify();
  })
);

const store = createStore(rootReducer, enhancer);

export {store};
