import React, {useState} from "react";
import styled from "styled-components";
import {red1} from "../../../../oat-color-variables";
import {LeftArrow} from "../../../../oat-svg-icons";
import {WrapSvg} from "./AddMember";

const MemberDetails = ({member, openModal, onCancel}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = () => {
    if (confirmDelete) {
      openModal("member-list");
    }
  };

  const toggleConfirmStatus = () => {
    setConfirmDelete(!confirmDelete);
  };

  return (
    <Layout>
      <div style={{marginBottom: 14}}>
        <WrapSvg onClick={onCancel}>
          <div style={{transform: `translate(-1px, 0)`}}>{LeftArrow}</div>
        </WrapSvg>
      </div>
      <UserInfo>
        <WrapField>
          <Field>Name</Field>
          <Field>{member.name}</Field>
        </WrapField>
        <WrapField>
          <Field>Email</Field>
          <Field>{member.email}</Field>
        </WrapField>
        <WrapField>
          <Field>Added Date</Field>
          <Field>20 / 3 / 2022</Field>
        </WrapField>
      </UserInfo>
      <DeleteSection>
        <ConfirmDelete>
          <input
            type="checkbox"
            id="confirm-delete-member"
            name="confirm-delete-member"
            value={confirmDelete}
            onChange={toggleConfirmStatus}
          />
          <label for="confirm-delete-member">Tick to proceed for removal</label>
        </ConfirmDelete>
        <WrapDelete>
          <DeleteBtn onClick={handleDelete}>Remove User</DeleteBtn>
        </WrapDelete>
      </DeleteSection>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100%;
  padding: 12px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  box-sizing: border-box;
`;

const WrapField = styled.div`
  display: flex;
  font-size: 1rem;
  padding: 8px 0;
`;

const Field = styled.div`
  flex: 1;
`;

const DeleteSection = styled.div`
  font-size: 0.95rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
`;

const ConfirmDelete = styled.div``;

const WrapDelete = styled.div`
  margin-top: 12px;
`;

const DeleteBtn = styled.button`
  background: ${red1};
  border: none;
  color: #fff;
  padding: 7px 32px;
  border-radius: 8px;
  font-weight: 700;
  cursor: pointer;
`;

export default MemberDetails;
