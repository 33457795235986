import React, { useState } from "react";
import styled from "styled-components";
import ThemeList from "./ThemeList";
import TemplateImages from "./TemplateImages";
import {Link} from "react-router-dom";
import AdDeleteTemp from "./AdDeleteTemp";
import {Button, Modal, Spinner} from "../../../../oat-ui";
import TempDesignType from "./TempDesignType";

const TemplateInfo = ({template, onSelectTheme, editing, updateEditStatus}) => {
  const [openModal, setOpenModal] = useState({
    status: false,
    type: null
  });

  const onOpenModal = () => {
    setOpenModal({
      status: true,
      type: "add-design-type"
    })
  };

  const onCloseModal = () => {
    setOpenModal({
      status: false,
      type: null
    })
  };

  const [processing, setProcessing] = useState(false);
  const onSetRequireUpgrade = async () => {
    setProcessing(true)
    await fetch(
      `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/template/${template.tempID}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          attributeName: 'requiresUpgrade',
          attributeValue: !template?.requiresUpgrade,
        }),
      }
    )
    .then((res) => res.json())
    .then((data) => {
      setProcessing(false);
      return window.location.reload();
    })
    .catch((e) => {});
  }

  return (
    <Wrapper>
      <EditSection>
        <EditTemp
          to={`/admin/designs/edit/${template.category}/${template.tempID}`}
        >
          Edit Template
        </EditTemp>
        {/* <div onClick={updateEditStatus}>
          {!editing ? (
            "Edit Template"
          ) : (
            <WrapSaveBtn>
              <Button>Save</Button>
            </WrapSaveBtn>
          )}
        </div> */}
      </EditSection>
      <Info>
        <Item>
          <p>ID :</p>
          <p>{template.tempID}</p>
        </Item>

        <Item>
          <p>Item Type : </p>
          <p> {template.category}</p>
          {/* <p> {template.type.display}</p> */}
        </Item>

        <Item>
          <p>Sizes : </p>
          <p>
            {template.size.w} x {template.size.h}
          </p>
        </Item>

        <Item>
          <p>Colors & ID : </p>
          <MultiLine>
            {template.themes &&
              template.themes.map((theme, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    margin: "0px 5px 5px 0",
                    alignItems: "center",
                  }}
                >
                  <ColorSet style={{background: theme.color}} />
                  <span style={{display: "flex"}}>{theme.color}</span>
                </div>
              ))}
          </MultiLine>
        </Item>

        <Item>
          <p>Tag :</p>
          <MultiLine style={{marginBottom: 14}}>
            <div style={{display: "flex"}}>
              {template.tags &&
                template.tags.map((tag, i) => <Tag key={i}>#{tag}</Tag>)}
            </div>
            {/* <AddTag onClick={toggleAddTagModal}>Edit</AddTag> */}
          </MultiLine>
        </Item>

        <Item>
          <p>Design Type : </p>{" "}
          <div style={{flex: 1}}>
            <p style={{flex: 0, marginRight: 48}}>{template?.designType}</p>
            <p style={{
              width: 120, 
              height: 28,
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              textDecoration: 'underline'
            }} 
              onClick={onOpenModal}>Edit
            </p>
          </div>
        </Item>
        
        <Item>
          <p>Requires Upgrade : </p>{" "}
          <div style={{flex: 1}}>
            {/* <p style={{flex: 0, marginRight: 48}}>{template?.requiresUpgrade}</p> */}
            <p 
              style={{
                width: 150, 
                height: 28,
                cursor: 'pointer',
                background: 'none',
                border: 'none',
              }} 
              onClick={onSetRequireUpgrade}>
                {template?.requiresUpgrade ? "True" : "False"} (Click to set {template?.requiresUpgrade ? "free" :  "pro" })
            </p>
          </div>
        </Item>

        <Item>
          <p>Created At : </p>{" "}
          <p>{new Date(template.createdAt).toLocaleDateString("en-US")}</p>
        </Item>

        <Item>
          <p>Updated At : </p>{" "}
          <p>{new Date(template.updatedAt).toLocaleDateString("en-US")}</p>
        </Item>
      </Info>

      <ThemeList item={template} onSelectTheme={onSelectTheme} />

      <DeleteBtnSection>
        <WrapButton>
          <AdDeleteTemp template={template} />
        </WrapButton>
      </DeleteBtnSection>

      <TemplateImages images={template.images} />

      {openModal.status ? (
        <Modal width={360} height={'auto'} close={onCloseModal}>
          <TempDesignType templateCategory={template?.category} designType={template?.designType} close={onCloseModal} />
        </Modal>
      ) : undefined}

      {processing ? (
        <Modal width={360} height={'auto'} close={onCloseModal}>
          <div style={{ display: 'flex', alignItems: 'center', height: 200, width: '100%'}}>
            <Spinner />
          </div>
        </Modal>
      ) : undefined }
    </Wrapper>
  );
};

const EditSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  div {
    cursor: pointer;
    font-size: 0.9rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  width: 100%;
`;

const Item = styled.div`
  padding: 0px 8px 16px;
  color: #232323;
  font-size: 0.85rem;
  display: flex;
  width: 100%;
  p {
    flex: 1;
  }
`;

const MultiLine = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  box-sizing: border-box;
`;

const ColorSet = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 1px #afa8a8;
`;

const Tag = styled.div`
  padding: 6px 10px 6px 0;
  color: #213e1c;
  box-sizing: border-box;
  border-radius: 18px;
  width: auto;
`;

const EditTemp = styled(Link)`
  font-size: 0.95rem;
  text-decoration: none;
`;

const AddTag = styled.div`
  text-align: right;
  cursor: pointer;
`;

const WrapButton = styled.div`
  width: 140px;
  height: 34px;
`;

const DeleteBtnSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 22px 12px 34px;
`;

const WrapSaveBtn = styled.div`
  width: 100px;
  height: 32px;
`;

export default TemplateInfo;
