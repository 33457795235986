import React from 'react';
import styled, {css} from 'styled-components';

const ProfileBio = () => {
    return (
      <Description>
        Passionate about technologies, entrepreneurship, business philosophy, and Mount Everest.
      </Description>
    )
}

export const textOverflowCSS = css`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Description = styled.div`
  font-size: 1rem;
  padding: 16px 0;
  max-height: 99px;
  margin-bottom: 12px;
  line-height: 28px;
  color: #232323;
  ${textOverflowCSS};
  @media only screen and (max-width: 600px) {
    padding: 0px 0 10px 22px;
    font-size: 0.9rem;
  }
`;

export default ProfileBio;