import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

const RequestLogin = () => {
  const navigate = useNavigate();
  const redirectLogin = () => {
    return navigate("/login");
  };

  return (
    <Layout>
      <div>
        Please <span onClick={redirectLogin}>Login</span> to Continue.
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.7rem;
  height: 90vh;
  color: #232323;
  span {
    color: #4f4fe2;
    cursor: pointer;
  }
`;

export default RequestLogin;
