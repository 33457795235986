export const lightTheme = {
  control: {
    backgroundColor: "transparent",
    fontWeight: "normal",
  },
  "&multiLine": {
    control: {
      fontFamily: "inherit",
      minHeight: 90,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      maxHeight: 90,
    },
    input: {
      height: 90,
      padding: 9,
      borderRadius: 6,
      resize: "none",
      boxSizing: "border-box",
      color: "inherit",
      fontFamily: "inherit",
      overflowY: "scroll",
      border: "1px solid #d2d2d2",
      scrollbarWidth: "thin", // Standard scrollbar width for Firefox
      scrollbarColor: "transparent transparent", // Standard scrollbar color for Firefox

      // Webkit-specific styles (for Chrome and Safari)
      WebkitOverflowScrolling: "touch",
      "&::-webkit-scrollbar": {
        width: "0.1rem",
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: "6px",
      },
      "&:hover::-webkit-scrollbar-thumb": {
        background: "#c2c6e86b",
      },
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    width: 160,
    top: -175,
    left: 10,
    borderRadius: 6,
    height: 150,
    overflowY: "scroll",
    boxShadow: "0px 0px 2px 0px #939494",
    list: {
      backgroundColor: "#fff",
      fontSize: 14,
      margin: 10,
    },
    item: {
      padding: "5px 10px",
      boxSizing: "border-box",
      color: "#232323",
      borderRadius: 6,
      fontWeight: "normal",
      marginBottom: 6,
      "&focused": {
        backgroundColor: "#d3deff",
      },
    },
  },
};

export const darkTheme = {
  control: {
    backgroundColor: "transparent",
    fontWeight: "normal",
  },
  "&multiLine": {
    control: {
      fontFamily: "inherit",
      minHeight: 90,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      maxHeight: 90,
    },
    input: {
      height: 90,
      padding: 9,
      borderRadius: 6,
      resize: "none",
      boxSizing: "border-box",
      color: "inherit",
      fontFamily: "inherit",
      overflowY: "scroll",
      border: "1px solid #434255",
      scrollbarWidth: "thin", // Standard scrollbar width for Firefox
      scrollbarColor: "transparent transparent", // Standard scrollbar color for Firefox
      // Webkit-specific styles (for Chrome and Safari)
      WebkitOverflowScrolling: "touch",
      "&::-webkit-scrollbar": {
        width: "0.1rem",
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: "6px",
      },
      "&:hover::-webkit-scrollbar-thumb": {
        background: "#c2c6e86b",
      },
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,
    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    width: 160,
    top: -175,
    left: 10,
    borderRadius: 6,
    height: 150,
    overflowY: "scroll",
    boxShadow: "0px 0px 2px 0px #939494",
    list: {
      backgroundColor: "#fff",
      fontSize: 14,
      margin: 10,
    },
    item: {
      padding: "5px 10px",
      boxSizing: "border-box",
      color: "#232323",
      borderRadius: 6,
      fontWeight: "normal",
      marginBottom: 6,
      "&focused": {
        backgroundColor: "#d3deff",
      },
    },
  },
};
