import React, {useState} from "react";
import {ColorSet, SelectedColor, TooltipLayout} from "../reuseTooltipStyles";
import {Content, OnHoverContent} from "../Tooltip";
import {
  updateSingleColorChart,
  onViewChartInfo,
} from "../../../../../../store/actions/fields/chart.action";
import {connect} from "react-redux";
import TooltipColorPickerHoc from "../tooltipHocs/TooltipColorPickerHoc";
import {
  toggleEditPanel,
  toggleTooltipPopper,
} from "../../../../../../store/actions/layout.action";
import TooltipItemsHoc from "../tooltipHocs/TooltipItemsHoc";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import TooltipSelectionHoc from "../tooltipHocs/TooltipSelectionHoc";
import {updateMultipleFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {fontSizes} from "../../../../editingPanel/panelTypes/desktop/common/SelectFontSize";
import {chartDefaultFontSize} from "../../../fields/charts/chartDefaultFontSize";

const DisplayItem = ({color, index, onSelectColor}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onSelectColor(index);
  };
  return (
    <Content
      style={{marginRight: 0, padding: "0 4px"}}
      onMouseDown={handleClick}
    >
      <SelectedColor color={color} />
    </Content>
  );
};

const ChartTooltip = ({
  active,
  colorSet,
  noOfColorSet,
  popperOpen,
  updateSingleColorChart,
  toggleTooltipPopper,
  storeFieldUndoRedo,
  updateFieldCollaboration,
  onViewChartInfo,
  toggleEditPanel,
  fontSize,
  updateMultipleFieldStyles,
}) => {
  const [selectedColorIndex, setSelectedColorIndex] = useState();

  const openColorPicker = (colorIndex) => {
    toggleTooltipPopper("color-picker");
    setSelectedColorIndex(colorIndex);
  };

  const handleColorChange = (color) => {
    updateSingleColorChart({active, color, index: selectedColorIndex});
  };

  const closePopper = () => {
    toggleTooltipPopper();
  };

  const handleDebounceValue = (value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const handleChartInfo = () => {
    toggleEditPanel("designs", true);
    onViewChartInfo();
  };

  const openFontSizeList = () => {
    toggleTooltipPopper("font-size");
  };

  const changeFontSize = ({value}) => {
    const payload = {
      fontSize: Number(value),
      legendFontSize: Number(value / chartDefaultFontSize),
      measurementFontSize: Number(value),
      categoryFontSize: Number(value),
    };
    updateMultipleFieldStyles(active, payload);
    updateFieldCollaboration();
  };

  return (
    <TooltipLayout>
      <TooltipItemsHoc style={{display: "flex", marginRight: 4}}>
        <Content onMouseDown={handleChartInfo}>
          <p>Edit</p>
          <OnHoverContent style={{width: 60, left: -20}}>
            Chart Info
          </OnHoverContent>
        </Content>

        <Content onMouseDown={openFontSizeList}>
          <p>{Number(fontSize.toFixed(1))}</p>
          <OnHoverContent style={{width: 60, left: 15}}>
            Font Size
          </OnHoverContent>
        </Content>

        <ColorSet>
          {[...Array(noOfColorSet)].map((x, i) => {
            return (
              <DisplayItem
                key={i}
                index={i}
                color={colorSet[i]}
                onSelectColor={openColorPicker}
              />
            );
          })}
        </ColorSet>
      </TooltipItemsHoc>

      {popperOpen.status && popperOpen.type === "color-picker" ? (
        <TooltipColorPickerHoc
          onClose={closePopper}
          activeColor={colorSet[selectedColorIndex]}
          onUpdate={handleColorChange}
          onDebounce={handleDebounceValue}
        />
      ) : undefined}

      {popperOpen.status && popperOpen.type === "font-size" ? (
        <TooltipSelectionHoc
          activeItem={fontSize}
          options={fontSizes}
          onChange={changeFontSize}
          offset={{x: 40, y: 45}}
          width={65}
          onClose={closePopper}
        />
      ) : undefined}
    </TooltipLayout>
  );
};

export default connect(null, {
  updateSingleColorChart,
  storeFieldUndoRedo,
  toggleTooltipPopper,
  updateFieldCollaboration,
  onViewChartInfo,
  toggleEditPanel,
  updateMultipleFieldStyles,
})(ChartTooltip);
