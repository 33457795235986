import {isFirefox} from "react-device-detect";
import {OpenSans} from "../../../../ui/fonts/googleFontLinks";
import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import {getHighestZIndex} from "./utils";
import { themeBuilder } from "../../data/themeBuilder.data";

export function createText() {
  return function (state, action) {
    const {template} = state;
    const {slideID, key, content, size, subtype, styles, selectFieldOnCreate} =
      action;
    let highestZIndex = getHighestZIndex(template, slideID);
    const currentSlide = template[slideID];
    Object.entries(currentSlide.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });
    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });
    const adjustedPos = (16 * state.size.w) / 100;
    let activeTheme = state.themes.find(
      (theme) => theme.id === state.activeTheme
    );
    if(typeof activeTheme === 'undefined') {
      activeTheme = themeBuilder
    }
    const addNewField = {
      key,
      pos: {x: adjustedPos, y: adjustedPos},
      content,
      type: "text",
      subtype,
      deleted: false,
      size,
      resizer: "corners-side",
      effects: {},
      ["styles"]: {
        whiteSpace: "pre",
        ...styles,
        color2: "",
        enableGradient: false,
        fontStyle: "normal",
        rotate: 0,
        textAlign: "left",
        opacity: 1,
        gradientAngle: 0,
        textDecoration: "none",
        lock: false,
        animateOnSlideDisplay: null, // step by step activated by arrow
        animationType: "none",
        animationSpeed: 0.5,
        animationDelay: 0,
        animationOrder: 0, // for step by step animation
        enableExitAnimation: false,
        zIndex: highestZIndex + 1,
        curveDegree: 0,
        bgType: "",
        bg1: activeTheme[subtype]?.background1,
        bg2: activeTheme[subtype]?.background2,
        bgDirection: activeTheme[subtype]?.bgDirection,
        shadowType: "",
        shadowX: 0,
        shadowY: 0,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowColor: activeTheme[subtype]?.shadowColor,
        borderRadius: 0,
        disableApplyingTheme: false
      },
      font: {
        custom: activeTheme[subtype].custom ? true : false,
        fontFamily: activeTheme[subtype].fontFamily
          ? activeTheme[subtype].fontFamily
          : "Open Sans",
        src: activeTheme[subtype].src ? activeTheme[subtype].src : OpenSans,
        format: activeTheme[subtype].format
          ? activeTheme[subtype].format
          : null,
        fontType: styles.fontWeight == "700" ? "Bold" : "Regular",
      },
    };
    const newState = [...state.template];
    newState[slideID].fields = {
      ...newState[slideID].fields,
      [key]: addNewField,
    };
  
    return {
      ...state,
      template: newState,
      fieldToEmitFromSocket: {
        type: "create",
        field: {
          ...addNewField,
          key,
        },
      },
      activeField: key,
      selectedFieldType: "text",
      cachedFieldStyles: newState[slideID].fields[key].styles,
      replaceImage: false,
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
    };
  };
}

export function applyTextEffects() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...getItem,
      effects: action.value,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function updateTextEffects() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    if (action.doubleShadow) {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        effects: {
          ...getItem.effects,
          shadow: getItem.effects.shadow.map((item, index) => {
            if (index === action.arrKey) {
              return {
                ...item,
                [action.key]: action.value,
              };
            }
            return {
              ...item,
            };
          }),
        },
      };
    } else {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        effects: {
          ...getItem.effects,
          shadow: getItem.effects.shadow.map((item) => {
            if (item.v < 0 && action.key === "v") {
              return {
                ...item,
                [action.key]: -action.value,
              };
            } else if (item.h < 0 && action.key === "h") {
              return {
                ...item,
                [action.key]: -action.value,
              };
            }
            return {
              ...item,
              [action.key]: action.value,
            };
          }),
        },
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
}

export function updateTextContent() {
  return function (state, action) {
    const {slideIndex, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideIndex].fields[fieldID];
    // matches any character (including whitespace and newlines) zero or more times.
    const pTagRegExp = /<p>[\s\S]*?<\/p>/;
    const disallowedTagsRegExp = /<(ul|ol|button)[^>]*>[\s\S]*?<\/\1>/;

    const fieldDiv = document.createElement('div');
    
    // Wrap content with associated tag, if not within allowed tags
    // when user press delete keyword multiple times, p ol li tags get deleted as well
    // so add content back into tags
    const allowedTags = ['p', 'ul', 'ol'];
    const isContentWithinAllowedTags = 
      allowedTags.some(tag => action.value.startsWith(`<${tag}>`) && 
      action.value.endsWith(`</${tag}>`));
    let content;
    if (!isContentWithinAllowedTags) {
      if ((getItem.subtype === 'listContent' || getItem.subtype === 'listContentWithBackground')) {
        content = `<ol>${action.value}</ol>`;
      } else if (getItem.subtype ===  'bulletContent') {
        content = `<ul>${action.value}</ul>`;
      } else {
        content = `<p>${action.value}</p>`;
      }
    } else {
      content = action.value;
    }

    fieldDiv.innerHTML = content;

    // action.value === "" ? `<p>${action.value}</p>` : `${action.value}`;

    // Find and remove <span> elements with no attributes
    // const spans = fieldDiv.querySelectorAll('span');
    // spans.forEach((span) => {
    //   if (span.attributes.length === 0) {
    //     while (span.firstChild) {
    //       span.parentNode.insertBefore(span.firstChild, span);
    //     }
    //     span.parentNode.removeChild(span);
    //   }
    // });
    const updatedString = fieldDiv.innerHTML;

    if ((getItem.subtype === 'listContent' || 
        getItem.subtype ===  'bulletContent' ||  
        getItem.subtype === 'listContentWithBackground')) {
      let transformedHTML = updatedString.replace(/<li>([\s\S]*?)<\/li>/g, (match, content) => {
        // Check if a <span> already exists within the content
        // if span is missing add a span for firefox
        if (/<span>/.test(content)) {
          return `<li>${content}</li>`;
        } else if (isFirefox) {
          const updatedContent = content.replace(/<br>/g, '</span><br><span>');
          return `<li><span>${updatedContent}</span></li>`;
        } else {
          return `<li>${content}</li>`;
        }
      });

      // For removing br tag
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = transformedHTML;
      // Check if the last child is a <br> tag
      const liElements = tempDiv.querySelectorAll('li');
      liElements.forEach(liElement => {
        // Check if the last child is a <br> tag
        const lastChild = liElement.lastChild;
        if (lastChild && lastChild.tagName === 'BR') {
            // Remove the <br> element
          liElement.removeChild(lastChild);
        }
      });
      transformedHTML = tempDiv.innerHTML;

      newState[slideIndex].fields[fieldID] = {
        ...getItem,
        content: transformedHTML //action.value,
      };
    } else {
      const tempDiv = document.createElement("div");
        tempDiv.innerHTML = updatedString;

      if (!pTagRegExp.test(updatedString)) {
        const pTags = tempDiv.querySelectorAll('p');
        if (pTags.length > 0) {
          pTags.forEach(pTag => {
            if (pTag.innerHTML.trim() !== '<br>') {
              const contentWithoutQuotes = pTag.textContent.replace(/^"(.*)"$/, '$1');
              pTag.textContent = contentWithoutQuotes;
            }
          });
        } else {
          const pTag = document.createElement('p');
          pTag.textContent = tempDiv.textContent;
          tempDiv.innerHTML = ''; 
          tempDiv.appendChild(pTag);
        }

        newState[slideIndex].fields[fieldID] = {
          ...getItem,
          content: tempDiv // tempDiv.innerHTML 
        };
      } else {
        // Remove "" from the content

        // inline text color not applying here
        const pTags = tempDiv.querySelectorAll('p');
        pTags.forEach((pTag, index) => {
          // if (pTag.innerHTML.trim() !== '<br>') {
          //   const contentWithoutQuotes = pTag.textContent.replace(/^"(.*)"$/, '$1');
          //   pTag.textContent = contentWithoutQuotes;
          // }
          const lastChildNode = pTag.lastChild;
          // Check if the last child node is a text node
          if (lastChildNode && lastChildNode.nodeType === 3) {
            // Trim trailing spaces only for the last text node
            lastChildNode.nodeValue = lastChildNode.nodeValue.trimEnd();
           }
           // Check if the only <p> node's child is a <br> tag
          if (lastChildNode && lastChildNode.tagName === 'BR' && pTags.length === 1) {
            // Remove the trailing <br> tag
            pTag.removeChild(lastChildNode);
          }
          // Remove last br of p tag - if multiple p tags exist
          else if (lastChildNode && lastChildNode.tagName === 'BR' && pTags.length - 1 === index) {
            pTag.removeChild(lastChildNode);
          }
        });

        const emptyPTags = tempDiv.querySelectorAll('p:empty');
        emptyPTags.forEach(function(emptyPTag) {
          emptyPTag.parentNode.removeChild(emptyPTag);
        });

        newState[slideIndex].fields[fieldID] = {
          ...getItem,
          content: tempDiv.innerHTML  
        };
        
        // else {
        //   newState[slideIndex].fields[fieldID] = {
        //     ...getItem,
        //     content: updatedString 
        //   };
        // }
      }
    } 
    // if( (getItem.subtype !== 'listContent' || 
    //   getItem.subtype !==  'bulletContent' ||  
    //   getItem.subtype !== 'listContentWithBackground')){
    //   if (!pTagRegExp.test(action.value)) {
    //       const tempDiv = document.createElement("div");
    //       tempDiv.innerHTML = action.value;

    //       // Check if it's wrapped in a <p> tag
    //       if (!tempDiv.querySelector('p')) {
    //         const pTag = document.createElement('p');
    //         pTag.textContent = tempDiv.textContent;
    //         tempDiv.innerHTML = ''; 
    //         tempDiv.appendChild(pTag);
    //       }

    //       newState[slideIndex].fields[fieldID] = {
    //         ...getItem,
    //         content: tempDiv.innerHTML 
    //       };
    //   } else {
    //     newState[slideIndex].fields[fieldID] = {
    //       ...getItem,
    //       content: action.value 
    //     };
    //   }
    // } else {
      
    // }
    return {
      ...state,
      template: newState,
    };
  };
}

export function addTextFamilySource() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      font: action.payload,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function addHighlightedTextColor() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const {color, startingIndex, endingIndex} = action;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];

    const content = getItem.content;
    const highlightedText = content.substring(startingIndex, endingIndex);
    const addSpan = `<span style="color: ${color}">${highlightedText}</span>`;
    const updateValue = (newState[activeSlide].fields[activeField] = {
      ...getItem,
      content: action.payload,
    });
    return {
      ...state,
      template: newState,
    };
  };
}
