import React, {useEffect, useState} from "react";
import {selectTempRatioDiff} from "../../../../store/selectors/layout/layout.selector";
import {connect} from "react-redux";
import TableRows from "../fields/table/cells/TableRows";
import { Table } from "../fields/table/DisplayTable";

const ViewOnlyTable = ({field, ratioDiff}) => {
  const {styles, content} = field || {};
  const {opacity, fontFamily, zIndex} = styles || {};

  const [lastColumnWidth, setLastColumnWidth] = useState(0);
  useEffect(() => {
    let accumulateWidth = 0;
    content &&
      content[0].columns &&
      content[0].columns.map((column, i) => {
        const columnWidth = content[0].columns[i].width;
        accumulateWidth = accumulateWidth + columnWidth;

        if (i === content[0].columns.length - 1) {
          const extraSpace = field?.size?.w - accumulateWidth;
          setLastColumnWidth(columnWidth + extraSpace);
        }
      });
  }, [content, field?.size]);

  return (
    <div
      style={{
        position: "relative",
        fontSize: 20,
        fontFamily,
        opacity,
        zIndex,
      }}
    >
      <Table roundedBorder={styles.roundedBorder} ratioDiff={ratioDiff}>
        <tbody>
          {content &&
            content.map((item, i) => (
              <TableRows
                key={i}
                rowIndex={i}
                item={item}
                styles={styles}
                activeCell={{row: null, column: null}}
                viewOnly={true}
                lastColumnWidth={lastColumnWidth}
                ratioDiff={ratioDiff}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = ({designTemplate}) => {
  return {
    ratioDiff: selectTempRatioDiff(designTemplate),
  };
};

export default connect(mapStateToProps, null)(ViewOnlyTable);
