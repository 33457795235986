import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {red1} from "../../../../../../oat-color-variables";
import {LeftArrow, Plus} from "../../../../../../oat-svg-icons";
import {addGroupSection} from "../../../../../store/actions/template/groupSection.action";
import {selectTemplate} from "../../../../../store/selectors/template/template.selector";
import DesignListOptions from "../../DesignListOptions";

const AddSlideContents = ({
  slides,
  addGroupSection,
  displayForm,
  toggleDisplayForm,
}) => {
  const [section, setSection] = useState({
    title: "",
    startingSlideNo: null,
  });
  const [error, setError] = useState(false);

  const selectItemLink = (item) => {
    addGroupSection({
      title: section.title,
      startingSlideNo: item.id,
    });
    toggleDisplayForm(true);
    setSection({
      title: "",
      startingSlideNo: null,
    });
    if (error) {
      setError(false);
    }
  };

  const onChangeTitle = (e) => {
    setSection({
      ...section,
      title: e.target.value,
    });
  };

  const onSelectSlide = () => {
    if (section.title !== "") {
      toggleDisplayForm(false);
    } else {
      setError(true);
    }
  };

  const backToForm = () => {
    toggleDisplayForm(true);
  };

  return displayForm ? (
    <>
      <Wrapper>
        <WrapForm>
          <Field>
            <Name>Content Title</Name>
            <FieldInput>
              <Input
                value={section.title}
                onChange={onChangeTitle}
                error={error}
              />
            </FieldInput>
          </Field>
          <Field>
            <Name>Link Slide</Name>
            <FieldInput>
              <PlusBottom onClick={onSelectSlide}>
                <WrapPlusSvg>{Plus} </WrapPlusSvg>
                Select
              </PlusBottom>
            </FieldInput>
          </Field>
        </WrapForm>
      </Wrapper>
    </>
  ) : (
    <>
      <TopSection>
        <WrapBackSvg onClick={backToForm}>{LeftArrow}</WrapBackSvg>
      </TopSection>
      <DesignListOptions
        slides={slides}
        onSelectSlide={selectItemLink}
        selected={{
          status: section.startingSlideNo !== null,
          item: section.startingSlideNo,
        }}
        columns={3}
      />
    </>
  );
};

const Wrapper = styled.div`
  color: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({theme}) => theme.deskGap};
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
`;

const WrapForm = styled.div`
  margin: 12px;
`;

const FieldInput = styled.div`
  flex: 2;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  background: transparent;
  color: ${({theme}) => theme.deskSecondaryTxt};
  font-size: 1rem;
  border: 1px solid ${({theme, error}) => (!error ? theme.inputBorder : red1)};
  flex: 1;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px;
  box-sizing: border-box;
  font-weight: 700;
`;

const Name = styled.div`
  padding-right: 22px;
  flex: 1;
`;

const PlusBottom = styled.div`
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.inputBorder};
  background: #ffffff24;
  cursor: pointer;
  display: flex;
  padding: 4px 15px 4px 10px;
  width: 94px;
`;

const WrapPlusSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: #fff;
`;

const WrapBackSvg = styled.div`
  width: 14px;
  height: 14px;
  fill: #fff;
  cursor: pointer;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 55px;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    slides: selectTemplate(designTemplate),
  };
};

export default connect(mapStateToProps, {addGroupSection})(AddSlideContents);
