import React, {useState, useEffect, useCallback, useRef} from 'react';
import { ArrowBack } from '../../../../../../../oat-svg-icons';
import { GoBack } from '../../reusableStyles/reusableStyles';
import { Spinner } from '../../../../../../../oat-ui';
import DisplayPurchasedPack from './DisplayPurchasedPack';
import { WrapImagePanel } from '../styles/userImageStyles';
import DisplayPackItem from '../oatAllPublic/DisplayPackItem';
import { selectUserInfo } from '../../../../../../../../redux/user/authUser';
import { connect } from 'react-redux';

const PurchasedItems = ({ 
    replaceImg, 
    onUploadImgByLink,
    onReplaceImgByLink,
    user
}) => {
    const panelRef = useRef();
    const [covers, setCovers] = useState([]);
    const [packItems, setPackItems] = useState([]);
    const [displaySection, setDisplaySection] = useState("pack-list");
    const [fetching, setFetching] = useState(false);
    const [infiniteLoading, setInfiniteLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [paginationToken, setPaginationToken] = useState(null);
    const [error, setError] = useState("");

    useEffect(() => {
        if (error) {
            setError(false);
        }
        if (paginationToken === null && covers.length === 0) {
            setFetching(true)
            fetch(`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/purchased-items`)
                .then((res) => res.json())
                .then((data) => {
                    setFetching(false);
                    setCovers(data.items);
                    setInitialLoading(false);
                    setPaginationToken(data.paginationToken)
                })
                .catch((e) => {
                    setFetching(false);
                })
        }
    }, [user.username]);

    const handleGoBackToOPackages = () => {
        setDisplaySection("pack-list");
    };

    const onSelectIconCollection = (packID, category) => {
        setDisplaySection("items-inside-pack");
        setFetching(true);
        fetch(`https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/icons/${category}/${packID}`)
            .then((res)=> res.json())
            .then((data)=> {
                setFetching(false)
                setPackItems(data)
            })
            .catch ((e) => {
                setFetching(false);
            })
    }

    const handleCreate = (item) => {
        const payload = {
            naturalWidth: item.imgSize?.w,
            naturalHeight: item.imgSize?.h,
            src: item.image,
            imageBy: item.imageBy,
            imgType: item.imgType,
            imgSize: item?.imgSize,
            colorSet: item?.colorSet
        };
          
        if (!replaceImg) {
            onUploadImgByLink(payload);
        } else {
            onReplaceImgByLink(payload);
        }
    };

    const observer = useRef();
    const lastItemRef = useCallback((node) => {
      if (initialLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && paginationToken) {
          setInfiniteLoading(true);
          fetch(`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/purchased-items?paginationToken=${paginationToken}`)
          .then((response) => response.json())
          .then((data) => {
            if (data?.items?.length > 0) {
              setCovers([...covers, ...data.items]);
              setPaginationToken(data.response.paginationToken)
              setInfiniteLoading(false);
            }
          })
          .catch((error) => {
            setError(true);
          });
        }
      });
      if (node) observer.current.observe(node);
    }, [initialLoading, paginationToken]);

    return (
        <div ref={panelRef}>
            {displaySection === "items-inside-pack" ? (
                <GoBack onClick={handleGoBackToOPackages}>
                    {ArrowBack}
                </GoBack>
            ) : undefined}

            {!fetching ?
                <WrapImagePanel>
                    {displaySection === "pack-list" && covers && covers.map((item, i) => {
                        return (
                            <DisplayPurchasedPack key={i} item={item} onSelect={onSelectIconCollection} />
                        );
                    })}

                    {displaySection === "items-inside-pack" && packItems && packItems.map((item, i) => {
                        return (
                            <DisplayPackItem key={i} item={item} onCreate={handleCreate} />
                        );
                    })}
                </WrapImagePanel>
            : (
                <div style={{width: "100%", marginTop: "20%"}}>
                    <Spinner />
                </div>
            )}

            {displaySection === "pack-list" && covers?.length > 0 ?
                <>
                    <div style={{height: 300}} />
                    <div ref={lastItemRef} /> 
                </>
            : undefined}

            {infiniteLoading ? 
                <div style={{width: "100%", margin: "50px 0"}}>
                    <Spinner />
                </div> 
            : undefined}
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
      user: selectUserInfo(state.authUser),
    };
};

export default connect(mapStateToProps, null)(PurchasedItems);