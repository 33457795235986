import React, {useState} from "react";
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {Button, ButtonSpinner, Spinner, TextField} from "../../oat-ui";
import {primaryPurple, red1} from "../../oat-color-variables";
import {Auth} from "aws-amplify";
import {updateUserAuthStatus} from "../../../redux/user/actions/authUser.action";
import {connect} from "react-redux";
import { getWindowSize } from "../../oat-window-size/getWindowSize";

const UserAuth = ({
  shopName,
  context,
  lang,
  onSubmit,
  formType,
  processing,
  error,
  updateUserAuthStatus,
}) => {
  let navigate = useNavigate();
  const [form, setForm] = useState({
    email: {
      value: "",
      touched: false,
      min: 3,
      error: false,
      errorMsg: null,
    },
    password: {
      value: "",
      touched: false,
      min: 8,
      error: false,
      errorMsg: null,
    },
    name: {
      value: "",
      touched: false,
      min: 2,
      error: false,
      errorMsg: null,
    },
  });
  const [OAuthProcessing, setOAuthProcessing] = useState(false);
  const [notReadyErrMsg, setNotReadyErrMsg] = useState(false);
  const windowSize = getWindowSize();

  const handleInputChange = (e, field) => {
    const {value} = e.target;
    setForm({
      ...form,
      [field]: {
        ...form[field],
        value,
        error: false,
        errorMsg: null,
      },
    });
  };

  const checkFieldEmpty = (field, message) => {
    if (!form[field].touched)
      setForm({
        ...form,
        [field]: {
          ...form[field],
          touched: true,
          error: true,
          errorMsg: message,
        },
      });
  };

  const handleTouched = (field) => {
    if (field === "password" && form.password.value.length < 8) {
      setForm({
        ...form,
        password: {
          ...form.password,
          touched: true,
          error: true,
          errorMsg: "Password should be at least 8 characters",
        },
      });
    } else if (
      field === "email" &&
      (form.email.value.length < 8 ||
        form.email.value.indexOf("@") === -1 ||
        form.email.value.indexOf(".") === -1)
    ) {
      setForm({
        ...form,
        email: {
          ...form.email,
          touched: true,
          error: true,
          errorMsg: "Please enter a valid email address",
        },
      });
    } else if (field === "name" && form.name.value === "") {
      setForm({
        ...form,
        name: {
          ...form.name,
          touched: true,
          error: true,
          errorMsg: "Please enter a username",
        },
      });
    } else {
      setForm({
        ...form,
        [field]: {
          ...form[field],
          touched: true,
          error: false,
          errorMsg: null,
        },
      });
    }
  };

  const handleSubmit = (e) => {
    // if(form.email.value === "theaungkhant@gmail.com" || form.email.value === "ulster5555@gmail.com") {
      if (!processing && !OAuthProcessing) {
        e.preventDefault();
        if (form.email.value === "") {
          checkFieldEmpty("email", "Please enter a valid email address");
          return;
        } else if (form.password.value === "") {
          checkFieldEmpty("password", "Please enter a password");
          return;
        } else if (formType === "register" && form.name.value === "") {
          checkFieldEmpty("name", "Please enter a name");
          return;
        }
        if (
          !form.email.error &&
          !form.password.error &&
          !processing &&
          formType === "login"
        ) {
          onSubmit(form.email, form.password);
        } else if (
          !form.email.error &&
          !form.password.error &&
          !form.name.error &&
          !processing &&
          formType === "register"
        ) {
          onSubmit(form.email, form.password, form.name);
        }
      }
    // } else {
    //   setNotReadyErrMsg(true);
    // } 
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const logInWithGmail = () => {
    // setNotReadyErrMsg(true);
    if (!processing && !OAuthProcessing) {
      setOAuthProcessing(true);
      Auth.federatedSignIn({
        provider: "Google",
      })
        .then(async (userInfo) => {
          const username = await userInfo.attributes["custom:acc-username"];
          await fetch(
            `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/userInfo`
          )
            .then((res) => res.json())
            .then(async (data) => {
              const {userItems, workspaceID} = data;
              setOAuthProcessing(false);
              await updateUserAuthStatus({
                cognito: userInfo,
                dynamo: userItems,
              });
              await navigate(`/dashboard/${workspaceID}/designs/home`); // homeDefault
              return window.location.reload();
            })
            .catch((err) => {
              // setErrorMessage("Something went wrong. Please try again later.");
              setOAuthProcessing(false);
            });
        })
        .catch((err) => {
          if (err.message.includes("User with that email already exists.")) {
            // setErrorMessage("User with that email already exists.");
            setOAuthProcessing(false);
          } else {
            setOAuthProcessing(false);
            // setErrorMessage(err.message);
          }
        });
    }
  };

  const linkToForgotPassword = () => {
    return navigate("/forgot-password");
  };

  return (
    <Layout style={{opacity: OAuthProcessing || processing ? 0.7 : 1}}>
      <WrapBgImage>
        {/* <img
          style={{maxWith: "100%", objectFit: "cover", width: "100%"}}
          src="https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
        /> */}
      </WrapBgImage>

      <Wrapper>
        <Form width={windowSize.width}>
          <Header to="/">{shopName}</Header>
          <LoginTxt>{context}</LoginTxt>
          <Line></Line>
          <WrapInput>
            <TextField
              onChange={handleInputChange}
              onTouched={handleTouched}
              field="email"
              placeholder="Email"
              type="text"
              styles={{
                border: `1px solid ${!form.email.error ? "#c7c7c7" : "red"}`,
              }}
            />
            {form.email.touched && form.email.error ? (
              <Error>{form.email.errorMsg}</Error>
            ) : undefined}
          </WrapInput>
          <WrapInput>
            <TextField
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onTouched={handleTouched}
              field="password"
              placeholder="Password"
              type="password"
              styles={{
                border: `1px solid ${!form.password.error ? "#c7c7c7" : "red"}`,
              }}
            />
            {form.password.touched && form.password.error ? (
              <Error>{form.password.errorMsg}</Error>
            ) : undefined}
          </WrapInput>
          {formType !== "login" ? (
            <WrapInput>
              <TextField
                onChange={handleInputChange}
                onTouched={handleTouched}
                field="name"
                placeholder="Name"
                type="text"
                styles={{
                  textTransform: "capitalize",
                  border: `1px solid ${!form.name.error ? "#c7c7c7" : "red"}`,
                }}
              />
              {form.name.touched && form.name.error ? (
                <Error>{form.name.errorMsg}</Error>
              ) : undefined}
            </WrapInput>
          ) : undefined}

          {error && error.status ? (
            <ErrorFromServer>{error.message}</ErrorFromServer>
          ) : undefined}

          <WrapButton>
            <Button
              onClick={handleSubmit}
              disabled={processing}
              styles={{
                borderRadius: 0,
                fontSize: "1rem",
                background: primaryPurple,
              }}
            >
              {!processing ? (
                formType === "login" ? (
                  "Login"
                ) : (
                  "Sign Up"
                )
              ) : (
                <WrapSpinner>
                  <Spinner color="#fff" />
                </WrapSpinner>
              )}
            </Button>
          </WrapButton>
          <Line style={{marginTop: 15}} />
          <WrapButton>
            <Button
              onClick={logInWithGmail}
              disabled={OAuthProcessing}
              styles={{
                borderRadius: 0,
                fontSize: "1rem",
                background: "#f97d7d",
              }}
            >
              {formType === "login"
                ? "Login with Gmail"
                : "Register with Gmail"}
            </Button>
          </WrapButton>

          {/* Delete later */}
          {notReadyErrMsg ? (<Error style={{ fontSize: '1.05rem', color: '#f65353' }}>
            Sorry, we are not ready for the public yet!
          </Error>) : undefined}

          <BottomSection>
            {formType === "login" ? (
              <>
                <AuthLink to={`/register`}>
                  Don't have an account? Register here.
                </AuthLink>
                <ForgotPassword onClick={linkToForgotPassword}>
                  Forgot Password
                </ForgotPassword>
              </>
            ) : (
              <AuthLink to={`/login`}>
                Already got an account? Login here
              </AuthLink>
            )}
            {formType === "register" ? (
              <Policy>
                By signing up, you agree to our Terms of Service and Privacy
                Policy.
              </Policy>
            ) : undefined}
          </BottomSection>
        </Form>
      </Wrapper>
    </Layout>
  );
};

export const Layout = styled.div`
  display: flex;
  background: #fbfafa;
  width: 100%;
  min-height: ${window.innerHeight}px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding-top: 10%;
  flex: 1;
  height: 38rem;
  @media only screen and (min-width: 600px) {
    padding-top: 5%;
    box-sizing: border-box;
    height: 100%;
    z-index: 10;
  }
`;

export const Form = styled.div`
  width: ${({width}) => width / 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fbfafa;
  border-radius: 16px;
  z-index: 10;
  padding: 2em;
  box-sizing: border-box;
  margin-bottom: 120px;
  @media only screen and (min-width: 601px) {
    width: 490px;
  }
  @media only screen and (min-width: 601px) {
    box-shadow: 0 0.1rem 0.9rem rgba(110, 115, 143, 0.2),
      0 0 0 0.1rem rgba(110, 115, 143, 0.05);
  }
  @media only screen and (max-width: 600px) {
    width: ${({width}) => width - 40}px;
  }
`;

export const Header = styled(Link)`
  padding: 14px 0;
  font-size: 2.3em;
  color: #4c4c4c;
  text-decoration: none;
  font-weight: bold;
  @media only screen and (min-width: 900px) {
    font-size: 2.8em;
  }
`;

export const LoginTxt = styled.h5`
  font-size: 1.3em;
  padding-bottom: 15px;
  color: #4c4c4c;
  @media only screen and (min-width: 900px) {
    font-size: 1.8em;
  }
`;

export const Line = styled.div`
  background: #d6d2d2;
  height: 1px;
  width: 100%;
  margin-bottom: 15px;
`;

export const WrapInput = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  margin: 10px 0;
`;

const WrapSpinner = styled.div`
  transform: scale(0.7);
`;

const BottomSection = styled.div`
  margin-top: 95px;
  width: 100%;
  text-align: center;
`;

export const WrapButton = styled.div`
  display: flex;
  margin: 10px;
  height: 42px;
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
`;

const AuthLink = styled(Link)`
  color: #3f3d40;
  font-size: 1.5em;
  font-weight: 500;
  padding: 15px 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #6c3fa7;
  }
`;

const Policy = styled.p`
  color: #656565;
  font-size: 1.3em;
  padding: 15px 0;
`;

const WrapBgImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  @media only screen and (max-width: 899px) {
    display: none;
  }
`;

export const Error = styled.p`
  padding: 12px 0;
  font-size: 0.9rem;
  color: ${red1};
`;

const ForgotPassword = styled.div`
  font-size: 0.95rem;
  padding: 12px 0;
  cursor: pointer;
`;

const UsernameMsg = styled.span`
  font-size: 0.9rem;
  padding: 10px 0 20px 0;
  color: #6a6767;
`;

const ErrorFromServer = styled.div`
  font-size: 1rem;
  padding: 7px 12px;
  width: 100%;
  box-sizing: border-box;
  color: #f03b3b;
  background: #ffd8cc87;
  border: 1px solid #f48d8d;
`;

UserAuth.defaultProps = {
  error: null,
};

export default connect(null, {updateUserAuthStatus})(UserAuth);
