import React, { useState } from "react";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import TemplateInfo from "../components/TemplateInfo";
import { useParams } from "react-router-dom";
import RequestTempLocation from "../components/RequestTempLocation";
import { selectUserInfo } from "../../../redux/user/authUser";
import { connect } from "react-redux";

const OatTempDetails = ({user}) => {
  let params = useParams();
  const [requestTempLocation, setRequestTempLocation] = useState({
    status: false,
    workspaceID: null,
    folderID: null,
    tempInfo: {},
  });
  const [folders, setFolders] = useState([])

  const onRequestTempLocation = (tempInfo) => {
    setRequestTempLocation({
      ...requestTempLocation,
      status: true,
      tempInfo,
    });
  }

  const handleModalClose = () => {
    setRequestTempLocation({...requestTempLocation, status: false});
  };

  const onSelectWorkspace = (item) => {
    setRequestTempLocation({
      ...requestTempLocation,
      workspaceID: item.value,
      workspaceName: item.display,
    })
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${item.value}/folders`
    )
      .then((res) => res.json())
      .then((data) => {
        let folderItems = [];
        data.designFolders && data.designFolders.map((item) =>
          folderItems.push({
            display: item.folderName,
            value: item.folderID,
          })
        );
        setFolders(folderItems);
      })
      .catch((e) => {});
  }

  const onSelectFolder = (item) => {
    setRequestTempLocation({
      ...requestTempLocation,
      folderID: item.value,
      folderName: item.display,
    });
  };

  return (
    <HelmetProvider>
      <Helmet>
        <body />
      </Helmet>

      <Layout>
        <div style={{  maxWidth: 1140 }}>
          <TemplateInfo 
            modalOpen={false}
            selectedItem={{tempID: params.id}} 
            onRequestTempLocation={onRequestTempLocation}  
          />
        </div>

        {requestTempLocation.status ? (
          <RequestTempLocation
            selectedLocation={requestTempLocation}
            tempInfo={requestTempLocation.tempInfo}
            onSelectWorkspace={onSelectWorkspace}
            onSelectFolder={onSelectFolder}
            onClose={handleModalClose}
          />
      ) : undefined}
      </Layout>
    </HelmetProvider>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  align-items: center;
  min-height: ${window.innerHeight}px;
  margin-top: 22px;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(OatTempDetails);
