import React, {useCallback} from "react";
import {connect} from "react-redux";
import {selectTempScale} from "../../../../../../../store/selectors/template/template.selector";
import {compactFormatter, formatWithCommas} from "./ScaleLabels";

const XScaleLabels = ({
  color,
  fontSize,
  fontFamily,
  item,
  gap,
  lowestValue,
  digitsLength,
  tickColor,
  scaleDistance,
  scaleType,
  scaleFormat,
  tempScale,
  currencyUnit,
  height,
}) => {
  const formatScaleValues = useCallback(
    (item) => {
      // compact notion
      if (scaleFormat === "1k") {
        return compactFormatter(item, 1);
      } else if (scaleFormat === "1,000") {
        return formatWithCommas(item);
      } else {
        return item;
      }
    },
    [scaleFormat]
  );

  return [...Array(item.lines + 1)].map((x, i) => (
    <g key={i}>
      {/* <line
        x1={gap * i}
        x2={gap * i}
        y1={height}
        y2={height}
        stroke={tickColor}
        strokeWidth={0.5 / tempScale}
      /> */}
      <text
        style={{
          textAnchor: "end",
          fontSize,
          alignmentBaseline: "before-edge",
          dominantBaseline: "text-before-edge",
          fill: color,
          fontFamily,
          transform: `translate(${gap * i + 6}px, ${height + 7}px)`, // 3 * (tempSize.w / 536)
        }}
      >
        {scaleType === "currency" ? currencyUnit + " " : undefined}
        {formatScaleValues((lowestValue + i * item.acc).toFixed(digitsLength))}
        {scaleType === "percentage" ? " %" : undefined}
      </text>
    </g>
  ));
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(XScaleLabels);
