import React from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import UserDashboardHoc from "../hoc/UserDashboardHoc";
import UserFolderHoc from "../hoc/UserFolderHoc";
import DashboardPanel from "../components/dashboardPanel/DashboardPanel";
import PurchasedPanel from "../components/dashboardPanel/purchased/PurchasedPanel";
import PurchasedByDate from "../components/dashboardPanel/purchased/PurchasedByDate";

const Purchased = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <body style="overflow: hidden;" />
      </Helmet>

      <UserDashboardHoc>
        <UserFolderHoc>
          <PurchasedByDate />
        </UserFolderHoc>
        
        <DashboardPanel>
          <PurchasedPanel />
        </DashboardPanel>
      </UserDashboardHoc>
    </HelmetProvider>
  );
};

export default Purchased;
