import React from 'react';
import styled from 'styled-components';
import {paleBlue2, red4 } from '../../oat-color-variables';

const AvailableServices = ({selectedItem, onSelectItem}) => {
    return (
        <Wrapper>
            <Item
                $active={selectedItem === 'sales-pitch-deck'}
                onClick={() => onSelectItem("sales-pitch-deck")}
            >
                Sales / Pitch Deck
            </Item>
            <Item
                $active={selectedItem === 'illustrations'}
                onClick={() => onSelectItem("illustrations")}
            >
                Illustrations
            </Item>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
    font-size: 0.9rem;
    margin-bottom: 18px;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    @media only screen and (max-width: 850px) {
        padding: 10px 0;
    }
    @media only screen and (min-width: 1500px) {
        padding: 28px 5%;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    height: 100%;
    cursor: pointer;
    padding: 7px 20px;
    margin: 0 12px 16px 0; 
    box-sizing: border-box;
    color: #2b2b30;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    border-radius: 20px;
    text-align: center;
    background: ${({$active}) => $active ? paleBlue2 : undefined};
    @media only screen and (min-width: 768px) {
        padding: 8px;
        width: 146px;
    }
    &:hover {
        fill: ${red4};
        p {
            color: ${red4};
        }
    }
`

export default AvailableServices;