import React from "react";
import styled from "styled-components";
import {navPanelHeight} from "./NavBar";

export const folderPanelWidth = 260;
const AdminSidebar = ({children}) => {
  return <Layout>{children}</Layout>;
};

const Layout = styled.div`
  width: ${folderPanelWidth}px;
  height: ${window.innerHeight - navPanelHeight}px;
  background: #fff;
  top: 57px;
  z-index: 1;
  padding: 14px 0;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  @media only screen and (max-width: 849px) {
    display: none;
  }
`;

export default AdminSidebar;
