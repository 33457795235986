import React from 'react';
import {Title} from "../../shared/reuseStyles/Title";
import { HorizontalFlip } from '../../../../../../ui/svg/Flip';
import styled from "styled-components";
import { connect } from 'react-redux';
import { updateFieldCollaboration } from '../../../../../../store/actions/collaboration/collaboration.action';
import { storeFieldUndoRedo } from '../../../../../../store/actions/fields/undoRedo.action';
import { transformImgOffset } from '../../../../../../store/actions/fields/image.action';
import { updateFieldStyles } from '../../../../../../store/actions/fields/common.action';
import { selectActiveFieldProps } from '../../../../../../store/selectors/fields.selector';
import { selectActiveSlide } from '../../../../../../store/selectors/template/slide.selector';
import { getWindowSize } from '../../../../../../../oat-window-size/getWindowSize';

const FlipVideo = ({
    field, 
    slideID,
    subtype, 
    transformImgOffset, 
    updateFieldStyles, 
    storeFieldUndoRedo, 
    updateFieldCollaboration
}) => {
    const windowSize = getWindowSize();
    const { size, imgPos, styles } = field || {};
    const flipHorizontal = async() => {
        const calcX =
          size?.w + -imgPos?.x - size?.w2;
    
        updateFieldStyles({
          active: {slideID, fieldID: field?.key},
          type: "scaleX",
          value: styles?.scaleX === 1 ? -1 : 1,
        });
    
        const imgOffset = {x: calcX, y: imgPos?.y};
        const active = {slideID, fieldID: field?.key};
        transformImgOffset(active, imgOffset);
        await storeFieldUndoRedo();
        await updateFieldCollaboration();
    };

    return (
        <div style={{display: "flex"}}>
            {windowSize.width >= 850 ? <Title>Flip</Title> : undefined}
            <WrapIcon>
                <div onClick={flipHorizontal}>
                    <HorizontalFlip fill="#b2b2b2" />
                </div>
            </WrapIcon>
        </div>
    );
};

const WrapIcon = styled.div`
  flex: 1;
`

const mapStateToProps = (state) => {
    return {
      field: selectActiveFieldProps(state.designTemplate),
      slideID: selectActiveSlide(state.designTemplate),
    };
  };

export default connect(mapStateToProps, {
    transformImgOffset,
    storeFieldUndoRedo, 
    updateFieldCollaboration,
    updateFieldStyles
})(FlipVideo);