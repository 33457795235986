import React, {useState, useEffect} from "react";
import {useParams} from "react-router";
import {DesignTemplate} from "../../../packages/oat-design-template";

const AdminUpdateTemplate = () => {
  let params = useParams();
  const {category, ratio, tempID} = params;
  const [isSaving, setIsSaving] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const updateTemplate = async (item) => {
    if (!isSaving) {
      setIsSaving(true);

      const newTimeoutId = setTimeout(() => {
        // If the request is taking too long, cancel it
        abortController.abort();
        setIsSaving(false);
      }, 45000); // 45 seconds
      setTimeoutId(newTimeoutId);

      await fetch(
        `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/templates/${category}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempInfo: {
              tempID,
              themeID: "main",
              // category,
              // ratio: item.templateInfo.ratio,
              // size: item.templateInfo.size,
              // lng: item.admin.tempLng,
            },
            tempDetails: item.template,
            isDraft: item.admin.isDraft,
          }),
          signal,
        }
      )
        .then((response) => {
          clearTimeout(timeoutId);
          if (!response.ok) {
            throw new Error("Network response error");
          }
          return response.json();
        })
        .then((data) => {
          setIsSaving(false);
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          setIsSaving(false);
        });
    }
  };


  return (
    <DesignTemplate
      onSave={updateTemplate}
      apiEndpoint={`https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template/${tempID}`}
      buttonTitle="Update"
      displaySlideListOnLoad={false}
      isSaving={isSaving}
    />
  );
};

export default AdminUpdateTemplate;
