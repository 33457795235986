import React from 'react';
import { WrapIconItem, WrapItem } from '../../reusableStyles/reusableStyles';

const DisplayPack = ({item, onSelect}) => {
    const handleClick = () => {
      onSelect(item.key);
    };
  
    return (
      <WrapIconItem>
        <WrapItem onClick={handleClick}>
          <img src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`} alt="" />
        </WrapItem>
      </WrapIconItem>
    );
  };

export default DisplayPack;