import React from "react";
import styled from "styled-components";
import {LayoutHoc} from "../../../packages/oat-public";
import OatTempDetails from "../../../packages/oat-templates/containers/OatTempDetails";

const TempDetails = () => {
  return (
    <LayoutHoc>
      <Wrapper>
        <OatTempDetails />
      </Wrapper>
    </LayoutHoc>
  );
};

const Wrapper = styled.div``;

export default TempDetails;
