import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {imageStyles} from "./../image/ImageField";

const maxRetries = 3;
let retryCount = 0;

const DisplayIcon = ({field}) => {
  const [svgContent, setSvgContent] = useState(``);

  useEffect(() => {
    const changeIconColor = () => {
      if (svgContent !== "") {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
      
        field?.colorSet.forEach(({ id, colors }) => {
          if(colors){
            colors && colors?.forEach((item, index) => {
              const svgElements = svgDoc.querySelectorAll(`.${field.key}-${id}-${index}`);          
              
              svgElements.forEach((svgElement) => {
                if(item.type === 'fill') {
                  const fillValue = svgElement.getAttribute('fill');
                  if (!fillValue.match(/url\(#(.*?)\)/)) {
                    svgElement.setAttribute('fill', item.color);
                  }      
                }

                if(item.type === 'stroke') {
                  const strokeValue = svgElement.getAttribute('stroke');
                  if (strokeValue) {
                    svgElement.setAttribute('stroke', item.color);   
                  }
                }
              });
            
              const gradientStops = svgDoc.querySelectorAll(`.${field.key}-${id}-gradient stop`);
              for (let i = 0; i < gradientStops.length; i++) {
                const stop = gradientStops[i];
                const newColor = colors[i % colors.length].color;
                stop.setAttribute('stop-color', newColor);
              };
            });
          }
        });
        const modifiedSvg = new XMLSerializer().serializeToString(svgDoc);
        setSvgContent(modifiedSvg);
      }
    };
    changeIconColor();
  }, [field?.colorSet, svgContent]);  // svgContent - to avoid resetting to default color on theme update

  useEffect(() => {
    function changeIDsAndClassName(svg) {
      if (svg) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svg, 'image/svg+xml');

        function appendUniqueKey(attributeValue) {
          return field?.key + '-' + attributeValue;
        }

        svgDoc.querySelectorAll('*').forEach(element => {
          // Update the id attribute with the unique key
          const originalId = element.getAttribute('id');
          if (originalId) {
            const newId = appendUniqueKey(originalId);
            element.setAttribute('id', newId);
          }
        
          // Update class attributes with the unique key
          const originalClass = element.getAttribute('class');
          if (originalClass) {
            const newClass = originalClass
              .split(' ')
              .map(className => appendUniqueKey(className))
              .join(' ');
            element.setAttribute('class', newClass);
          }

          const fillValue = element.getAttribute('fill');
          if (fillValue && fillValue.includes(`url(#${originalId}-gradient)`)) {
            // Replace the old gradient reference with the updated one
            element.setAttribute('fill', `url(#${field.key}-${originalId}-gradient)`);
          }
        });

        const modifiedSvg = new XMLSerializer().serializeToString(svgDoc);
        setSvgContent(modifiedSvg);
      }
    };

    const iconUrl = `https://oat-images-icons.s3.amazonaws.com/${field.content}`;
    new Promise((resolve, reject) => {
      const fetchData = () => {
        fetch(iconUrl, {cache: "no-store"})
          .then(async(response) => {
            if (!response.ok) {
              // throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return await response.text()
          })
          .then(async(svgData) => {
            await changeIDsAndClassName(svgData);
            resolve(svgData);
          })
          .catch((error) => {
            if (retryCount < maxRetries) {
              // Retry loading after a delay
              setTimeout(() => {
                retryCount++;
                fetchData();
              }, 10000); 
            } else {
              // Max retries reached
              // reject(new Error(`Failed to load SVG after ${maxRetries} retries. ${error.message}`));
            }
          });
      };
      fetchData();
    })
  }, [field?.content, field?.key]);

  return (
    <WrapSvg key={field.key} style={field.styles} size={field.size}>
      {field?.format === 'png' ? 
        <Image style={{ width: '100%', height: '100%' }} src={`https://oat-images-icons.s3.amazonaws.com/${field.content}`} alt="oat-all-image-icons" /> 
        :
        svgContent && (
          <div dangerouslySetInnerHTML={{ __html: svgContent }} />
      )}
    </WrapSvg>
  )
};

const WrapSvg = styled.div`
  width: ${({size}) => size.w}px;
  height: ${({size}) => size.h}px;
  svg { pointer-events: none; }; // hide svg title 
`;

const Image = styled.img`
 ${imageStyles}
`

export default DisplayIcon;
