import React, {useEffect} from "react";
import CommentDarkTheme from "../../../ui/svg/CommentDarkTheme";
import CommentLightTheme from "../../../ui/svg/CommentLightTheme";
import PenDarkTheme from "../../../ui/svg/PenDarkTheme";
import PenLightTheme from "../../../ui/svg/PenLightTheme";
import FinishLightTheme from "../../../ui/svg/FinishLightTheme";
import FinishDarkTheme from "../../../ui/svg/FinishDarkTheme";
import AnimateSvg from "../../../ui/svg/AnimateSvg";
import {green} from "../../../themes";
import {Notes} from "../../../../oat-svg-icons";
import styled from "styled-components";
import {Tooltip} from "../../../../oat-ui";
import {actionItemStyles} from "./SlideActionsPanel";
import {toggleSlideWorkingStatus} from "../../../store/actions/template/slide.action";
import {toggleEditPanel} from "../../../store/actions/layout.action";
import {useSocket} from "../../../webSocket/useSocket";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {
  selectActiveSlideID,
  selectIfNoActiveSlide,
  selectActiveSlideInfo,
} from "../../../store/selectors/template/slide.selector";

const SlideActionItems = ({
  theme,
  noActiveSlide,
  slideID,
  slide,
  toggleEditPanel,
  toggleSlideWorkingStatus,
  panelOpened,
  panelType,
  activeField
}) => {
  const {emitSocketEvents} = useSocket() || {};
  const location = useLocation();

  const showEditPanel = () => {
    if (!noActiveSlide) toggleEditPanel("designs", true);
  };

  const showAnimationPanel = () => {
    if (!noActiveSlide) toggleEditPanel("animations", true);
  };

  const toggleAddNotes = () => {
    if (!noActiveSlide) toggleEditPanel("notes", true);
  };

  const showCommentPanel = () => {
    if (!noActiveSlide) toggleEditPanel("comment", true);
  };

  const changeWorkingStatus = () => {
    if (!noActiveSlide) {
      toggleSlideWorkingStatus(slideID);
      const item = {type: "", value: slideID};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "changed-slide-status", item});
      }
    }
  };

  const query = new URLSearchParams(useLocation().search);
  const openCommentPanel = query.get("comment");
  useEffect(() => {
    if (openCommentPanel && openCommentPanel !== "") {
      toggleEditPanel("comment", true);
    }
  }, []);

  return (
    <Layout style={{flexDirection: panelOpened ? "row" : "column"}}>
      <WrapItem
        onClick={showEditPanel}
        panelOpened={panelOpened}
        selected={(panelOpened && panelType === "designs") || 
          (panelType !== "comment" && 
          panelType !== "animations" &&
          panelType !== "notes" && 
          panelOpened)
        }
      >
        <WrapSvg>
          {/* <svg viewBox="0 0 24 24">
              <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path>
            </svg> */}

          {theme === "light" ? PenLightTheme : PenDarkTheme}
        </WrapSvg>
        <Tooltip style={{width: 80}}>Edit Options</Tooltip>
      </WrapItem>
      {!location.pathname.includes("/admin/designs/") ?
        <>
          <WrapItem
            onClick={showAnimationPanel}
            panelOpened={panelOpened}
            selected={panelType === "animations"}
          >
            <WrapSvg
              style={{transform: `scale(1.2) rotateY(180deg) rotate(-2deg)`}}
            >
              {AnimateSvg}
            </WrapSvg>
            <Tooltip style={{width: 100}}>Animate</Tooltip>
          </WrapItem>
          <WrapItem
            onClick={showCommentPanel}
            panelOpened={panelOpened}
            selected={panelType === "comment"}
          >
            <WrapSvg>
              {theme === "light" ? CommentLightTheme : CommentDarkTheme}
            </WrapSvg>
            <Tooltip style={{width: 100}}>Add Comment</Tooltip>
          </WrapItem>
          <WrapItem onClick={changeWorkingStatus} panelOpened={panelOpened}>
            <WrapSvg style={{fill: slide && slide.markedDone ? green : undefined}}>
              {theme === "light" ? FinishLightTheme : FinishDarkTheme}
            </WrapSvg>
            <Tooltip style={{width: 100}}>Mark as Finish</Tooltip>
          </WrapItem>
          <WrapItem
            onClick={toggleAddNotes}
            panelOpened={panelOpened}
            selected={panelType === "notes"}
          >
            <WrapSvg>{Notes}</WrapSvg>
            <Tooltip style={{width: 70}}>Add Notes</Tooltip>
          </WrapItem> 
        </> : 
        undefined
      }
      {/* <WrapItem>
          <WrapSvg>
            <SvgTrash />
          </WrapSvg>
          <OnHoverContent style={{width: 70}}>Delete Design</OnHoverContent>
        </WrapItem> */}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const WrapItem = styled.div`
  ${actionItemStyles};
  margin: ${({panelOpened}) => (panelOpened ? "15px" : undefined)};
  flex: ${({panelOpened}) => (panelOpened ? "0 0 auto" : undefined)};
  margin-bottom: ${({panelOpened}) => (panelOpened ? "12px" : "22px")};
  background: ${({theme, selected}) =>
    selected ? theme.deskSecondaryBg4 : undefined};
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
`;

const mapStateToProps = (state) => {
  return {
    noActiveSlide: selectIfNoActiveSlide(state.designTemplate),
    slideID: selectActiveSlideID(state.designTemplate),
    slide: selectActiveSlideInfo(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  toggleEditPanel,
  toggleSlideWorkingStatus,
})(SlideActionItems);
