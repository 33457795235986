import React, {useState} from "react";
import {connect} from "react-redux";
import {updateThemeBuilderFields} from "../../../store/actions/template/theme.action";
import {selectThemeBuilder} from "../../../store/selectors/template/theme.selector";
import {
  InputName,
  WrapColorType,
} from "../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
import DeskGradientColors from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/DeskGradientColors";
import PopoverColorBox from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import Switch from "../../editDesign/editingPanel/panelTypes/shared/inputs/Switch";
import {ColorsSection, WrapItem} from "./buildThemeStyle";
import {Message} from "./ImageThemeBuilder";

const ShapeThemeBuilder = ({shape, updateThemeBuilderFields}) => {
  const [enableStroke, setEnableStroke] = useState(
    shape.strokeWidth !== 0 ? true : false
  );

  const handleFieldUpdate = (key, value) => {
    updateThemeBuilderFields({
      category: "shape",
      key,
      value,
    });
  };

  const toggleGradient = (type) => {
    handleFieldUpdate("enableGradient", !shape.enableGradient);
  };

  const changeColorOne = (value) => {
    handleFieldUpdate("color1", value);
  };

  const changeColorTwo = (value) => {
    handleFieldUpdate("color2", value);
  };

  const changeGradientAngle = (value) => {
    handleFieldUpdate("gradientAngle", value);
  };

  const toggleStrokeStatus = () => {
    handleFieldUpdate("strokeWidth", shape.strokeWidth !== 0 ? 0 : 7);
    setEnableStroke(!enableStroke);
  };

  const changeStroke = (value) => {
    handleFieldUpdate("stroke", value);
  };

  const changeLineColor = (value) => {
    handleFieldUpdate("lineColor", value);
  };

  const handleDebounceColor = () => {
    // leave empty
  };

  return (
    <>
      {/* <WrapColorType>
        <Switch
          title="Gradient"
          value={shape.enableGradient}
          onItemChange={toggleGradient}
          checked={shape.enableGradient}
        />
      </WrapColorType> */}

      <WrapItem style={{margin: 12}}>
        <Message style={{fontSize: "0.85rem"}}>
          Gradient and stroke effects will only apply if the options are
          enabled.
        </Message>
      </WrapItem>

      <WrapItem>
        {/* {!shape.enableGradient ? (
          <ColorsSection>
            <InputName>Colors</InputName>
            <PopoverColorBox
              value={shape.color1}
              updateFieldStyles={changeColorOne}
              onDebounce={handleDebounceColor}
            />
          </ColorsSection>
        ) : ( */}
        <DeskGradientColors
          colorOne={shape.color1}
          colorTwo={shape.color2}
          angle={shape.gradientAngle}
          updateColorOne={changeColorOne}
          updateColorTwo={changeColorTwo}
          updateFieldStyles={changeGradientAngle}
          onDebounceColorOne={handleDebounceColor}
          onDebounceColorTwo={handleDebounceColor}
        />
        {/* )} */}
      </WrapItem>

      {/* <WrapItem>
        <Switch
          title="Enable Stoke"
          value={enableStroke}
          onItemChange={toggleStrokeStatus}
          checked={enableStroke}
          displayTooltip={{
            status: true,
            info: "Will only apply to fields with stroke on",
          }}
        />
      </WrapItem> */}

      <WrapItem>
        <ColorsSection>
          <InputName>Stroke Color</InputName>
          <PopoverColorBox
            value={shape.stroke}
            updateFieldStyles={changeStroke}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Line Color</InputName>
          <PopoverColorBox
            value={shape.lineColor}
            updateFieldStyles={changeLineColor}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shape: selectThemeBuilder(state.designTemplate)["shape"],
  };
};

export default connect(mapStateToProps, {updateThemeBuilderFields})(
  ShapeThemeBuilder
);
