import React from "react";
import EditTableHeader from "../../../../desktop/table/EditTableHeader";
import {connect} from "react-redux";
import {selectActiveSlide} from "../../../../../../../../store/selectors/template/slide.selector";
import {selectActiveFieldProps} from "../../../../../../../../store/selectors/fields.selector";
import {DrawerLayout} from "../layoutStyles";
import EditSpacing from "../common/EditSpacing";
import {updateFieldStyles} from "../../../../../../../../store/actions/fields/common.action";
import EditTableBody from "../../../../desktop/table/EditTableBody";
import TableBorder from "./TableBorder";
import EditOpacity from "../../../../desktop/common/EditOpacity";
import EditRotate from "../common/EditRotate";
import ZIndex from "../../../../shared/ZIndex";
import ApplyAnimations from "../../../../desktop/animations/ApplyAnimations";
import EditTableRowsColumns from "./EditTableRowsColumns";

const TableDrawers = ({
  drawerType,
  slideID,
  activeField,
  updateFieldStyles,
  onClose,
}) => {
  const {styles} = activeField || {};
  let displayView = null;

  const handleUpdates = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const changeLetterSpacing = (value) => {
    handleUpdates("letterSpacing", Number(value));
  };

  const changeLineHeight = (value) => {
    handleUpdates("lineHeight", Number(value));
  };

  switch (drawerType) {
    case "table-header":
      displayView = <EditTableHeader styles={styles} />;
      break;
    case "table-body":
      displayView = <EditTableBody styles={styles} />;
      break;
    case "spacing":
      displayView = (
        <EditSpacing
          lineHeight={styles?.lineHeight}
          letterSpacing={styles?.letterSpacing}
          onChangeLetterSpacing={changeLetterSpacing}
          onChangeLineHeight={changeLineHeight}
        />
      );
      break;
    case "table-border":
      displayView = <TableBorder />;
      break;
    case "edit-table-rows-columns":
      displayView = <EditTableRowsColumns onClose={onClose} />;
      break;
    case "opacity":
      displayView = <EditOpacity value={styles?.opacity} />;
      break;
    case "rotate":
      displayView = <EditRotate value={styles?.rotate} />;
      break;
    case "layer":
      displayView = <ZIndex />;
      break;
    case "animation":
      displayView = <ApplyAnimations />;
      break;
    default:
  }

  return <DrawerLayout>{displayView}</DrawerLayout>;
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(TableDrawers);
