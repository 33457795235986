export const UPDATE_USER_AUTH_STATUS = "UPDATE_USER_AUTH_STATUS";
export const DISPATCH_SIGN_OUT = "DISPATCH_SIGN_OUT";
export const UPDATE_LOADING_STATUS = "UPDATE_LOADING_STATUS";
export const SUBTRACTED_CREDIT_AMOUNT = "SUBTRACTED_CREDIT_AMOUNT";

export function updateUserAuthStatus({cognito, dynamo}) {
  return {
    type: "UPDATE_USER_AUTH_STATUS",
    cognito,
    dynamo,
  };
}

export function updateLoadingStatus() {
  return {
    type: "UPDATE_LOADING_STATUS",
  };
}

export function dispatchSignOut() {
  return {
    type: "DISPATCH_SIGN_OUT",
  };
}

export function subtractCredit(amount) {
  return {
    type: "SUBTRACTED_CREDIT_AMOUNT",
    amount
  };
}
