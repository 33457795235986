import React from "react";
import {connect} from "react-redux";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";

const EditOpacity = ({fieldID, slideID, value, updateFieldStyles}) => {
  const handleUpdate = (item) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type: "opacity",
      value: Number(item / 100),
    });
  };

  return (
    <RangeSlider
      title="Opacity"
      value={value * 100}
      max={100}
      min={1}
      step={1}
      onItemChange={handleUpdate}
      fieldType="opacity"
    />
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(EditOpacity);
