import React, {useState} from "react";
import {updateEditingPanel} from "../../../../../../store/actions/layout.action";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {Items, Item, Title} from "./MobilePanelStyles";
import MobileDrawer from "../../../../drawer/MobileDrawer";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import ChartDrawers from "./edit/chart/ChartDrawers";
import LockField from "./edit/common/LockField";

const ChartPanel = ({slideID, activeField}) => {
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: null,
    height: 0,
  });
  const { subtype } = activeField || {};

  const onOpenDrawer = (type, height) => {
    setOpenDrawer({
      status: true,
      type,
      height,
    });
  };

  const handleDrawerClose = () => {
    setOpenDrawer({
      status: false,
      type: null,
      height: 0,
    });
  };

  const onSelectBarWidth = () => {
    onOpenDrawer("bar-width", 80);
  };

  const onSelectAxis = () => {
    onOpenDrawer("axis", 150);
  };

  const onSelectChartValues = () => {
    onOpenDrawer("values", 150);
  };

  const onSelectCategories = () => {
    onOpenDrawer("categories", 150);
  };

  const onSelectMeasurements = () => {
    onOpenDrawer("measurements", 150);
  };

  const onSelectBackground = () => {
    onOpenDrawer("background", 120);
  };

  const onSelectLegend = () => {
    onOpenDrawer("legend", 150);
  };

  const onSelectGrid = () => {
    onOpenDrawer("grid", 150);
  };

  const onSelectOpacity = () => {
    onOpenDrawer("opacity", 80);
  };

  const onSelectEditChartInfo = () => {
    onOpenDrawer("edit-chart-info", (60 * window.innerHeight) / 100);
  };

  const onSelectRotate = () => {
    onOpenDrawer("rotate", 80);
  };

  const onSelectLayer = () => {
    onOpenDrawer("layer", 80);
  };

  const onSelectAnimations = () => {
    onOpenDrawer("animation", (window.innerHeight * 35) / 100);
  };

  return (
    <>
      {subtype === "pie" || subtype === "donut" || subtype === "gauge" ? 
        <Items>
          <Item onClick={onSelectEditChartInfo}>
            <Title>Edit Chart</Title>
          </Item> 
          
          <Item onClick={onSelectChartValues}>
            <Title>Values</Title>
          </Item>

          <Item onClick={onSelectLegend}>
            <Title>Legend</Title>
          </Item>

          <Item onClick={onSelectOpacity}>
            <Title>Opacity</Title>
          </Item>

          <LockField />

          <Item onClick={onSelectRotate}>
            <Title>Rotate</Title>
          </Item>

          <Item onClick={onSelectLayer}>
            <Title>Layer</Title>
          </Item>

          <Item onClick={onSelectAnimations}>
            <Title>Animate</Title>
          </Item>
        </Items>
      : 
        <Items>
          <Item onClick={onSelectEditChartInfo}>
            <Title>Edit Chart</Title>
          </Item>

          <Item onClick={onSelectBarWidth}>
            <Title>Bar Width</Title>
          </Item>

          <Item onClick={onSelectAxis}>
            <Title>Axis</Title>
          </Item>

          <Item onClick={onSelectChartValues}>
            <Title>Values</Title>
          </Item>

          <Item onClick={onSelectCategories}>
            <Title>Categories</Title>
          </Item>

          <Item onClick={onSelectMeasurements}>
            <Title>Measurements</Title>
          </Item>

          <Item onClick={onSelectBackground}>
            <Title>Background</Title>
          </Item>

          <Item onClick={onSelectLegend}>
            <Title>Legend</Title>
          </Item>

          <Item onClick={onSelectGrid}>
            <Title>Grid</Title>
          </Item>

          <Item onClick={onSelectOpacity}>
            <Title>Opacity</Title>
          </Item>

          <LockField />

          <Item onClick={onSelectRotate}>
            <Title>Rotate</Title>
          </Item>

          <Item onClick={onSelectLayer}>
            <Title>Layer</Title>
          </Item>

          <Item onClick={onSelectAnimations}>
            <Title>Animate</Title>
          </Item>
        </Items>
      }

      {openDrawer ? (
        <MobileDrawer
          open={openDrawer?.status}
          height={openDrawer?.height}
          onClose={handleDrawerClose}
        >
          {activeField?.key ? (
            <ChartDrawers drawerType={openDrawer?.type} />
          ) : undefined}
        </MobileDrawer>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateEditingPanel,
  updateFieldStyles,
})(ChartPanel);
