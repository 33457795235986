import React, {useEffect, useRef, useState, useCallback} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {ArrowBack} from "../oat-svg-icons";
import {selectUserInfo} from "../../redux/user/authUser";
import {Bell} from "../oat-svg-icons";
import {Popper} from "../oat-ui";
import {getWindowSize} from "../oat-window-size/getWindowSize";
import NotificationLayout from "./components/NotificationLayout";

const Notification = ({user, emitAcceptedWorkspace}) => {
  const [selectedTab, setSelectedTab] = useState("notification");
  const [fetching, setFetching] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [items, setItems] = useState([]);
  const [notiCount, setNotiCount] = useState(0);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [socketOn, setSocketOn] = useState(false);
  const windowSize = getWindowSize();

  const onSelectNotifications = () => {
    setSelectedTab("notification");
  };

  const onSelectInvitations = () => {
    setSelectedTab("invitation");
  };

  const onSelectItem = () => {};

  const fetchNotifications = useCallback(() => {
    setFetching(true);
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/notifications`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setItems(data.response.items);
          // setItems([...items, ...data.response.items]);
          setNotiCount(data.response.notiCount);
        } else {
          setError({
            status: true,
            message: "Something went wrong.",
          });
        }
        setFetching(false);
      })
      .catch((e) => {
        setFetching(false);
      });
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const onSocketOpen = useCallback(() => {
    setSocketOn(true);
    if (user.username && user.loggedIn.status) {
      socket.current?.send(
        JSON.stringify({action: "setUsername", username: user.username})
      );
    }
  }, [user]);

  const onSocketClose = async () => {
    const current = new Date();
    setSocketOn(false);
    await socket.current?.close();
  };

  const onSocketReceiveData = useCallback(
    (e) => {
      const data = JSON.parse(e.data);
      // if (data.type === "invitation") {
      //   pushNewNotification(data);
      //   setNotiCount((prev) => prev + 1);
      // } else if (data.type === "slide-comment") {
      //   pushNewNotification(data);
      //   setNotiCount((prev) => prev + 1);
      // }
    },
    [setNotiCount]
  );

  const WebSocket_URL =
    "wss://0a871yd7zl.execute-api.us-east-1.amazonaws.com/production";
  const socket = useRef(null);
  useEffect(() => {
    if (socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(WebSocket_URL);
      socket.current?.addEventListener("open", onSocketOpen);
      socket.current?.addEventListener("close", onSocketClose);
      socket.current.onmessage = (e) => {
        if (e.data !== "Ping") pushNewNotification(JSON.parse(e.data));
      };
    }

    return () => {
      socket.current?.removeEventListener("open", onSocketOpen);
      socket.current?.removeEventListener("close", onSocketClose);
      socket.current?.close();
    };
    // reopen connection after PC has been closed on inactive.
    // if (!socketOn) {
    //   ref.current = setInterval(onSocketOpen, 1 * 60 * 1000);
    //   return () => {
    //     if (ref.current) {
    //       clearInterval(ref.current);
    //     }
    //   };
    // }
  }, [socket]);

  const handlePopperOpen = () => {
    setOpenNotification(true);
    setNotiCount(0);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/notifications/count`,
      {
        method: "PUT",
      }
    )
      .then((res) => res.json())
      .catch((e) => {});
  };

  const handlePopperClose = () => {
    // remove ws invitation notification after it has been accepted
    const updateItems = items.filter(
      (noti) => noti.ID !== acceptWsInvitation.notiID
    );
    setItems(updateItems);
    setOpenNotification(false);
  };

  function onKeepConnectionAlive() {
    if (socketOn) {
      socket.current?.send(
        JSON.stringify({
          action: "pingPong-dashboard",
        })
      );
    }
  }

  const ref = useRef(null);
  useEffect(() => {
    ref.current = setInterval(onKeepConnectionAlive, 4 * 45 * 1000);
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [socketOn]);

  const pushNewNotification = (item) => {
    const newItems = [
      {
        read: false,
        ...item,
      },
      ...items,
    ];
    setItems(newItems);
    setNotiCount((prev) => prev + 1);
  };

  const [acceptWsInvitation, setAcceptWsInvitation] = useState({
    processing: false,
    error: false,
    accepted: false,
    wsID: null,
    notiID: null,
  });
  const handleWsInvitation = (item) => {
    if (!acceptWsInvitation.processing) {
      if (error)
        //  setError(false);
        //  setProcessing(true);
        //  setWorkspaceAccepted({
        //    accepted: false,
        //    id: null,
        //  });
        setAcceptWsInvitation({
          processing: true,
          error: false,
          accepted: false,
          wsID: null,
          notiID: null,
        });
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces/${item.data.workspaceID}/invites/accept`,
        {
          method: "POST",
          body: JSON.stringify({
            notificationID: item.ID,
            timestamp: item.createdAt,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            // setWorkspaceAccepted({
            //   accepted: true,
            //   id: item.data.workspaceID,
            // });
            setAcceptWsInvitation({
              processing: false,
              error: false,
              accepted: true,
              wsID: item.data.workspaceID,
              notiID: item.ID,
            });
            emitAcceptedWorkspace({
              workspaceID: item.data.workspaceID,
              workspaceName: item.data.workspaceName,
              role: item.data.role,
            });
          } else {
            // setError(true);
            setAcceptWsInvitation({
              ...acceptWsInvitation,
              processing: false,
              error: true,
            });
          }
        })
        .catch((e) => {
          setAcceptWsInvitation({
            ...acceptWsInvitation,
            processing: false,
            error: true,
          });
        });
    }
  };

  return (
    <>
      <div style={{position: 'relative', fill: "#848484", padding: '1rem'}} onClick={handlePopperOpen}>
        {Bell}
        {notiCount > 0 ? <NotiCount>{notiCount}</NotiCount> : undefined}
      </div>
      {openNotification ? (
        windowSize.width >= 850 ? (
          <Popper
            width={450}
            height={340}
            offset={
              windowSize.width >= 850
                ? {x: windowSize.width - 450 - 220, y: 68}
                : {x: windowSize.width - 500, y: 68}
            }
            onClose={handlePopperClose}
          >
            <NotificationLayout
              items={items}
              selectedTab={selectedTab}
              user={user}
              fetching={fetching}
              onSelectNotifications={onSelectNotifications}
              onSelectInvitations={onSelectInvitations}
              onSelectItem={onSelectItem}
              handlePopperClose={handlePopperClose}
              acceptWsInvitation={acceptWsInvitation}
              handleWsInvitation={handleWsInvitation}
              fetchNotifications={fetchNotifications}
              error={error}
            />
          </Popper>
        ) : (
          <MobileLayer>
            <BackButton onClick={handlePopperClose}>{ArrowBack}</BackButton>
            <NotificationLayout
              items={items}
              selectedTab={selectedTab}
              user={user}
              fetching={fetching}
              onSelectNotifications={onSelectNotifications}
              onSelectInvitations={onSelectInvitations}
              onSelectItem={onSelectItem}
              handlePopperClose={handlePopperClose}
              acceptWsInvitation={acceptWsInvitation}
              handleWsInvitation={handleWsInvitation}
              fetchNotifications={fetchNotifications}
              error={error}
              $panelHeight={window.innerHeight - 100}
            />
          </MobileLayer>
        )
      ) : undefined}
    </>
  );
};

const NotiCount = styled.div`
  position: absolute;
  top: 30px;
  right: -8px;
  width: 22px;
  height: 22px;
  background: #7e91ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
`;

const MobileLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${window.innerHeight}px;
  background: #fff;
  z-index: 999;
`;

const BackButton = styled.div`
  width: 22px;
  height: 22px;
  margin: 1.2rem;
  fill: #4a4a4a;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(Notification);
