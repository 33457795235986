import React, {useState} from "react";
import {connect} from "react-redux";
import {Items, Item} from "./MobilePanelStyles";
import {updateEditingPanel} from "../../../../../../store/actions/layout.action";
import MobileDrawer from "../../../../drawer/MobileDrawer";
import {useSocket} from "../../../../../../webSocket/useSocket";
// import SvgOpacity from "../../../../../styles/svg/Opacity";
// import MobLockPosition from "../editingPanel/shared/MobLockPosition";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectActiveFieldProps,
  selectCachedFieldStyles,
} from "../../../../../../store/selectors/fields.selector";
import {shapeEditProps} from "../../desktop/shapes/EditShapePanel";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import styled from "styled-components";
import DeskGradientColors from "../../shared/colorPicker/DeskGradientColors";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import {Title} from "../../shared/reuseStyles/Title";
import Switch from "../../shared/inputs/Switch";
import {DrawerLayout} from "./edit/layoutStyles";
import {
  lineStyles,
  lineThicknessForRange,
} from "../../desktop/shapes/EditShapeLine";
import SelectionBox from "../ui/SelectionBox";
import EditOpacity from "../../desktop/common/EditOpacity";
import LockField from "./edit/common/LockField";
import ZIndex from "../../shared/ZIndex";
import EditRotate from "./edit/common/EditRotate";
import ApplyAnimations from "../../desktop/animations/ApplyAnimations";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import ShapeShadow from "../../desktop/shapes/ShapeShadow";

const ShapePanel = ({
  updateEditingPanel,
  updateFieldStyles,
  stableStyles,
  slideID,
  activeField,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: null,
    height: 0,
  });
  const {styles, subtype, name} = activeField || {};
  const {color1, color2, enableStroke, enableGradient} = styles || {};

  const handleDrawerClose = () => {
    setOpenDrawer({
      status: false,
      type: null,
    });
  };

  // updating fields

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const handleEmitValue = (type, value) => {
    updateFieldCollaboration();
  };

  const toggleStroke = () => {
    handleStyleUpdate("enableStroke", !enableStroke);
    handleEmitValue("enableStroke", !enableStroke);
    if (!enableStroke) {
      handleStyleUpdate("strokeWidth", 2);
      handleEmitValue("strokeWidth", 2);
    }
  };

  const onChangeStrokeWidth = (value) => {
    handleStyleUpdate("strokeWidth", value);
  };

  const onChangeStroke = (value) => {
    handleStyleUpdate("stroke", value);
  };

  const onChangeColorOne = (value) => {
    handleStyleUpdate("color1", value);
  };

  const onChangeColorTwo = (value) => {
    handleStyleUpdate("color2", value);
  };

  const onColorOneDebounce = (value) => {
    storeFieldUndoRedo("color1", value);
    handleEmitValue("color1", value);
  };

  const onColorTwoDebounce = (value) => {
    storeFieldUndoRedo("color2", value);
    handleEmitValue("color2", value);
  };

  const onStrokeDebounce = (value) => {
    storeFieldUndoRedo("stroke", value);
    handleEmitValue("stroke", value);
  };

  const onChangeGradientAngle = (value) => {
    handleStyleUpdate("gradientAngle", value);
  };

  const onChangeMarkerColor = (value) => {
    handleStyleUpdate("color2", value);
  };

  const markerColorDebounce = (value) => {
    storeFieldUndoRedo("color2", value);
    handleEmitValue("color2", value);
  };

  const onChangeBlur = (value) => {
    handleStyleUpdate("blur", value);
  };

  const onChangeLineThickness = (value) => {
    handleStyleUpdate("lineThickness", value);
  };

  const onChangeLineStyle = (value) => {
    handleStyleUpdate("lineStyle", value);
  };

  const onChangeBorderRadius = (value) => {
    handleStyleUpdate("borderRadius", value);
  };

  const toggleGradientStatus = (type) => {
    if (type === true) {
      handleStyleUpdate("enableGradient", type);
      handleEmitValue("enableGradient", type);
    } else {
      handleStyleUpdate("enableGradient", type);
      handleEmitValue("enableGradient", type);
    }
  };

  let displayView = null;
  switch (openDrawer?.type) {
    case "color":
      displayView = (
        <div>
          <div style={{paddingBottom: "1.5em"}}>
            <Switch
              title="Gradient"
              value={enableGradient}
              onItemChange={toggleGradientStatus}
              checked={enableGradient}
            />
          </div>
          {!styles.enableGradient ? (
            <ColorSection>
              <Title>Colors</Title>
              <PopoverColorBox
                value={color1}
                updateFieldStyles={onChangeColorOne}
                onDebounce={onColorOneDebounce}
              />
            </ColorSection>
          ) : (
            <DeskGradientColors
              colorOne={color1}
              colorTwo={color2}
              angle={styles?.gradientAngle}
              updateColorOne={onChangeColorOne}
              updateColorTwo={onChangeColorTwo}
              updateFieldStyles={onChangeGradientAngle}
              onDebounceColorOne={onColorOneDebounce}
              onDebounceColorTwo={onColorTwoDebounce}
            />
          )}
        </div>
      );
      break;
    case "stroke":
      displayView = (
        <>
          <div style={{paddingBottom: "1.5em"}}>
            <Switch
              title="Stroke"
              value={enableStroke}
              checkValue={enableStroke}
              onItemChange={toggleStroke}
              checked={enableStroke}
            />
          </div>
          {enableStroke ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "1.5em",
                }}
              >
                <RangeSlider
                  title="Stroke Width"
                  value={styles?.strokeWidth}
                  max={shapeEditProps.strokeWidth.max}
                  min={shapeEditProps.strokeWidth.min}
                  step={shapeEditProps.strokeWidth.step}
                  stableValue={stableStyles?.strokeWidth}
                  onItemChange={onChangeStrokeWidth}
                  fieldType="strokeWidth"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "2em",
                }}
              >
                <Title>Colors</Title>
                <PopoverColorBox
                  value={styles?.stroke}
                  updateFieldStyles={onChangeStroke}
                  onDebounce={onStrokeDebounce}
                />
              </div>
            </>
          ) : undefined}
        </>
      );
      break;
    case "opacity":
      displayView = <EditOpacity value={styles.opacity} />;
      break;
    case "borderRadius": // square & rectangle only
      displayView = (
        <RangeSlider
          title="Rounded Corners"
          value={styles?.borderRadius}
          max={shapeEditProps.borderRadius.max}
          min={shapeEditProps.borderRadius.min}
          step={shapeEditProps.borderRadius.step}
          stableValue={stableStyles?.borderRadius}
          onItemChange={onChangeBorderRadius}
          fieldType="borderRadius"
        />
      );
      break;
    case "blur":
      displayView = (
        <RangeSlider
          title="Blur"
          value={styles?.blur}
          max={shapeEditProps.blur.max}
          min={shapeEditProps.blur.min}
          step={shapeEditProps.blur.step}
          stableValue={stableStyles?.blur}
          onItemChange={onChangeBlur}
          fieldType="blur"
        />
      );
      break;
    case "line":
      displayView = (
        <div style={{ marginBottom: 80}}>
          <ColorSection style={{paddingBottom: "1.5em"}}>
            <Title>Line Color</Title>
            <PopoverColorBox
              value={styles?.color1}
              updateFieldStyles={onChangeColorOne}
              onDebounce={onColorOneDebounce}
            />
          </ColorSection>

          <div style={{paddingBottom: "1.5em"}}>
            <RangeSlider
              title="Thickness"
              value={styles?.lineThickness}
              max={lineThicknessForRange.max}
              min={lineThicknessForRange.min}
              step={1}
              stableValue={stableStyles?.lineThickness}
              onItemChange={onChangeLineThickness}
              fieldType="lineThickness"
            />
          </div>

          <SelectionBox
            title="Line Style"
            selected={styles?.lineStyle}
            onSelect={onChangeLineStyle}
            options={lineStyles}
          />

          {styles?.lineType !== "line" ? (
            <ColorSection style={{padding: "1.5em 0"}}>
              <Title>Marker Color</Title>
              <PopoverColorBox
                value={styles?.color2}
                updateFieldStyles={onChangeMarkerColor}
                onDebounce={markerColorDebounce}
              />
            </ColorSection>
          ) : undefined}
        </div>
      );
      break;
    case "rotate":
      displayView = <EditRotate value={styles?.rotate} />;
      break;
    case "layer":
      displayView = <ZIndex />;
      break;
    case "animation":
      displayView = <ApplyAnimations />;
      break;
    case "shadow":
      displayView = <ShapeShadow styles={styles}/>;
      break;
    default:
  }

  const onClickedColor = () => {
    setOpenDrawer({status: true, type: "color", height: 180});
  };

  const onClickedStroke = () => {
    setOpenDrawer({status: true, type: "stroke", height: 180});
  };

  const onClickedOpacity = () => {
    setOpenDrawer({status: true, type: "opacity", height: 90});
  };

  const onClickedBlur = () => {
    setOpenDrawer({status: true, type: "blur", height: 90});
  };

  const onClickedBorderRadius = () => {
    setOpenDrawer({status: true, type: "borderRadius", height: 90});
  };

  const onClickedLine = () => {
    setOpenDrawer({status: true, type: "line", height: 200});
  };

  const onClickedShadow = () => {
    setOpenDrawer({status: true, type: "shadow", height: 150});
  }

  const onSelectRotate = () => {
    setOpenDrawer({status: true, type: "rotate", height: 80});
  };

  const onSelectLayer = () => {
    setOpenDrawer({status: true, type: "layer", height: 80});
  };

  const onSelectAnimations = () => {
    setOpenDrawer({
      status: true,
      type: "animation",
      height: (window.innerHeight * 35) / 100,
    });
  };

  // () => updateEditingPanel(50, "blur")

  return (
    <>
      <Items>
        <Item onClick={onClickedColor}>
          <Title>
            <div
              style={{
                borderRadius: "50%",
                width: 18,
                height: 18,
                background: styles?.color1,
              }}
            />
          </Title>
        </Item>
        <Item onClick={onClickedStroke}>
          <Title>Stroke</Title>
        </Item>
        {name === "rectangle" ||
        name === "rounded-border-rectangle1" ||
        name === "rounded-border-rectangle2" ? (
          <Item onClick={onClickedBorderRadius}>
            <Title>Rounded Corners</Title>
          </Item>
        ) : undefined}
        <Item onClick={onClickedOpacity}>
          <Title>Opacity</Title>
        </Item>
        <Item onClick={onClickedBlur}>
          <Title>Blur</Title>
        </Item>

        <Item onClick={onClickedShadow}>
          <Title>Shadow</Title>
        </Item>

        {subtype === "line" ? (
          <Item onClick={onClickedLine}>
            <Title>Line</Title>
          </Item>
        ) : undefined}

        <LockField />

        <Item onClick={onSelectRotate}>
          <Title>Rotate</Title>
        </Item>

        <Item onClick={onSelectLayer}>
          <Title>Layer</Title>
        </Item>

        <Item onClick={onSelectAnimations}>
          <Title>Animate</Title>
        </Item>

        {/* <Item
          style={{padding: "0 10px 3px 0"}}
          onClick={() => updateEditingPanel(0, "")}
        >
          <Title> <MobLockPosition /> </Title>
        </Item> */}
      </Items>

      <MobileDrawer
        open={openDrawer.status}
        height={openDrawer.height}
        onClose={handleDrawerClose}
      >
        <DrawerLayout>{displayView}</DrawerLayout>
      </MobileDrawer>
    </>
  );
};

const ColorSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    stableStyles: selectCachedFieldStyles(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateEditingPanel,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ShapePanel);
