import React, {useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {
  changeSlide,
  changeSlideByID,
} from "../../../store/actions/template/slide.action";
import {
  selectActiveSlide,
  selectTotalSlides,
} from "../../../store/selectors/template/slide.selector";
import {
  Duplicate,
  EnterFullScreen,
  LeftArrow,
  RightArrow,
} from "../../../../oat-svg-icons";
import {grey4} from "../../../../oat-color-variables";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import PresentSlideList from "./PresentSlideList";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../store/selectors/template/template.selector";

const MobileViewSlideNav = ({
  slides,
  activeSlide,
  changeSlide,
  slideLength,
  setIsFullscreen,
  changeSlideByID,
  tempScale,
  canvasSize,
}) => {
  const [showSlideList, setShowSlideList] = useState(false);
  const windowSize = getWindowSize();

  const onBackwardSlide = () => {
    if (activeSlide === 0) return;
    changeSlide(activeSlide - 1);
  };

  const onForwardSlide = () => {
    if (activeSlide === slideLength - 1) return;
    changeSlide(activeSlide + 1);
  };

  const onDisplaySlideList = () => {
    setShowSlideList(!showSlideList);
  };

  const handleSlideChange = (value) => {
    changeSlideByID(value);
  };

  return (
    <Layout
      style={{top: windowSize.height / 2 + (canvasSize.h * tempScale) / 2}}
    >
      <SlideBar style={{width: windowSize.width}}>
        {!document.fullscreenElement ? (
          <>
            <div style={{flex: 1}}>
              <WrapSvg onClick={onDisplaySlideList}>{Duplicate}</WrapSvg>
            </div>

            <Wrapper style={{justifyContent: "space-around"}}>
              <WrapSvg 
                edge={activeSlide === 0} 
                onClick={onBackwardSlide}
              >
                {LeftArrow}
              </WrapSvg>
              <Text style={{color: "#fff"}}>
                {activeSlide + 1 + ` / ` + slideLength}
              </Text>
              <WrapSvg
                edge={slideLength - 1 === activeSlide}
                onClick={onForwardSlide}
              >
                {RightArrow}
              </WrapSvg>
            </Wrapper>

            {!document.fullscreenElement ? (
              <FullscreenButton onClick={setIsFullscreen}>
                <WrapFullscreenSvg>{EnterFullScreen}</WrapFullscreenSvg>
              </FullscreenButton>
            ) : undefined}
          </>
        ) : undefined}

        {document.fullscreenElement ? (
          <Wrapper>
            <WrapSvg
              style={{fill: activeSlide === 0 ? "#3e3e3e" : "#fff"}}
              onClick={onBackwardSlide}
            >
              {LeftArrow}
            </WrapSvg>
            <Text
              style={{
                justifyContent: "center",
                width: 90,
                color: "#fff",
              }}
            >
              {activeSlide + 1 + ` / ` + slideLength}
            </Text>
            <WrapSvg
              style={{
                fill: slideLength - 1 === activeSlide ? "#3e3e3e" : "#fff",
              }}
              onClick={onForwardSlide}
            >
              {RightArrow}
            </WrapSvg>
          </Wrapper>
        ) : undefined}
      </SlideBar>

      {showSlideList ? (
        <PresentSlideList
          showLayout={true}
          onSlideChange={handleSlideChange}
          slides={slides}
          styles={{top: 50}} // slide bar nav's height
          columnsPerScreen={4}
        />
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
`;

const SlideBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #232323;
  font-size: 1rem;
  height: 50px;
  padding: 0 1.1rem;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 2;
  width: 130px;
  align-items: center;
  fill: #d0c9ff;
  max-width: 130px;
`;

const WrapSvg = styled.div`
  width: 16px;
  height: 16px;
  fill: ${({edge}) => (!edge ? "#fff" : grey4)};
  cursor: ${({edge}) => (!edge ? "pointer" : "not-allowed")};
`;

const Text = styled.p`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  user-select: none;
`;

const FullscreenButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

const WrapFullscreenSvg = styled.div`
  width: 32px;
  height: 32px;
  fill: #a6acbf;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeSlide: selectActiveSlide(designTemplate),
    slideLength: selectTotalSlides(designTemplate),
    canvasSize: selectTemplateSize(designTemplate),
  };
};

export default connect(mapStateToProps, {changeSlide, changeSlideByID})(
  MobileViewSlideNav
);
