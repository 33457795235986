import React, {useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {
  closeDashboardModal,
  openDashboardModal,
} from "../../../store/actions/dashboard.action";
import {selectWorkspaceItemActions} from "../../../store/actions/workspace.action";
import {
  selectActiveWorkSpace,
  selectDashboardModal,
} from "../../../store/selectors/userDashboard.selector";
import {mainHover} from "../../../userDashboardStyles";
import {sidebarHeader} from "../reusableStyles/sidebarItem";
import WorkspaceModals from "./WorkspaceModals";
import { useLocation, useNavigate } from "react-router-dom";
// import WorkspaceOptions from "./WorkspaceOptions";

const ActiveWorkspace = ({
  user,
  workspace,
  modal,
  closeDashboardModal,
  openDashboardModal,
}) => {
  // const wsListpopperRef = useRef();
  // workspace list
  // const [openWSPopper, setOpenWSPopper] = useState(false);
  let navigate = useNavigate();

  const handlePopperOpen = () => {
    if (workspace.ID) {
      // setOpenWSPopper(true);
      openDashboardModal({
        modalName: "workspace-setting",
        type: "workspace",
      });
    }
  };

  // const closeAllPoppers = () => {
  //   setOpenWSPopper(false);
  // };

  // detectClickOutside(wsListpopperRef, () => closeAllPoppers());

  const closeModal = () => {
    closeDashboardModal(); // empty
    navigate(``, { replace: true });
  };

  // useEffect(() => {
  //   closeAllPoppers();
  // }, [workspace.ID]);

  const query = new URLSearchParams(useLocation().search);
  const toUpgrade = query.get("upgrade") == "true" ? true : false;

  useEffect(() => {
    if (toUpgrade) {
      openDashboardModal({
        modalName: "workspace-setting",
        type: "workspace",
      });
    } else if (modal.name === "workspace-setting") {
      closeDashboardModal(); // empty
      navigate(``, { replace: true });
    }
  },[toUpgrade])

  return (
    <Layout>
      <WorkspaceHeader onClick={handlePopperOpen}>
        <p>{workspace.name ? "  " + workspace.name : undefined}</p>
      </WorkspaceHeader>

      {/* {openWSPopper ? (
        <Popper
          width={235}
          height="auto"
          offset={{x: 360, y: 72}}
          onClose={closeAllPoppers}
        >
          <WorkspaceOptions
            owner={workspace.owner === user.username}
            userRole={workspace.role}
          />
        </Popper>
      ) : undefined} */}

      {modal.status && modal.type === "workspace" ? (
        <WorkspaceModals modalName={modal.name} close={closeModal} />
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
`;

const WorkspaceHeader = styled.div`
  font-size: 1.01rem;
  font-weight: 700;
  ${sidebarHeader};
  background: #e7e6ff; // ${mainHover};
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  cursor: pointer;
  left: 0;
  box-shadow: 1px 1px 6px 0px #f7f0f059;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
    modal: selectDashboardModal(state.userDashboard),
  };
};

export default connect(mapStateToProps, {
  selectWorkspaceItemActions,
  closeDashboardModal,
  openDashboardModal,
})(ActiveWorkspace);
