import React from "react";
import {connect} from "react-redux";
import RangeSlider from "../../../shared/inputs/RangeSlider";
import {updateFieldStyles} from "../../../../../../../store/actions/fields/common.action";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import { selectTempScale } from "../../../../../../../store/selectors/template/template.selector";

const EditChartLine = ({value, updateFieldStyles, slideID, fieldID, tempScale}) => {
  const handleUpdate = (item) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type: "lineSize",
      value: Number(item),
    });
  };
  return (
    <RangeSlider
      title="Line Size"
      value={value}
      max={Math.ceil(10 / tempScale)}
      min={1 / tempScale}
      step={(1 * Math.ceil(10 /tempScale)) / 100}
      onItemChange={handleUpdate}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate)
  };
};
export default connect(mapStateToProps, {updateFieldStyles})(EditChartLine);
