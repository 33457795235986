import React, {useRef, useState} from "react";
import {createPortal} from "react-dom";
import styled from "styled-components";
import {getWindowSize} from "../../../../../oat-window-size/getWindowSize";

const EditTextTableContents = ({close, children}) => {
  const layoutRef = useRef();
  const windowSize = getWindowSize();

  const handleClose = (event) => {
    close();
    // important, else penetrating button behind it
    event.stopPropagation();
    event.preventDefault();
  };

  return createPortal(
    <>
      <BackLayer
        style={{
          width: "100vw",
          height: windowSize.height,
          opacity: 1,
        }}
      ></BackLayer>
      <Wrapper
        ref={layoutRef}
        style={{
          // overflowY: "scroll",
          background: "#2b29379e",
          zIndex: 99999,
          height: windowSize.height,
        }}
      >
        <DrawerNavPanel>
          <div
            className="drawer-nav-panel__done"
            onClick={handleClose}
            onTouchEnd={handleClose}
            style={{
              position: "fixed",
              top: 0,
              zIndex: 9999,
              width: "100%",
              height: 48,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              background: "#2b2937",
            }}
          >
            Done
          </div>
        </DrawerNavPanel>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "18px",
            textAlign: "center",
            fontSize: "1.8rem",
          }}
        >
          {children}
        </div>
      </Wrapper>
    </>,
    document.getElementById("edit-mobile-text-field-content")
  );
};

const BackLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #1c1c1ce6;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1111;
  background: ${({theme}) => theme.deskSecondaryBg2};
  -webkit-backdrop-filter: blur(2.8px);
  backdrop-filter: blur(2.8px);
  color: ${({theme}) => theme.deskPrimaryTxt};
  box-sizing: border-box;
  font-size: 1.1rem;
  transition: all 0.4s ease-in-out;
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .drawer-container {
    height: 100%;
    margin-bottom: 32px;
  }
`;

const DrawerNavPanel = styled.div`
  position: relative;
  padding: 20px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 8px;
  box-sizing: border-box;
  color: ${({theme}) => theme.deskPrimaryTxt};
  .drawer-nav-panel__done {
    font-weight: 700;
  }
`;

export default EditTextTableContents;
