import React, {useState} from "react";
import {Button, ButtonSpinner, ShowError} from "../../../../oat-ui";
import {Field, Value} from "./DisplayUserInfo";
import {
  FieldName,
  Footer,
  BottomSection,
  Header,
  ContentSection,
  Cancel,
  TextField,
} from "../../reuse/dashboardUI/modalStyles";
import {Auth} from "aws-amplify";

const EditUserName = ({name, handleFieldChange, onCancel}) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({status: false, message: null});

  const onNameChange = (e) => {
    if (name.length < 45) {
      handleFieldChange(e, "name");
    }
  };

  const handleSubmit = async () => {
    if (error.status) {
      setError({status: false, message: null});
    }
    if (!processing) {
      setProcessing(true);
      const user = await Auth.currentAuthenticatedUser();
      const transformedName = name.replace(/\b\w/g, (match) => match.toUpperCase());
      await Auth.updateUserAttributes(user, {
        name: transformedName,
      }).catch((err) => {
        setError({status: true, message: err.message});
        setProcessing(false);
      });
      onCancel("display-user-info");
    }
  };

  const handleCancel = () => {
    onCancel("display-user-info");
  };

  return (
    <>
      <ContentSection>
        <Header>Edit Name</Header>
        <Field>
          <FieldName>Name</FieldName>
          <Value>
            <TextField value={name} onChange={onNameChange} />
          </Value>
        </Field>

        {error.status ? <ShowError message={error.message} /> : undefined}
      </ContentSection>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Cancel onClick={handleCancel}>Cancel</Cancel>
          <div style={{width: 120, height: "100%"}}>
            <Button onClick={handleSubmit} disabled={processing}>
              {!processing ? "Update" : <ButtonSpinner />}
            </Button>
          </div>
        </BottomSection>
      </Footer>
    </>
  );
};

export default EditUserName;
