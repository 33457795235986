import React from "react";
import styled from "styled-components";
import DropDown from "../../../../../../../ui/inputs/DropDown";
import {InputName} from "../../common/WrapColorStyles";
import {updateFieldStyles} from "../../../../../../../store/actions/fields/common.action";
import {connect} from "react-redux";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";

const positions = [
  {display: "Top", value: "top"},
  {display: "Right", value: "right"},
];

const LegendPosition = ({
  value,
  updateFieldStyles,
  slideID,
  fieldID,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const changeValue = (value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type: "legendPosition",
      value,
    });
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <div style={{display: "flex"}}>
      <InputName>Position</InputName>
      <WrapDropDown>
        <DropDown
          onSelect={changeValue}
          selected={value}
          options={positions}
          minWidth={100}
          overflowY="scroll"
          height={"auto"}
        />
      </WrapDropDown>
    </div>
  );
};

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(LegendPosition);
