import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {
  selectActiveFieldProps,
  selectEnableTextEdit,
} from "../../../../../../store/selectors/fields.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import RangeSlider from "../../shared/inputs/RangeSlider";
import LayoutPosition from "../common/LayoutPosition";
import {SplitLine} from "../common/SplitLine";
import TextAlign from "../common/TextAlign";
import {InputName, WrapColorBox} from "../common/WrapColorStyles";
import TextEffects from "./TextEffects";
import SelectFontSize from "../common/SelectFontSize";
import SelectFontFamily from "../common/fontFamily/SelectFontFamily";
import {updateTextContent} from "../../../../../../store/actions/fields/text.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import ItalicBold from "../common/fontFamily/ItalicBold";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectTemplate} from "../../../../../../store/selectors/template/template.selector";
import TextBackground from "./TextBackground";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {PanelLayout} from "../panelStyles";
import EditOpacity from "../common/EditOpacity";
import {
  handleContentChange,
  moveContentBefore,
  wrapRangeInNestedWordSpan,
  wrapRangeInWordSpan,
} from "../../../../../../utils/rangeSelector";
import SelectUnOrderedListBullet from "./SelectUnOrderedListBullet";
import TextDecoration from "./TextDecoration";
import InsertLink from "./InsertLink";
import Switch from "../../shared/inputs/Switch";
import AddLink from "../common/AddLink";

export const textEditProps = {
  fontSize: {
    min: 10,
    max: 180,
    step: 1,
  },
  letterSpacing: {
    min: -3,
    max: 20,
    step: 0.2,
  },
  lineHeight: {
    min: 0.4,
    max: 3.5,
    step: 0.1,
  },
  borderRadius: {
    min: 1,
    max: 60,
    step: 1,
  },
};

const EditText = ({
  slideIndex,
  activeField,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateTextContent,
  isBuildingTheme,
  template,
  updateFieldCollaboration,
  editingText,
}) => {
  // *** CHANGES MADE HERE SHOULD ALSO REFLECT IN MOBILE ONES ***
  const {styles} = activeField || {};
  const [color, setColor] = useState(styles?.color1);
  const [color2, setColor2] = useState(styles?.color2);
  const active = {slideID: slideIndex, fieldID: activeField?.key};
  const activeSlideFieldInfo = {
    slideIndex: slideIndex,
    fieldID: activeField?.key,
  };

  const handleColorChange = async (value) => {
    const sel = window.getSelection();
    if (!sel || sel.isCollapsed) {
      // const spanTagsInside = document.querySelectorAll(".innerTextStyle");
      let spanTagsInside = document.createElement('div');
      spanTagsInside.innerHTML = activeField?.content;
      // Remove the 'style' attribute from the span element

      const elementsWithClassName = spanTagsInside.querySelectorAll('.innerTextStyle');
      elementsWithClassName.forEach(function(element) {
        // Remove the 'style' attribute from each element
        // Get the current style attribute value
        let currentStyle = element.getAttribute('style');
        // Create a new style string without the 'color' property
        let newStyle = currentStyle.replace(/color\s*:\s*[^;]*;?/gi, '');
        // Set the updated style attribute value
        element.setAttribute('style', newStyle.trim());
      });

      setColor(value);
      await handleUpdates("color1", value);
      await updateTextContent(activeSlideFieldInfo, spanTagsInside.innerHTML);
      return;
    } else if (!sel.isCollapsed) {
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0).cloneRange();
        if (range.collapsed) {
          return;
        }

        const parentElement =
          sel.getRangeAt(0).commonAncestorContainer.parentElement;
        // first pass
        wrapRangeInWordSpan(range, "color", value);

        // second pass, find the nested .innerTextStyle
        // and wrap the content before and after it in their own spans
        const inner = document.querySelector(".innerTextStyle .innerTextStyle");
        const classNameAlreadyExistsWithLink = document.querySelector(".innerTextStyle .innerTextLink");
        if (!inner) {
          // when selecting two .innerTextStyle start to end, where the empty spans stick around
          // we remove them here
          range.startContainer.parentNode
            .querySelectorAll(".innerTextStyle:empty")
            .forEach((node) => node.remove());

          const updatedContent = await handleContentChange(
            range,
            activeField?.subtype
          );

          await updateTextContent(activeSlideFieldInfo, updatedContent);
          await storeFieldUndoRedo();
          await updateFieldCollaboration();
          // window.getSelection().removeAllRanges();
          // window.getSelection().addRange(range);

          const newRange = document.createRange();
          // Identify the new start and end positions after color modification
          const newStartContainer = range.startContainer;
          const newEndContainer = range.endContainer;
          // Create a new range and set it to the desired portion
          newRange.setStart(newStartContainer, range.startOffset);
          newRange.setEnd(newEndContainer, range.endOffset);
          // Set the start and end points of the range to select the current selection
          sel.removeAllRanges();
          sel.addRange(newRange);
          setColor(value);
        } else {
          const parent = inner.closest(".innerTextStyle:not(:scope)");
          const extractingRange = document.createRange();
          // wrap the content before
          extractingRange.selectNode(parent);
          extractingRange.setEndBefore(inner);

          const payload = {
            "font-style": parentElement.style?.fontStyle, //"italic",
            "font-weight": parentElement.style?.fontWeight,
            color: parentElement.style?.color,
          };
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // wrap the content after
          extractingRange.selectNode(parent);

          // Capture the selection offsets
          // Code order matters here
          const selectionStartOffset = extractingRange.startOffset;
          const selectionEndOffset = extractingRange.endOffset;

          extractingRange.setStartAfter(inner);
          wrapRangeInNestedWordSpan(extractingRange, payload);

          // finally, extract all the contents from parent
          // to its own parent and remove it, now that it's empty
          moveContentBefore(parent);

          const updatedContent = await handleContentChange(
            range,
            activeField?.subtype
          );
          setColor(value);
          await updateTextContent(activeSlideFieldInfo, updatedContent);

          const newRange = document.createRange();
          // Identify the new start and end positions after color modification
          const newStartContainer = range.startContainer;
          const newEndContainer = range.endContainer;
          // Create a new range and set it to the desired portion
          newRange.setStart(newStartContainer, selectionStartOffset);
          newRange.setEnd(newEndContainer, selectionEndOffset);
          // Set the start and end points of the range to select "World"
          sel.removeAllRanges();
          sel.addRange(newRange);
        }
      }
    }
    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  const handleUpdates = (type, value) => {
    updateFieldStyles({
      active,
      type,
      value,
    });
  };

  const changeFontSize = async (value) => {
    await handleUpdates("fontSize", value);
    await updateFieldCollaboration();
  };

  const changeFontFamily = (value) => {
    handleUpdates("fontFamily", value);
  };

  const changeTextAlign = async (value) => {
    await handleUpdates("textAlign", value);
    storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  const changeTextDecoration = async (value) =>  {
    await handleUpdates("textDecoration", value);
    storeFieldUndoRedo();
    await updateFieldCollaboration();
  }

  const changeLetterSpacing = (value) => {
    handleUpdates("letterSpacing", Number(value));
  };

  const changeLineHeight = (value) => {
    handleUpdates("lineHeight", Number(value));
  };

  const handleListBgColorChange = (value) => {
    handleUpdates("bg1", value);
  };

  const onColorDebounce = (value) => {
    const selection = window.getSelection();
    // only debounce on full color text change
    // partial text selection will not be debouncing
    // it will only emit updated content
    if (selection.isCollapsed) {
      storeFieldUndoRedo();
      updateFieldCollaboration();
    }
  };

  useEffect(() => {
    setColor(styles.color1);
    setColor2(styles.color2);
  }, [activeField?.key]);

  const toggleDisableApplyingTheme = async (value) => {
    handleUpdates("disableApplyingTheme", value);
    await updateFieldCollaboration();
  }

  // const [hasSelection, setHasSelection] = useState(false);
  // useEffect(() => {
  //   function handleSelectionChange() {
  //     const selection = window.getSelection();
  //     if (selection.rangeCount === 0 || selection.isCollapsed) {
  //       setHasSelection(false);
  //     } else {
  //       setHasSelection(true);
  //     }
  //   }

  //   document.addEventListener("mouseup", handleSelectionChange);
  //   return () => {
  //     document.removeEventListener("mouseup", handleSelectionChange);
  //   };
  // }, [editingText]);

  return (
    <PanelLayout>
      {/* {!hasSelection ? ( */}
      <>
        <LayoutPosition rotate={styles?.rotate} locked={styles?.lock} />
        <SplitLine />
        {/* {activeField.subtype === "link" ? (
          <>
            <AddLink />
            <SplitLine />
          </>
        ) : undefined} */}
        <WrapColorBox style={{paddingBottom: "2em"}}>
          <InputName>Color</InputName>
          <PopoverColorBox
            value={color}
            updateFieldStyles={handleColorChange}
            onDebounce={onColorDebounce}
          />
          {/* {enableGradient ? <PopoverColorBox
            value={color2}
            updateFieldStyles={handleColor2Change}
            onDebounce={onColorDebounce}
          /> : undefined} */}
        </WrapColorBox>

        {activeField?.subtype === "listContentWithBackground" ? (
          <WrapColorBox style={{paddingBottom: "2em"}}>
            <InputName>List Background</InputName>
            <PopoverColorBox
              value={activeField?.styles?.bg1}
              updateFieldStyles={handleListBgColorChange}
              onDebounce={onColorDebounce}
            />
          </WrapColorBox>
        ) : undefined}

        <div style={{paddingBottom: "2rem"}}>
          <SelectFontSize
            value={styles?.fontSize}
            onSelect={changeFontSize}
            scale={styles?.scale}
          />
        </div>

        <div style={{paddingBottom: "2rem"}}>
          <SelectFontFamily
            fontFamily={styles?.fontFamily}
            fontWeight={styles?.fontWeight}
            fontStyle={styles?.fontStyle}
            onSelect={changeFontFamily}
          />
        </div>

        <div style={{paddingBottom: "2rem"}}>
          <ItalicBold />
        </div>

        <div style={{paddingBottom: "2em", display: "flex"}}>
          <TextAlign onSelect={changeTextAlign} />
        </div>

        <div style={{paddingBottom: "2em", display: "flex"}}>
          <TextDecoration onSelect={changeTextDecoration} />
        </div>

        {/* <div style={{paddingBottom: "2rem"}}>
          <InsertLink />
        </div> */}

        <div style={{paddingBottom: "2rem"}}>
          <RangeSlider
            title="Spacing"
            fieldType="letterSpacing"
            value={styles?.letterSpacing}
            max={textEditProps.letterSpacing.max}
            min={textEditProps.letterSpacing.min}
            step={textEditProps.letterSpacing.step}
            onItemChange={changeLetterSpacing}
          />
        </div>

        <div style={{paddingBottom: "2rem"}}>
          <RangeSlider
            title="Line Height"
            fieldType="lineHeight"
            value={styles?.lineHeight}
            max={textEditProps.lineHeight.max}
            min={textEditProps.lineHeight.min}
            step={textEditProps.lineHeight.step}
            onItemChange={changeLineHeight}
          />
        </div>
       
        <div>
          <EditOpacity value={styles?.opacity} />
        </div>

        {/* <SplitLine />
      <TextBackground /> */}

        {activeField?.subtype === "bulletContent" ? (
          <>
            <SplitLine />
            <div>
              <SelectUnOrderedListBullet />
            </div>
          </>
        ) : undefined}

        <SplitLine />
        <TextEffects effects={activeField?.effects} />

        <SplitLine />

        <div style={{paddingBottom: "2rem"}}>
          <AddLink />
        </div>

        <div style={{paddingBottom: "2rem"}}>
          <Switch
            title="Disable Theme"
            value={styles?.disableApplyingTheme}
            checkValue={styles?.disableApplyingTheme}
            onItemChange={toggleDisableApplyingTheme}
            checked={styles?.disableApplyingTheme}
          />
        </div>
      </>
      {/*   ) : (
        <>
          <WrapColorBox style={{paddingBottom: "2em"}}>
            <InputName>Color</InputName>
            <PopoverColorBox
              value={color ? color : "#232323"}
              updateFieldStyles={handleColorChange}
              onDebounce={onColorDebounce}
            />
          </WrapColorBox>
          <div style={{paddingBottom: "2rem"}}>
            <ItalicBold />
          </div>
        </>
      )} */}
    </PanelLayout>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveFieldProps(designTemplate),
    slideIndex: selectActiveSlide(designTemplate),
    template: selectTemplate(designTemplate),
    editingText: selectEnableTextEdit(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  updateTextContent,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditText);
