import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {
  black1,
  grey5,
  greyText2,
  greyText3,
  paleBlue4,
  primaryBlack,
} from "../../../../../oat-color-variables";
import {selectActiveWorkSpace} from "../../../../store/selectors/userDashboard.selector";
import {Spinner} from "../../../../../oat-ui";
import {CollaboratorAccessLevel, Header} from "../workspaceStyles";
import UpdatePaymentDetails from "./UpdatePaymentDetails";

const WorkspaceInvoice = ({workspace, user}) => {
  const [billingInfo, setBillingInfo] = useState({
    customer: {},
    upcomingInvoice: {},
    receipts: [],
  });
  const [selectedTab, setSelectedTab] = useState("upcoming-invoice");
  const [fetching, setFetching] = useState({
    status: true,
    error: false,
  });

  useEffect(() => {
    if (workspace.role === "admin" || workspace.role === "owner") {
      setFetching({status: true, error: false});
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user}/workspaces/${workspace.ID}/payments/invoices`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setBillingInfo({
              customer: {}, //data.response.customer,
              upcomingInvoice: data.response.upcomingInvoice,
              receipts: data.response.invoices,
              subscriptionID: data.response.subscriptionID,
              paymentMethod: data.response.paymentMethod,
              remainingBalance: Math. abs(data.response.remainingBalance) / 100
            });
            setFetching({status: false, error: false});
          } else {
            setFetching({status: false, error: true});
          }
        })
        .catch((error) => {
          setFetching({status: false, error: true});
        });
    }
  }, []);

  const {upcomingInvoice, receipts} = billingInfo;

  const transformDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  };

  return (
    <Layout>
      {workspace.role === "owner" || workspace.role === "admin" ? (
        <>
          <Tabs>
            <Tab
              onClick={() => setSelectedTab("upcoming-invoice")}
              active={selectedTab === "upcoming-invoice"}
            >
              Upcoming Invoice
            </Tab>
            <Tab
              onClick={() => setSelectedTab("receipts")}
              active={selectedTab === "receipts"}
            >
              Receipts
            </Tab>
            <Tab
              onClick={() => setSelectedTab("update-card")}
              active={selectedTab === "update-card"}
            >
              Update Card Info
            </Tab>
          </Tabs>

          {selectedTab === "upcoming-invoice" ? (
            <div>
              <Header>Invoices</Header>

              {billingInfo?.remainingBalance && billingInfo?.remainingBalance > 0 ? (
                <RemainingBalance>
                  <h3>Remaining Balance: <span>{billingInfo.remainingBalance}</span></h3>
                  <p style={{color: '#545151', marginBottom: 12}}>If you have a remaining balance, the total amount of upcoming invoice will be subtracted from it.</p>
                </RemainingBalance> )
              : undefined}

              {fetching.status ? (
                <div style={{marginTop: 80}}>
                  <Spinner />
                </div>
              ) : undefined}

              {!fetching.status && Object.keys(upcomingInvoice).length > 1 ? (
                <DisplayInfo>
                  {/* <div className="billing-customer">
                    <div className="billing-customer__info">
                      <div>{customer.customer_name}</div>
                      <div>{customer.customer_email}</div>
                    </div> 
                  </div> */}

                  {upcomingInvoice.lines.data &&
                    upcomingInvoice.lines.data.map((data, i) => {
                      const startDate = transformDateFormat(
                        data.period.start * 1000
                      );
                      return (
                        <WrapTable key={i}>
                          <table key={i} className="billing-items">
                            <thead>
                              <tr>
                                <th style={{flex: 2}}>Upcoming</th>
                                <th>Quantity</th>
                                <th>Discount</th>
                                <th>Interval</th>
                                <th>SubTotal</th>
                                <th>Tax</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td style={{flex: 2}}>{startDate}</td>
                                <td>{data.quantity}</td>
                                <td>
                                  {data.discount_amounts &&
                                  data.discount_amounts.length > 0
                                    ? data.discount_amounts[0]?.amount / 100
                                    : "-"}
                                </td>
                                <td>{data.plan.interval}</td>
                                <td>{data.amount_excluding_tax / 100}</td>
                                <td>
                                  {data.tax_amounts.length > 0
                                    ? data.tax_amounts[0]?.amount / 100
                                    : 0}
                                </td>
                                <td>{upcomingInvoice.total / 100}</td>
                              </tr>
                            </tbody>
                          </table>
                        </WrapTable>
                      );
                    })}

                  <GapLine />
                </DisplayInfo>
              ) : undefined}

              {!fetching.status && Object.keys(upcomingInvoice).length === 0 ? (
                <Message>You have no invoice yet.</Message>
              ) : undefined}
            </div>
          ) : undefined}

          {selectedTab === "receipts" ? (
            <div>
              <Header>Receipts</Header>

              {!fetching.status && receipts.length !== 0 ? (
                <DisplayInfo>
                  {/* <div className="billing-customer">
                    <div className="billing-customer__info">
                      <div>{customer.customer_name}</div>
                      <div>{customer.customer_email}</div>
                    </div>
                  </div> */}

                  {receipts &&
                    receipts.map((data, i) => {
                      const startDate = transformDateFormat(data.createdAt * 1000);
                      return (
                        <WrapTable key={i} style={{ marginTop: 32 }}>
                          <table key={i} className="billing-items">
                            <thead>
                              <tr>
                                <th style={{flex: 2}}>Date</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Type</th>
                                <th>Discount</th>
                                <th>Tax</th>
                                <th>SubTotal</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td style={{flex: 2}}>{startDate}</td>
                                <td>{data.price / 100}</td>
                                <td>{data.quantity}</td>
                                <td style={{textTransform: "capitalize"}}>
                                  {data.productName}
                                </td>
                                <td>{data.amount_discount}</td>
                                <td>{data.amount_tax / 100}</td>
                                <td>{data.subtotal / 100}</td>
                                <td>{data.total / 100}</td>
                              </tr>
                            </tbody>
                          </table>
                        </WrapTable>
                      );
                    })}

                  <GapLine />
                </DisplayInfo>
              ) : undefined}

              {!fetching.status && receipts.length === 0 ? (
                <Message>You have no receipt yet.</Message>
              ) : undefined}
            </div>
          ) : undefined}

          {selectedTab === "update-card" ? (
            <UpdatePaymentDetails
              username={user}
              workspaceRole={workspace.role}
              workspaceID={workspace.ID}
              subscriptionID={billingInfo.subscriptionID}
              paymentMethod={billingInfo.paymentMethod}
            />
          ) : undefined}
        </>
      ) : (
        <CollaboratorAccessLevel>
          Collaborator cannot access billing info.
        </CollaboratorAccessLevel>
      )}
    </Layout>
  );
};

export const Layout = styled.div`
  color: ${black1};
  @media only screen and (max-width: 600px) {
    padding-bottom: 200px;
  }
`;

export const DisplayInfo = styled.div`
  font-size: 0.9rem;
  .billing-customer {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 34px;
    &__info {
      display: flex;
      flex-direction: column;
    }
  }
  .billing-items {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    tr {
      display: flex;
      th {
        flex: 1;
        text-align: left;
        color: ${greyText2};
        min-width: 120px;
      }
      td {
        flex: 1;
        padding: 12px 0 12px 10px;
        min-width: 120px;
        box-sizing: border-box;
        &:first-child {
          padding: 12px 0;
        }
      }
    }
  }
  .billing-summary {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 24px;
    &__currency {
      span {
        text-transform: uppercase;
      }
    }
    &__total {
      width: 180px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      h4 {
        font-size: 1.1rem;
        font-weight: 700;
        margin: 34px 0 16px;
      }
    }
  }
`;

export const GapLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 12px 0 28px;
  @media only screen and (min-width: 601px) {
    background: ${grey5};
  }
`;

const WrapTable = styled.div`
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 2px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 6px;
  }
  table {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media only screen and (min-width: 601px) {
    table {
      width: 700px;
    }
  }
  @media only screen and (max-width: 600px) {
    table {
      width: 540px;
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  padding-bottom: 18px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.div`
  padding: 5px 18px;
  flex: 0 0 auto;
  font-size: 0.84rem;
  color: ${black1};
  border-radius: 6px;
  background: ${({active}) => (active ? paleBlue4 : undefined)};
  cursor: pointer;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.95rem;
  height: 200px;
`;

const RemainingBalance = styled.div`
  h3 {
    font-size: 0.97rem;
    color: ${greyText3};
    margin: 10px 0px 0px;
  }
  p {
    font-size: 0.9rem;
    color: ${primaryBlack};
    margin: 8px 0 22px 0;
  }
`

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser).username,
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};
export default connect(mapStateToProps, null)(WorkspaceInvoice);
