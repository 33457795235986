const AnimateSvg = (
  <svg viewBox="0 0 24 24">
    <defs>
      <radialGradient
        id="animate-icon-radial-gradient"
        cx="23.38"
        cy="5.1"
        r="30.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="rgb(255 117 117)"></stop>
        <stop offset="0.51" stopColor="rgb(255 117 117)"></stop>
        <stop offset="0.58" stopColor="rgb(255 117 117)"></stop>
        <stop offset="0.67" stopColor="rgb(253 111 111)"></stop>
        <stop offset="0.78" stopColor="rgb(255 118 118)"></stop>
        <stop offset="0.88" stopColor="rgb(255 106 106)"></stop>
        <stop offset="0.98" stopColor="#fb7272"></stop>
      </radialGradient>
    </defs>
    <path d="M9.5,1.5h2.7l8.5,22.3H16.2L10.9,8.5,5.5,23.8H1ZM5.1,16.2H16.8v4H5.1Z"></path>
    <path
      d="M11.8.2h2.7L23,22.5H18.5L13.1,7.3,7.8,22.5H3.3ZM7.4,14.9H19.1V19H7.4Z"
      fill="url(#animate-icon-radial-gradient)"
    ></path>
    <polygon
      fill="#ffc9db"
      points="9.5 1.5 11.8 0.2 3.3 22.5 7.8 22.5 5.5 23.8 1 23.8 9.5 1.5"
    ></polygon>
    <polygon
      fill="#ffc9db"
      points="15.8 14.9 13.1 14.9 11.8 11.2 13.1 7.3 15.8 14.9"
    ></polygon>
    <polygon
      fill="#ffc9db"
      points="23 22.5 20.7 23.8 16.2 23.8 15 20.2 8.6 20.2 9 19 17.2 19 18.5 22.5 23 22.5"
    ></polygon>
  </svg>
);
export default AnimateSvg;
