import React from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../redux/user/authUser";
import Footer from "./Footer";
import NavBar from "./NavBar";

const LayoutHoc = ({children, authUser}) => {
  return (
    <div>
      <NavBar authUser={authUser} />
      {/* {authUser && authUser.loggedIn.status ? <SignedInNavBar /> : <NavBar />} */}
      {children}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(LayoutHoc);
