import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import {ExpandArrowForCanvas} from "../../../../../../../oat-svg-icons";
import SelectedShape from "../../../../canvasPanel/fields/shapes/SelectedShape";
import {
  PopUp,
  Selected,
  SelectionBox,
  Option,
} from "../../../../../../ui/inputs/DropDown";

const SelectOption = ({item, handleSelectItem, selected}) => {
  const onSelectItem = () => {
    handleSelectItem(item);
  };

  return (
    <Option active={selected === item?.value} onClick={onSelectItem}>
      <div style={{height: 30, padding: '0 12px 0 0px'}}>
        <SelectedShape
          shape={"line"}
          styles={{
            color1: "#9a9ad0ed",
            color2: "#9a9ad0",
            lineType: item?.value,
          }}
          index={item?.id + 'select-option'}
          size={{w: item?.size?.w, h: item?.size?.h}}
        />
      </div>
    </Option>
  );
};

const SelectLineTypes = ({
  onSelect,
  selected,
  options,
  height,
  overflowY,
  boxWidth,
}) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelectItem = (item) => {
    onSelect(item?.value);
    handleToggle();
  };

  return (
    <Wrapper>
      <SelectionBox onClick={handleToggle}>
        <Selected style={{height: 30}}>
          <SelectedShape
            shape="line"
            styles={{
              color1: "#9a9ad0",
              color2: "#9a9ad0",
              lineType: selected,
            }}
            index={"random1231a2"}
            size={{w: 60, h: 18}}
          />
        </Selected>
        <div style={{marginRight: 10, cursor: "pointer"}}>
          <div style={{width: 18, height: 18}}>{ExpandArrowForCanvas}</div>
        </div>
      </SelectionBox>
      {open ? (
        <PopUp ref={selectRef} style={{width: boxWidth, height, overflowY}}>
          {options && options.map((item, i) => (
            <SelectOption
              key={item?.id}
              item={item}
              handleSelectItem={handleSelectItem}
              selected={selected}
            />
          ))}
        </PopUp>
      ) : undefined}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  text-transform: capitalize;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

SelectLineTypes.defaultProps = {
  minWidth: 130,
  maxWidth: 130,
  height: "auto",
  overflowY: "auto",
  boxWidth: 130,
};

export default SelectLineTypes;
