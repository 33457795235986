import React from "react";
import styled from "styled-components";
import {paleBlue} from "../../oat-user-dashboard/userDashboardStyles";

const DisplayItem = ({item, addedItems, onSelect}) => {
  const handleSelect = () => {
    onSelect(item);
  };
  return (
    <Item selected={addedItems.includes(item.value)} onClick={handleSelect}>
      <p>{item.display}</p>
      <div className="multiple-select-hover-dot" />
    </Item>
  );
};

const MultipleSelect = ({items, selected, onSelect}) => {
  const handleSelection = (item) => {
    onSelect(item);
  };

  return (
    <Layout>
      {items && items.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <p>No Member</p>
        </div>
      ) : undefined}
      {items &&
        items.length > 0 &&
        items.map((item, index) => (
          <DisplayItem
            key={index}
            item={item}
            addedItems={selected}
            onSelect={handleSelection}
          />
        ))}
    </Layout>
  );
};

const Layout = styled.div`
  height: 124px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  overflow-y: scroll;
  box-shadow: 0px 0px 1px 1px #e1dfdf;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 142px;
  font-size: 0.85rem;
  margin-bottom: 8px;
  background: ${({selected}) => (selected ? paleBlue : undefined)};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  padding: 1px 2px 1px 5px;
  box-sizing: border-box;
  white-space: nowrap;
  &:hover {
    .multiple-select-hover-dot {
      display: block;
    }
  }
  .multiple-select-hover-dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 0 8px;
    background: #5090f6cf;
    display: none;
  }
`;

export default MultipleSelect;
