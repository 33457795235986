import React, {useCallback, useState, useEffect} from "react";
import {connect} from "react-redux";
import {
  getTemplateLayout,
  selectTempScale,
  selectTemplate,
  selectTemplateSize,
  selectTemplateStatus,
} from "../../../store/selectors/template/template.selector";
import {
  selectActiveField,
  selectActiveFieldProps,
  selectEditChartInfoStatus,
  selectEnableTextEdit,
  selectUndoRedoHistory,
  selectUndoRedoToEmitSocket,
} from "../../../store/selectors/fields.selector";
import {
  selectActiveSlide,
  selectActiveSlideID,
  selectActiveSlideIndex,
} from "../../../store/selectors/template/slide.selector";
import {
  selectMobEditingPanel,
  selectTooltipPopperStatus,
} from "../../../store/selectors/layout/layout.selector";
import {
  deselectActiveField,
  removeField,
  copyField,
  pasteField,
} from "../../../store/actions/fields/common.action";
import {
  resetEditingPanel,
  toggleTooltipPopper,
  setCanvasPanelWidth,
} from "../../../store/actions/layout.action";
import Canvas, {mobTopPanel, mobBtmPanel} from "./canvas/Canvas";
import Carousel from "../../../../oat-ui/core/Carousel";
import {
  Layout,
  WrapCanvas,
  PanelArea,
  CarouselFrame,
  WrapModal,
  CanvasSection,
} from "./CanvasPanelStyles";
import LazyLoad from "react-lazyload";
import {
  sidebarWidth,
  editingPanelWidth,
  deskNavHeight,
  slideActionsPanel,
  editingPanelWidthEditingChart,
} from "./../../../layoutSizes";
import {
  setCanvasSize,
  setTempScale,
} from "../../../store/actions/template/template.action";
import {dispatchRedo, dispatchUndo, storeActionsUndoRedo} from "../../../store/actions/fields/undoRedo.action";
import {getWindowSize} from "../../../../oat-window-size";
import BottomSection from "./bottomSection/BottomSection";
import {Spinner} from "../../../../oat-ui";
import {
  selectEmitItemsToSocket,
  selectUsersOnLine,
} from "../../../store/selectors/common.selector";
import {useSocket} from "../../../webSocket/useSocket";
import styled from "styled-components";
import {createShortKey} from "../../../store/reducers/functions/fields/utils";
import {
  createText,
  enableTextEdit,
  updateTextContent,
} from "../../../store/actions/fields/text.action";
import MobileSlideActions from "./../drawer/MobileSlideActions";
import MobileContents from "../drawer/MobileContents";
import {selectThemeProps} from "../../../store/selectors/template/theme.selector";
import { changeSlide } from "../../../store/actions/template/slide.action";
import { useDetectOnlineStatus } from "../../../../oat-hooks";

export const canvasAreaPadding = 30;
export const canvasPaddingTop = 12;

const CanvasPanel = ({
  activeField,
  activeSlide,
  activeSlideID,
  currentSlideIndex,
  deselectActiveField,
  slides,
  mobEditPanelHeight,
  removeField,
  copyField,
  pasteField,
  canvasSize,
  layout,
  textEditStatus,
  setTempScale,
  storeActionsUndoRedo,
  tempLoading,
  setCanvasPanelWidth,
  username,
  usersOnline,
  enableTextEdit,
  activeFieldProps,
  toEmitGroupedItems,
  setCanvasSize,
  createText,
  activeThemeProps,
  tempScale,
  openEditChartInfo,
  onViewChartInfo,
  updateTextContent,
  dispatchUndo,
  dispatchRedo,
  undoRedoToEmit,
  undoState,
  changeSlide
}) => {
  const windowSize = getWindowSize();
  const {emitSocketEvents} = useSocket() || {};
  const [mobilePanelPadding, setMobilePanelPadding] = useState();
  const [emitFieldToSocket, setEmitFieldToSocket] = useState({
    type: null, // delete or duplicating fields (create)
    status: false,
  });
  // const [panelHeight, setPanelHeight] = useState(windowSize.width - 57 - 10);
  const updatePanelHeight = () => {
    // handle change in height when zooming template exceeds the custom height
  };

  const [userActivatedCutKeyEvent, setUserActivatedCutKeyEvent] =
    useState(false);
  const [emitToSocket, setEmitToSocket] = useState(false);

  const detectShortcutKeys = useCallback(
    async (event) => {
      if (event.key === 'Tab' && textEditStatus) {
        // Prevent the default action of the Tab key
        event.preventDefault();
      }
      if ((event.keyCode === 37 || event.keyCode === 38) && !textEditStatus && event.target.type !== 'text' ) {
        if (activeSlide === 0) return;
        changeSlide(currentSlideIndex - 1);
        return;
      } else if ((event.keyCode === 39 || event.keyCode === 40) && !textEditStatus && event.target.type !== 'text' ) {
        if (activeSlide === slides.length - 1) {
          return;
        }
        changeSlide(currentSlideIndex + 1);
        return;
      }
    
      if (activeField !== -1 || activeField !== null) {
        if (event.target.matches('input')) {
          // Check if the input type is text
          if (event.target.type === 'text') {
            return;
          }
        }

        if (
          (event.ctrlKey || event.metaKey) &&
          event.keyCode == 88 &&
          event.target.type !== 'text' &&
          textEditStatus === false
        ) {
          // cut (ctrl + x)
          copyField();
          // to differentiate with clipboard event
          setUserActivatedCutKeyEvent(true);

          const timeout = setTimeout(async () => {
            if (
              event.target.nodeName == "INPUT" ||
              event.target.nodeName == "TEXTAREA" ||
              event.target.isContentEditable
            )
              return;
            const active = {slideIndex: activeSlide, fieldID: activeField};
            storeActionsUndoRedo("delete");
            await removeField(active);
          }, 10);
          return () => clearTimeout(timeout);
        } else if (
          // activeField !== -1 &&
          (event.keyCode === 8 || event.keyCode === 46) &&
          event.target.type !== 'text' &&
          textEditStatus === false
        ) {
          const modalBoxElement = document.getElementById("modal-box");
          const modalIsOn = modalBoxElement.hasChildNodes();
          if (modalIsOn) return;
          if (
            event.target.nodeName == "INPUT" ||
            event.target.nodeName == "TEXTAREA" ||
            event.target.isContentEditable
          )
            return;
          const active = {slideIndex: activeSlide, fieldID: activeField};
          storeActionsUndoRedo("delete");
          await removeField(active);
        } else if (event.keyCode === 27 && textEditStatus === false) {
          deselectActiveField();
          deselectOnCollaborate();
        } else if (event.keyCode === 27 && activeFieldProps?.type === "table") {
          enableTextEdit();
        } else if (!textEditStatus && (event.metaKey || event.ctrlKey) && event.shiftKey && event.key === 'z') {
          // Handle the "Command + Shift + Z" or "Ctrl + Shift + Z" shortcut
          if (undoState?.future.length !== 0) {
            await dispatchRedo();
            setEmitToSocket(true);
          }
        } else if (!textEditStatus && (event.metaKey || event.ctrlKey) && event.key === 'z') {
          // Handle the "Command + Z" or "Ctrl + Z" shortcut
          if (undoState?.history.length !== 0) {
            await dispatchUndo();
            setEmitToSocket(true);
          }
        } 
      }
    },
    [
      textEditStatus,
      activeSlide,
      activeField,
      activeSlideID,
      setUserActivatedCutKeyEvent,
      undoState?.history,
      undoState?.future
    ]
  );

  useEffect(() => {
    if (emitToSocket && Object.keys(undoRedoToEmit).length !== 0) {
      const item = {type: undoRedoToEmit?.type, value: undoRedoToEmit};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "grouped-fields-undoRedo", item});
      }
    }
  }, [emitToSocket, undoRedoToEmit]);

  useEffect(() => {
    // reverse the state back if user cut (ctrl+x) the field & didn't paste it back
    if (userActivatedCutKeyEvent) {
      // Use setTimeout to revert the state back after 30 seconds
      const timer = setTimeout(() => {
        setUserActivatedCutKeyEvent(false);
      }, 30000); // 30 seconds in milliseconds
      return () => clearTimeout(timer);
    }
  }, [userActivatedCutKeyEvent]);

  const detectPasteEvent = useCallback(
    (event) => {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (
        clipboardData &&
        !textEditStatus &&
        event.target.tagName !== "INPUT"
      ) {
        const pastedContent = clipboardData.getData("text");
        const {normalText} = activeThemeProps || {};
        if (pastedContent && !userActivatedCutKeyEvent) {
          const key = createShortKey();
          createText({
            slideID: activeSlide,
            key,
            content: `<p>${pastedContent}</p>`,
            subtype: "normalText",
            size: {
              w: pastedContent.length < 100 ? 320 / tempScale : 800 / tempScale,
              h: pastedContent.length < 100 ? 29 / tempScale : 80 / tempScale,
              w2: pastedContent.length < 100 ? 320 : 800,
              h2: pastedContent.length < 100 ? 29 : 80,
            },
            ["styles"]: {
              fontSize: 20,
              fontWeight: 400,
              letterSpacing: normalText?.letterSpacing,
              lineHeight: normalText?.lineHeight,
              scale: 1 / tempScale,
              color1: normalText?.color1,
              fontFamily: normalText?.fontFamily,
              whiteSpace: "pre-wrap",
            },
          });
          storeActionsUndoRedo("create");
        } else {
          const newKey = createShortKey();
          pasteField(activeSlide, newKey);
          setUserActivatedCutKeyEvent(false);
          storeActionsUndoRedo("create");
          setEmitFieldToSocket({type: "create", status: true});
        }
      } 
    },
    [
      activeSlide,
      tempScale,
      textEditStatus,
      userActivatedCutKeyEvent,
      setUserActivatedCutKeyEvent,
    ]
  );

  const handleOnCopy = useCallback(
    (event) => {
      if (
        (activeField !== -1 || activeField !== null) &&
        event.target.tagName !== "INPUT"
      ) {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        copyField();
        // clear the copied text from clipboard
        clipboardData.setData("text/plain", "");
      }
    },
    [activeFieldProps]
  );

  useEffect(() => {
    document.addEventListener("keydown", detectShortcutKeys, false);
    document.addEventListener("paste", detectPasteEvent, false);
    if (!textEditStatus) {
      document.addEventListener("copy", handleOnCopy, false);
    }
    return () => {
      document.removeEventListener("keydown", detectShortcutKeys, false);
      document.removeEventListener("paste", detectPasteEvent, false);
      document.removeEventListener("copy", handleOnCopy, false);
    };
  }, [
      textEditStatus, 
      activeSlide, 
      activeField, 
      userActivatedCutKeyEvent, 
      undoState?.history,
      undoState?.future,
      undoRedoToEmit
    ]);

  const canvasInPercent = layout.canvasPanel;
  // const canvasPanel = windowSize.width >= 850 && layout.showEditPanel
  //   ? windowSize.width - sidebarWidth - editingPanelWidth
  //   : ((windowSize.width - sidebarWidth - slideActionsPanel) *
  //       canvasInPercent) /
  //     100 : windowSize.width;

  let canvasPanel;
  if (windowSize.width >= 850 && layout.showEditPanel) {
    canvasPanel =
      windowSize.width -
      sidebarWidth -
      (openEditChartInfo ? editingPanelWidthEditingChart : editingPanelWidth);
  } else if (windowSize.width >= 850 && !layout.showEditPanel) {
    canvasPanel =
      ((windowSize.width - sidebarWidth - slideActionsPanel) *
        canvasInPercent) /
      100;
  } else if (windowSize.width < 850) {
    canvasPanel = windowSize.width - canvasAreaPadding;
  }

  const calcLayout = () => {
    let calcScale = 0;
    if (windowSize.width < 850) {
      const canvasArea = (canvasPanel * 98) / 100;
      // (windowSize.width / 100) * 98 - 25;
      calcScale = Math.min(
        (windowSize.height - 190) / canvasSize.h, // remove 100 to get full height on screenshot page
        canvasArea / canvasSize.w
      );
      setCanvasSize(canvasArea);
    } else {
      const canvasArea = canvasPanel - canvasAreaPadding;
      calcScale = Math.min(
        (windowSize.height - 150) / canvasSize.h,
        canvasArea / canvasSize.w
      );
      setCanvasSize(canvasArea);
    }

    if (calcScale !== Infinity) {
      const scaleValue = ((calcScale + Number.EPSILON) * 100) / 100;
      setTempScale(scaleValue);
      if (windowSize.width < 850) {
        setMobilePanelPadding(
          (windowSize.width - canvasSize.w * scaleValue) / 2
        );
      }
    }

    // if (calcScale !== Infinity) {
    //   setDisplayPanel({
    //     width: canvasSize.w * calcScale,
    //     height: canvasSize.h * calcScale,
    //   });
    // }
  };

  useEffect(() => {
    calcLayout();
    setCanvasPanelWidth(canvasPanel);
  }, [windowSize.width, windowSize.height, canvasSize, layout.canvasPanel, openEditChartInfo]);

  const handleDeselectField = (e) => {
    const textEditingElm = document.getElementById(`text-field-editing-active`);
    const mobileFieldActions = document.getElementById(
      `mobile-edit-field-actions-a1`
    );

    if (
      textEditStatus &&
      textEditingElm != null &&
      textEditingElm.contains(e.target)
    ) {
      return;
    } else if (
      // activate when hitting delete field button in mobile
      windowSize.width < 850 &&
      mobileFieldActions != null &&
      mobileFieldActions.contains(e.target)
    ) {
      return;
    } else {
      if (textEditStatus) {
        enableTextEdit();
      } else {
        deselectActiveField();
        deselectOnCollaborate();
      }
    }

    if (window.getSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
    }
    // e.stopPropagation();
  };

  const deselectOnCollaborate = () => {
    if (Object.keys(usersOnline?.users).length > 0) {
      if (emitSocketEvents) {
        emitSocketEvents({
          actionType: "deselect-field", // also for grouped
          item: {type: "", value: username},
        });
      }
    }
  };

  // const { isOnline } = useDetectOnlineStatus();
  // useEffect(() => {
  //   if (!isOnline) {

  //   }
  // },[isOnline])
  
  return (
    <>
      {tempLoading ? (
        <WrapModal
          style={{width: windowSize.width >= 850 ? canvasPanel : "100%"}}
        >
          <Spinner />
        </WrapModal>
      ) : (
        <LazyLoad height="100%" once>
          <Container>
            <Layout
              width={canvasPanel + "px"}
              style={{
                height:
                  windowSize.width < 850
                    ? windowSize.height -
                      (mobTopPanel +
                        mobEditPanelHeight.addedHeight +
                        mobBtmPanel) //
                    : windowSize.height - deskNavHeight + 3,
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: canvasPaddingTop,
              }}
              onMouseDown={handleDeselectField}
            >
              <PanelArea
                style={{
                  height: windowSize.width >= 850 ? "100%" : "auto",
                  // height: 2000,
                  // overflowY: 'scroll' // for content editable
                }} // height important
              >
                <Carousel
                  activeIndex={currentSlideIndex}
                  duration={windowSize.width < 850 ? 0.6 : 0}
                  timing="ease-in-out"
                  carouselType={
                    windowSize.width >= 850
                      ? "no-transition"
                      : "classic-transition"
                  }
                >
                  {slides &&
                    slides.map((slide, index) => {
                      if (windowSize.width < 850) {
                        return (
                          <CarouselFrame
                            aria-hidden={
                              index === currentSlideIndex ? false : true
                            }
                            key={slide.id}
                          >
                            <WrapCanvas>
                              <CanvasSection>
                                <Canvas
                                  slide={slide}
                                  canvasIndex={index}
                                  activeSlide={slide.id === activeSlideID}
                                  lastSlide={index === slides.length - 1}
                                />
                              </CanvasSection>
                            </WrapCanvas>
                          </CarouselFrame>
                        );
                      } else if (
                        windowSize.width >= 850 
                        // &&
                        // slide.id === activeSlideID
                      ) {
                        return (
                          <CarouselFrame
                            aria-hidden={
                              index === currentSlideIndex ? false : true
                            }
                            key={slide.id}
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: slide.id === activeSlideID ? 1111 : -1,
                              visibility: slide.id === activeSlideID ? 'visible' : 'hidden'
                            }}
                          >
                            <WrapCanvas>
                              <CanvasSection>
                                <Canvas
                                  slide={slide}
                                  canvasIndex={index}
                                  panelWidth={canvasPanel - 10}
                                  activeSlide={slide.id === activeSlideID}
                                  lastSlide={index === slides.length - 1}
                                />
                              </CanvasSection>
                            </WrapCanvas>
                          </CarouselFrame>
                        );
                      }
                    })}
                </Carousel>
                  
                <div 
                  id="text-field-on-edit" 
                  style={{ 
                    position: 'absolute', 
                    width: activeFieldProps?.type === 'text' && textEditStatus ? '100%' : undefined, 
                    height: activeFieldProps?.type === 'text' && textEditStatus ? '100%' : undefined
                  }} 
                />
                {/* {windowSize.width < 850 ? <SliderArrows /> : undefined} */}
              </PanelArea>

              {windowSize.width >= 850 && canvasPanel > 350 ? <BottomSection /> : undefined}
              {/* {windowSize.width >= 850 ? <ThemeBox /> : undefined} */}

              {windowSize.width <= 849 ? (
                <MobileSlideActions
                  currentSlideIndex={currentSlideIndex}
                  noOfSlides={slides.length}
                />
              ) : undefined}
            </Layout>

            <div id="canvas-field-item-tooltip" />
            <div id="canvas-field-item-field-ratio-tooltip" />

            {windowSize.width <= 849 ? <MobileContents /> : undefined}

            <OverlayLayer
              id="oat-item-drag-resize"
              style={{
                width: canvasPanel,
                left:
                  windowSize.width < 850 ? mobilePanelPadding : sidebarWidth,
                right: 0,
                top:
                  windowSize.width < 850
                    ? mobTopPanel + canvasPaddingTop
                    : deskNavHeight + canvasPaddingTop,
                bottom: 0,
                zIndex: 1,
                pointerEvents: "none",
                position: 'absolute'
              }}
            />
          </Container>
        </LazyLoad>
      )}
    </>
  );
};

const OverlayLayer = styled.div`
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

const Container = styled.div`
  user-select: none;
  -webkit-user-select: none;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeField: selectActiveField(designTemplate),
    activeFieldProps: selectActiveFieldProps(designTemplate),
    activeSlide: selectActiveSlide(designTemplate),
    activeSlideID: selectActiveSlideID(designTemplate),
    currentSlideIndex: selectActiveSlideIndex(designTemplate),
    slides: selectTemplate(designTemplate),
    mobEditPanelHeight: selectMobEditingPanel(designTemplate),
    canvasSize: selectTemplateSize(designTemplate),
    layout: getTemplateLayout(designTemplate),
    textEditStatus: selectEnableTextEdit(designTemplate),
    tempLoading: selectTemplateStatus(designTemplate),
    usersOnline: selectUsersOnLine(designTemplate),
    toEmitGroupedItems: selectEmitItemsToSocket(designTemplate),
    activeThemeProps: selectThemeProps(designTemplate),
    tempScale: selectTempScale(designTemplate),
    openEditChartInfo: selectEditChartInfoStatus(designTemplate),
    undoState: selectUndoRedoHistory(designTemplate),
    undoRedoToEmit: selectUndoRedoToEmitSocket(designTemplate),
  };
};

export default connect(mapStateToProps, {
  deselectActiveField,
  resetEditingPanel,
  removeField,
  copyField,
  pasteField,
  setTempScale,
  storeActionsUndoRedo,
  setCanvasPanelWidth,
  enableTextEdit,
  setCanvasSize,
  createText,
  updateTextContent,
  dispatchUndo,
  dispatchRedo,
  changeSlide
})(CanvasPanel);
