import React from "react";
import "./Switch.css";
import styled from "styled-components";
import {Title} from "../reuseStyles/Title";

const Switch = ({
  title,
  value,
  onItemChange,
  checked,
  displayTitle,
  displayTooltip,
  disabledPadding,
}) => {
  const toggleChange = () => {
    onItemChange(!value);
  };

  return (
    <Wrapper>
      {displayTitle ? (
        <Title
          disabledPadding={disabledPadding}
          style={{
            display: "flex",
          }}
        >
          <p>{title}</p>
          {displayTooltip ? (
            <InfoIcon>
              <div>i</div>
              <DisplayTooltipInfo>{displayTooltip.info}</DisplayTooltipInfo>
            </InfoIcon>
          ) : undefined}
        </Title>
      ) : undefined}

      <WrapSwitch>
        <label className="oatall-input-switch">
          <input
            type="checkbox"
            value={value}
            onChange={toggleChange}
            checked={checked === true ? true : false}
          />
          <span className="slider round" />
        </label>
      </WrapSwitch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const WrapSwitch = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 1em;
  @media only screen and (max-width: 849px) {
    margin: 0;
  }
`;

const DisplayTooltipInfo = styled.div`
  display: none;
  position: absolute;
  left: 30px;
  top: 30px;
  border-radius: 8px;
  background: ${({theme}) => theme.deskPrimaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  padding: 10px;
  z-index: 9999;
`;

const InfoIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${({theme}) => theme.deskSecondaryBg3};
  margin-left: 13px;
  cursor: pointer;
  &:hover ${DisplayTooltipInfo} {
    display: flex;
  }
`;

Switch.defaultProps = {
  displayTitle: true,
  displayTooltip: false,
  enabledPadding: true,
};

export default Switch;
