import React, {useState} from "react";
import styled from "styled-components";
import { Button, Spinner } from "../../../oat-ui";
import { red1 } from "../../../oat-color-variables";
import { removeImageItem } from "../../store/actions/media.action";
import { connect } from "react-redux";

const DisplayTag = ({tag}) => {
  return <Option>#{tag}</Option>;
};

const ImageDetails = ({selectedImage, removeImageItem, closeModal}) => {
  const [processing, setProcessing] = useState(false);

  const handleDelete = async () => {
    setProcessing(true);
    await fetch(
      `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/private-media-library/private/${selectedImage?.key}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(async(data) => {
        setProcessing(false);
        await removeImageItem(selectedImage?.key);
        await closeModal();
      })
      .catch((error) => {
        setProcessing(false);
      });
  }

  return (
    <Layout>
      <WrapImage>
        <Image
          src={`https://oat-images-icons.s3.amazonaws.com/${typeof selectedImage.image === 'object' ? selectedImage.image.medium : selectedImage.image}`}
        />
      </WrapImage>
      <Item>
        <Field>ID: </Field>
        <Value>{selectedImage.key}</Value>
      </Item>
      <Item>
        <Field>Created At: </Field>
        <Value>{selectedImage.createdAt}</Value>
      </Item>
      <Item>
        <Field>Tags: </Field>
        <Value>
          {selectedImage.tags &&
            selectedImage.tags.map((tag, i) => (
              <DisplayTag key={i} tag={tag} />
            ))}
        </Value>
      </Item>
      <Item style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end'}}>
        {processing ? 
          <div style={{top: 50, right: 0, marginRight: 40 }}>
            <Spinner /> 
          </div>
        : undefined}
        <Button styles={{ width: 140, height: 36, background: red1}} onClick={handleDelete}>Delete</Button>
      </Item>
    </Layout>
  );
};

const Layout = styled.div`
  margin: 12px;
  box-sizing: border-box;
`;

const WrapImage = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: 400px;
  padding: 32px;
  box-sizing: border-box;
`;

const Item = styled.div`
  display: flex;
  font-size: 0.95rem;
  margin: 14px;
  padding: 8px 0px 0px 10px;
`;

const Field = styled.div`
  min-width: 140px;
`;

const Value = styled.div`
  display: flex;
`;

const Option = styled.div`
  color: #3538a0;
  padding-right: 14px;
`;

export default connect(null, {removeImageItem})(ImageDetails);
