import styled from "styled-components";
import {greyText2, primaryBlue, red4} from "../../../../oat-color-variables";

export const Header = styled.div`
  font-size: 1.05rem;
  font-weight: 700;
  color: ${primaryBlue};
  margin: 8px 0 14px;
`;

export const Section = styled.div`
  margin-bottom: 32px;
`;

export const Message = styled.p`
  font-size: 0.95rem;
  color: ${greyText2};
`;

export const CollaboratorAccessLevel = styled.div`
  font-size: 0.95rem;
  text-align: center;
  margin-top: 82px;
`;

export const Error = styled.div`
  color: ${red4};
  font-size: 0.9rem;
  margin: 8px 6px 0px;
`;
