import React from "react";
import {Option, Dot} from "../../../../oat-ui/styles/SelectOption";

const UserInfoOptions = ({option, onSelect}) => {
  const handleClick = () => {
    onSelect(option.value);
  };

  return (
    <Option onClick={handleClick}>
      <div>{option.display}</div>
      <Dot />
    </Option>
  );
};

export default UserInfoOptions;
