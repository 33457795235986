export function updateEditingPanel() {
  return function (state, action) {
    const {height, value} = action;
    return {
      ...state,
      mobEditingPanel: {
        addedHeight: height,
        type: value !== undefined ? value : state.mobEditingPanel.type,
      },
      cropImage: state.cropImage ? false : state.cropImage,
    };
  };
}

export function resetEditingPanel() {
  return function (state) {
    return {
      ...state,
      mobEditingPanel: {
        addedHeight: 0,
        type: "",
      },
    };
  };
}

export function openDrawer() {
  return function (state, action) {
    return {
      ...state,
      mobile: {
        drawer: {
          open: true,
          type: action.drawerType,
          height: action.height,
        },
      },
    };
  };
}

export function closeDrawer() {
  return function (state, action) {
    return {
      ...state,
      mobile: {
        drawer: {
          ...state.mobile.drawer,
          open: false,
        },
      },
    };
  };
}
