const fullCanvasWidth = 100; // set 100 for screenshot

export function showEditPanel() {
  return function (state, action) {
    const openPanel = action.panelStatus ? true : !state.layout.showEditPanel;

    let selectedFieldType = null;
    if (action.panelType === "designs" && state.activeField !== -1) {
      selectedFieldType =
        state.template[state.activeSlide].fields[state.activeField].type;
    } else if (action.panelType) {
      selectedFieldType = action.panelType;
    } else {
      selectedFieldType = state.selectedFieldType;
    }
    return {
      ...state,
      activeField:
        window.innerWidth >= 850 &&
        (action.panelType === "comment" || action.panelType === "notes")
          ? -1
          : state.activeField,
      selectedFieldType: state.groupSelect.selection.show ? 'animations' : selectedFieldType,
      layout: {
        ...state.layout,
        showEditPanel: openPanel,
        canvasPanel: openPanel ? 0 : fullCanvasWidth,
      },
    };
  };
}

export function openModal() {
  return function (state, action) {
    return {
      ...state,
      openModal: {
        status: true,
        type: action.modalType,
        children: action.payload !== undefined ? action.payload : "",
      },
    };
  };
}

export function closeModal() {
  return function (state) {
    return {
      ...state,
      openModal: {
        status: false,
        type: "",
      },
    };
  };
}

export function toggleTooltipPopper() {
  return function (state, action) {
    return {
      ...state,
      openTooltipPopper: {
        status:
          action.value !== undefined || action.value !== "" ? true : false,
        type: action.value ? action.value : null,
      },
    };
  };
}

export function setCanvasPanelWidth() {
  return function (state, action) {
    return {
      ...state,
      layout: {
        ...state.layout,
        canvasPanelWidth: action.value,
      },
    };
  };
}

// export function setCreatePanel() {
//   return function (state, action) {
//     return {
//       ...state,
//       createPanel: action.panel,
//       activeField: -1,
//       selectedFieldType: "",
//       cropImage: state.cropImage ? false : state.cropImage,
//       mobEditingPanel: {
//         addedHeight: 0,
//         type: "",
//       },
//     };
//   };
// }
