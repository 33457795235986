import React, {useState} from "react";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {selectUserInfo} from "../../../../redux/user/authUser";
import {grey4, greyText1, paleBlue5, red1, red3, red4} from "../../../oat-color-variables";
import Check from "../../../oat-svg-icons/icons/Check";
import {selectActiveWorkSpace} from "../../../oat-user-dashboard/store/selectors/userDashboard.selector";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";
import PricingInterval from "./PricingInterval";
import { Button, ButtonSpinner, Modal, ShowError } from "../../../oat-ui";
import { userPrimaryGrey } from "../../../oat-user-dashboard/userDashboardStyles";

const PricingPlans = ({user, workspace}) => {
  let navigate = useNavigate();
  const [pricingInterval, setPricingInterval] = useState("yearly");
  const [error, setError] = useState({status: false, message: null});
  const [submiting, setSubmiting] = useState(false);
  const windowSize = getWindowSize();
  const query = new URLSearchParams(useLocation().search);
  const workspaceIDFromParam = query.get("workspaceID");

  const handleSubscription = async (workspaceID) => {
    await fetch(
      `https://x1hr190cjc.execute-api.us-east-1.amazonaws.com/main/user-subscription/${user.username}/workspaces/${workspaceID}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          plan: pricingInterval === "yearly" ? `pro-yearly` : "pro-monthly",
        }),
      }
    )
      .then((res) => {
        setSubmiting(false);
        if (res.status == 303) {
          return res.json();
        }
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({url}) => (window.location = url))
      .catch((e) => {
        setSubmiting(false);
      });
  }

  const submitPayment = async () => {
    const currentUrl = window.location.href;
    if (user && currentUrl.includes('pricing') && workspaceIDFromParam) {
      if (error.status) {
        setError({status: false, message: null});
      }
      setSubmiting(true);
      await handleSubscription(workspaceIDFromParam);
    } else if (user && workspace && workspace.ID) {
      if (error.status) {
        setError({status: false, message: null});
      }
      setSubmiting(true);
      await handleSubscription(workspace.ID);
    } else {
      setError({
        status: true,
        message: "Please select a workspace to subscribe.",
      });
    }
  };

  const choosePlan = () => {
    if (user.loggedIn.status) {
      submitPayment();
    } else {
      navigate("/register");
    }
  };

  const registerForFree = () => {
    if (user.loggedIn.status) {
      navigate(`/dashboard/${user.defaultWorkspaceID}/designs/home`);
    } else {
      navigate("/register");
    }
  };

  const togglePricingInterval = () => {
    if (pricingInterval === "yearly") {
      setPricingInterval("monthly");
    } else {
      setPricingInterval("yearly");
    }
  };

  const closeModal = () => {

  }

  return (
    <>
      <Header>Pricing</Header>

      {windowSize.width > 900 ?
      <div style={{margin: '0.5em 0 3em'}}>
        <PricingInterval pricingInterval={pricingInterval} onToggle={togglePricingInterval} />
      </div>
      : undefined}

      {error.status ? <Error>{error.message}</Error> : undefined}

      <Plans>
        <Plan style={{background: "#fff"}}>
          <PlanType>Free</PlanType>

          {windowSize.width <= 900 ?
            <div style={{margin: '2.5em 0'}}>
              <PricingInterval pricingInterval={pricingInterval} onToggle={togglePricingInterval} />
            </div>
          : undefined}

          <Features style={{color: "#282020"}}>
            <div style={{marginBottom: 17}}>
              <WrapSvg style={{fill: "#282020"}}>{Check}</WrapSvg>
              <span>Team Collaboration</span>
            </div>
            <div style={{marginBottom: 17}}>
              <WrapSvg style={{fill: "#282020"}}>{Check}</WrapSvg>
              <span>Share as Link</span>
            </div>
            <div style={{marginBottom: 17}}>
              <WrapSvg style={{fill: "#282020"}}>{Check}</WrapSvg>
              <span>Storage upto 1GB per workspace</span>
            </div>
          </Features>

          <Subscribe onClick={registerForFree} style={{color: '#fff', background: `linear-gradient(rgb(145 154 233), #a198f3)`}}>
            Register
          </Subscribe>
        </Plan>

        <Plan
          style={{
            background: "linear-gradient(220deg, rgb(122, 143, 247), rgb(154 144 212))",
            color: "#fff",
          }}
        >
          <PlanType>Pro</PlanType>

          {windowSize.width <= 900 ?
            <div style={{margin: '2.5em 0'}}>
              <PricingInterval 
                pricingInterval={pricingInterval} 
                onToggle={togglePricingInterval} 
                background="#eef2ff"
                slider="#857aff"
              />
            </div>
          : undefined}

          <div className="price">
            <div style={{ display: 'flex' }}>
              {/* <p style={{ 
                textDecoration: 'line-through', 
                marginRight: 18,
                color: '#f7c2c2'
              }}>
                {pricingInterval === "yearly" ? "$8" : "$10" }
              </p> */}
              <p>
                {pricingInterval === "yearly"
                  ? "$7 per month (Yearly)"
                  : "$9 per month (Monthly)"}
              </p>
            </div>
          </div>
          <Features style={{fontWeight: 600}}>
            <div style={{marginBottom: 17}}>
              <WrapSvg>{Check}</WrapSvg> <span>Unlimited Templates</span>
            </div>
            <div style={{marginBottom: 17}}>
              <WrapSvg>{Check}</WrapSvg> <span>Workspace Roles</span>
            </div>
            <div style={{marginBottom: 17}}>
              <WrapSvg>{Check}</WrapSvg>
              <span>Store Fonts and Images</span>
            </div>
            <div style={{marginBottom: 17}}>
              <WrapSvg>{Check}</WrapSvg> <span>Private Folders</span>
            </div>
            <div style={{marginBottom: 17}}>
              <WrapSvg>{Check}</WrapSvg> <span>Shareable Links</span>
            </div>
            <div style={{marginBottom: 17}}>
              <WrapSvg>{Check}</WrapSvg>{" "}
              <span>Storage upto 15GB per member</span>
            </div>
          </Features>
          <Subscribe onClick={choosePlan}>Subscribe</Subscribe>
        </Plan>

        {/* <Plan style={{background: "#fff", marginRight: 0}}>
          <PlanType>Enterprise</PlanType>
          <Price
            style={{
              fontSize: "1.3rem",
              padding: "30px 0",
              color: "#d6a0ff",
              fontWeight: 700,
            }}
          >
            Coming Soon ...
          </Price>
        </Plan> */}
      </Plans>

      {/* <Modal
        width={350}
        height="auto"
        close={closeModal}
      >
        <ContentSection>
          <Title>Select Workspace</Title>

          <p>Select the workspace you would like to upgrade</p>
          
          <WrapField style={{margin: 0}}>
            <FieldName>Workspace</FieldName>

            <WrapField style={{flex: 1, margin: 0}}>
              
            </WrapField>
          </WrapField>

          {error.status ? <ShowError message={error.message} /> : undefined}
        </ContentSection>

        <Footer
        style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}
        >
          <BottomSection
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <BottomSection>
              <Button
                styles={{flex: 1, width: '100%'}}
                onClick={submitPayment}
                disabled={submiting}
              >
                {!submiting ? (
                  "Subscribe"
                ) : (
                  <ButtonSpinner width={15} height={15} color={"#fff"} />
                )}
              </Button>
            </BottomSection>
          </BottomSection>
        </Footer>
      </Modal> */}

      {/* {workspace.role === "owner" ? (
        <Error>Only owners can upgrade the workspace</Error>
      ) : undefined} */}
    </>
  );
};

const Header = styled.h2`
  font-size: 3rem;
  margin-bottom: 45px;
  @media only screen and (max-width: 900px) {
    font-size: 2.7rem;
    margin-top: 32px;
  }
`;

const Plans = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 1200px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 0 4%;
    box-sizing: border-box;
  }
`;

const Plan = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 210px;
  border-radius: 12px;
  box-shadow: 0 0px 9px 0 #c9c9c970, 0 0 0 1px #c9c9c970;
  padding: 15px 0;
  margin-right: 25px;
  .price {
    font-size: 1.1rem;
    font-weight: 700;
  }
  @media only screen and (min-width: 901px) {
    width: 360px;
  }
  @media only screen and (max-width: 900px) {
    flex: 1;
    margin: 24px 0px;
    max-width: 400px;
    width: 100%;
  }
`;

const PlanType = styled.div`
  font-size: 2.5rem;
`;

const Features = styled.div`
  margin: 32px 20px;
  div {
    display: flex;
    align-items: center;
    font-size: 0.95rem;
  }
`;

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: #fff;
  padding-right: 6px;
`;

const Subscribe = styled.div`
  height: 42px;
  width: 90%;
  background: #fff;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 7px;
  box-shadow: 0px 0px 0px 1px #dfdfdf;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Error = styled.div`
  color: ${red4};
  font-size: 0.96rem;
  margin: 0 0 32px;
  background: ${red3};
  border: 1px solid ${red1};
  padding: 12px 28px;
  border-radius: 8px;
`;

const ContentSection = styled.div`
  padding: 15px 12px;
  box-sizing: border-box;
  p {
    font-size: 14px;
    color: ${grey4}
  }
`;

export const ModalBody = styled.div`
  padding: 15px 12px;
`;

export const BodyScroll = styled.div`
  height: ${({height}) => height}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  &::-webkit-scrollbar {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.h3`
  font-size: 1rem;
  color: #7275f4;
  text-transform: capitalize;
  padding-bottom: 0.8rem;
`;

const WrapField = styled.div`
  margin: 2px 0 8px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  padding: 0.3rem 0 0.7rem;
  color: #535353;
  position: relative;
`;

const FieldName = styled.div`
  flex: 1;
  color: ${userPrimaryGrey};
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 58px;
  flex-direction: column;
  background: ${paleBlue5};
  border-top: 1px solid #dddde1;
  padding: 10px;
  box-sizing: border-box;
`;

const FooterBtn = styled.div`
  display: flex;
  width: auto;
  height: 34px;
  margin-right: 8px;
`;

const BottomSection = styled.div`
  display: flex;
  width: auto;
  flex: 1;
  height: 34px;
`;

const Cancel = styled.div`
  font-size: 0.85rem;
  cursor: pointer;
  color: ${greyText1};
`;



const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspace: selectActiveWorkSpace(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(PricingPlans);
