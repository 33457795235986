import React, {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {blue1} from "../../../oat-color-variables";

const DisplayTag = ({tag, onSelect, selected}) => {
  const handleClick = () => {
    onSelect(tag.id);
  };
  return (
    <Tag onClick={handleClick} active={selected}>
      <div className="collection-border">
        <img src={tag.image} alt="" />
      </div>
      <p>{tag.display}</p>
    </Tag>
  );
};

const ProfileTags = ({tags}) => {
  let navigate = useNavigate();
  let params = useParams();
  const query = new URLSearchParams(useLocation().search);
  const tag = query.get("tag");
  const [selectedTag, setSelectedTag] = useState(!tag ? "" : tag);

  const handleSelect = (tag) => {
    setSelectedTag(tag);
    return navigate(`/${params.username}?tag=${tag}`);
  };

  return (
    <Wrapper>
      {tags &&
        tags.map((tag, i) => (
          <DisplayTag
            key={i}
            tag={tag}
            onSelect={handleSelect}
            selected={selectedTag === tag.id}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 0.95rem;
  margin: 0px 0 18px;
  overflow-x: scroll;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 900px) {
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin: 12px 0 24px;
  }
`;

const Tag = styled.div`
  padding: 0px 12px 6px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 8px 0 0;
  color: ${({active}) => (active ? blue1 : "#403f40")};
  font-weight: ${({active}) => (active ? 700 : 500)};
  flex: 0 0 auto;
  text-align: center;
  .collection-border {
    width: 64px;
    height: 64px;
    border: ${({active}) =>
      active ? `2px solid #9494f9` : `1px solid  #bbbbbe`};
    border-radius: 50%;
    padding: 4px;
    img {
      max-width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  p {
    padding-top: 8px;
    font-size: 0.94rem;
  }
`;

export default ProfileTags;
