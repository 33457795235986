import {css} from "styled-components";

// specify width, left, top,  bottom position
// Legacy tooltip
export const TooltipStyles = css`
  position: absolute;
  height: 24px;
  background: ${({theme}) => theme.tooltip};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.7rem;
  padding: 0 18px;
  display: none;
  z-index: 9999;
  font-weight: 600;
  user-select: none;
`;
