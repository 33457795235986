import React, {useState, useRef} from "react";
import styled from "styled-components";
import {red1} from "../../../../oat-color-variables";
import {detectClickOutside} from "../../../../oat-ui";
import {sidebarItemText} from "../reusableStyles/sidebarItem";
import CreateFolder from "./CreateFolder";
import DeleteFolder from "./DeleteFolder";
import RenameFolder from "./RenameFolder";
import {connect} from "react-redux";
import {selectWorkspacePlan} from "../../../store/selectors/userDashboard.selector";
import {proButtonStyles} from "../../../../oat-ui/styles/proIconStyles";

const FolderActions = ({
  selectedFolder,
  onEditFolderPrivacy,
  workspacePlan,
  close,
  onRequiresUpgrade,
  libraryFolder
}) => {
  const [actionType, setActionType] = useState({
    status: false,
    type: null,
  });
  const popperRef = useRef();

  const handleRenameFolder = (e) => {
    setActionType({
      status: true,
      type: "rename-folder",
    });
    e.stopPropagation();
  };

  const handleCreateSubfolder = (e) => {
    if (workspacePlan === "free") {
      onRequiresUpgrade(true);
    } else {
      setActionType({
        status: true,
        type: "create-subfolder",
      });
    }
    e.stopPropagation();
  };

  const handleDeleteFolder = (e) => {
    setActionType({
      status: true,
      type: "delete-folder",
    });
    e.stopPropagation();
  };

  const onIgnoreEvent = (e) => {
    e.stopPropagation();
  };

  const onEditPrivacy = (e) => {
    if (workspacePlan === "free") {
      onRequiresUpgrade(true);
    } else {
      onEditFolderPrivacy(selectedFolder.folderID);
    }
    e.stopPropagation();
  };

  detectClickOutside(popperRef, () => {
    close();
  });

  const displayPopper = () => {
    if (actionType.type === "rename-folder") {
      return <RenameFolder selectedFolder={selectedFolder} close={close} />;
    } else if (actionType.type === "delete-folder") {
      return <DeleteFolder selectedFolder={selectedFolder} close={close} />;
    } else if (actionType.type === "create-subfolder") {
      // subfolder creation
      return (
        <CreateFolder
          folderType={selectedFolder.folderType}
          isParentFolder={false}
          parentFolderID={selectedFolder.folderID}
          close={close}
        />
      );
    }
  };

  return (
    <Layout onClick={onIgnoreEvent} ref={popperRef}>
      {!actionType.status ? (
        <Wrapper>
          <Option onClick={handleRenameFolder}>
            <div>Rename Folder</div>
          </Option>
          
          {!selectedFolder?.isSubfolder && !libraryFolder ? (
            <Option onClick={handleCreateSubfolder}>
              <div>Create Subfolder</div>
              {workspacePlan === "free" ? (
                <UpgradeToPro>Pro</UpgradeToPro>
              ) : undefined}
            </Option>
          ) : undefined}

          {!selectedFolder?.isSubfolder && !libraryFolder ? (
            <Option onClick={onEditPrivacy}>
              <div>Edit Privacy</div>
              {workspacePlan === "free" ? (
                <UpgradeToPro>Pro</UpgradeToPro>
              ) : undefined}
            </Option>
          ) : undefined}

          <Option style={{color: red1}} onClick={handleDeleteFolder}>
            <div>Delete Folder</div>
          </Option>
        </Wrapper>
      ) : undefined}

      {actionType.status ? displayPopper() : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  background: #fff;
  z-index: 99999;
`;

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  margin: 8px 0;
`;

export const Option = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 162px;
  height: 34px;
  margin: 4px 8px;
  padding: 4px 8px;
  box-sizing: border-box;
  ${sidebarItemText};
`;

export const UpgradeToPro = styled.div`
  ${proButtonStyles};
  margin-left: 32px;
`;

const mapStateToProps = (state) => {
  return {
    workspacePlan: selectWorkspacePlan(state.userDashboard),
  };
};

FolderActions.default = {
  libraryFolder: false
};

export default connect(mapStateToProps, null)(FolderActions);
