import React, {useState, useRef, useCallback, useEffect} from "react";
import styled from "styled-components";
import {Spinner} from "../../../../../../oat-ui";
import {columnGap, Grid} from "./ReuseStyles";
import { getWindowSize } from "../../../../../../oat-window-size/getWindowSize";
import { Refresh } from "../../../../../../oat-svg-icons";

const DisplayItem = ({item, onSelect, imageWidth}) => {
  
  const handleClick = () => {
    onSelect(item);
  };

  const newHeight = (item.imgSize?.h * imageWidth) / item.imgSize?.w;
  return (
    <WrapItem
      style={{
        width: imageWidth,
        height: newHeight,
        marginBottom: 14,
        maxHeight: 240
      }}
    >
      <img
        src={item.imgType === "svg"
          ? `https://oat-images-icons.s3.amazonaws.com/${item.image}`
          : `https://oat-images-icons.s3.amazonaws.com/${item.image['small']}`
        }
        onClick={handleClick}
        loading="lazy"
      />
    </WrapItem>
  );
};

const PrivateMedia = ({
  type,
  replaceImg,
  onUploadImgByLink,
  onReplaceImgByLink,
}) => {
  const [images, setImages] = useState([]);
  const panelRef = useRef();
  const [mediaType, setMediaType] = useState("images");
  const [fetching, setFetching] = useState(false);
  // const [page, setPage] = useState(1);
  // const [isLastItem, setIsLastItem] = useState(false);
  const [imageWidth, setImageWidth] = useState();
  const windowSize = getWindowSize();
  const [refresh, setRefresh] = useState();
  const [paginationToken, setPaginationToken] = useState(null);
  const noOfColumns = windowSize.width >= 850 ? 3 : 2;

  useEffect(() => {
    const panelWidth = panelRef.current.offsetWidth;
    setImageWidth((panelWidth - columnGap * 2) / noOfColumns);
  }, [noOfColumns, windowSize.width]);

  const fetchImages = () => {
    setFetching(true);
    fetch(
      type === "public-media"
        ? `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/images` // ?category=emojis
        : `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/private-media-library/${mediaType}`
    )
      .then((res) => res.json())
      .then((data) => {
        setImages(data.items);
        setPaginationToken(data.paginationToken)
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
      });
  }

  useEffect(() => {
    if (paginationToken === null && images.length === 0) {
      fetchImages();
      setInitialLoading(false);
    }
  }, [type, images.length]);

  const selectImage = (item) => {
    const payload = {
      naturalWidth: item.imgSize?.w,
      naturalHeight: item.imgSize?.h,
      src: item.image,
      imageBy: item.imageBy,
      imgType: item.imgType,
      imgSize: item?.imgSize,
      colorSet: item?.colorSet
    };
    if (!replaceImg) {
      onUploadImgByLink(payload);
    } else {
      onReplaceImgByLink(payload);
    }
  };

  // useEffect(() => {
  //   fetchUnsplashImages();
  // }, [page]);

  const [initialLoading, setInitialLoading] = useState(true);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const observer = useRef();
  const lastItemRef = useCallback((node) => {
    if (initialLoading) {
      return;
    }
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && paginationToken) {
        setInfiniteLoading(true);
        fetch(`https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/private-media-library/${mediaType}?paginationToken=${paginationToken}`)
          .then((response) => response.json())
          .then((data) => {
              setImages([...images, ...data.items]);
              setPaginationToken(data.paginationToken)
              setInfiniteLoading(false);
          })
          .catch((error) => {
          });
      }
    }, { threshold: 0.5 });
    if (node) observer.current.observe(node);
  }, [initialLoading, paginationToken]);

  return (
    <Wrapper>
      {/* <a href="/admin/library/private" target="_blank">
        Upload Image Here
      </a> */}
      <ImageList ref={panelRef}>
        <WrapSvg onClick={fetchImages}>{Refresh}</WrapSvg>
        {!fetching ? (
          <Grid>
            <div>
              {images &&
                images.map((item, i) => {
                  if ((i + 1) % 3 === 1) {
                    return (
                      <DisplayItem
                        key={i}
                        item={item}
                        onSelect={selectImage}
                        imageWidth={imageWidth}
                      />
                    );
                  }
                })}
            </div>
            <div>
              {images &&
                images.map((item, i) => {
                  if ((i + 1) % 3 === 2) {
                    return (
                      <DisplayItem
                        key={i}
                        item={item}
                        onSelect={selectImage}
                        imageWidth={imageWidth}
                      />
                    );
                  }
                })}
            </div>
            <div>
              {images &&
                images.map((item, i) => {
                  // if (images.length === i + 1) {
                  //   return <div key={i} ref={lastItemRef} />;
                  // } else
                  if ((i + 1) % 3 === 0) {
                    return (
                      <DisplayItem
                        key={i}
                        item={item}
                        onSelect={selectImage}
                        imageWidth={imageWidth}
                      />
                    );
                  }
                })}
            </div>
            <div ref={lastItemRef} />;
            <div style={{height: 300}} />
          </Grid>
        ) : (
          <div style={{width: "100%", marginTop: "10%"}}>
            <Spinner />
          </div>
        )}
      </ImageList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
    font-size: 1.1rem;
    color: ${({theme}) => theme.deskPrimaryTxt};
  }
`;

const ImageList = styled.div`
  width: 100%;
  margin: 25px 0;
`;

const WrapItem = styled.div`
  position: relative;
  margin-bottom: 25px;
  background: #dedede;
  a {
    font-size: 0.8rem;
    color: ${({theme}) => theme.deskPrimaryTxt};
    position: absolute;
    bottom: -20px;
    left: 0px;
    cursor: pointer;
    text-decoration: none;
  }
  img {
    max-height: 240px;
    padding: 12px;
    box-sizing: border-box;
  }
`;

const WrapSvg = styled.div`
  width: 18px;
  height: 18px; 
  fill: ${({theme}) => theme.deskPrimaryTxt};
  margin-bottom: 12px;
  cursor: pointer;
`

export default PrivateMedia;
