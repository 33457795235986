import {
  UPDATE_GROUP_OFFSET,
  GET_ORIGINAL_OFFSET,
  INTERCEPT_GROUP_SELECTION,
  UPDATE_GROUP_SIZE,
  GROUPED_ITEMS_FOR_COLLABORATION,
  STORE_GROUP_FIELDS_INFO_UNDO_REDO
} from "../actionTypes";

export const updateMultiplePosition = (addedX, addedY) => {
  return {
    type: UPDATE_GROUP_OFFSET,
    addedX: addedX,
    addedY: addedY,
  };
};

export const updateGroupFieldSize = ({updatedOffset, updatedSize}) => {
  return {
    type: UPDATE_GROUP_SIZE,
    updatedOffset,
    updatedSize,
  };
};

export const getOriginalOffset = (size, offset, initValues) => {
  return {
    type: GET_ORIGINAL_OFFSET,
    size,
    offset,
    initValues,
  };
};

export const onInterceptFields = (keys) => {
  return {
    type: INTERCEPT_GROUP_SELECTION,
    keys,
  };
};

export const setGroupedItemsCollaboration = ({
  slideID,
  keys,
  username,
  size,
  offset,
}) => {
  return {
    type: GROUPED_ITEMS_FOR_COLLABORATION,
    slideID,
    keys,
    username,
    size,
    offset,
  };
};

export const storeGroupFieldsInfoUndoRedo = (type) => {
  return {
    type: STORE_GROUP_FIELDS_INFO_UNDO_REDO,
    updateType: type,
  };
};

