import {
  CHANGE_SLIDE_INDEX,
  CHANGE_SLIDE_ID_PRESENT_VIEW,
  ADD_NEW_SLIDE,
  SWITCH_SLIDE_INDEX,
  CHANGE_SLIDE_BY_ID,
  DELETE_SLIDE,
  CLONE_SLIDE,
  TOGGLE_SLIDE_WORKING_STATUS,
  ADD_SLIDE_NOTES,
  UPDATE_SLIDE_TITLE,
  SET_SLIDE_TRANSITION,
  ADD_NEW_SECTION_GROUP_SLIDES,
  SET_SLIDE_SECTION_NAME,
  CREATE_SLIDE_FROM_TEMPLATE,
  TOGGLE_TEMPORARILY_DISABLE_SLIDE_TRANSITION,
  SET_SMART_ALIGNMENT,
} from "../actionTypes";

export function changeSlide(value) {
  return {
    type: CHANGE_SLIDE_INDEX,
    value,
  };
}

export function changeSlideOnPresentView(direction) {
  return {
    type: CHANGE_SLIDE_ID_PRESENT_VIEW,
    direction,
  };
}

export function changeSlideByID(value) {
  return {
    type: CHANGE_SLIDE_BY_ID,
    value,
  };
}

export const createSlide = ({
  insertAfterSlideIndex,
  themeID,
  selectSlideOnCreate = true,
}) => ({
  type: ADD_NEW_SLIDE,
  insertAfterSlideIndex,
  themeID,
  selectSlideOnCreate,
});

export const createSlideFromTemplate = (slide, newSlideID, autoApplyCurrentTheme) => ({
  type: CREATE_SLIDE_FROM_TEMPLATE,
  slide,
  newSlideID,
  autoApplyCurrentTheme
});

export const cloneSlide = (slideID, selectSlideOnCreate = true) => ({
  type: CLONE_SLIDE,
  slideID,
  selectSlideOnCreate,
});

export const deleteSlide = (id) => ({
  type: DELETE_SLIDE,
  id,
});

export const switchSlideIndex = ({
  fromIndex,
  toIndex,
  activeSlideID,
  placeUnderSlideID,
  emitForCollaboration,
}) => {
  return {
    type: SWITCH_SLIDE_INDEX,
    fromIndex,
    toIndex,
    activeSlideID,
    placeUnderSlideID,
    emitForCollaboration,
  };
};

export const toggleSlideWorkingStatus = (slideID) => {
  return {
    type: TOGGLE_SLIDE_WORKING_STATUS,
    slideID,
  };
};

export const addSlideNotes = ({slideID, notes}) => {
  return {
    type: ADD_SLIDE_NOTES,
    slideID,
    notes,
  };
};

export const updateSlideTitle = (title) => {
  return {
    type: UPDATE_SLIDE_TITLE,
    title,
  };
};

export function setSlideTransition(payload) {
  return {
    type: SET_SLIDE_TRANSITION,
    payload,
  };
}

export function addNewSectionGroupSlides(value) {
  return {
    type: ADD_NEW_SECTION_GROUP_SLIDES,
    value,
  };
}

export function setSlideSectionName(value) {
  return {
    type: SET_SLIDE_SECTION_NAME,
    value,
  };
}

// view templates, when user clicks on slide link
export function toggleTemporarilyDisableSlideTransition() {
  return {
    type: TOGGLE_TEMPORARILY_DISABLE_SLIDE_TRANSITION,
  };
}

export function setSmartAlignment({type, value}) {
  return {
    type: SET_SMART_ALIGNMENT,
    key: type,
    value,
  };
}
