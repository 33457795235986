import React, {useEffect, useRef, useState, useCallback} from "react";
import {
  Modal,
  Spinner,
  BottomUpDrawer,
} from "../../../oat-ui";
import {
  Layout,
  ErrorMsg,
  imageStyles,
} from "./../styles/templateListStyles";
import TemplateTags from "./../TemplateTags";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";
import {useLocation} from "react-router";
import ImagesIconsPackDetails from "./ImagesIconsPackDetails";
import styled from 'styled-components'
import {WrapSection, NothingHere, PricingTag, WrapSpinner } from "./../styles/itemListStyles";
import { PricingType } from "../styles/itemDetailsStyles";
import { black3 } from "../../../oat-color-variables";
import PurchaseOptions from "../purchaseOptions/PurchaseOptions";
import { requiresUpgradeToProStyles } from "../../../oat-design-template/components/editDesign/parts/upgradeToPro";

const DisplayItem = ({item, onOpenModal}) => {
  const itemRef = useRef();
  const [itemSize, setItemSize] = useState({w: 0, h: 0});
  const [loading, setLoading] = useState(true);
  const windowSize = getWindowSize();
  
  const handleClick = () => {
    onOpenModal(item);
  };

  useEffect(() => {
    const width = itemRef.current.offsetWidth;
    const height = itemRef.current.offsetWidth;
    setItemSize({w: width, h: height})
  }, [windowSize.width]);

  const onFinishedLoading = () => {
    setLoading(false)
  };
  
  return (
    <Item 
      onClick={handleClick} 
      ref={itemRef} 
      style={{
        height: itemSize.h + 70,
        padding: '12px',
        background: 'rgb(255, 255, 255)',
        borderRadius: 12,
        border: '1px solid rgb(226 226 226)',
        boxSizing: 'border-box',
        cursor: 'pointer'
      }}
    >
      <Image
        src={`https://oat-images-icons.s3.amazonaws.com/${item.image}`}
        loading="lazy"
        style={{ 
          width: itemSize.w, 
          height: itemSize.h - 24,
          background: '#f9faff',
          transition: 'none',
          padding: window.innerWidth <= 600 ? 12 : 40
        }}
        onLoad={onFinishedLoading}
      />
      <h4>{item.packName}</h4>
      <ImageInfo style={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
      }}>
        <div style={{display: 'flex'}}>
          {item.availableFormat && item.availableFormat.map((item) => (
            <p style={{paddingRight: 8, textTransform: 'uppercase'}}>{item}</p> 
          ))}
        </div>
        {/* do not delete - later replace */}
        {/* <PricingType premium={item.premium}>
          {item.premium ? `$ ${item.price.value}` : 'free'}
        </PricingType> */}
        {item.premium ? <RequiresUpgradeToPro>Pro</RequiresUpgradeToPro> : <PricingType>free</PricingType>}
      </ImageInfo>
      {/* <PricingTag>
        Free
      </PricingTag> */}
      {/* <WrapTempInfo>
        <ItemName style={{flex: 4}} onClick={linkToTemplate}>
          {item.title}
        </ItemName>
      </WrapTempInfo> */}
    </Item>
  );
};

const ImagesIcons = () => {
  const [openModal, setOpenModal] = useState({
    status: false,
    type: null
  });
  const windowSize = getWindowSize();
  const query = new URLSearchParams(useLocation().search);
  const tagParam = query.get("tag");
  // request workspace and folder to store template if not provided
  const [requestTempLocation, setRequestTempLocation] = useState({
    status: false,
    workspaceID: null,
    folderID: null,
    tempInfo: {},
  });
  const [page, setPage] = useState(1);
  const [imagesIcons, setImagesIcons] = useState({
    items: [],
    selectedItem: null,
    error: null,
    lastEvaluatedKey: null,
    initialLoad: true,
  });
  const [tag, setTag] = useState({
    items: [],
    selected: "",
  });
  const [loading, setLoading] = useState({
    buttonSpinner: false,
    spinner: false,
  });

  const fetchAPI = (url, lastEvaluatedKey) => {
    if (imagesIcons.initialLoad) {
      setLoading({
        ...loading,
        spinner: true, 
      });
    } else {
      setLoading({
        ...loading,
        buttonSpinner: true,
      });
    }
    
    const requestOptions = {
      method: "GET",
      cache: "no-store",
      headers: {"Content-Type": "application/json"},
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.body.items.length > 0) {
          setImagesIcons({
            ...imagesIcons,
            items: [...imagesIcons.items, ...data.body.items],
            lastEvaluatedKey: data.body.paginationToken,
            initialLoad: false
          });
        } else if (data.body.items.length === 0) {
          setImagesIcons({
            items: [],
            selectedItem: null,
            error: null,
            lastEvaluatedKey: null,
            initialLoad: false
          });
        }
        setLoading({
          ...loading,
          spinner: false,
          buttonSpinner: false,
        });
      })
      .catch((err) => {
        setLoading({
          ...loading,
          spinner: false,
          buttonSpinner: false,
        });
      });
  };

  useEffect(() => {
    fetchAPI(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/iconsSalesList`, null
    );
    if (tagParam) {
      setTag({
        ...tag,
        selected: tagParam,
      });
    }
  }, []);

  const onDisplayPackItems = (item) => {
    setOpenModal({
      status: true,
      type: "display-pack-items"
    });
    setImagesIcons({
      ...imagesIcons,
      selectedItem: item,
    });
    const bodyElt = document.querySelector("body");
    bodyElt.style.overflow = "hidden";
  };

  const onSelectPurchaseOption = (item) => {
    setOpenModal({
      status: true,
      type: "display-purchase-option"
    });
    const bodyElt = document.querySelector("body");
    bodyElt.style.overflow = "hidden";
  };

  const handleModalClose = () => {
    setOpenModal({
      staus: false,
      type: null
    });
    setRequestTempLocation({...requestTempLocation, status: false});
    const bodyElt = document.querySelector("body");
    bodyElt.style.overflow = "auto";
  };

  useEffect(() => {
    if (page !== 1) {
      fetchAPI(
        `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/icons&paginationToken=${imagesIcons.lastEvaluatedKey}`,
        imagesIcons.lastEvaluatedKey
      );
    }
  }, [page])

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (imagesIcons.initialLoad) return; 
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async(entries) => {
        if (entries[0].isIntersecting && imagesIcons.lastEvaluatedKey !== null) {
          setPage((prev) => prev + 1);
        }
      }, { threshold: 0.5 });
      if (node) observer.current.observe(node);
    },
    [imagesIcons.initialLoad, imagesIcons.lastEvaluatedKey]
  );

  const modalHeight = windowSize.height > 800 ? 700 : windowSize.height - 40;

  return (
    <Layout>
      <WrapSection>
        {/* <TemplateHeader /> */}

        <div style={{ height: 40}} />

        {loading.spinner && imagesIcons.initialLoad ? (
          <WrapSpinner>
            <Spinner />
          </WrapSpinner>
        ) : undefined}

        <div style={{minHeight: window.innerHeight - 150}}>
          {!loading.spinner && imagesIcons.items.length > 0 ? (
            <>
              <Items>
                {imagesIcons.items &&
                  imagesIcons.items.map((item, i) => (
                    <DisplayItem
                      key={i}
                      item={item}
                      onOpenModal={onDisplayPackItems}
                    />
                  ))}
              </Items> 

              {imagesIcons.items && imagesIcons.items?.length > 0 ?
                <>
                  <div ref={lastItemRef} />
                  <div style={{height: 300}} />
                </>
              : undefined}
            </>
          ) : undefined}

          {!loading.spinner && imagesIcons.error !== null ? (
            <ErrorMsg>{imagesIcons.error}</ErrorMsg>
          ) : undefined}

          {!loading.spinner && !imagesIcons.initialLoad && imagesIcons?.items?.length === 0 ? (
            <NothingHere>Nothing here...!</NothingHere>
          ) : undefined}

        </div>
      </WrapSection>

      {openModal.status && openModal.type === "display-pack-items" && !requestTempLocation.status && windowSize.width > 600 ? (
        <Modal
          top={modalHeight > 420 ? (8 * windowSize.height) / 100 : 115}
          width={windowSize.width > 930 ? 930 : windowSize.width - 60}
          height={modalHeight}
          close={handleModalClose}
        >
          <ImagesIconsPackDetails
            modalOpen={openModal}
            selectedItem={imagesIcons.selectedItem}
            height={modalHeight}
            onSelectPurchaseOption={onSelectPurchaseOption}
          />
        </Modal>
      ) : undefined}

      {openModal.status && openModal.type === "display-purchase-option" && !requestTempLocation.status && windowSize.width > 600 ? (
        <Modal 
          top={modalHeight > 420 ? (8 * windowSize.height) / 100 : 115}
          width={360}
          height={290}
          close={handleModalClose}
        >
          <PurchaseOptions 
            selectedItem={imagesIcons.selectedItem} 
            close={handleModalClose}
          />
        </Modal>
      ) : undefined}

      {windowSize.width <= 600 ? (
        <BottomUpDrawer
          open={openModal.status}
          height={window.innerHeight - 60}
          close={handleModalClose}
          styles={{overflowY: 'scroll'}}
        >
          {imagesIcons.selectedItem ? (
            <ImagesIconsPackDetails
              selectedItem={imagesIcons.selectedItem}
              modalOpen={openModal.status}
            />
          ) : undefined}
        </BottomUpDrawer>
      ) : undefined}
    </Layout>
  );
};

const Items = styled.div`
  display: grid;
  columns: 2;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin: 0px 3.5% 0;
  padding-bottom: 90px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 900px) and (max-width: 1499px) {
    padding: 0 calc(15vw - 80px) 110px;
    columns: 3;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 28px;
    margin: 26px 0 0;
  }
  @media screen and (min-width: 1500px) {
    padding-bottom: 110px;
    columns: 4;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
    margin: 26px 0 0;
  }
`;

const Image = styled.img`
  ${imageStyles};
  max-height: 100%;
  object-fit: contain;
  padding: 14px;
  box-sizing: border-box;
`;

export const Item = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  font-size: 13px;
  h4 {
    font-size: 16px;
    color: ${black3};
    margin-bottom: 5px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 600px) {
      font-size: 15px;
      width: 140px;
    }
  }
  @media only screen and (min-width: 601px) {
    &:hover {
      box-shadow: 0px 0px 14px 2px #d2e0f68c;
    }
  }
`;

const ImageInfo = styled.div`
  
`;

const RequiresUpgradeToPro = styled.div`
  ${requiresUpgradeToProStyles}
`;

export default ImagesIcons;
