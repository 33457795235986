import React, {useState} from "react";
import styled from "styled-components";
import {grey1, red1} from "../../../oat-color-variables";
import {Button} from "../../../oat-ui";
import Auth from "@aws-amplify/auth";

const CreateStaff = ({closeModal}) => {
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [form, setForm] = useState({
    email: {
      value: "",
      touched: false,
      min: 3,
      error: false,
      errorMsg: null,
    },
    password: {
      value: "",
      touched: false,
      min: 8,
      error: false,
      errorMsg: null,
    },
    username: {
      value: "",
      touched: false,
      min: 2,
      error: false,
      errorMsg: null,
    },
  });

  const handleInputChange = (e, field) => {
    const {value} = e.target;
    setForm({
      ...form,
      [field]: {
        ...form[field],
        value,
        error: false,
        errorMsg: null,
      },
    });
  };

  const onChangeEmail = (e) => {
    handleInputChange(e, "email");
  };

  const onChangeUsername = (e) => {
    handleInputChange(e, "username");
  };

  const onChangePassword = (e) => {
    handleInputChange(e, "password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {email, username, password} = form;
    if (email.value && password.value && username.value && !processing) {
      const userExists = await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username.value}/checkusername`
      )
        .then((res) => res.json())
        .catch((err) => {
          setProcessing(false);
          setErrorMessage("Something went wrong.");
        });
      if (!userExists) {
        await Auth.signUp({
          username: email.value, // for email
          password: password.value,
          attributes: {
            email: email.value,
            "custom:acc-username": username.value,
            "custom:acc-type": "admin",
          },
        })
          .then(async () => {
            await Auth.signIn(email.value, password.value)
              .then(async () => closeModal())
              .catch(() =>
                setErrorMessage("Something went wrong. Please try again later.")
              );
          })
          .catch(() =>
            setErrorMessage("Provided email has already been used.")
          );
      } else {
        setErrorMessage("Username already exists");
      }
    } else {
      setErrorMessage("Please fill up all the existing fields");
    }
  };

  return (
    <Wrapper>
      <Header>Add New Staff</Header>
      <Field>
        <Name>Email</Name>
        <Input value={form.email.value} onChange={onChangeEmail} />
      </Field>
      <Field>
        <Name>Username</Name>
        <Input value={form.username.value} onChange={onChangeUsername} />
      </Field>
      <Field>
        <Name>Password</Name>
        <Input
          value={form.password.value}
          type="password"
          onChange={onChangePassword}
        />
      </Field>
      <CreateSection>
        <WrapButton>
          <Button onClick={handleSubmit}>Create</Button>
        </WrapButton>
      </CreateSection>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 12px;
`;

const Header = styled.h3`
  font-size: 1.2rem;
  margin: 12px 0 32px;
  color: #525252;
`;

const Field = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: 0.95rem;
`;

const Name = styled.div`
  flex: 1;
`;

const Input = styled.input`
  flex: 2;
  height: 24px;
  border: 1px solid ${grey1};
`;

const CreateSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const WrapButton = styled.div`
  height: 34px;
  width: 140px;
`;

const ErrorMessage = styled.div`
  font-size: 0.95rem;
  color: ${red1};
  padding: 4px 0;
  margin-top: 10px;
  text-align: right;
`;

export default CreateStaff;
