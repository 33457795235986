import React, {useState} from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {Title} from "../../shared/reuseStyles/Title";
import {Accordion, Expand, WrapBox} from "../common/AccordionStyles";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import TextAlign from "../common/TextAlign";
import {WrapColorBox} from "../common/WrapColorStyles";
import SelectFontSize from "../common/SelectFontSize";
import SelectFontFamily from "../common/fontFamily/SelectFontFamily";
import Switch from "../../shared/inputs/Switch";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {SplitLine} from "../common/SplitLine";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

let delayDebounce;
const EditTableBody = ({
  slideID,
  fieldID,
  storeFieldUndoRedo,
  styles,
  updateFieldStyles,
  updateFieldCollaboration,
}) => {
  const [openAccordion, setOpenAccordion] = useState(true);
  const windowSize = getWindowSize();

  const onUpdateStyles = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const onEmitValue = (type, value) => {
    updateFieldCollaboration();
    storeFieldUndoRedo();
  };

  const toggleAccordion = () => {
    setOpenAccordion(!openAccordion);
  };

  const updateTableFontSize = (value) => {
    onUpdateStyles("fontSize", value);
    clearTimeout(delayDebounce);
    delayDebounce = setTimeout(() => {
      updateFieldCollaboration();
    }, 2000);
    return () => clearTimeout(delayDebounce);
  };

  const updateTableBackground = (value) => {
    onUpdateStyles("background", value);
  };

  const updateTableTextColor = (value) => {
    onUpdateStyles("color", value);
  };

  const updateTableTextAlign = (value) => {
    onUpdateStyles("textAlign", value);
    onEmitValue("textAlign", value);
  };

  const onChangeBoldness = () => {
    const fontWeightAsNumber = Number(styles?.fontWeight);
    const value = fontWeightAsNumber <= 500 ? "700" : "400";
    onUpdateStyles("fontWeight", value);
    onEmitValue("fontWeight", value);
  };

  const onTextColorDebounce = (value) => {
    onEmitValue("color", value);
  };

  const onBgColorDebounce = (value) => {
    onEmitValue("background", value);
  };

  const onChangeStripeStatus = (value) => {
    onUpdateStyles("stripeRows", value);
    onEmitValue("stripeRows", value);
  };

  const updateTableStripeBackground = (value) => {
    onUpdateStyles("stripeBackground", value);
    onEmitValue("stripeBackground", value);
  };

  const updateTableStripeText = (value) => {
    onUpdateStyles("stripeColor", value);
    onEmitValue("stripeColor", value);
  };

  const onStripeColorDebounce = (value) => {
    onEmitValue("stripeColor", value);
  };

  const onStripeBgDebounce = (value) => {
    onEmitValue("stripeBackground", value);
  };

  return (
    <>
      {windowSize.width >= 850 ? (
        <Accordion onClick={toggleAccordion}>
          <Title>Table Cell</Title>
          <Expand>{openAccordion ? "-" : "+"}</Expand>
        </Accordion>
      ) : undefined}

      {openAccordion || windowSize.width < 850 ? (
        <WrapBox>
          <div style={{paddingBottom: "1.5em"}}>
            <Switch
              title="Bold"
              value={styles?.fontWeight == "700" ? true : false}
              onItemChange={onChangeBoldness}
              checked={styles?.fontWeight == "700" ? true : false}
            />
          </div>

          <div style={{paddingBottom: "1.5em", display: "flex"}}>
            <TextAlign onSelect={updateTableTextAlign} />
          </div>

          <div style={{paddingBottom: "1.5em"}}>
            <SelectFontSize
              value={styles?.fontSize}
              onSelect={updateTableFontSize}
            />
          </div>

          <div style={{paddingBottom: "1.5em"}}>
            <SelectFontFamily
              fontFamily={styles?.fontFamily}
              fontWeight={styles?.fontWeight}
              fontStyle={styles?.fontStyle}
            />
          </div>

          <div style={{paddingBottom: "1.5em"}}>
            <WrapColorBox>
              <Title>Color</Title>
              <PopoverColorBox
                value={styles?.color}
                updateFieldStyles={updateTableTextColor}
                onDebounce={onTextColorDebounce}
              />
            </WrapColorBox>
          </div>

          <div>
            <WrapColorBox>
              <Title>Background</Title>
              <PopoverColorBox
                value={styles?.background}
                updateFieldStyles={updateTableBackground}
                onDebounce={onBgColorDebounce}
              />
            </WrapColorBox>
          </div>

          <SplitLine />

          <div style={{paddingBottom: styles?.stripeRows ? "1.5em" : "1em" }}>
            <Switch
              title="Stripe Table"
              value={styles?.stripeRows}
              onItemChange={onChangeStripeStatus}
              checked={styles?.stripeRows}
            />
          </div>

          {styles?.stripeRows ?
            <>
              <div style={{paddingBottom: "1.5em"}}>
                <WrapColorBox>
                  <Title>Color</Title>
                  <PopoverColorBox
                    value={styles?.stripeColor}
                    updateFieldStyles={updateTableStripeText}
                    onDebounce={onStripeColorDebounce}
                  />
                </WrapColorBox>
              </div>

              <div style={{paddingBottom: "0.5em"}}>
                <WrapColorBox>
                  <Title>Background</Title>
                  <PopoverColorBox
                    value={styles?.stripeBackground}
                    updateFieldStyles={updateTableStripeBackground}
                    onDebounce={onStripeBgDebounce}
                  />
                </WrapColorBox>
              </div>
            </>
          : undefined}
        </WrapBox>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditTableBody);
