import styled from "styled-components";

export const InputName = styled.div`
  display: flex;
  flex: 1;
  padding-left: 1rem;
  align-items: center;
  font-size: 0.9rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

export const WrapColorBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const WrapColorType = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 2em;
  width: 100%;
`;
