function isFontFaceAdded(fontName) {
  const styleSheets = document.styleSheets;
  for (let i = 0; i < styleSheets.length; i++) {
    const styleSheet = styleSheets[i];
    const rules = styleSheet.cssRules || styleSheet.rules;
    if (rules) {
      for (let j = 0; j < rules.length; j++) {
        const rule = rules[j];
        if (rule.type === CSSRule.FONT_FACE_RULE && rule.style.fontFamily.includes(fontName)) {
          return true; // Font face is already added
        }
      }
    }
  }
  return false; 
}

export const loadUsedFonts = (usedFontList) => {
  if (usedFontList && usedFontList.length > 0) {
    const googleFonts = document.createElement("style");
    const customFonts = document.createElement("style");
    usedFontList.map((item) => {
      if (!item.custom) {
        const fontFamily = item.fontFamily.replace(/\s/g, '+');
        const italic = item.fontStyle === 'italic' ? 'ital,' : '';

        let fontWeight = 400;
        if (item?.fontWeight === 'normal') {
          fontWeight = 400;
        } else if (item?.fontWeight === 'bold') {
          fontWeight = 700;
        } else if (item?.fontWeight) {
          fontWeight = item?.fontWeight;
        } 
        // const fontWeight = item?.fontWeight ? item?.fontWeight === "normal" ? 400
        const weight = `wght@${fontWeight}`;
        // changes in here, should also reflect in common.js in redux store
        googleFonts.appendChild(
          // document.createTextNode(`@import url(https://fonts.googleapis.com/css2?family=${fontFamily}:${italic}${weight}&display=swap);`)
          document.createTextNode(`@import url(https://fonts.googleapis.com/css2?family=${fontFamily}:ital,wght@0,400;0,700;1,400;1,700&display=swap);`)
        );
        // DON'T DELETE - OAT ALL FONT LOAD
        // const format = item?.format ? item?.format : 'ttf';
        // googleFonts.appendChild(
        //   document.createTextNode(
        //     `@font-face {
        //       font-family: "${item.fontFamily}";
        //       font-style: ${item.fontStyle};
        //       font-weight: ${item.fontWeight};
        //       src: url(${`https://oat-all-fonts.s3.amazonaws.com/${item.src}`});
        //       format(${format});
        //       font-display: block;
        //     }`
        //   )
        // );
        document.head.appendChild(googleFonts);
      } else {
        if(!isFontFaceAdded(item.fontFamily + "-" + item.fontWeight + "-" + item.fontStyle)) {
          customFonts.appendChild(
            document.createTextNode(
              `@font-face {
                font-family: "${
                  item.fontFamily + "-" + item.fontWeight + "-" + item.fontStyle
                }";
                font-style: "${item.fontStyle}";
                font-weight: ${item.fontWeight};
                src: url(${`https://oat-users.s3.amazonaws.com/${item.src}`});
                format("${item.format}");
                font-display: block;
              }`
            )
          );
          document.head.appendChild(customFonts);
        }
      }
    });    
  }
};
