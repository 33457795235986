export async function handleDeleteFolder({
  workspaceID,
  username,
  payload,
  selectedFolder,
}) {
  return await fetch(
    selectedFolder.isSubfolder
      ? `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/folders/subfolders`
      : `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/folders`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        workspaceID,
        ...payload,
      }),
    }
  )
    .then((response) => response.json())
    .then((data) => data);
}
