import React, {useState} from "react";
import styled, {css} from "styled-components";
import {Trash, Plus} from "../../../../../../oat-svg-icons";
import {red1} from "../../../../../../oat-color-variables";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import { editShadowStyle } from "./tableStyles";

let clearFunc;
const actionHeight = 25;
export const cellGapColor = "#d2d2d2";

const DisplayRow = ({
  initAddRow,
  initDeleteRow,
  closePopper,
  tempScale,
  row,
  displayPopper,
  insertNewRow,
  index,
  onHighlightToDelete,
  removeTableRow,
  numberOfRows,
  borderWidth,
}) => {
  const onHoverToDeleteRow = (e) => {
    initDeleteRow(e, index);
  };

  const onHoverToAddRow = (e) => {
    initAddRow(e, index);
  };

  const handleRemoveRow = (e) => {
    // e.stopPropagation();
    removeTableRow(row.id);
  };

  const handleInsertCell = () => {
    insertNewRow(index);
  };

  const highlightToDelete = () => {
    onHighlightToDelete(index);
  };

  const cancelHighlightToDelete = () => {
    onHighlightToDelete(null);
  };

  return (
    <WrapItem tempScale={tempScale}>
      <Row
        onMouseEnter={onHoverToDeleteRow}
        onMouseLeave={closePopper}
        style={{height: row.height, overflow: "hidden"}}
        tempScale={tempScale}
      >
        <p>{row.index}</p>
        {displayPopper.status &&
        displayPopper.type === "delete" &&
        displayPopper.index === index ? (
          <Actions
            tempScale={tempScale}
            style={{
              top:
                // row.height - borderWidth,
                row.height / 2 - actionHeight / 2 / tempScale,
              display: numberOfRows === 1 ? "none" : undefined, // last row cannot be removed
            }} 
          >
            <Action 
              onClick={handleRemoveRow} 
              tempScale={tempScale}
            >
              <WrapSvg  
                onMouseEnter={highlightToDelete}
                onMouseLeave={cancelHighlightToDelete}    
                style={{fill: red1}} tempScale={tempScale}
              >
                {Trash}
              </WrapSvg>
            </Action>
          </Actions>
        ) : undefined}
      </Row>
      <AddCellGap
        style={{
          height: 6 / tempScale, // 6 - borderWidth, //
          // index === 0 ? 5 / tempScale : 8 / tempScale, //plusGapWidth,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          left: 0,
          bottom: -borderWidth, //-(6 / tempScale) / 2,
          width: "100%",
          zIndex: 9999,
        }}
        onMouseEnter={onHoverToAddRow}
        onMouseLeave={closePopper}
      >
        <div
          style={{
            width: "100%",
            height: 1 / tempScale,
            background: cellGapColor,
          }}
        />
        {displayPopper.status &&
        displayPopper.type === "add" &&
        displayPopper.index === index ? (
          <Actions
            onMouseEnter={onHoverToAddRow}
            onMouseLeave={closePopper}
            tempScale={tempScale}
          >
            <Action onClick={handleInsertCell} tempScale={tempScale}>
              <WrapSvg style={{fill: "#2b2b30"}} tempScale={tempScale}>
                {Plus}
              </WrapSvg>
            </Action>
          </Actions>
        ) : undefined}
      </AddCellGap>
    </WrapItem>
  );
};

const EditRowCells = ({
  rows,
  onCreate,
  onHoverToDelete,
  tempScale,
  onRemove,
  borderWidth,
}) => {
  const [displayPopper, setDisplayPopper] = useState({
    index: 1,
    status: true,
    type: null,
  });

  const windowSize = getWindowSize();

  const openPopper = (i, type) => {
    setDisplayPopper({
      index: i,
      status: true,
      type,
    });
    clearTimeout(clearFunc);
  };

  const closePopper = (e) => {
    clearFunc = setTimeout(() => {
      setDisplayPopper({
        index: null,
        status: false,
        type: null,
      });
    }, 200);
    e.stopPropagation();
  };

  const insertNewRow = (i) => {
    onCreate(i);
  };

  const displayAddRowIcon = (e, i) => {
    openPopper(i, "add");
    e.stopPropagation();
  };

  const displayDeleteRowIcon = (e, i) => {
    openPopper(i, "delete");
    e.stopPropagation();
  };

  const handleRowDeletion = (id) => {
    onRemove(id);
  };

  return (
    <Wrapper tempScale={tempScale}>
      <AddCellGap
        style={{
          width: "100%",
          height: 6 / tempScale, //plusGapWidth,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          position: "absolute",
          top: 1 / tempScale,
          zIndex: 999,
        }}
        onMouseEnter={(e) => {
          openPopper(-1, "add");
          e.stopPropagation();
        }}
        onMouseLeave={closePopper}
      >
        <div
          style={{
            width: "100%",
            height: 1 - borderWidth, // tempScale,
            background: cellGapColor,
          }}
        />
        {displayPopper.status &&
        displayPopper.type === "add" &&
        displayPopper.index === -1 ? (
          <Actions
            onMouseEnter={(e) => {
              openPopper(-1, "add");
              e.stopPropagation();
            }}
            onMouseLeave={closePopper}
            tempScale={tempScale}
            style={{top: -5 / tempScale}}
          >
            <Action onClick={() => insertNewRow(-1)} tempScale={tempScale}>
              <WrapSvg style={{fill: "#2b2b30"}} tempScale={tempScale}>
                {Plus}
              </WrapSvg>
            </Action>
          </Actions>
        ) : undefined}
      </AddCellGap>

      {windowSize.width >= 850 &&
        rows &&
        rows.map((row, i) => {
          return (
            <DisplayRow
              key={i}
              numberOfRows={rows.length}
              initAddRow={displayAddRowIcon}
              initDeleteRow={displayDeleteRowIcon}
              closePopper={closePopper}
              tempScale={tempScale}
              row={row}
              index={i}
              displayPopper={displayPopper}
              insertNewRow={insertNewRow}
              removeTableRow={handleRowDeletion}
              onHighlightToDelete={onHoverToDelete}
              borderWidth={borderWidth}
            />
          );
        })}
    </Wrapper>
  );
};

export const tableTabStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #505050;
  font-size: 0.75rem;
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  width: ${({tempScale}) => 24 / tempScale}px;
  top: ${({tempScale}) => 12 / tempScale};
  position: absolute;
  left: ${({tempScale}) => -32 / tempScale}px;
  background: #fff;
  border-radius: ${({tempScale}) => 2 / tempScale}px;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  background: #fff;
  width: ${({tempScale}) => 20 / tempScale}px;
  height: 100%;
  border-radius: ${({tempScale}) => 4 / tempScale}px;
  position: absolute;
  left: ${({tempScale}) => -30 / tempScale}px;
  box-shadow: 0px 0px 4px #b0b0b0;
`;

const Row = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${tableTabStyles};
  transition: 0.1s;
  user-select: none;
  font-family: "Nunito", sans-serif;
  p {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const WrapItem = styled.div`
  position: relative;
  p {
    font-size: ${({tempScale}) => 14 / tempScale}px;
    letter-spacing: 1px;
  }
  &:last-child {
    border: none;
  }
`;

const reuseStyle = css`
  border-bottom: 1px solid #dedede;
  font-weight: 700;
  color: #6e6e6e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:last-child {
    border-bottom: 0px;
  }
`;

const Action = styled.div`
  ${reuseStyle};
  width: ${({tempScale}) => 22 / tempScale}px;
  height: ${({tempScale}) => actionHeight / tempScale}px;
  border-radius: ${({tempScale}) => 4 / tempScale}px;
  box-shadow: ${editShadowStyle};
`;

export const WrapSvg = styled.div`
  width: ${({tempScale}) => 16 / tempScale}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddCellGap = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  @media only screen and (max-width: 849px) {
    display: none;
  }
`;

export default EditRowCells;
