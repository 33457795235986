import React from "react";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import TemplateList from "../components/TemplateList";
import ImagesIcons from "../components/imagesIcons.js/ImagesIcons";

const OatImagesIcons = ({process, templates, error}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <body />
      </Helmet>

      <Layout>
        <ImagesIcons/>
      </Layout>
    </HelmetProvider>
  );
};

const Layout = styled.div``;

export default OatImagesIcons;
