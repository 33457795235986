import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {applyImageFilter} from "../../../../../../store/actions/fields/image.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import placeholderImg from './placeholder-img.png';  // Import the local image

const filters = [
  {
    type: "sepia",
    value: {
      contrast: "90",
      brightness: 110,
      saturate: 150,
      hueRotate: -10,
      sepia: 0,
      grayScale: 0,
    },
  },
  {
    type: "saturate",
    value: {
      contrast: 75,
      brightness: 115,
      saturate: 85,
      hueRotate: 0,
      sepia: 0,
      grayScale: 0,
    },
  },
  {
    type: "sepia",
    value: {
      contrast: 100,
      brightness: 105,
      saturate: 100,
      hueRotate: 340,
      sepia: 0,
      grayScale: 0,
    },
  },
  {
    type: "saturate",
    value: {
      contrast: 75,
      brightness: 125,
      saturate: 180,
      sepia: 0,
      grayScale: 0,
      hueRotate: 0,
    },
  },
];

const DisplayItem = ({image, filter, onApply}) => {
  const {contrast, brightness, saturate, sepia, grayScale, hueRotate} =
    filter?.value || {};

  const handleClick = () => {
    onApply(filter?.value);
  };
  return (
    <Filter>
      <Image
        src={placeholderImg}
        style={{
          filter: `contrast(${contrast}%) brightness(${brightness}%) saturate(${saturate}%) sepia(${sepia}%) grayscale(${grayScale}%) hue-rotate(${hueRotate}deg)`,
        }}
        onClick={handleClick}
      />
    </Filter>
  );
};

const ImageFilters = ({
  slideID,
  fieldID,
  applyImageFilter,
  image,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const chooseFilter = (value) => {
    const active = {slideID, fieldID};
    applyImageFilter(active, value);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <Wrapper>
      {filters.map((filter, i) => {
        return (
          <DisplayItem
            key={i}
            image={image}
            filter={filter}
            onApply={chooseFilter}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex: 1;
  padding: 0.7em 0;
  justify-content: space-evenly;
`;

const Image = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const Filter = styled.div`
  margin-bottom: 2.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 30%;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    image: selectActiveFieldProps(state.designTemplate).content,
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  applyImageFilter,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ImageFilters);
