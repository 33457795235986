export const ToBack = (
  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.6 236.1">
    <title>To Back</title>
    <g>
      <path d="M75.2,148.3H18.8A13.8,13.8,0,0,1,5,134.5V18.8A13.8,13.8,0,0,1,18.8,5H134.5a13.8,13.8,0,0,1,13.8,13.8V79.4" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10"/>
      <line x1="85.2" y1="156" x2="85.2" y2="146" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10"/>
      <path d="M85.2,127.7V101.5A13.8,13.8,0,0,1,99,87.7h16.1" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10" strokeDasharray="18.26 18.26 18.26 18.26"/>
      <path d="M124.2,87.7h90.5a13.8,13.8,0,0,1,13.9,13.8V217.3a13.8,13.8,0,0,1-13.9,13.8H99a13.8,13.8,0,0,1-13.8-13.8V175.1" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10" strokeDasharray="18.26 18.26 18.26 18.26 18.26 18.26"/>
      <line x1="85.2" y1="166" x2="85.2" y2="156" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10"/>
    </g>
  </svg>
);
