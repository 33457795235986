import React, {useState} from "react";
import {connect} from "react-redux";
import {updateThemeBuilderFields} from "../../../store/actions/template/theme.action";
import {selectThemeBuilder} from "../../../store/selectors/template/theme.selector";
import {InputName} from "../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
import PopoverColorBox from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import Switch from "../../editDesign/editingPanel/panelTypes/shared/inputs/Switch";
import {ColorsSection, WrapItem} from "./buildThemeStyle";
import styled from "styled-components";

const ImageThemeBuilder = ({image, updateThemeBuilderFields}) => {
  const handleFieldUpdate = (key, value) => {
    updateThemeBuilderFields({
      category: "image",
      key,
      value,
    });
  };

  const changeShadow = (value) => {
    handleFieldUpdate("shadowColor", value);
  };

  const changeOverlay = (value) => {
    handleFieldUpdate("overlayColor", value);
  };

  const handleDebounceColor = () => {
    // leave empty
  };

  return (
    <>
      <WrapItem style={{margin: 12}}>
        <Message style={{fontSize: "0.85rem"}}>
          Effects will only apply if options are enabled.
        </Message>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Overlay</InputName>
          <PopoverColorBox
            value={image.overlayColor}
            updateFieldStyles={changeOverlay}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>

      <WrapItem>
        <ColorsSection>
          <InputName>Shadow</InputName>
          <PopoverColorBox
            value={image.shadowColor}
            updateFieldStyles={changeShadow}
            onDebounce={handleDebounceColor}
          />
        </ColorsSection>
      </WrapItem>
    </>
  );
};

export const Message = styled.span`
  color: ${({theme}) => theme.deskSecondaryTxt2};
  padding-left: 10px;
  font-style: italic;
`;

const mapStateToProps = (state) => {
  return {
    image: selectThemeBuilder(state.designTemplate)["image"],
  };
};

export default connect(mapStateToProps, {updateThemeBuilderFields})(
  ImageThemeBuilder
);
