import React, {useState, useRef, useEffect} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {
  storeFieldAsActiveUndoRedo,
  storeFieldAsHistoryUndoRedo,
} from "../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../store/selectors/fields.selector";

let delayDebounce;

const SelectOption = ({item, handleSelectItem, selected}) => {
  const onSelectItem = () => {
    handleSelectItem(item);
  };
  return (
    <Option active={selected === item.value} onClick={onSelectItem}>
      <p>{item.display}</p>
    </Option>
  );
};

const IncrementDropDown = ({
  onSelect,
  selected,
  options,
  minWidth,
  height,
  overflowY,
  fieldID,
  boxWidth,
  storeFieldAsActiveUndoRedo,
  storeFieldAsHistoryUndoRedo,
}) => {
  const [open, setOpen] = useState(false);
  const [optionsReversed, setOptionsReversed] = useState([]);

  useEffect(() => {
    setOptionsReversed(options.slice().reverse());
  }, [options]);

  const selectRef = useRef(null);
  const boxRef = useRef(null);

  // reinitiating here cuz cursor getting back in the end error
  // after typing from the front.
  const [currentValue, setCurrentValue] = useState(selected);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        boxRef.current &&
        selectRef.current &&
        !boxRef.current.contains(event.target) &&
        !selectRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef, selected]);

  const handleToggle = () => {
    if (!open) {
      setOpen(true);
      storeFieldAsHistoryUndoRedo();
    }
  };

  const handleSelectItem = (item) => {
    onSelect(item.value);
    setCurrentValue(item.value);
    storeFieldAsActiveUndoRedo();
  };

  function handleInputChange(e) {
    const {value} = e.target;
    const validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(value)) {
      setCurrentValue(value);
      clearTimeout(delayDebounce);
      delayDebounce = setTimeout(() => {
        onSelect(value);
        storeFieldAsActiveUndoRedo();
      }, 1200);
      return () => clearTimeout(delayDebounce);
    }

    e.preventDefault();
    e.stopPropagation();
  }

  const [disableIncrement, setDisableIncrement] = useState(false);
  const [disableDecrement, setDisableDecrement] = useState(false);
  const onIncrement = (e) => {
    // const newValue = currentValue + 1;
    const increment = options.find(
      (item) => item.value > Math.round(currentValue)
    );
    if (increment) {
      setCurrentValue(increment.value);
      onSelect(increment.value);
      storeFieldAsActiveUndoRedo();
    }
    e.stopPropagation();
  };

  const onDecrement = (e) => {
    // const newValue = currentValue - 1;
    const decrement = optionsReversed.find(
      (item) => item.value < Math.floor(currentValue)
    );
    if (decrement) {
      setCurrentValue(decrement.value);
      onSelect(decrement.value);
      storeFieldAsActiveUndoRedo();
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if (options[options.length - 1].value === Math.round(currentValue)) {
      setDisableIncrement(true);
      setDisableDecrement(false);
    } else if (options[0].value === currentValue) {
      setDisableDecrement(true);
      setDisableIncrement(false);
    } else {
      setDisableDecrement(false);
      setDisableIncrement(false);
    }
  }, [selected, fieldID, currentValue]);

  useEffect(() => {
    setCurrentValue(selected);
  }, [fieldID, selected]);

  return (
    <Wrapper>
      <SelectionBox
        ref={boxRef}
        onClick={handleToggle}
        style={{width: minWidth}}
      >
        <Selected
          type="text"
          value={currentValue}
          onChange={handleInputChange}
          width={minWidth}
        />
        <WrapOperators
          disableIncrement={disableIncrement}
          disableDecrement={disableDecrement}
        >
          <div
            //set zIndex +1 than the below one 
            style={{height: 14, zIndex: 10}}
            onClick={onIncrement}
            className="input-increment"
          >
            +
          </div>
          <div
            style={{height: 14, zIndex: 9}}
            onClick={onDecrement}
            className="input-decrement"
          >
            -
          </div>
        </WrapOperators>
      </SelectionBox>
      {open ? (
        <PopUp ref={selectRef} style={{width: boxWidth, height, overflowY}}>
          {options.map((item, i) => (
            <SelectOption
              key={i}
              item={item}
              handleSelectItem={handleSelectItem}
              selected={selected}
            />
          ))}
        </PopUp>
      ) : undefined}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  text-transform: capitalize;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

const PopUp = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  position: absolute;
  top: 40px;
  left: 0px;
  box-sizing: border-box;
  z-index: 9999;
  box-shadow: ${({theme}) => theme.boxShadow1};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  animation: bouncy 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  @-webkit-keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const operatorStyles = css`
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const WrapOperators = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;
  .input-increment {
    ${operatorStyles};
    font-size: 16px;
    opacity: ${({disableIncrement}) => (disableIncrement ? 0.5 : 1)};
  }
  .input-decrement {
    ${operatorStyles};
    font-size: 18px;
    opacity: ${({disableDecrement}) => (disableDecrement ? 0.5 : 1)};
  }
`;

const SelectionBox = styled.div`
  display: flex;
  width: auto;
  min-width: ${({minWidth}) => minWidth}px;
  height: 30px;
  max-width: 132px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  fill: ${({theme}) => theme.deskPrimaryTxt};
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  z-index: 1;
`;

const Selected = styled.input`
  max-width: ${({width}) => width - 20}px;
  padding: 0 10px 0 3px;
  background: transparent;
  border: none;
  color: inherit;
  box-sizing: border-box;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  height: 30px;
  cursor: pointer;
  margin: 6px;
  border-radius: 8px;
  flex: 1;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg3};
  }
`;

IncrementDropDown.defaultProps = {
  minWidth: 90,
  height: "auto",
  overflowY: "auto",
  boxWidth: 80,
};

const mapStateToProps = (state) => {
  return {
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  storeFieldAsActiveUndoRedo,
  storeFieldAsHistoryUndoRedo,
})(IncrementDropDown);
