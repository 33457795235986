import React, {useState} from "react";
import styled from "styled-components";
import {Button, ButtonSpinner} from "../../../../packages/oat-ui";
import {useParams} from "react-router-dom";

const lngList = [{display: "English", value: "en"}];
const tagList = {
  'social-media': [
    {display: "Hiring", value: "hiring"},
    {display: "Product", value: "product"},
    {display: "Event", value: "event"},
    {display: "Quotes", value: "quotes"},
    {display: "Restaurant Menu", value: "restaurant-menu"},
  ],
  presentation: [
    {display: "Marketing", value: "marketing"},
    {display: "Product", value: "product"},
    {display: "Sales", value: "sales"},
    {display: "Report", value: "report"},
  ],
  layouts: [{display: "Presentation", value: "presentation"}],
};

const DisplayItem = ({tag, active, onSelect}) => {
  const handleClick = () => {
    onSelect(tag.value);
  };
  return (
    <Option active={active} onClick={handleClick}>
      {tag.display}
    </Option>
  );
};

const RequestTemplateInfo = ({emitTemplateInfo}) => {
  const [processing, setProcessing] = useState(false);
  const [form, setForm] = useState({
    lng: "en",
    tags: [],
  });
  let params = useParams();
  const {category} = params;

  const handleLngSelection = (e) => {
    const {value} = e.target;
    setForm({
      ...form,
      lng: value,
    });
  };

  const handleTagSelection = (value) => {
    if (!form.tags.includes(value)) {
      setForm({
        ...form,
        tags: [...form.tags, value],
      });
    } else {
      setForm({
        ...form,
        tags: form.tags.filter((tag) => tag !== value),
      });
    }
  };

  const onSaveAsDraft = () => {
    if (!processing) {
      emitTemplateInfo({payload: form, publish: false});
    }
    setProcessing(true);
  };

  const onPublishTemplate = () => {
    if (!processing) {
      emitTemplateInfo({payload: form, publish: true});
    }
    setProcessing(true);
  };

  return (
    <Layout>
      <Form>
        {/* <Item>
          <Title>Language</Title>
          <WrapInput>
            <Select onChange={handleLngSelection}>
              <option value="" defaultValue="">
                {form.lng === "" ? "Select Language" : undefined}
              </option>
              <option disabled value=""></option>
              {lngList.map((tag, i) => (
                <option value={tag.value} key={i}>
                  {tag.display}
                </option>
              ))}
            </Select>
          </WrapInput>
        </Item> */}

        <WrapButton>
          <Button
            onClick={onSaveAsDraft}
            styles={{
              background: "#c1c5f0",
              color: "#383838",
              fontWeight: 400,
            }}
          >
            {!processing ? "Save as Draft" : <ButtonSpinner />}
          </Button>
        </WrapButton>

        <div
          style={{
            width: "100%",
            height: 1,
            background: "rgb(201 201 201)",
            margin: "22px 0",
          }}
        />

        {tagList[category].length > 0 ? (
          <Item>
            <Title>Tags</Title>
            <WrapInput>
              <Tags>
                {tagList[category] &&
                  tagList[category].map((tag) => (
                    <DisplayItem
                      key={tag.value}
                      tag={tag}
                      active={form.tags.includes(tag.value)}
                      onSelect={handleTagSelection}
                    />
                  ))}
              </Tags>
            </WrapInput>
          </Item>
        ) : undefined}

        <WrapButton>
          <Button onClick={onPublishTemplate}>
            {!processing ? "Publish" : <ButtonSpinner />}
          </Button>
        </WrapButton>
      </Form>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 0.9rem;
  margin-bottom: 18px;
`;

const Title = styled.div`
  flex: 1;
`;

const WrapInput = styled.div`
  flex: 2;
`;

const WrapButton = styled.div`
  display: flex;
  height: 38px;
  margin-top: 12px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Option = styled.div`
  border: 1px solid ${({active}) => (active ? "#9b91fd" : "#c7c6d1")};
  border-radius: 12px;
  background: ${({active}) => (active ? "#dcdeff" : undefined)};
  padding: 5px 14px;
  margin: 0 12px 12px 0;
  cursor: pointer;
`;

export default RequestTemplateInfo;
