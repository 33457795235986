import React, {useState, useEffect, useRef, useCallback, useLayoutEffect} from "react";
import LazyLoad from "react-lazyload";
import {ButtonSpinner, Spinner} from "../../oat-ui";
import {getWindowSize} from "../../oat-window-size";
import {useNavigate} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {selectUserInfo} from "../../../redux/user/authUser";
import {connect} from "react-redux";
import {genUID} from "../../oat-utility";
import {createTemplate} from "./createTemplate";
import {ArrowBack} from "../../oat-svg-icons";
import {
  ImageSection, 
  Layout, 
  ImageList, 
  Image, 
  BottomPanel, 
  BtmChildSection,
  Avatar,
  MadeBy,
  WrapSpinner,
  UseTemplate,
  Creator,
  BackButton,
  WrapImages,
  Error,
  PricingType
} from "./styles/itemDetailsStyles";
import styled from "styled-components";
import { black3 } from "../../oat-color-variables";
import { RequiresUpgradeToPro } from "./styles/templateListStyles";

const DisplayItem = ({item, onSelectImage, selectedIndex, index}) => {
  const [finishedLoading, setFinishedLoading] = useState(false);

  const handleClick = () => {
    onSelectImage(index);
  };

  const onFinishedLoading = () => {
    setFinishedLoading(true);
  }

  return (
    <Item onClick={handleClick} id={`template-details-${index}`}>
      <Image 
        style={{
          boxSizing: 'border-box',
          outline: finishedLoading && selectedIndex === index ? `2px solid #9e9eff` : undefined,
          marginTop: 2,
          marginLeft: 2
        }} 
        onLoad={onFinishedLoading}
        src={`https://d1nryft0rd5acr.cloudfront.net/${item}`} loading="lazy" />
    </Item>
  );
};

const TemplateInfo = ({
  selectedItem,
  user,
  modalOpen,
  height,
  onRequestTempLocation,
}) => {
  const [tempInfo, setTempInfo] = useState({});
  // for mobile and tablets
  const [enlargedDesign, setEnlargedDesign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [thumbnailSizeType, setThumbnailSizeType] = useState("small"); // small medium
  const [error, setError] = useState({status: false, message: null});
  let params = useParams();
  const imageSectionRef = useRef(null);
  let navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const folderID = query.get("folder");
  const workspaceID = query.get("workspace");

  useEffect(() => {
    if (selectedItem) {
      setError({
        status: false, message: null
      });
      setTempInfo({});
      setLoading(true);
      fetch(
        `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template/images/${selectedItem.tempID}/main` //${selectedItem.theme}
      )
        .then((response) => response.json())
        .then((data) => {
          setTempInfo(data);
          setEnlargedDesign(false);
          setSelectedImageIndex(0);
          setLoading(false);
        })
        .catch((err) => {
          setError({
            status: true, message: 'Something went wrong'
          });
        });
    }
  }, [selectedItem.tempID, modalOpen]);

  const changeSelectedImageIndex = (key) => {
    setSelectedImageIndex(key);
    if (windowSize.width < 950) {
      setEnlargedDesign(true);
    }
  };

  const exitEnlargedDesign = () => {
    setEnlargedDesign(false);
  };

  const [imageDisplayReady, setImageDisplayReady] = useState(false);
  const onImageLoaded = () => {
    setImageDisplayReady(true);
  };

  const [panelHeight, setPanelHeight] = useState(null);
  const windowSize = getWindowSize();
  useLayoutEffect(() => {
    if (imageSectionRef.current && imageSectionRef.current.offsetHeight) {
      setPanelHeight(imageSectionRef.current.offsetHeight);
    }
  }, [windowSize.width, imageDisplayReady]);

  const handleCreateTemplate = async (tempID, workspaceID, folderID, requiresUpgrade) => {
    if (error.status) {
      setError({status: false, message: null});
    }  
  
    if (!creatingTemplate) {
      setCreatingTemplate(true);
      await createTemplate({
        tempInfo,
        username: user.username,
        folderID,
        workspaceID,
        category: params.category,
        requiresUpgrade
      })
        .then(async(data) => {
          if (data.status === "success") {
            await sessionStorage.setItem('tempNewlyCreated', JSON.stringify({newlyCreated: true}));
            return await navigate(`/design/${data.tempID}`);
          } else {
            setError({status: true, message: data.message});
          }
          setCreatingTemplate(false);
        })
        .catch((e) => {
          setCreatingTemplate(false);
        });
    }
  };

  const redirectTemplateRoute = async () => {
    const tempID = genUID(10);
    if (!user.loggedIn.status) {
      window.open("/register", "_blank").focus();
      return;
    }

    if (folderID && workspaceID) {
      await handleCreateTemplate(tempID, workspaceID, folderID, tempInfo.requiresUpgrade);
    } else {
      onRequestTempLocation(tempInfo);
    }
  };

  useEffect(() => {
    if (windowSize.width > 600) {
      setThumbnailSizeType("medium");
    } else {
      setThumbnailSizeType("small");
    }
  }, [windowSize.width]);

  const detectShortcutKeys = useCallback(
    async (event) => {
      if (event.keyCode == 39 && selectedImageIndex !== tempInfo?.images['small'].length - 1) {
        setSelectedImageIndex(selectedImageIndex + 1)
      } else if (event.keyCode == 37 && selectedImageIndex !== 0) {
        setSelectedImageIndex(selectedImageIndex - 1)
      } else if (event.keyCode == 38 && selectedImageIndex !== 0 && selectedImageIndex !== 1) {
        event.preventDefault(); 
        setSelectedImageIndex(selectedImageIndex - 2)
      } else if (event.keyCode == 40 && selectedImageIndex !== tempInfo?.images['small'].length - 1 && selectedImageIndex !== tempInfo?.images['small'].length - 2) {
        event.preventDefault(); 
        setSelectedImageIndex(selectedImageIndex + 2)
      }
    }, [setSelectedImageIndex, selectedImageIndex, tempInfo]
  );

  useEffect(() => {
    document.addEventListener("keydown", detectShortcutKeys, false);
    return () => {
      document.removeEventListener("keydown", detectShortcutKeys, false);
    };
  });

  const listRef = useRef(null);
  useEffect(() => {
    // Scroll to the active item when it changes
    if (windowSize.width > 600) {
      if (selectedImageIndex && listRef.current) {
        const activeItemElement = document.getElementById(`template-details-${selectedImageIndex}`);
        if (activeItemElement && activeItemElement.offsetTop > listRef.current.scrollTop + listRef.current.offsetHeight) {
          listRef.current.scrollTo({
            top: activeItemElement.offsetTop - listRef.current.offsetTop,
            behavior: 'smooth',
          });
        } 
        else if (activeItemElement && activeItemElement.offsetTop < listRef.current.scrollTop) {
          listRef.current.scrollTo({
            top: Math.max(0, activeItemElement.offsetTop - activeItemElement.offsetHeight - 30),
            behavior: 'smooth',
          });
        }
      }
    }
  }, [selectedImageIndex, windowSize.width]);

  return (
    <Layout $height={windowSize.height} style={{ height}}>
      {!loading && tempInfo.images ? (
        <>
          <ImageSection 
            // for template-info (details) page full height is necessary
            // but not for the one with modal
            style={{ height: params?.id && windowSize.width < 950 ? windowSize.height : undefined}}>
            {windowSize.width >= 950 ? (
              <WrapMainImage>
                <MainImage
                  ref={imageSectionRef}
                  src={`https://d1nryft0rd5acr.cloudfront.net/${tempInfo.images['medium'][selectedImageIndex]}`}
                  alt=""
                  onLoad={onImageLoaded}
                  loading="lazy"
                />
              </WrapMainImage>
            ) : undefined}

            {enlargedDesign && windowSize.width < 950 ? (
              <>
                <BackButton onClick={exitEnlargedDesign}>
                  {ArrowBack}
                </BackButton>
                <WrapMainImage>
                  <LazyLoad height={300}>
                    <MainImage
                      ref={imageSectionRef}
                      src={`https://d1nryft0rd5acr.cloudfront.net/${tempInfo.images['medium'][selectedImageIndex]}`}
                      alt=""
                      onLoad={onImageLoaded}
                      loading="lazy"
                    />
                  </LazyLoad>
                </WrapMainImage>
              </>
            ) : (
              <WrapImages ref={listRef} panelHeight={panelHeight}>
                <ImageList>
                  {tempInfo.images[thumbnailSizeType] &&
                    tempInfo.images[thumbnailSizeType].map((template, i) => (
                      <DisplayItem
                        key={i}
                        item={template}
                        index={i}
                        onSelectImage={changeSelectedImageIndex}
                        selectedIndex={selectedImageIndex}
                      />
                    ))}
                </ImageList>
              </WrapImages>
            )}
          </ImageSection>

          {(window.innerWidth < 950 && params?.id) || 
            window.innerWidth >= 950 && params?.id && imageDisplayReady ? (
            <DisplayInfo>
              <Creator>
                <div style={{display: 'flex', flex: 1}}>
                  <Avatar loading="lazy" src="https://dnaeh50kru9rd.cloudfront.net/logos/oat-all-logo.jpg" alt="avatar" />
                  <WrapTempInfo>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {/* <MadeBy> 
                        By <span>Oat All</span>
                      </MadeBy> */}
                      <UpdatedAt>
                        {tempInfo?.updatedAt}
                      </UpdatedAt>
                    </div>
                    
                    <TempType style={{ margin: '6px 0 4px'}}>
                      {tempInfo?.designType}
                    </TempType>

                    {tempInfo?.requiresUpgrade ?
                      <RequiresUpgradeToPro>
                        Upgrade
                      </RequiresUpgradeToPro> : 
                      <PricingType>
                        Free
                      </PricingType>
                    }

                    {/* {tempInfo.premium ? 
                      "$" 
                    : 
                      <div style={{width: 56}}>
                        <PricingType>
                          {tempInfo.premium ? "$" : "Free"}
                        </PricingType>
                      </div>
                    } */}
                  </WrapTempInfo>
                </div>
                
                <div style={{display: 'flex', alignItems: 'center', marginTop: windowSize.width <= 600 ? 12 : undefined }}>
                  <UseTemplate
                    onClick={redirectTemplateRoute}
                    style={{
                      opacity: creatingTemplate ? 0.6 : 1,
                      pointerEvents: creatingTemplate ? "none" : undefined,
                    }}
                  >
                    {!creatingTemplate ? "Create Template" : <ButtonSpinner />}
                  </UseTemplate>
                </div>
              </Creator>

            </DisplayInfo>
          ) : undefined}

          {modalOpen ? ( //imageDisplayReady &&
            <BottomPanel>
              {windowSize.width >= 600 ? (
                <BtmChildSection>
                  {/* <Avatar loading="lazy" src="https://images.unsplash.com/photo-1635322641095-682ca42e2b3d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" />
                  <MadeBy to={`/u/aung`}>
                    By <span>Aung Khant</span>
                  </MadeBy> */}
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <TempType style={{ margin: '6px 6px 4px 8px'}}>
                      {tempInfo?.designType}
                    </TempType>
                    <div style={{width: 'auto', marginLeft: 12}}>
                      {/* <PricingType>
                        {tempInfo?.premium ? "$" : "Free"}
                      </PricingType> */}
                      {tempInfo?.requiresUpgrade ?
                        <RequiresUpgradeToPro>
                          Upgrade
                        </RequiresUpgradeToPro> : 
                        <PricingType>
                          Free
                        </PricingType>
                      }
                    </div>
                  </div>
                </BtmChildSection>
              ) : undefined}

              <Error>{error.status ? error.message : undefined}</Error>

              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {windowSize.width >= 600 ? <a href="/design/playground" target="_blank" style={{marginRight: 35, fontSize: 14, textDecoration: 'none', color: 'inherit'}}>Play Around</a> : undefined}
                <BtmChildSection
                  style={{display: "flex", justifyContent: "flex-end"}}
                >
                  <UseTemplate
                    onClick={redirectTemplateRoute}
                    style={{
                      opacity: creatingTemplate ? 0.6 : 1,
                      pointerEvents: creatingTemplate ? "none" : undefined,
                    }}
                  >
                    {!creatingTemplate ? "Create Template" : <ButtonSpinner />}
                  </UseTemplate>
                </BtmChildSection>
              </div>
            </BottomPanel>
          ) : undefined}
        </>
      ) : (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      )}
    </Layout>
  );
};

const WrapMainImage = styled.div`
  @media only screen and (min-width: 950px) {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: flex-start;
  }
  @media only screen and (max-width: 949px) {
    padding-bottom: 3px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

const MainImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 0px 1px 1px #e5e5e5;
  object-fit: contain;
  @media only screen and (max-width: 600px) {
    max-height: 370px;
    width: 100%;
  }
`;

const Item = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
`;

const DisplayInfo = styled.div`
  width: 100%;
  max-width: 90%;
`;

const WrapTempInfo = styled.div`
  display: flex; 
  align-items: center;
`;

const TempType = styled.div`
  font-size: 0.95rem;
  padding: 6px 15px 6px 0;
  color: ${black3};
`;

const UpdatedAt = styled.div`
  font-size: 0.9rem;
  color: #7a7676;
  padding-left: 24px;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(TemplateInfo);
