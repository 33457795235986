import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {updateBackgroundProps} from "../../../../store/actions/fields/background.action";
import {selectActiveBgColor} from "../../../../store/selectors/fields.selector";
import {selectActiveSlideID} from "../../../../store/selectors/template/slide.selector";
import PopoverColorBox from "../../editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import RangeSlider from "../../editingPanel/panelTypes/shared/inputs/RangeSlider";
import Switch from "../../editingPanel/panelTypes/shared/inputs/Switch";
import {storeBackgroundUndoRedo} from "../../../../store/actions/fields/undoRedo.action";
import {useSocket} from "../../../../webSocket/useSocket";
import {Title} from "../../editingPanel/panelTypes/shared/reuseStyles/Title";

let delayDebounce;
const EditBackground = ({
  colors,
  slideID,
  updateBackgroundProps,
  storeBackgroundUndoRedo,
}) => {
  const {emitSocketEvents} = useSocket() || {};

  const handleSocketEmit = (type, value) => {
    const payload = {type, value};
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "changed-background", item: payload});
    }
  };

  const onEnableGradient = () => {
    const value = colors.gradient ? false : true;
    updateBackgroundProps({slideID, type: "gradient", value});
    storeBackgroundUndoRedo();
    handleSocketEmit("gradient", value);
  };

  const changeColorOne = (value) => {
    updateBackgroundProps({slideID, type: "bg1", value});
  };

  const changeColorTwo = (value) => {
    updateBackgroundProps({slideID, type: "bg2", value});
  };

  const onColorOneDebounce = (value) => {
    storeBackgroundUndoRedo("bg1", value);
    handleSocketEmit("bg1", value);
  };

  const onColorTwoDebounce = (value) => {
    storeBackgroundUndoRedo("bg2", value);
    handleSocketEmit("bg2", value);
  };

  const onUpdateAngle = (value) => {
    updateBackgroundProps({slideID, type: "bgAng", value});

    clearTimeout(delayDebounce);
    delayDebounce = setTimeout(() => {
      storeBackgroundUndoRedo();
      handleSocketEmit("bgAng", value);
    }, 5000);
    return () => clearTimeout(delayDebounce);
  };

  return (
    <Layout>
      <Switch
        title="Gradient"
        value={colors?.gradient}
        checkValue={colors?.gradient}
        onItemChange={onEnableGradient}
        checked={colors?.gradient}
      />

      <WrapField>
        <Title>Color 1</Title>
        <Field>
          <PopoverColorBox
            value={colors?.bg1}
            updateFieldStyles={changeColorOne}
            onDebounce={onColorOneDebounce}
          />
        </Field>
      </WrapField>

      {colors?.gradient ? (
        <WrapField>
          <Title>Color 2</Title>
          <Field>
            <PopoverColorBox
              value={colors?.bg2}
              updateFieldStyles={changeColorTwo}
              onDebounce={onColorTwoDebounce}
            />
          </Field>
        </WrapField>
      ) : undefined}

      {colors?.gradient && colors?.type === "linear" ? (
        <WrapField>
          {/* <Name>Angle</Name> */}
          <RangeSlider
            title="Angle"
            value={colors?.bgAng}
            max={360}
            min={0}
            step={1}
            onItemChange={onUpdateAngle}
          />
        </WrapField>
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 16px;
`;

const WrapField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`;

const Name = styled.div`
  flex: 1;
`;

const Field = styled.div`
  flex: 1;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideID(state.designTemplate),
    colors: selectActiveBgColor(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateBackgroundProps,
  storeBackgroundUndoRedo,
})(EditBackground);
