import React from "react";
import styled from "styled-components";
import {getWindowSize} from "../../../oat-window-size";
import {folderPanelWidth} from "../layout/AdminSidebar";
import {navPanelHeight} from "../layout/NavBar";

const DashboardPanel = ({children}) => {
  const windowSize = getWindowSize();
  return (
    <Layout>
      <Wrapper>
        <Container width={windowSize.width}>
          <PanelArea>{children}</PanelArea>
        </Container>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  overflow-y: scroll;
  height: ${window.innerHeight - navPanelHeight}px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 849px) {
    position: relative;
    left: 0px;
  }
`;

const Container = styled.div`
  width: ${({width}) => width - folderPanelWidth - 2}px;
  @media only screen and (max-width: 849px) {
    width: ${({width}) => width}px;
  }
`;

const PanelArea = styled.div`
  padding: 0 15px 15px 16px;
  min-height: ${window.innerHeight}px;
`;

export default DashboardPanel;
