import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {createChart} from "../../../../../store/actions/fields/chart.action";
import {WrapTabs, Tab, WrapColumnTabs} from "../../reusableStyles/TabStyles";
import {
  XScrollItems,
  ItemName,
  Header,
  WrapCollection,
  Collection,
  Item,
} from "../../reusableStyles/ModalContent";
import {
  PieChartIcon,
  LineChartIcon,
  SingleColumnChart,
  AreaChartIcon,
  SingleBarChartIcon,
  DonutChartIcon,
  MultiColumnChartIcon,
  StackedColumnIcon,
} from "../chartIcons";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../store/selectors/template/template.selector";
import {storeActionsUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {createShortKey} from "../../../../../store/reducers/functions/fields/utils";
import {closeDrawer} from "../../../../../store/actions/layout.action";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import {chartDefaultFontSize} from "../../../canvasPanel/fields/charts/chartDefaultFontSize";

const DisplayChartSvg = ({chartType}) => {
  let display = null;
  switch (chartType) {
    case "pie":
      display = PieChartIcon;
      break;
    case "single-column-chart":
      display = SingleColumnChart;
      break;
    case "single-bar-chart":
      display = SingleBarChartIcon;
      break;
    case "line-chart":
      display = LineChartIcon;
      break;
    case "area-chart":
      display = AreaChartIcon;
      break;
    case "stacked-area-chart":
      display = AreaChartIcon;
      break;
    case "donut":
      display = DonutChartIcon;
      break;
    case "multi-column-chart":
      display = MultiColumnChartIcon;
      break;
    case "stacked-column-chart":
      display = StackedColumnIcon;
      break;
    default:
      display = "";
  }

  return display;
};

const CreateChart = ({
  createChart,
  closeModal,
  closeDrawer,
  tempSize,
  storeActionsUndoRedo,
  tempScale,
  slideID,
}) => {
  // const canvasPanel =
  //   ((windowSize.width - sidebarWidth - slideActionsPanel) * canvasInPercent) /
  //   100;
  // const canvasArea = canvasPanel - canvasAreaPadding;
  // const canvasArea = document.getElementById(
  //   "canvas-editable-area"
  // ).offsetWidth;
  // const tempScale = Math.min(
  //   (window.innerHeight - 150) / tempSize.h,
  //   canvasArea / tempSize.w
  // );

  const windowSize = getWindowSize();
  const ratioDiff = tempScale; //tempSize.w / 536;
  const widthForLandscape = (tempSize.w * tempScale * 45) / 100;
  const widthForPotrait = (tempSize.w * tempScale * 70) / 100;

  const heightForLandscape = (tempSize.w * tempScale * 30) / 100;
  const heightForPotrait = (tempSize.w * tempScale * 55) / 100;

  const assignChartSize =
    tempSize.w >= tempSize.h
      ? {
          w: widthForLandscape,
          h: heightForLandscape,
        }
      : {
          w: widthForPotrait,
          h: heightForPotrait,
        };

  const donutSize =
    tempSize.w >= tempSize.h
      ? (tempSize.w * tempScale * 30) / 100
      : (tempSize.w * tempScale * 60) / 100;
  const chartCollection = [
    {
      category: "pie",
      display: "Pie Charts",
      charts: [
        {
          // 30
          type: "pie",
          display: "Pie Chart",
          size: {
            w: donutSize / ratioDiff,
            h: donutSize / ratioDiff,
          },
          // pie size override in creation
        },
        {
          type: "donut",
          display: "Donut Chart",
          size: {
            w: donutSize / ratioDiff,
            h: donutSize / ratioDiff,
          },
          // donut size override in creation
        },
        // {
        //   type: "gauge",
        //   display: "Gauge Chart",
        //   size: {w: 150 * ratioDiff, h: 75 * ratioDiff},
        // },
      ],
    },
    {
      category: "bar-column",
      display: "Bar Charts",
      charts: [
        {
          type: "single-column-chart",
          display: "Single Column Chart",
          size: {
            w: assignChartSize.w / ratioDiff,
            h: assignChartSize.h / ratioDiff,
          },
        },
        {
          type: "single-bar-chart",
          display: "Single Bar Chart",
          size: {
            w: assignChartSize.w / ratioDiff,
            h: assignChartSize.h / ratioDiff,
          },
        },
        {
          type: "multi-column-chart",
          display: "Multi Column",
          size: {
            w: assignChartSize.w / ratioDiff,
            h: assignChartSize.h / ratioDiff,
          },
        },
        // {
        //   type: "multi-bar-chart",
        //   display: "Multi-bar Chart",
        //   size: {w: 178, h: 154},
        // },
        {
          type: "stacked-column-chart",
          display: "Stacked Column",
          size: {
            w: assignChartSize.w / ratioDiff,
            h: assignChartSize.h / ratioDiff,
          },
        },
      ],
    },
    {
      category: "line-area",
      display: "Line & Area Charts",
      charts: [
        {
          type: "line-chart",
          display: "Line Chart",
          size: {
            w: assignChartSize.w / ratioDiff,
            h: assignChartSize.h / ratioDiff,
          },
        },
        {
          type: "area-chart",
          display: "Area Chart",
          size: {
            w: assignChartSize.w / ratioDiff,
            h: assignChartSize.h / ratioDiff,
          },
        },
        {
          type: "stacked-area-chart",
          display: "Stacked Area Chart",
          size: {
            w: assignChartSize.w / ratioDiff,
            h: assignChartSize.h / ratioDiff,
          },
        },
      ],
    },
  ];

  const handleClose = () => {
    if (windowSize.width >= 850) {
      closeModal();
    } else {
      closeDrawer();
    }
  };

  const handleCreation = (chart) => {
    const scaledItem = 1; // no needed
    const chartStyles = {
      fontSize:
        windowSize.width >= 850
          ? 18 / scaledItem / tempScale
          : 12 / scaledItem / tempScale, // * scaledItem, // valueFontSize
      scale: 1 / tempScale, // * scaledItem, //scaledItem
      barSize: 16 / scaledItem / tempScale,
      legendFontSize:
        windowSize.width >= 850
          ? 18 / (chartDefaultFontSize * tempScale)
          : 12 / (chartDefaultFontSize * tempScale), // chartDefaultFontSize, // 14 / scaledItem / tempScale
      measurementFontSize:
        windowSize.width >= 850
          ? 15 / scaledItem / tempScale
          : 12 / scaledItem / tempScale, // * scaledItem,
      categoryFontSize:
        windowSize.width >= 850
          ? 15 / scaledItem / tempScale
          : 12 / scaledItem / tempScale, // * scaledItem,
      donutGap: 0, // 5 / tempScale > 10 ? 10 : 5 / tempScale, // pie
      dotSize: 5 / scaledItem / tempScale,
      lineSize: 2 / scaledItem / tempScale, // line
      scaleDistance: 12 / scaledItem / tempScale,
    };
    let chartProps;
    if (chart.type === "pie" || chart.type === "gauge") {
      // override size for pie chart
      const pieSize = (tempSize.w * tempScale * 60) / 100;
      chartProps = {
        key: createShortKey(),
        ["styles"]: chartStyles,
        dataSeries: [
          ["Categories", ""],
          ["Choco", 35],
          ["Ice-cream", 20],
          ["Sugar", 30],
          ["Smoothie", 15],
        ],
        type: "chart",
        subtype: chart.type === "pie" ? "pie" : "gauge",
        size: chart.size,
        resizer: chart.type === "pie" ? "corners" : "all",
      };
      createChart(slideID, chartProps);
      handleClose();
    } else if (chart.type === "donut") {
      const donutSize = (tempSize.w * tempScale * 60) / 100;
      chartProps = {
        key: createShortKey(),
        ["styles"]: chartStyles,
        dataSeries: [
          ["Categories", ""],
          ["Choco", 35],
          ["Ice-cream", 20],
          ["Sugar", 30],
          ["Smoothie", 15],
        ],
        type: "chart",
        subtype: "donut",
        size: chart.size,
        resizer: chart.type === "donut" ? "corners" : "all",
      };
      createChart(slideID, chartProps);
      handleClose();
    } else if (
      chart.type === "single-column-chart" ||
      chart.type === "single-bar-chart"
    ) {
      chartProps = {
        key: createShortKey(),
        ["styles"]: {
          ...chartStyles,
          barSize: 16 / scaledItem / tempScale,
        },
        dataSeries: [
          ["Categories", "Half Full"],
          ["Choco", 4],
          ["Ice-cream", 2],
          ["Sugar", 3],
          ["Milk", 6],
          ["Candy", 3],
        ],
        type: "chart",
        subtype:
          chart.type === "single-column-chart" ? "column-chart" : "bar-chart",
        size: chart.size,
        resizer: "all",
      };
      createChart(slideID, chartProps);
      handleClose();
    } else if (chart.type === "multi-column-chart") {
      chartProps = {
        key: createShortKey(),
        ["styles"]: chartStyles,
        dataSeries: [
          [
            "Categories",
            "Half Full",
            "Half Empty",
            "Full Loaded",
            "Half Loaded",
          ],
          ["Chocolate", 4, 3, 3, 1],
          ["Ice-cream", 2, 5, 4, 2],
          ["Sugar", 4, 4, 5, 3],
        ],
        type: "chart",
        subtype: "column-chart",
        size: chart.size,
        resizer: "all",
      };
      createChart(slideID, chartProps);
      handleClose();
    } else if (chart.type === "stacked-column-chart") {
      chartProps = {
        key: createShortKey(),
        ["styles"]: {
          ...chartStyles,
          barSize: 36 / scaledItem / tempScale,
        },
        dataSeries: [
          ["Categories", "Half Full", "Half Empty", "Full Loaded"],
          ["Choco", 4, 3, 3],
          ["Ice-cream", 2, 4, 4],
          ["Sugar", 4, 2, 6],
        ],
        type: "chart",
        subtype: chart.type,
        size: chart.size,
        resizer: "all",
      };
      createChart(slideID, chartProps);
      handleClose();
    } else if (
      chart.type === "line-chart" ||
      chart.type === "area-chart" ||
      chart.type === "stacked-area-chart"
    ) {
      chartProps = {
        key: createShortKey(),
        ["styles"]: chartStyles,
        dataSeries: [
          ["Categories", "Half Full", "Half Empty", "Full Loaded"],
          // ["Choco", 4, 3, 3],
          // ["Ice-cream", 2, 4, 4],
          // ["Sugar", 4, 4, 5],
          // ["Choco", 0.04, 0.03, 0.03],
          // ["Ice-cream", -0.02, 0.04, 0.04],
          // ["Sugar", 0.04, 0.04, 0.05],
          ["Choco", 1, 2, 3],
          ["Ice-cream", 3, 2, 5],
          ["Sugar", 1, 3, 5],
          ["Sugar", 2, 2, 7],
        ],
        type: "chart",
        subtype: chart.type,
        size: chart.size,
        resizer: "all",
      };
      createChart(slideID, chartProps);
      handleClose();
    }
    storeActionsUndoRedo("create");
  };

  const [activeTab, setActiveTab] = useState("all");
  const onSelectTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Wrapper>
      {windowSize.width < 850 ? (
        <WrapColumnTabs>
          <TabsSection>
            <WrapTabs>
              <Tab
                onClick={() => onSelectTab("all")}
                selected={activeTab === "all" ? true : false}
              >
                All Charts
              </Tab>
              <Tab
                onClick={() => onSelectTab("pie")}
                selected={activeTab === "pie" ? true : false}
              >
                Pie Charts
              </Tab>
              <Tab
                onClick={() => onSelectTab("bar-column")}
                selected={activeTab === "bar" ? true : false}
              >
                Bar Charts
              </Tab>
              <Tab
                onClick={() => onSelectTab("line-area")}
                selected={activeTab === "area" ? true : false}
              >
                Area & Line Charts
              </Tab>
            </WrapTabs>
          </TabsSection>
        </WrapColumnTabs>
      ) : undefined}

      <WrapCollection>
        {chartCollection.map((collection, i) => {
          if (activeTab === "all") {
            return (
              <Collection key={i}>
                <Header>{collection.display}</Header>
                <XScrollItems>
                  {collection.charts.map((chart, index) => (
                    <Item key={index} onClick={() => handleCreation(chart)}>
                      <div style={{width: 100, height: 100}}>
                        <DisplayChartSvg chartType={chart.type} />
                      </div>
                      <ItemName>{chart.display}</ItemName>
                    </Item>
                  ))}
                </XScrollItems>
              </Collection>
            );
          } else if (collection.category === activeTab) {
            return (
              <Collection key={i}>
                <Header>{collection.display}</Header>
                <XScrollItems>
                  {collection.charts.map((chart, index) => (
                    <Item key={index} onClick={() => handleCreation(chart)}>
                      <DisplayChartSvg chartType={chart.type} />
                      <ItemName>{chart.display}</ItemName>
                    </Item>
                  ))}
                </XScrollItems>
              </Collection>
            );
          }
        })}
      </WrapCollection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 850px) {
    flex-direction: row;
  }
`;

const TabsSection = styled.div`
  @media only screen and (min-width: 850px) {
    display: none;
  }
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    tempSize: selectTemplateSize(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  createChart,
  closeDrawer,
  storeActionsUndoRedo,
})(CreateChart);
