import React from 'react';
import styled from "styled-components";

const PricingInterval = ({pricingInterval, onToggle, background, slider}) => {
    return (
        <WrapSwitch background={background} slider={slider}>
          <p style={{paddingRight: 12}}>Monthly</p>
          <label className="oatall-input-switch">
            <input
              type="checkbox"
              value={pricingInterval}
              onChange={onToggle}
              checked={pricingInterval === "yearly"}
            />
            <span className="slider round" style={{background}} />
          </label>
          <p style={{paddingLeft: 12}}></p>
        </WrapSwitch> 
    );
};

const WrapSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.92rem;
  flex: 1;
  width: 100%;

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: -4px;
    bottom: 0;
    background-color: #b9b2d0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: ${({slider}) => slider ? slider : '#fff'};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
  }
  
  input:checked + .slider {
    background-color: ${({background}) => background ? background : '#857aff'};
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #6b70f9;
  }
`;

export default PricingInterval;