import React, {useState, useEffect, useLayoutEffect} from "react";
import Bar from "./barProps/Bar";
import ValueLabel from "./common/ValueLabel";
import ShowLegends from "./common/ShowLegends";
import ScaleGrid from "./barProps/ScaleGrid";
import {useScaleLine} from "./hooks/useScaleLine";
import ScaleLabels from "./barProps/ScaleLabels";
import {ScaledChart, WrapChart} from "../chartStyles";
import CategoryLabels from "./common/CategoryLabels";
import {connect} from "react-redux";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../../store/selectors/template/template.selector";
import ColumnBar from "./barProps/ColumnBar";

const edgeWidthPercent = 20;
const ColumnChart = ({
  size,
  dataSeries,
  styles,
  tempScale,
  tempSize,
  colorSet,
}) => {
  const {
    showBackground,
    background,
    gridColor,
    showGrid,
    gridStyle,
    showValue,
    showLegend,
    valueColor,
    fontSize,
    fontFamily,
    barSize,
    showXAxis,
    showYAxis,
    axisColor,
    showCategory,
    measurementColor,
    measurementFontSize,
    showMeasurement,
    scaleDistance,
    scaleType,
    scaleFormat,
    currencyUnit,
  } = styles;
  const [barWidth, setBarWidth] = useState(barSize);
  const [dataSet, setDataSet] = useState(dataSeries?.slice(1));
  const seriesLength = dataSet[0].length - 1; // excluding the initial category label
  const [edgeSpace, setEdgeSpace] = useState((size.w * edgeWidthPercent) / 100);
  // series bar width + edges
  const [seriesBarWidth, setSeriesBarWidth] = useState(
    dataSet.length * (barWidth * seriesLength) + edgeSpace * 2
  );
  const [barMargin, setBarMargin] = useState(
    (size.w - seriesBarWidth) / (dataSet.length - 1)
  );
  const [highestPositiveValue, setHighestPositiveValue] = useState(0);
  const [lowestNegativeValue, setLowestNegativeValue] = useState(0);
  const [categoryLabels, setCategoryLabels] = useState([]);

  useLayoutEffect(() => {
    let arrValues = [];
    let categories = [];
    const dataSet = dataSeries?.slice(1);
    setDataSet(dataSet);
    dataSet && dataSet.map((datum) => {
      categories.push(datum[0]);
      datum.map((item, i) => {
        if (i !== 0 && item !== "") {
          arrValues.push(item);
        }
      });
    });

    setCategoryLabels(categories);
    const getMaxValue = Math.max(...arrValues);
    const getMinValue = Math.min(...arrValues);
    // highest must either be positive value or starts with zero
    const highestWithoutNegative = getMaxValue < 0 ? 0 : getMaxValue;
    setHighestPositiveValue(highestWithoutNegative);

    if (getMinValue < 0) {
      setLowestNegativeValue(getMinValue);
    } else {
      setLowestNegativeValue(0);
    }
  }, [dataSeries]);

  useLayoutEffect(() => {
    setBarWidth(barSize);
    setBarMargin(
      (size.w - (dataSet.length * (barSize * seriesLength) + edgeSpace * 2)) /
        (dataSet.length - 1)
    );
  }, [barSize, dataSet, fontSize]);

  useLayoutEffect(() => {
    if (dataSet.length > 2) {
      const calcMargin = size.w - dataSet.length * (barSize * seriesLength);
      setBarMargin(calcMargin / dataSet.length);
      setEdgeSpace(calcMargin / dataSet.length / 2);
    } else {
      const remaingSpace = size.w / 2 - barWidth * seriesLength;
      setEdgeSpace(remaingSpace / 2);
      setBarMargin(remaingSpace);
    }
  }, [size.w, dataSet, barSize]);

  const {chartSpectrum, scaleLine, digitsLength} = useScaleLine({
    highestPositiveValue,
    lowestNegativeValue,
  });

  const valueHeightGap =
    tempSize.w > tempSize.h
      ? (1.1 * tempSize.h) / 100
      : (0.3 * tempSize.h) / 100;

  const [totalValueHeight, setTotalValueHeight] = useState(0);
  const [totalHeightInPx, setTotalHeightInPx] = useState({
    positive: 0,
    negative: 0,
  });

  useLayoutEffect(() => {
    const totalValueHeight = chartSpectrum.positive + chartSpectrum.negative;
    setTotalValueHeight(totalValueHeight);
    if (
      (chartSpectrum.positive || chartSpectrum.negative) &&
      totalValueHeight
    ) {
      setTotalHeightInPx({
        positive: (chartSpectrum.positive * size.h) / totalValueHeight,
        negative: (chartSpectrum.negative * size.h) / totalValueHeight,
      });
    }
  }, [size, chartSpectrum]);

  return (
    <WrapChart>
      <ScaledChart>
        <svg
          width={size.w}
          height={size.h}
          viewBox={`0 0 ${size.w} ${size.h}`}
          style={{background: showBackground ? background : undefined}}
        >
          {chartSpectrum &&
          (chartSpectrum.negative || chartSpectrum.positive) &&
          (totalHeightInPx.negative || totalHeightInPx.positive) ? (
            <g>
              {dataSet &&
                dataSet.map((items, index) => {
                  const datum = items.slice(1);
                  return datum.map((item, i) => {
                    const barX =
                      index *
                        (barWidth + barWidth * (seriesLength - 1) + barMargin) +
                      i * barWidth;
                    const barY =
                      item > 0
                        ? (totalHeightInPx.positive *
                            (chartSpectrum.positive - item)) /
                          chartSpectrum.positive
                        : (totalHeightInPx.positive * chartSpectrum.negative) /
                          chartSpectrum.negative;
                    const itemHeight =
                      item > 0
                        ? (item * totalHeightInPx.positive) /
                          chartSpectrum.positive
                        : (Math.abs(item) * totalHeightInPx.negative) /
                          chartSpectrum.negative;
                    return (
                      <g key={i}>
                        <ColumnBar
                          fill={colorSet[i]}
                          x={barX + edgeSpace}
                          y={barY}
                          width={barWidth}
                          height={itemHeight}
                          chartHeight={size.h}
                        />

                        {showValue && item !== 0 ? (
                          <ValueLabel
                            key={index}
                            x={barX + edgeSpace + barWidth / 2}
                            y={
                              item >= 0
                                ? barY - valueHeightGap
                                : valueHeightGap + barY + itemHeight + fontSize
                            }
                            color={valueColor}
                            width={barWidth}
                            value={item}
                            fontSize={fontSize}
                            fontFamily={fontFamily}
                            scaleFormat={scaleFormat}
                            textAnchor="middle"
                            alignmentBaseline="alphabetic"
                            dominantBaseline="alphabetic"
                          />
                        ) : undefined}
                      </g>
                    );
                  });
                })}
            </g>
          ) : undefined}

          {showCategory ? (
            <CategoryLabels
              styles={styles}
              chartHeight={size.h}
              labels={categoryLabels}
              barMargin={barMargin}
              barWidth={barSize}
              edgeSpace={barMargin / 2}
              seriesLength={seriesLength}
            />
          ) : undefined}

          {showGrid ? (
            scaleLine && Object.keys(scaleLine).length !== 0 ? (
              <g>
                <ScaleGrid
                  color={gridColor}
                  gridStyle={gridStyle}
                  item={scaleLine}
                  width={size.w}
                  gap={(scaleLine.acc * size.h) / scaleLine.total}
                />
              </g>
            ) : undefined
          ) : undefined}

          {showXAxis && totalValueHeight ? (
            <g>
              <line
                x1={0}
                x2={size.w}
                y1={(size.h * (chartSpectrum.positive - 0)) / totalValueHeight}
                y2={(size.h * (chartSpectrum.positive - 0)) / totalValueHeight}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              />
            </g>
          ) : undefined}

          {showYAxis ? (
            <g>
              <line
                x1={0}
                x2={0}
                y1={0}
                y2={size.h}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              ></line>
            </g>
          ) : undefined}

          {showMeasurement &&
          (chartSpectrum.positive || chartSpectrum.negative) ? (
            scaleLine.acc && Object.keys(scaleLine).length !== 0 ? (
              <g>
                <ScaleLabels
                  fontSize={measurementFontSize}
                  fontFamily={fontFamily}
                  color={measurementColor}
                  tickColor={axisColor}
                  item={scaleLine}
                  gap={(scaleLine.acc * size.h) / scaleLine.total}
                  highestValue={
                    chartSpectrum.positive >= 0 ? chartSpectrum.positive : 0
                  }
                  digitsLength={digitsLength}
                  scaleDistance={scaleDistance}
                  scaleType={scaleType}
                  currencyUnit={currencyUnit}
                  scaleFormat={scaleFormat}
                />
              </g>
            ) : undefined
          ) : undefined}
        </svg>
        {showLegend ? (
          <ShowLegends
            items={dataSeries?.[0].slice(1)}
            chartSize={size}
            styles={styles}
            colorSet={colorSet}
          />
        ) : undefined}
      </ScaledChart>
    </WrapChart>
  );
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
    tempSize: selectTemplateSize(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(ColumnChart);
