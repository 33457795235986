export const selectTemplate = (state) => state.template;
export const selectTemplateStatus = (state) => state.loading;
export const getTemplateErr = (state) => state.error;
export const selectTemplateSize = (state) => state.size;
export const selectOrgTempSize = (state) => state.orgTempSize;
export const selectTemplateRatio = (state) => state.ratio;
export const getTemplateLayout = (state) => state.layout;
export const selectTempScale = (state) => state.layout.tempScale;
export const selectTemplateLength = (state) => state.template.length;
export const selectTemplateName = (state) => state.templateName;
export const selectTemplateID = (state) => state.tempID;
export const selectTemplateSelection = (state) => state.templateSelection;
export const selectRefTemplate = (state) => state.refTemp;
export const selectTemplateOwner = (state) => state.owner;
export const selectTempSourceID = (state) => state.tempSourceID;
export const selectTemplateTitle = (state) => state.templateName;
export const selectTempLng = (state) => state.tempLng;
export const selectTempVisibility = (state) => state.visibility;
export const selectUsedFontList = (state) => state.usedFontList;
export const selectTempPasscode = (state) => state.passcode;
export const selectFolder = (state) => state.folder; // active
export const selectWorkspaceID = (state) => state.workspaceID; // active
export const selectEmbeddingType = (state) => state.embedType; // active
export const selectGuestList = (state) => state.guestUsers; // active
export const selectWorkspaceMembers = (state) => state.workspaceMembers; // active
export const selectTemplateCategory = (state) => state.category;
export const selectTemplateVersions = (state) => state.versions;
export const selectWorkspacePlan = (state) => state.workspacePlan;
export const selectIsViewOnDesignPage = (state) => state.viewOnDesignPage;
export const selectUsedFonts = (state) => state.usedFonts;
export const selectIsGuestUser = (state) => state.isGuestUser;