const PieChartIcon = (
  <svg width="100%" height="100%" viewBox="0 0 514.5 514.5">
    <g>
      <path
        className="donut-chart-segment"
        fill="#a8a4f8"
        stroke="none"
        d="M257.25 257.25 L257.25 0 A257.25 257.25 0 0 1 392.6746739001999 475.9683581664432 L257.25 257.25 A0 0 0 0 0 257.25 257.25"
      ></path>
    </g>
    <g>
      <path
        className="donut-chart-segment"
        fill="#eee191"
        stroke="none"
        d="M257.25 257.25 L392.6746739001999 475.9683581664432 A257.25 257.25 0 0 1 51.96006828215843 412.27776020856373 L257.25 257.25 A0 0 0 0 0 257.25 257.25"
      ></path>
    </g>
    <g>
      <path
        className="donut-chart-segment"
        fill="#ef8181"
        stroke="none"
        d="M257.25 257.25 L51.96006828215857 412.2777602085639 A257.25 257.25 0 0 1 257.2499999999997 0 L257.25 257.25 A0 0 0 0 0 257.25 257.25"
      ></path>
    </g>
  </svg>
);

export default PieChartIcon;
