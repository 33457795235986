const LessArrow = (
  // <svg viewBox="0 0 24 24">
  //   <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
  // </svg>
  <svg viewBox="0 0 18 23">
    <g>
      <polyline
        points="17 14.9 9 8.1 1 14.9"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
    </g>
  </svg>
);
export default LessArrow;
