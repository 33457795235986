import {SET_USER_ONLINE, REMOVE_USER_ONLINE, SET_USER_CONNECTION_ID} from "../actionTypes";

export const setUserOnline = (payload) => {
  return {
    type: SET_USER_ONLINE,
    payload,
  };
};

export const removeUserOnline = (payload) => {
  return {
    type: REMOVE_USER_ONLINE,
    payload,
  };
};

export const setUserConnectionId = (payload) => {
  return {
    type: SET_USER_CONNECTION_ID,
    payload,
  };
};
