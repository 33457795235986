import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
  getTemplateLayout,
  selectTemplate,
  selectTemplateSize,
  selectTempScale,
} from "../../store/selectors/template/template.selector";
import styled from "styled-components";
import {setTempScale} from "../../store/actions/template/template.action";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";
import ShowCanvas from "./../viewTemplate/ShowCanvas";

// For screenshoting design
const VerticalFormat = ({
  slides,
  layout,
  canvasSize,
  tempScale,
  setTempScale,
}) => {
  const sidebarWidth = 0;
  const spacing = 0;
  const canvasInPercent = layout.canvasPanel;

  // Set 100 to fullCanvasWidth in template redux layout ! !
  const windowSize = getWindowSize();
  useEffect(() => {
    // const canvasInPx =
    //   ((window.innerWidth - sidebarWidth - spacing) * canvasInPercent) / 100;
    // const calcScale = Math.min(
    //   (window.innerHeight - 100) / canvasSize.h,
    //   canvasInPx / canvasSize.w
    // );
    if (canvasSize.h) {
      const tempHeight = (canvasSize.h * window.innerWidth) / canvasSize.w;
      const calcScale = Math.min(
        tempHeight / canvasSize.h,
        window.innerWidth / canvasSize.w
      );
      setTempScale(calcScale);
    }
  }, [canvasSize, windowSize.width]);

  return (
    slides &&
    slides.map((slide, index) => (
      <WrapTemplate key={slide.id} id={slide.id}>
        {/* <Canvas slide={slide} canvasIndex={index} /> */}
        <ShowCanvas
          canvasIndex={index}
          slide={slide}
          canvasSize={canvasSize}
          scale={tempScale}
          activeSlideIndex={index}
          lastSlide={index === slides.length - 1}
          showChromeBar={false}
          enableAnimation={false}
        />
      </WrapTemplate>
    ))
  );
};

const WrapTemplate = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slides: selectTemplate(designTemplate),
    layout: getTemplateLayout(designTemplate),
    canvasSize: selectTemplateSize(designTemplate),
    tempScale: selectTempScale(designTemplate),
  };
};

export default connect(mapStateToProps, {setTempScale})(VerticalFormat);
