import React, {useState} from "react";
import {connect} from "react-redux";
import {selectTempRatioDiff} from "../../../../../../store/selectors/layout/layout.selector";

const angleForArcLength = (arcLength, atRadius) => arcLength / atRadius;

const GaugeChart = ({size, content, ratioDiff}) => {
  const [orgSize, setOrgSize] = useState(size);
  const d2r = (d) => d * (Math.PI / 180);
  const r2d = (r) => r / (Math.PI / 0);
  const center = size / 2;
  const hollow = (size * 70) / 150;
  const thickness = (size - hollow) / 2;
  const radiusOuter = size / 2;
  const radiusInner = radiusOuter - thickness;
  const gapSize = (size * 1) / orgSize;
  const gapAngleOffsetInner = r2d(angleForArcLength(gapSize, radiusInner));
  const gapAngleOffsetOuter = r2d(angleForArcLength(gapSize, radiusOuter));
  const minAngleDeg = r2d(angleForArcLength(gapSize * 2, radiusInner));
  const minValue = minAngleDeg / 180;

  const coords = (deg, r) => {
    const rad = d2r(deg);
    return [center - Math.cos(rad) * r, center - Math.sin(rad) * r];
  };

  const computePercentages = () => {
    const filtered = (content.data || []).filter(({value}) => value > 0);
    const total = filtered.reduce((t, {value = 0}) => t + value, 0);

    return filtered.map((item) => ({
      ...item,
      percent: item.value / total,
    }));
  };

  const items = computePercentages();

  const makeSegment = ({paths, subtotal}, {percent, color}, i) => {
    const colors = [];
    const startAngle = subtotal * 180; // +90 so we start at 12 o'clock
    const endAngle = startAngle + percent * 180;

    // no gaps for values beneath the minimum threshold
    const useGap = percent >= minValue;
    const innerGap = useGap ? gapAngleOffsetInner : 0;
    const outerGap = useGap ? gapAngleOffsetOuter : 0;

    const startAngleInner = startAngle + innerGap;
    const startAngleOuter = startAngle + outerGap;
    const endAngleInner = endAngle - innerGap;
    const endAngleOuter = endAngle - outerGap;

    const [x1, y1] = coords(startAngleInner, radiusInner); // start point on inner circle
    const [x2, y2] = coords(startAngleOuter, radiusOuter); // start point on outer circle
    const [x3, y3] = coords(endAngleOuter, radiusOuter); // end point on outer circle
    const [x4, y4] = coords(endAngleInner, radiusInner); // end point on inner circle

    const largeArc = percent > 0.5 ? 1 : 0;
    const sweepOuter = 1;
    const sweepInner = 0;

    const commands = [
      `M${x1} ${y1}`, // move to start angle coordinate, inner radius
      `L${x2} ${y2}`, // line to start angle coordinate, outer radius
      // arc to end angle coordinate, outer radius
      `A${radiusOuter} ${radiusOuter} 0 ${largeArc} ${sweepOuter} ${x3} ${y3}`,
      `L${x4} ${y4}`, // line to end angle coordinate, inner radius
      // arc back to start angle coordinate, inner radius
      `A${radiusInner} ${radiusInner} 0 ${largeArc} ${sweepInner} ${x1} ${y1}`,
    ];

    const fill = color || colors[i % colors.length];
    const fillProp = fill ? {fill} : {};

    paths.push(
      <path
        key={i}
        className="donut-chart-segment"
        {...fillProp}
        stroke="none"
        d={commands.join(" ")}
      />
    );

    return {
      paths,
      subtotal: subtotal + percent,
    };
  };

  return (
    <div>
      <svg width={size} height={size / 2} viewBox={`0 0 ${size} ${size / 2}`}>
        {items.reduce(makeSegment, {paths: [], subtotal: 0}).paths}
      </svg>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ratioDiff: selectTempRatioDiff(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(GaugeChart);
