import React from "react";
import {timeSince} from "../../oat-utility";
import {Link, useNavigate, useParams} from "react-router-dom";
import styled, {css} from "styled-components";
import {
  darkGrey1,
  grey4,
  primaryBlueBtn,
  red4,
} from "../../oat-color-variables";

const NotificationItem = ({
  item,
  onAcceptWsInvitation,
  acceptInvitationInfo,
  onClose,
}) => {
  let navigate = useNavigate();
  const {processing, error, wsID, accepted} = acceptInvitationInfo;

  const compareTime = () => {
    const currentTimeStamp = new Date().getTime();
    return timeSince(currentTimeStamp, item.createdAt);
  };

  const acceptInvitation = () => {
    onAcceptWsInvitation(item);
  };

  const navigateToWorkspace = () => {
    onClose();
    navigate(`/dashboard/${item.data.workspaceID}/designs/home`);
  };

  let params = useParams();
  const {workspaceID} = params;
  const navigateToFolder = () => {
    onClose();
    navigate(`/dashboard/${workspaceID}/designs/${item.data}`);
    window.location.reload(true);
  };

  let displayView = "";
  switch (item.type) {
    case "slide-comment":
      let commentLink = "";
      if (
        item.data &&
        (item.data.subcommentID === null ||
          item.data.subcommentID === undefined)
      ) {
        commentLink = `/design/${item.data.tempID}?slide=${item.data.slideID}&comment=${item.data.commentID}`;
      } else {
        commentLink = `/design/${item.data.tempID}?slide=${item.data.slideID}&comment=${item.data.commentID}&subcomment=${item.data.subcommentID}`;
      }
      displayView = (
        <NotiLink to={commentLink}>
          <NotiItem>
            {item.notifier.name} has commented on {item.data.tempName}.
          </NotiItem>
          <Time>{compareTime()}</Time>
        </NotiLink>
      );
      break;
    case "invitation":
      displayView = (
        <>
          <NotiItem>
            {item.notifier.name} has invited you to join workspace{" "}
            {item.data.workspaceName}.
          </NotiItem>
          <Invitations>
            {accepted && wsID === item.data.workspaceID ? (
              <VisitWorkspace onClick={navigateToWorkspace}>
                Go to Workspace
              </VisitWorkspace>
            ) : (
              <Accept onClick={acceptInvitation} processing={processing}>
                Accept
              </Accept>
            )}
            {error ? (
              <div style={{color: red4}}>Something went wrong</div>
            ) : undefined}
            {/* <Decline>Remove</Decline> */}
          </Invitations>
          <Time>{compareTime()}</Time>
        </>
      );
      break;
    case "shared-as-guest":
      let guestAccessLevelLink = "";
      if (item.data.accessLevel === "read-write") {
        guestAccessLevelLink = `/design/${item.data.tempID}`;
      } else {
        guestAccessLevelLink = `/p/${item.data.tempID}`;
      }
      displayView = (
        <NotiLink to={guestAccessLevelLink}>
          <NotiItem>
            {item.notifier} has share you a {item.data.tempTitle}.
          </NotiItem>
          <Time>{compareTime()}</Time>
        </NotiLink>
      );
      break;
    case "removal-from-ws":
      displayView = (
        <>
          <NotiItem>
            You have been removed from the {item.data} workspace.
          </NotiItem>
          <Time>{compareTime()}</Time>
        </>
      );
      break;
    case "private-folder-access":
      displayView = (
        <>
          <NotiItem>
            {item.notifier.name} has enabled access to private folder.
          </NotiItem>
          <Invitations>
            <VisitWorkspace onClick={navigateToFolder}>
              Go to Folder
            </VisitWorkspace>
          </Invitations>
          <Time>{compareTime()}</Time>
        </>
      );
      break;
    default:
      displayView = "";
  }

  return <Item>{displayView}</Item>;
};

const btnStyles = css`
  width: 100px;
  height: 32px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  font-size: 0.9rem;
  font-weight: 700;
  color: #fff;
`;

const Item = styled.div`
  font-size: 0.92rem;
  padding: 12px 14px;
  color: ${darkGrey1};
  cursor: pointer;
  border-bottom: 1px solid #dedede;
`;

const Time = styled.div`
  font-size: 0.85rem;
  padding: 8px 0 0;
  color: ${grey4};
`;

const Invitations = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0 0;
  margin: 12px 0 6px;
`;

const Accept = styled.button`
  ${btnStyles};
  background: ${primaryBlueBtn};
  margin-right: 14px;
  opacity: ${({processing}) => (processing ? 0.5 : 1)};
  cursor: ${({processing}) => (processing ? "not-allowed" : "pointer")};
`;

const Decline = styled.button`
  ${btnStyles};
  background: transparent;
  color: ${red4};
`;

const NotiLink = styled(Link)`
  text-decoration: none;
  color: ${darkGrey1};
`;

const VisitWorkspace = styled.div`
  color: #696dff;
  font-weight: 700;
  text-decoration: none;
`;

const NotiItem = styled.div`
  user-select: none;
`;

export default NotificationItem;
