import React, {useState, useEffect, useCallback, useRef} from "react";
import {connect} from "react-redux";
import {Spinner} from "../../../../../../oat-ui";
import {selectIsViewOnDesignPage, selectTempScale} from "../../../../../store/selectors/template/template.selector";
import styled from "styled-components";
import { PlayVideo } from "../../../../../../oat-svg-icons";

const EmbedVideo = ({field, tempScale, viewOnDesignPage, pointerEvents, activeSlide}) => {
  const {size, imgPos, subtype} = field || {};
  const {borderColor, showBorder, roundedBorder, borderRadius, scaleX, autoPlay} = field.styles || {};
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [isAutoPlay, setAutoPlay] = useState(false);
  const firstTimeDisplayingSlide = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const finishedLoading = useCallback(() => {
    if (subtype !== "uploaded") {
      setLoaded((prev)=> !prev.loaded);
    }
  }, [activeSlide, field?.content, subtype]);

  function playVideo() {
    if (videoRef?.current) { 
      if (videoRef.current.paused) {
        videoRef.current.play();
        setPlaying(true);
      } else {
        videoRef.current.pause();
        setPlaying(false);
        firstTimeDisplayingSlide.current = null;
      }
    }
  }

  const [pan, setPan] = useState({x: imgPos?.x, y: imgPos?.y});
  let panXPos = null, panYPos = null;
  const confinedPanX = size?.w - size.w2;
  if (pan?.x > 0) {
    panXPos = 0;
  } else if (pan?.x < confinedPanX) {
    panXPos = confinedPanX;
  } else {
    panXPos = pan?.x;
  }

  useEffect(() => {
    setPan({
      x: imgPos?.x,
      y: imgPos?.y,
    });
  }, [imgPos]);

  const confinedPanY = size?.h - size?.h2;
  if (pan?.y > 0) {
    panYPos = 0;
  } else if (pan?.y < confinedPanY) {
    panYPos = confinedPanY;
  } else {
    panYPos = pan?.y;
  }

  useEffect(() => {
    let playTimeout, pauseTimeout;
    // if (activeSlide && firstTimeDisplayingSlide === null) return;

    // if (activeSlide && autoPlay && firstTimeDisplayingSlide?.current === false) {
    //   firstTimeDisplayingSlide.current = true;
    // }

    // if (activeSlide && firstTimeDisplayingSlide === false && autoPlay) 
    //   videoRef?.current.play()

    if (videoRef?.current) {
      const handlePlayPause = async () => {
        try {
          if (viewOnDesignPage) {
            setLoaded(true)
            return;
          }

          setLoaded(true);
          if (!videoRef.current.paused && autoPlay && activeSlide && firstTimeDisplayingSlide.current === null) {
            setPlaying(true);
            setAutoPlay(true);
            firstTimeDisplayingSlide.current = null;
            return
          } 
          // else if (videoRef.current.paused && activeSlide && firstTimeDisplayingSlide.current === null) {
          //   return
          // }
          else 
          if (!viewOnDesignPage && activeSlide && autoPlay) {
            clearTimeout(pauseTimeout);
            playTimeout = setTimeout(async() => {
              if (videoRef?.current?.pause) {
                setAutoPlay(true);
                setLoaded(true);
                setPlaying(true);
                // setFirstTimeDisplayingSlide(null);
                videoRef.current.muted = true; 
                await videoRef?.current?.play().catch(error => {});
                firstTimeDisplayingSlide.current = null;
              }  
            }, 50);
          } else {
            clearTimeout(playTimeout);
            pauseTimeout = setTimeout(async() => {
              setAutoPlay(false);
              await videoRef?.current?.pause();
              setPlaying(false);
            }, 50);
          }
        } catch (error) {
          {}
        }
      };
      handlePlayPause();
    }

    return () => {
      clearTimeout(playTimeout);
      clearTimeout(pauseTimeout);
    };
  }, [activeSlide, autoPlay, viewOnDesignPage, isPlaying]);

  const handleLoadedData = () => {
    if (autoPlay && activeSlide && !isPlaying && !viewOnDesignPage && videoRef?.current?.paused) {
      videoRef.current.play().catch(error => {});
      setIsPlaying(true);
    }
  };

  let borderRadiusValue = subtype === 'uploaded' ? borderRadius == 100 ? borderRadius + '%' : borderRadius + 'px' 
  : roundedBorder ? 32 / tempScale : 0;

  return (
    <div
      style={{
        pointerEvents: pointerEvents ? "auto" : "none",
        width: size?.w,
        height: size?.h,
        borderRadius: borderRadiusValue,
        //  subtype === 'uploaded' ? borderRadius == 100 ? borderRadius + '%' : borderRadius + 'px' 
        overflow: "hidden",
        // outline:
        //   loaded && showBorder && subtype === 'embedded'
        //     ? `${4 / tempScale}px solid ${borderColor}`
        //     : undefined,
        outline: subtype === 'uploaded' && showBorder ? `${7 / tempScale}px solid ${borderColor}` : undefined,
        // boxSizing: `border-box`
        // boxShadow: loaded
        //   ? `rgb(0 0 0) ${6 / tempScale}px ${6 / tempScale}px ${
        //       1.2 / tempScale
        //     }px`
        //   : undefined,
        // https://getcssscan.com/css-box-shadow-examples
      }}
    >
      {field?.subtype === 'uploaded' ? 
        <WrapVideo 
          style={{ 
            cursor: !viewOnDesignPage ? 'pointer' : undefined
          }}
          onClick={playVideo} 
        >
          <Video 
            style={{
              width: size?.w2,
              height: size?.h2,
              transform: `translate(${panXPos}px, ${panYPos}px) scaleX(${scaleX})`,
              opacity: videoRef?.current?.paused && !viewOnDesignPage ? 0.7 : 1,
              background: videoRef?.current?.paused && !viewOnDesignPage ? '#232323' : undefined
            }}
            ref={videoRef} 
            autoPlay={activeSlide && !viewOnDesignPage ? true : false} // isAutoPlay
            muted={!videoRef?.current?.paused && autoPlay ? 'muted' : undefined}
            playsInline
            loading="lazy"
            onLoadedData={handleLoadedData}
            src={`https://oat-users.s3.amazonaws.com/${field?.content}`} type={`{video/${field?.format}`}>
          </Video>
          {!viewOnDesignPage && (videoRef?.current?.paused)  ?
            <PlayButton style={{ width: 100 / tempScale, height: 100 / tempScale}}>
              {PlayVideo}
            </PlayButton> 
          : undefined}
        </WrapVideo>
      : 
        <iframe
          src={field.content}
          scrolling="no"
          style={{width: size.w, height: size.h, zIndex: 1}}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onLoad={finishedLoading}
        ></iframe>
      }

      {!loaded ? (
        <LoadingArea style={{ borderRadius: borderRadiusValue}}>
          <WrapSpinner>
            <Spinner />
          </WrapSpinner>
        </LoadingArea>
      ) : undefined}      
    </div>
  );
};

const LoadingArea = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0ff8c;
  z-index: 4;

`;

const WrapSpinner = styled.div`
  width: 18px;
  height: 18px;
`;

const WrapVideo = styled.div`
  position: relative;
`;

const Video = styled.video`
  object-fit: cover;
`

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #688399ab;
`

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
    viewOnDesignPage: selectIsViewOnDesignPage(state.designTemplate),
  };
};

EmbedVideo.defaultProps = {
  pointerEvents: false,
};

export default connect(mapStateToProps, null)(EmbedVideo);
