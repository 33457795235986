import {useState, useLayoutEffect} from "react";

export default function useFullscreen(elRef) {
  const [isFullscreen, setIsFullscreen] = useState(
    document[getBrowserFullscreenElementProp()] != null
  );
  const [isSupported, setIsSupported] = useState(true);

  const setFullscreen = () => {
    if (elRef.current == null) return;

    // Check if fullscreen API is supported
    if (document.documentElement.requestFullscreen ||
      document.documentElement.webkitRequestFullscreen ||
      document.documentElement.mozRequestFullScreen ||
      document.documentElement.msRequestFullscreen) 
    {
      elRef.current
        .requestFullscreen()
        .then(() =>
          setIsFullscreen(document[getBrowserFullscreenElementProp()] != null)
        )
        .catch(() => {
          setIsFullscreen(false);
          setIsSupported(false);
          alert("Fullscreen is not supported in this browser.");
        });
    } else {
      setIsFullscreen(false);
      setIsSupported(false);
      alert("Fullscreen is not supported in this browser.");
    }
  };

  useLayoutEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
    };
    document.addEventListener("fullscreenchange", fullscreenChangeHandler);
    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);

  return [isFullscreen, setFullscreen, isSupported];
}

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== "undefined") {
    return "fullscreenElement";
  } else if (typeof document.mozFullScreenElement !== "undefined") {
    return "mozFullScreenElement";
  } else if (typeof document.msFullscreenElement !== "undefined") {
    return "msFullscreenElement";
  } else if (typeof document.webkitFullscreenElement !== "undefined") {
    return "webkitFullscreenElement";
  } else {
    return null;
  }
}
