import {
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_ERROR,
  FETCH_TEMPLATES_INIT,
  SWITCH_TEMPLATE_CATEGORY,
  FETCH_TEMP_CATEGORIES_INIT,
  FETCH_TEMP_CATEGORIES_SUCCESS,
  FETCH_TEMP_CATEGORIES_ERROR,
  REMOVE_TEMPLATE,
} from "./actionTypes";

export function fetchTempCategoriesInit() {
  return {
    type: FETCH_TEMP_CATEGORIES_INIT,
  };
}

export function fetchTempCategoriesSuccess(categories) {
  return {
    type: FETCH_TEMP_CATEGORIES_SUCCESS,
    categories,
  };
}

export function fetchTempCategoriesError(error) {
  return {
    type: FETCH_TEMP_CATEGORIES_ERROR,
    error,
  };
}

// fetch template categories
export const fetchTempCategories = (section) => {
  return async (dispatch) => {
    dispatch(fetchTempCategoriesInit());
    try {
      await fetch(`
        https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/media-templates/${section}
        `)
        .then((res) => res.json())
        .then((data) => dispatch(fetchTempCategoriesSuccess(data)))
        .catch((e) => dispatch(fetchTempCategoriesError(e)));
    } catch (e) {
      dispatch(fetchTempCategoriesError(e));
    }
  };
};

// - - - - - - - -
export function fetchTemplatesInit() {
  return {
    type: FETCH_TEMPLATES_INIT,
  };
}

export function fetchTemplatesSuccess(payload) {
  return {
    type: FETCH_TEMPLATES_SUCCESS,
    payload,
  };
}

export function fetchTemplatesError(error) {
  return {
    type: FETCH_TEMPLATES_ERROR,
    error,
  };
}

export function switchCategory(value) {
  return {
    type: SWITCH_TEMPLATE_CATEGORY,
    payload: value,
  };
}

// fetch templates
export const onCategoryChange = (category) => {
  return (dispatch) => {
    dispatch(fetchTemplatesInit());
    dispatch(switchCategory(category));
    try {
      fetch(
        category.draft
          ? `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/templates/design/${category.value}/${category.ratio}/en/draft`
          : `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/templates/${category.value}/${category.ratio}`
      )
        .then((res) => res.json())
        .then((data) => {
          dispatch(fetchTemplatesSuccess(data));
        })
        .catch((e) => dispatch(fetchTemplatesError(e)));
    } catch (e) {
      dispatch(fetchTemplatesError(e));
    }
  };
};

export function dispatchRemoveTemplate(index) {
  return {
    type: REMOVE_TEMPLATE,
    index,
  };
}
