import {
  getItemXOffset,
  getItemYOffset,
} from "../../../../../../utils/getItemOffset";
import {calcResize, degToRadian, getLength} from "../../utils";

export const calcOnResize = ({
  e,
  size,
  pos,
  initialProps,
  tempScale,
  rotate,
  direction,
  proportion = true,
  controlResizeSpeed = false, // only for text which has a huge ratio between width & height
  balanceRatio = true, // only for resizing table's columns
}) => {
  const rect = {
    width: size.w,
    height: size.h,
    centerX: pos.x + size.w / 2,
    centerY: pos.y + size.h / 2,
    rotateAngle: rotate,
  };
  const speedBump =
    (direction === "top-left" ||
      direction === "top-right" ||
      direction === "bottom-left" ||
      direction === "bottom-right") &&
    rect.width / rect.height > 10 &&
    balanceRatio
      ? 5
      : controlResizeSpeed
      ? size.w / size.h / 1.5
      : 1;
  // controlResizeSpeed ? size.w / size.h / 2.5 : 1;
  const clientX = getItemXOffset(e, tempScale) / speedBump;
  const clientY = getItemYOffset(e, tempScale) / speedBump;
  const deltaX = clientX - initialProps.orig.mouseX / speedBump;
  const deltaY = clientY - initialProps.orig.mouseY / speedBump;
  const minSize = 9;

  const alpha = Math.atan2(deltaY, deltaX);
  const deltaL = getLength(deltaX, deltaY);
  const beta = alpha - degToRadian(rotate + 0);
  const deltaW = Math.cos(beta) * deltaL;
  const deltaH = Math.sin(beta) * deltaL;
  const ratio =
    direction === "bottom-right" ||
    direction === "bottom-left" ||
    direction === "top-right" ||
    direction === "top-left"
      ? proportion
        ? rect.width / rect.height
        : 0
      : 0;

  const {
    position: {centerX, centerY},
    size: {width, height},
    delta,
  } = calcResize(direction, rect, deltaW, deltaH, ratio, minSize, minSize);
  return {centerX, centerY, width, height, rotate, deltaX, deltaY, delta};
};
