import {initGroupSelect} from "../../designTemplate";
import { areObjectsIdentical } from "../utils";

// check if two objects are identical
export function deepEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;

  if (x && y && tx === "object" && tx === ty) {
    const xKeys = ok(x);
    const yKeys = ok(y);

    if (xKeys.length !== yKeys.length) {
      return false;
    }

    return xKeys.every((key) => {
      if (key === "rotatedOffsets") {
        return true; // Skip comparison for "rotatedOffsets"
      }
      if (key === "styles" && x[key]?.whiteSpace !== y[key]?.whiteSpace) {
        return true; // Skip comparison for "styles.whiteSpace"
      }
      return deepEqual(x[key], y[key]);
    });
  } else {
    return x === y;
  }
}

// Group drag & resize event also fired in its reducer
export function storeFieldUndoRedo() {
  return function (state) {
    const {undoRedo, template, activeSlide, activeField} = state;
    if (
      !state.buildingTheme.status &&
      template?.length > 0 &&
      activeField !== -1
    ) {
      const currentField = template[activeSlide].fields[activeField];
      if (currentField) {
        // check if active item is not empty object. {}
        const ifActiveItemNotEmpty =
          undoRedo.active && Object.keys(undoRedo.active).length > 0;

        const ifAlreadyAdded = deepEqual(
          undoRedo.active,
          undoRedo.history[undoRedo.history.length - 1]
        );

        const newActiveItem = {
          item: currentField,
          slideID: template[activeSlide].id,
          type: "field",
        };

        // if (undoRedo.history[undoRedo.history.length - 1].type === "create") {
        //   const ifAlreadyAdded = deepEqual(
        //     undoRedo.active.item,
        //     undoRedo.history[undoRedo.history.length - 1].item
        //   );
        //   updateHistory =
        //     ifActiveItemNotEmpty && !ifAlreadyAdded
        //       ? [...undoRedo.history, undoRedo.active]
        //       : [...undoRedo.history, undoRedo.active];
        // } else {

        const updateHistory =
          ifActiveItemNotEmpty && !ifAlreadyAdded
            ? [
                ...undoRedo.history,
                // newActiveItem,
                undoRedo.active,
              ]
            : [...undoRedo.history];
        // }
        // to only accept 20 items
        if (updateHistory.length > 20) {
          updateHistory.shift();
        }

        return {
          ...state,
          undoRedo: {
            ...undoRedo,
            active: newActiveItem,
            history: updateHistory,
            future: [], // ...state.undoRedo.future will reset after slide change
          },
        };
      } else {
        return state;
      }
    } else {
      return state;
    }
  };
}

export function storeFieldAsActiveUndoRedo() {
  return function (state) {
    if (!state.buildingTheme.status) {
      const {undoRedo, template, activeSlide, activeField} = state;
      const currentField = template[activeSlide]?.fields[activeField];
      
      if (currentField) {
        const newActiveItem = {
          item: currentField,
          slideID: template[activeSlide].id,
          type: "field",
        };
        return {
          ...state,
          undoRedo: {
            ...undoRedo,
            active: newActiveItem,
            future: [], // state.undoRedo.future 
          },
        };
      } else {
        return state;
      }
    } else {
      return state;
    }
  };
}

export function storeFieldAsHistoryUndoRedo() {
  return function (state) {
    if (!state.buildingTheme.status) {
      const {undoRedo, template, activeSlide, activeField} = state;
      const ifAlreadyAdded = deepEqual(
        undoRedo.active,
        undoRedo.history[undoRedo.history.length - 1]
      );
      const currentField = template[activeSlide].fields[activeField];
      if (currentField) {
        const newActiveItem = {
          item: currentField,
          slideID: template[activeSlide].id,
          type: "field",
        };
        const updateHistory = !ifAlreadyAdded
          ? [...undoRedo.history, newActiveItem]
          : [...undoRedo.history];
        return {
          ...state,
          undoRedo: {
            ...undoRedo,
            history: updateHistory,
            future: [],
          },
        };
      } else {
        return state
      }
    } else {
      return state;
    }
  };
}

// also stored undoRedo active & history when updating background values
export function storeBackgroundUndoRedo() {
  return function (state, action) {
    if (!state.buildingTheme.status) {
      const {undoRedo, template, activeSlide} = state;
      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;
      const ifAlreadyAdded = deepEqual(
        undoRedo.active,
        undoRedo.history[undoRedo.history.length - 1]
      );
      const updateHistory =
        ifActiveItemNotEmpty && !ifAlreadyAdded
          ? [...undoRedo.history, undoRedo.active]
          : [...undoRedo.history];

      if (updateHistory.length > 20) {
        updateHistory.shift();
      }

      return {
        ...state,
        undoRedo: {
          ...state.undoRedo,
          active: {
            item: template[activeSlide].bg,
            slideID: template[activeSlide].id,
            type: "background",
          },
          history: updateHistory,
          future: [],
        },
      };
    }
  };
}

// For create, delete - fields
export function storeActionsUndoRedo() {
  return function (state, action) {
    if (!state.buildingTheme.status) {
      const {actionType} = action;
      const {undoRedo, template, activeSlide, activeField} = state;
      // check if active item is not empty object. {}
      const currentField = template[activeSlide]?.fields[activeField];
      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;

      const ifAlreadyAdded = deepEqual(
        undoRedo.active,
        undoRedo.history[undoRedo.history.length - 1]
      );

      let addToHistory;
      if (state.groupSelect.keys.length > 0) {
        if (actionType === "delete") {
          addToHistory = {
            item: state.groupSelect.keys,
            slideID: template[activeSlide].id,
            type: "group-delete",
            groupedKeys: state.groupSelect.keys,
          };
        } else if (actionType === "create") {
          addToHistory = {
            item: state.groupSelect.keys,
            slideID: template[activeSlide].id,
            type: "group-duplicate",
            groupedKeys: state.groupSelect.keys,
          };
        }
      } else {
        addToHistory = {
          item: currentField,
          slideID: template[activeSlide].id,
          type: actionType,
        };
      }

      let updateHistory;
      if (state.groupSelect.keys.length === 0 && actionType === "create") {
        const typeAsField = {
          item: currentField,
          slideID: template[activeSlide].id,
          type: 'field',
        }
        updateHistory = [
          ...undoRedo.history, 
          addToHistory, 
          typeAsField
        ];
      } else {
        updateHistory = [
          ...undoRedo.history, 
          addToHistory, 
          // typeAsField
        ];
      }
      if (ifActiveItemNotEmpty && !ifAlreadyAdded) {
        updateHistory = [
          ...undoRedo.history, 
          undoRedo.active, 
          addToHistory,
          // typeAsField
        ];
      }
      return {
        ...state,
        undoRedo: {
          ...undoRedo,
          history: updateHistory,
          active: {},
          future: [],
        },
      };
    }
  };
}

export function handleUndo() {
  return function (state) {
    const {undoRedo, template, activeSlide} = state;
    // create, delete
    const actionType = undoRedo.history[undoRedo.history.length - 1]?.type;

    let newSlideIndex = template.findIndex(
      (slide) =>
        slide.id === undoRedo.history[undoRedo.history?.length - 1]?.slideID
    );
    // activeSlide: activeSlideIndex,
    //   activeSlideID: slideID ? slideID : initialSlide?.id,
    let activeSlideHasChanged = activeSlide !== newSlideIndex ? true : false;
    const activeFieldKey =
      (undoRedo.history[undoRedo.history.length - 1]?.type === "field" ||
        undoRedo.history[undoRedo.history.length - 1]?.type === "create" ||
        undoRedo.history[undoRedo.history.length - 1]?.type === "delete") &&
      undoRedo.history[undoRedo.history.length - 1]?.item?.key;

    const lastItemSameWithActive = deepEqual(
      undoRedo.history[undoRedo.history.length - 1],
      undoRedo.active
    );
    const lastItem = undoRedo.history[undoRedo.history.length - 1];

    if (undoRedo.history.length >= 2 && lastItemSameWithActive && undoRedo.history[undoRedo.history.length - 2]?.type === "group-delete") {
      const newState = [...state.template];
      let updatedItems = {};
      const updatedFields = Object.entries(newState[newSlideIndex].fields).map(
        ([id, field]) => {
          if (undoRedo.history[undoRedo.history.length - 2].groupedKeys.includes(id)) {
            updatedItems[id] = field;
            return {...field, deleted: false};
          } else {
            return field;
          }
        }
      );

      newState[newSlideIndex] = {
        ...newState[newSlideIndex],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item?.key]: item}),
          {}
        ),
      };

      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;
      let futureItems = [], prevItems = [];
      prevItems = undoRedo.history.filter(
        (item, i) =>
          i !== undoRedo.history.length - 1 &&
          i !== undoRedo.history.length - 2
      );
     
      if (ifActiveItemNotEmpty && undoRedo.history[undoRedo.history.length - 2] != null) {
        futureItems = [...undoRedo.future, undoRedo.active, undoRedo.history[undoRedo.history.length - 2]];
      } else {
        futureItems = undoRedo.future;
      }

      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        activeSlide: newSlideIndex,
        activeSlideID: newState[newSlideIndex].id, // undoRedo.history[undoRedo.history.length - 2].slideID,
        groupSelect: initGroupSelect,
        undoRedo: {
          ...undoRedo,
          active: {},
          history: prevItems,
          future: futureItems,
        },
        emitUndoRedoItemToSocket: {...lastItem, type: "group-delete"},
      };
    } else if (
      lastItem && 
      lastItem?.type === "group-delete" ||
      lastItem?.type === "group-duplicate" ||
      lastItem?.type === "group-fields-drag-resize"
    ) {
      const prevItem = undoRedo.history[undoRedo.history.length - 1];
      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;
      let futureItems = [],
        prevItems = [];
      if (ifActiveItemNotEmpty && prevItem !== undefined) {
        futureItems = [...undoRedo.future, undoRedo.active, prevItem];
      } else {
        futureItems = undoRedo.future;
        // [...undoRedo.future, prevItem];
        //undoRedo.future;
      }
      const newState = [...state.template];
      prevItems = undoRedo.history.filter(
        (item, i) => i !== undoRedo.history.length - 1
      );

      let updatedItems = {};
      if (lastItem.type === "group-delete") {
        const updatedFields = Object.entries(newState[newSlideIndex].fields).map(
          ([id, field]) => {
            if (lastItem?.groupedKeys?.includes(id)) {
              updatedItems[id] = field;
              return {...field, deleted: false};
            } else {
              return field;
            }
          }
        );
        newState[newSlideIndex] = {
          ...newState[newSlideIndex],
          fields: updatedFields.reduce(
            (acc, item) => ({...acc, [item?.key]: item}),
            {}
          ),
        };
        return {
          ...state,
          template: newState,
          activeField: -1,
          selectedFieldType: "",
          activeSlide: newSlideIndex,
          activeSlideID: undoRedo.history[undoRedo.history.length - 1].slideID,
          groupSelect: initGroupSelect,
          undoRedo: {
            ...undoRedo,
            active: futureItems.length === 0 ? prevItem : {}, // prevItem, // 
            history: prevItems,
            future: futureItems,
          },
          emitUndoRedoItemToSocket: {
            ...lastItem,
            item: updatedItems,
            type: "group-create",
          },
        };
      } else if (lastItem.type === "group-duplicate") {
        const updatedFields = Object.entries(newState[newSlideIndex].fields).map(
          ([id, field]) =>
            lastItem?.groupedKeys?.includes(id)
              ? {
                  ...field,
                  deleted: true,
                }
              : field
        );
        newState[newSlideIndex] = {
          ...newState[newSlideIndex],
          fields: updatedFields.reduce(
            (acc, item) => ({...acc, [item?.key]: item}),
            {}
          ),
        };
        prevItems = undoRedo.history.filter(
          (item, i) =>
            i !== undoRedo.history.length - 1 &&
            i !== undoRedo.history.length - 2
        );
        if (ifActiveItemNotEmpty) {
          if (undoRedo.history[undoRedo.history.length - 2] !== undefined) {
            futureItems = [
              ...undoRedo.future,
              undoRedo.active,
              undoRedo.history[undoRedo.history.length - 1],
              undoRedo.history[undoRedo.history.length - 2],
            ];
          } else {
            futureItems = [
              ...undoRedo.future,
              undoRedo.active,
              undoRedo.history[undoRedo.history.length - 1]
            ];
          }
        } else {
          if (undoRedo.history[undoRedo.history.length - 2] !== undefined) {
            futureItems = [ ...undoRedo.future, prevItem, undoRedo.history[undoRedo.history.length - 2] ];
          } else {
            futureItems = [ ...undoRedo.future, prevItem ]
          }
        }
        return {
          ...state,
          template: newState,
          activeField: -1,
          selectedFieldType: "",
          activeSlide: newSlideIndex,
          activeSlideID: newState[newSlideIndex].id, // undoRedo.history[undoRedo.history.length - 2].slideID,
          groupSelect: initGroupSelect,
          undoRedo: {
            ...undoRedo,
            active: {},
            history: prevItems,
            future: futureItems,
          },
          emitUndoRedoItemToSocket: {...lastItem, type: "group-delete"},
        };
      } else if (lastItem.type === "group-fields-drag-resize") {
        // newly added
        const activeFieldEqualsLastItem = deepEqual(
          state.undoRedo.active,
          undoRedo.history[undoRedo.history.length - 1]
        );

        let updatedFields;

        if (activeFieldEqualsLastItem) {
          updatedFields = Object.entries(newState[newSlideIndex].fields).map(
            ([id, field]) =>
              undoRedo.history[
                undoRedo.history.length - 2
              ].groupedKeys?.includes(id)
                ? undoRedo.history[undoRedo.history.length - 2].item?.[id]
                : field
          );
        } else {
          updatedFields = Object.entries(newState[newSlideIndex].fields).map(
            ([id, field]) =>
              lastItem.groupedKeys?.includes(id) ? lastItem.item?.[id] : field
          );
        }
        newState[newSlideIndex] = {
          ...newState[newSlideIndex],
          fields: updatedFields.reduce(
            (acc, item) => ({...acc, [item?.key]: item}),
            {}
          ),
        };

        // if (
        //   undoRedo.history.length >= 2 &&
        //   undoRedo.history[undoRedo.history.length - 2].type ===
        //     "group-duplicate"
        // ) {
        //   futureItems = [
        //     ...undoRedo.future,
        //     undoRedo.history[undoRedo.history.length - 1],
        //     undoRedo.history[undoRedo.history.length - 2],
        //   ];
        //   prevItems = undoRedo.history.filter(
        //     (item, i) =>
        //       i !== undoRedo.history.length - 1 &&
        //       i !== undoRedo.history.length - 2
        //   );
        // } else

        if (
          // undoRedo.history.length &&
          undoRedo.future.length === 1 &&
          undoRedo.history.length === 4 &&
          undoRedo.history[undoRedo.history.length - 2].type ===
            "group-duplicate"
        ) {
          futureItems = [
            ...undoRedo.future,
            undoRedo.history[undoRedo.history.length - 1],
            undoRedo.history[undoRedo.history.length - 2],
          ];
          prevItems = undoRedo.history.filter(
            (item, i) =>
              i !== undoRedo.history.length - 1 &&
              i !== undoRedo.history.length - 2
          );
        } else if (activeFieldEqualsLastItem) {
          futureItems = [
            ...undoRedo.future,
            undoRedo.history[undoRedo.history.length - 1],
          ];
        } else if (ifActiveItemNotEmpty) {
          //&& !activeFieldEqualsLastItem
          futureItems = [...undoRedo.future, undoRedo.active]; //
        } else {
          futureItems = undoRedo.future;
          // [...undoRedo.future, prevItem];
          // undoRedo.future;
        }
        return {
          ...state,
          template: newState,
          activeField: -1,
          selectedFieldType: "",
          activeSlide: newSlideIndex,
          activeSlideID: undoRedo.history[undoRedo.history.length - 1].slideID,
          groupSelect: initGroupSelect,
          undoRedo: {
            ...undoRedo,
            active: prevItem,
            history: undoRedo.history[undoRedo.history.length - 1].type === "group-delete" ?  {} : prevItems,
            future: futureItems,
          },
          emitUndoRedoItemToSocket: {
            ...lastItem,
            type: "group-fields-drag-resize",
          },
        };
      }
    }
    //for cases where user create an item and then select another item and stop.
    //initiate undo (extra step has to take issue)
    else if (
      lastItemSameWithActive &&
      undoRedo.history.length >= 2 &&
      undoRedo.history[undoRedo.history.length - 2].type !== "background" &&
      undoRedo.history[undoRedo.history.length - 2].type === "create"
    ) {
      const newState = [...state.template];
      const activeUndo = state.undoRedo.active;
      const ifActiveSlideHasChanged = template.findIndex(
        (slide) =>
          slide.id === undoRedo.history[undoRedo.history?.length - 2]?.slideID
      );
      activeSlideHasChanged = ifActiveSlideHasChanged !== activeSlide ? true : false; 
      return {
        ...state,
        template: newState,
        activeField: activeSlideHasChanged ? -1 : undoRedo.history[undoRedo.history?.length - 2].item?.key,
        selectedFieldType: activeSlideHasChanged ? "" : undoRedo.history[undoRedo.history?.length - 2].item?.type,
        activeSlide: newSlideIndex, // newSlideIndex,
        activeSlideID: undoRedo.history[undoRedo.history?.length - 2].slideID,
        undoRedo: {
          ...undoRedo,
          active: {
            ...undoRedo.history[undoRedo.history.length - 2],
            type: "field",
          },
          history: undoRedo.history.filter(
            (item, i) => i !== undoRedo.history.length - 1
          ),
          future:
            undoRedo.active && Object.keys(undoRedo.active).length !== 0
              ? [...undoRedo.future, activeUndo]
              : undoRedo.future,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...undoRedo.history[undoRedo.history.length - 2],
          type: "delete", // revese to its undo type
        },
      };
    } else if (actionType === "create") {
      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;
      let futureItems = [];
      if (ifActiveItemNotEmpty) {
        futureItems = [
          ...undoRedo.future,
          undoRedo.active,
          undoRedo.history[undoRedo.history.length - 1],
        ];
      } else {
        futureItems = [
          ...undoRedo.future,
          undoRedo.history[undoRedo.history.length - 1],
        ];
      }
      const newState = [...state.template];
      delete newState[newSlideIndex].fields[activeFieldKey];
      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        activeSlide: newSlideIndex,
        activeSlideID: undoRedo.history[undoRedo.history.length - 1]?.slideID,
        undoRedo: {
          ...undoRedo,
          active: {},
          history: undoRedo.history.filter(
            (item, i) => i !== undoRedo.history.length - 1
          ),
          future: futureItems,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...undoRedo.history[undoRedo.history.length - 1],
          type: "delete", // revese to its undo type
        },
      };
    } else if (actionType === "delete") {
      const prevItem = [
        undoRedo.history[undoRedo.history.length - 1],
        undoRedo.history[undoRedo.history.length - 2],
      ];
      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;
      let futureItems = [];
      if (ifActiveItemNotEmpty) {
        futureItems = [...undoRedo.future, undoRedo.active, ...prevItem];
      } else {
        futureItems = [...undoRedo.future, ...prevItem];
      }

      const newState = [...state.template];
      const updatedActiveItem = undoRedo.history[undoRedo.history.length - 1];

      newState[newSlideIndex] = {
        ...newState[newSlideIndex],
        fields: {
          ...newState[newSlideIndex].fields,
          [updatedActiveItem.item?.key]: updatedActiveItem.item,
        },
      };

      return {
        ...state,
        template: newState,
        activeField: activeSlideHasChanged ? -1 : activeFieldKey, //activeFieldIndex, // -1
        selectedFieldType: activeSlideHasChanged ? "" : newState[newSlideIndex]?.fields[activeFieldKey]?.type,
        activeSlide: newSlideIndex,
        activeSlideID: undoRedo.history[undoRedo.history.length - 1]?.slideID,
        undoRedo: {
          ...undoRedo,
          active: {},
          history: undoRedo.history.filter(
            (item, i) =>
              i !== undoRedo.history.length - 1 &&
              i !== undoRedo.history.length - 2
          ),
          future: futureItems,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...updatedActiveItem,
          type: "create",
        },
      };
    } else if (actionType === "background") {
      const newState = [...state.template];
      const activeUndo = state.undoRedo.active;

      // if the backgrounds were the same with the current one
      const lastItemSameWithCurrentBg = areObjectsIdentical(
        newState[newSlideIndex].bg,
        undoRedo.history[undoRedo.history.length - 1].item
      );
      
      newState[newSlideIndex].bg =
        undoRedo.history[
          undoRedo.history.length - (lastItemSameWithCurrentBg ? 2 : 1)
        ].item;

      let prevItems = [],
        futureItems = [];
      let activeItem = {};
      
      if (lastItemSameWithCurrentBg) {
        activeItem = undoRedo.history[undoRedo.history.length - 2];
        prevItems = undoRedo.history.filter(
          (item, i) =>
            i !== undoRedo.history.length - 1 &&
            i !== undoRedo.history.length - 2
        );
        futureItems = [
          ...undoRedo.future,
          undoRedo.history[undoRedo.history.length - 1],
        ];
      } else {
        activeItem = undoRedo.history[undoRedo.history.length - 1];
        prevItems = undoRedo.history.filter(
          (item, i) => i !== undoRedo.history.length - 1
        );
        futureItems =
          undoRedo.active && Object.keys(undoRedo.active).length !== 0
            ? [...undoRedo.future, activeUndo]
            : undoRedo.future;
      }
      const updatedActiveItem = activeItem;
      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        activeSlide: newSlideIndex,
        activeSlideID: undoRedo.history[undoRedo.history.length - 1]?.slideID,
        undoRedo: {
          ...undoRedo,
          active: updatedActiveItem,
          history: prevItems,
          future: futureItems,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {...updatedActiveItem, type: "background"},
      };
    } else if (lastItemSameWithActive && undoRedo.history.length >= 2 && !state.enableTextEdit) {
      const newState = [...state.template];
      const activeUndo = state.undoRedo.active;
      newState[newSlideIndex].fields[activeFieldKey] = //activeFieldIndex
        undoRedo.history[undoRedo.history.length - 1]?.item;
      // When click undo, nothing changes happen for once, after resizing text to left or right handles
      // object comparing with active against the previous's last item won't work
      // due to style's pre-wrap was changed
      // -2 to -1 changed
      const updatedActiveItem = undoRedo.history[undoRedo.history.length - 1];
      const ifActiveSlideHasChanged = template.findIndex(
        (slide) =>
          slide.id === undoRedo.history[undoRedo.history?.length - 1]?.slideID
      );
      activeSlideHasChanged = ifActiveSlideHasChanged !== activeSlide ? true : false;
      return {
        ...state,
        template: newState,
        activeField: activeSlideHasChanged ? -1 : activeFieldKey, // activeFieldIndex,
        selectedFieldType: activeSlideHasChanged ? "" : newState[newSlideIndex]?.fields[activeFieldKey]?.type,
        activeSlide: newSlideIndex,
        activeSlideID: undoRedo.history[undoRedo.history.length - 1]?.slideID,
        undoRedo: {
          ...undoRedo,
          active: updatedActiveItem,
          history: undoRedo.history.filter(
            (item, i) =>
              i !== undoRedo.history.length - 1 
              // &&
              // i !== undoRedo.history.length - 2
          ),
          future:
            undoRedo.active && Object.keys(undoRedo.active).length !== 0
              ? [...undoRedo.future, activeUndo]
              : undoRedo.future,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {...updatedActiveItem, type: "field-update"},
      };
    } else if (undoRedo.history.length !== 0) {
      const newState = [...state.template];
      const activeUndo = state.undoRedo.active;
      newState[newSlideIndex].fields[activeFieldKey] = //activeFieldIndex
        undoRedo.history[undoRedo.history.length - 1]?.item;

      newState[newSlideIndex].fields[activeFieldKey] = //activeFieldIndex
        undoRedo.history[undoRedo.history.length - 1]?.item;

      // const activeSameWithLastItem = deepEqual(
      //   activeUndo,
      //   undoRedo.history[undoRedo.history.length - 1]
      // );


      // When click undo, nothing changes happen for once, after resizing text to left or right handles
      // object comparing with active against the previous's last item won't work
      // due to style's pre-wrap was changed
      const updatedActiveItem = undoRedo.history[undoRedo.history.length - 1];
      return {
        ...state,
        template: newState,
        activeField: lastItemSameWithActive || activeSlideHasChanged ? -1 : activeFieldKey, // activeFieldIndex,
        selectedFieldType: activeSlideHasChanged ? "" : newState[newSlideIndex]?.fields[activeFieldKey]?.type,
        activeSlide: newSlideIndex,
        activeSlideID: undoRedo.history[undoRedo.history.length - 1]?.slideID,
        undoRedo: {
          ...state.undoRedo,
          active: updatedActiveItem,
          history: undoRedo.history.filter(
            (item, i) => i !== undoRedo.history.length - 1
          ),
          future: !lastItemSameWithActive &&
            undoRedo.active &&
            Object.keys(undoRedo.active).length !== 0
              ? [...undoRedo.future, activeUndo]
              : undoRedo.future,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...updatedActiveItem,
          type: "field-update",
        },
      };
    } else {
      return state;
    }
  };
}

export function handleRedo() {
  return function (state) {
    const {undoRedo, template, activeSlide} = state;

    const actionType = undoRedo?.future[undoRedo.future.length - 1]?.type;
    const newSlideIndex = template.findIndex(
      (slide) =>
        slide.id === undoRedo.future[undoRedo.future.length - 1]?.slideID
    );
    const slideID = undoRedo.future[undoRedo.future.length - 1]?.slideID;
    const activeFieldKey = undoRedo.future[undoRedo.future.length - 1]?.item?.key;
    const ifActiveItemNotEmpty =
      undoRedo.active && Object.keys(undoRedo.active).length;
    let checkItemsIdentical = false;
    let activeSlideHasChanged = activeSlide !== newSlideIndex ? true : false;

    checkItemsIdentical =
      undoRedo.future.length >= 2 &&
      deepEqual(
        undoRedo.future[undoRedo.future.length - 1]?.item,
        undoRedo.future[undoRedo.future.length - 2]?.item
      );

    const lastItem = undoRedo.future[undoRedo.future.length - 1];

    if (
      lastItem && 
      lastItem?.type === "group-delete" ||
      lastItem?.type === "group-duplicate" ||
      lastItem?.type === "group-fields-drag-resize"
    ) {
      const redoItem = undoRedo.future[undoRedo.future.length - 1];
      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length > 1;
      let futureItems = [],
        prevItems = [];
      if (ifActiveItemNotEmpty) {
        if(redoItem?.slideID !== undoRedo.future[undoRedo.future.length - 2]?.slideID) {
          prevItems = [...undoRedo.history, undoRedo.active];
        } else {
          prevItems = [...undoRedo.history, undoRedo.active, redoItem];
        }
      } else {
        prevItems = [...undoRedo.history, redoItem];
      }
      const newState = [...state.template];
      futureItems = undoRedo.future.filter(
        (item, i) => i !== undoRedo.future.length - 1
      );

      let emitType;
      if (lastItem.type === "group-delete") {
        emitType = "group-delete";
        const updatedFields = Object.entries(newState[newSlideIndex]?.fields).map(
          ([id, field]) =>
            lastItem.groupedKeys?.includes(id)
              ? {...field, deleted: true}
              : field
        );
        newState[newSlideIndex] = {
          ...newState[newSlideIndex],
          fields: updatedFields.reduce(
            (acc, item) => ({...acc, [item?.key]: item}),
            {}
          ),
        };
      } else if (lastItem.type === "group-duplicate") {
        emitType = "group-create";
        if (redoItem?.slideID !== undoRedo.future[undoRedo.future.length - 2]?.slideID) {
          futureItems = undoRedo.future.filter(
            (item, i) => i !== undoRedo.future.length - 1 
          );
        } else {
          futureItems = undoRedo.future.filter(
            (item, i) =>
              i !== undoRedo.future.length - 1 && 
              i !== undoRedo.future.length - 2
          );
        }
        const updatedFields = Object.entries(newState[newSlideIndex]?.fields).map(
          ([id, field]) =>
          redoItem.item?.includes(id)
              ? {
                  // ...undoRedo.future[undoRedo.future.length - 1].item,
                  ...field,
                  deleted: false,
                }
              : field
        );
        if (undoRedo.future.length >= 2 && 
          redoItem?.slideID !== undoRedo.future[undoRedo.future.length - 2]?.slideID) {
          prevItems = [
            ...undoRedo.history,
            redoItem,
          ];
        } else if (undoRedo.future.length >= 2 && 
          redoItem?.slideID === undoRedo.future[undoRedo.future.length - 2]?.slideID) {
          prevItems = [
            ...undoRedo.history,
            redoItem,
            undoRedo.future[undoRedo.future.length - 2],
          ];
        } else {
          prevItems = [...undoRedo.history, redoItem];
        }

        newState[newSlideIndex] = {
          ...newState[newSlideIndex],
          fields: updatedFields.reduce(
            (acc, item) => ({...acc, [item?.key]: item}),
            {}
          ),
        };
      } else if (lastItem.type === "group-fields-drag-resize") {
        const activeAndLastItemEquals = deepEqual(
          undoRedo.active,
          undoRedo.future[undoRedo.future.length - 1]
        );

        if (activeAndLastItemEquals && undoRedo.future.length >= 2) {
          if (ifActiveItemNotEmpty) {
            prevItems = [
              ...undoRedo.history,
              redoItem,
              undoRedo.future[undoRedo.future.length - 2],
            ];
          }
          futureItems = undoRedo.future.filter(
            (item, i) =>
              i !== undoRedo.future.length - 1 &&
              i !== undoRedo.future.length - 2
          );

          const updatedFields = Object.entries(
            newState[newSlideIndex]?.fields
          ).map(([id, field]) =>
            undoRedo.future[undoRedo.future.length - 2].groupedKeys?.includes(id)
              ? undoRedo.future[undoRedo.future.length - 2]?.item?.[id]
              : field
          );
          newState[newSlideIndex] = {
            ...newState[newSlideIndex],
            fields: updatedFields.reduce(
              (acc, item) => ({...acc, [item?.key]: item}),
              {}
            ),
          };

          return {
            ...state,
            template: newState,
            activeField: -1,
            selectedFieldType: "",
            activeSlide: newSlideIndex,
            activeSlideID: slideID,
            groupSelect: initGroupSelect,
            undoRedo: {
              ...undoRedo,
              active: {}, //redoItem,
              // history: [...undoRedo.history, undoRedo.active],
              history: prevItems,
              // history: [
              //   ...undoRedo.history,
              //   redoItem,
              //   undoRedo.future[undoRedo.future.length - 2],
              // ],
              future: futureItems,
            },
            enableTextEdit: false,
            emitUndoRedoItemToSocket: lastItem,
          };
        } else {
          const updatedFields = Object.entries(
            newState[newSlideIndex]?.fields
          ).map(([id, field]) =>
            lastItem.groupedKeys?.includes(id) ? lastItem.item?.[id] : field
          );
          newState[newSlideIndex] = {
            ...newState[newSlideIndex],
            fields: updatedFields.reduce(
              (acc, item) => ({...acc, [item?.key]: item}),
              {}
            ),
          };
          // if (ifActiveItemNotEmpty) {
          //   prevItems = [...undoRedo.history, redoItem];
          // }
          return {
            ...state,
            template: newState,
            activeField: -1,
            selectedFieldType: "",
            activeSlide: newSlideIndex,
            activeSlideID: slideID,
            groupSelect: initGroupSelect,
            undoRedo: {
              ...undoRedo,
              active: {}, // redoItem,
              history: prevItems,
              // [...undoRedo.history, undoRedo.active],
              future: futureItems,
            },
            enableTextEdit: false,
            emitUndoRedoItemToSocket: lastItem,
          };
        }
      }
      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        activeSlide: newSlideIndex,
        activeSlideID: slideID,
        groupSelect: initGroupSelect,
        undoRedo: {
          ...undoRedo,
          active: {},
          history: prevItems,
          future: futureItems,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {...lastItem, type: emitType},
      };
    }
    // delete item, then select another item,
    // init undo - redo, without this section has to double click
    // to skip an extra field
    else if (
      undoRedo.future.length >= 2 &&
      checkItemsIdentical &&
      undoRedo.future[undoRedo.future.length - 2].type === "delete"
      //  &&
      // undoRedo.future[undoRedo.future.length - 2].type !== "background"
    ) {
      const newState = [...state.template];
      delete newState[newSlideIndex]?.fields[activeFieldKey];

      const prevItem = [
        undoRedo.future[undoRedo.future.length - 1],
        undoRedo.future[undoRedo.future.length - 2],
      ];
      let prevItems = [];
      if (ifActiveItemNotEmpty) {
        prevItems = [...undoRedo.history, undoRedo.active, ...prevItem];
      } else {
        prevItems = [...undoRedo.history, ...prevItem];
      }

      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        activeSlide: newSlideIndex,
        activeSlideID: slideID,
        undoRedo: {
          ...undoRedo,
          active: {},
          history: prevItems,
          future: undoRedo.future.filter(
            (item, i) =>
              i !== undoRedo.future.length - 1 &&
              i !== undoRedo.future.length - 2
          ),
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...undoRedo.future[undoRedo.future.length - 2],
          type: "delete",
        },
      };
    } else if (actionType === "create") {
      const newState = [...state.template];
      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;
      const activeItem = undoRedo.future[undoRedo.future.length - 2];
      const prevItems =
        ifActiveItemNotEmpty &&
        undoRedo.active &&
        Object.keys(undoRedo.active).length !== 0
          ? [
              ...undoRedo.history,
              state.undoRedo.active,
              undoRedo.future[undoRedo.future.length - 1],
            ]
          : [...undoRedo.history, undoRedo.future[undoRedo.future.length - 1]];
      const futureItems = undoRedo.future.filter(
        (item, i) =>
          i !== undoRedo.future.length - 1 && i !== undoRedo.future.length - 2
      );
      const updatedActiveItem = undoRedo.future[undoRedo.future.length - 1];
      newState[newSlideIndex] = {
        ...newState[newSlideIndex],
        fields: {
          ...newState[newSlideIndex]?.fields,
          [updatedActiveItem?.item.key]: updatedActiveItem?.item,
        },
      };
      return {
        ...state,
        template: newState,
        activeField: activeSlideHasChanged ? -1 : activeFieldKey, //activeFieldKey, //- 1, //activeFieldIndex, // -1
        selectedFieldType: activeSlideHasChanged ? -1 : newState[newSlideIndex]?.fields[activeFieldKey]?.type,
        activeSlide: newSlideIndex,
        activeSlideID: slideID,
        undoRedo: {
          ...undoRedo,
          active: activeItem,
          history: prevItems,
          future: futureItems,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {...updatedActiveItem, type: "create"},
      };
    }
    // delete here may not be needed for the first section of if
    else if (actionType === "delete") {
      const newState = [...state.template];
      delete newState[newSlideIndex]?.fields[activeFieldKey];
      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        activeSlide: newSlideIndex,
        activeSlideID: slideID,
        undoRedo: {
          ...undoRedo,
          active: {},
          history: [
            ...undoRedo.history,
            undoRedo.active,
            undoRedo.future[undoRedo.future.length - 1],
          ],
          future: undoRedo.future.filter(
            (item, i) => i !== undoRedo.future.length - 1
          ),
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...undoRedo.future[undoRedo.future.length - 1],
          type: "delete",
        },
      };
    } else if (actionType === "background") {
      const newState = [...state.template];
      const activeUndo = state.undoRedo.active;
      let prevItems = [],
        futureItems = [];
      let activeItem = {};

      // if the backgrounds were the same with the current one
      const lastItemSameWithCurrentBg = deepEqual(
        newState[newSlideIndex].bg,
        undoRedo.future[undoRedo.future.length - 1]?.item
      );

      newState[newSlideIndex].bg =
        undoRedo.future[
          undoRedo.future.length - (!lastItemSameWithCurrentBg ? 1 : 2)
        ]?.item;

      if (lastItemSameWithCurrentBg) {
        activeItem = undoRedo.future[undoRedo.future.length - 2];
        prevItems =
          undoRedo.active && Object.keys(undoRedo.active).length !== 0
            ? [
                ...undoRedo.history,
                activeUndo,
                undoRedo.future[undoRedo.future.length - 1],
              ]
            : [
                ...undoRedo.history,
                undoRedo.future[undoRedo.future.length - 1],
              ];
        futureItems = undoRedo.future.filter(
          (item, i) =>
            i !== undoRedo.future.length - 1 && i !== undoRedo.future.length - 2
        );
      } else {
        activeItem = undoRedo.future[undoRedo.future.length - 1];
        prevItems =
          undoRedo.active && Object.keys(undoRedo.active).length !== 0
            ? [...undoRedo.history, activeUndo]
            : undoRedo.history;
        futureItems = undoRedo.future.filter(
          (item, i) => i !== undoRedo.future.length - 1
        );
      }
      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        activeSlide: newSlideIndex,
        activeSlideID: slideID,
        undoRedo: {
          ...undoRedo,
          active: activeItem,
          history: prevItems,
          future: futureItems,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...activeItem,
          type: "background",
        },
      };
    } else if (undoRedo.future.length !== 0) {
      const lastItemSameWithActive = deepEqual(
        undoRedo.history[undoRedo.history.length - 1],
        undoRedo.active
      );
      const newState = [...state.template];
      newState[newSlideIndex].fields[activeFieldKey] =
        undoRedo.future[undoRedo.future.length - 1]?.item;

      const redoFuture = undoRedo.future.filter(
        (item, i) => i !== undoRedo.future.length - 1
      );

      const updatedItem = undoRedo.future[undoRedo.future.length - 1];
      return {
        ...state,
        template: newState,
        activeField: activeSlideHasChanged ? -1 : activeFieldKey, // -1, // 
        selectedFieldType: activeSlideHasChanged ? "" : newState[newSlideIndex]?.fields[activeFieldKey]?.type,
        activeSlide: newSlideIndex,
        activeSlideID: slideID,
        undoRedo: {
          ...undoRedo,
          history:
            !lastItemSameWithActive &&
            undoRedo.active &&
            Object.keys(undoRedo.active).length !== 0
              ? [...undoRedo.history, undoRedo.active]
              : undoRedo.history,
          active: updatedItem,
          future: redoFuture,
        },
        enableTextEdit: false,
        emitUndoRedoItemToSocket: {
          ...updatedItem,
          type: "field-update",
        },
      };
    } else {
      return state;
    }
  };
}