import React from "react";
import {connect, batch} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {WrapColorType} from "../common/WrapColorStyles";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectThemeProps} from "../../../../../../store/selectors/template/theme.selector";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import { ChooseShadowType, ShadowType, ShadowBox, WrapShadowTypes } from "../common/styles/shadowStyles";
import { selectTempScale } from "../../../../../../store/selectors/template/template.selector";

export const shadowTypeOptions = [
  {
    name: "None",
    shadowType: "none",
    value: "",
    size: 0,
    color: "transparent",
    blur: 0,
  },
  {
    name: "Classic",
    shadowType: "classic",
    value: "#5c5353 3px 3px 6px",
    size: 0.6,
    color: "#8e8e8eff",
    blur: 7,
  },
  {
    name: "Dim",
    shadowType: "dim",
    value: "rgb(132 132 133) -2px -2px 5px, rgb(132 132 133) 2px 2px 5px",
    // "0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)",
    size: 0.6,
    color: "#8a8a93ff",
    blur: 15,
  },
  {
    name: "Hard",
    shadowType: "hard",
    value: "#777792 4px 4px 0px",
    size: 1,
    color: "#777792",
    blur: 0,
  },
  {
    name: "Border",
    shadowType: "border",
    value: "#777792 0px 0px 0px 4px",
    size: 0.9,
    color: "#777792",
    blur: 0,
  },
];

const DisplayItem = ({item, active, onSelect}) => {
  const handleClick = () => {
    onSelect(item);
  };
  return (
    <ChooseShadowType active={active} onClick={handleClick}>
      <ShadowType active={active}>
        <ShadowBox style={{boxShadow: item?.value}} />
      </ShadowType>
      <p>{item?.name}</p>
    </ChooseShadowType>
  );
};

const ImageShadow = ({
  slideID,
  fieldID,
  styles,
  storeFieldUndoRedo,
  updateFieldStyles,
  activeThemeProps,
  updateFieldCollaboration,
  fileType,
  tempScale
}) => {
  const {enabledShadow, shadowColor, shadowType} = styles || {};
  const {image} = activeThemeProps || {};

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const handleEmitValue = (type, value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const toggleShadowStatus = () => {
    handleEmitValue("enabledShadow", styles?.enabledShadow);
    if (styles?.enabledShadow) {
      handleStyleUpdate("enabledShadow", false);
      handleEmitValue("enabledShadow", false);
    } else {
      handleStyleUpdate("enabledShadow", true);
      handleEmitValue("enabledShadow", true);

      if (styles?.shadowSize > 10) {
        handleStyleUpdate("shadowSize", styles?.shadowSize > 10 ? 0.7 : styles?.shadowSize)
      }
    }
  };

  const onSelectShadow = async (item) => {
    const updateShadow =
      shadowColor === image?.shadowColor ? image?.shadowColor : shadowColor;
    await handleStyleUpdate("shadowType", item?.shadowType);
    await handleStyleUpdate("shadowSize", item?.size);
    await handleStyleUpdate("shadowBlur", item?.blur);
    await handleStyleUpdate("shadowColor", updateShadow);

    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  const updateShadowSize = (value) => {
    handleStyleUpdate("shadowSize", Number(value));
  };

  const updateShadowBlur = (value) => {
    handleStyleUpdate("shadowBlur", Number(value));
  };

  const updateShadowColor = (value) => {
    handleStyleUpdate("shadowColor", value);
  };

  const onColorDebounce = (value) => {
    handleEmitValue("shadowColor", value);
  };

  return (
    <>
      <div style={{ marginBottom: '1rem'}}>
        <Switch
          title="Add Shadow"
          value={enabledShadow}
          onItemChange={toggleShadowStatus}
          checked={enabledShadow}
        />
      </div>
      {enabledShadow ? (
        <div style={{marginBottom: 50}}>
          {/* {fileType !== 'png' ? */}
            <div>
              <WrapShadowTypes>
                {shadowTypeOptions.map((item, i) => (
                  <DisplayItem key={i} active={item?.shadowType === shadowType} item={item} onSelect={onSelectShadow} />
                ))}
              </WrapShadowTypes>
            </div> 
          {/* : undefined} */}

          <WrapColorType style={{marginTop: 25}}>
            <Title>Color</Title>
            <PopoverColorBox
              value={shadowColor}
              updateFieldStyles={updateShadowColor}
              onDebounce={onColorDebounce}
            />
          </WrapColorType>

          <div style={{paddingBottom: "2em"}}>
            <RangeSlider
              title="Width"
              value={styles?.shadowSize}
              max={1}
              min={0}
              step={0.01}
              onItemChange={updateShadowSize}
            />
          </div>

          {fileType !== 'png' ?
            <div>
              <RangeSlider
                title="Blur"
                value={styles?.shadowBlur}
                max={15}
                min={0}
                step={0.1}
                onItemChange={updateShadowBlur}
                fieldType="shadowBlur"
              />
            </div>
          : undefined}
        </div>
      ) : undefined}
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    activeThemeProps: selectThemeProps(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate)
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateFieldStyles,
    storeFieldUndoRedo,
    updateFieldCollaboration,
  }))
)(ImageShadow);
