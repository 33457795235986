import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Spinner} from "../../../../oat-ui";
import {useParams} from "react-router";
// import {Modal, SpinningIcon} from "ui";
// import AdDeleteTemp from "./AdDeleteTemp";
import TemplateInfo from "./TemplateInfo";
// import ConfirmTempDelete from "./ConfirmTempDelete";
// import AddTemplateTags from "./AddTemplateTags";

const TemplateDetails = () => {
  const [editing, setEditing] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [template, setTemplate] = useState();
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [addTagModalStatus, setAddModalStatus] = useState(false);
  let params = useParams();
  const {tempID} = params;

  useEffect(() => {
    if (tempID) {
      setLoadingData(true);
      fetch(
        `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/template/${tempID}`
      )
        .then((response) => response.json())
        .then((data) => {
          setTemplate(data);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
  }, []);

  const fetchSelectedTheme = (themeID) => {
    // setTimeout(() => {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {"Content-Type": "application/json"},
    //   };
    //   fetch("http://localhost:4000/flyers", requestOptions)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       //  setTemplate(data);
    //       setTemplate(itemDetails);
    //       // setLoadingImages(false);
    //     });
    //   setTemplate({
    //     ...template,
    //     images: [
    //       "https://images.unsplash.com/photo-1548678342-aa677e0c8a1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80",
    //       "https://images.unsplash.com/photo-1634582870449-90577753e6e3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80",
    //     ],
    //   });
    // }, 1000);
  };

  const toggleDeleteModal = () => {
    setDeleteModalStatus(!deleteModalStatus);
  };

  const toggleAddTagModal = () => {
    setAddModalStatus(!addTagModalStatus);
  };

  const handleUpdateStatus = () => {
    setEditing(!editing);
  };

  return (
    <Layout>
      {!loadingData && template ? (
        <WrapLayout>
          <TemplateInfo
            template={template}
            onSelectTheme={fetchSelectedTheme}
            editing={editing}
            updateEditStatus={handleUpdateStatus}
          />
        </WrapLayout>
      ) : (
        <WrapSpinningIcon>
          <Spinner />
        </WrapSpinningIcon>
      )}

      {/* {deleteModalStatus ? (
        <Modal width={300} height={200} closeModal={toggleDeleteModal}>
          <ConfirmTempDelete />
        </Modal>
      ) : undefined}

      {addTagModalStatus ? (
        <Modal width={500} height={320} closeModal={toggleAddTagModal}>
          <AddTemplateTags closeModal={toggleAddTagModal} />
        </Modal>
      ) : undefined} */}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 200px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrapLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 25px;
  padding: 12px;
  width: 100%;
`;

const WrapSpinningIcon = styled.div`
  height: ${window.innerHeight - 130}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default TemplateDetails;
