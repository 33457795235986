import styled from "styled-components";
import {primaryGrey} from "../../../../../../oat-color-variables";

export const TooltipLayout = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const PopperLayout = styled.div`
  position: absolute;
  width: ${({width}) => (width ? width : 140)}px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${({theme}) => theme.boxShadow1};
`;

export const SelectedColor = styled.div`
  background: ${({color}) => color};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #b9b8b8;
`;

export const WrapTooltipSvg = styled.div`
  width: 18px;
  height: 18px;
  fill: ${primaryGrey};
`;

export const ColorSet = styled.div`
  margin-right: 8px;
  display: flex;
`;

export const ExpandSvg = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  margin-left: 8px;
  stroke: #6c6b6b;
`;
