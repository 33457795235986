import {
  ADD_GROUP_SECTION,
  DELETE_GROUP_SECTION,
  EDIT_GROUP_SECTION_CONTENT,
  EDIT_GROUP_SECTION_LINK,
} from "../actionTypes";

export function addGroupSection(payload) {
  return {
    type: ADD_GROUP_SECTION,
    payload,
  };
}

export function editGroupSectionContent(payload) {
  return {
    type: EDIT_GROUP_SECTION_CONTENT,
    payload,
  };
}

export function deleteGroupSection(id) {
  return {
    type: DELETE_GROUP_SECTION,
    id,
  };
}

export function editGroupSectionLink(payload) {
  return {
    type: EDIT_GROUP_SECTION_LINK,
    payload,
  };
}
