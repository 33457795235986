import styled from "styled-components";

export const CategorySection = styled.div`
  font-size: 0.9rem;
  margin-bottom: 120px;
`;

export const WrapCategory = styled.div`
  padding: 12px 0 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 600;
  color: grey;
  padding: 12px;
  flex-direction: column;
`;

export const FolderHeader = styled.div`
  display: flex;
  flex: 1;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;
