import styled from "styled-components";
import {paleBlue2} from "../../../../../oat-color-variables";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ContentSection = styled.div`
  padding: 15px 12px;
  box-sizing: border-box;
`;

export const WrapField = styled.div`
  margin: 8px 0;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  box-sizing: border-box;
  height: 58px;
  flex-direction: column;
  padding: 10px;
  background: ${paleBlue2};
  border-top: 1px solid #dddde1;
`;

export const BottomSection = styled.div`
  display: flex;
  width: auto;
  height: 34px;
`;

export const TextField = styled.input`
  width: 100%;
  height: 32px;
  color: #232323;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #cdcdcd;
  padding: 6px 3px;
  box-sizing: border-box;
`;
