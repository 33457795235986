import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  selectTemplateSize,
  selectTempScale,
} from "../../../../../../store/selectors/template/template.selector";
import ShowLegends from "../barCharts/common/ShowLegends";
import {chartDefaultFontSize} from "../chartDefaultFontSize";

const angleForArcLength = (arcLength, atRadius) => arcLength / atRadius;

// const round = (value, precision) => {
//   const multiplier = Math.pow(10, precision || 0);
//   return Math.round(multiplier * value) / multiplier;
// };

const PieChart = ({
  size,
  chartType,
  dataSeries,
  donut,
  styles,
  tempScale,
  colorSet,
}) => {
  const [orgSize, setOrgSize] = useState(size.w);
  const d2r = (d) => d * (Math.PI / 180);
  const r2d = (r) => r / (Math.PI / 180);
  const center = size.w / 2;
  const hollow = donut ? (size.w * 100) / 150 : 0; // change hole size
  const thickness = (size.w - hollow) / 2;
  const radiusOuter = size.w / 2;
  const radiusInner = radiusOuter - thickness;
  const gapSize = donut
    ? (size.w * (styles.donutGap / tempScale)) / orgSize
    : (size.w * 0) / orgSize; // CHANGE GAP SIZE HERE
  const gapAngleOffsetInner = r2d(angleForArcLength(gapSize, radiusInner));
  const gapAngleOffsetOuter = r2d(angleForArcLength(gapSize, radiusOuter));
  const minAngleDeg = r2d(angleForArcLength(gapSize * 2, radiusInner));
  const minValue = minAngleDeg / 360;
  const {
    valueColor,
    fontSize,
    fontFamily,
    showValue,
    showLegend,
    legendColor,
    legendFontSize,
    legendPosition,
    scale,
    scaleType,
    currencyUnit,
  } = styles;

  const coords = (deg, r) => {
    const rad = d2r(deg);
    return [center - Math.cos(rad) * r, center - Math.sin(rad) * r];
  };

  const computePercentages = () => {
    const dataSet = dataSeries
      ?.slice(1)
      ?.map((item, index) => [...item, colorSet[index]]);

    // when chart type changes from one type to another
    // pie or donut has only three array rooms [category, value and color]
    const filterOut = dataSet.map((outer) =>
      outer.filter((inner, i) => i === 0 || i === 1 || i === outer.length - 1)
    );

    const filtered = (filterOut || [])
      .map((innerArr) => innerArr.filter((el, index) => index !== 0))
      .filter((value) => Math.abs(value[0]) > 0);

    const total = filtered.reduce((t, value = 0) => t + value[0], 0);

    return filtered.map((item) => ({
      ...item,
      value: item[0],
      percent: item[0] / total,
      color: item[1],
    }));
  };

  const items = computePercentages();

  const makeSegment = ({paths, subtotal}, {value, percent, color}, i) => {
    const colors = [];
    const startAngle = subtotal * 360 + 90; // so we start at 12 o'clock
    const endAngle = startAngle + percent * 360;

    // no gaps for values beneath the minimum threshold
    const useGap = percent >= minValue;
    const innerGap = useGap ? gapAngleOffsetInner : 0;
    const outerGap = useGap ? gapAngleOffsetOuter : 0;

    const startAngleInner = startAngle + innerGap;
    const startAngleOuter = startAngle + outerGap;
    const endAngleInner = endAngle - innerGap;
    const endAngleOuter = endAngle - outerGap;

    const [x1, y1] = coords(startAngleInner, radiusInner); // start point on inner circle
    const [x2, y2] = coords(startAngleOuter, radiusOuter); // start point on outer circle
    const [x3, y3] = coords(endAngleOuter, radiusOuter); // end point on outer circle
    const [x4, y4] = coords(endAngleInner, radiusInner); // end point on inner circle

    const largeArc = percent > 0.5 ? 1 : 0;
    const sweepOuter = 1;
    const sweepInner = 0;

    const commands = [
      `M${x1} ${y1}`, // move to start angle coordinate, inner radius
      `L${x2} ${y2}`, // line to start angle coordinate, outer radius
      // arc to end angle coordinate, outer radius
      `A${radiusOuter} ${radiusOuter} 0 ${largeArc} ${sweepOuter} ${x3} ${y3}`,
      `L${x4} ${y4}`, // line to end angle coordinate, inner radius
      // arc back to start angle coordinate, inner radius
      `A${radiusInner} ${radiusInner} 0 ${largeArc} ${sweepInner} ${x1} ${y1}`,
    ];

    const fill = color || colors[i % colors.length];
    const fillProp = fill ? {fill} : {};
    const [valueLabelX, valueLabelY] = coords(
      (startAngleOuter + endAngleOuter) / 2,
      radiusOuter / (donut ? 1.2 : 2) // * 2.5 // put text outside
    );

    // console.log(
    //   ("x", 31.83 + Math.cos((startAngle * Math.PI) / 180) * radiusInner)(
    //     "y",
    //     31.83 + Math.sin((startAngle * Math.PI) / 180) * radiusInner
    //   )
    // );

    // const lineWithDistance = startAngleOuter - 90;
    paths.push(
      <g key={i}>
        <path
          className="donut-chart-segment"
          {...fillProp}
          stroke="none"
          d={commands.join(" ")}
        />
        {showValue ? (
          <text
            x={
              // (lineWithDistance > 180 ? -180 : 180) + // add for line with some distance
              valueLabelX //- (fontSize * (tempSize.w / 537)) / 2
            }
            y={valueLabelY} //+ (fontSize * (tempSize.w / 537)) / 2
            fill={valueColor}
            style={{
              fontSize: fontSize, // tempScale, //* (tempSize.w / 536),
              fontFamily,
              textAnchor: "middle",
            }}
          >
            <tspan
              style={{
                fontSize: fontSize, // tempScale, //* (tempSize.w / 536),
              }}
            >
              {scaleType === "currency" ? currencyUnit + " " : undefined}
              {value}
              {scaleType === "percentage" ? "%" : undefined}
              {/* {Math.round(percent * 100)} */}
            </tspan>
          </text>
        ) : undefined}
      </g>
    );

    return {
      paths,
      subtotal: subtotal + percent,
    };
  };

  const getTopRow = dataSeries;
  const legendData = getTopRow.slice(1);
  let legend = [];
  legend = [];
  legendData.map((item, i) => {
    legend.push(item[0]);
  });

  return (
    <>
      <div
        style={{
          // transform: `scale(${scale ? scale : 1})`,
          // transformOrigin: "0px 0px",
          position: "absolute",
          fontSize: chartDefaultFontSize,
        }}
      >
        <svg
          width={size.w}
          height={size.h}
          viewBox={`0 0 ${size.w} ${size.h}`}
          overflow="visible"
        >
          {items &&
            items.reduce(makeSegment, {
              paths: [],
              subtotal: 0,
            }).paths}
        </svg>

        {showLegend ? (
          <ShowLegends
            items={legend}
            chartSize={size}
            colorSet={colorSet}
            styles={styles}
          />
        ) : undefined}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(PieChart);
