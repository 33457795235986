import React from "react";
import {OatBlogDetails} from "../../../packages/oat-public";

const BlogList = () => {
  return (
    <div>
      <OatBlogDetails />
    </div>
  );
};

export default BlogList;
