import React, {useState, useRef} from "react";
import styled from "styled-components";
import {
  ModalBody,
  Header,
  Footer,
  BottomSection,
} from "../../reuse/dashboardUI/modalStyles";
import {Button, ButtonSpinner} from "../../../../oat-ui";
import {Auth} from "aws-amplify";
import {red4} from "../../../../oat-color-variables";

const avatarItems = [
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/chicken+avatar-01.svg",
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/dog+avatar-01.svg",
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/fox+avatar.svg",
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/lion+avatar-01.svg",
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/monkey-avatar-01.svg",
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/panda+avatar-01.svg",
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/piggy+avatar-01.svg",
  "https://oat-default-init.s3.amazonaws.com/avatar-icons/rabbit+avater-01.svg",
];

const DisplayItem = ({item, onSelect}) => {
  const handleSelection = () => {
    onSelect(item);
  };

  return (
    <Item onClick={handleSelection}>
      <img src={item} alt="" />
    </Item>
  );
};

const ChangeAvatar = ({
  username,
  email,
  cognitoUsername,
  onChangedAvatar,
  onClose,
  onCancel,
}) => {
  // for uploading from device
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({message: null, status: false});

  const uploadFromDevice = (event) => {
    if (error.status) {
      setError({message: "", status: false});
    }
    if (!uploading && event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 1048576) {
        setError({
          message: "File size cannot be bigger than 10MB.",
          status: true,
        });
        event.target.value = null;
      } else {
        setUploading(true);
        let format = event.target.files[0].type.split("image/")[1];
        if (format === "svg+xml") {
          format = "svg";
        }
        const getImg = event.target.files[0];
        const uploadedImage = URL.createObjectURL(getImg);
        const img = new Image();
        let fileReader = new FileReader();

        fileReader.onload = () => (img.src = fileReader.result);
        fileReader.onerror = () => {
          setError({message: "Couldn't identify image size.", status: true});
        };
        fileReader.readAsDataURL(event.target.files[0]);

        img.onload = async () => {
          const {url, fields, error, message} = await fetch(
            `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/userInfo/avatar`,
            {
              method: "POST",
              body: JSON.stringify({
                contentType: event.target.files[0].type,
                format,
              }),
            }
          )
            .then((response) => response.json())
            .catch((e) => setError({message: e, status: true}));

          const data = {
            bucket: "oat-users",
            ...fields,
            "Content-Type": event.target.files[0].type,
            file: event.target.files[0],
          };

          const formData = new FormData();
          for (const name in data) {
            formData.append(name, data[name]);
          }
          if (!error) {
            await fetch(url, {
              method: "POST",
              body: formData,
            }).then(async (data) => {
              URL.revokeObjectURL(uploadedImage);
              img.src = uploadedImage;
            });

            await fetch(
              `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/userInfo/avatar/resize`,
              {
                method: "PUT",
                body: JSON.stringify({user: username, cognitoUsername, fileType: format}),
              }
            )
              .then((response) => response.json())
              .then(async (data) => {
                await onChangedAvatar(data.imageUrl);
              })
              .catch((err) => {
                setError({message: err, status: true});
              });
            event.target.value = null;
            await setUploading(false);
            // await onCancel();
          } else {
            setError({status: true, message});
            setUploading(false);
            event.target.value = null;
          }
        };
      }
    }
  };

  const handleAvatarSelection = async (value) => {
    if (!processing) {
      setProcessing(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        picture: value,
      }).then(async () => {
        await onChangedAvatar(value);
        await setProcessing(false);
        await onCancel();
      });
    }
  };

  const inputFileRef = useRef();

  const onInitUploadImg = () => {
    setError({message: "", status: false});
    inputFileRef.current.click();
  };

  return (
    <div>
      <ModalBody>
        <Header style={{paddingBottom: 0}}>Choose Avatar</Header>
      </ModalBody>

      <ListItem style={{opacity: processing ? 0.5 : 1}}>
        {avatarItems &&
          avatarItems.map((item, i) => (
            <DisplayItem key={i} item={item} onSelect={handleAvatarSelection} />
          ))}
      </ListItem>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            width: "100%",
            height: 34,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Error>{error.status ? error.message : undefined}</Error>
          <UploadImageButton>
            <Button onClick={onInitUploadImg} disabled={uploading}>
              {!uploading ? "Upload from Device" : <ButtonSpinner />}
            </Button>
            <input
              ref={inputFileRef}
              type="file"
              onChange={uploadFromDevice}
              hidden
            />
          </UploadImageButton>
        </BottomSection>
      </Footer>
    </div>
  );
};

const Item = styled.div`
  cursor: pointer;
`;

const ListItem = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  height: 320px;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const UploadImageButton = styled.div`
  width: 180px;
  height: 100%;
  @media only screen and (max-width: 849px) {
    margin: 12px 0 0 0;
  }
`;

const Error = styled.div`
  font-size: 0.83rem;
  color: ${red4};
`;

export default ChangeAvatar;
