import React, {useState} from "react";
import styled from "styled-components";
import EditChartDataSeries from "./EditChartDataSeries";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../../store/selectors/fields.selector";
import {ChartActions} from "../reuse/chartAddRemove";
import {
  createChartRow,
  onViewChartInfo,
} from "../../../../../../../store/actions/fields/chart.action";
import {ScrollY} from "../../../../../../../../oat-ui/styles/ScrollY";
import ScaleTypeFormat from "./ScaleTypeFormat";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";
import {ArrowBack} from "../../../../../../../../oat-svg-icons";

const EditChartInfo = ({
  slideID,
  activeField,
  storeFieldUndoRedo,
  createChartRow,
  updateFieldCollaboration,
  onViewChartInfo,
}) => {
  const [activeCell, setActiveCell] = useState({
    status: false,
    row: null,
    col: null,
  });
  const [removeStatus, setRemoveStatus] = useState(false);

  const selectCell = ({row, col}) => {
    if (col !== 0 || row !== 0) {
      setActiveCell({
        status: true,
        row,
        col,
      });
    }
  };

  const handleDeselect = () => {
    setActiveCell({
      status: false,
      row: null,
      col: null,
    });
  };

  const {subtype: type, dataSeries} = activeField || {};

  const handleNewRow = () => {
    const active = {slideID, fieldID: activeField?.key};
    createChartRow(active);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  // const detectShortcutKeys = useCallback((event) => {
  //   if (event.keyCode == 9 && activeField.type === "chart") {
  //     setActiveCell((prev) => ({
  //       ...prev,
  //       status: true,
  //       row:
  //         dataSeries[0].length - 1 === prev.col &&
  //         dataSeries.length - 1 !== prev.row
  //           ? prev.row + 1
  //           : prev.row,
  //       col: dataSeries[0].length - 1 !== prev.col ? prev.col + 1 : 0,
  //     }));
  //     event.preventDefault();
  //   }
  // }, []);

  // useEffect(() => {
  //   document.addEventListener("keydown", detectShortcutKeys, false);
  //   return () => {
  //     document.removeEventListener("keydown", detectShortcutKeys, false);
  //   };
  // });

  const displayTable = () => {
    if (type === "pie" || type === "donut" || type === "gauge") {
      return (
        <EditChartDataSeries
          type={"single-column"}
          dataSeries={dataSeries}
          activeCell={activeCell}
          onSelectCell={selectCell}
          onDeselectCell={handleDeselect}
          removeStatus={removeStatus}
          colorSet={activeField?.colorSet}
          onCreateRow={handleNewRow}
        />
      );
    } else {
      return (
        <EditChartDataSeries
          type={"multi-column"}
          dataSeries={dataSeries}
          activeCell={activeCell}
          onSelectCell={selectCell}
          onDeselectCell={handleDeselect}
          removeStatus={removeStatus}
          colorSet={activeField?.colorSet}
          onCreateRow={handleNewRow}
        />
      );
    }
  };

  const handleRemove = () => {
    setRemoveStatus(!removeStatus);
  };

  const handleBack = () => {
    onViewChartInfo();
  };

  return (
    <div style={{marginBottom: 160}}>
      <WrapActions>
        <WrapSvg onClick={handleBack}>{ArrowBack}</WrapSvg>

        <ChartActions
          style={{
            fontSize: "0.95rem",
            textAlign: "center",
            color: !removeStatus ? "rgb(250 116 116)" : "#fff",
          }}
          removeStatus={removeStatus}
          onClick={handleRemove}
        >
          {!removeStatus ? "To Delete" : "Done"}
        </ChartActions>
      </WrapActions>

      <WrapTable>
        <Table>{dataSeries && displayTable()}</Table>
      </WrapTable>

      <ScaleTypeFormat />
    </div>
  );
};

const WrapTable = styled.div`
  margin-left: 0.8rem;
  display: flex;
  overflow-x: auto;
  overflow-y: scroll;
  margin-bottom: 1rem;
  max-height: ${window.innerHeight - 200}px;
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ff6600; /* Thumb color */
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0; /* Track color */
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${({theme}) => theme.deskGap};
  border-bottom: 0px;
  tr {
    td {
      width: 100px;
      color: ${({theme}) => theme.deskPrimaryTxt};
      font-size: 0.9rem;
      background: ${({theme}) => theme.deskSecondaryBg2};
      border-right: 1px solid ${({theme}) => theme.deskGap};
      border-bottom: 1px solid ${({theme}) => theme.deskGap};
      padding: 14px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 850px) {
    margin-right: 0.8rem;
  }
`;

const WrapActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  cursor: pointer;
  display: flex;
  justify-dataseries: space-between;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    activeField: selectActiveFieldProps(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldCollaboration,
  createChartRow,
  storeFieldUndoRedo,
  onViewChartInfo,
})(EditChartInfo);
