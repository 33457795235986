export function calcRotatedOffsets(pos, size, rotatedDegree) {
  const radians = rotatedDegree * Math.PI / 180;
  const topLeft = {
    x: pos.x + size.w/2 + size.w/-2 * Math.cos(radians) - size.h/-2 * Math.sin(radians), 
    y: pos.y + size.h/2 + size.w/-2 * Math.sin(radians) + size.h/-2 * Math.cos(radians)
  }
  const topRight = {
    x: pos.x + size.w/2 + size.w/2 * Math.cos(radians) - size.h/-2 * Math.sin(radians), 
    y: pos.y + size.h/2 + size.w/2 * Math.sin(radians) + size.h/-2 * Math.cos(radians)
  }
  const btmRight = {
    x: pos.x + size.w/2 + size.w/2 * Math.cos(radians) - size.h/2 * Math.sin(radians), 
    y: pos.y + size.h/2 + size.w/2 * Math.sin(radians) + size.h/2 * Math.cos(radians)
  }
  const btmLeft = {
    x: pos.x + size.w/2 + size.w/-2 * Math.cos(radians) - size.h/2 * Math.sin(radians), 
    y: pos.y + size.h/2 + size.w/-2 * Math.sin(radians) + size.h/2 * Math.cos(radians)
  }
  return {
    topLeft,
    topRight,
    btmRight,
    btmLeft
  }
}

export function calcShapeLineOffsets(startPoint, endPoint, rotatedDegree) {
  const rotationCenter = {
    x: (startPoint.x + endPoint.x) / 2,
    y: (startPoint.y + endPoint.y) / 2,
  };
  const angle = (rotatedDegree * Math.PI) / 180;

  const translatedStartPoint = {
    x: startPoint.x - rotationCenter.x,
    y: startPoint.y - rotationCenter.y,
  };
  const translatedEndPoint = {
    x: endPoint.x - rotationCenter.x,
    y: endPoint.y - rotationCenter.y,
  };

  const rotatedStartPoint = {
    x:
      translatedStartPoint.x * Math.cos(angle) -
      translatedStartPoint.y * Math.sin(angle),
    y:
      translatedStartPoint.x * Math.sin(angle) +
      translatedStartPoint.y * Math.cos(angle),
  };
  const rotatedEndPoint = {
    x:
      translatedEndPoint.x * Math.cos(angle) -
      translatedEndPoint.y * Math.sin(angle),
    y:
      translatedEndPoint.x * Math.sin(angle) +
      translatedEndPoint.y * Math.cos(angle),
  };

  const newStartPoint = {
    x: rotatedStartPoint.x + rotationCenter.x,
    y: rotatedStartPoint.y + rotationCenter.y,
  };
  const newEndPoint = {
    x: rotatedEndPoint.x + rotationCenter.x,
    y: rotatedEndPoint.y + rotationCenter.y,
  };

  return {newStartPoint, newEndPoint};
}

