import styled, {css} from 'styled-components';

export const WrapSpinner  = styled.div`
  margin-top: 92px;
`

export const GoBack = styled.div`
  width: 24px;
  height: 24px;
  fill: ${({theme}) => theme.svgIconColor};
  margin: 0px 0px 15px 15px;
  cursor: pointer;
`

export const svgStyles = css`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  padding: 14px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const WrapItem = styled.div`
  ${svgStyles};
  img { 
    max-width: 100%;
    object-fit: contain;
  }
  @media only screen and (max-width: 849px) {
    flex: 0 80%;
    height: 120px;
  }
`;

export const WrapIconItem = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  flex: 0 46%;
  background: ${({theme}) => theme.deskSecondaryBg3};
  border-radius: 10px;
  margin-bottom: 16px;
  @media only screen and (min-width: 850px) {
    flex: 0 31%;
  }
`;