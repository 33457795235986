import React, {useState, useEffect} from "react";
import {
  FolderLayout,
  FolderSection,
  FolderHeader,
  WrapSvg,
} from "./folderStyles";
import {ArrowDropDown, ArrowDropUp, Plus} from "../../../../oat-svg-icons";
import Folder from "./Folder";
import {connect} from "react-redux";
import {
  selectActiveFolder,
  selectActiveWorkSpace,
} from "../../../store/selectors/userDashboard.selector";
import {createNewFolder} from "../../../store/actions/folder.action";
import CreateFolder from "./CreateFolder";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import FolderActions from "./FolderActions";
import {Modal, Popper} from "../../../../oat-ui";
import {useNavigate} from "react-router-dom";
import EditPrivateFolder from "./EditPrivateFolder";
import RequiresUpgradeToPro from "../../reuse/requiresUpgradeToPro/RequiresUpgradeToPro";

const Folders = ({
  folders,
  user,
  createNewFolder,
  sectionTitle,
  workspaceID,
  activeFolderID,
  folderType,
}) => {
  let navigate = useNavigate();
  const [expandFolder, setExpandFolder] = useState(true);
  const [createFolder, setCreateFolder] = useState({
    status: false, // popper open
    name: "",
    private: false,
    accessibleUsers: [], // if private
    processing: false, // sending request
    error: false,
    errorMessage: null,
    offset: {x: 0, y: 0},
  });
  const [displayActionsPopper, setDisplayActionsPopper] = useState({
    status: false,
    clickedItem: null,
    offset: {x: 0, y: 0},
    folderType: null,
    subfolder: true,
    folderIndex: null,
  });
  const [editFolderPrivacy, setFolderPrivacy] = useState({
    status: false,
    folderID: null,
    private: null,
    allowedUsers: [],
  });

  const toggleExpand = () => {
    setExpandFolder(!expandFolder);
  };

  const openCreateBox = (e) => {
    setDisplayActionsPopper({
      status: false,
      clickedItem: null,
      offset: {x: 0, y: 0},
      folderType: null,
      subfolder: false,
      folderIndex: null,
    });
    setCreateFolder({
      ...createFolder,
      status: true,
      name: "New Folder",
      processing: false,
      errorMessage: null,
      error: false,
      offset: {x: e.clientX, y: e.clientY},
    });
  };

  const handleFolderName = (e) => {
    const {value} = e.target;
    setCreateFolder({
      ...createFolder,
      name: value,
    });
  };

  const handleFolderCreation = async () => {
    if (createFolder.name !== "" && !createFolder.processing) {
      const isSubfolder = createFolder.status ? false : true;
      let newFolder = {
        folderName: createFolder.name,
        folderType,
        // folderIndex: displayActionsPopper.folderIndex,
        isSubfolder,
      };
      if (isSubfolder) {
        newFolder = {
          ...newFolder,
          parentFolder: displayActionsPopper.clickedItem.folderID,
        };
      }
      if (!isSubfolder) {
        newFolder = {
          ...newFolder,
          privateFolder: createFolder.private,
          allowedUsers: createFolder.accessibleUsers,
        };
      }
      setCreateFolder({
        ...createFolder,
        errorMessage: null,
        error: false,
        processing: true,
      });
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          workspaceID,
          ...newFolder,
        }),
      };
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/folders`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "success") {
            const payload = {...newFolder, folderID: data.folderID};
            createNewFolder(payload);
            closeCreatePopper();
            return navigate(
              `/dashboard/${workspaceID}/designs/${data.folderID}`
            );
          } else {
            setCreateFolder({
              ...createFolder,
              error: true,
              errorMessage: data.message,
              processing: false,
            });
          }
        })
        .catch((e) => {
          setCreateFolder({
            ...createFolder,
            error: true,
            errorMessage: "Something went wrong.",
            processing: false,
          });
        });
    }
  };

  const closeCreatePopper = () => {
    setCreateFolder({
      status: false,
      name: "",
      private: false,
      error: false,
      errorMessage: null,
      processing: false,
      accessibleUsers: [],
    });
    if (displayActionsPopper.status) {
      closeActionsPopper();
    }
  };

  const handleRightClick = (e, item, index) => {
    if (e.type === "contextmenu") {
      const yOffset = e.clientY > window.innerHeight ? e.clientY - 165 : e.clientY;
      setDisplayActionsPopper({
        status: !displayActionsPopper.status,
        clickedItem: item,
        offset: {x: e.clientX, y: yOffset},
        folderType,
        folderIndex: index,
      });
      e.preventDefault();
    }
  };

  const handlePrivacyUpdate = (folderID) => {
    const getFolderInfo =
      folders && folders.find((item) => item.folderID === folderID);
    setFolderPrivacy({
      status: true,
      folderID,
      private: getFolderInfo.private,
      allowedUsers: getFolderInfo.allowedUsers,
    });
    closeActionsPopper();
  };

  const closeActionsPopper = () => {
    setDisplayActionsPopper({
      ...displayActionsPopper,
      status: false,
    });
  };

  const onCloseFolderPrivacyModal = () => {
    setFolderPrivacy({
      status: !editFolderPrivacy,
      folderID: null,
      private: null,
      allowedUsers: [],
    });
  };

  useEffect(() => {
    // expand folder after being created
    if (!expandFolder) {
      setExpandFolder(true);
    }
  }, [folders.length]);

  const [requiresUpgrade, setRequiresUpgrade] = useState(false);
  const onRequiresUpgradeModal = () => {
    setRequiresUpgrade(true);
    closeActionsPopper();
  };

  const closeRequiresUpgradeModal = () => {
    setRequiresUpgrade(false);
  };

  return (
    <FolderLayout
      style={{
        margin: `0 2px ${!expandFolder ? 0 : 12}px 8px`,
      }}
    >
      <FolderSection style={{paddingBottom: 18}}>
        <WrapSvg style={{paddingRight: 5}} onClick={toggleExpand}>
          {expandFolder ? ArrowDropUp : ArrowDropDown}
        </WrapSvg>
        <FolderHeader onClick={toggleExpand}>
          <p>{sectionTitle}</p>
        </FolderHeader>
        <WrapSvg style={{marginRight: 1}}>
          <div style={{display: "flex"}} onClick={openCreateBox}>
            {Plus}
          </div>
        </WrapSvg>
      </FolderSection>

      {expandFolder &&
        folders &&
        folders.map((item, i) => (
          <Folder
            index={i}
            key={i}
            username={user.username}
            folder={item}
            onRightClick={handleRightClick}
            folderType={folderType}
            currentFolderID={activeFolderID}
          />
        ))}

      {displayActionsPopper.status ? (
        <Popper
          width="auto"
          height="auto"
          offset={{
            x: 200,
            y: displayActionsPopper.offset.y,
          }}
          onClose={closeActionsPopper}
        >
          <FolderActions
            close={closeActionsPopper}
            selectedFolder={displayActionsPopper.clickedItem}
            workspaceID={workspaceID}
            subfolderInfo={createFolder}
            onCreateSubFolder={handleFolderCreation}
            onChangeSubFolder={handleFolderName}
            onEditFolderPrivacy={handlePrivacyUpdate}
            onRequiresUpgrade={onRequiresUpgradeModal}
          />
        </Popper>
      ) : undefined}

      {editFolderPrivacy.status ? (
        <Modal width={350} height="auto" close={onCloseFolderPrivacyModal}>
          <EditPrivateFolder
            editFolderPrivacy={editFolderPrivacy}
            folderType={folderType}
            workspaceID={workspaceID}
            user={user}
            close={onCloseFolderPrivacyModal}
          />
        </Modal>
      ) : undefined}

      {createFolder.status ? (
        <Popper
          width="auto"
          height="auto"
          offset={{
            x: 200,
            y: createFolder.offset.y + 20,
          }}
          onClose={closeCreatePopper}
        >
          {/* Subfolder creation */}
          <CreateFolder
            folderType={folderType}
            isParentFolder={createFolder.status}
            parentFolderID={displayActionsPopper.folderID}
            close={closeCreatePopper}
          />
        </Popper>
      ) : undefined}

      {requiresUpgrade ? (
        <RequiresUpgradeToPro close={closeRequiresUpgradeModal} />
      ) : undefined}
    </FolderLayout>
  );
};

const mapStateToProps = (state) => {
  const {userDashboard} = state;
  return {
    user: selectUserInfo(state.authUser),
    workspaceID: selectActiveWorkSpace(userDashboard).ID,
    activeFolderID: selectActiveFolder(userDashboard).ID,
  };
};

export default connect(mapStateToProps, {createNewFolder})(Folders);
