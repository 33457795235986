import React, {useState, useEffect} from "react";
import DesignListOptions from "../../DesignListOptions";
import {connect} from "react-redux";
import {selectTemplateSize} from "../../../../../store/selectors/template/template.selector";
import {createSlideFromTemplate} from "../../../../../store/actions/template/slide.action";
import {ArrowBack} from "../../../../../../oat-svg-icons";
import styled from "styled-components";
import {Spinner} from "../../../../../../oat-ui";

const ViewTemplateVersion = ({
  selectedTempVersion,
  tempSize,
  createSlideFromTemplate,
  backToVersionList,
}) => {
  const [template, setTemplate] = useState({
    fetching: false,
    items: [],
    error: {
      status: false,
      message: null,
    },
  });

  useEffect(() => {
    if (!template.fetching) {
      setTemplate({
        ...template,
        fetching: true,
        items: [],
      });
      fetch(
        `https://oat-users.s3.amazonaws.com/${selectedTempVersion.tempSourceID}`
      )
        .then((response) => response.json())
        .then((data) => {
          setTemplate({
            ...template,
            fetching: false,
            items: data.details,
          });
        })
        .catch((e) => {
          setTemplate({
            fetching: false,
            items: [],
            error: {
              status: true,
              message: e,
            },
          });
        });
    }
  }, []);

  const onCreateSlide = (slide) => {
    createSlideFromTemplate(slide);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "18px 12px 12px",
      }}
    >
      <Back onClick={backToVersionList}>{ArrowBack}</Back>
      {!template.fetching ? (
        <DesignListOptions
          slides={template.items}
          orgTempSize={tempSize}
          onSelectSlide={onCreateSlide}
          noOfColumns={3}
          loading={template.fetching}
        />
      ) : (
        <div style={{margin: 120}}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

const Back = styled.div`
  width: 18px;
  height: 18px;
  margin-bottom: 12px;
  cursor: pointer;
  fill: ${({theme}) => theme.deskPrimaryTxt};
`;

const mapStateToProps = (state) => {
  return {
    tempSize: selectTemplateSize(state.designTemplate),
  };
};

export default connect(mapStateToProps, {createSlideFromTemplate})(
  ViewTemplateVersion
);
