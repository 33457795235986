import React, {useRef} from "react";
import styled from "styled-components";
import {detectClickOutside} from "../../../../../../oat-ui";
import CanvasPopper from "../../../../../ui/CanvasPopper";
import ChatOnTemplate from "./ChatOnTemplate";
import ResizeTemplate from "./ResizeTemplate";
import SelectSlideOptions from "./SelectSlideOptions";

const SlideOptionsPopper = ({
  onChangePopper,
  selected,
  onClose,
  changeModal,
}) => {
  let displayItem = null;
  const popUpRef = useRef(null);

  const changePopperType = (value) => {
    if (value === "slide-animation") {
      changeModal(value);
      return;
    } else if (value === "custom-links") {
      changeModal(value);
      return;
    } else if (value === "notification") {
      changeModal(value);
      return;
    }

    // else if (value === "content-grouping") {
    //   changeModal(value);
    //   return;
    // }
    onChangePopper(value);
  };

  const onSelectModal = (value) => {
    changeModal(value);
  };

  detectClickOutside(popUpRef, () => onClose());

  switch (selected) {
    case "chat-on-template":
      displayItem = (
        <CanvasPopper
          width={260}
          height={305}
          offset={{
            x: window.innerWidth - 400,
            y: 56,
          }}
          onClose={onClose}
        >
          <ChatOnTemplate />
        </CanvasPopper>
      );
      break;
    case "slide-options":
      displayItem = (
        <CanvasPopper
          width={210}
          height={96}
          offset={{
            x: window.innerWidth - 315,
            y: 60,
          }}
          onClose={onClose}
        >
          <SelectSlideOptions onSelect={changePopperType} />
        </CanvasPopper>
      );
      break;
    // case "add-mobile-view":
    //   displayItem = (
    //     <CanvasPopper
    //       width={200}
    //       height={150}
    //       offset={{
    //         x: window.innerWidth - 100,
    //         y: 60,
    //       }}
    //       onClose={onClose}
    //     >
    //       <AddMobileView onSelect={onSelectModal} />
    //     </CanvasPopper>
    //   );
    //   break;
    case "resize-template":
      displayItem = (
        <Popper
          ref={popUpRef}
          style={{
            width: 330,
            height: "auto",
          }}
        >
          <ResizeTemplate close={onClose} />
        </Popper>
      );
      break;
    default:
      displayItem = (
        <Popper ref={popUpRef}>
          <SelectSlideOptions onSelect={changePopperType} />
        </Popper>
      );
  }

  return displayItem;
};

const Popper = styled.div`
  position: absolute;
  top: 0px;
  width: 260px;
  height: auto;
  color: ${({theme}) => theme.deskPrimaryTxt};
  box-shadow: ${({theme}) => theme.boxShadow1};
  background: ${({theme}) => theme.deskPrimaryBg};
  top: 50px;
  right: 90px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
  font-weight: 500;
`;

export default SlideOptionsPopper;
