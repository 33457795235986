import React from "react";
import "./ToggleSwitch.css";
import styled from "styled-components";

const ToggleSwitch = ({value, onItemChange}) => {
  const toggleChange = () => {
    onItemChange(!value);
  };

  return (
    <Wrapper>
      <WrapSwitch>
        <label className="oatall-input-switch">
          <input
            type="checkbox"
            value={value}
            onChange={toggleChange}
            checked={value}
          />
          <span className="slider round" />
        </label>
      </WrapSwitch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;
  @media only screen and (min-width: 850px) {
    display: flex;
    width: 100%;
  }
`;

const WrapSwitch = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

export default ToggleSwitch;
