import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {selectUserInfo} from "../../../../../../../../redux/user/authUser";
import {Spinner} from "../../../../../../../oat-ui";
import {
  selectTemplateID,
  selectWorkspacePlan,
} from "../../../../../../store/selectors/template/template.selector";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import CreateCustomLink, {domainLink} from "./CreateCustomLink";
import CustomLinkDetails from "./CustomLinkDetails";

const DisplayItem = ({item, onSelect}) => {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  const handleCopy = (e) => {
    const urlLink = inputRef.current.innerHTML;
    navigator.clipboard.writeText(domainLink + urlLink);
    setCopied(true);
    e.stopPropagation();
  };

  const handleSelect = () => {
    onSelect(item);
  };

  return (
    <>
      <Item onClick={handleSelect}>
        <LinkName>{item.linkName}</LinkName>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Link ref={inputRef}>{item.linkSlug}</Link>
          <CopyLink onClick={handleCopy} copied={copied}>
            {!copied ? "Copy Link" : "Copied"}
          </CopyLink>
        </div>
      </Item>

      <Gap />
    </>
  );
};

const CustomLinks = ({username, tempID, workspacePlan}) => {
  const [displayType, setDisplayType] = useState("display-custom-links");
  const [selectedItem, setSelectedItem] = useState();
  const [links, setLinks] = useState([]);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [fetching, setFetching] = useState(false);
  const [processing, setProcessing] = useState(false); // edit, delete

  const onSelectItem = (item) => {
    setDisplayType("view-custom-link-details");
    setSelectedItem(item);
  };

  const switchCustomLinkCreateForm = () => {
    setDisplayType("create-custom-link");
  };

  const backToList = () => {
    setDisplayType("display-custom-links");
  };

  const onCreateLink = async (payload) => {
    if (workspacePlan === "free") {
      setError({
        status: true,
        message: "Workspace needs to be upgraded.",
      });
    } else if (!processing) {
      removeErrorMessage();
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/custom-link`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payload,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            setLinks([
              {
                linkSlug: payload.linkSlug,
                linkName: payload.linkName,
                slugID: payload.slugID,
                uid: payload.uid,
                passcode: payload.passcode,
              },
              ...links,
            ]);
            backToList();
          } else {
            addErrorMessage(data.message);
          }
          setProcessing(false);
        })
        .catch((e) => {
          addErrorMessage();
          setProcessing(false);
        });
    }
  };

  const onUpdateLink = async (payload) => {
    if (!processing) {
      removeErrorMessage();
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/custom-link/${payload.uid}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payload,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            const updateLinks = links.map((item) =>
              item.uid === payload.uid ? payload : item
            );
            setLinks(updateLinks);
            backToList();
          } else {
            addErrorMessage(data.message);
          }
          setProcessing(false);
        })
        .catch((e) => {
          addErrorMessage();
          setProcessing(false);
        });
    }
  };

  const onDeleteLink = async (linkUID) => {
    if (!processing) {
      removeErrorMessage();
      setProcessing(true);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/custom-link/${linkUID}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            const updateLinks = links.filter((link) => link.uid !== linkUID);
            setLinks(updateLinks);
            backToList();
          } else {
            addErrorMessage(data.message);
          }
          setProcessing(false);
        })
        .catch((e) => {
          addErrorMessage();
          setProcessing(false);
        });
    }
  };

  const addErrorMessage = (message) => {
    setError({
      status: true,
      message: message ? message : "Something went wrong",
    });
  };

  const removeErrorMessage = () => {
    if (error.status)
      setError({
        status: false,
        message: "Something went wrong",
      });
  };

  useEffect(() => {
    removeErrorMessage();
    setFetching(true);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/custom-link`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setLinks(data.response.items);
        } else {
          addErrorMessage(data.message);
        }
        setTimeout(() => {
          setFetching(false);
        }, 300);
      })
      .catch((e) => {
        addErrorMessage();
        setFetching(false);
      });
  }, []);

  let displayView = null;
  switch (displayType) {
    case "display-custom-links":
      displayView = (
        <>
          <Wrapper>
            {fetching ? (
              <WrapSpinner style={{boxSizing: "border-box"}}>
                <Spinner />
              </WrapSpinner>
            ) : links.length > 0 ? (
              <WrapItems>
                {links &&
                  links.map((item, i) => (
                    <DisplayItem key={i} item={item} onSelect={onSelectItem} />
                  ))}
              </WrapItems>
            ) : (
              <StartAddingCustomLink>
                <div>Create a Friendly URL Builder to transform your link to human readle one.</div>
                <span>oatall.com/p/new-project-proposal-123</span>
                <WrapLinkBtn>
                  <SpecialButton onClick={switchCustomLinkCreateForm}>
                    Add Link
                  </SpecialButton>
                </WrapLinkBtn>
              </StartAddingCustomLink>
            )}
          </Wrapper>

          {links.length > 0 ? (
            <Footer>
              <AddLink>
                <SpecialButton onClick={switchCustomLinkCreateForm}>
                  Add Link
                </SpecialButton>
              </AddLink>
            </Footer>
          ) : undefined}
        </>
      );
      break;
    case "view-custom-link-details":
      displayView = (
        <CustomLinkDetails
          item={selectedItem}
          back={backToList}
          onUpdate={onUpdateLink}
          onDelete={onDeleteLink}
          error={error}
          processing={processing}
        />
      );
      break;
    case "create-custom-link":
      displayView = (
        <CreateCustomLink
          onCreate={onCreateLink}
          back={backToList}
          error={error}
          processing={processing}
        />
      );
      break;
    default:
  }

  return <Layout>{displayView}</Layout>;
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`;

const WrapItems = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  height: 350px;
`;

const Item = styled.div`
  font-size: 0.92rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  padding: 30px 10px 16px;
  margin: 0 18px;
  cursor: pointer;
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg};
    box-sizing: border-box;
    border-radius: 8px;
  }
  &:first-child {
    margin-top: 14px;
  }
  &:last-child {
    margin-bottom: 40px;
  }
`;

const textStyles = css`
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LinkName = styled.div`
  font-size: 0.92rem;
  font-weight: 700;
  margin-bottom: 8px;
  ${textStyles};
  width: 350px;
`;

const Link = styled.div`
  ${textStyles};
  width: 320px;
`;

const AddLink = styled.div`
  height: 38px;
  margin-right: 16px;
`;

const CopyLink = styled.button`
  border-radius: 5px;
  background: ${({theme, copied}) =>
    !copied ? theme.secondaryThemeHover : "#26a769"};
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 0.8rem;
  font-weight: 700;
  padding: 4px 12px;
  box-sizing: border-box;
  outline: none;
  border: none;
  cursor: pointer;
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const WrapSpinner = styled.div`
  margin-top: 94px;
`;

const StartAddingCustomLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center;
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 1.1rem;
  padding-top: 124px;
  span{
    padding-top: 34px;
    font-size: 14px;
  }
`;

const WrapLinkBtn = styled.div`
  width: 122px;
  height: 32px;
  margin-top: 34px;
`;

const Gap = styled.div`
  margin: 15px;
  border-bottom: 1px solid ${({theme}) => theme.deskGap};
`;

const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
    tempID: selectTemplateID(state.designTemplate),
    workspacePlan: selectWorkspacePlan(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(CustomLinks);
