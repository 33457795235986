import {useState, useEffect} from "react";

export function useElementOnScreen(ref, rootMargin = "0px") {
  const [isIntersecting, setIsIntersecting] = useState(null);
  const [hasLeft, setHasLeft] = useState(false);

  useEffect(() => {
    if (!hasLeft) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (isIntersecting === null && !hasLeft && entry.isIntersecting) {
            setIsIntersecting(entry.isIntersecting);
            setHasLeft(true);
          } else if (isIntersecting !== null && !entry.isIntersecting) {
            setIsIntersecting(false);
          }
        },
        {rootMargin}
      );
      if (ref.current && !hasLeft) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }
    // function handleScroll() {
    //   const {bottom, top} = ref.current.getBoundingClientRect();
    //   if (!hasLeft) {
    //     const animateOffset = (20 * window.innerHeight) / 100;
    //     return setIsIntersecting(window.innerHeight - top > animateOffset);
    //   }
    // }
    // if (!hasLeft) {
    //   window.addEventListener("scroll", handleScroll);
    // }
    // return () => window.removeEventListener("scroll", handleScroll);
  }, [hasLeft]); //

  return isIntersecting;
}
