import React from "react";
import {Option} from "../../../../../ui/styles/SelectStyles";
import {ThemeColor, ThemeName} from "../ChangeSlideTheme";
import SlideThemeActions from "../SlideThemeActions";
import styled from "styled-components";

const DisplayTheme = ({
  theme,
  onSelectTheme,
  onSelectActions,
  selectedActionsIndex,
  onAppliedActions,
  themesLength
}) => {
  const handleThemeSelection = () => {
    onSelectTheme(theme);
  };

  const handleThemeActionsIndex = (e) => {
    onSelectActions(theme.id);
  };

  // will not deselect field on clicking the div
  const cancelEventPropagate = (e) => {
    e.stopPropagation();
  };

  return (
    <Option style={{position: "relative"}}>
      <ThemeName
        onMouseDown={cancelEventPropagate}
        onClick={handleThemeSelection}
      >
        <ThemeColor bg={theme.bg} />
        <p>{theme.name}</p>
      </ThemeName>

      <MoreOptions
        onMouseDown={cancelEventPropagate}
        onClick={handleThemeActionsIndex}
      >
        <div />
        <div />
        <div />
      </MoreOptions>

      {theme.id === selectedActionsIndex ? (
        <SlideThemeActions 
          selectedID={theme.id} 
          applied={onAppliedActions} 
          themesLength={themesLength}
        />
      ) : undefined}
    </Option>
  );
};

const MoreOptions = styled.div`
  display: flex;
  position: absolute;
  right: 1px;
  width: 18px;
  flex-direction: column;
  align-items: center;
  div {
    width: 3px;
    height: 3px;
    background: ${({theme}) => theme.svgIconColor};
    margin-bottom: 2px;
    border-radius: 50%;
    opacity: 0.8;
  }
`;

export default DisplayTheme;
