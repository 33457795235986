import React from "react";
import styled from "styled-components";
import {Item, ActiveDot, WrapSidebar} from "../reusableStyles/sidebarItem";
import {UserSidebarPanel} from "../UserSidebar";

const SharedToUserSidebar = () => {
  return (
    <UserSidebarPanel>
      <WrapSidebar>
        <Item>
          <p>Templates</p>
          <ActiveDot />
        </Item>
      </WrapSidebar>
    </UserSidebarPanel>
  );
};

export default SharedToUserSidebar;
