import React from "react";

const ColumnBar = ({fill = "#ffd13d", x, y, width, height, chartWidth}) => (
  <g transform={`scale(-1,1) translate(-${chartWidth}px, 0)`}>
    {/* <rect fill={fill} x={x} y={y} height={height} width={width}>
      <animate
        attributeName="width"
        from={0}
        to={width}
        dur="0.8s"
        fill="freeze"
        // begin="2s"
      />
    </rect> */}
    <rect fill={fill} x={x} y={y} height={height} width={width} />
  </g>
);

export default ColumnBar;