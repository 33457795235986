
import styled from "styled-components";
import { blue1, blue6, grey4, greyText3, purple1, red4 } from "../../../oat-color-variables";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  align-items: center;
  min-height: 300px;
  max-height: ${({$height}) => $height - 85}px;
`;

export const ImageSection = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
  @media only screen and (min-width: 601px) {
    height: 480px;
  }
  @media only screen and (max-width: 949px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    padding: 0;
    width: 92%;
    margin-top: 14px;
  }
`;

export const WrapImages = styled.div`
  flex: 1;
  // max-height: ${({panelHeight}) => panelHeight + 15}px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ImageList = styled.div`
  columns: 2;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 4px;
  @media only screen and (max-width: 949px) {
    padding-left: 0px;
  }
  &:last-child {
    margin-bottom: 100px;
  }
`;

export const Item = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 1px #e4e3ea;
`;

export const BottomPanel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: ${blue6};
  border-top: 1px solid #f1f1f1;
  padding: 12px;
  box-sizing: border-box;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    position: fixed;
    bottom: 0;
    padding: 6px;
  }
`;

export const BtmChildSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media only screen and (max-width: 600px) {
    margin: 8px 0;
  }
`;

export const Avatar = styled.img`
  max-width: 100%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 2px 0.5px #bcbcbc;
`;

export const MadeBy = styled.div` 
  font-size: 0.9rem;
  // cursor: pointer;
  text-decoration: none;
  color: inherit;
  span {
    color: ${purple1};
    font-size: 1.1rem;
  }
`;

export const WrapSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 380px;
  @media only screen and (max-width: 850px) {
    min-height: 180px;
  }
`;

export const UseTemplate = styled.div`
  font-size: 0.92rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  background: ${blue1};
  padding: 8px 38px;
  width: 100%;
  min-width: 120px;
  height: 20px;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (min-width: 768px) {
    margin-right: 14px;
    width: auto;
    font-size: 1rem;
  }
`;

export const Creator = styled.div`
  display: flex;
  flex: 1;
  margin: 18px 0;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Error = styled.div`
  font-size: 0.9rem;
  color: ${red4};
  padding-right: 18px;
`;

export const BackButton = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 12px;
  fill: ${grey4};
`;

export const PricingType = styled.div`
  background: ${({premium}) => premium ? "#fff" : "#61cb7c"};
  color: ${({premium}) => premium ? greyText3 : "#fff"};
  font-size: 0.8rem;
  border: 1px solid ${({premium}) => premium ? "#e1e1e1" : "#d2deea"};
  font-weight: 700;
  padding: 2px 16px;
  border-radius: 32px;
`;