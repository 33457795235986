import { useCallback, useContext, createContext, useEffect, useState } from "react"

const NetworkContext = createContext(null)

export const NetworkProvider = ({children})=>{
    const [isOnline, setOnline] = useState(()=> {
        return navigator.onLine
    })
		
    const setOnlineToTrue = useCallback(() =>{
        setOnline(true)
    }, [])

    const setOnlineToFalse = useCallback(()=>{
        setOnline(false)
    }, [])

    useEffect(()=>{
        window.addEventListener('online', setOnlineToTrue)
        window.addEventListener('offline', setOnlineToFalse)
        return ()=>{
            window.removeEventListener('online', setOnlineToTrue)
            window.removeEventListener('offline', setOnlineToFalse)
        }
    }, [setOnlineToTrue,setOnlineToFalse ])

    return (
        <NetworkContext.Provider value={{isOnline}}>
            {children}
        </NetworkContext.Provider>
    )
}

export default function useDetectOnlineStatus() {
    const context = useContext(NetworkContext);
    return context;
}