// import {
//   primaryPurple,
//   brightPurple,
//   primaryBlack,
//   primaryGrey,
//   softPurple,
//   secondaryBlack,
// } from "../../globalStyles";

export const mainHover = "#f4eaff"; // select workspace color

export const paleBlue = "#f2f3ff"; // "#f4eafff0"; // background
export const paleBlueHover = '#f3f4ffcf';
export const userPrimaryPurple = "#9c9aed"; //"#836cff"; //primaryPurple;
// export const userBrightPurple = brightPurple;
export const red = "#ff1616";

export const userPrimaryBlack = "#232323"; //primaryBlack;
export const userSecondaryBlack = "#232323"; //secondaryBlack;
export const userPrimaryGrey = "#787878"; //primaryGrey;
export const dashGrey1 = "#323030";
export const userSoftPurple = "purple"; //softPurple;

export const greyLine = "#e6e6e6";

export const userPrimaryGreen = "#9fefcf66";
export const userSecondaryGreen = "#4cd54c";
export const userPrimaryOrange = "#efba9f66";
export const userSecondaryOrange = "#d5a54c";

export const modalBackDrop = "#38397563";
