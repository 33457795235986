import styled, {css} from "styled-components";
import {userPrimaryGrey, paleBlue, paleBlueHover} from "../../../userDashboardStyles";

// folder item, folder actions text
export const sidebarItemText = css`
  user-select: none;
  font-size: 0.93rem;
  font-weight: 500;
  color: #4c4c4e; // ${userPrimaryGrey};
  cursor: pointer;
  border-radius: 6px;
  @media only screen and (min-width: 850px) {
    &:hover {
      background: ${paleBlue};
    }
  }
  @media only screen and (max-width: 849px) {
    font-size: 0.9rem;
  }
`;

export const ActiveDot = styled.div`
  border-radius: 50%;
  background: #5090f6cf;
  width: 10px;
  height: 10px;
  visibility: ${({$active}) => ($active ? "visible" : "hidden")};
`;

export const WrapSidebar = styled.div`
  padding: 16px 0;
  box-sizing: border-box;
  width: 100%;
  height: auto;
`;

export const Item = styled.div`
  position: relative;
  padding: 0px 8px 0px 14px;
  margin: 0px 12px 5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  ${sidebarItemText};
  p {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 6px;
    &:hover {
      background: ${paleBlue};
    }
    &:hover ${ActiveDot} {
      visibility: visible;
    }
  }
`;

export const sidebarHeader = css`
  padding: 12px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: ${userPrimaryGrey};
  border-radius: 6px;
  position: relative;
  left: -10px;
`;
