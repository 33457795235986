export const colorList = [
  "#fff",
  "#4287f5",
  "#4debde",
  "#dedede",
  "#8dd929",
  "#cca43d",
  "#496275",
  "#cf4036",
  "#d459b3",
  "#0f5987",
  "#c4db2e",
  "#68d15c",
  "#444f91",
  "#232323",
];
