import {getItemCorners} from "../../canvas/tooltip/getRotatedOffsets";

export const calcGroupSelection = (currentSlide, groupedItems) => {
  let getXOffsets = [],
    getYOffsets = [],
    getMaxWidth = [],
    getMaxHeight = [],
    getInitValues = {};

  let selectionWidth = "",
    selectionHeight = "",
    minXOffset = "",
    minYOffset = "";

  currentSlide &&
    Object.entries(currentSlide.fields).map(([id, field]) => {
      const {pos, size, styles} = field;
      if (groupedItems?.includes(field.key)) {
        // from center to tooltip start pt.
        const x1y1 = {x: pos.x, y: pos.y};
        // right bottom corner of img
        const x2y2 = {x: pos.x + size.w, y: pos.y + size.h};
        const points = {
          offset: {x: (x1y1.x + x2y2.x) / 2, y: (x2y2.y + x1y1.y) / 2},
          size: {width: size.w, height: size.h},
          angle: (styles.rotate * Math.PI) / 180,
        };
        const {minX, maxX, minY, maxY} = getItemCorners(points);

        getXOffsets.push(minX);
        getYOffsets.push(minY);

        getMaxWidth.push(maxX);
        getMaxHeight.push(maxY);
      }

      minXOffset = Math.min(...getXOffsets);
      minYOffset = Math.min(...getYOffsets);

      const maxWidth = Math.max(...getMaxWidth);
      const maxHeight = Math.max(...getMaxHeight);

      selectionWidth = maxWidth - minXOffset;
      selectionHeight = maxHeight - minYOffset;
    });

  Object.entries(currentSlide.fields).map(([id, field]) => {
    const {type, pos, size, key} = field;
    if (groupedItems?.includes(key)) {
      const distanceX = ((pos.x - minXOffset) * 100) / selectionWidth;
      const distanceY = ((pos.y - minYOffset) * 100) / selectionHeight;
      const values = {
        offset: {
          x: pos.x,
          y: pos.y,
          orgPercentX: distanceX,
          orgPercentY: distanceY,
        },
        size: {
          w: size.w,
          h: size.h,
        },
      };

      if (type === "image") {
        const {imgPos} = field;
        const imgProps = {
          ...values,
          imgPos: {
            x: imgPos.x,
            y: imgPos.y,
          },
          imgSize: {
            w: size.w2,
            h: size.h2,
          },
        };
        getInitValues[key] = imgProps;
      } else {
        getInitValues[key] = values;
      }
    }
  });

  return {
    size: {
      width: selectionWidth,
      height: selectionHeight,
    },
    offset: {
      x: minXOffset,
      y: minYOffset,
    },
    initValues: getInitValues,
  };
};
