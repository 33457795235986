import React, {useState, useEffect, useRef, useCallback} from "react";
import {Spinner} from "../../../../../../oat-ui";
import {columnGap, Grid} from "./ReuseStyles";
import styled from "styled-components";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import { Error, WrapDelete, WrapError, WrapImage } from "./styles/userImageStyles";
import {Trash} from "../../../../../../oat-svg-icons";
import { dispatchEmbedVideoLink } from "../../../../../store/actions/fields/video.action";
import { storeActionsUndoRedo } from "../../../../../store/actions/fields/undoRedo.action";
import { connect } from "react-redux";
import { selectActiveSlide } from "../../../../../store/selectors/template/slide.selector";
import { createShortKey } from "../../../../../store/reducers/functions/fields/utils";
import { closeDrawer } from "../../../../../store/actions/layout.action";

const DisplayItem = ({onSelect, imageWidth, item, deleting, onDelete}) => {
  const handleSelection = () => {
    onSelect(item);
  };

  const handleImageDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(item);
  };

  return (
    <>
      {item.type === 'video' ? (
        <WrapImage style={{opacity: !deleting ? 1 : 0.4}} onClick={handleSelection}>
          <video width="640" height="360" playsinline src={`https://oat-users.s3.amazonaws.com/${item.image}`} type="video/mp4">
          </video>
          <WrapDelete onClick={handleImageDelete}>
            <WrapIcon>{Trash}</WrapIcon>
          </WrapDelete>
        </WrapImage>
      ) : (
        <WrapImage
          style={{
            opacity: !deleting ? 1 : 0.4,
            // width: imageWidth,
            // height: newHeight,
            // marginBottom: 14,
          }}
        >
          <img loading="lazy" src={`https://oat-users.s3.amazonaws.com/${item.image}`} onClick={handleSelection} />
          <WrapDelete onClick={handleImageDelete}>
            <WrapIcon>{Trash}</WrapIcon>
          </WrapDelete>
        </WrapImage>
      )}
    </>
  )
};

const UserUploadImages = ({
  username,
  workspace,
  replaceImg,
  onUploadImgByLink,
  onReplaceImgByLink,
  dispatchEmbedVideoLink,
  storeActionsUndoRedo,
  slideID,
  closeModal,
  closeDrawer,
  onClose
}) => {
  const panelRef = useRef();
  const [images, setImages] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [deletingID, setDeletingID] = useState(null);
  const [error, setError] = useState("");
  const [imageWidth, setImageWidth] = useState();
  const [initialLoading, setInitialLoading] = useState(true);
  const windowSize = getWindowSize();
  const [paginationToken, setPaginationToken] = useState(null);
  const noOfColumns = windowSize.width >= 850 ? 3 : 2;

  useEffect(() => {
    const panelWidth = panelRef.current.offsetWidth;
    setImageWidth((panelWidth - columnGap * 2) / noOfColumns);
  }, [noOfColumns, windowSize.width]);

  useEffect(() => {
    if (error) {
      setError(false);
    }
    if (paginationToken === null && images.length === 0) {
      setFetching(true);
      fetch(`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces/${workspace}/uploaded-assets?paginationToken`)
        .then((response) => response.json())
        .then((data) => {
          setImages(data.response.images);
          setPaginationToken(data.response.paginationToken)
          setFetching(false);
          setInitialLoading(false);
          if (error !== "") {
            setError(``);
          }
        })
        .catch((error) => {
          setError(`Couldn't load images`);
          setFetching(false);
        });
      }
  }, [username, workspace]);

  const handleSelection = (item) => {
    if (item.type === "video") {
      dispatchEmbedVideoLink({
        slideID, 
        key: createShortKey(), 
        link: item.image,
        uploaded: true,
        videoBy: 'user',
        format: item.format
      });
      storeActionsUndoRedo("create");
      if (window.innerWidth >= 850) {
        onClose();
      } else {
        closeDrawer();
      }
    } else {
      const payload = {
        naturalWidth: item.imgSize.w,
        naturalHeight: item.imgSize.h,
        src: item.image,
        imageBy: item.imageBy,
        imgType: item.imgType
      };
      if (!replaceImg) {
        onUploadImgByLink(payload);
      } else {
        onReplaceImgByLink(payload);
      }
    }
  };

  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (initialLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && paginationToken) {
          setInfiniteLoading(true);
          fetch(`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces/${workspace}/uploaded-assets?paginationToken=${paginationToken}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              setImages([...images, ...data.response.images]);
              setPaginationToken(data.response.paginationToken)
              setInfiniteLoading(false);
            }
          })
          .catch((error) => {
            setError(true);
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [initialLoading, workspace, username, paginationToken]
  );

  const handleDeleteImage = async (item) => {
    if (deletingID === null) {
      setDeletingID(item.key);
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/images`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileSize: item.fileSize,
            format: item.format,
            key: item.key,
            workspaceID: workspace
          }),
        }
      )
        .then((data) => {
          setDeletingID(null);
          setImages((images) =>
            images && images.filter((image) => image.key !== item.key)
          );
        })
        .catch((error) => {});
    }
  };

  return (
    <ImageList ref={panelRef}>

      {error !== "" && !fetching ? (
        <WrapError>
          <Error>{error}</Error>
        </WrapError>
      ) : undefined}

      {!fetching ? (
        <Grid>
          {windowSize.width >= 850 ? (
            <>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 1) {
                      return (
                        <DisplayItem
                          key={item.key}
                          item={item}
                          onSelect={handleSelection}
                          onDelete={handleDeleteImage}
                          deleting={deletingID === item.key}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 2) {
                      return (
                        <DisplayItem
                          key={item.key}
                          item={item}
                          onSelect={handleSelection}
                          onDelete={handleDeleteImage}
                          deleting={deletingID === item.key}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
              <div>
                {images &&
                  images.map((item, i) => {
                    // if (images.length === i + 1) {
                    //   return <div key={i} ref={lastItemRef} />;
                    // } else
                    if ((i + 1) % 3 === 0) {
                      return (
                        <DisplayItem
                          key={item.key}
                          item={item}
                          onSelect={handleSelection}
                          onDelete={handleDeleteImage}
                          deleting={deletingID === item.key}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
            </>
          ) : (
            <>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 1) {
                      return (
                        <DisplayItem
                          key={item.key}
                          item={item}
                          onSelect={handleSelection}
                          onDelete={handleDeleteImage}
                          deleting={deletingID === item.key}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
              <div>
                {images &&
                  images.map((item, i) => {
                    if ((i + 1) % 3 === 2) {
                      return (
                        <DisplayItem
                          key={item.key}
                          item={item}
                          onSelect={handleSelection}
                          onDelete={handleDeleteImage}
                          deleting={deletingID === item.key}
                          imageWidth={imageWidth}
                        />
                      );
                    }
                  })}
              </div>
            </>
          )}
          <div style={{height: 300}} />
          <div ref={lastItemRef} />
        </Grid>
      ) : (
        <div style={{width: "100%", marginTop: "20%"}}>
          <Spinner />
        </div>
      )}

      {infiniteLoading ? 
        <div style={{width: "100%", margin: "50px 0"}}>
          <Spinner />
        </div> 
      : undefined}
    </ImageList>
  );
};

const ImageList = styled.div`
  width: 100%;
  margin: 25px 0;
`;

// const WrapDelete = styled.div`
//   position: absolute;
//   width: 50px;
//   height: 30px;
//   background: #000000a1;
//   bottom: 20px;
//   right: 10px;
//   border-radius: 4px;
//   display: none;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
// `;


const WrapIcon = styled.div`
  width: 16px;
  height: 16px;
  fill: #dedede;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    slideID: selectActiveSlide(designTemplate),
  }
}

export default connect(mapStateToProps, {
  dispatchEmbedVideoLink,
  storeActionsUndoRedo,
  closeDrawer
})(UserUploadImages);
