export function changeFolderInit() {
  return function (state, action) {
    return {
      ...state,
      activeFolder: {
        ID: action.folderID,
        folderType: action.folderType,
        folderName: state.activeFolder.folderName,
      },
      fetchData: {
        ...state.fetchData,
        error: false,
        loading: true,
      },
    };
  };
}

export function changeFolderSuccess() {
  return function (state, action) {
    return {
      ...state,
      templates: action.templates,
      fetchData: {
        ...state.fetchData,
        loading: false,
      },
      paginationToken: null, // !important
      activeFolder: {
        ID: action.payload.folderID,
        folderName: action.payload.folderName,
        folderType: action.payload.folderType,
      },
    };
  };
}

export function changeFolderErr() {
  return function (state, action) {
    return {
      ...state,
      templates: [],
      fetchData: {
        loading: false,
        error: action.error,
      },
      activeFolder: {
        ...state.activeFolder,
        folderName: undefined,
      },
    };
  };
}

export function createNewFolder() {
  return function (state, action) {
    const {payload} = action;
    const {folderType, isSubfolder, parentFolder} = payload;
    if (isSubfolder) {
      return {
        ...state,
        templates: [],
        folders: {
          ...state.folders,
          [folderType]: state.folders[folderType].map((folder) =>
            folder.folderID === parentFolder
              ? {
                  ...folder,
                  subfolders: [
                    ...folder.subfolders,
                    {
                      folderID: payload.folderID,
                      folderName: payload.folderName,
                    },
                  ],
                }
              : folder
          ),
        },
        activeFolder: {
          ID: payload.folderID,
          folderName: payload.folderName,
          folderType,
        },
      };
    } else {
      return {
        ...state,
        templates: [],
        folders: {
          ...state.folders,
          [folderType]: [
            ...state.folders[folderType],
            {
              ...payload,
              private: payload.privateFolder,
              subfolders: [],
            },
          ],
        },
        activeFolder: {
          ID: payload.folderID,
          folderName: payload.folderName,
          folderType,
        },
      };
    };
  };
}

export function changeFolderName() {
  return function (state, action) {
    const {folderType, newFolderName, folderID, isSubfolder, subfolderID} =
      action.payload;

    if (isSubfolder) {
      return {
        ...state,
        folders: {
          ...state.folders,
          [folderType]: state.folders[folderType].map((item) =>
            item.folderID === folderID
              ? {
                  ...item,
                  subfolders: item.subfolders.map((subfolder) =>
                    subfolder.folderID === subfolderID
                      ? {
                          ...subfolder,
                          folderName: newFolderName,
                        }
                      : subfolder
                  ),
                }
              : item
          ),
        },
      };
    } else {
      return {
        ...state,
        folders: {
          ...state.folders,
          [folderType]: state.folders[folderType].map((item) =>
            item.folderID === folderID
              ? {
                  ...item,
                  folderName: newFolderName,
                }
              : item
          ),
        },
      };
    };
  };
}

export function deleteFolder() {
  return function (state, action) {
    const {folderType, folderID, isSubfolder, subfolderID} = action;
    if (isSubfolder) {
      return {
        ...state,
        templates: [],
        folders: {
          ...state.folders,
          [folderType]: state.folders[folderType].map((item) =>
            item.folderID === folderID
              ? {
                  ...item,
                  subfolders: item.subfolders.filter(
                    (subfolder) => subfolder.folderID !== subfolderID
                  ),
                }
              : item
          ),
        },
      };
    } else {
      return {
        ...state,
        templates: [],
        folders: {
          ...state.folders,
          [folderType]: state.folders[folderType].filter(
            (folder) => folder.folderID !== folderID
          ),
        },
      };
    }
  };
}

export function deselectActiveFolder() {
  return function (state, action) {
    return {
      ...state,
      activeFolder: {
        ID: null,
        folderType: null,
      },
    };
  };
}

export function updateFolderPrivacy() {
  return function (state, action) {
    const {folderType, isPrivate, folderID, allowedUsers} = action.payload;
    if (folderType === "designs") {
      return {
        ...state,
        folders: {
          ...state.folders,
          designs: state.folders.designs.map((item) =>
            item.folderID === folderID
              ? {
                  ...item,
                  private: isPrivate,
                  allowedUsers,
                }
              : item
          ),
        },
      };
    } else {
      return state;
    }
  };
}
