import React, {useState} from "react";
import styled from "styled-components";
import {Button, ButtonSpinner} from "../../../../oat-ui";
import {useNavigate, useParams} from "react-router";
import {connect} from "react-redux";
import {dispatchRemoveTemplate} from "../../../store/actions/tempCategory.action";

const AdDeleteTemp = ({template, dispatchRemoveTemplate}) => {
  const [processing, setProcessing] = useState(false);
  let navigate = useNavigate();
  let params = useParams();

  const handleClick = async () => {
    if (!processing) {
      setProcessing(true);
      await fetch(
        `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/template/${template.tempID}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempID: template.tempID,
            themeID: "main", // template.themes[0].slug,
            category: template.category,
            lng: template.lng,
            ratio: template.ratio,
            imagesLength: template.images.medium.length,
            tags: template.tags,
            premium: template.premium,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (data) => {
          setProcessing(false);
          await dispatchRemoveTemplate(template.tempID);
          return navigate(
            `/admin/designs/${params.category}/${template.ratio}`
          );
        })
        .catch((error) => {});
    }
  };

  return (
    <Wrapper>
      <WrapButton>
        <Button
          onClick={handleClick}
          styles={{background: "#f36f6f"}}
          disabled={processing}
        >
          {!processing ? "Delete" : <ButtonSpinner />}
        </Button>
      </WrapButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const WrapButton = styled.div`
  width: 170px;
  height: 40px;
  margin-bottom: 12px;
`;

export default connect(null, {dispatchRemoveTemplate})(AdDeleteTemp);
