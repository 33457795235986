import React from "react";
import styled from "styled-components";
import {blue1} from "../../../../oat-color-variables";

const customers = [
  {
    id: "313312",
    name: "FlyLa",
    joinedDate: "12 / 4 / 2022",
    plan: "pro-business",
    members: [],
  },
  {
    id: "423130",
    name: "Nation Wide",
    joinedDate: "12 / 4 / 2022",
    plan: "pro-business",
    members: [],
  },
];

const DisplayItem = ({item, onSelectItem, index}) => {
  const handleClick = () => {
    onSelectItem();
  };

  return (
    <Item onClick={handleClick}>
      <div>{index}</div>
      <div>{item.id}</div>
      <div>{item.name}</div>
      <div>{item.joinedDate}</div>
      <div className="customer-actions">Edit</div>
      <div className="customer-actions">Delete</div>
    </Item>
  );
};

const CustomerList = () => {
  const handleItemSelection = () => {};

  return (
    <Layout>
      {customers.map((customer, i) => (
        <DisplayItem
          key={i}
          index={i + 1}
          item={customer}
          onSelectItem={handleItemSelection}
        />
      ))}
    </Layout>
  );
};

const Layout = styled.div``;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 700px;
  font-size: 0.94rem;
  padding-bottom: 12px;
  div {
    width: 220px;
  }
  .customer-actions {
    text-align: right;
    cursor: pointer;
    color: ${blue1};
    &:hover {
      opacity: 0.7;
    }
  }
`;

export default CustomerList;
