const MultiColumnChartIcon = (
  <div style={{width: 120, height: 100}}>
    <svg width="100%" height="100%" viewBox="0 0 1185 515">
      <g>
        <g>
          <rect
            fill="#5de097"
            x="167.625"
            y="171.66666666666666"
            height="66.66666666666667%"
            width="105.75"
          ></rect>
        </g>
        <g>
          <rect
            fill="#ef8181"
            x="359.125"
            y="343.3333333333333"
            height="33.333333333333336%"
            width="105.75"
          ></rect>
        </g>
        <g>
          <rect
            fill="#6e61e5"
            x="253.375"
            y="85.83333333333333"
            height="83.33333333333333%"
            width="105.75"
          ></rect>
        </g>

        <g>
          <rect
            fill="#5de097"
            x="660.125"
            y="0"
            height="100%"
            width="105.75"
          ></rect>
        </g>
        <g>
          <rect
            fill="#6e61e5"
            x="775.875"
            y="171.66666666666666"
            height="66.66666666666667%"
            width="105.75"
          ></rect>
        </g>
        <g>
          <rect
            fill="#ef8181"
            x="881.625"
            y="257.5"
            height="50%"
            width="105.75"
          ></rect>
        </g>
      </g>
    </svg>
  </div>
);

export default MultiColumnChartIcon;
