import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {
  uploadAdminMedia,
  uploadAdminMediaError,
} from "../../../store/actions/media.action";
import UploadMedia from "../UploadMedia";

const UploadPublicMedia = ({
  authUser,
  uploadAdminMedia,
  uploadAdminMediaError,
}) => {
  const [location, setLocation] = useState("global");
  const [formItems, setFormItems] = useState({
    keywords: [],
    inputTag: "",
    cutout: false,
  });

  const handleTagInput = (e) => {
    const {value} = e.target;
    setFormItems({...formItems, inputTag: value});
  };

  const addTag = () => {
    if (formItems.inputTag !== "") {
      setFormItems({
        ...formItems,
        inputTag: "",
        keywords: [...formItems.keywords, formItems.inputTag],
      });
    }
  };

  const handleLocationChange = (e) => {
    const {value} = e.target;
    setLocation(value);
  };

  const handleUpload = async (event) => {
    if (event.target.files && event.target.files[0]) {
      // 15 MB

      let imgType = event.target.files[0].type.split("image/")[1];
      if (imgType === "svg+xml") {
        imgType = "svg";
      }
      const img = new Image();
      let fileReader = new FileReader();
      let imgSize = {w: 0, h: 0};

      fileReader.onload = () => (img.src = fileReader.result);
      fileReader.onerror = () => {
        uploadAdminMediaError("Couldn't identify image size.");
      };
      fileReader.readAsDataURL(event.target.files[0]);

      img.onload = async () => {
        imgSize = {
          w: img.width,
          h: img.height,
        };

        const imgInfo = {
          createdBy: '6BYg9lL22VY', // authUser.username, // later change to []
          uploadedBy: '6BYg9lL22VY', // authUser.username,
          imgType,
          imgSize,
          location: {
            locationBased: false,
            country: location, //global,
          },
          storageKey: formItems.storageKey,
          cutout: formItems.cutout,
          keywords: formItems.keywords,
        };
        await uploadAdminMedia(
          `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/media-for-users`,
          event,
          imgInfo
        );
        event.target.value = null;
      };
    }
  };

  const toggleCutoutStatus = () => {
    setFormItems({...formItems, cutout: !formItems.cutout});
  };

  return (
    <Wrapper>
      <UploadMedia
        formItems={formItems}
        onUpdateTag={handleTagInput}
        location={location}
        onAddTag={addTag}
        onUpload={handleUpload}
        onLocationChange={handleLocationChange}
        onToggleCutout={toggleCutoutStatus}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    authUser: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {
  uploadAdminMedia,
  uploadAdminMediaError,
})(UploadPublicMedia);
