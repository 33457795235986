import {deepEqual} from "./undoRedo";

export function selectBackground() {
  return function (state) {
    return {
      ...state,
      activeField: -1,
      selectedFieldType: "background",
    };
  };
}

export function setBackgroundColor() {
  return function (state, action) {
    const {activeSlide, template} = state;
    if (activeSlide !== -1) {
      return {
        ...state,
        template: template.map((item, index) =>
          index === activeSlide
            ? {
                ...item,
                bg: {
                  bg1: action.color,
                  bg2: "",
                  bgAng: 0,
                  gradient: false,
                  // type: "",
                },
              }
            : item
        ),
      };
    } else {
      return {
        ...state,
      };
    }
  };
}

export function updateBackgroundProps() {
  return function (state, action) {
    const {slideID, key, value} = action;
    const {template, undoRedo} = state;
    const slideIndex =
      template && template.findIndex((slide) => slide.id === slideID);
    const prevBg = state.template[slideIndex].bg;
    const newState = [...state.template];

    if (key !== "bgAng") {
      newState[slideIndex].bg = {
        ...newState[slideIndex].bg,
        [key]: value,
      };

      const ifActiveItemNotEmpty =
        undoRedo.active && Object.keys(undoRedo.active).length;
      const ifAlreadyAdded = deepEqual(
        undoRedo.active,
        undoRedo.history[undoRedo.history.length - 1]
      );
      const updateHistory =
        ifActiveItemNotEmpty && !ifAlreadyAdded
          ? [...undoRedo.history, undoRedo.active]
          : [...undoRedo.history];

      return {
        ...state,
        undoRedo: {
          ...state.undoRedo,
          history: updateHistory,
          active: {
            item: prevBg,
            slideID: template[slideIndex].id,
            type: "background",
          },
        },
        template: newState,
      };
    } else if (key === "bgAng") {
      const newState = [...state.template];
      newState[slideIndex].bg = {
        ...newState[slideIndex].bg,
        bgAng: value,
      };
      return {
        ...state,
        template: newState,
      };
    }
  };
}

// ??
export function revertBgColor() {
  return function (state, action) {
    const {activeSlide, template} = state;
    return {
      ...state,
      template: template.map((item, index) =>
        index === activeSlide
          ? {
              ...item,
              bg: {
                bg1: action.bg1,
                bg2: action.bg2,
                bgAng: action.bgAng,
              },
            }
          : item
      ),
    };
  };
}
