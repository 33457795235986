// Folder
// check later with mobile
export const CHANGE_FOLDER_INIT = "CHANGE_FOLDER_INIT";
export const CHANGE_FOLDER_SUCCESS = "CHANGE_FOLDER_SUCCESS";
export const CHANGE_FOLDER_ERR = "CHANGE_FOLDER_ERR";
export const CREATE_NEW_FOLDER = "CREATE_NEW_FOLDER";
export const CHANGE_FOLDER_NAME = "CHANGE_FOLDER_NAME";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DESELECT_ACTIVE_FOLDER = "DESELECT_ACTIVE_FOLDER";
export const UPDATE_FOLDER_PRIVACY = "UPDATE_FOLDER_PRIVACY";

export const LOAD_FOLDERS_AND_WSINFO = "LOAD_FOLDERS_AND_WSINFO";
// workspace
export const T0GGLE_WORKSPACE_MODAL = "T0GGLE_WORKSPACE_MODAL";
export const SWITCH_WORKSPACE_INIT = "SWITCH_WORKSPACE_INIT";
export const SWITCH_WORKSPACE_SUCCESS = "SWITCH_WORKSPACE_SUCCESS";
export const SWITCH_WORKSPACE_ERROR = "SWITCH_WORKSPACE_ERROR";
export const FETCH_WORKSPACES_INIT = "FETCH_WORKSPACES_INIT";
export const FETCH_WORKSPACES_SUCCESS = "FETCH_WORKSPACES_SUCCESS";
export const FETCH_WORKSPACES_ERR = "FETCH_WORKSPACES_ERR";
export const SWITCH_WORKSPACE = "SWITCH_WORKSPACE";
export const CREATE_WORKSPACE_INIT = "CREATE_WORKSPACE_INIT";
export const CREATE_WORKSPACE_SUCCESS = "CREATE_WORKSPACE_SUCCESS";
export const CREATE_WORKSPACE_ERR = "CREATE_WORKSPACE_ERR";
export const DELETE_WORKSPACE_INIT = "DELETE_WORKSPACE_INIT";
export const DELETE_WORKSPACE_SUCCESS = "DELETE_WORKSPACE_SUCCESS";
export const DELETE_WORKSPACE_ERR = "DELETE_WORKSPACE_ERR";
export const RENAME_WORKSPACE_INIT = "RENAME_WORKSPACE_INIT";
export const RENAME_WORKSPACE_SUCCESS = "RENAME_WORKSPACE_SUCCESS";
export const RENAME_WORKSPACE_ERR = "RENAME_WORKSPACE_ERR";
export const SELECT_WORKSPACE_ITEM_ACTIONS = "SELECT_WORKSPACE_ITEM_ACTIONS";
export const RESET_WORKSPACE_SELECTED_ACTIONS =
  "RESET_WORKSPACE_SELECTED_ACTIONS";

// template
export const RENAME_TEMPLATE = "RENAME_TEMPLATE";
export const USER_SEND_SHARE_TEMPLATE = "USER_SEND_SHARE_TEMPLATE";
export const ON_REMOVE_TEMPLATE_SUCCESS = "ON_REMOVE_TEMPLATE_SUCCESS";

// dashboard
export const GET_DASHBOARD_INIT = "GET_DASHBOARD_INIT";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";
export const OPEN_DASHBOARD_MODAL = "OPEN_DASHBOARD_MODAL";
export const CLOSE_DASHBOARD_MODAL = "CLOSE_DASHBOARD_MODAL";
export const DISPLAY_MODAL_ERROR = "DISPLAY_MODAL_ERROR"; // ???

// profile
export const SET_PROFILE_TAGS = "SET_PROFILE_TAGS";
export const ADD_PROFILE_TAG = "ADD_PROFILE_TAG";
export const REMOVE_PROFILE_TAG = "REMOVE_PROFILE_TAG";
export const EDIT_PROFILE_TAG = "EDIT_PROFILE_TAG";
