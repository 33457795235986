import {
  CLOSE_MODAL,
  OPEN_MODAL,
  CHANGE_PAGE_THEME,
  UPDATE_EDITING_PANEL,
  RESET_EDITING_PANEL,
  SHOW_EDIT_PANEL,
  TOGGLE_TOOLTIP_POPPER,
  SET_CANVAS_PANEL_WIDTH,
  OPEN_DRAWER,
  CLOSE_DRAWER,
} from "../actions/actionTypes";

export function closeModal() {
  console.log('vv')
  return {
    type: CLOSE_MODAL,
  };
}

export function openModal(modalType, payload) {
  return {
    type: OPEN_MODAL,
    modalType,
    payload,
  };
}

export const changePageTheme = (payload) => {
  return {
    type: CHANGE_PAGE_THEME,
    payload,
  };
};

export const updateEditingPanel = (height, type) => {
  return {
    type: UPDATE_EDITING_PANEL,
    height,
    value: type,
  };
};

export const resetEditingPanel = () => {
  return {
    type: RESET_EDITING_PANEL,
  };
};

export function toggleEditPanel(panelType, panelStatus) {
  return {
    type: SHOW_EDIT_PANEL,
    panelType,
    panelStatus,
  };
}

export function toggleTooltipPopper(value) {
  return {
    type: TOGGLE_TOOLTIP_POPPER,
    value,
  };
}

export function setCanvasPanelWidth(value) {
  return {
    type: SET_CANVAS_PANEL_WIDTH,
    value,
  };
}

export function openDrawer({type, height}) {
  return {
    type: OPEN_DRAWER,
    drawerType: type,
    height,
  };
}
export function closeDrawer(drawerType) {
  return {
    type: CLOSE_DRAWER,
  };
}
