
import styled from "styled-components";
import {Link} from "react-router-dom";
import { grey4, red4 } from "../../../../../oat-color-variables";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  align-items: center;
  min-height: 300px;
  max-height: ${({height}) => height - 85}px;
`;

export const ImageSection = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
  @media only screen and (min-width: 601px) {
    height: 420px;
  }
  @media only screen and (max-width: 949px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 14px;
  }
`;

export const WrapImages = styled.div`
  flex: 1.5;
  // max-height: ${({panelHeight}) => panelHeight + 15}px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
  }
`;

export const ImageList = styled.div`
  columns: 4;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  box-sizing: border-box;
  @media only screen and (max-width: 900px) {
    columns: 3;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 650px) {
    columns: 2;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
  &:last-child {
    margin-bottom: 100px;
  }
`;

export const Item = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  height: 250px;
`;

export const Image = styled.img`
  max-width: 100%;
  height: 100%;
  box-shadow: 0px 0px 1px 1px #dae5f7;
`;

export const BottomPanel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: #e6ddff87;
  padding: 12px;
  box-sizing: border-box;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  justify-content: flex-end;
  @media only screen and (max-width: 600px) {
    position: fixed;
    bottom: 0;
    padding: 6px;
  }
`;

export const BtmChildSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media only screen and (max-width: 600px) {
    margin: 8px 0;
  }
`;

export const Avatar = styled.img`
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

export const MadeBy = styled(Link)`
  font-size: 0.9rem;
  padding-left: 16px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  span {
    color: #4f22ff;
    font-size: 1rem;
  }
`;

export const WrapSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 300px;
`;

export const UseTemplate = styled.div`
  font-size: 0.92rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  background: #6c69ff;
  padding: 8px 38px;
  width: 100%;
  min-width: 70px;
  height: 20px;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (min-width: 768px) {
    margin-right: 14px;
    width: auto;
    font-size: 1rem;
  }
`;

export const Creator = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 18px 0;
`;

export const Error = styled.div`
  font-size: 0.9rem;
  color: ${red4};
  padding-right: 18px;
`;

export const BackButton = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 12px;
  fill: ${grey4};
`;

export const PricingType = styled.div`
  background: #2cb72c;
  color: #dedede;
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 20px;
  padding: 2px 16px;
  border-radius: 32px;
`;