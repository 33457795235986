import styled, {css} from "styled-components";
import {red1} from "../../../../../../../oat-color-variables";
import {Bouncy} from "../../../../../../../oat-ui/animation/bouncy";

const commentPanelPadding = "12px";

export const CommentList = styled.div`
  font-size: 0.95rem;
  box-sizing: border-box;
  padding: 12px;
  margin-bottom: 200px;
`;

export const WrapItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const UsernameAndTime = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
`;

export const Username = styled.div`
  font-weight: 700;
  margin-right: 12px;
  max-width: 140px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.85rem;
`;

export const Time = styled.div`
  font-size: 0.77rem;
  color: ${({theme}) => theme.deskSecondaryTxt2};
`;

export const Comment = styled.div`
  span {
    color: #7974ff;
  }
`;

export const Reply = styled.div`
  display: flex;
  visibility: ${({hide}) => (hide ? "hidden" : "visible")};
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: ${({theme}) => theme.deskTertiaryTxt};
  margin-top: 8px;
  div {
    cursor: pointer;
  }
`;

export const WrapTrashSvg = styled.div`
  width: 14px;
  height: 14px;
  fill: ${red1};
  margin-right: 15px;
  visibility: hidden;
`;

export const MoreOptions = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px;
  cursor: pointer;
  div {
    width: 3px;
    height: 3px;
    background: #fff;
    border-radius: 50%;
    margin-bottom: 3px;
    box-shadow: 1px 1px 1px #232323;
  }
`;

export const WrapComment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 10px 12px;
  margin-bottom: 14px;
  background: ${({theme, actionRequire, actionResolved, highlightComment}) =>
    highlightComment
      ? theme.deskSecondaryBg
      : actionRequire && actionResolved
      ? "#a3f48e80"
      : actionRequire && !actionResolved
      ? "#feaaaa36"
      : undefined};
  border-radius: 6px;
  user-select: text;
`;

export const ResponseLength = styled.div`
  font-size: 0.82rem;
  color: ${({theme}) => theme.deskSecondaryTxt2};
`;

export const Line = styled.div`
  margin: 12px 0;
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.deskGap};
`;

const textAreaStyles = css`
  background: transparent;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.deskGap};
  padding: ${commentPanelPadding};
  box-sizing: border-box;
  font-family: inherit;
  color: #fff;
  resize: none;
`;

export const WrapCommentArea = styled.div`
  position: relative;
  padding: ${commentPanelPadding};
  box-sizing: border-box;
  width: 100%;
  color: ${({theme}) => theme.deskSecondaryTxt2};
  font-family: inherit;
  @media only screen and (min-width: 850px) {
    background: ${({theme}) => theme.deskPrimaryBg2};
  }
`;

export const AddComment = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 12px 0;
  .cancel-comment {
    cursor: pointer;
    color: ${({theme}) => theme.deskPrimaryTxt};
    margin-right: 20px;
  }
`;

export const WrapCommentBtn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export const WrapActions = styled.div`
  display: flex;
  align-items: center;
`;

export const CommentActionsLayout = styled.div`
  position: absolute;
  top: 20px;
  right: 0px;
  width: 120px;
  height: auto;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  border-radius: 8px;
  color: ${({theme}) => theme.deskPrimaryTxt};
  padding: 9px;
  box-sizing: border-box;
  z-index: 9;
  opacity: ${({processing}) => (processing ? 0.6 : 1)};
  ${Bouncy};
  .comment-popper-option {
    font-size: 0.85rem;
    padding: 6px;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
      background: ${({theme}) => theme.deskSecondaryBg};
    }
  }
  @media only screen and (max-width: 849px) {
    background: ${({theme}) => theme.deskSecondaryBg4};
  }
`;

export const EditComment = styled.textarea`
  ${textAreaStyles};
  height: 70px;
`;

export const EditCancel = styled.p`
  font-size: 0.85rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  margin-right: 12px;
`;

export const WrapSpinner = styled.div`
  margin-right: 18px;
  transform: scale(0.7);
`;

export const Edited = styled.div`
  font-size: 0.8rem;
  margin-left: 8px;
`;
