import React, {useState, useRef, useEffect} from "react";
import {Content, OnHoverContent} from "./../Tooltip";
import {enableTextEdit, updateTextContent} from "../../../../../../store/actions/fields/text.action";
import {connect} from "react-redux";
import PencilSvg from "../../../../../../ui/svg/PencilSvg";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {SelectedColor, TooltipLayout} from "./../reuseTooltipStyles";
import TooltipColorPickerHoc from "../tooltipHocs/TooltipColorPickerHoc";
import {toggleTooltipPopper} from "../../../../../../store/actions/layout.action";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {fontSizes} from "../../../../editingPanel/panelTypes/desktop/common/SelectFontSize";
import TooltipSelectionHoc from "../tooltipHocs/TooltipSelectionHoc";
import TooltipItemsHoc from "../tooltipHocs/TooltipItemsHoc";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import TooltipFontFamily from "../tooltipHocs/TooltipFontFamily";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
} from "../../../../../../ui/svg/TextAligns";
import {Bold, Italic} from "../../../../../../../oat-svg-icons";
import styled from "styled-components";
import {primaryGrey} from "../../../../../../../oat-color-variables";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import { handleContentChange, moveContentBefore, wrapRangeInNestedWordSpan, wrapRangeInWordSpan } from "../../../../../../utils/rangeSelector";
import { selectActiveSlideIndex } from "../../../../../../store/selectors/template/slide.selector";
import { onTextSelection } from "../../../../editingPanel/panelTypes/desktop/text/onTextSelection";

const TextTooltip = ({
  field,
  active,
  textEditing,
  enableTextEdit,
  popperOpen,
  toggleTooltipPopper,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
  tempScale,
  updateTextContent
}) => {
  const {styles} = field || {};
  const [color, setColor] = useState(styles?.color1);
  const [color2, setColor2] = useState(styles?.color2);
  const activeSlideFieldInfo = {
    slideIndex: active?.slideID,
    fieldID: active?.fieldID,
  };

  const handleTextEdit = (e) => {
    enableTextEdit();
    e.stopPropagation();
    //   dispatchCurrentUndoState();
  };

  const handleUpdates = (type, value) => {
    updateFieldStyles({
      active,
      type,
      value,
    });
  };

  const handleColorChange = async (value) => {
    // extracting new funciton for reusability 
    const updatedContent = await onTextSelection({
      content: field?.content,
      color: value, 
      subtype: field?.subtype,
    });

    const sel = window.getSelection();
    if (!sel || sel.isCollapsed) {
      await handleUpdates("color1", value);
    }
    setColor(value);
    
    await updateTextContent(activeSlideFieldInfo, updatedContent);
    await storeFieldUndoRedo();
    await updateFieldCollaboration();

    // const sel = window.getSelection();
    // if (!sel || sel.isCollapsed) {
    //   // const spanTagsInside = document.querySelectorAll(".innerTextStyle");
    //   let spanTagsInside = document.createElement('div');
    //   spanTagsInside.innerHTML = field?.content;
    //   // Remove the 'style' attribute from the span element

    //   const elementsWithClassName = spanTagsInside.querySelectorAll('.innerTextStyle');
    //   elementsWithClassName.forEach(function(element) {
    //     // Remove the 'style' attribute from each element
    //     // Get the current style attribute value
    //     let currentStyle = element.getAttribute('style');
    //     // Create a new style string without the 'color' property
    //     let newStyle = currentStyle.replace(/color\s*:\s*[^;]*;?/gi, '');
    //     // Set the updated style attribute value
    //     element.setAttribute('style', newStyle.trim());
    //   });

    //   setColor(value);
    //   await handleUpdates("color1", value);
    //   await updateTextContent(activeSlideFieldInfo, spanTagsInside.innerHTML);
    //   return;
    // } else if (!sel.isCollapsed) {
    //   if (sel.rangeCount > 0) {
    //     const range = sel.getRangeAt(0).cloneRange();
    //     if (range.collapsed) {
    //       return;
    //     }

    //     const parentElement =
    //       sel.getRangeAt(0).commonAncestorContainer.parentElement;
    //     // first pass
    //     wrapRangeInWordSpan(range, "color", value);

    //     // second pass, find the nested .innerTextStyle
    //     // and wrap the content before and after it in their own spans
    //     const inner = document.querySelector(".innerTextStyle .innerTextStyle");
    //     const classNameAlreadyExistsWithLink = document.querySelector(".innerTextStyle .innerTextLink");
    //     if (!inner) {
    //       // when selecting two .innerTextStyle start to end, where the empty spans stick around
    //       // we remove them here
    //       range.startContainer.parentNode
    //         .querySelectorAll(".innerTextStyle:empty")
    //         .forEach((node) => node.remove());

    //       const updatedContent = await handleContentChange(
    //         range,
    //         field?.subtype
    //       );

    //       await updateTextContent(activeSlideFieldInfo, updatedContent);
    //       await storeFieldUndoRedo();
    //       await updateFieldCollaboration();
    //       // window.getSelection().removeAllRanges();
    //       // window.getSelection().addRange(range);

    //       const newRange = document.createRange();
    //       // Identify the new start and end positions after color modification
    //       const newStartContainer = range.startContainer;
    //       const newEndContainer = range.endContainer;
    //       // Create a new range and set it to the desired portion
    //       newRange.setStart(newStartContainer, range.startOffset);
    //       newRange.setEnd(newEndContainer, range.endOffset);
    //       // Set the start and end points of the range to select the current selection
    //       sel.removeAllRanges();
    //       sel.addRange(newRange);
    //       setColor(value);
    //     } else {
    //       const parent = inner.closest(".innerTextStyle:not(:scope)");
    //       const extractingRange = document.createRange();
    //       // wrap the content before
    //       extractingRange.selectNode(parent);
    //       extractingRange.setEndBefore(inner);

    //       const payload = {
    //         "font-style": parentElement.style?.fontStyle, //"italic",
    //         "font-weight": parentElement.style?.fontWeight,
    //         color: parentElement.style?.color,
    //       };
    //       wrapRangeInNestedWordSpan(extractingRange, payload);

    //       // wrap the content after
    //       extractingRange.selectNode(parent);

    //       // Capture the selection offsets
    //       // Code order matters here
    //       const selectionStartOffset = extractingRange.startOffset;
    //       const selectionEndOffset = extractingRange.endOffset;

    //       extractingRange.setStartAfter(inner);
    //       wrapRangeInNestedWordSpan(extractingRange, payload);

    //       // finally, extract all the contents from parent
    //       // to its own parent and remove it, now that it's empty
    //       moveContentBefore(parent);

    //       const updatedContent = await handleContentChange(
    //         range,
    //         field?.subtype
    //       );
    //       setColor(value);
    //       await updateTextContent(activeSlideFieldInfo, updatedContent);

    //       const newRange = document.createRange();
    //       // Identify the new start and end positions after color modification
    //       const newStartContainer = range.startContainer;
    //       const newEndContainer = range.endContainer;
    //       // Create a new range and set it to the desired portion
    //       newRange.setStart(newStartContainer, selectionStartOffset);
    //       newRange.setEnd(newEndContainer, selectionEndOffset);
    //       // Set the start and end points of the range to select "World"
    //       sel.removeAllRanges();
    //       sel.addRange(newRange);
    //     }
    //   }
    // }
    // await storeFieldUndoRedo();
    // await updateFieldCollaboration();
  };

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active,
      type,
      value,
    });
  };

  const changeFontSize = ({value}) => {
    handleStyleUpdate("fontSize", Number(value / styles?.scale));
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const changeFontFamily = (item) => {
    handleStyleUpdate("fontFamily", item.fontFamily);
    storeFieldUndoRedo();
  };

  const displayColorPicker = () => {
    if (!popperOpen.status) {
      toggleTooltipPopper();
    }
    toggleTooltipPopper("color-picker");
  };

  const openFontList = (e) => {
    e.stopPropagation();
    if (!popperOpen.status || popperOpen.type === "font-size") {
      toggleTooltipPopper();
    }
    toggleTooltipPopper("font-size");
  };

  const openFontFamily = (e) => {
    e.stopPropagation();
    if (!popperOpen.status || popperOpen.type === "font-family") {
      toggleTooltipPopper();
    }
    toggleTooltipPopper("font-family");
  };

  const closePopper = () => {
    toggleTooltipPopper();
  };

  const handleDebounceValue = (value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onSelectAlign = () => {
    if (styles.textAlign === "left") {
      handleStyleUpdate("textAlign", "center");
    } else if (styles.textAlign === "center") {
      handleStyleUpdate("textAlign", "right");
    } else {
      handleStyleUpdate("textAlign", "left");
    }
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onSelectBold = () => {
    const fontWeightAsNumber = Number(styles?.fontWeight);
    const value = fontWeightAsNumber <= 500 ? "700" : "400";
    handleStyleUpdate("fontWeight", value);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onSelectItalic = () => {
    const value = styles?.fontStyle === "italic" ? "normal" : "italic";
    handleStyleUpdate("fontStyle", value);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <TooltipLayout>
      <TooltipItemsHoc>
        {!textEditing ?
          <Content onMouseDown={handleTextEdit}>
            {PencilSvg} 
            <OnHoverContent style={{width: 60, left: -20}}>
              Edit Text
            </OnHoverContent>
          </Content>
        : undefined}
   
        <Content onMouseDown={openFontList}>
          <p>{Number((styles?.fontSize * styles?.scale).toFixed(1))}</p>
          <OnHoverContent style={{width: 60, left: 15}}>
            Font Size
          </OnHoverContent>
        </Content>

        <Content onMouseDown={displayColorPicker}>
          <SelectedColor color={styles?.color1} />
        </Content>

        <Content onMouseDown={openFontFamily}>
          <p
            style={{
              maxWidth: 65,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {styles.fontFamily}
          </p>
        </Content>

        <Content onMouseDown={onSelectAlign}>
          {styles?.textAlign === "center" ? (
            <AlignCenter />
          ) : styles?.textAlign === "right" ? (
            <AlignRight />
          ) : (
            <AlignLeft />
          )}
        </Content>

        <Content onMouseDown={onSelectBold}>
          <WrapSvg>{Bold}</WrapSvg>
        </Content>

        <Content onMouseDown={onSelectItalic}>
          <WrapSvg>{Italic}</WrapSvg>
        </Content>
      </TooltipItemsHoc>

      {popperOpen.status && popperOpen.type === "font-size" ? (
        <TooltipSelectionHoc
          activeItem={styles?.fontSize}
          options={fontSizes}
          onChange={changeFontSize}
          offset={{x: textEditing ? 0 : 40, y: 45}}
          width={65}
          onClose={closePopper}
        />
      ) : undefined}

      {popperOpen.status && popperOpen.type === "font-family" ? (
        <TooltipFontFamily
          offset={{x: 40, y: 50}}
          width={160}
          height={200}
          fontFamily={styles?.fontFamily}
          fontWeight={styles?.fontFamily}
          fontStyle={styles?.fontFamily}
          onChange={changeFontFamily}
          onClose={closePopper}
        />
      ) : undefined}

      {popperOpen.status && popperOpen.type === "color-picker" ? (
        <TooltipColorPickerHoc
          onClose={closePopper}
          activeColor={color}
          onUpdate={handleColorChange}
          onDebounce={handleDebounceValue}
        />
      ) : undefined}
    </TooltipLayout>
  );
};

const WrapSvg = styled.div`
  width: 20px;
  height: 20px;
  fill: ${primaryGrey};
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    field: selectActiveFieldProps(designTemplate),
    tempScale: selectTempScale(designTemplate),
    slideIndex: selectActiveSlideIndex(designTemplate)
  };
};

export default connect(mapStateToProps, {
  enableTextEdit,
  toggleTooltipPopper,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
  updateTextContent
})(TextTooltip);
