import {CREATE_SHAPE, STRAIGHTEN_SHAPE_LINE} from "../actionTypes";

export const createShape = (slideID, payload, selectFieldOnCreate = true) => {
  return {
    type: CREATE_SHAPE,
    slideID,
    payload,
    selectFieldOnCreate,
  };
};

export const straightenShapeLine = ({lineType, payload}) => {
  return {
    type: STRAIGHTEN_SHAPE_LINE,
    lineType,
    payload,
  };
};
