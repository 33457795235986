// main thread
export function addSlideComment() {
  return function (state, action) {
    const test = state.template.map((item, index) =>
      item.id === action.slideID
        ? {
            ...item,
            comments: [...item.comments, action.payload],
          }
        : item
    );
    return {
      ...state,
      template: test
    };
  };
}

export function respondToThread() {
  return function (state, action) {
    return {
      ...state,
      template: state.template.map((item, index) =>
        item.id === action.slideID
          ? {
              ...item,
              comments: item.comments.map((comment, i) =>
                action.mainThreadID === comment.ID
                  ? {
                      ...comment,
                      subComments: [
                        ...comment.subComments,
                        {...action.payload, createdAt: Date.now()},
                      ],
                    }
                  : comment
              ),
            }
          : item
      ),
    };
  };
}

export function updateSlideComment() {
  return function (state, action) {
    if (action.payload.updatingMainThread) {
      const {slideID, comment} = action.payload;
      return {
        ...state,
        template: state.template.map((slide, index) =>
          slide.id === slideID
            ? {
                ...slide,
                comments: slide.comments.map((item, i) =>
                  item.ID === comment.ID
                    ? {
                        ...item,
                        comment: comment.comment,
                        actionRequire: {
                          status: action.actionRequire,
                          resolved: false,
                        },
                        createdAt: comment.createdAt,
                        edited: true,
                      }
                    : item
                ),
              }
            : slide
        ),
      };
    } else {
      // updating subcomment
      const {slideID, mainThreadID, subComment} = action.payload;
      return {
        ...state,
        template: state.template.map((slide, index) =>
          slide.id === slideID
            ? {
                ...slide,
                comments: slide.comments.map((item, i) =>
                  item.ID === mainThreadID
                    ? {
                        ...item,
                        subComments: item.subComments.map((subItem) =>
                          subItem.ID === subComment.ID
                            ? {
                                ...subItem,
                                ...subComment,
                              }
                            : subItem
                        ),
                      }
                    : item
                ),
              }
            : slide
        ),
      };
    }
  };
}

export function deleteSlideComment() {
  return function (state, action) {
    if (!action.deletingSubComment) {
      return {
        ...state,
        template: state.template.map((item, index) =>
          index === state.activeSlide
            ? {
                ...item,
                comments: item.comments.filter(
                  (item) => item.ID !== action.commentID
                ),
              }
            : item
        ),
      };
    } else {
      return {
        ...state,
        template: state.template.map((slide, index) =>
          index === state.activeSlide
            ? {
                ...slide,
                comments: slide.comments.map((comment) =>
                  comment.ID === action.commentID
                    ? {
                        ...comment,
                        subComments: comment.subComments.filter(
                          (item) => item.ID !== action.subCommentID
                        ),
                      }
                    : comment
                ),
              }
            : slide
        ),
      };
    }
  };
}

export function onResolvedIssue() {
  return function (state, action) {
    return {
      ...state,
      template: state.template.map((item, index) =>
        item.id === action.slideID
          ? {
              ...item,
              comments: item.comments.map((comment) =>
                comment.ID === action.commentID
                  ? {
                      ...comment,
                      actionRequire: {
                        status: true,
                        resolved: true,
                      },
                    }
                  : comment
              ),
            }
          : item
      ),
    };
  };
}
