import styled from "styled-components";

export const ThemeColor = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 7px;
  color: #3a3a3a;
  box-shadow: 0px 0px 9px 2px rgb(10 5 5 / 6%);
  background: ${({bg}) =>
    bg.gradient
      ? `linear-gradient(${bg.bgAng}deg, ${bg.bg1}, ${bg.bg2})`
      : bg.bg1};
`;

export const ThemeOptionBox = styled.div`
  position: absolute;
  bottom: 65px;
  right: 20px;
  width: 200px;
  background: ${({theme}) => theme.deskPrimaryBg};
  box-shadow: 0px 5px 20px 0px rgb(0 0 0 / 10%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
`;

export const ThemeName = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  span {
    text-overflow: ellipsis;
    width: 134px;
    overflow: hidden;
  }
`;
