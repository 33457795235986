import React from "react";
import {connect} from "react-redux";
import {
  selectActiveFieldProps,
  selectActiveFieldStyles,
} from "../../../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../../../store/selectors/template/slide.selector";
import {storeFieldUndoRedo} from "../../../../../../../../store/actions/fields/undoRedo.action";
import {Item} from "./../../MobilePanelStyles";
import {updateFieldStyles} from "../../../../../../../../store/actions/fields/common.action";
import {updateFieldCollaboration} from "../../../../../../../../store/actions/collaboration/collaboration.action";
import { Title } from "../../../../shared/reuseStyles/Title";

const LockField = ({
  slideID,
  locked,
  activeField,
  updateFieldStyles,
  updateFieldCollaboration,
}) => {
  const onSelectLock = () => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField},
      type: "lock",
      value: !locked,
    });
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <Item onClick={onSelectLock}>
      <Title>Lock</Title>
    </Item>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    locked: selectActiveFieldStyles(designTemplate).lock,
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate).key,
  };
};

export default connect(mapStateToProps, {
  storeFieldUndoRedo,
  updateFieldStyles,
  updateFieldCollaboration,
})(LockField);
