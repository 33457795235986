import React from "react";
import {WrapItems, Item} from "./folderSectionStyles";
import {deselectActiveFolder} from "../../../../../store/actions/folder.action";
import {useNavigate} from "react-router-dom";
import {selectActiveWorkSpace} from "../../../../../store/selectors/userDashboard.selector";
import {connect} from "react-redux";
import { libraryList } from "../../../../../containers/UserDashboard";

const DisplayItem = ({item, onSelect}) => {
  const handleClick = () => {
    onSelect(item);
  };
  return <Item onClick={handleClick}>{item.folderName}</Item>;
};

const LibraryLinks = ({workspaceID, deselectActiveFolder, close}) => {
  let navigate = useNavigate();

  const handleSelect = (item) => {
    deselectActiveFolder();
    if (item.ID === "library-templates") {
      navigate(`/dashboard/${workspaceID}/designs/${item.folderID}`);
    } else {
      navigate(`/dashboard/${workspaceID}/library/${item.folderID}`);
    }
    close();
  };

  return (
    <WrapItems>
      {libraryList && libraryList.map((item, i) => (
        <DisplayItem item={item} key={i} onSelect={handleSelect} />
      ))}
    </WrapItems>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceID: selectActiveWorkSpace(state.userDashboard).ID,
  };
};

export default connect(mapStateToProps, {deselectActiveFolder})(LibraryLinks);
