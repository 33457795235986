import React from "react";
import styled from "styled-components";

const UnknownRoute = () => {
  return <Layout>404</Layout>;
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 90vh;
`;

export default UnknownRoute;
