import React, {useState} from "react";
import styled from "styled-components";
import {ButtonSpinner} from "../../../../../../oat-ui";
import SpecialButton from "../../../../../ui/inputs/SpecialButton";
import {WrapButton} from "./ShareTemplate";
import {connect} from "react-redux";
import {
  selectTemplate,
  selectTemplateLength,
  selectTemplateName,
  selectTemplateRatio,
  selectTemplateSize,
  selectTempSourceID,
  selectWorkspacePlan,
} from "../../../../../store/selectors/template/template.selector";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {selectTemplateThemes} from "../../../../../store/selectors/template/theme.selector";
import {
  selectActiveSlide,
  selectSlideGroupList,
} from "../../../../../store/selectors/template/slide.selector";
import {useParams} from "react-router-dom";
import {handleImgDownload} from "./handleImgDownload";
import DropDown from "../../../../../ui/inputs/DropDown";
import {red4} from "../../../../../../oat-color-variables";

const extensionTypes = [
  {display: "Jpg", value: "jpg"},
  {display: "Png", value: "png"},
];

const DownloadAsImage = ({
  tempLength,
  tempRatio,
  tempSize,
  user,
  themes,
  slideGroupList,
  tempName,
  template,
  activeSlide,
  tempSourceID,
  close,
  workspacePlan,
}) => {
  const [processing, setProcessing] = useState(false);
  const [currentSlideOnly, setCurrentSlideOnly] = useState(false);
  const [extention, setExtention] = useState("jpg");
  const [error, setError] = useState({status: false, message: ""});
  let params = useParams();
  const {tempID} = params;

  const toggleCheckBox = () => {
    setCurrentSlideOnly(!currentSlideOnly);
  };

  const selectExtention = (value) => {
    setExtention(value);
  };

  const generatePdf = async () => {
    if (workspacePlan === "free") {
      setError({status: true, message: "Workspace needs to be upgraded."});
    } else if (!processing) {
      if (error.status) {
        setError({status: false, message: null});
      }
      setProcessing(true);
      const selectedSlide = {
        status: currentSlideOnly,
        index: activeSlide,
      };

      const tempInfo = {
        tempID,
        tempRatio,
        tempSize,
        tempName,
        tempSourceID,
        tempLength,
      };

      handleImgDownload({
        username: user.username,
        template,
        selectedSlide,
        tempInfo,
        extention,
        themes,
        slideGroupList,
        currentSlideOnly,
      })
        .then((success) => {
          if (success) {
            setProcessing(false);
            close();
          }
        })
        .catch((e) =>
          setError({
            status: true,
            message: "Something went wrong.",
          })
        );
    }
  };

  return (
    <Wrapper>
      <Item>
        <Name>Choose Format</Name>
        <Field>
          <DropDown
            onSelect={selectExtention}
            selected={extention}
            options={extensionTypes}
            boxWidth={120}
          />
        </Field>
      </Item>
      <FloatRight>
        <OnlyDownloadCurrentPage>
          <input
            type="checkbox"
            id="download-current-slide-only"
            name="download-current-slide-only"
            value={currentSlideOnly}
            onChange={toggleCheckBox}
          />
          <label htmlFor="download-current-slide-only">
            Only download current slide
          </label>
        </OnlyDownloadCurrentPage>

        <WrapButton>
          <SpecialButton
            disabled={processing}
            onClick={generatePdf}
            styles={{height: 36}}
          >
            {!processing ? "Download" : <ButtonSpinner />}
          </SpecialButton>
        </WrapButton>

        {error.status ? <Error>{error.message}</Error> : undefined}
      </FloatRight>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 280px;
  color: ${({theme}) => theme.deskSecondaryTxt};
`;

const Item = styled.div`
  padding: 12px 0;
  display: flex;
`;

const Name = styled.div`
  flex: 1;
`;

const Field = styled.div`
  display: flex;
  flex: 1;
`;

const FloatRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 42px;
  justify-content: flex-end;
`;

const OnlyDownloadCurrentPage = styled.div`
  margin-bottom: 14px;
  label {
    user-select: none;
    margin-left: 10px;
  }
`;

export const Error = styled.div`
  color: ${red4};
  font-size: 0.9rem;
  margin-top: 16px;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    user: selectUserInfo(state.authUser),
    tempLength: selectTemplateLength(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
    tempRatio: selectTemplateRatio(designTemplate),
    themes: selectTemplateThemes(designTemplate),
    slideGroupList: selectSlideGroupList(designTemplate),
    tempName: selectTemplateName(designTemplate),
    template: selectTemplate(designTemplate),
    activeSlide: selectActiveSlide(designTemplate),
    tempSourceID: selectTempSourceID(designTemplate),
    workspacePlan: selectWorkspacePlan(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(DownloadAsImage);
