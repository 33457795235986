import React from 'react';
import { FadeIn, NoAnimation, ToBottom, ToLeft, ToRight, ToTop, ZoomIn, ZoomOut } from '../../../../../../ui/svg/animation/AnimationIcons';

const DisplayAnimationIcons = ({type}) => {

    let displayIcon;
    switch (type) {
        case "no-animate":
            displayIcon = <NoAnimation />
            break;
        case "bottom-to-top":
            displayIcon = <ToTop />
            break;
        case "top-to-bottom":
            displayIcon = <ToBottom />
            break;
        case "left-to-right":
            displayIcon = <ToRight />
            break;
        case "right-to-left":
            displayIcon = <ToLeft />
            break;
        case "zoom-in":
            displayIcon = <ZoomIn />
            break;
        case "zoom-out":
            displayIcon = <ZoomOut />
            break;
        case "fade-in":
            displayIcon = <FadeIn />
            break;
        default: 
            displayIcon = ""

    }

    return displayIcon;
}

export default DisplayAnimationIcons;