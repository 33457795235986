import React from "react";
import {connect, batch} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import {WrapColorType} from "../common/WrapColorStyles";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectThemeProps} from "../../../../../../store/selectors/template/theme.selector";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {ChooseShadowType, ShadowType, ShadowBox, WrapShadowTypes} from "../common/styles/shadowStyles";

export const shadowTypeOptions = [
  {
    name: "None",
    shadowType: "none",
    value: "",
    size: 0,
    color: "transparent",
    blur: 0,
  },
  {
    name: "Dim",
    shadowType: "dim",
    value: "rgb(132 132 133) -2px -2px 5px, rgb(132 132 133) 2px 2px 5px",
    // "0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)",
    size: 16,
    color: "#8a8a93ff",
    blur: 15,
  },
  {
    name: "Classic",
    shadowType: "classic",
    value: "#5c5353 3px 3px 6px",
    size: 16,
    color: "#8e8e8eff",
    blur: 7,
  },
  {
    name: "Hard",
    shadowType: "hard",
    value: "#777792 4px 4px 0px",
    size: 25,
    color: "#777792",
    blur: 0,
  }
];

const DisplayItem = ({item, active, onSelect}) => {
  const handleClick = () => {
    onSelect(item);
  };
  return (
    <ChooseShadowType active={active} onClick={handleClick}>
      <ShadowType active={active}>
        <ShadowBox style={{boxShadow: item?.value}} />
      </ShadowType>
      <p>{item?.name}</p>
    </ChooseShadowType>
  );
};

const ShapeShadow = ({
  slideID,
  fieldID,
  styles,
  storeFieldUndoRedo,
  updateFieldStyles,
  activeThemeProps,
  updateFieldCollaboration,
  fileType
}) => {
  const {enabledShadow, shadowColor, shadowType} = styles || {};
  const {image} = activeThemeProps || {};

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const handleEmitValue = (type, value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const toggleShadowStatus = () => {
    handleEmitValue("enabledShadow", styles?.enabledShadow);
    if (styles?.enabledShadow) {
      handleStyleUpdate("enabledShadow", false);
      handleEmitValue("enabledShadow", false);
    } else {
      handleStyleUpdate("enabledShadow", true);
      handleEmitValue("enabledShadow", true);
      if (styles?.shadowType === undefined) {
        handleStyleUpdate("shadowType", 'dim');
      }
      if (styles?.shadowColor === undefined) {
        handleStyleUpdate("shadowColor", '#6a6565ff');
      }
    }
  };

  const onSelectShadow = async (item) => {
    const updateShadow =
      shadowColor === image?.shadowColor ? image?.shadowColor : shadowColor;
    await handleStyleUpdate("shadowType", item?.shadowType);
    await handleStyleUpdate("shadowSize", item?.size);
    await handleStyleUpdate("shadowBlur", item?.blur);
    await handleStyleUpdate("shadowColor", updateShadow);

    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  const updateShadowSize = (value) => {
    handleStyleUpdate("shadowSize", Number(value));
  };

  const updateShadowBlur = (value) => {
    handleStyleUpdate("shadowBlur", Number(value));
  };

  const updateShadowColor = (value) => {
    handleStyleUpdate("shadowColor", value);
  };

  const onColorDebounce = (value) => {
    handleEmitValue("shadowColor", value);
  };

  return (
    <>
      <div style={{paddingBottom: enabledShadow ? '1em' : 0}}>
        <Switch
          title="Add Shadow"
          value={enabledShadow}
          onItemChange={toggleShadowStatus}
          checked={enabledShadow}
        />
      </div>
      {enabledShadow ? (
        <div style={{paddingBottom: window.innerWidth < 850 ? 80 : undefined}}> 
          <div>
            <WrapShadowTypes>
            {shadowTypeOptions.map((item, i) => (
                <DisplayItem key={i} active={item?.shadowType === shadowType} item={item} onSelect={onSelectShadow} />
            ))}
            </WrapShadowTypes>
          </div> 

          <WrapColorType style={{paddingBottom: 0}}>
            <Title>Shadow Color</Title>
            <PopoverColorBox
              value={shadowColor}
              updateFieldStyles={updateShadowColor}
              onDebounce={onColorDebounce}
            />
          </WrapColorType>
        </div>
      ) : undefined}
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    activeThemeProps: selectThemeProps(state.designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateFieldStyles,
    storeFieldUndoRedo,
    updateFieldCollaboration,
  }))
)(ShapeShadow);
