import React from "react";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {HomeHeader} from "./FeaturesShowcase2";
import {WrapHeader} from "./FeatureList";
import LibraryFeature from "./LibraryFeature";
import {Layout} from "./StickersIconsSection";
import { homePageBackground2 } from "./styles/layoutStyles";

const FeatureBySection = () => {
  return (
    <Layout style={{background: homePageBackground2}}>
      {/* <AnimateIn>
        <HomeHeader>Folders & Workspaces</HomeHeader>
      </AnimateIn> */}

      <WrapHeader style={{marginBottom: window.innerWidth <= 600 ? 12 : undefined }}>
        <AnimateIn>
          <HomeHeader>Inside a Workspace</HomeHeader>
        </AnimateIn>
      </WrapHeader>

      <LibraryFeature />
    </Layout>
  );
};

export default FeatureBySection;
