import {
  FETCH_MEDIA_INIT,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_ERROR,
  UPLOAD_MEDIA_INIT,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  TOGGLE_MEDIA_MODAL,
  REMOVE_IMAGE_ITEM,
} from "../actions/actionTypes";
import {
  fetchMediaError,
  fetchMediaInit,
  fetchMediaSuccess,
  removeImageItem,
  toggleAdminMediaModal,
  uploadMediaError,
  uploadMediaInit,
  uploadMediaSuccess,
} from "./functions/handleMedia";

const initialState = {
  items: [],
  paginationToken: null,
  loading: false,
  modal: {
    status: false,
    type: null,
    message: null,
    processing: false,
  },
  error: {
    status: false,
    message: null,
  },
};

const handlers = {};

// fetch
handlers[FETCH_MEDIA_INIT] = fetchMediaInit();
handlers[FETCH_MEDIA_SUCCESS] = fetchMediaSuccess();
handlers[FETCH_MEDIA_ERROR] = fetchMediaError();

// remove item
handlers[REMOVE_IMAGE_ITEM] = removeImageItem();

// upload
handlers[UPLOAD_MEDIA_INIT] = uploadMediaInit();
handlers[UPLOAD_MEDIA_SUCCESS] = uploadMediaSuccess();
handlers[UPLOAD_MEDIA_ERROR] = uploadMediaError();

handlers[TOGGLE_MEDIA_MODAL] = toggleAdminMediaModal();

function reducerFactory(initialState, handlers) {
  return function (state = initialState, action) {
    const handler = handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };
}

export default reducerFactory(initialState, handlers);
