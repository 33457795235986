import styled from "styled-components";

export const Dot = styled.div`
  border-radius: 50%;
  background: #5090f6cf;
  width: 10px;
  height: 10px;
  visibility: hidden;
`;

export const Option = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  color: ${({theme}) => theme.deskSecondaryTxt};
  font-size: 0.85rem;
  cursor: pointer;
  margin: 8px 10px;
  padding: 0px 6px;
  border-radius: 6px;
  box-sizing: border-box;
  user-select: none;
  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg};
  }
  &:hover ${Dot} {
    visibility: visible;
  }
`;
