import React, {useState, useRef} from "react";
import styled from "styled-components";
import Button from "../../../../oat-ui/core/Button";
import {ButtonSpinner, detectClickOutside} from "../../../../oat-ui";
import {
  Header,
  TextField,
  Footer,
  ContentSection,
  BottomSection,
} from "../../reuse/dashboardUI/modalStyles";
import {selectUserInfo} from "../../../../../redux/user/authUser";
import {
  selectActiveWorkSpace,
  selectWorkspacePlan,
} from "../../../store/selectors/userDashboard.selector";
import {useNavigate} from "react-router-dom";
import {createNewFolder} from "../../../store/actions/folder.action";
import {connect} from "react-redux";
import {Cancel, WrapButton, Error} from "../folderTypes/folderStyles";
import { UpgradeToPro } from "../folderTypes/FolderActions";

const CreateImageFolder = ({
  user,
  workspaceID,
  width,
  close,
  createNewFolder,
  workspacePlan,
}) => {
  const [newFolderInfo, setNewFolderInfo] = useState({
    name: "New Folder",
    processing: false,
    error: false, 
    errorMessage: null
  });
  const {name, processing, error, errorMessage} = newFolderInfo;
  let navigate = useNavigate();
  const popUpRef = useRef(null);

  detectClickOutside(popUpRef, () => close());

  const onChangeName = (e) => {
    const {value} = e.target;
    setNewFolderInfo({
      ...newFolderInfo,
      name: value,
    });
  };

  const handleCreate = async () => {
    if (newFolderInfo.name === "") {
      setNewFolderInfo({
        ...newFolderInfo,
        error: true,
        errorMessage: "Please fill folder name.",
        processing: false,
      });
    } else if (workspaceID) {
      if (newFolderInfo.name !== "" && !newFolderInfo.processing) {
        let newFolder = {
          folderName: newFolderInfo.name,
          folderType: 'library-images',
        };
        
        setNewFolderInfo({
          ...newFolderInfo,
          errorMessage: null,
          error: false,
          processing: true,
        });

        const requestOptions = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            workspaceID,
            ...newFolder,
            isSubfolder: null 
          }),
        };

        await fetch(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/folders`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (data) => {
            if (data.status === "success") {
              const payload = {...newFolder, folderID: data.folderID};
              await createNewFolder(payload);
              await close();
              return await navigate(
                `/dashboard/${workspaceID}/library/${data.folderID}`
              );
            } else {
              setNewFolderInfo({
                ...newFolderInfo,
                error: true,
                errorMessage: data.message,
                processing: false,
              });
            }
          })
          .catch((e) => {
            setNewFolderInfo({
              ...newFolderInfo,
              error: true,
              errorMessage: "Something went wrong.",
              processing: false,
            });
          });
      }
    }
  };

  const onUpgradeToPro = () => {
    navigate(`?upgrade=true`, { replace: true });
    close();
  }

  return (
    <Layout style={{width}} ref={popUpRef}>
      <ContentSection>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Header>Create Folder</Header>
          <div style={{marginRight: 8}}>
            {workspacePlan === "free" ? (
              <UpgradeToPro onClick={onUpgradeToPro}>Pro</UpgradeToPro>
            ) : undefined}
          </div>
        </div>
        
        <TextField
          value={name}
          placeholder="Folder Name"
          onChange={onChangeName}
          autoFocus
        />

        {error ? <Error>{errorMessage}</Error> : undefined}
      </ContentSection>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Cancel onClick={close}>Cancel</Cancel>
          <WrapButton>
            <Button
              styles={{width: 82}}
              onClick={handleCreate}
              disabled={processing}
            >
              {!processing ? "Create" : <ButtonSpinner />}
            </Button>
          </WrapButton>
        </BottomSection>
      </Footer>
    </Layout>
  );
};

const Layout = styled.div`
  height: auto;
  background: #fff;
  border-radius: 8px;
  z-index: 9999;
  box-shadow: 0px 0px 5px 0px #cbc3de;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    workspaceID: selectActiveWorkSpace(state.userDashboard).ID,
    workspacePlan: selectWorkspacePlan(state.userDashboard),
  };
};

CreateImageFolder.defaultProps = {
  width: 338,
};

export default connect(mapStateToProps, {createNewFolder})(CreateImageFolder);
