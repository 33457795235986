import React from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import UserTemplates from "../components/dashboardPanel/userTemplates/UserTemplates";
import UserDashboardHoc from "../hoc/UserDashboardHoc";
import UserSidebar from "../components/sidebar/UserSidebar";
import UserFolderHoc from "../hoc/UserFolderHoc";
import DashboardPanel from "../components/dashboardPanel/DashboardPanel";
import {useParams} from "react-router-dom";
import ImagesLibrary from "../components/dashboardPanel/userLibrary/images/ImagesLibrary";
import FontsLibrary from "../components/dashboardPanel/userLibrary/fonts/FontsLibrary";

export const libraryList = [
  {folderID: "images", folderName: "Images"},
  {folderID: "fonts", folderName: "Fonts"},
  {folderID: "library-templates", folderName: "Templates"},
];

const UserDashboard = () => {
  let params = useParams();
  const {section, folderID} = params;

  let displayView = null;
  if (section === "library") {
    if (folderID=== libraryList[0].folderID) {
      displayView = <ImagesLibrary />;
    } else if (folderID === libraryList[1].folderID) {
      displayView = <FontsLibrary />;
    } else if (folderID === libraryList[2].folderID) {
      // for lib reusable templates
      displayView = <UserTemplates />;
    } else { // for lib subfolders
      displayView = <ImagesLibrary />;
    } 
  } else {
    displayView = <UserTemplates />;
  };

  return (
    <HelmetProvider>
      <Helmet>
        <body style="overflow: hidden;" />
      </Helmet>

      <UserDashboardHoc>
        <UserFolderHoc>
          <UserSidebar />
        </UserFolderHoc>
        <DashboardPanel>{displayView}</DashboardPanel>
      </UserDashboardHoc>
    </HelmetProvider>
  );
};

export default UserDashboard;
