import React, {useState, useEffect, useRef, useMemo} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {updateTableContent} from "../../../../../../store/actions/fields/table.action";
import {selectUndoRedoHistory} from "../../../../../../store/selectors/fields.selector";
import ContentEditable from "react-contenteditable";
import { selectTempScale } from "../../../../../../store/selectors/template/template.selector";

const cellPadding = 0;
// contentEditable error
// on enter cursor not droping
// uncomment to reinstitiate codes below
// If the startNode is a Node of type Text ..., then startOffset is the number of characters from the start of startNode.
// For other Node types, startOffset is the number of child nodes between the start of the startNode.
// https://javascript.info/selection-range
// contenteditable - https://codepen.io/feketegy/pen/RwGBgyq
// https://stackoverflow.com/questions/36869503/set-caret-position-in-contenteditable-div-that-has-children
// https://stackoverflow.com/questions/69956977/html-contenteditable-keep-caret-position-when-inner-html-changes
function setCaretPosition(el, pos, length) {
  // Loop through all child nodes
  for (let i = 0; i < el.childNodes.length; i++) {
    const node = el.childNodes[i];
    // this one isn't really needed
    // if (node.nodeType == 1) {
    //   for (let j = 0; j < node.childNodes.length; j++) {
    //     const cnode = node.childNodes[j];
    //     // parent.textContent.length
    //     if (cnode.textContent.length >= pos) {
    //       var range = document.createRange(),
    //         sel = window.getSelection();
    //       range.setStart(cnode, pos);
    //       range.collapse(true);
    //       sel.removeAllRanges();
    //       sel.addRange(range);
    //       // if (j !== node.childNodes.length - 1) {
    //       //   return -1; // we are done
    //       // } else if (cnode.textContent.length === "") {
    //       //   return -1;
    //       // }
    //       if (j !== el.childNodes.length - 1) {
    //         return -1;
    //       }
    //     } else if (cnode.nodeType == 1 && cnode.textContent === "") {
    //       pos -= cnode.length;
    //       range.setStart(cnode, pos);
    //       // range.collapse(true);
    //       // sel.removeAllRanges();
    //       // sel.addRange(range);
    //       return -1;
    //     } else {
    //       pos -= cnode.length;
    //     }
    //   }
    // } else
    if (node.nodeType == 3) {
      // we have a text node
      if (node.length >= pos) {
        // finally add our range
        let range = document.createRange(),
          sel = window.getSelection();
        range.setStart(node, pos);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        // if (length === el.childNodes.length - 1) {
        //   return -1; // we are done
        // }
        return -1;
      } else {
        pos -= node.length;
      }
    } else {
      if (node.nodeType == 1) {
        for (let j = 0; j < node.childNodes.length; j++) {
          const cnode = node.childNodes[j];
          // if (cnode.textContent === "") {
          //   range.setStart(cnode, 0);
          // } else {
          //   pos = setCaretPosition(node, pos, j);
          // }
          pos = setCaretPosition(node, pos, j);
        }
      }
      // pos = setCaretPosition(node, pos, );
      if (pos == -1) {
        return -1; // no need to finish the for loop
      }
    }
  }

  return pos; // needed because of recursion stuff
}

function getCaret(el) {
  let caretAt = 0;
  const sel = window.getSelection();

  if (sel.rangeCount == 0) {
    return caretAt;
  }

  const range = sel.getRangeAt(0);
  const preRange = range.cloneRange();
  preRange.selectNodeContents(el);
  preRange.setEnd(range.endContainer, range.endOffset);
  caretAt = preRange.toString().length;

  return caretAt;
}

const hoverColorRed = "#ecaaaa";
const ColumnCell = ({
  rowIndex,
  colIndex,
  column,
  styles,
  hoverToDeleteRow,
  hoverToDeleteCol,
  fieldSelected,
  enableEditing,
  emitTimesClicked,
  onSelectCell,
  onUpdateContent,
  selectedCell,
  lastColumn,
  tempScale
}) => {
  const editTextRef = useRef();
  const caretPos = useRef();
  const [typing, setTyping] = useState(false);
  const {
    headerType,
    headerBg,
    headerColor,
    headerFontWeight,
    borderColor,
    borderWidth,
    textAlign,
    fontWeight,
    letterSpacing,
    lineHeight,
    color,
    background,
    showColumnColor,
  } = styles;

  const handleActiveCell = (e) => {
    if (fieldSelected) {
      onSelectCell(rowIndex, colIndex);
      if (!enableEditing) {
        emitTimesClicked();
      }
    }
  };

  const setCursorAtTheEnd = () => {
    let selection, range;
    if (editTextRef && editTextRef.current && document.createRange) {
      range = document.createRange(); //Create a range (like a selection but invisible)
      range.selectNodeContents(editTextRef.current);
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection(); //get the selection object (allows you to change selection)
      selection.removeAllRanges();
      selection.addRange(range);

      // if (typeof window.navigator.userAgent.indexOf("Firefox") != -1) {
      //   // Create a text node with a space
      //   var spaceNode = document.createTextNode(" ");
      //   // Insert the text node at the cursor position
      //   range.insertNode(spaceNode);
      //   // Move the range to the end of the text node
      //   range.setStartAfter(spaceNode);
      //   range.collapse(true);
      //   // Update the selection
      //   selection.removeAllRanges();
      //   selection.addRange(range);
      // }
    } else if (editTextRef && editTextRef.current && document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText(editTextRef.current);
      range.collapse(false);
      range.select();
    }
  };

  useEffect(() => {
    if (selectedCell) setCursorAtTheEnd();
  }, [selectedCell]);
  useEffect(() => {
    if (selectedCell && enableEditing) {
      setTimeout(() => {
        setCursorAtTheEnd();
      }, 150); // 150 necessary
    }
  }, [enableEditing, selectedCell]); //

  // hide error checking on typing
  useEffect(() => {
    if (typing) {
      setTimeout(() => {
        setTyping(false);
      }, 2000);
    }
  }, [typing]);

  const onEditContent = async (e) => {
    // having error on desktop both
    // e.preventDefault();
    // e.stopPropagation();

    // caretPos.current = getCaret(editTextRef.current);
    const scrollHeight = editTextRef.current.scrollHeight;
    setTyping(true);
    await onUpdateContent({
      content: e.target.value, // e.target.innerHTML,
      columnIndex: colIndex,
      scrollHeight,
    });
  };

  const tableStyles = useMemo(() => {
    let styles = {
      color,
      background,
      fontWeight,
    };
    if (headerType === "top" && rowIndex === 0) {
      styles = {
        color: headerColor,
        background: hoverToDeleteCol === colIndex ? hoverColorRed : headerBg,
        fontWeight: headerFontWeight,
      };
    } else if (headerType === "left" && colIndex === 0) {
      styles = {
        color: headerColor,
        background: hoverToDeleteCol === colIndex ? hoverColorRed : headerBg,
        fontWeight: headerFontWeight,
      };
    } else if (
      headerType === "top-left" &&
      (rowIndex === 0 || colIndex === 0)
    ) {
      styles = {
        color: headerColor,
        background: hoverToDeleteCol === colIndex ? hoverColorRed : headerBg,
        fontWeight: headerFontWeight,
      };
    }
    return styles;
  }, [
    headerType,
    headerColor,
    headerBg,
    headerFontWeight,
    colIndex,
    rowIndex,
    color,
    background,
    fontWeight,
    hoverToDeleteCol,
  ]);

  // useEffect(() => {
  //   if (
  //     editTextRef &&
  //     editTextRef.current &&
  //     caretPos.current &&
  //     selectedCell
  //   ) {
  //     // setCaretPosition(editTextRef.current, caretPos.current);
  //     // editTextRef.current.focus();
  //   }
  // }, [column.value, selectedCell]);

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedHTMLContent = clipboardData.getData("text/html");
    const pastedPlainTextContent = clipboardData.getData("text");

    if (pastedHTMLContent) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = pastedHTMLContent;

      const filteredSpans = Array.from(tempDiv.querySelectorAll("span")).filter(
        (span) => span.classList.contains("inside-text")
      );

      const plainText = tempDiv.textContent || tempDiv.innerText; // Extract plain text without HTML tags

      const filteredContent = document.createElement("div");
      filteredSpans.forEach((span) => {
        filteredContent.appendChild(span);
      });

      filteredContent.appendChild(document.createTextNode(plainText));
      document.execCommand("insertHTML", false, filteredContent.innerHTML);
    } else if (pastedPlainTextContent) {
      document.execCommand("insertHTML", false, pastedPlainTextContent);
    }
  };

  return (
    <Column
      key={column.id + "-" + colIndex}
      onMouseUp={handleActiveCell}
      onTouchEnd={handleActiveCell}
      $tempScale={tempScale}
      style={{
        width: lastColumn
          ? column.width + 2 //+ (colIndex + 1)
          : column.width,
        lineHeight,
        letterSpacing,
        textAlign,
        color: tableStyles.color,
        background: hoverToDeleteRow
          ? hoverColorRed
          : hoverToDeleteCol === colIndex
          ? hoverColorRed
          : tableStyles.background,
        fontWeight: tableStyles.fontWeight,
        borderRight: !lastColumn
          ? `${borderWidth}px solid ${
              showColumnColor ? borderColor : "transparent"
            }`
          : undefined,
      }}
    >
      {editTextRef && selectedCell ? (
        // <ColumnContent
        //   ref={editTextRef}
        //   contentEditable={true}
        //   dangerouslySetInnerHTML={{
        //     __html: column.value,
        //   }}
        //   suppressContentEditableWarning={true}
        //   spellCheck={typing ? false : true}
        //   onInput={onEditContent}
        // />
        <ContentEditable
          innerRef={editTextRef} // updating needs to be also reflected in below code
          html={column.value} // innerHTML of the editable div
          disabled={false} // use true to disable editing
          onChange={onEditContent} // handle innerHTML change
          onPaste={handlePaste}
          style={{width: '100%'}} // changes here should reflect in ColumnContent
        />
      ) : (
        <ColumnContent
          ref={editTextRef}
          contentEditable={false}
          dangerouslySetInnerHTML={{
            __html: column.value,
          }}
          suppressContentEditableWarning={true}
          spellCheck={false}
        />
      )}
    </Column>
  );
};

const Column = styled.td`
  text-align: left;
  box-sizing: border-box;
  padding: ${({$tempScale}) => `${5 / $tempScale}`}px;
  display: flex;
  align-items: flex-start;
  &:last-child {
    border-right: 0px;
  }
`;

const ColumnContent = styled.div`
  width: 100%; // changes here should reflect in ContentEditable
  padding: ${cellPadding}px;
  box-sizing: border-box;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    undoState: selectUndoRedoHistory(designTemplate),
    tempScale: selectTempScale(designTemplate)
  };
};

export default connect(mapStateToProps, {
  updateTableContent
})(ColumnCell);
