import React from "react";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import TemplateList from "../components/TemplateList";

const OatTemplates = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <body />
      </Helmet>

      <Layout>
        <TemplateList />
      </Layout>
    </HelmetProvider>
  );
};

const Layout = styled.div``;

export default OatTemplates;
