import {
  SWITCH_FOLDER,
  // CHANGE_FOLDER_INIT,
  // CHANGE_FOLDER_SUCCESS,
  // CHANGE_FOLDER_ERR,
  RENAME_TEMPLATE,
  CREATE_NEW_FOLDER,
  FETCH_WORKSPACES_INIT,
  FETCH_WORKSPACES_SUCCESS,
  FETCH_WORKSPACES_ERR,
  GET_DASHBOARD_INIT,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  ON_REMOVE_TEMPLATE_SUCCESS,
  CHANGE_FOLDER_NAME,
  DELETE_FOLDER,
  SET_PROFILE_TAGS,
  ADD_PROFILE_TAG,
  REMOVE_PROFILE_TAG,
  EDIT_PROFILE_TAG,
  CREATE_WORKSPACE_INIT,
  CREATE_WORKSPACE_SUCCESS,
  CREATE_WORKSPACE_ERR,
  DELETE_WORKSPACE_INIT,
  DELETE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE_ERR,
  RENAME_WORKSPACE_INIT,
  RENAME_WORKSPACE_SUCCESS,
  RENAME_WORKSPACE_ERR,
  SELECT_WORKSPACE_ITEM_ACTIONS,
  RESET_WORKSPACE_SELECTED_ACTIONS,
  SWITCH_WORKSPACE_INIT,
  SWITCH_WORKSPACE_SUCCESS,
  SWITCH_WORKSPACE_ERROR,
  OPEN_DASHBOARD_MODAL,
  CLOSE_DASHBOARD_MODAL,
  LOAD_FOLDERS_AND_WSINFO,
  CHANGE_FOLDER_INIT,
  CHANGE_FOLDER_SUCCESS,
  CHANGE_FOLDER_ERR,
  DESELECT_ACTIVE_FOLDER,
  UPDATE_FOLDER_PRIVACY,
} from "../actions/actionTypes";
import {
  closeDashboardModal,
  getDashboardError,
  getDashboardInit,
  getDashboardSuccess,
  openDashboardModal,
  setFoldersWorkspaceInfo,
} from "./functions/dashboard";
import {
  // changeFolderErr,
  // changeFolderInit,
  changeFolderName,
  changeFolderSuccess,
  createNewFolder,
  deleteFolder,
  changeFolderInit,
  changeFolderErr,
  deselectActiveFolder,
  updateFolderPrivacy,
} from "./functions/folder";
import {
  addProfileTag,
  editProfileTag,
  removeProfileTag,
  setProfileTags,
} from "./functions/profile";
import {
  onRemoveTemplateSuccess,
  renameTemplateTitle,
} from "./functions/template";
import {
  fetchWorkSpacesInit,
  fetchWorkSpacesSuccess,
  fetchWorkSpacesError,
  createWorkspaceSuccess,
  createWorkspaceError,
  createWorkspaceInit,
  deleteWorkspaceInit,
  deleteWorkspaceError,
  deleteWorkspaceSuccess,
  renameWorkspaceError,
  renameWorkspaceSuccess,
  renameWorkspaceInit,
  selectWorkspaceItemActions,
  resetWSSelectedActions,
  switchWorkspaceInit,
  switchWorkspaceSuccess,
  switchWorkspaceError,
  switchWorkspaceType,
} from "./functions/workspace";

const initialState = {
  activeFolder: {
    ID: null,
    folderType: null,
    folderName: null,
  },
  activeSection: "templates",
  folders: {
    selectedFolderType: "",
    designs: [],
    notes: [],
    forms: [],
    ['library-images']: []
  },
  templates: [],
  paginationToken: null,
  profile: {
    tags: [],
  },
  modal: {
    name: "",
    type: "", // workspace, folder, template etc
    status: false,
    error: null,
  },
  drawer: {
    name: "",
    status: false,
  },
  fetchData: {
    loading: false,
    error: false,
  },
  workspace: {
    active: {
      ID: null,
      name: null,
      role: null,
      owner: null,
    },
    items: [],
    selectedWSActions: {
      ID: null,
      name: null,
    }, // wsID for rename, delete
    loading: false, // for create, rename, delete
    onSwitchWorkspace: {
      // when workspace has been switched
      loading: false,
      error: false,
    },
    error: false,
    errorMessage: null,
    // error: {
    //   status: false,
    //   message: null,
    // },
  },
};

export const getUserDashboardModal = (state) => state.modal;

const handlers = {};
// folder
// handlers[SWITCH_FOLDER] = switchFolder();
handlers[CHANGE_FOLDER_INIT] = changeFolderInit();
handlers[CHANGE_FOLDER_SUCCESS] = changeFolderSuccess();
handlers[CHANGE_FOLDER_ERR] = changeFolderErr();
handlers[CREATE_NEW_FOLDER] = createNewFolder();
handlers[DELETE_FOLDER] = deleteFolder();
handlers[DESELECT_ACTIVE_FOLDER] = deselectActiveFolder();
handlers[UPDATE_FOLDER_PRIVACY] = updateFolderPrivacy();

// template
handlers[RENAME_TEMPLATE] = renameTemplateTitle();
handlers[CHANGE_FOLDER_NAME] = changeFolderName();
handlers[ON_REMOVE_TEMPLATE_SUCCESS] = onRemoveTemplateSuccess();

// dashboard
handlers[GET_DASHBOARD_INIT] = getDashboardInit();
handlers[GET_DASHBOARD_SUCCESS] = getDashboardSuccess();
handlers[GET_DASHBOARD_ERROR] = getDashboardError();
handlers[OPEN_DASHBOARD_MODAL] = openDashboardModal();
handlers[CLOSE_DASHBOARD_MODAL] = closeDashboardModal();
handlers[LOAD_FOLDERS_AND_WSINFO] = setFoldersWorkspaceInfo();

// profile
handlers[SET_PROFILE_TAGS] = setProfileTags();
handlers[ADD_PROFILE_TAG] = addProfileTag();
handlers[EDIT_PROFILE_TAG] = editProfileTag();
handlers[REMOVE_PROFILE_TAG] = removeProfileTag();

// workspace
handlers[FETCH_WORKSPACES_INIT] = fetchWorkSpacesInit();
handlers[FETCH_WORKSPACES_SUCCESS] = fetchWorkSpacesSuccess();
handlers[FETCH_WORKSPACES_ERR] = fetchWorkSpacesError();
handlers[CREATE_WORKSPACE_INIT] = createWorkspaceInit();
handlers[CREATE_WORKSPACE_SUCCESS] = createWorkspaceSuccess();
handlers[CREATE_WORKSPACE_ERR] = createWorkspaceError();
handlers[DELETE_WORKSPACE_INIT] = deleteWorkspaceInit();
handlers[DELETE_WORKSPACE_SUCCESS] = deleteWorkspaceSuccess();
handlers[DELETE_WORKSPACE_ERR] = deleteWorkspaceError();
handlers[RENAME_WORKSPACE_INIT] = renameWorkspaceInit();
handlers[RENAME_WORKSPACE_SUCCESS] = renameWorkspaceSuccess();
handlers[RENAME_WORKSPACE_ERR] = renameWorkspaceError();
handlers[SELECT_WORKSPACE_ITEM_ACTIONS] = selectWorkspaceItemActions();
handlers[RESET_WORKSPACE_SELECTED_ACTIONS] = resetWSSelectedActions();
handlers[SWITCH_WORKSPACE_INIT] = switchWorkspaceInit();
handlers[SWITCH_WORKSPACE_SUCCESS] = switchWorkspaceSuccess();
handlers[SWITCH_WORKSPACE_ERROR] = switchWorkspaceError();

export function reducerFactory(initialState, handlers) {
  return function (state = initialState, action) {
    const handler = handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };
}

export default reducerFactory(initialState, handlers);
