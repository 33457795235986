import React, {useState, useEffect} from "react";
import {timeSince} from "../../../../../../../oat-utility";
import {
  Time,
  Username,
  WrapComment,
  WrapItem,
  Comment,
  Reply,
  WrapCommentBtn,
  WrapActions,
  EditCancel,
  WrapSpinner,
  UsernameAndTime,
  Edited,
} from "./commentStyles";
import {Spinner} from "../../../../../../../oat-ui";
import CommentActions from "./CommentActions";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import {connect} from "react-redux";
import {selectActiveSlideInfo} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectTemplateID,
  selectTempSourceID,
} from "../../../../../../store/selectors/template/template.selector";
import {updateSlideComment} from "../../../../../../store/actions/template/comment.action";
import RespondingToComment from "./RespondingToComment";
import {
  Mention,
  MentionsInput,
} from "react-mentions/dist/react-mentions.cjs.prod";
import {darkTheme, lightTheme} from "./inputStyles";
import {useSocket} from "../../../../../../webSocket/useSocket";

const DisplaySubComment = ({
  item,
  onDelete,
  user,
  updateTimeDiff,
  deleting,
  slideID,
  tempSourceID,
  tempID,
  commentThread,
  hideReplySection,
  updateSlideComment,
  editing,
  onEditStatus,
  highLightComment,
  theme,
  searchList,
}) => {
  const [commentInput, setCommentInput] = useState(item.comment);
  const [timeDiff, setTimeDiff] = useState();
  const [processing, setProcessing] = useState(false);
  const [responding, setResponding] = useState({
    status: false,
    respondingTo: {},
  });
  const {emitSocketEvents} = useSocket() || {}; 

  useEffect(() => {
    const getTimeDiff = calculateTimeSince();
    setTimeDiff(getTimeDiff);
  }, [updateTimeDiff, item.createdAt]);

  const calculateTimeSince = () => {
    const currentTimeStamp = new Date().getTime();
    // let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    // let oneHourAgo = new Date(new Date().valueOf() - 1000 * 60 * 60);
    return timeSince(currentTimeStamp, item.createdAt); //item.time
  };

  const handleCommentChange = (e) => {
    const {value} = e.target;
    setCommentInput(value);
  };

  const submitChanges = async () => {
    if (!processing && user.username === item.commentedBy.username && user.username !== null) {
      setProcessing(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/comments/${commentThread.ID}/subcomments/${item.ID}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            tempSourceID,
            slideID,
            subComment: commentInput,
            respondingTo: commentThread.commentedBy,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const payload = {
            updatingMainThread: false,
            slideID,
            mainThreadID: commentThread.ID,
            subComment: {
              ID: item.ID,
              comment: commentInput,
              respondingTo: commentThread.commentedBy,
              edited: true,
              createdAt: data.response.updatedAt,
            },
          };

          setProcessing(false);
          updateSlideComment(payload);
          onEditStatus({
            status: false,
            commentID: null,
          });

          if (emitSocketEvents) {
            emitSocketEvents({
              actionType: "update-comment",
              item: {
                type: null,
                value: payload,
              },
            });
          }
        })
        .catch((e) => {
          setProcessing(false);
        });
    }
  };

  const handleEditStatus = (id) => {
    onEditStatus({
      status: true,
      commentID: id,
    });
  };

  const onCancelEditStatus = () => {
    onEditStatus({
      status: false,
      commentID: null,
    });
  };

  const initResponding = () => {
    setResponding({
      status: true,
      respondingTo: item.commentedBy,
    });
  };

  const exitResponding = () => {
    setResponding({
      status: false,
      respondingTo: item.commentedBy,
    });
  };

  const regex = /\@\[([^\]]+)\]\([^\)]+\)/g;

  return (
    <WrapComment
      id={item.ID}
      hide={hideReplySection}
      highlightComment={highLightComment} // when user open from commented notification link
    >
      <WrapItem>
        <UsernameAndTime>
          <Username>{item.commentedBy.name}</Username>
          <Time>{timeDiff}</Time>
          <Edited>{item.edited ? "(Edited)" : undefined}</Edited>
        </UsernameAndTime>

        <div>
          {!editing &&
          !responding.status &&
          item.commentedBy.username === user.username ? (
            <CommentActions
              item={item}
              subcomment={true}
              onEdit={handleEditStatus}
              onDelete={onDelete}
              deleting={deleting}
            />
          ) : undefined}
        </div>
      </WrapItem>

      {!editing ? (
        <Comment
          contentEditable="false"
          dangerouslySetInnerHTML={{
            __html: item.comment.replace(regex, "<span>@$1</span>"),
          }}
          suppressContentEditableWarning={true}
        />
      ) : (
        <MentionsInput
          style={theme === "light" ? lightTheme : darkTheme}
          value={commentInput}
          onChange={handleCommentChange}
          appendspaceonadd="true"
        >
          <Mention
            displayTransform={(id, name) => `@${name}`}
            style={{
              backgroundColor: theme === "light" ? "#b7b9ff" : "#171dff",
            }}
            trigger="@"
            data={searchList}
            // onAdd={handleAdd}
          />
        </MentionsInput>
      )}

      <Reply hide={editing ? false : hideReplySection}>
        <div />

        <WrapActions>
          {processing ? (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          ) : undefined}

          {!editing && !responding.status ? (
            <div onClick={initResponding}>Reply</div>
          ) : undefined}

          {editing ? (
            <WrapCommentBtn>
              <EditCancel onClick={onCancelEditStatus}>Cancel</EditCancel>
              <SpecialButton disabled={processing} onClick={submitChanges}>
                Edit
              </SpecialButton>
            </WrapCommentBtn>
          ) : undefined}
        </WrapActions>
      </Reply>

      {/* responding to subcomment - making another subcomment */}
      {responding.status ? (
        <RespondingToComment
          mainThread={commentThread}
          user={user}
          onCancel={exitResponding}
          slideID={slideID}
          tempID={tempID}
          theme={theme}
          searchList={searchList}
          respondingToCommentBy={item.commentedBy}
        />
      ) : undefined}
    </WrapComment>
  );
};

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideInfo(state.designTemplate).id,
    tempSourceID: selectTempSourceID(state.designTemplate),
    tempID: selectTemplateID(state.designTemplate),
  };
};

export default connect(mapStateToProps, {updateSlideComment})(
  DisplaySubComment
);
