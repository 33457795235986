import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {getWindowSize} from "../../../oat-window-size";
import {dashboardSidebarWidth} from "../sidebar/UserSidebar";
import {paleBlue} from "../../userDashboardStyles";
import {userDashboardNavPanelHeight} from "../navigation/UserDashboardNav";
import WorkspaceTabs from "./userTemplates/mobile/WorkspaceTabs";
import {isMobile, isTablet} from "react-device-detect";
import { useParams } from "react-router-dom";

export const panelLeftRightPadding = 20;
const DashboardPanel = ({children}) => {
  const windowSize = getWindowSize();
  let params = useParams();
  const dashboardArea = useRef(null);
  const {section, workspaceID, folderID} = params;

  useEffect(() => {
    if (dashboardArea && dashboardArea?.current) {
      const overflowArea = dashboardArea.current; 
      if (overflowArea) {
        overflowArea.scrollTop = 0;
      }
    }
  },[section, workspaceID, folderID]);
  
  return (
    <Layout ref={dashboardArea} className="parent-container">
      <Wrapper style={{height: windowSize.height}}>
        <Container width={windowSize.width}>
          {isMobile || isTablet || windowSize.width < 850 ? (
            <WorkspaceTabs />
          ) : undefined}
          <PanelArea>{children}</PanelArea>
        </Container>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  overflow-y: scroll;
  background: ${paleBlue};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 849px) {
    position: relative;
    left: 0px;
  }
`;

const Container = styled.div`
  width: ${({width}) => width - dashboardSidebarWidth - 2 - 70}px;
  @media only screen and (max-width: 849px) {
    width: ${({width}) => width}px;
  }
`;

const PanelArea = styled.div`
  padding: ${userDashboardNavPanelHeight + 8}px 15px 15px 16px;
  background: ${paleBlue};
  min-height: ${window.innerHeight + 150}px;
  @media only screen and (min-width: 1200px) and (max-width: 1800px) {
    padding: ${userDashboardNavPanelHeight + 8}px ${panelLeftRightPadding}px
      145px ${panelLeftRightPadding}px;
  }
  @media only screen and (max-width: 849px) {
    padding: 0px 18px 15px 18px;
  }
`;

export default DashboardPanel;
