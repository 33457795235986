import React, {useEffect, useMemo, useState} from "react";
import styled, {css} from "styled-components";
import {TooltipStyles} from "../../../../ui/styles/TooltipStyles";
import {
  SvgSun,
  SvgStar,
  SvgShare,
  SvgCustomise,
} from "../../../../ui/svg/DesktopNavSvg";
import {grey, royalBlue, white} from "../../../../themes";
import {ButtonSpinner, Modal} from "../../../../../oat-ui";
import ShareTemplate from "../../modal/customizeSlides/exporting/ShareTemplate";
import SlideOptionsPopper from "./popper/SlideOptionsPopper";
import SelectTransition from "../../modal/customizeSlides/SelectTransition";
import LearnMoreMobileView from "../../modal/customizeSlides/LearnMoreMobileView";
import SlideContents from "../../modal/customizeSlides/slideContents/SlideContents";
import ManageNotification from "../../modal/customizeSlides/ManageNotification";
import ChatOnTemplate from "./popper/ChatOnTemplate";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ExpandArrow} from "../../../../../oat-svg-icons";
import TemplateVersions from "../../modal/customizeSlides/templateVersions/TemplateVersions";
import ViewTemplateVersion from "../../modal/customizeSlides/templateVersions/ViewTemplateVersion";
import DropDown from "../../../../ui/inputs/DropDown";
import {onSelectLayoutType} from "../../../../store/actions/template/layouts.action";
import {connect} from "react-redux";
import {selectActiveSlideInfo} from "../../../../store/selectors/template/slide.selector";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import { selectWorkspacePlan } from "../../../../store/selectors/template/template.selector";

// for admin
export const layoutTypes = [
  // {display: "All", value: "all"},
  {display: "Headers & Paragraphs", value: "headers-and-paragraphs"},
  {display: "Content List", value: "content-list"},
  {display: "Text & Images", value: "text-and-images"},
  {display: "Cards", value: "cards"},
  {display: "Charts", value: "charts"},
  {display: "Plans & Timelines", value: "plans-and-timelines"},
  {display: "Testimonials", value: "testimonials"},
];

const TopRightSide = ({
  processDesign,
  mode,
  onChangeTheme,
  buttonTitle,
  noActiveSlideExists,
  isSaving,
  onSelectLayoutType,
  layoutType,
  isDraft,
  workspacePlan,
  user
}) => {
  const [modal, setModal] = useState({
    type: null,
    status: false,
  });
  const [popper, setPopper] = useState({type: null, status: false});
  const location = useLocation();
  let params = useParams();
  const {category} = params;
  let navigate = useNavigate();

  const toggleSlideOptions = () => {
    if (!noActiveSlideExists) {
      setPopper({
        type: "slide-options",
        status: true,
      });
    }
  };

  const openModal = (type) => {
    if (!noActiveSlideExists) {
      setModal({
        type,
        status: true,
      });
    }
    closePopper();
  };

  const openShareModal = () => {
    openModal("share");
  };

  const openTemplateVersions = (e) => {
    e.stopPropagation();
    openModal("versions");
  };

  const closeModal = () => {
    setModal({
      type: null,
      status: false,
    });
  };

  const closePopper = () => {
    setPopper({type: null, status: false});
  };

  const changePopperType = (type) => {
    setPopper({
      type,
      status: true,
    });
  };

  const [selectedTempVersion, setSelectedTempVersion] = useState();
  const onSelectTempVersion = (value) => {
    openModal("view-template-version");
    setSelectedTempVersion(value);
  };

  const onChangeLayoutType = (value) => {
    onSelectLayoutType(value);
  };

  useEffect(() => {
    if (category === "layouts") {
      onSelectLayoutType(layoutTypes[0].value);
    }
  }, []);

  const handleUpdateTemplate = () => {
    processDesign({isDraft: true});
  };

  const onPublishTemplate = () => {
    processDesign({isDraft: false});
  };

  const reuseBtnStyles = useMemo(() => ({
    minWidth: 56,
    height: 30,
    justifyContent: !location.pathname.includes("/admin/designs/")
      ? "space-around"
      : "center",
    opacity: isSaving ? 0.6 : 1,
    padding: !location.pathname.includes("/admin/designs/")
      ? "0px 13px 0px 16px"
      : "0 14px",
    fontSize: "0.88rem",
    opacity: !isSaving ? 1 : 0.6,
    cursor: !isSaving ? "pointer" : "not-allowed",
    pointerEvents: isSaving ? "none" : undefined,
  }), []);

  const navigateToSignUp = () => {
    navigate(`/register`);
  };

  return (
    <>
      {/* if user is not logged in and playing around */}
      {user.username === null ? 
        <RightSide>
          <ThemeIcon mode={mode} onClick={onChangeTheme}>
            {mode === "dark" ? SvgSun : SvgStar}
            <Tooltip>
              {mode === "dark" ? "Switch to light Mode" : "Switch to dark Mode"}
            </Tooltip>
          </ThemeIcon>
          <SaveButton onClick={navigateToSignUp} style={{...reuseBtnStyles}}>Register</SaveButton>
        </RightSide>
      :
      <RightSide>
        {!location.pathname.includes("/admin/designs/") ? (
          <ChatOnTemplate noActiveSlideExists={noActiveSlideExists} />
        ) : undefined}

        <ThemeIcon mode={mode} onClick={onChangeTheme}>
          {mode === "dark" ? SvgSun : SvgStar}
          <Tooltip>
            {mode === "dark" ? "Switch to light Mode" : "Switch to dark Mode"}
          </Tooltip>
        </ThemeIcon>

        {!location.pathname.includes("/admin/designs/") ? (
          <WrapCustomiseSvg onClick={toggleSlideOptions}>
            {SvgCustomise}
            <Tooltip>Customize</Tooltip>
          </WrapCustomiseSvg>
        ) : undefined}

        {category === "layouts" ? (
          <WrapDropDown>
            <DropDown
              onSelect={onChangeLayoutType}
              selected={layoutType}
              options={layoutTypes}
              minWidth={165}
              height={260}
              boxWidth={180}
            />
          </WrapDropDown>
        ) : undefined}

        {!location.pathname.includes("/admin/designs/") ? (
          <ShareIcon onClick={openShareModal}>
            {SvgShare}
            <Tooltip>Share / Export</Tooltip>
          </ShareIcon>
        ) : undefined}

        {isDraft && location.pathname.includes("/admin/designs/edit") ? (
          <PublishBtn onClick={onPublishTemplate}>Publish</PublishBtn>
        ) : undefined}

        <div style={{position: "relative"}}>
          <SaveButton
            onClick={handleUpdateTemplate}
            style={{
              ...reuseBtnStyles
            }}
          >
            <div>{isSaving ? <ButtonSpinner /> : buttonTitle}</div>

            {!location.pathname.includes("/admin/designs/") ? (
              <WrapSvg onClick={openTemplateVersions}>{ExpandArrow}</WrapSvg>
            ) : undefined}
          </SaveButton>
        </div>

        {modal.status && modal.type === "slide-animation" ? (
          <Modal
            width={750}
            height={520}
            top={76}
            close={closeModal}
            background={mode === "dark" ? royalBlue : white}
          >
            <SelectTransition close={closeModal} />
          </Modal>
        ) : undefined}

        {modal.status && modal.type === "notification" ? (
          <Modal
            width={460}
            height={290}
            top={76}
            close={closeModal}
            background={mode === "dark" ? royalBlue : white}
          >
            <ManageNotification close={closeModal} />
          </Modal>
        ) : undefined}

        {popper.status ? (
          <SlideOptionsPopper
            selected={popper.type}
            changeModal={openModal}
            onClose={closePopper}
            onChangePopper={changePopperType}
          />
        ) : undefined}

        {modal.status && modal.type === "share" ? (
          <Modal
            width={workspacePlan === 'free' ? 805 : 740}
            height={520}
            top={63}
            close={closeModal}
            background={mode === "dark" ? royalBlue : white}
          >
            <ShareTemplate
              width={workspacePlan === 'free' ? 775 : 740}
              close={closeModal}
              processDesign={processDesign}
            />
          </Modal>
        ) : undefined}

        {modal.status && modal.type === "versions" ? (
          <Modal
            width={540}
            height={380}
            top={63}
            close={closeModal}
            background={mode === "dark" ? royalBlue : white}
          >
            <TemplateVersions onSelect={onSelectTempVersion} />
          </Modal>
        ) : undefined}

        {modal.status && modal.type === "view-template-version" ? (
          <Modal
            width={780}
            height={540}
            top={63}
            close={closeModal}
            background={mode === "dark" ? royalBlue : white}
          >
            <ViewTemplateVersion
              selectedTempVersion={selectedTempVersion}
              backToVersionList={openTemplateVersions}
            />
          </Modal>
        ) : undefined}

        {/* {modal.status && modal.type === "add-mobile-view-learn-more" ? (
          <Modal
            width={550}
            height={420}
            close={closeModal}
            background={mode === "dark" ? royalBlue : white}
          >
            <LearnMoreMobileView close={closeModal} />
          </Modal>
        ) : undefined} */}

        {/* {modal.status && modal.type === "content-grouping" ? (
          <Modal
            width={600}
            height={670}
            close={closeModal}
            background={mode === "dark" ? royalBlue : white}
          >
            <SlideContents />
          </Modal>
        ) : undefined} */}
      </RightSide>}
    </>
  );
};

export const RightSide = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 25px;
  position: relative;
`;

export const Tooltip = styled.div`
  bottom: -40px;
  ${TooltipStyles}
`;

const WrapCustomiseSvg = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 22px;
  cursor: pointer;
  fill: ${grey};
  position: relative;
  &:hover ${Tooltip} {
    display: flex;
    width: 85px;
    left: -34px;
    bottom: -43px;
  }
`;

const styles = css`
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 22px;
  cursor: pointer;
`;

export const ShareIcon = styled.div`
  ${styles};
  fill: ${grey};
  &:hover ${Tooltip} {
    display: flex;
    width: 85px;
    left: -34px;
  }
`;

export const ThemeIcon = styled.div`
  ${styles};
  margin-left: 18px;
  fill: ${({mode}) => (mode === "dark" ? "#f1e516" : "#4c73f1fc")};
  &:hover ${Tooltip} {
    display: flex;
    width: 125px;
    left: -58px;
  }
`;

const SaveButton = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.deskButton};
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 700;
  user-select: none;
  color: ${({theme}) => theme.deskBtnTxtColor};
  @media only screen and (min-width: 850px) {
    &:hover {
      opacity: 0.7;
    }
  }
`;

const WrapSvg = styled.div`
  position: relative;
  top: -1px;
  margin: 0 0px 0 7px;
  width: 15px;
  height: 15px;
  stroke: #fff;
  padding: 2px;
  box-sizing: border-box;
  stroke: #fff;
  fill: #fff;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background: rgb(107 126 222 / 50%);
  }
`;

const WrapDropDown = styled.div`
  margin: 0 18px 0 4px;
  font-size: 0.92rem;
`;

const PublishBtn = styled.div`
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 0.95rem;
  margin: 0 32px 0 20px;
  cursor: pointer;
`;

TopRightSide.defaultProps = {
  buttonTitle: "Save",
};

const mapStateToProps = (state) => {
  return {
    layoutType: selectActiveSlideInfo(state.designTemplate).layoutType,
    isDraft: state.designTemplate.isDraft,
    workspacePlan: selectWorkspacePlan(state.designTemplate),
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, {
  onSelectLayoutType,
})(TopRightSide);
