const style = {
  fill: "none",
  stroke: "#dedede",
  strokeWidth: 1.8,
};

const style2 = {
  fill: "none",
  stroke: "#fff",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  strokeWidth: 4,
  strokeDasharray: "18px 16px",
};
const Notes = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    style={{transform: `scale(2.6)`}}
  >
    <defs>
      <radialGradient
        id="notes-icon-gradient-one-231ad3a-fill"
        cx="99.75"
        cy="104.8"
        r="104.75"
        gradientTransform="matrix(1, 0, 0, -1, 0, 130)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffdc2a" />
        <stop offset="1" stopColor="#ff5c7c" />
      </radialGradient>
    </defs>
    <path
      style={{fill: "url(#notes-icon-gradient-one-231ad3a-fill)"}}
      d="M48.7,38H79.4A10.6,10.6,0,0,1,90,48.6V61.1C90,75.4,75.4,90,61.2,90H48.7A10.6,10.6,0,0,1,38.1,79.4V48.7A10.6,10.6,0,0,1,48.7,38Z"
    />
    <line style={style2} x1="47" y1="52" x2="81" y2="52" />
    <line style={style2} x1="47" y1="64" x2="81" y2="64" />
    <path
      style={{fill: "#e85348"}}
      d="M69.3,79.7h0A10.2,10.2,0,0,1,59,90h5.2A25.9,25.9,0,0,0,90,64.2V59A10.2,10.2,0,0,1,79.7,69.3h0A10.5,10.5,0,0,0,69.3,79.7Z"
    />
    <path
      style={{fill: "#0443a8", fillOpacity: 0}}
      d="M78.1,89.6h0a39.1,39.1,0,0,1-5.9.4h0C86.4,90,90,86.4,90,72.2h0s0,7.2-1,9.2h0C87.6,86.9,84.2,88.9,78.1,89.6Z"
    />
  </svg>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 128 128"
  //   shapeRendering="geometricPrecision"
  //   textRendering="geometricPrecision"
  //   style={{transform: `scale(2.7)`}}
  // >
  //   <defs>
  //     <filter
  //       id="erAKiLht0WG3-filter"
  //       x="-400%"
  //       width="600%"
  //       y="-400%"
  //       height="600%"
  //     >
  //       <feGaussianBlur
  //         id="erAKiLht0WG3-filter-blur-0"
  //         stdDeviation="4,4"
  //         result="result"
  //       ></feGaussianBlur>
  //     </filter>
  //     <linearGradient
  //       id="notes-icon-gradient-one-231ad3a"
  //       x1="103.15"
  //       y1="77.42"
  //       x2="34.78"
  //       y2="53.99"
  //       spreadMethod="pad"
  //       gradientUnits="userSpaceOnUse"
  //       gradientTransform="matrix(1 0 0 1 0 0)"
  //     >
  //       <stop
  //         id="notes-icon-gradient-one-231ad3a-0"
  //         offset="0%"
  //         stopColor="rgb(117,159,255)"
  //       ></stop>
  //       <stop
  //         id="notes-icon-gradient-one-231ad3a-1"
  //         offset="100%"
  //         stopColor="rgb(62,117,255)"
  //       ></stop>
  //     </linearGradient>
  //     <linearGradient
  //       id="notes-icon-gradient-two-472gd6r"
  //       x1="103.15"
  //       y1="77.42"
  //       x2="34.78"
  //       y2="53.99"
  //       spreadMethod="pad"
  //       gradientUnits="userSpaceOnUse"
  //       gradientTransform="matrix(1 0 0 1 0 0)"
  //     >
  //       <stop
  //         id="notes-icon-gradient-two-472gd6r-0"
  //         offset="0%"
  //         stopColor="rgb(117,159,255)"
  //       ></stop>
  //       <stop
  //         id="notes-icon-gradient-two-472gd6r-1"
  //         offset="100%"
  //         stopColor="rgb(62,117,255)"
  //       ></stop>
  //     </linearGradient>
  //   </defs>
  //   <g>
  //     <g transform="matrix(1 0 0 1 0.000002 0.000002)" opacity="1">
  //       <path
  //         d="M48.65,38L79.35,38C82.174556,38,84.883425,39.12205,86.880687,41.119313C88.87795,43.116575,90,45.825444,90,48.65L90,61.2C90,75.448947,75.448947,90,61.2,90L48.65,90C45.825444,90,43.116575,88.87795,41.119313,86.880687C39.12205,84.883425,38,82.174556,38,79.35L38,48.65C38,42.768167,42.768168,38,48.65,38Z"
  //         fill="url(#notes-icon-gradient-two-472gd6r)"
  //         stroke="none"
  //         strokeWidth="1"
  //       ></path>
  //       <line
  //         x1="81"
  //         y1="60"
  //         x2="47"
  //         y2="60"
  //         transform="matrix(-1 0 0 -1 128 112)"
  //         fill="none"
  //         stroke="rgb(255,255,255)"
  //         strokeWidth="4"
  //         strokeLinecap="round"
  //         strokeMiterlimit="10"
  //         strokeDasharray="18 16"
  //       ></line>
  //       <line
  //         x1="81"
  //         y1="48"
  //         x2="47"
  //         y2="48"
  //         transform="matrix(-1 0 0 -1 128 112)"
  //         fill="none"
  //         stroke="rgb(255,255,255)"
  //         strokeWidth="4"
  //         strokeLinecap="round"
  //         strokeMiterlimit="10"
  //         strokeDasharray="18 16"
  //       ></line>
  //       <line
  //         x1="56"
  //         y1="47"
  //         x2="56"
  //         y2="65"
  //         transform="matrix(0 1 -1 0 112 5)"
  //         fill="none"
  //         stroke="rgb(255,255,255)"
  //         strokeWidth="4"
  //         strokeLinecap="round"
  //         strokeMiterlimit="10"
  //         strokeDashoffset="18"
  //         strokeDasharray="18"
  //       ></line>
  //       <path
  //         d="M 69.34 79.67 L 69.34 79.67 C 69.342662 82.412235 68.253808 85.042775 66.313816 86.980891 C 64.373824 88.919007 61.742231 90.005316 59 90 L 64.2 90 C 78.430714 89.956078 89.956078 78.430714 90 64.2 L 90 59 C 90.005316 61.742231 88.919007 64.373824 86.980891 66.313816 C 85.042775 68.253808 82.412235 69.342662 79.67 69.34 L 79.67 69.34 C 73.967184 69.345513 69.345513 73.967184 69.34 79.67 Z"
  //         fill="rgb(4,67,168)"
  //         stroke="none"
  //         strokeWidth="1"
  //       ></path>
  //     </g>
  //     <path
  //       d="M 78.061481 89.578242 L 78.061481 89.578242 C 76.393606 90 72.2 90 72.2 90 C 72.2 90 74.942231 90.005316 72.2 90 L 72.2 90 C 86.430714 89.956078 89.956078 86.430714 90 72.2 L 90 72.2 C 90.005316 74.942231 90 72.2 90 72.2 C 90 72.2 90 79.35 88.988936 81.353894 L 88.988936 81.353894 C 87.551113 86.880687 84.215364 88.9456 78.061481 89.578242 Z"
  //       opacity="0"
  //       fill="rgb(4,67,168)"
  //       stroke="none"
  //       strokeWidth="1"
  //     ></path>
  //   </g>
  // </svg>
);

export default Notes;
