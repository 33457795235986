import React, {useState, useEffect, useMemo} from "react";
import styled from "styled-components";
import SelectedShape from "./shapes/SelectedShape";
import ImageField from "./image/ImageField";
import {ulOlLi} from "../../parts/ul-ol-li";
import DisplayChart from "./charts/DisplayChart";
import DisplayTable from "./table/DisplayTable";
import TextField from "./text/TextField";
import DisplayIcon from "./icons/DisplayIcon";
import EmbedVideo from "./video/EmbedVideo";
import {connect} from "react-redux";
import {onPreviewAnimation} from "../../../../store/actions/fields/animation.action";
import {selectSlideAnimationOrder} from "../../../../store/selectors/template/slide.selector";
import EmbedMap from "./video/EmbedMap";

const transitionTiming = 'cubic-bezier(.29,.07,.64,.9)' //'cubic-bezier(0,.49,1,.97)';
export const imgFieldFontSize = 20;
const DisplayFields = React.forwardRef(
  (
    {
      field,
      selected,
      onSelectItem,
      onDragStart,
      onEditText,
      tempScale,
      slideIndex,
      tempSize,
      previewAnimation,
      onPreviewAnimation,
      dragging,
      resizing,
      croppingImage,
      activeSlide,
      animationOrder,
      previewAnimationOption,
    },
    fieldRef
  ) => {
    const {styles, pos, deleted, size} = field;
    const animateOnSlideDisplayHighestOrder = animationOrder?.animateOnSlideDisplayHighestOrder;
    const animateByClickHighestOrder = animationOrder?.highestOrder; // for step by step on click

    const handleSelection = (e) => {
      e.stopPropagation();
      onSelectItem(e, field);
    };

    const initDragEvent = (e) => {
      e.stopPropagation();
      onDragStart(e, field);
    };

    const displayFields = () => {
      switch (field.type) {
        case "text": {
          return (
            <WrapTextField
              style={{
                // width, height here important (overflow click)
                width: size.w,
                height: size.h,
                borderRadius: field.styles?.borderRadius,
                background: field.styles?.background,
                boxShadow:
                  styles?.bgType === "button"
                    ? `${3 / tempScale}px ${3 / tempScale}px 0 0 ${
                        field.styles?.shadowColor
                      }`
                    : undefined,
                // transform: `scale(${styles.scale})`,
                // outline:
                //   selected && enableEditing ? "1px solid #d5c6ff" : undefined,
              }}
            >
              <TextField
                onSelectItem={handleSelection}
                field={field}
                ref={fieldRef}
                handleDragStart={initDragEvent}
                handleTouchStart={selected ? initDragEvent : handleSelection}
                selected={selected}
                onEditText={onEditText}
                dragging={dragging}
                disableLinkStyles={true}
              />
            </WrapTextField>
          );
        }
        case "image":
          return (
            <div style={{
                ...fieldSize,
                fontSize: Math.max(1, Math.min(size.w, size.h) / imgFieldFontSize)
                // imgFieldFontSize, 
                // add cutout 
                // overflow: field.fileType === 'png' && cutout ? 'hidden' : undefined
              }} 
              ref={fieldRef}
            >
              <ImageField
                onDragStart={initDragEvent}
                onSelect={handleSelection}
                field={field}
                selected={selected}
                tempScale={tempScale}
                croppingImage={croppingImage}
                slideIndex={slideIndex}
              />
            </div>
          );
        case "icon":
          return (
            <Field
              ref={fieldRef}
              onMouseDown={initDragEvent}
              onTouchStart={selected ? initDragEvent : handleSelection}
            >
              <DisplayIcon field={field} />
            </Field>
          );
        case "shape":
          return (
            <Field
              style={{width: "100%", height: "100%"}}
              ref={fieldRef}
              onMouseDown={initDragEvent}
              onTouchStart={selected ? initDragEvent : handleSelection}
            >
              {field.size ? (
                <SelectedShape
                  size={field.size}
                  shape={field.name}
                  styles={{...styles}}
                  index={field.key + "shape"}
                  tempScale={tempScale}
                  isLandScape={tempSize.w > tempSize.h}
                />
              ) : undefined}
            </Field>
          );
        case "chart":
          return (
            <Field
              ref={fieldRef}
              onMouseDown={initDragEvent}
              onTouchStart={selected ? initDragEvent : handleSelection}
            >
              <DisplayChart field={field} />
            </Field>
          );
        case "table":
          return (
            <Field
              ref={fieldRef}
              onMouseDown={initDragEvent}
              onTouchStart={selected ? initDragEvent : handleSelection}
            >
              <DisplayTable
                field={field}
                selected={selected}
                dragging={dragging}
                resizing={resizing}
              />
            </Field>
          );
        case "video":
          return ( 
            <Field
              ref={fieldRef}
              onMouseDown={initDragEvent}
              onTouchStart={selected ? initDragEvent : handleSelection}
              style={{ width: fieldSize?.width , height: fieldSize?.height }}
            >
              <EmbedVideo field={field} activeSlide={activeSlide} /> 
              {/* <EmbedMap /> */}
              {/* {field?.subtype === 'map' ? 
                <EmbedMap /> :
                <EmbedVideo field={field} activeSlide={activeSlide} /> 
              } */}
            </Field>
          );
        default:
          return;
      }
    };

    const curveText = (text) => {
      const angleStep = 14;
      const angleOffset = -angleStep * text.length * 0.55;
      let angles = [];
      for (let i = 0; i < text.length; ++i) {
        const angle = i * angleStep + angleOffset + 12;
        angles.push({letter: text[i], angle});
      }
      return angles;
    };

    const dragFieldStyles = useMemo(
      () => ({
        display: deleted === true ? "none" : "block",
      }),
      [deleted, selected]
    );

    const fieldSize = useMemo(
      () => ({
        width: Math.ceil(size.w),
        height: size.h,
      }),
      [size, selected]
    );

    const fieldOffset = useMemo(
      () => ({
        display: deleted === true ? "none" : "block",
        transform: `translate(${pos.x}px, ${pos.y}px) rotate(${styles.rotate}deg)`,
      }),
      [
        deleted, 
        pos, 
        styles?.zIndex, 
        selected, 
        styles?.rotate,
      ]
    );

    const [animateOnSlideLoadOrder, setAnimateOnSlideLoadOrder] = useState(0);
    const [animateByClickOrder, setAnimateByClickOrder] = useState(0);
    const animateOnSlideDelayDuration = 500; 
    useEffect(() => {
      if(previewAnimation && activeSlide && 
        styles?.animateOnSlideDisplay && 
        styles?.animationType !== "none" &&
        animateOnSlideLoadOrder === 0
      ) {
        setTimeout(() => {
          setAnimateOnSlideLoadOrder(animateOnSlideLoadOrder + 0.5);
        }, Number(styles?.animationDelay * animateOnSlideDelayDuration));
      }
    }, [
      activeSlide, 
      styles?.animateOnSlideDisplay,
      styles?.animationDelay,
      styles?.animationType,
      previewAnimation,
      animateOnSlideLoadOrder // !important
    ]);

    // previewOnSlideLoad
    const [enableExit, setEnableExit] = useState(false);
    const [exitAnimation, setExitAnimation] = useState(false);
    let previewOnSlideLoad = previewAnimationOption === "on-slide-display" ? true : false;
    const animatedValue = useMemo(() => {
      let animationEffects = undefined;
      if (
        activeSlide &&
        (!styles?.animateOnSlideDisplay && animateByClickOrder !== 0 && styles?.animationOrder !== 0 ||
          styles?.animateOnSlideDisplay && animateOnSlideLoadOrder !== 0) &&
        styles?.animationType !== "none" &&
        previewAnimation &&
        previewOnSlideLoad === styles?.animateOnSlideDisplay && 
        !exitAnimation
      ) {
        animationEffects = {
          opacity: styles.opacity,
          visibility: "visible",
          WebkitTransition: styles?.animationType !== "no-animate" ? 
            `${styles?.animationSpeed * 1.5}s all ${transitionTiming} 
            ${!styles?.animateOnSlideDisplay ? 1000 * styles?.animationOrder : 0}ms` : undefined,
          transition: styles?.animationType !== "no-animate" ? 
            `${styles?.animationSpeed * 1.5}s all ${transitionTiming} 
            ${!styles?.animateOnSlideDisplay ? 1000 * styles?.animationOrder : 0}ms` : undefined, // delay before moving to another step
          transform: `translate3d(0px, 0px, 0px)`,
        };
      } else if (previewAnimation && exitAnimation) {
        animationEffects = {
          opacity: 0,
          visibility: 'hidden',
          WebkitTransition: `1.5s all ${200}ms`, 
          transition: `1.5s all ${200}ms`, 
        };
      }
      return animationEffects;
    }, [
      activeSlide,
      tempSize,
      previewAnimation,
      styles?.animationType,
      styles?.animationOrder,
      styles?.animationSpeed,
      styles?.animateOnSlideDisplay,
      previewOnSlideLoad,
      animateByClickOrder,
      animateOnSlideLoadOrder,
      exitAnimation
    ]);

    const animateFields = useMemo(() => {
      let animationEffects = undefined;
      if (
        activeSlide &&
        previewAnimation &&
        animateByClickOrder === 0 &&
        ((!styles?.animateOnSlideDisplay && styles?.animationOrder !== 0) ||
         (styles?.animationType && styles?.animationType !== "none")) 
         && 
          !styles?.animateOnSlideDisplay 
          &&
        previewOnSlideLoad === styles?.animateOnSlideDisplay
      ) {
        const horizontalDistance = (tempSize.w * 12) / 100;
        const verticalDistance = (tempSize.h * 12) / 100;
        // HERE
        const visibility = {
          opacity:
            0 === animateByClickOrder && styles?.animationType !== "none"
              ? 0
              : styles.opacity,
          visibility:
            0 === animateByClickOrder && styles?.animationType !== "none"
              ? "hidden"
              : "visible",
        };
        if (styles?.animationType === "bottom-to-top") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(0px, ${
              styles.animationOrder > animateByClickOrder
                ? `${verticalDistance}`
                : 0
            }px, 0)`,
          };
        } else if (styles?.animationType === "top-to-bottom") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(0px, ${
              styles.animationOrder > animateByClickOrder
                ? `-${verticalDistance}`
                : 0
            }px, 0)`,
          };
        } else if (styles?.animationType === "left-to-right") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(${
              styles.animationOrder > animateByClickOrder
                ? -horizontalDistance
                : 0
            }px, 0px, 0)`,
          };
        } else if (styles?.animationType === "right-to-left") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(${
              styles.animationOrder > animateByClickOrder
                ? horizontalDistance
                : 0
            }px, 0px, 0)`,
          };
        } else if (styles?.animationType === "zoom-in") {
          animationEffects = {
            ...visibility,
            transform: `translate(0px, 0px) scale(${
              styles?.animationOrder > animateByClickOrder ? 0.7 : 1
            })`,
          };
        } else if (styles.animationType === "zoom-out") {
          animationEffects = {
            ...visibility,
            transform: `translate(0px, 0px) scale(${
              styles?.animationOrder > animateByClickOrder ? 1.3 : 1
            })`,
          };
        } else if (styles.animationType === "fade-in") {
          animationEffects = {
            ...visibility,
          };
        } else if (styles.animationType === "no-animate") {
          animationEffects = {
            ...visibility,
          };
        }
      }
      if (
        activeSlide &&
        previewAnimation &&
        animateOnSlideLoadOrder === 0 &&
        ((styles?.animationType && styles?.animationType !== "none")) &&
        styles?.animateOnSlideDisplay 
        && 
        // styles?.animateOnSlideDisplay
        previewOnSlideLoad === styles?.animateOnSlideDisplay
      ) {
        const horizontalDistance = (tempSize.w * 12) / 100;
        const verticalDistance = (tempSize.h * 12) / 100;
        const visibility = {
          opacity:
            0 === animateOnSlideLoadOrder && styles?.animationType !== "none"
              ? 0
              : styles.opacity,
          visibility:
            0 === animateOnSlideLoadOrder && styles?.animationType !== "none"
              ? "hidden"
              : "visible",
        };
        if (styles?.animationType === "bottom-to-top") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(0px, ${
              styles?.animationDelay > animateOnSlideLoadOrder
                ? `${verticalDistance}`
                : 0
            }px, 0)`,
          };
        } else if (styles?.animationType === "top-to-bottom") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(0px, ${
              styles?.animationDelay > animateOnSlideLoadOrder
                ? `-${verticalDistance}`
                : 0
            }px, 0)`,
          };
        } else if (styles?.animationType === "left-to-right") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(${
              styles?.animationDelay > animateOnSlideLoadOrder
                ? -horizontalDistance
                : 0
            }px, 0px, 0)`,
          };
        } else if (styles?.animationType === "right-to-left") {
          animationEffects = {
            ...visibility,
            transform: `translate3d(${
              styles?.animationDelay > animateOnSlideLoadOrder
                ? horizontalDistance
                : 0
            }px, 0px, 0)`,
          };
        } else if (styles?.animationType === "zoom-in") {
          animationEffects = {
            ...visibility,
            transform: `translate(0px, 0px) scale(${
              styles?.animationDelay > animateOnSlideLoadOrder ? 0.7 : 1
            })`,
          };
        } else if (styles.animationType === "zoom-out") {
          animationEffects = {
            ...visibility,
            transform: `translate(0px, 0px) scale(${
              styles?.animationDelay > animateOnSlideLoadOrder ? 1.3 : 1
            })`,
          };
        } else if (styles.animationType === "fade-in") {
          animationEffects = { ...visibility };
        } else if (styles.animationType === "no-animate") {
          animationEffects = { ...visibility };
        }
      }
      return animationEffects;
    }, [
      animateOnSlideLoadOrder,
      previewAnimation,
      styles?.animationType,
      styles?.animationOrder,
      styles?.animationSpeed,
      styles?.animationDuration,
      styles?.animationDelay,
      activeSlide,
      tempSize,
      styles?.animateOnSlideDisplay,
      previewOnSlideLoad,
      animateByClickOrder,
    ]);

    useEffect(() => {
      if (previewAnimation && activeSlide &&
        animateByClickOrder === 0 && 
        animateByClickOrder < animateByClickHighestOrder &&
        !styles?.animateOnSlideDisplay && 
        styles?.animationType !== "none"
      ) { 
        setTimeout(() => {
          setAnimateByClickOrder(animateByClickOrder + 1);
        }, 50); // setTimeout important - set 50 here
      }
    }, [
      previewAnimation, styles?.animationOrder, 
      animateByClickOrder, styles?.animateOnSlideDisplay, 
      styles?.animationType, animateByClickHighestOrder
    ]);

    useEffect(() => {
      // after animation has ended, reset the state
      if (
        activeSlide &&
        previewAnimation &&
        (styles?.animationOrder !== 0 ||
          (styles?.animationType && styles?.animationType !== "none"))
      ) {   
        if (!previewOnSlideLoad) {
          setTimeout(() => {
            setAnimateByClickOrder(0);
          }, 10);
          if (!styles?.animateOnSlideDisplay && animateByClickHighestOrder === styles?.animationOrder) { 
            const timeoutDuration = (1200 * styles?.animationOrder) + (styles?.animationSpeed * 1000);  
            setTimeout(() => {
              onPreviewAnimation(false);  
            }, timeoutDuration);
          }
        }
        //  else {
        //    // to reset if no other animation fields are present
        //   setTimeout(() => {
        //     onPreviewAnimation(false);   
        //   }, 100);
        // };
        
        if (previewOnSlideLoad) {
          setTimeout(() => {
            setAnimateOnSlideLoadOrder(0);
          }, 10);
          if (styles?.animateOnSlideDisplay && animateOnSlideDisplayHighestOrder === styles?.animationDelay) {
            const timeoutDuration = (animateOnSlideDelayDuration * styles?.animationDelay) + (styles?.animationSpeed * 1500);
            setTimeout(() => {
              onPreviewAnimation(false);
            }, timeoutDuration);
          }
        } 
        // else {
        //   // to reset if no other animation fields are present
        //   setTimeout(() => {
        //     onPreviewAnimation(false);   
        //   }, 100);
        // };
      }
    }, [
      activeSlide,
      previewAnimation,
      animateByClickHighestOrder,
      animateOnSlideDisplayHighestOrder,
      styles?.animationOrder,
      styles?.animationType,
      styles?.animationSpeed,
      styles?.animateOnSlideDisplay,
      styles?.animationDelay,
      previewOnSlideLoad
    ]);

    const onAnimationFinished = () => {
      if (styles?.enableExitAnimation && 
        styles?.animationType !== "none" &&
        (!styles?.animateOnSlideDisplay || animateOnSlideDisplayHighestOrder === styles?.animationOrder)) 
      {
        setExitAnimation(true);
      }
    }
    // resetting state after exit animation has finished
    useEffect(() => {
      if (exitAnimation && !previewAnimation) {
        setExitAnimation(false);
      }
    },[exitAnimation, previewAnimation]);
    
    return (
      <div 
        style={{
          position: "absolute", 
          ...fieldSize, 
          ...fieldOffset,
          zIndex: croppingImage ? 1000 : styles.zIndex,
        }}
      >
        <DragField
          onTransitionEnd={onAnimationFinished}
          style={{
            // do not add fieldSize here, changes in fontSize will not auto update correctly
            ...dragFieldStyles,
            opacity: field.styles.opacity,
            transformOrigin: "50% 50%", // !important
            // outline:
            //   selected && field.type !== "shape" && field.subtype !== "line"
            //     ? "1px solid blue"
            //     : undefined,
            ...animatedValue,
            ...(previewAnimation ? {...animateFields} : {}),
          }}
          selected={selected}
        >
          {displayFields()}
        </DragField>
      </div>
    );
  }
);

// SOME STYLE CHANGES IN HERE, MIGHT NEED TO REFLECT IN DISPLAY FIELD.JS FILE
export const Field = styled.div`
  height: 100%;
  width: 100%;
  overflow-wrap: break-word;
  transform-origin: left top;
  // user-select: none;
  h2 {
    height: 100%; // for header
  }
  // white-space: pre-wrap;
  // word-wrap: break-word;
  outline: none;
  ${ulOlLi};
  pointer-events: auto;
`;

const DragField = styled.div`
  box-sizing: border-box;
  position: absolute;
  will-change: transform;
  // transform-origin: bottom left;
  // perspective: 1000px;
  // transition-property: transform perspective;
  // transition-timing-function: linear;
  // transition-delay: initial;
  // user-select: none;
`;

export const WrapTextField = styled.div`
  transform-origin: left top;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    animationOrder: selectSlideAnimationOrder(designTemplate),
    previewAnimation: designTemplate.previewAnimation,
    previewAnimationOption: designTemplate.previewAnimationOption,
  };
};

export default connect(mapStateToProps, {onPreviewAnimation}, null, {
  forwardRef: true,
})(DisplayFields);
