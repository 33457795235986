import React from "react";
import {connect} from "react-redux";
import RangeSlider from "../../../shared/inputs/RangeSlider";
import {updateFieldStyles} from "../../../../../../../store/actions/fields/common.action";
import Switch from "../../../shared/inputs/Switch";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import { selectTempScale } from "../../../../../../../store/selectors/template/template.selector";

const EditChartLine = ({
  showDots,
  dotSize,
  updateFieldStyles,
  slideID,
  fieldID,
  tempScale
}) => {
  const active = {slideID, fieldID};
  const handleSizeUpdate = (value) => {
    updateFieldStyles({
      active,
      type: "dotSize",
      value: Number(value),
    });
  };

  const handleDotVisibility = (value) => {
    updateFieldStyles({
      active,
      type: "showDots",
      value,
    });
  };

  return (
    <>
      <div style={{paddingBottom: showDots ? "2em" : "0em"}}>
        <Switch
          title="Show Dots"
          value={showDots}
          onItemChange={handleDotVisibility}
          checked={showDots}
        />
      </div>
      {showDots ? (
        <RangeSlider
          title="Dot Size"
          value={dotSize}
          max={Math.ceil(10 / tempScale)}
          min={1 / tempScale}
          step={(1 * Math.ceil(10 /tempScale)) / 100}
          onItemChange={handleSizeUpdate}
        />
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate)
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(EditChartLine);
