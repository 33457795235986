import React, {useState} from "react";
import styled from "styled-components";
import {darkGrey1, darkGrey2} from "../../../oat-color-variables";
import LayoutHoc from "../../layout/LayoutHoc";
import FAQuestions from "../components/FAQuestions";

const Faq = () => {
  return (
    <LayoutHoc>
      <FAQuestions />
    </LayoutHoc>
  );
};

export default Faq;
