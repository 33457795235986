import styled from "styled-components";

export const CanvasLayout = styled.div`
  position: relative;
  margin: ${({templateShape}) =>
    templateShape === "circle" ? "10px 0" : "0px"};
  box-sizing: border-box;
  // transition: all 0.2s ease; // stop animation on load slide selection
  @media only screen and (min-width: 850px) {
    overflow: scroll;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export const WrapFrame = styled.div`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  outline: none;
  text-align: center;
  position: relative; //
  touch-action: none;
  overflow: hidden; //
  display: flex;
  box-sizing: border-box;
  transform-origin: top left;
  border-radius: ${({templateShape}) =>
    templateShape === "circle" ? "50%" : "0%"};
  // border: 5px solid #fff; // may be for circle's border
`;

export const Card = styled.div`
  position: relative;
  pointer-events: auto;
  width: 100%;
  height: 100%;
`;
