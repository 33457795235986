import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserProfileTags} from "../../../../store/selectors/userDashboard.selector";
import {userPrimaryGrey} from "../../../../userDashboardStyles";
import {Item} from "../../../sidebar/reusableStyles/sidebarItem";
import {SplitLine} from "../../../sidebar/UserSidebar";
import CreateProfileTag from "./CreateProfileTag";
import Collection from "./Collection";

const ProfileCollections = ({collections, user}) => {
  return (
    <>
      <SplitLine style={{margin: "8px 0"}} />
      <Wrapper>
        <Item>
          <Tab>Collections</Tab>
          <CreateProfileTag />
        </Item>
        <ListItem>
          {collections &&
            collections.map((item, i) => (
              <Collection key={i} collection={item} index={i} />
            ))}
        </ListItem>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Tab = styled.div`
  padding: 12px 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: ${userPrimaryGrey};
  border-radius: 6px;
`;

const ListItem = styled.div`
  padding-top: 6px;
`;

export const Popper = styled.div`
  background: #fff;
  width: 200px;
  height: 120px;
  position: absolute;
  top: 30px;
  left: -50px;
  cursor: default;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: rgb(152 151 154) 0px 0px 3px 0px;
`;

const mapStateToProps = (state) => {
  return {
    collections: selectUserProfileTags(state.userDashboard),
  };
};

export default connect(mapStateToProps, null)(ProfileCollections);
