import React, {useRef, useCallback, useEffect} from "react";
import styled from "styled-components";
import PresentViewSlideArrows from "./PresentViewSlideArrows";
import {
  selectCurrentSlideOnClickAnimationOrder,
  selectActiveSlideInfo,
  selectSlideAnimationOrder,
} from "../../../store/selectors/template/slide.selector";
import {connect} from "react-redux";
import {changeSlideByID} from "../../../store/actions/template/slide.action";
import {
  selectEmbeddingType,
  selectTemplate,
} from "../../../store/selectors/template/template.selector";
import {
  ExitFullScreen,
  EnterFullScreen,
  Duplicate,
} from "../../../../oat-svg-icons";
import PresentSlideList from "./PresentSlideList";
import {detectClickOutside} from "../../../../oat-ui";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {isMobile, isTablet} from "react-device-detect";
import {canvasPanelHeight} from "../Present";

const DisplayTab = ({
  item,
  onSlideChange,
  pos,
  active,
  highestAnimationOrder,
  currentSlideOnClickAnimationOrder,
}) => {
  const handleClick = () => {
    // onSlideChange(item.startingSlideNo);
    onSlideChange(item.id);
  };

  // const [mousePosition, setMousePosition] = useState({
  //   x: null,
  //   y: null,
  // });
  // const onMouseMoving = (e) => {
  //   const onMove = (e) => {
  //     e.stopImmediatePropagation();
  //     const {clientX, clientY} = e;
  //     if (clientY > window.innerHeight - 95) {
  //       setMousePosition({
  //         x: clientX,
  //         y: clientY,
  //       });
  //     } else {
  //       setMousePosition({x: null, y: null});
  //     }
  //   };
  //   const onLeave = () => {
  //     document.removeEventListener("mouseover", onMove);
  //     document.removeEventListener("mouseleave", onLeave);
  //   };
  //   document.addEventListener("mouseover", onMove);
  //   document.addEventListener("mouseleave", onLeave);
  // };

  return (
    <TabArea
      // onMouseOver={onMouseMoving}
      onClick={handleClick}
      style={{
        flex: 1, //item.noOfSlides
      }}
    >
      <Tab
        active={active}
        currentSlideOnClickAnimationOrder={currentSlideOnClickAnimationOrder}
        highestAnimationOrder={highestAnimationOrder}
      />
      {/* {mousePosition.x ? (
        <HoveredContent
          style={{position: "absolute", top: -42, left: mousePosition.x}}
        >
          {item.section}
        </HoveredContent>
      ) : (
        undefined
      )} */}
    </TabArea>
  );
};

const TabClickableArea = 15;
const SlideNavPanel = ({
  slideInfo,
  changeSlideByID,
  slides,
  displayBar,
  showSlideList,
  onDisplayView,
  embedType,
  onViewComment,
  currentSlideOnClickAnimationOrder,
  slideHighestAnimationOrder,
  activateFullScreen,
  isFullscreen,
}) => {
  const {width, height} = getWindowSize();
  const panelAreaRef = useRef();
  // const panelHeight = width > 600 ? 70 : 48;

  const handleSlideChange = (value) => {
    changeSlideByID(value);
    if (showSlideList) {
      onDisplayView(false);
    }
  };

  const onToggleDisplaySlideList = () => {
    if (!showSlideList) {
      onDisplayView(true);
    } else {
      onDisplayView(false);
    }
  };

  const detectShortcutKeys = useCallback((event) => {
    if (event.keyCode === 27) {
      onDisplayView(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", detectShortcutKeys, false);
    return () => {
      document.removeEventListener("keydown", detectShortcutKeys, false);
    };
  });

  detectClickOutside(panelAreaRef, () => {
    onDisplayView(false);
  });

  return (
    <>
      {!isFullscreen && (embedType !== "with-panel" || showSlideList) ? (
        <FadeBackground
          showSlideList={showSlideList}
          style={{
            opacity: displayBar || showSlideList ? 1 : 0,
            bottom: displayBar || showSlideList ? 0 : -window.innerHeight,
          }}
        />
      ) : undefined}
      <Panel
        ref={panelAreaRef}
        style={{
          position:
            isMobile || isTablet || embedType === "with-panel"
              ? "relative"
              : "absolute",
          bottom:
            isMobile || isTablet || embedType === "with-panel"
              ? undefined
              : displayBar || showSlideList
              ? 0
              : -canvasPanelHeight,
          height: canvasPanelHeight,
        }}
      >
        {width > 600 ? (
          <SlideBarTabs style={{bottom: displayBar ? 12 : -TabClickableArea}}>
            {slideInfo &&
              slides &&
              slides.map((item, i) => (
                <DisplayTab
                  key={item.id}
                  item={item}
                  active={item.id === slideInfo.id}
                  onSlideChange={handleSlideChange}
                  highestAnimationOrder={slideHighestAnimationOrder}
                  currentSlideOnClickAnimationOrder={
                    currentSlideOnClickAnimationOrder === -1
                      ? 0
                      : currentSlideOnClickAnimationOrder
                  }
                />
              ))}
          </SlideBarTabs>
        ) : undefined}

        {width > 600 ? (
          <PresentSlideList
            showLayout={showSlideList}
            onSlideChange={handleSlideChange}
            slides={slides}
            styles={{bottom: 92}}
          />
        ) : undefined}

        <PanelContents $panelHeight={canvasPanelHeight}>
          <Section>
            {slideInfo && width > 600 ? (
              <SlideTitle style={{cursor: "pointer"}}>
                <WrapSvg onClick={onToggleDisplaySlideList}>
                  {Duplicate}
                </WrapSvg>
              </SlideTitle>
            ) : undefined}
          </Section>

          <Section style={{justifyContent: "center"}}>
            <PresentViewSlideArrows />
          </Section>

          <Section style={{justifyContent: "flex-end"}}>
            {/* <WrapSvg
            onClick={onViewComment}
            style={{width: 28, height: 28, marginRight: 28}}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <rect x="1.5" y="5" width="21" height="14" rx="7" ry="7" />
              <circle cx="6.5" cy="12" r="1.5" fill="#565656" />
              <circle cx="12" cy="12" r="1.5" fill="#565656" />
              <circle cx="17.5" cy="12" r="1.5" fill="#565656" />
            </svg>
          </WrapSvg> */}

            {window.screen.height !== window.innerHeight ? (
              <FullScreenMode
                style={{width: 32, height: 32}}
                onClick={activateFullScreen}
              >
                {EnterFullScreen}
              </FullScreenMode>
            ) : (
              <FullScreenMode
                style={{width: 32, height: 32}}
                onClick={activateFullScreen}
              >
                {ExitFullScreen}
              </FullScreenMode>
            )}
          </Section>
        </PanelContents>
      </Panel>
    </>
  );
};

const FadeBackground = styled.div`
  background: ${({showSlideList}) =>
    `linear-gradient(0deg, #2f3047bd, transparent ${
      showSlideList ? "75%" : "30%"
    })`};
  position: absolute;
  height: 100%;
  width: 100%;
  WebkitTransition: all 0.4s ease;
  transition: all 0.4s ease;
`;

const Panel = styled.div`
  width: 100%;
  WebkitTransition: all 0.4s ease;
  transition: all 0.4s ease;
  user-select: none;
  z-index: 999;
  background: #302e39bf;
`;

const PanelContents = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2%;
  box-sizing: border-box;
  justify-content: space-between;
  height: ${({$panelHeight}) => $panelHeight - 15}px;
  @media only screen and (min-width: 850px) {
    padding: 0 3.5rem;
  }
  @media only screen and (max-width: 600px) {
    height: ${({$panelHeight}) => $panelHeight}px;
  }
`;

const SlideTitle = styled.div`
  color: #fff;
  font-size: 1.1rem;
  display: flex;
  position: relative;
  span {
    padding-right: 9px;
  }
`;

const Section = styled.div`
  display: flex;
  flex: 1;
`;

export const FullScreenMode = styled.div`
  fill: #fff;
  cursor: pointer;
`;

const SlideBarTabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const TabArea = styled.div`
  height: ${TabClickableArea}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
`;

const Tab = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 1px;
  background: ${({active, highestAnimationOrder, currentSlideOnClickAnimationOrder}) =>
    !active
      ? "#fff"
      : highestAnimationOrder !== 0
      ? `linear-gradient(45deg, #8f88d3 ${
          ((currentSlideOnClickAnimationOrder + 1) * 100) / (highestAnimationOrder + 1)
        }%, #fff ${
          ((highestAnimationOrder - (currentSlideOnClickAnimationOrder + 1)) * 100) /
          highestAnimationOrder
        }%)`
      : "#8481f8de"}; //#b2b5f0;
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  fill: #fff;
  cursor: pointer;
`;

// const SubTab = styled.div`
//   padding: 2px;
//   box-sizing: border-box;
//   background: #c7a6f2;
//   margin: 2px;
//   margin-right: 10px;
//   flex: 1;
// `;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideInfo: selectActiveSlideInfo(designTemplate),
    slides: selectTemplate(designTemplate),
    embedType: selectEmbeddingType(designTemplate),
    currentSlideOnClickAnimationOrder: selectCurrentSlideOnClickAnimationOrder(designTemplate),
    slideHighestAnimationOrder:
      selectSlideAnimationOrder(designTemplate).highestOrder,
  };
};

export default connect(mapStateToProps, {changeSlideByID})(SlideNavPanel);
