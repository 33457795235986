import React from "react";
import styled from "styled-components";
import { Title } from "../../shared/reuseStyles/Title";

const TextDecoration = ({onSelect}) => {

  const handleSelect = (value) => {
    onSelect(value);
  };

  return (
    <>
      <Title>Decoration</Title>
      <div style={{flex: 1, display: "flex"}}>
        <WrapText onClick={() => handleSelect("none")}>U</WrapText>

        <WrapText onClick={() => handleSelect("line-through")} style={{textDecoration: 'line-through', fontStyle: 'bold'}}>U</WrapText>

        <WrapText onClick={() => handleSelect("underline")} style={{textDecoration: 'underline', fontStyle: 'bold'}}>U</WrapText>
      </div>
    </>
  );
};

const WrapText = styled.p`
  font-size: 18px;
  color: ${({theme}) => theme.deskSecondaryTxt};
  cursor: pointer;
  margin: 0 17px;
  &:first-child {
    margin-left: 8px;
  }
  &:last-child {
    margin-right: 8px;
  }
`;

export default TextDecoration;
