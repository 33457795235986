import React, {useEffect, useState} from "react";
import {UserAuth} from "../../../packages/oat-auth";
import {Auth} from "@aws-amplify/auth";
import {useNavigate} from "react-router-dom";
import {updateUserAuthStatus} from "../../../redux/user/actions/authUser.action";
import {connect} from "react-redux";
import {selectUserWorkspaceForRedirect} from "../../../redux/user/authUser";

const SignIn = ({updateUserAuthStatus, redirectWorkspaceID}) => {
  let navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) =>
        navigate(`/dashboard/${redirectWorkspaceID}/designs/home`)
      )
      .catch((err) => {});
  }, []);

  const onLogin = async (email, password) => {
    if (error.status) {
      setError({
        status: false,
        message: null,
      });
    }
    if (!processing) {
      const updatedEmail = email.value.trim();
      setProcessing(true);
      await Auth.signIn(updatedEmail, password.value)
        .then(async (user) => {
          const dynamoUsername = user.attributes["custom:acc-username"];
          await fetch(
            `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${dynamoUsername}/userInfo`
          )
            .then(async (res) => res.json())
            .then(async (data) => {
              setProcessing(false);
              await updateUserAuthStatus({
                cognito: user,
                dynamo: data.userItems,
              });
              navigate(`/dashboard/${data.workspaceID}/designs/home`);
              return window.location.reload();
            })
            .catch((e) => {
              setProcessing(false);
              setError({
                status: true,
                message: "Something went wrong. Please try again later.",
              });
            });
        })
        .catch((err) => {
          setProcessing(false);
          setError({
            status: true,
            message: err.message,
          });
        });
    }
  };

  return (
    <UserAuth
      shopName="Oat All"
      formType="login"
      context="Login"
      onSubmit={onLogin}
      processing={processing}
      error={error}
      //   onSignUp={(provider) => Auth.federatedSignIn({provider})}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    redirectWorkspaceID: selectUserWorkspaceForRedirect(state.authUser),
  };
};

export default connect(mapStateToProps, {updateUserAuthStatus})(SignIn);
