import {useState, useEffect} from "react";
import {
  getClosestNumber,
  getClosestNumberWithNegValues,
  getFactors,
} from "../common/getFactors";

export function fixFloatingPoint(value) {
  try {
    const result = Number.parseFloat(value.toPrecision(15));
    if (Number.isFinite(result)) {
      return result;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

function getDigitsNumber(x) {
  return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
}

export function getRemainingDigitsLength(number) {
  const value = number.toString();
  const indexPosition = value.toString().indexOf(".");
  const remaingPoints = value.slice(
    indexPosition === -1 ? 1 : indexPosition + 1,
    value.length
  );
  return remaingPoints.length;
}

export function containsDigit(number) {
  const value = number.toString();
  const indexPosition = value.toString().indexOf(".");
  return indexPosition === -1 ? false : true;
}

export function useScaleLine({
  highestPositiveValue = 0,
  lowestNegativeValue = 0,
}) {
  // chart highest value's closest point, eg 7 -> transform to 8
  const [chartSpectrum, setChartSpectrum] = useState({
    positive: 0,
    negative: 0,
  });
  const [scaleLine, setScaleLine] = useState({lines: 0, total: 0, acc: 0});
  const [closestNumber, setClosestNumber] = useState({
    positive: 0,
    negative: 0,
  });

  // check if contains digits
  const hasPositiveDigits = getDigitsNumber(highestPositiveValue);
  const hasNegaitveDigits = getDigitsNumber(lowestNegativeValue);

  // important
  // if all values are (+) use the first case
  // else if all are (-) use the latter
  const biggerValue = Math.abs(
    lowestNegativeValue === 0
      ? highestPositiveValue > lowestNegativeValue
        ? highestPositiveValue
        : lowestNegativeValue
      : lowestNegativeValue < highestPositiveValue
      ? lowestNegativeValue
      : highestPositiveValue
  ).toString();

  const indexLocation = biggerValue.indexOf(".");
  const remainingDigitsLength = getRemainingDigitsLength(biggerValue);

  // 1.34 -> remaining digits legth -> 2
  const digitsLength = indexLocation === -1 ? 0 : remainingDigitsLength;
  const powDigitsLength = Math.pow(10, digitsLength);

  const positiveValue =
    highestPositiveValue * hasPositiveDigits === 0
      ? highestPositiveValue
      : powDigitsLength * highestPositiveValue;
  const negativeValue =
    lowestNegativeValue * hasNegaitveDigits === 0
      ? lowestNegativeValue
      : powDigitsLength * lowestNegativeValue;

  useEffect(() => {
    // no negative values
    if (negativeValue >= 0) {
      // eg turn 6 -> 10 | for neg, turn -4 to -5
      const positiveClosestNumber = getClosestNumber(positiveValue);
      setClosestNumber({
        positive: positiveClosestNumber,
        negative: 0,
      });
    }
    // all negative values
    else if (negativeValue < 0 && positiveValue === 0) {
      const negativeClosestNumber = getClosestNumber(Math.abs(negativeValue));
      setClosestNumber({
        positive: 0,
        negative: negativeClosestNumber,
      });
    }
    // negative along with positive values
    else if (negativeValue < 0) {
      const {positive, negative} = getClosestNumberWithNegValues(
        positiveValue,
        Math.abs(negativeValue)
      );

      setClosestNumber({
        positive,
        negative,
      });
    }
  }, [highestPositiveValue, lowestNegativeValue]);

  useEffect(() => {
    const axisValues = getFactors(
      closestNumber.positive,
      Math.abs(closestNumber.negative)
    );

    setChartSpectrum({
      positive: closestNumber.positive / powDigitsLength,
      negative: closestNumber.negative / powDigitsLength,
    });

    if (axisValues.length > 0) {
      setScaleLine({
        lines: axisValues[0].lines,
        total: Math.round(axisValues[0].total) / powDigitsLength,
        acc: Math.round(axisValues[0].acc) / powDigitsLength,
      });
    }
  }, [closestNumber]);

  return {
    chartSpectrum,
    scaleLine,
    digitsLength,
  };
}
