import React from "react";
import {Layout, WrapHeader, maxWidthPanel} from "../FeatureList";
import AnimateIn from "../../../../oat-ui/animation/Animate";
import {HomeHeader} from "../FeaturesShowcase2";
import styled from "styled-components";
import {homePageBackground2} from "../styles/layoutStyles";
import Check from "../../../../oat-svg-icons/icons/Check";
import { Section, WrapImage, CheckSvg, ContentList, wrapImgStyles } from "./homeCoreStyles";

const EditTemplates = () => {
  return (
    <Layout style={{background: homePageBackground2}}>
      <Section>
        {/* <WrapHeader>
          <AnimateIn>
            <HomeHeader><span>2 -</span>Customize Templates</HomeHeader>
          </AnimateIn>
        </WrapHeader> */}

        <Wrapper>
          <AnimateIn>
            <Item>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {/* <ShortTitle>
                  Easily share as link or export to desire format
                </ShortTitle> */}
                <ContentList>
                  <HomeHeader><span>2 -</span>Customize Templates</HomeHeader>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <div>
                        <p className="feature">Featured Elements</p>
                        <p className="feature-description">
                          Stock photos, illustrations, charts, tables & more
                        </p>
                      </div>
                    </div>
                  </>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <div>
                        <p className="feature">Easily Animate</p>
                        <p className="feature-description">
                          Smooth out your designs with slick animations
                        </p>
                      </div>
                    </div>
                  </>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <div>
                        <p className="feature">Collaborate as Team</p>
                        <p className="feature-description">Assign tasks and work in real-time</p>
                      </div>
                    </div>
                  </>
                </ContentList>
              </div>
            </Item>
          </AnimateIn>

          <AnimateIn>
            <ImageItem
              loading="lazy"
              style={{
                background: `#fadcf0`,
                backgroundImage: `url(https://dnaeh50kru9rd.cloudfront.net/backgrounds/sky-blue-1.jpg)`,
                width: `100%`,
                height: `100%`,
                backgroundSize: `cover`
              }}
            >
              <WrapImage>
                <Image
                  loading="lazy"
                  src={`https://dnaeh50kru9rd.cloudfront.net/home-page/feature-guide-section/team-collaboration-illustration.png`}
                  alt="template-sharing-icon"
                  style={{ borderRadius: 10 }}
                />
              </WrapImage>
            </ImageItem>
          </AnimateIn>
        </Wrapper>
      </Section>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  ${maxWidthPanel};
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    flex-direction: column-reverse;
  }
`;

const Item = styled.div`
  ${wrapImgStyles};
  @media only screen and (min-width: 901px) {
    margin-right: 24px;
    justify-content: flex-start;
  }
`;

const ImageItem = styled.div`
  ${wrapImgStyles};
  padding: 94px 32px;
  @media only screen and (max-width: 900px) {
    padding: 42px 28px;
  }
`;

const Image = styled.img`
  animation: edit-templates-animate 5s linear infinite alternate;
  @-webkit-keyframes edit-templates-animate {
    0% {
      transform: rotate(-1deg) translateY(5%);
    }
    100% {
      transform: rotate(2deg) translateY(-8%);
    }
  }
  @keyframes edit-templates-animate {
    0% {
      transform: rotate(-1deg) translateY(5%);
    }
    100% {
      transform: rotate(2deg) translateY(-8%);
    }
  }
`;

export default EditTemplates;
