import React, { useEffect, useState, useRef } from "react";
import styled, {css} from "styled-components";
import {blue5} from "../../../oat-color-variables";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {defaultMaxWidthDesktop, defaultMaxWidthLaptop, defaultMaxWidthLaptop2, layoutPadding} from "../layoutVariables";
import {WrapHeader} from "./FeatureList";
import {HomeHeader} from "./FeaturesShowcase2";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";
import EmbedMode from "../../../oat-design-template/containers/EmbedMode";

const borderRadius = 12;
const EmbedTemplate = () => {
  const windowSize = getWindowSize();
  const layoutRef = useRef();
  const [embedSectionWidth, setEmbedSectionWidth] = useState(0);

  useEffect(() => {
    if (layoutRef?.current) {
      setTimeout(() => {
        setEmbedSectionWidth(layoutRef?.current?.offsetWidth)
      }, 25)
    }
  },[windowSize.width]);

  return (
    <AnimateIn>
      <ExportContainer >
        <WrapHeader ref={layoutRef} style={{maxWidth: 1040, width: '100%', textAlign: 'center'}}>
          <HomeHeader style={{ padding: window.innerWidth > 800 ? '0.5em 0 0.7em' : undefined }}>See it in Action!</HomeHeader>
        </WrapHeader>
        {/* <EmbedSection width={windowSize.width}>
          <iframe
            title="Oat All Features"
            src="https://www.oatall.com/p/n5ZhVWPfuT?embed=true"
            allow="fullscreen"
            width={560}
            height={315}
            loading="lazy"
          ></iframe>
        </EmbedSection> */}
        <EmbedMode transition={null} tempID="n5ZhVWPfuT" panelWidth={embedSectionWidth} /> 

        <div style={{zIndex: 2222}} />
      </ExportContainer>
    </AnimateIn>
  );
};

const ExportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4.2em ${layoutPadding.desktop} 4.2em;
  box-sizing: border-box;
  @media only screen and (min-width: 601px) and (max-width: 1000px) {
    padding: 4.2em ${layoutPadding.desktop} 4.2em;
  }
  @media only screen and (max-width: 600px) {
    padding: 2.2em ${layoutPadding.mobile};
  }
`;

export const embedWindowPanelSize = css`
  @media only screen and (max-width: 1079px) and (min-width: 800px) {
    max-width: ${({width}) => width - 140}px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1400px) {
    max-width: ${defaultMaxWidthLaptop2 + 80}px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    max-width: 1040px;
  }
  @media only screen and (min-width: 1501px) and (max-width: 1600px) {
    max-width: 1140px;
  }
  @media only screen and (min-width: 1601px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
  @media only screen and (max-width: 600px) {
    padding-top: 48px;
  }
  @media screen and (orientation: portrait) {
    max-width: 900px;
  }
`;

export const EmbedSection = styled.div`
  position: relative;
  width: 100%;
  z-index: 9999;
  overflow: hidden;
  border-radius: ${borderRadius}px;
  padding-top: 70px;
  box-shadow: 0px 0px 3px 0px #e0d9d9;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: ${borderRadius}px;
    overflow-y: hidden;
  }
  &::after {
    display: block;
    content: "";
    background-color: ${blue5};
    padding-bottom: 56.25%;
  }
  ${embedWindowPanelSize};
`;

export default EmbedTemplate;