import {
  FETCH_MEDIA_INIT,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_ERROR,
  UPLOAD_MEDIA_INIT,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  TOGGLE_MEDIA_MODAL,
  REMOVE_IMAGE_ITEM,
} from "./actionTypes";

export function getOatAllMedia(url) {
  return async (dispatch) => {
    dispatch(fetchMediaInit());
    try {
      await fetch(url)
        .then((res) => res.json())
        .then((data) => {
          dispatch(fetchMediaSuccess(data.items, data.paginationToken));
        })
        .catch((e) => dispatch(fetchMediaErr(e)));
    } catch (e) {
      dispatch(fetchMediaErr(e));
    }
  };
}

export function fetchMediaInit() {
  return {
    type: FETCH_MEDIA_INIT,
  };
}

export function fetchMediaSuccess(payload, paginationToken) {
  return {
    type: FETCH_MEDIA_SUCCESS,
    payload,
    paginationToken
  };
}

export function fetchMediaErr(error) {
  return {
    type: FETCH_MEDIA_ERROR,
    error,
  };
}

export function removeImageItem(id) {
  return {
    type: REMOVE_IMAGE_ITEM,
    id
  };
}


// upload
export function uploadAdminMedia(postUrl, event, imgInfo) {
  return async (dispatch) => {
    dispatch(uploadAdminMediaInit());
    try {
      //15 MB
      if (event.target.files[0].size > 15728640) {
        dispatch(
          uploadAdminMediaError("File size cannot be bigger than 15MB.")
        );
        event.target.value = null;
      } else {
        const {createdBy, uploadedBy, imgType, imgSize} = imgInfo;
        const imageFormat = event.target.files[0].type.split("image/")[1];
        const {url, fields, uploadedItem, error} = await fetch(postUrl, {
          method: "POST",
          body: JSON.stringify({
            contentType: event.target.files[0].type,
            imgType,
            owner: "oat-all",
            createdBy,
            uploadedBy,
            type: 
              (imageFormat === 'svg' || imageFormat === 'svg+xml') ?
              "icon" : "image", //"image",
            imgSize,
            ...imgInfo,
          }),
        })
          .then((response) => response.json())
          .catch((e) => {
            dispatch(uploadAdminMediaError(e))
          });

        const data = {
          bucket: "oat-images-icons",
          ...fields,
          "Content-Type": event.target.files[0].type,
          file: event.target.files[0],
        };

        if (!error) {
          const formData = new FormData();
          for (const name in data) {
            formData.append(name, data[name]);
          }

          await fetch(url, {
            method: "POST",
            body: formData,
          })
            .then((data) => dispatch(uploadAdminMediaSuccess(uploadedItem)))
            .catch((e) => {
              dispatch(uploadAdminMediaError(e))
            });
        }
      }
    } catch (e) {
      dispatch(uploadAdminMediaError(e));
    }
  };
}

export function uploadAdminMediaInit() {
  return {
    type: UPLOAD_MEDIA_INIT,
  };
}

export function uploadAdminMediaSuccess(payload) {
  return {
    type: UPLOAD_MEDIA_SUCCESS,
    payload,
  };
}

export function uploadAdminMediaError(error) {
  return {
    type: UPLOAD_MEDIA_ERROR,
    error,
  };
}

// modal
export function toggleAdminMediaModal(status, modalType) {
  return {
    type: TOGGLE_MEDIA_MODAL,
    status,
    modalType,
  };
}
