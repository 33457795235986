import React from "react";
import RangeSlider from "../../shared/inputs/RangeSlider";
import {blurMaxRange} from "../../shared/rangeValues.js/rangeValues";

const EditImageBlur = ({blur, stableValue, onChange}) => {
  const onUpdateValue = (value) => {
    onChange((value * blurMaxRange) / 100);
  };
  return (
    <RangeSlider
      title="Blur"
      value={parseInt((blur * 100) / blurMaxRange)}
      max={100}
      min={0}
      step={1}
      stableValue={parseInt((stableValue * 100) / blurMaxRange)}
      onItemChange={onUpdateValue}
      fieldType="blur"
    />
  );
};

export default EditImageBlur;
