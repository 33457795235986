import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {blue2} from "../../../../oat-color-variables";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {changeSlideByID} from "../../../../oat-design-template/store/actions/template/slide.action";
import {calcCanvasWidth} from "../../../utils/calcCanvasWidth";
import {selectSectionList} from "../../../../oat-design-template/store/selectors/template/groupedSection.selector";
import {selectTemplateTitle} from "../../../../oat-design-template/store/selectors/template/template.selector";

const DisplayItem = ({content, numberList, onSelect}) => {
  const handleClick = () => {
    onSelect(content);
  };

  return (
    <Item onClick={handleClick}>
      <span>{numberList}.</span>
      <div>{content.title}</div>
    </Item>
  );
};

const ProfileContentInfo = ({changeSlideByID, sectionList, tempTitle}) => {
  const windowSize = getWindowSize();
  const canvasWidth = calcCanvasWidth(windowSize.width);

  const handleSlideChange = (item) => {
    changeSlideByID(item.startingSlideNo);
  };

  return (
    <Layout>
      <Wrapper style={{width: canvasWidth}}>
        <ContentDetails>
          <Title>{tempTitle}</Title>
          <Description>
            The National Health Service is the publicly funded healthcare system
            in England, and one of the four National Health Service systems in
            the United Kingdom. It is the second largest single-payer healthcare
            system in the world after the Brazilian Sistema Único de Saúde.
          </Description>
        </ContentDetails>
        <ListSection>
          {sectionList &&
            sectionList.map((content, i) => (
              <DisplayItem
                key={i}
                content={content}
                numberList={i + 1}
                onSelect={handleSlideChange}
              />
            ))}
        </ListSection>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  @media only screen and (min-width: 850px) {
    margin-top: 54px;
  }
  @media only screen and (min-width: 601px) {
    padding: 0 2rem;
  }
  @media only screen and (max-width: 600px) {
    padding: 0 1.2rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 155px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ContentDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 62px;
`;

const Title = styled.div`
  font-size: 2.1rem;
  font-weight: 700;
  color: ${blue2};
  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
    margin-top: 12px;
  }
`;

const Description = styled.div`
  font-size: 1.2rem;
  padding: 16px 0;
  line-height: 2.2rem;
`;

const ListSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Item = styled.div`
  padding: 12px 0;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1.2rem;
  span {
    padding-right: 10px;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    sectionList: selectSectionList(designTemplate),
    tempTitle: selectTemplateTitle(designTemplate),
  };
};

export default connect(mapStateToProps, {changeSlideByID})(ProfileContentInfo);
