import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  Comment,
  Duplicate,
  Finish,
  Notes,
  Trash,
  Export,
} from "../../../../oat-svg-icons";
import {
  selectActiveSlideID,
  selectActiveSlideInfo,
} from "../../../store/selectors/template/slide.selector";
import {useSocket} from "../../../webSocket/useSocket";
import {
  cloneSlide,
  deleteSlide,
} from "../../../store/actions/template/slide.action";
import {closeDrawer, openDrawer} from "../../../store/actions/layout.action";
import {toggleSlideWorkingStatus} from "../../../store/actions/template/slide.action";
import {redMessage} from "../../../themes";

const MobileSlideActions = ({
  slideID,
  closeDrawer,
  cloneSlide,
  deleteSlide,
  toggleSlideWorkingStatus,
  markedAsDone,
  openDrawer,
}) => {
  const {emitSocketEvents} = useSocket() || {};

  const handleDuplicate = async () => {
    await cloneSlide(slideID);
    await closeDrawer();

    const item = {type: "", value: slideID};
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "duplicate-slide", item});
    }
  };

  const handleDelete = async () => {
    await deleteSlide(slideID);
    await closeDrawer();
    const item = {type: "", value: slideID};
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "delete-slide", item});
    }
  };

  const handleMarkAsDone = async () => {
    await toggleSlideWorkingStatus(slideID);
    await closeDrawer();
    const item = {type: "", value: slideID};
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "changed-slide-status", item});
    }
  };

  const openCommentSection = () => {
    const height = (window.innerHeight * 85) / 100;
    openDrawer({type: "comments", height});
  };

  const openAddNoteSection = () => {
    const height = (window.innerHeight * 85) / 100;
    openDrawer({type: "add-notes", height});
  };

  const openExportImageSection = () => {
    openDrawer({type: "export-as-image", height: 220});
  };

  const openShareLinkSection = () => {
    openDrawer({type: "share-a-link", height: 200});
  };

  return (
    <Layout>
      <div className="drawer-option-section">
        <Item onClick={handleDuplicate}>
          <div className="option-icon">{Duplicate}</div>
          <div className="option-text">Clone Design</div>
        </Item>
        <Item onClick={handleDelete}>
          <div className="option-icon" style={{fill: redMessage}}>
            {Trash}
          </div>
          <div className="option-text" style={{color: redMessage}}>
            Delete Design
          </div>
        </Item>
      </div>

      <div className="drawer-option-section">
        <Item onClick={openCommentSection}>
          <div className="option-icon">{Comment}</div>
          <div className="option-text">Comment</div>
        </Item>
        <Item onClick={openAddNoteSection}>
          <div className="option-icon">{Notes}</div>
          <div className="option-text">Add Notes</div>
        </Item>
        <Item onClick={handleMarkAsDone}>
          <div
            style={{
              fill: markedAsDone ? "#7ff87f" : "#ffff5e",
            }}
            className="option-icon"
          >
            {Finish}
          </div>
          <div
            style={{color: markedAsDone ? "#7ff87f" : "#ffff5e"}}
            className="option-text"
          >
            {markedAsDone ? "Done" : "Mark As Done"}
          </div>
        </Item>
      </div>

      <div className="drawer-option-section">
        <Item onClick={openExportImageSection}>
          <div className="option-icon">{Export}</div>
          <div className="option-text">Export as Image</div>
        </Item>
        <Item onClick={openShareLinkSection}>
          <div className="option-icon">{Export}</div>
          <div className="option-text">Share a Link</div>
        </Item>
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 16px;
  box-sizing: border-box;
  .drawer-option-section {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    font-size: 1rem;
    background: ${({theme}) => theme.deskSecondaryBg};
    color: ${({theme}) => theme.deskPrimaryTxt};
    margin-bottom: 12px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 18px 18px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({theme}) => theme.deskGap}; // #5e5e5e
  &:last-child {
    border-bottom: 0px;
  }
  .option-icon {
    width: 26px;
    height: 26px;
    fill: ${({theme}) => theme.deskPrimaryTxt};
  }
  .option-text {
    padding-left: 15px;
    font-size: 1rem;
    font-weight: 700;
  }
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideID(state.designTemplate),
    markedAsDone: selectActiveSlideInfo(state.designTemplate).markedDone,
  };
};

export default connect(mapStateToProps, {
  openDrawer,
  cloneSlide,
  deleteSlide,
  closeDrawer,
  toggleSlideWorkingStatus,
})(MobileSlideActions);
