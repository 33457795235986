import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  selectActiveFieldProps,
  selectEnableTextEdit,
} from "../../../../../../store/selectors/fields.selector";
import {selectTooltipPopperStatus} from "../../../../../../store/selectors/layout/layout.selector";
import ChartTooltip from "./ChartTooltip";
import IconTooltip from "./IconTooltip";
import ShapeTooltip from "./ShapeTooltip";
import TextTooltip from "./TextTooltip";
import TooltipItemsHoc from "../tooltipHocs/TooltipItemsHoc";
import ImageTooltip from "./ImageTooltip";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import TableTooltip from "./TableTooltip";

const TooltipTypes = ({field, slideID, textEditing, popperOpen}) => {
  const active = {slideID, fieldID: field?.key};
  const [chartColorSet, setChartColorSet] = useState(1);

  useEffect(() => {
    if (field?.type === "chart") {
      setChartColorSet(
        field?.subtype === "pie" ||
          field?.subtype === "donut" ||
          field?.subtype === "gauge"
          ? field?.dataSeries?.length - 1
          : field?.dataSeries?.[0]?.length - 1
      );
    }
  }, [field?.type]);

  let displayView = "";
  switch (field?.type) {
    case "text":
      displayView = (
        <TextTooltip
          active={active}
          textEditing={textEditing}
          popperOpen={popperOpen}
        />
      );
      break;
    case "image":
      displayView = <ImageTooltip />;
      break;
    case "shape":
      displayView = (
        <ShapeTooltip
          active={active}
          styles={field?.styles}
          subtype={field?.subtype}
          popperOpen={popperOpen}
        />
      );
      break;
    case "icon":
      displayView = (
        <IconTooltip
          active={active}
          colorSet={field?.colorSet}
          popperOpen={popperOpen}
        />
      );
      break;
    case "chart":
      displayView = (
        <ChartTooltip
          active={active}
          colorSet={field?.colorSet}
          fontSize={field?.styles?.fontSize}
          popperOpen={popperOpen}
          noOfColorSet={chartColorSet}
        />
      );
      break;
    case "table":
      displayView = <TableTooltip active={active} popperOpen={popperOpen} />;
      break;
    default:
      displayView = <TooltipItemsHoc />;
  }

  return displayView;
};

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    field: selectActiveFieldProps(state.designTemplate),
    textEditing: selectEnableTextEdit(state.designTemplate),
    popperOpen: selectTooltipPopperStatus(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(TooltipTypes);
