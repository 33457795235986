export const calcGroupResize = ({
  field,
  initFieldValues,
  orgSize,
  newSize,
  newOffset,
}) => {
  const {size: initSize, offset: initOffset} = initFieldValues;
  const ratioWidth = newSize.w / orgSize.w;
  const ratioHeight = newSize.h / orgSize.h;
  // position of a field from the start of a group resize area
  const toGroupX = (newSize.w * initOffset.orgPercentX) / 100;
  const toGroupY = (newSize.h * initOffset.orgPercentY) / 100;

  const sizeNPosition = {
    size: {
      w: initSize.w * ratioWidth,
      h: initSize.h * ratioHeight,
    },
    pos: {
      x: toGroupX + newOffset.x,
      y: toGroupY + newOffset.y,
    },
  };

  if (field.type === "image") {
    const {imgSize} = initFieldValues;
    const imgWidth = (imgSize.w * initSize.w * ratioWidth) / initSize.w;
    const imgHeight = (imgSize.h * initSize.h * ratioHeight) / initSize.h;

    const initImgPos = initFieldValues.imgPos;
    const transX = (initSize.w * ratioWidth * initImgPos.x) / initSize.w;
    const transY = (initSize.h * ratioHeight * initImgPos.y) / initSize.h;

    return {
      ...sizeNPosition,
      imgPos: {
        x: transX,
        y: transY,
      },
      imgSize: {
        w: imgWidth,
        h: imgHeight,
      },
    };
  } else {
    return sizeNPosition;
  }
};
