import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import {Title} from "../../shared/reuseStyles/Title";
import DropDown from "../../../../../../ui/inputs/DropDown";
import {selectActiveField, selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {WrapColorBox} from "../common/WrapColorStyles";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import {getWindowSize} from "../../../../../../../oat-window-size/getWindowSize";

const types = [ 
  {display: "\\2022", value: "\\2022"},
  {display: "\u2611", value: "\u2611"},
  {display: "\u2714", value: "\u2714"},
  {display: "\u25B8", value: "\u25B8"},
  {display: "\u25A0", value: "\u25A0"},
  {display: "\u25C6", value: "\u25C6"},
  {display: "\u003E", value: "\u003E"},
];

const XScrollItem = ({option, onSelect, selected}) => {
  const handleSelect = () => {
    onSelect(option.value);
  };

  return(
    <Item style={{ background: selected ? "" : undefined }} onClick={handleSelect}>
      {option.display}
    </Item>
  )
};

const SelectUnOrderedListBullet = ({
  slideID,
  fieldID,
  updateFieldCollaboration,
  bulletType,
  bulletColor,
  updateFieldStyles,
  storeFieldUndoRedo
}) => {
  const windowSize = getWindowSize();
  const changeBulletType = async (item) => {
    const active = {slideID, fieldID};
    await updateFieldStyles({
      active, type: "bulletType", value: item,
    });
    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  };

  const changeBulletColor = async (item) => {
    const active = {slideID, fieldID};
    await updateFieldStyles({
      active, type: "bulletColor", value: item,
    });
    await updateFieldCollaboration();
  };

  const onColorDebounce = (value) => {
    const selection = window.getSelection();
    // only debounce on full color text change
    // partial text selection will not be debouncing
    // it will only emit updated content
    if (selection.isCollapsed) {
      storeFieldUndoRedo();
      updateFieldCollaboration();
    }
  };

  return (
    <>
      {/* <Wrapper style={{paddingBottom: "1.5rem"}}>
        <Title>Choose Bullet</Title>
        {windowSize.width >= 850 ?
          <WrapDropDown>
            <DropDown
              onSelect={changeBulletType}
              selected={bulletType}
              options={types}
              minWidth={90}
              overflowY="scroll"
              height={160}
              boxWidth={50}
            />
          </WrapDropDown>
        : <WrapXScroll> 
            {types && types.map((type, i) => {
              return <XScrollItem key={i} option={type} selected={bulletType} onSelect={changeBulletType} />
            })}
          </WrapXScroll>
        }
      </Wrapper> */}

      <WrapColorBox>
        <Title>Bullet Color</Title>
        <PopoverColorBox
          value={bulletColor}
          updateFieldStyles={changeBulletColor}
          onDebounce={onColorDebounce}
        />
      </WrapColorBox>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const Item = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-right: 14px;
  background: ${({theme}) => theme.deskSecondaryBg};
  padding: 0 7px;
  border-radius: 3px;
`;

const WrapXScroll = styled.div`
  display: flex;
  flex: 1; 
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
    bulletType: selectActiveFieldProps(state.designTemplate).styles.bulletType,
    bulletColor: selectActiveFieldProps(state.designTemplate).styles.bulletColor
  };
};

export default connect(mapStateToProps, {
  updateFieldCollaboration,
  updateFieldStyles,
  storeFieldUndoRedo
})(SelectUnOrderedListBullet);
