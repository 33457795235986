import React from "react";
import styled from "styled-components";
import {black1} from "../../../../oat-color-variables";
import {Button} from "../../../../oat-ui";
import {paleBlue} from "../../../userDashboardStyles";

const DisplayItem = ({member, onSelectMember}) => {
  const handleClick = () => {
    onSelectMember(member);
  };

  return (
    <Item onClick={handleClick}>
      <Field>{member.name}</Field>
      <Field>{member.email}</Field>
    </Item>
  );
};

const MemberList = ({onSelectMember, switchModal}) => {
  const displayAddMemberModal = () => {
    // openModal("add-member");
    switchModal("add-member");
  };

  const onSelectItem = (member) => {
    // openModal("member-details");
    onSelectMember(member);
  };

  const members = [];

  return (
    <Layout>
      <ItemList>
        <Header style={{paddingBottom: 6}}>
          <Field>Name</Field>
          <Field>Email</Field>
        </Header>
        {members &&
          members.map((member, i) => (
            <DisplayItem
              key={i}
              member={member}
              onSelectMember={onSelectItem}
            />
          ))}
      </ItemList>
      <AddMember>
        <WrapButton>
          <Button styles={{height: 42}} onClick={displayAddMemberModal}>
            Add Members
          </Button>
        </WrapButton>
      </AddMember>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemList = styled.div`
  height: 400px;
  overflow-y: scroll;
  font-size: 0.95rem;
  padding: 12px;
  box-sizing: border-box;
  color: ${black1};
`;

const AddMember = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 70px;
  border-top: 1px solid #dedede;
`;

const WrapButton = styled.div`
  width: auto;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin-right: 14px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 18px;
  border-bottom: 1px solid #dedede;
`;

const Item = styled.div`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 4px;
  &:hover {
    background: ${paleBlue};
  }
`;

const Field = styled.div`
  flex: 2;
  padding: 9px 0;
`;

export default MemberList;
