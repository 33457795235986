import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectActiveSlide} from "../../../store/selectors/template/slide.selector";
import {selectTemplateThemes} from "../../../store/selectors/template/theme.selector";
import {storeActionsUndoRedo} from "../../../store/actions/fields/undoRedo.action";
import {
  SvgChart,
  SvgIcon,
  SvgImage,
  SvgShape,
  SvgTable,
  SvgText,
  SvgVideo,
} from "../../../ui/svg/DesktopNavSvg";
import {createSlide} from "../../../store/actions/template/slide.action";
import DisplayTheme from "./DisplayTheme";
import {openDrawer, closeDrawer} from "../../../store/actions/layout.action";
import {createTable} from "../../../store/actions/fields/table.action";
import {selectTemplateSize} from "../../../store/selectors/template/template.selector";
import {useSocket} from "../../../webSocket/useSocket";
import {handleTableCreation} from "../../../utils/handleTableCreation";

const MobileCreateDrawer = ({
  themes,
  slideID,
  createSlide,
  createTable,
  openDrawer,
  storeActionsUndoRedo,
  tempSize,
  closeDrawer,
}) => {
  const {emitSocketEvents} = useSocket() || {};

  function onSelectType(type) {
    openDrawer({type, height: window.innerHeight - 110});
  }

  async function handleNewSlide(themeID) {
    createSlide({insertAfterSlideIndex: slideID, themeID});
    await closeDrawer();
  }

  async function onCreateTable() {
    const {key, size} = handleTableCreation(tempSize);
    createTable(slideID, key, size);
    storeActionsUndoRedo("create");
    const payload = {
      type: "table",
      value: {key, size},
    };
    if (emitSocketEvents) {
      await emitSocketEvents({actionType: "field-created", item: payload});
      await closeDrawer();
    }
  }

  return (
    <div className="drawer-container">
      <Wrapper style={{marginTop: 14}}>
        <div className="drawer-section-title">New Field</div>
        <div className="drawer-section-options">
          <WrapSvg onClick={() => onSelectType("to-create-text")}>
            {SvgText}
          </WrapSvg>
          <WrapSvg onClick={() => onSelectType("to-create-image")}>
            {SvgImage}
          </WrapSvg>
          <WrapSvg onClick={() => onSelectType("to-create-shape")}>
            {SvgShape}
          </WrapSvg>
          <WrapSvg onClick={() => onSelectType("to-create-icon")}>
            {SvgIcon}
          </WrapSvg>
          <WrapSvg onClick={() => onSelectType("to-create-chart")}>
            {SvgChart}
          </WrapSvg>
          <WrapSvg onClick={onCreateTable}>{SvgTable}</WrapSvg>
          <WrapSvg onClick={() => onSelectType("to-create-video")}>
            {SvgVideo}
          </WrapSvg>
        </div>
      </Wrapper>

      <Wrapper style={{marginBottom: 14}}>
        <div className="drawer-section-title">Templates & Layouts</div>
        <div
          onClick={() => onSelectType("choose-templates")}
          style={{
            padding: "8px 20px",
            background: "#3d3952",
            width: 160,
            borderRadius: 8,
            marginTop: 12,
            fontSize: "0.85rem",
          }}
        >
          Choose Templates
        </div>
      </Wrapper>

      <Wrapper>
        <div className="drawer-section-title">Choose theme for blank slide</div>
        <div className="drawer-section-options">
          {themes &&
            themes.map((theme) => (
              <DisplayTheme
                key={theme.id}
                theme={theme}
                onCreate={handleNewSlide}
              />
            ))}
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0 0 0.8rem;
  .drawer-section-title {
    font-size: 1rem;
    font-weight: 700;
    color: ${({theme}) => theme.deskPrimaryTxt};
  }
  .drawer-section-options {
    display: flex;
    overflow-x: scroll;
    margin: 22px 0;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    div {
      width: 30px;
      height: 30px;
      flex: 0 0 auto;
      background: ${({theme}) => theme.deskPrimaryTxt};
      border-radius: 50%;
      margin-right: 14px;
      padding: 10px;
    }
  }
`;

const WrapSvg = styled.div`
  stroke: #52477f;
  fill: transparent;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempSize: selectTemplateSize(designTemplate),
    themes: selectTemplateThemes(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  createTable,
  createSlide,
  openDrawer,
  closeDrawer,
  storeActionsUndoRedo,
})(MobileCreateDrawer);
