import React, {useState} from "react";
import styled from "styled-components";
import {Modal} from "../../../../../../oat-ui";
import {
  ContentSection,
  Header,
  FieldName,
  Footer,
  BottomSection,
  Cancel,
  WrapField,
  TextField,
} from "../../../../reuse/dashboardUI/modalStyles";
import {Button, ButtonSpinner, ShowError} from "../../../../../../oat-ui";
import {black1} from "../../../../../../oat-color-variables";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";

const ShareAsGuest = ({item, username, tempID, close}) => {
  const [guestEmail, setGuestEmail] = useState("");
  const [accessLevel, setAccessLevel] = useState("read-only");
  const [error, setError] = useState({status: false, message: null});
  const [submiting, setSubmiting] = useState(false);
  const [currentViewTab, setCurrentViewTab] = useState("invite-guest-form");

  const updateGuestEmail = (e) => {
    const {value} = e.target;
    setGuestEmail(value);
  };

  const handleSubmit = async () => {
    if (!submiting) {
      if (
        guestEmail.length < 4 ||
        guestEmail.indexOf("@") === -1 ||
        guestEmail.indexOf(".") === -1
      ) {
        setError({
          status: true,
          message: "Please enter a valid email address.",
        });
      } else {
        setSubmiting(true);
        if (error.status) {
          setError({
            status: false,
            message: null,
          });
        }

        await fetch(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${item.id}/guests`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sharedUserEmail: guestEmail,
              accessLevel,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              setGuestEmail("");
              setAccessLevel(null);
              close();
            } else {
              setError({
                status: true,
                message: data.response.message,
              });
            }
            setSubmiting(false);
          })
          .catch((error) => {
            setError({
              status: true,
              message: "Something went wrong",
            });
            setSubmiting(false);
          });
      }
    }
  };

  const changeGuestListView = () => {
    setCurrentViewTab("guest-list");
  };

  const changeInviteGuestormView = () => {
    setCurrentViewTab("invite-guest-form");
  };

  const onSelectReadOnlyAccessLevel = () => {
    setAccessLevel('read-only');
  };

  const onSelectReadWriteAccessLevel = () => {
    setAccessLevel('read-write');
  };

  return (
    <Modal width={450} height="auto" close={close}>
      {
        currentViewTab === "invite-guest-form" ? (
          <div>
            <ContentSection>
              <Header>Share as Guest</Header>

              <WrapField style={{margin: 0}}>
                <FieldName>Guest Email</FieldName>

                <WrapField style={{flex: 1, margin: 0}}>
                  <TextField
                    value={guestEmail}
                    onChange={updateGuestEmail}
                    autoFocus
                  />
                </WrapField>
              </WrapField>

              <WrapField style={{alignItems: 'flex-start'}}>
                <FieldName>Sharing Type</FieldName>

                <WrapRadio>
                  <Radio onClick={onSelectReadOnlyAccessLevel}>
                    <RadioButton active={accessLevel === "read-only"}>
                      <div />
                    </RadioButton>
                    <span>Read Only</span>
                  </Radio>
                  <Radio onClick={onSelectReadWriteAccessLevel}>
                    <RadioButton active={accessLevel === "read-write"}>
                      <div />
                    </RadioButton>
                    <span>Read Write</span>
                  </Radio>
                </WrapRadio>
              </WrapField>

              {/* <GuestList onClick={changeGuestListView}>Guest List</GuestList> */}

              {error.status ? <ShowError message={error.message} /> : undefined}
            </ContentSection>

            <Footer
              style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}
            >
              <BottomSection
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Cancel onClick={close}>Cancel</Cancel>
                <BottomSection>
                  <Button
                    styles={{width: 100}}
                    onClick={handleSubmit}
                    disabled={submiting}
                  >
                    {!submiting ? (
                      "Share"
                    ) : (
                      <ButtonSpinner width={15} height={15} color={"#fff"} />
                    )}
                  </Button>
                </BottomSection>
              </BottomSection>
            </Footer>
          </div>
        ) : undefined
        // <div>
        //   <ContentSection>
        //     <Header>Guest List</Header>

        //     {error.status ? <ShowError message={error.message} /> : undefined}
        //   </ContentSection>

        //   <Footer
        //     style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}
        //   >
        //     <BottomSection
        //       style={{
        //         width: "100%",
        //         display: "flex",
        //         justifyContent: "flex-start",
        //         alignItems: "center",
        //       }}
        //     >
        //       <Cancel onClick={changeInviteGuestormView}>Back</Cancel>
        //     </BottomSection>
        //   </Footer>
        // </div>
      }
    </Modal>
  );
};

const GuestList = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${black1};
  font-size: 0.85rem;
  margin-bottom: 16px;
  cursor: pointer;
`;

const WrapRadio = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Radio = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;
`;

const RadioButton = styled.div`
  width: 16px;
  height: 16px;
  background: #fff;
  margin-right: 12px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 0px #7470f4;
  div {
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${({active}) => (active ? "#a5a7ff" : "#d1d1d1")};
    border-radius: 50%;
  }
`;

const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
  };
};

export default connect(mapStateToProps, null)(ShareAsGuest);
