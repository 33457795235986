import React from "react";
import styled from "styled-components";
import Overlay from "../../../../desktop/image/Overlay";
import ImageShadow from "../../../../desktop/image/ImageShadow";
import {selectActiveSlide} from "../../../../../../../../store/selectors/template/slide.selector";
import {
  selectActiveFieldProps,
  selectCachedFieldStyles,
} from "../../../../../../../../store/selectors/fields.selector";
import {connect} from "react-redux";
import AdjustImage from "../../../../desktop/image/AdjustImage";
import ImageFilters from "../../../../desktop/image/ImageFilters";
import FlipImg from "../../../../desktop/image/FlipImg";
import {updateFieldStyles} from "../../../../../../../../store/actions/fields/common.action";
import RangeSlider from "../../../../shared/inputs/RangeSlider";
import EditImageBlur from "../../../../desktop/image/EditImageBlur";
import ImageSelection from "../../../../../../modal/create/uploadImage/ImageSelection";
import ImageFrames from "../../../../desktop/image/ImageFrames";
import EditOpacity from "../../../../desktop/common/EditOpacity";
import EditRotate from "../common/EditRotate";
import ZIndex from "../../../../shared/ZIndex";
import ApplyAnimations from "../../../../desktop/animations/ApplyAnimations";

const EditImageProperties = ({
  propsType,
  slideID,
  activeField,
  stableStyles,
  updateFieldStyles,
}) => {
  let displayView = null;
  const {styles} = activeField || {};

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const onUpdateBorderRadius = (value) => {
    handleStyleUpdate("borderRadius", Number(value));
  };

  const onUpdateBlur = (value) => {
    handleStyleUpdate("blur", value);
  };

  switch (propsType) {
    case "upload-image":
      displayView = <ImageSelection />;
      break;
    case "overlay":
      displayView = (
        <LayoutWrapper>
          <Overlay
            enableOverlay={styles?.enableOverlay}
            activeField={activeField?.key}
            color={styles?.overlayColor}
            degree={styles?.overlayDegree}
            type={styles?.overlayType}
            stableStyles={stableStyles}
          />
        </LayoutWrapper>
      );
      break;
    case "shadow":
      displayView = (
        <LayoutWrapper>
          <ImageShadow styles={styles} stableStyles={stableStyles} />
        </LayoutWrapper>
      );
      break;
    case "effects":
      displayView = (
        <LayoutWrapper>
          <AdjustImage styles={styles} stableStyles={stableStyles} />
        </LayoutWrapper>
      );
      break;
    case "filters":
      displayView = (
        <LayoutWrapper>
          <ImageFilters />
        </LayoutWrapper>
      );
      break;
    case "flip":
      displayView = (
        <FlipImg
          scaleX={styles?.scaleX}
          scaleY={styles?.scaleY}
          activeField={activeField}
        />
      );
      break;
    case "border":
      displayView = (
        <RangeSlider
          title="Border Radius"
          value={styles?.borderRadius}
          min={0}
          max={100}
          step={1}
          stableValue={stableStyles?.borderRadius}
          onItemChange={onUpdateBorderRadius}
          fieldType="borderRadius"
        />
      );
      break;
    case "opacity":
      displayView = <EditOpacity value={styles?.opacity} />;
      break;
    case "blur":
      displayView = (
        <EditImageBlur
          onChange={onUpdateBlur}
          blur={styles?.blur}
          stableValue={stableStyles?.blur}
        />
      );
      break;
    case "frames":
      displayView = <ImageFrames />;
      break;
    case "rotate":
      displayView = <EditRotate value={styles?.rotate} />;
      break;
    case "layer":
      displayView = <ZIndex />;
      break;
    case "animation":
      displayView = <ApplyAnimations />;
      break;
    default:
  }

  return displayView;
};

const LayoutWrapper = styled.div`
  padding-bottom: 2em;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    stableStyles: selectCachedFieldStyles(designTemplate),
  };
};

export default connect(mapStateToProps, {updateFieldStyles})(
  EditImageProperties
);
