import React, {useState} from "react";
import styled from "styled-components";
import {Spinner, BottomUpDrawer} from "../../../oat-ui";
import {darkGrey1} from "../../../oat-color-variables";
import {getWindowSize} from "../../../oat-window-size";
import ViewProfileTemplate from "./ViewProfileTemplate";
import { useNavigate, useParams } from "react-router-dom";

const DisplayItem = ({item, onSelect}) => {
  const [onFinishedLoading, setOnFinishedLoading] = useState(false);

  const handleClick = () => {
    onSelect(item);
  };

  const finishedLoading = () => {
    setOnFinishedLoading(true);
  }

  return (
    <Item onClick={handleClick}>
      <Image 
        style={{
          boxShadow: onFinishedLoading ? `0px 0px 2px 1px #dcdcdc` : undefined
        }} 
      onLoad={finishedLoading} src={item.image} alt="" loading="lazy" />
    </Item>
  );
};

const ProfileTemplates = ({templates, loading}) => {
  const [viewDesign, setViewDesign] = useState({
    openDrawer: false,
    itemID: null,
  });
  const windowSize = getWindowSize();
  let navigate = useNavigate();
  let {username} = useParams();

  const onViewDesign = (item) => {
    navigate(`/u/${username}/${item.tempID}`);
    // setViewDesign({
    //   openDrawer: true,
    //   itemID: item.id,
    // });
  };

  const closeDrawer = (item) => {
    setViewDesign({
      openDrawer: false,
      itemID: null,
    });
  };

  return (
    <Wrapper>
      {!loading ? (
        templates && templates.length > 0 ? (
          <WrapItems>
            {templates.map((template, i) => (
              <DisplayItem item={template} key={i} onSelect={onViewDesign} />
            ))}
          </WrapItems>
        ) : undefined
      ) : (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      )}
      {!loading && templates && templates.length === 0 ? (
        <NothingToShow>There is nothing here.</NothingToShow>
      ) : undefined}

      {/* <BottomUpDrawer
        open={viewDesign.openDrawer}
        height={
          windowSize.width > 600 ? windowSize.height - 40 : windowSize.height
        }
        close={closeDrawer}
      >
        <ViewProfileTemplate />
      </BottomUpDrawer> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex: 3;
  margin: 18px 0;
`;

const WrapItems = styled.div`
  columns: 3;
  margin-bottom: 66px;
  grid-gap: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  @media only screen and (min-width: 1100px) {
    grid-gap: 20px;
    margin-bottom: 32px;
  }
  @media only screen and (min-width: 601px) and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 32px;
    grid-template-columns: none;
    grid-gap: 14px;
  }
`;

const Item = styled.div`
  font-size: 1rem;
  display: inline-block;
  padding-bottom: 0px;
  box-sizing: content-box;
  cursor: pointer;
  @media only screen and (max-width: 1100px) {
    padding-bottom: 12px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
`;

const WrapSpinner = styled.div`
  width: 100%;
  height: 100%;
  min-height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NothingToShow = styled.div`
  min-height: 170px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: ${darkGrey1};
`;

export default ProfileTemplates;
