import {
  GET_DASHBOARD_INIT,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  OPEN_DASHBOARD_MODAL,
  CLOSE_DASHBOARD_MODAL,
  LOAD_FOLDERS_AND_WSINFO,
} from "./actionTypes";

export function loadFoldersAndWorkspace(payload) {
  return {
    type: LOAD_FOLDERS_AND_WSINFO,
    payload,
  };
}

export function getDashboardInit(paginate) {
  return {
    type: GET_DASHBOARD_INIT,
    paginate
  };
}

export function getDashboardSuccess(payload, paginate) {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload,
    paginate
  };
}

export function getDashboardErr(error) {
  return {
    type: GET_DASHBOARD_ERROR,
    error: "Something went wrong. Please try again later.",
  };
}

export const fetchDashboardItems = ({api, paginate}) => {
  return async (dispatch) => {
    dispatch(getDashboardInit(paginate));
    try {
      await fetch(api, {cache: "no-cache"})
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            dispatch(getDashboardSuccess(data.response, paginate));
          } else {
            dispatch(getDashboardErr(data.response));
          }
        })
        .catch((e) => {
          dispatch(getDashboardErr(e));
        });
    } catch (e) {
      dispatch(getDashboardErr(e));
    }
  };
};

// toggle modal
export function openDashboardModal(payload) {
  return {
    type: OPEN_DASHBOARD_MODAL,
    payload,
  };
}

export function closeDashboardModal() {
  return {
    type: CLOSE_DASHBOARD_MODAL,
  };
}
