export function tempListInit() {
  return function (state, action) {
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        templates: action.paginate ? state.templateSelection.templates : [],
        selectedTempID: null,
        activeTab: "template-collection",
        loading: true,
      },
    };
  };
}

export function tempListSuccess() {
  return function (state, action) {
    let newTemplates = [];
    if (action.paginate) {
      newTemplates = [
        ...state.templateSelection.templates,
        ...action.templates
      ]
    } else {
      newTemplates = action.templates
    }
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        templates: newTemplates,
        userMadeTemplates: action.userMadeTemplates, // either user or oat templates
        loading: false,
        lastEvaluatedKey: action.lastEvaluatedKey,
      },
    };
  };
}

export function tempListError() {
  return function (state, action) {
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        loading: false,
        error: action.error,
      },
    };
  };
}

export function designListInit() {
  return function (state, action) {
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        selectedTempID: action.selectedTempID,
        activeTab: "slides-from-template-list",
        loading: true,
        designs: [],
        error: {
          status: false,
          message: null,
        },
      },
    };
  };
}

export function designListSuccess() {
  return function (state, action) {
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        designs: action.designs.details,
        usedFontList: action.designs.usedFontList,
        loading: false,
      },
    };
  };
}

export function designListError() {
  return function (state, action) {
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        loading: false,
        error: {
          status: true,
          message: action.error,
        },
      },
    };
  };
}

export function createDesignSwitchTab() {
  return function (state, action) {
    return {
      ...state,
      templateSelection: {
        ...state.templateSelection,
        activeTab: action.activeTab,
      },
    };
  };
}
