const Heart = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <g>
      <path
        strokeWidth={10}
        d="M154.4,262.1a6.3,6.3,0,0,1-9,0L50,166.7c-80.5-80.4,14.5-178.3,95.6-104a6.2,6.2,0,0,0,8.6,0c81.4-74.8,176.5,23.1,95.5,104Z"
      />
    </g>
  </svg>
);

export default Heart;
