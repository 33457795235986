import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectGuestList, selectWorkspaceMembers} from "../../../../store/selectors/template/template.selector";
import EditGuestAccessLevel from "./EditGuestAccessLevel";
import ShareTempAsGuest from "./ShareTempAsGuest";
import { selectUserInfo } from "../../../../../../redux/user/authUser";

const DisplayItem = ({guest, onSelect}) => {
  const handleClick = () => {
    onSelect(guest);
  };

  return (
    <Item key={guest.email} onClick={handleClick}>
      <div>{guest.email}</div>
      <div>{guest.accessLevel}</div>
    </Item>
  );
};

const GuestList = ({guests, email, wsMembers, close}) => {
  const [editGuestAccessLevel, setEditGuestAccessLevel] = useState({
    status: false,
    guestInfo: {email: "", accessLevel: ""},
  });

  const onSelectUser = (guest) => {
    if(userIsWsMember) {
      setEditGuestAccessLevel({status: true, guestInfo: guest});
    }
  };

  const toggleEditStatus = () => {
    setEditGuestAccessLevel({
      status: false,
      guestInfo: {email: "", accessLevel: ""},
    });
  };

  const [userIsWsMember, setUserIsWsMember] = useState(false);
  useEffect(() => {
    Object.entries(wsMembers).map(([id, item]) => {
      if(item.email === email){
        setUserIsWsMember(true)
      }
    })
  },[wsMembers, email]);

  return (
    <Layout>
      {!editGuestAccessLevel.status ? (
        <>
          <ShareTempAsGuest />

          {guests && guests.length > 0 ? (
            <Header>
              <div>Email </div>
              <div>Access Level</div>
            </Header>
          ) : undefined}

          <List>
            {guests &&
              guests.map((guest) => (
                <DisplayItem
                  key={guest.email}
                  guest={guest}
                  onSelect={onSelectUser}
                />
              ))}
          </List>
        </>
      ) : undefined}

      {userIsWsMember && editGuestAccessLevel.status ? (
        <EditGuestAccessLevel
          guest={editGuestAccessLevel.guestInfo}
          goBack={toggleEditStatus}
          close={close}
        />
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  margin: 18px 16px;
  font-size: 0.9rem;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 22px;
  border-bottom: 1px solid ${({theme}) => theme.deskGap};
  div {
    flex: 1;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 12px;
  cursor: pointer;
  div {
    flex: 1;
  }
`;

const mapStateToProps = (state) => {
  return {
    email: selectUserInfo(state.authUser).email,
    guests: selectGuestList(state.designTemplate),
    wsMembers: selectWorkspaceMembers(state.designTemplate)
  };
};

export default connect(mapStateToProps, null)(GuestList);
