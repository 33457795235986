import React, {useState, useEffect} from "react";
import {
  ButtonSpinner,
  Spinner,
  Modal,
  ToggleSwitch,
  ShowError,
} from "../../../../../oat-ui";
import {Layout} from "./modalStyles";
import {connect} from "react-redux";
import styled from "styled-components";
import {Button} from "../../../../../oat-ui";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {onRemoveTemplateSuccess} from "../../../../store/actions/template.action";
import {
  paleBlue3,
  paleBlue2,
  grey5,
  grey4,
  darkGrey2,
} from "../../../../../oat-color-variables";
import {selectUserProfileTags} from "../../../../store/selectors/userDashboard.selector";
import {setProfileTags} from "../../../../store/actions/userProfile.action";
import {BottomSection} from "../../../reuse/dashboardUI/modalStyles";

const DisplayTag = ({tag, active, onSelect}) => {
  const handleClick = () => {
    onSelect(tag.id);
  };
  return (
    <Tag onClick={handleClick} active={active}>
      {tag.display}
    </Tag>
  );
};

const AddTemplateToProfile = ({tags, item, user, close, setProfileTags}) => {
  const [processing, setProcessing] = useState(false);
  const [form, setForm] = useState({
    title: item.title,
    selectedTags: [],
    published: "published",
  });
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    errExists: false,
    errMsg: null,
  });
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  const addToProfile = () => {
    setSubmitStatus({
      ...submitStatus,
      loading: true,
    });
    const {slug} = user.account.business;
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        tempID: item.id,
        tempSourceID: item.tempSourceID,
        tags: form.selectedTags,
        owner: item.owner, // for giving permission role
        profileSlug: "io", //slug,
        title: form.title,
        publishedStatus: form.published ? "published" : "unpublished",
      }),
    };
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${slug}/profile`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") {
          setSubmitStatus({
            loading: false,
            errExists: true,
            errMsg: data.message,
          });
          close();
        } else if (data.status === "success") {
          setSubmitStatus({
            ...submitStatus,
            loading: false,
          });
          close();
        } else {
          setSubmitStatus({
            loading: false,
            errExists: true,
            errMsg:
              "Something went wrong. Please contact the team if the problem persists.",
          });
        }
      })
      .catch((e) => {});
  };

  const onSelectTag = (selectedID) => {
    if (form.selectedTags.includes(selectedID)) {
      const filterTags = form.selectedTags.filter((tag) => tag !== selectedID);
      setForm({
        ...form,
        selectedTags: filterTags,
      });
    } else {
      const updateTags = [...form.selectedTags, selectedID];
      setForm({
        ...form,
        selectedTags: updateTags,
      });
    }
  };

  useEffect(() => {
    if (user.account.business.profile) {
      setProcessing(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/profile?tag=`
      )
        .then((response) => response.json())
        .then((data) => {
          setProfileTags(data.userProfile.tags);
          setProcessing(false);
        })
        .catch((e) => {});
    }
  }, []);

  const changeTitle = (e) => {
    const {value} = e.target;
    setForm({
      ...form,
      title: value,
    });
  };

  const handlePublishedStatus = () => {
    setForm({
      ...form,
      published: !form.published,
    });
  };

  return (
    <Modal width={490} height="auto" close={close}>
      {/* {!user.account.businessProfile ? (
        <Wrapper>Start using profile to share</Wrapper>
      ) : undefined} */}
      {!processing && user.account.business.profile ? (
        <Layout>
          <Wrapper>
            <Field style={{marginTop: 10}}>
              <FieldName>Title</FieldName>
              <FieldValue>
                <Input type="text" value={form.title} onChange={changeTitle} />
              </FieldValue>
            </Field>

            <Field>
              <FieldName>Published</FieldName>
              <FieldValue>
                <ToggleSwitch
                  value={form.published}
                  onItemChange={handlePublishedStatus}
                />
              </FieldValue>
            </Field>

            <Field>
              <FieldName>
                Tags <span>(Optional)</span>
              </FieldName>
              <FieldValue style={{overflowX: "scroll"}}>
                <TagList>
                  {tags && tags.length > 0 ? (
                    tags.map((tag) => (
                      <DisplayTag
                        key={tag.id}
                        tag={tag}
                        active={form.selectedTags.includes(tag.id)}
                        onSelect={onSelectTag}
                      />
                    ))
                  ) : (
                    <div>You don't have any tags.</div>
                  )}
                </TagList>
              </FieldValue>
            </Field>

            {submitStatus.errExists ? (
              <ShowError message={submitStatus.errMsg} />
            ) : undefined}
          </Wrapper>

          <BottomSection>
            <BottomSection style={{width: 168}}>
              <Button onClick={addToProfile}>
                {!submitStatus.loading ? (
                  "Add To Profile"
                ) : (
                  <ButtonSpinner width={15} height={15} color={"#fff"} />
                )}
              </Button>
            </BottomSection>
          </BottomSection>
        </Layout>
      ) : undefined}
      {processing ? (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      ) : undefined}
    </Modal>
  );
};

const Wrapper = styled.div`
  padding: 12px;
  box-sizing: border-box;
  flex-direction: column;
  color: ${darkGrey2};
`;

const TagList = styled.div`
  display: flex;
  font-size: 0.9rem;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tag = styled.div`
  padding: 6px 16px;
  box-sizing: border-box;
  border-radius: 18px;
  margin-right: 12px;
  cursor: pointer;
  flex: 0 0 auto;
  background: ${({active}) => (active ? paleBlue3 : paleBlue2)};
  &:hover {
    background: ${({active}) => (active ? paleBlue3 : undefined)};
  }
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  margin: 22px 0;
`;

const FieldName = styled.div`
  flex: 1;
  span {
    font-size: 0.82rem;
    color: ${grey4};
  }
`;

const FieldValue = styled.div`
  flex: 2;
`;

const WrapSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Input = styled.input`
  height: 38px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${grey5};
  color: ${grey4};
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    tags: selectUserProfileTags(state.userDashboard),
  };
};

export default connect(mapStateToProps, {
  onRemoveTemplateSuccess,
  setProfileTags,
})(AddTemplateToProfile);
