import React, {useEffect, useState, useRef, useCallback, useLayoutEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  batchUpdateTableRowHeight,
  createTableColumn,
  createTableRow,
  dispatchTableHeight,
  dispatchTableSize,
  removeTableColumn,
  removeTableRow,
  updateTableContent,
  setActiveTableCell,
} from "../../../../../store/actions/fields/table.action";
import {
  selectActiveField,
  selectEnableTextEdit,
  selectTableResizeProps,
} from "../../../../../store/selectors/fields.selector";
import {selectTempRatioDiff} from "../../../../../store/selectors/layout/layout.selector";
import EditColumnCells from "./EditColumnCells";
import EditRowCells from "./EditRowCells";
import {enableTextEdit} from "../../../../../store/actions/fields/text.action";
import {selectTempScale} from "../../../../../store/selectors/template/template.selector";
import ResizeRowCells from "./resizeCells/ResizeRowCells";
import ResizeColumnCells from "./resizeCells/ResizeColumnCells";
import ActiveTableCell from "./ActiveTableCell";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {storeFieldAsActiveUndoRedo, storeFieldUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {createShortKey} from "../../../../../store/reducers/functions/fields/utils";
import TableRows from "./cells/TableRows";
import {updateFieldCollaboration} from "../../../../../store/actions/collaboration/collaboration.action";
import EditTableRowOption from "./mobileEditOption/EditTableRowOption";
import EditTableColumnOption from "./mobileEditOption/EditTableColumnOption";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import { sanitizeInput } from "../../dragResize/utils";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const alphaBetsForColumns = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
];
const DisplayTable = ({
  field,
  selected,
  dragging,
  resizing,
  dispatchTableHeight,
  dispatchTableSize,
  enableEditing,
  tableResizeProps,
  enableTextEdit,
  updateTableContent,
  tempScale,
  batchUpdateTableRowHeight,
  slideID,
  fieldID,
  storeFieldUndoRedo,
  storeFieldAsActiveUndoRedo,
  createTableRow,
  createTableColumn,
  removeTableRow,
  removeTableColumn,
  updateFieldCollaboration,
  selectedCell,
  setActiveTableCell,
  ratioDiff
}) => {
  const windowSize = getWindowSize();
  const tableRef = useRef();
  const [hoverToDeleteRow, setHoverToDeleteRow] = useState(null);
  const [hoverToDeleteCol, setHoverToDeleteCol] = useState(null);
  const [columnList, setColumnList] = useState([]);
  const [rowList, setRowList] = useState([]);
  const [resizingRow, setResizingRow] = useState({
    index: null,
    status: false,
  });
  const [resizingColumn, setResizingColumn] = useState({
    index: null,
    status: false,
  });
  const {styles, content} = field || {};
  const {
    fontSize,
    opacity,
    fontFamily,
    zIndex,
    letterSpacing,
    lineHeight,
    borderWidth,
  } = styles || {};
  const active = {slideID, fieldID};
  const delayDebounce = useRef(null);

  useEffect(() => {
    if (!enableEditing && selected) {
      setActiveTableCell({row: null, column: null});
    }
  }, [enableEditing, selected]);

  useLayoutEffect(() => {
    if (field?.size?.w) {
      let accWidthForResizeLine = 0;
      // accumulateWidthColumn = 0;
      // let combinedColWidth = 0;
      const columns = content[0].columns.map((column, i) => {
        // this is for resizer
        const prevWidth = i === 0 ? 0 : content[0].columns[i - 1].width;
        accWidthForResizeLine = accWidthForResizeLine + prevWidth;

        return {
          id: column.id,
          index: `${alphaBetsForColumns[i]}`,
          width: column.width,
          xOffset: accWidthForResizeLine + borderWidth / 2,
          // i === content[0].columns.length - 1
          //   ? accWidthForResizeLine + borderWidth * 2
          //   : accWidthForResizeLine + borderWidth / 2,
        };
      });
      setColumnList(columns);
    }
  }, [content[0].columns.length, field?.size, borderWidth]);

  useLayoutEffect(() => {
    if (field?.size?.w) changeEditRowBarHeight();
  }, [content.length, field?.size?.w, field?.size?.h, borderWidth, selectedCell]);

  // necessary for init loading for setting edit rows bar height
  useEffect(() => {
    if (selected) changeTableHeight();
  }, [
    selected,
    fontFamily,
    letterSpacing,
    lineHeight,
    content.length,
    enableEditing,
    selectedCell,
  ]);

  const onHoverToDeleteRow = (index) => {
    setHoverToDeleteRow(index);
  };

  const onHoverToDeleteColumn = (index) => {
    setHoverToDeleteCol(index);
  };

  const prevValue = usePrevious(fontSize !== NaN ? fontSize : 12);
  const firstTimeMounted = useRef(true);
  useEffect(() => {
    if (firstTimeMounted.current) {
      firstTimeMounted.current = false;
      return;
    } else if (tableRef && tableRef.current && prevValue !== fontSize) {
      const tableHeight = tableRef.current.scrollHeight;
      dispatchTableHeight(active, tableHeight);
    }
  }, [fontSize, borderWidth]);

  const changeEditRowBarHeight = () => {
    if (tableRef && tableRef.current) {
      let accumulateHeight = 0;
      const rows =
        content &&
        content.map((item, i) => {
          const selectedCellHeight = tableRef.current.rows[i].offsetHeight; // tempScale;
          const prevHeight =
            i === 0 ? 0 : tableRef.current.rows[i - 1].offsetHeight;
          accumulateHeight = accumulateHeight + prevHeight;
          return {
            id: item.row.id,
            index: Number(`${i + 1}`),
            yOffset: accumulateHeight,
            height: selectedCellHeight,
          };
        });
      setRowList(rows);
    }
  };

  const changeTableHeight = useCallback(() => {
    if (tableRef && tableRef.current && field?.size?.w) {
      const tableHeight = tableRef.current.scrollHeight;
      if (tableHeight && active) {
        dispatchTableHeight(active, tableHeight);
        changeEditRowBarHeight();
      }
    }
  },[active]);

  const isFirstRun = useRef(true);
  useLayoutEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (tableRef && tableRef.current && tableResizeProps.hideBorder === false) {
      const tableWidth = tableRef.current.offsetWidth;
      const tableHeight = tableRef.current.scrollHeight;
      dispatchTableSize({
        width: tableWidth,
        height: tableHeight,
      });
    }
  }, [tableResizeProps, content[0].columns.length, selected]);

  // const tableBorderStyle = useMemo(() => {
  //   return {
  //     borderColor: styles.borderColor,
  //   };
  // }, [styles.borderColor]);

  const handleCellSelection = (row, column) => {
    setActiveTableCell({row, column});  
  };

  const [timesClicked, setTimesClicked] = useState({
    times: 0,
    position: field.pos,
  });

  useEffect(() => {
    if (selected) {
      if (
        timesClicked.times === 2 &&
        timesClicked?.position.x === field.pos.x
      ) {
        enableTextEdit();
        setTimesClicked({
          times: 0,
          position: {},
        });
      } else if (timesClicked.times === 1) {
        if (timesClicked?.position.x !== field.pos.x) {
          setTimesClicked({
            times: 0,
            position: {},
          });
        } else {
          const timeout = setTimeout(() => {
            setTimesClicked({
              times: 0,
              position: {},
            });
          }, 300);
          return () => clearTimeout(timeout);
        }
      }
    } 
  }, [timesClicked, field.pos, selected]);

  useEffect(() => {
    if(!selected) {
      setTimesClicked({
        times: 0,
        position: {},
      });
    }
  },[selected])

  const handleTimesClicked = () => {
    setTimesClicked({
      times: timesClicked.times + 1,
      position: field.pos,
    });
  };

  const [firstCharInput, setFirstCharInput] = useState(false);
  useEffect(() => {
    if (enableEditing && selected) setFirstCharInput(false);
    if (!enableEditing && selected && !isFirstRun.current) {
      // // storeFieldUndoRedo();
    }
  }, [enableEditing, selected]);

  const handleTableContentUpdate = async (item) => {
    const {content, rowIndex, columnIndex, scrollHeight} = item;
    if (!firstCharInput) {
      setFirstCharInput(true);
    }
    if (tableRef && tableRef.current) {
      const tableHeight = tableRef.current.scrollHeight;
      const sanitizedContent = sanitizeInput(content);
      const payload = {
        active,
        content: sanitizedContent,
        rowIndex,
        columnIndex,
        // will only enter next line and update row's height if no extra space is given
        rowHeight:
          rowList[rowIndex]?.height > scrollHeight
            ? rowList[rowIndex]?.height
            : scrollHeight,
        tableHeight,
      };
      await updateTableContent({...payload});
      await emitTableChanges(content);
      // storeFieldUndoRedo();
      clearTimeout(delayDebounce.current);
      delayDebounce.current = setTimeout(() => {
        storeFieldUndoRedo();
        // storeFieldAsActiveUndoRedo();
      }, 3000);
    }
  };

  const emitTableChanges = async (content) => {
    if (selected) {
      clearTimeout(delayDebounce.current);
      delayDebounce.current = setTimeout(() => {
        storeFieldAsActiveUndoRedo();
        updateFieldCollaboration();
      }, 4000);
      return () => clearTimeout(delayDebounce.current);
    }
  };

  const handleResizingRowStart = (index) => {
    setResizingRow({
      index,
      status: true,
    });
  };

  const handleResizingRowEnd = async () => {
    if (tableRef && tableRef.current) {
      const tableHeight = tableRef.current.scrollHeight;
      const selectedRowInfo =
        tableRef.current.rows[resizingRow.index].offsetHeight;
      await batchUpdateTableRowHeight({
        slideID,
        fieldID,
        row: {
          index: resizingRow.index,
          height: selectedRowInfo,
        },
        tableHeight,
      });
    }
    setResizingRow({
      index: null,
      status: false,
    });
  };

  const handleResizingColumnEnd = async (index) => {
    // if (tableRef && tableRef.current) {
    //   const tableHeight = tableRef.current.scrollHeight;
    //   const selectedRowInfo =
    //     tableRef.current.rows[resizingRow.index].offsetHeight;
    //   await batchUpdateTableRowHeight({
    //     slideID,
    //     fieldID,
    //     row: {
    //       index: resizingRow.index,
    //       height: selectedRowInfo,
    //     },
    //     tableHeight,
    //   });
    // }
    setResizingColumn({
      index: null,
      status: false,
    });
  };

  // on click tab key to move to next cell
  const detectShortcutKeys = useCallback(
    (event) => {
      if (
        event.keyCode == 9 &&
        field?.type === "table" &&
        selectedCell.column !== null
      ) {
        setActiveTableCell({
          column:
            columnList.length - 1 === selectedCell.column &&
            rowList.length - 1 > selectedCell.row
              ? 0
              : columnList.length - 1 !== selectedCell.column
              ? selectedCell.column + 1
              : selectedCell.column,
          row:
            columnList.length - 1 === selectedCell.column &&
            rowList.length - 1 > selectedCell.row
              ? selectedCell.row + 1
              : selectedCell.row,
        });
        // to contain the cursor at the end of the last cell
        if (
          rowList.length - 1 == selectedCell.row &&
          columnList.length - 1 == selectedCell.column
        ) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },
    [setActiveTableCell, selectedCell]
  );
  useEffect(() => {
    document.addEventListener("keydown", detectShortcutKeys, false);
    return () => {
      document.removeEventListener("keydown", detectShortcutKeys, false);
    };
  });

  const handleCellActions = () => {
    setActiveTableCell({row: null, column: null});
    storeFieldUndoRedo();
  };

  useEffect(() => {
    if (selectedCell?.row !== null || selectedCell?.column !== null) {
      // storeFieldUndoRedo();
    }
  },[selectedCell])

  const handleRowRemove = (id) => {
    removeTableRow(active, id);
    setHoverToDeleteRow(null);
    handleCellActions();
  };

  const handleColumnRemove = (id) => {
    removeTableColumn(active, id);
    setHoverToDeleteCol(null);
    handleCellActions();
  };

  const insertNewRow = (index) => {
    const rowID = createShortKey();
    createTableRow(active, index, rowID);
    handleCellActions();
  };

  const insertNewColumn = (index) => {
    const columnID = createShortKey();
    createTableColumn(active, index, columnID);
    handleCellActions();
  };

  const [minColumWidth, setMinColumnWidth] = useState(0);
  useEffect(() => {
    // Calculate the width based on font size and letter spacing
    const text = "M"; // The content of the element
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px ${fontFamily}`;
    context.letterSpacing = `${letterSpacing}px`;
    const textWidth = context.measureText(text).width / tempScale;
    setMinColumnWidth(textWidth);
  }, [fontSize, fontFamily, letterSpacing, tempScale]);

  return (
    <div
      style={{
        position: "relative",
        fontSize: 20, //fontSize,
        fontFamily,
        opacity,
        zIndex,
      }}
    >
      {selected && !resizing && windowSize.width >= 850 ? ( // dragging creates problem when deleting (onDragStart)
        <div className="edit-table-cells">
          <div className="edit-table-row-cells">
            <EditRowCells
              rows={rowList}
              onHoverToDelete={onHoverToDeleteRow}
              tempScale={tempScale}
              slideID={slideID}
              fieldID={fieldID}
              onCreate={insertNewRow}
              onRemove={handleRowRemove}
              borderWidth={borderWidth}
            />
          </div>
          <div className="edit-table-col-cells">
            <EditColumnCells
              columns={columnList}
              onHoverToDelete={onHoverToDeleteColumn}
              tempScale={tempScale}
              slideID={slideID}
              fieldID={fieldID}
              onCreate={insertNewColumn}
              onRemove={handleColumnRemove}
              borderWidth={borderWidth}
            />
          </div>
        </div>
      ) : undefined}

      {selected && enableEditing && selectedCell?.row != null && !resizing && windowSize.width < 850 ? (
        <>
          <EditTableRowOption
            selectedRow={{
              index: selectedCell?.row,
              id: rowList[selectedCell.row]?.id,
            }}
            startingOffset={
              rowList[selectedCell.row]?.yOffset +
              rowList[selectedCell.row]?.height / 2 -
              75 / 2
            }
          />
          <EditTableColumnOption
            selectedColumn={selectedCell?.column}
            startingOffset={
              columnList[selectedCell.column]?.xOffset +
              columnList[selectedCell.column]?.width / 2 -
              75 / 2
            }
          />
        </>
      ) : undefined}

      {selected && field?.size? (
        <div className="edit-table-resize">
          <div className="edit-table-resize-rows">
            <ResizeRowCells
              rows={rowList}
              tableSize={field?.size}
              updateTableHeight={changeTableHeight}
              resizingRow={resizingRow}
              onResizeStart={handleResizingRowStart}
              onResizeEnd={handleResizingRowEnd}
            />
          </div>
          <div className="edit-table-resize-columns">
            <ResizeColumnCells
              table={tableRef}
              columns={columnList}
              tableSize={field?.size}
              updateTableHeight={changeTableHeight}
              onResizeEnd={handleResizingColumnEnd}
              minColumWidth={minColumWidth}
            />
          </div>
        </div>
      ) : undefined}

      {selected && enableEditing ? (
        <div className="highlight-table-active-cell">
          <ActiveTableCell
            rows={rowList}
            columns={columnList}
            tempScale={tempScale}
            selectedRow={selectedCell.row}
            selectedColumn={selectedCell.column}
          />
        </div>
      ) : undefined}

      <Table
        ref={selected ? tableRef : undefined}
        roundedBorder={styles.roundedBorder}
        ratioDiff={ratioDiff}
      >
        <tbody>
          {content &&
            content.map((item, rowIndex) => (
              <TableRows
                key={rowIndex}
                rowIndex={rowIndex}
                fieldSelected={selected}
                item={item}
                styles={styles}
                hoverToDeleteRow={hoverToDeleteRow}
                hoverToDeleteCol={hoverToDeleteCol}
                enableEditing={enableEditing}
                onSelectCell={handleCellSelection}
                emitTimesClicked={handleTimesClicked}
                onUpdateContent={handleTableContentUpdate}
                activeColumn={selectedCell.column}
                selectedRow={
                  selected &&
                  enableEditing &&
                  selectedCell.row !== null &&
                  selectedCell.row === rowIndex
                }
                ratioDiff={ratioDiff}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export const Table = styled.table`
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  border-radius: ${({roundedBorder, ratioDiff}) =>
        roundedBorder ? 10 * ratioDiff : "0"}px;
  tbody {
    border-spacing: 0;
    border-collapse: separate;
  }
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    enableEditing: selectEnableTextEdit(designTemplate),
    tableResizeProps: selectTableResizeProps(designTemplate),
    ratioDiff: selectTempRatioDiff(designTemplate),
    tempScale: selectTempScale(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
    selectedCell: designTemplate.activeTableCell,
  };
};

export default connect(mapStateToProps, {
  dispatchTableHeight,
  dispatchTableSize,
  enableTextEdit,
  updateTableContent,
  batchUpdateTableRowHeight,
  storeFieldUndoRedo,
  createTableRow,
  createTableColumn,
  removeTableRow,
  removeTableColumn,
  updateFieldCollaboration,
  setActiveTableCell,
  storeFieldAsActiveUndoRedo
})(DisplayTable);
