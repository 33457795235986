import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import Amplify from "aws-amplify";
import config from "./aws-exports";
// import {createRoot} from "react-dom/client";
Amplify.configure(config);

// const domNode = document.getElementById("root");
// const root = createRoot(domNode);
// root.render(
//   <Provider store={store}>
//     <React.StrictMode>
//       <Router>
//         <App />
//       </Router>
//     </React.StrictMode>
//   </Provider>
// );

const rootElement = document.getElementById("root");

createRoot(rootElement).render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
