import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  box-sizing: border-box;
  flex-direction: column;
  @media only screen and (max-width: 849px) {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: ${window.innerHeight}px;
    // background: #171723;
  }
  @media only screen and (min-width: 850px) {
    width: ${({width}) => width};
    background: ${({theme}) => theme.deskSecondaryBg};
    align-items: flex-start;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
  }
  animation: ${({animate}) =>
    animate ? "zoomOut 0.7s ease-in-out" : undefined};
  transition: ${({animate}) =>
    animate ? "opacity 0.7s ease-in-out" : undefined};
  @-webkit-keyframes zoomOut {
    0% {
      transform: scale(1.3);
      opacity: 0;
    }
    25% {
      opacity: 0.3;
      background-image: linear-gradient(
        to top,
        rgba(4, 4, 4, 0.65),
        rgba(54, 54, 54, 0.2)
      );
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes zoomOut {
    0% {
      transform: scale(1.3);
      opacity: 0;
    }
    25% {
      opacity: 0.3;
      background-image: linear-gradient(
        to top,
        rgba(4, 4, 4, 0.65),
        rgba(54, 54, 54, 0.2)
      );
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const PanelArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  width: 100%;
  height: auto;
  overflow: hidden;
  @media only screen and (min-width: 850px) {
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
`;

export const WrapCanvas = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  // height: 100%; //auto; // 100%;
  width: auto;
  // align-items: center;
`;

export const CarouselFrame = styled.div`
  width: 100%; //99.75%; // place the template in the exact center
  flex-shrink: 0;
  // overflow: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 850px) {
    align-items: flex-start;
  }
`;

export const WrapModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${window.innerHeight - 100}px;
`;

export const CanvasSection = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const WrapCloseSvg = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  fill: #fff;
  cursor: pointer;
  @media only screen and (min-width: 850px) {
    display: none;
  }
`;

export const MobileClosBtn = styled.div`
  display: flex;
  justifycontent: flex-end;
`;
