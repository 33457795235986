import React from "react";
import {Modal} from "../../../../oat-ui";
import CreateWorkspace from "./actions/CreateWorkspace";
import AddWorkspaceMember from "./members/AddWorkspaceMember";
import WorkspaceInfo from "./WorkspaceInfo";

const WorkspaceModals = ({modalName, close}) => {
  let displayModal = null;

  switch (modalName) {
    case "create-workspace":
      displayModal = (
        <Modal width={400} height="auto" close={close}>
          <CreateWorkspace close={close} />
        </Modal>
      );
      break;
    case "add-workspace-member":
      displayModal = (
        <Modal width={400} height="auto" close={close}>
          <AddWorkspaceMember close={close} />
        </Modal>
      );
      break;
    case "workspace-setting":
      displayModal = (
        <Modal top={70} width={740} height={"auto"} close={close}>
          <WorkspaceInfo close={close} />
        </Modal>
      );
      break;
    default:
      displayModal = null;
  }
  return displayModal;
};

export default WorkspaceModals;
