import {proButtonStyles} from "../../../../oat-ui/styles/proIconStyles";
import {css} from "styled-components";

export const requiresUpgradeToProStyles = css`
  ${proButtonStyles};
  margin-left: 12px;
  background: linear-gradient(320deg, #3181ff, #ff9be8);
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
`;
