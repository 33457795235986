import {
  ADD_SLIDE_COMMENT,
  RESPOND_TO_THREAD,
  DELETE_SLIDE_COMMENT,
  UPDATE_SLIDE_COMMENT,
  ON_RESOLVED_ISSUE,
} from "../actionTypes";

export function addSlideComment(payload, slideID) {
  return {
    type: ADD_SLIDE_COMMENT,
    payload,
    slideID
  };
}

export function updateSlideComment(payload) {
  return {
    type: UPDATE_SLIDE_COMMENT,
    payload,
  };
}

export function respondToThread({mainThreadID, payload, slideID}) {
  return {
    type: RESPOND_TO_THREAD,
    slideID,
    mainThreadID,
    payload,
  };
}

export function deleteSlideComment({
  commentID,
  deletingSubComment,
  subCommentID,
}) {
  return {
    type: DELETE_SLIDE_COMMENT,
    commentID,
    deletingSubComment,
    subCommentID,
  };
}

export function onResolvedIssue({slideID, commentID}) {
  return {
    type: ON_RESOLVED_ISSUE,
    commentID,
    slideID,
  };
}
