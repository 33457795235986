import React, {useCallback} from "react";
import {connect} from "react-redux";
import {selectTempScale} from "../../../../../../../store/selectors/template/template.selector";

export function compactFormatter(num, digits) {
  const lookup = [
    {value: 1, symbol: ""},
    {value: 1e3, symbol: "k"},
    {value: 1e6, symbol: "M"},
    {value: 1e9, symbol: "G"},
    {value: 1e12, symbol: "T"},
    {value: 1e15, symbol: "P"},
    {value: 1e18, symbol: "E"},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      if (num < 0) {
        return -num >= item.value;
      }
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : 0;
}

export function formatWithCommas(item) {
  return item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ScaleLabels = ({
  color,
  fontSize,
  fontFamily,
  item,
  gap,
  highestValue,
  digitsLength,
  tickColor,
  scaleDistance,
  scaleType,
  scaleFormat,
  tempScale,
  currencyUnit,
}) => {
  const formatScaleValues = useCallback(
    (item) => {
      // compact notion
      if (scaleFormat === "1k") {
        return compactFormatter(item, 1);
      } else if (scaleFormat === "1,000") {
        return formatWithCommas(item);
      } else {
        return item;
      }
    },
    [scaleFormat]
  );

  return [...Array(item.lines + 1)].map((x, i) => (
    <g key={i}>
      <line
        x1={-1.5}
        x2={0}
        y1={gap * i}
        y2={gap * i}
        stroke={tickColor}
        strokeWidth={0.5 / tempScale}
      />
      <text
        style={{
          textAnchor: "end",
          fontSize,
          alignmentBaseline: "alphabetic",
          fill: color,
          fontFamily,
          transform: `translate(-${scaleDistance}px, ${gap * i + 6}px)`, // 3 * (tempSize.w / 536)
        }}
      >
        {scaleType === "currency" ? currencyUnit + " " : undefined}
        {formatScaleValues((highestValue - i * item.acc).toFixed(digitsLength))}
        {scaleType === "percentage" ? " %" : undefined}
      </text>
    </g>
  ));
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(ScaleLabels);
