import React from "react";
import {useNavigate} from "react-router";
import styled from "styled-components";
import {Button} from "../../../../oat-ui";
import {veryLightGrey, primaryWhite} from "../../../../oat-color-variables";

const ThemeList = ({item, onSelectTheme}) => {
  let history = useNavigate();
  const {type, size, tempID} = item;

  const redirectPath = () => {
    const themeID = Math.random()
      .toString(36)
      .substr(2, 5);
    return history.push(
      `/backend/admin/create/design/${type.value}/${size}/templates/${tempID}/addTheme/${themeID}`
    );
  };

  return (
    <>
      <Line />
      <Wrapper>
        <Header>Themes</Header>
        <WrapButton>
          <Button
            styles={{
              background: "transparent",
              color: "#232323",
              color: "rgb(93 93 93)",
              fontSize: "1.5rem",
              fontWeight: 500,
            }}
            handleClick={() => redirectPath()}
          >
            +
          </Button>
        </WrapButton>
      </Wrapper>
      {item.themes &&
        item.themes.map((theme, i) => (
          <Item key={i} onClick={() => onSelectTheme(theme.themeID)}>
            <Info>
              {theme.themeID}
              <ColorSet
                style={{
                  background: theme.color,
                }}
              />
            </Info>

            <Info style={{justifyContent: "flex-end"}}>{theme.createdAt}</Info>
          </Item>
        ))}
    </>
  );
};

const Line = styled.div`
  height: 1px;
  margin: 22px 0;
  background: ${veryLightGrey};
`;

const Wrapper = styled.div`
  display: flex;
  width: 68%;
  justify-content: space-between;
`;

const Header = styled.h3`
  font-size: 1rem;
  color: #3b3e42;
  padding: 12px 0;
`;

const Item = styled.div`
  padding: 12px 8px;
  border-radius: 6px;
  background: ${primaryWhite};
  border-radius: 6px;
  width: 65%;
  margin-bottom: 12px;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
`;

const WrapButton = styled.div`
  height: 45px;
  margin-bottom: 12px;
`;

const Info = styled.div`
  flex: 1;
  padding: 0 10px 0 25px;
  display: flex;
`;

const ColorSet = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 1px #afa8a8;
  margin-left: 12px;
`;

export default ThemeList;
