import React from "react";
import {selectDrawerStatus} from "../../../store/selectors/layout/layout.selector";
import MobileDrawer from "./MobileDrawer";
import {connect} from "react-redux";
import CreateText from "../modal/create/text/CreateText";
import CreateShape from "../modal/create/shapes/CreateShape";
import CreateIcon from "../modal/create/icons/CreateIcon";
import CreateVideo from "../modal/create/video/CreateVideo";
import ImageSelection from "../modal/create/uploadImage/ImageSelection";
import CreateChart from "../modal/create/chart/CreateChart";
import SlideNotes from "../editingPanel/panelTypes/desktop/slideNotes/SlideNotes";
import ExportAsImage from "./export/ExportAsImage";
import ShareLink from "./share/ShareLink";
import EditBackground from "./fields/EditBackground";
import MobileSlideActions from "./MobileSlideOptions";
import {closeDrawer} from "../../../store/actions/layout.action";
import MobileCreateDrawer from "./MobileCreateDrawer";
import CommentSection from "../editingPanel/panelTypes/desktop/comments/CommentSection";
import NewDesignList from "../slideListPanel/newDesignList/NewDesignList";
import IconDrawer from "../modal/create/icons/IconDrawer";

const MobileContents = ({drawer, closeDrawer}) => {
  let displayView = null;

  switch (drawer.type) {
    case "choose-templates":
      displayView = <NewDesignList />;
      break;
    case "create":
      displayView = <MobileCreateDrawer />;
      break;
    case "to-create-text":
      displayView = <CreateText />;
      break;
    case "to-create-image":
      displayView = <ImageSelection />;
      break;
    case "to-create-shape":
      displayView = <CreateShape />;
      break;
    // case "to-create-icon":
    //   displayView = <CreateIcon />;
    //   break;
    case "to-create-chart":
      displayView = <CreateChart />;
      break;
    case "to-create-video":
      displayView = <CreateVideo />;
      break;
    case "slide-options":
      displayView = <MobileSlideActions />;
      break;
    case "comments":
      displayView = <CommentSection />;
      break;
    case "edit-background":
      displayView = <EditBackground />;
      break;
    case "export-as-image":
      displayView = <ExportAsImage />;
      break;
    case "add-notes":
      displayView = <SlideNotes />;
      break;
    case "share-a-link":
      displayView = <ShareLink />;
      break;
    default:
  }

  const handleDrawerClose = () => {
    closeDrawer();
  };

  return (
    <>
      <MobileDrawer
        open={drawer.open && drawer.type !== "to-create-icon"}
        height={drawer.type !== "to-create-icon" ? drawer.height : 0}
        onClose={handleDrawerClose}
      >
        {displayView}
      </MobileDrawer>
      
      <IconDrawer 
        open={drawer.open && drawer.type === "to-create-icon"}
        height={drawer.type === "to-create-icon" ? drawer.height : 0}
        onClose={handleDrawerClose}
      >
        <CreateIcon />
      </IconDrawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    drawer: selectDrawerStatus(state.designTemplate),
  };
};

export default connect(mapStateToProps, {closeDrawer})(MobileContents);
