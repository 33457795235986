import styled, {css} from "styled-components";
import {primaryGrey, primaryPurple} from "../../../oat-color-variables";
import { Link } from "react-router-dom";
import { requiresUpgradeToProStyles } from "../../../oat-design-template/components/editDesign/parts/upgradeToPro";

export const listViewWidth = 15; // vw
export const listSubtractWidth = 80; // in px
const listLayoutPadding = `calc(${listViewWidth}vw - ${listSubtractWidth}px) 110px`;

export const Layout = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: ${window.innerHeight}px;
  @media only screen and (min-width: 1500px) {
    display: flex;
    align-items: center;
  }
`;

export const Items = styled.div`
  display: grid;
  columns: 3;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin: 26px 3.5% 0;
  padding-bottom: 90px;
  @media screen and (max-width: 600px) {
    grid-template-columns: ${({$landscape}) => ($landscape ? "1fr" : "1fr 1fr")};
  }
  @media screen and (min-width: 900px) and (max-width: 1499px) {
    padding: 0 ${listLayoutPadding};
    grid-template-columns: ${({$landscape}) =>
      $landscape ? "1fr 1fr" : "1fr 1fr 1fr"};
    grid-gap: 28px;
    margin: 26px 0 0;
  }
  @media screen and (min-width: 1500px) {
    padding-bottom: 110px;
    grid-template-columns: ${({$landscape}) =>
      $landscape ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
    grid-gap: 24px;
    margin: 26px 0 0;
  }
`;

export const PotraitItem = styled(Link)`
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 38px;
  font-size: 13px;
  color: inherit;
  text-decoration: none;
`;

export const LandscapeItem = styled(Link)`
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 28px;
  font-size: 13px;
  color: inherit;
  text-decoration: none;
`;

export const Header = styled.h3`
  font-size: 1.24rem;
  margin: 0.8rem 0 1.4rem;
  color: ${primaryPurple};
  user-select: none;
  width: 50%;
`;

export const Divider = styled.div`
  height: 1px;
  background: ${primaryGrey};
  margin: 18px 0;
`;

export const ItemName = styled.p`
  cursor: pointer;
`;

export const imageStyles = css`
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  border--top-left-radius: 8px;
  border--top-right-radius: 8px;
  margin-bottom: 10px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  position; relative;
  transition: all 0.3s ease-in-out;
`;

export const Image = styled.img`
  ${imageStyles};
  @media only screen and (min-width: 601px) {
    &:hover {
      box-shadow: 0px 0px 14px 2px #d2e0f68c;
    }
  }
`;

export const MoreOption = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px 2px 0;
  min-width: 50px;
  flex: 1;
  align-items: center;
  z-index: 99;
  height: 25px;
  cursor: pointer;
  div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #6b70f9;
    margin: 0 3px;
  }
`;

export const WrapTempInfo = styled.div`
  display: flex;
  min-height: 30px;
  align-items: center;
`;

export const ErrorMsg = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DesignType = styled.p `
  text-transform: capitalize;
  font-size: 14px;
  color: #383838;
  @media only screen and (min-width: 850px) {
    font-size: 16px;
  }
`

export const RequiresUpgradeToPro = styled.div`
  ${requiresUpgradeToProStyles};
  font-size: 0.8rem;
  padding: 2px 12px;
`

export const Owner = styled.img`
  max-width: 100%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 12px;
  box-shadow: 0px 0px 2px 0.5px #cecece;
  border-radius: 50%;
`