import React, {useRef} from "react";
import {connect} from "react-redux";
import {
  createChartColumn,
  updateChartInfo,
  deleteChartRow,
  deleteChartColumn,
} from "../../../../../../../store/actions/fields/chart.action";
import ChartInputField from "../reuse/ChartInputField";
import styled from "styled-components";
import {detectClickOutside} from "../../../../../../../../oat-ui";
import RemoveChartData from "../reuse/RemoveChartData";
import PickChartColor from "../reuse/PickChartColor";
import DisplayChartData from "../reuse/DisplayChartData";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";

let delayDebounce;
const EditChartInputs = ({innerIndex, outerIndex, onUpdate, value}) => {
  const handleUpdate = (value, emitToSocket) => {
    onUpdate({
      row: outerIndex,
      column: innerIndex,
      value: value,
      emitToSocket,
    });
  };

  return (
    <ChartInputField
      innerIndex={innerIndex}
      outerIndex={outerIndex}
      value={value}
      changeValue={handleUpdate}
    />
  );
};

const EditChartDataSeries = ({
  dataSeries,
  type,
  activeCell,
  onSelectCell,
  onDeselectCell,
  removeStatus,
  createChartColumn,
  updateChartInfo,
  colorSet,
  deleteChartRow,
  deleteChartColumn,
  storeFieldUndoRedo,
  slideID,
  fieldID,
  onCreateRow,
  updateFieldCollaboration,
}) => {
  const dataSet = dataSeries?.slice(1);
  const legendList = dataSeries?.[0].slice(1);
  const {status, row, col} = activeCell || {};
  const tableRef = useRef(null);
  const active = {slideID, fieldID};

  detectClickOutside(tableRef, () => {
    setTimeout(() => {
      // !== null important
      if (col !== null && dataSeries?.length > 0) {
        onDeselectCell();
      }
    }, 100);
  });

  const handleEmitSocket = (type, value) => {
    updateFieldCollaboration();
  };

  const addNewColumn = () => {
    createChartColumn(active);
    storeFieldUndoRedo();
    handleEmitSocket("chart-create-column", active);
  };

  const handleRemoveColumn = (index) => {
    // 2 - including category titles
    if (dataSet[0].length > 2) {
      deleteChartColumn(active, index);
      storeFieldUndoRedo();
      handleEmitSocket("chart-remove-column", index);
    }
  };

  const handleRemoveRow = (index) => {
    if (dataSet.length > 1) {
      deleteChartRow(active, index);
      storeFieldUndoRedo();
      handleEmitSocket("chart-remove-row", index);
    }
  };

  const handleUpdateInfo = ({row, column, value, emitToSocket}) => {
    const payload = {active, row, column, value};
    console.log(value)
    updateChartInfo({...payload});
    storeFieldUndoRedo();

    if (emitToSocket) {
      clearTimeout(delayDebounce);
      delayDebounce = setTimeout(() => {
        // storeFieldUndoRedo();
        handleEmitSocket("chart-data", payload);
      }, 2500);
      return () => clearTimeout(delayDebounce);
    }
  };

  return (
    <tbody style={{userSelect: "none"}} ref={tableRef}>
      {removeStatus ? (
        <tr>
          <td style={{borderRight: "0px"}}></td>
          <td></td>
          {legendList &&
            legendList.map((column, i) => (
              <td key={i}>
                <RemoveChartData
                  index={i}
                  onRemove={handleRemoveColumn}
                  disabled={legendList.length <= 1}
                />
              </td>
            ))}
          <td></td>
        </tr>
      ) : undefined}

      {dataSeries &&
        dataSeries?.map((data, outerIndex) => (
          <tr key={outerIndex}>
            {/* border necesssary */}
            {!removeStatus ? <td style={{borderRight: "0px"}}></td> : undefined}

            {removeStatus ? (
              <td>
                {outerIndex !== 0 ? (
                  <RemoveChartData
                    index={outerIndex}
                    onRemove={handleRemoveRow}
                    disabled={dataSet.length <= 1}
                  />
                ) : undefined}
              </td>
            ) : undefined}

            {data &&
              data.map((column, innerIndex) => {
                const displayRow = () => {
                  return (
                    <td style={{minWidth: 150}} key={innerIndex}>
                      <div style={{display: "flex", alignItems: "center"}}>
                        {status && row === outerIndex && col === innerIndex ? (
                          // For editing
                          <EditChartInputs
                            innerIndex={innerIndex}
                            outerIndex={outerIndex}
                            value={column}
                            onUpdate={handleUpdateInfo}
                          />
                        ) : (
                          <DisplayChartData
                            column={column}
                            outerIndex={outerIndex}
                            innerIndex={innerIndex}
                            onSelectCell={onSelectCell}
                          />
                        )}

                        {outerIndex === 0 &&
                        innerIndex !== 0 &&
                        type !== "single-column" ? (
                          <div style={{marginLeft: 10}}>
                            <PickChartColor
                              activeColor={colorSet[innerIndex - 1]}
                              innerIndex={innerIndex}
                            />
                          </div>
                        ) : undefined}

                        {/* for pie & donut chart */}
                        {outerIndex === 0 &&
                        innerIndex !== 0 &&
                        type === "single-column" ? (
                          <div>Values</div>
                        ) : undefined}

                        {/* for pie & donut color options */}
                        {outerIndex !== 0 &&
                        innerIndex !== 0 &&
                        type === "single-column" ? (
                          <div style={{marginLeft: 10}}>
                            <PickChartColor
                              activeColor={colorSet[outerIndex - 1]}
                              innerIndex={outerIndex}
                            />
                          </div>
                        ) : undefined}
                      </div>
                    </td>
                  );
                };
                if (
                  type == "single-column" &&
                  (innerIndex == 0 || innerIndex == 1)
                ) {
                  return displayRow();
                } else if (type !== "single-column") {
                  return displayRow();
                }
              })}

            {outerIndex === 0 && type !== "single-column" ? (
              <CreateColumn onClick={addNewColumn}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: 80,
                    fontSize: "1.4rem",
                  }}
                >
                  +
                </div>
              </CreateColumn>
            ) : (
              <td></td>
            )}
          </tr>
        ))}

      <tr>
        <td
          style={{fontSize: "1.4rem", textAlign: "center"}}
          onClick={onCreateRow}
        >
          +
        </td>
        {legendList &&
          legendList.map((data, outerIndex) => (
            <td
              key={outerIndex}
              style={{borderRight: "1px solid transparent"}}
            ></td>
          ))}
        <td></td>
        {type === "single-column" && legendList.length > 1 ? undefined : (
          <td></td>
        )}
      </tr>
    </tbody>
  );
};

const CreateColumn = styled.td`
  color: ${({theme}) => theme.addDesignTxt};
  font-size: 1.5rem;
  padding: 0 1rem;
  width: 45px;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  createChartColumn,
  updateChartInfo,
  deleteChartRow,
  deleteChartColumn,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditChartDataSeries);
