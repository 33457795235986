import React, {useEffect} from "react";
import styled from "styled-components";
import {fetchTemplate} from "../store/actions/template/template.action";
import {connect} from "react-redux";
import VerticalFormat from "../components/displaySlides/VerticalFormat";
import {useParams} from "react-router-dom";
import {selectUsedFontList} from "../store/selectors/template/template.selector";
import {loadUsedFonts} from "../utils/loadUsedFonts";
import SocketProvider from "../webSocket/Socket";

const Screenshot = ({fetchTemplate, api, usedFontList}) => {
  let params = useParams();
  const {username, tempID, screenshot} = params;
  useEffect(() => {
    fetchTemplate({
      api,
      // isMobileView
      // `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/hein/template/0.782`
      // `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template/12345678/pink`
    });
  }, [username, tempID]);

  useEffect(() => {
    loadUsedFonts(usedFontList);
  }, [usedFontList]);

  return (
    <Layout>
      <SocketProvider
        username={""}
        name={""}
        avatar={null}
        tempID={tempID}
        enableSocket={false}
      >
        <Wrapper>
          <VerticalFormat />
        </Wrapper>
      </SocketProvider>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state) => {
  return {
    usedFontList: selectUsedFontList(state.designTemplate),
  };
};

export default connect(mapStateToProps, {fetchTemplate})(Screenshot);
