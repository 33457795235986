import React from "react";
import {Dot, Option} from "../../../../../ui/styles/SelectStyles";

const ThemeApplyOptions = ({item, onApply}) => {
  const handleClick = () => {
    onApply(item.value);
  };

  // will not deselect field on clicking the div
  const cancelEventPropagate = (e) => {
    e.stopPropagation();
  };

  return (
    <Option onMouseDown={cancelEventPropagate} onClick={handleClick}>
      <p>{item.display}</p>
      <Dot />
    </Option>
  );
};

export default ThemeApplyOptions;
