import React, {useEffect, useState} from "react";
import {Field, Value} from "./DisplayUserInfo";
import {Auth} from "aws-amplify";
import {Button, ButtonSpinner} from "../../../../oat-ui";
import {
  Header,
  FieldName,
  Footer,
  BottomSection,
  Cancel,
  ContentSection,
  TextField,
} from "../../reuse/dashboardUI/modalStyles";

const ChangeEmail = ({email, onChangedEmail, onCancel}) => {
  const [inputEmail, setInputEmail] = useState(email);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setInputEmail(email);
  }, [email]);

  const onChangeInput = (e) => {
    const {value} = e.target;
    setInputEmail(value);
  };

  const handleSubmit = async () => {
    setProcessing(true);
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      email: inputEmail,
    }).then(() => {
      setProcessing(false);
      onChangedEmail(inputEmail);
    });
    // let result = await Auth.verifyCurrentUserAttributeSubmit("email", "abc123");
  };

  const handleCancel = () => {
    onCancel("display-user-info");
  };

  return (
    <>
      <ContentSection>
        <Header>Edit Email</Header>
        <Field>
          <FieldName>New Email</FieldName>
          <Value>
            <TextField value={inputEmail} onChange={onChangeInput} />
          </Value>
        </Field>
      </ContentSection>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Cancel onClick={handleCancel}>Cancel</Cancel>
          <div style={{width: 120, height: "100%"}}>
            <Button onClick={handleSubmit}>
              {!processing ? "Update" : <ButtonSpinner />}
            </Button>
          </div>
        </BottomSection>
      </Footer>
    </>
  );
};

export default ChangeEmail;
