import {
  SWITCH_WORKSPACE_INIT,
  SWITCH_WORKSPACE_SUCCESS,
  SWITCH_WORKSPACE_ERROR,
  FETCH_WORKSPACES_INIT,
  FETCH_WORKSPACES_SUCCESS,
  FETCH_WORKSPACES_ERR,
  CREATE_WORKSPACE_INIT,
  CREATE_WORKSPACE_SUCCESS,
  CREATE_WORKSPACE_ERR,
  DELETE_WORKSPACE_INIT,
  DELETE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE_ERR,
  RENAME_WORKSPACE_INIT,
  RENAME_WORKSPACE_SUCCESS,
  RENAME_WORKSPACE_ERR,
  SELECT_WORKSPACE_ITEM_ACTIONS,
  RESET_WORKSPACE_SELECTED_ACTIONS,
} from "./actionTypes";

export function switchWorkspaceInit() {
  return {
    type: SWITCH_WORKSPACE_INIT,
  };
}

export function switchWorkspaceSuccess({activeWorkspace, folders, templates}) {
  return {
    type: SWITCH_WORKSPACE_SUCCESS,
    activeWorkspace,
    folders,
    templates,
  };
}

export function switchWorkspaceError(error) {
  return {
    type: SWITCH_WORKSPACE_ERROR,
    error,
  };
}

// change workspace - not "ws type"
export function switchWorkspace({username, workspace}) {
  return async (dispatch) => {
    try {
      dispatch(switchWorkspaceInit());
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces/${workspace.ID}`
      )
        .then((res) => res.json())
        .then((data) => {
          dispatch(
            switchWorkspaceSuccess({
              activeWorkspace: workspace,
              folders: data.folders,
              templates: data.templates,
            })
          );
        })
        .catch((e) => dispatch(switchWorkspaceError(e.message)));
    } catch (e) {
      dispatch(switchWorkspaceError(e.message));
    }
  };
}

export function getWorkspaceInit() {
  return {
    type: FETCH_WORKSPACES_INIT,
  };
}

export function getWorkspaceSuccess(payload) {
  return {
    type: FETCH_WORKSPACES_SUCCESS,
    payload,
  };
}

export function getWorkspaceErr(error) {
  return {
    type: FETCH_WORKSPACES_ERR,
    error,
  };
}

export const fetchWorkspaces = (username) => {
  return async (dispatch) => {
    dispatch(getWorkspaceInit());
    try {
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces`
      )
        .then((res) => res.json())
        .then((items) => dispatch(getWorkspaceSuccess(items)))
        .catch((e) => dispatch(getWorkspaceErr(e)));
    } catch (e) {
      dispatch(getWorkspaceErr(e));
    }
  };
};

// CREATE
export function createWorkspaceInit() {
  return {
    type: CREATE_WORKSPACE_INIT,
  };
}

export function createWorkspaceSuccess(payload) {
  return {
    type: CREATE_WORKSPACE_SUCCESS,
    payload,
  };
}

export function createWorkspaceErr(error) {
  return {
    type: CREATE_WORKSPACE_ERR,
    error,
  };
}

// export const createWorkspace = ({user, workspaceName, workspaceType}) => {
//   return async (dispatch) => {
//     dispatch(createWorkspaceInit());
//     try {
//       await fetch(
//         `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/workspaces`,
//         {
//           method: "POST",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             workspaceName,
//             workspaceType,
//             name: user.name,
//             email: user.email,
//           }),
//         }
//       )
//         .then((res) => res.json())
//         .then((data) => {
//           navigate(`/dashboard/${workspaceName}/home`);
//           dispatch(createWorkspaceSuccess(data));
//         })
//         .catch((e) => dispatch(createWorkspaceErr(e)));
//     } catch (e) {
//       dispatch(createWorkspaceErr(e));
//     }
//   };
// };

// Delete
export function dispatchDeleteWorkspaceInit() {
  return {
    type: DELETE_WORKSPACE_INIT,
  };
}

export function deleteWorkspaceSuccess(workspaceID, redirectedWorkspace) {
  return {
    type: DELETE_WORKSPACE_SUCCESS,
    workspaceID,
    redirectedWorkspace,
  };
}

export function dispatchDeleteWorkspaceErr(error) {
  return {
    type: DELETE_WORKSPACE_ERR,
    error,
  };
}

// export const deleteWorkspace = ({username, workspaceID}) => {
//   return async (dispatch) => {
//     dispatch(dispatchDeleteWorkspaceInit());
//     try {
//       await fetch(
//         `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces/${workspaceID}`,
//         {
//           method: "DELETE",
//         }
//       )
//         .then((res) => res.json())
//         .then((data) => {
//           if (data.status === "success") {
//             const {reRoutedWorkspace} = data.response;
//             const redirectedWorkspace = {
//               ID: reRoutedWorkspace.ID,
//               name: reRoutedWorkspace.name,
//               role: reRoutedWorkspace.role,
//               owner: reRoutedWorkspace.owner,
//             };
//             dispatch(
//               dispatchDeleteWorkspaceSuccess(workspaceID, redirectedWorkspace)
//             );
//           } else {
//             dispatch(dispatchDeleteWorkspaceErr(data.message));
//           }
//         })
//         .catch((e) => {
//           dispatch(dispatchDeleteWorkspaceErr(e));
//         });
//     } catch (e) {
//       dispatch(dispatchDeleteWorkspaceErr(e));
//     }
//   };
// };

// Rename
export function dispatchRenameWorkspaceInit() {
  return {
    type: RENAME_WORKSPACE_INIT,
  };
}

export function dispatchRenameWorkspaceSuccess(payload) {
  return {
    type: RENAME_WORKSPACE_SUCCESS,
    payload,
  };
}

export function dispatchRenameWorkspaceErr(error) {
  return {
    type: RENAME_WORKSPACE_ERR,
    error,
  };
}

export const renameWorkspace = ({username, payload}) => {
  const {workspaceID, workspaceName} = payload;
  return async (dispatch) => {
    dispatch(dispatchRenameWorkspaceInit());
    try {
      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces/${workspaceID}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspaceName,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            dispatch(dispatchRenameWorkspaceSuccess(payload));
          } else {
            dispatch(dispatchRenameWorkspaceErr(data.message));
          }
        })
        .catch((e) => dispatch(dispatchRenameWorkspaceErr(e)));
    } catch (e) {
      dispatch(dispatchRenameWorkspaceErr(e));
    }
  };
};

// toggle delete | rename popper
export function selectWorkspaceItemActions(workspace) {
  return {
    type: SELECT_WORKSPACE_ITEM_ACTIONS,
    workspace,
  };
}

// reset selected workspace item  actions (rename|delete)
export function resetWSActions(error) {
  return {
    type: RESET_WORKSPACE_SELECTED_ACTIONS,
  };
}
