import React, {useEffect, useState, useRef} from "react";
import {connect, batch} from "react-redux";
import {updateFieldStyles, addLinkToField} from "../../../../../../store/actions/fields/common.action";
import {updateTextContent} from "../../../../../../store/actions/fields/text.action";
import {selectPageTheme} from "../../../../../../store/selectors/layout/layout.selector";
import {selectTemplate} from "../../../../../../store/selectors/template/template.selector";
import {Title} from "../../shared/reuseStyles/Title";
import styled from "styled-components";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../../../store/selectors/fields.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import { storeFieldUndoRedo } from "../../../../../../store/actions/fields/undoRedo.action";
import { handleContentChange, wrapATagLink } from "../../../../../../utils/rangeSelector";

let delayDebounce;

const AddLink = ({
  slideIndex,
  fieldID,
  addLinkToField,
  updateFieldCollaboration,
  updateTextContent,
  storeFieldUndoRedo,
  mode,
  activeField,
}) => {
  const [selection, setSelection] = useState('');
  const [linkType, setLinkType] = useState("slide-number");
  const [inputLink, setInputLink] = useState("");
  const active = {slideID: slideIndex, fieldID};
  const linkRef = useRef();
  const contentRef = useRef();
  const selectionRef = useRef(null);

  const saveSelection = () => {
    const sel = window.getSelection();
    if (sel.rangeCount > 0) {
      selectionRef.current = sel.getRangeAt(0);
    }
  };

  const restoreSelection = () => {
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(selectionRef.current);
  };

  const handleSelection = () => {
    saveSelection();
    const selectedText = window.getSelection().toString();
    linkRef.current = selectedText;
  };

  const onChangeLink = (e) => {
    const selection = selectionRef.current;
    setInputLink(e.target.value);

    if (selection) {
      const linkElement = linkRef.current ? `<a href="${linkRef.current}">${selection}</a>` : selection;

      if (selection.toString().startsWith('<span')) {
        const newContent = contentRef.current.innerHTML.replace(
          selection.toString(),
          `<span class="highlight">${linkElement}</span>`
        );
        contentRef.current.innerHTML = newContent;
      } else {
        const newContent = contentRef.current.innerHTML.replace(
          selection.toString(),
          `<span class="highlight">${linkElement}</span>`
        );
        contentRef.current.innerHTML = newContent;
      }

      linkRef.current = '';
    }
  };

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelection);

    return () => {
      document.removeEventListener('selectionchange', handleSelection);
    };
  }, []);

  const onBlurUpdate = async (e) => {
    const sel = window.getSelection();
    const activeSlideFieldInfo = {
        slideIndex,
        fieldID: activeField?.key,
    };

    if (!sel.isCollapsed) {
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0).cloneRange();
        if (range.collapsed) {
          return;
        }

        const parentElement =
          sel.getRangeAt(0).commonAncestorContainer.parentElement;
        // first pass
        wrapATagLink(range, "color", e.target.value);

        // second pass, find the nested .innerTextStyle
        // and wrap the content before and after it in their own spans
        const inner = document.querySelector(".innerTextStyle .innerTextStyle");
        if (!inner) {
          // when selecting two .innerTextStyle start to end, where the empty spans stick around
          // we remove them here
          range.startContainer.parentNode
            .querySelectorAll(".innerTextStyle:empty")
            .forEach((node) => node.remove());

          const updatedContent = await handleContentChange(
            range,
            activeField?.subtype
          );

          await updateTextContent(activeSlideFieldInfo, updatedContent);
          await storeFieldUndoRedo();
          await updateFieldCollaboration();
          // window.getSelection().removeAllRanges();
          // window.getSelection().addRange(range);

          const newRange = document.createRange();
          // Identify the new start and end positions after color modification
          const newStartContainer = range.startContainer;
          const newEndContainer = range.endContainer;
          // Create a new range and set it to the desired portion
          newRange.setStart(newStartContainer, range.startOffset);
          newRange.setEnd(newEndContainer, range.endOffset);
          // Set the start and end points of the range to select the current selection
          sel.removeAllRanges();
          sel.addRange(newRange);
          await updateTextContent(activeSlideFieldInfo, updatedContent);
        //   setColor(value);
        } 
        // else {
        //   const parent = inner.closest(".innerTextStyle:not(:scope)");
        //   const extractingRange = document.createRange();
        //   // wrap the content before
        //   extractingRange.selectNode(parent);
        //   extractingRange.setEndBefore(inner);

        //   const payload = {
        //     "font-style": parentElement.style.fontStyle, //"italic",
        //     "font-weight": parentElement.style.fontWeight,
        //     color: parentElement.style.color,
        //   };
        //   wrapRangeInNestedWordSpan(extractingRange, payload);

        //   // wrap the content after
        //   extractingRange.selectNode(parent);

        //   // Capture the selection offsets
        //   // Code order matters here
        //   const selectionStartOffset = extractingRange.startOffset;
        //   const selectionEndOffset = extractingRange.endOffset;

        //   extractingRange.setStartAfter(inner);
        //   wrapRangeInNestedWordSpan(extractingRange, payload);

        //   // finally, extract all the contents from parent
        //   // to its own parent and remove it, now that it's empty
        //   moveContentBefore(parent);

        //   const updatedContent = await handleContentChange(
        //     range,
        //     activeField.subtype
        //   );
        //   setColor(value);
        //   await updateTextContent(activeSlideFieldInfo, updatedContent);

        //   const newRange = document.createRange();
        //   // Identify the new start and end positions after color modification
        //   const newStartContainer = range.startContainer;
        //   const newEndContainer = range.endContainer;
        //   // Create a new range and set it to the desired portion
        //   newRange.setStart(newStartContainer, selectionStartOffset);
        //   newRange.setEnd(newEndContainer, selectionEndOffset);
        //   // Set the start and end points of the range to select "World"
        //   sel.removeAllRanges();
        //   sel.addRange(newRange);
        // }
      }
    }
    await storeFieldUndoRedo();
    await updateFieldCollaboration();
  }

  return (
    <Wrapper>
        <WrapTitle>
            <Title>Link Type</Title>
        </WrapTitle>

        <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
        <Input
            value={linkRef.current}
            onChange={onChangeLink}
            onBlur={onBlurUpdate}
            // onChange={(e) => (linkRef.current = e.target.value)}
            onFocus={restoreSelection}
            placeholder="Link or Slide No"
        />
        </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const WrapTitle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Input = styled.input`
  display: flex;
  width: auto;
  max-width: 132px;
  min-width: 100px;
  height: 30px;
  color: ${({theme}) => theme.deskSecondaryTxt};
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  fill: ${({theme}) => theme.deskPrimaryTxt};
  border: none;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    slides: selectTemplate(designTemplate),
    mode: selectPageTheme(designTemplate),
    slideIndex: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateFieldStyles,
    addLinkToField,
    updateFieldCollaboration,
    updateTextContent,
    storeFieldUndoRedo
  }))
)(AddLink);
