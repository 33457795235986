import { calcRotatedOffsets } from "../../../../components/editDesign/canvasPanel/dragResize/resize/utils/calcRotatedOffsets";
import {shapeStyles} from "../../../../components/editDesign/modal/create/shapes/CreateShape";
import { themeBuilder } from "../../data/themeBuilder.data";
import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import {getHighestZIndex} from "./utils";

export function createShape() {
  return function (state, action) {
    const {template} = state;
    const {slideID, payload, selectFieldOnCreate} = action;
    const key = payload.key;
    let highestZIndex = getHighestZIndex(template, slideID);
    const currentSlide = template[slideID];
    Object.entries(currentSlide.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });
    const adjustedPos = (10 * state.size.w) / 100;
    const adjustedWidth = (payload.size.w * state.size.w) / 100;
    const adjustedHeight = (payload.size.h * state.size.w) / 100;
    let currentTheme = state.themes.find(
      (theme) => theme.id === state.activeTheme
    );
    if(typeof currentTheme === 'undefined') {
      currentTheme = themeBuilder
    }
    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });
    const newFieldProps = {
      key,
      pos: {x: adjustedPos, y: adjustedPos},
      size: {
        w: adjustedWidth,
        h:
          payload.subtype === "line"
            ? adjustedHeight + 10 / state.layout.tempScale
            : adjustedHeight,
      },
      type: payload.type,
      subtype: payload.subtype,
      name: payload.name,
      resizer: payload.resizer,
      deleted: false,
      ["styles"]: {
        enableStroke: false, // place before variables
        ...shapeStyles,
        ...payload.styles,
        color1:
          payload.styles?.color1 === 'transparent' ? 'transparent' :
          payload.subtype === "line"
            ? currentTheme?.shape?.lineColor
            : currentTheme?.shape?.color1,
        color2: payload.styles?.color2 === 'transparent' ? 'transparent' : currentTheme?.shape?.color2,
        stroke: currentTheme?.shape?.stroke,
        lock: false,
        animationType: 'none',
        animateOnSlideDisplay: null,
        animationSpeed: 0.5,
        animationDelay: 0,
        animationOrder: 0,
        enableExitAnimation: false,
        enabledShadow: false,
        shadowColor: '#6a6565ff',
        rotate: 0,
        zIndex: highestZIndex + 1,
        disableApplyingTheme: false
      },
    };
    const newState = [...state.template];
    newState[slideID].fields = {
      ...newState[slideID].fields,
      [key]: newFieldProps,
    };
    return {
      ...state,
      template: newState,
      activeField: key,
      selectedFieldType: payload.type,
      cachedFieldStyles: newState[slideID].fields[key].styles,
      groupSelect: initGroupSelect,
      fieldToEmitFromSocket: {
        type: "create",
        field: {
          ...newFieldProps,
          key,
        },
      },
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
    };
  };
}

export function straightenShapeLine() {
  return function (state, action) {
    const {activeSlide, template, activeField} = state;
    const newState = [...template];
    const {payload} = action;
    const field = newState[activeSlide].fields[activeField];
    const { pos, size, styles } = field;
    if (action.lineType === "vertical") {
      const {
        topLeft, topRight, btmRight, btmLeft
      } = calcRotatedOffsets(pos, size, styles.rotate)

      newState[activeSlide].fields[activeField] = {
        ...newState[activeSlide].fields[activeField],
        pos: payload.offset,
        size: payload.size,
        ["styles"]: {
          ...newState[activeSlide].fields[activeField].styles,
          rotate: payload.angle,
        },
        rotatedOffsets: {
          topLeft: {
            x: topLeft.x,
            y: topLeft.y,
          },
          topRight: {
            x: topRight.x,
            y: topRight.y,
          },
          btmLeft: {
            x: btmLeft.x,
            y: btmLeft.y,
          },
          btmRight: {
            x: btmRight.x,
            y: btmRight.y,
          },
        },
      };
    } else {
      newState[activeSlide].fields[activeField] = {
        ...newState[activeSlide].fields[activeField],
        pos: payload.offset,
        ["styles"]: {
          ...newState[activeSlide].fields[activeField].styles,
          rotate: 0,
        },
        rotatedOffsets: {
          topLeft: {
            x: 0,
            y: 0,
          },
          topRight: {
            x: 0,
            y: 0,
          },
          btmLeft: {
            x: 0,
            y: 0,
          },
          btmRight: {
            x: 0,
            y: 0,
          },
        },
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
}
