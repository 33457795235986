import { black1 } from "../../../oat-color-variables";
import styled from "styled-components";

export const WrapButton = styled.div`
  width: 150px;
  height: 42px;
`;

export const WrapSection = styled.div`
  @media only screen and (min-width: 1500px) {
    width: 100%;
    max-width: 1400px;
  }
`;

export const NothingHere = styled.div`
  font-size: 1.4rem;
  color: ${black1};
  margin-top: 14%;
  text-align: center;
`;

export const PricingTag = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const WrapSpinner = styled.div`
  height: 380px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    height: 250px;
  }
`;