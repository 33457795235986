import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import IncrementDropDown from "../../../../../../ui/inputs/IncrementDropDown";
import {InputName} from "../common/WrapColorStyles";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

export const barSizes = [
  {value: 6, display: "6"},
  {value: 8, display: "8"},
  {value: 10, display: "10"},
  {value: 12, display: "12"},
  {value: 14, display: "14"},
  {value: 16, display: "16"},
  {value: 18, display: "18"},
  {value: 20, display: "20"},
  {value: 25, display: "25"},
  {value: 30, display: "30"},
  {value: 45, display: "45"},
  {value: 60, display: "60"},
  {value: 80, display: "80"},
];

const ChartBarSize = ({
  fieldID,
  slideID,
  updateFieldStyles,
  barSize,
  tempScale,
  updateFieldCollaboration,
}) => {
  const changeBarSize = async (value) => {
    const updatedValue = Number(value / tempScale);
    await updateFieldStyles({
      active: {slideID, fieldID},
      type: "barSize",
      value: updatedValue,
    });
    await updateFieldCollaboration();
  };

  return (
    <Wrapper>
      <InputName>Bar Width</InputName>
      <WrapDropDown>
        <IncrementDropDown
          onSelect={changeBarSize}
          selected={Number((barSize * tempScale).toFixed(1))}
          options={barSizes}
          minWidth={70}
          overflowY="scroll"
          height={160}
        />
      </WrapDropDown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempScale: selectTempScale(designTemplate),
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  updateFieldCollaboration,
})(ChartBarSize);
