import React, {useState} from "react";
import styled from "styled-components";
import {red4} from "../../../../../../../oat-color-variables";
import {LeftArrow} from "../../../../../../../oat-svg-icons";
import {Spinner} from "../../../../../../../oat-ui";
import {genUID} from "../../../../../../../oat-utility";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import CustomLinkPasscode from "./CustomLinkPasscode";

export const domainLink = "oatall.com/link/";

const CreateCustomLink = ({onCreate, processing, back, error}) => {
  const [item, setItem] = useState({
    linkSlug: "",
    linkName: "",
    slugID: genUID(4), // to store link's ending uid
    uid: genUID(6), // to identify item when updating & deleting
    passcode: {
      enabled: false,
      value: "",
    },
  });

  const handleInputChange = (e) => {
    const {value} = e.target;
    const linkSlug = value.replace(/\W+/g, "-").toLowerCase();

    setItem({
      ...item,
      linkName: value,
      linkSlug: linkSlug + "-" + item.slugID,
    });
  };

  const handleUpdate = () => {
    if (item.linkName !== "") {
      onCreate(item);
    }
  };

  const togglePasscodeStatus = () => {
    setItem({
      ...item,
      passcode: {
        ...item.passcode,
        enabled: !item.passcode.enabled,
      },
    });
  };

  const handlePasscodeChange = (e) => {
    const {value} = e.target;
    setItem({
      ...item,
      passcode: {
        ...item.passcode,
        value,
      },
    });
  };

  return (
    <Layout>
      <WrapSvg onClick={back}>{LeftArrow}</WrapSvg>
      <WrapItem>
        <LinkName
          value={item.linkName}
          autoFocus
          placeholder="Link Name"
          onChange={handleInputChange}
        />

        <Link>
          <span style={{paddingRight: 18}}>Link:</span>{" "}
          {domainLink + item.linkSlug}
        </Link>

        <CustomLinkPasscode
          passcode={item.passcode}
          onToggleStatus={togglePasscodeStatus}
          onPasscodeChange={handlePasscodeChange}
        />

        <SaveSection>
          {processing ? (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          ) : undefined}
          <SaveBtn>
            <SpecialButton
              onClick={handleUpdate}
              disabled={item.linkName === "" || processing}
            >
              Create
            </SpecialButton>
          </SaveBtn>
        </SaveSection>

        {error.status ? (
          <CustomLinkError>{error.message}</CustomLinkError>
        ) : undefined}
      </WrapItem>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 26px;
`;

const WrapSvg = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: ${({theme}) => theme.svgIconColor};
`;

const WrapItem = styled.div`
  font-size: 0.92rem;
  color: ${({theme}) => theme.deskPrimaryTxt};
  margin-top: 32px;
`;

const LinkName = styled.input`
  width: 100%;
  font-size: 1rem;
  margin-bottom: 22px;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 2px solid #4355b1;
`;

const Link = styled.div`
  font-size: 1rem;
  color: ${({theme}) => theme.deskSecondaryTxt2};
`;

const SaveSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
`;

const SaveBtn = styled.div`
  height: 36px;
`;

export const WrapSpinner = styled.div`
  width: 22px;
  height: 100%;
  margin-right: 32px;
  transform: scale(0.7);
`;

export const CustomLinkError = styled.div`
  color: ${red4};
  text-align: right;
  font-size: 1rem;
  margin: 12px 0;
  padding: 6px;
`;

export default CreateCustomLink;
