import React, {useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {LeftArrow} from "../../../../../oat-svg-icons";
import {Button, Modal} from "../../../../../oat-ui";

const UpdateProfileBio = ({close, profileInfo, changeModal}) => {
  const [bio, setBio] = useState(profileInfo.bio);

  const onInputChange = (e) => {
    const {value} = e.target;
    setBio(value);
  };

  const goBack = () => {
    changeModal("profile-image-and-cover");
  };

  const handleUpdate = () => {};

  return (
    <Modal width={420} height={270} close={close}>
      <Layout>
        <Back>
          <WrapSvg onClick={goBack}>{LeftArrow}</WrapSvg>
        </Back>
        <Wrapper>
          <TextArea
            value={bio}
            placeholder="Profile's Bio"
            onChange={onInputChange}
          />
          <UpdatePanel>
            <WrapButton>
              <Button onClick={handleUpdate}>Update</Button>
            </WrapButton>
          </UpdatePanel>
        </Wrapper>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  padding: 6px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 10px 0;
`;

const TextArea = styled.textarea`
  min-height: 110px;
  box-sizing: border-box;
  border: 1px solid #e9e9e9;
  background: #f4f0ff;
  font-size: 1rem;
  resize: none;
  padding: 4px 0;
  font-family: inherit;
  color: #2b2b2b;
`;

const UpdatePanel = styled.div`
  height: 32px;
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
`;

const WrapButton = styled.div`
  width: 100px;
`;

const Back = styled.div`
  margin: 10px 0 0 10px;
`;

const WrapSvg = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps)(UpdateProfileBio);
