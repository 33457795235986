import React from "react";
import {connect} from "react-redux";
import {selectTempScale} from "../../../../../../../store/selectors/template/template.selector";

const YScaleGrid = ({color, height, item, gap, tempScale, gridStyle}) => {
  return [...Array(item.lines)].map((x, i) => {
    return (
      <g key={i}>
        <line
          x1={gap * i}
          x2={gap * i}
          y1={0}
          y2={height}
          stroke={color}
          strokeWidth={1}
          strokeDasharray={gridStyle === "dashed" ? 5 : 0}
        />
      </g>
    );
  });
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(YScaleGrid);
