import React from "react";
import {useParams} from "react-router";
import {Screenshot} from "../../../../packages/oat-design-template";

const AdminScreenshot = () => {
  let params = useParams();
  const {tempID} = params;
  return (
    <Screenshot
      api={`https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template/${tempID}`}
    />
  );
};

export default AdminScreenshot;
