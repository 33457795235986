import React from "react";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import { Instagram, LinkedIn } from "../../oat-svg-icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Layout>
      <LeftSection>
        <div>
          {/* <Item to="/product-features">Features</Item> */}
          <Item to="/faq">FAQ</Item>
          <Item to="/blogs">Blog</Item>
          <Item to="/pricing">Pricing</Item>
          <Item to="/privacy-policy">Privacy Policy</Item>
          <Item to="/terms-of-use">Terms of Use</Item>
          {/* <Item to="/careers">Careers</Item> */}
        </div>
      </LeftSection>
      <RightSection>
        <div style={{display: 'flex', margin: '20px 0', fill: '#3b3b3b'}}>
          <WrapSvg href="https://www.instagram.com/hello_oatall" target="_blank" aria-label="Instagram Link">
            {Instagram}
          </WrapSvg>
          <WrapSvg href="https://www.linkedin.com/company/oat-all" target="_blank" aria-label="Linkedin Link">
            {LinkedIn}
          </WrapSvg>
        </div>
        © {currentYear} | Oat All Limited, All Rights Reserved
      </RightSection>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgb(255 255 255 / 60%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  // position: fixed;
  // bottom: 0;
  width: 100%;
  margin-bottom: 34px;
  border-top: 1px solid #dedede;
  @media only screen and (max-width: 850px) {
    height: auto;
    padding: 18px 0;
    flex-direction: column;
  }
`;

const reuseStyles = css`
  display: flex;
  font-size: 1rem;
  padding: 0 72px;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    font-size: 0.94rem;
    padding: 10px 22px;
  }
`;

const LeftSection = styled.div`
  ${reuseStyles};
  align-items: flex-end;
  @media only screen and (max-width: 1000px) {
    justify-content: flex-end;
    text-align: right;
  }
`;

const WrapSvg = styled.a`
  width: 24px;
  height: 24px;
  margin-left: 14px;
  cursor: pointer;
`

const RightSection = styled.div`
  ${reuseStyles};
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  @media only screen and (max-width: 850px) {
    font-size: 0.94rem;
  }
`;

const Item = styled(Link)`
  padding-right: 22px;
  cursor: pointer;
  color: #2b2b2b;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
  &:last-child {
    padding-right: 0px;
  }
`;

export default Footer;
