import React, {useState, useEffect} from "react";
import {timeSince} from "../../../../../../../oat-utility";
import {
  Time,
  Username,
  WrapComment,
  WrapItem,
  Comment,
  Reply,
  WrapCommentBtn,
  WrapActions,
  EditCancel,
  WrapSpinner,
  ResponseLength,
  UsernameAndTime,
  Edited,
} from "./commentStyles";
import {Spinner} from "../../../../../../../oat-ui";
import CommentActions from "./CommentActions";
import SpecialButton from "../../../../../../ui/inputs/SpecialButton";
import {connect} from "react-redux";
import {selectActiveSlideInfo} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectTemplateID,
  selectTempSourceID,
} from "../../../../../../store/selectors/template/template.selector";
import {updateSlideComment} from "../../../../../../store/actions/template/comment.action";
import {
  Mention,
  MentionsInput,
} from "react-mentions/dist/react-mentions.cjs.prod";
import {darkTheme, lightTheme} from "./inputStyles";
import {useSocket} from "../../../../../../webSocket/useSocket";

const DisplayComment = ({
  item,
  onDelete,
  hideReplySection,
  username,
  updateTimeDiff,
  deleting,
  slideID,
  tempSourceID,
  tempID,
  onViewResponse,
  updateSlideComment,
  highLightComment,
  theme,
  searchList,
}) => {
  const [commentInput, setCommentInput] = useState(item.comment);
  const [timeDiff, setTimeDiff] = useState();
  const [processing, setProcessing] = useState(false);
  const [editing, setEditing] = useState({
    status: false,
    commentID: null,
  });
  const {emitSocketEvents} = useSocket() || {};

  useEffect(() => {
    const getTimeDiff = calculateTimeSince();
    setTimeDiff(getTimeDiff);
  }, [updateTimeDiff, item.createdAt]);

  const calculateTimeSince = () => {
    const currentTimeStamp = new Date().getTime();
    // let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    // let oneHourAgo = new Date(new Date().valueOf() - 1000 * 60 * 60);
    return timeSince(currentTimeStamp, item.createdAt); //item.time
  };

  const handleCommentChange = (e) => {
    const {value} = e.target;
    setCommentInput(value);
  };

  const submitChanges = () => {
    if (!processing && username === item.commentedBy.username) {
      setProcessing(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/comments/${item.ID}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            tempSourceID,
            slideID,
            comment: commentInput,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const payload = {
            updatingMainThread: true,
            slideID,
            comment: {
              ID: item.ID,
              comment: commentInput,
              actionRequire: item.actionRequire,
              createdAt: data.response.updatedAt,
            },
          };
          setProcessing(false);
          updateSlideComment(payload);
          if (emitSocketEvents) {
            emitSocketEvents({
              actionType: "update-comment",
              item: {
                type: null,
                value: payload,
              },
            });
          }
          onCancelEditStatus();
        })
        .catch((e) => setProcessing(false));
    }
  };

  const onEditStatus = (id) => {
    setEditing({
      status: true,
      commentID: id,
    });
  };

  const onCancelEditStatus = (id) => {
    setEditing({
      status: false,
      commentID: null,
    });
  };

  const viewResponses = () => {
    onViewResponse({item, isResponding: false});
  };

  const replyToThread = () => {
    onViewResponse({item, isResponding: true});
  };

  const regex = /\@\[([^\]]+)\]\([^\)]+\)/g;

  const [containsMentionUser, setContainsMentionUser] = useState(false);
  useEffect(() => {
    if (item?.actionRequire?.status) {
      const isMentionedUser = item?.mentionedUsers && 
        item?.mentionedUsers.find((user) => user.username === username) || {}
      
      setContainsMentionUser(Object.keys(isMentionedUser)?.length > 0 ? true : false);
    }
  }, [item?.actionRequire?.status, item?.mentionedUsers, username])

  return (
    <WrapComment
      id={item.ID}
      hide={hideReplySection}
      highlightComment={highLightComment} // when user open from commented notification link
      actionRequire={item.actionRequire.status}
      actionResolved={item.actionRequire.resolved}
    >
      <WrapItem>
        <UsernameAndTime>
          <Username>{item.commentedBy.name}</Username>
          <Time>{timeDiff}</Time>
          <Edited>{item.edited ? "(Edited)" : undefined} </Edited>
        </UsernameAndTime>

        {!editing.status && (item.commentedBy.username === username || containsMentionUser) ? (
          <CommentActions
            item={item}
            subcomment={false}
            onEdit={onEditStatus}
            onDelete={onDelete}
            deleting={deleting}
            isMentionedUser={containsMentionUser} // if viewing comment is in a mentioned user
          />
        ) : undefined}
      </WrapItem>

      {!editing.status ? (
        <Comment
          contentEditable="false"
          dangerouslySetInnerHTML={{
            __html: item.comment.replace(regex, "<span>@$1</span>"),
          }}
          suppressContentEditableWarning={true}
        />
      ) : (
        <MentionsInput
          style={theme === "light" ? lightTheme : darkTheme}
          value={commentInput}
          onChange={handleCommentChange}
          appendspaceonadd="true"
        >
          <Mention
            displayTransform={(id, name) => `@${name}`}
            style={{
              backgroundColor: theme === "light" ? "#b7b9ff" : "#171dff",
            }}
            trigger="@"
            data={searchList}
            // onAdd={handleAdd}
          />
        </MentionsInput>
      )}

      <Reply hide={hideReplySection}>
        {!editing.status && item.subComments ? (
          <ResponseLength onClick={viewResponses}>
            {item.subComments.length > 0
              ? item.subComments.length.toString()
              : undefined}
            {item.subComments.length >= 2 ? " Replies" : undefined}
            {item.subComments.length === 1 ? " Reply" : undefined}
          </ResponseLength>
        ) : (
          <div />
        )}

        <WrapActions>
          {processing ? (
            <WrapSpinner>
              <Spinner />
            </WrapSpinner>
          ) : undefined}

          {!editing.status ? (
            <div onClick={replyToThread}>Reply</div>
          ) : (
            <WrapCommentBtn>
              <EditCancel onClick={onCancelEditStatus}>Cancel</EditCancel>
              <SpecialButton disabled={processing} onClick={submitChanges}>
                Edit
              </SpecialButton>
            </WrapCommentBtn>
          )}
        </WrapActions>
      </Reply>
    </WrapComment>
  );
};

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlideInfo(state.designTemplate).id,
    tempSourceID: selectTempSourceID(state.designTemplate),
    tempID: selectTemplateID(state.designTemplate),
  };
};

export default connect(mapStateToProps, {updateSlideComment})(DisplayComment);
