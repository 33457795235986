import React, {useState, useEffect, useLayoutEffect, useCallback} from "react";
import {connect} from "react-redux";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import {ScaledChart, WrapChart} from "../chartStyles";
import ScaleGrid from "./barProps/ScaleGrid";
import ScaleLabels, { compactFormatter, formatWithCommas } from "./barProps/ScaleLabels";
import CategoryLabels from "./common/CategoryLabels";
import ShowLegends from "./common/ShowLegends";
import {fixFloatingPoint, useScaleLine} from "./hooks/useScaleLine";

// Stacked
const Bar = ({
  fill = "#ffd13d",
  x,
  y,
  heightInPx,
  width,
  value,
  fontSize,
  valueColor,
  showValue,
  scaleFormat
}) => {

  const formatScaleValues = useCallback(
    (item) => {
      // compact notion
      if (scaleFormat === "1k") {
        return compactFormatter(item, 1);
      } else if (scaleFormat === "1,000") {
        return formatWithCommas(item);
      } else {
        return item;
      }
    },
    [scaleFormat]
  );

  return (
    <g>
      <rect fill={fill} x={x} y={y} height={heightInPx} width={width} />
      {showValue ? (
        <g
          style={{
            width,
            transform: `translate(${x + width / 2}px, ${heightInPx / 2 + y}px)`,
          }}
        >
          <text
            style={{
              textAnchor: "middle",
              alignmentBaseline: "central",
              dominantBaseline: "central",
              fontSize: fontSize,
              fill: valueColor,
            }}
          >
            {formatScaleValues(value)}
          </text>
        </g>
      ) : undefined}
    </g>
  );
};

const StackedColumn = ({size, styles, colorSet, dataSeries, tempScale}) => {
  const [dataSet, setDataSet] = useState(dataSeries.slice(1));
  const width = size.w;
  const height = size.h;
  const {
    showBackground,
    background,
    gridColor,
    showGrid,
    showValue,
    valueColor,
    fontSize,
    fontFamily,
    barSize,
    showLegend,
    showXAxis,
    showYAxis,
    axisColor,
    showCategory,
    measurementColor,
    measurementFontSize,
    showMeasurement,
    gridStyle,
    scaleDistance,
    currencyUnit,
    scaleType,
    scaleFormat,
  } = styles;
  const [edgeSpace, setEdgeSpace] = useState(0);
  const [highestPositiveValue, setHighestPositiveValue] = useState(0);
  const [lowestNegativeValue, setLowestNegativeValue] = useState(0);
  const [chartInfo, setChartInfo] = useState([]);
  const [barWidth, setBarWidth] = useState(barSize);
  const [categoryLabels, setCategoryLabels] = useState([]);
  const [barMargin, setBarMargin] = useState(
    (size.w - (barWidth * dataSet.length + 2 * edgeSpace)) /
      (dataSet.length - 1)
  );

  useEffect(() => {
    let categories = [];
    const dataSet = dataSeries?.slice(1);
    setDataSet(dataSet);
    const getHighestPositiveByCategory =
      dataSet &&
      dataSet.map((datum) => {
        return datum.reduce((acc, item, i) => {
          if (item > 0 && item !== "") {
            return fixFloatingPoint(acc + item);
          }
          return acc;
        }, 0);
      });
    const getHighestNegativeByCategory =
      dataSet &&
      dataSet.map((datum) => {
        categories.push(datum[0]);
        return datum.reduce((acc, item, i) => {
          if (item < 0 && item !== "") {
            return fixFloatingPoint(acc + item);
          }
          return acc;
        }, 0);
      });
    setCategoryLabels(categories);
    setHighestPositiveValue(Math.max(...getHighestPositiveByCategory)); // 15 change here
    setLowestNegativeValue(Math.min(...getHighestNegativeByCategory));
  }, [dataSeries]);

  const {chartSpectrum, scaleLine, digitsLength} = useScaleLine({
    highestPositiveValue,
    lowestNegativeValue,
  });

  const [totalValueHeight, setTotalValueHeight] = useState(0);
  useEffect(() => {
    setTotalValueHeight(chartSpectrum.positive + chartSpectrum.negative);
  }, [chartSpectrum]);

  useLayoutEffect(() => {
    let getYOffsets = 0;
    const dataSet = dataSeries.slice(1);
    // if chartSpectrum.positive === 0 -> all values are negative
    const chartHeight =
      chartSpectrum.positive === 0
        ? Math.round((height * chartSpectrum.negative) / totalValueHeight)
        : Math.round((height * chartSpectrum.positive) / totalValueHeight);

    const onSortChartValues = () => {
      return (
        chartHeight !== Infinity &&
        dataSet &&
        dataSet.map((innerArr) => {
          let negAcc = 0,
            posAcc = 0;
          const datum = innerArr.slice(1);

          return {
            items:
              datum &&
              datum.map(function (number, i) {
                const item = number === "" ? 0 : number;
                if (i === 0) {
                  if (item > 0) {
                    getYOffsets =
                      (chartHeight * (chartSpectrum.positive - item)) /
                      chartSpectrum.positive;
                  } else if (item < 0) {
                    getYOffsets =
                      chartSpectrum.positive === 0
                        ? 0
                        : (chartHeight * chartSpectrum.negative) /
                          chartSpectrum.negative;
                  }

                  if (item > 0) {
                    posAcc = Math.abs(item);
                  } else if (item < 0) {
                    negAcc = Math.abs(item);
                  }

                  return {
                    value: item === 0 ? "" : item,
                    yOffset: getYOffsets !== -Infinity ? getYOffsets : 0,
                  };
                } else {
                  if (item > 0) {
                    getYOffsets =
                      (chartHeight *
                        (chartSpectrum.positive - (item + posAcc))) /
                      chartSpectrum.positive;
                  } else if (item < 0) {
                    getYOffsets =
                      chartSpectrum.positive === 0
                        ? 0 + (negAcc * size.h) / totalValueHeight
                        : chartHeight + (negAcc * size.h) / totalValueHeight;
                  }

                  if (item < 0) {
                    negAcc = negAcc + Math.abs(item);
                  } else if (item > 0) {
                    posAcc = posAcc + Math.abs(item);
                  }

                  return {
                    value: item === 0 ? "" : item,
                    yOffset: getYOffsets !== -Infinity ? getYOffsets : 0,
                  };
                }
              }),
          };
        })
      );
    };
    if (chartSpectrum.positive || chartSpectrum.negative) {
      const getChartInfo = onSortChartValues();
      setChartInfo(getChartInfo);
    }

    const calcMargin = size.w - dataSet.length * barSize;
    setBarMargin(calcMargin / dataSet.length);
    setEdgeSpace(calcMargin / dataSet.length / 2);
  }, [totalValueHeight, size, chartSpectrum, dataSeries, barSize]);

  useEffect(() => {
    setBarWidth(barSize);
    setBarMargin(
      (size.w - (barWidth * dataSet.length + 2 * edgeSpace)) /
        (dataSet.length - 1)
    );
    setEdgeSpace(
      (size.w - (barWidth * dataSet.length + 2 * edgeSpace)) /
        (dataSet.length - 1) /
        2
    );
  }, [barSize, dataSeries]);

  let totalPosHeightInPx = 0,
    totalNegHeightInPx = 0;
  totalPosHeightInPx = (chartSpectrum.positive * size.h) / totalValueHeight;
  totalNegHeightInPx = (chartSpectrum.negative * size.h) / totalValueHeight;

  // const getTopRow = dataSeries[0];
  // const legendData = getTopRow.slice(1);
  // let legend = [];
  // legendData.map((item, i) => legend.push(item));

  return (
    <WrapChart>
      <ScaledChart>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          style={{background: showBackground ? background : undefined}}
        >
          {totalValueHeight && chartInfo ? (
            <g>
              {chartInfo &&
                chartInfo.map(
                  (datum, index) =>
                    datum.items &&
                    datum.items.map((item, i) => {
                      const itemHeight =
                        item.value > 0
                          ? (item.value * totalPosHeightInPx) /
                            chartSpectrum.positive
                          : (Math.abs(item.value) * totalNegHeightInPx) /
                            chartSpectrum.negative;
                      return item.value !== -Infinity ? (
                        <Bar
                          key={i}
                          fill={colorSet[i]}
                          x={index * (barWidth + barMargin) + edgeSpace}
                          y={item.yOffset}
                          width={barWidth}
                          heightInPx={itemHeight}
                          value={item.value}
                          fontSize={fontSize}
                          valueColor={valueColor}
                          showValue={showValue}
                          scaleFormat={scaleFormat}
                        />
                      ) : undefined;
                    })
                )}
            </g>
          ) : undefined}

          {showGrid ? (
            scaleLine && Object.keys(scaleLine).length !== 0 ? (
              <g>
                <ScaleGrid
                  color={gridColor}
                  item={scaleLine}
                  width={width}
                  gap={(scaleLine.acc * height) / scaleLine.total}
                  gridStyle={gridStyle}
                />
              </g>
            ) : undefined
          ) : undefined}

          {showCategory ? (
            <CategoryLabels
              styles={styles}
              chartHeight={size.h}
              labels={categoryLabels}
              barMargin={barMargin}
              barWidth={barWidth}
              edgeSpace={barMargin / 2}
              seriesLength={1}
            />
          ) : undefined}

          {showXAxis &&
          (chartSpectrum.positive || chartSpectrum.negative) &&
          totalValueHeight ? (
            <g>
              <line
                x1={0}
                x2={size.w}
                y1={(size.h * (chartSpectrum.positive - 0)) / totalValueHeight}
                y2={(size.h * (chartSpectrum.positive - 0)) / totalValueHeight}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              />
            </g>
          ) : undefined}

          {showYAxis ? (
            <g>
              <line
                x1={0}
                x2={0}
                y1={0}
                y2={size.h}
                stroke={axisColor}
                strokeWidth={1 / tempScale}
              ></line>
            </g>
          ) : undefined}

          {showMeasurement ? (
            (chartSpectrum.positive || chartSpectrum.negative) &&
            scaleLine &&
            Object.keys(scaleLine).length !== 0 ? (
              <g>
                <ScaleLabels
                  fontSize={measurementFontSize}
                  fontFamily={fontFamily}
                  color={measurementColor}
                  tickColor={axisColor}
                  item={scaleLine}
                  gap={(scaleLine.acc * size.h) / scaleLine.total}
                  highestValue={chartSpectrum.positive}
                  digitsLength={digitsLength}
                  scaleDistance={scaleDistance}
                  scaleType={scaleType}
                  scaleFormat={scaleFormat}
                  currencyUnit={currencyUnit}
                />
              </g>
            ) : undefined
          ) : undefined}
        </svg>

        {showLegend ? (
          <ShowLegends
            items={dataSeries[0].slice(1)}
            chartSize={size}
            styles={styles}
            colorSet={colorSet}
          />
        ) : undefined}
      </ScaledChart>
    </WrapChart>
  );
};

const mapStateToProps = (state) => {
  return {
    tempScale: selectTempScale(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(StackedColumn);
