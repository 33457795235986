export const TableHeaderTop = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.6 227">
        <title>Header Top</title>
        <g>
            <rect x="0.5" y="0.5" width="397.2" height="226.03" fill="#fff" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="0.5" y="0.5" width="397.2" height="74.52" fill="#c5c7ff" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="0.5" y="75" width="397.2" height="74.52" fill="#fff" stroke="#3a3838" stroke-miterlimit="10"/>
            <polyline points="140.6 226.5 0.5 226.5 0.5 0.5 140.6 0.5" fill="none" stroke="#fff" stroke-miterlimit="10"/>
            <polyline points="257.7 0.5 397.8 0.5 397.8 226.5 257.7 226.5" fill="none" stroke="#fff" stroke-miterlimit="10"/>
            <rect x="0.5" y="0.5" width="130.8" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="131.2" y="0.5" width="133.7" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="265" y="0.5" width="133.2" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
        </g>
    </svg>
);

export const TableHeaderLeft = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.6 227">
        <title>Header Left</title>
        <g>
            <rect x="0.5" y="0.5" width="397.2" height="226.03" fill="#fff" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="0.5" y="0.5" width="130.7" height="226.03" fill="#c5c7ff" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="0.5" y="75" width="397.2" height="74.52" fill="#fff" stroke="#3a3838" stroke-miterlimit="10"/>
            <polyline points="140.6 226.5 0.5 226.5 0.5 0.5 140.6 0.5" fill="none" stroke="#fff" stroke-miterlimit="10"/>
            <polyline points="257.7 0.5 397.8 0.5 397.8 226.5 257.7 226.5" fill="none" stroke="#fff" stroke-miterlimit="10"/>
            <rect x="0.5" y="0.5" width="130.8" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="131.2" y="0.5" width="133.7" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="265" y="0.5" width="133.2" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="0.5" y="75" width="130.7" height="74.52" fill="#c5c7ff" stroke="#3a3838" stroke-miterlimit="10"/>
        </g>
    </svg>
);

export const TableHeaderTopLeft = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.7 227">
        <title>Header Top Left</title>
        <g>
            <rect x="1.5" y="0.5" width="397.2" height="226.03" fill="#fff" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="0.5" y="0.5" width="397.2" height="80.1" fill="#c5c7ff" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="1.5" y="75" width="397.2" height="74.52" fill="#fff" stroke="#3a3838" stroke-miterlimit="10"/>
            <polyline points="141.6 226.5 1.5 226.5 1.5 0.5 141.6 0.5" fill="none" stroke="#fff" stroke-miterlimit="10"/>
            <polyline points="258.7 0.5 398.8 0.5 398.8 226.5 258.7 226.5" fill="none" stroke="#fff" stroke-miterlimit="10"/>
            <rect x="1.5" y="0.5" width="130.8" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="132.2" y="0.5" width="133.7" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="266" y="0.5" width="133.2" height="226.03" fill="none" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="1.5" y="75" width="130.7" height="74.52" fill="#c5c7ff" stroke="#3a3838" stroke-miterlimit="10"/>
            <rect x="1.5" y="149.5" width="130.7" height="75.74" fill="#c5c7ff" stroke="#3a3838" stroke-miterlimit="10"/>
        </g>
    </svg>
)