import {
  SET_SINGLE_BG_COLOR,
  SET_GRADIENT_BG_PROPS,
  SELECT_BACKGROUND,
  REVERT_BG_COLOR,
} from "../actionTypes";

export const setSingleBgColor = (color) => {
  return {
    type: SET_SINGLE_BG_COLOR,
    color,
  };
};

export const updateBackgroundProps = ({slideID, type, value}) => {
  return {
    type: SET_GRADIENT_BG_PROPS,
    slideID,
    key: type,
    value,
  };
};

export const selectBackground = () => {
  return {
    type: SELECT_BACKGROUND,
  };
};

export const revertBgColor = ({bg1, bg2, bgAng}) => {
  return {
    type: REVERT_BG_COLOR,
    bg1,
    bg2,
    bgAng,
  };
};
