import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {LayoutHoc} from "../../oat-public";
import ProfileCover from "../components/userProfile/ProfileCover";
import ProfileTemplates from "../components/userProfile/ProfileTemplates";
import UserInfo, { Description } from "../components/userProfile/UserInfo";
import {useLocation, useParams} from "react-router-dom";
import { getWindowSize } from "../../oat-window-size/getWindowSize";
import ProfileBio from "../components/userProfile/ProfileBio";

const UserProfile = () => {
  const [fetching, setFetching] = useState(false);
  const [templates, setTemplates] = useState([
    {
      image: "https://oat-users.s3.amazonaws.com/temp-Ka1ZDkEiJw1.jpeg",
      tempID: 'Ka1ZDkEiJw1'
    },
    {
      image: "https://oat-users.s3.amazonaws.com/temp-DKqXDludz9U.jpeg",
      tempID: 'DKqXDludz9U'
    },
    {
      image: "https://oat-users.s3.amazonaws.com/temp-GhcEQfKmhYd.jpeg",
      tempID: 'GhcEQfKmhYd'
    },
    {
      image: "https://oat-users.s3.amazonaws.com/temp-Qhf1NbIIOYs.jpeg",
      tempID: 'Qhf1NbIIOYs'
    }
    // {
    //   image: "https://images.unsplash.com/photo-1597773150796-e5c14ebecbf5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //   tempID: "c",
    // },
    // {
    //   image: "https://images.unsplash.com/photo-1677644334825-0eb411012ac0?q=80&w=3043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //   tempID: "d",
    // },
  ]);
  const [userProfile, setUserProfile] = useState({
    tags: [
      {
        id: "art",
        display: "Art",
        image:
          "https://images.unsplash.com/photo-1590787996529-a542c86ca267?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      },
      {
        id: "culture",
        display: "Culture",
        image:
          "https://plus.unsplash.com/premium_photo-1674487959529-7d4377c54737?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      },
    ],
  });
  let params = useParams();
  const {username} = params;
  const query = new URLSearchParams(useLocation().search);
  const tag = query.get("tag");
  const windowSize = getWindowSize();

  useEffect(() => {
    // setFetching(true);
    // fetch(
    //   !tag
    //     ? `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/profile?tag=`
    //     : `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/profile?tag=${tag}`
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setTemplates(data.templates);
    //     setUserProfile(data.userProfile);
    //     setFetching(false);
    //   })
    //   .catch(() => {
    //     setFetching(false);
    //   });
  }, [tag]);

  return (
    <LayoutHoc>
      <Layout>
        <ProfileCover cover={userProfile.cover} />
        <Section>
          <UserInfo item={userProfile} />

          {windowSize.width <= 600 ? <ProfileBio /> : undefined}

          <ProfileTemplates templates={templates} loading={fetching} />
        </Section>

        
      </Layout>

    </LayoutHoc>
  );
};

const Layout = styled.div`
  min-height: ${window.innerHeight}px;
  padding-bottom: 420px;
  margin: 14px;
  @media only screen and (min-width: 1600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Section = styled.div`
  @media only screen and (min-width: 1301px) {
    max-width: 1300px;
    padding: 0px calc(12vw - 85px) 0px;
  }
`;

export default UserProfile;
