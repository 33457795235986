import React, {useState, useEffect} from "react";
import {BlankTemplate} from "../../../packages/oat-design-template";
import {useParams, useNavigate} from "react-router";
import {Modal} from "../../../packages/oat-ui";
import {white} from "../../../packages/oat-design-template/themes";
import RequestTemplateInfo from "./components/RequestTemplateInfo";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../redux/user/authUser";

const AdminCreateTemplate = ({user}) => {
  let params = useParams();
  let navigate = useNavigate();
  const {category, ratio} = params;
  const [template, setTemplate] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const createTemplate = async ({payload, publish}) => {
    if (!isSaving) {
      setIsSaving(true);

      const newTimeoutId = setTimeout(() => {
        // If the request is taking too long, cancel it
        abortController.abort();
        setIsSaving(false);
      }, 45000); // 45 seconds
      setTimeoutId(newTimeoutId);

      await fetch(
        `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/templates/${category}/${ratio}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tempInfo: {
              category,
              ratio,
              size: template.info.size,
              lng: payload.lng,
              theme: {
                name: "main",
                colorPalette: {color1: "", color2: ""},
              },
              tags: payload.tags,
              premium: false,
              tempOwner: "oat-all",
              createdBy: [user.username],
              // title: "Starting Up",
              // description: "Template description goes here",
            },
            tempDetails: {
              ...template.details,
              themeCollection: [],
              currentThemeCollection: "",
            },
            publish
          }),
          signal,
        }
      )
        .then((response) => {
          clearTimeout(timeoutId);
          if (!response.ok) {
            throw new Error("Network response error");
          };
          return response.json();
        })
        .then((data) => {
          setIsSaving(false);
          navigate(`/admin/designs/edit/presentation/${data.tempID}`);
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          setIsSaving(false);
        });
    }
  };

  const addTemplateInfo = (item) => {
    setTemplate({
      details: item.template,
      info: item.templateInfo,
    });
    setOpenModal(true);
  };

  const toggleModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <BlankTemplate
        onSave={addTemplateInfo}
        buttonTitle="Create"
        isSaving={isSaving}
      />
      {openModal ? (
        <Modal width={420} height={330} close={toggleModal} background={white}>
          <RequestTemplateInfo emitTemplateInfo={createTemplate} />
        </Modal>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(AdminCreateTemplate);
