import React from "react";
import styled from "styled-components";
import LayoutHoc from "../../layout/LayoutHoc";
import PlansComparison from "../components/PlansComparison";
import PricingPlans from "../components/PricingPlans";

const Pricing = () => {
  return (
    <LayoutHoc>
      <Layout>
        <PricingPlans />

        <GapSpace />

        <ComparePlanSection>
          <PlansComparison />
        </ComparePlanSection>
      </Layout>
    </LayoutHoc>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 2.2% 2% 248px;
  @media only screen and (max-width: 900px) {
    margin: 2.2% 2% 190px;
  }
`;

const GapSpace = styled.div`
  margin-top: 64px;
`;

const ComparePlanSection = styled.div`
  @media only screen and (min-width: 1300px) {
    width: 100%;
    max-width: 1140px;
  }
  @media only screen and (max-width: 1299px) and (min-width: 767px) {
    width: 95%;
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 22px;
    box-sizing: border-box;
  }
`;

export default Pricing;
