import {sectionPanelWidth} from "../../sections/Sections";
import {dashboardSidebarWidth} from "../../sidebar/UserSidebar";
import {panelLeftRightPadding} from "../DashboardPanel";

export function getDashboardLayout(windowWidth) {
  let gapSize = 20;
  let itemsPerRow = 3;
  if (windowWidth >= 1150 && windowWidth < 1491) {
    itemsPerRow = 3;
  } else if (windowWidth >= 1491 && windowWidth < 1801) {
    itemsPerRow = 4;
  } else if (windowWidth >= 1801) {
    itemsPerRow = 5;
  } else if (windowWidth < 1149 && windowWidth > 600) {
    itemsPerRow = 2;
  } else {
    itemsPerRow = 1;
  }

  if (windowWidth >= 1200) {
    gapSize = 20;
  } else if (windowWidth < 1000 && windowWidth >= 850) {
    gapSize = 12;
  } else if (windowWidth < 768) {
    gapSize = 20;
  }

  const panelWidth =
    windowWidth >= 850
      ? windowWidth -
        dashboardSidebarWidth -
        sectionPanelWidth -
        panelLeftRightPadding * 2
      : windowWidth - (panelLeftRightPadding - 2) * 2;
  const itemSize = (panelWidth - gapSize * (itemsPerRow - 1)) / itemsPerRow;

  return {
    width: itemSize,
    height: (180 * itemSize) / 320,
  };
}
