import React, {useRef} from "react";
import styled from "styled-components";
import {calcHue} from "./../utils/calcHue";
import tinycolor from "tinycolor2";

const Hue = ({hsl, onChange, onSelectionEnd}) => {
  const sliderRef = useRef();
  const lastTouchEventRef = useRef(null);

  const calcColorVariation = (e) => {
    if (sliderRef.current) {
      const getColor = calcHue(e, hsl, sliderRef.current, "horizontal");
      let newColor = null;
      if (getColor?.s === 0 || getColor?.l === 0) {
        newColor = tinycolor.fromRatio({
          h: getColor.h,
          s: 0.3,
          l: 0.4,
        });
      } else if (getColor?.h) {
        newColor = tinycolor.fromRatio({
          h: getColor.h,
          s: getColor.s,
          l: getColor.l,
        });
      }
      return newColor;
    }
  };

  const handleChange = (e) => {
    const newColor = calcColorVariation(e);
    onChange(newColor);
    // e.preventDefault(); // causes warning in mobile
    e.stopPropagation();
    lastTouchEventRef.current = e;
  };

  const onChangeStart = (e) => {
    // !important only for desktop
    if (window.innerWidth >= 850) {
      e.preventDefault();
    }
    handleChange(e);
    window.addEventListener("touchmove", handleChange);
    window.addEventListener("touchend", handleMouseUp);
    window.addEventListener("mousemove", handleChange);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mouseleave", handleMouseUp);
    lastTouchEventRef.current = e;
  };

  const handleMouseUp = (e) => {
    if (lastTouchEventRef.current) {
      const newColor = calcColorVariation(lastTouchEventRef.current);
      onSelectionEnd(newColor);
      removeEventListeners();
    }
  };

  const removeEventListeners = () => {
    window.removeEventListener("touchmove", handleChange);
    window.removeEventListener("touchend", handleMouseUp);
    window.removeEventListener("mousemove", handleChange);
    window.removeEventListener("mouseup", handleMouseUp);
    window.removeEventListener("mouseleave", handleMouseUp);
  };

  return (
    <Wrapper>
      <Slider
        ref={sliderRef}
        onMouseDown={onChangeStart}
        onTouchMove={onChangeStart}
        onTouchStart={onChangeStart} // causes flickering in mobile, update no more?
      >
        <HueBar />
        <Selector style={{left: `${(hsl.h * 100) / 360}%`}}>
          <div />
        </Selector>
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  absolute: 0px 0px 0px 0px;
  height: 100%;
  user-select: none;
`;

const HueBar = styled.div`
  height: 100%;
  background: -webkit-linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
  background: linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
`;

const Selector = styled.div`
  position: absolute;
  top: -2px;
  div {
    margin-top: 1px;
    width: 4px;
    height: 14px;
    box-shadow: 0 0 2px #000000;
    background: #fff;
    transform: translate(-2px, 0px);
  }
`;

const Slider = styled.div`
  position: relative;
  margin: 10px 0;
  height: 100%;
`;

export default Hue;
