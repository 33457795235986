export const ArrowLeft = (
  <svg viewBox="0 0 24 24">
    <path d="m14 7-5 5 5 5V7z"></path>
  </svg>
);

export const ArrowRight = (
  <svg viewBox="0 0 24 24">
    <path d="m10 17 5-5-5-5v10z"></path>
  </svg>
);
