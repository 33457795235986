import React from "react";
import {batch, connect} from "react-redux";
import styled from "styled-components";
import {updateThemeBuilderFields} from "../../../store/actions/template/theme.action";
import {selectThemeBuilder} from "../../../store/selectors/template/theme.selector";
import DropDown from "../../../ui/inputs/DropDown";
import {
  InputName,
  WrapColorType,
} from "../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
import DeskGradientColors from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/DeskGradientColors";
import PopoverColorBox from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import Switch from "../../editDesign/editingPanel/panelTypes/shared/inputs/Switch";
import {Title} from "../../editDesign/editingPanel/panelTypes/shared/reuseStyles/Title";

const bgTypes = [
  {value: "center", display: "Center"},
  {value: "linear", display: "Linear"},
];

const BgThemeBuilder = ({background, updateThemeBuilderFields}) => {
  const handleFieldUpdate = (key, value) => {
    updateThemeBuilderFields({
      category: "bg",
      key,
      value,
    });
  };

  const handleColorChange = (type) => {
    handleFieldUpdate("gradient", !background.gradient);
  };

  const updateGradientType = (value) => {
    handleFieldUpdate("type", value);
  };

  const updateBackgroundOne = (value) => {
    handleFieldUpdate("bg1", value);
  };

  const updateBackgroundTwo = (value) => {
    handleFieldUpdate("bg2", value);
  };

  const updateBackgroundAngle = (value) => {
    handleFieldUpdate("bgAng", value);
  };

  const onColorOneDebounce = () => {};

  const onColorTwoDebounce = () => {};

  return (
    <>
      <WrapColorType>
        <Switch
          title="Enable Gradient"
          value={background.gradient}
          onItemChange={handleColorChange}
          checked={background.gradient}
          displayTooltip={{
            status: true,
            info: "Gradient will be enabled when the theme is applied",
          }}
        />
      </WrapColorType>

      <div style={{paddingBottom: "2em"}}>
        {!background.gradient ? (
          <ColorsSection>
            <InputName>Colors</InputName>
            <PopoverColorBox
              value={background.bg1}
              updateFieldStyles={updateBackgroundOne}
              onDebounce={onColorOneDebounce}
            />
          </ColorsSection>
        ) : (
          <DeskGradientColors
            colorOne={background.bg1}
            colorTwo={background.bg2}
            angle={background.bgAng}
            updateColorOne={updateBackgroundOne}
            updateColorTwo={updateBackgroundTwo}
            updateFieldStyles={updateBackgroundAngle}
            gradientType={background.type}
            onDebounceColorOne={onColorOneDebounce}
            onDebounceColorTwo={onColorTwoDebounce}
          />
        )}
      </div>

      {background.gradient ? (
        <div style={{display: "flex", paddingBottom: "2em"}}>
          <div style={{display: "flex", alignItems: "center"}}>
            <Title>Gradient Type</Title>
          </div>
          <WrapDropDown>
            <DropDown
              onSelect={updateGradientType}
              selected={background.type}
              options={bgTypes}
              minWidth={125}
            />
          </WrapDropDown>
        </div>
      ) : undefined}
    </>
  );
};

const WrapItem = styled.div`
  width: 100%;
  margin: 15px 0;
`;

const ColorsSection = styled.div`
  display: flex;
  align-items: center;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
`;

const mapStateToProps = (state) => {
  return {
    background: selectThemeBuilder(state.designTemplate)["bg"],
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateThemeBuilderFields,
  }))
)(BgThemeBuilder);
