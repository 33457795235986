import React from "react";
import styled from "styled-components";

const TextField = ({
  value,
  field,
  styles,
  type,
  onChange,
  placeholder,
  onTouched,
  onKeyDown
}) => {
  const handleChange = (e) => {
    onChange(e, field);
  };

  const updateTouchedStatus = () => {
    onTouched(field);
  };

  return (
    <Input
      type={type}
      value={value}
      style={styles}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={updateTouchedStatus}
      onKeyDown={onKeyDown}
    />
  );
};

const Input = styled.input`
  outline: none;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: none;
  border: 1px solid #c7c6d1;
`;

export default TextField;
