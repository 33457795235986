import {createShortKey} from "../store/reducers/functions/fields/utils";

export function handleTableCreation(tempSize) {
  let tableWidth =
    tempSize.w > tempSize.h ? (tempSize.w * 55) / 100 : (tempSize.w * 50) / 100;
  const tableHeight =
    tempSize.w > tempSize.h ? (tempSize.h * 40) / 100 : (tempSize.h * 60) / 100;
  const size = {
    w: tableWidth,
    h: tableHeight,
  };
  const key = createShortKey();
  return {
    key,
    size,
  };
}
