import {css} from "styled-components";

export const ulOlLi = css`
  ul,
  ol {
    padding: 0;
    margin: 0;
    counter-reset: oat-all-counter;
    li {
      list-style-type: none;
      position: relative;
      padding-left: ${({subtype}) =>
        subtype === "bulletContent" ? "2ch" : "3ch"};
      counter-increment: oat-all-counter;
      padding-bottom: 10px;
      &:last-child {
        padding-bottom: 0px;
      }
    }
    li::before {
      display: inline-block;
      content: counter(oat-all-counter);
      ${({subtype, $unorderedBullet}) =>
        subtype === "bulletContent" &&
        css`
          content: "\\2022"; // "${$unorderedBullet?.bulletType ? $unorderedBullet?.bulletType : '\u25CF'}"; // "\\2022"; // "✅";
          margin-left: -2ch;
          width: 2ch;
          color: ${$unorderedBullet?.bulletColor};
          transform: scale(1.5);
          transform-origin: 0;
          // font-size: 14px;
          // vertical-align: middle; /* Vertical centering */
        `};
      ${({subtype}) =>
        subtype === "listContent" &&
        css`
          content: counter(oat-all-counter) "."; // numbers
          margin-left: -3ch;
          width: 3ch;
        `};
      ${({subtype}) =>
        subtype === "listContentWithBackground" &&
        css`
          --size: 3.2ch; // 1.8ch;
          position: absolute;
          top: ${({fontSize, lineHeight}) =>
            Math.floor((fontSize * lineHeight) / 2 - fontSize / 2)}px; // 0;
          left: 0ch;
          width: var(--size);
          height: var(--size);
          color: inherit;
          background: ${({background}) => (background ? background : "#fff")};
          // box-shadow: 1px 1px 0 #999;
          font-size: ${({fontSize}) => fontSize / 1.5}px;
          border-radius: 50%;
          line-height: var(--size);
          text-align: center;
        `};
    }
  }
`;
