import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {LeftArrow, Plus, RightArrow, Trash} from "../../../../oat-svg-icons";
import {openDrawer} from "../../../store/actions/layout.action";
import {changeSlide} from "../../../store/actions/template/slide.action";
import {optionDrawerHeight, defaultDrawerHeight} from "./MobileDrawer";
import {
  selectActiveSlide,
  selectActiveSlideInfo,
} from "../../../store/selectors/template/slide.selector";
import {red1} from "../../../../oat-color-variables";
import PencilSvg from "../../../ui/svg/PencilSvg";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../store/selectors/fields.selector";
import {removeField} from "../../../store/actions/fields/common.action";

const MobileSlideActions = ({
  activeSlide,
  activeField,
  activeFieldKey,
  currentSlideIndex,
  openDrawer,
  noOfSlides,
  changeSlide,
  slideBackground,
  removeField,
}) => {
  const onBackwardSlide = () => {
    if (currentSlideIndex === 0) return;
    changeSlide(currentSlideIndex - 1);
  };

  const onForwardSlide = () => {
    if (currentSlideIndex === noOfSlides - 1) return;
    changeSlide(currentSlideIndex + 1);
  };

  const toggleCreateDrawer = () => {
    openDrawer({type: "create", height: defaultDrawerHeight});
  };

  const toggleOptionsDrawer = () => {
    openDrawer({type: "slide-options", height: optionDrawerHeight});
  };

  const toggleBackground = () => {
    openDrawer({type: "edit-background", height: 250});
  };

  const handleDelete = async () => {
    const active = {slideIndex: activeSlide, fieldID: activeFieldKey};
    await removeField(active);
  };

  return (
    <Layout>
      <div className="mobile-toggle-slides">
        <WrapSvg
          style={{fill: currentSlideIndex === 0 ? "#3e3e3e" : "#eeeeee"}}
          onClick={onBackwardSlide}
        >
          {LeftArrow}
        </WrapSvg>

        <WrapSvg
          style={{
            fill: noOfSlides - 1 === currentSlideIndex ? "#3e3e3e" : "#eeeeee",
          }}
          onClick={onForwardSlide}
        >
          {RightArrow}
        </WrapSvg>
      </div>

      <div id="mobile-edit-field-actions-a1">
        {/* <WrapSvg
          style={{
            fill: red4,
          }}
          onClick={onForwardSlide}
        >
          {PencilSvg}
        </WrapSvg> */}

        {activeField !== -1 ? (
          <WrapSvg
            style={{
              width: 25,
              height: 25,
              fill: red1,
              padding: 4,
              boxSizing: "border-box",
            }}
            onClick={handleDelete}
          >
            {Trash}
          </WrapSvg>
        ) : undefined}
      </div>

      <div className="mobile-slide-options">
        <div onClick={toggleBackground}>
          <Background background={slideBackground?.bg1} />
        </div>

        <div style={{marginLeft: 10}}>
          <WrapSvg onClick={toggleCreateDrawer} style={{width: 22, height: 22}}>
            {Plus}
          </WrapSvg>
        </div>

        <Options onClick={toggleOptionsDrawer}>
          <div />
          <div />
          <div />
        </Options>
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  box-sizing: border-box;
  .mobile-toggle-slides {
    display: flex;
    width: 66px;
    height: 100%;
    fill: #d0c9ff;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-slide-options {
    display: flex;
    align-items: center;
  }
`;

const WrapSvg = styled.div`
  width: 16px;
  height: 16px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
`;

const Options = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  div {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: ${({theme}) => theme.deskPrimaryTxt};
  }
`;

const Background = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({background}) => background};
`;

const mapStateToProps = (state) => {
  return {
    activeSlide: selectActiveSlide(state.designTemplate),
    activeField: selectActiveField(state.designTemplate),
    activeFieldKey: selectActiveFieldProps(state.designTemplate)?.key,
    slideBackground: selectActiveSlideInfo(state.designTemplate).bg,
  };
};

export default connect(mapStateToProps, {changeSlide, openDrawer, removeField})(
  MobileSlideActions
);
