import React, {useState} from "react";
import {Button, ButtonSpinner, Modal} from "../../../../../oat-ui";
import {
  BottomSection,
  Cancel,
  Footer,
} from "../../../reuse/dashboardUI/modalStyles";
import {LibraryErrMsg} from "../userLibraryStyles";
import {
  Form,
  Field,
  Input,
  Textarea,
  ImageInfo,
  PreviewPanel,
  PreviewImage,
} from "./UploadImage";

const ViewImageDetails = ({item, processing, error, onUpdate, onClose}) => {
  const [image, setImage] = useState({
    name: item.name ? item.name : "",
    description: item.description ? item.description : "",
  });

  const handleInputChange = (e, type) => {
    const {value} = e.target;
    setImage({
      ...image,
      [type]: value,
    });
  };

  const handleNameChange = (e) => {
    handleInputChange(e, "name");
  };

  const handleDescriptionChange = (e) => {
    handleInputChange(e, "description");
  };

  const handleUpate = () => {
    onUpdate(image);
  };

  return (
    <Modal width={640} height="auto" close={onClose}>
      <ImageInfo>
        <PreviewPanel>
          <PreviewImage src={`https://oat-users.s3.amazonaws.com/${item.image}`} />
        </PreviewPanel>
        <Form>
          <Field>
            <p>Image Name (Optional)</p>
            <Input
              autoFocus
              type="text"
              value={image.name}
              onChange={handleNameChange}
              spellCheck="false"
            />
          </Field>

          <Field>
            <p>Short Description (Optional)</p>
            <Textarea
              type="text"
              value={image.description}
              onChange={handleDescriptionChange}
              spellCheck="false"
              resize={false}
            />
          </Field>

          {error.status ? (
            <LibraryErrMsg>{error.message}</LibraryErrMsg>
          ) : undefined}
        </Form>
      </ImageInfo>

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Cancel onClick={onClose}>Cancel</Cancel>
          <Button
            styles={{width: 120}}
            onClick={handleUpate}
            disabled={processing}
          >
            {processing ? <ButtonSpinner /> : "Update"}
          </Button>
        </BottomSection>
      </Footer>
    </Modal>
  );
};

export default ViewImageDetails;
