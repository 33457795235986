import React, {useLayoutEffect, useState, useRef} from "react";
import {
  CanvasLayout,
  WrapFrame,
  Card,
} from "../canvasPanel/canvas/CanvasStyles";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectTempScale} from "../../../store/selectors/template/template.selector";
import ViewOnlyFields from "../canvasPanel/viewOnlyFields/ViewOnlyFields";
import {getWindowSize} from "../../../../oat-window-size/getWindowSize";
import {Spinner} from "../../../../oat-ui";
import {slideListPanelHeight} from "../slideListPanel/newDesignList/ChooseTemplateCollection";
import { panelHeight } from "../slideListPanel/SlideListPanel";
import { ArrowBack } from "../../../../oat-svg-icons";

const DisplayItem = ({
  slide,
  onSelectItem,
  width,
  height,
  tempSize,
  scale,
  selected,
}) => {
  const handleClick = (e) => {
    onSelectItem(slide);
  };

  return (
    <WrapCanvas
      style={{
        width: width ? width : "auto",
        height: height ? height : "auto",
      }}
    >
      <CanvasLayout
        onClick={handleClick}
        style={{
          width,
          height,
          overflow: "hidden",
          cursor: "pointer",
          opacity: !selected.status
            ? 1
            : selected.status && selected.item === slide.id
            ? 1
            : 0.4,
          background: !slide.bg.gradient
            ? slide.bg.bg1
            : slide.bg.type !== "center"
            ? `linear-gradient(${slide.bg.bgAng}deg, ${slide.bg.bg1}, ${slide.bg.bg2})`
            : `radial-gradient(${slide.bg.bg1}, ${slide.bg.bg2})`,
        }}
      >
        <WrapFrame
          width={tempSize.w}
          height={tempSize.h}
          style={{transform: `scale(${scale})`, pointerEvents: "none"}}
        >
          <Card
            style={{
              overflow: "hidden",
              pointerEvents: "none",
            }}
          >
            {slide && slide.fields
              ? Object.entries(slide.fields).map(([key, field], i) => (
                  <div key={key}>
                    <ViewOnlyFields field={field} />
                  </div>
                ))
              : undefined}
          </Card>
        </WrapFrame>
      </CanvasLayout>
    </WrapCanvas>
  );
};

const DesignListOptions = ({
  onSelectSlide,
  slides,
  selected,
  noOfColumns,
  orgTempSize,
  enableBackButton,
  goBack
}) => {
  const wrapperRef = useRef();
  const [size, setSize] = useState({width: 0, height: 0, scale: 0});
  const windowSize = getWindowSize();
  // const orgTempSize = {
  //   w: 1920, h: 1080
  // }
  useLayoutEffect(() => {
    if (wrapperRef.current && noOfColumns) {
      // each gap 12 -> 3 colums in one row -> 2 gaps -> 2 * 12 -> 24
      // const newWidth = (wrapperRef.current.offsetWidth - 24) / columns;
      // const newHeight = (newWidth * orgTempSize.h) / orgTempSize.w;
      const newWidth = (wrapperRef.current.offsetWidth - 24) / noOfColumns;
      const newHeight = (newWidth * orgTempSize.h) / orgTempSize.w;
      const calcScale = Math.min(
        newHeight / orgTempSize.h,
        newWidth / orgTempSize.w
      );
      setSize({
        width: newWidth,
        height: newHeight,
        scale: calcScale,
      });
    }
  }, [orgTempSize.w, orgTempSize.h, windowSize.width, noOfColumns]);

  return (
    <Layout style={{ height: panelHeight }}>
      
      {enableBackButton ? (
        <WrapSvg onClick={goBack}>
          {ArrowBack}
        </WrapSvg>)
      : undefined}

      <Wrapper ref={wrapperRef} noOfColumns={noOfColumns}>
        {slides &&
          Object.entries(slides).map(([key, slide], i) => (
            <DisplayItem
              slide={slide}
              key={key}
              index={i}
              onSelectItem={onSelectSlide}
              width={size.width}
              height={size.height}
              tempSize={orgTempSize}
              scale={size.scale}
              selected={selected}
            />
          ))}
      </Wrapper>

      {slides && Object.keys(slides).length === 0 ? (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  overflow-y: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 849px) {
    height: auto;
  }
`;

const Wrapper = styled.div`
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(
    ${({noOfColumns}) => noOfColumns},
    minmax(0, 1fr)
  );
  margin-bottom: 100px;
  user-select: none;
  // @media screen and (min-width: 601px) and (max-width: 1149px) {
  //   grid-template-columns: repeat(2, minmax(0, 1fr));
  // }
  // @media only screen and (max-width: 600px) {
  //   grid-template-columns: repeat(1, minmax(0, 1fr));
  // }
`;

const WrapCanvas = styled.div`
  margin: 0 0 10px 0px;
  background: #fff;
`;

const WrapSpinner = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 120px;
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 12px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
  cursor: pointer;
`

DesignListOptions.defaultProps = {
  noOfColumns: 3,
  selected: {
    status: null,
    item: null,
  },
};

const mapStateToProps = ({designTemplate}) => {
  return {
    tempScale: selectTempScale(designTemplate),
  };
};

export default connect(mapStateToProps, null)(DesignListOptions);
