
export const handleAlignmentY = (currentY, dragYWithinRange, alignment, size, orgFieldPos) => {
    if (currentY > alignment.y.value - dragYWithinRange && currentY < alignment.y.value + dragYWithinRange) {
        return alignment.y.value - orgFieldPos.y;
    } else if (
        currentY + size.h > alignment.y.value - dragYWithinRange && 
        currentY + size.h < alignment.y.value + dragYWithinRange
    ) {
        return alignment.y.value - size.h - orgFieldPos.y;
    } else if (
        currentY + size.h / 2 > alignment.y.value - dragYWithinRange &&
        currentY + size.h / 2 < alignment.y.value + dragYWithinRange
    ) {
        return alignment.y.value - size.h / 2 - orgFieldPos.y;
    } else {
        return null;
    }    
}
