import React, {useState} from "react";
import Hoc from "../../hoc/Hoc";
import {Button, Modal, Spinner} from "../../../oat-ui";
import {HeaderSection, Layout, WrapButton} from "../../reuseStyles/reuseStyles";
import CreateStaff from "./CreateStaff";

const StaffList = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const toggleModal = () => {
    setOpenCreateModal(!openCreateModal);
  };

  return (
    <Hoc>
      <Layout>
        <HeaderSection>
          <div></div>
          <WrapButton>
            <Button onClick={toggleModal} styles={{padding: "0 22px"}}>
              Create Staff
            </Button>
          </WrapButton>
        </HeaderSection>
        {openCreateModal ? (
          <Modal width={440} height={310} close={toggleModal}>
            <CreateStaff closeModal={toggleModal} />
          </Modal>
        ) : undefined}
      </Layout>
    </Hoc>
  );
};

export default StaffList;
