import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  selectFontFamily,
  // updateFieldStyles,
} from "../../../../../../../store/actions/fields/common.action";
import FontList from "./FontList";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {Title} from "../../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";

const SelectFontFamily = ({
  selectFontFamily,
  fontFamily,
  fontWeight,
  fontStyle,
  slideID,
  fieldID,
  updateFieldCollaboration,
}) => {
  // const [showFontsPanel, setShowFontsPanel] = useState(true);

  // const toggleFontPanel = () => {
  //   setShowFontsPanel(!showFontsPanel);
  // };

  // useEffect(() => {
  //   setShowFontsPanel(false);
  // }, [activeField]);

  const changeFontFamily = async (item) => {
    const active = {slideID, fieldID};
    await selectFontFamily(active, item);
    await updateFieldCollaboration();
  };

  return (
    <Wrapper>
      <Title>Choose Fonts</Title>

      <WrapDropDown>
        <FontList
          onSelect={changeFontFamily}
          selected={fontFamily}
          overflowY="scroll"
          height={160}
          minWidth={132}
          fontWeight={fontWeight}
          fontStyle={fontStyle}
        />
      </WrapDropDown>

      {/* <PlusButton onClick={toggleFontPanel} />

        <Slider style={{right: showFontsPanel ? 0 : -editingPanelWidth}}>
          <DisplayFontList close={toggleFontPanel} showPanel={showFontsPanel} />
        </Slider> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

// const Slider = styled.div`
//   position: fixed;
//   top: 50px;
//   transition: all 0.3s ease;
//   background: ${({theme}) => theme.deskPrimaryBg2};
//   z-index: 9999;
// `;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  selectFontFamily,
  updateFieldCollaboration,
})(SelectFontFamily);
