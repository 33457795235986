import React from "react";
import styled from "styled-components";

const TemplateImages = ({images}) => {
  return (
    <WrapImages>
      <div
        style={{
          height: "220px",
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        {images.medium &&
          images.medium.map((image, i) => (
            <Image
              src={`https://oat-admin-templates.s3.amazonaws.com/${image}`}
              key={i}
            />
          ))}
      </div>
    </WrapImages>
  );
};

const WrapImages = styled.div`
  width: ${window.innerWidth - 260 - 70}; //72vw;
  display: flex;
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  padding: 0 12px 12px 0;
  &:last-child {
    padding-right: 0;
  }
`;

export default TemplateImages;
