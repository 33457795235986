import React, {useState} from "react";
import PrivateFolderUI from "./PrivateFolderUI";
import {
  Header,
  Footer,
  BottomSection,
  ContentSection,
} from "../../reuse/dashboardUI/modalStyles";
import {Button, ButtonSpinner} from "../../../../oat-ui";
import {Cancel, Error, WrapButton} from "./folderStyles";
import {updateFolderPrivacy} from "../../../store/actions/folder.action";
import {connect} from "react-redux";

const EditPrivateFolder = ({
  editFolderPrivacy,
  folderType,
  updateFolderPrivacy,
  workspaceID,
  user,
  close,
}) => {
  const [processing, setProcessing] = useState(false);
  const [folderPrivacy, setFolderPrivacy] = useState(editFolderPrivacy.private);
  const [accessibleUsers, setAccessibleUsers] = useState(
    editFolderPrivacy.allowedUsers
  );
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  const onChangeVisibility = () => {
    setFolderPrivacy(!folderPrivacy);
  };

  const handleAccessibleUsers = (item) => {
    if (!accessibleUsers.includes(item.value)) {
      const updateItems = [...accessibleUsers, item.value];
      setAccessibleUsers(updateItems);
    } else {
      const updateItems =
        accessibleUsers &&
        accessibleUsers.filter((user) => item.value !== user);
      setAccessibleUsers(updateItems);
    }
  };

  const handleUpdate = async (e) => {
    if (!processing) {
      setProcessing(true);
      const updatedItem = {
        folderID: editFolderPrivacy.folderID,
        folderType,
        isPrivate: folderPrivacy,
        allowedUsers: accessibleUsers,
        permittedBy: {
          username: user.username,
          name: user.name,
        },
      };
      if (error.status)
        setError({
          status: false,
          message: null,
        });

      const requestOptions = {
        method: "PATCH",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          workspaceID,
          ...updatedItem,
        }),
      };

      await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/folders/${editFolderPrivacy.folderID}/private`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "success") {
            await updateFolderPrivacy(updatedItem);
            await close();
          } else {
            setError({
              status: true,
              message: data.message,
            });
            setProcessing(false);
          }
        })
        .catch((e) => {
          setError({
            status: true,
            message: "Something went wrong.",
          });
          setProcessing(false);
        });
      e.stopPropagation();
    }
  };

  return (
    <>
      <ContentSection>
        <Header>Folder Privacy</Header>
        <PrivateFolderUI
          privateFolder={folderPrivacy}
          accessibleUsers={accessibleUsers}
          onChangeVisibility={onChangeVisibility}
          handleAccessibleUsers={handleAccessibleUsers}
        />
      </ContentSection>

      {error.status ? (
        <Error style={{padding: "0px 10px 10px 0"}}>{error.message}</Error>
      ) : undefined}

      <Footer>
        <BottomSection
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Cancel onClick={close}>Cancel</Cancel>
          <WrapButton>
            <Button
              styles={{width: 82}}
              onClick={handleUpdate}
              disabled={processing}
            >
              {!processing ? "Update" : <ButtonSpinner />}
            </Button>
          </WrapButton>
        </BottomSection>
      </Footer>
    </>
  );
};

export default connect(null, {updateFolderPrivacy})(EditPrivateFolder);
