import {OpenSans} from "../../../../ui/fonts/googleFontLinks";
import { themeBuilder } from "../../data/themeBuilder.data";
import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import {getHighestZIndex} from "./utils";

function addChartColorSet({template, active, themes}) {
  const {slideID, fieldID} = active;
  const getItem = template[slideID].fields[fieldID];
  const getCurrentTheme = themes.find(
    (theme) => theme.id === template[slideID].theme
  );
  const getNumberOfColumns = getItem.dataSeries[0].length - 1; // removed top header
  const colorVariables = ["#756efa", "#ddf571", "#4ef591", "#eb5987"];
  let color = "";
  if (getCurrentTheme.chart.colorSet.length > getNumberOfColumns) {
    color = getCurrentTheme.chart.colorSet[getNumberOfColumns];
  } else {
    // if default colors run out
    if (!getItem.colorSet.includes(colorVariables[0])) {
      color = colorVariables[0];
    } else if (!getItem.colorSet.includes(colorVariables[1])) {
      color = colorVariables[1];
    } else if (!getItem.colorSet.includes(colorVariables[2])) {
      color = colorVariables[2];
    } else {
      color = colorVariables[3];
    }
  }
  return color;
}

export function createChart() {
  return function (state, action) {
    const {template} = state;
    const newState = [...state.template];
    const {slideID, payload, selectFieldOnCreate} = action;
    let highestZIndex = getHighestZIndex(template, slideID);
    const currentSlide = newState[slideID];
    Object.entries(currentSlide.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });
    const adjustedPos = (10 * state.size.w) / 100;
    const {key, dataSeries, type, size, resizer, styles, subtype} = payload || {};
    let activeTheme = state.themes.find(
      (theme) => theme.id === state.activeTheme
    );
    if(typeof activeTheme === 'undefined') {
      activeTheme = themeBuilder
    }
    const {
      labelColor,
      valueColor,
      colorSet,
      background,
      fontFamily,
      gridColor,
      axisColor,
    } = activeTheme.chart;
    const newFieldProps = {
      key,
      dataSeries,
      pos: {x: adjustedPos, y: adjustedPos},
      size,
      type,
      resizer,
      deleted: false,
      font: {
        custom: activeTheme.chart.custom ? true : false,
        fontFamily: activeTheme.chart.fontFamily
          ? activeTheme.chart.fontFamily
          : "Open Sans",
        src: activeTheme.chart.src ? activeTheme.chart.src : OpenSans,
        format: activeTheme.chart.format ? activeTheme.chart.format : null,
        fontType: "Regular",
      },
      colorSet: [
        colorSet[0],
        colorSet[1],
        colorSet[2],
        colorSet[3],
        colorSet[4],
      ],
      ["styles"]: {
        ...styles,
        // fontSize: 10,
        fontFamily,
        background,
        opacity: 1,
        rotate: 0,
        showBackground: false,
        showGrid: false,
        gridColor,
        gridStyle: "dashed",
        showValue: true,
        valueColor,
        showLegend: true, //false,
        legendColor: labelColor,
        legendPosition: "top",
        showMeasurement: true,
        measurementColor: labelColor,
        showCategory: true,
        categoryColor: labelColor,
        rotateCategory: false,
        categoryHeight: 0, // bar & line, used to measure height of the whole rotated area
        metric: "none", // percentage, currency, weight, distance
        curveLine: false, // bar
        barCorner: false, // bar
        pieDisplayValueStyle: "", // pie
        dotIcon: "circle", // line - square, triangle, circle, hexagon
        showXAxis: true,
        showYAxis: true,
        axisColor,
        showDots: true, // line
        curveBar: false, // bar
        curveLine: false, // line
        scaleType: "number", // number, currency, percentage
        currencyUnit: "$",
        scaleFormat: "1000", // 1,000, 1000, 1k
        lock: false,
        animateOnSlideDisplay: null,
        animationType: "none",
        animationSpeed: 0.5,
        animationDelay: 0,
        animationOrder: 0,
        enableExitAnimation: false,
        zIndex: highestZIndex + 1,
      },
    };
    newState[slideID].fields = {
      ...newState[slideID].fields,
      [key]: {...newFieldProps, subtype},
    };
    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });
    return {
      ...state,
      template: newState,
      activeField: key,
      selectedFieldType: type,
      cachedFieldStyles: newState[slideID].fields[key].styles,
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
      fieldToEmitFromSocket: {
        type: "create",
        field: {
          ...newFieldProps,
          subtype,
          key,
        },
      },
    };
  };
}

export function createChartRow() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    const getItemLength = getItem.dataSeries[0].length - 1; // removed top header
    const color = addChartColorSet({
      template: state.template,
      active: action.active,
      themes: state.themes,
    });
    if (
      getItem.subtype === "pie" ||
      getItem.subtype === "donut" ||
      getItem.subtype === "gauge"
    ) {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        colorSet: [...getItem.colorSet, color],
        dataSeries: [
          ...getItem.dataSeries,
          ["New", ...Array(getItemLength).fill(1)],
        ],
      };
    } else {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        dataSeries: [
          ...getItem.dataSeries,
          ["New", ...Array(getItemLength).fill(1)],
        ],
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
}

export function createChartColumn() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const {themes} = state;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    const color = addChartColorSet({
      template: state.template,
      active: action.active,
      themes,
    });
    newState[slideID].fields[fieldID] = {
      ...getItem,
      dataSeries: getItem.dataSeries.map((item, index) =>
        index !== 0 ? [...item, 1] : [...item, "New"]
      ),
      colorSet: [...getItem.colorSet, color],
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function updateChartInfo() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const {row, column, value} = action;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    if (!getItem?.dataSeries) {
      return state
    }
    const updateValue = Object.assign([...getItem.dataSeries], {
      [row]: Object.assign([...getItem.dataSeries?.[row]], {
        [column]: value,
      }),
    });
    newState[slideID].fields[fieldID] = {
      ...getItem,
      dataSeries: updateValue,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function updateSingleColorChart() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = state.template[slideID].fields[fieldID];

    const activeTheme = state.themes.find(
      (theme) => theme.id === state.activeTheme
    );
    let updateColorSet;
    if (getItem.colorSet.length <= action.index) {
      updateColorSet = [
        ...getItem.colorSet,
        activeTheme.chart.colorSet[action.index],
      ];
    } else {
      updateColorSet = getItem.colorSet.map((color, index) =>
        index === action.index ? action.color : color
      );
    }
    newState[slideID].fields[fieldID] = {
      ...getItem,
      colorSet: updateColorSet,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function updateChartColorSet() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      colorSet: action.payload,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function deleteChartRow() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const item = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...item,
      dataSeries: item.dataSeries.filter(
        (outerArr, index) => index !== action.index
      ),
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function deleteChartColumn() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const item = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...item,
      dataSeries: item.dataSeries.map(
        (items) => items.filter((item, i) => i !== action.index + 1) // + 1 for adjusting category names indexes
      ),
      colorSet: item.colorSet.filter((color, index) => index !== action.index),
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function changeChartType() {
  return function (state, action) {
    const {slideID, fieldID, type} = action.payload;
    const newState = [...state.template];
    const item = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...item,
      subtype: type,
      resizer: type === "pie" ? "corners" : "all",
    };
    if (type === "pie" || type === "donut") {
      const chartWidth = newState[slideID].fields[fieldID].size.w;
      const chartHeight = newState[slideID].fields[fieldID].size.h;
      const size = chartWidth > chartHeight ? chartHeight : chartWidth;
      newState[slideID].fields[fieldID].size = {
        w: size,
        h: size,
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
}

export function onViewChartInfo() {
  return function (state) {
    return {
      ...state,
      viewChartInfo: !state.viewChartInfo,
    };
  };
}
