import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import ProfileImageCover from "./ProfileImageCover";
import UpdateProfileBio from "./UpdateProfileBio";

const ProfileModals = ({user, close}) => {
  const [profileInfo, setProfileInfo] = useState({});
  const [fetching, setFetching] = useState(false);
  const [modalType, setModalType] = useState("profile-image-and-cover");

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    };
    setFetching(true);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.account.business.slug}/profile?tag=`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setProfileInfo(data.userProfile);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
      });
  }, []);

  const changeModal = (value) => {
    setModalType(value);
  };

  let displayModal = null;
  switch (modalType) {
    case "profile-image-and-cover":
      displayModal = (
        <ProfileImageCover
          fetching={fetching}
          close={close}
          changeModal={changeModal}
          profileInfo={profileInfo}
        />
      );
      break;
    case "profile-update-bio":
      displayModal = (
        <UpdateProfileBio
          close={close}
          changeModal={changeModal}
          profileInfo={profileInfo}
        />
      );
      break;
    default:
      displayModal = (
        <ProfileImageCover
          fetching={fetching}
          close={close}
          changeModal={changeModal}
        />
      );
  }

  return displayModal;
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps)(ProfileModals);
