import {CREATE_ICON, UPDATE_ICON_COLORS} from "../actionTypes";

export const createIcon = (slideID, payload, selectFieldOnCreate = true) => {
  return {
    type: CREATE_ICON,
    slideID,
    payload,
    selectFieldOnCreate,
  };
};

export const dispatchIconColorUpdate = ({active, row, column, value}) => {
  return {
    type: UPDATE_ICON_COLORS,
    active,
    row,
    column,
    value,
  };
};
