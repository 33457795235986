import React from "react";
import {updateFieldStyles} from "../../../../../../../store/actions/fields/common.action";
import {connect} from "react-redux";
import {WrapColorBox} from "../../common/WrapColorStyles";
import PopoverColorBox from "../../../shared/colorPicker/PopoverColorBox";
import Switch from "../../../shared/inputs/Switch";
import DropDown, {Wrapper} from "../../../../../../../ui/inputs/DropDown";
import styled from "styled-components";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {Title} from "../../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";

const gridStyleList = [
  {display: "Dashed", value: "dashed"},
  {display: "Straight", value: "straight"},
];

const EditChartGrid = ({
  gridColor,
  gridStyle,
  showGrid,
  updateFieldStyles,
  slideID,
  fieldID,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const handleSocketEmit = (type, value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const toggleButton = () => {
    if (!showGrid) {
      handleStyleUpdate("showGrid", true);
      handleSocketEmit("showGrid", true);
    } else if (showGrid) {
      handleStyleUpdate("showGrid", false);
      handleSocketEmit("showGrid", false);
    }
  };

  const changeGridStyle = (value) => {
    handleStyleUpdate("gridStyle", value);
    handleSocketEmit("gridStyle", value);
  };

  const changeGridColor = (value) => {
    handleStyleUpdate("gridColor", value);
  };

  const handleDebounced = (value) => {
    handleSocketEmit("gridColor", value);
  };

  return (
    <>
      <div style={{paddingBottom: showGrid ? "2em" : 0}}>
        <Switch
          title="Show Grid"
          value={showGrid}
          onItemChange={toggleButton}
          checked={showGrid}
        />
      </div>
      {showGrid ? (
        <>
          <div style={{display: "flex", paddingBottom: "2em"}}>
            <Title>Color</Title>
            <WrapColorBox>
              <PopoverColorBox
                value={gridColor}
                updateFieldStyles={changeGridColor}
                onDebounce={handleDebounced}
              />
            </WrapColorBox>
          </div>

          <Wrapper style={{display: "flex"}}>
            <Title>Grid Style</Title>
            <WrapDropDown>
              <DropDown
                onSelect={changeGridStyle}
                selected={gridStyle}
                options={gridStyleList}
                minWidth={115}
                height={100}
              />
            </WrapDropDown>
          </Wrapper>
        </>
      ) : undefined}
    </>
  );
};

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditChartGrid);
