import React, {useState} from "react";
import {connect} from "react-redux";
import {
  setFieldToAlignPosition,
  updateFieldStyles,
} from "../../../../../../store/actions/fields/common.action";
import RangeSlider from "../../shared/inputs/RangeSlider";
import Switch from "../../shared/inputs/Switch";
import ZIndex from "../../shared/ZIndex";
import {Title} from "../../shared/reuseStyles/Title";
import {Accordion, Expand, WrapBox} from "./AccordionStyles";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import styled from "styled-components";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import AlignmentSelection from "./alignment/AlignmentSelection";
import { selectPageTheme } from "../../../../../../store/selectors/layout/layout.selector";

export const alignmentTypes = [
  {display: "Center Center", value: "center-center"},
  {display: "Center Top", value: "top-center"},
  {display: "Center Bottom", value: "bottom-center"},
  {display: "Center Start", value: "center-start"},
  {display: "Left Center", value: "center-left"},
  {display: "Left Top", value: "top-left"},
  {display: "Left Bottom", value: "bottom-left"},
  {display: "Right Center", value: "center-right"},
  {display: "Right Top", value: "top-right"},
  {display: "Right Bottom", value: "bottom-right"},
];

const LayoutPosition = ({
  fieldID,
  slideID,
  rotate,
  locked,
  updateFieldStyles,
  storeFieldUndoRedo,
  setFieldToAlignPosition,
  updateFieldCollaboration,
  pageTheme
}) => {
  const [openBox, setOpenBox] = useState(true);
  const [oldRotateValue, setOldRotateValue] = useState(rotate);
  const [selectedAlignOption, setSelectedAlignOption] = useState("");
  const active = {slideID, fieldID};

  const updateStyles = (type, value) => {
    updateFieldStyles({
      active,
      type,
      value,
    });
  };

  const onLockPosition = () => {
    updateStyles("lock", !locked);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onChangeRotate = (value) => {
    updateStyles("rotate", value);
  };

  const onBlurRotate = (e) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const changeFieldAlignment = (value) => {
    setSelectedAlignOption(value);
    setFieldToAlignPosition({active, payload: value});
  };

  return (
    <>
      <Accordion onClick={() => setOpenBox(!openBox)}>
        <Title>Layout</Title>
        <Expand>{openBox ? "-" : "+"}</Expand>
      </Accordion>
      {openBox ? (
        <WrapBox>
          <div style={{paddingBottom: "1.5em"}}>
            <Switch
              title="Lock Position"
              value={locked}
              onItemChange={onLockPosition}
              checked={locked}
            />
          </div>
          <div style={{paddingBottom: "1.5em"}}>
            <RangeSlider
              title="Rotate"
              fieldType="rotate"
              value={rotate}
              max={360}
              min={0}
              step={1}
              stableValue={oldRotateValue}
              onItemChange={onChangeRotate}
              onBlur={onBlurRotate}
            />
          </div>
          
          <div>
            <ZIndex />
          </div>

          <div>
            <div style={{display: "flex", padding: "1.5em 0 1em"}}>
              <div style={{display: "flex", flex: 1, alignItems: "center"}}>
                <Title>Set Position</Title>
              </div>
              <WrapDropDown>
                <AlignmentSelection
                  onSelect={changeFieldAlignment}
                  selected={selectedAlignOption}
                  options={alignmentTypes}
                  minWidth={150}
                  boxWidth={155}
                  height={210}
                  pageTheme={pageTheme}
                />
              </WrapDropDown>
            </div>
          </div>
        </WrapBox>
      ) : undefined}
    </>
  );
};

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    pageTheme: selectPageTheme(designTemplate)
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  setFieldToAlignPosition,
  updateFieldCollaboration,
})(LayoutPosition);
