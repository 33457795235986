import {
  CHANGE_FOLDER_INIT,
  CHANGE_FOLDER_SUCCESS,
  CHANGE_FOLDER_ERR,
  CREATE_NEW_FOLDER,
  CHANGE_FOLDER_NAME,
  DELETE_FOLDER,
  DESELECT_ACTIVE_FOLDER,
  UPDATE_FOLDER_PRIVACY,
} from "./actionTypes";

// this one is used for desktop
// for mobile, might call to api ??
export function changeFolderInit(folderID, folderType, folderName) {
  return {
    type: CHANGE_FOLDER_INIT,
    folderID,
    folderType,
    folderName,
  };
}

export function changeFolderSuccess(templates, payload) {
  return {
    type: CHANGE_FOLDER_SUCCESS,
    templates,
    payload,
  };
}

export function changeFolderErr(error) {
  return {
    type: CHANGE_FOLDER_ERR,
    error,
  };
}

// check for mobile for additional api is needed?
export const onFolderChange = (payload) => {
  const {username, workspaceID, folderID, folderType, folderName} = payload;
  return (dispatch) => {
    dispatch(changeFolderInit(folderID, folderType, folderName));
    try {
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/workspaces/${workspaceID}/folders/${folderID}`
        // `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/templates?workspaceID=${workspaceID}&folderID=${folderID}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            dispatch(changeFolderSuccess(data.response.templates, payload));
          } else {
            dispatch(changeFolderErr("Something went wrong"));
          }
        })
        .catch((err) => changeFolderErr("Something went wrong"));
    } catch (e) {
      dispatch(changeFolderErr(e));
    }
  };
};

export function deselectActiveFolder() {
  return {
    type: DESELECT_ACTIVE_FOLDER,
  };
}

export const createNewFolder = (payload) => {
  return {
    type: CREATE_NEW_FOLDER,
    payload,
  };
};

export const changeFolderName = (payload) => {
  return {
    type: CHANGE_FOLDER_NAME,
    payload,
  };
};

export const dispatchDeleteFolder = ({
  folderID,
  folderType,
  isSubfolder,
  subfolderID,
}) => {
  return {
    type: DELETE_FOLDER,
    folderID,
    folderType,
    isSubfolder,
    subfolderID,
  };
};

export const updateFolderPrivacy = (payload) => {
  return {
    type: UPDATE_FOLDER_PRIVACY,
    payload,
  };
};
