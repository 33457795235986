import React from "react";
import styled from "styled-components";
import {Option, Dot} from "../../../../oat-ui/styles/SelectOption";

const SharedTempPopper = ({openModal, user}) => {
  const getShareLink = () => {
    openModal("share-link");
  };

  const addToProfile = () => {
    if (user.account.business.permission.profile.create) {
      openModal("add-to-profile");
    } else {
      openModal("create-temp-profile-not-enough-permission");
    }
  };

  return (
    <Wrapper>
      <Option onClick={getShareLink}>
        <p>Share a Link</p>
        <Dot />
      </Option>
      {/* {user.account.business.profile ? (
        <Option onClick={addToProfile}>
          <p>Add To Profile</p>
          <Dot />
        </Option>
      ) : undefined} */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 8px 0;
`;

export default SharedTempPopper;
