import { createSelector } from "reselect";
import { selectActiveSlideInfo } from "./template/slide.selector";

export const selectViewTableEditBarStatus = (state) =>
  state.tableResizeProps.viewTableEditBar;

// group selection
export const selectInterceptedFields = (state) => state.groupSelect;
export const selectInitFieldValues = (state) =>
  state.groupSelect.initFieldValues;

export const selectLibraryCustomFonts = (state) => state.libraryCustomFonts;

export const selectUsersOnLine = (state) => state.usersOnline;

export const selectDragging = (state) => state.dragging;
export const selectResizing = (state) => state.resizing;

// chat
export const selectChats = (state) => state.chats;

export const selectTempNotiInfo = (state) => state.tempNotiInfo;

export const selectFieldToEmitFromSocket = (state) =>
  state.fieldToEmitFromSocket;

export const selectEmitItemsToSocket = (state) => state.emitItemsToSocket;
