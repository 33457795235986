import {
  ADMIN_SWITCH_WORKSPACE,
  FETCH_TEMP_CATEGORIES_INIT,
  FETCH_TEMP_CATEGORIES_SUCCESS,
  FETCH_TEMP_CATEGORIES_ERROR,
  SWITCH_TEMPLATE_CATEGORY,
  FETCH_TEMPLATES_INIT,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_ERROR,
  REMOVE_TEMPLATE,
} from "../actions/actionTypes";
import {
  fetchTemplatesError,
  fetchTemplatesInit,
  fetchTemplatesSuccess,
  removeTemplate,
} from "./functions/tempCategory";
import {
  changeTemplateCategory,
  getTempCategoriesError,
  getTempCategoriesInit,
  getTempCategoriesSuccess,
} from "./functions/tempCategory";
import {switchSection} from "./functions/section";

const initialState = {
  activeSection: "",
  activeCategory: {
    name: null,
    ratio: null,
    size: {w: 0, h: 0},
    value: null,
  },
  tempCategories: [],
  templates: [],
  templateDetails: {},
  modal: {
    name: "",
    status: false,
    error: null,
  },
  fetchData: {
    loading: false,
    error: false,
    errMsg: null,
  },
};

const handlers = {};

handlers[ADMIN_SWITCH_WORKSPACE] = switchSection();

handlers[FETCH_TEMP_CATEGORIES_INIT] = getTempCategoriesInit();
handlers[FETCH_TEMP_CATEGORIES_SUCCESS] = getTempCategoriesSuccess();
handlers[FETCH_TEMP_CATEGORIES_ERROR] = getTempCategoriesError();
handlers[SWITCH_TEMPLATE_CATEGORY] = changeTemplateCategory();
handlers[FETCH_TEMPLATES_INIT] = fetchTemplatesInit();
handlers[FETCH_TEMPLATES_SUCCESS] = fetchTemplatesSuccess();
handlers[FETCH_TEMPLATES_ERROR] = fetchTemplatesError();
handlers[REMOVE_TEMPLATE] = removeTemplate();

function reducerFactory(initialState, handlers) {
  return function (state = initialState, action) {
    const handler = handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };
}

export default reducerFactory(initialState, handlers);
