import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
// import Present from "../../oat-design-template/components/viewTemplate/Present";
// import {PresentTemplate} from "../../oat-design-template";
import {fetchProfileTemplate} from "../../oat-design-template/store/actions/template/template.action";
import {
  getTemplateLayout,
  selectTemplateSize,
} from "../../oat-design-template/store/selectors/template/template.selector";
import {LayoutHoc} from "../../oat-public";
import WrapProfileTemp from "../components/viewProfileTemplate/WrapProfileTemp";
import ProfileContentInfo from "../components/viewProfileTemplate/profileContentInfo/ProfileContentInfo";
// import ViewProfileTemplate from "../components/userProfile/ViewProfileTemplate";
import { ArrowBack } from "../../oat-svg-icons";
import EmbedMode from "../../oat-design-template/containers/EmbedMode";
import { getWindowSize } from "../../oat-window-size/getWindowSize";
// import WrapIframe from "../utils/WrapIframe";

const mobilePadding = 14;
const ShowProfileTemplate = ({fetchProfileTemplate}) => {
  let params = useParams();
  let navigate = useNavigate();
  const {slug, id} = params;

  // useEffect(() => {
  //   if (id) {
  //     fetchProfileTemplate(
  //       // `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${slug}/profile/item/${tempID}`
  //       `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/oat/template/6Q6ZmXoZKc/view`
  //     );
  //   }
  // }, [id]);

  const goBack = () => {
    navigate(`/u/${params.username}`)
  };

  const windowSize = getWindowSize();
  const layoutRef = useRef();
  const [embedSectionWidth, setEmbedSectionWidth] = useState(0);

  useEffect(() => {
    if (windowSize.width > 1340) {
      setEmbedSectionWidth(1040)
    } else if (windowSize.width <= 801) {
      setEmbedSectionWidth((92 * windowSize.width) / 100)
    } else {
      setEmbedSectionWidth((80 * windowSize.width) / 100)
    }
  },[windowSize.width]);

  return (
    <LayoutHoc>
      <WrapLayout ref={layoutRef}>
      {/* 
        <WrapSvg onClick={goBack}>
          {ArrowBack}
        </WrapSvg> */}

        <EmbedMode transition={null} tempID={id} panelWidth={embedSectionWidth} />

        {/* <ProfileContentInfo /> */}
      </WrapLayout>
    </LayoutHoc>
  );
};

const WrapLayout = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin: 24px 0 162px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    @media only screen and (min-width: 850px) {
      border-radius: 10px;
    }
  }
  @media only screen and (min-width: 850px) {
    padding-bottom: 56.25%;
  }
`;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin-top: 0;
  @media only screen and (min-width: 850px) {
    padding: 0 calc(16vw - 8rem);
    margin-top: 77px;
    height: 100vh;
  }
  @media only screen and (max-width: 768px) {
    padding: 18px ${mobilePadding}px 0;
    box-sizing: border-box;
    min-height: 100vh;
  }
`;

const WrapSvg = styled.div`
  position: absolute;
  left: 4%;
  top: 20px;
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
  @media only screen and (min-width: 1460px) {
    top: 20px;
    left: calc(15vw - 8rem); // ${1086 - (window.innerWidth / 2)}px;
  }
  @media only screen and (min-width: 1650px) {
    top: 20px;
    left: calc(22vw - 8rem); // ${1086 - (window.innerWidth / 2)}px;
  }
  @media only screen and (max-width: 1300px) {
    position: relative;
    left: 20px;
    top: 0;
    margin-top: 20px;
  }
`

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    canvasSize: selectTemplateSize(designTemplate),
    layout: getTemplateLayout(designTemplate),
  };
};

export default connect(mapStateToProps, {fetchProfileTemplate})(
  ShowProfileTemplate
);
