export function switchWorkspaceInit() {
  return function (state) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        onSwitchWorkspace: {
          loading: true,
          error: false,
        },
      },
    };
  };
}

export function switchWorkspaceSuccess() {
  return function (state, action) {
    const {activeWorkspace, folders, templates} = action;
    return {
      ...state,
      folders: {
        designs: folders.designFolders,
        // notes: folders.notesFolders,
        // forms: folders.formFolders
      },
      templates,
      workspace: {
        ...state.workspace,
        active: {
          ...state.workspace.active,
          ID: activeWorkspace.ID,
          name: activeWorkspace.name,
          plan: activeWorkspace.plan,
        },
        onSwitchWorkspace: {
          loading: false,
          error: false,
        },
        error: false,
        errorMessage: null,
      },
    };
  };
}

export function switchWorkspaceError() {
  return function (state) {
    return {
      ...state,
      folders: {
        selectedFolderType: "",
        designs: [],
        // notes: [],
        // forms: [],
      },
      workspace: {
        ...state.workspace,
        onSwitchWorkspace: {
          loading: false,
          error: true,
        },
        active: {
          ID: null,
          name: null,
          role: null,
          owner: null,
          plan: null,
        },
        selectedWSActions: {
          ID: null,
          name: null,
        },
      },
    };
  };
}

export function fetchWorkSpacesInit() {
  return function (state) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: true,
        error: false,
        errorMessage: null,
      },
    };
  };
}

export function fetchWorkSpacesSuccess() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        items: action.payload,
        loading: false,
      },
    };
  };
}

export function fetchWorkSpacesError() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: false,
        error: true,
      },
    };
  };
}

export function createWorkspaceInit() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: true,
        error: false,
        errorMessage: null,
      },
    };
  };
}

export function createWorkspaceSuccess() {
  return function (state, action) {
    const {ID, name, role, owner, type} = action.payload;
    return {
      ...state,
      templates: [],
      workspace: {
        ...state.workspace,
        active: {
          ...state.workspace.active,
          ID,
          name,
          role,
          owner,
          type,
        },
        items: [...state.workspace.items, action.payload],
        loading: false,
      },
    };
  };
}

export function createWorkspaceError() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: false,
        error: true,
        errorMessage: "Something went wrong.",
      },
    };
  };
}

export function deleteWorkspaceInit() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: true,
        error: false,
        errorMessage: null,
      },
    };
  };
}

export function deleteWorkspaceSuccess() {
  return function (state, action) {
    const {redirectedWorkspace} = action;
    return {
      ...state,
      activeFolder: {
        ID: null,
        folderType: null,
      },
      folders: [],
      templates: [],
      workspace: {
        ...state.workspace,
        active: {
          ID: redirectedWorkspace.ID,
          name: redirectedWorkspace.name,
          role: redirectedWorkspace.role,
          owner: redirectedWorkspace.owner,
        },
        items: state.workspace.items.filter(
          (item) => item.wsID !== action.workspaceID
        ),
        loading: false,
        error: false,
        errorMessage: null,
      },
    };
  };
}

export function deleteWorkspaceError() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: false,
        error: true,
        errorMessage: "Something went wrong.",
      },
    };
  };
}

export function renameWorkspaceInit() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: true,
        error: false,
        errorMessage: null,
      },
    };
  };
}

export function renameWorkspaceSuccess() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        items: state.workspace.items.map((item) =>
          item.ID === action.payload.workspaceID
            ? 
            {
              ...item,
              name: action.payload.workspaceName
            }
            : item
        ),
        loading: false,
        error: false,
        errorMessage: null,
        active: {
          ...state.workspace.active,
          ID: action.payload.workspaceID,
          name: action.payload.workspaceName,
        },
      },
    };
  };
}

export function renameWorkspaceError() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        loading: false,
        error: true,
        errorMessage: action.error,
      },
    };
  };
}

export function selectWorkspaceItemActions() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        selectedWSActions: {
          ID: action.workspace.ID,
          name: action.workspace.name,
        },
      },
    };
  };
}

export function resetWSSelectedActions() {
  return function (state) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        selectedWSActions: null,
      },
    };
  };
}

export function switchWorkspaceType() {
  return function (state, action) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        active: {
          ...state.workspace.active,
          type: action.payload,
        },
      },
    };
  };
}
