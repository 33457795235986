import styled from "styled-components";
import {paleBlue} from "../../oat-color-variables";

export const Layout = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px 20px;
  min-height: ${window.innerHeight}px;
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
`;

export const WrapButton = styled.div`
  height: 38px;
`;

export const WrapItem = styled.div`
  font-size: 0.92rem;
  width: 15.6%;
  height: 270px;
  margin-right: 1%;
  text-decoration: none;
  margin: 12px 16px 38px 0;
`;

export const UploadFile = styled.button`
  font-size: 0.95rem;
  font-weight: 700;
  color: #fff;
  height: 38px;
  padding: 0 16px;
  background: ${paleBlue};
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;
