import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {
  changeSlideTheme,
  toggleBuildingThemeStatus,
} from "../../../../store/actions/template/theme.action";
import {selectTemplateThemes} from "../../../../store/selectors/template/theme.selector";
import {selectThemeProps} from "../../../../store/selectors/template/theme.selector";
import {TooltipStyles} from "../../../../ui/styles/TooltipStyles";
import {detectClickOutside} from "../../../../../oat-ui";
import {Option} from "../../../../ui/styles/SelectStyles";
import {themeBoxStyles} from "./SlideThemeStyles";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ThemeApplyOptions from "./ThemeList/ThemeApplyOptions";
import DisplayTheme from "./ThemeList/DisplayTheme";
import {selectActiveSlideID} from "../../../../store/selectors/template/slide.selector";
import { useSocket } from "../../../../webSocket/useSocket";

const ChangeSlideTheme = ({
  activeTheme,
  changeSlideTheme,
  themes,
  toggleBuildingThemeStatus,
  slideID,
}) => {
  const activeThemeIndex =
    activeTheme && themes.findIndex((theme) => theme.id === activeTheme?.id);

  const [themeBox, setThemeBox] = useState({
    show: false,
    selectedIndex: "",
  });
  // const [applyType, setApplyType] = useState(null);
  const [showApplyOptions, setApplyOptions] = useState(false);
  const [themeActionsIndex, setThemeActionsIndex] = useState(null);
  const selectRef = useRef(null);
  let navigate = useNavigate();
  let params = useParams();
  const location = useLocation();
  const {emitSocketEvents} = useSocket() || {};

  const applyOptions = [
    {display: "All Slides", value: "all"},
    {display: "Current Slide", value: "single"},
    {
      display: `To Slides with ${activeTheme && activeTheme.name}`,
      value: "selected-theme",
    },
  ];

  const handlePopperClose = () => {
    setThemeBox({
      ...themeBox,
      show: false,
    });
    setThemeActionsIndex(null);
    setApplyOptions(false);
  };

  detectClickOutside(selectRef, () => handlePopperClose());

  const appliedActions = () => {
    setThemeActionsIndex(null);
  };

  const handleToggle = () => {
    setThemeBox({show: !themeBox.show});
  };

  const selectTheme = (theme) => {
    const value = {
      themeID: theme?.id,
      prevValue: {
        themeID: activeTheme?.id,
      },
    };
    setThemeBox({show: !themeBox?.show, selectedTheme: value});
    setApplyOptions(true);
  };

  const applyToSlide = (value) => {
    const item = {
      applyTo: value,
      slideID,
      selectedTheme: themeBox?.selectedTheme,
    }
    changeSlideTheme({...item});
    setApplyOptions(!showApplyOptions);
    // setApplyType(value)

    const payload = {type: "", value: item};
    if (emitSocketEvents) {
      emitSocketEvents({actionType: "apply-theme-to-slide", item: payload});
    }
  };

  const handleBuildTheme = () => {
    toggleBuildingThemeStatus();
    return navigate(`${location.pathname}?building-theme=true`);
  };

  const selectThemeActionsIndex = (index) => {
    setThemeActionsIndex(index);
  };

  // will not deselect field on clicking the div
  const cancelEventPropagate = (e) => {
    e.stopPropagation();
  };

  return (
    <Wrapper>
      {themeBox.show ? (
        <ThemeOptionBox style={{height: "auto"}} ref={selectRef}>
          <div style={{width: "100%", height: "100%"}}>
            {themes &&
              themes.map((theme, i) => (
                <DisplayTheme
                  theme={theme}
                  themesLength={themes.length}
                  key={i}
                  onSelectTheme={selectTheme}
                  onSelectActions={selectThemeActionsIndex}
                  selectedActionsIndex={themeActionsIndex}
                  onAppliedActions={appliedActions}
                />
              ))}
            <OptionLine />
            <Option onClick={handleBuildTheme}>Create Theme +</Option>
          </div>
        </ThemeOptionBox>
      ) : undefined}

      {showApplyOptions ? (
        <ThemeActionsBox ref={selectRef} style={{height: 135}}>
          <div style={{margin: 2, width: "100%", height: "100%"}}>
            {applyOptions.map((item, i) => (
              <ThemeApplyOptions key={i} item={item} onApply={applyToSlide} />
            ))}
          </div>
        </ThemeActionsBox>
      ) : undefined}

      {/* {applyType === "all" ? 
        <ThemeActionsBox ref={selectRef} style={{width: 280, height: 105}}>
          <div style={{
              padding: 12, 
              fontSize: 15, 
              width: "100%", 
              height: "100%",
              boxSizing: 'border-box'
          }}>
            Are you sure you want to apply to <span style={{color: 'red'}}>ALL</span> slides?
          </div>
        </ThemeActionsBox> :
      undefined} */}

      <WrapBox onMouseDown={cancelEventPropagate}>
        <ThemeName style={{width: "100%", marginLeft: 15}}>
          {/* display active theme */}
          {themes[activeThemeIndex >= 0 ? activeThemeIndex : 0] ? (
            <WrapThemeColor onClick={handleToggle}>
              <ThemeColor
                bg={themes[activeThemeIndex >= 0 ? activeThemeIndex : 0] && 
                  themes[activeThemeIndex >= 0 ? activeThemeIndex : 0]?.bg}
              />
              <p style={{width: 120, whiteSpace: "nowrap"}}>
                {activeTheme && activeTheme?.name}
              </p>
            </WrapThemeColor>
          ) : // display default theme
          !activeThemeIndex && themes.length > 0 ? (
            <WrapThemeColor onClick={handleToggle}>
              <ThemeColor bg={themes[0] && themes[0]?.bg} />
              <p style={{width: 120, whiteSpace: "nowrap"}}>Untitled Theme</p>
            </WrapThemeColor>
          ) : undefined}

          {/* if no theme exists or gets deleted */}
          {(!themes || themes.length === 0) && activeThemeIndex === -1 ? (
            <AddTheme onClick={handleBuildTheme}>
              Create Theme <span>+</span>
            </AddTheme>
          ) : undefined}
        </ThemeName>
        <OnHoverContent>Theme Options</OnHoverContent>
      </WrapBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  user-select: none;
`;

const WrapBox = styled.div`
  width: 175px;
  height: 40px;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.deskBtmSectionBoxShadow};
  border: 1px solid ${({theme}) => theme.deskBtnSectionBorder};
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: ${({theme}) => theme.deskPrimaryTxt};
  align-items: center;
  font-size: 0.85rem;
  margin: 0 20px 0 12px;
`;

export const ThemeColor = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 7px;
  top: -1px;
  position: relative;
  color: ${({theme}) => theme.deskTertiaryTxt};
  background: ${({bg}) =>
    bg.gradient
      ? `linear-gradient(${bg.bgAng}deg, ${bg.bg1}, ${bg.bg2})`
      : bg.bg1};
  box-shadow: 0px 0px 2.5px 0px #757575;
`;

const boxStyles = css`
  bottom: 47px;
  left: 10px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ThemeOptionBox = styled.div`
  ${boxStyles};
  ${themeBoxStyles};
  animation: bouncy 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  @-webkit-keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ThemeActionsBox = styled.div`
  ${boxStyles};
  ${themeBoxStyles};
  background: ${({theme}) => theme.deskSecondaryBg2};
  color: ${({theme}) => theme.deskPrimaryTxt};
  animation: bouncy 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  @-webkit-keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ThemeName = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 100%;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 134px;
  }
`;

const WrapThemeColor = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const OnHoverContent = styled.div`
  width: auto;
  top: -26px;
  right: 72px;
  ${TooltipStyles};
`;

const OptionLine = styled.div`
  height: 1px;
  width: 100%;
  background: ${({theme}) => theme.deskGap};
`;

const AddTheme = styled.p`
  display: flex;
  align-items: center;
  span {
    font-size: 1.4rem;
    padding-left: 8px;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    activeTheme: selectThemeProps(designTemplate),
    themes: selectTemplateThemes(designTemplate),
    slideID: selectActiveSlideID(designTemplate)
  };
};

export default connect(mapStateToProps, {
  changeSlideTheme,
  toggleBuildingThemeStatus,
})(ChangeSlideTheme);
