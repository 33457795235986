import React from "react";
import styled from "styled-components";

const SpecialButton = ({disabled, children, onClick, styles}) => {
  return (
    <Wrapper
      disabled={disabled}
      style={{
        opacity: !disabled ? 1 : 0.6,
        cursor: !disabled ? "pointer" : "not-allowed",
        ...styles,
        pointerEvents: disabled ? "none" : undefined,
      }}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.deskButton};
  color: ${({theme}) => theme.deskBtnTxtColor};
  font-size: 1rem;
  border: 1px solid transparent;
  border-radius: 8px;
  height: 100%;
  padding: 0 16px;
  text-decoration: none;
  outline: none;
  font-weight: 600;
  user-select: none;
  @media only screen and (min-width: 850px) {
    font-size: 0.9rem;
    &:hover {
      opacity: 0.7;
    }
  }
`;

SpecialButton.defaultProps = {
  disabled: false,
};

export default SpecialButton;
