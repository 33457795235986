import React from "react";
import styled from "styled-components";
import {paleBlue2} from "../../../../../oat-color-variables";
import {useParams, useNavigate} from "react-router";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {connect} from "react-redux";

const items = [
  {
    display: "Presentation",
    category: "presentation",
    ratio: "16x9",
    size: {
      w: 1920,
      h: 1080,
    },
  },
  // {
  //   display: "Social Media Post",
  //   category: "social-media",
  //   ratio: "1x1",
  //   size: {
  //     w: 1080,
  //     h: 1080,
  //   },
  // },
];

const DisplayItem = ({item, onSelectItem}) => {
  const handleClick = () => {
    onSelectItem(item);
  };
  return (
    <Item onClick={handleClick}>
      <p>{item.display}</p>
      <p>({item.ratio})</p>
    </Item>
  );
};

const AvailableTemplateSizes = ({user}) => {
  let navigate = useNavigate();
  let params = useParams();
  const {workspaceID, folderID} = params;

  const selectTemplateType = async (item) => {
    return navigate(
      `/templates/${item.category}/${item.ratio}?workspace=${workspaceID}&folder=${folderID}`
    );
    // CREATE BLANK TEMP
    // const {ratio, size} = item;
    // const userNewTempID = uuid.v1()();
    // await fetch(
    //   `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/templates`,
    //   {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       user: user.username,
    //       userNewTempID,
    //       refTempID: null,
    //       workspaceID,
    //       folderID,
    //       size,
    //       ratio,
    //       blankTemp: true,
    //     }),
    //   }
    // )
    //   .then((response) => navigate(`/design/${userNewTempID}`))
    //   .catch((error) => console.log(error));
  };

  return (
    <div>
      <Wrapper>
        {/* <Header>
          <div></div>
          <CustomSize>Custom Size</CustomSize>
        </Header> */}

        <Items>
          {items.map((item, i) => (
            <DisplayItem
              item={item}
              key={i}
              onSelectItem={selectTemplateType}
            />
          ))}

          <div style={{paddingBottom: 50}} />
        </Items>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  height: 100%;
  border-radius: 6px;
  color: #787878;
  font-weight: 600;
`;

const Items = styled.div`
  // overflow-y: scroll;
  height: 310px;
  padding: 6px 0;
`;

const Item = styled.div`
  padding: 12px 18px;
  font-size: 0.95rem;
  cursor: pointer;
  text-transform: capitalize;
  margin: 4px 0;
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 850px) {
    padding: 10px 12px;
    &:hover {
      background: ${paleBlue2};
    }
  }
`;

const mapStateToProps = (state) => {
  const {authUser} = state;
  return {
    user: selectUserInfo(authUser),
  };
};

export default connect(mapStateToProps, null)(AvailableTemplateSizes);
