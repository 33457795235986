import React, {useState, useRef} from "react";
import styled from "styled-components";

let delayDebounce;
const ChartInputField = ({value, innerIndex, outerIndex, changeValue}) => {
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);

  const onBlurInput = (e) => {
    // if (/^\d+(\.\d+)?$/.test(value)) {
      if (innerIndex === 0 || outerIndex === 0) {
        changeValue(inputValue, false); // 2nd param whether to emit to socket or not
      } else if (
        inputValue === "" ||
        inputValue === undefined ||
        inputValue === null
      ) {
        changeValue("", false);
      } else if (/^-?\d*\.?\d*$/.test(inputValue)) {
        changeValue((inputValue), false);
      }
      e.stopPropagation();
    // }
  };

  const onChangeValue = (e) => {
    const {value} = e.target;
    // if (/^\d+(\.\d{0,2})?$|^$/.test(value)) {
       if (innerIndex === 0 || outerIndex === 0 || (/^-?\d*\.?\d*$/.test(value))) {
        setInputValue((value));
      } 
      clearTimeout(delayDebounce);
      delayDebounce = setTimeout(() => {
        if (innerIndex === 0 || outerIndex === 0) {
          changeValue((value), true);
        } else if (value === "" || value === undefined || value === null) {
          changeValue("", true);
        } else if (/^-?\d*\.?\d*$/.test(value)) {
          changeValue(Number(value), true);
        }
      }, 2500);
      return () => clearTimeout(delayDebounce);
    // }
  };

  return (
    <Input
      type="text"
      ref={inputRef}
      autoFocus={true}
      value={inputValue}
      onChange={onChangeValue}
      onBlur={onBlurInput}
      tabIndex={outerIndex}
    />
  );
};

const Input = styled.input`
  width: 95%;
  outline: none;
  border: 1px solid #7d5de0;
  background: transparent;
  color: inherit;
  box-sizing: border-box;
`;

export default ChartInputField;
