import React, {useState} from "react";
import styled from "styled-components";
import {Button, TextField} from "../../oat-ui";
import {primaryPurple} from "../../oat-color-variables";

const AdminAuth = ({onSubmit}) => {
  const [processing, setProcessing] = useState(false);
  const [form, setForm] = useState({
    username: {
      value: "",
      touched: false,
      min: 3,
      error: false,
      errorMsg: null,
    },
    password: {
      value: "",
      touched: false,
      min: 6,
      error: false,
      errorMsg: null,
    },
  });

  const handleInputChange = (e, field) => {
    const {value} = e.target;
    setForm({
      ...form,
      [field]: {
        ...form[field],
        value,
        error: false,
        errorMsg: null,
      },
    });
  };

  const handleTouched = (field) => {
    if (field === "password" && form.password.value.length < 6) {
      setForm({
        ...form,
        password: {
          ...form.password,
          touched: true,
          error: true,
          errorMsg: "Password should be at least 6 characters",
        },
      });
    } else if (field === "username" && form.username.value.length < 3) {
      setForm({
        ...form,
        username: {
          ...form.username,
          touched: true,
          error: true,
          errorMsg: "Please enter a valid username",
        },
      });
    } else {
      setForm({
        ...form,
        field: {
          ...form[field],
          touched: true,
          error: false,
          errorMsg: null,
        },
      });
    }
  };

  const handleSubmit = (e) => {
    if (!processing) {
      e.preventDefault();
      const {username, password} = form;
      if (!username.error && !password.error) {
        onSubmit(username.value, password.value);
      }
    }
  };

  return (
    <Layout>
      <WrapBgImage>
        {/* <img
          style={{maxWith: "100%", objectFit: "cover", width: "100%"}}
          src="https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
        /> */}
      </WrapBgImage>

      <Wrapper>
        <Form>
          <Header>SignIn</Header>
          <Line></Line>

          <WrapInput>
            <TextField
              onChange={handleInputChange}
              onTouched={handleTouched}
              field="username"
              placeholder="Username"
              type="text"
              styles={{
                border: "1px solid #c7c7c7",
              }}
            />
            {form.username.touched && form.username.error ? (
              <Error>{form.username.errorMsg}</Error>
            ) : undefined}
          </WrapInput>

          <WrapInput>
            <TextField
              onChange={handleInputChange}
              onTouched={handleTouched}
              field="password"
              placeholder="Password"
              type="password"
              styles={{
                border: "1px solid #c7c7c7",
              }}
            />
            {form.password.touched && form.password.error ? (
              <Error>{form.password.errorMsg}</Error>
            ) : undefined}
          </WrapInput>

          <WrapButton>
            <Button
              onClick={handleSubmit}
              disabled={processing}
              styles={{
                borderRadius: 0,
                fontSize: "1rem",
                background: primaryPurple,
              }}
            >
              Sign In
            </Button>
          </WrapButton>
        </Form>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  background: #fbfafa;
  width: 100%;
  height: ${window.innerHeight}px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding-top: 10%;
  flex: 1;
  height: 38rem;
  @media only screen and (min-width: 900px) {
    padding-top: 5%;
    box-sizing: border-box;
    height: 100%;
    z-index: 10;
  }
`;

const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90vw;
  background: #fbfafa;
  padding: 2em;
  border-radius: 16px;
  z-index: 10;
  @media only screen and (min-width: 900px) {
    width: 40vw;
    box-shadow: 0 0.1rem 0.9rem rgba(110, 115, 143, 0.2),
      0 0 0 0.1rem rgba(110, 115, 143, 0.05);
  }
  @media only screen and (min-width: 1350px) {
    width: 440px;
  }
`;

const Header = styled.h3`
  padding: 14px 0;
  font-size: 2.3em;
  color: #4c4c4c;
  @media only screen and (min-width: 900px) {
    font-size: 2.8em;
  }
`;

const Line = styled.div`
  background: #d6d2d2;
  height: 1px;
  width: 100%;
  margin-bottom: 15px;
`;

const WrapInput = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  margin: 10px 0;
`;

const WrapButton = styled.div`
  display: flex;
  margin: 24px 10px;
  height: 42px;
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
`;

const WrapBgImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  @media only screen and (max-width: 899px) {
    display: none;
  }
`;

const Error = styled.p`
  padding: 12px 0;
  font-size: 0.9rem;
  color: #eb3f3f;
`;

export default AdminAuth;
