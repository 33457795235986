import React, {useState, useEffect, useLayoutEffect} from "react";
import styled from "styled-components";
import {
  createBlankTemplate,
  dispatchResetState,
} from "../store/actions/template/template.action";
import {connect} from "react-redux";
import {
  selectEditPanelStatus,
  selectModalStatus,
} from "../store/selectors/layout/layout.selector";
import {
  selectBuildingTheme,
  selectTemplateThemes,
} from "../store/selectors/template/theme.selector";
import {selectPageTheme} from "../store/selectors/layout/layout.selector";
import {ThemeProvider} from "styled-components";
import {lightTheme, darkTheme, mobDarkTheme} from "../themes";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {getWindowSize} from "../../oat-window-size";
import {useLocation, useNavigate} from "react-router-dom";
import MobileNav from "../components/editDesign/navBar/MobileNav";
import DesktopNav from "../components/editDesign/navBar/desktop/DesktopNav";
import {changePageTheme} from "../store/actions/layout.action";
import SlideListPanel from "../components/editDesign/slideListPanel/SlideListPanel";
import {deskNavHeight} from "../layoutSizes";
import CanvasPanel from "../components/editDesign/canvasPanel/CanvasPanel";
import DeskEditingPanel from "../components/editDesign/editingPanel/DeskEditingPanel";
import MobEditingPanel from "../components/editDesign/editingPanel/MobEditingPanel";
import SlideActionsPanel from "../components/editDesign/slideActionsPanel/SlideActionsPanel";
import {
  selectTemplate,
  selectTemplateName,
  selectTemplateRatio,
  selectTemplateSize,
} from "../store/selectors/template/template.selector";
import {selectUserInfo} from "../../../redux/user/authUser";
import {
  selectSlideGroupList,
  selectSlideTransition,
} from "../store/selectors/template/slide.selector";
import WrapThemeBuilder from "../components/themeBuilder/WrapThemeBuilder";
import SocketProvider from "../webSocket/Socket";
import {googleFonts} from "../ui/fonts/googleFontLinks";
import {Container} from "./DesignTemplate";
import { loadOatAllFonts } from "../utils/loadOatAllFonts";

const DesignTemplate = ({
  template,
  modal,
  createBlankTemplate,
  displayEditPanel,
  user,
  changePageTheme,
  mode,
  buildingTheme,
  onSave,
  buttonTitle,
  themes,
  tempName,
  slideGroupList,
  dispatchResetState,
  isSaving,
  tempSize,
}) => {
  const windowSize = getWindowSize();
  const query = new URLSearchParams(useLocation().search);
  const width = query.get("width") ? query.get("width") : 1920;
  const height = query.get("height") ? query.get("height") : 1080;
  const buildingThemeParam = query.get("building-theme") === "true";
  const editingThemeParam = query.get("editing-theme") === "true";
  const location = useLocation();
  let navigate = useNavigate();
  // save data before closing window
  const [hasSavedBeforeClosing, setHasSavedBeforeClosing] = useState(true);

  useEffect(() => {
    if (!buildingTheme.status && (buildingThemeParam || editingThemeParam)) {
      return navigate(`${location.pathname}`);
    }

    const size = {w: width, h: height};
    createBlankTemplate(size);
  }, []);

  useLayoutEffect(() => {
    return function cleanup() {
      dispatchResetState();
    };
  }, []);

  const handleCreateTemplate = async () => {
    if (template?.length > 0) {
      const templateInfo = {
        ratio: "",
        size: tempSize,
        title: tempName,
        transition: "overlay-transition",
      };
      const removeDeletedFields =
        template &&
        template.map((slide) => {
          return {
            ...slide,
            fields: Object.entries(slide.fields)
              .filter(([id, item]) => item.deleted === false)
              .reduce((acc, [id, item]) => ({...acc, [id]: item}), {}),
          };
        });
      onSave({
        template: {
          details: removeDeletedFields,
          themes,
          slideGroupList,
          transition: "overlay-transition",
        },
        templateInfo,
      });
      setHasSavedBeforeClosing(true);
    }
  };

  const updateTheme = () => {
    changePageTheme(mode === "light" ? "dark" : "light");
  };

  let applyTheme = lightTheme;
  if (mode !== "light" && window.innerWidth >= 850) {
    applyTheme = darkTheme;
  } else if (mode === "light" && window.innerWidth < 850) {
    applyTheme = mobDarkTheme;
  } else if (mode !== "light" && window.innerWidth < 850) {
    applyTheme = mobDarkTheme;
  } else {
    applyTheme = lightTheme;
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.overscrollBehaviorX = "none";
    return () => {
      document.body.style.overflow = "";
      document.body.style.overscrollBehaviorX = "";
    };
  }, [user.username]);

  useEffect(() => {
    // admin load google fonts
    if(user.staff.status) {
      loadOatAllFonts();
    }
  }, [user.staff.status]);

  const [fontsLoaded, setFontsLoaded] = useState(false);
  useEffect(() => {
    if (!fontsLoaded) {
      const oatAllFonts = document.createElement("style");
      Object.entries(googleFonts).map(([id, item]) => {
        item.types &&
          item.types.map((type, i) => {
            if (i === 0) {
              setFontsLoaded(true);
              oatAllFonts.appendChild(
                document.createTextNode(`@import url(${item.types[0].src});`)
              );
            }
          });
      });
      document.head.appendChild(oatAllFonts);
    }
  }, []);

  // confirm leaving before closing window tab
  useEffect(() => {
    function listener(e) {
      if (!hasSavedBeforeClosing) {
        e.preventDefault();
        const message = "Changes you made may not be saved.";
        e.returnValue = message;
        return message;
      }
    }
    window.addEventListener("beforeunload", listener);
    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [hasSavedBeforeClosing]);
  useEffect(() => {
    setHasSavedBeforeClosing(false);
  }, [template]);

  return (
    <HelmetProvider>
      <Helmet>
        <body style="overflow: hidden; overscroll-behavior-x: none" />
        <link
          rel="stylesheet"
          type="text/css"
          href="css/landscape.css"
          media="screen and (orientation: landscape)"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="css/portrait.css"
          media="screen and (orientation: portrait)"
        />
      </Helmet>
      <ThemeProvider theme={applyTheme}>
        <SocketProvider
          username={user.username}
          name={user.name}
          tempID={""}
          enableSocket={false}
        >
          <Container>
            {!buildingTheme.status ? (
              <Layout style={{opacity: modal.status ? 0.3 : 1}}>
                <SocketProvider
                  username={user.username}
                  name={user.name}
                  avatar={user.avatar}
                  tempID={""}
                  enableSocket={false}
                >
                  {windowSize.width < 850 ? (
                    <MobileNav />
                  ) : (
                    <>
                      {/* <button onClick={onConnect}>Con</button>
                  <button onClick={testEmitData}>IO</button> */}
                      <DesktopNav
                        processDesign={handleCreateTemplate} //{toggleTest} //reformatStyles checkUserType
                        mode={mode}
                        onChangeTheme={updateTheme}
                        buttonTitle={buttonTitle}
                        isSaving={isSaving}
                      />
                    </>
                  )}

                  <Wrapper>
                    {window.innerWidth >= 850 ? (
                      <SlideListPanel slides={template} />
                    ) : undefined}

                    <CanvasPanel />

                    {windowSize.width >= 850 && displayEditPanel ? (
                      <DeskEditingPanel theme={mode} />
                    ) : undefined}

                    {windowSize.width >= 850 && !displayEditPanel ? (
                      <SlideActionsPanel theme={mode} />
                    ) : undefined}

                    {windowSize.width < 850 ? <MobEditingPanel /> : undefined}
                  </Wrapper>
                </SocketProvider>
              </Layout>
            ) : (
              <WrapThemeBuilder buildingTheme={buildingTheme} />
            )}

            {/* {modal.status && windowSize.width >= 850 ? (
            <ModalWrapper adminCreateTemp={reformatStyles} />
          ) : undefined} */}
          </Container>
        </SocketProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  background: ${({theme}) => theme.deskPrimaryBg2};
  @media only screen and (min-width: 850px) {
    // transition: all 0.5s ease;
  }
  @media only screen and (max-width: 849px) {
    overflow: hidden;
    background: #1c1c2f;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 850px) {
    flex-direction: row;
    height: ${({printProof}) =>
      printProof ? window.innerHeight : window.innerHeight - deskNavHeight}px;
  }
`;

const mapStateToProps = (state) => {
  const {authUser, designTemplate} = state;
  return {
    buildingTheme: selectBuildingTheme(designTemplate),
    modal: selectModalStatus(designTemplate),
    displayEditPanel: selectEditPanelStatus(designTemplate),
    user: selectUserInfo(authUser),
    mode: selectPageTheme(designTemplate),
    template: selectTemplate(designTemplate),
    slideTransition: selectSlideTransition(designTemplate),
    themes: selectTemplateThemes(designTemplate),
    tempName: selectTemplateName(designTemplate),
    slideGroupList: selectSlideGroupList(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
  };
};

export default connect(mapStateToProps, {
  createBlankTemplate,
  changePageTheme,
  dispatchResetState,
})(DesignTemplate);
