import React from "react";
import {
  Circle,
  Blob1,
  Blob2,
  Rectangle,
  Triangle,
  RightTriangle,
  Blob3,
  Blob4,
  Blob5,
  RadialGradientCircle,
  RoundedBorderRectangle1,
  RoundedBorderRectangle2,
  Hexagon,
  Shape1,
  Shape2,
  Line,
  Shape3,
  MessageBox,
  Rhombus,
  Parallelogram,
  HalfCircle,
} from "./Shapes";

let displayShape = null;
const SelectedShape = ({shape, styles, index, size, tempScale, isLandScape}) => {

  switch (shape) {
    case "circle":
      displayShape = (
        <Circle
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "radial-gradient-circle":
      displayShape = (
        <RadialGradientCircle
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "blob1":
      displayShape = (
        <Blob1 styles={styles} index={index} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "blob2":
      displayShape = (
        <Blob2 styles={styles} index={index} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "rectangle":
      displayShape = (
        <Rectangle
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "rounded-border-rectangle1":
      displayShape = (
        <RoundedBorderRectangle1
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "rounded-border-rectangle2":
      displayShape = (
        <RoundedBorderRectangle2
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "triangle":
      displayShape = (
        <Triangle
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "right-triangle":
      displayShape = (
        <RightTriangle
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "blob3":
      displayShape = (
        <Blob3 styles={styles} index={index} size={size} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "blob4":
      displayShape = (
        <Blob4 styles={styles} index={index} size={size} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "blob5":
      displayShape = (
        <Blob5 styles={styles} index={index} size={size} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "hexagon":
      displayShape = (
        <Hexagon
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "parallelogram":
      displayShape = (
        <Parallelogram
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "shape1":
      displayShape = (
        <Shape1
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "shape2":
      displayShape = (
        <Shape2
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "shape3":
      displayShape = (
        <Shape3
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    case "rhombus":
      displayShape = (
        <Rhombus styles={styles} index={index} size={size} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "half-circle":
      displayShape = (
        <HalfCircle styles={styles} index={index} size={size} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "line":
      displayShape = (
        <Line styles={styles} index={index} size={size} tempScale={tempScale} isLandScape={isLandScape} />
      );
      break;
    case "message-box-1":
      displayShape = (
        <MessageBox
          styles={styles}
          index={index}
          size={size}
          tempScale={tempScale}
          isLandScape={isLandScape}
        />
      );
      break;
    default:
      displayShape = "";
  }
  return displayShape;
};

export default SelectedShape;
