import React, {useState, useRef} from "react";
import styled from "styled-components";
import {detectClickOutside} from "../../../../../../../oat-ui";
import MyFontList from "../../../../editingPanel/panelTypes/desktop/common/fontFamily/MyFontList";
import OatAllFontList from "../../../../editingPanel/panelTypes/desktop/common/fontFamily/OatAllFontList";
import {Wrapper, Options} from "./TooltipSelectionHoc";

const TooltipFontFamily = ({
  width,
  height,
  offset,
  fontFamily,
  fontWeight,
  fontStyle,
  onChange,
  onClose,
}) => {
  const popUpRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("google");

  const updateSelection = (item) => {
    onChange(item);
  };

  detectClickOutside(popUpRef, () => onClose());

  const onSelectGoogleTab = () => {
    setSelectedTab("google");
  };

  const onSelectMyFontsTab = () => {
    setSelectedTab("my-fonts");
  };

  return (
    <Layout
      ref={popUpRef}
      style={{
        overflow: "hidden",
        transform: `translate(${offset?.x}px, ${offset?.y}px)`,
      }}
    >
      <Wrapper style={{width, height}}>
        <Tabs>
          <Tab onClick={onSelectGoogleTab} active={selectedTab === "google"}>
            Google
          </Tab>
          <Tab onClick={onSelectMyFontsTab} active={selectedTab === "my-fonts"}>
            My Fonts
          </Tab>
        </Tabs>
        <Options style={{ height: 165 }}>
          {selectedTab === "google" ? (
            <OatAllFontList
              onSelectFont={updateSelection}
              selected={fontFamily}
              fontWeight={fontWeight}
              fontStyle={fontStyle}
              close={onClose}
            />
          ) : (
            <MyFontList
              onSelectFont={updateSelection}
              selected={fontFamily}
              fontWeight={fontWeight}
              fontStyle={fontStyle}
              close={onClose}
            />
          )}
        </Options>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4444;
`;

const Tabs = styled.div`
  display: flex;
  margin: 8px;
`;

const Tab = styled.div`
  color: ${({active, theme}) =>
    active ? theme.deskTertiaryTxt : theme.deskPrimaryTxt};
  flex: 1;
  text-align: center;
  cursor: pointer;
`;

TooltipFontFamily.defaultProps = {
  width: 125,
  height: 150,
  offset: {
    x: -20,
    y: 45,
  },
};

export default TooltipFontFamily;
