import React, {useState, useEffect} from "react";
import DesignListOptions from "../../../modal/DesignListOptions";
import styled from "styled-components";
import {fetchLayouts} from "../../../../../store/actions/template/layouts.action";
import {connect} from "react-redux";
import {selectLayouts} from "../../../../../store/selectors/template/layouts.selector";
import {createSlideFromTemplate} from "../../../../../store/actions/template/slide.action";
import {
  selectTemplateCategory,
  selectTemplateRatio,
} from "../../../../../store/selectors/template/template.selector";
import {selectThemeProps} from "../../../../../store/selectors/template/theme.selector";
import {changeSlideTheme} from "../../../../../store/actions/template/theme.action";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";
import {useParams} from "react-router";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import { selectActiveSlideID } from "../../../../../store/selectors/template/slide.selector";
import { createSlideID } from "../../../../../store/reducers/functions/fields/utils";

// for admin
export const layoutTypes = [
  {display: "All", value: "all"},
  {display: "Headers & Paragraphs", value: "headers-and-paragraphs"},
  {display: "Content List", value: "content-list"},
  {display: "Text & Images", value: "text-and-images"},
  {display: "Cards", value: "cards"},
  {display: "Charts", value: "charts"},
  {display: "Plans & Timelines", value: "plans-and-timelines"},
  {display: "Testimonials", value: "testimonials"},
];

const DisplayItem = ({item, selected, onSelect}) => {
  const onSelectItem = () => {
    onSelect(item);
  };
  return (
    <LayoutTagItem selected={selected} onClick={onSelectItem}>
      {item.display}
    </LayoutTagItem>
  );
};

const LayoutsTemplate = ({
  layouts,
  fetchLayouts,
  createSlideFromTemplate,
  isLayoutTab,
  tempRatio,
  loading,
  tempCategory,
  activeTheme,
  changeSlideTheme,
  slideID,
  user,
}) => {
  const [selectedRatio, setSelectedRatio] = useState("");
  const [selectedLayoutType, setSelectedLayoutType] = useState("all");
  const [layoutList, setLayoutList] = useState();
  const windowSize = getWindowSize();
  let params = useParams();
  const {category, ratio} = params;

  // useEffect(() => {
  //   //checkIfRatioExists
  //   if (layouts.hasOwnProperty(tempRatio)) {
  //     setSelectedRatio(tempRatio);
  //   } else {
  //     setSelectedRatio("16x9");
  //   }
  // }, [tempRatio]);

  const size = {w: 1920, h: 1080};

  useEffect(() => {
    if (
      tempCategory !== undefined &&
      !window.location.href.includes("/admin/designs/")
    ) {
      if (tempRatio !== "") {
        fetchLayouts(tempRatio, tempCategory);
      }
    } else if (category && window.location.href.includes("/admin/designs/")) {
      fetchLayouts(ratio, category);
    }
  }, [tempRatio, tempCategory, category, ratio]);

  const createSlide = async (slide) => {
    const newSlideID = createSlideID();
    const autoApplyActiveTheme = true;
    await createSlideFromTemplate(slide, newSlideID, autoApplyActiveTheme);
    await changeSlideTheme({
      applyTo: "single",
      slideID: newSlideID,
      selectedTheme: {
        themeID: activeTheme.id,
        prevValue: {
          themeID: activeTheme.id,
        },
      },
    });
  };

  const handleLayoutType = (item) => {
    setSelectedLayoutType(item.value);
    const updateLayouts =
      layouts && layouts.filter((slide) => slide.layoutType === item.value);
    setLayoutList(updateLayouts);
  };

  // useEffect(() => {
  //   setLayoutList(layouts[selectedRatio]);
  // }, [layouts]);

  useEffect(() => {
    if (selectedLayoutType === "all") {
      setLayoutList(layouts);
    } 
  }, [layouts, selectedLayoutType]);

  return (
    <>
      {isLayoutTab && Object.keys(layouts).length !== 0 ? (
        <LayoutTypes>
          {layoutTypes &&
            layoutTypes.map((item, index) => (
              <DisplayItem
                key={index}
                item={item}
                onSelect={handleLayoutType}
                selected={selectedLayoutType === item.value}
              />
            ))}
        </LayoutTypes>
      ) : undefined}

      <DesignListOptions
        slides={layoutList}
        orgTempSize={size}
        onSelectSlide={createSlide}
        noOfColumns={2}
        loading={loading}
      />
    </>
  );
};

const LayoutTypes = styled.div`
  display: flex;
  flex-wrap: nowrap;
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 0.95rem;
  user-select: none;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LayoutTagItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  cursor: pointer;
  padding: 3px 18px 3px;
  margin-bottom: 10px;
  box-sizing: border-box;
  user-select: none;
  border-right: 1px solid ${({theme}) => theme.deskGap};
  color: ${({theme, selected}) =>
    selected ? theme.deskTertiaryTxt : theme.deskPrimaryTxt};
  &:last-child {
    border-right: none !important;
  }
`;

const mapStateToProps = (state) => {
  return {
    layouts: selectLayouts(state.designTemplate),
    tempRatio: selectTemplateRatio(state.designTemplate),
    tempCategory: selectTemplateCategory(state.designTemplate),
    activeTheme: selectThemeProps(state.designTemplate),
    user: selectUserInfo(state.authUser),
    slideID: selectActiveSlideID(state.designTemplate)
  };
};

export default connect(mapStateToProps, {
  fetchLayouts,
  createSlideFromTemplate,
  changeSlideTheme,
})(LayoutsTemplate);
