import React, {useState} from "react";
// import {updateEditingPanel} from "../../../../../store/actions/mobEditPanel";
import {connect} from "react-redux";
import {
  cropImage,
  updateReplaceImgStatus,
} from "../../../../../../store/actions/fields/image.action";
// import {BottomUpDrawer} from "ui";
// import {closeModal, openModal} from "../../../../../store/actions/common";
// import // selectModalStatus,
// "../../../../../../store/reducers/template";
import {Items, Item, Title} from "./MobilePanelStyles";
import {
  selectActiveFieldProps,
  selectActiveFieldStyles,
  selectCropImage,
} from "../../../../../../store/selectors/fields.selector";
import MobileDrawer from "../../../../drawer/MobileDrawer";
import ImageDrawers from "./edit/image/ImageDrawers";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {DrawerLayout} from "./edit/layoutStyles";
import {ExitDone} from "./TextPanel";
import LockField from "./edit/common/LockField";
// import MobLockPosition from "../editingPanel/shared/MobLockPosition";

const ImagePanel = ({
  activeField,
  updateReplaceImgStatus,
  croppingActive,
  cropImage,
  // drawer,
  // openModal,
  // closeModal,
  styles,
}) => {
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: null,
    height: 0,
  });
  const longPanel = window.innerWidth > 600 ? window.innerHeight / 2 : window.innerHeight / 1.5;

  const handleDrawerClose = () => {
    setOpenDrawer({
      status: false,
      type: null,
      height: 0,
    });
  };

  const onOpenDrawer = (type, height) => {
    setOpenDrawer({
      status: true,
      type,
      height,
    });
  };

  const onSelectFilters = () => {
    onOpenDrawer("filters", longPanel);
  };

  const onSelectOverlay = () => {
    onOpenDrawer("overlay", 200);
  };

  const openShadowDrawer = () => {
    onOpenDrawer("shadow", 200);
  };

  const onSelectFlip = () => {
    onOpenDrawer("flip", 80);
  };

  const onSelectApplyEffects = () => {
    onOpenDrawer("effects", 200);
  };

  const onSelectBlur = () => {
    onOpenDrawer("blur", 80);
  };

  const onSelectBorder = () => {
    onOpenDrawer("border", 80);
  };

  const onSelectOpacity = () => {
    onOpenDrawer("opacity", 80);
  };

  const onSelectFrame = () => {
    onOpenDrawer("frames", longPanel);
  };

  const onSelectCrop = () => {
    if (!croppingActive) {
      cropImage(true);
    } else {
      cropImage(false);
    }
  };

  const handleReplace = () => {
    updateReplaceImgStatus();
    onOpenDrawer("upload-image", longPanel);
  };

  const onSelectRotate = () => {
    onOpenDrawer("rotate", 80);
  };

  const onSelectLayer = () => {
    onOpenDrawer("layer", 80);
  };

  const onSelectAnimations = () => {
    onOpenDrawer("animation", (window.innerHeight * 35) / 100);
  };

  return (
    <>
      {!croppingActive ? (
        <Items>
          <Item onClick={handleReplace}>
            <Title>Replace</Title>
          </Item>
          <Item onClick={onSelectFilters}>
            <Title>Filters</Title>
          </Item>
          <Item onClick={onSelectCrop}>
            <Title>Crop</Title>
          </Item>
          <Item onClick={onSelectApplyEffects}>
            <Title>Adjust</Title>
          </Item>
          <Item>
            <Title onClick={onSelectFlip}>Flip</Title>
          </Item>
          <Item onClick={openShadowDrawer}>
            <Title>Shadow</Title>
          </Item>
          {!activeField?.frame && Number.isInteger(styles?.borderRadius) ? (
            <Item onClick={onSelectBorder}>
              <Title>Rounded</Title>
            </Item>
          ) : undefined}
          {activeField?.fileType !== "png" ? (
            <Item onClick={onSelectOverlay}>
              <Title>Overlay</Title>
            </Item>
          ) : undefined}
          <Item onClick={onSelectBlur}>
            <Title>Blur</Title>
          </Item>
          <Item onClick={onSelectOpacity}>
            <Title>Opacity</Title>
          </Item>
          <Item onClick={onSelectFrame}>
            <Title>Frames</Title>
          </Item>

          <LockField />

          <Item onClick={onSelectRotate}>
            <Title>Rotate</Title>
          </Item>

          <Item onClick={onSelectLayer}>
            <Title>Layer</Title>
          </Item>

          <Item onClick={onSelectAnimations}>
            <Title>Animate</Title>
          </Item>

          {/* <BottomUpDrawer drawer={drawer} closeDrawer={closeModal} /> */}
        </Items>
      ) : (
        <ExitDone onClick={onSelectCrop}>Done</ExitDone>
      )}

      <MobileDrawer
        open={openDrawer.status}
        height={openDrawer.height}
        onClose={handleDrawerClose}
      >
        <DrawerLayout>
          <ImageDrawers propsType={openDrawer.type} />
        </DrawerLayout>
      </MobileDrawer>
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    // drawer: selectModalStatus(state.designTemplate),
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    styles: selectActiveFieldStyles(state.designTemplate),
    croppingActive: selectCropImage(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateReplaceImgStatus,
  cropImage,
  // closeModal,
  // openModal,
})(ImagePanel);
