import React, {useEffect, useState} from "react";
import {Button, Modal, Spinner} from "../../../../../oat-ui";
import styled from "styled-components";
import {
  grey4,
  greyText1,
  greyText2,
  paleBlue2,
} from "../../../../../oat-color-variables";
import Select from "../../../../../oat-ui/core/Select";
import {LibraryErrMsg, WrapSmallSpinner} from "../userLibraryStyles";
import {Header} from "../../../reuse/dashboardUI/modalStyles";

const fontWeightList = [
  {value: "100", display: "100"},
  {value: "200", display: "200"},
  {value: "300", display: "300"},
  {value: "400", display: "400"},
  {value: "500", display: "500"},
  {value: "600", display: "600"},
  {value: "700", display: "700"},
  {value: "800", display: "800"},
  {value: "900", display: "900"},
];

const UploadLibraryFonts = ({
  user,
  workspace,
  uploadedFile,
  onCreated,
  onClose,
}) => {
  const [fontFamily, setFontFamily] = useState("");
  const [fontStyle, setFontStyle] = useState("normal");
  const [fontType, setFontType] = useState("");
  const [fontFormat, setFontFormat] = useState("");
  const [fontWeight, setFontWeight] = useState(400);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  useEffect(() => {
    if (uploadedFile) {
      const uploadedFileName = uploadedFile.name;
      const reversedFileName = uploadedFile.name.split("").reverse().join("");
      const finalDash = reversedFileName.indexOf("-"); // eg. "Mouse-Memoris-"
      const fileNameInReversed = reversedFileName.substring(
        finalDash + 1,
        reversedFileName.length
      );
      const fontFamily = fileNameInReversed.split("").reverse().join("");
      setFontFamily(fontFamily);

      const finalDot = reversedFileName.indexOf(".");
      const format = reversedFileName
        .substring(finalDot, 0)
        .split("")
        .reverse()
        .join("");

      setFontFormat(format);

      if (
        format !== "ttf" &&
        format !== "otf" &&
        format !== "woff" &&
        format !== "woff2"
      ) {
        setError({
          status: true,
          message: "Provided format is not supported.",
        });
      } else if (uploadedFile.size > 10000000) {
        setError({
          status: true,
          message: "File size exceeding 10 MB limit.",
        });
      } else if (error.status) {
        setError({
          status: false,
          message: null,
        });
      }

      if (
        uploadedFileName.includes("Regular") ||
        uploadedFileName.includes("regular") ||
        uploadedFileName.includes("Normal") ||
        uploadedFileName.includes("normal")
      ) {
        setFontType("Regular");
        setFontWeight(400);
      } else if (
        uploadedFileName.includes("Bold") ||
        uploadedFileName.includes("bold")
      ) {
        setFontType("Bold");
        setFontWeight(700);
      } else if (
        uploadedFileName.includes("Black") ||
        uploadedFileName.includes("black")
      ) {
        setFontType("Black");
        setFontWeight(900);
      } else if (
        uploadedFileName.includes("Medium") ||
        uploadedFileName.includes("medium")
      ) {
        setFontType("Medium");
        setFontWeight(500);
      } else if (
        uploadedFileName.includes("Light") ||
        uploadedFileName.includes("light")
      ) {
        setFontType("Light");
        setFontWeight(300);
      } else if (
        uploadedFileName.includes("Thin") ||
        uploadedFileName.includes("thin")
      ) {
        setFontType("Thin");
        setFontWeight(100);
      } else {
        setFontType("Regular");
        setFontWeight(400);
      }

      if (
        uploadedFileName.includes("Extra Light") ||
        uploadedFileName.includes("ExtraLight") ||
        uploadedFileName.includes("extra Light") ||
        uploadedFileName.includes("extraLight")
      ) {
        setFontWeight(200);
      } else if (
        uploadedFileName.includes("Extra Bold") ||
        uploadedFileName.includes("ExtraBold") ||
        uploadedFileName.includes("extra Bold") ||
        uploadedFileName.includes("extraBold")
      ) {
        setFontWeight(800);
      }

      if (
        uploadedFileName.includes("Italic") ||
        uploadedFileName.includes("italic")
      ) {
        setFontStyle("italic");
        setFontType((prevState) => prevState + " " + "Italic");
      }
    }
  }, [uploadedFile]);

  const onFontFamilyChange = (e) => {
    const {value} = e.target;
    setFontFamily(value);
  };

  const onFontTypeChange = (e) => {
    const {value} = e.target;
    setFontType(value);
  };

  const toggleItalic = () => {
    setFontStyle((prevState) => (prevState === "italic" ? "normal" : "italic"));
  };

  const onFontWeightChange = (item) => {
    setFontWeight(item.value);
  };

  const onSubmitFont = async () => {
    setProcessing(true);
    if (!processing) {
      const {url, fields, error, fontSrc} = await fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/fonts`,
        {
          method: "POST",
          body: JSON.stringify({
            contentType: "binary/octet-stream",
            type: "font",
            fontFamily,
            fontStyle,
            fontWeight,
            fontType,
            fontFormat,
            description: "",
            fileSize: uploadedFile.size,
            workspaceID: workspace.ID,
          }),
        }
      )
        .then((response) => response.json())
        .catch((e) =>
          setError({
            status: true,
            message: error,
          })
        );

      const data = {
        bucket: "oat-users",
        ...fields,
        "Content-Type": "binary/octet-stream",
        file: uploadedFile,
      };

      const formData = new FormData();
      for (const name in data) {
        formData.append(name, data[name]);
      }
      if (!error) {
        await fetch(url, {
          method: "POST",
          body: formData,
        }).catch((e) =>
          setError({
            status: true,
            message: e.message,
          })
        );

        const newFont = document.createElement("style");
        newFont.appendChild(
          document.createTextNode(
            `@font-face {
              font-family: "${
                fontFamily + "-" + fontWeight + "-" + fontStyle
              }"; font-style: "${fontStyle}"; font-weight: ${fontWeight}; src: url(${`https://oat-users.s3.amazonaws.com/${fontSrc}`}); format("${fontFormat}"); font-display: block;
            }`
          )
        );
        document.head.appendChild(newFont);

        setProcessing(false);
        onCreated();
        onClose();
      }
    }
  };

  return (
    <Modal width={540} height="auto" close={onClose}>
      <Layout>
        <Header style={{margin: "22px 0 2px 18px"}}>
          <p>Add Font</p>
        </Header>

        <Content>
          <WrapField>
            <Field>
              <FieldName>Font Name</FieldName>
            </Field>
            <Field>
              <Input
                type="text"
                value={fontFamily}
                onChange={onFontFamilyChange}
                spellCheck="false"
              />
            </Field>
          </WrapField>

          <WrapField>
            <Field>
              <FieldName>Font Type</FieldName>
            </Field>
            <Field>
              <Input
                type="text"
                value={fontType}
                onChange={onFontTypeChange}
                spellCheck="false"
              />
            </Field>
          </WrapField>

          <WrapField>
            <Field>
              <FieldName>Font Weight</FieldName>
            </Field>
            <Field style={{display: "flex"}}>
              <Select
                onSelect={onFontWeightChange}
                selected={fontWeight}
                options={fontWeightList}
                minWidth={70}
                overflowY="scroll"
                height={160}
              />
            </Field>
          </WrapField>

          <WrapField>
            <Field>
              <FieldName>Italic</FieldName>
            </Field>
            <Field>
              <CheckBox
                type="checkbox"
                checked={fontStyle === "italic"}
                onChange={toggleItalic}
              />
            </Field>
          </WrapField>

          <Message>
            Please check if the inserted information is correct.
          </Message>

          {error.status ? (
            <LibraryErrMsg>{error.message}</LibraryErrMsg>
          ) : undefined}
        </Content>

        <Footer>
          {processing ? (
            <WrapSmallSpinner>
              <Spinner />
            </WrapSmallSpinner>
          ) : undefined}
          <WrapButton>
            <Button onClick={onSubmitFont} disabled={processing}>
              Upload
            </Button>
          </WrapButton>
        </Footer>
      </Layout>
    </Modal>
  );
};

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({theme}) => theme.deskPrimaryBg};
  height: 300px;
  padding: 8px 20px 20px;
`;

// const Header = styled.div`
//   height: 54px;
//   background: ${paleBlue2};
//   display: flex;
//   align-items: center;
//   color: ${darkGrey1};
//   h3 {
//     font-size: 1rem;
//     padding: 0 20px;
//   }
// `;

const FieldName = styled.h4`
  margin: 12px 0 24px;
  color: ${grey4};
`;

const WrapField = styled.div`
  display: flex;
  align-items: center;
`;

const Field = styled.div`
  flex: 1;
  font-size: 0.9rem;
  width: 100%;
`;

const Input = styled.input`
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  height: 28px;
  padding-left: 6px;
  color: ${greyText1};
  box-sizing: border-box;
  width: 90%;
`;

const CheckBox = styled.input``;

const Footer = styled.div`
  height: 60px;
  background: ${paleBlue2};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const WrapButton = styled.div`
  display: flex;
  width: 100px;
  height: 32px;
  margin-right: 12px;
`;

const Message = styled.div`
  font-size: 0.95rem;
  font-style: italic;
  color: ${greyText2};
  margin-top: 8px;
`;

export default UploadLibraryFonts;
