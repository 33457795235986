import React, {useState, useRef, useEffect} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {
  onReplaceImg,
  createImage,
} from "../../../../../store/actions/fields/image.action";
import {
  WrapColumnTabs,
  ColumnTabs,
  ColumnTab,
  WrapTabs,
} from "../../reusableStyles/TabStyles";
import UserUploadImages from "./UserUploadedImages";
import LibraryImages from "./LibraryImages";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../../store/selectors/fields.selector";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import PrivateMedia from "./PrivateMedia";
import UnsplashImages from "./UnsplashImages";
import {
  selectIsGuestUser,
  selectTemplateSize,
  selectTempScale,
  selectWorkspaceID,
} from "../../../../../store/selectors/template/template.selector";
import {blue4, red5} from "../../../../../../oat-color-variables";
import {
  storeActionsUndoRedo,
  storeFieldUndoRedo,
} from "../../../../../store/actions/fields/undoRedo.action";
import {imageUploadSizeLimits} from "../../../../../../oat-utility/variables/uploadSizeLimits";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {createShortKey} from "../../../../../store/reducers/functions/fields/utils";
import {closeDrawer} from "../../../../../store/actions/layout.action";
import {Spinner} from "../../../../../../oat-ui";
import {useLocation} from "react-router-dom";
import {selectPageTheme} from "../../../../../store/selectors/layout/layout.selector";
import Pexels from "./Pexels";
import { createIcon } from "../../../../../store/actions/fields/icon.action";
import OatAllPublicImages from "./oatAllPublic/OatAllPublicImages";
import PurchasedItems from "./purchasedItems/PurchasedItems";
import { dispatchEmbedVideoLink } from "../../../../../store/actions/fields/video.action";

export const modalWidth = 1020;
const ImageSelection = ({
  replaceImg,
  createImage,
  onReplaceImg,
  closeModal,
  closeDrawer,
  user,
  selectedFieldProps,
  tempScale,
  canvasSize,
  workspace,
  storeFieldUndoRedo,
  storeActionsUndoRedo,
  slideID,
  fieldID,
  createIcon,
  pageTheme,
  replaceImage,
  dispatchEmbedVideoLink,
  isGuestUser,
  openModal
}) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const [uploading, setUploading] = useState(false);
  const inputFileRef = useRef(null);
  const location = useLocation();
  const [isAdminDesigning, setIsAdminDesigning] = useState(false);
  const listContainerRef = useRef(null);
  const containsAdmin = location.pathname.includes("/admin/designs");
  // const [isPending, startTransition] = useTransition();

  useEffect(() => {
    if (
      containsAdmin &&
      user.staff.status &&
      (user.staff.position === "graphic-designer" ||
        user.staff.position === "super-admin")
    ) {
      setIsAdminDesigning(true);
    }

    setSelectedTab("pexels");
  }, []);

  const onFileUpload = async (event) => {
    if (error.status) {
      setError({message: "", status: false});
    }
    if (event.target.files && event.target.files[0]) {
      setSelectedTab("upload-image");
      if (event.target.files[0].size > imageUploadSizeLimits) {
        setError({
          message: "File size cannot be bigger than 10MB.",
          status: true,
        });
        event.target.value = null;
      } else  {
        const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/svg+xml', 'image/webp'];
        const videoMimeTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 'video/quicktime', 'video/x-ms-wmv', 'video/x-flv', 'video/3gpp', 'video/x-matroska', 'video/x-m4v', 'video/mpeg', 'video/ogg'];
        const fileType = event.target.files[0].type;
        let format;
        if (imageMimeTypes.includes(fileType)) {
          setUploading(true);
          
          format = event.target.files[0].type.split("image/")[1];
          if (format === "svg+xml") {
            format = "svg";
          }
          const getImg = event.target.files[0];
          const uploadedImage = URL.createObjectURL(getImg);
          const img = new Image();
          let fileReader = new FileReader();
          let imgSize = {w: 0, h: 0};

          fileReader.onload = () => (img.src = fileReader.result);
          fileReader.onerror = () => {
            setError({message: "Couldn't identify image size.", status: true});
          };
          fileReader.readAsDataURL(event.target.files[0]);

          img.onload = async () => {
            imgSize = {w: img.width, h: img.height};
            
            const presignedData = await fetch(
              `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace}/images`,
              {
                method: "POST",
                body: JSON.stringify({
                  contentType: event.target.files[0].type,
                  format,
                  type: "image",
                  fileSize: event.target.files[0].size,
                  workspaceID: workspace,
                  imgSize,
                  uploadToLibrary: false,
                }),
              }
            )
              .then((response) => response.json())
              .catch((e) => {
                setError({message: e, status: true})
              });

            // prevent error when connection got cut off while uploading
            if (!presignedData?.url) {
              setError({message: 'Connection Error', status: true});
              return;
            }

            const url = presignedData?.url;
            const fields = presignedData?.fields ?? [];
            const imageUrl = presignedData?.imageUrl;
            const imgKey = presignedData?.imgKey;
            const error = presignedData?.error ?? false;
            const message = presignedData?.message ?? '';  

            const data = {
              bucket: "oat-users",
              ...fields,
              "Content-Type": event.target.files[0].type,
              file: event.target.files[0],
            };

            const formData = new FormData();
            for (const name in data) {
              formData.append(name, data[name]);
            }

            const fileSize = event.target.files[0].size;

            if (!error) {
              await fetch(url, {
                method: "POST",
                body: formData,
              }).then(async (data) => {
                const getHeightByPercent =
                  (35 * (canvasSize.h * tempScale)) / 100;
                const fixedHeight = getHeightByPercent / tempScale;
                const calcWidth = (fixedHeight * img.width) / img.height;
                if (!replaceImg) {
                  const imgPayload = {
                    key: createShortKey(),
                    imgSize: {
                      width: calcWidth,
                      height: fixedHeight,
                    },
                    fieldSize: {
                      width: calcWidth,
                      height: fixedHeight,
                    },
                    imgInfo: {
                      signedUrl: imageUrl,
                      imgKey,
                      fileType: format,
                      fileSize,
                    },
                    imageBy: 'user',
                    uploadToLibrary: false,
                  };
                  await createImage({
                    slideID,
                    ...imgPayload,
                  });
                  await storeActionsUndoRedo("create");
                } else {
                  const {size} = selectedFieldProps;
                  const calcWidth = (size.h * img.width) / img.height;
                  if (calcWidth > size.w) {
                    const payload = {
                      imgSize: {
                        width: calcWidth,
                        height: size.h,
                      },
                      fieldSize: {
                        width: size.w,
                        height: size.h,
                      },
                      imgInfo: {
                        signedUrl: imageUrl,
                        imgKey,
                        fileType: format,
                        fileSize,
                      },
                      imageBy: 'user',
                      uploadToLibrary: false,
                    };
                    await onReplaceImg({active: {slideID, fieldID}, ...payload});
                    await storeFieldUndoRedo();
                  } else {
                    const calcHeight = (size.w * img.height) / img.width;
                    const payload = {
                      imgSize: {
                        width: size.w,
                        height: calcHeight,
                      },
                      fieldSize: {
                        width: size.w,
                        height: size.h,
                      },
                      imgInfo: {
                        signedUrl: imageUrl,
                        imgKey,
                        fileType: format,
                        fileSize,
                      },
                      imageBy: 'user',
                      uploadToLibrary: false,
                    };
                    await onReplaceImg({active: {slideID, fieldID}, ...payload});
                    await storeFieldUndoRedo();
                  }
                }

                URL.revokeObjectURL(uploadedImage);
                img.src = uploadedImage;
              }).catch((e) => {
                setError({status: true, message});
                event.target.value = null;
              })
              event.target.value = null;
              setUploading(false);
              if (window.innerWidth >= 850) {
                closeModal();
              } else {
                closeDrawer();
              }
            } else {
              setError({status: true, message});
              event.target.value = null;
            }
          }
        } else if (videoMimeTypes.includes(fileType)) {
          setUploading(true);
          try {
            format = event.target.files[0].type.split("video/")[1];

            const {url, fields, imageUrl, imgKey, error, message} = await fetch(
              `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/workspace/${workspace}/images`,
              {
                method: "POST",
                body: JSON.stringify({
                  contentType: event.target.files[0].type,
                  format,
                  type: "video",
                  fileSize: event.target.files[0].size,
                  workspaceID: workspace,
                  imgSize: {w: 640, h: 368},
                  uploadToLibrary: false
                }),
              }
            )
            .then((response) => response.json())
            .catch((e) => {
              setError({message: e, status: true})
              setUploading(false);
            });

            const data = {
              bucket: "oat-users",
              ...fields,
              "Content-Type": event.target.files[0].type,
              file: event.target.files[0],
            };

            const formData = new FormData();
            for (const name in data) {
              formData.append(name, data[name]);
            }

            await fetch(url, {
              method: 'POST',
              body: formData
            }).then(async(data) => {
              await dispatchEmbedVideoLink({
                slideID, 
                key: imgKey, 
                link: imageUrl,
                videoBy: 'user',
                uploaded: true,
              });
              await storeActionsUndoRedo("create");
              setUploading(false);

              if (window.innerWidth >= 850) {
                closeModal();
              } else {
                closeDrawer();
              }
            });
          } catch (error) {
            setUploading(false);
            // Handle error
          }
        } else {
          setError({
            message: "File is neither image nor video.",
            status: true,
          });
          setUploading(false);
        }
      } 
    }
  };

  const handleTabSelection = (value) => {
    setSelectedTab(value);
  };

  // imageBy - either by user or oatall
  const handleExternalLinkUpload = (payload) => {
    if (payload.imgType === 'svg' || payload.format === 'svg') {
      const iconProps = {
        key: createShortKey(),
        colorSet: payload?.colorSet ? payload?.colorSet : [
          {name: '', colors: [], id: ''}
        ],
        // [
        //    {name: 'Paw', colors: [], id: 'paw'}
        // ], // item.colorSet
        content: payload.src,
        type: "icon",
        size: {w: payload.imgSize.w, h: payload.imgSize.h}, // item.size,
        resizer: "corners",
      };
      createIcon(slideID, iconProps);
    } else if (payload?.imgType !== 'svg' && payload?.format !== 'svg') {
      const {src, naturalWidth, naturalHeight, imageBy, imgType} = payload;
      const external = payload?.external;
      const getHeightByPercent = (35 * (canvasSize.h * tempScale)) / 100;
      const fixedHeight = getHeightByPercent / tempScale;
      const calcWidth = (fixedHeight * naturalWidth) / naturalHeight;
      const imgPayload = {
        key: createShortKey(),
        imgSize: {
          width: calcWidth,
          height: fixedHeight,
        },
        fieldSize: {
          width: calcWidth,
          height: fixedHeight
        },
        imgInfo: {
          signedUrl: src,
          fileType: imgType,
          external
        },
        imageBy
      };
      createImage({
        slideID,
        ...imgPayload,
      });
    } 
    storeActionsUndoRedo("create");
    if (window.innerWidth >= 850) {
      closeModal();
    } else {
      closeDrawer();
    }
  };

  const handleReplacingImage = async (image) => {
    const {src, naturalWidth, naturalHeight, imageBy, imgType} = image;
    const external = image?.external;
    const {size} = selectedFieldProps;
    const calcWidth = (size.h * naturalWidth) / naturalHeight;
    const calcHeight = (size.w * naturalHeight) / naturalWidth;

    // const imgInfoProps = userUploaded
    //   ? {
    //       signedUrl: src,
    //       // imgKey: item.key,
    //       // fileType: item.format,
    //       // fileSize: item.fileSize,
    //     }
    //   : {signedUrl: src};
    let imgAttributes = {
      active: {slideID, fieldID},
      fieldSize: {
        width: size.w,
        height: size.h,
      },
      imgInfo: {
        signedUrl: src,
        fileType: imgType,
        external
      },
      imageBy,
      uploadToLibrary: false,
    };
    let payload;
    if (calcWidth > size.w) {
      payload = {
        ...imgAttributes,
        imgSize: {
          width: calcWidth,
          height: size.h,
        },
      };
      await onReplaceImg({active: {slideID, fieldID}, ...payload});
    } else {
      let payload = {
        ...imgAttributes,
        imgSize: {
          width: size.w,
          height: calcHeight,
        },
      };
      await onReplaceImg({active: {slideID, fieldID}, ...payload});
    }
    await storeFieldUndoRedo();
    const item = {type: "", value: payload};
    if (window.innerWidth >= 850) {
      await closeModal();
    } else {
      await closeDrawer();
    }
  };

  const onReplaceImage = async (payload) => {
    await handleReplacingImage(payload);
  };

  const handleScrollListToTop = () => {
    // Scroll the list to the top when the query changes
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop = 0;
    }
  };

  const displayRightPanel = () => {
    switch (selectedTab) {
      case "upload-image":
        return (
          <UploadImageSection>
            {!error.status && uploading ? (
              <div style={{position: "absolute"}}>
                <Spinner />
              </div>
            ) : undefined}
            {error.status ? <ErrorMsg>{error.message}</ErrorMsg> : undefined}
          </UploadImageSection>
        );
      case "library-images":
        return !isGuestUser ? (
          <LibraryImages
            replaceImg={replaceImg}
            onUploadImgByLink={handleExternalLinkUpload}
            onReplaceImgByLink={onReplaceImage}
            user={user}
            workspace={workspace}
          />
        ) : undefined;
      case "unsplash":
        return (
          <UnsplashImages
            replaceImg={replaceImg}
            onUploadImgByLink={handleExternalLinkUpload}
            onReplaceImgByLink={onReplaceImage}
            scrollListToTop={handleScrollListToTop}
          />
        );
      case "pexels":
        return (
          <Pexels
            replaceImg={replaceImg}
            onUploadImgByLink={handleExternalLinkUpload}
            onReplaceImgByLink={onReplaceImage}
            scrollListToTop={handleScrollListToTop}
          />
        );
      // case "oat-images":
      //   return (
      //     <OatImages
      //       // update route  linke
      //       apiLink={`https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/resource/media/image`}
      //       replaceImg={replaceImg}
      //       onUploadImgByLink={handleExternalLinkUpload}
      //       onReplaceImgByLink={onReplaceImage}
      //       user={user}
      //     />
      //   );
      case "uploaded-images":
        return (
          <UserUploadImages
            workspace={workspace}
            username={user.username}
            replaceImg={replaceImg}
            onUploadImgByLink={handleExternalLinkUpload}
            onReplaceImgByLink={onReplaceImage}
            onClose={closeModal}
          />
        );
      case "oat-all-public-media":
        return (
          <OatAllPublicImages 
            replaceImg={replaceImg} 
            onReplaceImage={onReplaceImage} 
            handleExternalLinkUpload={onReplaceImage}
            onUploadImgByLink={handleExternalLinkUpload}
            onReplaceImgByLink={onReplaceImage}
          />
        );
      case "oat-all-private-media":
        return (
          <PrivateMedia
            type="private-media"
            replaceImg={replaceImg}
            onUploadImgByLink={handleExternalLinkUpload}
            onReplaceImgByLink={onReplaceImage}
          />
        );
        case "purchased-items":
          return (
            <PurchasedItems 
              replaceImg={replaceImg}
              onUploadImgByLink={handleExternalLinkUpload}
              onReplaceImgByLink={onReplaceImage}
            />
          );
      default:
        return "";
    }
  };

  const onInitUploadImg = () => {
    if (containsAdmin) {
      window.open('/admin/library/private', '_blank');
    } else {
      setError({message: "", status: false});
      inputFileRef.current.value = '';
      inputFileRef.current.click();
    }
  };

  useEffect(() => {
    if (!openModal && uploading) {
      setUploading(false);
      if (selectedTab === "upload-image") {
        setSelectedTab("pexels")
      }
      if (window.innerWidth >= 850) {
        closeModal();
      } else {
        closeDrawer();
      }
      setError({status: false, message: null});
    }
  }, [openModal, selectedTab, uploading])

  return (
    <Wrapper>
      <WrapColumnTabs>
        <ColumnTabs style={{justifyContent: "space-between"}}>
          <WrapTabs>
            {/* {!isAdminDesigning ? (
              <ColumnTab
                onClick={() => handleTabSelection("oat-images")}
                selected={selectedTab === "oat-images" ? true : false}
              >
                Oat All Images
              </ColumnTab>
            ) : undefined} */}
            {!isAdminDesigning && !isGuestUser ? (
              <ColumnTab
                onClick={() => handleTabSelection("library-images")}
                selected={selectedTab === "library-images" ? true : false}
              >
                Library
              </ColumnTab>
            ) : undefined}
            {!isAdminDesigning ? (
              <ColumnTab
                onClick={() => handleTabSelection("uploaded-images")}
                selected={selectedTab === "uploaded-images" ? true : false}
              >
                Uploaded Images
              </ColumnTab>
            ) : undefined}
            {!isAdminDesigning ? (
              <ColumnTab
                onClick={() => handleTabSelection("purchased-items")}
                selected={selectedTab === "purchased-items" ? true : false}
              >
                Purchased Items
              </ColumnTab>
            ) : undefined}

            {isAdminDesigning ? (
              <ColumnTab
                onClick={() => handleTabSelection("oat-all-public-media")}
                selected={selectedTab === "oat-all-public-media" ? true : false}
              >
                Oat All Public
              </ColumnTab>
            ) : undefined}
            {isAdminDesigning || user.username === "FSmcsj15yAa" ? (
              <ColumnTab
                onClick={() => handleTabSelection("oat-all-private-media")}
                selected={
                  selectedTab === "oat-all-private-media" ? true : false
                }
              >
                Oat All Private
              </ColumnTab>
            ) : undefined}

            {window.innerWidth >= 850 ? <Line /> : undefined}

            {isAdminDesigning || user.staff.status ? (
              <ColumnTab
                onClick={() => handleTabSelection("unsplash")}
                selected={selectedTab === "unsplash" ? true : false}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  width: window.innerWidth < 850 ? 130 : undefined,
                  justifyContent: window.innerWidth < 850 ? 'center' : undefined
                }}
              >
                <WrapSvg style={{ width: 16, height: 16, marginRight: 18}}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M448,230.17V480H0V230.17H141.13V355.09H306.87V230.17ZM306.87,32H141.13V156.91H306.87Z"/>
                  </svg>
                </WrapSvg>
                <div>Unsplash</div>
              </ColumnTab>
            ) : undefined}

            <ColumnTab
              onClick={() => handleTabSelection("pexels")}
              selected={selectedTab === "pexels" ? true : false}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                padding: '6px 12px 5px 0px',
                width: window.innerWidth < 850 ? 130 : undefined,
                justifyContent: window.innerWidth < 850 ? 'center' : undefined
              }}
            >
              <WrapSvg>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1378 984.9">
                  <path fill="#05A081" d="M378.4,137.4h621.2c24.5,0,44.4,19.9,44.4,44.4v621.2c0,24.5-19.9,44.4-44.4,44.4H378.4
                    c-24.5,0-44.4-19.9-44.4-44.4V181.8C334,157.3,353.9,137.4,378.4,137.4z"/>
                  <path fill="#FFFFFF" d="M622.4,603.4h85.7v-83.2H734c38.3-0.5,68.9-31.9,68.5-70.1c-0.5-37.6-30.8-68-68.5-68.5H622.4V603.4z
                    M752.5,647.8H578.1V337.1H734c62.8,0,113.7,50.9,113.7,113.7c0,55.7-40.3,103.1-95.2,112.2L752.5,647.8z"/>
                  <rect x="314" y="117.4" fill="none" width="750" height="750"/>
                </svg>
              </WrapSvg>
              <div style={{display: 'flex', alignItems: 'center'}}>Pexels</div>
            </ColumnTab>
          </WrapTabs>

          {user.username !== null ? 
            <UploadImageButton>
              <UploadImage
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', 
                  margin: 0, 
                  textAlign: "center", 
                  fontWeight: 800
                }}
                onClick={onInitUploadImg}
                selected={selectedTab === "upload-image" ? true : false}
              >
                Upload Media
              </UploadImage>
              <input
                ref={inputFileRef}
                type="file"
                onChange={onFileUpload}
                hidden
              />
            </UploadImageButton> : 
          undefined}
          
        </ColumnTabs>
      </WrapColumnTabs>

      <ImgListSection>
        {/* using portal with an id */}
        <div id="unsplash-search-image-input" />
        <BodyPanel ref={listContainerRef}>
          {selectedTab !== "" || selectedTab !== null
            ? displayRightPanel()
            : undefined}
        </BodyPanel>
      </ImgListSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({theme}) => theme.deskPrimaryBg};
  @media only screen and (min-width: 850px) {
    display: flex;
  }
`;

const ImgListSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3.5;
  margin: 14px 14px 0 14px;
  height: 100%;
`;

const BodyPanel = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ErrorMsg = styled.div`
  width: 100%;
  font-size: 1.1rem;
  color: ${red5};
  text-align: center;
  margin-top: 122px;
`;

const UploadImageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme, pageTheme}) => pageTheme === 'dark' ? blue4 : theme.deskButton};
  margin-bottom: 28px;
  border-radius: 6px;
  height: 34px;
  @media only screen and (min-width: 700px) and (max-width: 849px) {
    width: 150px;
  }
  @media only screen and (max-width: 849px) {
    margin: 12px 0 0 0;
  }
`;

const UploadImageSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 125px;
`;

const UploadImage = styled.div`
  width: 100%;
  font-size: 0.92rem;
  font-weight: 800;
  color: #fff;
  background: ${({theme, selected}) =>
    selected ? theme.deskButton : undefined};
  cursor: pointer;
  border-radius: 6px;
`;

const Line = styled.div`
  width: 100%;
  height: 4px;
  border-top: 1px solid  ${({theme}) =>theme.deskGap};
  margin: 6px 0 24px;
`;

const WrapSvg = styled.div`
  width: 50px;
  height: 35px;
  fill: ${({theme}) => theme.deskBoldText};
  margin-right: 4px;
`

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    replaceImg: designTemplate.replaceImage,
    selectedFieldProps: selectActiveFieldProps(designTemplate),
    user: selectUserInfo(state.authUser),
    tempScale: selectTempScale(designTemplate),
    canvasSize: selectTemplateSize(designTemplate),
    workspace: selectWorkspaceID(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
    pageTheme: selectPageTheme(designTemplate),
    isGuestUser: selectIsGuestUser(designTemplate)
  };
};

export default connect(mapStateToProps, {
  onReplaceImg,
  createImage,
  storeFieldUndoRedo,
  storeActionsUndoRedo,
  closeDrawer,
  createIcon,
  dispatchEmbedVideoLink
})(ImageSelection);
