import {
  SET_PROFILE_TAGS,
  ADD_PROFILE_TAG,
  REMOVE_PROFILE_TAG,
  EDIT_PROFILE_TAG,
} from "./actionTypes";

export function setProfileTags(tags) {
  return {
    type: SET_PROFILE_TAGS,
    tags,
  };
}

export function addProfileTag(payload) {
  return {
    type: ADD_PROFILE_TAG,
    payload,
  };
}

export function removeProfileTag(id) {
  return {
    type: REMOVE_PROFILE_TAG,
    id,
  };
}

export function editProfileTag(payload) {
  return {
    type: EDIT_PROFILE_TAG,
    payload,
  };
}
