import React from "react";
import styled from "styled-components";
import {black1} from "../../../../oat-color-variables";
import AdminSidebar from "../../layout/AdminSidebar";
import {iconCategories, imageCategories} from "../../../../oat-design-template/components/editDesign/modal/create/icons/CreateIcon";
import { useParams } from "react-router-dom";

// export const iconCategories = [
//   {display: "Emojis", value: "emojis"},
//   {display: "Social Media", value: "social-media"},
// ];

const DisplayItem = ({category, onSelect}) => {
  const handleClick = () => {
    onSelect(category);
  };
  return <Item onClick={handleClick}>{category.display}</Item>;
};

const ImageCollectionPanel = ({onSelectTab}) => {
  let params = useParams();
  const {type} = params; 

  return (
    <AdminSidebar>
      <Layout>
        {iconCategories && type === 'icons' &&
          iconCategories.map((category) => (
            <DisplayItem
              category={category}
              onSelect={onSelectTab}
              key={category.value}
            />
          ))}

        {imageCategories && type === 'images' &&
         imageCategories.map((category) => (
          <DisplayItem
            category={category}
            onSelect={onSelectTab}
            key={category.value}
          />
        ))}
      </Layout>
    </AdminSidebar>
  );
};

const Layout = styled.div`
  margin: 14px;
`;

const Item = styled.div`
  font-size: 0.9rem;
  color: ${black1};
  margin-bottom: 16px;
  cursor: pointer;
`;

export default ImageCollectionPanel;
