import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import {black1, grey1} from "../../../../../../oat-color-variables";
import {Team, Lock} from "../../../../../../oat-svg-icons";
import {Spinner} from "../../../../../../oat-ui";
import {
  fetchWorkspaces,
  switchWorkspace,
} from "../../../../../store/actions/workspace.action";
import {
  selectActiveWorkSpace,
  selectWorkspaceList,
  selectWSSwitchingStatus,
  selecWorkSpaceLoadingStatus,
} from "../../../../../store/selectors/userDashboard.selector";
import {ActiveDot} from "../../../../sidebar/reusableStyles/sidebarItem";
import {Item} from "../folderSection/folderSectionStyles";

const DisplayItem = ({item, onSelect, disabled, active}) => {
  const handleClick = () => {
    if (!disabled) onSelect(item);
  };

  return (
    <Item
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 22,
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={handleClick}
    >
      <WorkspaceName>{item.name}</WorkspaceName>
      <ActiveDot style={{position: "absolute", right: 54}} active={active} />
      <WorkspaceType>{item.type === "team" ? Team : Lock}</WorkspaceType>
    </Item>
  );
};

const DisplayWorkspaces = ({
  user,
  workspaces,
  loading,
  activeWorkspace,
  fetchWorkspaces,
  workspaceSwitching,
  switchWorkspace,
  onSelectSetting,
  open,
  close,
}) => {
  let navigate = useNavigate();
  useEffect(() => {
    fetchWorkspaces(user.username);
  }, [open]);

  const onSelectItem = async (workspace) => {
    if (!workspaceSwitching) {
      await switchWorkspace({user: user.username, workspace});
    }
    await close();
    return await navigate(`/dashboard/${workspace.ID}/designs/home`);
  };

  return (
    <Layout>
      {loading ? (
        <div style={{marginTop: 125}}>
          <Spinner />
        </div>
      ) : undefined}

      {!loading ? (
        <>
          <Header>
            <h3>{activeWorkspace.name}</h3>
            <div onClick={onSelectSetting}>Setting</div>
          </Header>

          <div
            style={{
              width: "100%",
              height: 1,
              background: grey1,
              margin: "16px 0",
            }}
          />
        </>
      ) : undefined}

      {!loading &&
        workspaces &&
        workspaces.map((workspace, i) => (
          <DisplayItem
            key={i}
            active={workspace.ID === activeWorkspace.ID}
            item={workspace}
            onSelect={onSelectItem}
            disabled={workspaceSwitching}
          />
        ))}
    </Layout>
  );
};

const Layout = styled.div`
  display; flex;
  flex-direction: column;
`;

const WorkspaceName = styled.div`
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  width: 220px;
`;

const WorkspaceType = styled.div`
  width: 13px;
  height: 18px;
  fill: #bdbdc0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 1.2em;
    color: ${black1};
  }
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
    loading: selecWorkSpaceLoadingStatus(state.userDashboard),
    workspaces: selectWorkspaceList(state.userDashboard),
    activeWorkspace: selectActiveWorkSpace(state.userDashboard),
    workspaceSwitching: selectWSSwitchingStatus(state.userDashboard).loading,
  };
};

export default connect(mapStateToProps, {fetchWorkspaces, switchWorkspace})(
  DisplayWorkspaces
);
