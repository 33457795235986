const MagnifyingGlass = (
  <svg viewBox="0 0 20 20">
    <g stroke="none" strokeWidth="1">
      <g transform="translate(-1217.000000, -57.000000)">
        <g>
          <path d="M1236.59048,75.4247619 L1231.82238,70.6566667 C1233.00429,69.2157143 1233.71667,67.37 1233.71667,65.3583333 C1233.71667,60.7440476 1229.97262,57 1225.35833,57 C1220.74,57 1217, 60.7440476 1217,65.3583333 C1217,69.972619 1220.74,73.7166667 1225.35833,73.7166667 C1227.37,73.7166667 1229.21167,73.0083333 1230.65262,71.8264286 L1235.42071,76.5904762 C1235.74452,76.9142857 1236.26667,76.9142857 1236.59048,76.5904762 C1236.91429,76.2707143 1236.91429,75.7445238 1236.59048,75.4247619 Z M1225.35833,72.0530952 C1221.66286,72.0530952 1218.65952,69.0497619 1218.65952,65.3583333 C1218.65952,61.6669048 1221.66286,58.6595238 1225.35833,58.6595238 C1229.04976,58.6595238 1232.05714,61.6669048 1232.05714,65.3583333 C1232.05714,69.0497619 1229.04976,72.0530952 1225.35833,72.0530952 Z"></path>
        </g>
      </g>
    </g>
  </svg>
  // <svg viewBox="0 0 24 24">
  //   <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
  // </svg>
);

export default MagnifyingGlass;
