import {calcRotatedOffsets} from "../../../../components/editDesign/canvasPanel/dragResize/resize/utils/calcRotatedOffsets";
import { loadOatAllFonts } from "../../../../utils/loadOatAllFonts";
import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import {createShortKey, getHighestZIndex} from "./utils";
import {calcGroupSelection} from "../../../../components/editDesign/canvasPanel/dragResize/groupSelection/calcGroupSelection";

export function setActiveField() {
  return function (state, action) {
    const {activeSlide, template, cropImage, layout} = state;
    const {payload} = action;
    const {fieldID} = payload;
    const item = template[activeSlide]?.fields[payload.fieldID];
    const newState = [...state.template];

    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID: activeSlide,
      fieldIDs: item?.groupAsOne?.keys?.length > 0 ?  item?.groupAsOne?.keys : [fieldID],
      tempScale: layout.tempScale,
      tempSize: state.size,
    });

    if (item?.groupAsOne?.keys?.length > 0) {
      const {size, offset, initValues} = calcGroupSelection(
        template[activeSlide],
        item?.groupAsOne?.keys
      );
      
      const groupSize = {
        w: size.width,
        h: size.height,
      };

      return {
        ...state,
        activeField: -1,
        // emitItemsToSocket: {
        //   payload: {
        //     slideID: currentSlide.id,
        //     groupedArea: {
        //       offset,
        //       size: groupSize,
        //     },
        //     updatedItems: updatedFieldsToEmitAsObject,
        //   },
        //   type: "emit-grouped-fields-drag",
        // },
        selectedFieldType: "animations",
        groupSelect: {
          ...state.groupSelect,
          keys: item?.groupAsOne?.keys,
          initFieldValues: initValues,
          groupAsOne: true, 
          selection: {
            show: true,
            size: groupSize, 
            offset, 
            orgSize: item?.groupAsOne?.size,
            orgOffset: item?.groupAsOne?.offset,
          },
        },
        smartAlignment: {
          ...state.smartAlignment,
          points: {
            x: xAlign,
            y: yAlign,
          },
        },
      }
    } else {
      return {
        ...state,
        activeField: payload.fieldID,
        selectedFieldType:
           state.selectedFieldType === "animations" ? "animations" : payload.type,
        cachedFieldStyles: item?.styles,
        cachedFieldProps: item,
        mobEditingPanel: {
          addedHeight: 0,
          type: "",
        },
        cropImage: cropImage ? false : cropImage,
        groupSelect: initGroupSelect,
        enableTextEdit: false,
        openTooltipPopper: {
          ...state.openTooltipPopper,
          status: state.activeField === -1 ? false : true,
          type: state.activeField === -1 ? null : state.openTooltipPopper.type,
        },
        smartAlignment: {
          ...state.smartAlignment,
          points: {
            x: xAlign,
            y: yAlign,
          },
        },
        viewChartInfo: false,
      };
    }
  };
}

export function resizeField() {
  return function (state, action) {
    if (state.activeField !== -1) {
      const {active, size, styles} = action;
      const {slideID, fieldID} = active;
      const newState = [...state.template];
      const getItem = newState[slideID].fields[fieldID];
      
      let rotatedOffsets = getItem?.rotatedOffsets;
      if (styles?.rotate === 0 || styles?.rotate === 360) { 
        rotatedOffsets = {
          topLeft: 0,
          topRight: 0,
          btmRight: 0,
          btmLeft: 0
        }
      } else {
        rotatedOffsets = calcRotatedOffsets(getItem?.pos, size, styles.rotate) 
      }
      newState[slideID].fields[fieldID] = {
        ...getItem,
        size,
        ["styles"]: {
          ...getItem.styles,
          scale: styles.scale ? styles.scale : 1,
          rotate: styles.rotate ? styles.rotate : getItem.styles.rotate,
        },
        rotatedOffsets
      };
      return {
        ...state,
        template: newState,
        // enableTextEdit: false, // causing text input error
      };
    } else {
      return state;
    }
  };
}

export function dragField() {
  return function (state, action) {
    const {active, payload} = action;
    const newState = [...state.template];
    const {slideID, fieldID} = active;
    const selectedField = newState[slideID].fields[fieldID];
    const {pos, size, styles} = selectedField;
    let rotatedOffsets = selectedField?.rotatedOffsets
    if (styles?.rotate === 0 || styles?.rotate === 360) { 
      rotatedOffsets = {
        topLeft: 0,
        topRight: 0,
        btmRight: 0,
        btmLeft: 0
      }
    } else {
      rotatedOffsets = calcRotatedOffsets(pos, size, styles.rotate) 
    }

    // to store rotated offsets
    if (selectedField.type === "shape" && selectedField.subtype === "line") {
      newState[slideID].fields[fieldID] = {
        ...newState[slideID].fields[fieldID],
        pos: payload,
        rotatedOffsets,
      };
    } else {
      newState[slideID].fields[fieldID] = {
        ...newState[slideID].fields[fieldID],
        pos: payload,
        rotatedOffsets,
      };
    }

    return {
      ...state,
      template: newState,
      // enableTextEdit: false, // causing text input error
    };
  };
}

export function updateFieldStyles() {
  return function (state, action) {
    if (action.active && action.active.fieldID !== -1) {
      const {slideID, fieldID} = action.active;
      const newState = [...state.template];
      const getItem = newState[slideID].fields[fieldID];
      // const prevValue = state.template[slideID].fields[fieldID];
      if (action.key === "rotate") {
        const field = newState[slideID].fields[fieldID];
        const { pos, size, styles } = field;
        const {
          topLeft, topRight, btmRight, btmLeft
        } = calcRotatedOffsets(pos, size, styles.rotate)

        newState[slideID].fields[fieldID] = {
          ...getItem,
          ["styles"]: {
            ...getItem.styles,
            [action.key]: action.value,
          },
          rotatedOffsets: {
            topLeft: {
              x: topLeft.x,
              y: topLeft.y,
            },
            topRight: {
              x: topRight.x,
              y: topRight.y,
            },
            btmLeft: {
              x: btmLeft.x,
              y: btmLeft.y,
            },
            btmRight: {
              x: btmRight.x,
              y: btmRight.y,
            },
          },
        };
      } else {
        newState[slideID].fields[fieldID] = {
          ...getItem,
          ["styles"]: {
            ...getItem.styles,
            [action.key]: action.value,
          },
        };
      }
      return {
        ...state,
        template: newState,
      };
    }
    return state;
  };
}

export function deselectField() {
  return function (state) {
    return {
      ...state,
      activeField: -1,
      selectedFieldType: state.selectedField === "animations" ? "animations" : "",
      cropImage: state.cropImage ? false : state.cropImage,
      mobEditingPanel: {
        addedHeight: 0,
        type: "",
      },
      viewChartInfo: false, // reset chart's wider panel from editing
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      openTooltipPopper: {
        status: false,
        type: null,
      },
    };
  };
}

export function updateSingleColor() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      ["styles"]: {
        ...getItem.styles,
        [action.key]: action.color,
        ["gradientAngle"]: 0,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

// For text, image
export function updateFieldContent() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      content: action.value,
    };
    return {
      ...state,
      template: newState,
      openModal: {status: false, type: ""},
    };
  };
}

export function zIndexToFront() {
  return function (state, action) {
    const {template} = state;
    const {slideID, fieldID} = action.active;
    const slideIndex = template.findIndex((slide) => slide.id === slideID);
    const currentSlide = template[slideIndex];
    const newState = [...state.template];
    let highestZIndex = getHighestZIndex(template, slideIndex);
    const updatedFields = Object.entries(currentSlide.fields).map(
      ([id, field]) => {
        if (field.key === fieldID) {
          return {
            ...field,
            ["styles"]: {
              ...field.styles,
              zIndex: highestZIndex,
            },
          };
        } else if (field.styles.zIndex > action.value) {
          return {
            ...field,
            ["styles"]: {
              ...field.styles,
              zIndex: field.styles.zIndex === 0 ? 0 : field.styles.zIndex - 1,
            },
          };
        } else {
          return field;
        }
      }
    );
    newState[slideIndex] = {
      ...newState[slideIndex],
      fields: updatedFields.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      ),
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function zIndexToBack() {
  return function (state, action) {
    const {template} = state;
    const {slideID, fieldID} = action.active;
    const slideIndex = template.findIndex((slide) => slide.id === slideID);
    const currentSlide = template[slideIndex];
    const newState = [...state.template];
    const updatedFields = Object.entries(currentSlide.fields).map(
      ([id, field]) => {
        if (field.key === fieldID) {
          return {
            ...field,
            ["styles"]: {
              ...field.styles,
              zIndex: 1,
            },
          };
        } else if (field.styles.zIndex < action.value) {
          return {
            ...field,
            ["styles"]: {
              ...field.styles,
              zIndex: field.styles.zIndex + 1,
            },
          };
        } else {
          return {
            ...field,
          };
        }
      }
    );
    newState[slideIndex] = {
      ...newState[slideIndex],
      fields: updatedFields.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      ),
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function removeField() {
  return function (state, action) {
    const {groupSelect} = state;
    const {slideIndex, fieldID} = action.active;
    let newState = [...state.template];
    if (!groupSelect.selection.show && state.activeField !== -1) {
      const field = newState[slideIndex].fields[fieldID];
      delete newState[slideIndex].fields[fieldID];

      let reIndexAnimationOrder = true,
        animationOrders = [],
        updatedFields = null;

      if (field.styles?.animationOrder !== 0) {
        updatedFields = Object.entries(newState[slideIndex].fields).map(
          ([id, item]) => {
            if (item.styles?.animationOrder === field.styles?.animationOrder) {
              reIndexAnimationOrder = false;
              return item;
            } else {
              if (
                item.styles?.animationOrder &&
                !animationOrders?.includes(item.styles?.animationOrder)
              ) {
                animationOrders.push(item.styles?.animationOrder);
              }
              return item;
            }
          }
        );

        if (reIndexAnimationOrder) {
          let indexMapping = {};
          const sortArray = [...animationOrders];
          sortArray.sort((a, b) => a - b);
          sortArray.map((order, i) => (indexMapping[order] = i + 1));

          const fieldsAsArray =
            updatedFields &&
            updatedFields.map((item) => {
              if (indexMapping.hasOwnProperty(item.styles?.animationOrder)) {
                return {
                  ...item,
                  ["styles"]: {
                    ...item.styles,
                    animationOrder: indexMapping[item.styles?.animationOrder],
                  },
                };
              } else {
                return item;
              }
            });

          newState[slideIndex] = {
            ...newState[slideIndex],
            fields: fieldsAsArray.reduce(
              (acc, item) => ({...acc, [item.key]: item}),
              {}
            ),
          };
        }
      }
      return {
        ...state,
        template: newState,
        activeField: -1,
        selectedFieldType: "",
        cropImage: false,
        mobEditingPanel: {
          addedHeight: 0,
          type: "",
        },
        fieldToEmitFromSocket: {
          type: "delete",
          fields: [fieldID],
        },
      };
    } else if (state.groupSelect.selection.show) {
      // REMOVE Multiple Fields (group select)
      let updatedFieldsToEmit = [],
        animationOrders = [],
        updatedFields = null;
      updatedFields = Object.entries(newState[slideIndex].fields).map(
        ([id, field]) => {
          if (groupSelect.keys?.includes(field.key)) {
            updatedFieldsToEmit.push(field.key);
            return {
              ...field,
              deleted: true,
            };
          } else {
            if (
              field.styles?.animationOrder &&
              !animationOrders?.includes(field.styles?.animationOrder)
            ) {
              animationOrders.push(field.styles?.animationOrder);
            }
            return field;
          }
        }
      );

      let indexMapping = {};
      const sortArray = [...animationOrders];
      sortArray.sort((a, b) => a - b);
      sortArray.map((order, i) => (indexMapping[order] = i + 1));

      const fieldsAsArray =
        updatedFields &&
        updatedFields.map((item) => {
          if (
            Object.keys(indexMapping).length > 0 &&
            indexMapping.hasOwnProperty(item.styles?.animationOrder)
          ) {
            return {
              ...item,
              ["styles"]: {
                ...item.styles,
                animationOrder: indexMapping[item.styles?.animationOrder],
              },
            };
          } else {
            return item;
          }
        });

      newState[slideIndex] = {
        ...newState[slideIndex],
        fields: fieldsAsArray.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };

      return {
        ...state,
        groupSelect: initGroupSelect,
        template: newState,
        activeField: -1,
        fieldToEmitFromSocket: {
          type: "delete",
          fields: updatedFieldsToEmit,
        },
      };
    } else {
      return state;
    }
  };
}

export function copyField() {
  return function (state) {
    const {activeSlide, activeField, groupSelect} = state;
    const newState = [...state.template];
    if (state.activeField !== -1) {
      const field = newState[activeSlide].fields[activeField];
      return {
        ...state,
        copiedItems: {items: field, slideID: state.activeSlideID},
      };
    } else if (groupSelect.selection.show) {
      let copyItemsArr = [];
      newState[activeSlide] = {
        ...newState[activeSlide],
        fields: Object.entries(newState[activeSlide].fields).map(
          ([id, field]) => {
            if (groupSelect.keys?.includes(field.key)) {
              copyItemsArr.push(field);
            }
          }
        ),
      };
      return {
        ...state,
        copiedItems: {
          items: copyItemsArr,
          slideID: state.activeSlideID,
          copiedArea: {
            size: groupSelect.selection.size,
            offset: groupSelect.selection.offset,
          },
        },
      };
    } else {
      return state;
    }
  };
}

export function pasteField() {
  return function (state, action) {
    // only paste if field has been copied
    if (state.copiedItems.items) {
      const {copiedItems, template, layout} = state;
      const {slideID, newFieldKey: key} = action;
      const newState = [...template];
      let highestZIndex = getHighestZIndex(template, slideID);

      if (!Array.isArray(copiedItems.items)) {
        const {xAlign, yAlign} = setSmartAignmentPoints({
          template,
          slideID,
          fieldIDs: [key],
          tempScale: layout.tempScale,
          tempSize: state.size,
        });
        const field = copiedItems.items;
        let pos = {x: 0, y: 0};

        if (copiedItems.slideID === state.activeSlideID) {
          if (state?.size?.w < field.pos.x + field.size.w) {
            pos = {
              x: field.pos.x,
              y: field.pos.y + field.size.h / 2.5,
            }
          } else if (state?.size?.h < field.pos.y + field.size.h) {
            pos = {
              x: field.pos.x + field.size.w / 2.5,
              y: field.pos.y,
            }
          } else {
            pos = {
              x: state?.size?.w < field.pos.x + field.size.w ? 
                state?.size?.w - field.size.w : field.pos.x,
              y: state?.size?.h < field.pos.y + field.size.h  
                ? field.pos.y - field.size.h
                : field.pos.y + field.size.h / 2,
            }
          }
        } else {
          pos = {
            x: field.pos.x,
            y: field.pos.y,
          }
        };

        let rotatedOffsets = field?.rotatedOffsets;

        if (field?.styles?.rotate === 0 || field?.styles?.rotate === 360) { 
          rotatedOffsets = {
            topLeft: 0,
            topRight: 0,
            btmRight: 0,
            btmLeft: 0
          }
        } else {
          rotatedOffsets = calcRotatedOffsets(pos, field?.size, field?.styles?.rotate) 
        }

        const addNewField = {
          ...copiedItems.items,
          key,
          pos,
          ["styles"]: {
            ...field.styles,
            zIndex: highestZIndex + 1,
            lock: false
          },
          rotatedOffsets
        };
        newState[slideID].fields = {
          ...newState[slideID].fields,
          [key]: addNewField,
        };
        return {
          ...state,
          template: newState,
          activeField: key,
          selectedFieldType: field.type,
          undoRedo: {
            ...state.undoRedo,
            active: {
              item: addNewField,
              trackIndex: key,
              slideID: template[slideID].id,
              type: "field",
            },
          },
          fieldToEmitFromSocket: {
            type: "paste",
            payload: {
              slideID: newState[slideID].id,
              updatedItems: [addNewField],
              groupedFields: false,
            },
          },
          smartAlignment: {
            ...state.smartAlignment,
            points: {
              x: xAlign,
              y: yAlign,
            },
            x: {
              display: false,
              value: null,
            },
            y: {
              display: false,
              value: null,
            },
          },
        };
      } else {
        let updateKeys = [];

        let biggestSize = {w: 0, h: 0};
        let largestOffsetWithSize = {x: 0, y: 0 };
        copiedItems.items.map((item, i) => {
          if (item.size.w > biggestSize.w) {
            largestOffsetWithSize.x = item.pos.x + copiedItems.slideID === state.activeSlideID ? 0 : item.size.w;
            biggestSize.w = copiedItems.slideID === state.activeSlideID ? 0 : item.size.w;
          }
          if (item.size.h > biggestSize.h) {
            largestOffsetWithSize.y = item.pos.y + copiedItems.slideID === state.activeSlideID ? 0 : item.size.h;
            biggestSize.h = copiedItems.slideID === state.activeSlideID ? 0 : item.size.h;
          }
        })

        let copiedPastedItems = {fields: []};
        copiedItems.items.map((item, i) => {
          const newID = createShortKey();
          updateKeys.push(newID);
          let x = 0, y = 0;
          if (copiedItems.slideID !== state.activeSlideID) {
            x = item.pos.x;
            y = item.pos.y;
          } else if (state?.size?.w < largestOffsetWithSize.x) {
            x = item.pos.x;
            y = item.pos.y + 50 / layout.tempScale;
          } else if (state?.size?.h < largestOffsetWithSize.y) {
            x = item.pos.x + 50 / layout.tempScale;
            y = item.pos.y + 50 / layout.tempScale;
          } else {
            x = item.pos.x;
            y = item.pos.y + (100 / layout.tempScale);
          }

          copiedPastedItems.fields.push({
            ...item,
            key: newID,
            pos: {x, y}
          });
        });

        const {offset, size, initValues} = calcGroupSelection(
          copiedPastedItems,
          updateKeys
        );

        const pastedItems = copiedPastedItems.fields.map((item, i) => {
          const newID = item.key; 
          let rotatedOffsets = item?.rotatedOffsets;
          // if (item?.styles?.rotate !== 0) {
          //   rotatedOffsets = calcRotatedOffsets(
          //     item?.pos,
          //     item?.size,
          //     item?.styles.rotate
          //   );
          // }
          if (item?.styles?.rotate === 0 || item?.styles?.rotate === 360) { 
            rotatedOffsets = {
              topLeft: 0,
              topRight: 0,
              btmRight: 0,
              btmLeft: 0
            }
          } else {
            rotatedOffsets = calcRotatedOffsets(
              item?.pos,
              item?.size,
              item?.styles.rotate
            );
          }
  
          let copyField = {
            ...item,
            key: newID,
            ["styles"]: {
              ...item.styles,
              zIndex: highestZIndex + i + 1,
              lock: false
            },
            rotatedOffsets,
          }
          if (item?.groupAsOne?.keys?.length > 0) {
            return {
              ...copyField,
              groupAsOne: {
                keys: updateKeys,
                ...copiedItems.copiedArea,
                offset: {
                  x: offset.x,
                  y: offset.y 
                },
              }
            }
          } else {
            return copyField
          }
        });
        const addItems = pastedItems.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        );
        newState[slideID].fields = {
          ...newState[slideID].fields,
          ...addItems,
        };
        const {xAlign, yAlign} = setSmartAignmentPoints({
          template,
          slideID,
          fieldIDs: updateKeys,
          tempScale: state.layout.tempScale,
          tempSize: state.size,
        });
        
        return {
          ...state,
          template: newState,
          groupSelect: {
            ...state.groupSelect,
            keys: updateKeys,
            initFieldValues: initValues,
            selection: {
              ...copiedItems.copiedArea,
              offset: {
                x: offset.x,
                y: offset.y 
              },
              orgOffset: {
                w: offset.x, 
                h: offset.y
              },
              orgSize: {
                w: copiedItems.copiedArea.size.w, 
                h: copiedItems.copiedArea.size.h
              },
              show: true,
            },
          },
          fieldToEmitFromSocket: {
            type: "paste",
            // fields: [pastedItems],
            payload: {
              slideID: newState[slideID].id,
              groupedFields: true,
              groupedArea: {
                ...copiedItems.copiedArea,
                offset: {
                  x: offset.x,
                  y: offset.y
                },
              },
              updatedItems: pastedItems,
            },
          },
          smartAlignment: {
            ...state.smartAlignment,
            points: {
              x: xAlign,
              y: yAlign,
            },
            x: {
              display: false,
              value: null,
            },
            y: {
              display: false,
              value: null,
            },
          },
        };
      }
    } else {
      return state;
    }
  };
}

export function duplicateField() {
  return function (state, action) {
    const {template} = state;
    const {slideIndex, fieldID} = action.active;
    const key = action.newFieldKey;
    const newState = [...state.template];
    const field = newState[slideIndex].fields[fieldID];
    let highestZIndex = getHighestZIndex(template, slideIndex);
    const activeTemp = template[slideIndex];

    Object.entries(activeTemp.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });

    let rotatedOffsets = field?.rotatedOffsets;
    let updatedOffsets = {
      x: field.pos.x,
      y: field.pos.y + field.size.h / 2,
    };
    if (field?.styles?.rotate !== 0) {
      rotatedOffsets = calcRotatedOffsets(
        updatedOffsets,
        field?.size,
        field?.styles.rotate
      );
    };

    const addNewField = {
      ...newState[slideIndex].fields[fieldID],
      key,
      pos: updatedOffsets,
      ["styles"]: {
        ...field.styles,
        zIndex: highestZIndex + 1,
      },
      rotatedOffsets
    };
    newState[slideIndex].fields = {
      ...newState[slideIndex].fields,
      [key]: addNewField,
    };

    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID: slideIndex,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });
    return {
      ...state,
      template: newState,
      activeField: key,
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
      },
      undoRedo: {
        ...state.undoRedo,
        active: {
          item: addNewField,
          trackIndex: key, // updateActiveField,
          slideID: template[slideIndex].id,
          type: "field",
        },
      },
      fieldToEmitFromSocket: {
        type: "paste",
        payload: {
          slideID: newState[slideIndex].id,
          updatedItems: [addNewField],
          groupedFields: false,
        },
        // payload: {
        //   slideID: newState[slideID].id,
        //   groupedFields: true,
        //   groupedArea: {
        //     // size: selection.orgSize,
        //     ...copiedItems.copiedArea,
        //     offset: {
        //       x: copiedItems.copiedArea.offset.x + 20,
        //       y: copiedItems.copiedArea.offset.y + 20,
        //     },
        //   },
        //   updatedItems: pastedItems,
        // },
      },
    };
  };
}

// text & table editing content
export const enableTextEdit = () => {
  return function (state) {
    return {
      ...state,
      enableTextEdit: !state.enableTextEdit,
    };
  };
};

export function storeCustomFonts() {
  return function (state, action) {
    let fontList = {};
    const {payload} = action;

    loadOatAllFonts();

    const customFont = document.createElement("style");
    for (let i = 0; i < payload.length; i++) {
      fontList = {
        ...fontList,
        [payload[i].fontFamily]: {
          fontFamily: payload[i].fontFamily,
          types: payload[i].fontTypes,
        },
      };

      if (payload[i].fontTypes.length > 0) {
        customFont.appendChild(
          document.createTextNode(
            `@font-face {
            font-family: "${
              payload[i].fontFamily +
              "-" +
              payload[i].fontTypes[0].fontWeight +
              "-" +
              payload[i].fontTypes[0].fontStyle
            }";
            src: url(${`https://oat-users.s3.amazonaws.com/${payload[i].fontTypes[0].src}`});
            format("${payload[i].fontTypes[0].format}");
            font-style: "${payload[i].fontTypes[0].fontStyle}";
            font-weight: ${payload[i].fontTypes[0].fontWeight};
          }`
          )
        );
      }
      document.head.appendChild(customFont);
    }

    return {
      ...state,
      libraryCustomFonts: {
        fonts: {
          ...fontList,
        },
        isLoadedFirstTime: true,
      },
    };
  };
}

export function addLibraryFont() {
  return function (state, action) {
    const {fontFamily} = action.payload;
    return {
      ...state,
      libraryCustomFonts: {
        ...state.libraryCustomFonts,
        fonts: {
          ...state.libraryCustomFonts.fonts,
          [fontFamily]: {
            fontFamily,
            types: state.libraryCustomFonts.fonts?.[fontFamily]
              ? [
                  ...state.libraryCustomFonts.fonts?.[fontFamily]?.types,
                  action.payload,
                ]
              : [action.payload],
          },
        },
      },
    };
  };
}

export function selectFontFamily() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const field = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...field,
      ["styles"]: {
        ...field.styles,
        fontFamily: action.payload.fontFamily,
        fontStyle: action?.payload?.fontStyle ? action?.payload?.fontStyle : field.styles?.fontStyle,
        fontWeight: action.payload?.fontWeight
          ? action.payload.fontWeight
          : field.styles.fontWeight,
      },
      font: action.payload,
    };
    return {
      ...state,
      template: newState,
    };
  };
}

// effecting bold & italic
export function selectFontType() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const field = newState[activeSlide].fields[activeField];
    const {fontWeight, fontStyle, src, format, fontType} = action.payload;
    newState[activeSlide].fields[activeField] = {
      ...field,
      ["styles"]: {
        ...field.styles,
        fontWeight,
        fontStyle,
      },
      font: {
        custom: newState[activeSlide].fields[activeField].font.custom,
        fontType,
        src,
        format,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function draggingField() {
  return function (state, action) {
    if (action.dragging) {
      return {
        ...state,
        dragging: {
          status: true,
          orig: action.orig,
        },
      };
    } else {
      return {
        ...state,
        dragging: {
          status: false,
          orig: {},
        },
      };
    }
  };
}

export function resizingField() {
  return function (state, action) {
    if (action.resizing) {
      return {
        ...state,
        resizing: {
          status: action.resizing,
          orig: action.orig,
          direction: action.direction,
        },
      };
    } else {
      return {
        ...state,
        resizing: {
          status: action.resizing,
          orig: {},
          direction: null,
        },
      };
    }
  };
}

export const updateMultipleFieldStyles = () => {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const field = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...field,
      ["styles"]: {
        ...field.styles,
        ...action.payload,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
};

export const setFieldToAlignPosition = () => {
  return function (state, action) {
    const tempSize = state.size;
    const newState = [...state.template];
    const {payload} = action;
    const appliedRatio = tempSize.w > tempSize.h ? 7 : 10;
    if (!state.groupSelect.selection.show) {
      const {slideID, fieldID} = action.active;
      const field = newState[slideID].fields[fieldID];
      const fieldSize = newState[slideID].fields[fieldID].size;
      if (payload === "center-center") {
        const newX = (tempSize.w - fieldSize.w) / 2;
        const newY = (tempSize.h - fieldSize.h) / 2;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: newX,
            y: newY,
          },
        };
      } else if (payload === "center-start") {
        const newX = tempSize.w / 2;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: newX,
            y: field.pos.y,
          },
        };
      } else if (payload === "top-left") {
        const newX = (tempSize.w * appliedRatio) / 100;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: newX,
            y: newX, // set newX here
          },
        };
      } else if (payload === "top-center") {
        const newX = (tempSize.w - fieldSize.w) / 2;
        const newY = (tempSize.w * appliedRatio) / 100;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: newX,
            y: newY,
          },
        };
      } else if (payload === "center-left") {
        const newX = (tempSize.w * appliedRatio) / 100;
        const newY = (tempSize.h - fieldSize.h) / 2;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: newX,
            y: newY,
          },
        };
      } else if (payload === "center-right") {
        const newX = (tempSize.w * appliedRatio) / 100;
        const newY = (tempSize.h - fieldSize.h) / 2;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: tempSize.w - newX - fieldSize.w,
            y: newY,
          },
        };
      } else if (payload === "top-right") {
        const newX = (tempSize.w * appliedRatio) / 100;
        const newY = (tempSize.w * appliedRatio) / 100;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: tempSize.w - newX - fieldSize.w,
            y: newY,
          },
        };
      } else if (payload === "bottom-left") {
        const newX = (tempSize.w * appliedRatio) / 100;
        const gapY = (tempSize.h * appliedRatio) / 100;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: newX,
            y: tempSize.h - gapY - fieldSize.h,
          },
        };
      } else if (payload === "bottom-center") {
        const newX = (tempSize.w - fieldSize.w) / 2;
        const gapY = (tempSize.h * appliedRatio) / 100;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: newX,
            y: tempSize.h - gapY - fieldSize.h,
          },
        };
      } else if (payload === "bottom-right") {
        const newX = (tempSize.w * appliedRatio) / 100;
        const gapY = (tempSize.h * appliedRatio) / 100;
        newState[slideID].fields[fieldID] = {
          ...field,
          pos: {
            x: tempSize.w - newX - fieldSize.w,
            y: tempSize.h - gapY - fieldSize.h,
          },
        };
      }
      return {
        ...state,
        template: newState,
        fieldToEmitFromSocket: {
          type: "update",
          field: newState[slideID].fields[fieldID],
        },
      };
    } else {
      const {groupSelect, activeSlide} = state;
      const currentSlide = state.template.find(
        (slide, index) => index === activeSlide
      );
      let prevFields = [],
        updatedFieldsToEmit = [];
      const {keys, selection} = state.groupSelect;

      let updatedX = 0,
        updatedY = 0;
      if (payload === "center-center") {
        updatedX = (tempSize.w - selection.size.w) / 2;
        updatedY = (tempSize.h - selection.size.h) / 2;
      } else if (payload === "center-left") {
        updatedX = (tempSize.w * appliedRatio) / 100;
        updatedY = (tempSize.h - selection.size.h) / 2;
      } else if (payload === "top-left") {
        updatedX = (tempSize.w * appliedRatio) / 100;
        updatedY = (tempSize.w * appliedRatio) / 100;
      } else if (payload === "top-middle-edge") {
        updatedX = tempSize.w / 2;
        updatedY = (tempSize.w * appliedRatio) / 100;
      } else if (payload === "center-middle-edge") {
        updatedX = tempSize.w / 2;
        updatedY = (tempSize.h - selection.size.h) / 2;
      }
      const updatedFields = Object.entries(newState[activeSlide].fields).map(
        ([id, field]) => {
          if (keys?.includes(field.key) && !field.styles.lock) {
            const updateOffset = {
              ...field.pos,
              x: field.pos.x - (selection.offset.x - updatedX),
              y: field.pos.y - (selection.offset.y - updatedY),
            };
            let rotatedOffsets = field?.rotatedOffsets;
            if (field?.styles?.rotate !== 0) {
              rotatedOffsets = calcRotatedOffsets(
                updateOffset,
                field?.size,
                field?.styles.rotate
              );
            }
            if (field?.subtype !== "line") {
              prevFields.push(field);

              updatedFieldsToEmit.push({
                key: field.key,
                offset: updateOffset,
              });
              return {
                ...field,
                ["pos"]: updateOffset,
                rotatedOffsets
              };
            } else if (field?.subtype === "line") {
              updatedFieldsToEmit.push({
                key: field.key,
                offset: updateOffset,
              });
              return {
                ...field,
                ["pos"]: updateOffset,
                rotatedOffsets
              };
            }
          } else {
            return field;
          }
        }
      );

      newState[activeSlide] = {
        ...newState[activeSlide],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };

      const updatedFieldsToEmitAsObject = updatedFieldsToEmit.reduce(
        (acc, item) => ({...acc, [item.key]: item}),
        {}
      );

      const updateSelectedArea = {
        x: updatedX,
        y: updatedY,
      };

      return {
        ...state,
        template: newState,
        groupSelect: {
          ...groupSelect,
          selection: {
            ...selection,
            offset: updateSelectedArea,
            orgOffset: updateSelectedArea,
          },
        },
        // emitItemsToSocket is used in GroupDragResize.js
        // fieldToEmitFromSocket is used in Socket.js
        fieldToEmitFromSocket: {
          type: "emit-grouped-fields-drag",
          payload: {
            slideID: currentSlide.id,
            groupedArea: {
              offset: updateSelectedArea,
              size: selection.orgSize,
            },
            updatedItems: updatedFieldsToEmitAsObject,
          },
        },
      };
    }
  };
};

export function groupFieldsAsOne() {
  return function (state) {
    const {groupSelect, template, activeSlide} = state;
    if (groupSelect.selection.show && !groupSelect?.groupAsOne) {
      const newState = [...template];
      const updatedFields = Object.entries(newState[activeSlide].fields).map(
        ([id, field]) => {
          if (groupSelect.keys?.includes(field.key)) {
            return {
              ...field,
              groupAsOne: {
                keys: groupSelect.keys,
                size: groupSelect.selection.size,
                offset: groupSelect.selection.offset
              }
            }
          } else {
            return field;
          }
      });

      newState[activeSlide] = {
        ...newState[activeSlide],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    
      return {
        ...state,
        template: newState,
        groupSelect: {
          ...state.groupSelect,
          groupAsOne: false, 
        },
      };
    } else if (groupSelect?.groupAsOne) {
      // ungroup
      const newState = [...template];
      const updatedFields = Object.entries(newState[activeSlide].fields).map(
        ([id, field]) => {
          if (groupSelect.keys?.includes(field.key)) {
            return {
              ...field,
              groupAsOne: {
                keys: [],
              }
            }
          } else {
            return field;
          }
      });

      newState[activeSlide] = {
        ...newState[activeSlide],
        fields: updatedFields.reduce(
          (acc, item) => ({...acc, [item.key]: item}),
          {}
        ),
      };
    
      return {
        ...state,
        template: newState,
      };
    }
    return state;
  };
}

export const applyLinkToImageIcon = () => {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const field = newState[slideID].fields[fieldID];

    // slide number
    let linkedSlideID;
    const externalLink = action.link.length >= 3 ? true : false;
    if (!externalLink) {
      const linkSlideInfo = newState && newState.find((item, index) => index === action.link);
      linkedSlideID = linkSlideInfo?.id;
    }

    newState[slideID].fields[fieldID] = {
      ...field,
      link: externalLink ? action.link : linkedSlideID,
    };
    return {
      ...state,
      template: newState,
    };
  };
};

export function addLinkToField() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    const {linkType, value} = action?.payload;
    const slide = newState.find((item, i) => i + 1 == value);
    if (
      slide &&
      linkType === "slide-number" &&
      value !== ""
    ) {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        link: {
          value: slide ? slide.id : "",
          linkType,
        },
      };
    } else if (
      linkType === "url" &&
      value !== ""
    ) {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        link: {
          value,
          linkType,
        },
      };
    } else {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        link: {
          value: "",
          linkType: "",
        },
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
}