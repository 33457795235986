import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {primaryPurple, red3} from "../../../oat-color-variables";
import {Trash} from "../../../oat-svg-icons";
import {Button, Modal} from "../../../oat-ui";

const DisplayItem = ({item, onSelect}) => {
  const handleClick = () => {
    onSelect(item);
  };
  return (
    <WrapItem
      style={{background: item.bg1, fontFamily: item.font}}
      onClick={handleClick}
    >
      <h2 style={{color: item.header}}>{item.name}</h2>
      <p style={{color: item.normalText}}>Secondary</p>
    </WrapItem>
  );
};

const TempThemeList = ({item}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [themes, setThemes] = useState([]);
  const [processing, setProcessing] = useState(false);
  let navigate = useNavigate();

  const onSelectItem = (value) => {
    setOpenModal(true);
    setSelectedItem(value);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    setProcessing(true);
    await fetch(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template-themes`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: selectedItem.key,
          tag: selectedItem.tag,
        }),
      }
    )
      .then(() => {
        setProcessing(false);
        setOpenModal(false);
        setThemes((themes) =>
          themes.filter((item) => item.SK !== selectedItem.SK)
        );
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetch(
      `https://puh958jp9k.execute-api.us-east-1.amazonaws.com/main/template-themes`
    )
      .then((res) => res.json())
      .then((data) => setThemes(data));
  }, []);

  const navigateDesignPage = () => {
    return navigate(`/admin/template-themes/create`);
  };

  return (
    <Layout>
      <TopPanel>
        <Create>
          <Button onClick={navigateDesignPage}>Create Theme</Button>
        </Create>
      </TopPanel>
      <Items>
        {themes &&
          themes.map((item, i) => (
            <DisplayItem key={i} item={item} onSelect={onSelectItem} />
          ))}
      </Items>
      {openModal ? (
        <Modal width={400} height={320} close={closeModal}>
          <ModalContent>
            <WrapInfo>
              <Field>
                <Name>Theme Name</Name>
                <Value>{selectedItem.name}</Value>
              </Field>
              <Field>
                <Name>Created At</Name>
                <Value>{selectedItem.name}</Value>
              </Field>
            </WrapInfo>
            <Actions>
              <Delete
                onClick={handleDelete}
                style={{opacity: !processing ? 1 : 0.7}}
              >
                <WrapSvg>{Trash}</WrapSvg>
                {processing ? <span>Deleting...</span> : undefined}
              </Delete>
              <Edit>Edit</Edit>
            </Actions>
          </ModalContent>
        </Modal>
      ) : undefined}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 18px;
`;

const TopPanel = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 12px;
`;

const Create = styled.div`
  width: 150px;
  height: 42px;
`;

const WrapItem = styled.div`
  width: 260px;
  height: 200px;
  border-radius: 5px;
  margin: 14px 14px 14px 0;
  padding: 12px;
  cursor: pointer;
  h2 {
    font-size: 4em;
    padding-bottom: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 245px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 1.6rem;
    padding-bottom: 14px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
`;

const WrapInfo = styled.div`
  padding: 14px;
`;

const Field = styled.div`
  display: flex;
  font-size: 1rem;
  margin-bottom: 18px;
`;

const Name = styled.div`
  flex: 1;
`;

const Value = styled.div`
  flex: 2;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #dedede;
`;

const Edit = styled.button`
  background: ${primaryPurple};
  color: #fff;
  padding: 8px 12px;
  cursor: pointer;
  width: 90px;
  border-radius: 4px;
  font-size: 0.96rem;
  border: none;
`;

const WrapSvg = styled.div`
  width: 18px;
  height: 18px;
  fill: #f95454;
`;

const Delete = styled.div`
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  span {
    font-size: 12px;
    padding-left: 4px;
  }
  &:hover {
    background: ${red3};
  }
`;

export default TempThemeList;
