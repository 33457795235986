import React from "react";
import {FolderHeader} from "./folderStyles";
import styled from "styled-components";
import {onCategoryChange} from "../../../store/actions/tempCategory.action";
import {connect} from "react-redux";
import {useNavigate} from "react-router";

const Folder = ({category, key, onCategoryChange, active}) => {
  let navigate = useNavigate();

  const switchFolder = () => {
    const {value, ratio} = category;
    onCategoryChange(category);
    return navigate(`/admin/designs/${value}/${ratio}`);
  };

  return (
    <Wrapper
      key={key}
      onClick={switchFolder}
      style={{
        background: active.value === category.value ? "#f4eafff0" : undefined,
      }}
    >
      <FolderHeader>
        <p>{category.name}</p>
      </FolderHeader>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 10px;
  position: relative;
  justify-content: flex-start;
  border-radius: 7px;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

export default connect(null, {onCategoryChange})(Folder);
