import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {changeChartType} from "../../../../../../../store/actions/fields/chart.action";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import DropDown from "../../../../../../../ui/inputs/DropDown";
import {InputName} from "../../common/WrapColorStyles";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";

const types = [
  {display: "Pie Chart", value: "pie"},
  {display: "Donut Chart", value: "donut"},
  {display: "Column Chart", value: "column-chart"},
  {display: "Bar Chart", value: "bar-chart"},
  {display: "Area Chart", value: "area-chart"},
  {display: "Line Chart", value: "line-chart"},
  {display: "Stacked Column Chart", value: "stacked-column-chart"},
  {display: "Stacked Area Chart", value: "stacked-area-chart"},
];
const ChangeChartType = ({
  chartType,
  activeSlide,
  activeField,
  changeChartType,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const changeValue = (item) => {
    const payload = {
      slideID: activeSlide,
      fieldID: activeField,
      type: item,
    };
    changeChartType(payload);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <Wrapper>
      <InputName>Chart Type</InputName>
      <WrapDropDown>
        <DropDown
          onSelect={changeValue}
          selected={chartType}
          options={types}
          minWidth={130}
          overflowY="scroll"
          height={160}
          boxWidth={140}
        />
      </WrapDropDown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const WrapDropDown = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const mapStateToProps = (state) => {
  return {
    activeSlide: selectActiveSlide(state.designTemplate),
    activeField: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  changeChartType,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(ChangeChartType);
