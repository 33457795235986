import React, {useState} from "react";
import {updateEditingPanel} from "../../../../../../store/actions/layout.action";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {
  selectActiveFieldProps,
  selectActiveFieldStyles,
  selectEnableTextEdit,
  //   selectModalStatus,
} from "../../../../../../store/selectors/fields.selector";
// import MobTextAlign from "./../../mobile/editingPanel/text/MobTextAlign";
// import {openModal} from "../../../../../store/actions/common";
// import MobLockPosition from "../editingPanel/shared/MobLockPosition";
// import SvgOpacity from "../../../../../styles/svg/Opacity";
import {Items, Item, Title} from "./MobilePanelStyles";
import MobileDrawer from "../../../../drawer/MobileDrawer";
import {enableTextEdit} from "../../../../../../store/actions/fields/text.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import styled from "styled-components";
import {Bold, Italic} from "../../../../../../../oat-svg-icons";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
} from "../../../../../../ui/svg/TextAligns";
import TextDrawers from "./edit/TextDrawers";
import LockField from "./edit/common/LockField";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const TextPanel = ({
  updateEditingPanel,
  updateFieldStyles,
  styles,
  enableTextEdit,
  slideID,
  activeField,
  textEditStatus,
  storeFieldUndoRedo,
}) => {
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: null,
    height: 0,
  });

  const handleDrawerClose = () => {
    setOpenDrawer({
      status: false,
      type: null,
      height: 0,
    });
  };

  const handleUpdates = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const onOpenDrawer = (type, height) => {
    setOpenDrawer({status: true, type, height});
  };

  const onSelectToEditText = () => {
    enableTextEdit();
  };

  const onClickedColor = () => {
    onOpenDrawer("color", 180);
  };

  const onSelectSpacing = () => {
    onOpenDrawer("spacing", 140);
  };

  const onSelectBold = () => {
    const fontWeightAsNumber = Number(styles?.fontWeight);
    const value = fontWeightAsNumber <= 500 ? "700" : "400";
    handleUpdates("fontWeight", value);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onSelectItalic = () => {
    const value = styles?.fontStyle === "italic" ? "normal" : "italic";
    handleUpdates("fontStyle", value);
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onSelectAlign = () => {
    if (styles?.textAlign === "left") {
      handleUpdates("textAlign", "center");
    } else if (styles?.textAlign === "center") {
      handleUpdates("textAlign", "right");
    } else {
      handleUpdates("textAlign", "left");
    }
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  const onSelectEffects = () => {
    onOpenDrawer("effects", 210);
  };

  const onSelectFontSize = () => {
    onOpenDrawer("fontSize", 80);
  };

  const onSelectFontFamily = () => {
    onOpenDrawer("fontFamily", 200);
  };

  const onSelectOpacity = () => {
    onOpenDrawer("opacity", 80);
  };

  const onSelectRotate = () => {
    onOpenDrawer("rotate", 80);
  };

  const onSelectLayer = () => {
    onOpenDrawer("layer", 80);
  };

  const onSelectTextBg = () => {
    onOpenDrawer("text-background", 180);
  };

  const onSelectAnimations = () => {
    onOpenDrawer("animation", (window.innerHeight * 35) / 100);
  };

  const onSelectBulletContent = () => {
    onOpenDrawer("bullet-content", 180);
  }

  return (
    <>
      {!textEditStatus ? (
        <Items>
          <Item onClick={onSelectToEditText}>
            <Title>Edit Text</Title>
          </Item>

          <Item onClick={onSelectFontFamily}>
            <Title>Fonts</Title>
          </Item>

          <Item style={{padding: "0 12px"}} onClick={onClickedColor}>
            <Title>
              <div
                style={{
                  borderRadius: "50%",
                  width: 18,
                  height: 18,
                  background: styles?.color1,
                }}
              />
            </Title>
          </Item>

          <Item onClick={onSelectFontSize}>
            <Title>{Number((styles?.fontSize * styles?.scale).toFixed(1))}</Title>
          </Item>

          {activeField?.subtype === "bulletContent" ? (
            <Item onClick={onSelectBulletContent}>
              <Title>Bullets</Title>
            </Item>
          ) : undefined}

          <Item onClick={onSelectEffects}>
            <Title>Effects</Title>
          </Item>

          <Item style={{padding: "0 12px"}} onClick={onSelectAlign}>
            <Title>
              <WrapSvg>
                {styles?.textAlign === "center" ? (
                  <AlignCenter />
                ) : styles?.textAlign === "right" ? (
                  <AlignRight />
                ) : (
                  <AlignLeft />
                )}
              </WrapSvg>
            </Title>
          </Item>

          <Item style={{padding: "0 12px"}} onClick={onSelectBold}>
            <Title style={{padding: "0 10px 3px 0"}}>
              <WrapSvg>{Bold}</WrapSvg>
            </Title>
          </Item>

          <Item onClick={onSelectItalic}>
            <Title style={{padding: "0 10px 3px 0"}}>
              <WrapSvg>{Italic}</WrapSvg>
            </Title>
          </Item>

          <Item style={{padding: "0 10px 3px 0"}} onClick={onSelectOpacity}>
            <Title>Opacity</Title>
          </Item>

          <Item onClick={onSelectSpacing}>
            <Title>Spacing</Title>
          </Item>

          {/* <Item onClick={onSelectTextBg}>
            <Title>Background</Title>
          </Item> */}

          <LockField />

          <Item onClick={onSelectRotate}>
            <Title>Rotate</Title>
          </Item>

          <Item onClick={onSelectLayer}>
            <Title>Layer</Title>
          </Item>

          <Item onClick={onSelectAnimations}>
            <Title>Animate</Title>
          </Item>
        </Items>
      ) : (
        <ExitDone onClick={onSelectToEditText}>Done</ExitDone>
      )}

      {openDrawer ? (
        <MobileDrawer
          open={openDrawer.status}
          height={openDrawer.height}
          onClose={handleDrawerClose}
        >
          {activeField?.key ? (
            <TextDrawers propsType={openDrawer.type} />
          ) : undefined}
        </MobileDrawer>
      ) : undefined}
    </>
  );
};

export const ExitDone = styled.div`
  color: ${({theme}) => theme.deskPrimaryTxt};
  width: 100%;
  margin: 5px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 0.95rem;
  text-align: center;
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  fill: ${({theme}) => theme.deskPrimaryTxt};
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    styles: selectActiveFieldStyles(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    textEditStatus: selectEnableTextEdit(designTemplate),
    // drawer: selectModalStatus(template),
  };
};

export default connect(mapStateToProps, {
  updateEditingPanel,
  updateFieldStyles,
  enableTextEdit,
  storeFieldUndoRedo,
  updateFieldCollaboration,
  //   openModal,
})(TextPanel);
