export function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function isOdd(num) {
  return num % 2;
}

// either ends by 5 or 0
export const getClosestNumber = (value) => {
  const number =
    value % 5 === 0
      ? value + 1
      : isOdd(value)
      ? value + 1
      : value < 40
      ? value
      : value + 2;
  if (number <= 10) {
    if (number <=4) {
      return number + 1;
    } else if (isOdd(value)){
      return number;
    } else {
      return number + 2;
    }
  } else if (number < 50) {
    if (number <= 25) {
      return 5 * Math.ceil(number / 5);
    } else if (number < 50) {
      return 10 * Math.ceil(number / 10);
    }
  } else if (number <= 500) {
    if (number < 100) {
      return 20 * Math.ceil(number / 20);
    }
    if (number <= 120) {
      return 120;
    } else if (number <= 150) {
      return 50 * Math.ceil(number / 50);
    } else if (number <= 200) {
      return 200;
    } else if (number <= 250) {
      return 250;
    } else if (number <= 500) {
      return 100 * Math.ceil(number / 100);
    }
  } else if (number <= 1000) {
    return 200 * Math.ceil(number / 200);
  } else if (number <= 1200) {
    return 1200;
  } else if (number <= 2000) {
    return 500 * Math.ceil(number / 500);
  } else {
    const numDigits = number.toString().length;
    // const defaultDivisibleInt = "10";
    const initDivisibleNumber = "1";
    const defaultDivisibleInt = initDivisibleNumber.padEnd(numDigits, "0");
    const divisibleNumber = parseInt(
      defaultDivisibleInt.toString().padEnd(numDigits - 1, "0")
    );

    // const divisibleNumber = parseInt(
    //   defaultDivisibleInt.padEnd(numDigits, "0")
    // );
    const closestNumber = divisibleNumber * Math.ceil(number / divisibleNumber);
    const reduceFractions = reduce(closestNumber, divisibleNumber);

    if (reduceFractions[0] === 2) {
      // 2 / 12000 rather than 20,000 display 12,000 or 15,000 accordingly
      const quater = (75 * closestNumber) / 100;
      const closestEdge = (60 * closestNumber) / 100;
      if (closestEdge > number) {
        return Math.ceil(closestEdge);
      } else if (quater > number) {
        return Math.ceil(quater);
      }
      return Math.ceil(closestNumber);
    } else if (reduceFractions[0] === 3) {
      const oneSixth = (1 * closestNumber) / 6;
      if (closestNumber - oneSixth > number) {
        return Math.ceil(closestNumber - oneSixth);
      }
      return Math.ceil(closestNumber);
    } else if (reduceFractions[0] === 5) {
      return Math.ceil(closestNumber);
    } else if (reduceFractions[0] === 7) {
      return Math.ceil(closestNumber + divisibleNumber);
    } else if (reduceFractions[0] === 9) {
      return Math.ceil(closestNumber + divisibleNumber);
    } else {
      return closestNumber;
    }
  }
};

const containsNoDigit = (number) => {
  const numberToString = number.toString();
  const hasDigit = /^\d+$/.test(numberToString);
  return hasDigit ? true : false;
};

export const getFactors = (positiveNumber, negativeNumber) => {
  const number = Math.ceil(positiveNumber + negativeNumber);
  let result = [];

  // to prevent from invalid array length error
  const numberLength = number.toString().length;
  const factors =
    numberLength < 17 &&
    Array.from(Array(number + 1), (_, item) => item).filter((item) => {
      const remainderZero = number % item === 0;
      if (remainderZero && number <= 7) {
        return 1;
      } else if (remainderZero && item > 1 && number <= 50) {
        return item;
      } else if (remainderZero && item > 5 && number > 50) {
        return item;
      } else {
        return 0;
      }
    });

  // let smallestDivisibleByFive = null;
  let finestScaleSet = {
    totalLines: 0,
    accumulation: 0,
  };

  for (let i = 0; i < factors.length; i++) {
    const totalLines = number / factors[i];
    const conditionMet =
      number <= 50
        ? totalLines < 7 && totalLines >= 2
        : totalLines < 7 && totalLines > 3;
    const divisibleByRatio =
      containsNoDigit(positiveNumber / factors[i]) &&
      containsNoDigit(negativeNumber / factors[i]);

    // const initDivisibleNumber = "5";
    // const defaultDivisibleInt = initDivisibleNumber.padEnd(numDigits, "0");
    // const divisibleNumber = parseInt(
    //   defaultDivisibleInt.toString().padEnd(numDigits - 1, "0")
    // );

    // const transformToString = factors[i].toString().includes()
    if (conditionMet && divisibleByRatio) {
      const numDigits = factors[i].toString();
      const firstNumber = parseInt(numDigits.charAt(0));
      if (
        firstNumber !== 6 &&
        // firstNumber !== 7 &&
        firstNumber !== 9
      ) {
        // priority if divisible by 5 === 0,
        if (factors[i] % 5 === 0) {
          // between 4-6 lines is more preferrable
          if (4 <= totalLines && 6 >= totalLines) {
            finestScaleSet = {
              totalLines: totalLines,
              accumulation: factors[i],
            };
          } else if (3 === totalLines) {
            finestScaleSet = {
              totalLines: totalLines,
              accumulation: factors[i],
            };
          }
        } else if (finestScaleSet.totalLines <= 2) {
          finestScaleSet = {
            totalLines: totalLines,
            accumulation: factors[i],
          };
        }
      }
    }
    if (result.length > 5) break;
  }

  result.push({
    lines: finestScaleSet.totalLines,
    total: number,
    acc: finestScaleSet.accumulation,
  });

  return result;
};

// reduce a fraction by finding the Greatest Common Divisor and dividing by it.
function reduce(numerator, denominator) {
  let gcd = function gcd(a, b) {
    return b ? gcd(b, a % b) : a;
  };
  gcd = gcd(numerator, denominator);
  return [numerator / gcd, denominator / gcd];
}

// if there is both positive & negative values
export const getClosestNumberWithNegValues = (positive, negative) => {
  // discard "5" but anything divisible by 5 add + 1 so 50 -> 51 and it will add space from ceiling
  const positiveValue = positive + 1;
  const negativeValue = Math.abs(negative + 1);
  const largerValue =
    positiveValue >= negativeValue ? positiveValue : negativeValue;

  const numDigits = largerValue.toString().length;
  const initDivisibleNumber = "1";
  const defaultDivisibleInt = initDivisibleNumber.padEnd(numDigits, "0");
  const divisibleNumber = parseInt(
    defaultDivisibleInt.toString().padEnd(numDigits - 1, "0")
  );

  const positiveResult =
    divisibleNumber * Math.ceil(positiveValue / divisibleNumber);
  const negativeResult =
    divisibleNumber * Math.ceil(negativeValue / divisibleNumber);
  const reduceFractions = reduce(positiveResult, negativeResult);

  if (largerValue <= 7) {
    if (reduceFractions.includes(2) && reduceFractions.includes(1)) {
      if (reduceFractions[0] !== 2) {
        if (reduceFractions[0] === 1) {
          return {
            positive: positiveValue + 2,
            negative: negativeValue + 1,
          };
        }
      } else if (reduceFractions[1] !== 2) {
        if (reduceFractions[1] === 1) {
          return {
            positive: positiveValue,
            negative: negativeValue,
          };
        }
      }
    } else if (
      reduceFractions.includes(3) &&
      (reduceFractions.includes(1) || reduceFractions.includes(4))
    ) {
      if (reduceFractions[0] !== 3) {
        if (reduceFractions[0] === 1) {
          return {
            // we don't want 7
            positive: isOdd(positiveValue)
              ? positiveValue + 1
              : positiveValue + 2,
            negative: negativeValue + 1,
          };
        } else if (reduceFractions[0] === 4) {
          return {
            // we don't want 7
            positive: positiveValue + 2,
            negative: negativeValue + 1,
          };
        }
      } else if (reduceFractions[1] !== 3) {
        if (reduceFractions[1] === 1) {
          return {
            positive: isOdd(positiveValue)
              ? positiveValue + 1
              : positiveValue + 2,
            negative: negativeValue + 1,
          };
        } else if (reduceFractions[1] === 4) {
          return {
            // we don't want 7
            positive: positiveValue + 2,
            negative: negativeValue + 1,
          };
        }
      }
    }
    // 5:2,7
    else if (reduceFractions.includes(5) && reduceFractions.includes(7)) {
      if (reduceFractions[0] !== 5) {
        if (reduceFractions[0] === 2) {
          // [2,5] -> 5:5
          return {
            positive: positiveValue + 3,
            negative: negativeValue,
          };
        } else if (reduceFractions[0] === 7) {
          // [7,5] -> 8:8
          return {
            positive: positiveValue + 1,
            negative: negativeValue + 3,
          };
        }
      } else if (reduceFractions[1] !== 5) {
        if (reduceFractions[1] === 2) {
          // [5,2] -> 5:5
          return {
            positive: positiveValue,
            negative: negativeValue + 3,
          };
        } else if (reduceFractions[1] === 7) {
          // [5,7] -> 8:8
          return {
            positive: positiveValue + 3,
            negative: negativeValue + 1,
          };
        }
      }
    }
    // 7:5,6
    else if (
      reduceFractions.includes(7) &&
      (reduceFractions.includes(5) || reduceFractions.includes(6))
    ) {
      if (reduceFractions[0] !== 7) {
        if (reduceFractions[0] === 5 || reduceFractions[0] === 6) {
          // [5,6] -> 8:8
          return {
            positive: 8,
            negative: 8,
          };
        }
      } else if (reduceFractions[1] !== 7) {
        if (reduceFractions[1] === 5 || reduceFractions[1] === 6) {
          // [5,6] -> 8:8
          return {
            positive: 8,
            negative: 8,
          };
        }
      } else if (reduceFractions[0] === 7 || reduceFractions[1] === 7) {
        // [7,6] -> 8:8
        return {
          positive: 8,
          negative: 8,
        };
      }
    }
    return {
      positive: 2 * Math.ceil(positiveValue / 2),
      negative: 2 * Math.ceil(negativeValue / 2),
    };
  } else if (largerValue <= 15) {
    return {
      positive: 5 * Math.ceil(positiveValue / 5),
      negative: 5 * Math.ceil(negativeValue / 5),
    };
  } else {
    let accPositive = positiveResult;
    let accNegative = negativeResult;

    // 1:2,5,6,7,8,9,10
    if (
      reduceFractions.includes(1) &&
      (reduceFractions.includes(2) ||
        reduceFractions.includes(5) ||
        reduceFractions.includes(6) ||
        reduceFractions.includes(7) ||
        reduceFractions.includes(8) ||
        reduceFractions.includes(9) ||
        reduceFractions.includes(10))
    ) {
      if (reduceFractions[0] !== 1) {
        // if (reduceFractions[0] === 5) {
        //   // [5,1] -> 5:2
        //   accPositive = Math.ceil(positiveResult);
        //   accNegative = Math.ceil(negativeResult);
        // }
        if (reduceFractions[0] === 2) {
          // 2:1
          // eg: 14000, -9000, normally 14000 would transform to 20000, but that would create a huge gap
          // 14000, -9000 -> 15000, -10000
          // 15000, -9000 -> 20000, -10000
          const lowerValue = negativeResult + negativeResult / 2;
          if (positiveValue > lowerValue) {
            accPositive = Math.ceil(positiveResult);
          } else {
            accPositive = Math.ceil(lowerValue);
          }
          // eg: 10300, -4300 -> 15000, 5000
          const belowHalfOfAccNegative = accNegative / 2 > negativeValue;
          if (belowHalfOfAccNegative) {
            accNegative = accNegative / 2;
          }
        } else if (reduceFractions[0] === 6) {
          accPositive = Math.ceil(positiveResult);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 7) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 8) {
          accPositive = Math.ceil(positiveResult);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 10) {
          accPositive = Math.ceil(positiveResult);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      } else if (reduceFractions[1] !== 1) {
        if (reduceFractions[1] === 2) {
          // 1:2
          const belowHalfOfAccPositive = accPositive / 2 > positiveValue;
          if (belowHalfOfAccPositive) {
            accPositive = accPositive / 2;
          }
          const lowerValue = positiveResult + positiveResult / 2;
          if (negativeValue > lowerValue) {
            accNegative = Math.ceil(negativeResult);
          } else {
            accNegative = Math.ceil(lowerValue);
          }
        } else if (reduceFractions[1] === 6) {
          // 1:6 -> 2:6
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult);
        } else if (reduceFractions[1] === 7) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[1] === 8) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult);
        } else if (reduceFractions[1] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[1] === 10) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    // 2:3, 5,7,9
    else if (
      reduceFractions.includes(2) &&
      (reduceFractions.includes(5) ||
        reduceFractions.includes(7) ||
        reduceFractions.includes(9))
    ) {
      if (reduceFractions[0] !== 2) {
        if (reduceFractions[0] === 5) {
          // [5,2] -> 6:2
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult);
        } else if (reduceFractions[0] === 7) {
          // [7,2] -> 8:2
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult);
        } else if (reduceFractions[0] === 9) {
          // [9,2] -> 10:2
          accPositive = Math.ceil(positiveResult + divisibleNumber);
        }
      } else if (reduceFractions[1] !== 2) {
        if (reduceFractions[1] === 5) {
          // [2,5] -> 2:6
          accPositive = Math.ceil(positiveResult);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[1] === 7) {
          // [2,7] -> 2:8
          accPositive = Math.ceil(positiveResult);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[1] === 9) {
          // [2,9] -> 2:10
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    //3:4,5,7,8,10
    else if (
      reduceFractions.includes(3) &&
      (reduceFractions.includes(4) ||
        reduceFractions.includes(5) ||
        reduceFractions.includes(7) ||
        reduceFractions.includes(8) ||
        reduceFractions.includes(10))
    ) {
      if (reduceFractions[0] !== 3) {
        if (reduceFractions[0] === 4) {
          // [4,3] -> 4:4
          // eg 3500,-2300 -> 4000,3000
          // eg 7700,-5700 -> 8000,6000 -> 4:3 but diff is 2
          // 8000 - 6000 -> 2000, needs to x with 2 instead
          const calcDiff = String(positiveResult - negativeResult).charAt(0);
          const multipler = Number(calcDiff);
          accNegative = Math.ceil(negativeResult + divisibleNumber * multipler);
        } else if (reduceFractions[0] === 5) {
          // [5,3] -> 5:5
          accNegative = accPositive;
        } else if (reduceFractions[0] === 7) {
          // [7,3] -> 8:4
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 8) {
          // [8,3] -> 8,4
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 10) {
          // [10,3] -> 10:5 -> 2:1
          accNegative = Math.ceil(negativeResult + divisibleNumber * 2);
        }
      } else if (reduceFractions[1] !== 3) {
        if (reduceFractions[1] === 4) {
          // [3,4] -> 4:4
          // accPositive = Math.ceil(positiveResult + divisibleNumber);
          const calcDiff = String(negativeResult - positiveResult).charAt(0);
          const multipler = Number(calcDiff);
          accPositive = Math.ceil(positiveResult + divisibleNumber * multipler);
        } else if (reduceFractions[1] === 5) {
          // [3,5] -> 5:5
          accPositive = accNegative;
        } else if (reduceFractions[1] === 7) {
          // [3,7] -> 4:8
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[1] === 8) {
          // [3,8] -> 4:8
          accPositive = Math.ceil(positiveResult + divisibleNumber);
        } else if (reduceFractions[1] === 10) {
          // [3,10] -> 5:10 -> 1:2
          accPositive = Math.ceil(positiveResult + divisibleNumber * 2);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    //4:5,7,9
    else if (
      reduceFractions.includes(4) &&
      (reduceFractions.includes(5) ||
        reduceFractions.includes(7) ||
        reduceFractions.includes(9))
    ) {
      if (reduceFractions[0] !== 4) {
        if (reduceFractions[0] === 5) {
          // [5,4] -> 5:5
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 7) {
          // [7,4] -> 4:2
          accPositive = Math.ceil(positiveResult + divisibleNumber);
        } else if (reduceFractions[0] === 9) {
          // [9,4] -> 10:5
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      } else if (reduceFractions[1] !== 4) {
        if (reduceFractions[1] === 5) {
          // [4,5] -> 5:5
          accPositive = Math.ceil(positiveResult + divisibleNumber);
        } else if (reduceFractions[1] === 7) {
          // [4,7] -> 2:4
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[1] === 9) {
          // [4,9] -> 5,10
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    // 5:6,7,8,9
    else if (
      reduceFractions.includes(5) &&
      (reduceFractions.includes(6) ||
        reduceFractions.includes(7) ||
        reduceFractions.includes(8) ||
        reduceFractions.includes(9))
    ) {
      if (reduceFractions[0] !== 5) {
        if (reduceFractions[0] === 6) {
          // [6,5] -> 6:6
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 7) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 3);
        } else if (reduceFractions[0] === 8) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 2);
        } else if (reduceFractions[0] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
        }
      } else if (reduceFractions[1] !== 5) {
        if (reduceFractions[1] === 6) {
          // [5,6] -> 6:6
          accPositive = Math.ceil(positiveResult + divisibleNumber);
        } else if (reduceFractions[1] === 7) {
          // [5,7] -> 5:10
          accNegative = Math.ceil(negativeResult + divisibleNumber * 3);
        } else if (reduceFractions[1] === 8) {
          accNegative = Math.ceil(negativeResult + divisibleNumber * 2);
        } else if (reduceFractions[1] === 9) {
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    // 6:7,9
    else if (
      reduceFractions.includes(6) &&
      (reduceFractions.includes(7) || reduceFractions.includes(9))
    ) {
      if (reduceFractions[0] !== 6) {
        if (reduceFractions[0] === 7) {
          //[7,6] -> 7:7
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[0] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
        }
      } else if (reduceFractions[1] !== 6) {
        if (reduceFractions[1] === 7) {
          //[6,7] -> 7:7
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber * 3);
        } else if (reduceFractions[1] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 4);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    // 7:7,8,9,10
    else if (
      reduceFractions.includes(7) &&
      (reduceFractions.includes(7) ||
        reduceFractions.includes(8) ||
        reduceFractions.includes(9) ||
        reduceFractions.includes(10))
    ) {
      if (reduceFractions[0] === 7) {
        if (reduceFractions[1] === 7) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 3);
          accNegative = Math.ceil(negativeResult + divisibleNumber * 3);
        } else if (reduceFractions[1] === 8) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 3);
          accNegative = Math.ceil(negativeResult + divisibleNumber * 2);
        } else if (reduceFractions[1] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 3);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        } else if (reduceFractions[1] === 10) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 3);
          accNegative = Math.ceil(negativeResult);
        }
      } else if (reduceFractions[1] === 7) {
        if (reduceFractions[0] === 7) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 3);
          accNegative = Math.ceil(negativeResult + divisibleNumber * 3);
        } else if (reduceFractions[0] === 8) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 2);
          accNegative = Math.ceil(negativeResult + divisibleNumber * 3);
        } else if (reduceFractions[0] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber);
          accNegative = Math.ceil(negativeResult + divisibleNumber * 3);
        } else if (reduceFractions[0] === 10) {
          accPositive = Math.ceil(positiveResult);
          accNegative = Math.ceil(negativeResult + divisibleNumber * 3);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    // 8:9
    else if (reduceFractions.includes(8) && reduceFractions.includes(9)) {
      if (reduceFractions[0] === 8) {
        // 8:9 -> 10:10
        if (reduceFractions[1] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 2);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      } else if (reduceFractions[1] === 8) {
        // 9:8 -> 10:10
        if (reduceFractions[0] === 9) {
          accPositive = Math.ceil(positiveResult + divisibleNumber * 2);
          accNegative = Math.ceil(negativeResult + divisibleNumber);
        }
      }
      return {
        positive: accPositive,
        negative: accNegative,
      };
    }

    // important
    return {
      positive: accPositive,
      negative: accNegative,
    };
  }
};
