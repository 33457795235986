import { themeBuilder } from "../../data/themeBuilder.data";
import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import {getHighestZIndex} from "./utils";

export function embedVideoLink() {
  return function (state, action) {
    const {template} = state;
    const {key, slideID, link, selectFieldOnCreate, videoBy, format} = action;
    let highestZIndex = getHighestZIndex(template, slideID);
    const currentSlide = template[slideID];
    Object.entries(currentSlide.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });
    const videoWidth = (40 * state.size.w) / 100;
    const videoHeight = (videoWidth * 1080) / 1920;
    let activeTheme = state.themes.find(
      (theme) => theme.id === state.activeTheme
    );
    if(typeof activeTheme === 'undefined') {
      activeTheme = themeBuilder
    }
    const newFieldProps = {
      key,
      pos: {x: 100, y: 100},
      imgPos: {x: 0, y: 0},
      size: {
        w: videoWidth,
        h: videoHeight,
        w2: videoWidth,
        h2: videoHeight,
      },
      content: link,
      type: "video",
      subtype: action?.uploaded ? "uploaded" : "embedded", 
      resizer: "all",
      deleted: false,
      videoBy, // default user for uploaded
      format,
      ["styles"]: {
        rotate: 0,
        opacity: 1,
        zIndex: highestZIndex + 1,
        roundedBorder: true,
        showBorder: false,
        enableBorder: false,
        borderColor: activeTheme["video"]?.borderColor,
        enableShadow: false,
        shadowX: "",
        shadowY: "",
        shadowBlur: "",
        shadowColor: "",
        frame: "",
        lock: false,
        animateOnSlideDisplay: null,
        animationType: "none",
        animationSpeed: 0.5,
        animationDelay: 0,
        animationOrder: 0,
        enableExitAnimation: false,
        scaleX: 1,
        autoPlay: false
      },
    };
    const newState = [...state.template];
    newState[slideID].fields = {
      ...newState[slideID].fields,
      [key]: {...newFieldProps},
    };
    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });
    return {
      ...state,
      template: newState,
      activeField: key,
      selectedFieldType: "video",
      cachedFieldStyles: {
        rotate: 0,
        opacity: 1,
        zIndex: highestZIndex + 1,
      },
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
      fieldToEmitFromSocket: {
        type: "create",
        field: {
          ...newFieldProps,
          key,
        },
      },
    };
  };
}
