import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectTemplateVersions} from "../../../../../store/selectors/template/template.selector";

const DisplayItem = ({version, onSelect}) => {
  const handleClick = () => {
    onSelect(version);
  };
  return (
    <div onClick={handleClick}>
      Updated at {new Date(version.timestamp).toLocaleDateString("en-US")} -
      {new Date(version.timestamp).toLocaleTimeString("en-US")}
    </div>
  );
};

const TemplateVersions = ({onSelect, tempVersions}) => {
  return (
    <Layout>
      <Header>Versions</Header>
      {Object.entries(tempVersions).map(([id, items], index) => {
        return (
          <Item key={id}>
            <User>{items[0].name}</User>
            <Versions>
              {items.map((version, i) => (
                <DisplayItem key={id} version={version} onSelect={onSelect} />
              ))}
            </Versions>
          </Item>
        );
      })}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 16px 16px;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

const Header = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 26px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 22px;
`;

const User = styled.div`
  flex: 1;
  font-size: 0.92rem;
`;

const Versions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.5;
  font-size: 0.9rem;
  div {
    margin-bottom: 14px;
    cursor: pointer;
    color: ${({theme}) => theme.deskSecondaryTxt2};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    tempVersions: selectTemplateVersions(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(TemplateVersions);
