import React from "react";
import {connect} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveField} from "../../../../../../store/selectors/fields.selector";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {selectTempScale} from "../../../../../../store/selectors/template/template.selector";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import SelectFontSize from "../common/SelectFontSize";
import {WrapColorBox} from "../common/WrapColorStyles";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {Title} from "../../shared/reuseStyles/Title";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const EditChartCategories = ({
  showCategory,
  categoryColor,
  categoryFontSize,
  updateFieldStyles,
  tempScale,
  fieldID,
  slideID,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID},
      type,
      value,
    });
  };

  const handleSocketEmit = (type, value) => {
    updateFieldCollaboration();
  };

  const toggleButton = () => {
    if (!showCategory) {
      handleStyleUpdate("showCategory", true);
      handleSocketEmit();
    } else if (showCategory) {
      handleStyleUpdate("showCategory", false);
      handleSocketEmit();
    }
    storeFieldUndoRedo();
  };

  const updateCategoryFontSize = (value) => {
    handleStyleUpdate("categoryFontSize", Number(value)); //  /
    handleSocketEmit(); // /tempScale
  };

  const updateCategoryColor = (value) => {
    handleStyleUpdate("categoryColor", value);
  };

  const handleDebounced = (value) => {
    handleSocketEmit();
    storeFieldUndoRedo();
  };

  return (
    <>
      <div style={{paddingBottom: showCategory ? "2em" : 0}}>
        <Switch
          title="Show Categories"
          value={showCategory}
          onItemChange={toggleButton}
          checked={showCategory}
        />
      </div>
      {showCategory ? (
        <>
          <div style={{paddingBottom: "2rem"}}>
            <SelectFontSize
              value={Math.round(categoryFontSize)} // * tempScale
              onSelect={updateCategoryFontSize}
            />
          </div>
          <div style={{display: "flex"}}>
            <Title>Color</Title>
            <WrapColorBox>
              <PopoverColorBox
                value={categoryColor}
                updateFieldStyles={updateCategoryColor}
                onDebounce={handleDebounced}
              />
            </WrapColorBox>
          </div>
        </>
      ) : undefined}
    </>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempScale: selectTempScale(designTemplate),
    fieldID: selectActiveField(designTemplate),
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(EditChartCategories);
