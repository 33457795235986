import React, {useRef} from "react";
import styled from "styled-components";
import {detectClickOutside} from "../../../../../../../oat-ui";
import {Bouncy} from "../../../../../../../oat-ui/animation/bouncy";
import {ActiveDot} from "../../../../../../ui/styles/ActiveDot";

const DisplayItem = ({item, index, onSelect, active}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onSelect({value: item.value, index});
  };
  return (
    <Option active={active} onMouseDown={handleClick}>
      <p>{item?.display}</p>
      {active ? <ActiveDot /> : undefined}
    </Option>
  );
};

const TooltipSelectionHoc = ({
  activeItem,
  width,
  height,
  options,
  offset,
  onChange,
  onClose,
  children,
}) => {
  const popUpRef = useRef(null);

  const updateSelection = (index) => {
    onChange(index);
  };

  detectClickOutside(popUpRef, () => onClose());

  return (
    <Layout
      ref={popUpRef}
      style={{
        transform: `translate(${offset?.x}px, ${offset?.y}px)`,
      }}
    >
      <Wrapper style={{width, height}}>
        <Options>
          {children}
          {options && options.map((option, i) => (
            <DisplayItem
              key={i}
              index={i}
              item={option}
              active={activeItem === option}
              onSelect={updateSelection}
            />
          ))}
        </Options>
      </Wrapper>
    </Layout>
  );
};

export const Wrapper = styled.div`
  background: ${({theme}) => theme.deskPrimaryBgTransparent};
  border-radius: 8px;
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 0.9rem;
  padding-top: 2px;
  ${Bouncy};
`;

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4444;
`;

export const Options = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.1rem;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 6px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: #c2c6e86b;
  }
`;

const Option = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 6px 4px;
  margin: 6px 10px;
  border-radius: 6px;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg3};
  }
  &:last-child {
    margin-bottom: 12px;
  }
`;

TooltipSelectionHoc.defaultProps = {
  width: 125,
  height: 150,
  offset: {
    x: -20,
    y: 45,
  },
};

export default TooltipSelectionHoc;
