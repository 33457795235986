import styled from "styled-components";

export const PanelHeader = styled.h3`
  padding: 10px 16px 14px;
  font-size: 0.95rem;
  font-weight: 700;
`;

export const PanelLayout = styled.div`
  padding: 2em 0px 10em;
  min-height: 1100px;
`;
