import React, {useRef} from "react";
import styled from "styled-components";
import {Close as CloseSvg} from "../../oat-svg-icons";
import {createPortal} from "react-dom";
import {detectClickOutside} from "../utils/detectClickOutside";
import {getWindowSize} from "../../oat-window-size/getWindowSize";
import {Bouncy} from "../animation/bouncy";

const Modal = ({
  children,
  close,
  width,
  height,
  background,
  backdrop,
  top,
  overflowX,
  overflowY,
}) => {
  const windowSize = getWindowSize();
  const modalRef = useRef(null);
  detectClickOutside(modalRef, () => {
    close();
  });

  let getMaxWidth = 100;
  if (windowSize.width > 600) {
    getMaxWidth = 94;
  } else if (windowSize.width > 1000) {
    getMaxWidth = 90;
  }

  return createPortal(
    <Wrapper backdrop={backdrop}>
      <WrapModal style={{top: windowSize.width >= 601 ? top : "20%"}}>
        <ModalDiv
          ref={modalRef}
          background={background}
          style={{
            width: windowSize.width >= 601 ? width : "92%",
            height,
            maxWidth:
              windowSize.width >= 601
                ? `calc(${getMaxWidth}vw - 5.4rem)`
                : undefined,
          }}
        >
          <WrapChildren $overflowX={overflowX} $overflowY={overflowY}>
            {children}
          </WrapChildren>
          {windowSize.width >= 601 ? (
            <CloseBtn onClick={close}>{CloseSvg}</CloseBtn>
          ) : undefined}
        </ModalDiv>
      </WrapModal>
    </Wrapper>,
    document.getElementById("modal-box")
  );
};

Modal.defaultProps = {
  width: 930,
  height: 560,
  maxWidth: 100, // in vw
  top: 70, //(window.innerHeight * 10) / 100,
  background: "#fff",
  backdrop: "#3b375163", //"rgba(56, 57, 117, 0.39)",
  overflowX: "hidden",
  overflowY: "scroll", // "visible" if there were dropbox selection, we want it make it visible
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5555;
  background: ${({backdrop}) => (backdrop ? backdrop : "none")};
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Bouncy};
`;

const WrapModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  right: 0;
  left: 0;
  height: ${window.innerHeight}px;
`;

const ModalDiv = styled.div`
  position: relative;
  background: ${({theme, background}) =>
    theme.deskModalBg ? theme.deskModalBg : background};
  box-shadow: 4px 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 99999;
  border-radius: 8px; // put here both
  // overflow: hidden;
`;

const WrapChildren = styled.div`
  height: 100%;
  border-radius: 8px;
  overflow: ${({$overflowX}) => $overflowX};
  overflow-y: ${({$overflowY}) => $overflowY};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 3px;
  right: -42px;
  padding: 7px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  fill: #4a4a4a;
  background: #fff;
  border-radius: 50%;
  &:before {
    content: "";
    position: relative;
    width: 20px;
    height: 20px;
  }
`;

export default Modal;
