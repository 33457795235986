import React, {useState, useRef} from "react";
import {ErrorMsg} from "../../uploadStyles";
import {UploadForm, UploadFormField} from "./CreateImagePack";
import {ButtonSpinner, detectClickOutside} from "../../../../../oat-ui";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import {connect} from "react-redux";
import styled from "styled-components";
import {UploadFile} from "../../../../reuseStyles/reuseStyles";
import {useParams} from "react-router-dom";

const UploadImagesInPack = ({
  username,
  selectedPackID,
  onUploadComplete,
  onClose,
  type,
  category
}) => {
  const [uploadForm, setUploadForm] = useState({
    processing: false,
    file: null,
    keywords: [],
    error: false,
    message: null,
  });
  const modalRef = useRef();
  const inputFileRef = useRef();
  let params = useParams();

  const onInitUploadImg = () => {
    setUploadForm({
      ...uploadForm,
      file: null,
    });
    inputFileRef.current.click();
  };

  const onUploadFile = (event) => {
    if (uploadForm.error) {
      setUploadForm({
        ...uploadForm,
        error: false,
        message: null,
      });
    }
    // 10 MB
    if (event.target.files[0].size > 10485760) {
      setUploadForm({
        ...uploadForm,
        error: true,
        message: "File size cannot be bigger than 10MB.",
      });
      event.target.value = null;
    } else {
      setUploadForm({
        ...uploadForm,
        file: event.target.files[0],
        error: false,
        message: null,
      });
    }
  };

  const handleCreate = async () => {
    const image = uploadForm;
    if (!uploadForm.file) {
      setUploadForm({
        ...uploadForm,
        error: true,
        message: "Please attach a file",
      });
      return;
    }
    if (!uploadForm.processing) {
      setUploadForm({
        ...uploadForm,
        processing: true,
      });
      if (image.file) {
        let format = image.file.type.split("image/")[1];
        if (format === "svg+xml") {
          format = "svg";
        }
        let imgSize = {w: 0, h: 0};
        const img = new Image();
        let fileReader = new FileReader();
        fileReader.onload = () => (img.src = fileReader.result);
        fileReader.onerror = () => {
          setUploadForm({
            ...uploadForm,
            processing: false,
            error: true,
            message: "Couldn't identify image size.",
          });
        };
        fileReader.readAsDataURL(image.file);

        img.onload = async () => {
          imgSize = {
            w: img.width,
            h: img.height,
          };

          const response = await fetch(
            `https://5nb5ia9280.execute-api.us-east-1.amazonaws.com/main/media-for-users`, // /${type}/${category}/${selectedPackID}
            {
              method: "POST",
              body: JSON.stringify({
                contentType: image.file.type,
                imgType: format,
                owner: "oat-all",
                uploadedBy: username,
                location: {
                  locationBased: false,
                  country: "global",
                },
                type,
                keywords: uploadForm.keywords,
                // cutout,
                imgSize,
                packID: selectedPackID,
                category: 'illustrations' // params.category
              }),
            }
          )
            .then((response) => response.json())
            .catch((e) => {
              setUploadForm({
                ...uploadForm,
                processing: false,
                error: true,
                message: "Something went wrong.",
              });
            });

          const url = response?.url;
          const fields = response?.fields;
          const error = response?.error;
          const message = response?.message;

          const data = {
            bucket: "oat-images-icons",
            ...fields,
            "Content-Type": image.file.type,
            file: image.file,
          };

          const formData = new FormData();
          for (const name in data) {
            formData.append(name, data[name]);
          }

          if (!error && url) {
            await fetch(url, {
              method: "POST",
              body: formData,
            })
              .then(async () => {
                URL.revokeObjectURL(image.preview);
                // onUploadComplete(uploadedItem);
                setUploadForm({
                  processing: false,
                  file: "",
                  keywords: [],
                  error: false,
                  message: null,
                });
                onClose();
              })
              .catch((e) => {});
          } else {
            setUploadForm({
              ...uploadForm,
              processing: false,
              error: true,
              message,
            });
          }
        };
      }
    }
  };

  detectClickOutside(modalRef, () => {
    onClose();
    setUploadForm({
      processing: false,
      file: null,
      keywords: [],
      error: false,
      message: null,
    });
  });


  return (
    <Modal ref={modalRef}>
      <UploadForm>
        <UploadFormField>
          <p>File</p>
          <div
            style={{
              textDecoration: "underline",
              flex: 1,
              cursor: "pointer",
            }}
            onClick={onInitUploadImg}
          >
            Upload {uploadForm.file ? "+1 Attachment" : undefined}
          </div>
          <input
            ref={inputFileRef}
            value={""}
            type="file"
            onChange={onUploadFile}
            hidden
          />
        </UploadFormField>
      </UploadForm>

      {/* add keywords later */}

      <div style={{margin: "14px 0"}}>
        <UploadFile 
          style={{
            width: "100%", 
            height: 34,  
            opacity: uploadForm.processing ? 0.7 : 1,
            pointerEvents: uploadForm.processing ? 'none' : undefined
          }} 
          onClick={handleCreate}
        >
          {!uploadForm.processing ? "Create" : <ButtonSpinner />}
        </UploadFile>
      </div>

      {uploadForm.error ? <ErrorMsg>{uploadForm.message}</ErrorMsg> : undefined}
    </Modal>
  );
};

const Modal = styled.div`
  position: absolute;
  width: 420px;
  height: 200px;
  top: 10%;
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 9999;
`;

const mapStateToProps = (state) => {
  return {
    username: selectUserInfo(state.authUser).username,
  };
};

export default connect(mapStateToProps, null)(UploadImagesInPack);
