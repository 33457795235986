import React from "react";
import {Option} from "../../../../../../../ui/inputs/DropDown";

const DisplayItem = ({fontFamily, type, selected, onSelect}) => {
  const handleClick = () => {
    onSelect(type);
  };

  return (
    <Option active={selected === fontFamily} onClick={handleClick}>
      <p
        style={{
          fontFamily: fontFamily + "-" + type.fontWeight + "-" + type.fontStyle,
          fontWeight: type.fontWeight == 700 || type.fontWeight == 'bold' ? 700 : 
            type.fontWeight == 400 || type.fontWeight == 'normal' ? 400 : type.fontWeight, 
        }}
      >
        {fontFamily}
      </p>
    </Option>
  );
};

const DisplayCustomFont = ({item, onSelectItem, selected}) => {
  const handleSelect = (font) => {
    onSelectItem(item, font.fontWeight, font.fontStyle);
  };
  
  return (
    item.types &&
    item.types.map((type, index) => (
      <DisplayItem
        key={index}
        fontFamily={item.fontFamily}
        type={type}
        selected={selected}
        onSelect={handleSelect}
        index={index}
      />
    ))
  );
};

export default DisplayCustomFont;
