import React from "react";
import styled from "styled-components";
import {Modal} from "../../../../../../oat-ui";
import {royalBlue, white} from "../../../../../themes";
import CreateChart from "./CreateChart";

const WrapCreateChart = ({mode, close}) => {
  return (
    <Modal
      width={680}
      height={520}
      top={70}
      close={close}
      background={mode === "dark" ? royalBlue : white}
    >
      <CreateChart closeModal={close} />
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export default WrapCreateChart;
