import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {TooltipStyles} from "../../../../ui/styles/TooltipStyles";
import RightPanel from "./RightPanel";
import LeftPanel from "./LeftPanel";
import {
  SvgText,
  SvgChart,
  SvgImage,
  SvgShape,
  SvgIcon,
  SvgTable,
  SvgVideo,
} from "../../../../ui/svg/DesktopNavSvg";
import CreateFieldsModal from "../../modal/create/CreateFieldsModal";
import {deskNavHeight} from "../../../../layoutSizes";
import {connect} from "react-redux";
import {createTable} from "../../../../store/actions/fields/table.action";
import {selectReplaceImage} from "../../../../store/selectors/fields.selector";
import {updateReplaceImgStatus} from "../../../../store/actions/fields/image.action";
import {
  selectActiveSlide,
  selectIfNoActiveSlide,
} from "../../../../store/selectors/template/slide.selector";
import {storeActionsUndoRedo} from "../../../../store/actions/fields/undoRedo.action";
import {selectTemplateSize} from "../../../../store/selectors/template/template.selector";
import {handleTableCreation} from "../../../../utils/handleTableCreation";
import IconModal from "../../modal/create/icons/IconModal";
import CreateIcon from "../../modal/create/icons/CreateIcon";
import ImageModal from "../../modal/create/uploadImage/ImageModal";
import ImageSelection from "../../modal/create/uploadImage/ImageSelection";

const TopDeskPanel = ({
  mode,
  processDesign,
  userType,
  onChangeTheme,
  buttonTitle,
  createTable,
  replaceImage,
  updateReplaceImgStatus,
  noActiveSlide,
  storeActionsUndoRedo,
  slideID,
  tempSize,
  isSaving,
}) => {
  const [modal, setModal] = useState({
    type: "",
    status: false,
  });

  const closeModal = () => {
    setModal({
      type: "",
      status: false,
    });
  };

  const openModal = (type) => {
    setModal({
      type,
      status: true,
    });
  };

  const openText = () => {
    if (!noActiveSlide) {
      openModal("text");
    }
  };

  const openImage = () => {
    if (!noActiveSlide) {
      openModal("upload-image");
    }
  };

  const openIcon = () => {
    if (!noActiveSlide) {
      openModal("icon");
    }
  };

  const openShape = () => {
    if (!noActiveSlide) {
      openModal("shape");
    }
  };

  const openChart = () => {
    if (!noActiveSlide) {
      openModal("chart");
    }
  };

  const openVideo = () => {
    if (!noActiveSlide) {
      openModal("video");
    }
  };

  const onTableCreation = () => {
    if (!noActiveSlide) {
      const {key, size} = handleTableCreation(tempSize);
      createTable(slideID, key, size);
      storeActionsUndoRedo("create");
    }
  };

  // handling replace image & with modal status
  useEffect(() => {
    if (replaceImage) {
      openModal("upload-image");
    }
  }, [replaceImage]);
  useEffect(() => {
    if (replaceImage && !modal.status) {
      updateReplaceImgStatus();
    }
  }, [modal.status]);

  const closeImageModal = () => {
    setModal({
      type: null,
      status: false,
    });
  }

  const closeIconModal = () => {
    setModal({
      type: null,
      status: false,
    });
  }

  return (
    <Wrapper>
      <LeftSide>
        <LeftPanel isSaving={isSaving} />
      </LeftSide>

      <CenterTools>
        <Tool onClick={openText}>
          <WrapSVG>{SvgText}</WrapSVG>
          <Tooltip>Text</Tooltip>
        </Tool>

        <Tool onClick={openImage}>
          <WrapSVG style={{transform: "scale(1.4)"}}>{SvgImage}</WrapSVG>
          <Tooltip>Media</Tooltip>
        </Tool>

        <Tool onClick={openIcon}>
          <WrapSVG>{SvgIcon}</WrapSVG>
          <Tooltip>Elements</Tooltip>
        </Tool>

        <Tool onClick={openShape}>
          <WrapSVG>{SvgShape}</WrapSVG>
          <Tooltip>Shapes</Tooltip>
        </Tool>

        <Tool onClick={openChart}>
          <WrapSVG>{SvgChart}</WrapSVG>
          <Tooltip>Charts</Tooltip>
        </Tool>

        <Tool onClick={onTableCreation}>
          <WrapSVG>{SvgTable}</WrapSVG>
          <Tooltip>Table</Tooltip>
        </Tool>

        <Tool onClick={openVideo}>
          <WrapSVG style={{transform: `scale(1.2)`}}>{SvgVideo}</WrapSVG>
          <Tooltip style={{width: 82, left: -25}}>Embed</Tooltip>
        </Tool>

        {modal.status ? (
          <CreateFieldsModal type={modal.type} close={closeModal} mode={mode} />
        ) : undefined}

        <ImageModal open={modal.status && (modal.type === 'upload-image' || modal.type === 'replace-image')} close={closeImageModal}>
          <ImageSelection openModal={modal.status} closeModal={closeImageModal} />
        </ImageModal> 
        
        <IconModal open={modal.status && modal.type === 'icon'} close={closeIconModal}>
          <CreateIcon closeModal={closeIconModal} />
        </IconModal> 
      </CenterTools>

      <RightPanel
        mode={mode}
        processDesign={processDesign}
        onChangeTheme={onChangeTheme}
        userType={userType}
        buttonTitle={buttonTitle}
        noActiveSlideExists={noActiveSlide}
        isSaving={isSaving}
      />
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: ${deskNavHeight}px;
  // border-bottom: 1px solid ${({theme}) => theme.deskGap};
  // box-shadow: 0 1px 1px rgb(171 159 159 / 10%);
  box-shadow: ${({theme}) => theme.topPanelBoxShadow};
  z-index: 100;
  @media only screen and (min-width: 850px) {
    background: ${({theme}) => theme.deskPrimaryBg};
  }
  @media only screen and (max-width: 849px) {
    height: 75px;
    max-width: none;
  }
`;

export const CenterTools = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-evenly;
  max-width: 380px;
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
`;

export const Title = styled.p`
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  @media only screen and (min-width: 850px) {
    color: #929292;
    line-height: 13px;
    padding-top: 5px;
  }
`;

const Tooltip = styled.div`
  bottom: -80px;
  ${TooltipStyles}
`;

export const Tool = styled.div`
  position: relative;
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  cursor: pointer;
  padding-top: 5px;
  &:hover {
    ${Title} {
      color: #5f4df1;
    }
  }
  &:hover ${Tooltip} {
    display: flex;
    width: 50px;
    left: -18px;
    bottom: -26px;
  }
`;

export const WrapSVG = styled.div`
  width: 18px;
  height: 18px;
  stroke: ${({theme}) => theme.createSvgStroke};
  fill: ${({theme}) => theme.createSvgFill};
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    replaceImage: selectReplaceImage(designTemplate),
    noActiveSlide: selectIfNoActiveSlide(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    tempSize: selectTemplateSize(designTemplate),
  };
};

export default connect(mapStateToProps, {
  createTable,
  updateReplaceImgStatus,
  storeActionsUndoRedo,
})(TopDeskPanel);
