import React from "react";
import {OatBlogList} from "../../../packages/oat-public";

const BlogList = () => {
  return (
    <div>
      <OatBlogList />
    </div>
  );
};

export default BlogList;
