import React, {useState, useRef, useEffect} from "react";
import {useNavigate} from "react-router";
import styled from "styled-components";
import {
  paleBlue5,
  primaryBlack,
  primaryGrey,
} from "../../../oat-color-variables";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";
import LayoutHoc from "../../layout/LayoutHoc";

const items = [
  // {
  //   id: "12313",
  //   title: "How to cope with team",
  //   description:
  //     "Learn the difference between low-fidelity and high-fidelity designs, and when you should use which.",
  //   image:
  //     "https://cdn.sanity.io/images/oneb1r22/production/7c01c44e5e4aaf54ad8507f1b723fecf741bbed2-774x560.png?auto=format&q=98&fit=max&w=460",
  //   templateLink: "",
  //   published: "Jan 14, 2022",
  // },
  // {
  //   id: "12313",
  //   title: "How to cope with team",
  //   description:
  //     "Prototyping can revolutionize your design process, but these common pitfalls could be holding you back.",
  //   image:
  //     "https://cdn.sanity.io/images/oneb1r22/production/ff63f6607eadefd29be97821935e3eaf88b03460-774x560.png?auto=format&q=98&fit=max&w=460",
  //   templateLink: "",
  //   published: "Jan 14, 2022",
  // },
  // {
  //   id: "12313",
  //   title: "How to cope with team",
  //   description:
  //     "Learn the fundamental rules to designing good, user-friendly apps.",
  //   image:
  //     "https://cdn.sanity.io/images/oneb1r22/production/8c6dc6b77715243b7da3a4e7910d8dc0d7a4a881-774x560.png?auto=format&q=98&fit=max&w=460",
  //   templateLink: "",
  //   published: "Jan 14, 2022",
  // },
  // {
  //   id: "12313",
  //   title: "How to cope with team",
  //   description: "The new snow has no name, face climate disaster",
  //   image:
  //     "https://cdn.sanity.io/images/oneb1r22/production/a0c7cea5ea8a9c08e3bd596acc56c801e844373b-774x560.png?auto=format&q=98&fit=max&w=460",
  //   templateLink: "",
  //   published: "Jan 14, 2022",
  // },
];

const Item = ({item, onItemSelect, size}) => {
  const [loaded, setLoaded] = useState(false);
  const handleClick = () => {
    onItemSelect(item);
  };

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <WrapItem>
      <Image
        src={item.image}
        style={{
          width: size.width,
          height: loaded ? "auto" : size.height,
          background: paleBlue5,
        }}
        onLoad={handleLoad}
        onClick={handleClick}
      />

      <WrapTempInfo onClick={handleClick}>
        <ArticleType>Guides</ArticleType>
        <ItemName style={{flex: 4}}>{item.title}</ItemName>
        <Description>{item.description}</Description>
        <PublishedDate>{item.published}</PublishedDate>
      </WrapTempInfo>
    </WrapItem>
  );
};

const BlogList = () => {
  const [blogs, setBlogs] = useState(items);
  let navigate = useNavigate();
  const itemsRef = useRef();
  const [itemSize, setItemSize] = useState({
    width: 0,
    height: 0,
  });
  const windowSize = getWindowSize();

  const onItemSelect = (item) => {
    return navigate(`/blogs/${item.id}`);
  };

  let itemsPerRow = 3;
  let colGap = 25;
  if (windowSize.width >= 1000) {
    itemsPerRow = 3;
  } else if (windowSize.width < 1000 && windowSize.width > 600) {
    itemsPerRow = 2;
  } else {
    itemsPerRow = 1;
  }

  useEffect(() => {
    if (itemsRef.current) {
      const itemSize =
        (itemsRef.current.offsetWidth - colGap * (itemsPerRow - 1)) /
        itemsPerRow;
      setItemSize({
        width: itemSize,
        height: (326 * itemSize) / 450,
      });
    }
  }, [itemsRef, windowSize.width]);

  return (
    <LayoutHoc>
      <Layout>

        {blogs.length === 0 ? <Message>Seems like there is no blog written yet.</Message> : undefined}

        {blogs.length > 0 ? 
          <Items ref={itemsRef}>
            {blogs &&
              blogs.map((blog, i) => (
                <Item
                  key={i}
                  item={blog}
                  onItemSelect={onItemSelect}
                  size={itemSize}
                />
              ))}
          </Items> : undefined}
      </Layout>
    </LayoutHoc>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 25px;
  min-height: ${window.innerHeight}px;
  @media only screen and (max-width: 600px) {
    margin: 12px 25px;
  }
`;

const Items = styled.div`
  display: flex;
  padding-top: 10px;
  flex-flow: wrap;
  margin-bottom: 52px;
  display: grid;
  column-gap: 25px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-width: 1200px;
  @media only screen and (min-width: 601px) and (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (min-width: 1500px) {
    max-width: 1400px;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 0px;
  }
`;

const WrapItem = styled.div`
  text-decoration: none;
  margin-bottom: 46px;
`;

const WrapTempInfo = styled.div`
  display: flex;
  min-height: 30px;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
`;

const ItemName = styled.h3`
  font-size: 1.2rem;
  padding: 12px 0 6px;
  color: ${primaryBlack};
`;

const Description = styled.p`
  font-size: 1.05rem;
  color: ${primaryBlack};
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
`;

const PublishedDate = styled.div`
  margin: 22px 0 8px;
  font-size: 0.92rem;
  color: ${primaryGrey};
  font-weight: 600;
`;

const ArticleType = styled.div`
  color: #5800ff;
  font-size: 0.9rem;
`;

const Message = styled.div`
  font-size: 1.2rem;
  margin-top: 180px;
  @media only screen and (max-width: 600px) {
    margin-top: 175px;
  }
`

export default BlogList;
