import React from "react";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import {blue3, secondaryBlack} from "../../../oat-color-variables";
import {NavLayout, WrapNavLinks} from "./LoggedInUserNavContents";

const GuestNavContents = ({close}) => {
  return (
    <NavLayout>
      <Section>
        <List onClick={close}>
          <WrapNavLinks>
            <div>Templates</div>
            <GoTo to="/templates/presentation/16x9">Presentations</GoTo>
            {/* <GoTo to="/templates/social-media/1x1">Social Media (1:1)</GoTo> */}
          </WrapNavLinks>
        </List>
        <List onClick={close}>
          <GoTo to="/illustrations">Illustrations</GoTo>
        </List>
        {/* <List onClick={close}>
          <GoTo to="/blogs">Blog</GoTo>
        </List> */}
        <List onClick={close}>
          <GoTo to="/faq">FAQ</GoTo>
        </List>
        {/* <List onClick={close}>
          <GoTo to="/about-us">About Us</GoTo>
        </List> */}
        <List onClick={close}>
          <GoTo to="/pricing">Pricing</GoTo>
        </List>
      </Section>
    </NavLayout>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6% 0 6% 32px;
  
`;

const reuse = css`
  color: ${secondaryBlack};
  &:hover {
    color: ${blue3};
  }
`;

const GoTo = styled(Link)`
  color: ${secondaryBlack};
  text-decoration: none;
  ${reuse};
`;

const List = styled.h3`
  cursor: pointer;
  padding: 18px 0;
  ${reuse};
`;

export default GuestNavContents;
