import React from "react";
import styled from "styled-components";
import SpecialButton from "../../../../ui/inputs/SpecialButton";

const LearnMoreMobileView = () => {
  return (
    <Wrapper>
      <MediaSection>
        <img
          src="https://images.unsplash.com/photo-1533420896084-06d2bce5365f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
          alt="learn-more"
        />
      </MediaSection>
      <BottomSection>
        <WrapButton>
          <SpecialButton>Add Mobile View</SpecialButton>
        </WrapButton>
      </BottomSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  height: 100%;
`;

const MediaSection = styled.div`
  img {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const WrapButton = styled.div`
  width: 150px;
  height: 38px;
`;

export default LearnMoreMobileView;
