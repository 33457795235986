import {OpenSans} from "../../../../ui/fonts/googleFontLinks";
import { themeBuilder } from "../../data/themeBuilder.data";
import {initGroupSelect} from "../../designTemplate";
import {setSmartAignmentPoints} from "../utils";
import {createShortKey, getHighestZIndex} from "./utils";

// get cell's initial values
export function setInitialCellSizes() {
  return function (state) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      size: {
        ...getItem.size,
        initTableWidth: getItem.size.w,
        initTableHeight: getItem.size.h,
      },
      content: getItem.content.map((item) => ({
        ...item,
        row: {
          ...item.row,
          initHeight: item.row.height,
        },
        columns: item.columns.map((column) => ({
          ...column,
          initWidth: column.width,
        })),
      })),
    };
    return {
      ...state,
      template: newState,
      tableResizeProps: {
        ...state.tableResizeProps,
        hideBorder: true,
      },
    };
  };
}

export function removeInitCellSizes() {
  return function (state) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      size: {
        w: getItem.size.w,
        h: getItem.size.h,
      },
      content: getItem.content.map((item) => ({
        ...item,
        row: {
          ...item.row,
          height: item.row.height,
        },
        columns: item.columns.map((column) => ({
          value: column.value,
          width: column.width,
        })),
      })),
    };
    return {
      ...state,
      template: newState,
      tableResizeProps: {
        ...state.tableResizeProps,
        hideBorder: false,
      },
    };
  };
}

export function resizeTable() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    const {newTableSize} = action;

    let tableWidth = newTableSize;
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      content: getItem.content.map((item) => ({
        ...item,
        row: {
          ...item.row,
          height:
            newTableSize.h /
              ((getItem.size.initTableHeight - 4) / item.row.initHeight) -
            1,
          // (action.size.h * 20) / 93,
          // newTableSize.h / 3 - 2 / 3,
        },
        columns: item.columns.map((column, index) => {
          const colWidth = Math.ceil(
            Math.ceil(newTableSize.w * Math.ceil(column.initWidth)) /
              getItem.size.initTableWidth
          );
          const newCombinedColWidth = Math.ceil(
            Math.ceil(newTableSize.w) - colWidth
          );
          tableWidth =
            tableWidth + item.columns.length - 1 !== index
              ? colWidth
              : Math.ceil(newTableSize.w) - newCombinedColWidth;
          return {
            ...column,
            width: colWidth,
            // item.columns.length - 1 !== index
            //   ? colWidth
            //   : Math.ceil(newTableSize.w) - newCombinedColWidth,
          };
        }),
        // item.columns.map((column) => ({
        //   ...column,
        //   width:
        //     Math.ceil(newTableSize.w * column.initWidth) / getItem.size.initTableWidth,
        //   //  Math.round(
        //   //   newTableSize.w /
        //   //     Math.round(getItem.size.initTableWidth / column.initWidth)
        //   // ),
        // })),
      })),
      size: {
        ...getItem.size,
        w: tableWidth, // newTableSize.w,
        h: newTableSize.h,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function addNewTableRow() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...getItem,
      content: [
        ...getItem.content.slice(0, action.rowIndex + 1),
        {
          row: {
            id: createShortKey(),
            height: getItem.content[0].row.height,
          },
          columns: getItem.content[0].columns.map((item) => ({
            value: "",
            width: item.width,
          })),
        },
        ...getItem.content.slice(action.rowIndex + 1),
      ],
      size: {
        w: getItem.size.w,
        h: getItem.size.h + getItem.content[0].row.height,
      },
    };
    return {
      ...state,
      template: newState,
      fieldToEmitFromSocket: {
        type: "update",
        field: newState[slideID].fields[fieldID],
      },
    };
  };
}

export function addNewTableColumn() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    const {colIndex} = action;
    newState[slideID].fields[fieldID] = {
      ...getItem,
      content: getItem.content.map((item) => ({
        ...item,
        columns: [
          ...item.columns.slice(0, colIndex + 1),
          {
            value: "",
            width: getItem.content[0].columns[0].width,
            background: "",
            color: "",
          },
          ...item.columns.slice(colIndex + 1),
        ],
      })),
      size: {
        w: getItem.size.w + getItem.content[0].columns[0].width,
        h: getItem.size.h,
      },
    };
    return {
      ...state,
      template: newState,
      fieldToEmitFromSocket: {
        type: "update",
        field: newState[slideID].fields[fieldID],
      },
    };
  };
}

export function removeTableRow() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    const selectedRowInfo = getItem.content.find(
      (item) => item.row.id === action.id
    );

    newState[slideID].fields[fieldID] = {
      ...getItem,
      content: getItem.content.filter((item) => item.row.id !== action.id),
      size: {
        w: getItem.size.w,
        h: getItem.size.h - selectedRowInfo.row.height - 1,
        // getItem.content[action.index].row.height - 1,
        // add -1
        // for instance, total height of 64, made up of 3 rows with a height of 20
        // extra 4+ for its border height (1px each)
        // for 3 rows -> 4px of border height
        // for 2 rows -> 3px of border height
      },
    };
    return {
      ...state,
      template: newState,
      fieldToEmitFromSocket: {
        type: "update",
        field: newState[slideID].fields[fieldID],
      },
    };
  };
}

export function removeTableColumn() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    const selectedColumnInfo = getItem.content[0].columns.find(
      (item, index) => index === action.index
    );
    newState[slideID].fields[fieldID] = {
      ...getItem,
      content: getItem.content.map((item) => ({
        ...item,
        columns: item.columns.filter((column, index) => index !== action.index),
      })),
      size: {
        w: getItem.size.w - selectedColumnInfo.width,
        // - selectedColumnInfo.column.width,
        // getItem.content[0].columns[action.index].width,
        h: getItem.size.h,
      },
    };
    return {
      ...state,
      template: newState,
      fieldToEmitFromSocket: {
        type: "update",
        field: newState[slideID].fields[fieldID],
      },
    };
  };
}

export function updateTableContent() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...getItem,
      content: getItem.content.map((item, rowIndex) =>
        rowIndex === action.rowIndex
          ? {
              ...item,
              row: {...item.row, height: action.rowHeight},
              columns: item.columns.map((column, colIndex) =>
                colIndex === action.columnIndex
                  ? {
                      ...column,
                      value: action.content,
                    }
                  : column
              ),
            }
          : item
      ),
      size: {
        w: Math.round(getItem.size.w),
        h: action.tableHeight,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function resizeTableColumnCell() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    if (action.direction === "right") {
      let combinedColumnWidth = 0;
      newState[slideID].fields[fieldID] = {
        ...getItem,
        content: getItem.content.map((item) => ({
          ...item,
          columns: item.columns.map((column, colIndex) => {
            if (
              action.colIndex === colIndex
              //  &&
              // colIndex !== item.columns.length - 1
            ) {
              combinedColumnWidth = combinedColumnWidth + column.width;
              return {
                ...column,
                width: action.payload,
              };
            }
            // else if (
            //   action.colIndex === colIndex &&
            //   colIndex === item.columns.length - 1
            // ) {
            //   const combinedColumnWidth =
            //     action.initTableSize.w + action.sizeDiff - action.payload;
            //   // let combinedColumnWidth =  action.initTableSize.w +
            //   //     action.sizeDiff - action.payload;
            //   return {
            //     ...column,
            //     width:
            //       action.initTableSize.w +
            //       action.sizeDiff -
            //       combinedColumnWidth,
            //   };
            // }
            else {
              return column;
            }
          }),
        })),
        size: {
          w: action.initTableSize.w + action.sizeDiff,
          h: getItem.size.h,
        },
      };
    } else {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        content: getItem.content.map((item) => ({
          ...item,
          columns: item.columns.map((column, colIndex) => {
            if (action.colIndex === colIndex) {
              return {
                ...column,
                width: action.payload,
              };
            }
            // else if (colIndex === item.columns.length - 1) {
            //   // const ratio = action.sizeDiff / action.initTableSize.w;
            //   // const test = column.width - ratio;
            //   const ratio = action.sizeDiff / action.initTableSize.w;
            //   // action.sizeDiff / action.payload;
            //   // 4 / action.payload; // 0.28
            //   return {
            //     ...column,
            //     width: column.width + ratio,
            //   };
            // }
            else {
              return column;
            }
          }),
        })),
        size: {
          w: action.initTableSize.w + action.sizeDiff,
          h: getItem.size.h,
        },
      };
    }
    return {
      ...state,
      template: newState,
    };
  };
}

export function updateTableHeight() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    if (getItem?.size) {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        size: {
          w: getItem.size.w,
          h: action.payload,
        },
      };
      return {
        ...state,
        template: newState,
      };
    } else {
      return state
    }
  };
}

// update both width and height
export function updateTableSize() {
  return function (state, action) {
    const {activeSlide, activeField} = state;
    const newState = [...state.template];
    const getItem = newState[activeSlide].fields[activeField];
    newState[activeSlide].fields[activeField] = {
      ...getItem,
      size: {
        w: action.width,
        h: action.height,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function createTable() {
  return function (state, action) {
    const {template} = state;
    const {slideID, size, selectFieldOnCreate, key} = action;
    const newState = [...template];
    let highestZIndex = getHighestZIndex(template, slideID);
    const currentSlide = template[slideID];
    Object.entries(currentSlide.fields).map(([id, field]) => {
      if (field.styles.zIndex > highestZIndex) {
        highestZIndex = field.styles.zIndex;
      }
    });

    const offset = (10 * state.size.w) / 100;

    const rowHeight = size.h / 4;
    let activeTheme = state.themes.find(
      (theme) => theme.id === state.activeTheme
    );
    if(typeof activeTheme === 'undefined') {
      activeTheme = themeBuilder
    }
    const itemScale = 1,
      borderWidth = 1; // state.layout.tempScale;
    const tableWidth = size.w; // Math.round(size.w * state.layout.tempScale);
    // Math.round(size.w * state.layout.tempScale * 2);
    // const tableWidth = tableWidth - borderWidth / state.layout.tempScale;
    const newFieldProps = {
      key,
      pos: {x: offset, y: offset},
      size: {
        w: tableWidth,
        // tableWidth - borderWidth / state.layout.tempScale,
        h: size.h,
      },
      content: [
        {
          row: {
            id: createShortKey(),
            height: rowHeight, // state.layout.tempScale, // * state.ratioDiff,
          },
          columns: [
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "", // for particular cell
              color: "", // for particular cell
            }, //100 * state.ratioDiff
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "",
              color: "",
            },
            {
              value: "",
              width: tableWidth - Math.round(tableWidth / 3) * 2,
              background: "",
              color: "",
            },
          ],
        },
        {
          row: {
            id: createShortKey(),
            height: rowHeight, // state.layout.tempScale, //* state.ratioDiff,
          },
          columns: [
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "",
              color: "",
            },
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "",
              color: "",
            },
            {
              value: "",
              width: tableWidth - Math.round(tableWidth / 3) * 2,
              background: "",
              color: "",
            },
          ],
        },
        {
          row: {
            id: createShortKey(),
            height: rowHeight, // state.layout.tempScale, //* state.ratioDiff,
          },
          columns: [
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "",
              color: "",
            },
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "",
              color: "",
            },
            {
              value: "",
              width: tableWidth - Math.round(tableWidth / 3) * 2,
              id: "mtEfqe",
              background: "",
              color: "",
            },
          ],
        },
        {
          row: {
            id: createShortKey(),
            height: rowHeight, // state.layout.tempScale, // * state.ratioDiff,
          },
          columns: [
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "",
              color: "",
            },
            {
              value: "",
              width: Math.round(tableWidth / 3),
              background: "",
              color: "",
            },
            {
              value: "",
              width: tableWidth - Math.round(tableWidth / 3) * 2,
              background: "",
              color: "",
            },
          ],
        },
      ],
      type: "table",
      resizer: "all",
      deleted: false,
      ["styles"]: {
        headerBg: activeTheme.table.headerBg,
        headerColor: activeTheme.table.headerColor,
        headerFontWeight: 700,
        headerAlign: "center",
        headerType: "top",
        fontFamily: activeTheme.table.fontFamily,
        color: activeTheme.table.color,
        fontSize:
          window.innerWidth >= 850
            ? 16 / state.layout.tempScale
            : 12 / state.layout.tempScale,
        rotate: 0,
        opacity: 1,
        borderColor: activeTheme.table.borderColor,
        background: activeTheme.table.background,
        textAlign: "left",
        fontWeight: 400,
        scale: itemScale,
        letterSpacing: activeTheme.table.letterSpacing,
        lineHeight: activeTheme.table.lineHeight,
        lock: false,
        showRowColor: true, // cells border color
        showColumnColor: true,
        showBorderEdge: true,
        animateOnSlideDisplay: null,
        animationType: "none",
        animationSpeed: 0.5,
        animationDelay: 0,
        animationOrder: 0,
        enableExitAnimation: false,
        zIndex: highestZIndex + 1,
        stripeRows: false, // enable stripe background color
        stripeBackground: activeTheme.table?.stripeBackground, // stripe bg color
        stripeColor: activeTheme.table?.stripeColor,
        borderWidth: borderWidth / state.layout.tempScale, // not allowing to update atm
      },
      font: {
        custom: activeTheme.table.custom ? true : false,
        fontFamily: activeTheme.table.fontFamily
          ? activeTheme.table.fontFamily
          : "Open Sans",
        src: activeTheme.table.src ? activeTheme.table.src : OpenSans,
        format: activeTheme.table.format ? activeTheme.table.format : null,
        fontType: "Regular",
      },
    };

    newState[slideID].fields = {
      ...newState[slideID].fields,
      [key]: newFieldProps,
    };
    const {xAlign, yAlign} = setSmartAignmentPoints({
      template,
      slideID,
      fieldIDs: [key],
      tempScale: state.layout.tempScale,
      tempSize: state.size,
    });
    return {
      ...state,
      template: newState,
      fieldToEmitFromSocket: {
        type: "create",
        field: {
          ...newFieldProps,
          key,
        },
      },
      activeField: key,
      selectedFieldType: "table",
      cachedFieldStyles: newState[slideID].fields[key].styles,
      openModal: {status: false, type: "", children: null},
      groupSelect: initGroupSelect,
      enableTextEdit: false,
      smartAlignment: {
        ...state.smartAlignment,
        points: {
          x: xAlign,
          y: yAlign,
        },
        x: {
          display: false,
          value: null,
        },
        y: {
          display: false,
          value: null,
        },
      },
    };
  };
}

export function resizeTableRowCell() {
  return function (state, action) {
    const {slideID, fieldID, row, addedHeight} = action;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...getItem,
      content: getItem.content && getItem.content.map((item, rowIndex) =>
        rowIndex === row.index
          ? {
              ...item,
              row: {
                ...item.row,
                height: row.height,
              },
            }
          : item
      ),
      size: {
        w: getItem.size.w,
        h: action.initTableSize.h + addedHeight,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function batchUpdateTableRowsHeight() {
  return function (state, action) {
    const newState = [...state.template];
    const {slideID, fieldID, tableHeight, row} = action;
    const getItem = newState[slideID].fields[fieldID];
    newState[slideID].fields[fieldID] = {
      ...getItem,
      content: getItem.content && getItem.content.map((item, rowIndex) => {
        if (rowIndex == row.index) {
          return {
            ...item,
            row: {...item.row, height: row.height},
          };
        } else {
          return item;
        }
      }),
      size: {
        w: getItem.size.w,
        h: tableHeight,
      },
    };
    return {
      ...state,
      template: newState,
    };
  };
}

export function toggleTableRowsColumnsDrawer() {
  return function (state, action) {
    if (action.selectedType) {
      return {
        ...state,
        toggleTableRowsColumnsDrawer: {
          status: true,
          type: action.selectedType,
          index: action.index,
          id: action.id,
        },
      };
    } else {
      return {
        ...state,
        toggleTableRowsColumnsDrawer: {
          status: false,
          type: null,
          index: null,
          id: null,
        },
      };
    }
  };
}

export function setActiveTableCell() {
  return function (state, action) {
    return {
      ...state,
      activeTableCell: {
        row: action.row,
        column: action.column,
      },
    };
  };
}

export function cloneTableRowColumn() {
  return function (state, action) {
    const {slideID, fieldID} = action.active;
    const newState = [...state.template];
    const getItem = newState[slideID].fields[fieldID];
    if (action.cloneType === "row") {
      newState[slideID].fields[fieldID] = {
        ...getItem,
        content: [
          ...getItem.content.slice(0, action.index + 1),
          {
            row: {
              id: createShortKey(),
              height: getItem.content[action.index].row.height,
            },
            columns: getItem.content[action.index].columns.map((item) => ({
              value: item.value,
              width: item.width,
            })),
          },
          ...getItem.content.slice(action.index + 1),
        ],
        size: {
          w: getItem.size.w,
          h: getItem.content[action.index].row.height,
        },
      };
      return {
        ...state,
        template: newState,
        fieldToEmitFromSocket: {
          type: "update",
          field: newState[slideID].fields[fieldID],
        },
      };
    } else {
      const {index} = action;
      newState[slideID].fields[fieldID] = {
        ...getItem,
        content: getItem.content.map((item, i) => ({
          ...item,
          columns: [
            ...item.columns.slice(0, index + 1),
            {
              value: getItem.content[i].columns[index].value,
              width: getItem.content[i].columns[index].width,
            },
            ...item.columns.slice(index + 1),
          ],
        })),
        size: {
          w: getItem.content[0].columns[index].width,
          h: getItem.size.h,
        },
      };
      return {
        ...state,
        template: newState,
        fieldToEmitFromSocket: {
          type: "update",
          field: newState[slideID].fields[fieldID],
        },
      };
    }
  };
}
