import React, {useState, useRef} from "react";
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import {blue3, blue6, primaryBlack} from "../../oat-color-variables";
import MobileNav from "./MobileNav";
import {getWindowSize} from "../../oat-window-size";
import {connect} from "react-redux";
import {selectUserWorkspaceForRedirect} from "../../../redux/user/authUser";
import { detectClickOutside } from "../../oat-ui";

export const barHeight = 56;
export const displayNavAt = 900;
export const navBtnBoxShadow = '0px 0px 1px 0px #5457ff';
export const navBtnBackground = 'rgb(236 240 255 / 53%)';
const boxShadow = '#e8e8e8'

const NavBar = ({authUser, redirectWorkspaceID}) => {
  const {width} = getWindowSize();
  const [openPopper, setOpenPopper] = useState(false);
  const popperRef = useRef();

  const onSelectTempCategory = () => {
    setOpenPopper(!openPopper);
  };

  detectClickOutside(popperRef, () => {
    setOpenPopper(false);
  });

  return (
    <div style={{height: barHeight}}>
      <Layout>
        {width >= displayNavAt ? (
          <Wrapper>
            <Left>
              <CompanyName to="/">
                <div style={{width: 25, height: 25, padding: 15, borderRadius: '50%'}}>
                  <img style={{width: 25, height: 25}}src={`${process.env.PUBLIC_URL}/oat-all-favicon.png`} alt="oat-all" />
                </div>
                 Oat All
              </CompanyName>
              {/* <TempItem onClick={onSelectTempCategory}>
                Templates
                {openPopper ? <Popper 
                  ref={popperRef} 
                  openPopper={openPopper}
                >
                  <CategoryLink to="/templates/presentation/16x9">Presentation</CategoryLink>
                  <CategoryLink to="/templates/social-media/1x1">Social Media (1:1)</CategoryLink>
                </Popper>  : undefined}
              </TempItem> */}
              <NavItem to="/templates/presentation/16x9">Templates</NavItem>
              <NavItem to="/illustrations">Illustrations</NavItem>
              {/* <NavItem to="/blogs">Blog</NavItem> */}
              {/* <NavItem to="/about-us">About Us</NavItem> */}
              <NavItem to="/pricing">Pricing</NavItem>
            </Left>
            <Right>
              {!authUser.loggedIn.status ? (
                <>
                  <SignIn to="/login">Sign In</SignIn>
                  <Register to="/register">Register</Register>
                </>
              ) : (
                <div style={{display: "flex", alignItems: "center"}}>
                  <NavItem
                    to={
                      redirectWorkspaceID
                        ? `/dashboard/${redirectWorkspaceID}/designs/home`
                        : "/"
                    }
                    style={{
                      display: 'flex',
                      justifyContent: "space-around",
                      alignItems: 'center',
                      background: navBtnBackground, 
                      minWidth: 120, 
                      textAlign: 'center',
                      boxShadow: navBtnBoxShadow
                    }}
                  >
                    Dashboard 
                    <div style={{ width: 20, height: 20, fill: '#7c7c8f' }}>
                      <svg viewBox="0 0 24 24">
                        <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                      </svg>
                    </div>
                  </NavItem>
                  {/* <WrapAvatar>
                  <AvatarImg src="https://images.unsplash.com/photo-1629755590546-f95805384911?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60" />
                </WrapAvatar> */}
                </div>
              )}
            </Right>
          </Wrapper>
        ) : (
          <MobileNav />
        )}
      </Layout>
    </div>
  );
};

const Layout = styled.div`
  height: ${barHeight}px;
  box-shadow: 0 1px 1px #e8e8e8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background: rgb(255 255 255 / 60%);
  backdrop-filter: blur(4px);
  top: 0;
  z-index: 5555;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  @media only screen and (max-width: 900px) {
    justify-content: space-between;
    margin: 0 3%;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  padding-right: 68px;
`;

const Right = styled.div`
  display: flex;
`;

export const CompanyName = styled(Link)`
  font-size: 1.1rem;
  font-weight: 800;
  cursor: pointer;
  margin-right: 32px;
  text-decoration: none;
  color: ${primaryBlack};
  display: flex;
  align-items: center;
`;

const reuseItem = css`
  font-size: 1rem;
  cursor: pointer;
  margin-right: 25px;
  padding: 5px 12px;
  border-radius: 4px;
  text-decoration: none;
  color: ${primaryBlack};
  user-select: none;
  &:hover {
    background: ${blue6};
    box-shadow: ${navBtnBoxShadow};
  }
  @media only screen and (max-width: 900px) {
    display: none;
    font-size: 0.95rem;
  }
`
export const NavItem = styled(Link)`
  ${reuseItem}
`;

const TempItem = styled.div`
  ${reuseItem};
  position: relative;
`;

const btnStyles = css`
  font-size: 1rem;
  margin-left: 24px;
  padding: 5px 18px;
  cursor: pointer;
  text-decoration: none;
  color: ${primaryBlack};
`;

const SignIn = styled(Link)`
  ${btnStyles};
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const Register = styled(Link)`
  ${btnStyles};
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  background: ${blue3};
`;

const WrapAvatar = styled.div``;

const AvatarImg = styled.img`
  object-fit: cover;
  max-width: 100%;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const Popper = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 110px;
  background: #fff;
  top: 55px;
  left: -20px;
  position: absolute;
  border-radius: 8px;
  visibility: ${({openPopper}) => openPopper ? 'visible' : 'hidden'};
  font-size: 15px;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px 1px ${boxShadow};
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  animation: bouncy1 0.3s cubic-bezier(0.13, 0.7, 0.25, 1.3);
  @-webkit-keyframes bouncy1 {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bouncy {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const CategoryLink = styled(Link)`
  width: 100%;
  margin-bottom: 8px;
  padding: 8px 5px;
  box-sizing: border-box;
  border-radius: 6px;
  text-decoration: none;
  color: inherit;
  &:hover {
    background: rgba(236, 240, 255, 1);
  }
`

const mapStateToProps = (state) => {
  return {
    redirectWorkspaceID: selectUserWorkspaceForRedirect(state.authUser),
  };
};

export default connect(mapStateToProps, null)(NavBar);
