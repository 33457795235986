import styled, {css} from "styled-components";
import { defaultMaxWidthDesktop, defaultMaxWidthLaptop } from "../../layoutVariables";
import { contentListStyles } from "../styles/layoutStyles";
import { black3 } from "../../../../oat-color-variables";

export const wrapImgStyles = css`
  display: flex;
  flex: 1;
  margin-bottom: 2.4rem;
  justify-content: flex-start;
  box-sizing: border-box;
  border-radius: 12px;
  .feature-description {
    color: ${black3};
    line-height: 28px;
    font-size: 16px;
  }
  @media only screen and (min-width: 901px) {
    justify-content: center;
    .feature-description {
      font-size: 17px;
    }
  }
`;

export const Section = styled.div`
  width: 100%;
  margin-bottom: 3.6rem;
  @media only screen and (min-width: 1000px) and (max-width: 1179px) {
    max-width: 94%;
  }
  @media only screen and (min-width: 1180px) and (max-width: 1500px) {
    max-width: ${defaultMaxWidthLaptop}px;
  }
  @media only screen and (min-width: 1501px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
  @media only screen and (max-width: 899px) {
    margin-bottom: 0;
  }
`;


export const ContentList = styled.div`
  ${contentListStyles};
`;

export const ShortTitle = styled.div`
  margin-bottom: 12px;
`;

export const WrapImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  img {
    max-width: 100%;
    @media only screen and (max-width: 900px) and (min-width: 601px){
      max-width: 90%;
    }
  }
`;

export const CheckSvg = styled.div`
  width: 22px;
  height: 22px;
  padding: 0 12px 0 0;
  fill: #209820;
  flex-shrink: 0;
`;