import React from "react";
import {Title} from "../../shared/reuseStyles/Title";
import styled from "styled-components";

const DisplayItem = ({item, onSelect, selected}) => {
  const handleClick = () => {
    onSelect(item?.value);
  };
  return (
    <Option selected={selected} onClick={handleClick}>
      {item?.display}
    </Option>
  );
};

const SelectionBox = ({title, options, selected, onSelect}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Options>
        {options &&
          options.map((item) => (
            <DisplayItem
              key={item?.value}
              item={item}
              selected={item?.value === selected}
              onSelect={onSelect}
            />
          ))}
      </Options>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const Options = styled.div`
  display: flex;
  overflow-x: scroll;
  flex: 1.2;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 849px) {
    font-size: 0.85rem;
  }
`;

const Option = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  width: 95px;
  padding: 4px 4px 4px 10px;
  box-sizing: border-box;
  text-align: left;
  border-radius: 6px;
  margin-bottom: 12px;
  background: ${({theme, selected}) =>
    selected ? theme.deskSecondaryBg : undefined};
`;

export default SelectionBox;
