import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  createContext,
} from "react";
import {connect, useDispatch} from "react-redux";
import ProcessingOverlay from "../components/editDesign/parts/ProcessingOverlay";
import {
  collaboratorSelectField,
  collaboratorDeselectField,
  setExistingOnlineUsers,
  collaborationFieldChanged,
  deleteFieldsCollaboration,
  updateGroupFieldsCollaboration,
  updateUndoRedoFieldsOfCollaboration,
  addNewSlideCollaboration,
} from "../store/actions/collaboration/collaboration.action";
import {updateBackgroundProps} from "../store/actions/fields/background.action";
import {
  createChartColumn,
  createChartRow,
  deleteChartColumn,
  deleteChartRow,
  updateChartInfo,
  updateSingleColorChart,
} from "../store/actions/fields/chart.action";
import {
  collaborateGroupFieldsAsOne,
  createFieldFromSocket,
  pasteFieldFromSocket,
  resetFieldToEmitFromSocket,
  updateFieldPosition,
  updateFieldSize,
  updateFieldStyles,
  updateMultipleFieldStyles,
  zIndexToBack,
  zIndexToFront,
  addLinkToField
} from "../store/actions/fields/common.action";
import {setGroupedItemsCollaboration} from "../store/actions/fields/groupSelection.action";
import {
  transformImgOffset,
} from "../store/actions/fields/image.action";
import {
  updateTableContent,
} from "../store/actions/fields/table.action";
import {
  updateTextContent,
} from "../store/actions/fields/text.action";
import {sendChat} from "../store/actions/template/chat.action";
import {
  addSlideNotes,
  cloneSlide,
  deleteSlide,
  switchSlideIndex,
  toggleSlideWorkingStatus,
} from "../store/actions/template/slide.action";
import {
  editTemplateName,
  fetchTemplateCanvas,
  resizeTemplate,
} from "../store/actions/template/template.action";
import {
  changeSlideTheme,
  createThemeFromSocket,
  dispatchCloneTheme,
  dispatchDeleteTheme,
  updateThemeFromSocket,
} from "../store/actions/template/theme.action";
import {
  removeUserOnline,
  setUserConnectionId,
  setUserOnline,
} from "../store/actions/template/usersOnline.action";
import {
  selectFieldToEmitFromSocket,
  selectUsersOnLine,
} from "../store/selectors/common.selector";
import {selectActiveField} from "../store/selectors/fields.selector";
import {
  selectActiveSlide,
  selectActiveSlideID,
} from "../store/selectors/template/slide.selector";
import {selectTemplate} from "../store/selectors/template/template.selector";
import {selectTemplateThemes} from "../store/selectors/template/theme.selector";
import {
  applyAnimationDelay,
  applyAnimationOnSlideLoad,
  applyAnimationOrder,
  selectAnimationType,
} from "../store/actions/fields/animation.action";
import {
  addSlideComment,
  deleteSlideComment,
  onResolvedIssue,
  respondToThread,
  updateSlideComment,
} from "../store/actions/template/comment.action";

const WebSocket_URL =
  "wss://0a871yd7zl.execute-api.us-east-1.amazonaws.com/production";

export const SocketContext = createContext(null);
const timeoutRange = 12000;
const SocketProvider = ({
  username,
  name,
  avatar,
  tempID,
  slideID,
  slideIndex,
  fieldID,
  usersOnline,
  children,
  enableSocket,
  template,
  themes,
  templateAPI,
  newFieldsToEmit,
  toEmitGroupedItems,
}) => {
  const ws = useRef(null);
  const dispatch = useDispatch();
  const [socketOn, setSocketOn] = useState(false);
  const [requestingLatestVersion, setRequestingLatestVersion] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  let fullUrl = window.location.href;

  const onSocketOpen = useCallback(() => {
    // console.log("open", name, username);
    setSocketOn(true);
    ws.current?.send(
      JSON.stringify({
        action: "templateLoaded",
        username,
        name,
        avatar,
        tempID,
      })
    );
  }, [tempID]);

  useEffect(() => {
    if (socketOn) {
      const timer = setTimeout(() => {
        ws.current?.send(
          JSON.stringify({
            action: "checkUserList",
            tempID,
          })
        );
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [socketOn, tempID]);

  // if got disconnected
  const onSocketClose = useCallback(() => {
    // console.log("close");
    setSocketOn(false);
  }, []);

  useEffect(() => {
    if (
      enableSocket &&
      ws.current?.readyState !== WebSocket.OPEN &&
      username &&
      tempID &&
      !fullUrl.includes("/admin/") // if admin, won't be emitting
    ) {
      const socket = new WebSocket(WebSocket_URL);
      socket.addEventListener("open", onSocketOpen);
      socket.addEventListener("close", onSocketClose);
      socket.addEventListener("message", async (e) => {
        const data = JSON.parse(e.data);
        if (data.type === "emitChanges") {
          const {type, changes} = data.payload;
          const selectSlideOnCreate = false; // for collaboration
          if (type == "field-changed") {
            dispatch(
              collaborationFieldChanged({
                active: changes.active,
                payload: changes.value,
              })
            );
          } else if (type === "deselect-field") {
            dispatch(collaboratorDeselectField(changes.value));
          } else if (type === "position-changed") {
            dispatch(collaboratorSelectField(changes.active));
            dispatch(
              collaborationFieldChanged({
                active: changes.active,
                payload: changes.value,
              })
            );
          } else if (type === "size-changed") {
            dispatch(
              updateFieldPosition({
                active: changes.active,
                payload: changes.value.offset,
              })
            );
            dispatch(
              updateFieldSize({
                active: changes.active,
                payload: changes.value.size,
              })
            );
            if (changes.type === "image") {
              dispatch(
                transformImgOffset(changes.active, changes.value.imgPos)
              );
            }
          } else if (type === "content-changed") {
            if (changes.type === "text") {
              dispatch(updateTextContent(changes.active, changes.value));
            } else if (changes.type === "table") {
              dispatch(updateTableContent({...changes.value}));
            } else if (changes.type === "chart-data") {
              dispatch(updateChartInfo({...changes.value}));
            } else if (changes.type === "chart-create-column") {
              dispatch(createChartColumn({...changes.value}));
            } else if (changes.type === "chart-create-row") {
              dispatch(createChartRow(changes.active));
            } else if (changes.type === "chart-remove-column") {
              dispatch(deleteChartColumn(changes.active, changes.value));
            } else if (changes.type === "chart-remove-row") {
              dispatch(deleteChartRow(changes.active, changes.value));
            } else if (changes.type === "chart-update-color") {
              dispatch(
                updateSingleColorChart({
                  active: changes.active,
                  ...changes.value,
                })
              );
            } else if (changes.type === "inserted-link") {
              dispatch(addLinkToField(changes.active, changes.value));
            }
          } else if (type === "changed-background") {
            dispatch(
              updateBackgroundProps({
                slideID: changes.active.slideID,
                type: changes.type,
                value: changes.value,
              })
            );
          } else if (type === "style-changed") {
            if (changes.type === "fontSize") {
              dispatch(
                updateFieldStyles({
                  ...changes,
                  value: changes.value / 1,
                  active: changes.active,
                })
              );
            } else {
              dispatch(
                updateFieldStyles({
                  ...changes,
                  active: changes.active,
                })
              );
            }
          } else if (type === "field-created") {
            dispatch(
              createFieldFromSocket({
                active: changes.active,
                field: changes.value,
              })
            );
          } else if (type === "field-pasted") {
            dispatch(
              pasteFieldFromSocket({
                active: changes.active,
                payload: changes.value,
              })
            );
          } else if (type === "field-removed") {
            dispatch(
              deleteFieldsCollaboration({
                active: changes.active,
                payload: changes.value,
              })
            );
            // dispatch(collaborationFieldRemoved(changes.active));
          } else if (type === "zIndex-changed") {
            if (changes.type === "zIndex-to-front") {
              dispatch(zIndexToFront(changes.active, changes.value));
            } else {
              dispatch(zIndexToBack(changes.active, changes.value));
            }
          } else if (type === "apply-theme-to-slide") {
            await dispatch(changeSlideTheme({
              applyTo: changes.value.applyTo,
              slideID: changes.value.slideID,
              selectedTheme: {
                themeID: changes.value.selectedTheme?.themeID,
                prevValue: {
                  themeID: changes.value.selectedTheme?.themeID,
                },
              }
            })); 
          } else if (type === "create-slide") {
            await dispatch(
              addNewSlideCollaboration(
                changes.value.insertAfterSlideID,
                changes.value?.newSlide,
              )
            );
            if (changes.value?.updateTheme?.status) {
              await dispatch(changeSlideTheme({
                applyTo: "single",
                slideID: changes.value.newSlide.id,
                selectedTheme: {
                  themeID: changes.value.updateTheme?.activeTheme,
                  prevValue: {
                    themeID: changes.value.updateTheme?.activeTheme,
                  },
                }
              })); 
            }
          } else if (type === "duplicate-slide") {
            dispatch(cloneSlide(changes.value, selectSlideOnCreate));
          } else if (type === "delete-slide") {
            dispatch(deleteSlide(changes.value));
          } else if (type === "select-animation-type") {
            dispatch(
              selectAnimationType(
                changes.active,
                changes.value.type,
                changes.value.groupedKeys
              )
            );
          } else if (type === "apply-animation-order") {
            dispatch(
              applyAnimationOrder({
                active: changes.active,
                value: changes.value.order,
                highestIndex: changes.value.highestIndex,
                groupedKeys: changes.value.groupedKeys,
              })
            );
          } else if (type === "swapped-slide") {
            const {
              fromIndex,
              toIndex,
              activeSlideID,
              placeUnderSlideID,
              emitForCollaboration,
            } = changes.value;
            dispatch(
              switchSlideIndex({
                fromIndex,
                toIndex,
                activeSlideID,
                placeUnderSlideID,
                emitForCollaboration,
              })
            );
          } else if (type === "update-multiple-field-styles") {
            dispatch(updateMultipleFieldStyles(changes.active, changes.value));
          } else if (type === "update-grouped-fields") {
            dispatch(updateGroupFieldsCollaboration(changes));
          } else if (type === "grouped-fields-undoRedo") {
            dispatch(
              updateUndoRedoFieldsOfCollaboration({
                active: changes.active,
                payload: changes.value,
              })
            );
          }
          // animation
          else if (type === "applied-animation") {
            if (changes.type === "animation-type") {
              dispatch(selectAnimationType({...changes.value}));
            } else if (changes.type === "animation-order") {
              dispatch(applyAnimationOrder({...changes.value}));
            } else if (changes.type === "animation-delay-duration") {
              dispatch(applyAnimationDelay({...changes.value}));
            } else if (changes.type === "animate-on-slide-display") {
              dispatch(applyAnimationOnSlideLoad({...changes.value}));
            }
          }
          // theme
          else if (type === "theme-changed") {
            if (changes.type === "create-theme") {
              dispatch(createThemeFromSocket(changes.value));
            } else if (changes.type === "update-theme") {
              dispatch(updateThemeFromSocket(changes.value));
            } else if (changes.type === "duplicate-theme") {
              dispatch(
                dispatchCloneTheme(
                  changes.value.currentSlideID,
                  changes.value.newSlideID
                )
              );
            } else if (changes.type === "delete-theme") {
              dispatch(dispatchDeleteTheme(changes.value));
            }
          } else if (type === "changed-temp-title") {
            dispatch(editTemplateName(changes.value));
          } else if (type === "changed-slide-status") {
            dispatch(toggleSlideWorkingStatus(changes.value));
          } else if (type === "resized-template") {
            dispatch(
              resizeTemplate({
                resizeFrom: changes.value.resizeFrom,
                resizeTo: changes.value.resizeTo,
              })
            );
          } else if (type === "add-comment") {
            dispatch(addSlideComment(changes.value, changes.active.slideID));
          } else if (type === "add-subcomment") {
            dispatch(respondToThread({...changes.value}));
          } else if (type === "update-comment") {
            dispatch(updateSlideComment(changes.value));
          } else if (type === "delete-comment") {
            dispatch(deleteSlideComment({...changes.value}));
          } else if (type === "resolved-comment") {
            dispatch(onResolvedIssue({...changes.value}));
          } else if (type === "slide-notes-added") {
            dispatch(
              addSlideNotes({
                slideID: changes.active.slideID,
                notes: changes.value,
              })
            );
          } else if (type === "group-fields-as-one") {
            dispatch(
              collaborateGroupFieldsAsOne({
                active: changes.active,
                payload: changes.value,
              })
            );
          }
        }
        // chat
        else if (data.type === "chatOnTemplate") {
          dispatch(sendChat({...data.payload}));
        }
        // for collaboration
        else if (data.type === "groupedItems") {
          dispatch(setGroupedItemsCollaboration({...data.payload}));
        }
        // load existing online users
        else if (data.type === "setExistingOnlineUsers") {
          dispatch(setUserConnectionId(data.connectionId))
          dispatch(setExistingOnlineUsers(data.usersOnline));
          if (data.requiresToLoadLatestVersion) {
            setRequestingLatestVersion({
              status: true,
              message: "Getting the latest update...",
              onlineUsers: data.onlineUsersByOrder,
            });
          }
        }
        // when new users come in online
        else if (data.type === "setUserOnline") {
          dispatch(setUserOnline(data.payload));
        } else if (data.type === "removeUserOnline") {
          dispatch(removeUserOnline(data.payload));
        } else if (data.type === "latestVersionReady") {
          if (data.status === "success") {
            setTimeout(async () => {
              await dispatch(
                fetchTemplateCanvas({
                  url: templateAPI,
                  slideID: null,
                })
              );
              await setRequestingLatestVersion({
                status: false,
                message: null,
                connectionId: null,
                onlineUsers: [],
              });
              setWaitProcess(false);
            }, [timeoutRange]);
          }
        } else if (data.type === "user-to-confirm-online") {
          setUserConfirmsOnline({
            status: true,
            tempID,
            requestedConnectionId: data.requestedConnectionId,
          });
        } else if (data.type === "toSaveLatestDesign") {
          // call API to save the template
          // then notify that update has been finished
          setRequestingLatestVersion({
            ...requestingLatestVersion,
            status: true,
            message: "Member joining in...",
            connectionId: data.requestedConnectionId,
          });
        } else if (data.type === "retrieving-versions-failed") {
          setRequestingLatestVersion({
            status: false,
            message: "Failed to get latest update...",
            connectionId: null,
          });
          setWaitProcess(false);
        }
        // one user gets first, second comes in, third request the latest
        // second one needs to wait for first and third one to finish exchanging the update
        else if (data.type === "process-started") {
          setWaitProcess(true);
        } else if (data.type === "process-finished") {
          setTimeout(async () => {
            setWaitProcess(false);
            setUserConfirmsOnline({status: false, requestedConnectionId: null});
          }, [timeoutRange]);
        }
      });
      ws.current = socket;

      return () => {
        socket.close();
      };
    }
  }, [username, tempID, ws]);

  const [waitProcess, setWaitProcess] = useState(false);
  const [userConfirmsOnline, setUserConfirmsOnline] = useState({
    status: false,
    requestedConnectionId: null,
  });
  useEffect(() => {
    if (userConfirmsOnline.status && ws.current && socketOn && WebSocket.OPEN && isOnline) {
      ws.current?.send(
        JSON.stringify({
          action: "userConfirmsOnline",
          tempID,
        })
      );
    }
  }, [tempID, userConfirmsOnline, isOnline]); //

  useEffect((data) => {
    const asynFetch = async () => {
      if (
        requestingLatestVersion.status &&
        requestingLatestVersion?.connectionId &&
        isOnline
      ) {
        const removeDeletedFields =
          template &&
          template.map((slide) => {
            return {
              ...slide,
              fields: Object.entries(slide?.fields)
                .filter(([id, item]) => item.deleted === false)
                .reduce((acc, [id, item]) => ({...acc, [id]: item}), {}),
            };
          });
        fetch(
          `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${tempID}/save-design-without-screenshot`,
          {
            method: "PUT",
            cache: "no-store",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              tempID,
              tempDetails: {
                details: removeDeletedFields,
                themes,
                sections: [],
              },
              requestedConnectionId: requestingLatestVersion.connectionId,
            }),
          }
        )
          .then((res) => res.json())
          .then(async (item) => {
            ws.current?.send(
              JSON.stringify({
                action: "updateProcessFinished",
                tempID,
              })
            );
            setTimeout(async () => {
              setRequestingLatestVersion({
                status: false,
                message: null,
              });
              setWaitProcess(false);
              setUserConfirmsOnline({
                status: false,
                requestedConnectionId: null,
              });
            }, timeoutRange);
          })
          .catch((e) => {
          });
        }
      }
      asynFetch();
    },
    [template, requestingLatestVersion, isOnline]
  );

  // keeping connection alive
  const onKeepConnectionAlive = useCallback(() => {
    if (WebSocket.OPEN && isOnline) {
      ws.current?.send(
        JSON.stringify({
          action: "pingPong",
          tempID,
        })
      );
    } 
  }, [tempID, isOnline]);

  const ref = useRef(null);
  useEffect(() => {
    ref.current = setInterval(onKeepConnectionAlive, 4.5 * 60 * 1000);
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [tempID]);

  const emitSocketEvents =
    useCallback(({actionType, item}) => {
      if (ws.current && socketOn && Object.keys(usersOnline.users).length > 0) {
        ws.current?.send(
          JSON.stringify({
            action: "emitTemplateChanges",
            tempID,
            payload: {
              type: actionType,
              changes: {
                active: {
                  slideID,
                  slideIndex,
                  fieldID,
                  username,
                },
                type: item.type,
                value: item.value,
              },
            },
          })
        );
      }
    }, [socketOn, tempID, slideID, slideIndex, fieldID, username, usersOnline.users]);
  // [usersOnline.users, slideID, fieldID]
  // );

  const emitSocketThemeChanges = useCallback(({actionType, item}) => {
    if (ws.current && socketOn && Object.keys(usersOnline?.users).length > 0) {
      ws.current?.send(
        JSON.stringify({
          action: "emitTemplateChanges",
          tempID,
          payload: {
            type: actionType,
            changes: {
              type: item.type,
              value: item.value,
            },
          },
        })
      );
    }
  },[socketOn, tempID, usersOnline.users]);

  useEffect(() => {
    if (
      Object.keys(newFieldsToEmit).length !== 0 &&
      Object.keys(usersOnline.users).length > 0 &&
      isOnline
    ) {
      if (emitSocketEvents) {
        // create field
        if (newFieldsToEmit.type === "create") {
          emitSocketEvents({
            actionType: "field-created",
            item: {
              type: "",
              value: newFieldsToEmit.field,
            },
          });
          dispatch(resetFieldToEmitFromSocket());
        } else if (newFieldsToEmit.type === "create-slide") {
          emitSocketEvents({
            actionType: "create-slide",
            item: {
              type: "",
              value: newFieldsToEmit.slideInfo,
            },
          });
          dispatch(resetFieldToEmitFromSocket());
        }
        // update fields
        else if (newFieldsToEmit.type === "update") {
          emitSocketEvents({
            actionType: "field-changed",
            item: {
              type: "",
              value: newFieldsToEmit.field,
            },
          });
          dispatch(resetFieldToEmitFromSocket());
        } else if (newFieldsToEmit.type === "delete") {
          emitSocketEvents({
            actionType: "field-removed",
            item: {
              type: "",
              value: newFieldsToEmit?.fields,
            },
          });
          dispatch(resetFieldToEmitFromSocket());
        } else if (newFieldsToEmit.type === "paste") {
          emitSocketEvents({
            actionType: "field-pasted",
            item: {
              type: "",
              value: newFieldsToEmit?.payload,
            },
          });
          dispatch(resetFieldToEmitFromSocket());
        } else if (newFieldsToEmit.type === "emit-grouped-fields-drag") {
          const item = {
            type: "grouped-position-updated",
            value: newFieldsToEmit.payload,
          };
          emitSocketEvents({actionType: "update-grouped-fields", item});
        } 
      }
    }
  }, [newFieldsToEmit, usersOnline.users, isOnline]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  // useEffect(() => {
  //   if (
  //     toEmitGroupedItems.type === "emit-grouped-fields-remove" &&
  //     Object.keys(usersOnline.users).length > 0
  //   ) {
  //     emitSocketEvents({
  //       actionType: "update-grouped-fields",
  //       item: {
  //         type: "grouped-fields-removed",
  //         value: toEmitGroupedItems.payload,
  //       },
  //     });
  //   }
  // }, [toEmitGroupedItems, usersOnline.users]);

  return (
    <SocketContext.Provider
      value={{socket: socketOn ? ws : undefined, emitSocketEvents, emitSocketThemeChanges}}
    >
      {enableSocket &&
      (requestingLatestVersion.status ||
        userConfirmsOnline.status ||
        waitProcess) ? (
        <ProcessingOverlay message={requestingLatestVersion?.message} />
      ) : undefined}
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.defaultProps = {
  enableSocket: false,
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    fieldID: selectActiveField(designTemplate),
    slideIndex: selectActiveSlide(designTemplate),
    slideID: selectActiveSlideID(designTemplate),
    usersOnline: selectUsersOnLine(designTemplate),
    template: selectTemplate(designTemplate),
    themes: selectTemplateThemes(designTemplate),
    newFieldsToEmit: selectFieldToEmitFromSocket(designTemplate),
    // toEmitGroupedItems: selectEmitItemsToSocket(designTemplate),
  };
};

export default connect(mapStateToProps, null)(SocketProvider);
