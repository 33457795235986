import React, {useState} from "react";
import {Modal} from "../../../../oat-ui";
import AddMember from "./AddMember";
import MemberDetails from "./MemberDetails";
import MemberList from "./MemberList";

const MemberModals = ({close}) => {
  const [activeModal, setActiveModal] = useState("member-list");
  let displayModal = null;

  const [selectedMember, setSelectedMember] = useState();

  const onSelectMember = (member) => {
    setSelectedMember(member);
    setActiveModal("member-details");
  };

  const switchModal = (value) => {
    setActiveModal(value);
  };

  const onCancel = () => {
    setActiveModal("member-list");
  };

  switch (activeModal) {
    case "member-details":
      displayModal = (
        <Modal width={420} height="auto" close={close}>
          <MemberDetails member={selectedMember} onCancel={onCancel} />
        </Modal>
      );
      break;
    case "add-member":
      displayModal = (
        <Modal width={400} height="auto" close={close}>
          <AddMember onCancel={switchModal} />
        </Modal>
      );
      break;
    default:
      displayModal = (
        <Modal width={600} height={470} close={close}>
          <MemberList
            onSelectMember={onSelectMember}
            switchModal={switchModal}
            onCancel={onCancel}
          />
        </Modal>
      );
      break;
  }
  return displayModal;
};

export default MemberModals;
