import React from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {selectThemeProps} from "../../../../../store/selectors/template/theme.selector";
import {createText} from "../../../../../store/actions/fields/text.action";
import {ulOlLi} from "../../../parts/ul-ol-li";
import {storeActionsUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {
  selectTempScale,
  selectTemplateSize,
} from "../../../../../store/selectors/template/template.selector";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {createShortKey} from "../../../../../store/reducers/functions/fields/utils";
import {closeDrawer} from "../../../../../store/actions/layout.action";
import {getWindowSize} from "../../../../../../oat-window-size/getWindowSize";

const DisplayItem = ({onCreate, text, subtype, lineHeight}) => {
  const handleClick = () => {
    onCreate(text);
  };

  let fontSize = 16;
  if (subtype === "heading") {
    fontSize = 30;
  } else if (subtype === "subheading") {
    fontSize = 24;
  } else if (subtype === "normalText") {
    fontSize = 20;
  }
  return (
    <Item
      dangerouslySetInnerHTML={{__html: text.content}}
      onClick={handleClick}
      style={{fontSize}}
      lineHeight={lineHeight}
      subtype={subtype}
    />
  );
};

const CreateText = ({
  createText,
  closeModal,
  closeDrawer,
  activeThemeProps,
  storeActionsUndoRedo,
  tempScale,
  slideID,
  tempSize,
  canvasSize,
}) => {
  const {
    heading,
    subheading,
    normalText,
    smallText,
    bulletContent,
    listContent,
  } = activeThemeProps;
  const windowSize = getWindowSize();

  const availableTextTypes = [
    {
      content: "<p>Heading</p>",
      type: "text",
      subtype: "heading",
      size: {
        w: windowSize.width < 850 ? 140 / tempScale : 260 / tempScale, // 0.584375, //(tempSize.w * 130) / 536,
        h: 40 / tempScale, // 0.584375, //(tempSize.h * 32) / 536,
        w2: windowSize.width < 850 ? 140 : 260,
        h2: 42,
      },
      ["styles"]: {
        fontSize: windowSize.width >= 850 ? 56 : 32,
        fontWeight: 700,
        letterSpacing: heading.letterSpacing,
        lineHeight: heading.lineHeight,
        scale: 1 / tempScale, //0.584375, // 1) * ratioDiff,
        color1: heading.color1,
        fontFamily: heading.fontFamily,
      },
    },
    {
      content: "<p>Subheading</p>",
      type: "text",
      subtype: "subheading",
      size: {
        w: 230 / tempScale, // 0.584375, //(tempSize.w * 130) / 536,
        h: 38 / tempScale, // 0.584375, //(tempSize.h * 32) / 536,
        w2: 230,
        h2: 38,
      },
      ["styles"]: {
        fontSize: windowSize.width >= 850 ? 38 : 32,
        fontWeight: 700,
        letterSpacing: subheading.letterSpacing,
        lineHeight: subheading.lineHeight,
        scale: 1 / tempScale, //0.584375, // 1) * ratioDiff,
        color1: subheading.color1,
        fontFamily: subheading.fontFamily,
      },
    },
    {
      content: "<p>Normal Text</p>", //  <a href='#'>Text</a>
      type: "text",
      subtype: "normalText",
      size: {
        w: 186 / tempScale,
        h: 29 / tempScale,
        w2: 186,
        h2: 29,
      },
      ["styles"]: {
        fontSize: 24,
        fontWeight: 400,
        letterSpacing: normalText.letterSpacing,
        lineHeight: normalText.lineHeight,
        scale: 1 / tempScale,
        color1: normalText.color1,
        fontFamily: normalText.fontFamily,
      },
    },
    {
      content: "<p>Small Text</p>",
      type: "text",
      subtype: "smallText",
      size: {
        w: 160 / tempScale,
        h: 29 / tempScale,
        w2: 160,
        h2: 29,
      },
      ["styles"]: {
        fontSize: 18,
        fontWeight: 400,
        letterSpacing: smallText.letterSpacing,
        lineHeight: smallText.lineHeight,
        scale: 1 / tempScale,
        color1: smallText.color1,
        fontFamily: smallText.fontFamily,
      },
    },
    {
      content:
        "<ul><li><span>Bullet One</span></li><li><span>Bullet Two</span></li></ul>",
      type: "text",
      subtype: "bulletContent",
      size: {
        w: 110 / tempScale,
        h: 45 / tempScale,
        w2: 110,
        h2: 45,
      },
      ["styles"]: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: bulletContent.letterSpacing,
        lineHeight: bulletContent.lineHeight,
        scale: 1 / tempScale,
        color1: bulletContent.color1,
        fontFamily: bulletContent.fontFamily,
        bulletType: "\\2022",
        bulletColor: bulletContent.bulletColor
      },
    },
    {
      content:
        "<ol><li><span>List One</span></li><li><span>List Two</span></li></ol>",
      type: "text",
      subtype: "listContent",
      size: {
        w: 94 / tempScale,
        h: 42 / tempScale,
        w2: 94,
        h2: 42,
      },
      ["styles"]: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: listContent.letterSpacing,
        lineHeight: listContent.lineHeight,
        scale: 1 / tempScale,
        color1: listContent.color1,
        fontFamily: listContent.fontFamily,
      },
    },
    {
      content:
        "<ol><li><span>List One</span></li><li><span>List Two</span></li></ol>",
      type: "text",
      subtype: "listContentWithBackground",
      size: {
        w: 94 / tempScale,
        h: 42 / tempScale,
        w2: 94,
        h2: 42,
      },
      styles: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: listContent.letterSpacing,
        lineHeight: listContent.lineHeight,
        scale: 1 / tempScale,
        color1: listContent.color1,
        fontFamily: listContent.fontFamily,
        bg1: "red",
      },
    },
    // {
    //   content: "<p>Link</p>",
    //   type: "text",
    //   subtype: "link",
    //   size: {
    //     w: 50 / tempScale,
    //     h: 14 / tempScale,
    //     w2: 50, //(1920 * 82) / 536,
    //     h2: 14, // (1080 * 18) / 297,
    //   },
    //   styles: {
    //     fontSize: 18, //(1080 * 16) / 297,
    //     fontWeight: 400,
    //     letterSpacing: normalText.letterSpacing,
    //     lineHeight: normalText.lineHeight,
    //     scale: 1 / tempScale,
    //     color1: normalText.color1,
    //     fontFamily: normalText.fontFamily,
    //   },
    // },
  ];

  const handleCreate = ({content, size, subtype, styles}) => {
    const key = createShortKey();
    createText({
      slideID,
      key,
      content,
      size,
      subtype,
      styles,
    });
    storeActionsUndoRedo("create");

    if (window.innerWidth >= 850) {
      closeModal();
    } else {
      closeDrawer();
    }
  };

  return (
    <Items>
      {availableTextTypes.map((text, i) => (
        <DisplayItem
          key={i}
          text={text}
          onCreate={handleCreate}
          subtype={text.subtype}
          lineHeight={text.styles.lineHeight}
        />
      ))}
    </Items>
  );
};

const baseItem = css`
  display: flex;
  flex: 0 46%;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.deskPrimaryTxt};
  cursor: pointer;
  margin-bottom: 2%;
  @media only screen and (min-width: 850px) {
    flex: 0 24%;
  }
`;

const Item = styled.button`
  background: ${({theme}) => theme.modalItemBg};
  font-size: 18px;
  height: 100px;
  ${baseItem};
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0 1%;
  line-height: 2;
  ${ulOlLi};
  ul, ol {
    li {
      span {
        margin-left: 6px;
      }
      padding-bottom: 0px !important;
    } 
  }
  &:last-child {
    margin-bottom: 1.5em;
  }
  &:hover {
    opacity: 0.6;
  }
`;

const Items = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding-top: 14px;
  @media only screen and (min-width: 850px) {
    padding: 14px 10px 0 10px;
  }
`;

const mapStateToProps = (state) => {
  return {
    activeThemeProps: selectThemeProps(state.designTemplate),
    tempScale: selectTempScale(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
    tempSize: selectTemplateSize(state.designTemplate),
    canvasSize: state.designTemplate.canvasSize,
  };
};

export default connect(mapStateToProps, {
  createText,
  storeActionsUndoRedo,
  closeDrawer,
})(CreateText);
