import React, {useEffect, useState} from "react";
import {connect, batch} from "react-redux";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {addLinkToField} from "../../../../../../store/actions/fields/common.action";
import {selectTemplate} from "../../../../../../store/selectors/template/template.selector";
import {Title} from "../../shared/reuseStyles/Title";
import styled from "styled-components";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectActiveField,
  selectActiveFieldProps,
} from "../../../../../../store/selectors/fields.selector";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

let delayDebounce;
export const linkTypes = [
  {value: "within-current-slide", display: "Within Current Slide"},
  {value: "external-link", display: "External Link"},
];

const AddLink = ({
  slides,
  link,
  slideID,
  fieldID,
  addLinkToField,
  updateFieldCollaboration,
}) => {
  const active = {slideID, fieldID};
  const [displayLink, setDisplayLink] = useState(link);

  const onChangeLink = (e) => {
    const {value} = e.target;
    const linkInfo = {
      linkType: Number(value) ? 'slide-number' : 'url',
      value,
    };
    addLinkToField({active, payload: linkInfo});
    setDisplayLink(value);

    clearTimeout(delayDebounce);
    delayDebounce = setTimeout(() => {
      updateFieldCollaboration();
    }, 3500);
    return () => clearTimeout(delayDebounce);
  };

  useEffect(() => {
    if (link?.value) {
      const index = slides && slides.findIndex((item) => item?.id === link?.value);
      setDisplayLink(Number(index + 1));
    } else {
      setDisplayLink(0);
    }
  }, [link?.linkType, fieldID, link?.value, slides, link]);

  return (
    <>
      <Wrapper>
        <WrapTitle>
          <Title>Add Link</Title>
        </WrapTitle>

        <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
          <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
            <Input
              value={
                displayLink || link?.linkType === "slide-number" ? displayLink : link?.value ? link?.value : ""
              }
              onChange={onChangeLink}
              placeholder="Link or Slide No"
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const WrapTitle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Input = styled.input`
  display: flex;
  width: auto;
  max-width: 132px;
  min-width: 100px;
  height: 30px;
  color: ${({theme}) => theme.deskSecondaryTxt};
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  fill: ${({theme}) => theme.deskPrimaryTxt};
  border: none;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
`;

const mapStateToProps = ({designTemplate}) => {
  return {
    slides: selectTemplate(designTemplate),
    slideID: selectActiveSlide(designTemplate),
    fieldID: selectActiveField(designTemplate),
    link: selectActiveFieldProps(designTemplate).link,
  };
};

export default connect(
  mapStateToProps,
  batch(() => ({
    updateFieldStyles,
    addLinkToField,
    updateFieldCollaboration,
  }))
)(AddLink);
