import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {ExpandArrow} from "../../../../../../../oat-svg-icons";
import {dispatchIconColorUpdate} from "../../../../../../store/actions/fields/icon.action";
import {toggleTooltipPopper} from "../../../../../../store/actions/layout.action";
import {
  ColorSet,
  ExpandSvg,
  SelectedColor,
  TooltipLayout,
} from "../reuseTooltipStyles";
import {Content} from "../Tooltip";
import TooltipColorPickerHoc from "../tooltipHocs/TooltipColorPickerHoc";
import TooltipItemsHoc from "../tooltipHocs/TooltipItemsHoc";
import TooltipSelectionHoc from "../tooltipHocs/TooltipSelectionHoc";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const DisplayItem = ({onSelect, color, index}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onSelect(index);
  };
  return (
    <Content style={{marginRight: 0}} onMouseDown={handleClick}>
      <SelectedColor color={color} />
    </Content>
  );
};
const IconTooltip = ({
  active,
  colorSet,
  popperOpen,
  dispatchIconColorUpdate,
  toggleTooltipPopper,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const [selectedItem, setSelectedItem] = useState({
    categoryRow: 0, // indexes
    colorIndex: 1,
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let items = [];
    colorSet &&
      colorSet.map((item, i) =>
        items.push({
          display: item.name,
          value: item.colors,
        })
      );
    setCategories(items);
    setSelectedItem({
      categoryRow: 0,
      colorIndex: 1,
    });
  }, []);

  const handleCategoryChange = ({index}) => {
    setSelectedItem({
      categoryRow: index,
      colorIndex: 1,
    });
    closePopper();
  };

  const displayColorPicker = (colorIndex) => {
    setSelectedItem({
      ...selectedItem,
      colorIndex,
    });
    toggleTooltipPopper("color-picker");
  };

  const displayCategoryList = (e) => {
    e.stopPropagation();
    toggleTooltipPopper("icon-category");
  };

  const handleColorUpdate = (value) => {
    dispatchIconColorUpdate({
      active,
      row: selectedItem.categoryRow,
      column: selectedItem.colorIndex,
      value,
    });
    storeFieldUndoRedo();
  };

  const closePopper = () => {
    toggleTooltipPopper();
  };

  const handleDebounceValue = (value) => {
    updateFieldCollaboration();
  };

  return (
    <TooltipLayout>
      <TooltipItemsHoc style={{display: "flex"}}>
        {categories.length > 0 && colorSet[selectedItem?.categoryRow]?.colors?.length > 0 ? 
          categories.length > 1 ?
            <Content
              style={{textTransform: "capitalize", margin: 0}}
              onMouseDown={displayCategoryList}
            >
              {colorSet[selectedItem?.categoryRow] &&
                categories.length > 1 &&
                colorSet[selectedItem?.categoryRow]?.name}
              {categories.length > 1  ? <ExpandSvg>{ExpandArrow}</ExpandSvg> : undefined}
            </Content>
          : undefined
        : undefined}

        <ColorSet>
          {colorSet[selectedItem?.categoryRow]?.colors?.map((item, i) => 
            <DisplayItem
              key={i}
              index={i}
              color={item?.color}
              onSelect={displayColorPicker}
            />
          )}
        </ColorSet>
      </TooltipItemsHoc>

      {popperOpen.status && popperOpen.type === "icon-category" && categories?.length > 0? (
        <TooltipSelectionHoc
          activeItem={colorSet[selectedItem?.categoryRow]?.id}
          options={categories}
          onChange={handleCategoryChange}
          onClose={closePopper}
        />
      ) : undefined}

      {popperOpen.status && popperOpen.type === "color-picker" && categories.length > 0 ? (
        <TooltipColorPickerHoc
          onDebounce={handleDebounceValue}
          onClose={closePopper}
          activeColor={
            colorSet[selectedItem?.categoryRow].colors[selectedItem?.colorIndex]?.color
          }
          onUpdate={handleColorUpdate}
        />
      ) : undefined}
    </TooltipLayout>
  );
};

export default connect(null, {
  dispatchIconColorUpdate,
  toggleTooltipPopper,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(IconTooltip);
