import {
  UPDATE_THEME_BUILDER_FIELDS,
  CREATE_THEME,
  UPDATE_THEME,
  CHANGE_SLIDE_THEME,
  BUILDING_THEME,
  UPDATE_THEME_NAME,
  EDIT_THEME,
  CLONE_THEME,
  DELETE_THEME,
  APPLY_TO_ALL_TEXT_TYPE,
  SET_THEME_INFO,
  CREATE_THEME_FROM_SOCKET,
  UPDATE_THEME_FROM_SOCKET,
} from "../actionTypes";

export const toggleBuildingThemeStatus = () => {
  return {
    type: BUILDING_THEME,
  };
};

export const updateThemeBuilderFields = ({category, key, value}) => {
  return {
    type: UPDATE_THEME_BUILDER_FIELDS,
    category,
    key,
    value,
  };
};

export const applyToAllTextType = ({key, value}) => {
  return {
    type: APPLY_TO_ALL_TEXT_TYPE,
    key,
    value,
  };
};

export const createTheme = (name, key) => {
  return {
    type: CREATE_THEME,
    name,
    key,
  };
};

export const updateTheme = (index) => {
  return {
    type: UPDATE_THEME,
    index,
  };
};

export const changeSlideTheme = ({applyTo, slideID, selectedTheme}) => {
  return {
    type: CHANGE_SLIDE_THEME,
    applyTo,
    slideID,
    selectedTheme,
  };
};

export const updateThemeName = (name) => {
  return {
    type: UPDATE_THEME_NAME,
    name,
  };
};

export const dispatchEditTheme = (id) => {
  return {
    type: EDIT_THEME,
    id,
  };
};

export const dispatchDeleteTheme = (id) => {
  return {
    type: DELETE_THEME,
    id,
  };
};

export const dispatchCloneTheme = (id, newSlideID) => {
  return {
    type: CLONE_THEME,
    id,
    newSlideID,
  };
};

export const dispatchThemeInfo = (payload) => {
  return {
    type: SET_THEME_INFO,
    payload,
  };
};

export const createThemeFromSocket = (payload) => {
  return {
    type: CREATE_THEME_FROM_SOCKET,
    payload,
  };
};

export const updateThemeFromSocket = (payload) => {
  return {
    type: UPDATE_THEME_FROM_SOCKET,
    payload,
  };
};
