import React, {useState, useEffect} from "react";
import {DesignTemplate} from "../../../packages/oat-design-template";
import {selectUserInfo} from "../../../redux/user/authUser";
import {connect} from "react-redux";
import {useParams, useLocation, useNavigate} from "react-router";

const DesignProfileTemplate = ({user}) => {
  let params = useParams();
  const {tempID, profileSlug} = params;
  const query = new URLSearchParams(useLocation().search);
  const mobileView = query.get("mobileView");
  let navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const saveUserTemplate = async (item) => {
    if (!isSaving) {
      setIsSaving(true);
      await fetch(
        !mobileView
          ? `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/profile/item/${tempID}`
          : // `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/profile-${profileSlug}/templates`
            // need to create mobile update link
            `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/profile-${profileSlug}/templates/mobile-template`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            profileSlug: user.account.business.slug,
            tempInfo: {
              ...item.templateInfo,
              tempID,
            },
            tempDetails: item.template,
          }),
        }
      )
        // .then((response) => response.json())
        .then(() => setIsSaving(false))
        .catch((error) => {});
    }
  };

  useEffect(() => {
    if (
      user.account.business.slug !== profileSlug ||
      !user.account.business.permission.profile.update
    ) {
      if (user.loggedIn.status) {
        return navigate(`/dashboard/shared`);
      } else {
        return navigate(`/`);
      }
    }
  }, [user]);

  const {state} = useLocation();

  return (
    <DesignTemplate
      onSave={saveUserTemplate}
      apiEndpoint={`https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${profileSlug}/profile/item/${tempID}`}
      buttonTitle="Save"
      displaySlideListOnLoad={state ? state.newlyLoaded : false}
      isSaving={isSaving}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(DesignProfileTemplate);
