import React from "react";
import {themeBoxStyles} from "./SlideThemeStyles";
import styled from "styled-components";
import {Option} from "../../../../ui/styles/SelectStyles";
import {connect} from "react-redux";
import {
  dispatchEditTheme,
  dispatchDeleteTheme,
  dispatchCloneTheme,
} from "../../../../store/actions/template/theme.action";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSocket} from "../../../../webSocket/useSocket";
import {createShortKey} from "../../../../store/reducers/functions/fields/utils";

const SlideThemeActions = ({
  dispatchEditTheme,
  dispatchDeleteTheme,
  dispatchCloneTheme,
  selectedID,
  applied,
  themesLength
}) => {
  let navigate = useNavigate();
  let params = useParams();
  const location = useLocation();
  const {emitSocketThemeChanges} = useSocket() || {};

  const handleEdit = () => {
    dispatchEditTheme(selectedID);
    return navigate(`${location.pathname}?editing-theme=true`, { replace: true });
  };

  const handleClone = () => {
    const newSlideID = createShortKey();
    dispatchCloneTheme(selectedID, newSlideID);
    applied();

    const item = {
      type: "duplicate-theme",
      value: {
        currentSlideID: selectedID,
        newSlideID,
      },
    };
    emitSocketThemeChanges({actionType: "theme-changed", item});
  };

  const handleDelete = () => {
    dispatchDeleteTheme(selectedID);
    applied();

    const item = {type: "delete-theme", value: selectedID};
    emitSocketThemeChanges({actionType: "theme-changed", item});
  };

  return (
    <Wrapper>
      <Option onClick={handleEdit}>Edit</Option>
      <Option onClick={handleClone}>Clone</Option>
      {/* leave at least one theme */}
      {/* {themesLength > 1 ?  */}
        <Option onClick={handleDelete} style={{color: "#f37272"}}>
          Delete
        </Option> 
      {/* : undefined} */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  top: 0px;
  left: 200px;
  width: 140px;
  height: 130px;
  ${themeBoxStyles};
`;

export default connect(null, {
  dispatchEditTheme,
  dispatchDeleteTheme,
  dispatchCloneTheme,
})(SlideThemeActions);
