import {isTouchEvent} from "./isTouchEvent";

export const calcHue = (e, hsl, slider, direction) => {
  const {left, top} = slider.getBoundingClientRect();
  const clientX = isTouchEvent(e) ? e.touches[0].clientX : e.clientX;
  const clientY = isTouchEvent(e) ? e.touches[0].clientY : e.clientY;
  const boxWidth = slider.clientWidth;
  const boxHeight = slider.clientHeight;

  const newXOffset = clientX - (left + window.pageXOffset);
  const newYOffset = clientY - (top + window.pageYOffset);

  if (direction === "vertical") {
    let h;
    if (newYOffset < 0) {
      h = 359;
    } else if (newYOffset > boxHeight) {
      h = 0;
    } else {
      const percent = -((newYOffset * 100) / boxHeight) + 100;
      h = (360 * percent) / 100;
    }

    if (hsl.h !== h) {
      return {
        h,
        s: hsl.s,
        l: hsl.l,
        a: hsl.a,
        source: "hsl",
      };
    }
  } else {
    let h;
    if (newXOffset < 0) {
      h = 0;
    } else if (newXOffset > boxWidth) {
      h = 359;
    } else {
      const percent = (newXOffset * 100) / boxWidth;
      h = (360 * percent) / 100;
    }

    if (hsl.h !== h) {
      return {
        h,
        s: hsl.s,
        l: hsl.l,
        a: hsl.a,
        source: "hsl",
      };
    }
  }
  return null;
};
