import React from "react";
import {PresentTemplate as DisplayTemplate} from "../../../packages/oat-design-template";
// import {useParams} from "react-router";

const PresentTemplate = () => {
  // let params = useParams();
  // const {username, tempID} = params;

  // useEffect(() => {
  //   `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${username}/template/${templateID}`
  // },[])

  return <DisplayTemplate />;
};

export default PresentTemplate;
