import React from "react";
import {ActiveDot, Item} from "../reusableStyles/sidebarItem";

const LibFolderImages = ({item, active, onSelect, onRightClick}) => {
  const handleRightClick = (e) => {
    onRightClick(e, item);
  };

  const handleSelect = () => {
    onSelect(item);
  };

  return (
    <Item 
      onContextMenu={handleRightClick} 
      onClick={handleSelect} 
      style={{ padding: '0px 16px 0px 24px' }}
    >
      <p>{item.folderName}</p>
      {active ? (
        <ActiveDot style={{right: 16}} $active={active} />
      ) : undefined}
    </Item>
  );
};

export default LibFolderImages;