export function renameTemplateTitle() {
  return function(state, action) {
    const {updatedTitle, tempID} = action;
    return {
      ...state,
      templates: state.templates.map((item) =>
        item.tempID === tempID
          ? {
              ...item,
              title: updatedTitle,
            }
          : item
      ),
    };
  };
}

export function onRemoveTemplateSuccess() {
  return function(state, action) {
    return {
      ...state,
      templates: state.templates.filter(
        (item) => item.tempID !== action.tempID
      ),
    };
  };
}
