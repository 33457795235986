export const OpenSans = 
  "https://oat-all-fonts.s3.amazonaws.com/OpenSans-Regular.ttf";
  // "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap";
export const ComicNeue =
  "https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap";
export const RobotoCondensed =
  "https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap";
export const Merriweather =
  "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap";
export const DoHyeon =
  "https://fonts.googleapis.com/css2?family=Do+Hyeon:wght@400;700&display=swap";
export const TitilliumWeb =
  "https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap";
export const OleoScriptSwashCaps =
  "https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@400;700&display=swap";
export const Kanit = "https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap";

export const Noto = "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap";
export const Inter = "https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap";
export const Raleway = "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap";
export const PlayfairDisplay = "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,600;0,700;0,900;400;1,600;1,700;1,900&display=swap";
export const Ubuntu = "https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap";
export const Lora = "https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap";
export const DMSans = 'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,700;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,700&display=swap';
export const PTSans = 'https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Barlow = 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Arimo = 'https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const FiraSans = 'https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,700&display=swap';
export const Montserrat = 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const JosefinSans = 'https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Karla = 'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const SourceCodePro = 'https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Bitter = 'https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Cabin = 'https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Jost = 'https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Archivo = 'https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const EBGaramond = 'https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap';
// export const NotoColorEmoji = 'https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&family=Noto+Emoji&display=swap';

export const BarlowCondensed = 'https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Overpass = 'https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const CrimsonText = 'https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const PublicSans = 'https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap';
export const Prompt = 'https://fonts.googleapis.com/css2?family=Prompt&family=Noto+Emoji&display=swap';
export const ChakraPetch = 'https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Noto+Emoji&display=swap';
export const IBMPlexMono = 'https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Noto+Emoji&display=swap';
export const ZillaSlab = 'https://fonts.googleapis.com/css2?family=Zilla+Slab&family=Noto+Emoji&display=swap';
export const Arvo = 'https://fonts.googleapis.com/css2?family=Arvo&family=Noto+Emoji&display=swap';
export const Mulish = "https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,700;1,400;1,700&display=swap"

// export const ExoTwo = 'https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,400;0,700;1,400;1,700&display=swap';

//"Kanit-Regular.ttf";

export const Marhey = 'https://fonts.googleapis.com/css2?family=Marhey&display=swap';
export const Cairo = 'https://fonts.googleapis.com/css2?family=Cairo&display=swap';
export const Oxygen = 'https://fonts.googleapis.com/css2?family=Oxygen&display=swap';
export const DancingScript = 'https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap';
export const SpaceGrotesk = 'https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap';
export const NanumGothicCoding = 'https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap';
export const Hind = 'https://fonts.googleapis.com/css2?family=Hind&display=swap';
export const Comfortaa = 'https://fonts.googleapis.com/css2?family=Comfortaa&display=swap';
export const Assistant = 'https://fonts.googleapis.com/css2?family=Assistant&display=swap';

export const fontTypes = [
  {
    fontType: "Regular",
    fontWeight: "400",
    fontStyle: "normal",
    src: '',
  },
  {
    fontType: "Regular Italic",
    fontWeight: "400",
    fontStyle: "italic",
    src: '',
  },
  {
    fontType: "Bold",
    fontWeight: "700",
    fontStyle: "normal",
    src: '',
  },
  {
    fontType: "Bold Italic",
    fontWeight: "700",
    fontStyle: "italic",
    src: '',
  },
];

export const noItalic = [
  {
    fontType: "Regular",
    fontWeight: "400",
    fontStyle: "normal",
    src: '',
  },
  {
    fontType: "Bold",
    fontWeight: "700",
    fontStyle: "normal",
    src: '',
  }
];

export const googleFonts = {
  ["Arimo"]: {
    fontFamily: "Arimo",
    types: fontTypes
  },
  ['Archivo'] : {
    fontFamily: "Archivo",
    types: fontTypes
  }, 
  ["Arvo"]: {
    fontFamily: "Arvo",
    types: fontTypes
  },
  ["Asap"]: {
    fontFamily: "Asap",
    types: fontTypes
  },
  ["Assistant"]: {
    fontFamily: "Assistant",
    types: noItalic
  },
  ["Barlow"]: {
    fontFamily: "Barlow",
    types: fontTypes
  },
  ["Barlow Condensed"]: {
    fontFamily: "Barlow Condensed",
    types: fontTypes
  },
  ['Bitter'] : {
    fontFamily: "Bitter",
    types: fontTypes
  }, 
  ['Cabin'] : {
    fontFamily: "Cabin",
    types: fontTypes
  },
  ['Cairo'] : {
    fontFamily: "Cairo",
    types: noItalic
  },
  ["Chakra Petch"]: {
    fontFamily: "Chakra Petch",
    types: fontTypes
  },
  ["Comfortaa"]: {
    fontFamily: "Comfortaa",
    types: noItalic
  },
  ["Comic Neue"]: {
    fontFamily: "Comic Neue",
    types: fontTypes
  },
  ["Crimson Text"]: {
    fontFamily: "Crimson Text",
    types: fontTypes
  },
  ['Dancing Script'] : {
    fontFamily: "Dancing Script",
    types: noItalic
  }, 
  ["DM Sans"]: {
    fontFamily: "DM Sans",
    types: fontTypes
  }, 
  ["Do Hyeon"]: {
    fontFamily: "Do Hyeon",
    types: noItalic
  },
  ['EB Garamond'] : {
    fontFamily: "EB Garamond",
    types: fontTypes
  },
  ["Fira Sans"]: {
    fontFamily: "Fira Sans",
    types: fontTypes
  },
  ["Hind"]: {
    fontFamily: "Hind",
    types: noItalic
  },
  ["IBM Plex Mono"]: {
    fontFamily: "IBM Plex Mono",
    types: fontTypes
  },
  ["Inter"]: {
    fontFamily: "Inter",
    types: fontTypes
  },
  ['Josefin Sans'] : {
    fontFamily: "Josefin Sans",
    types: fontTypes
  }, 
  ['Jost'] : {
    fontFamily: "Jost",
    types: fontTypes
  },
  ["Kanit"]: {
    fontFamily: "Kanit",
    types: fontTypes
  },
  ['Karla'] : {
    fontFamily: "Karla",
    types: fontTypes
  },
  ["Lora"]: {
    fontFamily: "Lora",
    types: fontTypes
  },
  ["Marhey"]: {
    fontFamily: "Marhey",
    types: noItalic
  },
  ["Merriweather"]: {
    fontFamily: "Merriweather",
    types: fontTypes
  },
  ["Mohave"]: {
    fontFamily: "Mohave",
    types: fontTypes
  },
  ["Montserrat"] : {
    fontFamily: "Montserrat",
    types: fontTypes
  }, 
  ["Mulish"]: {
    fontFamily: "Mulish",
    types: fontTypes
  },
  ["Nanum Gothic Coding"]: {
    fontFamily: "Nanum Gothic Coding",
    types: noItalic
  },
  ["Noto Sans"]: {
    fontFamily: "Noto Sans",
    types: fontTypes
  },
  ["Oleo Script Swash Caps"]: {
    fontFamily: "Oleo Script Swash Caps",
    types: noItalic
  },
  ["Open Sans"]: {
    fontFamily: "Open Sans",
    types: [
      {
        fontType: "Light",
        fontWeight: "300",
        fontStyle: "normal",
        src: OpenSans,
      },
      {
        fontType: "Light Italic",
        fontWeight: "300",
        fontStyle: "italic",
        src: OpenSans,
      },
      {
        fontType: "Regular",
        fontWeight: "400",
        fontStyle: "normal",
        src: OpenSans,
      },
      {
        fontType: "Regular Italic",
        fontWeight: "400",
        fontStyle: "italic",
        src: OpenSans,
      },
      {
        fontType: "Medium",
        fontWeight: "500",
        fontStyle: "normal",
        src: OpenSans,
      },
      {
        fontType: "Medium Italic",
        fontWeight: "500",
        fontStyle: "italic",
        src: OpenSans,
      },
      {
        fontType: "Semi Bold",
        fontWeight: "600",
        fontStyle: "normal",
        src: OpenSans,
      },
      {
        fontType: "Semi Bold Italic",
        fontWeight: "600",
        fontStyle: "italic",
        src: OpenSans,
      },
      {
        fontType: "Bold",
        fontWeight: "700",
        fontStyle: "normal",
        src: OpenSans,
      },
      {
        fontType: "Bold Italic",
        fontWeight: "700",
        fontStyle: "italic",
        src: OpenSans,
      },
      {
        fontType: "Extra Bold",
        fontWeight: "800",
        fontStyle: "normal",
        src: OpenSans,
      },
      {
        fontType: "Extra Bold Italic",
        fontWeight: "800",
        fontStyle: "italic",
        src: OpenSans,
      },
    ],
  },
  ["Overpass"]: {
    fontFamily: "Overpass",
    types: fontTypes
  },
  ["Oxygen"]: {
    fontFamily: "Oxygen",
    types: noItalic
  },
  ["Playfair Display"]: {
    fontFamily: "Playfair Display",
    types: fontTypes
  },
  ["Poppins"]: {
    fontFamily: "Poppins",
    types: fontTypes
  },
  ["PT Sans"]: {
    fontFamily: "PT Sans",
    types: fontTypes
  },
  ["Public Sans"]: {
    fontFamily: "Public Sans",
    types: fontTypes
  },
  ["Prompt"]: {
    fontFamily: "Prompt",
    types: fontTypes
  },
  ["Raleway"]: {
    fontFamily: "Raleway",
    types: fontTypes
  },
  ["Roboto Condensed"]: {
    fontFamily: "Roboto Condensed",
    types: fontTypes
  },
  ["Saira"]: {
    fontFamily: "Saira",
    types: fontTypes
  },
  ['Source Code Pro'] : {
    fontFamily: "Source Code Pro",
    types: fontTypes
  }, 
  ["Space Grotesk"]: {
    fontFamily: "Space Grotesk",
    types: noItalic
  },
  ["Titillium Web"]: {
    fontFamily: "Titillium Web",
    types: fontTypes
  },
  // ['Noto Color Emoji'] : {
  //   fontFamily: "Noto Color Emoji",
  //   types: [
  //     {
  //       fontType: "Regular",
  //       fontWeight: "400",
  //       fontStyle: "normal",
  //       src: '',
  //     },
  //     {
  //       fontType: "Regular Italic",
  //       fontWeight: "400",
  //       fontStyle: "italic",
  //       src: '',
  //     }
  //   ],
  // },
  ["Ubuntu"]: {
    fontFamily: "Ubuntu",
    types: fontTypes
  },
  ["Work Sans"]: {
    fontFamily: "Work Sans",
    types: fontTypes
  },
  ["Zilla Slab"]: {
    fontFamily: "Zilla Slab",
    types: fontTypes
  }
};

