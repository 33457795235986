import React, {useState} from "react";
import {connect} from "react-redux";
import {Items, Item} from "./MobilePanelStyles";
import {updateEditingPanel} from "../../../../../../store/actions/layout.action";
import MobileDrawer from "../../../../drawer/MobileDrawer";
import {useSocket} from "../../../../../../webSocket/useSocket";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {
  selectActiveFieldProps,
  selectCachedFieldStyles,
} from "../../../../../../store/selectors/fields.selector";
import styled from "styled-components";
import PopoverColorBox from "../../shared/colorPicker/PopoverColorBox";
import Switch from "../../shared/inputs/Switch";
import {DrawerLayout} from "./edit/layoutStyles";
import EditOpacity from "../../desktop/common/EditOpacity";
import LockField from "./edit/common/LockField";
import ZIndex from "../../shared/ZIndex";
import EditRotate from "./edit/common/EditRotate";
import {WrapColorBox} from "../../desktop/common/WrapColorStyles";
import {Title} from "../../shared/reuseStyles/Title";
import ApplyAnimations from "../../desktop/animations/ApplyAnimations";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";
import FlipVideo from "../../desktop/video/FlipVideo";
import RangeSlider from "../../shared/inputs/RangeSlider";

const VideoPanel = ({
  updateEditingPanel,
  updateFieldStyles,
  stableStyles,
  slideID,
  activeField,
  updateFieldCollaboration,
}) => {
  const [openDrawer, setOpenDrawer] = useState({
    status: false,
    type: null,
    height: 0,
  });
  const {styles, subtype} = activeField || {};
  const {roundedBorder, borderColor, showBorder, borderRadius} = styles || {};

  const handleDrawerClose = () => {
    setOpenDrawer({
      status: false,
      type: null,
    });
  };

  // updating fields

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });
  };

  const handleEmitValue = () => {
    updateFieldCollaboration();
  };

  const toggleRoundedBorder = (value) => {
    handleStyleUpdate("roundedBorder", value);
    handleEmitValue();
  };

  const changeShowBorderStatus = async (value) => {
    await handleStyleUpdate("showBorder", value);
    await handleEmitValue();
  };

  const changeBorderColor = (value) => {
    handleStyleUpdate("borderColor", value);
  };

  const onColorDebounce = (value) => {
    handleEmitValue("borderColor", value);
  };

  const updateBorderRadius = (value) => {
    handleStyleUpdate("borderRadius", Number(value));
  };

  const changeAutoPlayStatus = async (value) => {
    await handleStyleUpdate("autoPlay", value);
    await handleEmitValue();
  }

  let displayView = null;
  switch (openDrawer.type) {
    case "border":
      displayView = (
        <div>
          {subtype === "embedded" ? 
            <div style={{paddingBottom: "1.5em"}}>
              <Switch
                title="Rounded Border"
                value={roundedBorder}
                onItemChange={toggleRoundedBorder}
                checked={roundedBorder}
              />
            </div>
          : undefined}

          {subtype === "uploaded" ?
            <div style={{paddingBottom: "1.5em"}}>
              <RangeSlider
                title="Rounded"
                value={borderRadius ? borderRadius : 0}
                min={0}
                max={100}
                step={1}
                onItemChange={updateBorderRadius}
                fieldType="borderRadius"
              />
            </div> 
          : undefined}

          <div style={{paddingBottom: "1.5em"}}>
            <Switch
              title="Display Border"
              value={showBorder}
              onItemChange={changeShowBorderStatus}
              checked={showBorder}
            />
          </div>

          {showBorder ? (
            <WrapColorBox style={{display: "flex", paddingBottom: "2em"}}>
              <Title>Color</Title>
              <PopoverColorBox
                value={borderColor}
                updateFieldStyles={changeBorderColor}
                onDebounce={onColorDebounce}
              />
            </WrapColorBox>
          ) : undefined}

          {subtype === "uploaded" ? 
            <div style={{paddingBottom: "1.5em"}}>
              <Switch
                title="Auto Play"
                value={roundedBorder}
                onItemChange={changeAutoPlayStatus}
                checked={roundedBorder}
              />
            </div>
          : undefined}
        </div>
      );
      break;
    case "opacity":
      displayView = <EditOpacity value={styles?.opacity} />;
      break;
    case "rotate":
      displayView = <EditRotate value={styles?.rotate} />;
      break;
    case "flip": 
      displayView = <FlipVideo />
      break;
    case "layer":
      displayView = <ZIndex />;
      break;
    case "animation":
      displayView = <ApplyAnimations />;
      break;
    default:
  }

  const onSelectBorder = () => {
    setOpenDrawer({status: true, type: "border", height: 150});
  };

  const onSelectOpacity = () => {
    setOpenDrawer({status: true, type: "opacity", height: 80});
  };

  const onSelectRotate = () => {
    setOpenDrawer({status: true, type: "rotate", height: 80});
  };

  const onSelectLayer = () => {
    setOpenDrawer({status: true, type: "layer", height: 80});
  };

  const onSelectAnimations = () => {
    setOpenDrawer({
      status: true,
      type: "animation",
      height: (window.innerHeight * 35) / 100,
    });
  };

  // () => updateEditingPanel(50, "blur")

  return (
    <>
      <Items>
        <Item onClick={onSelectBorder}>
          <Title>Border</Title>
        </Item>

        <Item>
          <FlipVideo />
        </Item>

        <Item onClick={onSelectOpacity}>
          <Title>Opacity</Title>
        </Item>

        <LockField />

        <Item onClick={onSelectRotate}>
          <Title>Rotate</Title>
        </Item>

        <Item onClick={onSelectLayer}>
          <Title>Layer</Title>
        </Item>

        <Item onClick={onSelectAnimations}>
          <Title>Animate</Title>
        </Item>

        {/* <Item
          style={{padding: "0 10px 3px 0"}}
          onClick={() => updateEditingPanel(0, "")}
        >
          <Title> <MobLockPosition /> </Title>
        </Item> */}
      </Items>

      <MobileDrawer
        open={openDrawer.status}
        height={openDrawer.height}
        onClose={handleDrawerClose}
      >
        <DrawerLayout>{displayView}</DrawerLayout>
      </MobileDrawer>
    </>
  );
};

const ColorSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
    activeField: selectActiveFieldProps(designTemplate),
    stableStyles: selectCachedFieldStyles(designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateEditingPanel,
  updateFieldStyles,
  updateFieldCollaboration,
})(VideoPanel);
