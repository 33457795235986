import styled from "styled-components";
import {primaryPurple, red4} from "../../../../oat-color-variables";

export const Button = styled.button`
  background: ${primaryPurple};
  outline: none;
  border: none;
  padding: 10px 22px;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
`;

export const HeaderDiv = styled.div``;

export const LibraryErrMsg = styled.div`
  padding: 5px;
  font-size: 1rem;
  border-radius: 4px;
  color: ${red4};
  text-align: right;
  margin-top: 8px;
`;

export const WrapSmallSpinner = styled.div`
  transform: scale(0.7);
  margin-right: 22px;
`;

export const WrapSpinner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 34px 0;
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: ${red4};
  padding: 12px;
  margin: 12px;
  background: #fbcdcd7d;
  border-radius: 8px;
`;
