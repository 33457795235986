import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Spinner} from "../../../../../../../../oat-ui";
import {fetchLibraryFontsSuccess} from "../../../../../../../store/actions/fields/common.action";
import styled from "styled-components";
import {Refresh} from "../../../../../../../../oat-svg-icons";
import {selectLibraryCustomFonts} from "../../../../../../../store/selectors/common.selector";
import {selectUserInfo} from "../../../../../../../../../redux/user/authUser";
import {selectWorkspaceID} from "../../../../../../../store/selectors/template/template.selector";
import DisplayCustomFont from "./DisplayCustomFont";
import { loadUsedFonts } from "../../../../../../../utils/loadUsedFonts";

const MyFontList = ({
  selected,
  fetchLibraryFontsSuccess,
  library,
  user,
  workspaceID,
  onSelectFont,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [displayFontList, setDisplayFontList] = useState(
    Object.values(library.fonts)
  );

  useEffect(() => {
    setDisplayFontList(Object.values(library.fonts))
  }, [library.fonts])

  const fetchFonts = () => {
    setLoading(true);
    fetch(
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/library/fonts/${workspaceID}?paginationToken=&fetchFolders=false`
    )
      .then((res) => res.json())
      .then((data) => {
        fetchLibraryFontsSuccess(data.response.fonts);
        setLoading(false);
        if (error) setError(false);
      })
      .catch((e) => {
        setError(true);
      });
  };

  const handleSelection = (item, fontWeight, fontStyle) => {
    const selectedFontType = item.types.find(
      (type) => type.fontWeight == fontWeight && type.fontStyle == fontStyle
    );
    const payload = {
      fontFamily: item.fontFamily,
      fontWeight,
      fontStyle,
      src: selectedFontType.src,
      format: selectedFontType.format,
      custom: true,
      fontType: selectedFontType.fontType, // for ref only to weight & style
    };
    onSelectFont(payload);
  };

  useEffect(() => {
    const updatedFonts = [];
    displayFontList && displayFontList.map(item => ({
      ...item,
      types: item.types && item.types.map(type => (
          updatedFonts.push({
            ...type,
            custom: true,
            fontFamily: item.fontFamily, 
          })
      )),
    }));
    loadUsedFonts(updatedFonts);
  }, [displayFontList])

  // useEffect(() => {
  //   fetchFonts()
  // }, [])

  return (
    <>
      {displayFontList &&
        displayFontList.map((item, i) => (
          <DisplayCustomFont
            key={i}
            item={item}
            onSelectItem={handleSelection}
            selected={selected}
          />
        ))}

      {!loading && displayFontList && displayFontList.length === 0 ? (
        <div style={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          marginBottom: 16
        }}>
          <EmptyFontMsg>
            No font available
          </EmptyFontMsg>
          <WrapSvg onClick={fetchFonts}>{Refresh}</WrapSvg>
        </div>
       ) : undefined}

      {loading ? (
        <WrapSpinner>
          <Spinner />
        </WrapSpinner>
      ) : undefined}

      {error ? (
        <Error>
          <p>Failed to load</p>
          <WrapSvg onClick={fetchFonts}>{Refresh}</WrapSvg>
        </Error>
      ) : undefined}
    </>
  );
};

const WrapSpinner = styled.div`
  transform: scale(0.8);
  padding-top: 42px;
  width: 100%;
`;

const Error = styled.div`
  color: ${({theme}) => theme.deskPrimaryTxt};
  font-size: 0.9rem;
  padding: 8px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WrapSvg = styled.div`
  width: 18px;
  height: 18px;
  margin-top: 12px;
  cursor: pointer;
  fill: ${({theme}) => theme.deskPrimaryTxt};
`;

const EmptyFontMsg = styled.div`
  font-size: 0.85rem;
  text-align: center;
  margin-top: 32px;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    user: selectUserInfo(state.authUser),
    workspaceID: selectWorkspaceID(designTemplate),
    library: selectLibraryCustomFonts(designTemplate),
  };
};

export default connect(mapStateToProps, {
  fetchLibraryFontsSuccess,
})(MyFontList);
