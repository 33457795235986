import {
  UPDATE_FIELD_POSITION,
  UPDATE_FIELD_SIZE,
  UPDATE_FIELD_STYLES,
  UPDATE_SINGLE_COLOR,
  ZINDEX_TO_FRONT,
  ZINDEX_TO_BACK,
  DESELECT_ACTIVE_FIELD,
  DUPLICATE_FIELD,
  REMOVE_FIELD,
  SET_ACTIVE_FIELD,
  COPY_FIELD,
  PASTE_FIELD,
  FETCH_LIBRARY_FONTS_SUCCESS,
  ADD_LIBRARY_FONT,
  SELECT_FONT_FAMILY,
  SELECT_FONT_TYPE,
  RESIZING_FIELD,
  DRAGGING_FIELD,
  CREATE_FIELD_FROM_SOCKET,
  PASTE_FIELD_FROM_SOCKET,
  UPDATE_MULTIPLE_FIELD_STYLES,
  RESET_FIELD_TO_EMIT_FROM_SOCKET,
  SET_FIELD_TO_ALIGN_POSITION,
  GROUP_FIELDS_AS_ONE,
  COLLABORATE_GROUP_FIELDS_AS_ONE,
  APPLY_LINK_IMAGE_ICON,
  ADD_LINK_TO_TEXT
} from "../actionTypes";

export const onSelectField = (fieldID, type) => {
  return {
    type: SET_ACTIVE_FIELD,
    payload: {fieldID, type},
  };
};

export const deselectActiveField = () => {
  return {
    type: DESELECT_ACTIVE_FIELD,
    index: -1,
  };
};

export const updateFieldStyles = ({active, value, type}) => {
  return {
    type: UPDATE_FIELD_STYLES,
    active,
    key: type,
    value,
  };
};

export const updateSingleColor = (color, key) => {
  return {
    type: UPDATE_SINGLE_COLOR,
    color,
    key,
  };
};

export const updateFieldPosition = ({active, payload}) => {
  return {
    type: UPDATE_FIELD_POSITION,
    active,
    payload,
  };
};

export const updateFieldSize = ({active, payload}) => {
  const {outerWidth, outerHeight, innerWidth, innerHeight, scale, rotate} =
    payload;
  return {
    type: UPDATE_FIELD_SIZE,
    active,
    size: {
      w: outerWidth,
      h: outerHeight,
      w2: innerWidth,
      h2: innerHeight,
    },
    ["styles"]: {
      scale: scale ? scale : 1,
      rotate,
    },
  };
};

export const zIndexToFront = (active, value) => {
  return {
    type: ZINDEX_TO_FRONT,
    active,
    value,
  };
};

export const zIndexToBack = (active, value) => {
  return {
    type: ZINDEX_TO_BACK,
    active,
    value,
  };
};

export const duplicateField = (active, newFieldKey) => {
  return {
    type: DUPLICATE_FIELD,
    active,
    newFieldKey,
  };
};

export const removeField = (active) => {
  return {
    type: REMOVE_FIELD,
    active,
  };
};

export const copyField = () => {
  return {
    type: COPY_FIELD,
  };
};

export const pasteField = (slideID, newFieldKey) => {
  return {
    type: PASTE_FIELD,
    slideID,
    newFieldKey,
  };
};

export const createFieldFromSocket = ({active, field}) => {
  return {
    type: CREATE_FIELD_FROM_SOCKET,
    active,
    field,
  };
};

export const pasteFieldFromSocket = ({active, payload}) => {
  return {
    type: PASTE_FIELD_FROM_SOCKET,
    active,
    payload,
  };
};

export function fetchLibraryFontsSuccess(payload) {
  return {
    type: FETCH_LIBRARY_FONTS_SUCCESS,
    payload,
  };
}

export function addLibraryFont(payload) {
  return {
    type: ADD_LIBRARY_FONT,
    payload,
  };
}

export function selectFontFamily(active, payload) {
  return {
    type: SELECT_FONT_FAMILY,
    active,
    payload,
  };
}

export function onSelectFontType(payload) {
  return {
    type: SELECT_FONT_TYPE,
    payload,
  };
}

export function resizingField({resizing, orig, direction}) {
  return {
    type: RESIZING_FIELD,
    resizing,
    orig,
    direction,
  };
}

export function draggingField({dragging, orig}) {
  return {
    type: DRAGGING_FIELD,
    dragging,
    orig,
  };
}

export function updateMultipleFieldStyles(active, payload) {
  return {
    type: UPDATE_MULTIPLE_FIELD_STYLES,
    active,
    payload,
  };
}

export function resetFieldToEmitFromSocket() {
  return {
    type: RESET_FIELD_TO_EMIT_FROM_SOCKET,
  };
}

export function setFieldToAlignPosition({active, payload}) {
  return {
    type: SET_FIELD_TO_ALIGN_POSITION,
    active,
    payload,
  };
}

export function groupFieldsAsOne() {
  return {
    type: GROUP_FIELDS_AS_ONE,
    // active,
    // keys
  };
}

export function collaborateGroupFieldsAsOne({active, payload}) {
  return {
    type: COLLABORATE_GROUP_FIELDS_AS_ONE,
    active,
    payload
  };
}

export const applyLinkToImageIcon = ({active, link}) => {
  return {
    type: APPLY_LINK_IMAGE_ICON,
    payload: {active, link},
  };
};

export function addLinkToField({active, payload}) {
  return {
    type: ADD_LINK_TO_TEXT,
    active,
    payload,
  };
}