import React from "react";
import styled from "styled-components";
import {HorizontalFlip, VerticalFlip} from "../../../../../../ui/svg/Flip";
import {Title} from "../../shared/reuseStyles/Title";
import {connect} from "react-redux";
import {selectActiveSlide} from "../../../../../../store/selectors/template/slide.selector";
import {transformImgOffset} from "../../../../../../store/actions/fields/image.action";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const FlipImg = ({
  scaleX,
  scaleY,
  activeField,
  slideID,
  transformImgOffset,
  storeFieldUndoRedo,
  updateFieldStyles,
  updateFieldCollaboration,
}) => {
  const handleOffset = (flipDir) => {
    if (flipDir === "horizontal") {
      const calcX =
        activeField?.size?.w + -activeField?.imgPos?.x - activeField?.size?.w2;
      onFlipImage({
        type: "scaleX",
        value: scaleX === 1 ? -1 : 1,
        offset: {
          x: calcX,
          y: activeField?.imgPos?.y,
        },
      });
    } else if (flipDir === "vertical") {
      const calcY =
        activeField?.size?.h + -activeField?.imgPos?.y - activeField?.size?.h2;
      onFlipImage({
        type: "scaleY",
        value: scaleY === 1 ? -1 : 1,
        offset: {
          x: activeField?.imgPos?.x,
          y: calcY,
        },
      });
    }
  };

  const flipHorizontal = () => {
    handleOffset("horizontal");
  };

  const flipVertical = () => {
    handleOffset("vertical");
  };

  const onFlipImage = async ({type, value, offset}) => {
    updateFieldStyles({
      active: {slideID, fieldID: activeField?.key},
      type,
      value,
    });

    const imgOffset = {x: offset.x, y: offset.y};
    const active = {slideID, fieldID: activeField?.key};
    transformImgOffset(active, imgOffset);
    await storeFieldUndoRedo();

    await updateFieldCollaboration();
  };

  return (
    <Wrapper>
      <Title>Flip</Title>
      <WrapIcons>
        <div onClick={flipHorizontal}>
          <HorizontalFlip fill="#b2b2b2" />
        </div>
        <div onClick={flipVertical}>
          <VerticalFlip fill="#b2b2b2" />
        </div>
      </WrapIcons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const WrapIcons = styled.div`
  display: flex;
  flex: 1;
  height: 20px;
  div {
    &:first-child {
      margin-right: 14px;
    }
    cursor: pointer;
  }
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    slideID: selectActiveSlide(designTemplate),
  };
};

export default connect(mapStateToProps, {
  storeFieldUndoRedo,
  transformImgOffset,
  updateFieldStyles,
  updateFieldCollaboration,
})(FlipImg);
