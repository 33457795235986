import React, {useState, useRef, useEffect, useContext} from "react";
import {detectClickOutside} from "../../../../../../../oat-ui";
import DeskColorOptions from "../../../../editingPanel/panelTypes/shared/colorPicker/DeskColorOptions";
import {PopperLayout} from "../reuseTooltipStyles";
import {tooltipContext} from "../Tooltip";
import {Bouncy} from "../../../../../../../oat-ui/animation/bouncy";
import styled from "styled-components";
import { connect } from "react-redux";
// import { selectActiveFieldProps } from "../../../../../../store/selectors/fields.selector";
// import { selectTempScale } from "../../../../../../store/selectors/template/template.selector";

const TooltipColorPickerHoc = ({
  activeColor,
  onUpdate,
  onDebounce,
  onClose,
  // activeFieldSize,
  // tempScale
}) => {
  const [offset, setOffset] = useState({x: -20, y: 50});
  const popUpRef = useRef(null);
  const {
    offset: tooltipOffset,
    size: tooltipSize,
    tooltipAboveField,
  } = useContext(tooltipContext);

  detectClickOutside(popUpRef, () => onClose());

  useEffect(() => {
    if (popUpRef?.current) {
      const {offsetWidth, offsetHeight} = popUpRef?.current;
      const overflowHeight =
        tooltipOffset.y + offsetHeight - window.innerHeight;
      const overflowing =
        tooltipOffset.y + tooltipSize.height + offsetHeight > window.innerHeight
          ? true
          : false;
      const overflowingWidth =
        tooltipOffset.x + offsetWidth < 0
          ? true
          : false;
      if (overflowing) {
        setOffset({
          x: -offsetWidth + 24,
          y: 0 - offsetHeight + tooltipSize.height,
        });
      } else if (tooltipAboveField) {
          setOffset({
            x: -offsetWidth + 60,
            y: 50,
          });
      } else {
        setOffset({
          x: 0,
          y: 50,
        });
      }
    }
  }, [popUpRef.current]);

  const handleUpdate = (value) => {
    onUpdate(value);
    // clearTimeout(delayDebounce);
    // delayDebounce = setTimeout(() => {
    //   onDebounce(value);
    // }, 3500);
    // return () => clearTimeout(delayDebounce);
  };

  const handleDebounce = (value) => {
    // storeBackgroundUndoRedo("bg1", value);
    // handleSocketEmit("bg1", value);
    onDebounce(value);
  };

  return (
    <PopperLayout
      width={260}
      ref={popUpRef}
      style={{
        transform: `translate(${offset?.x - 20}px, ${offset?.y}px)`,
      }}
    >
      <Wrapper>
        <DeskColorOptions
          activeColor={activeColor}
          updateFieldStyles={handleUpdate}
          onClose={onClose}
          useInTooltip={true}
          onSelectionEnd={handleDebounce}
        />
      </Wrapper>
    </PopperLayout>
  );
};

const Wrapper = styled.div`
  ${Bouncy};
`;

// const mapStateToProps = (state) => {
//   const {designTemplate} = state;
//   return {
//     activeFieldSize: selectActiveFieldProps(designTemplate)?.size,
//     tempScale: selectTempScale(designTemplate)
//   };
// };

export default connect(null, null)(TooltipColorPickerHoc);
