import {css} from "styled-components";

export const TooltipStyles = css`
  position: absolute;
  height: 25px;
  background: #2b2848; // #141422e3;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
  display: none;
  font-size: 0.78rem;
  font-weight: 700;
  color: #fff;
  z-index: 9999;
`;
