import React from "react";
import styled, {css} from "styled-components";
import {black2} from "../../../oat-color-variables";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {
  defaultMaxWidthDesktop,
  defaultMaxWidthLaptop,
  layoutPadding,
} from "../layoutVariables";
import {HomeHeader} from "./FeaturesShowcase2";
import {homePageBackground2} from "./styles/layoutStyles";

const FeatureList = () => {
  return (
    <Layout style={{background: homePageBackground2}}>
      <Layer>
        <WrapHeader>
          <AnimateIn>
            <HomeHeader>More Features</HomeHeader>
          </AnimateIn>
        </WrapHeader>
        <Wrapper>
          <AnimateIn>
            <Item index={1} style={{background: "transparent"}}>
              <WrapImage>
                <img
                  src={
                    "https://oat-default-init.s3.amazonaws.com/home-page/branded-theme-diamond-480.png"
                  }
                  alt="stay-on-brand"
                  loading="lazy"
                />
              </WrapImage>

              <WrapContent>
                <h3>Branded Themes</h3>
                <p>Apply brand themes to stay consistent & professional</p>
              </WrapContent>
            </Item>
          </AnimateIn>

          <AnimateIn>
            <Item index={2} style={{background: "transparent"}}>
              <WrapImage>
                <img
                  src={
                    "https://dnaeh50kru9rd.cloudfront.net/home-page/resize-templates-icon-480.png"
                  }
                  alt="resize-template"
                  loading="lazy"
                />
              </WrapImage>

              <WrapContent>
                <h3>Resize Template</h3>
                <p>Convert to any desirable size in a snap</p>
              </WrapContent>
            </Item>
          </AnimateIn>

          <AnimateIn>
            <Item index={3} style={{background: "transparent"}}>
              <WrapImage>
                <img
                  src={
                    "https://oat-default-init.s3.amazonaws.com/home-page/bg-removal-480.png"
                  }
                  alt="background-removal"
                  loading="lazy"
                />
              </WrapImage>

              <WrapContent>
                <h3>Background Removal</h3>
                <p>Cut out backgrounds from your images</p>
              </WrapContent>
            </Item>
          </AnimateIn>
        </Wrapper>
      </Layer>
    </Layout>
  );
};

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 601px) {
    padding: 3.8rem ${layoutPadding.desktop} 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 2em ${layoutPadding.mobile};
    margin-bottom: 0em;
    padding-top: 35px;
  }
`;

const Layer = styled.div`
  margin-bottom: 44px;
  @media only screen and (max-width: 849px) {
    margin-bottom: 70px;
    width: 100%; // !important;
  }
`;

export const maxWidthPanel = css`
  max-width: ${defaultMaxWidthLaptop}px;
  margin: 0 auto;
  @media only screen and (min-width: 1000px) and (max-width: 1179px) {
    max-width: 100%;
  }
  @media only screen and (min-width: 1180px) and (max-width: 1500px) {
    max-width: ${defaultMaxWidthLaptop}px;
  }
  @media only screen and (min-width: 1501px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
`;
export const WrapHeader = styled.div`
  ${maxWidthPanel};
  text-align: left;
  margin-bottom: 34px;
  @media only screen and (max-width: 800px) {
    margin-bottom: 12px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  // grid-column-gap: 20px;
  // grid-row-gap: 20px;
  ${maxWidthPanel};
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
`;

export const Item = styled.div`
  // margin-bottom: 1.6rem;
  text-align: center;
  padding: 32px;
  width: 100%;
  h3 {
    font-size: 1.02rem;
    font-weight: 700;
    margin-top: 14px;
    color: ${black2};
  }
  p {
    font-size: 0.98rem;
    margin-top: 8px;
    color: ${black2};
  }
  @media only screen and (min-width: 801px) {
    min-height: 324px;
    border-top-left-radius: ${({index}) => (index === 1 ? "12px" : undefined)};
    border-bottom-left-radius: ${({index}) =>
      index === 1 ? "12px" : undefined};
    border-top-right-radius: ${({index}) => (index === 3 ? "12px" : undefined)};
    border-bottom-right-radius: ${({index}) =>
      index === 3 ? "12px" : undefined};
  }
  @media only screen and (max-width: 1000px) {
    min-height: 320px;
  }
  @media only screen and (max-width: 800px) {
    border-radius: 12px;
    min-height: 0px;
  }
`;

const WrapImage = styled.div`
  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

export const WrapContent = styled.div`
  width: 100%;
`;

export default FeatureList;
