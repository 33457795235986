import React, {useState} from "react";
import {connect} from "react-redux";
import {selectThemeBuilder} from "../../../store/selectors/template/theme.selector";
import {InputName} from "../../editDesign/editingPanel/panelTypes/desktop/common/WrapColorStyles";
import PopoverColorBox from "../../editDesign/editingPanel/panelTypes/shared/colorPicker/PopoverColorBox";
import {
  updateThemeBuilderFields,
  applyToAllTextType,
} from "../../../store/actions/template/theme.action";
import ThemeBuilderFontFamily from "./reusable/ThemeBuilderFontFamily";
import DropDown from "../../../ui/inputs/DropDown";
import RangeSlider from "../../editDesign/editingPanel/panelTypes/shared/inputs/RangeSlider";
import {textEditProps} from "../../editDesign/editingPanel/panelTypes/desktop/text/EditText";
import styled from "styled-components";
import { Radio, RadioButton } from "../../../../oat-ui/styles/RadioStyles";

const textTypes = [
  {value: "heading", display: "Heading"},
  {value: "subheading", display: "Subheading"},
  {value: "normalText", display: "Normal Text"},
  {value: "smallText", display: "Small Text"},
  // {value: "button", display: "Button"},
  {value: "bulletContent", display: "Bullet Content"},
  {value: "listContent", display: "List Content"},
  // {value: "link", display: "Link"},
];
const TextThemeBuilder = ({
  themeInfo,
  updateThemeBuilderFields,
  applyToAllTextType,
}) => {
  const [selectedFontType, setSelectedFontType] = useState("heading");
  const [applyType, setApplyType] = useState("select-particular-type");

  const onChangeFontType = (value) => {
    setSelectedFontType(value);
  };

  const handleUpdate = (category, key, value) => {
    if (applyType === "select-particular-type") {
      updateThemeBuilderFields({
        category,
        key,
        value,
      });
    } else {
      applyToAllTextType({
        key,
        value,
      });
    }
  };

  const onUpdateFontColor = (value) => {
    handleUpdate(selectedFontType, "color1", value);
  };

  const onSelectApplyType = (value) => {
    setApplyType(value)
  }

  const onUpdateTextFontFamily = (item) => {
    handleUpdate(selectedFontType, "fontFamily", item.fontFamily);
    handleUpdate(selectedFontType, "src", item.src);
    if (item.custom) handleUpdate(selectedFontType, "custom", item.custom);
    if (item.format) {
      handleUpdate(selectedFontType, "format", item.format);
    }
  };

  const onUpdateLetterSpacing = (value) => {
    handleUpdate(selectedFontType, "letterSpacing", Number(value));
  };

  const onUpdateLineHeight = (value) => {
    handleUpdate(selectedFontType, "lineHeight", Number(value));
  };

  const onUpdateButtonBg = (value) => {
    updateThemeBuilderFields({
      category: "button",
      key: "background",
      value,
    });
  };

  const onUpdateButtonColor = (value) => {
    updateThemeBuilderFields({
      category: "button",
      key: "color1",
      value,
    });
  };

  const onUpdateOrderedBulletBg = (value) => {
    updateThemeBuilderFields({
      category: "listContentWithBackground",
      key: "bg1",
      value,
    });
  };

  const onUpdateBulletColor = (value) => {
    updateThemeBuilderFields({
      category: "bulletContent",
      key: "bulletColor",
      value,
    });
  }

  const handleDebounceColor = () => {
    // leave empty
  };

  return themeInfo[selectedFontType] ? (
    <>
      <div style={{display: "flex", alignItems: 'flex-start', paddingBottom: "2em", marginTop: 22}}>
        <InputName>Apply To</InputName>
        <WrapRadio>
            <Radio onClick={() => onSelectApplyType("select-particular-type")}>
              <RadioButton active={applyType === "select-particular-type"}>
                <div />
              </RadioButton>
              <InputName>Selected Type</InputName>
            </Radio>
            <Radio onClick={() => onSelectApplyType("all-text-type")}>
            <RadioButton active={applyType === "all-text-type"}>
              <div />
            </RadioButton>
            <InputName>All Text Type</InputName>
          </Radio>
        </WrapRadio>
      </div>

      {applyType === "select-particular-type" ? 
        <div style={{paddingBottom: "2em", display: "flex"}}>
          <InputName>Text Type</InputName>
          <div style={{display: "flex", flex: 1}}>
            <DropDown
              onSelect={onChangeFontType}
              selected={selectedFontType}
              options={textTypes}
              overflowY="scroll"
              minWidth={160}
              boxWidth={160}
              height={210}
            />
          </div>
        </div> 
      : undefined}

      <div style={{display: "flex", paddingBottom: "2em"}}>
        <InputName>Color</InputName>
        <PopoverColorBox
          value={themeInfo[selectedFontType].color1}
          updateFieldStyles={onUpdateFontColor}
          onDebounce={handleDebounceColor}
        />
      </div>
      <div style={{paddingBottom: "2rem"}}>
        <ThemeBuilderFontFamily
          fontFamily={themeInfo[selectedFontType].fontFamily}
          fontWeight={themeInfo[selectedFontType].fontWeight}
          fontStyle={themeInfo[selectedFontType].fontStyle}
          onChange={onUpdateTextFontFamily}
        />
      </div>
      <div style={{paddingBottom: "2rem"}}>
        <RangeSlider
          title="Letter Spacing"
          fieldType="letterSpacing"
          value={themeInfo[selectedFontType].letterSpacing}
          max={textEditProps.letterSpacing.max}
          min={textEditProps.letterSpacing.min}
          step={textEditProps.letterSpacing.step}
          onItemChange={onUpdateLetterSpacing}
        />
      </div>
      <div style={{paddingBottom: "2rem"}}>
        <RangeSlider
          title="Line Height"
          fieldType="lineHeight"
          value={themeInfo[selectedFontType].lineHeight}
          max={textEditProps.lineHeight.max}
          min={textEditProps.lineHeight.min}
          step={textEditProps.lineHeight.step}
          onItemChange={onUpdateLineHeight}
        />
      </div>
      
      {/* <div style={{display: "flex", paddingBottom: "2em"}}>
        <InputName>Button Background</InputName>
        <PopoverColorBox
          value={themeInfo["button"]?.background}
          updateFieldStyles={onUpdateButtonBg}
          onDebounce={handleDebounceColor}
        />
      </div>

      <div style={{display: "flex", paddingBottom: "1em"}}>
        <InputName>Button Color</InputName>
        <PopoverColorBox
          value={themeInfo["button"]?.color1}
          updateFieldStyles={onUpdateButtonColor}
          onDebounce={handleDebounceColor}
        />
      </div> */}
     
      <Message>
        Shadow effects will only apply if the option is enabled.
      </Message>

      {applyType === "all-text-type" || selectedFontType === "listContent" ? 
        <div style={{display: "flex", padding: "1em 0"}}>
          <InputName>List Content Background</InputName>
          <PopoverColorBox
            value={themeInfo["listContentWithBackground"]?.bg1}
            updateFieldStyles={onUpdateOrderedBulletBg}
            onDebounce={handleDebounceColor}
          />
        </div>
      : undefined}

      {applyType === "all-text-type" || selectedFontType === "bulletContent" ?
        <div style={{display: "flex", padding: "1em 0"}}>
          <InputName>Bullet Color</InputName>
          <PopoverColorBox
            value={themeInfo["bulletContent"]?.bulletColor}
            updateFieldStyles={onUpdateBulletColor}
            onDebounce={handleDebounceColor}
          />
        </div> 
      : undefined}
    </>
  ) : undefined;
};

const Message = styled.span`
  font-size: 0.85rem;
  font-style: italic;
  padding-left: 16px;
  color: ${({theme}) => theme.deskSecondaryTxt2};
`;

const WrapRadio = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const mapStateToProps = (state) => {
  return {
    themeInfo: selectThemeBuilder(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  updateThemeBuilderFields,
  applyToAllTextType,
})(TextThemeBuilder);
