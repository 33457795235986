import {
  CREATE_TABLE_COLUMN,
  CREATE_TABLE_ROW,
  REMOVE_TABLE_ROW,
  REMOVE_TABLE_COLUMN,
  UPDATE_TABLE_CONTENT,
  RESIZE_TABLE_COLUMN_CELL,
  UPDATE_TABLE_HEIGHT,
  RESIZE_TABLE,
  SET_INIT_CELL_SIZES,
  REMOVE_INIT_CELL_SIZES,
  UPDATE_TABLE_SIZE,
  CREATE_TABLE,
  RESIZE_TABLE_ROW_CELL,
  BATCH_UPDATE_TABLE_ROW_HEIGHT,
  TOGGLE_TABLE_ROWS_COLUMNS_DRAWER,
  ACTIVE_TABLE_CELL,
  CLONE_TABLE_ROW_COLUMN,
} from "../actionTypes";

export const createTable = (slideID, key, size, selectFieldOnCreate = true) => {
  return {
    type: CREATE_TABLE,
    key,
    slideID,
    size,
    selectFieldOnCreate,
  };
};

export const createTableColumn = (active, colIndex, colID) => {
  return {
    type: CREATE_TABLE_COLUMN,
    active,
    colID,
    colIndex,
  };
};

export const createTableRow = (active, rowIndex, rowID) => {
  return {
    type: CREATE_TABLE_ROW,
    active,
    rowID,
    rowIndex,
  };
};

export const removeTableRow = (active, id) => {
  return {
    type: REMOVE_TABLE_ROW,
    active,
    id,
  };
};

export const removeTableColumn = (active, index) => {
  return {
    type: REMOVE_TABLE_COLUMN,
    active,
    index,
  };
};

export const resizeTableActions = ({newTableSize}) => {
  return {
    type: RESIZE_TABLE,
    newTableSize,
  };
};

export const dispatchInitCellSizes = () => {
  return {
    type: SET_INIT_CELL_SIZES,
  };
};

export const removeInitCellSizes = () => {
  return {
    type: REMOVE_INIT_CELL_SIZES,
  };
};

export const updateTableContent = ({
  active,
  content,
  rowIndex,
  columnIndex,
  rowHeight,
  tableHeight,
}) => {
  return {
    type: UPDATE_TABLE_CONTENT,
    active,
    content,
    rowIndex,
    columnIndex,
    rowHeight, // total number of line height
    tableHeight,
  };
};

export const resizeTableColumnCell = ({
  active,
  colIndex,
  payload,
  direction,
  sizeDiff,
  initTableSize,
}) => {
  return {
    type: RESIZE_TABLE_COLUMN_CELL,
    active,
    colIndex,
    payload,
    direction,
    sizeDiff,
    initTableSize,
  };
};

export const dispatchTableHeight = (active, payload) => {
  return {
    type: UPDATE_TABLE_HEIGHT,
    active,
    payload,
  };
};

export const dispatchTableSize = ({width, height}) => {
  return {
    type: UPDATE_TABLE_SIZE,
    width,
    height,
  };
};

export const resizeTableRowCell = ({
  slideID,
  fieldID,
  row,
  addedHeight,
  initTableSize,
}) => {
  return {
    type: RESIZE_TABLE_ROW_CELL,
    slideID,
    fieldID,
    row,
    addedHeight,
    initTableSize,
  };
};

export const batchUpdateTableRowHeight = ({
  slideID,
  fieldID,
  row,
  tableHeight,
}) => {
  return {
    type: BATCH_UPDATE_TABLE_ROW_HEIGHT,
    slideID,
    fieldID,
    row,
    tableHeight,
  };
};

export const toggleTableRowsColumnsDrawer = (type, index, id) => {
  return {
    type: TOGGLE_TABLE_ROWS_COLUMNS_DRAWER,
    selectedType: type, // row or column
    index,
    id,
  };
};

export const setActiveTableCell = ({row, column}) => {
  return {
    type: ACTIVE_TABLE_CELL,
    row,
    column,
  };
};

export const cloneTableRowColumn = ({active, cloneType, index}) => {
  return {
    type: CLONE_TABLE_ROW_COLUMN,
    active,
    cloneType,
    index,
  };
};
