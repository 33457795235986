import React, { useState } from 'react';
import styled from 'styled-components'
import { blue3 } from '../../../../oat-color-variables';

const CookiePolicyNoti = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    localStorage.setItem('cookiePolicyAccepted', 'true'); // Store in local storage
  };

  if (accepted || localStorage.getItem('cookiePolicyAccepted')) {
    return null; 
  }

  return (
    <Box>
      <p>This website uses cookies. By continuing to use this site, you accept our cookie policy.</p>
      <button onClick={handleAccept}>Accept</button>
    </Box>
  );
};

const Box = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 420px;
  height: 160px;
  background: #fff;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 16px;
  color: #232323;
  box-shadow: rgb(157 158 235) 0px 0px 1px 0px;
  button {
    height: 32px;
    border-radius: 6px;
    margin: 22px 0 32px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background: ${blue3};
    z-index: 55;
    border: none;
    padding: 5px 18px;
    cursor: pointer;
    text-decoration: none;
  }
  @media only screen and (max-width: 600px) {
    width: 320px;
    height: auto;
    padding: 20px;
    font-size: 15px;
    button {
      font-size: 15px;
      margin: 12px 0 0px;
    }
  }
`;


export default CookiePolicyNoti;
