const SvgClose = (
  <svg viewBox="0 0 24 24">
    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
  </svg>
  // <svg
  //   width="100%"
  //   viewBox="0 0 413.348 413.348"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path d="M413.348 24.354L388.994 0l-182.32 182.32L24.354 0 0 24.354l182.32 182.32L0 388.994l24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
  // </svg>
);

export default SvgClose;
