import React from "react";
import styled from "styled-components";
import {requiresUpgradeToProStyles} from "../../../parts/upgradeToPro";
import {selectWorkspaceID, selectWorkspacePlan} from "../../../../../store/selectors/template/template.selector";
import {connect} from "react-redux";

const DisplayOption = ({option, onSelect, workspacePlan, workspaceID}) => {
  const handleClick = () => {
    onSelect(option);
  };

  return (
    <Option onClick={handleClick}>
      <div>{option.display}</div>
      {option.upgradeRequires && workspacePlan === "free" ? (
        <RequiresUpgradeToPro href={`/pricing?workspaceID=${workspaceID}`} target="_blank">Pro</RequiresUpgradeToPro>
      ) : undefined}
    </Option>
  );
};

const options = [
  // {
  //   value: "slide-animation",
  //   display: "Slide Animation",
  //   upgradeRequires: false,
  // },
  {
    value: "resize-template",
    display: "Resize Template",
    upgradeRequires: true,
  },
  {value: "notification", display: "Notification", upgradeRequires: false},
  // {value: "add-mobile-view", display: "Add Mobile View"},
  // {value: "content-grouping", display: "Content Grouping"},
];

const SelectSlideOptions = ({workspacePlan, workspaceID, onSelect}) => {
  const handleSelection = (option) => {
    onSelect(option.value);
  };

  return options.map((option, i) => (
    <DisplayOption
      key={i}
      option={option}
      workspacePlan={workspacePlan}
      workspaceID={workspaceID}
      onSelect={handleSelection}
    />
  ));
};

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 6px;
  box-sizing: border-box;
  &:hover {
    background: ${({theme}) => theme.deskSecondaryBg};
  }
`;

const RequiresUpgradeToPro = styled.a`
  ${requiresUpgradeToProStyles}
`;

const mapStateToProps = (state) => {
  return {
    workspacePlan: selectWorkspacePlan(state.designTemplate),
    workspaceID: selectWorkspaceID(state.designTemplate),
  };
};

export default connect(mapStateToProps, null)(SelectSlideOptions);
