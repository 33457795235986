import React from "react";
import {Content} from "./../Tooltip";
import {connect} from "react-redux";
import {selectActiveFieldProps} from "../../../../../../store/selectors/fields.selector";
import {SelectedColor, TooltipLayout} from "./../reuseTooltipStyles";
import TooltipColorPickerHoc from "../tooltipHocs/TooltipColorPickerHoc";
import {toggleTooltipPopper} from "../../../../../../store/actions/layout.action";
import {updateFieldStyles} from "../../../../../../store/actions/fields/common.action";
import {fontSizes} from "../../../../editingPanel/panelTypes/desktop/common/SelectFontSize";
import TooltipSelectionHoc from "../tooltipHocs/TooltipSelectionHoc";
import TooltipItemsHoc from "../tooltipHocs/TooltipItemsHoc";
import {storeFieldUndoRedo} from "../../../../../../store/actions/fields/undoRedo.action";
import TooltipFontFamily from "../tooltipHocs/TooltipFontFamily";
import {updateFieldCollaboration} from "../../../../../../store/actions/collaboration/collaboration.action";

const rowTypes = [
  {display: "Header", value: "header"},
  {display: "Body", value: "body"},
];

const TableTooltip = ({
  field,
  active,
  popperOpen,
  toggleTooltipPopper,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const {styles} = field;

  const handleColorChange = (value) => {
    updateFieldStyles({active, type: "headerColor", value});
    updateFieldStyles({active, type: "color", value});
  };

  const handleStyleUpdate = (type, value) => {
    updateFieldStyles({active, type, value});
    updateFieldCollaboration();
    storeFieldUndoRedo();
  };

  const changeFontSize = ({value}) => {
    handleStyleUpdate("fontSize", Number(value / styles.scale));
  };

  const changeFontFamily = (item) => {
    handleStyleUpdate("fontFamily", item.fontFamily);
  };

  const displayColorPicker = () => {
    toggleTooltipPopper("color-picker");
  };

  const openFontSizeList = (e) => {
    e.stopPropagation();
    toggleTooltipPopper("font-size");
  };

  const openFontFamilyList = (e) => {
    e.stopPropagation();
    toggleTooltipPopper("font-family");
  };

  const closePopper = () => {
    toggleTooltipPopper();
  };

  const handleDebounceValue = (value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <TooltipLayout>
      <TooltipItemsHoc>
        <Content onMouseDown={openFontSizeList}>
          <p>{Number((styles?.fontSize * styles?.scale).toFixed(1))}</p>
        </Content>

        <Content onMouseDown={openFontFamilyList}>
          <p
            style={{
              maxWidth: 65,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {styles?.fontFamily}
          </p>
        </Content>

        <Content onMouseDown={displayColorPicker}>
          <SelectedColor color={styles?.color} />
        </Content>
      </TooltipItemsHoc>

      {popperOpen.status && popperOpen.type === "font-size" ? (
        <TooltipSelectionHoc
          activeItem={styles?.fontSize}
          options={fontSizes}
          onChange={changeFontSize}
          offset={{x: 40, y: 50}}
          width={65}
          onClose={closePopper}
        />
      ) : undefined}

      {popperOpen.status && popperOpen.type === "font-family" ? (
        <TooltipFontFamily
          offset={{x: 40, y: 50}}
          width={160}
          height={200}
          fontFamily={styles?.fontFamily}
          fontWeight={styles?.fontFamily}
          fontStyle={styles?.fontFamily}
          onChange={changeFontFamily}
          onClose={closePopper}
        />
      ) : undefined}

      {popperOpen.status && popperOpen.type === "color-picker" ? (
        <TooltipColorPickerHoc
          onClose={closePopper}
          activeColor={styles?.color}
          onUpdate={handleColorChange}
          onDebounce={handleDebounceValue}
        />
      ) : undefined}
    </TooltipLayout>
  );
};

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    field: selectActiveFieldProps(designTemplate),
  };
};

export default connect(mapStateToProps, {
  toggleTooltipPopper,
  updateFieldStyles,
  storeFieldUndoRedo,
  updateFieldCollaboration,
})(TableTooltip);
