import React from "react";
import TemplateList from "../../components/dashboardArea/templates/TemplateList";
import DashboardPanel from "../../components/dashboardArea/DashboardPanel";
import Hoc from "../../hoc/Hoc";
import {useParams} from "react-router-dom";
import LayoutsRatio from "../../components/layout/folders/LayoutsRatio";
import Categories from "../../components/layout/folders/Categories";
import AdminSidebar from "../../components/layout/AdminSidebar";

const Dashboard = () => {
  let params = useParams();
  const {category} = params;
  return (
    <Hoc>
      <AdminSidebar>
        {category === "layouts" ? <LayoutsRatio /> : <Categories />}
      </AdminSidebar>
      <DashboardPanel>
        <TemplateList />
      </DashboardPanel>
    </Hoc>
  );
};

export default Dashboard;
