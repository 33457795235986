import React from "react";
import styled, {css} from "styled-components";
import {connect} from "react-redux";
import {createShape} from "../../../../../store/actions/fields/shape.action";
import SelectedShape from "../../../canvasPanel/fields/shapes/SelectedShape";
import {storeActionsUndoRedo} from "../../../../../store/actions/fields/undoRedo.action";
import {selectActiveSlide} from "../../../../../store/selectors/template/slide.selector";
import {createShortKey} from "../../../../../store/reducers/functions/fields/utils";
import {closeDrawer} from "../../../../../store/actions/layout.action";
import {selectPageTheme} from "../../../../../store/selectors/layout/layout.selector";

export const shapeStyles = {
  enableGradient: false,
  gradientAngle: 0,
  opacity: 1,
  rotate: 0,
  enableStroke: false,
  strokeWidth: 0,
  blur: 0,
};

export const lineStyles = {
  opacity: 1,
  rotate: 0,
  enableStroke: false,
  strokeWidth: 0,
};

const items = [
  {
    id: "av228ad3b",
    name: "line",
    subtype: "line",
    lineType: "line",
    color1: "#94bbf8ff",
    color2: "#94bbf8ff",
    size: {
      w: 80,
      h: 20,
    },
    percentageOfSize: {
      w: 40,
      h: 2,
    },
  },
  {
    id: "bs621td4t",
    name: "line",
    subtype: "line",
    lineType: "line-with-right-arrow",
    color1: "#94bbf8ff",
    color2: "#94bbf8ff",
    size: {
      w: 80,
      h: 20,
    },
    percentageOfSize: {
      w: 40,
      h: 2,
    },
  },
  {
    id: "mn711sc4a",
    name: "line",
    subtype: "line",
    lineType: "line-with-left-arrow",
    color1: "#94bbf8ff",
    color2: "#94bbf8ff",
    size: {
      w: 80,
      h: 20,
    },
    percentageOfSize: {
      w: 40,
      h: 2,
    },
  },
  {
    id: "vn411sc9a",
    name: "line",
    subtype: "line",
    lineType: "line-with-arrows",
    color1: "#94bbf8ff", // "#94bbf8ff",
    color2: "#94bbf8ff", // "#94bbf8ff",
    size: {
      w: 80,
      h: 20,
    },
    percentageOfSize: {
      w: 40,
      h: 2,
    },
  },
  {
    id: "bs621td4t",
    name: "line",
    subtype: "line",
    lineType: "line-with-right-circle",
    color1: "#94bbf8ff",
    color2: "#94bbf8ff",
    size: {
      w: 80,
      h: 20,
    },
    percentageOfSize: {
      w: 40,
      h: 2,
    },
  },
  {
    id: "mn711sc4a",
    name: "line",
    subtype: "line",
    lineType: "line-with-left-circle",
    color1: "#94bbf8ff",
    color2: "#94bbf8ff",
    size: {
      w: 80,
      h: 20,
    },
    percentageOfSize: {
      w: 40,
      h: 2,
    },
  },
  {
    id: "vn411sc9a",
    name: "line",
    subtype: "line",
    lineType: "line-with-circles",
    color1: "#94bbf8ff", // "#94bbf8ff",
    color2: "#94bbf8ff", // "#94bbf8ff",
    size: {
      w: 80,
      h: 20,
    },
    percentageOfSize: {
      w: 40,
      h: 2,
    },
  },
  {
    id: "kt771scb7",
    name: "circle",
    subtype: "shape",
    color1: "#94bbf8ff", //"#94bbf8ff",
    color2: "",
    size: {
      w: 80,
      h: 80,
    },
    // ratio will be used for determing the size based on the canvas's width
    // for instance value 20 represents a value of full width canva's (100%), which is 20%
    // in percentage
    percentageOfSize: {
      w: 20,
      h: 20,
    },
    ["styles"]: {
      // strokeOnly: true,
      strokeWidth: 5,
      enableStroke: true,
      color1: "transparent",
    },
  },
  {
    id: "kn771sc7a",
    name: "circle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 80,
      h: 80,
    },
    // ratio will be used for determing the size based on the canvas's width
    // for instance value 20 represents a value of full width canva's (100%), which is 20%
    // in percentage
    percentageOfSize: {
      w: 20,
      h: 20,
    },
  },
  {
    id: "vh381pc0a",
    name: "radial-gradient-circle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "#6545fbc9",
    size: {
      w: 80,
      h: 80,
    },
    // ratio will be used when creating based on the canvas's width
    // for instance value 20 represents a value of full width canva's (100%), which is 20%
    // in percentage
    percentageOfSize: {
      w: 20,
      h: 20,
    },
  },
  {
    // ellipse
    id: "nnc889sc9a",
    name: "circle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 120,
      h: 80,
    },
    percentageOfSize: {
      w: 30,
      h: 20,
    },
    ["styles"]: {
      // strokeOnly: true,
      strokeWidth: 5,
      enableStroke: true,
      color1: "transparent",
    },
  },
  {
    // ellipse
    id: "bht689yc4a",
    name: "circle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 120,
      h: 80,
    },
    percentageOfSize: {
      w: 30,
      h: 20,
    },
  },
  {
    // square
    id: "vt111gc5a",
    name: "rectangle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 20,
      h: 20,
    },
    ["styles"]: {
      borderRadius: 0,
    },
  },
  {
    id: "aa311tc9a",
    name: "rectangle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
    ["styles"]: {
      borderRadius: 12,
    },
  },
  {
    id: "vr251yc6a",
    name: "rectangle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
    ["styles"]: {
      strokeWidth: 5,
      enableStroke: true,
      color1: "transparent",
      borderRadius: 0,
    },
  },
  {
    id: "hj901ec9a",
    name: "rounded-border-rectangle1",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 100,
      h: 50,
    },
    percentageOfSize: {
      w: 25,
      h: 15,
    },
    ["styles"]: {
      borderRadius: 0,
    },
  },
  {
    id: "jo411sx7a",
    name: "rounded-border-rectangle2",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 100,
      h: 50,
    },
    percentageOfSize: {
      w: 30,
      h: 15,
    },
    ["styles"]: {
      borderRadius: 12,
    },
  },
  {
    id: "aq711sc1a",
    name: "triangle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
  },
  {
    id: "r4e15sd1a",
    name: "triangle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
    ["styles"]: {
      strokeWidth: 5,
      enableStroke: true,
      color1: "transparent",
    },
  },
  {
    id: "ms327su9a",
    name: "right-triangle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
  },
  // {
  //   name: "blob1",
  //   color1: "#80ffcd",
  //   color2: "",
  //   size: {
  //     w: "100%",
  //     h: "100%",
  //   },
  // },
  // {
  //   name: "blob2",
  //   color1: "#94bbf8ff",
  //   color2: "",
  //   size: {
  //     w: "100%",
  //     h: "100%",
  //   },
  // },
  {
    id: "vt151sc3b",
    name: "blob3",
    subtype: "blob",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
  },
  {
    id: "qr461yc9a",
    name: "blob4",
    subtype: "blob",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
  },
  {
    id: "hh477uc9a",
    name: "blob5",
    subtype: "blob",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
  },
  {
    id: "rh6474c9g",
    name: "blob5",
    subtype: "blob",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 70,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
    ["styles"]: {
      strokeWidth: 5,
      enableStroke: true,
      color1: "transparent",
    },
  },
  {
    id: "vy477yc5a",
    name: "hexagon",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 85,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 20,
    },
  },
  {
    id: "vc551st9a",
    name: "shape1",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 80,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 22,
    },
  },
  {
    id: "bn331tc5a",
    name: "shape2",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 80,
      h: 70,
    },
    percentageOfSize: {
      w: 25,
      h: 22,
    },
  },
  {
    id: "ft235wg1a",
    name: "rhombus",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 100,
      h: 75,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
  },
  {
    id: "er140wrcq",
    name: "parallelogram",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 100,
      h: 75,
    },
    percentageOfSize: {
      w: 25,
      h: 25,
    },
  },
  {
    id: "re256wexl",
    name: "half-circle",
    subtype: "shape",
    color1: "#94bbf8ff",
    color2: "",
    size: {
      w: 100,
      h: 80,
    },
    percentageOfSize: {
      w: 20,
      h: 10,
    },
  },
  // {
  //   id: "gd265yd1i",
  //   name: "message-box-1",
  //   subtype: "shape",
  //   color1: "#94bbf8ff",
  //   color2: "",
  //   size: {
  //     w: 200,
  //     h: 100,
  //   },
  //   percentageOfSize: {
  //     w: 25,
  //     h: 15,
  //   },
  // },
];

const DisplayItem = ({item, index, onSelect, pageTheme}) => {
  const handleClick = () => {
    onSelect(item);
  };
  return (
    <Shape pageTheme={pageTheme} onClick={handleClick}>
      <WrapShape>
        <SelectedShape
          shape={item.name}
          styles={{
            ...shapeStyles,
            color1: item.color1,
            color2: item.color2,
            lineType: item.lineType,
            enableGradient:
              item.name === "radial-gradient-circle" ? true : false,
            ...item.styles,
            stroke: "#94bbf8ff",
            enableStroke: item.styles?.enableStroke,
          }}
          index={index}
          size={item.size}
          tempScale={1}
        />
      </WrapShape>
    </Shape>
  );
};

const CreateShape = ({
  createShape,
  closeModal,
  closeDrawer,
  storeActionsUndoRedo,
  slideID,
  pageTheme,
}) => {
  const onCreate = (item) => {
    let shapeStylesBasedOnType = {};
    if (item.subtype === "line") {
      shapeStylesBasedOnType = {
        lineType: item.lineType,
        lineStyle: "straight",
        lineThickness: 2,
        strokeDasharray: 10,
        color1: item.color1,
        color2: item.color2,
      };
    } else {
      shapeStylesBasedOnType = {
        enableGradient: item.color2 !== "" ? true : false,
        ...item.styles,
        enableStroke: item.styles?.enableStroke,
      };
    }
    const shapeProps = {
      key: createShortKey(),
      type: "shape",
      size: {
        w: item.percentageOfSize.w,
        h: item.percentageOfSize.h, // 30
      },
      resizer: "all", //  "corners",
      subtype: item.subtype,
      name: item.name,
      ["styles"]: {
        ...shapeStylesBasedOnType,
      },
    };
    createShape(slideID, shapeProps);
    storeActionsUndoRedo("create");

    if (window.innerWidth >= 850) {
      closeModal();
    } else {
      closeDrawer();
    }
  };

  return (
    <Wrapper>
      {items &&
        items.map((item, i) => (
          <DisplayItem
            key={item.id + "-" + i}
            index={item.id + "-" + i}
            item={item}
            onSelect={onCreate}
            pageTheme={pageTheme}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  z-index: 1;
  box-sizing: border-box;
  padding-top: 14px;
  @media only screen and (min-width: 850px) {
    padding: 14px 10px 0 10px;
  }
`;

const baseItem = css`
  display: flex;
  flex: 0 44%;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  cursor: pointer;
  margin-bottom: 2%;
  @media only screen and (min-width: 850px) {
    flex: 0 22%;
  }
`;

const Shape = styled.div`
  background: ${({theme, pageTheme}) =>
    pageTheme === "light" ? "#bfb4f11c" : theme.modalItemBg};
  font-size: 18px;
  height: 100px;
  width: 100px;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  ${baseItem};
  border-radius: 6px;
  padding: 0 1%;
  &:last-child {
    margin-bottom: 1.5em;
  }
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 849px) {
    background: ${({theme}) => theme.itemBgColor1};
  }
`;

const WrapShape = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mapStateToProps = (state) => {
  return {
    pageTheme: selectPageTheme(state.designTemplate),
    slideID: selectActiveSlide(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  createShape,
  closeDrawer,
  storeActionsUndoRedo,
})(CreateShape);
