import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import SpecialButton from "../../../../../ui/inputs/SpecialButton";
import {connect} from "react-redux";
import {resizeTemplate} from "../../../../../store/actions/template/template.action";
import {
  selectTemplateSize,
  selectWorkspacePlan,
} from "../../../../../store/selectors/template/template.selector";
import {useSocket} from "../../../../../webSocket/useSocket";
import {detectClickOutside} from "../../../../../../oat-ui";
import {redMessage} from "../../../../../themes";

const ResizeTemplate = ({resizeTemplate, tempSize, workplacePlan, close}) => {
  const [resizeForm, setResizeForm] = useState({width: "", height: ""});
  const [error, setError] = useState({status: false, message: null});
  const {emitSocketEvents} = useSocket() || {};
  const regex = new RegExp(/^\d*\.?\d*$/);
  const [scaleType, setScaleType] = useState("px");
  const [openUnitBox, setOpenUnitBox] = useState(false);
  const layoutRef = useRef();
  const selectionRef = useRef();
  const widthRef = useRef();
  const heightRef = useRef();

  // transformed user's input size to px
  const [convertToPx, setConvertToPx] = useState({
    width: 0,
    height: 0,
  });

  const onChangeWidth = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      if (e.target === widthRef.current) {
        heightRef.current.focus();
      }
    }
    
    const {value} = e.target;
    if (regex.test(value)) {
      setResizeForm({
        ...resizeForm,
        width: value,
      });
    }
  };

  const onChangeHeight = (e) => {
    const {value} = e.target;
    if (regex.test(value)) {
      setResizeForm({
        ...resizeForm,
        height: value,
      });
    }
  };

  const onResize = async () => {
    if (workplacePlan === "free") {
      setError({status: true, message: "Workspace needs to be upgraded."});
    } 
    
    else if (scaleType === "px" && (convertToPx.width > 6001 || resizeForm.height > 6001)) {
      setError({status: true, message: "Max size is 6000 px"});
    }
    else if (scaleType === "in" && (convertToPx.width > 62.5 || resizeForm.height > 62.5)) {
      setError({status: true, message: "Max size is 62.5 in"});
    }
    else if (scaleType === "cm" && (convertToPx.width > 160 || resizeForm.height > 160)) {
      setError({status: true, message: "Max size is 160 cm"});
    }
    else if (scaleType === "mm" && (convertToPx.width > 1600 || resizeForm.height > 1600)) {
      setError({status: true, message: "Max size is 1600 mm"});
    }
   
    else if (resizeForm.width !== "" && resizeForm.height !== "") {
      setError({status: false, message: null});
      const {width, height} = convertToPx;
      let resizeTo = {w: width, h: height};
      // const dimension = width > height ? "landscape" : "potrait";

      if (width < 250 && height < 250) {
        const newWidth = 300;
        const newHeight = 300;
        resizeTo = {
          w: Math.round(newWidth),
          h: Math.round(newHeight),
        };
      }
      // else if (width < 250 && height > 250) {
      //   const newWidth = height / 1.5;
      //   const newHeight = height; //(newWidth * height) / newWidth;
      //   resizeTo = {
      //     w: Math.round(newWidth),
      //     h: Math.round(newHeight),
      //   };
      // } else if (width > 250 && height < 250) {
      //   const newHeight = width / 3;
      //   resizeTo = {
      //     w: Math.round(width),
      //     h: Math.round(newHeight),
      //   };
      // }
      // else if (width > 6000 && height > 6000) {
      //   const newWidth = 6000;
      //   const newHeight = 6000;
      //   resizeTo = {
      //     w: Math.round(newWidth),
      //     h: Math.round(newHeight),
      //   };
      // } else if (width > 6000 && height < 1000) {
      //   const newWidth = 6000;
      //   const newHeight = (6000 * 1000) / 6000;
      //   resizeTo = {
      //     w: Math.round(newWidth), // resizeForm.width,
      //     h: Math.round(newHeight), // resizeForm.height,
      //   };
      // }
      else if (height > 6000 && width < 1000) {
        const newHeight = 6000;
        const newWidth = (6000 * 1000) / 6000;
        resizeTo = {
          w: Math.round(newWidth),
          h: Math.round(newHeight),
        };
      } else if (width > 6000 && width > height) {
        const newWidth = 6000;
        const newHeight = (6000 * height) / width;
        resizeTo = {
          w: Math.round(newWidth),
          h: Math.round(newHeight),
        };
      } else if (height > 6000 && height > width) {
        const newHeight = 6000;
        const newWidth = (6000 * width) / height;
        resizeTo = {
          w: Math.round(newWidth),
          h: Math.round(newHeight),
        };
      } else {
        resizeTo = {
          w: Math.round(convertToPx.width),
          h: Math.round(convertToPx.height),
        };
      }

      const resizePayload = {
        resizeFrom: tempSize,
        resizeTo,
      };
      await resizeTemplate({...resizePayload});

      const item = {type: "", value: resizePayload};
      if (emitSocketEvents) {
        emitSocketEvents({actionType: "resized-template", item});
      }
      close();
    }
  };

  useEffect(() => {
    const {width, height} = resizeForm;
    if (scaleType === "cm") {
      const conversion = 37.79527559;
      const widthCmToPx = Math.round(width * conversion) / 1;
      const heightCmToPx = Math.round(height * conversion) / 1;
      setConvertToPx({
        width: widthCmToPx,
        height: heightCmToPx,
      });
    } else if (scaleType === "mm") {
      const conversion = 3.779527559;
      const widthMmToPx = Math.round(width * conversion) / 1;
      const heightMmToPx = Math.round(height * conversion) / 1;
      setConvertToPx({
        width: widthMmToPx,
        height: heightMmToPx,
      });
    } else if (scaleType === "in") {
      const conversion = 96;
      const widthInToPx = Math.round(width * conversion) / 1;
      const heightInToPx = Math.round(height * conversion) / 1;
      setConvertToPx({
        width: widthInToPx,
        height: heightInToPx,
      });
    } else {
      setConvertToPx({
        width,
        height,
      });
    }
  }, [resizeForm]);

  const toggleScaleType = () => {
    setOpenUnitBox(!openUnitBox);
  };

  const changeUnit = (value) => {
    setScaleType(value);
    setOpenUnitBox(false);
  };

  const changeUnitPx = (e) => {
    e.stopPropagation();
    changeUnit("px");
  };

  const changeUnitMm = (e) => {
    e.stopPropagation();
    changeUnit("mm");
  };

  const changeUnitIn = (e) => {
    e.stopPropagation();
    changeUnit("in");
  };

  const changeUnitCm = (e) => {
    e.stopPropagation();
    changeUnit("cm");
  };

  detectClickOutside(layoutRef, () => {
    if (openUnitBox) toggleScaleType();
  });

  return (
    <Wrapper>
      <WrapForm>
        <Field style={{margin: "10px 0 18px"}}>
          <div className="resize-field-name">Enter Width</div>
          <Input
            ref={widthRef}
            autoFocus
            placeholder={scaleType}
            value={resizeForm.width}
            onChange={onChangeWidth}
          />
        </Field>
        <Field style={{marginBottom: 16}}>
          <div className="resize-field-name">Enter Height</div>
          <Input
            ref={heightRef}
            placeholder={scaleType}
            value={resizeForm.height}
            onChange={onChangeHeight}
          />
        </Field>
        <Field style={{marginBottom: 16}}>
          <div className="resize-field-name">Scale Type</div>
          <ScaleUnit ref={layoutRef} className="resize-field-name">
            <div onClick={toggleScaleType} className="selected-unit">
              {scaleType}
            </div>
            {openUnitBox ? (
              <Select ref={selectionRef}>
                <div onClick={changeUnitPx}>px</div>
                <div onClick={changeUnitMm}>mm</div>
                <div onClick={changeUnitIn}>in</div>
                <div onClick={changeUnitCm}>cm</div>
              </Select>
            ) : undefined}
          </ScaleUnit>
        </Field>
      </WrapForm>

      <Error>{error.status ? error.message : undefined}</Error>

      <BottomSection>
        <WrapButton>
          <SpecialButton onClick={onResize}>Resize</SpecialButton>
        </WrapButton>
      </BottomSection>
    </Wrapper>
  );
};

const BottomSection = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
  margin: 12px 0 12px 0;
`;

const WrapButton = styled.div`
  width: 180px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.95rem;
`;

const WrapForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const Field = styled.div`
  display: flex;
  flex: 1;
  font-size: 0.95rem;
  align-items: center;
  margin-bottom: 8px;
  .resize-field-name {
    flex: 1;
  }
`;

const Input = styled.input`
  height: 32px;
  box-sizing: border-box;
  margin-right: 4px;
  border: none;
  box-shadow: ${({theme}) => theme.boxShadow1};
`;

const ScaleUnit = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 64px;
  padding: 6px 10px;
  cursor: pointer;
  .selected-unit {
    width: 64px;
    background: ${({theme}) => theme.deskSecondaryBg2};
    padding: 6px 10px;
    border-radius: 6px;
    box-shadow: ${({theme}) => theme.boxShadow1};
  }
`;

const Select = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  width: 64px;
  margin: 8px 0;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  padding: 6px 0 8px;
  z-index: 999;
  background: ${({theme}) => theme.deskSecondaryBg2};
  box-shadow: ${({theme}) => theme.boxShadow1};
  div {
    border-radius: 6px;
    margin: 2px 8px;
    padding: 6px;
    &:hover {
      background: ${({theme}) => theme.deskSecondaryBg3};
    }
  }
`;

const Error = styled.div`
  text-align: right;
  color: ${redMessage};
  margin: 8px 0;
`;

const mapStateToProps = (state) => {
  const {designTemplate} = state;
  return {
    tempSize: selectTemplateSize(designTemplate),
    workplacePlan: selectWorkspacePlan(designTemplate),
  };
};

export default connect(mapStateToProps, {resizeTemplate})(ResizeTemplate);
