import styled from "styled-components";

export const Title = styled.div`
  flex: 1;
  color: ${({theme}) => theme.deskPrimaryTxt};
  -webkit-user-select: none !important;
  user-select: none !important;
  @media only screen and (max-width: 849px) {
    font-size: 0.9rem;
    // display: ${({showTitle}) => (showTitle ? "block" : "none")};
    // padding-left: 1rem;
  }
  @media only screen and (min-width: 850px) {
    padding-left: ${({disabledPadding}) => (disabledPadding ? "0" : "1rem")};
    box-sizing: border-box;
    display: block;
  }
`;
