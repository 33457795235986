import React from "react";
import styled from "styled-components";

const getGradient = (styles, index) => (
  <defs>
    <linearGradient
      id={index}
      gradientTransform={`rotate(${styles.gradientAngle} 0.5 0.5) `}
    >
      <stop offset="0%" stopColor={styles.color1} />
      <stop offset="100%" stopColor={styles.color2} />
    </linearGradient>
  </defs>
);

const getShadow = (shadowType, shadowColor, isLandScape, tempScale) => {
  let shadow = "";
  if (shadowType === "classic") {
    const shadowSize = isLandScape ? '0.3em 0.3em 0.2em' : '0.15em 0.15em 0.1em';
    shadow = `drop-shadow(${shadowColor} ${shadowSize})`
  } else if (shadowType === "dim") {
    const shadowSize = isLandScape ? '0.4em 0.4em 0.7em' : '0.1em 0.1em 0.15em'
    shadow = `drop-shadow(${shadowColor} ${shadowSize})`
  } else if (shadowType === "hard") {
    const shadowSize = isLandScape ? `0.4em 0.4em 0em` : `0.15em 0.15em 0em` 
    // '0.45em 0.45em 0em'
    shadow = `drop-shadow(${shadowColor} ${shadowSize})`
  }
  return shadow;
}

// for double stroke
const applyDoubleStroke = (styles, size, index) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  return (
    <g>
      <use
        href={`#${dbl_stroke}`}
        stroke={styles?.enableStroke ? styles.stroke : undefined}
        strokeWidth={
          styles?.enableStroke ? (size.w * styles?.strokeWidth) / 70 : 0
        }
        fill={styles.enableGradient ? `url(#${index})` : styles.color1}
        clipPath={`url(#${clip})`}
      />
    </g>
  );
};

export const Circle = ({styles, size, tempScale, index, isLandScape}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape style={{opacity: styles.opacity}}>
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w * 2} ${size.h * 2}`}
        filter={`${shadow} blur(${styles.blur}px)`}
      >
        {/* <ellipse cx={size.w} cy={size.h} rx={size.w} ry={size.h}></ellipse> */}
        {/* <rect rx="0.00" width="100%" height="100%"></rect> */}
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <g fill={styles.enableGradient ? `url(#${index})` : styles.color1}>
            <ellipse
              id={dbl_stroke}
              cx={size.w}
              cy={size.h}
              rx={size.w}
              ry={size.h}
              stroke={styles?.enableStroke ? styles.stroke : undefined}
              strokeWidth={
                styles?.enableStroke ? (size.w * styles.strokeWidth) / 50 : 0
              }
              fill={styles.enableGradient ? `url(#${index})` : styles.color1}
            />
          </g>
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const RadialGradientCircle = ({styles, size, index, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape style={{opacity: styles.opacity}}>
      {/* filter: `blur(${styles.blur}px)` */}
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w * 2} ${size.h * 2}`}
        style={{
          opacity: styles.opacity,
          filter: `${shadow} blur(${styles.blur}px)`
        }}
      >
        {styles.enableGradient ? (
          <defs>
            <radialGradient
              id={index}
              cx="50%"
              cy="40%"
              r="50%"
              fx="50%"
              fy="30%"
            >
              <stop offset="0%" stopColor={styles.color1} />
              <stop offset="100%" stopColor={styles.color2} />
            </radialGradient>
          </defs>
        ) : undefined}
        <defs>
          <g fill={styles.enableGradient ? `url(#${index})` : styles.color1}>
            <ellipse
              id={dbl_stroke}
              cx={size.w}
              cy={size.h}
              rx={size.w}
              ry={size.h}
              stroke={styles.stroke}
              strokeWidth={styles.enableStroke ? (size.w * styles.strokeWidth) / 50 : 0}
              fill={styles.enableGradient ? `url(#${index})` : styles.color1}
            />
            <clipPath id={clip}>
              <use href={`#${dbl_stroke}`} />
            </clipPath>
          </g>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const Blob1 = ({styles, index, isLandScape, tempScale}) => {
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}} // filter: `blur(${styles.blur}px)`
    >
      <svg
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <path
          stroke={styles.stroke}
          strokeWidth={styles.enableStroke ? styles.strokeWidth : 0}
          d="M26.5,-52.3C31.6,-42.9,31,-30.3,29.9,-21C28.8,-11.7,27.2,-5.9,34.5,4.2C41.9,14.3,58.1,28.6,61.5,41.9C64.9,55.2,55.5,67.4,43.1,68.5C30.7,69.5,15.3,59.3,-0.3,59.9C-16,60.5,-32,71.9,-41.5,69.1C-51,66.4,-53.9,49.6,-52.2,35.7C-50.5,21.9,-44.1,10.9,-42.2,1.1C-40.2,-8.7,-42.7,-17.4,-38.6,-21.1C-34.5,-24.9,-23.8,-23.8,-16.2,-31.7C-8.7,-39.7,-4.4,-56.8,3.2,-62.2C10.7,-67.7,21.4,-61.7,26.5,-52.3Z"
          transform="translate(100 100)"
          style={{transform: "translate(50%, 50%) scale(1.3)"}}
          fill={styles.enableGradient ? `url(#${index})` : styles.color1}
        />
      </svg>
    </WrapShape>
  );
};

export const Blob2 = ({styles, index, isLandScape, tempScale}) => {
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <path
          stroke={styles.stroke}
          strokeWidth={styles.enableStroke ? styles.strokeWidth : 0}
          d="M28.9,-49C40,-43.6,53.4,-41.1,56,-33.4C58.5,-25.7,50.2,-12.9,51.1,0.5C51.9,13.8,61.8,27.6,57.4,32.1C53,36.5,34.2,31.7,22.3,35.6C10.3,39.5,5.2,52.1,0.1,51.9C-4.9,51.7,-9.8,38.6,-17.7,32.4C-25.5,26.1,-36.2,26.6,-45.7,22.3C-55.1,18.1,-63.2,9,-62.3,0.5C-61.4,-8,-51.4,-15.9,-47.3,-29.5C-43.3,-43.2,-45.3,-62.5,-38.4,-70.4C-31.5,-78.3,-15.7,-74.8,-3.4,-68.8C8.9,-62.9,17.7,-54.5,28.9,-49Z"
          transform="translate(100 100)"
          style={{transform: "translate(50%, 60%) scale(1.4)"}}
          fill={styles.enableGradient ? `url(#${index})` : styles.color1}
        />
      </svg>
    </WrapShape>
  );
};

export const Rectangle = ({styles, index, size, tempScale, isLandScape}) => {
  let type = "radial";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{
        opacity: styles.opacity,
        // borderRadius: styles?.borderRadius ? styles?.borderRadius : 0
      }}
    >
      {/* svg with rounded stroke width causes error, as stroke width is getting too big  */}
      {/* <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w} ${size.h}`}
        preserveAspectRatio="none"
        style={{
          opacity: styles.opacity,
          filter: `${shadow} blur(${styles.blur}px)`,
          // overflow: "visible",
        }}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <rect
          width="100%"
          height="100%"
          stroke={styles.stroke}
          strokeWidth={styles?.enableStroke ? styles.strokeWidth : 0}
          rx={styles?.borderRadius ? styles?.borderRadius : 0}
          fill={
            styles?.strokeOnly
              ? "none"
              : styles.enableGradient
              ? `url(#${index})`
              : styles.color1
          }
          strokeLinejoin="round"
        />
      </svg> */}
      <DivShaped
        width={size.w}
        height={size.h}
        opacity={styles.opacity}
        strokeWidth={styles.strokeWidth}
        stroke={styles.stroke}
        enableStroke={styles.enableStroke}
        style={{
          borderRadius: styles.borderRadius / 16 + 'em',
          background: !styles.enableGradient
            ? styles.color1
            : type !== "center"
            ? `linear-gradient(${styles.gradientAngle + 90}deg, ${
                styles.color1
              }, ${styles.color2})`
            : `radial-gradient(${styles.color1}, ${styles.color2})`,
          filter: `${shadow} blur(${styles.blur}px)`,
        }}
      />
    </WrapShape>
  );
};

export const Triangle = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke"; // double stroke
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w} ${size.h}`}
        style={{
          opacity: styles.opacity,
          filter: `${shadow} blur(${styles.blur}px)`
        }}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <polygon
            id={dbl_stroke}
            points={`${size.w / 2} , 1.0000 1.0000, ${size.h} ${size.w} , ${
              size.h
            }`}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? 2 * styles.strokeWidth : 0}
          />
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const RightTriangle = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke"; // double stroke
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w} ${size.h}`}
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <polygon
            id={dbl_stroke}
            points={`${size.w} , 1.0000 1.0000, ${size.h} ${size.w} , ${size.h}`}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? 2 * styles.strokeWidth : 0}
          />
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const RoundedBorderRectangle1 = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w} ${size.h}`}
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <rect
            id={dbl_stroke}
            width="100%"
            height="100%"
            rx={0}
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? (size.w * styles.strokeWidth) / 50 : 0}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
          />
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const Hexagon = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${5 * (size.w / 3)} ${size.h * 1.64}`}
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <polygon
            id={dbl_stroke}
            points={`${size.w / 3}, 0.0000 ${(size.w / 3) * 4}, 0.0000 ${
              5 * (size.w / 3)
            }, ${size.h / 1.24}  ${(size.w / 3) * 4}, ${size.h * 1.64} ${
              size.w / 3
            }, ${size.h * 1.64} 0.0000, ${size.h / 1.24}`}
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? (2 * styles.strokeWidth) : 0}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
          />
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const Shape1 = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w * 2} ${size.h * 2}`}
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <polygon
            id={dbl_stroke}
            points={`0.0000, 0.0000 ${size.w * 2}, 0.0000 ${size.w * 1.3}, ${
              size.h
            } ${size.w * 2}, ${size.h * 2} 0.0000, ${size.h * 2}`}
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? (styles.strokeWidth * 2) : 0}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
          />
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const Shape2 = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w * 2.4} ${size.h * 2.4}`}
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <polygon
            id={dbl_stroke}
            points={`0.0000, 0.0000 ${size.w * 1.8}, 0.0000 ${size.w * 2.4}, ${
              size.h * 1.2
            } ${size.w * 1.8}, ${size.h * 2.4} 0.0000, ${size.h * 2.4}`}
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? styles.strokeWidth * 2.4 : 0}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
          />
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const Shape3 = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w * 2.4} ${size.h * 2.4}`}
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}

        <defs>
          <polygon
            id={dbl_stroke}
            points={`0.0000, 0.0000 ${size.w * 2.4 - size.w * 0.5}, 0.0000 ${
              size.w * 2.4
            }, ${(size.h / 2) * 2.4} ${size.w * 2.4 - size.w * 0.5}, ${
              size.h * 2.4
            } 0.0000, ${size.h * 2.4} ${size.w * 0.5}, ${(size.h / 2) * 2.4}`}
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? styles?.strokeWidth * 2 : 0}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
          ></polygon>
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>

        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
  );
};

export const RoundedBorderRectangle2 = ({styles, index, size, tempScale, isLandScape}) => {
  const type = "radical";
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity}}
    >
      <svg
        width={`${size.w}px`}
        height={`${size.h}px`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size.w} ${size.h}`}
        style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
      >
        {styles.enableGradient ? getGradient(styles, index) : undefined}
        <defs>
          <rect
            id={dbl_stroke}
            width="100%"
            height="100%"
            rx={15 / (tempScale ? tempScale : 1)} // tempScale ? 10 / tempScale : 0
            stroke={styles.stroke}
            strokeWidth={styles.enableStroke ? styles.strokeWidth : 0}
            fill={styles.enableGradient ? `url(#${index})` : styles.color1}
          />
          <clipPath id={clip}>
            <use href={`#${dbl_stroke}`} />
          </clipPath>
        </defs>
        {applyDoubleStroke(styles, size, index)}
      </svg>
    </WrapShape>
    // <DivShaped
    //   width={size.w}
    //   height={size.h}
    //   opacity={styles.opacity}
    //   strokeWidth={(size.w * styles.strokeWidth) / 50}
    //   stroke={styles.stroke}
    //   blur={styles.blur}
    //   style={{
    //     borderRadius: 50,
    //     background: !styles.enableGradient
    //       ? styles.color1
    //       : type !== "center"
    //       ? `linear-gradient(${styles.gradientAngle + 90}deg, ${
    //           styles.color1
    //         }, ${styles.color2})`
    //       : `radial-gradient(${styles.color1}, ${styles.color2})`,
    //   }}
    // />
  );
};

export const Rhombus = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <svg
      width={`${size.w}px`}
      height={`${size.h}px`}
      version="2.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${size.w} ${size.h}`}
      style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
    >
      {styles.enableGradient ? getGradient(styles, index) : undefined}
      <defs>
        <polygon 
          id={dbl_stroke}
          points={`${size.w / 2},0 ${size.w},${size.h/2} ${size.w/2},${size.h} 0,${size.h/2}`} 
          fill={styles.enableGradient ? `url(#${index})` : styles.color1} 
        />
        <clipPath id={clip}>
          <use href={`#${dbl_stroke}`} />
        </clipPath>
      </defs>
      {applyDoubleStroke(styles, size, index)}
    </svg>
  );
};

export const Parallelogram = ({styles, index, size, isLandScape, tempScale}) => {
  const prlogramTopLeftSpace = (size.w * 85) / 100;
  const prlogramBottomRightSpace = (size.w * 15) / 100;
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <svg
      width={`${size.w}px`}
      height={`${size.h}px`}
      version="2.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${size.w} ${size.h}`}
      style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
    >
      {styles.enableGradient ? getGradient(styles, index) : undefined}
      <defs>    
        <polygon 
          id={dbl_stroke}
          points={`${size.w - prlogramTopLeftSpace},0 ${size.w},0 ${size.w - prlogramBottomRightSpace},${size.h} 0,${size.h}`}
          fill={styles.enableGradient ? `url(#${index})` : styles.color1} 
          strokeWidth={styles.strokeWidth * 2}
        />
        <clipPath id={clip}>
          <use href={`#${dbl_stroke}`} />
        </clipPath>
      </defs>
      {applyDoubleStroke(styles, size, index)}
    </svg>
  );
};

export const HalfCircle = ({styles, index, size, isLandScape, tempScale}) => {
  const dbl_stroke = index + "dbl_stroke";
  const clip = index + "clip";
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <svg
      width={`${size.w}px`}
      height={`${size.h}px`}
      version="2.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${size.w} ${size.h}`}
      style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
    >
      {styles.enableGradient ? getGradient(styles, index) : undefined}
      {/* <defs>     */}
        <path 
          id={dbl_stroke}
          d={`M0,${size.h} A${size.w/2},${size.h} 0 1,1 ${size.w},${size.h} Z`}
          fill={styles.enableGradient ? `url(#${index})` : styles.color1} 
          strokeWidth={styles.strokeWidth}
        />
        <clipPath id={clip}>
          <use href={`#${dbl_stroke}`} />
        </clipPath>
      {/* </defs> */}
      {applyDoubleStroke(styles, size, index)}
    </svg>
  )
}

const divStyleBlob = (styles, size, radius, tempScale) => {
  const type = "radical";
  return (
    <DivShaped
      width={size.w}
      height={size.h}
      opacity={styles.opacity}
      strokeWidth={styles?.strokeWidth ? styles.strokeWidth : 0}
      stroke={styles.stroke}
      blur={styles.blur}
      enableStroke={styles.enableStroke}
      style={{
        borderRadius: radius,
        background: !styles.enableGradient
          ? styles.color1
          : type !== "center"
          ? `linear-gradient(${styles.gradientAngle + 90}deg, ${
              styles.color1
            }, ${styles.color2})`
          : `radial-gradient(${styles.color1}, ${styles.color2})`,
      }}
    />
  );
};

export const Blob3 = ({styles, size, tempScale, isLandScape}) => {
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
    >
      {divStyleBlob(
        styles,
        size,
        "44% 66% 68% 24% / 44% 66% 63% 44%",
        tempScale
      )}
    </WrapShape>
  );
};

export const Blob4 = ({styles, size, tempScale, isLandScape}) => {
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
    >
      {divStyleBlob(styles, size, "66% 66% 66% 0% / 66% 66% 66% 0%", tempScale)}
    </WrapShape>
  );
};

export const Blob5 = ({styles, size, tempScale, isLandScape}) => {
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
    <WrapShape
      style={{opacity: styles.opacity, filter: `${shadow} blur(${styles.blur}px)`}}
    >
      {divStyleBlob(styles, size, "94% 0% 0% 24% / 94% 0% 0% 0%", tempScale)}
    </WrapShape>
  );
};

const arrowRightMarker = (index, lineColor, height) => (
  <defs>
    <marker
      id={`${index}-right-markers`}
      viewBox={`0 0 ${height} ${height}`}
      refX="0"
      refY={height / 2}
      markerUnits="strokeWidth"
      markerWidth={"70%"}
      markerHeight={"70%"}
      orient="auto"
    >
      <polygon
        points={`${height},${height / 2} 0,${height} 0,0`}
        fill={lineColor}
      />
    </marker>
  </defs>
);

const arrowLeftMarker = (index, lineColor, height) => (
  <defs>
    <marker
      id={`${index}-left-markers`}
      viewBox={`0 0 ${height} ${height}`}
      refX="1.5"
      refY={height / 2}
      markerUnits="strokeWidth"
      markerWidth={"70%"}
      markerHeight={"70%"}
      orient="auto"
    >
      <polygon
        points={`0,${height / 2} ${height},0 ${height},${height}`}
        fill={lineColor}
      />
    </marker>
  </defs>
);

const circleLeftMarker = (index, circleColor, height) => (
  <defs>
    <marker
      id={`${index}-left-markers`}
      viewBox={`0 0 ${height} ${height}`}
      refX="1.5"
      refY={height / 2}
      markerUnits="strokeWidth"
      markerWidth={"70%"}
      markerHeight={"70%"}
      orient="auto"
    >
      <circle cx={height / 2} cy={height / 2} r={height / 2} fill={circleColor} />
    </marker>
  </defs>
);

const circleRightMarker = (index, circleColor, height) => (
  <defs>
    <marker
      id={`${index}-right-markers`}
      viewBox={`0 0 ${height} ${height}`}
      refX="1.5"
      refY={height / 2}
      markerUnits="strokeWidth"
      markerWidth={"70%"}
      markerHeight={"70%"}
      orient="auto"
    >
      <circle cx={height / 2} cy={height / 2} r={height / 2} fill={circleColor} />
    </marker>
  </defs>
);


export const Line = ({styles, size, index, tempScale}) => {
  return (
    <WrapShape
      style={{
        display: "flex",
        alignItems: "center",
        width: size.w,
        opacity: styles.opacity,
        filter: `blur(${styles.blur}px)`
      }}
    >
      <svg
        viewBox={`0 0 ${size.w} ${size.h}`}
        version="2.0"
        xmlns="http://www.w3.org/2000/svg"
        height={size.h}
        style={{overflow: "visible", width: "100%"}}
      >
        {styles.lineType === "line-with-right-arrow" ||
        styles.lineType === "line-with-arrows"
          ? arrowRightMarker(index, styles.color2, size.h)
          : (styles.lineType === "line-with-right-circle" ||
          styles.lineType === "line-with-circles") 
          ? circleRightMarker(index, styles.color2, size.h)
          : undefined}

        {styles.lineType === "line-with-left-arrow" ||
        styles.lineType === "line-with-arrows"
          ? arrowLeftMarker(index, styles.color2, size.h)
          : (styles.lineType === "line-with-left-circle" ||
          styles.lineType === "line-with-circles")
          ? circleLeftMarker(index, styles.color2, size.h) 
          : undefined}

        <line
          x1={0}
          y1={`${size.h / 2}px`}
          x2={`${size.w}px`}
          y2={`${size.h / 2}px`}
          stroke={styles.color1}
          strokeWidth={
            styles?.lineThickness ? styles?.lineThickness : 1
          }
          strokeDasharray={
            styles.lineStyle === "dashed"
              ? styles.strokeDasharray
              : styles.lineStyle === "dotted"
              ? `${1}, ${15}`
              : undefined
          }
          strokeLinecap={styles.lineStyle === "dotted" ? "round" : undefined}
        />

        {styles.lineType === "line" ? undefined : (
          <line
            x1="0"
            y1={`${size.h / 2}px`}
            x2={`${size.w}px`}
            y2={`${size.h / 2}px`}
            stroke={styles.color1}
            markerStart={
              styles.lineType === "line-with-left-arrow" ||
              styles.lineType === "line-with-arrows" ||
              styles.lineType === "line-with-left-circle" ||
              styles.lineType === "line-with-circles" 
                ? `url(#${index}-left-markers)`
                : undefined
            }
            markerEnd={
              styles.lineType === "line-with-right-arrow" ||
              styles.lineType === "line-with-arrows" || 
              styles.lineType === "line-with-right-circle" ||
              styles.lineType === "line-with-circles" 
                ? `url(#${index}-right-markers)`
                : undefined
            }
          ></line>
        )}
      </svg>
    </WrapShape>
  );
};

export const MessageBox = ({styles, size, index, tempScale, isLandScape}) => {
  let shadow = "";
  if (styles?.enabledShadow) {
    shadow = getShadow(styles?.shadowType, styles?.shadowColor, isLandScape, tempScale)
  }
  return (
  //   <svg width={`${size.w}px`}
  //     height={`${size.h}px`}
  //     viewBox={`0 0 ${size.w} ${size.h}`}
  //     version="2.0"  
  //     xmlns="http://www.w3.org/2000/svg">
  //     <defs>
  //       <linearGradient id={`${index}-msg-box-gradient`} x1="0%" y1="0%" x2="100%" y2="0%">
  //         <stop offset="0%" stopColor="#0099FF" />
  //         <stop offset="100%" stopColor="#0055FF" />
  //       </linearGradient>
  //     </defs>

  //    <path stroke={styles.stroke} strokeWidth={8} strokeMiterlimit={10} fill={`url(#${index}-msg-box-gradient)`} d="M10,100 C10,50 30,10 80,10 L320,10 C370,10 390,50 390,100 L390,150 L80,150 L70,190 L60,150 L10,150 L10,100" /> 
  //     {/* <rect x="10" y="10" width={size.w - 30 } height={size.h - 30} rx="10" ry="10"
  //           fill={`url(#${index}-msg-box-gradient)`} stroke={styles.stroke} strokeWidth="4" />  */}
  // </svg>

    <svg 
      width={`${size.w}px`}
      height={`${size.h}px`}
      viewBox={`0 0 ${size.w} ${size.h}`}
      version="2.0" 
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient id={`${index}-msg-box-gradient`} cx="294.99" cy="21.16" r="315.12" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#29abe2"/>
          <stop offset="0.4" stop-color="#27a9e1"/>
          <stop offset="0.61" stop-color="#22a1db"/>
          <stop offset="0.78" stop-color="#1894d3"/>
          <stop offset="0.92" stop-color="#0b81c6"/>
          <stop offset="1" stop-color="#0071bc"/>
        </radialGradient>
      </defs>
      <path stroke={styles.stroke} strokeWidth={8} strokeMiterlimit={10} fill={`url(#${index}-msg-box-gradient)`} 
        d="M365.73,218.5c-5.5-3.5-11.7-9.2-13-17.3a1.7,1.7,0,0,1,.5-1.5,63.4,63.4,0,0,0,14.1-39.9V138.1a65.1,65.1,0,0,0-65.1-65.2H116.33a65.2,65.2,0,0,0-65.2,65.2v19.7a65.1,65.1,0,0,0,65.2,65.1H241.07a62.7,62.7,0,0,0,34.5-10.2,2,2,0,0,1,2.3,0c4.4,3.4,9,7.6,23.3,9.4A2,2,0,0,0,365.73,218.5Z"
        // d="M274.3,218.5c-5.5-3.5-11.7-9.2-13-17.3a1.7,1.7,0,0,1,.5-1.5,63.4,63.4,0,0,0,14.1-39.9V138.1a65.1,65.1,0,0,0-65.1-65.2H85.1a65.2,65.2,0,0,0-65.2,65.2v19.7a65.1,65.1,0,0,0,65.2,65.1H212.8a62.7,62.7,0,0,0,34.5-10.2,2,2,0,0,1,2.3,0c4.4,3.4,9,7.6,23.3,9.4A2,2,0,0,0,274.3,218.5Z"
        />
    </svg>
  )
};

const DivShaped = styled.div`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  opacity: ${({opacity}) => opacity};
  border: ${({strokeWidth, stroke, enableStroke}) => enableStroke ? `${strokeWidth}px solid ${stroke}` : undefined};
  box-sizing: border-box;
`;

const WrapShape = styled.div`
  // width: 80px;
  // height: 80px;
  height: 100%;
  user-select: none;
`;
