import React, {useState} from "react";
import {Modal} from "../../../../../../oat-ui";
import {ContentSection} from "../modalStyles";
import {connect} from "react-redux";
import styled from "styled-components";
import {selectUserInfo} from "../../../../../../../redux/user/authUser";
import SharedUserList from "../SharedUserList";
import ShareToWorkspace from "./ShareToWorkspace";

const ToAnotherWorkspace = ({item, user, close, changeModal}) => {
  const [displaySharedList, setDisplaySharedList] = useState(false);

  const onSwitchDisplay = () => {
    setDisplaySharedList(!displaySharedList);
  };

  return (
    <>
      {!displaySharedList ? (
        <Modal
          width={430}
          height="auto"
          close={close}
          overflowY="visible"
          overflowX="visible"
        >
          <ShareToWorkspace template={item} close={close} />
        </Modal>
      ) : undefined}
      {displaySharedList ? (
        <Modal width={330} height={320} close={close}>
          <ContentSection>
            <BackSection onClick={onSwitchDisplay}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 443.52 443.52"
                >
                  <path d="M143.492 221.863L336.226 29.129c6.663-6.664 6.663-17.468 0-24.132-6.665-6.662-17.468-6.662-24.132 0l-204.8 204.8c-6.662 6.664-6.662 17.468 0 24.132l204.8 204.8c6.78 6.548 17.584 6.36 24.132-.42 6.387-6.614 6.387-17.099 0-23.712L143.492 221.863z" />
                </svg>
              </div>
            </BackSection>
            <SharedUserList tempID={item.id} />
          </ContentSection>
        </Modal>
      ) : undefined}
    </>
  );
};

const BackSection = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  div {
    width: 12px;
    height: 12px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps, null)(ToAnotherWorkspace);
