import styled from "styled-components";
import { primaryBlue, red4 } from "../../../oat-color-variables";

export const Layout = styled.div`
  background: #fff;
  margin: 12px;
`;

export const Header = styled.h3`
  color: ${primaryBlue};
  font-size: 1rem;
  margin-bottom: 30px;
`;

export const Item = styled.div`
  display: flex;
  font-size: 0.95rem;
  margin-bottom: 16px;
`;

export const Field = styled.div`
  flex: 1;
  color: #232323;
`;

export const WrapSelectInput = styled.div`
  flex: 1.5;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  height: 52px;
  padding: 10px;
  margin-top: 12px;
  border-radius: 6px;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;

export const WrapButton = styled.div`
  width: 142px;
`;

export const Error = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 12px 12px;
  font-size: 0.88rem;
  color: ${red4};
`;
