import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import {paleGreen1, primaryBlue} from "../../../../oat-color-variables";
import {
  userPrimaryGrey,
  userSecondaryBlack,
} from "../../../userDashboardStyles";

export const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: ${window.innerHeight}px;
`;

export const Items = styled.div`
  padding-top: 10px;
  flex-flow: wrap;
  justify-content: flex-start;
  @media only screen and (max-width: 849px) {
    width: 100%;
  }
  display: grid;
  column-gap: 20px;
  @media screen and (min-width: 1491px) and (max-width: 1800px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media screen and (min-width: 1150px) and (max-width: 1490px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (min-width: 1801px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media screen and (min-width: 601px) and (max-width: 1149px) {
    column-gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 600px) {
    column-gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const itemStyles = css`
  font-size: 0.92rem;
  color: ${userSecondaryBlack};
  text-decoration: none;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 1px #dcdcdc;
`;

export const WrapItem = styled(Link)`
  ${itemStyles}
`;

export const WrapTempThumbnailForGuest = styled.a`
  ${itemStyles}
`;

export const Header = styled.div`
  font-size: 1.1rem;
  margin: 0.5rem 0 1.4rem;
  color: ${primaryBlue};
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  .user-dashboard-header {
    display: flex;
    flex: 1;
    align-items: center;
    h3 {
      font-weight: 700;
    }
  }
  @media only screen and (min-width: 850px) {
    font-size: 1.24rem;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: ${userPrimaryGrey};
  margin: 18px 0;
`;

export const ItemName = styled.p`
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  background: ${paleGreen1};
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  user-select: none;
  cursor: pointer;
  object-fit: contain;
  z-index: 2;
`;

export const MoreOption = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 10px 2px 0;
  min-width: 50px;
  height: 100%;
  z-index: 99;
  cursor: pointer;
  div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #6b70f9;
    margin: 0 3px;
  }
`;

export const WrapTempInfo = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${window.innerHeight - 150}px;
`;

export const WrapDashAreaSpinner = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
`;

export const WrapImage = styled.div`
  width: 100%;
  background: ${paleGreen1};
  display: flex;
  justify-content: center;
`;