import React from "react";
import {connect} from "react-redux";
import PopoverColorBox from "../../../shared/colorPicker/PopoverColorBox";
import {updateSingleColorChart} from "../../../../../../../store/actions/fields/chart.action";
import {selectActiveSlide} from "../../../../../../../store/selectors/template/slide.selector";
import {selectActiveField} from "../../../../../../../store/selectors/fields.selector";
import {storeFieldUndoRedo} from "../../../../../../../store/actions/fields/undoRedo.action";
import {updateFieldCollaboration} from "../../../../../../../store/actions/collaboration/collaboration.action";

const PickChartColor = ({
  slideID,
  fieldID,
  updateSingleColorChart,
  activeColor,
  innerIndex,
  storeFieldUndoRedo,
  updateFieldCollaboration,
}) => {
  const handleClick = (color) => {
    const active = {slideID, fieldID};
    updateSingleColorChart({active, color, index: innerIndex - 1}); // to adjust with category header
  };

  const handleDebounce = (value) => {
    storeFieldUndoRedo();
    updateFieldCollaboration();
  };

  return (
    <PopoverColorBox
      value={activeColor}
      updateFieldStyles={handleClick}
      onDebounce={handleDebounce}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    slideID: selectActiveSlide(state.designTemplate),
    fieldID: selectActiveField(state.designTemplate),
  };
};

export default connect(mapStateToProps, {
  storeFieldUndoRedo,
  updateSingleColorChart,
  updateFieldCollaboration,
})(PickChartColor);
