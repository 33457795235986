const SingleColumnChart = (
  <svg width="100%" height="100%" viewBox="0 0 857.5 514.5">
    <g>
      <rect
        fill="#6e61e5ff"
        x="51.45"
        y="411.6"
        height="20%"
        width="68.60000000000001"
      ></rect>
      <rect
        fill="#6e61e5ff"
        x="222.95"
        y="274.4"
        height="46.666666666666664%"
        width="68.60000000000001"
      ></rect>
      <rect
        fill="#6e61e5ff"
        x="394.45"
        y="126.91"
        height="75.33333333333333%"
        width="68.60000000000001"
      ></rect>
      <rect
        fill="#6e61e5ff"
        x="565.95"
        y="370.44"
        height="28%"
        width="68.60000000000001"
      ></rect>
      <rect
        fill="#6e61e5ff"
        x="737.45"
        y="0"
        height="100%"
        width="68.60000000000001"
      ></rect>
    </g>
  </svg>
);

export default SingleColumnChart;
