import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {createShortKey} from "../../../../../../store/reducers/functions/fields/utils";
import {respondToThread} from "../../../../../../store/actions/template/comment.action";
import CommentRespondInput from "./CommentRespondInput";
import {useSocket} from "../../../../../../webSocket/useSocket";

const RespondingToComment = ({
  mainThread,
  onCancel,
  respondToThread,
  user,
  tempID,
  slideID,
  theme,
  searchList,
  respondingToCommentBy,
}) => {
  const [input, setInput] = useState("");
  const [processing, setProcessing] = useState(false);
  const {emitSocketEvents} = useSocket() || {};

  const handleInput = (e) => {
    const {value} = e.target;
    setInput(value);
  };

  const handleSubmit = () => {
    const currentTimeStamp = new Date().getTime();
    if (!processing) {
      let finalUserList = [],
        finalUsernames = [];
      
      // remove duplicated mentioned users
      const filterMentionedUsers = Array.from(new Set(mentionedUsers.map(obj => obj.id)))
      .map(value => mentionedUsers.find(obj => obj.id === value));

      // if user remove the user from input, update the list here before submitting
      filterMentionedUsers.map((mentionedUser) => {
        if (input.includes(mentionedUser.id) && mentionedUser.id !== user.username) {
          finalUserList.push({
            username: mentionedUser.id,
            name: mentionedUser.display,
            email: mentionedUser.email,
          });
          finalUsernames.push(mentionedUser.id);
        }
      });

      // to notify the parent comment owner
      // won't notify if commenter is the owner of the main thread
      if (!finalUsernames.includes(mainThread.commentedBy.username) && mainThread.commentedBy.username !== user.username) {
        finalUserList.push({
          username: mainThread.commentedBy.username,
          name: mainThread.commentedBy.name,
        });
      }

      // changes here should also reflect in CommentResponses.js
      const payload = {
        ID: createShortKey(),
        comment: input,
        commentedBy: {
          name: user.name,
          username: user.username,
        },
        mentionedUsers: finalUserList,
        createdAt: currentTimeStamp,
      };
      setProcessing(true);
      fetch(
        `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.username}/template/${tempID}/comments/${mainThread.ID}/subcomments`,
        {
          method: "POST",
          body: JSON.stringify({
            mainThreadID: mainThread.ID,
            payload,
            slideID,
            respondingTo: respondingToCommentBy, // responding to subcomment
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          respondToThread({
            mainThreadID: mainThread.ID,
            slideID,
            payload: {
              ...payload,
              createdAt: data.response.updatedAt,
            },
          });

          if (emitSocketEvents) {
            emitSocketEvents({
              actionType: "add-subcomment",
              item: {
                type: null,
                value: {
                  mainThreadID: mainThread.ID,
                  payload,
                  slideID,
                  createdAt: data.response.updatedAt,
                },
              },
            });
          }
          setProcessing(false);
          onCancel();
        })
        .catch((e) => {
          setProcessing(false);
        });
    }
  };

  const filterMentionedUsers = (username) => {
    mentionedUsers.filter(mentionedUser => mentionedUser.username !== username);
    return mentionedUsers.length > 0
  }

  const [mentionedUsers, setMentionedUsers] = useState([]);
  const handleAdd = (userID) => {
    const getUser = searchList.find((member) => member.id === userID);
    setMentionedUsers([...mentionedUsers, getUser]);
  };

  useEffect(() => {
    if (respondingToCommentBy?.name && respondingToCommentBy?.username !== user.username) {
      const getUser = searchList.find((member) => member.id === respondingToCommentBy.username);
      const item = {
        id: respondingToCommentBy.username,
        display: respondingToCommentBy.name,
        email: getUser?.email
      };
      const userAlreadyAdded = filterMentionedUsers(respondingToCommentBy.username)
      if (!userAlreadyAdded) {
        setMentionedUsers([...mentionedUsers, item])
        setInput(`@[${respondingToCommentBy?.name}](${respondingToCommentBy?.username})`)
      }
    }
  }, [respondingToCommentBy, searchList]);

  return (
    <div style={{width: "100%"}}>
      <CommentRespondInput
        value={input}
        onChange={handleInput}
        onSubmit={handleSubmit}
        processing={processing}
        enableCancel={true}
        onCancel={onCancel}
        theme={theme}
        searchList={searchList}
        onAddMentionedUsers={handleAdd}
      />
    </div>
  );
};

export default connect(null, {respondToThread})(RespondingToComment);
