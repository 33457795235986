import React from "react";
import {toggleTableRowsColumnsDrawer} from "../../../../../../store/actions/fields/table.action";
import {connect} from "react-redux";
import {EditRowColumnOption} from "./EditTableRowOption";

const EditTableColumnOption = ({
  selectedColumn,
  toggleTableRowsColumnsDrawer,
  startingOffset,
}) => {
  const onClickTableActions = () => {
    toggleTableRowsColumnsDrawer("column", selectedColumn);
  };

  return (
    <EditRowColumnOption
      style={{
        left: startingOffset,
        top: -90,
      }}
      onClick={onClickTableActions}
    >
      <div />
      <div />
    </EditRowColumnOption>
  );
};
export default connect(null, {toggleTableRowsColumnsDrawer})(
  EditTableColumnOption
);
