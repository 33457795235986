import React from "react";
import styled from "styled-components";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";
import { defaultMaxWidthDesktop } from "../layoutVariables";

const FeatureNightTheme = () => {
  const windowSize = getWindowSize();

  return (
    <Layout>
      <AnimateIn>
        <Header>
          <h1>
            And with a {" "}
            <span style={{textDecoration: "underline", color: "#6fc8ff"}}>
              Night Theme!
            </span>
          </h1>
        </Header>
      </AnimateIn>

      <AnimateIn>
        <img
          src={
            windowSize.width > 1450
              ? "https://dnaeh50kru9rd.cloudfront.net/home-page/night-theme/night-mode-1500x.png"
              : windowSize.width > 550
              ? "https://dnaeh50kru9rd.cloudfront.net/home-page/night-theme/night-mode-1200x.png"
              : "https://dnaeh50kru9rd.cloudfront.net/home-page/night-theme/night-mode-600x.png"
          }
          alt=""
          loading="lazy"
        />
      </AnimateIn>
    </Layout>
  );
};

const Layout = styled.div`
  background: linear-gradient(45deg, #342e42, #110e22);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 32px;
  padding: 105px 0 95px;
  img {
    max-width: 100%;
    object-fit: contain;
    padding: 0 1.5%;
    box-sizing: border-box;
    filter: drop-shadow(2px 4px 6px #3c3c55);
    @media only screen and (min-width: 1200px) {
      max-width: 1200px;
    }
  }
  @media only screen and (min-width: 601px) {
    padding: 85px 3% 135px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 600px) {
    padding: 75px 20px 60px;
    box-sizing: border-box;
    img {
      padding: 0;
      height: auto;
    }
  }
`;

const Header = styled.div`
  h1 {
    color: #cfcfcf;
    font-size: 2.4rem;
    margin-bottom: 42px;
  }
`;

export default FeatureNightTheme;
