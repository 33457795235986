import React, {useRef} from "react";
import {Modal} from "../../../../../oat-ui";
import styled from "styled-components";
import {connect} from "react-redux";
import {selectUserInfo} from "../../../../../../redux/user/authUser";
import Spinner from "../../../../../oat-ui/core/Spinner";

const ProfileImageCover = ({
  user,
  profileInfo,
  close,
  changeModal,
  fetching,
}) => {
  const avatarFileRef = useRef(null);
  const coverFileRef = useRef(null);

  const onInitUploadCover = () => {
    coverFileRef.current.click();
  };

  const onInitUploadAvatar = () => {
    avatarFileRef.current.click();
  };

  const uploadAvatar = (e) => {
    handleImageUpload(
      e,
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.account.business.slug}/userInfo/profile/avatar`
    );
  };

  const uploadCover = (e) => {
    handleImageUpload(
      e,
      `https://vx5fpvw01l.execute-api.us-east-1.amazonaws.com/main/${user.account.business.slug}/userInfo/profile/cover`
    );
  };

  const handleImageUpload = async (event, uploadUrl) => {
    if (event.target.files && event.target.files[0]) {
      const {url, fields} = await fetch(uploadUrl, {
        method: "POST",
        body: JSON.stringify({
          contentType: event.target.files[0].type,
        }),
      }).then((response) => response.json());

      const data = {
        bucket: "oat-users",
        ...fields,
        "Content-Type": event.target.files[0].type,
        file: event.target.files[0],
      };

      const formData = new FormData();
      for (const name in data) {
        formData.append(name, data[name]);
      }

      await fetch(url, {
        method: "POST",
        body: formData,
      }).then(() => close());
      event.target.value = null;
    }
  };

  const navigateToUpdateBio = () => {
    changeModal("profile-update-bio");
  };

  return (
    <Modal width={420} height={374} close={close}>
      {!fetching ? (
        <Wrapper>
          <Item>
            <FieldName>Profile Image</FieldName>
            <FieldValue>
              <Avatar src={profileInfo.avatar} />
              <UploadFile onClick={onInitUploadAvatar}>Change</UploadFile>
              <input
                ref={avatarFileRef}
                type="file"
                onChange={uploadAvatar}
                hidden
              />
            </FieldValue>
          </Item>
          <Item>
            <FieldName>Cover</FieldName>
            <FieldValue>
              <Cover src={profileInfo.cover} />
              <UploadFile onClick={onInitUploadCover}>Change</UploadFile>
              <input
                ref={coverFileRef}
                type="file"
                onChange={uploadCover}
                hidden
              />
            </FieldValue>
          </Item>
          <Item>
            <FieldName>Business Info</FieldName>
            <FieldValue>
              <UploadFile onClick={navigateToUpdateBio}>Edit</UploadFile>
            </FieldValue>
          </Item>
        </Wrapper>
      ) : (
        <Wrapper>
          <Spinner />
        </Wrapper>
      )}
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
`;

const Item = styled.div`
  display: flex;
  height: 74px;
  align-items: center;
  border-bottom: 1px solid #dedede;
`;

const FieldName = styled.div`
  flex: 1;
  font-size: 1rem;
`;

const FieldValue = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
`;

const Cover = styled.img`
  width: 120px;
  height: 54px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 12px;
`;

const UploadFile = styled.div`
  cursor: pointer;
`;

const mapStateToProps = (state) => {
  return {
    user: selectUserInfo(state.authUser),
  };
};

export default connect(mapStateToProps)(ProfileImageCover);
