import React from "react";
import styled from "styled-components";
import {createPortal} from "react-dom";
import { MagnifyingGlass } from "../../../oat-svg-icons";

const SearchInput = ({value, placeholder, onChange}) => {
  return (
    <Wrapper>
      <WrapInput>
        <SearchButton>{MagnifyingGlass}</SearchButton>
        <Input value={value} placeholder={placeholder ? placeholder : "Search"} onChange={onChange} />
      </WrapInput>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 14px;
`;

const WrapInput = styled.div`
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 36px;
  background: transparent;
  border: 1px solid ${({theme}) => theme.deskGap};
  padding-left: 36px;
  font-size: 0.95rem;
  box-sizing: border-box;
  border-radius: 6px;
  color: ${({theme}) => theme.deskPrimaryTxt};
`;

const SearchButton = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  left: 10px;
  cursor: pointer;
  fill: ${({theme}) => theme.deskPrimaryTxt};
`;

export default SearchInput;
